import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getPokaAssessor } from '@/store/poka/actions';

import { selectPokaSkills } from '@/store/poka/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Typography } from 'antd';
import ShowProgress from '@/components/ShowProgress/ShowProgress';

import AssessorsModal from './parts/AssessorsModal';

import { PlanStatus } from '@shared/constants/plan';

import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.scss';

import { IN_PROGRESS, SCHEDULED } from '@/constants/study-plan-statuses';

const PokaInfoBlock = ({ topic, statistic, endMaterial }) => {
  const { t } = useTranslation('pokaInfo');
  const { assessor } = useSelector(selectPokaSkills);
  const { currentUser } = useSelector(selectUsers);
  const { studyPlanId } = useSelector(selectStudyPlan);

  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // подгруз в /collection-topics/52772/study
    if (studyPlanId?.[0]?.status !== SCHEDULED) dispatch(getPokaAssessor(currentUser.id, topic?.id));
  }, []);

  const onSubmit = () => {
    if (topic?.poka?.withoutValidation) return endMaterial(true);
    return setOpenModal(true);
  };

  const textInButton = () => {
    if (topic?.poka?.withoutValidation) return t('complete');
    return t('sendToApprove');
  };

  const buttonIsDisabled = () => {
    if (!statistic) return true;
    if (statistic?.countBlocks !== statistic?.countFinishedBlocks) return true;
    if (
      studyPlanId?.[0]?.status !== PlanStatus.IN_PROGRESS &&
      studyPlanId?.[0]?.status !== PlanStatus.REJECTED_BY_MANAGER
    )
      return true;
    return false;
  };

  return (
    <div className={style['poka-info']}>
      <div className={style['poka-info__element']}>
        <Typography.Text className={style['poka-info__text']}>{t('targetAssessment')}</Typography.Text>
        <Typography.Text className={style['poka-info__value']}>{topic?.poka?.endorsementLevel?.label}</Typography.Text>
      </div>
      <div className={style['poka-info__element']}>
        <Typography.Text className={style['poka-info__text']}>{t('assessment')}</Typography.Text>
        <Typography.Text className={cn(style['poka-info__value'], style['poka-info__value--blue'])}>
          {t('awaitingAssessment')}
        </Typography.Text>
      </div>
      {studyPlanId[0]?.status && (studyPlanId[0]?.status !== SCHEDULED || studyPlanId[0]?.status !== IN_PROGRESS) && (
        <div className={style['poka-info__element']}>
          <Typography.Text className={style['poka-info__text']}>{t('asessor')}</Typography.Text>
          <Typography.Text className={style['poka-info__text']}>{assessor?.assessorName}</Typography.Text>
        </div>
      )}
      <Link to={`/collection-topics/${topic?.id}`}>
        <Typography.Text className={style['poka-info__link']}>{t('aboutSkill')}</Typography.Text>
      </Link>
      <div>
        <ShowProgress steps={statistic?.countBlocks} completedSteps={statistic?.countFinishedBlocks} />
      </div>
      <Button type='primary' size='large' onClick={onSubmit} disabled={buttonIsDisabled()}>
        {textInButton()}
      </Button>
      <AssessorsModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        assessors={topic?.poka?.assessors}
        endMaterial={endMaterial}
      />
    </div>
  );
};

PokaInfoBlock.propTypes = {
  topic: PropTypes.object,
  statistic: PropTypes.object,
  endMaterial: PropTypes.func,
};

export default PokaInfoBlock;
