export function extractUsername(student) {
  let name = '';
  if (typeof student === 'object' && student !== null) {
    const attr = ['last_name', 'first_name', 'middle_name'];
    for (let i = 0; i < attr.length; i++) {
      const field = attr[i];
      if (field in student && typeof student[field] === 'string' && student[field].length > 0) {
        i === 0 ? (name = student[field]) : (name += ' ' + student[field]);
      }
    }
  }

  return name;
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isString(value, notEmpty = false) {
  if (notEmpty === true) {
    return typeof value === 'string' && value.length > 0;
  }

  return typeof value === 'string';
}

export function isBool(value) {
  return typeof value === 'boolean';
}

export function isObject(value) {
  return typeof value === 'object' && value !== null;
}

export function isNum(value) {
  return typeof value === 'number';
}

export function randomValueFromArray(arr) {
  if (typeof arr !== 'undefined' && Array.isArray(arr)) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
}
