import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'antd';
import FileInput from '@/components/FileInput/FileInput';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../Question.module.scss';

const { TextArea } = Input;

export const BlockWithOpenAnswer = ({ data, setAnswer, nextCurrent, test }) => {
  const [files, setFiles] = useState([]);
  const [answerText, setAnswerText] = useState('');
  const [errorField, setErrorField] = useState(false);
  const { t } = useTranslation('question');

  useEffect(() => {
    setFiles([]);
    setAnswerText('');
  }, [data]);

  const handleFileAttach = files => {
    if (Array.isArray(files)) {
      setFiles(files);
      setErrorField(false);
    }
  };

  const questionHandler = event => {
    const answer = String(event.target.value).replaceAll(';', '');
    setAnswerText(answer);
    setErrorField(false);
  };

  const validationSetAnswer = answerData => {
    if ((data.hasAttachment && !answerData.file.length) || !answerData.userAnswer.length) {
      setErrorField(true);
    } else {
      setErrorField(false);
      nextCurrent();
      if (data.hasAttachment) {
        setAnswer({
          questionId: answerData.questionId,
          userAnswer: answerData.userAnswer,
          uuid: answerData.file.length && answerData.file[0]['uuid'],
          testId: test?.test?.testId,
        });
      } else {
        setAnswer({
          questionId: answerData.questionId,
          userAnswer: answerData.userAnswer,
          testId: test?.test?.testId,
        });
      }
    }
  };

  const confirmQuestionClick = () => {
    validationSetAnswer({
      questionId: data.id,
      userAnswer: answerText,
      file: files,
    });
  };

  return (
    <>
      <div className={css['Question-openAnswer']}>
        <TextArea
          autoSize={{ minRows: 3 }}
          name='question'
          placeholder={t('openAnswer')}
          onChange={questionHandler}
          maxLength={4096}
          className={css['Question-openAnswer']}
          value={answerText}
        />
        {data.hasAttachment && (
          <div className={css['Question-openAnswer-fileInputBlock']}>
            <div className={css['Question-openAnswer-label']}>{t('hasAttachment')}</div>
            <FileInput
              onChange={handleFileAttach}
              singleFile
              label=''
              required={true}
              fileLoading={true}
              fileLoadingImage={false}
              name='openAnswerFileAttached'
              buttonAddText={t('file')}
              value={files}
              files={files}
              isPublicFile={true}
            />
          </div>
        )}
      </div>

      <div className={css['Question-confirmButton']}>
        <Button onClick={confirmQuestionClick} type='primary' size='large' disabled={false}>
          {t('confirm')}
        </Button>
      </div>

      {errorField && <span className={classNames(css['Question__error'])}>{t('errorOpenAnswer')}</span>}
    </>
  );
};

BlockWithOpenAnswer.propTypes = {
  data: PropTypes.object,
  setAnswer: PropTypes.func,
  nextCurrent: PropTypes.func,
  test: PropTypes.object,
};
