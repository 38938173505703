import React from 'react';

export const BarChartOutlined = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z'
        fill='#F6F6F6'
      />
      <path
        d='M15.0132 13.7334H5.83988V5.41337C5.83988 5.3547 5.79188 5.3067 5.73322 5.3067H4.98655C4.92788 5.3067 4.87988 5.3547 4.87988 5.41337V14.5867C4.87988 14.6454 4.92788 14.6934 4.98655 14.6934H15.0132C15.0719 14.6934 15.1199 14.6454 15.1199 14.5867V13.84C15.1199 13.7814 15.0719 13.7334 15.0132 13.7334ZM7.01322 12.6667H7.75988C7.81855 12.6667 7.86655 12.6187 7.86655 12.56V10.64C7.86655 10.5814 7.81855 10.5334 7.75988 10.5334H7.01322C6.95455 10.5334 6.90655 10.5814 6.90655 10.64V12.56C6.90655 12.6187 6.95455 12.6667 7.01322 12.6667ZM9.03988 12.6667H9.78655C9.84522 12.6667 9.89322 12.6187 9.89322 12.56V8.29337C9.89322 8.2347 9.84522 8.1867 9.78655 8.1867H9.03988C8.98122 8.1867 8.93322 8.2347 8.93322 8.29337V12.56C8.93322 12.6187 8.98122 12.6667 9.03988 12.6667ZM11.0665 12.6667H11.8132C11.8719 12.6667 11.9199 12.6187 11.9199 12.56V9.33337C11.9199 9.2747 11.8719 9.2267 11.8132 9.2267H11.0665C11.0079 9.2267 10.9599 9.2747 10.9599 9.33337V12.56C10.9599 12.6187 11.0079 12.6667 11.0665 12.6667ZM13.0932 12.6667H13.8399C13.8985 12.6667 13.9465 12.6187 13.9465 12.56V7.2267C13.9465 7.16804 13.8985 7.12004 13.8399 7.12004H13.0932C13.0345 7.12004 12.9865 7.16804 12.9865 7.2267V12.56C12.9865 12.6187 13.0345 12.6667 13.0932 12.6667Z'
        fill='#2D3440'
      />
    </g>
    <defs>
      <clipPath id='clip0_301_2'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ExternalIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none'>
      <rect fill='#F6F6F6' width='20' height='20' rx='10' />
      <g transform='translate(2 2)' />
      <g stroke='#2D3440'>
        <path d='M13.17 9.185v3.953c0 .7-1.412 1.575-3.153 1.575s-3.153-.873-3.153-1.575V9.185' />
        <path d='M10.017 11.108 5.03 8.34l4.987-2.768 4.987 2.768z' />
      </g>
    </g>
  </svg>
);

export const DefaultCorpIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none'>
      <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
      <g fill='#2D3440'>
        <path d='m13.331 6.121 3.012 2.506c.512.426-.127 1.195-.64.768L12.692 6.89 10 4.65 7.32 6.89c-.512.427-1.153-.34-.642-.768.512-.428 1.153.34.642.767l-3 2.506c-.512.427-1.153-.34-.642-.768l3-2.505L9.662 3.63A.5.5 0 0 1 10 3.49c.127 0 .246.054.337.139zm0 0-.64.769zm-6.01.768-.642-.767z' />
        <path d='M9 15H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1v6h2v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4h2V8a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm2-5H9v4h2z' />
      </g>
    </g>
  </svg>
);
