/* eslint-disable */
import { API_BASE, API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_INCOME_START = 'GET_INCOME_START';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';
export const GET_INCOME_FAIL = 'GET_INCOME_FAIL';
export const GET_USER_INCOME_START = 'GET_USER_INCOME_START';
export const GET_USER_INCOME_SUCCESS = 'GET_USER_INCOME_SUCCESS';
export const GET_USER_INCOME_FAIL = 'GET_USER_INCOME_FAIL';
export const GET_USER_EXTERNAL_INCOME_SUCCESS = 'GET_USER_EXTERNAL_INCOME_SUCCESS';
export const GET_USER_EXTERNAL_INCOME_FAIL = 'GET_USER_EXTERNAL_INCOME_FAIL';
export const GET_USER_EXTERNAL_INCOME_START = 'GET_USER_EXTERNAL_INCOME_START';
export const RESET_USER_INCOME = 'RESET_USER_INCOME';
export const RESET_SCOPE_INCOME = 'RESET_SCOPE_INCOME';
export const SET_SCOPE_INCOME = 'SET_SCOPE_INCOME';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getIncomeExternalUser = (onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_BASE}/api/v1/oauth2/authorization/google`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_USER_EXTERNAL_INCOME_START, GET_USER_EXTERNAL_INCOME_SUCCESS, GET_USER_EXTERNAL_INCOME_FAIL],
    onSuccess,
    onError,
  };
};

export const getIncome = (userId, incomeType, redirectTo) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(userId)}/income${getQueryString({
    sort: 'date,desc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken'),
  },
  incomeType,
  redirectTo,
  types: [GET_INCOME_START, GET_INCOME_SUCCESS, GET_INCOME_FAIL],
});

const defaultToken = '';

export const getUserIncome = (userId, page, incomeType = '', redirectTo = '', sort = 'date,desc') => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/income${getQueryString({ sort, userId, page })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  incomeType,
  redirectTo,
  types: [GET_USER_INCOME_START, GET_USER_INCOME_SUCCESS, GET_USER_INCOME_FAIL],
});

// todo новый варинт, т.к. не совместим со старым,
// по задаче https://jira.simbirsoft.com/browse/QQ-241
export const getUserIncome2 = ({
  userId,
  size = 1,
  sort = 'id,desc',
  topicId,
  incomeType = '',
  bonus = '',
  onSuccess = null,
  redirectTo = '',
  incomeContentLabel = null,
}) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/income${getQueryString({
    sort,
    userId,
    topicId,
    size,
    bonus,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  incomeType,
  redirectTo,
  incomeContentLabel,
  onSuccess,
  types: [GET_USER_INCOME_START, GET_USER_INCOME_SUCCESS, GET_USER_INCOME_FAIL],
});

export const setScopeIncome = scopeAction => ({
  action: { scope: scopeAction },
  type: scopeAction ? SET_SCOPE_INCOME : RESET_SCOPE_INCOME,
});

export const resetUserIncome = () => ({
  type: RESET_USER_INCOME,
});
