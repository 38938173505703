/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MentorRequestModal from '@/components/Mentoring/MentorRequestModal';

import Resources from '@/resources';

import css from './CardMentor.module.scss';

const CardAddMentor = () => {
  const { t } = useTranslation('mentors');
  const [isRequest, setIsRequest] = useState(false);
  const click = () => setIsRequest(true);

  return (
    <div className={css['Card']}>
      <div className={css['Card-empty']}>
        <div className={css['Card-empty-icon']} onClick={click}>
          <Resources.icons.Plus />
        </div>
        <div className={css['Card-empty-text']}>{t('becomeMentor')}</div>
      </div>

      {isRequest && <MentorRequestModal open={isRequest} onClose={() => setIsRequest(false)} />}
    </div>
  );
};

export default CardAddMentor;
