/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_USER_TOPICS_START = 'GET_OWN_USER_TOPICS_START';
export const GET_USER_TOPICS_SUCCESS = 'GET_OWN_USER_TOPICS_SUCCESS';
export const GET_USER_TOPICS_FAIL = 'GET_OWN_USER_TOPICS_FAIL';
export const GET_RECOMMENDED_TOPICS_START = 'GET_RECOMMENDED_TOPICS_START';
export const GET_RECOMMENDED_TOPICS_SUCCESS = 'GET_RECOMMENDED_TOPICS_SUCCESS';
export const GET_RECOMMENDED_TOPICS_FAIL = 'GET_RECOMMENDED_TOPICS_FAIL';
export const GET_MY_RECOMMENDATIONS_TOPICS_START = 'GET_MY_RECOMMENDATIONS_TOPICS_START';
export const GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS = 'GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS';
export const GET_MY_RECOMMENDATIONS_TOPICS_FAIL = 'GET_MY_RECOMMENDATIONS_TOPICS_FAIL';
export const POST_USER_TOPICS_START = 'POST_USER_TOPICS_START';
export const POST_USER_TOPICS_SUCCESS = 'POST_USER_TOPICS_SUCCESS';
export const POST_USER_TOPICS_FAIL = 'POST_USER_TOPICS_FAIL';
export const POST_MULTIPLE_USER_TOPICS_START = 'POST_MULTIPLE_USER_TOPICS_START';
export const POST_MULTIPLE_USER_TOPICS_SUCCESS = 'POST_MULTIPLE_USER_TOPICS_SUCCESS';
export const POST_MULTIPLE_USER_TOPICS_FAIL = 'POST_MULTIPLE_USER_TOPICS_FAIL';

export const DELETE_USER_TOPIC_START = 'DELETE_USER_TOPIC_START';
export const DELETE_USER_TOPIC_SUCCESS = 'DELETE_USER_TOPIC_SUCCESS';
export const DELETE_USER_TOPIC_FAIL = 'DELETE_USER_TOPIC_FAIL';

export const DELETE_PROFILE_USER_TOPIC_START = 'DELETE_PROFILE_USER_TOPIC_START';
export const DELETE_PROFILE_USER_TOPIC_SUCCESS = 'DELETE_PROFILE_USER_TOPIC_SUCCESS';
export const DELETE_PROFILE_USER_TOPIC_FAIL = 'DELETE_PROFILE_USER_TOPIC_FAIL';

export const DELETE_PROFILE_USER_TOPIC_RECOMMENDED_START = 'DELETE_PROFILE_USER_TOPIC_RECOMMENDED_START';
export const DELETE_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS = 'DELETE_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS';
export const DELETE_PROFILE_USER_TOPIC_RECOMMENDED_FAIL = 'DELETE_PROFILE_USER_TOPIC_RECOMMENDED_FAIL';

export const POST_PROFILE_USER_TOPIC_RECOMMENDED_START = 'POST_PROFILE_USER_TOPIC_RECOMMENDED_START';
export const POST_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS = 'POST_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS';
export const POST_PROFILE_USER_TOPIC_RECOMMENDED_FAIL = 'POST_PROFILE_USER_TOPIC_RECOMMENDED_FAIL';

export const DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_START = 'DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_START';
export const DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_SUCCESS = 'DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_SUCCESS';
export const DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_FAIL = 'DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_FAIL';

export const GET_USER_TOPICS_VALUE_START = 'GET_USER_TOPICS_VALUE_START';
export const GET_USER_TOPICS_VALUE_SUCCESS = 'GET_USER_TOPICS_VALUE_SUCCESS';
export const GET_USER_TOPICS_VALUE_FAIL = 'GET_USER_TOPICS_VALUE_FAIL';

export const CREATE_PROFILE_STUDY_PLAN_START = 'CREATE_PROFILE_STUDY_PLAN_START';
export const CREATE_PROFILE_STUDY_PLAN_SUCCESS = 'CREATE_PROFILE_STUDY_PLAN_SUCCESS';
export const CREATE_PROFILE_STUDY_PLAN_FAIL = 'CREATE_PROFILE_STUDY_PLAN_FAIL';

export const GET_ANOTHER_MY_RECOMMENDED_TOPICS_START = 'GET_ANOTHER_MY_RECOMMENDED_TOPICS_START';
export const GET_ANOTHER_MY_RECOMMENDED_TOPICS_SUCCESS = 'GET_ANOTHER_MY_RECOMMENDED_TOPICS_SUCCESS';
export const GET_ANOTHER_MY_RECOMMENDED_TOPICS_FAIL = 'GET_ANOTHER_MY_RECOMMENDED_TOPICS_FAIL';

export const CREATE_ANOTHER_PROFILE_STUDY_PLAN_START = 'CREATE_ANOTHER_PROFILE_STUDY_PLAN_START';
export const CREATE_ANOTHER_PROFILE_STUDY_PLAN_SUCCESS = 'CREATE_ANOTHER_PROFILE_STUDY_PLAN_SUCCESS';
export const CREATE_ANOTHER_PROFILE_STUDY_PLAN_FAIL = 'CREATE_ANOTHER_PROFILE_STUDY_PLAN_FAIL';

export const GET_STATISTIC_TOPICS_START = 'GET_STATISTIC_TOPICS_START';
export const GET_STATISTIC_TOPICS_SUCCESS = 'GET_STATISTIC_TOPICS_SUCCESS';
export const GET_STATISTIC_TOPICS_FAIL = 'GET_STATISTIC_TOPICS_FAIL';

export const SET_AVA_TO_CACHE_SUCCESS = 'SET_AVA_TO_CACHE_SUCCESS';

export const CHANGE_USER_TOPIC_FILTER = 'CHANGE_USER_TOPIC_FILTER';
export const CLEAR_USER_TOPIC_FILTER = 'CLEAR_USER_TOPIC_FILTER';
export const CHECK_FAVORITE_TOPICS_START = 'CHECK_FAVORITE_TOPICS_START';
export const CHECK_FAVORITE_TOPICS_SUCCESS = 'CHECK_FAVORITE_TOPICS_SUCCESS';
export const CHECK_FAVORITE_TOPICS_FAIL = 'CHECK_FAVORITE_TOPICS_FAIL';
export const GET_ANOTHER_USER_TOPICS_START = 'GET_ANOTHER_OWN_USER_TOPICS_START';
export const GET_ANOTHER_USER_TOPICS_SUCCESS = 'GET_ANOTHER_OWN_USER_TOPICS_SUCCESS';
export const GET_ANOTHER_USER_TOPICS_FAIL = 'GET_ANOTHER_OWN_USER_TOPICS_FAIL';
export const GET_ANOTHER_RECOMMENDED_TOPICS_START = 'GET_ANOTHER_RECOMMENDED_TOPICS_START';
export const GET_ANOTHER_RECOMMENDED_TOPICS_SUCCESS = 'GET_ANOTHER_RECOMMENDED_TOPICS_SUCCESS';
export const GET_ANOTHER_RECOMMENDED_TOPICS_FAIL = 'GET_ANOTHER_RECOMMENDED_TOPICS_FAIL';
export const SEND_NOTIFICATION_PLANNED = 'SEND_NOTIFICATION_PLANNED';
export const SEND_NOTIFICATION_RECOMMENDED = 'SEND_NOTIFICATION_RECOMMENDED';
export const SEND_NOTIFICATION_DEFAULT = 'SEND_NOTIFICATION_DEFAULT';

export const GET_MY_RECOMMENDED_NEWS_START = 'GET_MY_RECOMMENDED_NEWS_START';
export const GET_MY_RECOMMENDED_NEWS_SUCCESS = 'GET_MY_RECOMMENDED_NEWS_SUCCESS';
export const GET_MY_RECOMMENDED_NEWS_FAIL = 'GET_MY_RECOMMENDED_NEWS_FAIL';

export const GET_ANOTHER_RECOMMENDED_NEWS_START = 'GET_RECOMMENDED_NEWS_START';
export const GET_ANOTHER_RECOMMENDED_NEWS_SUCCESS = 'GET_RECOMMENDED_NEWS_SUCCESS';
export const GET_ANOTHER_RECOMMENDED_NEWS_FAIL = 'GET_RECOMMENDED_NEWS_FAIL';

export const PUT_TOPIC_ALLRECOMMEND_START = 'PUT_TOPIC_ALLRECOMMEND_START';
export const PUT_TOPIC_ALLRECOMMEND_SUCCESS = 'PUT_TOPIC_ALLRECOMMEND_SUCCESS';
export const PUT_TOPIC_ALLRECOMMEND_FAIL = 'PUT_TOPIC_ALLRECOMMEND_FAIL';

const headers = {
  Accept: 'application/json',
};

const defaultToken = '';

const getTopicFilterApiValues = filter => {
  const { tag, ...rest } = filter;
  return tag && tag.id ? { ...rest, tagId: tag.id } : rest;
};

export const getUserTopics = ({ filter = {}, ...rest }, onSuccess, isAnotherUser) => {
  const apiFilter = getTopicFilterApiValues(filter);
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({
      recommend: false,
      sort: 'createdDate,desc',
      ...apiFilter,
      ...rest,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: isAnotherUser
      ? [GET_ANOTHER_USER_TOPICS_START, GET_ANOTHER_USER_TOPICS_SUCCESS, GET_ANOTHER_USER_TOPICS_FAIL]
      : [GET_USER_TOPICS_START, GET_USER_TOPICS_SUCCESS, GET_USER_TOPICS_FAIL],
    onSuccess,
  };
};

export const getNewTopicsValue = (params = {}, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({
      recommend: true,
      sort: 'createdDate,desc',
      ...params,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_USER_TOPICS_VALUE_START, GET_USER_TOPICS_VALUE_SUCCESS, GET_USER_TOPICS_VALUE_FAIL],
    onSuccess,
  };
};

export const getRecommendedUserTopics = (config, isAnotherUser, myRecommendations, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({
      recommend: true,
      sort: 'createdDate,desc',
      ...config,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types:
      isAnotherUser && !myRecommendations
        ? [
            GET_ANOTHER_RECOMMENDED_TOPICS_START,
            GET_ANOTHER_RECOMMENDED_TOPICS_SUCCESS,
            GET_ANOTHER_RECOMMENDED_TOPICS_FAIL,
          ]
        : isAnotherUser && myRecommendations
          ? [
              GET_ANOTHER_MY_RECOMMENDED_TOPICS_START,
              GET_ANOTHER_MY_RECOMMENDED_TOPICS_SUCCESS,
              GET_ANOTHER_MY_RECOMMENDED_TOPICS_FAIL,
            ]
          : myRecommendations
            ? [
                GET_MY_RECOMMENDATIONS_TOPICS_START,
                GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS,
                GET_MY_RECOMMENDATIONS_TOPICS_FAIL,
              ]
            : [GET_RECOMMENDED_TOPICS_START, GET_RECOMMENDED_TOPICS_SUCCESS, GET_RECOMMENDED_TOPICS_FAIL],
    onSuccess,
  };
};

export const getRecommendedForMeUserTopics = (config, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({
      recommend: true,
      sort: 'createdDate,desc',
      ...config,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_RECOMMENDED_TOPICS_START, GET_RECOMMENDED_TOPICS_SUCCESS, GET_RECOMMENDED_TOPICS_FAIL],
    onSuccess,
  };
};

export const getMyRecommendationUserTopics = (config, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({
      recommend: true,
      sort: 'createdDate,desc',
      ...config,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [
      GET_MY_RECOMMENDATIONS_TOPICS_START,
      GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS,
      GET_MY_RECOMMENDATIONS_TOPICS_FAIL,
    ],
    onSuccess,
  };
};

export const setAvaToCache = (userId, topicId) => {
  return {
    type: SET_AVA_TO_CACHE_SUCCESS,
    value: {
      id: userId,
      topicUrl: topicId,
    },
  };
};

export const checkFavoriteUserTopic = (userId, topicId) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic${getQueryString({ userId, topicId, recommend: false })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [CHECK_FAVORITE_TOPICS_START, CHECK_FAVORITE_TOPICS_SUCCESS, CHECK_FAVORITE_TOPICS_FAIL],
  };
};

export const getStatisticTopics = userId => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/topic/statistic${getQueryString({ userId })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_STATISTIC_TOPICS_START, GET_STATISTIC_TOPICS_SUCCESS, GET_STATISTIC_TOPICS_FAIL],
  };
};

export const deleteUserTopic = (id = 0, favourite, byTopic, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/topic/${encodeURI(id)}${getQueryString({
    favourite,
    byTopic,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_USER_TOPIC_START, DELETE_USER_TOPIC_SUCCESS, DELETE_USER_TOPIC_FAIL],
  onSuccess,
});

export const deleteProfileUserTopic = (id = 0, favourite, byTopic, typeList, onSuccess) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/user/topic/${encodeURI(id)}${getQueryString({
      favourite,
      byTopic,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    listName: typeList,
    types:
      typeList === 'recommended'
        ? [
            DELETE_PROFILE_USER_TOPIC_RECOMMENDED_START,
            DELETE_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS,
            DELETE_PROFILE_USER_TOPIC_RECOMMENDED_FAIL,
          ]
        : typeList === 'myRecommended'
          ? [
              DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_START,
              DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_SUCCESS,
              DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_FAIL,
            ]
          : [DELETE_PROFILE_USER_TOPIC_START, DELETE_PROFILE_USER_TOPIC_SUCCESS, DELETE_PROFILE_USER_TOPIC_FAIL],
    onSuccess,
  };
};

export const createProfileUserTopic = (userId, id = 0, typeList, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/user/${encodeURI(userId)}/topic/${encodeURI(id)}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: {},
    listName: typeList,
    types:
      typeList === 'recommended'
        ? [
            POST_PROFILE_USER_TOPIC_RECOMMENDED_START,
            POST_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS,
            POST_PROFILE_USER_TOPIC_RECOMMENDED_FAIL,
          ]
        : [POST_USER_TOPICS_START, POST_USER_TOPICS_SUCCESS, POST_USER_TOPICS_FAIL],
    onSuccess,
  };
};

export const getAnotherRecommendedNews = (page = 0, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/recommend${getQueryString({
    type: 'FOR_ME',
    sort: 'createdDate,desc',
    size: 10,
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_ANOTHER_RECOMMENDED_NEWS_START, GET_ANOTHER_RECOMMENDED_NEWS_SUCCESS, GET_ANOTHER_RECOMMENDED_NEWS_FAIL],
});

export const getMyRecommendedNews = (page, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/recommend${getQueryString({
    type: 'BY_ME',
    sort: 'createdDate,desc',
    size: 10,
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MY_RECOMMENDED_NEWS_START, GET_MY_RECOMMENDED_NEWS_SUCCESS, GET_MY_RECOMMENDED_NEWS_FAIL],
  onSuccess,
});

export const createProfileStudyPlan = (
  userId,
  topicId,
  planDate,
  planStatus,
  comment,
  onSuccess,
  isAnother,
  withoutSchedule,
  eventId
) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/plan`,
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    comment,
    date: planDate,
    topicId,
    userId,
    withoutSchedule,
    eventId,
  },
  onSuccess,
  month: planDate,
  types: !isAnother
    ? [CREATE_PROFILE_STUDY_PLAN_START, CREATE_PROFILE_STUDY_PLAN_SUCCESS, CREATE_PROFILE_STUDY_PLAN_FAIL]
    : [
        CREATE_ANOTHER_PROFILE_STUDY_PLAN_START,
        CREATE_ANOTHER_PROFILE_STUDY_PLAN_SUCCESS,
        CREATE_ANOTHER_PROFILE_STUDY_PLAN_FAIL,
      ],
});

export const createUserTopic = (userId, id = 0, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/${encodeURI(userId)}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_USER_TOPICS_START, POST_USER_TOPICS_SUCCESS, POST_USER_TOPICS_FAIL],
  onSuccess,
});

export const createUserTopicToMultipleUsers = (userIds, id = 0, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/${encodeURI(id)}${getQueryString({ userIds })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_MULTIPLE_USER_TOPICS_START, POST_MULTIPLE_USER_TOPICS_SUCCESS, POST_MULTIPLE_USER_TOPICS_FAIL],
  onSuccess,
});

export const allUsersTopicRecommend = (topicId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${topicId}/recommend`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [PUT_TOPIC_ALLRECOMMEND_START, PUT_TOPIC_ALLRECOMMEND_SUCCESS, PUT_TOPIC_ALLRECOMMEND_FAIL],
});

export const sendNotification = (type, user) => ({
  type: `SEND_NOTIFICATION_${type}`,
  action: {
    user,
  },
});

export const changeUserTopicFilter = (name, value) => ({
  type: CHANGE_USER_TOPIC_FILTER,
  name,
  value,
});

export const clearUserTopicFilter = () => ({
  type: CLEAR_USER_TOPIC_FILTER,
});
