import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { updateBookAddresses } from '@/store/books/actions';
import { bindGoogleAccount, loginGoogle, logoutGoogle } from '@/store/login/actions';
import { getCanBeMentor, getCurrentMentor } from '@/store/mentoring_profile/actions';
import { getStudyPlan, getStudyPlanStat } from '@/store/study-plan/actions';
import { getTopicByAuthor, getTopicCreByCurUser, getTopics } from '@/store/topics/actions';
import { getCompetencies } from '@/store/user_competencies/actions';
import { getCustomFields } from '@/store/user_customfield/actions';
import {
  createProfileStudyPlan,
  createProfileUserTopic,
  deleteProfileUserTopic,
  getMyRecommendationUserTopics,
  getNewTopicsValue,
  getRecommendedForMeUserTopics,
  getUserTopics,
} from '@/store/user-topics/actions';
import { editAboutUser, getSkillsUser, getUser, overwriteValuesUser } from '@/store/users/actions';

import { selectCourse } from '@/store/course/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectMentors } from '@/store/mentors/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';
import { isLoadingSelectTopics, selectTopics } from '@/store/topics/selectors';
import { selectCustomField } from '@/store/user_customfield/selectors';
import { isLoadingSelectUserTopics, selectUserTopics } from '@/store/user-topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Col, Layout, Modal, Row } from 'antd';
import RegistrationPageDataAbout from '@/pages/Login/RegistrationPageDataAbout';
import { Avatar } from '@/components';
import MentorTag from '@/components/Mentoring/MentorTag';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal';
import TabsLink from '@/components/TabsLink/TabsLink';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';

import CalendarModal from '../CalendarModal';
import DoneSvg from './profile-done.svg';
import PlanSvg from './profile-plan.svg';

import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
} from '@shared/utils/topic-utils';

import { selectMentoring } from '@/store/mentoring_profile/selector';
import dayjs from 'dayjs';

import css from './MyProfile.module.scss';

import { SCHEDULED } from '@/constants/study-plan-statuses';

const STATUS_ACTIVE = 'ACTIVE';

const { Content } = Layout;

const MyProfile = () => {
  const { t } = useTranslation('myProfile');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, skills } = useSelector(selectUsers);
  const { stat } = useSelector(selectStudyPlan);
  const {
    cachedFiles,
    recommendedTotal,
    myRecommendationsTotal,
    myAnotherRecommendationsNews,
    myRecommendationsNews,
    recommended,
  } = useSelector(selectUserTopics);

  const { topicsByCurUserTotal } = useSelector(selectTopics);

  const isLoadingUserTopics = useSelector(isLoadingSelectUserTopics);
  const isLoadingTopics = useSelector(isLoadingSelectTopics);

  const isLoading = isLoadingUserTopics || isLoadingTopics;

  const customField = useSelector(selectCustomField);
  const { externalLogin } = useSelector(selectLogin);
  const allSelectMentoring = useSelector(selectMentoring);
  const { canBeMentor, activeMenteesCount, status } = allSelectMentoring;
  const mentorLoading = allSelectMentoring.isLoading;
  const { openDate } = useSelector(selectCourse);

  const { mentorsCommentsTotal } = useSelector(selectMentors);

  const [stateNew, setStateNew] = useState(0);
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const [planningTopicType, setPlanningTopicType] = useState({});
  const [startDate, setStartDate] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [topicInfo, setTopicInfo] = useState({});
  const [planningTopicId, setPlanningTopicId] = useState(0);
  const [page, setPage] = useState(0);
  const [placement, setPlacement] = useState(location.pathname.replace('/my-profile', '') || '/info');
  const [addressId, setAddressId] = useState(0);
  const [showParticipationConfirmationModal, setShowParticipationConfirmationModal] = useState(false);

  const updateInfinityScrollRecommended = page => {
    dispatch(
      getRecommendedForMeUserTopics({
        userId: currentUser.id,
        size: 10,
        page,
      })
    );
  };

  const updateInfinityScrollMyRecommendations = page => {
    dispatch(
      getMyRecommendationUserTopics({
        recBy: currentUser.id,
        size: 10,
        page,
      })
    );
  };

  const updateInfinityScrollTopicCreByCurUser = page => {
    dispatch(getTopicCreByCurUser({ creBy: currentUser.id, size: 10, page }, false));
  };

  useEffect(() => {
    setPage(0);
    dispatch(
      getNewTopicsValue({ userId: currentUser.id }, res =>
        setStateNew(res.filter(item => item.topic && item.topic.viewers && item.topic.viewers.viewed === false).length)
      )
    );
    dispatch(getStudyPlanStat(false, currentUser.id));
    dispatch(getSkillsUser(currentUser.id));
    dispatch(getCompetencies(currentUser.id));
    dispatch(getStudyPlan(false, currentUser.id));

    dispatch(
      getUserTopics({
        userId: currentUser.id,
        page: 0,
        size: 10,
      })
    );

    updateInfinityScrollRecommended(0);
    updateInfinityScrollMyRecommendations(0);
    updateInfinityScrollTopicCreByCurUser(0);

    dispatch(
      getTopicByAuthor(
        {
          recBy: currentUser.id,
          page: 0,
          size: 10,
        },
        false,
        true
      )
    );

    dispatch(getCustomFields(currentUser.id));
    // dispatch(getTags(false, currentUser.id));

    // Менторство
    if (currentUser?.domainCompany?.hasMentors) {
      dispatch(getCanBeMentor(res => res === false && currentUser.mentor && dispatch(getCurrentMentor())));
    }
  }, []);

  const handleCalendarCancel = () => {
    setCalendarModalVisible(false);
  };

  const loginGoogleSuccess = response => {
    dispatch(
      loginGoogle(response.Zi.access_token, () => {
        dispatch(getUser());
      })
    );
  };

  const logoutGoogleSuccess = key => {
    dispatch(
      logoutGoogle(key, () => {
        dispatch(getUser());
      })
    );
  };

  const loginGoogleFailure = () => {};

  const handleFavoriteClick = (topicId, type) => {
    dispatch(createProfileUserTopic(currentUser.id, topicId, type));
  };

  const handleDeleteClick = (topicId, type) => {
    dispatch(deleteProfileUserTopic(topicId, true, true, type));
  };

  const showCalendarModal = (topicId, type, startDate, topicInfo) => {
    setCalendarModalVisible(true);
    setPlanningTopicType(type);
    setStartDate(startDate);
    setTopicInfo(topicInfo);
    setPlanningTopicId(topicId);
  };

  const handleStudyPlanClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    webinarDateTime,
    planInfo,
    selectedId
  ) => {
    const topics = recommended?.elements?.map(el => el.topic);
    const topic = topics.find(t => t.id === topicId);

    for (let i = 0; i < planningMonths.length; i++) {
      dispatch(
        createProfileStudyPlan(
          currentUser.id,
          topicId,
          planningMonths[i],
          SCHEDULED,
          comment,
          () => {
            if (addressId > 0) {
              dispatch(updateBookAddresses(addressId, currentUser.id));
              setAddressId(0);
            }

            dispatch(getTopics({}, page));
            if (topicInfo?.recommend) {
              updateInfinityScrollRecommended(0);
            }

            handleCalendarCancel();

            if (topic && topic.targetAudience === false && isAnyCourseOrConference(topic?.type?.id)) {
              setShowParticipationConfirmationModal(true);
            }
          },
          false,
          getWithoutSchedule(planningTopicType?.id, withoutSchedule),
          selectedId
        )
      );
    }
  };

  const editInfo = () => {
    setIsEdit(!isEdit);
  };

  const disabledSelect = planningTopicType?.id === 8;

  const handleParticipationConfirmationModalCancel = () => {
    setShowParticipationConfirmationModal(false);
  };

  return (
    <>
      <Content>
        <Row className={css['app-profline__box']}>
          <Col span={24} className={css['app-profline__container']}>
            <div className={css['app-profline__card']}>
              <div className={css['app-profline__header']}>
                <div className={css['user-info-desktop']}>
                  <div className={css['user-info']}>
                    <div className={css['user-info__ava']}>
                      <Avatar user={currentUser} />
                    </div>
                    <div className={css['user-info__text']}>
                      <div className={css['user-info__text__header']}>
                        <h4 className={css['user-info__name']}>{`${currentUser.firstName} ${currentUser.lastName}`}</h4>
                        {status === STATUS_ACTIVE && <MentorTag />}
                        <span className={css['user-info__text__header__pencil']} onClick={editInfo}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='12'
                            height='12'
                            viewBox='0 0 19 19'
                            fill='none'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M12.879 1.70697C13.4415 1.14456 14.2045 0.828613 15 0.828613C15.7955 0.828613 16.5584 1.14456 17.121 1.70697L17.293 1.87897C17.8554 2.44156 18.1713 3.20448 18.1713 3.99997C18.1713 4.79546 17.8554 5.55839 17.293 6.12097L15.556 7.85997L5.70696 17.708C5.57896 17.836 5.41859 17.9269 5.24297 17.971L1.24296 18.971C1.07538 19.0129 0.899782 19.0108 0.73328 18.9647C0.566778 18.9186 0.415053 18.8302 0.292893 18.708C0.170733 18.5859 0.0823047 18.4342 0.036226 18.2677C-0.00985267 18.1012 -0.0120098 17.9256 0.0299649 17.758L1.02997 13.758C1.07401 13.5823 1.16491 13.422 1.29297 13.294L11.199 3.38797L12.879 1.70797V1.70697ZM15.707 3.12097C15.5194 2.9335 15.2651 2.82819 15 2.82819C14.7348 2.82819 14.4805 2.9335 14.293 3.12097L13.307 4.10697L14.862 5.72397L15.879 4.70697C16.0664 4.51944 16.1718 4.26514 16.1718 3.99997C16.1718 3.73481 16.0664 3.4805 15.879 3.29297L15.707 3.12097ZM13.447 7.13797L11.892 5.52197L2.90296 14.512L2.37397 16.626L4.48897 16.097L13.448 7.13797H13.447Z'
                              fill='#858585'
                            />
                          </svg>
                        </span>
                      </div>
                      {currentUser?.domainCompany.hasUserPosition && currentUser.position && (
                        <div className={css['user-info__position']}>
                          {currentUser?.domainCompany.positionSingleNameImenit}: {currentUser.position}
                        </div>
                      )}
                      {currentUser?.domainCompany.hasUserDepartment && currentUser.department && (
                        <div className={css['user-info__position']}>
                          {currentUser?.domainCompany.departmentSingleNameImenit}: {currentUser.department}
                        </div>
                      )}
                      {currentUser?.domainCompany.hasUserFunction && currentUser.function && (
                        <div className={css['user-info__position']}>
                          {currentUser?.domainCompany.functionSingleNameImenit}: {currentUser.function}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={css['user-info-mobile']}>
                  <div className={css['user-info']}>
                    <div className={css['user-info__ava']}>
                      <Avatar user={currentUser} />
                    </div>
                    <div className={css['user-info__text']}>
                      <div className={css['user-info__text__header']}>
                        <h4 className={css['user-info__name']}>
                          <p>{`${currentUser.firstName}`}</p>
                          <p>{`${currentUser.lastName}`}</p>
                          {status === STATUS_ACTIVE && (
                            <div className={css['user-info__tag']}>
                              <MentorTag />
                            </div>
                          )}
                        </h4>
                        <span className={css['user-info__text__header__pencil']} onClick={editInfo}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='19'
                            height='19'
                            viewBox='0 0 19 19'
                            fill='none'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M12.879 1.70697C13.4415 1.14456 14.2045 0.828613 15 0.828613C15.7955 0.828613 16.5584 1.14456 17.121 1.70697L17.293 1.87897C17.8554 2.44156 18.1713 3.20448 18.1713 3.99997C18.1713 4.79546 17.8554 5.55839 17.293 6.12097L15.556 7.85997L5.70696 17.708C5.57896 17.836 5.41859 17.9269 5.24297 17.971L1.24296 18.971C1.07538 19.0129 0.899782 19.0108 0.73328 18.9647C0.566778 18.9186 0.415053 18.8302 0.292893 18.708C0.170733 18.5859 0.0823047 18.4342 0.036226 18.2677C-0.00985267 18.1012 -0.0120098 17.9256 0.0299649 17.758L1.02997 13.758C1.07401 13.5823 1.16491 13.422 1.29297 13.294L11.199 3.38797L12.879 1.70797V1.70697ZM15.707 3.12097C15.5194 2.9335 15.2651 2.82819 15 2.82819C14.7348 2.82819 14.4805 2.9335 14.293 3.12097L13.307 4.10697L14.862 5.72397L15.879 4.70697C16.0664 4.51944 16.1718 4.26514 16.1718 3.99997C16.1718 3.73481 16.0664 3.4805 15.879 3.29297L15.707 3.12097ZM13.447 7.13797L11.892 5.52197L2.90296 14.512L2.37397 16.626L4.48897 16.097L13.448 7.13797H13.447Z'
                              fill='#858585'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={css['user-info__text_320-768']}>
                    {currentUser?.domainCompany.hasUserPosition && currentUser.position && (
                      <div className={css['user-info__position']}>
                        {currentUser?.domainCompany.positionSingleNameImenit}: {currentUser.position}
                      </div>
                    )}
                    {currentUser?.domainCompany.hasUserDepartment && currentUser.department && (
                      <div className={css['user-info__position']}>
                        {currentUser?.domainCompany.departmentSingleNameImenit}: {currentUser.department}
                      </div>
                    )}
                    {currentUser?.domainCompany.hasUserFunction && currentUser.function && (
                      <div className={css['user-info__position']}>
                        {currentUser?.domainCompany.functionSingleNameImenit}: {currentUser.function}
                      </div>
                    )}
                  </div>
                </div>
                <div className={css['learning-progress']}>
                  <div className={css['learning-progress-desktop']}>
                    <div className={css['learning-progress__item']}>
                      <Link to='/planning/finished' className={css['learning-progress__link']}>
                        <img src={DoneSvg} alt='' />
                        {t('materials', {
                          finished: stat?.finished || 0,
                        })}
                      </Link>
                    </div>
                    <div className={css['learning-progress__item']}>
                      <Link to='/planning' className={css['learning-progress__link']}>
                        <img src={PlanSvg} alt='' />
                        {t('plans', {
                          total: stat?.total > 1 ? stat?.total - stat?.finished : stat?.total || 0,
                        })}
                      </Link>
                    </div>
                  </div>
                  <div className={css['learning-progress-mobile']}>
                    <div className={css['learning-progress__item']}>
                      <Link to='/planning/finished' className={css['learning-progress__link']}>
                        <CheckOutlined />
                        {t('materials-mobile', {
                          finished: stat?.finished || 0,
                        })}
                      </Link>
                    </div>
                    <div className={css['learning-progress__item']}>
                      <Link to='/planning' className={css['learning-progress__link']}>
                        <ClockCircleOutlined />
                        {t('plans-mobile', {
                          total: stat?.total > 1 ? stat?.total - stat?.finished : stat?.total || 0,
                        })}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <TabsLink
                basePage='/my-profile'
                isProfile={status === STATUS_ACTIVE}
                placement={placement}
                setPlacement={setPlacement}
                tabsList={[
                  {
                    url: '/info',
                    name: t('info'),
                  },
                  ...(canBeMentor === true || status
                    ? [
                        {
                          url: '/mentoring',
                          name: t('mentoring'),
                          total: activeMenteesCount !== 0 ? activeMenteesCount : '',
                        },
                      ]
                    : mentorLoading === true
                      ? [{ loading: mentorLoading }]
                      : []),
                  ...(status === STATUS_ACTIVE
                    ? [
                        {
                          url: '/mentoring-comments',
                          name: t('comments'),
                          total: mentorsCommentsTotal !== 0 ? mentorsCommentsTotal : '',
                        },
                      ]
                    : mentorLoading === true
                      ? [{ loading: mentorLoading }]
                      : []),
                  {
                    url: '/recommended',
                    name: t('recommended'),
                    newValue: stateNew,
                    total: recommendedTotal !== 0 ? recommendedTotal : '',
                  },
                  {
                    url: '/my-recommendations',
                    name: t('recommendation'),
                    total: myRecommendationsTotal !== 0 ? myRecommendationsTotal : '',
                  },
                  // {
                  //   url: '/added',
                  //   name: t('iAdded'),
                  //   total: topicsByCurUserTotal !== 0 ? topicsByCurUserTotal : '',
                  // },
                  {
                    url: '/settings',
                    name: t('settings'),
                    domainCompany: currentUser?.domainCompany,
                  },
                ]}
              />
              <div className={css['app-profline__content']}>
                <Outlet
                  context={{
                    userName: currentUser.firstName,
                    domainCompany: currentUser?.domainCompany,
                    user: currentUser,
                    currentUser: currentUser,
                    skillsUser: skills,
                    competenciesUser: currentUser.competences || [],
                    isLoading: (recommendedTotal && myRecommendationsTotal && topicsByCurUserTotal && true) || true,
                    isEdit: isEdit,
                    myCustomFields: customField,

                    updateInfinityScrollRecommended: updateInfinityScrollRecommended,
                    updateInfinityScrollMyRecommendations: updateInfinityScrollMyRecommendations,
                    updateInfinityScrollTopicCreByCurUser: updateInfinityScrollTopicCreByCurUser,

                    navigate: navigate,
                    editAboutUser: editAboutUser,
                    cachedFiles: cachedFiles,
                    overwriteValuesUser: overwriteValuesUser,
                    onFavoriteClick: handleFavoriteClick,
                    onDeleteClick: handleDeleteClick,
                    onStudyPlanClick: showCalendarModal,
                    bindGoogleAccount: bindGoogleAccount,
                    loginGoogleSuccess: loginGoogleSuccess,
                    logoutGoogleSuccess: logoutGoogleSuccess,
                    loginGoogleFailure: loginGoogleFailure,

                    currentUserId: currentUser.id,
                    profileId: currentUser.id,
                    recBy: currentUser.id,
                    creBy: currentUser.id,
                    externalLogin: externalLogin,

                    isLoadingRecTopic: isLoading,
                    isLoaded: isLoading,
                    isLoadingTopic: isLoading,
                    isLoadingMyRecTopic: isLoading,

                    openDate: openDate,
                    myAnotherRecommendationsNews: myAnotherRecommendationsNews,
                    myRecommendationsNews: myRecommendationsNews,
                    dataqa: 'myProfileAddedMaterialList',
                    addressId,
                    setAddressId,
                    // list: recommended?.elements,
                    // list: myRecommendations?.elements,
                    // list: topicsByCurUser.elements,
                    // hasMore:  recommended?.hasMore,
                    // hasMore: myRecommendations?.hasMore,
                    // hasMore: topicsByCurUser.hasMore,
                    // curPage: recommended?.page,
                    // curPage: myRecommendations?.page,
                    // curPage: topicsByCurUser.page,
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Content>
      {calendarModalVisible && (
        <CalendarModal
          disabledSelect={disabledSelect}
          dateEvent={dayjs(startDate).format('D MMMM YYYY')}
          title={!disabledSelect ? t('plan') : t('planingEvent')}
          open={calendarModalVisible}
          onOk={handleStudyPlanClick}
          onCancel={handleCalendarCancel}
          okText={t('add')}
          planningTopicId={planningTopicId}
          isLoading={isLoading}
          topicInfo={topicInfo}
          eventTypeId={planningTopicType?.id}
          {...(planningTopicType?.id === 20 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
          {...(planningTopicType?.id === 8 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
          hasEvents={topicInfo?.eventCount !== 0}
          selectMonthEvents={isCorpCourseWithOrWithoutApprove(planningTopicType?.id) || planningTopicType?.id === 20}
          isMyProfile
        />
      )}

      <Modal
        className={css['edit-profile']}
        title={''}
        onCancel={editInfo}
        width='600px'
        footer={null}
        open={isEdit}
        maskClosable
        closeIcon={<ModalCloseIcon className={css['edit-profile-closeIcon']} />}
      >
        <RegistrationPageDataAbout
          edit
          onClose={editInfo}
          isNotFixed={true}
          profileEdit={currentUser?.domainCompany.profileEdit}
          editProfile
        />
      </Modal>

      {showParticipationConfirmationModal && (
        <ParticipationConfirmationModal
          open={showParticipationConfirmationModal}
          onClose={handleParticipationConfirmationModalCancel}
        />
      )}
    </>
  );
};

export default MyProfile;
