/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { D360LoadProjects } from '@/store/d360/Projects/actions';

import { Col, Empty, Row, Spin } from 'antd';

import { Content } from '../../../components/Layout';
import {
  D360_MODE,
  D360_MODE_ALPHA,
  D360_MODE_BETA,
  D360_MODE_DEFAULT,
  D360_MODE_EXPERIMENTAL,
  D360_MODE_RELEASE,
} from './constants';
import ProjectCard from './ProjectCard';

import css from '../css/styles.module.scss';

export const Projects = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('D360_Projects');
  const [versionName, setVersionName] = useState(null);
  const [versionClass, setVersionClass] = useState(null);
  const surveys = useSelector(state => state.d360Projects);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeof surveys === 'object') {
      if ('loading' in surveys && typeof surveys.loading === 'boolean') {
        setLoading(surveys.loading);
      }

      if ('projects' in surveys && Array.isArray(surveys.projects)) {
        setProjects(surveys.projects);
      }
    }
  }, [surveys]);

  useEffect(() => {
    switch (D360_MODE) {
      case D360_MODE_EXPERIMENTAL:
        setVersionName(D360_MODE_EXPERIMENTAL);
        setVersionClass(css.VersionExperimental);
        break;

      case D360_MODE_ALPHA:
        setVersionName(D360_MODE_ALPHA);
        setVersionClass(css.VersionAlpha);
        break;

      case D360_MODE_BETA:
        setVersionName(D360_MODE_BETA);
        setVersionClass(css.VersionBeta);
        break;

      case D360_MODE_RELEASE:
        setVersionName(D360_MODE_RELEASE);
        setVersionClass(css.VersionRelease);
        break;

      default:
        setVersionName(D360_MODE_DEFAULT);
        setVersionClass(css.VersionExperimental);
        break;
    }

    dispatch(D360LoadProjects());
  }, []);

  const render = () => {
    return (
      <Content>
        <h1 className={css.VersionTitle}>
          {t('title')} <span className='Logo360'>360&deg;</span>
          <span className={versionClass}>{versionName}</span>
        </h1>

        <Row>
          {loading && (
            <Col key={'loading'} span={24} className={css.ProjectLoading}>
              <Spin size={'large'} />
            </Col>
          )}

          {!loading && !projects.length && (
            <Col key={'empty'} span={24} className={css.ProjectLoading}>
              <Empty description={t('empty')} />
            </Col>
          )}

          {!loading &&
            projects.map((project, index) => {
              return (
                <Col span={6} key={'project-' + index}>
                  <ProjectCard project={project} />
                </Col>
              );
            })}
        </Row>
      </Content>
    );
  };

  return render();
};

export default Projects;
