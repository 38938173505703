import {
  ACCEPT_REQUEST_STATUS_FAIL,
  ACCEPT_REQUEST_STATUS_START,
  ACCEPT_REQUEST_STATUS_SUCCESS,
  CREATE_MENTOR_FAIL,
  CREATE_MENTOR_START,
  CREATE_MENTOR_SUCCESS,
  FINISH_REQUEST_STATUS_FAIL,
  FINISH_REQUEST_STATUS_START,
  FINISH_REQUEST_STATUS_SUCCESS,
  GET_CAN_BE_MENTOR_FAIL,
  GET_CAN_BE_MENTOR_START,
  GET_CAN_BE_MENTOR_SUCCESS,
  GET_CURRENT_MENTOR_FAIL,
  GET_CURRENT_MENTOR_START,
  GET_CURRENT_MENTOR_SUCCESS,
  GET_MENTEES_REQUESTS_FAIL,
  GET_MENTEES_REQUESTS_START,
  GET_MENTEES_REQUESTS_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_START,
  GET_MENTORS_SUCCESS,
  REJECT_REQUEST_STATUS_FAIL,
  REJECT_REQUEST_STATUS_START,
  REJECT_REQUEST_STATUS_SUCCESS,
  REVIEW_REQUEST_STATUS_FAIL,
  REVIEW_REQUEST_STATUS_START,
  REVIEW_REQUEST_STATUS_SUCCESS,
  SET_MENTOR_STATUS_BULK_FAIL,
  SET_MENTOR_STATUS_BULK_START,
  SET_MENTOR_STATUS_BULK_SUCCESS,
  SET_MENTOR_STATUS_FAIL,
  SET_MENTOR_STATUS_START,
  SET_MENTOR_STATUS_SUCCESS,
  UPDATE_MENTOR_PHOTO_FAIL,
  UPDATE_MENTOR_PHOTO_START,
  UPDATE_MENTOR_PHOTO_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// Получить список всех менторов постранично
export const getMentors = ({ page, size, sort, status }) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentor${getQueryString({
      page,
      size,
      sort,
      status,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_MENTORS_START, GET_MENTORS_SUCCESS, GET_MENTORS_FAIL],
  };
};

// Получить всех менти/заявки на обучения для текущего пользователя/ментора
export const getMenteesRequests = status => {
  const defaultStatuses = [
    'FINISHED',
    'ACTIVE',
    'WAITING_MENTEE',
    'WAITING_MENTEE_MEETING',
    'WAITING_MENTOR_MEETING',
    'WAITING_MENTOR_DECISION',
    'WAITING_MENTEE_DECISION',
    'REJECTED_BY_MENTOR',
    'REJECTED_BY_MENTEE',
    'WAITING_RATING',
    'WAITING_MENTOR',
  ];
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentor/mentee${getQueryString({
      status: Array.isArray(status) && status.length > 0 ? status : defaultStatuses,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_MENTEES_REQUESTS_START, GET_MENTEES_REQUESTS_SUCCESS, GET_MENTEES_REQUESTS_FAIL],
  };
};

// Принять менторство ментором
export const acceptRequestStatus = (data, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentor/status/accept`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [ACCEPT_REQUEST_STATUS_START, ACCEPT_REQUEST_STATUS_SUCCESS, ACCEPT_REQUEST_STATUS_FAIL],
    onSuccess,
  };
};

// Завершить обучение ментором
export const finishRequestStatus = (data, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentor/status/finish`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    onSuccess,
    types: [FINISH_REQUEST_STATUS_START, FINISH_REQUEST_STATUS_SUCCESS, FINISH_REQUEST_STATUS_FAIL],
  };
};

// Завершить опрос ментором
export const surveyRequestStatus = data => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentor/status/review`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [REVIEW_REQUEST_STATUS_START, REVIEW_REQUEST_STATUS_SUCCESS, REVIEW_REQUEST_STATUS_FAIL],
  };
};

// Завершить обучение ментором
export const rejectRequestStatus = data => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentor/status/reject`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [REJECT_REQUEST_STATUS_START, REJECT_REQUEST_STATUS_SUCCESS, REJECT_REQUEST_STATUS_FAIL],
  };
};

// Создание запроса на нового ментора от текущего пользователя
export const createMentor = data => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/mentor/current`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: data,
    types: [CREATE_MENTOR_START, CREATE_MENTOR_SUCCESS, CREATE_MENTOR_FAIL],
  };
};

// Получить ментора для текущего пользователя
export const getCurrentMentor = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentor/current`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_CURRENT_MENTOR_START, GET_CURRENT_MENTOR_SUCCESS, GET_CURRENT_MENTOR_FAIL],
  };
};

// Проверка может ли текущий пользователь быть ментором
export const getCanBeMentor = (onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentor/mentor`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_CAN_BE_MENTOR_START, GET_CAN_BE_MENTOR_SUCCESS, GET_CAN_BE_MENTOR_FAIL],
    onSuccess,
    onError,
  };
};

// UUID фото или картинки аватара ментора
export const updateMentorPhoto = uuid => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/mentor/photo${getQueryString({ uuid })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: {},
    types: [UPDATE_MENTOR_PHOTO_START, UPDATE_MENTOR_PHOTO_SUCCESS, UPDATE_MENTOR_PHOTO_FAIL],
  };
};

// установка статуса ментора (ACTIVE/REJECTED)
export const setMentorStatus = data => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentor/status`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: {
      mentorVM: data,
    },
    types: [SET_MENTOR_STATUS_START, SET_MENTOR_STATUS_SUCCESS, SET_MENTOR_STATUS_FAIL],
  };
};

// Массовая установка статусов менторов (ACTIVE/REJECTED)
export const setMentorStatusBulk = data => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/status/bulk`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    mentors: data,
  },
  types: [SET_MENTOR_STATUS_BULK_START, SET_MENTOR_STATUS_BULK_SUCCESS, SET_MENTOR_STATUS_BULK_FAIL],
});
