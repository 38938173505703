// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-ANFiOm.user-src-components-WelcomeModal-WelcomeModal-module__ql-font-LyAWzT .user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-label-FzGy16[data-value="Montserrat"]:before, .user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-ANFiOm.user-src-components-WelcomeModal-WelcomeModal-module__ql-font-LyAWzT .user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-item-dOeBH4[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-WelcomeModal-WelcomeModal-module__ql-font-Montserrat-CNBNKh {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-modal-yWStZq {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 34px 60px 16px;
  display: flex;
}

.user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-description-CYmGbU {
  margin-bottom: 40px;
}

.user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-buttons-GbcveZ {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

@media (max-width: 650px) {
  .user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-buttons-GbcveZ {
    flex-direction: column;
  }
}

.user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-button-G51282 {
  width: 280px;
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/WelcomeModal/WelcomeModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,aAAa;AACf;;AAMA;EAHE,mBAAmB;AACrB;;AAKA;EAFE,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAIA;EACE;IADE,sBAAsB;EACxB;AACF;;AAGA;EAAE,YAAY;EACZ,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.WelcomeModalTD-modal {\n  padding: 34px 60px 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n}\n.WelcomeModalTD-description {\n  margin-bottom: 40px;\n}\n.WelcomeModalTD-buttons {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  justify-content: center;\n}\n@media (width <= 650px) {\n  .WelcomeModalTD-buttons {\n    flex-direction: column;\n  }\n}\n.WelcomeModalTD-button {\n  height: 60px;\n  width: 280px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-ANFiOm`,
	"qlPicker": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-ANFiOm`,
	"ql-font": `user-src-components-WelcomeModal-WelcomeModal-module__ql-font-LyAWzT`,
	"qlFont": `user-src-components-WelcomeModal-WelcomeModal-module__ql-font-LyAWzT`,
	"ql-picker-label": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-label-FzGy16`,
	"qlPickerLabel": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-label-FzGy16`,
	"ql-picker-item": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-item-dOeBH4`,
	"qlPickerItem": `user-src-components-WelcomeModal-WelcomeModal-module__ql-picker-item-dOeBH4`,
	"ql-font-Montserrat": `user-src-components-WelcomeModal-WelcomeModal-module__ql-font-Montserrat-CNBNKh`,
	"qlFontMontserrat": `user-src-components-WelcomeModal-WelcomeModal-module__ql-font-Montserrat-CNBNKh`,
	"WelcomeModalTD-modal": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-modal-yWStZq`,
	"welcomeModalTdModal": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-modal-yWStZq`,
	"WelcomeModalTD-description": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-description-CYmGbU`,
	"welcomeModalTdDescription": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-description-CYmGbU`,
	"WelcomeModalTD-buttons": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-buttons-GbcveZ`,
	"welcomeModalTdButtons": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-buttons-GbcveZ`,
	"WelcomeModalTD-button": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-button-G51282`,
	"welcomeModalTdButton": `user-src-components-WelcomeModal-WelcomeModal-module__WelcomeModalTD-button-G51282`
};
export default ___CSS_LOADER_EXPORT___;
