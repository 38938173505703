// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-XkRPYM.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-DNVIME .user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-label-vlYhOJ[data-value="Montserrat"]:before, .user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-XkRPYM.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-DNVIME .user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-item-qaooQh[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-Montserrat-_QQhqG {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-NsKKVk {
  width: 620px !important;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-body-BFVBe7 {
  text-align: center;
  padding: 32px 32px 0;
  font-size: 1.5em;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-footer-hKqBT_ {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-btn-R32pnf {
  min-width: 160px;
  max-width: 500px;
}

.user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-text-AHIoCM {
  text-align: left;
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,uBAAuB;AACzB;;AAMA;EAHE,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;AAClB;;AAKA;EAFE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAIA;EADE,gBAAgB;EAChB,gBAAgB;AAClB;;AAGA;EAAE,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ModalCurrentPaidTopic {\n  width: 620px !important;\n}\n.ModalCurrentPaidTopic-body {\n  padding: 32px 32px 0;\n  text-align: center;\n  font-size: 1.5em;\n}\n.ModalCurrentPaidTopic-footer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n}\n.ModalCurrentPaidTopic-btn {\n  min-width: 160px;\n  max-width: 500px;\n}\n.ModalCurrentPaidTopic-text {\n  text-align: left;\n  padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-XkRPYM`,
	"qlPicker": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-XkRPYM`,
	"ql-font": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-DNVIME`,
	"qlFont": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-DNVIME`,
	"ql-picker-label": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-label-vlYhOJ`,
	"qlPickerLabel": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-label-vlYhOJ`,
	"ql-picker-item": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-item-qaooQh`,
	"qlPickerItem": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-picker-item-qaooQh`,
	"ql-font-Montserrat": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-Montserrat-_QQhqG`,
	"qlFontMontserrat": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ql-font-Montserrat-_QQhqG`,
	"ModalCurrentPaidTopic": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-NsKKVk`,
	"modalCurrentPaidTopic": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-NsKKVk`,
	"ModalCurrentPaidTopic-body": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-body-BFVBe7`,
	"modalCurrentPaidTopicBody": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-body-BFVBe7`,
	"ModalCurrentPaidTopic-footer": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-footer-hKqBT_`,
	"modalCurrentPaidTopicFooter": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-footer-hKqBT_`,
	"ModalCurrentPaidTopic-btn": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-btn-R32pnf`,
	"modalCurrentPaidTopicBtn": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-btn-R32pnf`,
	"ModalCurrentPaidTopic-text": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-text-AHIoCM`,
	"modalCurrentPaidTopicText": `user-src-components-ModalCurrentPaidTopic-ModalCurrentPaidTopic-module__ModalCurrentPaidTopic-text-AHIoCM`
};
export default ___CSS_LOADER_EXPORT___;
