import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { PlanStatus } from '@shared/constants/plan';

import PropTypes from 'prop-types';

import css from './IconPokaStatus.module.scss';

const IconPokaStatus = ({ studyPlanStatus }) => {
  const { t } = useTranslation('iconPokaStatus');

  switch (studyPlanStatus) {
    case PlanStatus.WAITING:
      return (
        <Tooltip title={t('statusWaiting')}>
          <ExclamationCircleFilled className={css['IconPoka-item-icon']} />
        </Tooltip>
      );

    case PlanStatus.REJECTED_BY_MANAGER:
      return (
        <Tooltip title={t('statusRejectedByManager')}>
          <CheckCircleFilled className={css['IconPoka-item-icon']} />
        </Tooltip>
      );
  }
};

IconPokaStatus.propTypes = {
  studyPlanStatus: PropTypes.string,
};

export default IconPokaStatus;
