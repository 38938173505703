import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearReplyMessage } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { AiOutlineSend } from 'react-icons/ai';
import { FiCornerUpLeft, FiX } from 'react-icons/fi';

import FileInput from './FileInput';
import ModalWithFile from './ModalWithFile';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const Footer = ({ socket }) => {
  const [value, setValue] = useState(undefined);
  const [filesData, setFilesData] = useState([]);

  const { t } = useTranslation('chat');
  const { selectedUser, replyMessage } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);

  const textAreaRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    textAreaRef.current.focus();
  }, [replyMessage]);

  useEffect(() => {
    document.body.addEventListener('keypress', submitEnter);

    return () => {
      document.body.removeEventListener('keypress', submitEnter);
    };
  }, [value]);

  const handleClearReply = () => {
    dispatch(clearReplyMessage());
  };

  const submitEnter = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleCloseModalFiles = () => {
    setFilesData([]);
  };

  const sendMessage = () => {
    if (!value?.trim().length && !filesData.length) return setValue(undefined);

    if (selectedUser) {
      socket?.emit('sendMessage', {
        userId: selectedUser?.id,
        content: value,
        chatId: selectedUser?.chatId,
        replyMessageId: replyMessage?.messageId,
        files: filesData.map(file => {
          // eslint-disable-next-line
          const { fileBlob, ...rest } = file;
          return { ...rest };
        }),
      });
    }

    textAreaRef.current.value = '';
    setFilesData([]);
    setValue(undefined);
    handleClearReply();
    handleCloseModalFiles();
    adjustTextareaHeight();
  };

  const renderFileText = () => {
    if (replyMessage?.files?.length >= 2) {
      return t('files.0');
    } else if (replyMessage?.files?.length === 1) {
      if (replyMessage?.files[0].fileType.includes('image')) {
        return t('files.2');
      } else {
        return t('files.1');
      }
    }
  };

  const handleOnChangeValue = event => {
    setValue(event.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const currentTextArea = textAreaRef.current;
    if (currentTextArea) {
      currentTextArea.parentElement.style.height = '36px';
      currentTextArea.style.height = '30px';
      currentTextArea.parentElement.style.height = currentTextArea.scrollHeight + 6 + 'px';
      currentTextArea.style.height = currentTextArea.scrollHeight + 'px';
    }
  };

  return (
    <div className={replyMessage ? classNames(styles.footer, styles.footer_reply) : styles.footer}>
      {replyMessage && (
        <div className={styles.footer__reply}>
          <div className={styles.footer__box}>
            <FiCornerUpLeft />
            <div className={styles.footer__message}>
              <p className={styles.footer__name}>
                {t('replyMessage')}
                {currentUser.id === replyMessage.userId ? (
                  <>
                    {currentUser.firstName} {currentUser.lastName}
                  </>
                ) : (
                  <>
                    {replyMessage.userFirstName} {replyMessage.userLastName}
                  </>
                )}
              </p>
              <p className={styles['footer__message-content']}>
                {replyMessage?.content ? replyMessage.content : renderFileText()}
              </p>
            </div>
          </div>
          <FiX className={styles.footer__icon} onClick={handleClearReply} />
        </div>
      )}
      <div className={styles.footer__content}>
        <FileInput setFileData={file => setFilesData([...filesData, file])} />
        <textarea
          maxLength={4096}
          className={styles.footer__textArea}
          placeholder={t('placeholderMessage')}
          value={filesData?.length ? '' : value}
          ref={textAreaRef}
          onChange={handleOnChangeValue}
        />
        {/*<FiSmile className={styles.footer__icon}/> СМАЙЛИКИ */}
        <AiOutlineSend onClick={sendMessage} className={styles.footer__icon} />
        <ModalWithFile
          visible={!!filesData?.length}
          onClose={handleCloseModalFiles}
          filesData={filesData}
          sendMessage={sendMessage}
          setFilesData={setFilesData}
          valueInput={value}
          setValueInput={e => setValue(e)}
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  socket: PropTypes.shape({
    emit: PropTypes.func,
  }),
};

export default memo(Footer);
