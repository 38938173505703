import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { checkPreviousStudyPlans } from '@/store/study-plan/actions';

import { Button, Col, message, Row, Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import AudienceConfirmationModal from '../ModalTargetAudience/Modal';
import TypeBadge from '../TypeBadge';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import { checkActiveCourse } from '@shared/utils/course-utils';
import { isAnyCourseOrConference, shouldCheckPreviousStudyPlans } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

import { ADD_REPEAT } from '@/constants/studyPlanStatus';
import { TOPIC_TYPES } from '@/constants/topic-types';
import comments from '@/assets/images/comments.svg';
import lock from '@/assets/images/lock.svg';
import locked from '@/assets/images/locked.svg';
import statistic from '@/assets/images/material-statistic-plan.svg';
import repost from '@/assets/images/repost.svg';
import views from '@/assets/images/views.svg';

const MYRECOMMENDED = 'myRecommended';
const RECOMMENDED = 'recommended';
const MYMATERIAL = 'myMaterial';

const ListItem = ({
  typeList,
  recUsers,
  user,
  onFavoriteClick,
  topic,
  onStudyPlanClick,
  onDeleteClick,
  hideActionButtons,
  isExternalLogin,
  isAnother,
  lastRec,
  theLast,
  checkIsEnd,
  checkCoursePlan,
  openDate,
  onAddToPlanNow,
  setHandleStudyPlanClickState,
}) => {
  const { t } = useTranslation(['listItem', 'constants']);
  const [isAudienceConfirmationModalOpen, setIsAudienceConfirmationModalOpen] = useState(false);

  const dispatch = useDispatch();

  const isEventType = topic?.type?.id === 8;

  const currentLocalPhotoUUID =
    typeList === MYRECOMMENDED || typeList === MYMATERIAL
      ? user.localPhotoUuid
      : recUsers && recUsers[0].localPhotoUuid;

  const handleFavoriteClick = () => {
    onFavoriteClick(topic.id, typeList);
  };

  const handleStudyPlanClick = () => {
    if (topic.isTopicFull && isEventType) {
      message.warning(t('topicFull'));
      return;
    }

    const withNoEvents = Utils.isWithoutEvents(topic);

    if (onStudyPlanClick) {
      onStudyPlanClick(topic.id, topic.type, topic.startDate, {
        id: topic.id,
        startDate: topic.startDate,
        topicCost: topic.cost,
        link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
        eventCount: topic.futureEvents,
        independent: topic.independent,
        duration: topic.duration,
        recommend: true,
        withNoEvents,
      });
    }
  };

  const handleModalConfirm = () => {
    setIsAudienceConfirmationModalOpen(false);
    if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
      setHandleStudyPlanClickState(() => handleStudyPlanClick);
    } else {
      handleStudyPlanClick();
    }
  };

  const handleModalCancel = () => {
    setIsAudienceConfirmationModalOpen(false);
  };

  const handleStudyPlanClickWithCheck = () => {
    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      setIsAudienceConfirmationModalOpen(true);
    } else if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
      setHandleStudyPlanClickState(() => handleStudyPlanClick);
    } else {
      handleStudyPlanClick();
    }
  };

  const handleDeleteClick = () => {
    onDeleteClick(topic.id, typeList);
  };

  const topicType = topic.type && TOPIC_TYPES(t).find(type => type.id === topic.type.id);
  const recUsersArr = lastRec && lastRec.length > 0 ? lastRec : recUsers;
  const typeBadge = topicType ? (
    <TypeBadge type={topicType.code} name={topic.type.name} className={css.markerOnly} noText />
  ) : null;
  const bigName = topic.name.length > 100;
  const cutTopicName = bigName ? (
    <Tooltip title={topic.name} placement={theLast ? 'top' : 'bottom'}>
      <span>
        <Link
          data-external-login={!isExternalLogin && topic.scope === 'INTERNAL'}
          to={`/collection-topics/${topic.id}`}
          className={css.material__list__title}
        >
          {typeBadge}
          {!isExternalLogin && topic.scope === 'INTERNAL' && (
            <img className={css['material-list__item__locked']} src={locked} alt='' />
          )}
          <span className={css['material-list__item__name']}>{`${topic.name.substr(0, 100)}...`}</span>
        </Link>
      </span>
    </Tooltip>
  ) : (
    <span>
      <Link
        data-external-login={!isExternalLogin && topic.scope === 'INTERNAL'}
        to={`/collection-topics/${topic.id}`}
        className={css.material__list__title}
      >
        {typeBadge}
        {!isExternalLogin && topic.scope === 'INTERNAL' && (
          <img className={css['material-list__item__locked']} src={locked} alt='' />
        )}
        <span>{topic.name}</span>
      </Link>
    </span>
  );
  const toMyCollectionButton =
    (!isAnother ? typeList !== MYRECOMMENDED : typeList) &&
    (topic.isFavourite ? (
      <Button
        className={classNames(
          css['App-btn'],
          css['App-favorite'],
          topic.scope === 'INTERNAL' && !isExternalLogin && css['externalLogin']
        )}
        type='link'
        onClick={handleDeleteClick}
        icon={<HeartFilled className={css['App-favorite-icon']} />}
        size='large'
      ></Button>
    ) : (
      <Button
        className={classNames(css['App-btn'], topic.scope === 'INTERNAL' && !isExternalLogin && css['externalLogin'])}
        onClick={handleFavoriteClick}
        type='link'
        icon={<HeartOutlined className={css['App-favorite-icon']} />}
        size='large'
      ></Button>
    ));

  const isActiveCourse = checkActiveCourse({ topic, openDate }, true);

  const disabledAddToPlan = topic.status === 'DISABLED' || checkIsEnd || !isActiveCourse;
  const disabledPlanned = (topic.status === 'DISABLED' && checkCoursePlan) || checkIsEnd || !isActiveCourse;

  const withNoEvents = Utils.isWithoutEvents(topic) && onAddToPlanNow;

  const addToPlanBlock = useMemo(
    () =>
      !isActiveCourse ? (
        <Button disabled={true} className={css['App-btn']} type='primary' size='large'>
          <div className={css['App-btn-flexWrap']}>
            <div className={css['App-btn-noActive']} />
            {t('unActive')}
          </div>
        </Button>
      ) : (
        <>
          {withNoEvents && (
            <Button
              disabled={disabledAddToPlan}
              className={classNames(css['App-btn'], css['App-btn-first'])}
              onClick={onAddToPlanNow}
              type='primary'
              size='large'
            >
              <div>{t('addToPlanNow')}</div>
            </Button>
          )}
          <Button
            disabled={disabledAddToPlan}
            className={css['App-btn']}
            onClick={handleStudyPlanClickWithCheck}
            type='primary'
            size='large'
          >
            <div>{t(withNoEvents ? 'addToPlanLater' : 'addToPlan')}</div>
          </Button>
        </>
      ),
    [withNoEvents, disabledAddToPlan, topic]
  );

  const retakeTopic = topic?.userPlanType === ADD_REPEAT;

  const actionButtons = hideActionButtons
    ? null
    : (!typeList.includes(MYMATERIAL) || !typeList.includes(MYRECOMMENDED)) &&
      (topic?.planStatus?.planStatus ? (
        <div className={css['App-btn-group']}>
          <Button
            className={classNames(css['App-btn'], { [css['App-btn-success']]: !retakeTopic })}
            size='large'
            onClick={retakeTopic && handleStudyPlanClickWithCheck}
            type={retakeTopic && 'primary'}
            ghost={!retakeTopic}
            disabled={disabledPlanned && !retakeTopic}
          >
            {disabledPlanned ? (
              <div className={css['App-btn-flexWrap']}>
                <div className={css['App-btn-noActive']} />
                {t('unActive')}
              </div>
            ) : (
              <div>{t(Utils.checkTitleAddToPlan(topic))}</div>
            )}
          </Button>
          {toMyCollectionButton}
        </div>
      ) : (
        <div className={css['App-btn-group']}>
          {addToPlanBlock}
          {toMyCollectionButton}
        </div>
      ));

  const DELETED_USER =
    typeList !== RECOMMENDED
      ? user?.status === 'DELETED'
      : recUsersArr[0] && recUsersArr[0].status && recUsersArr[0].status === 'DELETED';

  const [currentAvatar] = useLocalFileByUUID(currentLocalPhotoUUID, { defaultImg: PortraitPlaceholder });

  return (
    <Row
      className={classNames(
        css['material-list__item'],
        typeList === 'myMaterial' && css['material-list__item__myrecomended']
      )}
    >
      {typeList === RECOMMENDED && (
        <Col md={7} lg={5}>
          <Link target='_blank' to={`/collegues/${recUsers[0].id}`} className={css['material-list__item__user']}>
            <span
              className={classNames(css['material-list__item__avatar'], {
                [css.DeleteUser]: DELETED_USER,
              })}
            >
              <img className={classNames({ [css['DeleteUser-image']]: DELETED_USER })} src={currentAvatar} alt='' />
            </span>
            {DELETED_USER && (
              <Tooltip title={t('userDeleted')}>
                <div className={classNames(css['DeleteUser-key'], css['DeleteUser-key-fix1'])}>
                  <img key='deleteUser' src={lock} alt='' />
                </div>
              </Tooltip>
            )}
            <div>
              {`${recUsersArr[0].firstName} ${recUsersArr[0].lastName.charAt(0)}.`}
              {recUsers.length > 1 && (
                <div className={css['material-list__item__user__andMore']}>и еще {recUsers.length - 1}</div>
              )}
            </div>
          </Link>
        </Col>
      )}
      {typeList === MYRECOMMENDED && (
        <Col md={5} lg={4}>
          <Link target='_blank' to={`/collegues/${user.id}`} className={css['material-list__item__user']}>
            <img src={repost} className={css['material-list__item__repost']} alt='' />
            <span
              className={classNames(css['material-list__item__avatar'], {
                [css.DeleteUser]: DELETED_USER,
              })}
            >
              <img className={classNames({ [css['DeleteUser-image']]: DELETED_USER })} src={currentAvatar} alt='' />
            </span>
            {DELETED_USER && (
              <Tooltip title={t('userDeleted')}>
                <div className={classNames(css['DeleteUser-key'], css['DeleteUser-key-fix2'])}>
                  <img key='deleteUser' src={lock} alt='' />
                </div>
              </Tooltip>
            )}
            <div>
              {`${user.firstName} ${user.lastName.charAt(0)}.`}
              {recUsers.length > 1 && typeList.includes(RECOMMENDED) && (
                <div className={css['material-list__item__user__andMore']}>и еще {recUsers.length - 1}</div>
              )}
            </div>
          </Link>
        </Col>
      )}
      <Col md={typeList !== MYMATERIAL ? 8 : 10} lg={typeList !== MYMATERIAL ? 11 : 13}>
        <div className={css['material-list__item__name']}>
          <div data-viewed={!isAnother && topic.viewers && !topic.viewers.viewed && typeList === RECOMMENDED}>
            {!isExternalLogin && topic.scope !== 'INTERNAL' ? (
              cutTopicName
            ) : (
              <Tooltip title={t('blocked')} placement={theLast && bigName ? 'bottom' : 'top'}>
                {cutTopicName}
              </Tooltip>
            )}
          </div>
        </div>
      </Col>

      {typeList === MYMATERIAL ? (
        <Col xs={0} sm={0} md={3}>
          <div className={css['material-list__item__feedback__box']}>
            <Tooltip placement='bottom' title={t('viewed')}>
              <div className={css['material-list__item__feedback']}>
                <img src={views} alt='' /> {topic.viewers.count}
              </div>
            </Tooltip>
            <Tooltip placement='bottom' title={t('feedback')}>
              <div className={css['material-list__item__feedback']}>
                <img src={comments} alt='' /> {topic.mention ? topic.mention.count : 0}
              </div>
            </Tooltip>
            <Tooltip placement='bottom' title={t('userPlanned')}>
              <div className={css['material-list__item__feedback']}>
                <img src={statistic} alt='' /> {topic.planStatus ? topic.planStatus.count : 0}
              </div>
            </Tooltip>
          </div>
        </Col>
      ) : (
        <Col md={10} lg={8}>
          {!isExternalLogin && topic.scope !== 'INTERNAL' ? actionButtons : toMyCollectionButton}
        </Col>
      )}
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </Row>
  );
};

ListItem.propTypes = {
  checkCoursePlan: PropTypes.bool,
  checkIsEnd: PropTypes.bool,
  isExternalLogin: PropTypes.any,
  getPhoto: PropTypes.func,
  hideActionButtons: PropTypes.bool,
  inMyCollection: PropTypes.bool,
  isAnother: PropTypes.bool,
  isFav: PropTypes.bool,
  lastRec: PropTypes.any,
  onDeleteClick: PropTypes.func,
  onDeleteClickCatalog: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  onFavoriteClickCatalog: PropTypes.func,
  onStudyPlanClick: PropTypes.func,
  profileId: PropTypes.number,
  recUsers: PropTypes.array,
  setNoViewedValue: PropTypes.func,
  theLast: PropTypes.bool,
  topic: PropTypes.object,
  typeList: PropTypes.string,
  user: PropTypes.object,
  openDate: PropTypes.string,
  setHandleStudyPlanClickState: PropTypes.func,
};

export default ListItem;
