import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedUser } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar } from 'antd';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const GroupElement = ({ data }) => {
  const avatar = useImage(data?.chatData?.chat_photo_uuid, 'avatar');
  const dispatch = useDispatch();

  const { t } = useTranslation('chat');
  const { currentUser } = useSelector(selectUsers);
  const { selectedUser } = useSelector(selectChat);

  const isMessageToday = useMemo(() => {
    const date = new Date();
    const format = 'DD:MM:YYYY';
    if (data?.lastMessage) {
      return dayjs(date).format(format) === dayjs(data?.lastMessage?.createdAt).format(format);
    } else {
      return false;
    }
  }, [data?.lastMessage]);

  const isMessageOnTheWeek = useMemo(() => {
    const date = new Date();
    if (data?.lastMessage) {
      return (
        dayjs(date).week() === dayjs(data?.lastMessage?.createdAt).week() &&
        dayjs(date).year() === dayjs(data?.lastMessage?.createdAt).year()
      );
    } else {
      return false;
    }
  }, [data?.lastMessage]);

  const handleSelectGroup = () => {
    if (selectedUser?.chatId !== data?.chatData?.chat_id) {
      dispatch(
        setSelectedUser({
          photo: data.chatData.chat_photo_uuid,
          chatId: data.chatData.chat_id,
          chatName: data.chatData.chat_name,
          chatAuthorId: data.chatData.author_id,
        })
      );
    }
  };

  const renderDate = () => {
    if (isMessageToday) return dayjs(data?.lastMessage?.createdAt).format('HH:mm');
    else if (isMessageOnTheWeek) return dayjs(data?.lastMessage?.createdAt).format('dd');
    else return dayjs(data?.lastMessage?.createdAt).format('DD.MM.YYYY');
  };

  const renderFileText = () => {
    if (data.lastMessage?.files?.length >= 2) {
      return t('files.0');
    } else if (data.lastMessage?.files?.length === 1) {
      if (data.lastMessage.files[0].fileType.includes('image')) {
        return t('files.2');
      } else {
        return t('files.1');
      }
    }
  };

  return (
    <div
      className={
        data?.chatData?.chat_id === selectedUser?.chatId
          ? classNames(styles.groupElement, styles.groupElement_active)
          : styles.groupElement
      }
      onClick={handleSelectGroup}
    >
      <div className={styles.groupElement__header}>
        <div className={styles.groupElement__box}>
          <Avatar src={avatar} size={32} />
          <p className={styles.groupElement__name}>{data?.chatData?.chat_name}</p>
        </div>
        <p className={styles.groupElement__date}>{renderDate()}</p>
      </div>
      <div className={styles.groupElement__content}>
        <div className={styles.groupElement__message}>
          {data?.lastMessage && (
            <p
              className={
                data?.chatData?.chat_id === selectedUser?.chatId
                  ? classNames(styles.groupElement__userName, styles.groupElement__userName_active)
                  : styles.groupElement__userName
              }
            >
              {data?.lastMessage?.user_id === currentUser.id ? t('you') : data?.lastMessage?.user_first_name}
            </p>
          )}
          {data?.lastMessage && <>{data?.lastMessage?.content ? data.lastMessage.content : renderFileText()}</>}
        </div>
        {!!data?.countNotReadMessage && <div className={styles.groupElement__noRead}>{data.countNotReadMessage}</div>}
      </div>
    </div>
  );
};

GroupElement.propTypes = {
  data: PropTypes.shape({
    chatData: PropTypes.shape({
      chat_id: PropTypes.number,
      chat_name: PropTypes.string,
      chat_photo_uuid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      author_id: PropTypes.number,
    }),
    lastMessage: PropTypes.shape({
      content: PropTypes.string,
      createdAt: PropTypes.string,
      forwarded_message_id: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
      user_id: PropTypes.number,
      user_first_name: PropTypes.string,
      user_last_name: PropTypes.string,
      user_local_photo_uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    countNotReadMessage: PropTypes.number,
  }),
};

export default memo(GroupElement);
