// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-NotificationTypes-style-module__ql-picker-xXNHt2.user-src-components-NotificationTypes-style-module__ql-font-MWVSsP .user-src-components-NotificationTypes-style-module__ql-picker-label-KkM9l2[data-value="Montserrat"]:before, .user-src-components-NotificationTypes-style-module__ql-picker-xXNHt2.user-src-components-NotificationTypes-style-module__ql-font-MWVSsP .user-src-components-NotificationTypes-style-module__ql-picker-item-Sz94GC[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-NotificationTypes-style-module__ql-font-Montserrat-Ujd1z9 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-NotificationTypes-style-module__currentIcon-mfcUcX {
  color: var(--personalColor);
  font-size: 1em;
}

.user-src-components-NotificationTypes-style-module__currentTag-lA1XX4 {
  font-size: .8em;
}

@media (max-width: 1440px) {
  .user-src-components-NotificationTypes-style-module__currentTag-lA1XX4 {
    font-size: .8vw;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/NotificationTypes/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,2BAA2B;EAC3B,cAAc;AAChB;;AAOA;EAJE,eAAe;AACjB;;AAMA;EACE;IAHE,eAAe;EACjB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.currentIcon {\n  color: var(--personalColor);\n  font-size: 1em;\n}\n\n.currentTag {\n  font-size: 0.8em;\n}\n@media (max-width: 1440px) {\n  .currentTag {\n    font-size: 0.8vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-NotificationTypes-style-module__ql-picker-xXNHt2`,
	"qlPicker": `user-src-components-NotificationTypes-style-module__ql-picker-xXNHt2`,
	"ql-font": `user-src-components-NotificationTypes-style-module__ql-font-MWVSsP`,
	"qlFont": `user-src-components-NotificationTypes-style-module__ql-font-MWVSsP`,
	"ql-picker-label": `user-src-components-NotificationTypes-style-module__ql-picker-label-KkM9l2`,
	"qlPickerLabel": `user-src-components-NotificationTypes-style-module__ql-picker-label-KkM9l2`,
	"ql-picker-item": `user-src-components-NotificationTypes-style-module__ql-picker-item-Sz94GC`,
	"qlPickerItem": `user-src-components-NotificationTypes-style-module__ql-picker-item-Sz94GC`,
	"ql-font-Montserrat": `user-src-components-NotificationTypes-style-module__ql-font-Montserrat-Ujd1z9`,
	"qlFontMontserrat": `user-src-components-NotificationTypes-style-module__ql-font-Montserrat-Ujd1z9`,
	"currentIcon": `user-src-components-NotificationTypes-style-module__currentIcon-mfcUcX`,
	"currentTag": `user-src-components-NotificationTypes-style-module__currentTag-lA1XX4`
};
export default ___CSS_LOADER_EXPORT___;
