/* eslint-disable */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import { getCompaniesByUserName } from '@/store/company/actions';
import { getFile } from '@/store/files/actions';
import { getPositionsByUserName } from '@/store/position/actions';
import { allUsersTopicRecommend } from '@/store/user-topics/actions';
import { clearUsers } from '@/store/users/actions';

import { selectCompany } from '@/store/company/selectors';
import { selectNotifications as notificationsSelect } from '@/store/notifications/selectors';
import { selectPositions } from '@/store/position/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Form, Input, Select, Spin, Switch } from 'antd';
import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';
import { CloseOutlined } from '@ant-design/icons';

import Modal from '../Modal';
import User from '../User';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

class UsersModal extends Component {
  static propTypes = {
    cachedFiles: PropTypes.array,
    currentUser: PropTypes.object,
    domainCompany: PropTypes.object,
    error: PropTypes.bool,
    getFile: PropTypes.func,
    getUsers: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingUsers: PropTypes.bool,
    loadMore: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    usersList: PropTypes.array,
    usersPageNumber: PropTypes.number,
    usersTotal: PropTypes.number,
    topic: PropTypes.object,
    getCompaniesByUserName: PropTypes.func,
    getPositionsByUserName: PropTypes.func,
    recommendTopic: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    allUsersTopicRecommend: PropTypes.func,
    clearUsers: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'recommendColleague',
  };

  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      department: '',
      currentUsers: [],
      selectAll: false,
      topicId: null,
      isAdmin: false,
    };

    this.searchUsersDebounced = _.debounce(props.getUsers, 700); // default param { size: 10, page: 0, status: 'ACTIVE' }
    this.searchCompaniesByUserName = _.debounce(props.getCompaniesByUserName, 700);
    this.searchPositionsByUserName = _.debounce(props.getPositionsByUserName, 700);
  }

  componentDidMount() {
    if (this.props.topic && this.props.topic.id) {
      this.setState({ topicId: this.props.topic.id });
    }

    if (Utils.isAdminOrSuperAdmin(this.props.currentUser)) {
      this.setState({ isAdmin: true });
    }
  }

  componentWillUnmount() {
    this.searchUsersDebounced.cancel();
    this.searchCompaniesByUserName.cancel();
    this.searchPositionsByUserName.cancel();
  }

  UNSAFE_componentWillUpdate(newProps, newState) {
    if (newState.searchString.length > 4 && newState.searchString !== this.state.searchString) {
      this.searchCompaniesByUserName(newState.searchString, this.state.topicId);
      this.searchPositionsByUserName(newState.searchString, this.state.topicId);
    }
    if (
      (newState.searchString !== this.state.searchString ||
        newState.company !== this.state.company ||
        newState.position !== this.state.position) &&
      (newState.searchString.length > 4 || newState.searchString.length === 0)
    ) {
      this.searchUsersDebounced(
        {
          fullName: newState.searchString,
          isDepartment: this.state.department,
          position: newState.position,
          company: newState.company,
          isPersonalized: true,
          status: this.props.recommendTopic ? 'ACTIVE' : undefined,
          topicId: this.state.topicId || undefined,
          newsId: this.props.newsId || undefined,
          page: 0,
          size: 10,
        },
        () => {},
        () => {},
        true
      );
    }
    if (newProps.isLoading !== this.props.isLoading && !newProps.isLoading && !newProps.error) {
      this.props.onCancel();
    }
  }

  clearLoadingList = () => {
    this.setState({ currentUsers: [] });
  };

  handleChange = (event, nameControl) => {
    const name = nameControl ? nameControl : event.target.name;
    const value = nameControl ? event : event.target.value;
    this.setState({ [name]: value });
  };

  clearSelectValue = name => {
    this.setState({ [name]: undefined });
  };

  handleUserClick = user => {
    this.setState({
      currentUsers: [...this.state.currentUsers, user],
    });
  };

  handleRecommendButtonClick = () => {
    if (!this.state.selectAll) {
      const userIds = this.state.currentUsers.map(user => user.id);
      this.props.onOk(userIds);
    } else {
      this.props.allUsersTopicRecommend(this.props.topic.id, () => {
        this.props.onCancel();
      });
    }
  };

  handleUserDelete = userId => {
    this.setState({ currentUsers: this.state.currentUsers.filter(user => user.id !== userId) });
  };

  handleSelectAll = () => {
    this.setState({ selectAll: !this.state.selectAll });
  };

  getPlan = () => {
    this.searchUsersDebounced(
      {
        fullName: this.state.searchString,
        isDepartment: this.state.department,
        company: this.state.company,
        position: this.state.position,
        size: 10,
        page: this.props.usersPageNumber + 1,
        isPersonalized: true,
        status: this.props.recommendTopic ? 'ACTIVE' : undefined,
        topicId: this.state.topicId || undefined,
        newsId: this.props.newsId || undefined,
      },
      () => {},
      () => {},
      false,
      false
    );
  };

  onChangeSwitch = checked => {
    this.props.clearUsers();
    this.searchUsersDebounced(
      {
        fullName: this.state.searchString,
        isDepartment: !!checked,
        company: this.state.company,
        position: this.state.position,
        size: 10,
        page: 0,
        isPersonalized: true,
        status: this.props.recommendTopic ? 'ACTIVE' : undefined,
        topicId: this.state.topicId || undefined,
        newsId: this.props.newsId || undefined,
      },
      () => {},
      () => {},
      false,
      checked
    );

    this.setState({ department: !!checked });
  };

  showMassege = () => {
    if (this.state.searchString && this.state.searchString.length > 0) {
      return (
        <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
          {this.props.t('findColleague')} <br />
          {this.props.t('check')}
        </p>
      );
    }
    if (this.state.department && this.state.department.length > 0) {
      return (
        <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
          {this.props.t('onlyYou')}
        </p>
      );
    }
    return null;
  };

  render() {
    const { t } = this.props;
    const { usersList, onCancel, currentUser, isLoading, isLoadingUsers } = this.props;
    const { currentUsers } = this.state;

    const currentUsersItems = currentUsers.map(user => (
      <User
        key={user.id}
        isLoading={this.props.isLoadingUsers}
        user={user}
        getFile={this.props.getFile}
        onDelete={this.handleUserDelete}
        active={true}
      />
    ));

    const users = usersList.map(user =>
      currentUsers.map(u => u.id).includes(user.id) ? (
        <User
          key={user.id}
          user={user}
          isLoading={this.props.isLoadingUsers}
          getFile={this.props.getFile}
          onDelete={this.handleUserDelete}
          selected
        />
      ) : (
        <User
          key={user.id}
          user={user}
          isLoading={this.props.isLoadingUsers}
          getFile={this.props.getFile}
          onDelete={this.handleUserDelete}
          onClick={this.handleUserClick}
        />
      )
    );

    const hasDepartment = currentUser.department && currentUser.department.length > 0;

    const hasMore = this.props.usersList.length + 1 !== this.props.usersTotal && !this.props.isLoadingUsers;

    let btnDisabled = false;
    if (!this.state.selectAll) {
      btnDisabled = !this.state.currentUsers.length || this.props.isLoading;
    }

    return (
      <Modal
        open={this.props.visible}
        onCancel={onCancel}
        hideFooter
        headerFixToModal
        cardOn
        width={750}
        contentClassName={css['App-card-modal']}
      >
        <div className={css['UserItem-shadow-box']}>
          <h3 className={css['UserItem-title']}>{this.props.t(this.props.placeholder)}</h3>
          <div
            className={classNames(css['UserItem-search'], {
              [css.noMargin]: !hasDepartment,
            })}
          >
            <div className={css['UserItem-search-container']}>
              <Input
                onChange={this.handleChange}
                name='searchString'
                value={this.state.searchString}
                className={css['UserItem-search-input']}
                placeholder={this.props.t('search')}
                allowClear={{ clearIcon: <CloseOutlined /> }}
                size='large'
              />
            </div>
          </div>
          <Form className={css['Users-form']}>
            <Form.Item className={css['Users-form-item']}>
              <Select
                placeholder={t('selectPositionPlaceholder')}
                onChange={value => this.handleChange(value, 'position')}
                value={this.state.position}
                options={this.props.positions.map(item => ({ label: item.name, value: item.name }))}
                disabled={!this.props.positions.length}
                size='large'
                allowClear={{ clearIcon: <CloseOutlined /> }}
              />
            </Form.Item>
            <Form.Item className={css['Users-form-item']}>
              <Select
                placeholder={t('selectCompanyPlaceholder')}
                onChange={value => this.handleChange(value, 'company')}
                value={this.state.company}
                options={this.props.companies.map(item => ({ label: item.name, value: item.name }))}
                disabled={!this.props.companies.length}
                size='large'
                allowClear={{ clearIcon: <CloseOutlined /> }}
              />
            </Form.Item>
          </Form>
          <div className={css['Switchers']}>
            {!this.props?.newsId && Utils.isSuperAdmin(this.props.currentUser) && (
              <div style={{ padding: '10px 0 10px 0' }}>
                <div>
                  <Switch onChange={this.handleSelectAll} />
                  <span>{t('selectAll')}</span>
                </div>
              </div>
            )}
            {this.props.domainCompany && this.props.domainCompany.hasUserDepartment && hasDepartment && (
              <div>
                <Switch
                  className={classNames(css['Switch-input'], css['Switchers-switch'])}
                  onChange={this.onChangeSwitch}
                  disabled={this.props?.recommendTopic ? isLoadingUsers : false}
                />
                <span>{this.props.domainCompany.myDepartmentButtonName}</span>
              </div>
            )}
          </div>
        </div>
        <div className={css['UserItem-list-box']}>
          <div className={css['UserItem-list']}>
            <div
              style={{
                textAlign: 'center',
                display: this.state.selectAll ? 'block' : 'none',
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              {t('selectAllMessage')}
            </div>
            <Spin spinning={this.props?.recommendTopic ? isLoadingUsers && !this.state.selectAll : false}>
              <Scrollbars
                style={{
                  width: '100%',
                  height: window.outerWidth > 767 ? 300 : 200,
                  paddingRight: 45,
                  display: this.state.selectAll ? 'none' : 'block',
                }}
              >
                {users && users.length > 0 ? (
                  <InfiniteScroll
                    initialLoad={false}
                    useWindow={false}
                    threshold={100}
                    loadMore={this.getPlan}
                    hasMore={hasMore}
                    className={css['UserItem-list-InfiniteScroll']}
                  >
                    {users}
                  </InfiniteScroll>
                ) : null}

                {!isLoadingUsers && !isLoading && users && users.length === 0 ? this.showMassege() : null}
              </Scrollbars>
            </Spin>
          </div>
        </div>
        {!!currentUsersItems.length && <div className={css['UserItem-container']}>{currentUsersItems}</div>}
        <div className={classNames(css['UserItem-shadow-box'], css.footer)}>
          <div className={css['UserItem-footer']}>
            <div>
              {!this.state.selectAll && (
                <span
                  className={classNames(css['UserItem-footer-users-number'], {
                    [css['UserItem-footer-users-number-disabled']]: !usersList.length,
                  })}
                >
                  {t('employeesChosen')}: <b>{currentUsers.length}</b>
                </span>
              )}
              {!!currentUsers.length && (
                <Button
                  className={css['UserItem-footer-clear-choice']}
                  onClick={this.clearLoadingList}
                  type='link'
                  size='large'
                >
                  {t('clearChoice')}
                </Button>
              )}
            </div>
            <Button
              onClick={this.handleRecommendButtonClick}
              disabled={btnDisabled}
              className={css['UserItem-footer-button']}
              type='primary'
              size='large'
            >
              {this.props.t('recommend')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapActionToProps = {
  getFile,
  getCompaniesByUserName,
  getPositionsByUserName,
  allUsersTopicRecommend,
  clearUsers,
};

const mapStateToProps = createSelector(
  [userTopicsSelect, notificationsSelect, usersSelect, selectPositions, selectCompany],
  (userTopics, notifications, users, positions, company) => ({
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    currentUser: users.currentUser,
    isLoading: userTopics.isLoading,
    isLoadingUsers: users.isLoading,
    error: userTopics.error,
    notificationType: notifications.type,
    usersList:
      users.usersListConcat && users.currentUser
        ? users.usersListConcat.filter(user => user.id !== users.currentUser.id)
        : [],
    usersPageNumber: users.usersPageNumber || 0,
    usersTotal: users.usersTotal,
    positions:
      positions.searchPositions &&
      positions.searchPositions.map((position, item) => {
        return { id: item + 1, name: position };
      }),
    subCompanies: company.subCompanies,
    companies:
      company.subCompanies &&
      company.subCompanies.map((subCompany, item) => {
        return { id: item + 1, name: subCompany.trim() };
      }),
  })
);

export default connect(mapStateToProps, mapActionToProps)(withTranslation('usersModal')(UsersModal));
