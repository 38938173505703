import ImageComponent from './ImageComponent';

import classNames from 'classnames';

import css from './MatchingQuestion.module.scss';

const Card = props => {
  const innerContent = props.isText ? (
    <foreignObject
      x='10%'
      y='4%'
      width='85%'
      height='90%'
      className={classNames(css['foreignObject'], css['answer-card-text'])}
    >
      {props.content}
    </foreignObject>
  ) : (
    <foreignObject x='10%' y='7%' width='80%' height='85%' className={css['foreignObject']}>
      <ImageComponent src={props.content} />
    </foreignObject>
  );

  return props.inResults ? (
    <svg
      width='300'
      height='200'
      viewBox='0 0 300 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(css['answer-card'], css['answer-card-dragged'])}
    >
      <g clipPath='url(#clip0_46_218)'>
        <path
          d='M16.5454 185.423C18.1848 193.887 25.5956 200 34.217 200H54.5664C64.5075 200 72.5664 191.941 72.5664 182V101.036V18C72.5664 8.05885 64.5075 0 54.5664 0H34.217C25.5956 0 18.1848 6.11313 16.5454 14.5772L0.662953 96.5772C0.225053 98.8381 0.225053 101.162 0.662953 103.423L16.5454 185.423Z'
          fill='#AEAEAE'
        />
        <path
          d='M281.867 0C292.063 0 300.328 8.26534 300.328 18.4611V181.539C300.328 191.735 292.063 200 281.867 200H37.0397C28.2321 200 20.6512 193.778 18.9331 185.14L2.71621 103.601C2.24337 101.224 2.24337 98.7763 2.71621 96.3989L18.9331 14.86C20.6512 6.22171 28.2321 0 37.0396 0H281.867Z'
          fill='#D9D9D9'
        />
      </g>
      <defs>
        <clipPath id='clip0_46_218'>
          <rect width='300' height='200' fill='white' />
        </clipPath>
      </defs>
      {innerContent}
    </svg>
  ) : (
    <svg
      width='331'
      height='200'
      viewBox='0 0 331 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(css['answer-card'], props.wasDragged && css['answer-card-dragged'])}
    >
      <g clipPath='url(#clip0_38_153)'>
        <path
          d='M18.4861 185.792C20.2723 194.081 27.6022 200 36.0822 200H62.7295C72.6706 200 80.7295 191.941 80.7295 182V101.036V18C80.7295 8.05885 72.6706 0 62.7295 0H36.0822C27.6022 0 20.2723 5.91871 18.4861 14.2084L0.816993 96.2084C0.27851 98.7075 0.27851 101.293 0.816993 103.792L18.4861 185.792Z'
          fill='#AEAEAE'
        />
        <path
          d='M312.835 0C323.031 0 331.297 8.26534 331.297 18.4611V181.539C331.297 191.735 323.031 200 312.835 200H38.801C30.1306 200 22.6285 193.966 20.7693 185.497L2.86902 103.959C2.29649 101.351 2.29649 98.6494 2.86902 96.0415L20.7693 14.5026C22.6285 6.03383 30.1306 0 38.8011 0H312.835Z'
          fill='#D9D9D9'
        />
        <ellipse cx='307.985' cy='93.1903' rx='2.67737' ry='2.6845' fill='#020202' />
        <ellipse cx='315.635' cy='100.093' rx='2.67737' ry='2.6845' fill='#020202' />
        <ellipse cx='315.635' cy='106.996' rx='2.67737' ry='2.6845' fill='#020202' />
        <ellipse cx='315.635' cy='93.1903' rx='2.67737' ry='2.6845' fill='#020202' />
        <ellipse cx='307.985' cy='106.996' rx='2.67737' ry='2.6845' fill='#020202' />
        <ellipse cx='307.985' cy='100.093' rx='2.67737' ry='2.6845' fill='#020202' />
      </g>
      {/* <defs>
      <clipPath id='clip0_38_153'>
        <rect width='331' height='200' fill='white' />
      </clipPath>
    </defs> */}
      {innerContent}
    </svg>
  );
};

const AnswerCard = ({ answer, inResults, wasDragged }) => {
  const content = <Card isText={answer.text} content={answer.content} inResults={inResults} wasDragged={wasDragged} />;

  return content;
};

export default AnswerCard;
