import React from 'react';
import { useTranslation } from 'react-i18next';

import { HiStar } from 'react-icons/hi';

import css from './MentorTag.module.scss';

const MentorTag = () => {
  const { t } = useTranslation('MentorTag');

  return (
    <span className={css['MentorTag']}>
      <HiStar className={css['MentorTag-icon']} />
      {t('mentor')}
    </span>
  );
};

export default MentorTag;
