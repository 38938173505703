/* eslint-disable */
import { GET_PLAN_FAIL, GET_PLAN_START, GET_PLAN_SUCCESS } from './actions';

const initialState = {
  plans: [],
  error: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_PLAN_START:
      return {
        ...state,
        plans: [],
        error: false,
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        plans: response,
        error: false,
      };
    case GET_PLAN_FAIL:
      return {
        ...state,
        plans: [],
        error: true,
      };
    default:
      return state;
  }
};
