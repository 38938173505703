/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Layout, Spin } from 'antd';

import FileItem from '../CollectionMaterialsFileItem/FileItem';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './MaterialsTabs.module.scss';

const { Content } = Layout;

class MaterialsTabsFile extends Component {
  static propTypes = {
    getAllFile: PropTypes.func,
    getFile: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingAllFile: PropTypes.bool,
    topic: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  exportFiles = () => {
    const validName = this.props.topic.name.replace(/[^A-Za-zА-Яа-яЁё0-9 ]/g, '');
    this.props.getAllFile(this.props.topic.name, this.props.topic.id, res => {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, validName);
      }
      const objectURL = URL.createObjectURL(res);
      const tempLink = document.createElement('a');
      tempLink.style = 'display: none';
      tempLink.href = objectURL;
      tempLink.download = validName;
      document.body.appendChild(tempLink);
      tempLink.click();
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(objectURL);
      }, 1000);
    });
  };

  render() {
    const files =
      this.props.topic.files &&
      this.props.topic.files.map(file => <FileItem key={file.uuid} file={file} getFile={this.props.getFile} />);
    return (
      <Content>
        <div className={css['MaterialsTabsAbout-container']}>
          {this.props.topic.files && this.props.topic.files.length >= 1 ? (
            <div
              className={classNames(css['MaterialsTabsAbout-fileDownload'], {
                [css['MaterialsTabsAbout-fileDownload-disabled']]: this.props.isLoadingAllFile,
              })}
              onClick={this.exportFiles}
            >
              {window.navigator.onLine && (
                <>
                  {this.props.t('downloadAll')}
                  {this.props.isLoadingAllFile ? (
                    <Spin />
                  ) : (
                    <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
                      <g className={css['MaterialsTabsAbout-fileDownload-down']} opacity='.85'>
                        <rect x='4' y='11' width='9' height='2' rx='1' />
                        <path d='M8.193 9.378A.42.42 0 0 0 8.5 9.5q.184 0 .307-.122L12 6.695q.212-.186.096-.45a.41.41 0 0 0-.403-.25H10V2.5a.4.4 0 0 0-.13-.29.43.43 0 0 0-.307-.122H7.427a.44.44 0 0 0-.307.122.4.4 0 0 0-.13.29v3.494H5.307a.41.41 0 0 0-.403.251q-.115.264.096.45z' />
                      </g>
                    </svg>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className={css['MaterialsTabsAbout-container-noInfo']}>{this.props.t('noFiles')}</div>
          )}
          {files}
        </div>
      </Content>
    );
  }
}

export default withTranslation('materialsTabsFile')(MaterialsTabsFile);
