/* eslint-disable */
import { useEffect, useState } from 'react';

export const usePercent = (total, current) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (typeof total === 'number' && typeof current === 'number') {
      if (total > 0) {
        setPercent((current * 100) / total);
      }
    }
  }, [total, current]);

  return percent;
};

export default usePercent;
