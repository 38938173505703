import { useTranslation } from 'react-i18next';

import { Modal, Typography } from 'antd';

import { mockComments } from '../constants';

import PropTypes from 'prop-types';

import style from '../style.module.scss';

const PokaComments = ({ open, onCancel }) => {
  const { t } = useTranslation('pokaHeader');

  return (
    <Modal title={t('commentFromAssessor')} footer={null} open={open} onCancel={onCancel}>
      <div className={style['poka-comments']}>
        {mockComments.map((comment, index) => (
          <div key={index} className={style['comment']}>
            <div className={style['comment__header']}>
              <Typography.Text className={style['comment__time']}>
                {comment.date} {comment.time}
              </Typography.Text>
              <Typography.Text className={style['comment__author']}>
                {comment.author}, {comment.department}
              </Typography.Text>
            </div>
            <div className={style['comment__content']}>{comment.content}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

PokaComments.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default PokaComments;
