// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-tXNtJU.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-RI8glp .user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-label-dls49f[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-tXNtJU.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-RI8glp .user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-item-JH6qgc[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-Montserrat-j3fB0J {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-collapse-HWxG_6 {
  background-color: #fff;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-F9Qq_P {
  grid-column-gap: 12px;
  cursor: pointer;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #fff;
  grid-template-columns: auto 1fr;
  align-items: center;
  min-height: 32px;
  padding: 12px 20px 11px;
  display: grid;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-chapter-iYGJTo {
  padding: 8px 20px 8px 10px;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-_oF297 {
  color: #000;
  min-width: 100px;
  max-width: 300px;
  font-size: 14px;
  line-height: 20px;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-active-Xkyzmd {
  font-weight: 500;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-subtitle-B2dICr {
  color: #999;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-subtitle-B2dICr img {
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/test/Courses/Courses.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;AACxB;;AAOA;EAJE,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;AACf;;AAMA;EAHE,0BAA0B;AAC5B;;AAKA;EAFE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAIA;EADE,gBAAgB;AAClB;;AAGA;EAAE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.Courses-collapse {\n  background-color: #fff;\n}\n.Courses-item {\n  padding: 12px 20px 11px 20px;\n  min-height: 32px;\n  display: grid;\n  grid-template-columns: auto 1fr;\n  align-items: center;\n  grid-column-gap: 12px;\n  cursor: pointer;\n  background-color: #fff;\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n.Courses-item-chapter {\n  padding: 8px 20px 8px 10px;\n}\n.Courses-item-title {\n  font-size: 14px;\n  line-height: 20px;\n  color: #000;\n  max-width: 300px;\n  min-width: 100px;\n}\n.Courses-item-title-active {\n  font-weight: 500;\n}\n.Courses-item-subtitle {\n  font-size: 14px;\n  color: #999;\n  display: flex;\n  align-items: center;\n}\n.Courses-item-subtitle img {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-tXNtJU`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-tXNtJU`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-RI8glp`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-RI8glp`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-label-dls49f`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-label-dls49f`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-item-JH6qgc`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-picker-item-JH6qgc`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-Montserrat-j3fB0J`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__ql-font-Montserrat-j3fB0J`,
	"Courses-collapse": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-collapse-HWxG_6`,
	"coursesCollapse": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-collapse-HWxG_6`,
	"Courses-item": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-F9Qq_P`,
	"coursesItem": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-F9Qq_P`,
	"Courses-item-chapter": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-chapter-iYGJTo`,
	"coursesItemChapter": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-chapter-iYGJTo`,
	"Courses-item-title": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-_oF297`,
	"coursesItemTitle": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-_oF297`,
	"Courses-item-title-active": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-active-Xkyzmd`,
	"coursesItemTitleActive": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-title-active-Xkyzmd`,
	"Courses-item-subtitle": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-subtitle-B2dICr`,
	"coursesItemSubtitle": `user-src-pages-CollectionMaterialsIDStudy-test-Courses-Courses-module__Courses-item-subtitle-B2dICr`
};
export default ___CSS_LOADER_EXPORT___;
