import { API_BASE } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_TEST_START = 'GET_TEST_START';
export const GET_TEST_SUCCESS = 'GET_TEST_SUCCESS';
export const GET_TEST_FAIL = 'GET_TEST_FAIL';

export const CHECK_TEST_START = 'CHECK_TEST_START';
export const CHECK_TEST_SUCCESS = 'CHECK_EST_SUCCESS';
export const CHECK_TEST_FAIL = 'CHECK_TEST_FAIL';

export const RESET_TEST_DATA = 'RESET_TEST_DATA';

export const CHECK_FINISH_TEST_FAIL = 'CHECK_FINISH_TEST_FAIL';

export const SET_CAREER_START = 'SET_CAREER_START';
export const SET_CAREER_SUCCESS = 'SET_CAREER_SUCCESS';
export const SET_CAREER_FAIL = 'SET_CAREER_FAIL';

export const GET_CAREER_START = 'GET_CAREER_START';
export const GET_CAREER_SUCCESS = 'GET_CAREER_SUCCESS';
export const GET_CAREER_FAIL = 'GET_CAREER_FAIL';

export const GET_RESULTS_START = 'GET_RESULTS_START';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_RESULTS_FAIL = 'GET_RESULTS_FAIL';

export const GET_DEPARTMENT_LIST_START = 'GET_DEPARTMENT_LIST_START';
export const GET_DEPARTMENT_LIST_SUCCESS = 'GET_DEPARTMENT_LIST_SUCCESS';
export const GET_DEPARTMENT_LIST_FAIL = 'GET_DEPARTMENT_LIST_FAIL';

export const GET_POSTION_LIST_START = 'GET_POSTION_LIST_START';
export const GET_POSTION_LIST_SUCCESS = 'GET_POSTION_LIST_SUCCESS';
export const GET_POSTION_LIST_FAIL = 'GET_POSTION_LIST_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTest = () => ({
  type: REST_API,
  method: POST,
  url: `${API_BASE}/api/v1/trajectory/conventus/quiz`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [GET_TEST_START, GET_TEST_SUCCESS, GET_TEST_FAIL],
});

export const checkTest = () => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/trajectory/conventus/quiz`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_TEST_START, CHECK_TEST_SUCCESS, CHECK_TEST_FAIL],
});

export const setCareer = id => ({
  type: REST_API,
  method: POST,
  url: `${API_BASE}/api/v1/trajectory/user/career/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [SET_CAREER_START, SET_CAREER_SUCCESS, SET_CAREER_FAIL],
});

export const testError = () => ({
  type: CHECK_FINISH_TEST_FAIL,
});

export const resetTest = () => ({
  type: RESET_TEST_DATA,
});

export const getCareerList = () => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/trajectory/career?page=0&sort=name,asc&size=1000`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CAREER_START, GET_CAREER_SUCCESS, GET_CAREER_FAIL],
});

export const getDepartmentList = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/departmentInternal?page=0&sort=name,asc&size=1000`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DEPARTMENT_LIST_START, GET_DEPARTMENT_LIST_SUCCESS, GET_DEPARTMENT_LIST_FAIL],
  onSuccess,
});

export const getPositionList = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/functionInternal?page=0&sort=name,asc&size=1000`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSTION_LIST_START, GET_POSTION_LIST_SUCCESS, GET_POSTION_LIST_FAIL],
  onSuccess,
});

export const getResults = (data, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/trajectory/conventus${getQueryString(data)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_RESULTS_START, GET_RESULTS_SUCCESS, GET_RESULTS_FAIL],
  onSuccess,
});
