/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';
import Checkbox from '@/components/Checkbox';
import { Alert } from '@/components/elements';
import FileInput from '@/components/FileInput';
import HoursMinutesInput from '@/components/HoursMinutesInput';
import CustomInput from '@/components/Input';
import NumberInput from '@/components/NumberInput';
import NumberInputCost from '@/components/NumberInput/NumberInputCost';
import ScheduleCustom from '@/components/ScheduleCustom';
import Select from '@/components/Select';
import TagsInputLink from '@/components/TagsInput/TagsInputLink';
import TimeInput from '@/components/TimeInput';

import DatePicker from '@shared/components/DatePicker';
import TagsAutocomplete from '@shared/components/TagsAutocomplete';
import { isConference, isEvent } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './TopicAddModal.module.scss';

const { TextArea } = Input;

export const InputItem = ({
  field,
  topicType,
  files,
  onChange,
  topicVideoTypes,
  onAuthorsChange,
  onLinksChange,
  onChangeSchedule,
  handleDeleteSchedule,
  handleAddSchedule,
  onFilesChange,
  onDateTimeChange,
  topic,
  required,
  topicClasses,
  onYearChange,
  curTime,
  currentUser,
  isIPR,
}) => {
  const { t } = useTranslation('inputItem');
  if (field.type === 'files') {
    return (
      <FileInput
        label={field.label}
        required={required}
        topicType={topicType}
        error={topic.errors[field.apiField]}
        onChange={onFilesChange}
        name={field.apiField}
        value={topic[field.responseField]}
        files={files}
      />
    );
  }
  if (field.type === 'tagsInput') {
    let value = Array.isArray(topic[field.apiField])
      ? topic[field.apiField].filter(data => !data.undeletable).map(link => link && (link.url || link.name))
      : [''];
    if (value && !value.length) {
      value = [''];
    }
    return (
      <Form.Item
        label={field.label}
        required={required}
        error={topic.errors[field.apiField]}
        validateStatus={topic.errors[field.apiField] ? 'error' : ''}
      >
        <TagsInputLink
          modificate
          error={topic.errors[field.apiField]}
          onChange={onLinksChange}
          name={field.apiField}
          topicType={topicType}
          placeholder='http://'
          value={value}
        />
      </Form.Item>
    );
  }
  if (field.type === 'authorsInput') {
    const value = Array.isArray(topic[field.apiField]) ? topic[field.apiField] : [];

    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <TagsAutocomplete
          isAuthor
          name={field.apiField}
          onChange={onAuthorsChange}
          placeholder={t('placeholder1')}
          value={value}
          t={t}
        />
      </Form.Item>
    );
  }
  if (field.type === 'timeInput') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <TimeInput
          modificate
          onChange={onChange}
          name={field.apiField}
          error={topic.errors[field.apiField]}
          value={topic[field.apiField]}
          startWith={field.startWith}
        />
      </Form.Item>
    );
  }
  if (field.type === 'numberInput') {
    const label = field.apiField === 'cost' && topic.typeId === 20 ? t('placeholder2') : t('placeholder3');
    return (
      <Form.Item label={label} required={required} error={topic.errors[field.apiField]}>
        <NumberInputCost modificate onChange={onChange} name={field.apiField} value={topic[field.apiField]} />
      </Form.Item>
    );
  }
  if (field.type === 'checkbox') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <Checkbox onChange={onChange} name={field.apiField} value={topic[field.apiField]} disabled={isIPR} />
      </Form.Item>
    );
  }
  if (field.type === 'calendar') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <DatePicker
          disabled={
            !dayjs(topic[field.apiField]).isSame(dayjs(), 'day') &&
            !dayjs(topic[field.apiField]).isAfter() &&
            topic.typeId === 8 &&
            topic[field.apiField] !== null
          }
          modificate
          readOnly
          typeId={topic.typeId}
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'year') {
    const dayjsDate = topic[field.apiField] ? dayjs(topic[field.apiField]) : null;
    const isValid = dayjsDate ? dayjsDate.isValid() : false;
    const yearValue = isValid ? dayjsDate.year() : null;
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <NumberInput modificate onChange={onYearChange} name={field.apiField} value={yearValue} min={0} max={270000} />
      </Form.Item>
    );
  }
  if (field.type === 'videoStyleselector') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <Select
          modificate
          onChange={onChange}
          name={field.apiField}
          options={topicVideoTypes}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'topicClassSelector') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <Select
          modificate
          onChange={onChange}
          name={field.apiField}
          placeholder={t('placeholder4')}
          getPopupContainer={() => document.getElementById('area')}
          options={topicClasses}
          value={topic[field.apiField] ? topic[field.apiField] : undefined}
        />
      </Form.Item>
    );
  }
  if (field.type === 'dateTimeInput') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <HoursMinutesInput
          modificate
          onChange={onDateTimeChange}
          name={field.apiField}
          disabledHours={topic.typeId === 8 && dayjs(topic.startDate).isSame(dayjs(), 'day')}
          dateIsBefore={topic.typeId === 8 && dayjs(topic.startDate).isBefore(dayjs(), 'day')}
          curTime={curTime}
          time={dayjs(topic[field.apiField]).format('HH:mm:ss')}
        />
      </Form.Item>
    );
  }
  if (field.type === 'inputPlace') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <CustomInput
          dataqa='userMaterialsPlaceInput'
          maxLength={100}
          placeholder={t('placeholder5')}
          modificate
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'inputCompany') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <CustomInput
          dataqa='userMaterialCompanyInput'
          maxLength={255}
          placeholder={t('placeholder6')}
          modificate
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'yearIssued') {
    const dayjsDate = topic[field.apiField] ? dayjs(topic[field.apiField]) : undefined;
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <NumberInput
          dataqa='userMaterialsYearIssuedInput'
          modificate
          yearIssued
          onChange={onYearChange}
          name={field.apiField}
          placeholder='2007'
          value={dayjsDate && dayjsDate.year()}
          min={0}
          max={9999}
        />
      </Form.Item>
    );
  }
  if (field.type === 'inputPublisher') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <CustomInput
          maxLength={255}
          placeholder={t('placeholder7')}
          modificate
          onChange={onChange}
          name={field.apiField}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }
  if (field.type === 'schedule') {
    let value = Array.isArray(topic[field.apiField]) ? topic[field.apiField].map(schedule => schedule) : [''];
    if (value && !value.length) {
      value = [
        {
          dates: [],
          endTime: '',
          startTime: '',
          place: '',
        },
      ];
    }

    const disabledDays = (() => {
      if (isConference(topic.typeId)) {
        return {
          before: dayjs().add(6, 'days').toDate(),
        };
      } else if (isEvent(topic.typeId)) {
        return {
          before: dayjs().add(1, 'days').toDate(),
        };
      } else {
        return null;
      }
    })();

    return (
      <Form.Item wrapperCol={24} required={required}>
        {topic.id && isConference(topic.typeId) ? (
          <Alert type='warning' description={t('placeholder8')} />
        ) : (
          <ScheduleCustom
            error={topic.errors[field.apiField]}
            value={value}
            currentUser={currentUser}
            handleDeleteSchedule={handleDeleteSchedule}
            handleAddSchedule={handleAddSchedule}
            curTime={curTime}
            topic={topic}
            onChange={onChangeSchedule}
            onDateChange={onDateTimeChange}
            name={field.apiField}
            disabledDays={disabledDays}
          />
        )}
      </Form.Item>
    );
  }

  if (field.type === 'textArea') {
    return (
      <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
        <TextArea
          className={css['Add-modal-textarea']}
          onChange={onChange}
          name={field.apiField}
          rows={4}
          value={topic[field.apiField]}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item label={field.label} required={required} error={topic.errors[field.apiField]}>
      <CustomInput modificate onChange={onChange} name={field.apiField} value={topic[field.apiField]} />
    </Form.Item>
  );
};

InputItem.propTypes = {
  curTime: PropTypes.any,
  currentUser: PropTypes.any,
  field: PropTypes.any,
  files: PropTypes.any,
  handleAddSchedule: PropTypes.any,
  handleDeleteSchedule: PropTypes.any,
  modificate: PropTypes.bool,
  onAuthorsChange: PropTypes.any,
  onChange: PropTypes.any,
  onChangeSchedule: PropTypes.any,
  onDateTimeChange: PropTypes.any,
  onFilesChange: PropTypes.any,
  onLinksChange: PropTypes.any,
  onYearChange: PropTypes.any,
  required: PropTypes.bool,
  topic: PropTypes.any,
  topicClasses: PropTypes.any,
  topicType: PropTypes.any,
  topicVideoTypes: PropTypes.any,
  isIPR: PropTypes.bool,
};
