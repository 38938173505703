import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createFileImage, getFile } from '@/store/files/actions';
import { createNews, editNewsVisibility, getNews } from '@/store/news/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectNews } from '@/store/news/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Alert, Button, Form, Input, Modal, Spin } from 'antd';
import BannerInput from '@/components/BannerInput/BannerInput';

import { MAX_CHAR_NEWS } from './constance';

import QuillEditor from '@shared/components/QuillEditor';
import TagsAutocomplete from '@shared/components/TagsAutocomplete';

import Utils from '@/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './NewsModalCreate.module.scss';

const ModalNews = ({ visible, isCreate, filterParams, onClose, newsPhotoUuid }) => {
  const { t } = useTranslation('newsModalCreate');
  const dispatch = useDispatch();

  const { currentUser } = useSelector(selectUsers);
  const { isLoadingNewsId, newsId } = useSelector(selectNews);
  const { isLoadingImage } = useSelector(selectFiles);

  const [data, setData] = useState({
    id: null,
    title: '',
    description: '',
    link: '',
    photoUuid: '',
    tagNames: [],
    authorId: null,
    author: '',
  });
  const [fileBlob, setFileBlob] = useState(undefined);
  const [isLoadingPreviwImage, setLoadingPreviwImage] = useState(false);
  const [previewImg, setPreviewImg] = useState('');

  const [errors, setErrors] = useState({
    title: false,
    description: false,
  });

  useEffect(() => {
    if (newsId && Object.keys(newsId).length > 0) {
      setData({
        ...data,
        id: newsId.id,
        title: newsId.title,
        description: newsId.description,
        link: newsId.link,
        photoUuid: newsId.photoUuid, // newsPhotoUuid
        tagNames: newsId.tagNames,
        authorId: newsId.authorDto?.id,
        author: newsId.authorDto?.name,
      });
    }
  }, [newsId]);

  useEffect(() => {
    if (newsPhotoUuid && !previewImg) {
      setLoadingPreviwImage(true);
      dispatch(
        getFile(newsPhotoUuid, blob => {
          const imageUrl = URL.createObjectURL(blob);
          setPreviewImg(imageUrl);
          setLoadingPreviwImage(false);
        })
      );
    }
  }, []);

  const validData = value => {
    let copyValue = _.cloneDeep(value);

    for (let key in copyValue) {
      if (!copyValue[key]) {
        delete copyValue[key]; // удалил пустые значения
      }

      if (Array.isArray(copyValue[key]) && copyValue[key].length === 0) {
        delete copyValue[key];
      }
    }

    if (copyValue?.tagNames?.length > 0) {
      copyValue.tagNames = copyValue?.tagNames.map(i => {
        const isNewTag = String(i.id).startsWith('new');
        const id = isNewTag ? null : i.id;

        return { name: i.name, id };
      });
    }

    if (copyValue?.id) {
      delete copyValue.id;
    }

    if (copyValue.title) {
      copyValue.title = copyValue.title.trim();
    }

    if (copyValue.description) {
      copyValue.description = copyValue.description.trim();
    }

    return copyValue;
  };

  const updateImage = blob => {
    setFileBlob(blob);
    setPreviewImg(window.URL.createObjectURL(blob));
  };

  const onChangeEditor = htmlString => {
    setData(prevData => ({ ...prevData, description: htmlString }));
  };

  const pushError = (name, status) => {
    setErrors({ ...errors, [name]: status });
  };

  const checkErrors = () => {
    const parser = new DOMParser();

    const title = data.title.trim();
    const description = parser.parseFromString(data.description.trim(), 'text/html');
    const descriptionInnerText = description.body.innerText.trim();

    if (!title || !descriptionInnerText) {
      setErrors({ title: !title, description: !descriptionInnerText });
      setData(prevData => ({ ...prevData, title, description: descriptionInnerText }));

      return false;
    }

    return true;
  };

  const templateCallback = id => {
    const getNewsAndClose = () => {
      dispatch(getNews(0, filterParams));
      onClose();
    };

    if (id && currentUser && Utils.isAdminOrSuperAdmin(currentUser)) {
      dispatch(
        editNewsVisibility(id, false, () => {
          getNewsAndClose();
        })
      );
    } else {
      getNewsAndClose();
    }
  };

  const onCreateNews = () => {
    if (!checkErrors()) return;

    const formData = new FormData();
    formData.append('file', fileBlob);

    const sendData = validData(data);

    if (fileBlob) {
      dispatch(
        createFileImage(formData, res => {
          dispatch(createNews({ ...sendData, photoUuid: res.uuid }, id => templateCallback(id)));
        })
      );
    } else {
      dispatch(createNews(sendData, id => templateCallback(id)));
    }
  };

  const onChangeTags = e => {
    if (e?.value) {
      const namesTags = e.value.map(i => ({ name: i.name, id: i.id }));
      setData({ ...data, tagNames: namesTags });
    }
  };

  const onDeleteBlob = () => {
    setFileBlob(undefined);
    setPreviewImg(undefined);
    setData({ ...data, photoUuid: '' });
  };

  const footerModal = [
    <Button onClick={onClose} key='onClose' size='large'>
      {t('close')}
    </Button>,
    <Button
      className={css['ModalNews-form__footer-btn']}
      onClick={onCreateNews}
      key='onSumbit'
      size='large'
      type='primary'
    >
      {t('create')}
    </Button>,
  ];

  return (
    <Modal
      open={visible}
      title={<span className={css['ModalNews-title']}>{t('titleCreate')}</span>}
      className={css['ModalNews']}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <Spin size='large' className={css['ModalNews-spin']} spinning={isLoadingNewsId || isLoadingImage}>
        <Form className={css['ModalNews-form']} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          {/* Название */}
          <Form.Item
            label={t('nameNews')}
            colon={false}
            validateStatus={errors.title ? 'error' : ''}
            help={errors.title && t('notNameNews')}
            required
          >
            <Input
              size='large'
              onChange={e => setData({ ...data, title: e.target.value })}
              value={data.title}
              placeholder={t('placeholderNews')}
              maxLength={500}
            />
          </Form.Item>

          {/* Редактор описания */}
          <Form.Item
            label={t('editorNews')}
            colon={false}
            validateStatus={errors.description ? 'error' : ''}
            help={errors.description && t('notEditorNews')}
            required
          >
            {(isCreate || (!isLoadingNewsId && newsId?.description)) && (
              <QuillEditor
                onChange={onChangeEditor}
                Content={newsId?.description || data.description}
                textMaxChar={t('maxCharNews')}
                maxChar={MAX_CHAR_NEWS}
                placeholder={t('placeholderNewsEditor')}
                showFooter
                hideCodeCount
              />
            )}
          </Form.Item>

          {/* Изображение */}
          <Form.Item label={t('imageNews')} colon={false}>
            <Alert
              message={<span>{t('infoUploadImage')}</span>}
              className={css['ModalNews-form-alert']}
              type='info'
              closable
            />
            <Spin spinning={!isLoadingNewsId && isLoadingPreviwImage}>
              <BannerInput
                updateImage={updateImage}
                pushError={pushError}
                typeUpload='image/png, image/jpeg, image/gif,'
                errorSizeMessage={t('errorSizeMessageNews')}
                changeImageText={t('changeImageNews')}
                clearFileBlob={onDeleteBlob}
                fileBlob={fileBlob || previewImg}
                canDelete
              />
              <div className={css['ModalNews-form-upload']}>
                {!isLoadingPreviwImage && (
                  <img
                    className={`${css['ModalNews-form-upload-img']} ${previewImg && css['show']}`}
                    src={previewImg}
                    alt={t('infoPreviewImage')}
                  />
                )}
              </div>
            </Spin>
          </Form.Item>

          {/* Ссылка */}
          <Form.Item label={t('link')} colon={false}>
            <Input
              size='large'
              className={css['ModalNews-form__linkInput']}
              onChange={e => setData({ ...data, link: e.target.value })}
              value={data.link}
              placeholder={t('placeholderLink')}
              maxLength={500}
            />
          </Form.Item>

          {/* Тэги */}
          <Form.Item label={t('tags')} colon={false}>
            <TagsAutocomplete
              dataqa='tags'
              onChange={onChangeTags}
              name='tagNames'
              className={css['ModalNews-form__tagInput']}
              placeholder={t('placeholderTags')}
              value={data.tagNames}
              // isGlobal={currentUser?.domainCompany?.global}
              t={t}
              isNew
              isNews
            />
          </Form.Item>
        </Form>
        <div className={css['ModalNews-form__footer']}>{isLoadingNewsId || isLoadingImage ? false : footerModal}</div>
      </Spin>
    </Modal>
  );
};

ModalNews.propTypes = {
  visible: PropTypes.bool,
  isCreate: PropTypes.bool,
  onClose: PropTypes.func,
  newsPhotoUuid: PropTypes.string,
  filterParams: PropTypes.object,
};

export default ModalNews;
