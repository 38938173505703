/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createFile } from '@/store/files/actions';

import { selectFiles as filesSelect } from '@/store/files/selectors';

import { Button, Form, Spin, Tag, Tooltip } from 'antd';
import OldIcon from '@/components/OldIcon';

import Modal from '../Modal';

import { DOC_TYPES } from '@shared/constants/image-types';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './FileInput.module.scss';

import archiveImage from '@/assets/images/archive2.png';
import docImage from '@/assets/images/docs2.png';
import galleryImage from '@/assets/images/gallery.png';
import mediaImage from '@/assets/images/media2.png';

export class FileInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    createFile: PropTypes.func,
    error: PropTypes.string,
    fileInfo: PropTypes.object,
    fileLoading: PropTypes.bool,
    fileLoadingImage: PropTypes.bool,
    files: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    topicType: PropTypes.object,
    value: PropTypes.array,
    buttonAddText: PropTypes.string,
    disabled: PropTypes.bool,
    singleFile: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: this.props.value || [],
      error: this.props.error || '',
      modalError: false,
      buttonAddText: this.props.t('add'),
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({ files: this.props.value });
    }
  }

  setInputRef = input => {
    const a = DOC_TYPES.join(', ');
    input && input.setAttribute('accept', a);
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileUpload = (file, fileName, fileSize) => {
    const { onChange, name, t } = this.props;
    if (file) {
      this.props.createFile(
        file,
        fileInfo => {
          const files = [{ uuid: fileInfo.uuid, name: fileName, size: fileSize }];
          if (!this.props?.singleFile) {
            files.unshift(...this.state.files);
          }
          this.setState({ files });
          onChange(files, name);
          this.setState({ error: '' });
        },
        () => {
          const label =
            this.props.topicType && this.props.topicType.id === 4
              ? t('video')
              : this.props.topicType && this.props.topicType.id === 16
                ? t('doc')
                : null;
          this.setState({
            error: t('error', { label }),
            modalError: true,
          });
        }
      );
    }
  };

  handleFileChange = event => {
    event.preventDefault();

    const { t } = this.props;
    const reader = new FileReader();
    const file = event.target.files[0];
    const formData = new FormData();

    if (this.props.isPublicFile) {
      formData.isPublicFile = true;
    }

    formData.append('file', file);
    reader.onloadend = () => {
      if (file?.type || file?.size || file?.name) {
        if (!DOC_TYPES.includes(file.type) || file.size > 209715200) {
          const label =
            this.props.topicType && this.props.topicType.id === 4
              ? t('video')
              : this.props.topicType && this.props.topicType.id === 16
                ? t('doc')
                : null;
          this.setState({
            error: t('error', { label }),
            modalError: true,
          });
        } else {
          this.setState({ error: '' });
          this.handleFileUpload(formData, file.name, file.size);
        }
      }
    };
    file && reader.readAsDataURL(file);
    event.target.value = null;
  };

  handleFileDelete = fileUuid => {
    const { onChange, name } = this.props;
    const files = this.state.files.filter(file => file.uuid !== fileUuid);
    this.setState({ files });
    onChange(files, name);
  };

  onCancel = () => {
    this.hideConfirmDialog();
    this.openFileDialog();
  };

  hideConfirmDialog = () => {
    this.setState({ modalError: false });
  };

  render() {
    const { className, t } = this.props;

    const buttonAddText =
      this.props.buttonAddText && this.props.buttonAddText.length > 0 ? this.props.buttonAddText : t('add');

    let { files } = this.state;

    if (!Array.isArray(files)) {
      files = [];
    }

    const fileTags = files.map(file => (
      <Tag key={file.uuid} className={css['FileInput-item']}>
        {file.name && file.name.length > 55 ? (
          <Tooltip title={file.name}>{`${file.name.substring(0, 55)}...`}</Tooltip>
        ) : (
          file.name
        )}
        {!this.props.disabled && <OldIcon type='close' onClick={() => this.handleFileDelete(file.uuid)} />}
      </Tag>
    ));

    const content = fileTags.length ? <div className={css['FileInput-content']}>{fileTags}</div> : null;
    return (
      <div>
        <Form.Item label={this.props.label} required={this.props.required} error={this.state.error}>
          <div className={classNames(css.FileInput, className)}>
            <Button
              onClick={this.openFileDialog}
              disabled={
                this.props.disabled ||
                this.props.fileLoading ||
                this.props.fileLoadingImage ||
                (this.props.files && this.props.files.length >= 10)
              }
              size='large'
            >
              {this.props.fileLoading ? (
                <div>
                  {t('loading')}
                  <Spin size='small' />
                </div>
              ) : (
                buttonAddText
              )}
            </Button>
            <input dataqa='collectionFileInput' type='file' ref={this.setInputRef} onChange={this.handleFileChange} />
          </div>
          {content}
        </Form.Item>
        <Modal
          modificate
          fileModal
          open={this.state.modalError}
          onOk={this.onCancel}
          onCancel={this.hideConfirmDialog}
          okText={t('add')}
        >
          <div className={css['App-card-label']}>{t('fileTypesDescription')}</div>
          <div className={css['App-card-info']}>
            <img src={docImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('books')}</h2>
                <div className={css['App-card-info-little']}>{t('max', { limit: '200' })}</div>
              </div>
              <h3>{t('bookTypes')}</h3>
            </div>
          </div>
          <div className={css['App-card-info']}>
            <img src={mediaImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('media')}</h2>
                <div className={css['App-card-info-little']}>{t('max', { limit: '200' })}</div>
              </div>
              <h3>{t('mediaTypes')}</h3>
            </div>
          </div>
          <div className={css['App-card-info']}>
            <img src={galleryImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('images')}</h2>
                <div className={css['App-card-info-little']}>{t('max', { limit: '10' })}</div>
              </div>
              <h3>{t('imagesTypes')}</h3>
            </div>
          </div>
          <div className={css['App-card-info']}>
            <img src={archiveImage} alt='' />
            <div className={css['App-card-info-item']}>
              <div className={css['App-card-info-item-block']}>
                <h2>{t('archive')}</h2>
                <div className={css['App-card-info-little']}>{t('max', { limit: '200' })}</div>
              </div>
              <h3>{t('archiveTypes')}</h3>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createSelector(filesSelect, files => ({
  fileInfo: files.info,
  fileLoading: files.isLoading,
  fileLoadingImage: files.isLoadingImage,
}));

const mapActionsToProps = {
  createFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('fileInput')(FileInput));
