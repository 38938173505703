// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-KzlOJC.user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Xjzchd .user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-label-FsTtGV[data-value="Montserrat"]:before, .user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-KzlOJC.user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Xjzchd .user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-item-jLTzL0[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Montserrat-CC15tz {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-Chat-parts-Aside-Aside-module__aside-mrj5nM {
  box-sizing: border-box;
  border-bottom: 1px solid #d5d5d5;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  display: flex;
}

.user-src-pages-Chat-parts-Aside-Aside-module__aside__element-guSGh5 {
  color: inherit;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  display: flex;
}

.user-src-pages-Chat-parts-Aside-Aside-module__aside__element-guSGh5:hover {
  color: inherit;
}

.user-src-pages-Chat-parts-Aside-Aside-module__aside__element_active-VT7Ran, .user-src-pages-Chat-parts-Aside-Aside-module__aside__element_active-VT7Ran:hover {
  color: var(--personalColor);
}

.user-src-pages-Chat-parts-Aside-Aside-module__aside__text-w2XATi {
  font-size: 12px;
}

@media (max-width: 1200px) {
  .user-src-pages-Chat-parts-Aside-Aside-module__aside__element-guSGh5 {
    font-size: 24px;
  }

  .user-src-pages-Chat-parts-Aside-Aside-module__aside__text-w2XATi {
    font-size: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/Chat/parts/Aside/Aside.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,gCAAgC;EAChC,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,aAAa;AACf;;AAMA;EAHE,cAAc;EACd,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,aAAa;AACf;;AAKA;EAFE,cAAc;AAChB;;AAIA;EADE,2BAA2B;AAC7B;;AAMA;EAHE,eAAe;AACjB;;AAMA;EACE;IAHE,eAAe;EACjB;;EAKA;IAFE,eAAe;EACjB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.aside {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  padding: 10px 0;\n  border-bottom: 1px solid #d5d5d5;\n}\n.aside__element {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  align-items: center;\n  color: inherit;\n  font-size: 20px;\n}\n.aside__element:hover {\n  color: inherit;\n}\n.aside__element_active {\n  color: var(--personalColor);\n}\n.aside__element_active:hover {\n  color: var(--personalColor);\n}\n.aside__text {\n  font-size: 12px;\n}\n\n@media (width <= 1200px) {\n  .aside__element {\n    font-size: 24px;\n  }\n  .aside__text {\n    font-size: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-KzlOJC`,
	"qlPicker": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-KzlOJC`,
	"ql-font": `user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Xjzchd`,
	"qlFont": `user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Xjzchd`,
	"ql-picker-label": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-label-FsTtGV`,
	"qlPickerLabel": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-label-FsTtGV`,
	"ql-picker-item": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-item-jLTzL0`,
	"qlPickerItem": `user-src-pages-Chat-parts-Aside-Aside-module__ql-picker-item-jLTzL0`,
	"ql-font-Montserrat": `user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Montserrat-CC15tz`,
	"qlFontMontserrat": `user-src-pages-Chat-parts-Aside-Aside-module__ql-font-Montserrat-CC15tz`,
	"aside": `user-src-pages-Chat-parts-Aside-Aside-module__aside-mrj5nM`,
	"aside__element": `user-src-pages-Chat-parts-Aside-Aside-module__aside__element-guSGh5`,
	"asideElement": `user-src-pages-Chat-parts-Aside-Aside-module__aside__element-guSGh5`,
	"aside__element_active": `user-src-pages-Chat-parts-Aside-Aside-module__aside__element_active-VT7Ran`,
	"asideElementActive": `user-src-pages-Chat-parts-Aside-Aside-module__aside__element_active-VT7Ran`,
	"aside__text": `user-src-pages-Chat-parts-Aside-Aside-module__aside__text-w2XATi`,
	"asideText": `user-src-pages-Chat-parts-Aside-Aside-module__aside__text-w2XATi`
};
export default ___CSS_LOADER_EXPORT___;
