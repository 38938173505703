import { useOutletContext } from 'react-router-dom';

import { MaterialCard } from '@/components/material-card';

import StudyPlanButton from '../StudyPlanButton';

import { classNames } from '@shared/utils/styles-utils';
import { isEventOrConference } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { CURRENT_MONTH, LAST_MONTH, NEXT_MONTH } from '@/constants/study-plan-statuses';

const cn = classNames(styles);

export const Card = ({
  material,
  currentUser,
  isAnother,
  showCalendarModal,
  setHandleStudyPlanClickState,
  setHandleStudyPlanClickExecuted,
}) => {
  const { onAddToPlanNow } = useOutletContext();

  const activeMonthName = material.id;
  const planningDate = dayjs(activeMonthName, 'YYYY-MM');
  const activeDate = dayjs().format('YYYY-MM');

  const { topic } = material;

  const checkIsEnd =
    topic.type &&
    isEventOrConference(topic.type.id) &&
    !_get(topic, 'planStatus.planStatus') &&
    dayjs(`${topic.endDate} ${topic.endTime}`).isBefore(dayjs());

  const checkCoursePlan = topic.type && !_get(topic, 'planStatus.planStatus');

  const config =
    planningDate === activeDate ? CURRENT_MONTH : String(planningDate) < String(activeDate) ? LAST_MONTH : NEXT_MONTH;

  const actionType = config && config[material.status] && config[material.status].buttonType;

  const withNoEvents = Utils.isWithoutEvents(material?.topic);

  return (
    <MaterialCard
      material={material}
      small
      className={cn('planning-card')}
      asideClass={cn('planning-card__aside')}
      excludeParts={['footer']}
      currentUser={currentUser}
      mdPoster={3}
      mdCentr={12}
    >
      <StudyPlanButton
        topicType={material.topic.type.id}
        topicName={material.topic.name}
        mention={material.mention}
        topicWasFinishedBefore={material.topic.isFinishedBefore}
        planId={material.id}
        checkIsEnd={checkIsEnd}
        checkCoursePlan={checkCoursePlan}
        plan={material}
        isAnother={isAnother}
        topicId={material.topic.id}
        showCalendarModal={showCalendarModal}
        actionType={actionType}
        activeMonth={activeMonthName}
        favouriteClass={cn('planning-card__favourite')}
        withNoEvents={withNoEvents}
        onAddToPlanNow={() => onAddToPlanNow(material.topic)}
        topicCost={topic.cost}
        topicTypeApproved={topic.type.approved}
        topicTypewithEvents={topic.type.withEvents}
        setHandleStudyPlanClickState={setHandleStudyPlanClickState}
        setHandleStudyPlanClickExecuted={setHandleStudyPlanClickExecuted}
      />
    </MaterialCard>
  );
};

Card.propTypes = {
  material: PropTypes.any,
  isAnother: PropTypes.bool,
  showCalendarModal: PropTypes.any,
  currentUser: PropTypes.object,
};
