export const mockComments = [
  {
    date: '01.01.2024',
    time: '10:10',
    author: 'Иванов Иван',
    department: 'главный инженер',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lectus felis, porttitor sit amet eleifend eget, rhoncus vel quam. Phasellus et congue ipsum. Nullam id rhoncus quam. Mauris eu rutrum lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed sed rhoncus massa, vehicula dictum ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },
  {
    date: '01.01.2024',
    time: '12:12',
    author: 'Петров Петр',
    department: 'инженер',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lectus felis, porttitor sit amet eleifend eget, rhoncus vel quam. Phasellus et congue ipsum. Nullam id rhoncus quam. Mauris eu rutrum lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed sed rhoncus massa, vehicula dictum ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
  },
];
