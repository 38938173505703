import React from 'react';

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiMaximize2,
  FiMinimize2,
  FiZoomIn,
  FiZoomOut,
} from 'react-icons/fi';

import css from './Controller.module.scss';

const Controller = ({ fullscreen, changeFullscreen, changePage, changeZoom }) => {
  const render = () => {
    return (
      <div className={css['Controller']}>
        <FiChevronsLeft onClick={() => changePage('firstPage')} className={css['Controller__button']} />
        <FiChevronLeft onClick={() => changePage('prevPage')} className={css['Controller__button']} />
        <FiChevronRight onClick={() => changePage('nextPage')} className={css['Controller__button']} />
        <FiChevronsRight onClick={() => changePage('lastPage')} className={css['Controller__button']} />
        <FiZoomOut onClick={() => changeZoom('zoomOut')} className={css['Controller__button']} />
        <FiZoomIn onClick={() => changeZoom('zoomIn')} className={css['Controller__button']} />
        {!fullscreen ? (
          <FiMaximize2 onClick={changeFullscreen} className={css['Controller__button']} />
        ) : (
          <FiMinimize2 onClick={changeFullscreen} className={css['Controller__button']} />
        )}
      </div>
    );
  };

  return render();
};

export default Controller;
