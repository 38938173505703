import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getMentees, reviewMentee } from '@/store/mentee/actions';

import { Button, Form, Input } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import styles from '../styles.module.scss';

const { TextArea } = Input;
const cn = classNames(styles);

const ThankMentorForm = ({ selectedRecord, currentValueSelectMentor }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('mentorCard');
  const dispatch = useDispatch();

  const handleStoreReviewMentee = ({ menteeId, mentorId, text, currentValueSelectMentor }) => {
    dispatch(reviewMentee(menteeId, mentorId, text));
    setTimeout(() => {
      dispatch(getMentees(currentValueSelectMentor));
    }, 500);
  };

  const submitThankForm = () => handleSubmit(selectedRecord, currentValueSelectMentor);

  const handleSubmit = ({ id, mentorId }, currentValueSelectMentor) => {
    form.validateFields().then(values => {
      handleStoreReviewMentee({
        menteeId: id,
        mentorId,
        text: values.gratitude,
        currentValueSelectMentor,
      });
    });
  };

  return (
    <Form onFinish={submitThankForm} form={form}>
      <Form.Item
        name={'gratitude'}
        rules={[
          {
            required: true,
            message: t('pleaseInputYourThank'),
          },
          () => ({
            validator(_, value) {
              if (value?.length === 1) {
                return Promise.reject(new Error(t('warningMoreThan1')));
              } else if (value?.length > 1500) {
                return Promise.reject(new Error(t('warningLessThan1500')));
              }
              return Promise.resolve();
            },
          }),
        ]}
        validateStatus={form?.getFieldValue('gratitude')?.length === 0 ? 'error' : 'success'}
      >
        <TextArea
          className={cn('thankMentorFormTextarea')}
          rows={3}
          placeholder={t('thankMentorModalWordsOfGratitude')}
        />
      </Form.Item>
      <Form.Item className={cn('thankMentorFormControl')}>
        <Button htmlType='submit' className={cn('thankMentorFormButton')} type='primary' size='large'>
          {t('thankMentorModalSendThanks')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ThankMentorForm;
