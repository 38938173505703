import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Tooltip } from 'antd';

import cardPlaceholder from './cardPlaceholder.png';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './MultiCard.module.scss';

const MultiCard = ({ imageUuid, title, parametrs, action, className, ...rest }) => {
  const [cardImage, setCardImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;

    if (imageUuid) {
      dispatch(
        getFile(
          imageUuid,
          img => setCardImage(URL.createObjectURL(img)),
          () => setImageError(true),
          signal
        )
      );
    }

    return () => controller.abort();
  }, [imageUuid, dispatch]);

  // Рендер нижних полей
  const parametrsRender = () =>
    Array.isArray(parametrs) &&
    parametrs?.map((param, index) => (
      <div className={css['MultiCard-parametrs']} key={index}>
        <span className={css['MultiCard-parametrs-label']}>{param?.label}</span>
        <span className={css['MultiCard-parametrs-value']}>{param?.value}</span>
      </div>
    ));

  return (
    <div className={classNames(css['MultiCard-container'], className)} {...rest}>
      <div className={css['MultiCard-container-image']}>
        <img
          className={classNames(css['MultiCard-cover'], (imageError || cardImage) && css['MultiCard-cover-loaded'])}
          src={cardImage ? cardImage : cardPlaceholder}
          alt='cardImg'
        />
      </div>
      <div className={css['MultiCard-container-info']}>
        {title && (
          <Tooltip title={title?.length > 45 && title}>
            <h3 className={css['MultiCard-title']}>{Utils.trString(title, 45)}</h3>
          </Tooltip>
        )}
        {parametrsRender()}
      </div>
      <div className={css['MultiCard-container-action']}>{action}</div>
    </div>
  );
};

MultiCard.propTypes = {
  imageUuid: PropTypes.string, // Картинка в виде uuid
  title: PropTypes.string, // Заголовок
  parametrs: PropTypes.array, // Какие либо параметры карточки
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null, undefined])]), // Кнопки
  className: PropTypes.string, // При необходимости, можно добавить собственный className контейнеру карточки
};

export default MultiCard;
