import {
  ADD_NEW_GROUP,
  ADD_NEW_MESSAGE,
  ADD_NEW_MESSAGE_IN_CHAT,
  ADD_NEW_PERSONAL_GROUP,
  CHECK_NEW_MESSAGE_SUCCESS,
  CLEAR_FOUND_GROUPS,
  CLEAR_GROUPS,
  CLEAR_MESSAGES,
  CLEAR_PERSONAL_GROUPS,
  CLEAR_REPLY_MESSAGE,
  CLEAR_SEARCH_USERS,
  CLEAR_SELECTED_USER,
  CLEAR_USERS,
  DELETE_CHAT_GROUP,
  DELETE_CHAT_GROUP_USER_SUCCESS,
  GET_CHAT_GROUPS_START,
  GET_CHAT_GROUPS_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_START,
  GET_MESSAGES_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_IN_CHAT_SUCCESS,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  READ_MESSAGES_IN_CHAT,
  SEARCH_GROUPS,
  SEARCH_USERS_FAIL,
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SET_REPLY_MESSAGE,
  SET_SELECTED_USER,
  UPDATE_CHAT_GROUP,
} from './constants';

const initialState = {
  selectedUser: null,
  replyMessage: null,
  countNewMessage: 0,
  users: [],
  messages: [],
  foundUsers: [],
  groups: [],
  foundGroups: [],
  chatUsers: [],
  isLoading: {
    searchUsers: false,
    getGroups: false,
    getUsers: false,
    getChatGroups: false,
  },
  isError: {
    searchUsers: false,
    getGroups: false,
    getUsers: false,
  },
  pagination: {
    getUsers: {
      size: 0,
      totalElements: 0,
      page: 0,
    },
  },
};

const findGroup = (groupId, groups) => {
  const groupIndex = groups.findIndex(group => group?.chatId === groupId || group?.chatData?.chat_id === groupId);
  return groupIndex;
};

export default (state = initialState, { type, response, headers, action, data }) => {
  const newGroupsList = state.groups;
  let groupIndex = null;
  let newGroup = null;

  switch (type) {
    case GET_USERS_START:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getUsers: true,
        },
        isError: {
          ...state.isError,
          getUsers: false,
        },
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...response],
        isLoading: {
          ...state.isLoading,
          getUsers: false,
        },
        isError: {
          ...state.isError,
          getUsers: false,
        },
        pagination: {
          ...state.pagination,
          getUsers: {
            size: Number(headers.get('X-Page-Size')) || 0,
            totalElements: Number(headers.get('X-Total-Elements')) || 0,
            page: Number(headers.get('X-Page-Number')) || 0,
          },
        },
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          getUsers: false,
        },
        isError: {
          ...state.isError,
          getUsers: true,
        },
        users: [],
      };

    case SET_SELECTED_USER:
      if (data.chatId && !data?.isMentoring) {
        groupIndex = findGroup(data.chatId, newGroupsList);
        state.countNewMessage -= newGroupsList[groupIndex].countNotReadMessage;
      }

      if (data.chatId && !data?.isMentoring && groupIndex !== -1 && newGroupsList[groupIndex].countNotReadMessage)
        newGroupsList[groupIndex].countNotReadMessage = 0;

      return {
        ...state,
        selectedUser: data,
        foundUsers: [],
      };

    case SEARCH_USERS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, searchUsers: true },
        isError: { ...state.isError, searchUsers: false },
        foundUsers: state.foundUsers,
      };

    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, searchUsers: false },
        isError: { ...state.isError, searchUsers: false },
        foundUsers: action.isClear ? response : [...state.foundUsers, ...response],
      };

    case SEARCH_USERS_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, searchUsers: false },
        isError: { ...state.isError, searchUsers: true },
        foundUsers: state.foundUsers,
      };

    case GET_CHAT_GROUPS_START:
      return { ...state, isLoading: { ...state.isLoading, getChatGroups: true } };

    case GET_MESSAGES_START:
    case GET_MESSAGES_FAIL:
      return state;

    case GET_CHAT_GROUPS_SUCCESS:
      return { ...state, groups: response, isLoading: { ...state.isLoading, getChatGroups: false } };

    case GET_MESSAGES_SUCCESS:
      return { ...state, messages: response };

    case CLEAR_SEARCH_USERS:
      return { ...state, foundUsers: [] };

    case ADD_NEW_MESSAGE_IN_CHAT:
      groupIndex = findGroup(data.chat_id, newGroupsList);

      newGroup = newGroupsList[groupIndex];
      newGroup.lastMessage = data;
      newGroupsList.splice(groupIndex, 1);
      newGroupsList.unshift(newGroup);

      return {
        ...state,
        messages: [data, ...state.messages],
        groups: newGroupsList,
      };

    case ADD_NEW_PERSONAL_GROUP:
      return {
        ...state,
        groups: [{ ...data, countNotReadMessage: 1 }, ...state.groups],
        countNewMessage: state.countNewMessage + 1,
      };

    case ADD_NEW_GROUP:
      return {
        ...state,
        groups: [data, ...state.groups],
      };

    case CLEAR_MESSAGES:
      return { ...state, messages: [] };

    case CLEAR_SELECTED_USER:
      return { ...state, selectedUser: null };

    case READ_MESSAGES_IN_CHAT:
      groupIndex = findGroup(data.chat_id, newGroupsList);
      if (groupIndex !== -1) {
        newGroupsList[groupIndex].lastMessage.is_read = true;
      }
      return { ...state, groups: newGroupsList };

    case ADD_NEW_MESSAGE:
      groupIndex = findGroup(data.chat_id, newGroupsList);

      if (groupIndex !== -1) {
        newGroup = newGroupsList[groupIndex];
        newGroup.lastMessage = data;
        newGroup.countNotReadMessage += 1;
        newGroupsList.splice(groupIndex, 1);
        newGroupsList.unshift(newGroup);
      }

      return {
        ...state,
        countNewMessage: state.countNewMessage + 1,
        groups: newGroupsList,
      };

    case CHECK_NEW_MESSAGE_SUCCESS:
      return { ...state, countNewMessage: typeof response === 'number' ? response : 0 };

    case SET_REPLY_MESSAGE:
      return { ...state, replyMessage: data };

    case CLEAR_REPLY_MESSAGE:
      return { ...state, replyMessage: null };

    case CLEAR_GROUPS:
      return { ...state, groups: [] };

    case GET_USERS_IN_CHAT_SUCCESS:
      return { ...state, chatUsers: response };

    case CLEAR_USERS:
      return { ...state, users: [] };

    case SEARCH_GROUPS:
      return {
        ...state,
        foundGroups: state.groups.filter(group =>
          group?.chatData?.chat_name?.toLowerCase()?.includes(data.toLowerCase())
        ),
      };

    case CLEAR_FOUND_GROUPS:
      return { ...state, foundGroups: [] };

    case CLEAR_PERSONAL_GROUPS:
      return { ...state, chatUsers: [] };

    case DELETE_CHAT_GROUP:
      if (state.selectedUser?.chatId === data.chatId) {
        return {
          ...state,
          selectedUser: null,
          groups: state.groups.filter(group => group?.chatData?.chat_id !== data?.chatId),
        };
      } else {
        return {
          ...state,
          groups: state.groups.filter(group => group?.chatData?.chat_id !== data?.chatId),
        };
      }

    case DELETE_CHAT_GROUP_USER_SUCCESS:
      return {
        ...state,
        chatUsers: state.chatUsers.filter(user => user.id !== response.userId),
      };

    case UPDATE_CHAT_GROUP:
      groupIndex = findGroup(data.chat_id, newGroupsList);
      if (groupIndex !== -1) {
        newGroupsList[groupIndex].chatData = data;
      }
      if (state.selectedUser.chatId === data.chat_id) {
        state.selectedUser.chatName = data.chat_name;
        state.selectedUser.photo = data.chat_photo_uuid;
      }

      return { ...state, selectedUser: state.selectedUser };

    default:
      return state;
  }
};
