// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-EventCost-styles-module__ql-picker-eNI2jC.user-src-components-EventCost-styles-module__ql-font-FKgi8F .user-src-components-EventCost-styles-module__ql-picker-label-wMU7c7[data-value="Montserrat"]:before, .user-src-components-EventCost-styles-module__ql-picker-eNI2jC.user-src-components-EventCost-styles-module__ql-font-FKgi8F .user-src-components-EventCost-styles-module__ql-picker-item-GlB1DI[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-EventCost-styles-module__ql-font-Montserrat-tjkGQe {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-EventCost-styles-module__eventCost-badge-AnD4xP {
  color: #fff;
  text-align: right;
  background-color: #4bbd5c;
  border: 1px solid #4bbd5c;
  border-radius: 16px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/EventCost/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.eventCost-badge {\n  background-color: #4bbd5c;\n  color: #fff;\n  border-radius: 16px;\n  border: 1px solid #4bbd5c;\n  font-weight: 500;\n  font-size: 14px;\n  padding-left: 10px;\n  padding-right: 10px;\n  align-items: center;\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-EventCost-styles-module__ql-picker-eNI2jC`,
	"qlPicker": `user-src-components-EventCost-styles-module__ql-picker-eNI2jC`,
	"ql-font": `user-src-components-EventCost-styles-module__ql-font-FKgi8F`,
	"qlFont": `user-src-components-EventCost-styles-module__ql-font-FKgi8F`,
	"ql-picker-label": `user-src-components-EventCost-styles-module__ql-picker-label-wMU7c7`,
	"qlPickerLabel": `user-src-components-EventCost-styles-module__ql-picker-label-wMU7c7`,
	"ql-picker-item": `user-src-components-EventCost-styles-module__ql-picker-item-GlB1DI`,
	"qlPickerItem": `user-src-components-EventCost-styles-module__ql-picker-item-GlB1DI`,
	"ql-font-Montserrat": `user-src-components-EventCost-styles-module__ql-font-Montserrat-tjkGQe`,
	"qlFontMontserrat": `user-src-components-EventCost-styles-module__ql-font-Montserrat-tjkGQe`,
	"eventCost-badge": `user-src-components-EventCost-styles-module__eventCost-badge-AnD4xP`,
	"eventCostBadge": `user-src-components-EventCost-styles-module__eventCost-badge-AnD4xP`
};
export default ___CSS_LOADER_EXPORT___;
