/* eslint-disable */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export class MaterialCost extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    topic: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      cost: 0,
      costMin: 0,
      currencyCode: '',
    };
  }

  componentDidMount() {
    this.getCost();
  }

  getCost() {
    const { topic, currentUser } = this.props;
    let defaultCurrency = {
      id: '',
      code: '',
      name: '',
      nominal: 1,
      value: 1,
    };

    let convertedCost = 0;
    let convertedCostMin = 0;

    // Получаем текущую валюты компании
    if (
      currentUser &&
      currentUser.currencies &&
      Array.isArray(currentUser.currencies) &&
      currentUser.domainCompany &&
      currentUser.domainCompany.currencyId
    ) {
      const { currencies } = currentUser;
      const { currencyId } = currentUser.domainCompany;

      if (typeof currencyId === 'number') {
        currencies.map(currency => {
          if (currency.id === currencyId) {
            defaultCurrency = currency;
            this.setState({ currencyCode: currency?.code });
          }
        });
      }
    }

    if (topic?.cost) {
      if (defaultCurrency.nominal > 0) {
        convertedCost = topic.cost / defaultCurrency.nominal;
      }
    }

    if (topic?.costMin) {
      if (defaultCurrency.nominal > 0) {
        convertedCostMin = topic.costMin / defaultCurrency.nominal;
      }
    }

    // Это глобальный материал, требуется ли конвертация валюты ?
    if (topic && topic.global && topic.global === true) {
      if (defaultCurrency.value > 0) {
        convertedCost = (topic.cost / defaultCurrency.value).toFixed(2);
        convertedCostMin = (topic.costMin / defaultCurrency.value).toFixed(2);
      }
    }

    this.setState({ cost: convertedCost, costMin: convertedCostMin });
  }

  render() {
    const { cost, costMin, currencyCode } = this.state;

    const toCost = Intl.NumberFormat('ru-RU', {
      style: 'currency',
      maximumSignificantDigits: 21,
      currency: currencyCode || 'RUB',
    }).format(cost);
    const formCost = Intl.NumberFormat('ru-RU', {
      style: 'currency',
      maximumSignificantDigits: 21,
      currency: currencyCode || 'RUB',
    }).format(costMin);

    if (costMin > 0 && cost < 1000000000) {
      return this.props.t('rangeCost', { cost: toCost, costMin: formCost });
    } else {
      return this.props.t('to', { cost: toCost });
    }
  }
}

export default withTranslation('materialCost')(MaterialCost);
