/* eslint-disable */
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Col, Row } from 'antd';

import FilterSelects from './FilterSelects';
import { FilterTag } from './FilterTag';

import UtilsPoka from '@shared/constants/showPoka';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TopicFilter.module.scss';

import { KAMPUS_DEV_DOMAIN } from '@/constants/global';
import { TOPIC_TYPE, TOPIC_TYPE_COLLECTION } from '@/constants/topic-types';

const arrDomains = [
  KAMPUS_DEV_DOMAIN,
  'polyus.kampus.team',
  'gazprommedia.kampus.team',
  'tandpdouble.kampus.team',
  'tandp.kampus.team',
  `gc.cloud-stage.${KAMPUS_DEV_DOMAIN}`,
  `cloud-stage.${KAMPUS_DEV_DOMAIN}`,
  'severstal.kampus.team',
  // 'td.kampus.team',
  'digitalenergy.kampus.team',
  // 'corphn.kampus.team',
  'kam-1810.kampus.local',
];

class TopicFilter extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    domainCompany: PropTypes.object,
    filter: PropTypes.object,
    filterBlock: PropTypes.object,
    currentUser: PropTypes.object,
    myCollection: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onSortReset: PropTypes.func,
    // topicCategories: PropTypes.array,
    topicLevel: PropTypes.array,
    topicTypes: PropTypes.array,
    topicLanguages: PropTypes.array,
    showAllCategory: PropTypes.bool,
    hasEvent: PropTypes.bool,
    positions: PropTypes.array,
    jobLevels: PropTypes.array,
    topicCategoryCompetencies: PropTypes.array,
  };

  static defaultProps = {
    filter: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tagsModalVisible: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target || event;
    let extraParams = {};

    if (!this.props.myCollection) {
      if (name === 'typeId') {
        if (value === 2) {
          extraParams = {
            sort: 'state,asc&sort=start,asc',
            actual: 'true',
            excludedTopicType: undefined,
          };
        } else if (value === 4) {
          extraParams = {
            excludedTopicType: '38',
            topicTypeId: '',
          };
        } else if (value === 5) {
          extraParams = {
            topicTypeId: '38',
            excludedTopicType: undefined,
          };
        } else if (!value) {
          extraParams = {
            topicTypeLabelId: '',
            topicTypeId: '',
            free: undefined,
            hasBlock: undefined,
            topicSubTypeId: '',
            excludedTopicType: undefined,
          };
        } else {
          this.props.onSortReset();
        }
      }
    } else if (name === 'typeId') {
      if (value === 2) {
        extraParams = {
          name: 'sort',
          value: 'topic.nearEvent,asc',
          actual: true,
        };
      } else {
        this.props.onSortReset();
      }
    }

    this.props.onChange({ ...extraParams, [name]: value });
  };

  handleRecommendChange = () => {
    const {
      filter: { trajectory },
    } = this.props;

    this.props.onChange({
      name: 'trajectory',
      value: trajectory === 'true' ? 'false' : 'true',
    });
  };

  clearFilterItem = name => {
    this.props.onChange({ name, value: '' });
    this.props.onSortReset();
  };

  isPolusCompany = () => {
    const { domainCompany } = this.props;

    if (domainCompany?.domain && arrDomains.includes(domainCompany.domain)) {
      return true;
    }

    return false;
  };

  render() {
    const {
      // topicCategories,
      topicLevel,
      className,
      onClear,
      filter,
      domainCompany,
      currentUser: { member, trajectoryEnable },
      myCollection,
      topicLanguages,
      t,
      hasEvent,
      filterPositions,
      jobLevels,
      optTopicCompCat,
    } = this.props;

    const {
      topicLevelId,
      languageId,
      // topicCategoryName,
      topicCompetenceCategory,
    } = filter;

    const typeId = hasEvent ? 2 : filter.typeId;

    const topicTypeArr = TOPIC_TYPE(t)
      .map(t => {
        return { id: t.id, params: t.params, name: t.name };
      })
      .filter(item => {
        // TODO: посмотреть как сделать это чище, сейчас много завязано на изначальном поведении в ТЗ
        if (item.id !== 2) {
          //  что бы скрыть События из выпадашки задача 1851
          return item;
        }
      });

    // удалить после слития всей поки в прод
    const filteredtopicTypeArr = topicTypeArr.filter(item => {
      if (
        item.id === 5 &&
        item.params === 'typeId' &&
        UtilsPoka.showPokaElems(item.name, this.props.currentUser.domainCompany.domain, this.props.currentUser?.email)
      ) {
        return null;
      }
      return item;
    });

    const filterBlk = myCollection
      ? TOPIC_TYPE_COLLECTION(t).filter(item => {
          if (item.id === typeId) {
            return item;
          }
        })
      : TOPIC_TYPE(t).filter(item => {
          if (item.id === typeId) {
            return item;
          }
        });

    const isRecommendShow =
      domainCompany.hasUserTrajectory && trajectoryEnable && member && member.count > 0 && !myCollection;

    const filters = () => {
      const filters = [
        // {
        //   name: 'topicCategoryName',
        //   value: topicCategoryName,
        //   options: topicCategories?.map(item => ({ name: item.name, id: item.name })),
        //   label: t('rate'),
        // },
        {
          name: 'topicCompetenceCategory',
          value: topicCompetenceCategory,
          options: optTopicCompCat,
          label: t('topicompCat'),
        },
        {
          name: 'jobFamilyId',
          value: filter.jobFamilyId,
          options: filterPositions,
          label: t('position'),
        },
        filter.typeId !== 5
          ? {
              name: 'topicLevelId',
              value: topicLevelId,
              options: topicLevel,
              label: t('level'),
              short: isRecommendShow,
            }
          : {
              name: 'pokaCategories',
              options: filter.filterPokaCategory,
              label: t('pokaCategories'),
            },
      ];

      if (topicLanguages.length > 1) {
        filters.push({
          name: 'languageId',
          value: languageId,
          options: topicLanguages,
          label: t('language'),
          short: isRecommendShow,
        });
      }

      filters.push(
        {
          name: 'topicMandatories',
          value: filter.topicMandatories,
          options: filter.filterMandatories,
          label: t('mandatory'),
        },
        { name: 'jobLevelId', value: filter.jobLevelId, options: jobLevels, label: t('jobLevel') }
      );

      if (filter.typeId === 5) {
        filters.push({
          name: 'pokaSites',
          options: filter.filterPokaSites,
          label: t('pokaSites'),
        });
      }

      if (!hasEvent) {
        filters.unshift({
          name: 'typeId',
          value: typeId,
          options: filteredtopicTypeArr,
          // options: topicTypeArr, // удалить после слития всей поки в прод
          label: t('type'),
          short: isRecommendShow,
        });
      }

      return filters;
    };

    return (
      <div className={classNames(css.TopicFilter, className)}>
        <div className={classNames(css['TopicFilter-collapse-container'])}>
          <input type='checkbox' id={css.TopicFilterCollapse} />
          <label className={css['TopicFilter-collapse']} htmlFor={css.TopicFilterCollapse}>
            <span>{t('filters')}</span>
          </label>

          <Row className={css['TopicFilter-row']}>
            <Col className={css['TopicFilter-col']} span={24}>
              <Row className={css['TopicFilter-row-container']} type='flex' align='middle'>
                <Col span={24} className={css['TopicFilter-inputs']}>
                  <FilterSelects
                    filters={filters()}
                    handleChange={this.handleChange}
                    clearFilterItem={this.clearFilterItem}
                    showAllCategory={this.props.showAllCategory}
                  />
                </Col>
                <Col className={css['TopicFilter-label']}>
                  <div className={css['TopicFilter-label-clear']} onClick={onClear}>
                    {t('clear')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} className={css['TopicFilter-filter']}>
                  {typeId ? (
                    <FilterTag onChange={this.props.onChange} filter={filter} filterBlk={filterBlk} />
                  ) : (
                    <Row
                      className={css['TopicFilter-row-container']}
                      type='flex'
                      justify='space-between'
                      align='middle'
                      style={{ flex: 1 }}
                    >
                      <Col flex={1}>
                        <div className={css['TopicFilter-filter-label']}>{t('description')}</div>
                      </Col>
                      {/* <Col>
                        <Button
                          onClick={this.props.openModalSelectCompetence}
                          size={'large'}
                          type={'primary'}
                          id='choiceCompetence'
                          className={css['TopicFilter-filter-button']}
                        >
                          {useIsMobileClass() ? t('choiceCompetenceShort') : t('choiceCompetence')}
                        </Button>
                      </Col> */}
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation(['topicFilter', 'constants'])(TopicFilter);
