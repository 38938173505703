import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getFiles } from '@/store/files/actions';
import {
  clearNewsId,
  createComment,
  deleteComment,
  getComments,
  getCountComments,
  getNewsById,
  recommendNews,
  restoreComment,
  setRate,
  setViewedNews,
  updateComment,
} from '@/store/news/actions';
import { getUsers } from '@/store/users/actions';

import { selectNews } from '@/store/news/selectors';

import { Layout, notification, Rate, Spin, Tabs, Typography } from 'antd';
import ArticleFrame from '@/components/ArticleFrame';
import Comments from '@/components/Comments';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import Tag from '@/components/Tag';
import UsersModal from '@/components/UsersModal/UsersModal';
import { LoadingOutlined } from '@ant-design/icons';
import { FiCornerUpLeft, FiThumbsUp } from 'react-icons/fi';

import RateModal from './parts/RateModal';

import useImage from '@shared/hooks/useImage';

import Utils from '@/Utils';

import styles from './NewsId.module.scss';

const { Content } = Layout;
const { Text, Title } = Typography;

const NewsId = () => {
  const [visibleRecommendedModal, setVisibleRecommendedModal] = useState(false);
  const [visibleRateModal, setVisibleRateModal] = useState(false);

  const { id } = useParams();
  const { t } = useTranslation('newsId');
  const { newsData, countComments, commentsList, isLoading, pagination } = useSelector(selectNews);

  const image = useImage(newsData?.photoUuid);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearNewsId()); // чистим стор при размонтировании
    };
  }, []);

  useEffect(() => {
    dispatch(
      getNewsById(id, res => {
        if (!image && res.photoUuid) {
          dispatch(getFiles(res.photoUuid));
        }
      })
    );
    dispatch(getCountComments(id));
  }, [id]);

  useEffect(() => {
    if (!newsData?.viewed && newsData?.id) {
      dispatch(setViewedNews(newsData.id, { viewed: false }));
    }
  }, [newsData]);

  useEffect(() => {
    if (visibleRecommendedModal) {
      dispatch(
        getUsers(
          {
            newsId: id,
            status: 'ACTIVE',
            size: 10,
          },
          () => {},
          () => {},
          false,
          true
        )
      );
    }
  }, [visibleRecommendedModal]);

  const actionsForComments = useMemo(() => {
    return {
      onCreate: text => dispatch(createComment({ newsId: id, text })),
      onRead: page => dispatch(getComments(id, page)),
      onUpdate: (commentId, text) => dispatch(updateComment(commentId, { newsId: id, text })),
      onDelete: commentId => dispatch(deleteComment(commentId)),
      onRestore: commentId => dispatch(restoreComment(commentId)),
    };
  }, []);

  const handleRecommended = userIds => {
    setVisibleRecommendedModal(false);
    dispatch(
      recommendNews(
        id,
        userIds,
        () => {
          notification.success({
            message: t('great'),
            description: t('notificationSuccess'),
          });
        },
        () => {
          notification.error({
            message: t('error'),
            description: t('notificationError'),
          });
        }
      )
    );
  };

  const handleRate = value => {
    if (value) {
      const body = { rate: value };
      dispatch(setRate(body, id, () => setVisibleRateModal(false)));
    }
  };

  const itemsTabs = [
    {
      key: '1',
      label: t('description'),
      children: (
        <>
          {newsData?.description && <ArticleFrame body={newsData?.description} />}
          {newsData?.link && (
            <p className={styles.news__footer_link}>
              {t('linkToOther')}:
              <a href={newsData?.link} target='_blank' rel='noreferrer' className={styles.news__link}>
                {t('further')}
              </a>
            </p>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: t('comments', { countComments }),
      children: (
        <Comments
          actions={actionsForComments}
          isLoading={isLoading?.getComments}
          totalElements={pagination.commentsList.total}
          commentsList={commentsList}
          placeholder={t('commentsPlaceholder')}
        />
      ),
    },
  ];

  return (
    <Content className={styles.news}>
      <div className={styles.news__content}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
              }}
              spin
            />
          }
          spinning={isLoading.getNewsById}
        >
          <div className={styles.news__header}>
            <div className={styles.news__element}>
              <Text className={styles.news__title}>{t('author')}</Text>
              <Link to={`/collegues/${newsData?.authorDto.id}`} className={styles.news__link}>
                {newsData?.authorDto.name}
              </Link>
            </div>
            {newsData?.link && (
              <div className={styles.news__element}>
                <Text className={styles.news__title}>{t('linkToOther')}</Text>
                <a href={newsData?.link} target='_blank' rel='noreferrer' className={styles.news__link}>
                  {Utils.trString(newsData?.link, 20)}
                </a>
              </div>
            )}
            <div className={styles.news__element}>
              <Text className={styles.news__title}>{t('date')}</Text>
              <Text className={styles.news__text}>{newsData?.createdDate}</Text>
            </div>
            <div className={styles.news__element}>
              <Text className={styles.news__title}>{t('rate')}</Text>
              <Rate className={styles.news__rate} disabled value={newsData?.rate} />
            </div>
          </div>
          <div className={styles.news__main}>
            <Title level={3} className={styles.news__name}>
              {newsData?.title}
            </Title>
          </div>
          {newsData?.photoUuid &&
            (image ? (
              <img className={styles.news__image} src={image} alt='' />
            ) : (
              <div className={styles.news__spin_image}>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 48,
                      }}
                      spin
                    />
                  }
                />
              </div>
            ))}
          <div className={styles.news__additional}>
            <div className={styles.news__link} onClick={() => setVisibleRecommendedModal(true)}>
              <FiCornerUpLeft />
              <Text>{t('recommend')}</Text>
            </div>
            {!newsData?.hasRated && (
              <div className={styles.news__link} onClick={() => setVisibleRateModal(true)}>
                <FiThumbsUp />
                <Text>{t('setRate')}</Text>
              </div>
            )}
          </div>
          {!!newsData?.tagNames && (
            <div className={styles.news__tags}>
              {newsData.tagNames.map((tag, index) => (
                <Tag key={index} tag={tag}>
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}
          <Tabs items={itemsTabs}></Tabs>
          <ModalCloseIcon onClick={() => document.location.replace('/news')} className={styles.news__back} />
        </Spin>
      </div>

      <UsersModal
        newsId={id}
        recommendTopic
        getUsers={(params, onSuccess, onError, searching, isStartLoad) =>
          dispatch(getUsers(params, onSuccess, onError, searching, isStartLoad))
        }
        onOk={handleRecommended}
        visible={visibleRecommendedModal}
        onCancel={() => setVisibleRecommendedModal(false)}
        placeholder='recommendColleagueNews'
      />

      {visibleRateModal && (
        <RateModal onCancel={() => setVisibleRateModal(false)} onOk={handleRate} visible={visibleRateModal} />
      )}
    </Content>
  );
};

export default NewsId;
