/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getStudyPlan, getStudyPlanStat } from '@/store/study-plan/actions';
import { getUserById, loginRedirect } from '@/store/users/actions';

import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Tooltip } from 'antd';
import { Avatar } from '@/components';

import DoneSvg from '../MyProfile/profile-done.svg';
import PlanSvg from '../MyProfile/profile-plan.svg';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ColleguesProfile.module.scss';

import { USER_SIGN_IN_ANOTHER_USER } from '@/constants/permissions';
import locked from '@/assets/images/lock.svg';

// TODO: Доработать типизацию

const ColleguesProfileHeader = ({ user, domainCompany, getStudyPlanStat, statAnother, loginRedirect, currentUser }) => {
  const { t } = useTranslation('colleaguesProfile');
  const { status, firstName = '', lastName = '', function: userFunction, position, department } = user;

  const authorities = currentUser.authorities;
  const isSameUser = currentUser.id === user.id;

  useEffect(() => {
    if (user.id) {
      getStudyPlanStat(true, user.id);
    }
  }, [user.id]);

  const loginAsUser = () => {
    loginRedirect(user.login, res => {
      localStorage.setItem('accessToken', res.accessToken);
      localStorage.setItem('authorizationToken', res.accessToken);
      localStorage.setItem('refreshToken', res.refreshToken);
      document.location.reload();
    });
  };

  const userInfo = useMemo(
    () => (
      <>
        {domainCompany && domainCompany.hasUserPosition && position && (
          <div className={css['user-info__position']}>
            {domainCompany.positionSingleNameImenit}: {position}
          </div>
        )}
        {domainCompany && domainCompany.hasUserDepartment && department && (
          <div className={css['user-info__position']}>
            {domainCompany.departmentSingleNameImenit}: {department}
          </div>
        )}
        {domainCompany && domainCompany.hasUserFunction && userFunction && (
          <div className={css['user-info__position']}>
            {domainCompany.functionSingleNameImenit}: {userFunction}
          </div>
        )}
        {authorities.includes(USER_SIGN_IN_ANOTHER_USER) && !isSameUser && (
          <div className={css['login-as-user']} onClick={loginAsUser}>
            {t('logInAsUser')}
          </div>
        )}
      </>
    ),
    [domainCompany, position, department, userFunction, authorities, isSameUser]
  );

  return (
    <div className={css['app-profline__header']}>
      <div className={classNames(css['user-info'], css['user-info-desktop'])}>
        <div className={css['user-info__ava']}>
          <div style={{ opacity: status === 'DELETED' ? 0.5 : 1 }}>
            <Avatar user={user} />
          </div>
          {status === 'DELETED' && (
            <Tooltip title={t('userDeleted')}>
              <div className={css['del-user']}>
                <img src={locked} alt='' />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={css['user-info__text']}>
          <h4 className={css['user-info__name']}>{`${firstName} ${lastName}`}</h4>
          {userInfo}
        </div>
      </div>

      <div className={css['user-info-mobile']}>
        <div className={css['user-info']}>
          <div className={css['user-info__ava']}>
            <Avatar user={user} />
          </div>
          <div className={css['user-info__text']}>
            <div className={css['user-info__text__header']}>
              <h4 className={css['user-info__name']}>
                <p>{`${user.firstName}`}</p>
                <p>{`${user.lastName}`}</p>
              </h4>
            </div>
          </div>
        </div>
        <div>{userInfo}</div>
      </div>

      <div className={css['learning-progress']}>
        <div className={css['learning-progress__item']}>
          <img src={DoneSvg} alt='' />
          {statAnother.finished >= 0 && `${statAnother.finished} ${t('completed')}`}
        </div>
        <div className={css['learning-progress__item']}>
          <img src={PlanSvg} alt='' />
          {statAnother.total >= 0 && `${statAnother.total - statAnother.finished} ${t('planned')}`}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createSelector(
  [studyPlanSelect, userTopicsSelect, loginSelect, usersSelect],
  (studyPlan, userTopics, login, users) => ({
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    studyPlanStat: studyPlan.statAnother || {},
    studyPlanTotal: studyPlan.studyAnotherPlanTotal,
    statAnother: studyPlan.statAnother || {},
    ownTotal: userTopics.ownAnotherTotal,
    externalLogin: login.externalLogin,
    currentUser: users.currentUser,
  })
);

const mapActionsToProps = {
  getUserById,
  getStudyPlanStat,
  getStudyPlan,
  loginRedirect,
};

ColleguesProfileHeader.propTypes = {
  domainCompany: PropTypes.any,
  externalLogin: PropTypes.string,
  getPhoto: PropTypes.func,
  getStudyPlanStat: PropTypes.any,
  match: PropTypes.any,
  reqAvatar: PropTypes.any,
  score: PropTypes.number,
  setAvaToCache: PropTypes.func,
  studyPlanStat: PropTypes.any,
  user: PropTypes.any,
  statAnother: PropTypes.any,
  loginRedirect: PropTypes.func,
  currentUser: PropTypes.any,
};

export default connect(mapStateToProps, mapActionsToProps)(ColleguesProfileHeader);
