import {
  GET_EXECUTIVE_TOPIC_INFO_FAIL,
  GET_EXECUTIVE_TOPIC_INFO_START,
  GET_EXECUTIVE_TOPIC_INFO_SUCCESS,
} from './constance';

const initialState = {
  loading: false,
  topicInfo: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_EXECUTIVE_TOPIC_INFO_START:
      return { ...state, loading: true };
    case GET_EXECUTIVE_TOPIC_INFO_SUCCESS:
      return { ...state, loading: false, topicInfo: response };
    case GET_EXECUTIVE_TOPIC_INFO_FAIL:
      return { ...state, loading: false, topicInfo: {} };
    default:
      return state;
  }
};
