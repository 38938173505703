// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-Shop-Shop-module__ql-picker-BnsEiG.user-src-pages-Shop-Shop-module__ql-font-ieFXb_ .user-src-pages-Shop-Shop-module__ql-picker-label-Mk8ze2[data-value="Montserrat"]:before, .user-src-pages-Shop-Shop-module__ql-picker-BnsEiG.user-src-pages-Shop-Shop-module__ql-font-ieFXb_ .user-src-pages-Shop-Shop-module__ql-picker-item-SrOstA[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-Shop-Shop-module__ql-font-Montserrat-KO0iKK {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-Shop-Shop-module__Shop-header-O6Lq3p {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1200px) {
  .user-src-pages-Shop-Shop-module__Shop-header-O6Lq3p {
    display: block;
  }
}

.user-src-pages-Shop-Shop-module__Shop-header-title-wv7jpp > h1 {
  font-weight: 800;
}

.user-src-pages-Shop-Shop-module__Shop-header-search-IjyxwT {
  width: 600px;
  margin-top: 6px;
}

@media (max-width: 1200px) {
  .user-src-pages-Shop-Shop-module__Shop-header-search-IjyxwT {
    width: 100%;
    margin-bottom: 32px;
  }
}

.user-src-pages-Shop-Shop-module__Shop-container-Fi73_V {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  display: flex;
}

.user-src-pages-Shop-Shop-module__Shop-container-items-7KTSvS {
  flex-flow: wrap;
  justify-content: center;
  gap: 32px;
  width: 100%;
  display: flex;
}

.user-src-pages-Shop-Shop-module__Shop-container-items-coin-SDdQPY {
  width: 19px;
  height: 12px;
}

.user-src-pages-Shop-Shop-module__Shop-container-pagination-ro9bfH {
  justify-content: center;
  margin-top: 40px;
  display: flex;
}

.user-src-pages-Shop-Shop-module__Shop-container-item-button-_x4ilp {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/Shop/Shop.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,8BAA8B;EAC9B,aAAa;AACf;;AAMA;EACE;IAHE,cAAc;EAChB;AACF;;AAKA;EAFE,gBAAgB;AAClB;;AAIA;EADE,YAAY;EACZ,eAAe;AACjB;;AAGA;EACE;IAAE,WAAW;IACX,mBAAmB;EACrB;AACF;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AACA;EAEE,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,WAAW;EACX,aAAa;AACf;;AAAA;EAGE,WAAW;EACX,YAAY;AACd;;AADA;EAIE,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;AACf;;AAFA;EAKE,WAAW;AACb","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Shop-header {\n  display: flex;\n  justify-content: space-between;\n}\n@media (width <= 1200px) {\n  .Shop-header {\n    display: block;\n  }\n}\n.Shop-header-title > h1 {\n  font-weight: 800;\n}\n.Shop-header-search {\n  width: 600px;\n  margin-top: 6px;\n}\n@media (width <= 1200px) {\n  .Shop-header-search {\n    width: 100%;\n    margin-bottom: 32px;\n  }\n}\n.Shop-container {\n  min-height: 30vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.Shop-container-items {\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  gap: 32px;\n  width: 100%;\n}\n.Shop-container-items-coin {\n  width: 19px;\n  height: 12px;\n}\n.Shop-container-pagination {\n  display: flex;\n  justify-content: center;\n  margin-top: 40px;\n}\n.Shop-container-item-button {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-Shop-Shop-module__ql-picker-BnsEiG`,
	"qlPicker": `user-src-pages-Shop-Shop-module__ql-picker-BnsEiG`,
	"ql-font": `user-src-pages-Shop-Shop-module__ql-font-ieFXb_`,
	"qlFont": `user-src-pages-Shop-Shop-module__ql-font-ieFXb_`,
	"ql-picker-label": `user-src-pages-Shop-Shop-module__ql-picker-label-Mk8ze2`,
	"qlPickerLabel": `user-src-pages-Shop-Shop-module__ql-picker-label-Mk8ze2`,
	"ql-picker-item": `user-src-pages-Shop-Shop-module__ql-picker-item-SrOstA`,
	"qlPickerItem": `user-src-pages-Shop-Shop-module__ql-picker-item-SrOstA`,
	"ql-font-Montserrat": `user-src-pages-Shop-Shop-module__ql-font-Montserrat-KO0iKK`,
	"qlFontMontserrat": `user-src-pages-Shop-Shop-module__ql-font-Montserrat-KO0iKK`,
	"Shop-header": `user-src-pages-Shop-Shop-module__Shop-header-O6Lq3p`,
	"shopHeader": `user-src-pages-Shop-Shop-module__Shop-header-O6Lq3p`,
	"Shop-header-title": `user-src-pages-Shop-Shop-module__Shop-header-title-wv7jpp`,
	"shopHeaderTitle": `user-src-pages-Shop-Shop-module__Shop-header-title-wv7jpp`,
	"Shop-header-search": `user-src-pages-Shop-Shop-module__Shop-header-search-IjyxwT`,
	"shopHeaderSearch": `user-src-pages-Shop-Shop-module__Shop-header-search-IjyxwT`,
	"Shop-container": `user-src-pages-Shop-Shop-module__Shop-container-Fi73_V`,
	"shopContainer": `user-src-pages-Shop-Shop-module__Shop-container-Fi73_V`,
	"Shop-container-items": `user-src-pages-Shop-Shop-module__Shop-container-items-7KTSvS`,
	"shopContainerItems": `user-src-pages-Shop-Shop-module__Shop-container-items-7KTSvS`,
	"Shop-container-items-coin": `user-src-pages-Shop-Shop-module__Shop-container-items-coin-SDdQPY`,
	"shopContainerItemsCoin": `user-src-pages-Shop-Shop-module__Shop-container-items-coin-SDdQPY`,
	"Shop-container-pagination": `user-src-pages-Shop-Shop-module__Shop-container-pagination-ro9bfH`,
	"shopContainerPagination": `user-src-pages-Shop-Shop-module__Shop-container-pagination-ro9bfH`,
	"Shop-container-item-button": `user-src-pages-Shop-Shop-module__Shop-container-item-button-_x4ilp`,
	"shopContainerItemButton": `user-src-pages-Shop-Shop-module__Shop-container-item-button-_x4ilp`
};
export default ___CSS_LOADER_EXPORT___;
