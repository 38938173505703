/* eslint-disable */
import { GET_ANOTHER_USER_BY_ID_START } from '../users/actions';
import {
  CHANGE_USER_TOPIC_FILTER,
  CHECK_FAVORITE_TOPICS_FAIL,
  CHECK_FAVORITE_TOPICS_START,
  CHECK_FAVORITE_TOPICS_SUCCESS,
  CLEAR_USER_TOPIC_FILTER,
  CREATE_PROFILE_STUDY_PLAN_START,
  CREATE_PROFILE_STUDY_PLAN_SUCCESS,
  DELETE_PROFILE_USER_TOPIC_FAIL,
  DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_START,
  DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_SUCCESS,
  DELETE_PROFILE_USER_TOPIC_RECOMMENDED_FAIL,
  DELETE_PROFILE_USER_TOPIC_RECOMMENDED_START,
  DELETE_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS,
  DELETE_PROFILE_USER_TOPIC_SUCCESS,
  DELETE_USER_TOPIC_FAIL,
  DELETE_USER_TOPIC_START,
  DELETE_USER_TOPIC_SUCCESS,
  GET_ANOTHER_MY_RECOMMENDED_TOPICS_START,
  GET_ANOTHER_MY_RECOMMENDED_TOPICS_SUCCESS,
  GET_ANOTHER_RECOMMENDED_NEWS_FAIL,
  GET_ANOTHER_RECOMMENDED_NEWS_START,
  GET_ANOTHER_RECOMMENDED_NEWS_SUCCESS,
  GET_ANOTHER_RECOMMENDED_TOPICS_FAIL,
  GET_ANOTHER_RECOMMENDED_TOPICS_START,
  GET_ANOTHER_RECOMMENDED_TOPICS_SUCCESS,
  GET_ANOTHER_USER_TOPICS_FAIL,
  GET_ANOTHER_USER_TOPICS_START,
  GET_ANOTHER_USER_TOPICS_SUCCESS,
  GET_MY_RECOMMENDATIONS_TOPICS_FAIL,
  GET_MY_RECOMMENDATIONS_TOPICS_START,
  GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS,
  GET_MY_RECOMMENDED_NEWS_FAIL,
  GET_MY_RECOMMENDED_NEWS_START,
  GET_MY_RECOMMENDED_NEWS_SUCCESS,
  GET_RECOMMENDED_TOPICS_FAIL,
  GET_RECOMMENDED_TOPICS_START,
  GET_RECOMMENDED_TOPICS_SUCCESS,
  GET_STATISTIC_TOPICS_FAIL,
  GET_STATISTIC_TOPICS_START,
  GET_STATISTIC_TOPICS_SUCCESS,
  GET_USER_TOPICS_FAIL,
  GET_USER_TOPICS_START,
  GET_USER_TOPICS_SUCCESS,
  GET_USER_TOPICS_VALUE_START,
  GET_USER_TOPICS_VALUE_SUCCESS,
  POST_MULTIPLE_USER_TOPICS_FAIL,
  POST_MULTIPLE_USER_TOPICS_START,
  POST_MULTIPLE_USER_TOPICS_SUCCESS,
  POST_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS,
  POST_USER_TOPICS_FAIL,
  POST_USER_TOPICS_START,
  POST_USER_TOPICS_SUCCESS,
  PUT_TOPIC_ALLRECOMMEND_FAIL,
  PUT_TOPIC_ALLRECOMMEND_START,
  PUT_TOPIC_ALLRECOMMEND_SUCCESS,
  SET_AVA_TO_CACHE_SUCCESS,
} from './actions';

const initialState = {
  own: null,
  ownTotal: null,
  ownPageNumber: null,
  ownPageSize: null,
  ownAnother: null,
  ownAnotherTotal: null,
  ownAnotherPageNumber: null,
  ownAnotherPageSize: null,
  statistic: null,
  recommended: {
    elements: [],
    hasMore: true,
  },
  myRecommendations: {
    elements: [],
    hasMore: true,
  },
  recommendedTotal: null,
  recommendedPageNumber: null,
  recommendedPageSize: null,
  recommendedAnother: {
    elements: [],
    hasMore: true,
  },
  new: 0,
  recommendedAnotherTotal: null,
  recommendedAnotherPageNumber: null,
  recommendedAnotherPageSize: null,
  anotherPlanMaterialInFav: null,
  favoriteTopics: [],
  isLoading: false,
  isRecommendedLoading: false,
  isMyRecommendationsLoading: false,
  error: false,
  topicFilter: {},
  myRecommendationsAnother: {
    elements: [],
    hasMore: true,
    page: 0,
  },
  myRecommendationsAnotherTotal: 0,
  myRecommendationsAnotherPageSize: 0,
  cachedFiles: {},

  myRecommendationsNewsIsLoading: false,
  myRecommendationsNews: [],
  myRecommendationsNewsPagination: {
    total: 0,
    pageNumber: 0,
    pageSize: 0,
  },

  myAnotherRecommendationsNewsIsLoading: false,
  myAnotherRecommendationsNews: [],
  myAnotherRecommendationsNewsPagination: {
    total: 0,
    pageNumber: 0,
    pageSize: 0,
  },
};

export default (state = initialState, { type, response, name, value, headers, action }) => {
  switch (type) {
    case GET_USER_TOPICS_START:
    case PUT_TOPIC_ALLRECOMMEND_START:
      return { ...state, isLoading: true, error: false };

    case GET_USER_TOPICS_SUCCESS:
      return {
        ...state,
        own: response,
        ownTotal: Number(headers.get('X-Total-Elements')) || 0,
        ownPageSize: Number(headers.get('X-Page-Size')) || 0,
        ownPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_USER_TOPICS_VALUE_START:
      return { ...state, new: 0, isLoading: true, error: false };
    case GET_USER_TOPICS_VALUE_SUCCESS:
      const newElems = response.filter(item => item.topic.viewers.viewed === false);
      return { ...state, new: newElems.length, isLoading: false };
    case GET_RECOMMENDED_TOPICS_START:
      return {
        ...state,
        error: false,
        isRecommendedLoading: true,
      };

    case GET_ANOTHER_USER_BY_ID_START:
      return {
        ...state,
        isLoading: false,
        myRecommendationsAnother: {
          elements: [],
          page: 0,
        },
        myRecommendationsAnotherTotal: 0,
        myRecommendationsAnotherPageSize: 0,
        recommended: {
          elements: [],
          page: 0,
        },
        myRecommendations: {
          elements: [],
          page: 0,
        },
        recommendedTotal: null,
        recommendedPageNumber: null,
        recommendedPageSize: null,
        recommendedAnother: {
          elements: [],
        },
        recommendedAnotherTotal: null,
        recommendedAnotherPageNumber: null,
        recommendedAnotherPageSize: null,
      };
    case GET_STATISTIC_TOPICS_START:
    case GET_ANOTHER_MY_RECOMMENDED_TOPICS_START:
      return { ...state, isLoading: true, error: false };
    case GET_MY_RECOMMENDATIONS_TOPICS_START:
      return { ...state, error: false, isMyRecommendationsLoading: true };

    case SET_AVA_TO_CACHE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cachedFiles: { ...state.cachedFiles, [value.id]: value.topicUrl },
        reqAvatar: { ...state.reqAvatar, [value.id]: false },
      };

    case GET_STATISTIC_TOPICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        statistic: response,
      };

    case GET_RECOMMENDED_TOPICS_SUCCESS:
      return {
        ...state,
        recommended: {
          elements: Number(headers.get('X-Page-Number')) === 0 ? response : state.recommended.elements.concat(response),
          hasMore: Number(headers.get('X-Total-Elements')) > state.recommended.elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        recommendedTotal: Number(headers.get('X-Total-Elements')) || 0,
        recommendedPageSize: Number(headers.get('X-Page-Size')) || 0,
        isRecommendedLoading: false,
      };

    case GET_MY_RECOMMENDATIONS_TOPICS_SUCCESS: {
      const elements =
        Number(headers.get('X-Page-Number')) === 0 ? response : state.myRecommendations.elements.concat(response);
      return {
        ...state,
        myRecommendations: {
          elements,
          hasMore: Number(headers.get('X-Total-Elements')) > elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        myRecommendationsTotal: Number(headers.get('X-Total-Elements')) || 0,
        myRecommendationsPageSize: Number(headers.get('X-Page-Size')) || 0,
        isMyRecommendationsLoading: false,
      };
    }

    case GET_MY_RECOMMENDED_NEWS_START: {
      return {
        ...state,
        myRecommendationsNewsIsLoading: true,
        myRecommendationsNews: [...state.myRecommendationsNews],
      };
    }

    case GET_MY_RECOMMENDED_NEWS_SUCCESS: {
      return {
        ...state,
        myRecommendationsNewsIsLoading: false,
        myRecommendationsNews: [...state.myRecommendationsNews, response.content],
      };
    }

    case GET_ANOTHER_RECOMMENDED_NEWS_START: {
      return {
        ...state,
        myRecommendationsNewsIsLoading: true,
        myAnotherRecommendationsNews: [...state.myAnotherRecommendationsNews],
      };
    }

    case GET_ANOTHER_RECOMMENDED_NEWS_SUCCESS:
      return {
        ...state,
        myRecommendationsNewsIsLoading: false,
        myAnotherRecommendationsNews: [...state.myAnotherRecommendationsNews, response.content],
      };

    case GET_ANOTHER_MY_RECOMMENDED_TOPICS_SUCCESS: {
      const elements =
        Number(headers.get('X-Page-Number')) === 0
          ? response
          : state.myRecommendationsAnother.elements.concat(response);
      return {
        ...state,
        myRecommendationsAnother: {
          elements,
          hasMore: Number(headers.get('X-Total-Elements')) > elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        myRecommendationsAnotherTotal: Number(headers.get('X-Total-Elements')) || 0,
        myRecommendationsAnotherPageSize: Number(headers.get('X-Page-Size')) || 0,
        isLoading: false,
      };
    }

    case CHECK_FAVORITE_TOPICS_START:
      return { ...state, isLoading: true };
    case CHECK_FAVORITE_TOPICS_SUCCESS:
      return { ...state, favoriteTopics: response, isLoading: false };
    case CHECK_FAVORITE_TOPICS_FAIL:
      return { ...state, isLoading: false, error: true };
    case GET_ANOTHER_USER_TOPICS_START:
      return {
        ...state,
        ownAnother: null,
        isLoading: true,
        ownAnotherTotal: null,
        ownAnotherPageSize: null,
        ownAnotherPageNumber: null,
        error: false,
      };

    case GET_ANOTHER_USER_TOPICS_SUCCESS:
      return {
        ...state,
        ownAnother: response,
        ownAnotherTotal: Number(headers.get('X-Total-Elements')) || 0,
        ownAnotherPageSize: Number(headers.get('X-Page-Size')) || 0,
        ownAnotherPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_ANOTHER_RECOMMENDED_TOPICS_START:
      return { ...state, isLoading: true, error: false };

    case GET_ANOTHER_RECOMMENDED_TOPICS_SUCCESS:
      if (response.length > 0) {
        return {
          ...state,
          recommendedAnother: {
            elements:
              Number(headers.get('X-Page-Number')) === 0
                ? response
                : [...state.recommendedAnother.elements, ...response],
            hasMore:
              Number(headers.get('X-Page-Number')) === 0
                ? Number(headers.get('X-Total-Elements')) > response.length
                : Number(headers.get('X-Total-Elements')) > state.recommendedAnother.elements.length,
            page: Number(headers.get('X-Page-Number')) || 0,
          },
          recommendedAnotherTotal: Number(headers.get('X-Total-Elements')) || 0,
          isLoading: false,
        };
      }
      return {
        ...state,
        recommendedAnother: {
          elements: [...state.recommendedAnother.elements],
          hasMore: false,
        },
        isLoading: false,
      };
    case CREATE_PROFILE_STUDY_PLAN_START:
    case POST_USER_TOPICS_START:
    case POST_MULTIPLE_USER_TOPICS_START:
      return { ...state, isLoading: true, error: false };

    case POST_MULTIPLE_USER_TOPICS_SUCCESS:
      return { ...state, isLoading: false };
    case POST_USER_TOPICS_SUCCESS:
    case POST_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS: {
      const newList = state.recommended.elements.map(item => {
        if (item.topic.id === response[0].userTopic.topic.id) {
          return response[0].userTopic;
        }
        return item;
      });

      const newListAnother = state.recommendedAnother.elements.map(itemAnother => {
        if (itemAnother.topic.id === response[0].userTopic.topic.id) {
          response[0].userTopic.recUsers = itemAnother.recUsers;
          return response[0].userTopic;
        }
        return itemAnother;
      });
      if (action.listName === 'anotherMyRecomended') {
        const myRecommendationsAnother = state.myRecommendationsAnother.elements.map(itemAnother => {
          if (itemAnother.topic.id === response[0].userTopic.topic.id) {
            response[0].userTopic.recUsers = itemAnother.recUsers;
            return {
              ...itemAnother,
              topic: { ...response[0].userTopic.topic },
            };
          }
          return itemAnother;
        });
        return {
          ...state,
          myRecommendationsAnother: {
            ...state.myRecommendationsAnother,
            elements: myRecommendationsAnother,
          },
          isLoading: false,
        };
      }
      if (action.listName === 'anotherPlan') {
        return {
          ...state,
          anotherPlanMaterialInFav: response[0].userTopic,
          isLoading: false,
        };
      }
      return {
        ...state,
        recommendedAnother: {
          ...state.recommendedAnother,
          elements: newListAnother,
        },
        recommended: {
          ...state.recommended,
          elements: newList,
        },
        isLoading: false,
      };
    }

    case CREATE_PROFILE_STUDY_PLAN_SUCCESS: {
      const newList = state.recommended.elements.map(item => {
        if (item.topic.id === response.topic.id) {
          const correctedTopic = {
            ...response.topic,
            planStatus: {
              ...response.topic.planStatus,
              planStatus: response.status,
            },
          };

          item.topic = correctedTopic;
          item.userPlanType = response.topic?.userPlanType;
        }
        return item;
      });

      const newListAnother = state.recommendedAnother.elements.map(itemAnother => {
        if (itemAnother.topic.id === response.topic.id) {
          itemAnother.topic.planStatus = {
            ...itemAnother.topic.planStatus,
            planStatus: response.status,
          };
          return itemAnother;
        }
        return itemAnother;
      });
      const newListMyRecommendAnother = state.myRecommendationsAnother.elements.map(item => {
        if (item.topic.id === response.topic.id) {
          item.topic.planStatus = {
            ...item.topic.planStatus,
            planStatus: response.status,
          };
          return item;
        }
        return item;
      });
      return {
        ...state,
        myRecommendationsAnother: {
          ...state.myRecommendationsAnother,
          elements: newListMyRecommendAnother,
        },
        recommendedAnother: {
          ...state.recommendedAnother,
          elements: newListAnother,
        },
        recommended: {
          ...state.recommended,
          elements: newList,
        },
        isLoading: false,
      };
    }
    case DELETE_USER_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_PROFILE_USER_TOPIC_RECOMMENDED_START:
      return { ...state, isLoading: false };
    case DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_START:
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_PROFILE_USER_TOPIC_SUCCESS:
    case DELETE_PROFILE_USER_TOPIC_MY_RECOMMENDED_SUCCESS:
    case DELETE_USER_TOPIC_SUCCESS:
    case DELETE_PROFILE_USER_TOPIC_RECOMMENDED_SUCCESS: {
      let newListAnother = [];
      let newList = [];
      if (response.topic) {
        newListAnother = state.recommendedAnother.elements.map(itemAnother => {
          if (itemAnother.topic.id === response.topic.id) {
            response.recUsers = itemAnother.recUsers;
            return response;
          }
          return itemAnother;
        });
        newList = state.recommended.elements.map(item => {
          if (item.topic.id === response.topic.id) {
            return response;
          }
          return item;
        });
      }
      if (action.listName === 'anotherPlan') {
        return {
          ...state,
          anotherPlanMaterialInFav: response,
          isLoading: false,
        };
      }
      if (action.listName === 'anotherMyRecomended') {
        const myRecommendationsAnotherList = state.myRecommendationsAnother.elements.map(item => {
          if (item.topic.id === response.topic.id) {
            return { ...item, topic: response.topic };
          }
          return item;
        });
        return {
          ...state,
          myRecommendationsAnother: {
            ...state.myRecommendationsAnother,
            elements: myRecommendationsAnotherList,
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        recommendedAnother: {
          ...state.recommendedAnother,
          elements: newListAnother,
        },
        recommended: {
          ...state.recommended,
          elements: newList,
        },
        isLoading: false,
      };
    }

    case GET_RECOMMENDED_TOPICS_FAIL:
      return {
        error: true,
        isLoading: false,
        isRecommendedLoading: false,
      };

    case GET_MY_RECOMMENDATIONS_TOPICS_FAIL:
      return {
        error: true,
        isMyRecommendationsLoading: false,
      };

    case GET_STATISTIC_TOPICS_FAIL:
    case DELETE_PROFILE_USER_TOPIC_FAIL:
    case DELETE_PROFILE_USER_TOPIC_RECOMMENDED_FAIL:
    case GET_USER_TOPICS_FAIL:
    case DELETE_USER_TOPIC_FAIL:
    case POST_USER_TOPICS_FAIL:
    case GET_ANOTHER_USER_TOPICS_FAIL:
    case GET_ANOTHER_RECOMMENDED_TOPICS_FAIL:
    case POST_MULTIPLE_USER_TOPICS_FAIL:
      return {
        error: true,
        isLoading: false,
      };

    case CHANGE_USER_TOPIC_FILTER:
      const topicFilter = { ...state.topicFilter, [name]: value };
      return { ...state, topicFilter };

    case CLEAR_USER_TOPIC_FILTER:
      return { ...state, topicFilter: initialState.topicFilter };

    case GET_ANOTHER_RECOMMENDED_NEWS_FAIL:
    case GET_MY_RECOMMENDED_NEWS_FAIL:
      return { ...state };

    case PUT_TOPIC_ALLRECOMMEND_SUCCESS:
      return { ...state, isLoading: false };

    case PUT_TOPIC_ALLRECOMMEND_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};
