import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getMentors } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Avatar, Button, Tooltip } from 'antd';
import photo from '@/pages/Header/PortraitPlaceholder.png';
import { UserOutlined } from '@ant-design/icons';

import {
  ACTIVE,
  CLOSE,
  ON_WAITING_LIST,
  REQUEST_MENTORING,
  REQUEST_SEND,
  WAITING_LIST,
  WAITING_MENTOR,
} from '../constants';
import ModalActions from '../MentorActionModal/ModalActions';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import { mentors } from '@/router/routes';
import { selectMentoring } from '@/store/mentoring_profile/selector';
import PropTypes from 'prop-types';

import css from './CardMentor.module.scss';

const CardMentor = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAction, setIsAction] = useState({
    visible: false,
    action: '',
    mentorId: null,
  });

  const { mentorsFilters, mentorsCardCurrentPage } = useSelector(selectMentors);
  const { sizePage } = useSelector(selectMentoring);

  const [currentAvatar] = useLocalFileByUUID(props.photoUuid || props.photoUuidUser, { defaultImg: photo });

  const showModal = action => {
    setIsAction({
      ...isAction,
      visible: true,
      action,
      mentorId: props.mentorId,
    });
  };

  const onCloseModalActions = (block, close) => {
    if ((block === REQUEST_SEND && close !== CLOSE) || block === ON_WAITING_LIST) {
      dispatch(getMentors(mentorsFilters.filters ? mentorsFilters.filters : {}, mentorsCardCurrentPage, sizePage));
    }

    setIsAction({ ...isAction, visible: false });
  };

  const renderButton = () => {
    // запросить менторинг
    if (props.availableSlots > 0 && props.mentoringStatus !== ACTIVE && props.mentoringStatus !== WAITING_MENTOR) {
      return (
        <Button
          className={css['Card-action-btn']}
          onClick={() => showModal(REQUEST_MENTORING)}
          type='primary'
          size='large'
        >
          {t('requestMentoring')}
        </Button>
      );
    }

    // встать в лист ожидания
    if (
      props.availableSlots === 0 &&
      props.mentoringStatus !== ON_WAITING_LIST &&
      props.mentoringStatus !== WAITING_MENTOR &&
      props.mentoringStatus !== ACTIVE
    ) {
      return (
        <Button className={css['Card-action-btn']} onClick={() => showModal(WAITING_LIST)} type='primary' size='large'>
          {t('waitingList')}
        </Button>
      );
    }

    // Запрошено
    if (props.mentoringStatus === WAITING_MENTOR) {
      return (
        <Button className={css['Card-action-btn']} disabled size='large'>
          {t('retrieved')}
        </Button>
      );
    }

    // В процессе
    if (props.mentoringStatus === ACTIVE) {
      return (
        <Button className={css['Card-action-btn']} disabled size='large'>
          {t('inProcess')}
        </Button>
      );
    }
    // в листе ожидания
    if (props.mentoringStatus === ON_WAITING_LIST && props.availableSlots === 0) {
      return (
        <Button className={css['Card-action-btn']} disabled size='large'>
          {t('awaitMentor')}
        </Button>
      );
    }
  };

  const renderCompetencies =
    props.competencies?.length &&
    props.competencies.map((item, index) => {
      const id = item.id;
      const name = String(item.name).trim();

      if (index === 2) {
        return <div key={id}>{name}...</div>;
      }
      return <div key={id}>{name}</div>;
    });

  const threeComp = renderCompetencies.length > 0 && renderCompetencies.splice(0, 3);
  const listComp = props.competencies?.length > 0 && props.competencies.map(item => `- ${item.name}`);
  const stringComp = listComp.length > 0 && listComp?.join('\n');

  const goToMentorsID = () => navigate(`/${mentors.path}/${props.mentorId}`);

  return (
    <>
      <div className={css['Card']} key={props.mentorId}>
        <div className={css['Card-content']} onClick={goToMentorsID}>
          <div className={css['Card-content-avatar']}>
            <Avatar size={120} icon={<UserOutlined />} src={currentAvatar} />
          </div>

          <div className={css['Card-content-desc']}>
            <div className={css['Card-content-desc-userName']}>{props.fullName}</div>
            <div className={css['Card-content-desc-position']}>
              <Tooltip title={props.position}>{props.position}</Tooltip>
            </div>
            <div className={css['Card-content-desc-competencies']}>
              <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={stringComp}>
                {threeComp}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={css['Card-action']}>
          <div className={css['Card-action-slot']}>
            {t('freeSlots')} {props.availableSlots}
          </div>
          {renderButton()}
        </div>
      </div>

      {isAction.visible && (
        <ModalActions
          open={isAction.visible}
          onClose={onCloseModalActions}
          action={isAction.action}
          mentorId={isAction.mentorId}
        />
      )}
    </>
  );
};

CardMentor.propTypes = {
  availableSlots: PropTypes.number,
  competencies: PropTypes.array,
  fullName: PropTypes.string,
  mentorId: PropTypes.number,
  position: PropTypes.string,
  mentoringStatus: PropTypes.string,
  photoUuid: PropTypes.string,
  photoUuidUser: PropTypes.string,
};

export default CardMentor;
