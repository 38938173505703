export const GET_POKA_SKILLS_START = 'GET_POKA_SKILLS_START';
export const GET_POKA_SKILLS_SUCCESS = 'GET_POKA_SKILLS_SUCCESS';
export const GET_POKA_SKILLS_FAIL = 'GET_POKA_SKILLS_FAIL';

export const GET_POKA_STATUS_START = 'GET_POKA_STATUS_START';
export const GET_POKA_STATUS_SUCCESS = 'GET_POKA_STATUS_SUCCESS';
export const GET_POKA_STATUS_FAIL = 'GET_POKA_STATUS_FAIL';

export const GET_POKA_CATEGORY_START = 'GET_POKA_CATEGORY_START';
export const GET_POKA_CATEGORY_SUCCESS = 'GET_POKA_CATEGORY_SUCCESS';
export const GET_POKA_CATEGORY_FAIL = 'GET_POKA_CATEGORY_FAIL';

export const SAVE_POKA_SKILLS_FILTERS = 'SAVE_POKA_SKILLS_FILTERS';

export const CLEAR_POKA_SKILLS_FILTERS = 'CLEAR_POKA_SKILLS_FILTERS';

export const SAVE_POKA_SKILLS_SORT = 'SAVE_POKA_SKILLS_SORT';

export const GET_POKA_ASSESSOR_START = 'GET_POKA_ASSESSOR_START';
export const GET_POKA_ASSESSOR_SUCCESS = 'GET_POKA_ASSESSOR_SUCCESS';
export const GET_POKA_ASSESSOR_FAIL = 'GET_POKA_ASSESSOR_FAIL';

export const GET_POKA_CHECKLIST_START = 'GET_POKA_CHECKLIST_START';
export const GET_POKA_CHECKLIST_SUCCESS = 'GET_POKA_CHECKLIST_SUCCESS';
export const GET_POKA_CHECKLIST_FAIL = 'GET_POKA_CHECKLIST_FAIL';

export const START_POKA_CHECKLIST_START = 'START_POKA_CHECKLIST_START';
export const START_POKA_CHECKLIST_SUCCESS = 'START_POKA_CHECKLIST_SUCCESS';
export const START_POKA_CHECKLIST_FAIL = 'START_POKA_CHECKLIST_FAIL';

export const FINISH_POKA_CHECKLIST_START = 'FINISH_POKA_CHECKLIST_START';
export const FINISH_POKA_CHECKLIST_SUCCESS = 'FINISH_POKA_CHECKLIST_SUCCESS';
export const FINISH_POKA_CHECKLIST_FAIL = 'FINISH_POKA_CHECKLIST_FAIL';

export const SET_IS_FAVOURITE = 'SET_IS_FAVOURITE';

export const GET_POKA_DIR_SITE_START = 'GET_POKA_DIR_SITE_START';
export const GET_POKA_DIR_SITE_SUCCESS = 'GET_POKA_DIR_SITE_SUCCESS';
export const GET_POKA_DIR_SITE_FAIL = 'GET_POKA_DIR_SITE_FAIL';
