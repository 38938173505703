/* eslint-disable */
/* jsx-a11y/anchor-is-valid */
/* react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteChatGroupByRequestId } from '@/store/chat/actions';
import { getUserIncome2 as getIncome } from '@/store/income/actions';
import { finishMentee, getMentees, rejectMentee } from '@/store/mentee/actions';

import { selectCurrentUser } from '@/store/users/selectors';

import { Button, Col, Dropdown, Input, Modal, Radio, Row, Tooltip } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import MentorTag from '@/components/Mentoring/MentorTag';
import Rate from '@/components/Rate';
import { EllipsisOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { HiLightBulb, HiOutlineExclamation, HiOutlineUserGroup } from 'react-icons/hi';

import estimate from '../../../assets/images/estimate.png';
import { mentorStatuses, rejectReasonStatuses } from '../constants';
import ThankMentorForm from './forms/thankMentorForm';
import NoteModal from './note-modal/NoteModal';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';
import { classNames } from '@shared/utils/styles-utils';

import { mentors } from '@/router/routes';
import dayjs from 'dayjs';

import styles from './styles.module.scss';

import { MENTOR_COMPLETE } from '@/constants/income-types';

const { TextArea } = Input;

const cn = classNames(styles);

export const MentorCard = ({ data, className, currentValueSelectMentor, asideClass }) => {
  const { t } = useTranslation('mentorCard');
  const dispatch = useDispatch();

  const [isModalOpenAcquaintanceMentor, setIsModalOpenAcquaintanceMentor] = useState(false);

  const [isModalOpenEstimate, setIsModalOpenEstimate] = useState(false);

  const [isModalOpenCancelRequest, setIsModalOpenCancelRequest] = useState(false);

  const [isModalOpenCancelRequestWaitingList, setIsModalOpenCancelRequestWaitingList] = useState(false);

  const [isModalOpenCancelMentoring, setIsModalOpenCancelMentoring] = useState(false);

  const [isModalOpenThankMentor, setIsModalOpenThankMentor] = useState(false);

  const [isModalOpenChangeMind, setIsModalOpenChangeMind] = useState(false);
  const [radioButtonValueChangeMind, setRadioButtonValueChangeMind] = useState();

  const [selectedRecord, setSelectedRecord] = useState();

  const [currenAvatar] = useLocalFileByUUID(data?.mentorPhotoUuid, { defaultImg });
  const currentUser = useSelector(selectCurrentUser);

  const [isMobile] = useIsMobile(768);

  const [shortNameBtn] = useIsMobile(991);

  const ratingByMentorHandler = record => {
    setIsModalOpenEstimate(true);
    setSelectedRecord(record);
  };

  const primaryNoteTextEstimate = (
    <div className={cn('material-card__mentorRating')}>
      <p>{t('mentorRating')}</p>
      <Rate count={5} value={selectedRecord?.ratingByMentor} disabled noChange size='lg' />
    </div>
  );

  const itemsDD = [
    {
      key: '1',
      label: t('ratingByMentor'),
    },
  ];

  const rejectReasonStatusesLocalisation = status => {
    switch (status) {
      case rejectReasonStatuses.NO_HELP:
        return t('rejectReasonStatuses_NO_HELP');
      case rejectReasonStatuses.NO_COMPETENCE:
        return t('rejectReasonStatuses_NO_COMPETENCE');
      case rejectReasonStatuses.NO_TIME:
        return t('rejectReasonStatuses_NO_TIME');
      default:
        break;
    }
  };

  const onCancelThankMentorModal = () => {
    setIsModalOpenThankMentor(false);
    dispatch(getMentees(currentValueSelectMentor));
  };

  const statusContent = (status, record) => {
    const cancelMentoringButtonHandler = () => {
      setSelectedRecord(record);
      setIsModalOpenCancelMentoring(true);
    };

    const finishMentoringButtonHandler = () => {
      setSelectedRecord(record);
      setIsModalOpenThankMentor(true);
    };

    const statusContentComponent = content => (
      <>
        <div className={cn('material-card__block')}>
          {content}
          {data?.status?.value === mentorStatuses.FINISHED && data?.ratingByMentor && data?.reviewByMentor && (
            <div>
              <Dropdown menu={{ items: itemsDD, onClick: () => ratingByMentorHandler(record) }} trigger={['click']}>
                {/* <a onClick={e => e.preventDefault()}>...</a> */}
                <EllipsisOutlined className={cn('material-card__threeDotsButton')} />
              </Dropdown>
            </div>
          )}
        </div>
        {/* <div className={cn('material-card__block')}>{content}</div> */}

        {/* {data?.status?.value === mentorStatuses.FINISHED && data?.ratingByMentor && data?.reviewByMentor && (
          <div className={cn('material-card__threeDotsButton')}>
            <Dropdown menu={{ items: itemsDD, onClick: () => ratingByMentorHandler(record) }} trigger={['click']}>
              <a onClick={e => e.preventDefault()}>...</a>
            </Dropdown>
          </div>
        )} */}
      </>
    );

    switch (status) {
      case mentorStatuses.ACTIVE:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div>
              <span className={cn('material-card__statusAndActions_Active')}>
                {t('statusContent_ACTIVE_PrimaryText')}
              </span>
              <div style={{ whiteSpace: 'nowrap' }}>
                {record?.activatedTimestamp &&
                  `${t('statusContent_ACTIVE_from')} ${dayjs(record.activatedTimestamp).format('D MMMM YYYY')}${t(
                    'statusContent_ACTIVE_shortYear'
                  )}`}
              </div>
            </div>
            <Button
              key='cancelMentoringButtonHandler'
              onClick={cancelMentoringButtonHandler}
              size='large'
              type='primary'
            >
              {shortNameBtn
                ? t('statusContent_ACTIVE_SecondaryButtonText_sort')
                : t('statusContent_ACTIVE_SecondaryButtonText')}
            </Button>
          </div>
        );

      case mentorStatuses.WAITING_MENTOR_MEETING:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions_Waiting')}>
            <div className={cn('material-card__statusAndActions_StageOfAcquaintance')}>
              {t('statusContent_WAITING_MENTOR_MEETING_PrimaryText')}
            </div>
            <div className={cn('material-card__statusAndActions_meetingWithMentor')}>
              <InfoCircleOutlined />
              <div>
                {record?.statusChangeDate &&
                  t('statusContent_WAITING_MENTOR_MEETING_SecondaryText', {
                    date: dayjs(record.statusChangeDate).format('DD.MM.YYYY'),
                  })}
              </div>
            </div>
            <div className={cn('material-card__finishedStatusButtonsContainer')}>
              <Button
                onClick={() => setIsModalOpenChangeMind(true)}
                key='statusContent_WAITING_MENTOR_MEETING_SecondaryButtonText'
                size='large'
                type='primary'
              >
                {t('statusContent_WAITING_MENTOR_MEETING_SecondaryButtonText')}
              </Button>
              <Button
                size='large'
                type='primary'
                onClick={() => setIsModalOpenAcquaintanceMentor(true)}
                key='statusContent_WAITING_MENTOR_MEETING_PrimaryButtonText'
              >
                {t('statusContent_WAITING_MENTOR_MEETING_PrimaryButtonText')}
              </Button>
            </div>
          </div>
        );

      case mentorStatuses.WAITING_MENTOR:
        return statusContentComponent(
          <div className={cn('material-card__waitingMentor')}>
            <div className={cn('material-card__statusAndActions_StageOfAcquaintance')}>
              {t('statusContent_WAITING_MENTOR_PrimaryText')}
            </div>
            <Button
              size='large'
              type='primary'
              className={cn('material-card__waitingMentor-btn')}
              onClick={() => setIsModalOpenCancelRequest(true)}
              key='statusContent_WAITING_MENTOR_SecondaryButtonText'
            >
              {t('statusContent_WAITING_MENTOR_SecondaryButtonText')}
            </Button>
          </div>
        );

      case mentorStatuses.WAITING_MENTOR_DECISION:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div className={cn('material-card__statusAndActions_StageOfAcquaintance')}>
              {t('statusContent_WAITING_MENTOR_DECISION_PrimaryText')} <InfoCircleOutlined />
            </div>
            <Button
              size='large'
              type='primary'
              onClick={() => setIsModalOpenCancelRequest(true)}
              key='statusContent_WAITING_MENTOR_DECISION_SecondaryButtonText'
            >
              {t('statusContent_WAITING_MENTOR_DECISION_SecondaryButtonText')}
            </Button>
          </div>
        );

      case mentorStatuses.ON_WAITING_LIST:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div className={cn('material-card__statusAndActions_StageOfAcquaintance')}>
              {t('statusContent_ON_WAITING_LIST_PrimaryText')}{' '}
            </div>
            <Button
              size='large'
              type='primary'
              onClick={() => setIsModalOpenCancelRequestWaitingList(true)}
              key='statusContent_ON_WAITING_LIST_SecondaryButtonText'
            >
              {t('statusContent_ON_WAITING_LIST_SecondaryButtonText')}
            </Button>
          </div>
        );

      case mentorStatuses.REJECTED_BY_MENTOR:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div className={cn('material-card__statusAndActions_StageRejectedByMentor')}>
              {t('statusContent_REJECTED_BY_MENTOR_PrimaryText')}
              <Tooltip
                placement='bottom'
                title={
                  record.rejectReason === rejectReasonStatuses.OTHER
                    ? record.rejectReasonOther
                    : rejectReasonStatusesLocalisation(record.rejectReason)
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
        );

      case mentorStatuses.REJECTED_BY_MENTEE:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div className={cn('material-card__statusAndActions_StageFinished')}>
              {t('statusContent_REJECTED_BY_MENTEE_PrimaryText')}
              <Tooltip placement='bottom' title={t('rejectDecision')}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
        );

      case mentorStatuses.FINISHED:
        return statusContentComponent(
          <div className={cn('material-card__statusAndActions')}>
            <div className={cn('material-card__statusAndActions_StageFinished')}>
              {t('statusContent_FINISHED_PrimaryText')}
            </div>
            <div className={cn('material-card__finishedStatusButtonsContainer')}>
              {!data?.hasMenteeReview && (
                <Button
                  size='large'
                  type='primary'
                  onClick={finishMentoringButtonHandler}
                  key='statusContent_FINISHED_SecondaryButtonText2'
                >
                  {shortNameBtn
                    ? t('statusContent_FINISHED_SecondaryButtonText2_sort')
                    : t('statusContent_FINISHED_SecondaryButtonText2')}
                </Button>
              )}
            </div>
          </div>
        );

      default:
        break;
    }
  };

  const rejectMenteeModalRadioGroupArray = [
    {
      value: 1,
      label: t('rejectMenteeModalChangePlans'),
    },
    {
      value: 2,
      label: t('rejectMenteeModalNotWhatIwant'),
    },
    {
      value: 3,
      label: t('rejectMenteeModalChooseAnotherMentor'),
    },
    {
      value: 4,
      label: t('rejectMenteeModalChangeMind'),
    },
    {
      value: 5,
      label: t('rejectMenteeModalAnotherAnswer'),
    },
  ];

  const renderRejectMenteeModalRadioGroup = () =>
    rejectMenteeModalRadioGroupArray.map(({ value, label }, id) => {
      return (
        <Radio key={id} className={cn('material-card__radioButton')} value={value}>
          {label}
        </Radio>
      );
    });

  const renderCompetences = () => data.competences.map((i, id) => <div key={id}>{i}</div>);

  const renderTtileRejectMenteeModal = () => (
    <div>
      <p>{t('rejectMenteeModalIndicateReason')}</p>
      <p>{t('rejectMenteeModalImportantForMentor')}</p>
    </div>
  );

  const renderThankMentorModal = () => (
    <div>
      <p>{t('thankMentorModalPrimaryText')}</p>
      <p>{t('thankMentorModalSecondaryText')}</p>
    </div>
  );

  const renderChangeMindModalFooter = () => [
    <Button onClick={() => setIsModalOpenChangeMind(false)} key='setIsModalOpenChangeMind' size='large' type='primary'>
      {t('rejectMenteeModalSecondaryButtonText')}
    </Button>,
    <Button key='rejectMenteeModalPrimaryButtonText' size='large' type='primary'>
      {t('rejectMenteeModalPrimaryButtonText')}
    </Button>,
  ];

  const handlerFuncModalCancelRequest = () => {
    dispatch(rejectMentee(data?.id));
    setIsModalOpenCancelRequest(false);
    setTimeout(() => {
      dispatch(getMentees(currentValueSelectMentor));
    }, 1000);
  };

  const handlerFuncModalCancelRequestWaitingList = () => {
    dispatch(rejectMentee(data?.id));
    setIsModalOpenCancelRequestWaitingList(false);
    setTimeout(() => {
      dispatch(getMentees(currentValueSelectMentor));
    }, 1000);
  };

  const handlerFuncModalCancelMentoring = () => {
    setIsModalOpenCancelMentoring(false);
    dispatch(
      finishMentee(selectedRecord?.id, () => {
        if (!currentUser?.domainCompany?.hasCoins) return;

        dispatch(
          getIncome({
            userId: currentUser.id,
            bonus: 'MENTOR_COMPLETE',
            incomeType: MENTOR_COMPLETE,
            incomeContentLabel: t('commentMentorMessage'),
          })
        );
      })
    );
    dispatch(deleteChatGroupByRequestId(selectedRecord?.id));
    setIsModalOpenThankMentor(true);
  };

  const WrappedThankMentorForm = ThankMentorForm;

  return (
    <>
      <div className={cn('material-card', className, 'material-card--mentor')}>
        <Row justify='space-between' className={cn('material-card__content')}>
          {!isMobile && (
            <Col md={4} lg={3} className={cn('material-card__poster')}>
              <img src={currenAvatar} className={cn('material-card__image')} alt='avatar' />
            </Col>
          )}
          <Col md={12} lg={13} className={cn('material-card__body')}>
            <div className={cn('material-card__title')}>
              <Link className={cn('material-card__title-link')} to={`/${mentors.path}/${data.mentorId}`}>
                <Tooltip placement='top'>{data.mentorFullName}</Tooltip>
              </Link>
              <MentorTag />
            </div>
            <div className={cn('material-card__attributes')}>{renderCompetences()}</div>
          </Col>

          <Col md={6} lg={7} className={cn('material-card__contentCol')}>
            {data?.status?.value && statusContent(data.status.value, data)}
          </Col>
        </Row>
      </div>

      <NoteModal
        isModalOpen={isModalOpenAcquaintanceMentor}
        setIsModalOpen={setIsModalOpenAcquaintanceMentor}
        imageType='reactIcons'
        image={<HiOutlineUserGroup />}
        primaryNoteText={t('acquaintanceModalPrimaryQuestion')}
        secondaryNoteText={t('acquaintanceModalSecondaryQuestion')}
        primaryButtonText={t('acquaintanceModalPrimaryButtonText')}
        secondaryButtonText={t('acquaintanceModalSecondaryButtonText')}
      />

      <Modal
        title={renderTtileRejectMenteeModal()}
        open={isModalOpenChangeMind}
        className={cn('material-card__ChangeMindModal')}
        onCancel={() => setIsModalOpenChangeMind(false)}
        footer={renderChangeMindModalFooter()}
      >
        <Radio.Group onChange={e => setRadioButtonValueChangeMind(e.target.value)} value={radioButtonValueChangeMind}>
          {renderRejectMenteeModalRadioGroup()}
          {radioButtonValueChangeMind === 5 && <TextArea placeholder={t('rejectMenteeModalWhyRejectRequest')} />}
        </Radio.Group>
      </Modal>

      <NoteModal
        isModalOpen={isModalOpenCancelRequest}
        setIsModalOpen={setIsModalOpenCancelRequest}
        handlerFunc={handlerFuncModalCancelRequest}
        imageType='reactIcons'
        image={<HiOutlineExclamation />}
        primaryNoteText={t('cancelRequestModalPrimaryNoteText')}
        primaryButtonText={t('cancelRequestModalPrimaryButtonText')}
        secondaryButtonText={t('cancelRequestModalSecondaryButtonText')}
      />

      <NoteModal
        isModalOpen={isModalOpenCancelRequestWaitingList}
        setIsModalOpen={setIsModalOpenCancelRequestWaitingList}
        handlerFunc={handlerFuncModalCancelRequestWaitingList}
        imageType='reactIcons'
        image={<HiOutlineExclamation />}
        primaryNoteText={t('cancelRequestModalPrimaryNoteText')}
        primaryButtonText={t('cancelRequestModalPrimaryButtonText')}
        secondaryButtonText={t('cancelRequestModalSecondaryButtonText')}
      />

      <Modal
        title={renderThankMentorModal()}
        open={isModalOpenThankMentor}
        className={cn('material-card__ThankMentorModal')}
        onCancel={onCancelThankMentorModal}
        footer={null}
      >
        <WrappedThankMentorForm selectedRecord={selectedRecord} currentValueSelectMentor={currentValueSelectMentor} />
      </Modal>

      <NoteModal
        isModalOpen={isModalOpenEstimate}
        setIsModalOpen={setIsModalOpenEstimate}
        image={estimate}
        primaryNoteText={primaryNoteTextEstimate}
        secondaryNoteText={selectedRecord?.reviewByMentor}
        primaryButtonText={null}
        secondaryButtonText={null}
      />

      <NoteModal
        isModalOpen={isModalOpenCancelMentoring}
        setIsModalOpen={setIsModalOpenCancelMentoring}
        handlerFunc={handlerFuncModalCancelMentoring}
        imageType='reactIcons'
        image={<HiLightBulb />}
        primaryNoteText={t('cancelMentoringModalTakeAllKnowledge')}
        primaryButtonText={t('cancelMentoringModalReadyToFinish')}
        secondaryButtonText={t('cancelMentoringModalNotTakeAllKnowledge')}
      />
    </>
  );
};
