/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';

import { getFaq } from '@/store/faq/actions';
import { getFile } from '@/store/files/actions';

import { selectFaq } from '@/store/faq/selectors';

import { Layout } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './Faq.module.scss';

import Link from '@/assets/images/link.svg';
import Preloader from '@/assets/images/logo_flower.svg';

const { Content } = Layout;

export class Faq extends Component {
  componentDidMount() {
    this.props.getFaq(data => {
      data.forEach(faq => {
        if (faq.image) {
          this.props.getFile(faq.image, res => {
            this.setState({ [faq.image]: URL.createObjectURL(res) });
          });
        }
      });
    });
  }

  finalLink = link => {
    const parsed = link
      ? link.indexOf('youtube.com') !== -1
        ? link.substring(link.indexOf('watch') + 8)
        : link.indexOf('youtu.be') !== -1
          ? link.substring(link.indexOf('be/') + 3)
          : ''
      : '';
    return parsed && parsed.length !== 0 && parsed.indexOf('&') !== -1
      ? parsed.substring(0, parsed.indexOf('&') || parsed.index('?'))
      : parsed;
  };

  showFaq = () => {
    if (this.props.faq) {
      const faqContent = this.props.faq.map(item => (
        <div key={item.id} className={css['Faq-block']}>
          <div>
            <div className={css['Faq-header-container']}>
              <h3 className={css['Faq-header']}>{item.header}</h3>
              {item.sectionLink && (
                <a href={item.sectionLink} target='_blank' rel='noopener noreferrer' className={css['Faq-header-link']}>
                  <img className={css['Faq-header-link']} src={Link} alt='' />
                </a>
              )}
            </div>
            <p className={css['Faq-text']}>{item.description}</p>
          </div>
          {(item.image || item.videoLink) && (
            <div className={css['Faq-content']}>
              {item.image ? (
                <img className={css['Faq-image']} src={(this.state && this.state[item.image]) || Preloader} alt='' />
              ) : (
                <YouTube className={css['Faq-video']} videoId={this.finalLink(item.videoLink)} />
              )}
            </div>
          )}
        </div>
      ));
      return faqContent.reverse();
    }
    return null;
  };

  render() {
    return (
      <Content className={css['App-content']}>
        <h1>{this.props.t('about')}</h1>
        <div className={css['App-card-item']}>
          <div className={css['Faq-content']}>{this.showFaq()}</div>
        </div>
      </Content>
    );
  }
}

Faq.propTypes = {
  faq: PropTypes.array,
  getFaq: PropTypes.func,
};

const mapStateToProps = createSelector([selectFaq], faq => ({
  faq: faq.faq,
}));

const mapActionsToProps = {
  getFaq,
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('faq')(Faq));
