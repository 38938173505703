import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { updateMentorCareerPlan } from '@/store/career_plan/actions';
import { getCompetence } from '@/store/competence/actions';
import { getMentors } from '@/store/mentors/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectMentors } from '@/store/mentors/selectors';

import { Button, Input, Modal, notification, Select, Tabs } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import { WAITING_MENTOR } from '@/components/Mentoring/constants';

import { MAX_MENTORS_SELECTED, MENTORS_SIZE } from '../../constants';
import MentorItemModal from './MentorItemModal';

import { debounce, isEmpty, remove } from 'lodash';
import PropTypes from 'prop-types';

import css from './MentorsCareerPlanModal.module.scss';

const { Option } = Select;

const MentorsCareerPlanModal = ({ data, clearCompetence, visible, closeModal, currentCompetence }) => {
  const { t } = useTranslation('careerPlan');
  const dispatch = useDispatch();
  const { mentorsCardAll, isLoading, mentorsCardTotal, mentorsCardCurrentPage } = useSelector(selectMentors);
  const { competence } = useSelector(selectCompetence);
  const [filters, setFilters] = useState({ competencesName: '', fullName: '', competenceId: null });
  const [active, setActive] = useState([]);

  useEffect(() => {
    return () => {
      onChangeMentorsSearch.cancel();
      getCompetenceDebounced.cancel();
    };
  }, []);

  useEffect(() => {
    currentCompetence?.mentors?.length > 0 && setActive(currentCompetence?.mentors);
    setFilters(prevFilters => ({
      ...prevFilters,
      competenceId: currentCompetence?.id,
      competencesName: currentCompetence?.name,
    }));
  }, [currentCompetence]);

  const getCompetenceDebounced = useCallback(
    debounce(filters => {
      dispatch(getCompetence(filters));
    }, 600),
    []
  );

  useEffect(() => {
    visible && getCompetenceDebounced(filters.competencesName);
  }, [dispatch, filters.competencesName, visible]);

  const onChangeMentorsSearch = useCallback(
    debounce(filters => {
      dispatch(
        getMentors(
          filters,
          0,
          MENTORS_SIZE,
          '',
          '',
          () => {},
          () => {},
          true
        )
      );
    }, 600),
    []
  );

  useEffect(() => {
    if ((filters.fullName.length > 0 || filters.competenceId) && visible) {
      onChangeMentorsSearch({
        ...(filters.competenceId && { competences: [filters.competenceId] }),
        fullName: filters.fullName,
      });
    } else if (!filters.fullName && !filters.competenceId && visible) {
      onChangeMentorsSearch({});
    }
  }, [filters.competenceId, filters.fullName, visible]);

  const handleClickMentor = mentor => {
    if (active.some(e => e?.id === mentor?.id)) {
      setActive(remove(active, n => n?.id !== mentor?.id));
    } else {
      if (active?.length + 1 <= MAX_MENTORS_SELECTED) {
        setActive(prevActive => [...prevActive, mentor]);
      } else {
        notification.warning({
          message: t('mentorsModal.errorTitle'),
          description: t('mentorsModal.errorDescription', { max: MAX_MENTORS_SELECTED }),
        });
      }
    }
  };

  const mentorsAvatar = useMemo(
    () =>
      active?.length > 0 &&
      active.map(mentor => (
        <MentorItemModal
          key={`${mentor?.id}_small`}
          mentor={mentor}
          active={active}
          small
          onClickMentor={handleClickMentor}
          disabled={mentor?.mentoringStatusDto && mentor?.mentoringStatusDto?.value !== WAITING_MENTOR}
        />
      )),
    [active, visible]
  );

  const mentorsCards = useMemo(
    () =>
      !isEmpty(mentorsCardAll) &&
      mentorsCardAll.map(mentor => {
        const isDisabled = Array.isArray(active) && active?.some(item => item?.id === mentor?.id);

        return (
          <MentorItemModal
            key={mentor?.id}
            mentor={mentor}
            active={active}
            onClickMentor={() => handleClickMentor(mentor)}
            disabled={isDisabled}
          />
        );
      }),
    [mentorsCardAll, active]
  );

  const competencesOptions = useMemo(
    () =>
      competence?.content?.map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      )),
    [competence]
  );

  const loadMore = page => {
    dispatch(
      getMentors(
        { ...(filters.competenceId && { competences: [filters.competenceId] }), fullName: filters.fullName },
        page,
        MENTORS_SIZE,
        '',
        '',
        () => {},
        () => {},
        false
      )
    );
  };

  const onSubmit = () => {
    const sendData = {
      mentors: active.map(mentor => mentor?.id),
      competenceId: currentCompetence?.id,
    };

    dispatch(
      updateMentorCareerPlan(sendData, data?.id, () => {
        closeModal();
        notification.success({
          message: t('mentorsModal.success'),
          description: t('mentorsModal.successDescription'),
        });
      })
    );
  };

  const onCancelModal = () => {
    closeModal();
    clearCompetence();
    setActive([]);
  };

  const modalTitle = <p className={css['MentorsModal-title']}>{t('mentorsModal.title')}</p>;

  const modalFooter = (
    <div className={css['MentorsModal-footer']}>
      <Button type='primary' onClick={onSubmit}>
        {t('mentorsModal.save')}
      </Button>
      <div className={css['MentorsModal-footer-mentors']}>{mentorsAvatar}</div>
    </div>
  );

  const hasMore = mentorsCardTotal !== mentorsCardAll.length && !isLoading;

  const tabItems = [
    {
      key: '1',
      label: t('mentorsModal.competences'),
      children: (
        <Select
          className={css['MentorsModal-tabs-search']}
          showSearch
          onSearch={e => setFilters(prevFilters => ({ ...prevFilters, competencesName: e }))}
          onChange={e => setFilters(prevFilters => ({ ...prevFilters, competencesName: '', competenceId: e }))}
          filterOption={false}
          autoClearSearchValue
          value={[filters?.competenceId]}
          allowClear
        >
          {competencesOptions}
        </Select>
      ),
    },
    {
      key: '2',
      label: t('mentorsModal.fullname'),
      children: (
        <Input
          onChange={e => {
            const { value } = e.target;
            setFilters(prevFilters => ({ ...prevFilters, fullName: value }));
          }}
          value={filters.fullName}
          allowClear
        />
      ),
    },
  ];

  return (
    <Modal open={visible} onCancel={onCancelModal} title={modalTitle} footer={modalFooter} width={600}>
      <div>
        <Tabs className={css['MentorsModal-tabs']} items={tabItems} />
        <div className={css['MentorsModal-mentors']}>
          {Array.isArray(mentorsCardAll) && mentorsCardAll.length > 0 ? (
            <InfiniteScroll
              pageStart={mentorsCardCurrentPage}
              loadMore={loadMore}
              hasMore={hasMore}
              initialLoad={false}
              threshold={300}
              useWindow={false}
            >
              {mentorsCards}
            </InfiniteScroll>
          ) : (
            <CustomEmpty className={css['MentorsModal-mentors-empty']} withoutTitle loading={isLoading} />
          )}
        </div>
      </div>
    </Modal>
  );
};

MentorsCareerPlanModal.propTypes = {
  clearCompetence: PropTypes.func,
  closeModal: PropTypes.func,
  currentCompetence: PropTypes.shape({
    id: PropTypes.number,
    mentors: PropTypes.array,
    name: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.number,
  }),
  visible: PropTypes.bool,
};

export default MentorsCareerPlanModal;
