import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { updateLoginEmpty } from '@/store/login/actions';

import { selectCompany } from '@/store/company/selectors';
import { selectLogin } from '@/store/login/selectors';

import { Button, Form, Input, Spin } from 'antd';

import { domainCompanyid } from '@/utils/domainCompanyId';
import debounce from '@shared/utils/debounce';

import classNames from 'classnames';

import css from './Login.module.scss';

const AuthorizationPage = () => {
  const { imageBackgroundLogin, isLoadingImageLogo, imageLogo, loginUserRecaptcha } = useOutletContext();
  const { t } = useTranslation('authorization');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSelect = useSelector(selectLogin);
  const { company } = useSelector(selectCompany);

  const [readInfo, changeReadInfo] = useState(false);
  const [form] = Form.useForm();

  const provider = company.providers.find(provider => provider.type === 'INTERNAL' && !provider.external);
  const ldapLogin = company.providers.find(provider => provider.type === 'LDAP');

  const handleSubmit = formData => {
    loginUserRecaptcha(formData.password, formData.username);
  };

  const navigation = (
    <>
      {ldapLogin && (
        <div className={css['LoginPage-label']}>
          <div className={classNames(css.LoginPage__toInfo)} onClick={() => changeReadInfo(!readInfo)}>
            {t('cantSingIn')}
          </div>
        </div>
      )}
      {provider && (
        <div className={css['LoginPage-label']}>
          <div
            id='btnRecoverPassword'
            className={classNames(css.LoginPage__toInfo)}
            onClick={() => navigate('/user-login/recovery')}
          >
            {t('recoverPassword')}
          </div>
        </div>
      )}
      {provider && company.hasRegistration && (
        <div className={css['LoginPage-label']}>
          <div
            id='btnRegistration'
            className={classNames(css.LoginPage__toInfo)}
            onClick={() => navigate('/user-login/registration')}
          >
            {t('registration')}
          </div>
        </div>
      )}
    </>
  );

  useEffect(() => {
    dispatch(updateLoginEmpty());
  }, []);

  return (
    <div className={css['App-card-item']}>
      {imageBackgroundLogin && <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />}
      <Form className={css['LoginPage-form']} onFinish={handleSubmit} form={form}>
        <div className={css['LoginPage-form__content']}>
          <div className={css['LoginPage-header']}>
            <Spin spinning={isLoadingImageLogo} wrapperClassName={css['LoginPage-header__logo']}>
              {!isLoadingImageLogo && <img src={imageLogo} alt='' />}
            </Spin>
            <div className={css['LoginPage-header__subtitle']}>
              {domainCompanyid.ABSOLUTE_ID === company.id ? t('subtitle_2') : t('subtitle')}
            </div>
          </div>
          <Form.Item
            colon={false}
            className={css['LoginPage-form-item']}
            name='username'
            validateStatus={loginSelect.error || form?.getFieldValue('username')?.length === 0 ? 'error' : 'success'}
            rules={[
              {
                required: true,
                message: t('mailRequired'),
              },
            ]}
          >
            <Input placeholder={provider?.usernameAlias} data-qa='loginUsernameInput' />
          </Form.Item>
          <Form.Item
            colon={false}
            className={css['LoginPage-form-item']}
            name='password'
            validateStatus={loginSelect.error || form?.getFieldValue('password')?.length === 0 ? 'error' : 'success'}
            rules={[
              {
                required: true,
                message: t('passwordRequired'),
              },
              {
                pattern: /^[a-zA-Z0-9!@#$%^&*]/,
                message: t('passwordWrong'),
              },
            ]}
          >
            <Input.Password
              className={css['LoginPage-form-item-password']}
              placeholder={t('password')}
              data-qa='loginPasswordInput'
              onChange={debounce(() => {
                if (loginSelect.error) {
                  dispatch(updateLoginEmpty());
                }
              }, 300)}
            />
          </Form.Item>
          {loginSelect.error && <span className={css['LoginPage-form-item-error']}>{loginSelect.error}</span>}
          <Form.Item className={css['LoginPage-form-google']}>
            <Button size='large' type={'primary'} htmlType='submit' name='ldap' data-qa='loginBtn'>
              {t('enter')}
            </Button>
          </Form.Item>
          {navigation}
        </div>
      </Form>
    </div>
  );
};

export default AuthorizationPage;
