import React from 'react';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const Widget = ({ icon, title, text, onClick }) => {
  const Icon =
    typeof icon === 'string' ? (
      <img src={icon} className={cn('widget__icon')} alt='icon' />
    ) : (
      <span className={cn('widget__icon')}>{icon}</span>
    );

  return (
    <div className={cn('widget', { 'widget_with-action': !!onClick })} onClick={onClick}>
      {Icon}
      <div className={cn('widget__body')}>
        <div className={cn('widget__title')}>{title}</div>
        <div className={cn('widget__text')}>{text}</div>
      </div>
    </div>
  );
};

Widget.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};
