import {
  GET_USERS_TOPIC_ACCESS_FAIL,
  GET_USERS_TOPIC_ACCESS_START,
  GET_USERS_TOPIC_ACCESS_SUCCESS,
  POST_REC_TOPIC_ACCESS_FAIL,
  POST_REC_TOPIC_ACCESS_START,
  POST_REC_TOPIC_ACCESS_SUCCESS,
} from './constants';

const initialState = {
  usersTopicAccess: [],
  loading: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_USERS_TOPIC_ACCESS_START:
    case POST_REC_TOPIC_ACCESS_START:
      return { ...state, loading: true };

    case GET_USERS_TOPIC_ACCESS_SUCCESS:
      return { ...state, loading: false, usersTopicAccess: response };

    case POST_REC_TOPIC_ACCESS_SUCCESS:
      return { ...state, loading: false };

    case GET_USERS_TOPIC_ACCESS_FAIL:
    case POST_REC_TOPIC_ACCESS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
