/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUsers } from '@/store/users/actions';

import { selectCompilations as compilationList } from '@/store/compilations/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectTopics as topicsList } from '@/store/topics/selectors';
import { selectUserTopics as userTopics } from '@/store/user-topics/selectors';
import { selectUsers as usersList } from '@/store/users/selectors';

import Participant from './Participant';
import ParticipantsModal from './ParticipantsModal';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './Participants.module.scss';

class Participants extends Component {
  static propTypes = {
    compilationId: PropTypes.number,
    currentUser: PropTypes.object,
    getUsers: PropTypes.func,
    isFav: PropTypes.bool,
    planStatus: PropTypes.string,
    topicId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      participantModalVisible: false,
      totalParticipants: 0,
      initialTotalParticipants: 0,
      participants: [],
      initialParticipants: [],
      loading: false,
      page: 0,
      otherParticipants: null,
    };
  }

  componentDidMount() {
    this.loadParticipants();
  }

  getFilter = () => {
    const { topicId, compilationId } = this.props;
    if (topicId) {
      return { topicId };
    }

    return { compilationId };
  };

  loadParticipants = filter => {
    const basicFilter = this.getFilter();
    this.setState({ loading: true });
    this.props.getUsers({ ...basicFilter, ...filter }, (participants, headers) => {
      this.setState(s => ({
        loading: false,
        participants,
        initialParticipants: !s.initialParticipants.length ? participants : s.initialParticipants,
        initialTotalParticipants: !s.initialTotalParticipants
          ? Number(headers.get('x-total-elements'))
          : s.initialTotalParticipants,
        totalParticipants: Number(headers.get('x-total-elements')),
      }));
    });
  };

  loadParticipantsMore = filter => {
    const basicFilter = this.getFilter();
    if (this.state.participants.length < this.state.totalParticipants && !this.state.loading) {
      this.setState({ loading: true });
      this.props.getUsers({ ...basicFilter, ...filter, page: this.state.page + 1 }, (participants, headers) => {
        this.setState(s => ({
          loading: false,
          page: s.page++,
          participants: [...s.participants, ...participants],
          totalParticipants: Number(headers.get('x-total-elements')),
        }));
      });
    }
  };

  showAvatar = () => {
    let avatarList = [];
    const { isFav } = this.props;
    const avatarWithoutCurrentUser =
      this.state.initialParticipants &&
      Array.isArray(this.state.initialParticipants) &&
      this.state.initialParticipants.filter(user => {
        if (!isFav) {
          if ((user && user.user && user.user.id) !== this.props.currentUser.id) {
            return user;
          }
        } else if ((user && user.id) !== this.props.currentUser.id) {
          return user;
        }
      });

    if (avatarWithoutCurrentUser && avatarWithoutCurrentUser.length > 4) {
      avatarList = avatarWithoutCurrentUser.slice(0, 4);
    } else {
      avatarList = avatarWithoutCurrentUser.map(res => res);
    }

    return avatarList.map(res => {
      if (!isFav) {
        return <Participant key={res && res.user && res.user.id} participant={res && res.user} />;
      }
      return <Participant key={res.id} participant={res} />;
    });
  };

  showLabel = () => {
    const totalParticipants = this.getFilteredParticipantsCount(this.state.initialParticipants);

    if (this.props.planStatus) {
      if (totalParticipants > 5) {
        return (
          <p className={css.Label} onClick={this.showParticipantModal}>
            {this.props.t('more2', { count: totalParticipants - 5 })}
          </p>
        );
      }
      return null;
    }
    if (totalParticipants > 4) {
      return (
        <p className={css.Label} onClick={this.showParticipantModal}>
          {this.props.t('more2', { count: totalParticipants - 4 })}
        </p>
      );
    }
    return null;
  };

  showParticipantModal = () => {
    this.setState({ participantModalVisible: true });
  };

  hideParticipantsModal = () => {
    this.setState({ participantModalVisible: false });
  };

  getFilteredParticipants = participants => {
    return participants.filter(user => user.id !== this.props.currentUser.id);
  };

  getFilteredParticipantsCount = participants => {
    const participantsFiltered = this.getFilteredParticipants(participants);
    return this.state.initialTotalParticipants - (participants.length - participantsFiltered.length);
  };

  render() {
    const participants = this.getFilteredParticipants(this.state.participants);
    const totalParticipants = this.getFilteredParticipantsCount(this.state.participants);

    return (
      <div className={css.AvatarLabelBlock}>
        {this.showAvatar()}
        {this.showLabel()}
        {this.state.participantModalVisible && (
          <ParticipantsModal
            isFav
            planStatus={this.props.planStatus}
            currentUser={this.props.currentUser}
            onCancel={this.hideParticipantsModal}
            onOk={this.handleRecommendPost}
            totalParticipants={totalParticipants}
            participants={participants}
            load={this.loadParticipants}
            loadMore={this.loadParticipantsMore}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  [compilationList, topicsList, usersList, userTopics, loginSelect],
  (compilations, topics, users, favoriteTopics, login) => ({
    compilation: compilations.compilation,
    currentUserId: users.currentUser && users.currentUser.id,
    currentUser: users.currentUser,
    domainCompany: users.currentUser.domainCompany,
    compilationIsUpdate: compilations.isLoading,
    compilationComments: compilations.compilationComments,
    pageCompilationComments: compilations.pageCompilationComments,
    userTopicsFavorite: favoriteTopics.favoriteTopics,
    hasMoreCompilationComments: compilations.hasMoreCompilationComments,
    topics: topics.topicsCompilation,
    topicsLoading: topics.isLoading,
    topicsPageNumber: topics.topicsCompilationPageNumber,
    topicsTotal: topics.topicsCompilationTotal,
    externalLogin: login.externalLogin,
  })
);

const mapActionsToProps = {
  getUsers,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Participants));
