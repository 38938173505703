/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserTopics } from '@/store/user-topics/actions';

import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Pagination } from 'antd';

import MaterialCard from '../../../components/MaterialCard';
import Modal from '../../../components/Modal';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Planning.module.scss';

export class MyCollectionModal extends Component {
  static propTypes = {
    currentUserId: PropTypes.number,
    externalLogin: PropTypes.string,
    getUserTopics: PropTypes.func,
    onCancel: PropTypes.func,
    onTopicSelect: PropTypes.func,
    ownPageNumber: PropTypes.number,
    ownPageSize: PropTypes.number,
    ownTotal: PropTypes.number,
    ownUserTopics: PropTypes.array,
    visible: PropTypes.bool,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.visible && newProps.visible !== this.props.visible) {
      this.props.getUserTopics({
        userId: this.props.currentUserId,
        page: 0,
        size: 18,
      });
    }
  }

  handleTopicClick = (id, scope) => () => {
    if ((!this.props.externalLogin || scope === 'EXTERNAL') && this.props.onTopicSelect) {
      this.props.onTopicSelect({ id });
    }
  };

  changePage = page => {
    this.props.getUserTopics({
      userId: this.props.currentUserId,
      page: page - 1,
      size: 18,
    });
  };

  render() {
    const { visible, onCancel, ownUserTopics, ownPageSize, ownPageNumber, ownTotal } = this.props;
    const topicCards =
      ownUserTopics &&
      ownUserTopics.map(topic => {
        if (topic.topic.status === 'ACTIVE') {
          return (
            <div key={topic.id} className={css['Planning-topics-card']}>
              <MaterialCard
                topic={topic.topic}
                externalLogin={this.props.externalLogin}
                onClick={this.handleTopicClick(topic.topic.id, topic.topic.scope)}
                inMyCollection
                hideActionButtons
              />
            </div>
          );
        }
      });
    const pagination =
      ownPageSize && ownTotal && ownTotal > ownPageSize ? (
        <Pagination
          current={ownPageNumber + 1}
          total={ownTotal}
          pageSize={ownPageSize}
          onChange={this.changePage}
          className={css['Planning-pagination']}
        />
      ) : null;

    return (
      <Modal open={visible} onCancel={onCancel} width={900} hideFooter>
        <div className={classNames(css['App-card-item'], css['Planning-topic-modal'])}>
          <div className={css['Planning-topic-modal-header']}>{this.props.t('addToPlan')}</div>
          <div className={css['Planning-topics']}>{topicCards}</div>
          {pagination}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([userTopicsSelect, usersSelect], (userTopics, users) => ({
  ownPageSize: userTopics.ownPageSize,
  ownPageNumber: userTopics.ownPageNumber || 0,
  ownTotal: userTopics.ownTotal,
  ownUserTopics: userTopics.own,
  currentUserId: users.currentUser && users.currentUser.id,
}));

const mapActionsToProps = {
  getUserTopics,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('plan')(MyCollectionModal));
