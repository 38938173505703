import React from 'react';

import { Button, Modal } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import styles from '../styles.module.scss';

const cn = classNames(styles);

const NoteModal = ({
  isModalOpen,
  setIsModalOpen,
  handlerFunc,
  image,
  imageType,
  primaryNoteText,
  secondaryNoteText,
  primaryButtonText,
  secondaryButtonText,
}) => {
  const renderFooter = [
    <>
      {secondaryButtonText && (
        <Button
          onClick={() => setIsModalOpen(false)}
          size='large'
          className={cn('material-card__AcquaintanceModal_btn')}
          key='setIsModalOpen'
        >
          {secondaryButtonText}
        </Button>
      )}
      {primaryButtonText && (
        <Button
          onClick={handlerFunc}
          type='primary'
          size='large'
          className={cn('material-card__AcquaintanceModal_btn')}
          key='handlerFunc'
        >
          {primaryButtonText}
        </Button>
      )}
    </>,
  ];

  return (
    <Modal
      open={isModalOpen}
      className={cn('material-card__AcquaintanceModal')}
      onCancel={() => setIsModalOpen(false)}
      footer={!!primaryButtonText && !!secondaryButtonText ? renderFooter : null}
      width={650}
    >
      <div className={cn('material-card__handshake')}>
        {imageType === 'reactIcons' ? <>{image}</> : <img src={image} alt={`${image}`} />}
      </div>
      <div className={cn('material-card__mentorAcquaintanceModalText')}>
        <span>{primaryNoteText}</span>
        {secondaryNoteText && <span>{secondaryNoteText}</span>}
      </div>
    </Modal>
  );
};

export default NoteModal;
