/* eslint-disable */
import { GET_LANGUAGES_FILTERED_SUCCESS, GET_LANGUAGES_SUCCESS, GET_LOCALES_SUCCESS } from './actions';

const initialState = {
  locales: [],
  languages: [],
  languagesFiltered: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_LOCALES_SUCCESS:
      return {
        ...state,
        locales: response,
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: response,
      };
    case GET_LANGUAGES_FILTERED_SUCCESS:
      return {
        ...state,
        languagesFiltered: response,
      };
    default:
      return state;
  }
};
