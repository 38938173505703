import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, Outlet, RouterProvider, useNavigate, useSearchParams } from 'react-router-dom';

import { eventManagerCourses, eventRequestConfirm, eventRequestRefuse } from '@/store/course/actions';

import LoginLayout, { loginChildren, loginLoader } from '@/pages/Login';
import LoginThrough from '@/pages/Login/LoginThrough';
import NotFound from '@/pages/NotFound';
import UserLayout, { userChildren, userLoader } from '@/pages/UserLayout';
import { DomainSpinner } from '@/components/DomainSpinner';

import ConfirmMessage from '../ConfirmMessage';
import NotAccessGoogle from '../NotFound/NotAccessGoogle';
import SendRefuseModal from '../SendRefuseModal';
import ServerError from '../ServerError';

const Root = () => {
  const { t } = useTranslation('routes');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [context, setContext] = useState({});

  const showModal = success => {
    setContext({
      eventRequest: 'error',
      message: {
        title: success ? t('rejected') : t('changed'),
        subtitle: success ? t('rejectedMail') : t('infoPlan'),
      },
    });
  };

  useLayoutEffect(() => {
    if (searchParams.get('manager-approve')) {
      const managerToken = searchParams.get('manager-approve');

      dispatch(
        eventManagerCourses(
          managerToken,
          '',
          () => {
            setContext({
              eventRequest: 'confirm',
              message: { title: t('agreement'), subtitle: t('confirmMail') },
            });
          },
          () => {
            setContext({
              eventRequest: 'error',
              message: { title: t('changed'), subtitle: t('infoPlan') },
            });
          }
        )
      );
    } else if (searchParams.get('confirm')) {
      const code = searchParams.get('code');
      dispatch(
        eventRequestConfirm(
          code,
          () => {
            setContext({
              eventRequest: 'confirm',
              message: { title: t('confirmed'), subtitle: t('scheduleMail') },
            });
          },
          () => {
            setContext({
              eventRequest: 'error',
              message: { title: t('changed'), subtitle: t('infoPlan') },
            });
          }
        )
      );
    } else if (searchParams.get('refuse')) {
      const code = searchParams.get('code');
      dispatch(
        eventRequestRefuse(
          code,
          () => {
            setContext({
              eventRequest: 'refuse',
              message: { title: t('participationRejected'), subtitle: t('materialInPlan') },
            });
          },
          () => {
            setContext({
              eventRequest: 'error',
              message: { title: t('changed'), subtitle: t('infoPlan') },
            });
          }
        )
      );
    } else if (searchParams.get('manager-reject')) {
      const rejectCode = searchParams.get('manager-reject');
      setContext({ rejectCode, showModal });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(context).length > 0) {
      if (context?.rejectCode) {
        navigate('/refuse');
      } else {
        navigate('/confirm');
      }
    }
  }, [context]);

  return <Outlet context={{ ...context }} />;
};

const RootRoutes = () => {
  const rootRoutes = [
    {
      path: '/',
      element: <Root />,
      ErrorBoundary: ServerError,
      children: [
        {
          path: 'user-login/',
          loader: loginLoader,
          Component: LoginLayout,
          children: loginChildren,
        },
        { path: 'not-access-google', Component: NotAccessGoogle },
        { path: 'confirm', Component: ConfirmMessage },
        { path: 'refuse', Component: SendRefuseModal },
        { path: 'not-found', Component: NotFound },
        { path: 'server-error', Component: ServerError },
        { path: 'login-through', Component: LoginThrough },
        {
          path: '',
          loader: userLoader,
          Component: UserLayout,
          children: userChildren,
        },
      ],
    },
    { path: '*', Component: NotFound },
  ];

  return <RouterProvider router={createBrowserRouter(rootRoutes)} fallbackElement={<DomainSpinner />} />;
};

export default RootRoutes;
