/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Dropdown, Menu, Tooltip } from 'antd';
import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';
import { DownOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import link from '@/assets/images/link.svg';

const POLUS_COMPANY_DOMAIN = 'polyus.kampus.team';
//const POLUS_COMPANY_DOMAIN = 'kampus.local';

class MaterialsLink extends Component {
  static propTypes = {
    absolute: PropTypes.bool,
    topic: PropTypes.object,
    getAlpinaLink: PropTypes.func,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  linkData = topic =>
    topic.links && topic.links[0].name && topic.links[0].name.length > 60 ? (
      <Tooltip title={topic.links[0].name}>{`${topic.links[0].name.substring(0, 60)}...`}</Tooltip>
    ) : (
      topic.links && topic.links[0].name
    );

  alpinaRedirect = link => {
    this.props.getAlpinaLink(link.url, res => res.url && window.open(res.url, '_blank'));
  };

  componentDidMount() {
    const { topic } = this.props;
    if (topic && topic.links && Array.isArray(topic.links)) {
      topic.links.map((linkItem, i) => {
        linkItem.url = this.filterUrl(linkItem.url);
      });
    }
  }

  filterUrl = url => {
    const { currentUser } = this.props;

    if (
      currentUser &&
      currentUser.hasOwnProperty('domainCompany') &&
      currentUser.domainCompany.domain !== null &&
      currentUser.domainCompany.domain === POLUS_COMPANY_DOMAIN &&
      url !== null
    ) {
      if (url.search('tmconsult.ru') !== -1 || url.search('el-tmconsult.ru') !== -1) {
        url = `${url}&fstnm=${currentUser.firstName}&lstnm=${currentUser.lastName}&eml=${currentUser.email}`;
      }
    }

    return url;
  };

  render() {
    const { topic, t } = this.props;
    const links =
      topic &&
      topic.links &&
      topic.links.map((linkItem, i) => {
        if (i !== 0) {
          const linkLabel =
            linkItem.name && linkItem.name.length > 70 ? (
              <Tooltip title={linkItem.name}>{`${linkItem.name.substring(0, 70)}...`}</Tooltip>
            ) : (
              linkItem.name
            );
          return (
            <Menu.Item key={linkItem.id} className={css.LinkItem}>
              {!linkItem.undeletable ? (
                <a href={linkItem.url} target='_blank'>
                  <img src={link} className={css['LinkItem-image']} alt='' />
                  {linkLabel}
                </a>
              ) : (
                <a onClick={() => this.alpinaRedirect(linkItem)}>
                  <img src={link} className={css['LinkItem-image']} alt='' />
                  {linkLabel}
                </a>
              )}
            </Menu.Item>
          );
        }
      });
    const menuLinks = <Menu className={css.Dropdown}>{links}</Menu>;

    return (
      <div
        className={classNames(css.LinkBlock, {
          [css.LinkAbsolute]: this.props.absolute,
        })}
      >
        <div className={css['LinkBlock-label']}>{t('link')}</div>

        <div className={css['LinkBlock-link']}>
          {topic.links && !topic.links[0].undeletable ? (
            <a
              data-qa='detailCollectionLinkItem'
              className={css['LinkBlock-link-name']}
              href={topic.links && topic.links[0].url}
              target='_blank'
            >
              {this.linkData(topic)}
            </a>
          ) : (
            <a className={css['LinkBlock-link-name']} onClick={() => this.alpinaRedirect(topic.links[0])}>
              {this.linkData(topic)}
            </a>
          )}

          {topic.links && topic.links.length > 1 ? (
            <Dropdown
              overlayClassName={css['LinkBlock-link-overlay']}
              overlay={menuLinks}
              trigger={['click']}
              placement='bottomRight'
            >
              <div className={css['LinkBlock-link-count']}>
                {t('more', { count: topic.links.length - 1 })} <DownOutlined />
              </div>
            </Dropdown>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation('materialsLink')(MaterialsLink);
