import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { createFile } from '@/store/files/actions';

import { Button, notification, Tooltip } from 'antd';
import { FiPaperclip } from 'react-icons/fi';

import { DOC_TYPES, IMAGE_TYPES } from '@shared/constants/image-types';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const MAX_FILE_SIZE = 200000000; // 20 МГ (число в байтах)

const FileInput = ({ isButton, setFileData }) => {
  const { t } = useTranslation('chat');

  const dispatch = useDispatch();

  const handleError = file => {
    const defaultError = {
      message: t('error.1'),
      description: t('error.2'),
    };

    if (file.size > MAX_FILE_SIZE) {
      defaultError.description = t('error.3');
    }

    notification.error(defaultError);
  };

  const handleSelectFile = event => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    if (file?.type || file?.size || file?.name) {
      if ((!DOC_TYPES.includes(file.type) && !IMAGE_TYPES.includes(file.type)) || file.size > MAX_FILE_SIZE) {
        handleError(file);
      } else {
        dispatch(
          createFile(
            formData,
            resFile => {
              const data = {
                fileName: resFile.name,
                fileUuid: resFile.uuid,
                fileType: file.type,
              };
              if (file.type.includes('image')) {
                setFileData({
                  ...data,
                  fileBlob: file,
                });
              } else {
                setFileData(data);
              }
            },
            () => {
              handleError(file);
            }
          )
        );
      }
    }
  };

  return (
    <div className={classNames([styles.fileInput], isButton && [styles.fileInput__isButton])}>
      <Tooltip title={t('maxFileSize')}>
        {isButton ? <Button>{t('add')}</Button> : <FiPaperclip className={styles.fileInput__icon} />}
        <input
          type='file'
          onClick={event => (event.target.value = null)}
          onChange={handleSelectFile}
          className={isButton ? styles.fileInput__inputButton : styles.fileInput__input}
        />
      </Tooltip>
    </div>
  );
};

FileInput.propTypes = {
  isButton: PropTypes.bool,
  setFileData: PropTypes.func,
};

FileInput.defaultProps = {
  isButton: false,
};

export default memo(FileInput);
