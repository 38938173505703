export const CREATE_NEWS_COMMENT_START = 'CREATE_NEWS_COMMENT_START';
export const CREATE_NEWS_COMMENT_SUCCESS = 'CREATE_NEWS_COMMENT_SUCCESS';
export const CREATE_NEWS_COMMENT_FAIL = 'CREATE_NEWS_COMMENT_FAIL';

export const READ_NEWS_COMMENT_START = 'READ_NEWS_COMMENT_START';
export const READ_NEWS_COMMENT_SUCCESS = 'READ_NEWS_COMMENT_SUCCESS';
export const READ_NEWS_COMMENT_FAIL = 'READ_NEWS_COMMENT_FAIL';

export const UPDATE_NEWS_COMMENT_START = 'UPDATE_NEWS_COMMENT_START';
export const UPDATE_NEWS_COMMENT_SUCCESS = 'UPDATE_NEWS_COMMENT_SUCCESS';
export const UPDATE_NEWS_COMMENT_FAIL = 'UPDATE_NEWS_COMMENT_FAIL';

export const DELETE_NEWS_COMMENT_START = 'DELETE_NEWS_COMMENT_START';
export const DELETE_NEWS_COMMENT_SUCCESS = 'DELETE_NEWS_COMMENT_SUCCESS';
export const DELETE_NEWS_COMMENT_FAIL = 'DELETE_NEWS_COMMENT_FAIL';

export const RESTORE_NEWS_COMMENT_START = 'RESTORE_NEWS_COMMENT_START';
export const RESTORE_NEWS_COMMENT_SUCCESS = 'RESTORE_NEWS_COMMENT_SUCCESS';
export const RESTORE_NEWS_COMMENT_FAIL = 'RESTORE_NEWS_COMMENT_FAIL';

export const GET_COUNT_NEWS_COMMENT_START = 'GET_COUNT_NEWS_COMMENT_START';
export const GET_COUNT_NEWS_COMMENT_SUCCESS = 'GET_COUNT_NEWS_COMMENT_SUCCESS';
export const GET_COUNT_NEWS_COMMENT_FAIL = 'GET_COUNT_NEWS_COMMENT_FAIL';

export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const GET_NEWS_FOR_WIDGET_START = 'GET_NEWS_FOR_WIDGET_START';
export const GET_NEWS_FOR_WIDGET_SUCCESS = 'GET_NEWS_FOR_WIDGET_SUCCESS';
export const GET_NEWS_FOR_WIDGET_FAIL = 'GET_NEWS_FOR_WIDGET_FAIL';

export const GET_NEWS_BY_ID_START = 'GET_NEWS_BY_ID_START';
export const GET_NEWS_BY_ID_SUCCESS = 'GET_NEWS_BY_ID_SUCCESS';
export const GET_NEWS_BY_ID_FAIL = 'GET_NEWS_BY_ID_FAIL';

export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';

export const SET_RATE_START = 'SET_RATE_START';
export const SET_RATE_SUCCESS = 'SET_RATE_SUCCESS';
export const SET_RATE_FAIL = 'SET_RATE_FAIL';

export const RECOMMENDED_USERS_NEWS_START = 'RECOMMENDED_USERS_NEWS_START';
export const RECOMMENDED_USERS_NEWS_SUCCESS = 'RECOMMENDED_USERS_NEWS_SUCCESS';
export const RECOMMENDED_USERS_NEWS_FAIL = 'RECOMMENDED_USERS_NEWS_FAIL';

export const SET_VIEWED_START = 'SET_VIEWED_START';
export const SET_VIEWED_SUCCESS = 'SET_VIEWED_SUCCESS';
export const SET_VIEWED_FAIL = 'SET_VIEWED_FAIL';

export const CHANGE_WIDGET_NEWS_MODAL_VISIBLE = 'CHANGE_WIDGET_NEWS_MODAL_VISIBLE';

export const CLEAR_NEWS_ID = 'CLEAR_NEWS_ID';

export const UPDATE_NEWS_VISIBILITY_START = 'UPDATE_NEWS_VISIBILITY_START';
export const UPDATE_NEWS_VISIBILITY_SUCCESS = 'UPDATE_NEWS_VISIBILITY_SUCCESS';
export const UPDATE_NEWS_VISIBILITY_FAIL = 'UPDATE_NEWS_VISIBILITY_FAIL';
