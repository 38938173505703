// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-s2rkzz.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-dNdwrN .user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-label-_RdBIr[data-value="Montserrat"]:before, .user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-s2rkzz.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-dNdwrN .user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-item-CwZu6T[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-Montserrat-BToM2E {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Header-result-cgwTYY {
  margin: 0 16px;
}

.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-VDIQmO {
  font-size: 16px;
}

.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info-oMZP7r {
  margin: 24px 0 16px;
}

.user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info2-iKXmtz {
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/DiagnosticTalentQ/CheckResult/checkResult.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,cAAc;AAChB;;AAOA;EAJE,eAAe;AACjB;;AAMA;EAHE,mBAAmB;AACrB;;AAKA;EAFE,mBAAmB;AACrB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Header-result {\n  margin: 0 16px;\n}\n\n.Description {\n  font-size: 16px;\n}\n.Description-info {\n  margin: 24px 0 16px;\n}\n.Description-info2 {\n  margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-s2rkzz`,
	"qlPicker": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-s2rkzz`,
	"ql-font": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-dNdwrN`,
	"qlFont": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-dNdwrN`,
	"ql-picker-label": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-label-_RdBIr`,
	"qlPickerLabel": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-label-_RdBIr`,
	"ql-picker-item": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-item-CwZu6T`,
	"qlPickerItem": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-picker-item-CwZu6T`,
	"ql-font-Montserrat": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-Montserrat-BToM2E`,
	"qlFontMontserrat": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__ql-font-Montserrat-BToM2E`,
	"Header-result": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Header-result-cgwTYY`,
	"headerResult": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Header-result-cgwTYY`,
	"Description": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-VDIQmO`,
	"description": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-VDIQmO`,
	"Description-info": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info-oMZP7r`,
	"descriptionInfo": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info-oMZP7r`,
	"Description-info2": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info2-iKXmtz`,
	"descriptionInfo2": `user-src-pages-DiagnosticTalentQ-CheckResult-checkResult-module__Description-info2-iKXmtz`
};
export default ___CSS_LOADER_EXPORT___;
