/* eslint-disable */
import React, { useState } from 'react';

import Modal from '@/components/Modal';

import RulesModal from './RulesModal';
import UserInfoModal from './UserInfoModal';
import WelcomeModal from './WelcomeModal';

import PropTypes from 'prop-types';

export default function ProfileModal(props) {
  const [step, setStep] = useState(0);
  const [visible, changeVisible] = useState(true);

  const goToNextStep = () => {
    if (step >= 2) {
      changeVisible(false);
      return;
    }
    setStep(step + 1);
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return <WelcomeModal goToNextStep={goToNextStep} />;
      case 1:
        return <RulesModal goToNextStep={goToNextStep} />;
      case 2:
        return <UserInfoModal onCancel={props.onCancel} />;
      default:
        return null;
    }
  };

  return (
    <Modal open={props.visible && visible} width={683} hideFooter>
      {renderContent()}
    </Modal>
  );
}
