/* eslint-disable */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserIncome } from '@/store/income/actions';

import { selectIncome as incomeSelect } from '@/store/income/selectors';

import { Col, Pagination, Row } from 'antd';
import { Card } from '@/components/Layout';
import Modal from '@/components/Modal';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import CoinHistoryRow from './CoinHistoryRow';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CoinHistoryModal.module.scss';

// Мои баллы
export class CoinHistoryModal extends Component {
  static propTypes = {
    getUserIncome: PropTypes.func,
    onCancel: PropTypes.func,
    student: PropTypes.number,
    userIncome: PropTypes.array.isRequired,
    userIncomePageNumber: PropTypes.number,
    userIncomePageSize: PropTypes.number,
    userIncomeTotal: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.props.getUserIncome(this.props.student, 0);

    window.addEventListener('resize', this.updateMobileState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMobileState);
  }

  changePageCoin = event => {
    this.props.getUserIncome(this.props.student, event - 1);
  };

  updateMobileState = () => {
    if (window.innerWidth < 767) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const { userIncome, t, ...props } = this.props;

    const coinRows = userIncome.map(ui => <CoinHistoryRow key={ui.id} userIncome={ui} t={this.props.t} />);

    const cardSkeleton = (date, amount, comment, productId, orderStatus) => (
      <div className={css['HistoryCoin-cardSkeletonContainer']}>
        <div>
          <div className={css['HistoryCoin-cardSkeletonContainer-label']}>{t('date')}</div>
          <div className={css['HistoryCoin-cardSkeletonContainer-text']}>{dayjs(date).format('DD.MM.YYYY')}</div>
        </div>
        <div>
          <div className={css['HistoryCoin-cardSkeletonContainer-label']}>{t('operation')}</div>
          <div className={css['HistoryCoin-cardSkeletonContainer-text']}>
            {amount > 0 ? t('income') : t('outcome')}: {Math.abs(amount)} {t('coins')}
          </div>
        </div>
        <div>
          <div className={css['HistoryCoin-cardSkeletonContainer-label']}>{t('comment')}</div>
          <div className={css['HistoryCoin-cardSkeletonContainer-text']}>
            {productId ? <a href={`/shop/${productId}`}>{comment}</a> : comment}
            <br />
            {orderStatus && t('orderStatus', { status: t(orderStatus) })}
          </div>
        </div>
      </div>
    );

    const paginationCoin = (
      <Pagination
        className={css['HistoryCoin-pagination']}
        align='center'
        current={this.props.userIncomePageNumber + 1}
        total={this.props.userIncomeTotal}
        pageSize={this.props.userIncomePageSize}
        onChange={this.changePageCoin}
        showSizeChanger={false}
      />
    );

    return (
      <Modal width={800} className={css['Students-modal']} {...props} hideFooter>
        <Row gutter={1} className={css['Students-profile']}>
          <Col span={24} className={css['Students-profile-col']}>
            <Card className={css['Students-profile-card']}>
              <h2>{t('history')}</h2>
              {/* <p className={css['HistoryCoin-warning']}>{t('soon')}</p> */}
              {this.state.isMobile ? (
                <Scrollbars ref={this.scrollbar} autoHide className={css['HistoryCoin-scrollbar']}>
                  <div className={css['HistoryCoin-cardSkeletonWrapper']}>
                    {userIncome?.map(({ id, date, amount, comment, productId, orderStatus }) => (
                      <div key={id}>{cardSkeleton(date, amount, comment, productId, orderStatus)}</div>
                    ))}
                  </div>
                </Scrollbars>
              ) : (
                <table className={css['HistoryCoin-table']}>
                  <thead className={css['HistoryCoin-table-head']}>
                    <tr>
                      <th>{t('date')}</th>
                      <th>
                        <ArrowLeftOutlined />
                      </th>
                      <th>
                        <ArrowRightOutlined />
                      </th>
                      <th>{t('comment')}</th>
                    </tr>
                  </thead>
                  <tbody className={css['HistoryCoin-table-body']}>{coinRows}</tbody>
                </table>
              )}

              {this.props.userIncomeTotal === 0 ? '' : paginationCoin}
            </Card>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(incomeSelect, income => ({
  userIncome: income.userIncome,
  userIncomePageNumber: income.userIncomePageNumber,
  userIncomePageSize: income.userIncomePageSize,
  userIncomeTotal: income.userIncomeTotal,
}));

const mapActionsToProps = {
  getUserIncome,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('coinHistoryModal')(CoinHistoryModal));
