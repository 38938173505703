/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getReportPdf } from '@/store/talentq/actions';

import { selectTalentQ } from '@/store/talentq/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Divider, Row, Tooltip } from 'antd';
import { FcBullish } from 'react-icons/fc';

import CompilationTalentQ from '../CompilationTalentQ/CompilationTalentQ';
import ResultTalentQ from '../ResultTalentQ/ResultTalentQ';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './checkResult.module.scss';

export const CheckResult = props => {
  const { t } = props;
  const [isCompilation, setIsCompilation] = useState(true);
  const [isResultTQ, setIsResultTQ] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.currentUser && (props.currentUser.firstName || props.currentUser.lastName)) {
      setName(`${props.currentUser.firstName} ${props.currentUser.lastName}`);
    }
  }, [props.currentUser]);

  const nameReport = `Report ${name}.pdf`;

  const onShowResult = () => {
    setIsCompilation(false);
    setIsResultTQ(true);
  };

  const onShowCompilation = () => {
    setIsCompilation(true);
    setIsResultTQ(false);
  };

  const handleDownloadReport = () => {
    props.getReportPdf(blob => {
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = url;
      link.download = nameReport;
      link.click();
    });
  };

  const render = () => {
    return (
      <>
        <Row justify='space-between' className={css['Header']}>
          <Col>
            <h1>
              <FcBullish />
              <strong>&nbsp;{t('assesment')}</strong>
            </h1>
          </Col>
          <Col>
            <Tooltip title={t('compilationsTooltip')}>
              <Button type='primary' onClick={() => onShowCompilation()} disabled={props.isLoadingRec}>
                {t('compilations')}
              </Button>
            </Tooltip>
            <Tooltip title={t('resultsTooltip')}>
              <Button
                className={css['Header-result']}
                type='primary'
                onClick={() => onShowResult()}
                disabled={props.isLoadingRec}
              >
                {t('results')}
              </Button>
            </Tooltip>
            <Tooltip title={t('reportTooltip')}>
              <Button type='primary' onClick={() => handleDownloadReport()} disabled={props.isLoadingRec}>
                &nbsp;
                {t('report')}
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Divider />

        <div className={css['Description']}>
          <div className={css['Description-info']}>{t('info')}</div>
          <div className={css['Description-info2']}>{t('info2')}</div>
        </div>
        <Divider />

        {isCompilation && <CompilationTalentQ />}
        {isResultTQ && <ResultTalentQ />}
      </>
    );
  };

  return render();
};

CheckResult.propTypes = {
  t: PropTypes.func.isRequired,
  getReportPdf: PropTypes.func,
  currentUser: PropTypes.object,
  isLoadingRec: PropTypes.bool,
};

const mapStateToProps = createSelector([selectUsers, selectTalentQ], (users, selectTalentQ) => ({
  currentUser: users.currentUser,
  isLoadingRec: selectTalentQ.isLoadingRec,
}));

const mapActionsToProps = { getReportPdf };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticTalentq')(CheckResult));
