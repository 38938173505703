/* eslint-disable */
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getScorm,
  sendScormBlockStatistics,
  setScormBlockFinish,
  setScormBlockRestart,
  setScormBlockStart,
} from '@/store/lms/actions';

import { selectLMS } from '@/store/lms/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';

import { Button, Spin } from 'antd';
import FullScreenButton from '@/components/FullScreenButton';

import { API_BASE } from '@shared/constants/config';

import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './index.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const ScormSettings = {
  dataCommitFormat: 'flattened',
  commitRequestDataType: 'application/json;charset=UTF-8',
  logLevel: 5,
  xhrHeaders: {},
};

export const Scorm = ({
  block,
  getStatistic,
  currentUser,
  topicId,
  getBlockStatistic,
  canRestartBlocks,
  getStatisticStart,
}) => {
  const { t } = useTranslation('scorm');
  const dispatch = useDispatch();

  const { statistic } = useSelector(selectLMS);
  const { studyPlanId } = useSelector(selectStudyPlan);

  const [srcScorm, setSrcScorm] = useState('');
  const containerIframeRef = useRef(null);
  const userData = useRef({});
  const [canPass, setCanPass] = useState(false);
  const [isLoadingPass, setLoadingPass] = useState(false);

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  const sendData = isResult => {
    dispatch(
      sendScormBlockStatistics(
        block.blockId,
        userData.current,
        () => {
          const [plan] = studyPlanId;

          getBlockStatistic({
            topicId,
            userId: currentUser.id,
            planId: null || plan?.id,
            onSuccess: () => {
              if (isResult) {
                setLoadingPass(false);
              }
            },
          });
        },
        () => setLoadingPass(false)
      )
    );
  };

  const actionBeforeUnLoad = event => {
    if (block.status !== 'finished') {
      sendData();
      if (event) {
        event.preventDefault();
        event.returnValue = '';
      }
    }
  };

  useLayoutEffect(() => {
    let scorm = {
      api: {},
      prefix: '',
      type: '2004',
      isOnceFinishing: true,
    };

    const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

    dispatch(
      getScorm(block.scorm.scormId, actualScormData => {
        console.log('actualScormData --- block ---', block);
        if (actualScormData?.version === 'VERSION_1_2') {
          scorm.api = window.API = new Scorm12API(ScormSettings);
          scorm.prefix = 'LMS';
          scorm.type = 'VERSION_1_2';
        } else {
          scorm.api = window.API_1484_11 = new Scorm2004API(ScormSettings);
        }

        const objectJSON = JSON.parse(block.scorm.scormData) || {};

        const initScormJSON = {
          ...objectJSON,
          learner_id: currentUser.id,
          learner_name: `${currentUser.firstName} ${currentUser.lastName}`,
        };

        console.log('initScormJSON ---->', initScormJSON);

        let scormJSON = initScormJSON;

        // Initialize scorm data
        scorm.api.loadFromJSON(initScormJSON);

        userData.current = {
          scoreMin: Number.isNaN(block.scorm?.scoreMin) ? 0 : block.scorm.scoreMin,
          scoreMax: Number.isNaN(block.scorm?.scoreMax) ? 0 : block.scorm.scoreMax,
          scoreRow: Number.isNaN(block.scorm?.scoreRow) ? 0 : block.scorm.scoreRow,
          percentPassing: Number.isNaN(block.scorm?.percentPassing) ? 0 : block.scorm.percentPassing,
          progressMeasure: block.scorm?.progressMeasure ? block.scorm.progressMeasure : '0',
          status: block.scorm.status || 'unknown',
          successStatus: block.scorm.successStatus || 'unknown',
          scormData: block.scorm.scormData,
        };

        const isPassIsRequired = () =>
          block.scorm.requiredTestPassing &&
          block.scorm.successStatus &&
          (!block.scorm.requiredFullCompletion || userData.current.status === 'completed');

        const isCompletedRequired = () =>
          block.scorm.requiredFullCompletion &&
          userData.current.status === 'completed' &&
          !block.scorm.requiredTestPassing;

        const updateUserScormData = (CMIElementRAW, value) => {
          const CMIElement = CMIElementRAW.slice(0, 3) === 'cmi' ? CMIElementRAW.slice(4) : CMIElementRAW;

          console.warn(' CMIElementRAW -------- ', CMIElementRAW, ' = ', value);

          scormJSON = scorm.api.cmi.toJSON();

          if (CMIElement === 'score.scaled' && value > userData.current.percentPassing) {
            userData.current.percentPassing = value;
          }

          if (CMIElement === 'score.raw' && value > userData.current.scoreRow)
            userData.current.scoreRow = Math.round(value);
          if (CMIElement === 'score.min' && value > userData.current.scoreMin) userData.current.scoreMin = value;
          if (CMIElement === 'score.max' && value > userData.current.scoreMax) userData.current.scoreMax = value;

          if (
            block.scorm?.status !== 'completed' &&
            (CMIElement === 'completion_status' || CMIElement === 'core.completion_status')
          ) {
            userData.current.status = value;
            // userData.current.successStatus = 'passed'; // KAM-6164 надо проверить скорм
          }

          if (
            block.scorm?.successStatus !== 'passed' &&
            (CMIElement === 'success_status' || CMIElement === 'lesson_status' || CMIElement === 'core.lesson_status')
          ) {
            userData.current.successStatus = value;
            if (value === 'passed') userData.current.status = 'completed'; //Костыль для некоторых скормов которые со статусмо successStatus = passed не возвращают статус completion_status = completed
          }

          console.log('update scorm JSON --->>>', scormJSON, scorm.api.cmi);
          userData.current.scormData = JSON.stringify(scormJSON);

          if ((isPassIsRequired() || isCompletedRequired()) && scorm.isOnceFinishing) {
            scorm.isOnceFinishing = false;
            setCanPass(true);
            setLoadingPass(true);
            sendData('finished');
          }
        };

        scorm.api.on(`${scorm.prefix}SetValue.*`, updateUserScormData);

        if (!block.requiredPassing) {
          setCanPass(true);
        } else {
          scorm.api.on(`${scorm.prefix}Finish`, () => {
            updateUserScormData('completion_status', 'completed');
          });
          window.addEventListener('beforeunload', actionBeforeUnLoad);
        }

        if (isLocalhost)
          setSrcScorm('/data/scormdriver/indexAPI.html'); // setSrcScorm(import.meta.env.PUBLIC_APP_API_BASE + actualScormData?.rootFile);
        else setSrcScorm(API_BASE + actualScormData?.rootFile);
      })
    );

    if (block.isAvailableForStart) {
      dispatch(
        setScormBlockStart(block.blockId, () => {
          getStatisticStart();
        })
      );
    }

    return () => {
      // Сохраняем текущее состояние прохождения
      actionBeforeUnLoad();
      if (scorm.type === 'VERSION_1_2') delete window.API;
      else delete window.API_1484_11;

      window.removeEventListener('beforeunload', actionBeforeUnLoad);
    };
  }, []);

  const finishScormBlock = () => {
    dispatch(setScormBlockFinish(block.blockId, () => getStatistic(next <= 1)));
  };

  const restartScormBlock = () => {
    dispatch(setScormBlockRestart(block.blockId, () => getStatistic(true)));
    dispatch(getScorm(block.scorm.scormId));
  };

  return (
    <div className={css.ScormWrapper}>
      <div className={css.ScormTypeTitle}>SCORM</div>
      <div className={css.ScormTitle}>
        {block.name}
        {srcScorm && <FullScreenButton iframeRef={containerIframeRef} />}
      </div>
      <div className={css.ScormPlayer}>
        <iframe
          ref={containerIframeRef}
          className={css.CustomFrame}
          title='scormPlayer'
          src={srcScorm}
          height='100%'
          width='100%'
        />
      </div>
      <div className={css.ScormButtons}>
        {block.status?.toLowerCase() !== 'finished' && (
          <Spin size='large' spinning={isLoadingPass}>
            <Button disabled={!canPass} onClick={finishScormBlock} type='primary' size='large'>
              {next > 1 ? t('next') : t('end')}
            </Button>
          </Spin>
        )}

        {block.status?.toLowerCase() === 'finished' && canRestartBlocks && (
          <Button onClick={restartScormBlock} type='primary' size='large'>
            {t('repeat')}
          </Button>
        )}
      </div>
    </div>
  );
};

Scorm.propTypes = {
  block: PropTypes.object,
  getStatistic: PropTypes.func,
  currentUser: PropTypes.object,
  topicId: PropTypes.string,
  getBlockStatistic: PropTypes.func,
};
