import { API_D360_URL } from '../api_service';
import { D360_GET_REPORT_FAIL, D360_GET_REPORT_START, D360_GET_REPORT_SUCCESS } from './constants';

import { POST, REST_API } from '@shared/constants/rest-api';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360GetReport = (id, onSuccess, onFail) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/employee/report`,
    body: {
      id: id,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_GET_REPORT_START, D360_GET_REPORT_SUCCESS, D360_GET_REPORT_FAIL],
    onSuccess,
    onFail,
  };
};
