import { API_URL } from '@shared/constants/config';

import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

class ConnectStompClient {
  constructor(endpoint, attempts, retryInterval, newNotification, setUnReadNotifications) {
    this.endpoint = endpoint;
    this.attempts = attempts;
    this.retryInterval = retryInterval;
    this.newNotification = newNotification;
    this.setUnReadNotifications = setUnReadNotifications;
    this.stompClient = null;

    this.connect();
  }

  async connect() {
    this.attempts -= 1;

    if (this.attempts > 0) {
      const socket = new SockJS(
        (window.location.hostname === 'localhost' ? 'https://kampus.local/api/v1' : API_URL) +
          '/ws?access_token=' +
          this.endpoint
      );

      this.stompClient = new Client({
        webSocketFactory: () => socket,
        debug: () => null,
        reconnectDelay: this.retryInterval,
        onConnect: () => {
          console.log('Stomp over WebSocket connection established');

          this.stompClient.subscribe('/topic/notifications', notification => {
            const message = JSON.parse(notification.body);
            this.newNotification(message[0]);
          });

          this.stompClient.subscribe('/user/queue/notifications', notification => {
            const listNotification = JSON.parse(notification.body);

            if (listNotification.length > 0) {
              this.newNotification(listNotification[0]);
            }

            this.setUnReadNotifications(listNotification);
          });

          this.stompClient.subscribe('/user/queue/notification', notification => {
            const newNotification = JSON.parse(notification.body);
            this.newNotification(newNotification);
            this.setUnReadNotifications(newNotification, true);
          });

          const body = { page: 0, size: 99 };
          this.stompClient.publish({ destination: '/app/notifications/fetch', body: JSON.stringify(body) });
        },
        onStompError: frame => {
          console.error('Broker reported error: ' + frame.headers['message']);
          console.error('Additional details: ' + frame.body);
          setTimeout(this.connect.bind(this), this.retryInterval);
        },
        onWebSocketClose: () => {
          console.log('Stomp over WebSocket connection closed');
          setTimeout(this.connect.bind(this), this.retryInterval);
        },
      });

      if (!this.endpoint) {
        console.log('endpoint', this.endpoint);
      }

      this.stompClient.activate();
    } else {
      console.log('SockJS and Stomp connection attempts exceeded.');
    }
  }

  disconnect() {
    if (this.stompClient) {
      this.stompClient.deactivate();
    }
  }
}

export default ConnectStompClient;
