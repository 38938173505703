import ImageComponent from './ImageComponent';

import classNames from 'classnames';

import css from './MatchingQuestion.module.scss';

const Card = props => {
  const innerContent = props.isText ? (
    <foreignObject
      x='10%'
      y='4%'
      width='85%'
      height='90%'
      className={classNames(css['foreignObject'], css['question-card-text'])}
    >
      {props.content}
    </foreignObject>
  ) : (
    <foreignObject x='10%' y='7%' width='80%' height='85%' className={css['foreignObject']}>
      <ImageComponent src={props.content} />
    </foreignObject>
  );

  return props.inResults ? (
    <svg
      width='300'
      height='200'
      viewBox='0 0 300 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={css['question-card']}
    >
      <g clipPath='url(#clip0_48_50)'>
        <path
          d='M241 18C241 8.05887 249.059 0 259 0H280.211C291.561 0 300.078 10.376 297.866 21.5081L282.967 96.4919C282.506 98.808 282.506 101.192 282.967 103.508L297.866 178.492C300.078 189.624 291.561 200 280.211 200H259C249.059 200 241 191.941 241 182V18Z'
          fill='#AEAEAE'
        />
        <path
          d='M277.481 0C289.131 0 297.868 10.6581 295.584 22.0817L280.724 96.3795C280.246 98.7695 280.246 101.231 280.724 103.621L295.584 177.918C297.868 189.342 289.131 200 277.481 200H18.4611C8.26533 200 0 191.735 0 181.539V18.4612C0 8.26535 8.26534 0 18.4611 0H277.481Z'
          fill='#D9D9D9'
        />
      </g>
      {innerContent}
    </svg>
  ) : (
    <svg
      width='331'
      height='200'
      viewBox='0 0 331 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className={css['question-card']}
    >
      <path
        d='M264 18C264 8.05887 272.059 0 282 0H303.211C314.561 0 323.078 10.376 320.866 21.5081L305.967 96.4919C305.506 98.808 305.506 101.192 305.967 103.508L320.866 178.492C323.078 189.624 314.561 200 303.211 200H282C272.059 200 264 191.941 264 182V18Z'
        fill='#AEAEAE'
      />
      <path
        d='M300.481 0C312.131 0 320.868 10.6581 318.584 22.0817L303.724 96.3795C303.246 98.7695 303.246 101.231 303.724 103.621L318.584 177.918C320.868 189.342 312.131 200 300.481 200H41.4611C31.2653 200 23 191.735 23 181.539V18.4612C23 8.26535 31.2653 0 41.4611 0H300.481Z'
        fill='#D9D9D9'
      />
      {innerContent}
    </svg>
  );
};

const QuestionCard = ({ question, inResults }) => {
  const content = <Card isText={question.text} content={question.content} inResults={inResults} />;

  return content;
};

export default QuestionCard;
