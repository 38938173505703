/* eslint-disable */
import { useTranslation } from 'react-i18next';

import { GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_SUCCESS, LOGOUT_GOOGLE_FAIL, LOGOUT_GOOGLE_SUCCESS } from '../login/actions';
import {
  CANCEL_STUDY_PLAN,
  CREATE_STUDY_PLAN_FAIL,
  CREATE_STUDY_PLAN_SUCCESS,
  DELETE_STUDY_PLAN_SUCCESS,
  FINISH_ALL_PLANS_FAIL,
  FINISH_ALL_PLANS_SUCCESS,
  UPDATE_STUDY_PLAN_DATE_FAIL,
  UPDATE_STUDY_PLAN_DATE_SUCCESS,
  UPDATE_STUDY_PLAN_FINISHED,
  UPDATE_STUDY_PLAN_STATUS_FAIL,
} from '../study-plan/actions';
import {
  POST_TOPIC_FAIL,
  POST_TOPIC_SUCCESS,
  TOPIC_VALIDATION_FAIL,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_SUCCESS,
} from '../topics/actions';
import { CHECK_FINISH_TEST_FAIL } from '../trajectory/actions';
import {
  POST_USER_TOPICS_FAIL,
  POST_USER_TOPICS_SUCCESS,
  SEND_NOTIFICATION_DEFAULT,
  SEND_NOTIFICATION_PLANNED,
  SEND_NOTIFICATION_RECOMMENDED,
} from '../user-topics/actions';

import dayjs from 'dayjs';

const initialState = {
  message: '',
  type: '',
};

export default (state = initialState, { type, action, errorMessage }) => {
  const selectedMonth = dayjs(action?.month, 'YYYY-MM-DD').format('MMMM YYYY') || '';

  switch (type) {
    case POST_TOPIC_SUCCESS:
      return {
        message: 'Поздравляем! Новый материал загружен в Базу знаний',
        type: 'success',
        action: type,
      };

    case UPDATE_TOPIC_SUCCESS:
      return {
        message: 'Материал успешно изменен',
        type: 'success',
        action: type,
      };

    case TOPIC_VALIDATION_FAIL:
      return {
        message: errorMessage || 'Внимание! Заполните все обязательные поля',
        errorMessage: errorMessage,
        type: 'error',
        action: type,
      };

    case POST_TOPIC_FAIL:
      return {
        message: 'При создании темы возникла ошибка',
        type: 'error',
        action: type,
      };

    case UPDATE_TOPIC_FAIL:
      return {
        message: 'При редактировании темы возникла ошибка',
        type: 'error',
        action: type,
      };

    case GOOGLE_LOGIN_FAIL:
      return {
        message: 'Данный аккаунт уже привязан к другому пользователю',
        type: 'error',
        action: type,
      };

    case GOOGLE_LOGIN_SUCCESS:
      return {
        message: 'Аккаунт успешно привязан',
        type: 'success',
        action: type,
      };

    case LOGOUT_GOOGLE_FAIL:
      return {
        message: 'При отвязке произошла ошибка',
        type: 'error',
        action: type,
      };

    case LOGOUT_GOOGLE_SUCCESS:
      return {
        message: 'Аккаунт успешно отвязан',
        type: 'success',
        action: type,
      };

    case CREATE_STUDY_PLAN_SUCCESS:
      return {
        message: `Поздравляем! Материал добавлен в ${selectedMonth}`,
        data: selectedMonth,
        type: 'success',
        action: type,
      };

    case CREATE_STUDY_PLAN_FAIL:
      return {
        message: `Внимание! Данный материал уже включен в ${selectedMonth}`,
        data: selectedMonth,
        type: 'warning',
        action: type,
      };

    case CANCEL_STUDY_PLAN:
      return {
        message: 'В плане больше нет материалов.',
        type: 'warning',
        action: type,
      };

    case UPDATE_STUDY_PLAN_DATE_SUCCESS:
      return {
        message: `Поздравляем! Материал перенесен в ${selectedMonth}`,
        data: selectedMonth,
        type: 'success',
        action: type,
      };

    case UPDATE_STUDY_PLAN_DATE_FAIL:
      return {
        message: `Внимание! Данный материал уже включен в ${selectedMonth}`,
        data: selectedMonth,
        type: 'warning',
        action: type,
      };
    case UPDATE_STUDY_PLAN_STATUS_FAIL:
      return {
        message: 'Внимание! Данный материал уже выполнен!',
        type: 'warning',
        action: type,
      };
    case UPDATE_STUDY_PLAN_FINISHED:
      return {
        message: 'Поздравляем! Данный материал выполнен!',
        type: 'success',
        action: type,
      };
    case FINISH_ALL_PLANS_FAIL:
      return {
        message: 'Внимание! Материал уже выполнен в следующих месяцах!',
        type: 'warning',
        action: type,
      };
    case FINISH_ALL_PLANS_SUCCESS:
      return {
        message: 'Материал удалён из плана в следующих месяцах!',
        type: 'success',
        action: type,
      };
    case POST_USER_TOPICS_SUCCESS:
      return {
        message: 'Поздравляем! Материал добавлен в Избранное',
        type: 'success',
        action: type,
      };

    case POST_USER_TOPICS_FAIL:
      return {
        message: 'Данный материал уже добавлен в Избранное',
        type: 'warning',
        action: type,
      };

    case DELETE_STUDY_PLAN_SUCCESS:
      return {
        message: 'Материал удален из плана указанного месяца',
        type: 'success',
        action: type,
      };

    case SEND_NOTIFICATION_RECOMMENDED:
      return {
        message: 'Спасибо, данный материал отобразится в списке рекомендаций у выбранных пользователей',
        type: 'success',
        action: type,
      };

    case SEND_NOTIFICATION_PLANNED:
      return {
        message: `${action.user} уже добавил данный материал в свой план`,
        data: action.user,
        type: 'warning',
        action: type,
      };

    case SEND_NOTIFICATION_DEFAULT:
      return {
        message: `Вы уже рекомендовали этот материал пользователю ${action.user}`,
        data: action.user,
        type: 'warning',
        action: type,
      };

    case CHECK_FINISH_TEST_FAIL:
      return {
        message: 'Тест не пройден, необходимо завершить прохождение теста',
        type: 'error',
        action: type,
      };
    default:
      return state;
  }
};
