import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'antd';

import DatePicker from '@shared/components/DatePicker';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import Arrow from '@/assets/images/blue-arrow-up.svg';
import ExtLink from '@/assets/images/ext-linkl.svg';

const { TextArea } = Input;

// TODO: Доработать типизацию
const DATE_FORMAT = 'YYYY-MM-DD';

const CalendarModalData = ({
  comment: propsComment,
  disabledSelect,
  dateEvent,
  openDate: propsOpenDate,
  visible,
  onOk,
  planningTopicId,
  rest,
  planInfo,
  scheduleButtonHandler,
  hasEventsFlag,
  hasEvents,
  toPrev,
  topicInfo,
  eventTypeId,
  noComment,
  isLoadingPlan,
  okText,
}) => {
  const [noDate, setNoDate] = useState(false);
  const [comment, setComment] = useState(propsComment || '');
  const [selectedMonths, setSelectedMonths] = useState(disabledSelect ? [] : [dayjs(dateEvent).format(DATE_FORMAT)]);
  const [openDate, setOpenDate] = useState(propsOpenDate ? dayjs(propsOpenDate, DATE_FORMAT) : null);
  const [localValue, setLocalValue] = useState(null);
  const { t } = useTranslation('calendarModalData');

  useEffect(() => {
    setComment('');
    setSelectedMonths(!disabledSelect ? [] : [dayjs(dateEvent).format(DATE_FORMAT)]);
    setOpenDate(propsOpenDate ? dayjs(propsOpenDate, DATE_FORMAT) : null);
  }, [visible, propsOpenDate, dateEvent, disabledSelect]);

  useEffect(() => {
    setLocalValue(null);
  }, [planningTopicId]);

  const handleOkClick = () => {
    if (onOk) {
      onOk(planningTopicId, selectedMonths, comment, noDate, rest, planInfo);
    }
  };

  const changeComment = event => {
    const text = event.target.value;
    setComment(text);
  };

  const handleDateClick = date => {
    if (!date.value) {
      setLocalValue(null);
      setSelectedMonths([]);
      return;
    }
    const arr = [];
    arr.push(dayjs(date.value).format(DATE_FORMAT));
    setSelectedMonths(arr);
    setLocalValue(date.value);
  };

  const handleChangeCheckbox = () => {
    setNoDate(!noDate);
    setSelectedMonths([]);
    scheduleButtonHandler({ comment });
  };

  const renderPrev = (hasEventsFlag, hasEvents, toPrev) => {
    if (!toPrev) {
      return null;
    }
    if (!hasEvents || !hasEventsFlag) {
      return null;
    }
    return (
      <div className={css['Calendar-modal-toPrev']} onClick={() => toPrev({ hasEventsFlag: true })}>
        <img src={Arrow} alt='' /> {t('back')}
      </div>
    );
  };
  const placeholder = dayjs(openDate ? openDate : undefined)
    .add(5, 'days')
    .format('DD.MM.YYYY');

  return (
    <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
      {renderPrev(hasEventsFlag, hasEvents, toPrev)}
      <div className={classNames(css['Calendar-modal-header'], css['Calendar-modal-header--ext'])}>
        <div>{t('announcementInfo')}</div>
        <div>{t('checkSchedule')}</div>
      </div>
      {topicInfo && topicInfo.link && (
        <div className={css['Calendar-modal__row']}>
          <div className={css['Calendar-modal__row__label']}>{t('schedule')}</div>
          <div className={css['Calendar-modal__row__field']}>
            <a href={topicInfo.link} target='_blank' rel='noopener noreferrer'>
              <img className={css['Calendar-modal__row__field__img']} src={ExtLink} alt='' />
              {topicInfo.link.length < 40 ? topicInfo.link : `${topicInfo.link.slice(0, 40)}...`}
            </a>
          </div>
        </div>
      )}
      <div className={css['Calendar-modal__row']}>
        <div className={classNames(css['Calendar-modal__row__label'], css.paddingAlign)}>{t('ilGo')}</div>
        <div className={css['Calendar-modal__row__field']}>
          <DatePicker
            modificate
            readOnly
            selectMonth={noDate}
            typeId={eventTypeId}
            openDate={openDate}
            placeholder={placeholder}
            onChange={handleDateClick}
            name='startDate'
            disabledAfterAndCurrent
            value={localValue}
            dateFormat
          />
        </div>
      </div>
      {!noComment && (
        <div className={css['Calendar-modal__row']}>
          <div className={css['Calendar-modal__row__field']}>
            <TextArea placeholder={t('comment')} rows={3} onChange={changeComment} value={comment} maxLength={500} />
          </div>
        </div>
      )}
      <div className={css['Calendar-modal__row__btn-box']}>
        <div>
          <Button
            disabled={isLoadingPlan || !selectedMonths || selectedMonths.length === 0}
            className={css['Calendar-modal-btn__orange']}
            type='primary'
            onClick={handleOkClick}
            size='large'
          >
            {okText}
          </Button>
        </div>
        <div>
          <Button className={css['Calendar-modal-btn__schedule']} onClick={handleChangeCheckbox} ghost size='large'>
            {t('noSchedule')}
          </Button>
        </div>
      </div>
    </div>
  );
};

CalendarModalData.propTypes = {
  dateEvent: PropTypes.any,
  disabledSelect: PropTypes.bool,
  eventTypeId: PropTypes.number,
  hasEvents: PropTypes.number,
  hasEventsFlag: PropTypes.bool,
  headerFixToModal: PropTypes.bool,
  isLoadingPlan: PropTypes.bool,
  noComment: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  openDate: PropTypes.string,
  planInfo: PropTypes.any,
  planningTopicId: PropTypes.number,
  rest: PropTypes.any,
  title: PropTypes.string,
  toPrev: PropTypes.func,
  scheduleButtonHandler: PropTypes.func,
  topicInfo: PropTypes.any,
  visible: PropTypes.bool,
  comment: PropTypes.any,
  visibleModalData: PropTypes.any,
};

export default CalendarModalData;
