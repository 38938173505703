import {
  FINISH_MENTEE_FAIL,
  FINISH_MENTEE_START,
  FINISH_MENTEE_SUCCESS,
  GET_MENTEE_AVAILABLE_STATUSES_FAIL,
  GET_MENTEE_AVAILABLE_STATUSES_START,
  GET_MENTEE_AVAILABLE_STATUSES_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  REJECT_MENTEE_FAIL,
  REJECT_MENTEE_START,
  REJECT_MENTEE_SUCCESS,
  REVIEW_MENTEE_FAIL,
  REVIEW_MENTEE_START,
  REVIEW_MENTEE_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getMentees = status => {
  const qs = status ? `?status=${status}` : '';

  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentee${qs}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_MENTEE_START, GET_MENTEE_SUCCESS, GET_MENTEE_FAIL],
  };
};

export const rejectMentee = (id, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentee/status/reject`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: { id },
    types: [REJECT_MENTEE_START, REJECT_MENTEE_SUCCESS, REJECT_MENTEE_FAIL],
    onSuccess,
    onError,
  };
};

export const finishMentee = (menteeId, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/mentee/status/finish`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: { menteeId },
    onSuccess,
    types: [FINISH_MENTEE_START, FINISH_MENTEE_SUCCESS, FINISH_MENTEE_FAIL],
  };
};

export const reviewMentee = (menteeId, mentorId, text) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/mentor/gratitude`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: { menteeId, mentorId, text },
    types: [REVIEW_MENTEE_START, REVIEW_MENTEE_SUCCESS, REVIEW_MENTEE_FAIL],
  };
};

export const getMenteeAvailableStatuses = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/mentee/status`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [
      GET_MENTEE_AVAILABLE_STATUSES_START,
      GET_MENTEE_AVAILABLE_STATUSES_SUCCESS,
      GET_MENTEE_AVAILABLE_STATUSES_FAIL,
    ],
  };
};
