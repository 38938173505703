import {
  GET_ASSESSMENT_URL_FAIL,
  GET_ASSESSMENT_URL_START,
  GET_ASSESSMENT_URL_SUCCESS,
  GET_COMPETNCES_FAIL,
  GET_COMPETNCES_START,
  GET_COMPETNCES_SUCCESS,
  GET_RECOMMENDATION_FAIL,
  GET_RECOMMENDATION_START,
  GET_RECOMMENDATION_SUCCESS,
  GET_REPORT_PDF_FAIL,
  GET_REPORT_PDF_START,
  GET_REPORT_PDF_SUCCESS,
  GET_SCORE_FAIL,
  GET_SCORE_START,
  GET_SCORE_SUCCESS,
} from './constants';

import { API_TQ } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

// получаем урлы
export const getAssessmentUrl = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/assessment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ASSESSMENT_URL_START, GET_ASSESSMENT_URL_SUCCESS, GET_ASSESSMENT_URL_FAIL],
  onSuccess,
  onError,
});

// получаем пдф
export const getReportPdf = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/report`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_REPORT_PDF_START, GET_REPORT_PDF_SUCCESS, GET_REPORT_PDF_FAIL],
  onSuccess,
  onError,
});

// получаем результат после тестирования
export const getScore = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/score`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SCORE_START, GET_SCORE_SUCCESS, GET_SCORE_FAIL],
  onSuccess,
  onError,
});

// получаем результаты пользоователя
export const getRecommendation = (zone, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/recommendation${getQueryString({ zone })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_RECOMMENDATION_START, GET_RECOMMENDATION_SUCCESS, GET_RECOMMENDATION_FAIL],
  onSuccess,
  onError,
});

// получаем компетенции пользоователя
export const getCompetences = (zone, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_TQ}/api/company/competences${getQueryString({ zone })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETNCES_START, GET_COMPETNCES_SUCCESS, GET_COMPETNCES_FAIL],
  onSuccess,
  onError,
});
