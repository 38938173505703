import { memo } from 'react';

import { FiDownload, FiTrash } from 'react-icons/fi';

import useImage from '@shared/hooks/useImage';

import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const Photo = ({ src, handleDelete, fileData, uuid }) => {
  const image = useImage(fileData?.fileUuid ? fileData.fileUuid : uuid);
  const handleDownloadFile = () => {
    let link = document.createElement('a');
    link.href = image;
    link.download = fileData.fileName;
    link.click();
  };

  return (
    <div className={styles.photo}>
      <img src={src ? src : image} className={styles.photo__image} alt='' />

      {src ? (
        <div className={styles.photo__icon} onClick={() => handleDelete(uuid)}>
          <FiTrash size={16} />
        </div>
      ) : (
        <div className={styles.photo__icon} onClick={handleDownloadFile}>
          <FiDownload size={16} />
        </div>
      )}
    </div>
  );
};

Photo.propTypes = {
  src: PropTypes.string,
  uuid: PropTypes.string,
  handleDelete: PropTypes.func,
  fileData: PropTypes.object,
};

export default memo(Photo);
