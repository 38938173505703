import React from 'react';

import { Divider } from 'antd';

import useIsMobile from '@shared/hooks/useIsMobile';

import dayjs from 'dayjs';

import css from './styles.module.scss';

export const SchedulePopoverData = ({ t, event, currentUser }) => {
  const [isMobile] = useIsMobile(769);

  const formatEventRow = (dateItem, startTime, endTime, location, isLocationWide, index, dateIndex) => {
    const timezoneOffset = currentUser?.timezone?.offset / 60 - currentUser?.domainCompany?.timezone?.offset / 60 || 0;

    const correctedStartDateTime = dayjs(`${dateItem}T${startTime}`).add(timezoneOffset, 'h');
    const correctedEndDateTime = dayjs(`${dateItem}T${endTime}`).add(timezoneOffset, 'h');

    const formattedDate = correctedStartDateTime.format('DD.MM.YYYY');
    const formattedStartTime = correctedStartDateTime.format('HH:mm');
    const formattedEndTime = correctedEndDateTime.format('HH:mm');

    return (
      <div
        key={`schedule_${index}_${dateIndex}`}
        className={
          isLocationWide ? css['Details-schedule__schedule__row--wide'] : css['Details-schedule__schedule__row']
        }
      >
        <span>{formattedDate}</span>
        <span>{`${formattedStartTime} - ${formattedEndTime}`}</span>
        <span>{location}</span>
      </div>
    );
  };

  const isLocationWideForAnyEvent = event.eventPeriods?.some(period =>
    period.dates?.some(() => (period.place ? period.place.length > 80 : false))
  );

  const createEventRows = periods => {
    return periods?.map((period, index) => {
      const { startTime, endTime, dates, place } = period;
      const location = place ? place : t('locationNotPicked');
      const isLocationWide = isLocationWideForAnyEvent;

      return dates?.map((dateItem, dateIndex) =>
        formatEventRow(dateItem, startTime, endTime, location, isLocationWide, index, dateIndex)
      );
    });
  };

  return (
    <div
      className={
        isLocationWideForAnyEvent
          ? `${css['Details-schedule__schedule']} ${css['Details-schedule--wide']}`
          : css['Details-schedule__schedule']
      }
    >
      <div className={css['Details-schedule__schedule__title']}>{t('scheduleEvents')}</div>
      {!isMobile ? (
        <>
          <div
            className={
              isLocationWideForAnyEvent
                ? css['Details-schedule__schedule__header--wide']
                : css['Details-schedule__schedule__header']
            }
          >
            <span>{t('date')}</span>
            <span>{t('time')}</span>
            <span>{t('location')}</span>
          </div>
          {createEventRows(event.eventPeriods)}
        </>
      ) : (
        <>
          <Divider className={css['Details-schedule__schedule__divider']} />
          {event.eventPeriods?.map((period, index) => {
            const { startTime, endTime, dates, place } = period;

            return dates?.map((dateItem, dateIndex) => {
              const timezoneOffset =
                currentUser?.timezone?.offset / 60 - currentUser?.domainCompany?.timezone?.offset / 60 || 0;
              const correctedStartDateTime = dayjs(`${dateItem}T${startTime}`).add(timezoneOffset, 'h');
              const correctedEndDateTime = dayjs(`${dateItem}T${endTime}`).add(timezoneOffset, 'h');

              const formattedDate = correctedStartDateTime.format('DD.MM.YYYY');
              const formattedStartTime = correctedStartDateTime.format('HH:mm');
              const formattedEndTime = correctedEndDateTime.format('HH:mm');
              const location = place ? place : t('locationNotPicked');

              return (
                <div key={`mobile_schedule_${index}_${dateIndex}`}>
                  <div className={css['Details-schedule__schedule__header__cell']}>{t('date')}</div>
                  <div className={css['Details-schedule__schedule__cell']}>{formattedDate}</div>

                  <div className={css['Details-schedule__schedule__header__cell']}>{t('time')}</div>
                  <div className={css['Details-schedule__schedule__cell']}>
                    {`${formattedStartTime} - ${formattedEndTime}`}
                  </div>

                  <div className={css['Details-schedule__schedule__header__cell']}>{t('location')}</div>
                  <div className={css['Details-schedule__schedule__cell']}>{location}</div>

                  <Divider className={css['Details-schedule__schedule__divider']} />
                </div>
              );
            });
          })}
        </>
      )}
    </div>
  );
};
