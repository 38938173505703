import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { clearUsers, getUsers } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';

import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiX } from 'react-icons/fi';

import User from './User';

import useDebounce from '@shared/hooks/useDebounce';

import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const antIcon = <LoadingOutlined />;

const UsersList = ({ onSelectUser, selectedUser, defaultSelectedUser }) => {
  const [searchValue, setSearchValue] = useState(undefined);

  const { users, pagination, isLoading } = useSelector(selectChat);
  const { t } = useTranslation('chat');

  const debounceSearchValue = useDebounce(searchValue, 800);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUsers());
    dispatch(getUsers(searchValue));
  }, [debounceSearchValue]);

  const loadMore = () => {
    if (!isLoading.getUsers) {
      dispatch(getUsers(searchValue, pagination.getUsers.page + 1));
    }
  };

  const listUser = users?.map((user, index) => (
    <User
      key={index}
      defaultSelectedUser={defaultSelectedUser}
      selectedUser={selectedUser}
      data={user}
      onSelectUser={onSelectUser}
    />
  ));

  return (
    <div className={styles.usersList}>
      <Spin spinning={isLoading.getUsers} indicator={antIcon}>
        <Input onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder={t('findUser')} />
        <div className={styles.usersList__box}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={loadMore}
            hasMore={users?.length !== pagination.getUsers.totalElements}
            useWindow={false}
          >
            {listUser}
          </InfiniteScroll>
        </div>
      </Spin>
      {!!selectedUser?.length && (
        <div className={styles.usersList__preview}>
          {selectedUser.map(user => (
            <div key={user.id} className={styles.usersList__tag}>
              <p className={styles.usersList__name}>
                {user?.firstName} {user?.lastName}
              </p>
              <FiX className={styles.usersList__icon} onClick={() => onSelectUser(user)} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

UsersList.propTypes = {
  onSelectUser: PropTypes.func,
  selectedUser: PropTypes.object,
  defaultSelectedUser: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
};

export default memo(UsersList);
