import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateMentorCareerPlan } from '@/store/career_plan/actions';

import { Avatar, Button, Checkbox, Flex, Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';
import { FINISHED, WAITING_MENTOR } from '@/components/Mentoring/constants';
import { DeleteOutlined } from '@ant-design/icons';

import { MENTORS } from '../../constants';
import ActionButtons from '../ActionButtons';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';

import css from '../../CareerPlan.module.scss';

const MentorItem = ({ mentor, competence, careerPlan, canEdit }) => {
  const [mentorAvatar] = useLocalFileByUUID(mentor?.localPhotoUuid, { defaultImg: PortraitPlaceholder });
  const dispatch = useDispatch();
  const [isMobile] = useIsMobile(769);
  const { t } = useTranslation('careerPlan');

  const onDeleteTopic = mentorId => {
    const updatedActiveList = competence?.mentors.filter(mentor => mentor.id !== mentorId);

    const mentors = updatedActiveList.map(mentor => mentor.id);

    const sendData = {
      mentors: mentors,
      competenceId: competence?.id,
    };

    dispatch(updateMentorCareerPlan(sendData, careerPlan?.id));
  };

  const isDisabled = mentor?.mentoringStatusDto && mentor?.mentoringStatusDto?.value !== WAITING_MENTOR;

  return (
    <div key={mentor?.id} className={css['CareerPlan-container-competence-content-item-container']}>
      <div className={css['CareerPlan-container-competence-content-topic-content']}>
        <Link to={`/mentors/${mentor.id}`} className={css['CareerPlan-container-competence-content-item']}>
          {!isMobile && <Checkbox checked={mentor?.mentoringStatusDto?.value === FINISHED} />}
          <Avatar
            size={isMobile && 42}
            src={mentorAvatar}
            className={css['CareerPlan-container-competence-content-item-avatar']}
          />
          <p className={css['CareerPlan-container-competence-content-item-text']}>
            {mentor?.firstName + ' ' + mentor?.lastName}
          </p>
        </Link>
        {canEdit && isMobile && (
          <Tooltip title={isDisabled && t('deleteMentor')}>
            <Button
              onClick={() => onDeleteTopic(mentor?.id)}
              type='text'
              danger
              icon={<DeleteOutlined />}
              disabled={isDisabled}
            />
          </Tooltip>
        )}
      </div>
      <Flex className={css['CareerPlan-container-competence-content-topic-actionBtn']} gap={20}>
        <ActionButtons type={MENTORS} data={mentor} currentCompetence={competence} careerPlanId={careerPlan?.id} />
        {canEdit && !isMobile && (
          <Tooltip title={isDisabled && t('deleteMentor')}>
            <Button
              onClick={() => onDeleteTopic(mentor?.id)}
              type='text'
              danger
              icon={<DeleteOutlined />}
              disabled={isDisabled}
            />
          </Tooltip>
        )}
      </Flex>
    </div>
  );
};

export default MentorItem;
