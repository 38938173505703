/* eslint-disable */

import React, { Component } from 'react';

import { List } from 'antd';

import CustomModal from '../Modal/Modal';

import PropTypes from 'prop-types';

import css from './AddressBook.module.scss';

export default class AddressBook extends Component {
  static propTypes = {
    textWindowTitle: PropTypes.string,
    textAccessible: PropTypes.string,
    textNotAccessible: PropTypes.string,
    textSubmitButton: PropTypes.string,
    textReadOnline: PropTypes.string,
    onButtonSubmit: PropTypes.func,
    onButtonClose: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    data: PropTypes.array,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false,
      okButtonDisabled: true,
      addressId: null,
    };
  }

  getMapLink(addr) {
    return 'https://yandex.ru/maps/?text=' + addr;
  }

  onClickItem(item) {
    // Выключаем кнопку "выбрать адрес"
    this.setState({ okButtonDisabled: true });

    // Сбросим ранее выделенный элемент
    let elems = document.getElementsByClassName(css.AddressBookListItem);
    if (elems !== null) {
      for (let eindex = 0; eindex < elems.length; eindex++) {
        elems[eindex].style.backgroundColor = '#fff';
      }
    }

    // Получаем выбранный элемент
    let el = document.getElementById('abli' + item.id);
    if (el !== null) {
      el.style.backgroundColor = '#dadada';

      if (item.accessible) {
        this.setState({
          okButtonDisabled: false,
          addressId: item.id,
        });
      }
    }
  }

  getAddressListData() {
    let data = [{ id: 0, address: this.props.textReadOnline, accessible: true }];

    if (Array.isArray(this.props.data)) {
      data = data.concat(this.props.data);
    }

    return data;
  }

  addressList() {
    return (
      <div className={css.AddressBookList}>
        <List
          bordered={false}
          itemLayout='horizontal'
          dataSource={this.getAddressListData()}
          size='small'
          split={true}
          renderItem={item => (
            <List.Item id={'abli' + item.id} className={css.AddressBookListItem} onClick={() => this.onClickItem(item)}>
              {item.id === 0 ? (
                <List.Item.Meta title={item.address} />
              ) : (
                <List.Item.Meta
                  title={
                    <a target='_blank' href={this.getMapLink(item.address)}>
                      {item.address}
                    </a>
                  }
                />
              )}
              <div style={{ fontWeight: 500 }}>
                {item.accessible ? this.props.textAccessible : this.props.textNotAccessible}
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  }

  render() {
    return (
      <CustomModal
        className={css.AddressBookModal}
        title={this.props.textWindowTitle}
        okText={this.props.textSubmitButton}
        onOk={() => {
          this.props.onButtonSubmit(this.state.addressId);
        }}
        onCancel={this.props.onButtonClose}
        manualCancel={true}
        open={this.props.open}
        children={this.addressList()}
        loading={this.state.okButtonDisabled}
        modificate
      />
    );
  }
}
