/* eslint-disable */
import { API_URL, API_V2 } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

export const GET_FAQ_LINKS_START = 'GET_FAQ_LINKS_START';
export const GET_FAQ_LINKS_SUCCESS = 'GET_FAQ_LINKS_SUCCESS';
export const GET_FAQ_LINKS_FAIL = 'GET_FAQ_LINKS_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

/*
export const getFaqLinks = () => ({
  type: REST_API,
  method: GET,
  url: `${API_V2}/footer`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FAQ_LINKS_START, GET_FAQ_LINKS_SUCCESS, GET_FAQ_LINKS_FAIL],
});
*/

export const getFaqLinks = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/footer`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FAQ_LINKS_START, GET_FAQ_LINKS_SUCCESS, GET_FAQ_LINKS_FAIL],
});
