import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, Input } from 'antd';
import photo from '@/pages/Header/PortraitPlaceholder.png';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CommentRateMentor.module.scss';

const { TextArea } = Input;

const CommentRateMentor = props => {
  const { t } = useTranslation('mentors');
  const [edit, setEdit] = useState(false);
  const [newComment, setNewComment] = useState(props.text || '');
  const [isDeleteComment, setIsDeleteComment] = useState(false);

  const [currentAvatar] = useLocalFileByUUID(props.photoUuid || props.localPhotoUuid, { defaultImg: photo });

  const editComment = () => {
    const params = {
      mentorId: props.mentorId,
      text: newComment,
    };

    props.tabEditComment(props.commentId, params, () => {
      setEdit(false);
      setNewComment('');
    });
  };

  const restoreComment = () => {
    props.tabRestoreComment(props.commentId, () => setIsDeleteComment(false));
  };

  const deleteComment = () => {
    setIsDeleteComment(true);
    props.deleteComment(props.commentId);
  };

  const openEditComment = () => {
    setNewComment(props.text);
    setEdit(true);
  };

  const renderText = isDeleteComment ? (
    <div className={css['Comments-other-block-restore']}>
      <div>{t('commentDelete')}</div>
      <div>
        <Button type='link' onClick={restoreComment} className={css['Comments-other-block-restore-btn']}>
          {t('restore')}
        </Button>
      </div>
    </div>
  ) : (
    props.text
  );

  return (
    <div className={css['Comments-other']}>
      <div>
        <Avatar className={css['Comments-create-avatar']} src={currentAvatar} size={48} />
      </div>
      <div className={css['Comments-other-block']}>
        <div className={css['Comments-other-block-userName']}>{props.userName}</div>
        <div className={css['Comments-other-block-date']}>{dayjs(props.date).format('LL')}</div>
        {edit && !isDeleteComment ? (
          <div className={css['Comments-other-block-text']}>
            <TextArea
              className={css['Comments-other-block-area']}
              onChange={e => setNewComment(e?.target?.value)}
              placeholder={t('placeHolderComments')}
              rows={4}
              maxLength={1500}
              value={newComment}
            />
            <div className={css['Comments-other-block-actions']}>
              <Button className={css['Comments-other-block-actions-send']} onClick={editComment} type='primary'>
                {t('updatedComment')}
              </Button>
              <Button className={css['Comments-other-block-actions-reset']} type='link' onClick={() => setEdit(false)}>
                {t('cancelComment')}
              </Button>
            </div>
          </div>
        ) : (
          <div className={css['Comments-other-block-text']}>
            {renderText}
            <span className={css['Comments-other-block-text-edit']}>
              {props.modified === true && !isDeleteComment && t('modifiedComment')}
            </span>
          </div>
        )}
      </div>

      {props.editable === true && !isDeleteComment && !edit && (
        <div className={css['Comments-other-buttons']}>
          <EditOutlined className={css['Comments-other-buttons-edit']} onClick={openEditComment} />
          {props.canDelete && <DeleteOutlined onClick={deleteComment} />}
        </div>
      )}
    </div>
  );
};

CommentRateMentor.propTypes = {
  userName: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  photoUuid: PropTypes.string,
  localPhotoUuid: PropTypes.string,
  editable: PropTypes.bool,
  commentId: PropTypes.number,
  mentorId: PropTypes.number,
  modified: PropTypes.bool,
  canDelete: PropTypes.bool,
  deleteComment: PropTypes.func,
  tabEditComment: PropTypes.func,
  tabRestoreComment: PropTypes.func,
};

export default CommentRateMentor;
