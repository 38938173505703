/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Modal, Tooltip } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Modal.module.scss';

import './Modal-css.css';

import ModalCloseIcon from '../ModalCloseIcon/ModalCloseIcon';

class CustomModal extends Component {
  static propTypes = {
    bigBtn: PropTypes.bool,
    btnCenter: PropTypes.bool,
    cardOn: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    closableControl: PropTypes.bool,
    closeButton: PropTypes.bool,
    contentClassName: PropTypes.string,
    editable: PropTypes.bool,
    fileModal: PropTypes.bool,
    flower: PropTypes.bool,
    headerFixToModal: PropTypes.bool,
    hideFooter: PropTypes.bool,
    loading: PropTypes.bool,
    manualCancel: PropTypes.bool,
    maskClosable: PropTypes.bool,
    modalCategory: PropTypes.bool,
    modificate: PropTypes.bool,
    modificateClose: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.string,
    id: PropTypes.string,
    cancelText: PropTypes.string,
    classNameFooter: PropTypes.string,
    isPlanningPageAction: PropTypes.bool,
  };

  static defaultProps = {
    okButtonDisabled: () => {
      return false;
    },
  };

  render() {
    const {
      className,
      children,
      title,
      okText,
      contentClassName,
      hideFooter,
      editable,
      onEdit,
      closeButton,
      modalCategory,
      maskClosable,
      closableControl,
      btnCenter,
      loading,
      bigBtn,
      t,
      id,
      cancelText,
      classNameFooter,
      isPlanningPageAction,
      ...rest
    } = this.props;

    const footer = hideFooter ? null : (
      <div
        className={classNames(
          classNameFooter,
          css['Modal-footer'],
          { [css['Modal-footer-planningPageAction']]: isPlanningPageAction },
          { [css['Modal-footer--btn-center']]: btnCenter },
          { [css['Modal-footer-modalCategory']]: modalCategory }
        )}
      >
        <Button
          id={'Modal-footer-modalCategory-btn-save'}
          data-qa='collectionTopicsAddMaterialModalScopeSaveBtn'
          disabled={loading}
          onClick={() => this.props.onOk()}
          className={classNames(
            { [css['Btn-fileModal']]: this.props.fileModal },
            { [css['Btn-bigBtn']]: bigBtn },
            { [css['Btn-marginTop']]: this.props.joinConfirmationModal }
          )}
          size='large'
          type='primary'
        >
          {okText}
        </Button>
        {this.props.manualCancel && (
          <Button className={css['Modal-cancel']} onClick={this.props.onCancel} size='large'>
            {cancelText || t('cancel')}
          </Button>
        )}
      </div>
    );

    const editButton = editable ? (
      <Tooltip title={t('edit')}>
        <button className={css['Modal-edit']} onClick={onEdit}>
          <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g transform='translate(1 1)' stroke='currentColor' fill='none'>
              <circle cx='17' cy='17' r='17' />
              <path d='m15.475 21.825-4.263 1.066 1.065-4.264 8.393-8.393 3.198 3.198z' />
            </g>
          </svg>
        </button>
      </Tooltip>
    ) : null;

    return (
      <Modal
        {...rest}
        className={classNames(className, css.Modal, {
          [css['Modal-fileModal']]: this.props.fileModal || this.props.cardOn,
        })}
        styles={{ mask: { backgroundColor: 'rgba(246, 246, 246, 0.95)' } }}
        maskClosable={closableControl ? maskClosable : true}
        closable={false}
        footer={null}
      >
        {!this.props.modalCategory ? (
          <div
            className={classNames(
              css['Modal-header'],
              { [css['Modal-header-modificate']]: this.props.modificate },
              { [css['Modal-header-modalCategory']]: this.props.modalCategory },
              {
                [css['Modal-close-headerFixToModal']]: this.props.headerFixToModal,
              }
            )}
          >
            <div
              className={classNames(
                css['Modal-title'],
                { [css['Modal-title-planningPageAction']]: isPlanningPageAction },
                { [css['Modal-title-header']]: this.props.modificate }
              )}
            >
              {title}
            </div>
            <div className={css['Modal-buttons']}>
              {editButton}
              {!closeButton ? (
                <ModalCloseIcon
                  className={classNames(
                    css['Modal-close'],
                    { [css['Modal-close-modificate']]: this.props.modificate },
                    {
                      [css['Modal-close-modificateClose']]: this.props.modificateClose,
                    },
                    { [css['Modal-close-flower']]: this.props.flower }
                  )}
                  onClick={this.props.onCancel}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <div
          className={classNames(
            css['Modal-content'],
            { [css['Modal-content-planningPageAction']]: isPlanningPageAction },
            { [css['Modal-content-modificate']]: this.props.modificate },
            { [css['Modal-content-modalCategory']]: this.props.modalCategory },
            contentClassName
          )}
          id={id}
        >
          {children}
          {footer}
        </div>
      </Modal>
    );
  }
}

export default withTranslation('modal')(CustomModal);
