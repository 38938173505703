/* eslint-disable */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { getEventParty } from '@/store/study-plan/actions';

import { selectNotifications as notificationsSelect } from '@/store/notifications/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Switch } from 'antd';
import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';

import Input from '../Input';
import Modal from '../Modal';
import ParticipantModalItem from './ParticipantModalItem';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ITEMS_PER_PAGE = 10;

class ParticipantsModal extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    domainCompany: PropTypes.object,
    getEventParty: PropTypes.func,
    getFile: PropTypes.func,
    match: PropTypes.object,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    participants: PropTypes.array,
    planStatus: PropTypes.string,
    topicId: PropTypes.string,
    totalParticipants: PropTypes.number,
    visible: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      department: '',
      currentUsers: [],
      participantsList: !props.topicId ? props.participants : [],
      currentPage: 1,
    };
    this.scrollbar = React.createRef();
  }

  componentDidMount() {
    if (this.props.topicId) {
      this.props.getEventParty(this.props.topicId, { page: 0, size: ITEMS_PER_PAGE }, response => {
        this.setState({ participantsList: response.content });
      });
    }
  }

  UNSAFE_componentWillUpdate(newProps, newState) {
    if (newState.searchString !== this.state.searchString) {
      if (this.props.topicId) {
        this.scrollbar.current.scrollTop();
        this.props.getEventParty(
          this.props.topicId,
          {
            page: 0,
            size: ITEMS_PER_PAGE,
            fullName: newState.searchString,
            isDepartment: this.state.department,
          },
          response => {
            this.setState({
              participantsList: response.content,
              currentPage: 1,
            });
          }
        );
      } else if (this.props.participants && this.props.participants.length) {
        this.setState({
          participantsList: this.props.participants.filter(participant =>
            participant.user.lastName.toLowerCase().includes(newState.searchString.toLowerCase())
          ),
        });
      }
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChangeSwitch = checked => {
    this.scrollbar.current.scrollTop();
    this.props.getEventParty(
      this.props.topicId,
      {
        page: 0,
        size: ITEMS_PER_PAGE,
        isDepartment: !!checked,
        fullName: this.state.searchString,
      },
      response => {
        this.setState({
          participantsList: response.content,
          department: !!checked,
          currentPage: 1,
        });
      }
    );
  };

  showTotalParticipantsLabel = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  };

  showMessage = () => {
    if (this.state.searchString && this.state.searchString.length > 0) {
      return (
        <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
          {this.props.t('findColleague')} <br />
          {this.props.t('check')}
        </p>
      );
    }
    const filterCurrentUsersItems = this.state.participantsList.filter(u => {
      if (u.user.id !== this.props.currentUser.id) {
        return u;
      }
    });
    if (this.state.department && !filterCurrentUsersItems.length) {
      return (
        <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
          {this.props.t('colleaguesCourse')}
        </p>
      );
    }
    return null;
  };

  onScrollLoad = values => {
    if (values.top === 1 && this.state.participantsList.length < this.props.totalParticipants) {
      const pageNumber = this.state.currentPage + 1;
      this.props.getEventParty(
        this.props.topicId,
        {
          page: 0,
          size: ITEMS_PER_PAGE * pageNumber,
          fullName: this.state.searchString,
          isDepartment: this.state.department,
        },
        response => {
          this.setState({
            participantsList: response.content,
            currentPage: pageNumber,
          });
        }
      );
    }
  };

  render() {
    const { onCancel, currentUser } = this.props;
    const filterCurrentUsersItems = this.state.participantsList.filter(u => {
      if (u.user.id !== this.props.currentUser.id) {
        return u;
      }
    });
    const currentUsersItems = filterCurrentUsersItems.map(user => (
      <ParticipantModalItem key={user.user.id} user={user.user} getFile={this.props.getFile} />
    ));
    const hasDepartment = currentUser.department && currentUser.department.length > 0;
    const countParticipants = this.props.planStatus ? this.props.studyPlanTotal - 1 : this.props.studyPlanTotal;

    return (
      <Modal
        open={this.props.visible}
        onCancel={onCancel}
        hideFooter
        headerFixToModal
        cardOn
        width={600}
        contentClassName={css['App-card-modal']}
      >
        <div className={css['UserItem-shadow-box']}>
          <h3 className={css['UserItem-title']}>
            {this.props.t('planned', {
              countParticipants,
              coworker: this.showTotalParticipantsLabel(
                countParticipants,
                this.props.t('colleagues', { returnObjects: true })
              ),
            })}
          </h3>
          <div
            className={classNames(css['UserItem-search'], {
              [css.noMargin]: !hasDepartment,
            })}
          >
            <Input
              onChange={this.handleChange}
              name='searchString'
              value={this.state.searchString}
              dark='true'
              className={css['UserItem-search-input']}
              placeholder={this.props.t('search')}
              modificate
            />
          </div>
          {this.props.domainCompany && this.props.domainCompany.hasUserDepartment && hasDepartment && (
            <div className={css['Switchers']}>
              <Switch onChange={this.onChangeSwitch} />
              <span>{this.props.domainCompany.myDepartmentButtonName}</span>
            </div>
          )}
        </div>
        <div className={css['UserItem-list-box']}>
          <div className={css['UserItem-list']}>
            <Scrollbars
              ref={this.scrollbar}
              autoHide
              style={{ height: 365, paddingRight: 45 }}
              onScrollFrame={values => this.onScrollLoad(values)}
            >
              {currentUsersItems && currentUsersItems.length > 0 ? (
                <div className={css['UserItem-list-InfiniteScroll']}>{currentUsersItems}</div>
              ) : null}
              {currentUsersItems && currentUsersItems.length === 0 ? this.showMessage() : null}
            </Scrollbars>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapActionToProps = {
  getFile,
  getEventParty,
};

const mapStateToProps = createSelector(
  [userTopicsSelect, notificationsSelect, usersSelect, selectStudyPlan],
  (userTopics, notifications, users, studyPlan) => ({
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    currentUser: users.currentUser,
    studyPlanTotal: studyPlan.eventPartyTotal,
  })
);

export default connect(mapStateToProps, mapActionToProps)(withTranslation('participantsModal')(ParticipantsModal));
