/* eslint-disable */
import React, { Component } from 'react';

import Tag from '@/components/Tag';

import PropTypes from 'prop-types';

export class FilterTag extends Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.object,
    filterBlk: PropTypes.array,
    filterBlock: PropTypes.object,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    searchTags: PropTypes.func,
    tagsSearch: PropTypes.array,
    tagsSearchPageNumber: PropTypes.number,
    tagsSearchPageSize: PropTypes.number,
    tagsSearchTotal: PropTypes.number,
    topicCategories: PropTypes.array,
    topicLevel: PropTypes.array,
    topicTypes: PropTypes.array,
  };

  static defaultProps = {
    filter: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filterBlk: props.filterBlock,
    };
  }

  handleChange = event => {
    if (this.props.onChange) {
      const { name, value } = event.target || event;
      this.props.onChange({ [name]: value });
    }
  };

  changeSortCatalog = (params, sort, activeTag) => {
    const parseSort = sort.split(`&${params}=`);
    if (activeTag) {
      const arrActiveTag = this.props.filter[params] && this.props.filter[params].split(`&${params}=`);
      const arrFilterActiveTag = arrActiveTag.filter(tag => {
        if (parseSort.indexOf(tag) === -1) {
          return tag;
        }
      });
      const activeTagString = arrFilterActiveTag.join(`&${params}=`);
      this.handleChange({ name: params, value: activeTagString });
    } else if (params in this.props.filter) {
      if (params === 'topicTypeLabelId') {
        const newSort = `${this.props.filter.topicTypeLabelId}&topicTypeLabelId=${sort}`;
        this.handleChange({ name: params, value: newSort });
      }
      if (params === 'topicTypeId') {
        const newSort = `${this.props.filter.topicTypeId}&topicTypeId=${sort}`;
        this.handleChange({ name: params, value: newSort });
      }
      if (params === 'topicSubTypeId') {
        const newSort = `${this.props.filter.topicSubTypeId}&topicSubTypeId=${sort}`;
        this.handleChange({ name: params, value: newSort });
      }

      if (
        params === 'sort' ||
        params === 'actual' ||
        params === 'free' ||
        params === 'hasBlock' ||
        params === 'isOnline' ||
        params === 'isOpenSession'
      ) {
        this.handleChange({ name: params, value: sort });
      }
    } else {
      this.handleChange({ name: params, value: sort });
    }
  };

  showTags = () => {
    const filterBlockConst =
      this.props.filterBlk &&
      this.props.filterBlk.length &&
      this.props.filterBlk[0].fields.map(tag => {
        const arrActiveTag = this.props.filter[tag.params]?.split(`&${tag.params}=`).filter(item => item !== '');
        const checkActiveType = this.props.filter.typeId === 1 || this.props.filter.typeId === 2;
        const checkActiveLabel =
          arrActiveTag &&
          (arrActiveTag.join(',').indexOf(tag.sort.split('&topicSubTypeId=').join(',')) !== -1 ||
            arrActiveTag.join(',').indexOf(tag.sort.split('&sort=').join(',')) !== -1 ||
            arrActiveTag.join(',').indexOf(tag.sort.split('&topicTypeId=').join(',')) !== -1);

        const activeTag =
          (arrActiveTag && arrActiveTag.indexOf(tag.sort) !== -1) || (checkActiveType && checkActiveLabel);
        return (
          <Tag
            key={tag.name}
            tag={tag}
            activeTag={!!activeTag}
            filterCatalog
            onClick={() => this.changeSortCatalog(tag.params, tag.sort, activeTag)}
          />
        );
      });
    return filterBlockConst;
  };

  render() {
    return this.showTags();
  }
}
