import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';

import css from './MatchingQuestion.module.scss';

const ImageComponent = props => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('ImageComponent');

  const imageSrc = useImage(props.src);

  const viewFullImage = e => {
    e.stopPropagation();
    e.preventDefault();
    setVisible(true);
  };

  return (
    <div className={classNames(props.isDroppable && css['ImageComponent-droppable'])}>
      <img class={css['ImageComponent-imgMiniSize']} src={imageSrc} alt='mini size' />
      <ArrowsAltOutlined
        className={css['ImageComponent-iconFull']}
        onClick={e => {
          e.stopPropagation();
          viewFullImage(e);
        }}
      />
      <Modal
        maskClosable
        className={css['ImageComponent-imageModal']}
        open={visible}
        onCancel={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(false);
        }}
        footer={null}
        zIndex={1100}
        title={t('title')}
      >
        <div className={css['ImageComponent-imageModal-container']}>
          <img src={imageSrc} alt='full size' className={css['ImageComponent-fullImage']} />
        </div>
      </Modal>
    </div>
  );
};

export default ImageComponent;
