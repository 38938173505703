import { useEffect, useState } from 'react';

import FinishModal from './components/FinishModal';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import css from './Timer.module.scss';

dayjs.extend(duration);

const Timer = ({ time, getDetails, blockId, currentUser }) => {
  const [timer, setTimer] = useState(
    dayjs.duration({
      hours: dayjs(time, 'HH:mm:ss').format('HH'),
      minutes: dayjs(time, 'HH:mm:ss').format('mm'),
      seconds: dayjs(time, 'HH:mm:ss').format('ss'),
    })
  );
  const [visibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
    setTimer(
      dayjs.duration({
        hours: dayjs(time, 'HH:mm:ss').format('HH'),
        minutes: dayjs(time, 'HH:mm:ss').format('mm'),
        seconds: dayjs(time, 'HH:mm:ss').format('ss'),
      })
    );
  }, [time]);

  useEffect(() => {
    if (timer.hours() !== 0 || timer.minutes() !== 0 || timer.seconds() !== 0) {
      const interval = setInterval(() => {
        setTimer(timer.subtract(1, 'seconds'));
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setVisibleModal(true);
    }
  }, [timer]);

  const checkResult = () => {
    getDetails(blockId, currentUser.id);
    setVisibleModal(false);
  };

  const renderTime = time => (+time >= 10 ? +time : '0' + +time);

  const render = () => {
    return (
      <div className={css['Timer']}>
        {renderTime(timer.hours())}:{renderTime(timer.minutes())}:{renderTime(timer.seconds())}
        <FinishModal visible={visibleModal} onSuccess={checkResult} />
      </div>
    );
  };

  return render();
};

export default Timer;
