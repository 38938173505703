export const GET_MENTORS_START = 'GET_MENTORS_START';
export const GET_MENTORS_SUCCESS = 'GET_MENTORS_SUCCESS';
export const GET_MENTORS_FAIL = 'GET_MENTORS_FAIL';

export const CREATE_MENTOR_START = 'CREATE_MENTOR_START';
export const CREATE_MENTOR_SUCCESS = 'CREATE_MENTOR_SUCCESS';
export const CREATE_MENTOR_FAIL = 'CREATE_MENTOR_FAIL';

export const GET_CURRENT_MENTOR_START = 'GET_CURRENT_MENTOR_START';
export const GET_CURRENT_MENTOR_SUCCESS = 'GET_CURRENT_MENTOR_SUCCESS';
export const GET_CURRENT_MENTOR_FAIL = 'GET_CURRENT_MENTOR_FAIL';

export const GET_CAN_BE_MENTOR_START = 'GET_CAN_BE_MENTOR_START';
export const GET_CAN_BE_MENTOR_SUCCESS = 'GET_CAN_BE_MENTOR_SUCCESS';
export const GET_CAN_BE_MENTOR_FAIL = 'GET_CAN_BE_MENTOR_FAIL';

export const UPDATE_MENTOR_PHOTO_START = 'UPDATE_MENTOR_PHOTO_START';
export const UPDATE_MENTOR_PHOTO_SUCCESS = 'UPDATE_MENTOR_PHOTO_SUCCESS';
export const UPDATE_MENTOR_PHOTO_FAIL = 'UPDATE_MENTOR_PHOTO_FAIL';

export const SET_MENTOR_STATUS_START = 'SET_MENTOR_STATUS_START';
export const SET_MENTOR_STATUS_SUCCESS = 'SET_MENTOR_STATUS_SUCCESS';
export const SET_MENTOR_STATUS_FAIL = 'SET_MENTOR_STATUS_FAIL';

export const SET_MENTOR_STATUS_BULK_START = 'SET_MENTOR_STATUS_BULK_START';
export const SET_MENTOR_STATUS_BULK_SUCCESS = 'SET_MENTOR_STATUS_BULK_SUCCESS';
export const SET_MENTOR_STATUS_BULK_FAIL = 'SET_MENTOR_STATUS_BULK_FAIL';

export const GET_MENTEES_REQUESTS_START = 'GET_MENTEES_REQUESTS_START';
export const GET_MENTEES_REQUESTS_SUCCESS = 'GET_MENTEES_REQUESTS_SUCCESS';
export const GET_MENTEES_REQUESTS_FAIL = 'GET_MENTEES_REQUESTS_FAIL';

export const ACCEPT_REQUEST_STATUS_START = 'ACCEPT_REQUEST_STATUS_START';
export const ACCEPT_REQUEST_STATUS_SUCCESS = 'ACCEPT_REQUEST_STATUS_SUCCESS';
export const ACCEPT_REQUEST_STATUS_FAIL = 'ACCEPT_REQUEST_STATUS_FAIL';

export const REJECT_REQUEST_STATUS_START = 'REJECT_REQUEST_STATUS_START';
export const REJECT_REQUEST_STATUS_SUCCESS = 'REJECT_REQUEST_STATUS_SUCCESS';
export const REJECT_REQUEST_STATUS_FAIL = 'REJECT_REQUEST_STATUS_FAIL';

export const FINISH_REQUEST_STATUS_START = 'FINISH_REQUEST_STATUS_START';
export const FINISH_REQUEST_STATUS_SUCCESS = 'FINISH_REQUEST_STATUS_SUCCESS';
export const FINISH_REQUEST_STATUS_FAIL = 'FINISH_REQUEST_STATUS_FAIL';

export const REVIEW_REQUEST_STATUS_START = 'REVIEW_REQUEST_STATUS_START';
export const REVIEW_REQUEST_STATUS_SUCCESS = 'REVIEW_REQUEST_STATUS_SUCCESS';
export const REVIEW_REQUEST_STATUS_FAIL = 'REVIEW_REQUEST_STATUS_FAIL';
