/* eslint-disable */
import { useTranslation } from 'react-i18next';

import { Flex, Select } from 'antd';

import PropTypes from 'prop-types';

import css from './TopicFilter.module.scss';

export default function FilterSelects(props) {
  const { t } = useTranslation(['constants', 'constants']);

  return props.filters.map(filter => {
    const nameToLabel = filter?.options && filter.options.map(opt => ({ label: opt.name, value: opt.id, ...opt }));

    const options =
      props.showAllCategory && filter.options ? [{ value: '', label: t('all') }, ...nameToLabel] : nameToLabel;

    return (
      <Flex key={filter.name} className={css.formItemLabel}>
        <Select
          data-qa={filter.dataqa}
          style={{ flex: 1, width: '100%' }}
          id={filter.name}
          onChange={(_, event) => props.handleChange({ ...event, name: filter.name })}
          name={filter.name}
          options={options}
          allowClear
          size={'large'}
          placeholder={filter.label}
          value={filter.value}
          onClear={() => props.clearFilterItem(filter.name)}
        />
      </Flex>
    );
  });
}

FilterSelects.propTypes = {
  curTime: PropTypes.any,
  filters: PropTypes.array,
  handleChange: PropTypes.func,
  clearFilterItem: PropTypes.func,
  showAllCategory: PropTypes.bool,
};
