/* eslint-disable */
import {
  CHECKED_AVAILABLE_SUCCESS,
  CLEAR_DETAILS_SUCCESS,
  CLEAR_QUESTION_SUCCESS,
  FINISH_ARTICLE_BLOCK_FAIL,
  FINISH_ARTICLE_BLOCK_START,
  FINISH_ARTICLE_BLOCK_SUCCESS,
  FINISH_BLOCKS_FAIL,
  FINISH_BLOCKS_START,
  FINISH_BLOCKS_SUCCESS,
  FINISH_POKA_FILE_BLOCK_FAIL,
  FINISH_POKA_FILE_BLOCK_START,
  FINISH_POKA_FILE_BLOCK_SUCCESS,
  FINISH_PRESENTATION_BLOCK_FAIL,
  FINISH_PRESENTATION_BLOCK_START,
  FINISH_PRESENTATION_BLOCK_SUCCESS,
  FINISH_SCORM_BLOCK_FAIL,
  FINISH_SCORM_BLOCK_START,
  FINISH_SCORM_BLOCK_SUCCESS,
  FINISH_TILDA_BLOCK_FAIL,
  FINISH_TILDA_BLOCK_START,
  FINISH_TILDA_BLOCK_SUCCESS,
  FINISH_VIDEO_BLOCK_FAIL,
  FINISH_VIDEO_BLOCK_START,
  FINISH_VIDEO_BLOCK_SUCCESS,
  GET_ARTICLE_START,
  GET_ARTICLE_SUCCESS,
  GET_BLOCK_DETAILS_START,
  GET_BLOCK_DETAILS_SUCCESS,
  GET_BLOCK_STATISTIC_FAIL,
  GET_BLOCK_STATISTIC_START,
  GET_BLOCK_STATISTIC_SUCCESS,
  GET_BLOCKS_FAIL,
  GET_BLOCKS_START,
  GET_BLOCKS_SUCCESS,
  GET_INFO_BLOCK_FAIL,
  GET_INFO_BLOCK_START,
  GET_INFO_BLOCK_SUCCESS,
  GET_POKA_FILE_FAIL,
  GET_POKA_FILE_START,
  GET_POKA_FILE_SUCCESS,
  GET_PRESENTATION_FAIL,
  GET_PRESENTATION_START,
  GET_PRESENTATION_SUCCESS,
  GET_QUESTION_START,
  GET_QUESTION_SUCCESS,
  GET_SCORM_FAIL,
  GET_SCORM_START,
  GET_SCORM_SUCCESS,
  GET_TYPEFORM_FAIL,
  GET_TYPEFORM_START,
  GET_TYPEFORM_SUCCESS,
  GET_VIDEO_START,
  GET_VIDEO_SUCCESS,
  RESTART_BLOCKS_FAIL,
  RESTART_BLOCKS_START,
  RESTART_BLOCKS_SUCCESS,
  RESTART_SCORM_BLOCK_FAIL,
  RESTART_SCORM_BLOCK_START,
  RESTART_SCORM_BLOCK_SUCCESS,
  SEND_SCORM_BLOCK_STATISTICS_FAIL,
  SEND_SCORM_BLOCK_STATISTICS_START,
  SEND_SCORM_BLOCK_STATISTICS_SUCCESS,
  SET_ANSWER_FAIL,
  SET_ANSWER_START,
  SET_ANSWER_SUCCESS,
  SET_TYPEFORM_FINISH_FAIL,
  SET_TYPEFORM_FINISH_START,
  SET_TYPEFORM_START_FAIL,
  SET_TYPEFORM_START_START,
  SET_TYPEFORM_START_SUCCESS,
  START_ARTICLE_BLOCK_FAIL,
  START_ARTICLE_BLOCK_START,
  START_ARTICLE_BLOCK_SUCCESS,
  START_BLOCKS_START,
  START_BLOCKS_SUCCESS,
  START_POKA_FILE_BLOCK_FAIL,
  START_POKA_FILE_BLOCK_SUCCESS,
  START_PRESENTATION_BLOCK_FAIL,
  START_PRESENTATION_BLOCK_START,
  START_PRESENTATION_BLOCK_SUCCESS,
  START_SCORM_BLOCK_FAIL,
  START_SCORM_BLOCK_START,
  START_SCORM_BLOCK_SUCCESS,
  START_TILDA_BLOCK_FAIL,
  START_TILDA_BLOCK_START,
  START_TILDA_BLOCK_SUCCESS,
  START_VIDEO_BLOCK_FAIL,
  START_VIDEO_BLOCK_START,
  START_VIDEO_BLOCK_SUCCESS,
} from './actions';
import { CLEAR_QUESTION_NUMBER, SET_QUESTION_NUMBER } from './constants/questions';

const initialState = {
  statistic: null,
  question: null,
  details: null,
  scorm: null,
  video: null,
  presentation: null,
  article: null,
  typeform: null,
  isLoading: true,
  error: false,
  currentQuestionNumber: 1,
  arrayChecked: [],
  pokaFiles: {
    description: '',
    files: [],
  },
};

export default (state = initialState, { type, response, blockId }) => {
  switch (type) {
    case GET_BLOCK_STATISTIC_START:
      return {
        ...state,
        question: null,
        details: null,
        isLoading: true,
        error: false,
      };

    case GET_BLOCK_STATISTIC_SUCCESS:
      return {
        ...state,
        statistic: response,
        isLoading: false,
        error: false,
      };

    case GET_ARTICLE_SUCCESS:
      return { ...state, article: response, isLoading: false, error: false };

    case GET_BLOCK_STATISTIC_FAIL:
      return {
        ...state,
        statistic: [],
        isLoading: false,
        error: true,
      };

    case START_BLOCKS_START:
    case GET_QUESTION_START:
      return {
        ...state,
        details: null,
      };

    case START_BLOCKS_SUCCESS:
    case SET_ANSWER_SUCCESS:
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        question: response,
      };

    case GET_BLOCK_DETAILS_START:
      return {
        ...state,
        question: null,
      };

    case GET_BLOCK_DETAILS_SUCCESS:
      return {
        ...state,
        details: response,
      };

    case GET_SCORM_START:
      return {
        ...state,
        scorm: null,
      };

    case GET_SCORM_SUCCESS:
      return {
        ...state,
        scorm: response,
      };
    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        video: response,
        isLoading: false,
      };
    case GET_VIDEO_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SCORM_FAIL:
      return {
        ...state,
        error: true,
      };
    case GET_TYPEFORM_FAIL:
    case SET_TYPEFORM_FINISH_FAIL:
    case SET_TYPEFORM_START_FAIL:
    case GET_ARTICLE_START:
    case GET_PRESENTATION_START:
    case GET_PRESENTATION_FAIL:
    case GET_POKA_FILE_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case GET_PRESENTATION_SUCCESS:
      return {
        ...state,
        presentation: response,
        isLoading: false,
      };

    case GET_TYPEFORM_SUCCESS:
      return {
        ...state,
        typeform: response,
        isLoading: false,
      };

    case SET_TYPEFORM_START_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_TYPEFORM_START:
    case SET_TYPEFORM_START_START:
    case SET_TYPEFORM_FINISH_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_INFO_BLOCK_SUCCESS:
      return { ...state, block: response };

    case CLEAR_DETAILS_SUCCESS:
      return { ...state, details: null };

    case CLEAR_QUESTION_SUCCESS:
      return { ...state, question: null };

    case SET_QUESTION_NUMBER:
      return { ...state, currentQuestionNumber: response };

    case CLEAR_QUESTION_NUMBER:
      return { ...state, currentQuestionNumber: 1 };

    case GET_POKA_FILE_START:
      return {
        ...state,
        pokaFiles: {
          description: '',
          files: [],
        },
        isLoading: true,
      };

    case GET_POKA_FILE_SUCCESS:
      return { ...state, pokaFiles: response, isLoading: false };

    case START_POKA_FILE_BLOCK_SUCCESS:
    case START_POKA_FILE_BLOCK_FAIL:
    case FINISH_POKA_FILE_BLOCK_START:
    case FINISH_POKA_FILE_BLOCK_SUCCESS:
    case FINISH_POKA_FILE_BLOCK_FAIL:
    case START_SCORM_BLOCK_START:
    case START_SCORM_BLOCK_SUCCESS:
    case START_SCORM_BLOCK_FAIL:
    case FINISH_SCORM_BLOCK_START:
    case FINISH_SCORM_BLOCK_SUCCESS:
    case FINISH_SCORM_BLOCK_FAIL:
    case RESTART_SCORM_BLOCK_START:
    case RESTART_SCORM_BLOCK_SUCCESS:
    case RESTART_SCORM_BLOCK_FAIL:
    case SEND_SCORM_BLOCK_STATISTICS_START:
    case SEND_SCORM_BLOCK_STATISTICS_SUCCESS:
    case SEND_SCORM_BLOCK_STATISTICS_FAIL:
    case START_VIDEO_BLOCK_START:
    case START_VIDEO_BLOCK_SUCCESS:
    case START_VIDEO_BLOCK_FAIL:
    case FINISH_VIDEO_BLOCK_START:
    case FINISH_VIDEO_BLOCK_SUCCESS:
    case FINISH_VIDEO_BLOCK_FAIL:
    case START_ARTICLE_BLOCK_START:
    case START_ARTICLE_BLOCK_SUCCESS:
    case START_ARTICLE_BLOCK_FAIL:
    case FINISH_ARTICLE_BLOCK_START:
    case FINISH_ARTICLE_BLOCK_SUCCESS:
    case FINISH_ARTICLE_BLOCK_FAIL:
    case START_PRESENTATION_BLOCK_START:
    case START_PRESENTATION_BLOCK_SUCCESS:
    case START_PRESENTATION_BLOCK_FAIL:
    case FINISH_PRESENTATION_BLOCK_START:
    case FINISH_PRESENTATION_BLOCK_SUCCESS:
    case FINISH_PRESENTATION_BLOCK_FAIL:
    case START_TILDA_BLOCK_START:
    case START_TILDA_BLOCK_SUCCESS:
    case START_TILDA_BLOCK_FAIL:
    case FINISH_TILDA_BLOCK_START:
    case FINISH_TILDA_BLOCK_SUCCESS:
    case FINISH_TILDA_BLOCK_FAIL:
    case GET_INFO_BLOCK_START:
    case GET_INFO_BLOCK_FAIL:
    case GET_BLOCKS_START:
    case GET_BLOCKS_SUCCESS:
    case GET_BLOCKS_FAIL:
    case FINISH_BLOCKS_START:
    case FINISH_BLOCKS_SUCCESS:
    case FINISH_BLOCKS_FAIL:
    case RESTART_BLOCKS_START:
    case RESTART_BLOCKS_SUCCESS:
    case RESTART_BLOCKS_FAIL:
    case SET_ANSWER_START:
    case SET_ANSWER_FAIL:
      return { ...state };

    case CHECKED_AVAILABLE_SUCCESS:
      return { ...state, arrayChecked: [...state.arrayChecked, blockId] };

    default:
      return state;
  }
};
