/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { errorChange } from '@/store/error/actions';

import { selectError as errorSelect } from '@/store/error/selectors';

import { Button, Layout } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './NotFound.module.scss';

const { Content } = Layout;

class NotAccessGoogle extends Component {
  static propTypes = {
    errorChange: PropTypes.func,
    navigate: PropTypes.func,
  };

  reset = () => {
    this.props.errorChange();
    localStorage.setItem('authorizationToken', '');
    this.props.navigate('/user-login/');
  };

  render() {
    return (
      <Layout className={css.NotFound}>
        <Content className={css['NotFound-content']}>
          <svg xmlns='http://www.w3.org/2000/svg' width='461' height='226' viewBox='0 0 461 226'>
            <g fill='none'>
              <text fill='#E86F00' transform='translate(-6 -53)'>
                <tspan x='.25' y='235'>
                  403
                </tspan>
              </text>
              <path
                d='M303.735 193.212c9.85 2.717 16.305 6 23.438.566s8.945 4.756 16.984 5.888-5.428 6-16.644 6c-11.663 0-23.778-12.454-23.778-12.454m-77.565-35.787c-5.208.801-9.615 1.87-13.565 9.737-4.986-.69-9.532-4.38-10.472-9.537-11.685 11.017-16.989-7.57-17.994-7.912 0 0-5.776 3.572-5.109 17.193-9.214-2.804-17.026-25.906-17.026-25.906s-6.21 13.621-3.227 23.203c-2.528-2.945-6.289-6.19-11.796-8.78.601 9.413-5.208 12.82-5.208 12.82s-7.813-5.41-11.752-10.818c0 26.551 21.524 48.075 48.074 48.075h96.15c-26.551 0-48.075-21.524-48.075-48.075'
                fill='#E6E6E6'
              />
              <path d='M274.245 205.5h2.24v-.054c-1 .031-1.523.054-2.24.054' fill='#EDEDED' />
              <path
                d='M245.133 157.425c5.876 14.422 28.043 11.752 26.708-5.876 8.947 4.006 13.755 12.152 13.092 18.696 8.007-2.404 11.747-11.752 11.747-16.025 5.742 3.74 8.012 10.016 6.677 19.03 13.888-3.272 14.689-25.84 14.689-25.84s7.21 28.444 23.236 10.015c0 26.441-21.524 48.075-48.074 48.075h-96.15c26.55 0 48.075-21.634 48.075-48.075'
                fill='#F6F6F6'
              />
              <path d='M197.058 205.5h-2.574v-.054c1 .031 1.857.054 2.574.054' fill='#FFF' />
              <path
                d='M103.735 196.5c7.25 2 12 4.417 17.25.417s6.583 3.5 12.5 4.333-3.995 4.417-12.25 4.417c-8.583 0-17.5-9.167-17.5-9.167M52 202.353s16.85-.507 16.47 0-10.516 3.547-10.516 3.547z'
                fill='#E6E6E6'
              />
              <path
                d='M143 213.477s22.995-.693 22.476 0c-.519.691-14.35 4.84-14.35 4.84zm267.714-13.359c-5.971 1.647-9.882 3.637-14.206.343s-5.422 2.882-10.294 3.569 3.29 3.637 10.088 3.637c7.069 0 14.412-7.55 14.412-7.55M269.17 218.638s35.093-1.055 34.302 0-21.9 7.388-21.9 7.388z'
                fill='#FFF'
              />
            </g>
          </svg>
          <h1>Вы пробуете подключиться без VPN</h1>
          <p>
            И вы можете это делать. Но сначала нужно зайти с VPN, перейти в свой профиль, в настройках привязать
            Google-аккаунт.
          </p>
          <div>
            <Button className={css['NotFound-btn']} type='primary' size='large' inverted onClick={this.reset}>
              Вернуться на экран авторизации
            </Button>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = createSelector([errorSelect], error => ({
  errorStatus: error,
}));
const mapActionsToProps = {
  errorChange,
};

export default connect(mapStateToProps, mapActionsToProps)(NotAccessGoogle);
