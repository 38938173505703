import React, { useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Menu.module.scss';

const MenuBurger = ({ open, content }) => {
  const el = document.getElementsByClassName('ant-layout')[0];
  const body = document.body;

  useEffect(() => {
    if (open && el && body) {
      el.style.height = '100%';
      el.style.overflow = 'hidden';
      body.style.height = '100';
      body.style.overflow = 'hidden';
    } else if (el && body) {
      el.style.height = null;
      el.style.overflow = null;
      body.style.height = 'inherit';
      body.style.overflow = 'auto';
    }
  }, [open, el, body]);

  return (
    <div className={open ? classNames(css['menu'], css['menu--open']) : css['menu']}>
      <div className={css['container']}>{content}</div>
    </div>
  );
};

MenuBurger.propTypes = {
  open: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
};

export default MenuBurger;
