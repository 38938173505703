import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { clearUnReadNotifications, setUnReadNotifications } from '@/store/notifications-new/actions';

import { notification } from 'antd';
import { useNotificationTypes } from '@/components/NotificationTypes';

import ConnectStompClient from '@/sockets/notifications.socket';

import css from './Notifications.module.scss';

let audioNotification;
let isStartAudio = false;
let isMobile = false;

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myNotification');

  const [api, contextHolder] = notification.useNotification();

  const newNotificantion = msg => {
    if (isStartAudio && audioNotification) {
      audioNotification.volume = 0; // изменил значение по задаче https://kampus.atlassian.net/browse/KAM-7451
      // audioNotification.play(); Закомментировал по задаче https://kampus.atlassian.net/browse/KAM-7451
    }

    const { IconNotification } = useNotificationTypes({ type: msg.groupTemplateType, t });

    const key = `notification-key-${msg.id}`;
    const message = isMobile
      ? msg.subject.length > 30
        ? msg.subject.slice(0, 32) + ' ...'
        : msg.subject
      : msg.subject.length > 49
        ? msg.subject.slice(0, 47) + ' ...'
        : msg.subject;
    const description = isMobile
      ? msg.content.length > 30
        ? msg.content.slice(0, 32) + ' ...'
        : msg.content
      : msg.content.length > 59
        ? msg.content.slice(0, 57) + ' ...'
        : msg.content;

    api.open({
      message: (
        <Link className={css['notifications--message']} to={`/my-notifications?modal_id=${msg.id}`}>
          {message}
        </Link>
      ),
      placement: isMobile ? 'topRight' : 'bottomRight',
      top: isMobile ? 80 : 24,
      description: (
        <Link className={css['notifications--message']} to={`/my-notifications?modal_id=${msg.id}`}>
          {description}
        </Link>
      ),
      className: css['notifications--box'],
      key,
      icon: <IconNotification />,
    });
  };

  const eventOnceInitAudio = () => {
    // audioNotification.play(); Закомментировал по задаче https://kampus.atlassian.net/browse/KAM-7451
    isStartAudio = true;
    window.removeEventListener('click', eventOnceInitAudio);
  };

  useEffect(() => {
    const onCheckMobile = () => {
      isMobile = window.innerWidth < 992;
    };
    onCheckMobile();
    const JWT_TOKEN = localStorage.getItem('authorizationToken') || '';
    const SPLIT_JWT_TOKEN = JWT_TOKEN.split(' ');

    const stompClient = new ConnectStompClient(
      SPLIT_JWT_TOKEN[1],
      3,
      5000,
      newNotificantion,
      (listNotification, isIncrement) => {
        dispatch(setUnReadNotifications(listNotification, isIncrement));
      }
    );

    audioNotification = new Audio('/assets/sounds/notification.wav');
    audioNotification.volume = 0;

    document.addEventListener('resize', onCheckMobile);
    const windowListener = window.addEventListener('click', eventOnceInitAudio);

    return () => {
      console.log('disconnect ---------------------------');
      dispatch(clearUnReadNotifications());
      stompClient.disconnect();
      windowListener && window.removeEventListener('click', eventOnceInitAudio);
      document.removeEventListener('resize', onCheckMobile);
    };
  }, []);

  return <div className={css.DragItem}>{contextHolder}</div>;
};

export default Notifications;
