/* eslint-disable */
import React from 'react';

import classNames from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import css from './OldIcon.module.scss';

const Icon = props => {
  const { type, className = '', spin } = props;

  const typeClassname = `anticon-${type}`;

  const classString = classNames(
    {
      [css['anticon-spin']]: !!spin || type === 'loading',
      [css[typeClassname]]: true,
    },
    className
  );
  return <i {...omit(props, ['type', 'spin'])} className={classString} />;
};

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.any,
  spin: PropTypes.bool,
  style: PropTypes.any,
};

export default Icon;
