// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-FL0ill.user-src-components-BurgerMenu-Menu-Menu-module__ql-font-WGE7ft .user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-label-wPI4N8[data-value="Montserrat"]:before, .user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-FL0ill.user-src-components-BurgerMenu-Menu-Menu-module__ql-font-WGE7ft .user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-item-CdakNd[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-BurgerMenu-Menu-Menu-module__ql-font-Montserrat-ba7n1z {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-BurgerMenu-Menu-Menu-module__container-mQ8sk6 {
  width: 100%;
}

.user-src-components-BurgerMenu-Menu-Menu-module__container-mQ8sk6 .ant-menu-item.ant-menu-item-disabled {
  display: none;
}

.user-src-components-BurgerMenu-Menu-Menu-module__container-mQ8sk6 > ul {
  height: calc(100dvh - 80px);
  overflow: scroll;
}

.user-src-components-BurgerMenu-Menu-Menu-module__menu-qGpEGd {
  text-align: left;
  z-index: 6;
  background: #fff;
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 100dvh;
  padding: 48px 0 0;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: -40px;
  right: -100%;
  box-shadow: 0 4px 30px #121e4bcc;
}

@media (max-width: 540px) {
  .user-src-components-BurgerMenu-Menu-Menu-module__menu-qGpEGd {
    width: 320px;
  }
}

.user-src-components-BurgerMenu-Menu-Menu-module__menu--open-SXsHKK {
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/BurgerMenu/Menu/Menu.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;AACb;;AAMA;EAHE,aAAa;AACf;;AAKA;EAFE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAKA;EAFE,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB,0BAA0B;EAC1B,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,UAAU;EACV,YAAY;EACZ,gCAAgC;AAClC;;AAIA;EACE;IADE,YAAY;EACd;AACF;;AAGA;EAAE,QAAQ;AACV","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.container {\n  width: 100%;\n}\n.container :global .ant-menu-item.ant-menu-item-disabled {\n  display: none;\n}\n.container > ul {\n  overflow: scroll;\n  height: calc(100dvh - 80px);\n}\n\n.menu {\n  display: flex;\n  height: 100dvh;\n  background: #fff;\n  text-align: left;\n  padding: 48px 0 0;\n  position: fixed;\n  top: -40px;\n  border-radius: 8px 0 0 8px;\n  right: -100%;\n  transition: 0.3s;\n  z-index: 6;\n  width: 100%;\n  box-shadow: 0 4px 30px rgba(18, 30, 75, 0.8);\n}\n@media (width <= 540px) {\n  .menu {\n    width: 320px;\n  }\n}\n.menu--open {\n  right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-FL0ill`,
	"qlPicker": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-FL0ill`,
	"ql-font": `user-src-components-BurgerMenu-Menu-Menu-module__ql-font-WGE7ft`,
	"qlFont": `user-src-components-BurgerMenu-Menu-Menu-module__ql-font-WGE7ft`,
	"ql-picker-label": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-label-wPI4N8`,
	"qlPickerLabel": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-label-wPI4N8`,
	"ql-picker-item": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-item-CdakNd`,
	"qlPickerItem": `user-src-components-BurgerMenu-Menu-Menu-module__ql-picker-item-CdakNd`,
	"ql-font-Montserrat": `user-src-components-BurgerMenu-Menu-Menu-module__ql-font-Montserrat-ba7n1z`,
	"qlFontMontserrat": `user-src-components-BurgerMenu-Menu-Menu-module__ql-font-Montserrat-ba7n1z`,
	"container": `user-src-components-BurgerMenu-Menu-Menu-module__container-mQ8sk6`,
	"menu": `user-src-components-BurgerMenu-Menu-Menu-module__menu-qGpEGd`,
	"menu--open": `user-src-components-BurgerMenu-Menu-Menu-module__menu--open-SXsHKK`,
	"menuOpen": `user-src-components-BurgerMenu-Menu-Menu-module__menu--open-SXsHKK`
};
export default ___CSS_LOADER_EXPORT___;
