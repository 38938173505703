import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { setRestoreToken } from '@/store/company/actions';
import { actualLoginRegisterRestore, restoreUserData } from '@/store/login/actions';
import { getUser } from '@/store/users/actions';

import { selectCompany } from '@/store/company/selectors';

import { Button, Form, Input, Spin } from 'antd';
import LogoCompany from '@/components/LogoCompany';

import classNames from 'classnames';

import css from './Login.module.scss';

const RecoveryPageData = () => {
  const { imageBackgroundLogin, imageLogo, navigate } = useOutletContext();
  const { t } = useTranslation('login');
  const { restoreToken } = useSelector(selectCompany);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(true);

  const handleSubmit = formData => {
    dispatch(
      restoreUserData(formData.newPassword, restoreToken, response => {
        localStorage.setItem('authorizationToken', response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('expiresIn', response.expiresIn);
        localStorage.setItem('expiresTime', response.expiresIn * 1000 + Date.now());
        dispatch(getUser({ onSuccess: navigate('/planning') }, response.accessToken));
      })
    );
  };

  const handleChangeRenderPage = flag => {
    navigate(`/user-login/${flag}`);
  };

  useLayoutEffect(() => {
    const refreshToken = searchParams.get('token');
    const restoreToken = `Bearer ${refreshToken}`;

    dispatch(
      actualLoginRegisterRestore(
        restoreToken,
        () => {
          dispatch(setRestoreToken(restoreToken));
          setLoading(false);
        },
        () => {
          navigate(`/user-login/auth-fail`);
        }
      )
    );
  }, []);

  return (
    <Spin spinning={isLoading} className={css['App-card-item']}>
      <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
      <Form className={css['LoginPage-form']} onFinish={handleSubmit}>
        <div className={css['LoginPage-form__content']}>
          <div className={css['LoginPage-header']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
          </div>
          <div className={classNames(css['LoginPage-titleHead'], css['LoginPage-titleHead-modificate'])}>
            {t('changePassword')}
          </div>
          <Form.Item
            colon={false}
            name='newPassword'
            className={css['LoginPage-form-item']}
            hasFeedback
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t('passwordRequired'),
              },
              {
                min: 6,
                max: 25,
                message: t('passwordLength'),
              },
              {
                pattern: /^[a-zA-Z0-9!@#$%^&*]/,
                message: t('passwordWrong'),
              },
            ]}
          >
            <Input.Password placeholder={t('newPassword')} />
          </Form.Item>
          <Form.Item
            colon={false}
            name='confirm'
            dependencies={['newPassword']}
            hasFeedback
            className={css['LoginPage-form-item']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t('passwordRequired'),
              },
              {
                min: 6,
                max: 25,
                message: t('passwordLength'),
              },
              {
                pattern: /^[a-zA-Z0-9!@#$%^&*]/,
                message: t('passwordWrong'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('passwordsNotMatch')));
                },
              }),
            ]}
          >
            <Input.Password placeholder={t('repeatPassword')} />
          </Form.Item>
          <Form.Item className={css['LoginPage-form-google']}>
            <Button size='large' type={'primary'} htmlType='submit'>
              {t('changePass')}
            </Button>
          </Form.Item>
          <div className={css['LoginPage-label']}>
            <div className={classNames(css.LoginPage__toInfo)} onClick={() => handleChangeRenderPage('auth')}>
              {t('goBack')}
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default RecoveryPageData;
