/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';

import { Collapse } from 'antd';

import classNames from 'classnames';

import css from './Courses.module.scss';

import articleIcon from '@/assets/icons/article.svg';
import checkedIcon from '@/assets/icons/checked.svg';
import notStarted from '@/assets/icons/not-started.svg';
import presentationIcon from '@/assets/icons/presentation.svg';
import scormIcon from '@/assets/icons/scorm.svg';
import testIcon from '@/assets/icons/test.svg';
import uncheckedIcon from '@/assets/icons/unchecked.svg';
import videoIcon from '@/assets/icons/video.svg';

const { Panel } = Collapse;

const TestStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROCESS: 'IN_PROCESS',
  FINISHED: 'FINISHED',
};

const Courses = ({ data, currentTestId, onChange, chapters }) => {
  const [open, setOpen] = useState([]);

  useEffect(() => {
    setOpen(chapters.filter(chapter => chapter.id).map(chapter => chapter.id.toString()));
  }, [chapters]);

  useEffect(() => {
    const currentChapter = chapters.find(chapter => chapter.blocks.find(block => block.id === currentTestId));
    if (currentChapter && currentChapter.id && !open.includes(currentChapter.id.toString()))
      setOpen([...open, currentChapter.id.toString()]);
  }, [currentTestId]);

  const chaptersWithStatistic = useMemo(
    () =>
      data &&
      chapters.map(chapter => ({
        ...chapter,
        blocks: chapter.blocks.map(block => ({
          ...block,
          ...data.find(statistic => statistic.blockId === block.id),
        })),
      })),
    [chapters, data]
  );

  const blockIcon = typeId => {
    switch (typeId) {
      case 1:
      case 6:
        return testIcon;

      case 3:
        return videoIcon;

      case 4:
        return presentationIcon;

      case 10:
      case 5:
        return articleIcon;

      default:
        return scormIcon;
    }
  };

  const block = item => (
    <div className={css['Courses-item']} key={item.blockId} onClick={() => onChange(item)}>
      <img
        src={
          item.status === TestStatus.FINISHED
            ? checkedIcon
            : currentTestId === item.blockId
              ? notStarted
              : uncheckedIcon
        }
        alt=''
      />
      <div>
        <div
          className={classNames([
            css['Courses-item-title'],
            currentTestId === item.blockId && css['Courses-item-title-active'],
          ])}
        >
          {item.name}
        </div>
        <div className={css['Courses-item-subtitle']}>
          <img src={blockIcon(item?.type?.id)} alt='' />
          {item.type?.name}
        </div>
      </div>
    </div>
  );

  const header = data => {
    const active = data.blocks.find(block => block.id === currentTestId);
    const allFinished = !data.blocks.find(block => block.status !== TestStatus.FINISHED);
    return (
      <div className={classNames(css['Courses-item'], css['Courses-item-chapter'])} key={data.id}>
        <img src={allFinished ? checkedIcon : active ? notStarted : uncheckedIcon} alt='' />
        <div>
          <div className={classNames([css['Courses-item-title'], active && css['Courses-item-title-active']])}>
            {data.name}
          </div>
        </div>
      </div>
    );
  };

  const collapseItem = chapter => {
    return [
      {
        key: '1',
        label: header(chapter),
        children: chapter.blocks.map(block),
      },
    ];
  };

  return data && currentTestId && chapters ? (
    <div className={css.Courses}>
      {chaptersWithStatistic?.map(chapter =>
        chapter?.id ? (
          <Collapse
            className={css['Courses-collapse']}
            key={chapter?.id}
            defaultActiveKey={['1']}
            expandIconPosition='end'
            bordered={false}
            items={collapseItem(chapter)}
          />
        ) : (
          chapter?.blocks?.map(item => block(item))
        )
      )}
    </div>
  ) : null;
};

export default Courses;
