// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ModalTargetAudience-Modal-module__ql-picker-mZi4v0.user-src-components-ModalTargetAudience-Modal-module__ql-font-fbcw7Z .user-src-components-ModalTargetAudience-Modal-module__ql-picker-label-lFg_06[data-value="Montserrat"]:before, .user-src-components-ModalTargetAudience-Modal-module__ql-picker-mZi4v0.user-src-components-ModalTargetAudience-Modal-module__ql-font-fbcw7Z .user-src-components-ModalTargetAudience-Modal-module__ql-picker-item-g2I7bu[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ModalTargetAudience-Modal-module__ql-font-Montserrat-Mrq59e {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ModalTargetAudience-Modal-module__Modal-oecI7L {
  max-width: 450px;
}

.user-src-components-ModalTargetAudience-Modal-module__Modal-body-IMXH6o {
  padding: 32px 16px;
}

.user-src-components-ModalTargetAudience-Modal-module__Modal-footer-SALLTi {
  justify-content: space-around;
  display: flex;
}

.user-src-components-ModalTargetAudience-Modal-module__Modal-btn-WCWVgG {
  min-width: 160px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ModalTargetAudience/Modal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EAHE,kBAAkB;AACpB;;AAKA;EAFE,6BAA6B;EAC7B,aAAa;AACf;;AAIA;EADE,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Modal {\n  max-width: 450px;\n}\n.Modal-body {\n  padding: 32px 16px;\n}\n.Modal-footer {\n  display: flex;\n  justify-content: space-around;\n}\n.Modal-btn {\n  min-width: 160px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-mZi4v0`,
	"qlPicker": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-mZi4v0`,
	"ql-font": `user-src-components-ModalTargetAudience-Modal-module__ql-font-fbcw7Z`,
	"qlFont": `user-src-components-ModalTargetAudience-Modal-module__ql-font-fbcw7Z`,
	"ql-picker-label": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-label-lFg_06`,
	"qlPickerLabel": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-label-lFg_06`,
	"ql-picker-item": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-item-g2I7bu`,
	"qlPickerItem": `user-src-components-ModalTargetAudience-Modal-module__ql-picker-item-g2I7bu`,
	"ql-font-Montserrat": `user-src-components-ModalTargetAudience-Modal-module__ql-font-Montserrat-Mrq59e`,
	"qlFontMontserrat": `user-src-components-ModalTargetAudience-Modal-module__ql-font-Montserrat-Mrq59e`,
	"Modal": `user-src-components-ModalTargetAudience-Modal-module__Modal-oecI7L`,
	"modal": `user-src-components-ModalTargetAudience-Modal-module__Modal-oecI7L`,
	"Modal-body": `user-src-components-ModalTargetAudience-Modal-module__Modal-body-IMXH6o`,
	"modalBody": `user-src-components-ModalTargetAudience-Modal-module__Modal-body-IMXH6o`,
	"Modal-footer": `user-src-components-ModalTargetAudience-Modal-module__Modal-footer-SALLTi`,
	"modalFooter": `user-src-components-ModalTargetAudience-Modal-module__Modal-footer-SALLTi`,
	"Modal-btn": `user-src-components-ModalTargetAudience-Modal-module__Modal-btn-WCWVgG`,
	"modalBtn": `user-src-components-ModalTargetAudience-Modal-module__Modal-btn-WCWVgG`
};
export default ___CSS_LOADER_EXPORT___;
