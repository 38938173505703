/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Modal } from 'antd';

import useFormatCost from '@shared/hooks/useFormatCost';

import PropTypes from 'prop-types';

import css from './PenaltyModal.module.scss';

export const ResultCancelModal = props => {
  const { t } = useTranslation('penaltyModal');

  const formattedCost = useFormatCost(props.material?.event?.cancellationPenalty ?? 0, props.currentUser);

  return (
    <Modal width={450} open={props.open} zIndex={10000} footer={null} onCancel={props.onCancel}>
      <div className={css['penaltyModal-body']}>
        <div>{t('resultMain', { penaltyCost: formattedCost })}</div>
        <div className={css['penaltyModal-body-alert']}>{t('resultAlertText')}</div>
        <div>{t('resultConfText')}</div>
      </div>
      <div className={css['penaltyModal-footer']}>
        <Link to='mailto:MarsUniversityRussia@effem.com'>
          <Button onClick={props.onFinish} type='primary'>
            {t('mailtoText')}
          </Button>
        </Link>
        <Button onClick={props.onAccept} type='primary'>
          {t('resultOkText')}
        </Button>
      </div>
    </Modal>
  );
};

ResultCancelModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  material: PropTypes.any,
  onFinish: PropTypes.func,
};
