import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams /* useNavigate */ } from 'react-router';

import { getFile } from '@/store/files/actions';
import { getTrackById } from '@/store/tracks/actions';

import { selectTracks } from '@/store/tracks/selectors';

import { Button, Layout, Row, Spin } from 'antd';
import { Content } from '@/components/Layout';
import Tag from '@/components/Tag';
import TrackProgress from '@/components/TrackProgress';
import { CloseOutlined } from '@ant-design/icons';

import MaterialCard from './MaterialCard';

import { isEmpty } from 'lodash';

import css from './TrackId.module.scss';

const TrackId = () => {
  const { t } = useTranslation('TrackId');
  const { id } = useParams();
  const dispatch = useDispatch();
  const { track, isLoading } = useSelector(selectTracks);
  const [cover, setCover] = useState(null);
  const [statPer, setStatPer] = useState(0);

  useEffect(() => {
    dispatch(getTrackById(id, false));
  }, [id]);

  const { state } = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    if (state && state.cover && state.trackId === +id) {
      setCover(state.cover);
    } else {
      !isEmpty(track) &&
        track.coverImage &&
        dispatch(getFile(track.coverImage, cover => setCover(URL.createObjectURL(cover))));
    }
  }, [state, dispatch, track, id]);

  useEffect(() => {
    return () => {
      setCover(null);
      // navigate('', {
      //   state: {
      //     ...state,
      //     cover: null,
      //     trackId: null,
      //   },
      // });
    };
  }, []);

  // Считаем статистику
  useEffect(() => {
    if (track?.finishedTopics && track?.totalTopics) {
      setStatPer(Math.ceil((track.finishedTopics / track.totalTopics) * 100));
    }
  }, [track.finishedTopics, track.totalTopics]);

  const competences = useMemo(
    () =>
      Array.isArray(track.competences) &&
      !isEmpty(track.competences) &&
      track.competences.map(competence => <Tag key={competence.id} tag={competence} noHover />),
    [track.competences]
  );

  const categories = useMemo(
    () =>
      Array.isArray(track.categories) &&
      !isEmpty(track.categories) &&
      track?.categories.map(category => <p key={category.id}>{category?.name}</p>),
    [track.categories]
  );

  const skills = useMemo(
    () =>
      Array.isArray(track.skills) &&
      !isEmpty(track.skills) &&
      track?.skills.map(skill => <p key={skill.id}>{skill?.name}</p>),
    [track.skills]
  );

  const topics = useMemo(
    () =>
      Array.isArray(track.topics) &&
      !isEmpty(track.topics) &&
      track?.topics
        .sort((a, b) => a.position - b.position)
        .map(topic => <MaterialCard key={topic.id} topic={topic} trackId={id} />),
    [track?.topics]
  );

  const onClose = () => window.location.replace('/planning?tab=tracks');

  return (
    <Layout>
      <Content>
        <div className={css['Track']}>
          {isLoading && !state ? (
            <Spin />
          ) : (
            <>
              <div className={css['Track-container']}>
                <Button
                  onClick={onClose}
                  className={css['Track-close']}
                  icon={<CloseOutlined />}
                  shape='circle'
                  size='large'
                />
                <div className={css['Track-header']}>
                  <div className={css['Track-header-content']}>
                    <div>
                      <h2 className={css['Track-header-title']}>{state?.trackName ?? track?.name}</h2>
                      <p className={css['Track-header-subtitle']}>
                        {state?.shortDescription ?? track.shortDescription}
                      </p>
                    </div>
                  </div>
                  {cover && track?.coverImage ? (
                    <img className={css['Track-header-cover']} src={cover} alt='TrackCover' />
                  ) : (
                    <div className={css['Track-header-cover']}></div>
                  )}
                </div>
                <div className={css['Track-content']}>
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <Row>
                        <div className={css['Track-content-top']}>
                          <div className={css['Track-content-left']}>
                            {/* Описание */}
                            {!isEmpty(track?.fullDescription) && (
                              <p className={css['Track-content-description']}>{track?.fullDescription}</p>
                            )}
                            {/* Компетенции */}
                            {!isEmpty(track?.competences) && (
                              <>
                                <p className={css['Track-content-title']}>{t('competences')}</p>
                                <div className={css['Track-content-competences']}>{competences}</div>
                              </>
                            )}
                            {/* Материалы */}
                            <p className={css['Track-content-title']}>{t('topics')}</p>
                            <div className={css['Track-content-topics']}>{topics}</div>
                          </div>
                          <div className={css['Track-content-right']}>
                            <TrackProgress
                              statPer={statPer}
                              finishedTopics={track?.finishedTopics}
                              totalTopics={track?.totalTopics}
                              className={css['Track-content-right-progress']}
                            />
                            <p className={css['Track-content-right-title']}>{t('duration')}</p>
                            <p className={css['Track-content-right-subtitle']}>
                              {t('days', { days: track?.duration })}
                            </p>
                            {!isEmpty(categories) && (
                              <p className={css['Track-content-right-title']}>{t('category')}</p>
                            )}
                            <div className={css['Track-content-right-subtitle']}>{categories}</div>
                            {!isEmpty(skills) && <p className={css['Track-content-right-title']}>{t('skills')}</p>}
                            <div className={css['Track-content-right-subtitle']}>{skills}</div>
                          </div>
                        </div>
                      </Row>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default TrackId;
