import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getNews } from '@/store/news/actions';

import { selectNews } from '@/store/news/selectors';

import { Layout, Pagination, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Filter from './parts/Filter';
import { SEARCH_TYPES } from './parts/Filter/constants';
import NewsCard from './parts/NewsCard';
import NewsModalCreate from './parts/NewsModalCreate/NewsModalCreate';

import styles from './News.module.scss';

const { Content } = Layout;
const { Text, Title } = Typography;

const News = ({ navigate }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('news');
  const { newsList, pagination, isLoading } = useSelector(selectNews);

  const [modalVisible, setModalVisible] = useState(false);
  const [filterParams, setFilterParams] = useState({
    sort: 'createdDate,desc',
    type: SEARCH_TYPES[0],
    value: undefined,
  });

  useEffect(() => {
    dispatch(getNews(0, filterParams));
  }, [filterParams]);

  const handlerChangePage = value => {
    dispatch(getNews(value - 1, filterParams));
  };

  return (
    <Content className={styles.news}>
      <div className={styles.news__header}>
        <Title level={1} className={styles.news__title}>
          {t('title')}
        </Title>
        <Filter
          navigate={navigate}
          params={filterParams}
          changeParams={param => setFilterParams(param)}
          openModal={() => setModalVisible(true)}
          placeHolder={t('search')}
        />
      </div>
      {newsList.length > 0 ? (
        <div className={styles.news__content}>
          {newsList?.map((data, index) => (
            <NewsCard data={data} key={index} />
          ))}
        </div>
      ) : (
        <div className={styles.news__noContent}>
          {isLoading.getNews ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          ) : (
            <Text className={styles.news__text}>{t('noNews')}</Text>
          )}
        </div>
      )}
      {modalVisible && (
        <NewsModalCreate
          filterParams={filterParams}
          visible={modalVisible}
          isCreate
          onClose={() => setModalVisible(false)}
        />
      )}
      {newsList.length && pagination.newsList.size <= pagination.newsList.total ? (
        <Pagination
          pageSize={pagination.newsList.size}
          total={pagination.newsList.total}
          current={pagination.newsList.currentPage + 1}
          onChange={handlerChangePage}
          onShowSizeChange={false}
        />
      ) : null}
    </Content>
  );
};

export default News;
