import {
  GET_USER_TIMEZONE_FAIL,
  GET_USER_TIMEZONE_START,
  GET_USER_TIMEZONE_SUCCESS,
  SET_USER_TIMEZONE_FAIL,
  SET_USER_TIMEZONE_START,
  SET_USER_TIMEZONE_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getUserTimezone = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/timezone`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_TIMEZONE_START, GET_USER_TIMEZONE_SUCCESS, GET_USER_TIMEZONE_FAIL],
  onSuccess,
  onError,
});

export const setUserTimezone = (timezoneId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/timezone${getQueryString({ timezoneId: timezoneId })}`,
  body: {},
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [SET_USER_TIMEZONE_START, SET_USER_TIMEZONE_SUCCESS, SET_USER_TIMEZONE_FAIL],
  onSuccess,
  onError,
});
