// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-gLmLcv.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-XotQO6 .user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-label-G7CK74[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-gLmLcv.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-XotQO6 .user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-item-adEQNV[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-Montserrat-wAwQD7 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer-FFgA4b {
  background-color: #f5f5f5;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 10px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer__modal-TWVXw5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/test/Timer/Timer.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Timer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n  background-color: #f5f5f5;\n  border-radius: 8px;\n  width: 100px;\n}\n.Timer__modal {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-gLmLcv`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-gLmLcv`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-XotQO6`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-XotQO6`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-label-G7CK74`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-label-G7CK74`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-item-adEQNV`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-picker-item-adEQNV`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-Montserrat-wAwQD7`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__ql-font-Montserrat-wAwQD7`,
	"Timer": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer-FFgA4b`,
	"timer": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer-FFgA4b`,
	"Timer__modal": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer__modal-TWVXw5`,
	"timerModal": `user-src-pages-CollectionMaterialsIDStudy-test-Timer-Timer-module__Timer__modal-TWVXw5`
};
export default ___CSS_LOADER_EXPORT___;
