import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getInfoCompany } from '@/store/company/actions';

import { selectCompany } from '@/store/company/selectors';

import { ConfigProvider, Spin } from 'antd';
import { CompanyBlocked } from '@/pages/CompanyBlocked';
import { DomainSpinner } from '@/components/DomainSpinner';
import { LoadingOutlined } from '@ant-design/icons';

import { googleAnalytics } from '@/utils/google-analytics';
import { changeLanguage } from '@shared/utils/changeLanguage';

import { legacyLogicalPropertiesTransformer, px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import dayjs from 'dayjs';
import { darken, setLightness } from 'polished';

import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';

import enUS from 'antd/lib/locale/en_US';
import ruRu from 'antd/lib/locale/ru_RU';

import RootRoutes from './RootRoutes';

import '@/styles/main.scss';

import { CookiesProvider } from 'react-cookie';

import config from '@shared/services/merge-antd-style-config';

const px2rem = px2remTransformer({
  rootValue: 14, // 14px = 1rem; @default 14
});

const ThemeContext = createContext();

Spin.setDefaultIndicator(
  <span className='ant-spin-dot-holder'>
    <LoadingOutlined />
  </span>
);

export const ThemeProvider = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { company } = useSelector(selectCompany);

  const [theme, setTheme] = useState({}); // Start with a default theme or empty object
  const [isLoadingProject, changeLoadingProject] = useState(true);
  const [isError, setIsError] = useState(false);

  const updateTheme = newTheme => {
    setTheme({ ...theme, ...newTheme });
  };

  const setCompanyInfo = response => {
    const currentColor = response.colour || '#ff8c00';
    const darkenColor = darken(0.1, currentColor);
    const lightenColor = setLightness(0.75, currentColor);
    const body = document.querySelector('body');
    body.style.setProperty('--personalColor', currentColor);
    body.style.setProperty('--personalColorDark', darkenColor);
    body.style.setProperty('--personalColorLight', lightenColor);

    changeLanguage(i18n, dayjs, response.defaultLanguage);

    const replaceConfig = JSON.stringify(config)
      .replaceAll('--personalColorLight', lightenColor)
      .replaceAll('--personalColorDark', darkenColor)
      .replaceAll('--personalColor', currentColor);

    const currentConfig = JSON.parse(replaceConfig);

    setTheme({ ...currentConfig, cssVar: true, hashed: false });

    switch (response?.id) {
      case 5451:
        document.title = response.shortName;
        let link = document.querySelector("link[rel~='icon']");
        link.href = '/assets/favicons/absolute/favicon.ico';
        break;
      case 2451:
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `!(function () {
          function t() {
            var t = document.createElement('script');
            var h = (Math.random() * (99999 - 00001)).toFixed();
            (t.type = 'module'), (t.async = !0),
            (t.src = 'https://evahelper.ru/build/main.js?h=' + h), document.head.appendChild(t);
          }
          'complete' == document.readyState
            ? t()
            : window.attachEvent
            ? window.attachEvent('onload', t)
            : window.addEventListener('load', t, !1);
        })();`;

        document.getElementsByTagName('head')[0].appendChild(script);
        break;
      default:
        if (import.meta.env.PUBLIC_APP_TITLE_USER) document.title = import.meta.env.PUBLIC_APP_TITLE_USER;
        break;
    }

    if (response.googleAnalyticId) googleAnalytics(window, document, 'script', 'dataLayer', response.googleAnalyticId);
  };

  useLayoutEffect(() => {
    dispatch(
      getInfoCompany(
        response => {
          setCompanyInfo(response);
          changeLoadingProject(false);
        },
        () => {
          setIsError(true);
        }
      )
    );
  }, []);

  if (isLoadingProject) return <DomainSpinner />;

  if (company?.status === 'BLOCKED' || company?.status === 'INACTIVE' || isError) return <CompanyBlocked />;

  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <ConfigProvider theme={theme} locale={i18n.language === 'ru_RU' ? ruRu : enUS}>
        <StyleProvider hashPriority='high' transformers={[legacyLogicalPropertiesTransformer, px2rem]} layer>
          <CookiesProvider defaultSetOptions>
            <RootRoutes />
          </CookiesProvider>
        </StyleProvider>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);
