/* eslint-disable */
/*

 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCompany } from '@/store/company/selectors';

import { Layout } from 'antd';
import MarkdownViewer from '@/components/MarkdownViewer';

import css from './ServerError.module.scss';

import locked from '@/assets/images/login-lock.png';

const { Content } = Layout;

export const CompanyBlocked = () => {
  const { company } = useSelector(selectCompany);
  const { t } = useTranslation('companyBlocked');

  return (
    <Layout className={css.ServerError}>
      <Content className={css.LoginContent}>
        <div className={css['App-card-item']}>
          <div className={css['App-card-item-imageBlock']}>
            <img src={locked} alt='' />
          </div>
          <div className={css['App-card-item-text']}>
            <div className={css['App-card-item-text__title']}>{t('blocked')}</div>
            <MarkdownViewer className={css['App-card-item-text__content']}>{t('callback')}</MarkdownViewer>
          </div>
          {company.supportLink && (
            <div className={css['App-card-item-link']} onClick={() => document.location.replace(company.supportLink)}>
              {t('button')}
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default CompanyBlocked;
