import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectCurrentUser, selectUsers as usersSelect } from '@/store/users/selectors';

import { Row, Typography } from 'antd';
import TrajectoryModal from '@/pages/TrajectoryModal';
import { Content } from '@/components/Layout';

import Diagnostic from './Diagnostic';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './Diagnostic.module.scss';

const { Title } = Typography;

export const DiagnosticContainer = ({ isTrajectoryModalVisible, quizDate }) => {
  const [showTips, setShowTips] = useState(JSON.parse(localStorage.getItem('tips') || 'true'));
  const { t } = useTranslation('diagnostic');

  const tipsHandler = useCallback(() => {
    setShowTips(!showTips);
    localStorage.setItem('tips', String(!showTips));
  }, [showTips]);

  return (
    <Content className={css['App-content']}>
      <div className={css['CollectionMaterials-header-row']}>
        <Title level={1}>{t('diagnostic')}</Title>
        {!isTrajectoryModalVisible && !showTips && (
          <span className={css['CollectionMaterials-header-link']} onClick={tipsHandler}>
            {t('tooltip')}
          </span>
        )}
      </div>
      {isTrajectoryModalVisible ? (
        <div className={css.TopicFilter}>
          <Row className={css['TopicFilter-row']}>
            <TrajectoryModal />
          </Row>
        </div>
      ) : (
        <>
          {showTips && (
            <div
              className={classNames(
                css['CollectionMaterials-header-row'],
                css['CollectionMaterials-header-row-message']
              )}
            >
              <div className={css['CollectionMaterials-header-label']}>
                {t('here')}
                <br />
                {t('resultsTip')}
                <br />
                {t('lists')}
                <br />
                {t('addCarts')}
                <br />
                {quizDate && (
                  <>
                    {t('nextTest', {
                      date: dayjs(quizDate).format('DD MMMM YYYY'),
                    })}
                    <br />
                  </>
                )}
              </div>
              <span className={css['CollectionMaterials-header-link']} onClick={tipsHandler}>
                {t('hide')}
              </span>
            </div>
          )}
          <Diagnostic />
        </>
      )}
    </Content>
  );
};

const mapStateToProps = createSelector([usersSelect, selectCurrentUser], (users, current) => ({
  isTrajectoryModalVisible: users.isTrajectoryModalVisible,
  quizDate: (current?.member && current?.member?.nextQuizTime) || null,
}));

DiagnosticContainer.propTypes = {
  isTrajectoryModalVisible: PropTypes.bool,
  quizDate: PropTypes.string,
};

export default connect(mapStateToProps)(DiagnosticContainer);
