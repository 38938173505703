/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const POST_FILE_START = 'POST_FILE_START';
export const POST_FILE_SUCCESS = 'POST_FILE_SUCCESS';
export const POST_FILE_FAIL = 'POST_FILE_FAIL';
export const POST_FILE_IMAGE_START = 'POST_FILE_IMAGE_START';
export const POST_FILE_IMAGE_SUCCESS = 'POST_FILE_IMAGE_SUCCESS';
export const POST_FILE_IMAGE_FAIL = 'POST_FILE_IMAGE_FAIL';
export const DELETE_FILE_START = 'DELETE_FILE_START';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';
export const GET_FILE_START = 'GET_FILE_START';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_FAIL = 'GET_FILE_FAIL';
export const GET_ALLFILE_START = 'GET_ALLFILE_START';
export const GET_ALLFILE_SUCCESS = 'GET_ALLFILE_SUCCESS';
export const GET_ALLFILE_FAIL = 'GET_ALLFILE_FAIL';
export const GET_FILES_START = 'GET_FILES_START';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';
export const GET_PUBLIC_FILE_START = 'GET_PUBLIC_FILE_START';
export const GET_PUBLIC_FILE_SUCCESS = 'GET_PUBLIC_FILE_SUCCESS';
export const GET_PUBLIC_FILE_FAIL = 'GET_PUBLIC_FILE_AIL';

export const GET_VIDEO_IDB_START = 'GET_VIDEO_IDB_START';
export const GET_VIDEO_IDB_SUCCESS = 'GET_VIDEO_IDB_SUCCESS';
export const GET_VIDEO_IDB_FAIL = 'GET_VIDEO_IDB_FAIL';
export const IN_CACHE_FILE = 'IN_CACHE_FILE';

const headers = {
  Accept: '*/*',
};

const defaultToken = '';

export const createFile = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/service/file${getQueryString({
    isPublic: file.isPublicFile,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_START, POST_FILE_SUCCESS, POST_FILE_FAIL],
});

export const deleteFile = (uuid, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/service/file/${uuid}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  responseType: 'text',
  types: [DELETE_FILE_START, DELETE_FILE_SUCCESS, DELETE_FILE_FAIL],
  onSuccess,
  onError,
});

export const createFileImage = (file, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/service/file`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  onSuccess,
  onError,
  types: [POST_FILE_IMAGE_START, POST_FILE_IMAGE_SUCCESS, POST_FILE_IMAGE_FAIL],
});

export const getFile = (uuid, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/service/file/${uuid}${getQueryString({
      mode: 'DOWNLOAD_MODE',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    requestType: 'formData',
    responseType: 'blob',
    withoutRefresh: true,
    types: [GET_FILE_START, GET_FILE_SUCCESS, GET_FILE_FAIL],
    onSuccess,
    onError,
  };
};

export const getPublicFile = (uuid, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/public/file/${uuid}`,
  headers: { ...headers },
  requestType: 'formData',
  responseType: 'blob',
  withoutRefresh: true,
  types: [GET_PUBLIC_FILE_START, GET_PUBLIC_FILE_SUCCESS, GET_PUBLIC_FILE_FAIL],
  onSuccess,
  onError,
});

export const getPublicFileOffline = response => ({
  type: GET_PUBLIC_FILE_SUCCESS,
  response,
});

export const getAllFile = (filename, topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/download${getQueryString({
    filename,
    topicId,
    mode: 'DOWNLOAD_MODE',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  requestType: 'formData',
  responseType: 'blob',
  types: [GET_ALLFILE_START, GET_ALLFILE_SUCCESS, GET_ALLFILE_FAIL],
  onSuccess,
  onError,
});

export const getVideoForIDB = (urlVideo, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/service/file/export/video`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { url: urlVideo },
  requestType: 'formData',
  responseType: 'blob',
  withoutRefresh: true,
  types: [GET_VIDEO_IDB_START, GET_VIDEO_IDB_SUCCESS, GET_VIDEO_IDB_FAIL],
  onSuccess,
  onError,
});

export const getFiles = uuid => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/service/file/${uuid}${getQueryString({
    mode: 'DOWNLOAD_MODE',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  responseType: 'blob',
  types: [GET_FILES_START, GET_FILES_SUCCESS, GET_FILES_FAIL],
  uuid,
});

export const inCacheFile = (name, urlImg) => ({
  values: { name, urlImg },
  type: IN_CACHE_FILE,
});
