import { useMemo } from 'react';

import { Avatar } from 'antd';
import { FiCheck } from 'react-icons/fi';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const STATUS_USER = {
  default: 'default',
  selected: 'selected',
};

const User = ({ data, onSelectUser, selectedUser, defaultSelectedUser }) => {
  const avatar = useImage(data?.localPhotoUuid, 'avatar');

  const hisSelect = useMemo(() => {
    if (defaultSelectedUser?.filter(user => user.id === data.id).length) {
      return STATUS_USER.default;
    } else if (selectedUser.filter(user => user.id === data.id).length) {
      return STATUS_USER.selected;
    } else {
      return null;
    }
  }, [selectedUser]);

  const handleSelectUser = () => {
    if (hisSelect !== STATUS_USER.default) {
      onSelectUser(data);
    }
  };

  return (
    <div
      className={classNames(styles.user, { [styles.user_default]: hisSelect === STATUS_USER.default })}
      onClick={handleSelectUser}
    >
      <Avatar
        src={avatar}
        className={classNames({
          [styles.user__avatar_selected]: hisSelect === STATUS_USER.selected,
          [styles.user__avatar_default]: hisSelect === STATUS_USER.default,
          [styles.user__avatar]: hisSelect === null,
        })}
      />
      {hisSelect && (
        <div
          className={classNames(styles.user__icon, { [styles.user__icon_default]: hisSelect === STATUS_USER.default })}
        >
          <FiCheck />
        </div>
      )}
      <div className={styles.user__content}>
        {data?.firstName} {data?.lastName}
      </div>
    </div>
  );
};

User.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    localPhotoUuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  selectedUser: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      localPhotoUuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  defaultSelectedUser: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
  onSelectUser: PropTypes.func,
};

export default User;
