export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATION_BY_ID_START = 'GET_NOTIFICATION_BY_ID_START';
export const GET_NOTIFICATION_BY_ID_SUCCESS = 'GET_NOTIFICATION_BY_ID_SUCCESS';
export const GET_NOTIFICATION_BY_ID_FAIL = 'GET_NOTIFICATION_BY_ID_FAIL';

export const PUT_READ_NOTIFICATION_BY_ID_START = 'PUT_READ_NOTIFICATION_BY_ID_START';
export const PUT_READ_NOTIFICATION_BY_ID_SUCCESS = 'PUT_READ_NOTIFICATION_BY_ID_SUCCESS';
export const PUT_READ_NOTIFICATION_BY_ID_FAIL = 'PUT_READ_NOTIFICATION_BY_ID_FAIL';

export const PUT_READ_ALL_NOTIFICATIONS_START = 'PUT_READ_ALL_NOTIFICATION_START';
export const PUT_READ_ALL_NOTIFICATIONS_SUCCESS = 'PUT_READ_ALL_NOTIFICATION_SUCCESS';
export const PUT_READ_ALL_NOTIFICATIONS_FAIL = 'PUT_READ_ALL_NOTIFICATION_FAIL';

export const GET_LIST_NOTIFICATIONS_START = 'GET_LIST_NOTIFICATIONS_START';
export const GET_LIST_NOTIFICATIONS_SUCCESS = 'GET_LIST_NOTIFICATIONS_SUCCESS';
export const GET_LIST_NOTIFICATIONS_FAIL = 'GET_LIST_NOTIFICATIONS_FAIL';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const CLEAR_UNREAD_NOTIFICATIONS = 'CLEAR_UNREAD_NOTIFICATIONS';

export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
