// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-b2Kf7e.user-src-components-FullScreenButton-FullScreenButton-module__ql-font-SNBGfq .user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-label-g_GquS[data-value="Montserrat"]:before, .user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-b2Kf7e.user-src-components-FullScreenButton-FullScreenButton-module__ql-font-SNBGfq .user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-item-jPc7vc[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-FullScreenButton-FullScreenButton-module__ql-font-Montserrat-eCpQdy {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-IH1yzo {
  cursor: pointer;
  float: right;
  z-index: auto;
  margin-top: -.6rem;
  position: relative;
  top: auto;
  left: auto;
}

.user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-active-o57Jfx {
  z-index: 9999;
  position: fixed;
  top: 12px;
  right: 8px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/FullScreenButton/FullScreenButton.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAMA;EAHE,aAAa;EACb,eAAe;EACf,SAAS;EACT,UAAU;AACZ","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.FullScreenButton {\n  cursor: pointer;\n  float: right;\n  margin-top: -0.6rem;\n  position: relative;\n  left: auto;\n  top: auto;\n  z-index: auto;\n}\n.FullScreenButton-active {\n  position: fixed;\n  top: 12px;\n  right: 8px;\n  z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-b2Kf7e`,
	"qlPicker": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-b2Kf7e`,
	"ql-font": `user-src-components-FullScreenButton-FullScreenButton-module__ql-font-SNBGfq`,
	"qlFont": `user-src-components-FullScreenButton-FullScreenButton-module__ql-font-SNBGfq`,
	"ql-picker-label": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-label-g_GquS`,
	"qlPickerLabel": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-label-g_GquS`,
	"ql-picker-item": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-item-jPc7vc`,
	"qlPickerItem": `user-src-components-FullScreenButton-FullScreenButton-module__ql-picker-item-jPc7vc`,
	"ql-font-Montserrat": `user-src-components-FullScreenButton-FullScreenButton-module__ql-font-Montserrat-eCpQdy`,
	"qlFontMontserrat": `user-src-components-FullScreenButton-FullScreenButton-module__ql-font-Montserrat-eCpQdy`,
	"FullScreenButton": `user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-IH1yzo`,
	"fullScreenButton": `user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-IH1yzo`,
	"FullScreenButton-active": `user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-active-o57Jfx`,
	"fullScreenButtonActive": `user-src-components-FullScreenButton-FullScreenButton-module__FullScreenButton-active-o57Jfx`
};
export default ___CSS_LOADER_EXPORT___;
