import {
  ADD_NEW_GROUP,
  ADD_NEW_MESSAGE,
  ADD_NEW_MESSAGE_IN_CHAT,
  ADD_NEW_PERSONAL_GROUP,
  CHECK_NEW_MESSAGE_FAIL,
  CHECK_NEW_MESSAGE_START,
  CHECK_NEW_MESSAGE_SUCCESS,
  CLEAR_FOUND_GROUPS,
  CLEAR_GROUPS,
  CLEAR_MESSAGES,
  CLEAR_PERSONAL_GROUPS,
  CLEAR_REPLY_MESSAGE,
  CLEAR_SEARCH_USERS,
  CLEAR_SELECTED_USER,
  CLEAR_USERS,
  CREATE_CHAT_GROUP_FAIL,
  CREATE_CHAT_GROUP_START,
  CREATE_CHAT_GROUP_SUCCESS,
  DELETE_CHAT_GROUP,
  DELETE_CHAT_GROUP_BY_ID_FAIL,
  DELETE_CHAT_GROUP_BY_ID_START,
  DELETE_CHAT_GROUP_BY_ID_SUCCESS,
  DELETE_CHAT_GROUP_USER_FAIL,
  DELETE_CHAT_GROUP_USER_START,
  DELETE_CHAT_GROUP_USER_SUCCESS,
  GET_CHAT_GROUP_BY_ID_FAIL,
  GET_CHAT_GROUP_BY_ID_START,
  GET_CHAT_GROUP_BY_ID_SUCCESS,
  GET_CHAT_GROUPS_FAIL,
  GET_CHAT_GROUPS_START,
  GET_CHAT_GROUPS_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_START,
  GET_MESSAGES_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_IN_CHAT_FAIL,
  GET_USERS_IN_CHAT_START,
  GET_USERS_IN_CHAT_SUCCESS,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  READ_MESSAGES_IN_CHAT,
  SEARCH_GROUPS,
  SEARCH_USERS_FAIL,
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SET_REPLY_MESSAGE,
  SET_SELECTED_USER,
  UPDATE_CHAT_GROUP,
  UPDATE_CHAT_GROUP_AUTHOR_FAIL,
  UPDATE_CHAT_GROUP_AUTHOR_START,
  UPDATE_CHAT_GROUP_AUTHOR_SUCCESS,
  UPDATE_CHAT_GROUP_BY_ID_FAIL,
  UPDATE_CHAT_GROUP_BY_ID_START,
  UPDATE_CHAT_GROUP_BY_ID_SUCCESS,
  UPDATE_CHAT_GROUP_USERS_FAIL,
  UPDATE_CHAT_GROUP_USERS_START,
  UPDATE_CHAT_GROUP_USERS_SUCCESS,
} from './constants';

import { API_URL, API_URL_V3 } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getChatGroups = isPersonal => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/groups${getQueryString({ isPersonal })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_CHAT_GROUPS_START, GET_CHAT_GROUPS_SUCCESS, GET_CHAT_GROUPS_FAIL],
});

export const getChatMentorGroups = requestId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/groups/mentor/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_CHAT_GROUPS_START, GET_CHAT_GROUPS_SUCCESS, GET_CHAT_GROUPS_FAIL],
});

export const getChatGroup = chatId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/group/${chatId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_CHAT_GROUP_BY_ID_START, GET_CHAT_GROUP_BY_ID_SUCCESS, GET_CHAT_GROUP_BY_ID_FAIL],
});

export const createChatGroup = (chatName, chatPhotoUuid, users) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL_V3}/group`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    chatName,
    chatPhotoUuid,
    users,
  },
  withoutRefresh: true,
  types: [CREATE_CHAT_GROUP_START, CREATE_CHAT_GROUP_SUCCESS, CREATE_CHAT_GROUP_FAIL],
});

export const createChatGroupMentor = (requestId, users) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL_V3}/group/mentor/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    users,
  },
  withoutRefresh: true,
  types: [CREATE_CHAT_GROUP_START, CREATE_CHAT_GROUP_SUCCESS, CREATE_CHAT_GROUP_FAIL],
});

export const updateChatGroup = data => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL_V3}/group`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  body: data,
  types: [UPDATE_CHAT_GROUP_BY_ID_START, UPDATE_CHAT_GROUP_BY_ID_SUCCESS, UPDATE_CHAT_GROUP_BY_ID_FAIL],
});

export const deleteChatGroupById = (chatId, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL_V3}/group/${chatId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [DELETE_CHAT_GROUP_BY_ID_START, DELETE_CHAT_GROUP_BY_ID_SUCCESS, DELETE_CHAT_GROUP_BY_ID_FAIL],
  onSuccess,
});

export const deleteChatGroupByRequestId = requestId => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL_V3}/group/mentor/${requestId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [DELETE_CHAT_GROUP_BY_ID_START, DELETE_CHAT_GROUP_BY_ID_SUCCESS, DELETE_CHAT_GROUP_BY_ID_FAIL],
});

export const searchUsers = (fullName, page, isClear = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({
    sort: 'lastName,asc',
    size: 20,
    fullName,
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [SEARCH_USERS_START, SEARCH_USERS_SUCCESS, SEARCH_USERS_FAIL],
  isClear,
});

export const getUsers = (fullName, page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({
    sort: 'lastName,asc',
    size: 20,
    fullName,
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_USERS_START, GET_USERS_SUCCESS, GET_USERS_FAIL],
});

export const getMessages = chatId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/messages${getQueryString({
    chatId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_MESSAGES_START, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAIL],
});

export const getUsersInGroup = chatId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/group/users${getQueryString({
    chatId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [GET_USERS_IN_CHAT_START, GET_USERS_IN_CHAT_SUCCESS, GET_USERS_IN_CHAT_FAIL],
});

export const checkNewMessage = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL_V3}/check/new/message`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  types: [CHECK_NEW_MESSAGE_START, CHECK_NEW_MESSAGE_SUCCESS, CHECK_NEW_MESSAGE_FAIL],
});

export const changeAuthorGroup = (chatId, userId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL_V3}/group/author/${userId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  body: { chatId },
  types: [UPDATE_CHAT_GROUP_AUTHOR_START, UPDATE_CHAT_GROUP_AUTHOR_SUCCESS, UPDATE_CHAT_GROUP_AUTHOR_FAIL],
  onSuccess,
});

export const deleteGroupUser = (chatId, userId) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL_V3}/group/user/${userId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  body: { chatId },
  types: [DELETE_CHAT_GROUP_USER_START, DELETE_CHAT_GROUP_USER_SUCCESS, DELETE_CHAT_GROUP_USER_FAIL],
});

export const addNewUsers = (chatId, userIds, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL_V3}/group/users/${chatId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  withoutRefresh: true,
  body: { userIds },
  types: [UPDATE_CHAT_GROUP_USERS_START, UPDATE_CHAT_GROUP_USERS_SUCCESS, UPDATE_CHAT_GROUP_USERS_FAIL],
  onSuccess,
});

export const setSelectedUser = data => ({
  type: SET_SELECTED_USER,
  data,
});

export const clearSearchUsers = () => ({
  type: CLEAR_SEARCH_USERS,
});

export const addNewMessageInChat = data => ({
  type: ADD_NEW_MESSAGE_IN_CHAT,
  data,
});

export const addNewGroup = data => ({
  type: ADD_NEW_GROUP,
  data,
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const clearSelectedUser = () => ({
  type: CLEAR_SELECTED_USER,
});

export const readMessagesInChat = data => ({
  type: READ_MESSAGES_IN_CHAT,
  data,
});

export const addNewMessage = data => ({
  type: ADD_NEW_MESSAGE,
  data,
});

export const setReplyMessage = data => ({
  type: SET_REPLY_MESSAGE,
  data,
});

export const clearReplyMessage = () => ({
  type: CLEAR_REPLY_MESSAGE,
});

export const clearGroups = () => ({
  type: CLEAR_GROUPS,
});

export const clearUsers = () => ({
  type: CLEAR_USERS,
});

export const searchGroups = data => ({
  type: SEARCH_GROUPS,
  data,
});

export const clearFoundGroups = () => ({
  type: CLEAR_FOUND_GROUPS,
});

export const clearPersonalGroups = () => ({
  type: CLEAR_PERSONAL_GROUPS,
});

export const deleteChatGroup = data => ({
  type: DELETE_CHAT_GROUP,
  data,
});

export const updateChatGroupInfo = data => ({
  type: UPDATE_CHAT_GROUP,
  data,
});

export const addNewPersonalGroup = data => ({
  type: ADD_NEW_PERSONAL_GROUP,
  data,
});
