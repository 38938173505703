export const pluralize = (n, forms, lang = 'ru') => {
  if (lang === 'ru') {
    return forms[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 1.0000000001 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
          ? 1
          : 2
    ];
  }
  return forms[n === 1 ? 0 : 1];
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
