/* eslint-disable */
import {
  ADD_COMPETENCE_SKILLS_FAIL,
  ADD_COMPETENCE_SKILLS_START,
  ADD_COMPETENCE_SKILLS_SUCCESS,
  CLEAR_COMPETENCE_SKILL,
  CREATE_COMPETENCE_FAIL,
  CREATE_COMPETENCE_START,
  CREATE_COMPETENCE_SUCCESS,
  DELETE_COMPETENCE_FAIL,
  DELETE_COMPETENCE_START,
  DELETE_COMPETENCE_SUCCESS,
  EDIT_COMPETENCE_FAIL,
  EDIT_COMPETENCE_START,
  EDIT_COMPETENCE_SUCCESS,
  GET_COMPETENCE_FAIL,
  GET_COMPETENCE_MORE_FAIL,
  GET_COMPETENCE_MORE_START,
  GET_COMPETENCE_MORE_SUCCESS,
  GET_COMPETENCE_SKILL_FAIL,
  GET_COMPETENCE_SKILL_START,
  GET_COMPETENCE_SKILL_SUCCESS,
  GET_COMPETENCE_SKILLS_FAIL,
  GET_COMPETENCE_SKILLS_START,
  GET_COMPETENCE_SKILLS_SUCCESS,
  GET_COMPETENCE_START,
  GET_COMPETENCE_SUCCESS,
} from './actions';

const initialState = {
  competence: {},
  competenceWithSkillsContent: [],
  competencesMore: [],
  pageTotal: null,
  pageSize: null,
  pageNumber: null,
  competenceWithSkillsHasMore: true,
  isLoading: false,
  isLoadingCompetenceWithSkills: false,
  error: false,
  editingError: false,
  creationError: false,
  deletionError: false,
  totalElements: null,
  globalCompetence: {
    competence: [],
    total: null,
    pageSize: null,
    pageNumber: null,
    isLoading: false,
    error: false,
  },
  skills: null,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_COMPETENCE_START:
      return {
        ...state,
        competence: {},
        total: null,
        pageSize: null,
        pageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_COMPETENCE_MORE_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_COMPETENCE_SUCCESS:
      return {
        ...state,
        competence: response,
        total: Number(headers.get('X-Total-Elements')) || 0,
        pageSize: Number(headers.get('X-Page-Size')) || 0,
        pageNumber: Number(headers.get('X-Page-Number')) || 0,
        totalElements: response.totalElements || 0,
        isLoading: false,
      };

    case GET_COMPETENCE_MORE_SUCCESS:
      return {
        ...state,
        competencesMore: response.content,
        total: Number(headers.get('X-Total-Elements')) || 0,
        pageSize: Number(headers.get('X-Page-Size')) || 0,
        pageNumber: Number(headers.get('X-Page-Number')) || 0,
        totalElements: response.totalElements || 0,
        isLoading: false,
      };

    case GET_COMPETENCE_SKILL_START:
      return { ...state, isLoadingCompetenceWithSkills: true, competenceWithSkillsHasMore: false };

    case GET_COMPETENCE_SKILL_SUCCESS:
      return {
        ...state,
        competenceWithSkillsContent: [...state.competenceWithSkillsContent, ...response.content],
        isLoadingCompetenceWithSkills: false,
        competenceWithSkillsHasMore: !response.last,
      };

    case GET_COMPETENCE_SKILL_FAIL:
      return { ...state, isLoadingCompetenceWithSkills: false, competenceWithSkillsHasMore: false, error: true };

    case GET_COMPETENCE_MORE_FAIL:
    case GET_COMPETENCE_FAIL:
      return { ...state, error: true, isLoading: false };

    case CREATE_COMPETENCE_START:
      return { ...state, isLoading: true, creationError: false };
    case CREATE_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };
    case CREATE_COMPETENCE_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case ADD_COMPETENCE_SKILLS_START:
      return { ...state, isLoading: true, creationError: false };
    case ADD_COMPETENCE_SKILLS_SUCCESS:
      return { ...state, isLoading: false };
    case ADD_COMPETENCE_SKILLS_FAIL:
      return { ...state, creationError: true, isLoading: false };

    case EDIT_COMPETENCE_START:
      return { ...state, isLoading: true, editingError: false };

    case EDIT_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };

    case EDIT_COMPETENCE_FAIL:
      return { ...state, editingError: true, isLoading: false };

    case DELETE_COMPETENCE_START:
      return { ...state, isLoading: true, deletionError: false };

    case DELETE_COMPETENCE_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_COMPETENCE_FAIL:
      return { ...state, deletionError: true, isLoading: false };

    case GET_COMPETENCE_SKILLS_START:
      return { ...state };

    case GET_COMPETENCE_SKILLS_SUCCESS:
      return { ...state, skills: response };

    case GET_COMPETENCE_SKILLS_FAIL:
      return { ...state };

    case CLEAR_COMPETENCE_SKILL:
      return { ...state, competenceWithSkillsContent: [], competenceWithSkillsHasMore: true };

    default:
      return state;
  }
};
