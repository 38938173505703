/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { changeTopicFilter } from '@/store/topics/actions';

import { selectTopics as topicsSelect } from '@/store/topics/selectors';

import { Button, Col, Layout, Row } from 'antd';

import hardSkillsIcon from './hardSkills.svg';
import softSkillsIcon from './softSkills.svg';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Collection.module.scss';

import { HARD_SKILLS, SOFT_SKILLLS, TOPIC_CATEGORIES } from '@/constants/topic-categories';

const { Content } = Layout;

export class Collection extends Component {
  static propTypes = {
    changeTopicFilter: PropTypes.func,
    topicCategories: PropTypes.array,
  };

  handleFilterChange = event => {
    this.props.changeTopicFilter('topicCategoryId', TOPIC_CATEGORIES[event.target.name]);
    this.props.navigate('/collection-topics');
  };

  render() {
    const { t } = this.props;
    return (
      <Content className={classNames(css['App-content'], css.Collection)}>
        <h1>{t('qiwi')}</h1>
        <div className={css['Collection-values']}>{t('values')}</div>
        <Row gutter={1} className={classNames(css['App-cards'], css['Collection-cards'])}>
          <Col span={12}>
            <div className={css['App-card-item']}>
              <img src={hardSkillsIcon} />
              <h2>{t('hardSkills')}</h2>
              <div>{t('hardDescription')}</div>
              <Button
                className={classNames(css['App-btn'], css['App-btn-ghost'], css['App-btn-big'])}
                type='primary'
                name={HARD_SKILLS}
                onClick={this.handleFilterChange}
              >
                {t('toMaterials')}
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className={css['App-card-item']}>
              <img src={softSkillsIcon} />
              <h2>{t('softSkills')}</h2>
              <div>{t('softDescription')}</div>
              <Button
                className={classNames(css['App-btn'], css['App-btn-ghost'], css['App-btn-big'])}
                type='primary'
                name={SOFT_SKILLLS}
                onClick={this.handleFilterChange}
              >
                {t('toMaterials')}
              </Button>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
const mapStateToProps = createSelector(topicsSelect, topics => ({
  topicCategories: topics.categories,
}));

const mapActionsToProps = {
  changeTopicFilter,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('collection')(Collection));
