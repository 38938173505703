/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_PLAN_START = 'GET_PLAN_START';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPlan = (topicId, userId, isCurrent, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan${getQueryString({
    topicId,
    userId,
    isCurrent,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_PLAN_START, GET_PLAN_SUCCESS, GET_PLAN_FAIL],
});
