export const GET_ASSESSMENT_URL_START = 'GET_ASSESSMENT_URL_START';
export const GET_ASSESSMENT_URL_SUCCESS = 'GET_ASSESSMENT_URL_SUCCESS';
export const GET_ASSESSMENT_URL_FAIL = 'GET_ASSESSMENT_URL_FAIL';

export const GET_REPORT_PDF_START = 'GET_REPORT_PDF_START';
export const GET_REPORT_PDF_SUCCESS = 'GET_REPORT_PDF_SUCCESS';
export const GET_REPORT_PDF_FAIL = 'GET_REPORT_PDF_FAIL';

export const GET_SCORE_START = 'GET_SCORE_START';
export const GET_SCORE_SUCCESS = 'GET_SCORE_SUCCESS';
export const GET_SCORE_FAIL = 'GET_SCORE_FAIL';

export const GET_RECOMMENDATION_START = 'GET_RECOMMENDATION_START';
export const GET_RECOMMENDATION_SUCCESS = 'GET_RECOMMENDATION_SUCCESS';
export const GET_RECOMMENDATION_FAIL = 'GET_RECOMMENDATION_FAIL';

export const GET_COMPETNCES_START = 'GET_COMPETNCES_START';
export const GET_COMPETNCES_SUCCESS = 'GET_COMPETNCES_SUCCESS';
export const GET_COMPETNCES_FAIL = 'GET_COMPETNCES_FAIL';
