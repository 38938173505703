const SCHEDULED = 'SCHEDULED';
const FINISHED = 'FINISHED';
const IN_PROGRESS = 'IN_PROGRESS';
const WAITING = 'WAITING';
const APPROVED = 'APPROVED';
const REJECTED = 'REJECTED';
const REJECTED_BY_USER = 'REJECTED_BY_USER';
const WAITING_BY_USER = 'WAITING_BY_USER';
const APPROVED_BY_USER = 'APPROVED_BY_USER';
const EXPIRED = 'EXPIRED';
const PLANNED = 'PLANNED';
const ON_REGISTRATION_STATUS = 'ON_REGISTRATION';

const APPROVED_STATUSES = [
  'APPROVED',
  'APPROVED_BY_MANAGER',
  'APPROVED_BY_USER',
  'WAITING_BY_USER',
  'ON_REGISTRATION',
  'REJECTED_BY_USER',
];

const LAST_MONTH_DEFAULT_CONFIG = {
  label: 'Не выполнен',
  buttonType: 'changePlanningDate',
};
const CURRENT_MONTH_SCHEDULED_CONFIG = {
  label: 'Не выполнен',
  buttonType: 'startStudyPlan',
};
const NEXT_MONTH_DEFAULT_CONFIG = {
  label: 'Не выполнен',
  buttonType: 'changePlanningDate',
};
const FINISHED_CONFIG = {
  label: 'Выполнен',
  buttonType: 'recommend',
};
const WAITING_CONFIG = {
  label: 'На согласовании руководителя',
  buttonType: 'changePlanningDate',
};
const WAITING_ADMIN = {
  label: 'На согласовании отдела обучения',
  buttonType: 'changePlanningDate',
};
const APPROVED_CONFIG = {
  label: 'Согласован',
  buttonType: 'changePlanningDate',
};
const REJECTED_CONFIG = {
  label: 'Отклонен',
  buttonType: 'linkToAdminRefuse',
};
const REJECTED_BY_MANAGER = {
  label: 'Отклонен',
  buttonType: 'linkToAdminRefuse',
};
const WAITING_BY_USER_CONFIG = {
  label: 'Требует подтверждения',
  buttonType: 'refuseConfirmPlan',
};
const EXPIRED_CONFIG = {
  label: 'Завершен',
  buttonType: 'setReviewPlan',
};
const CURRENT_MONTH_APPROVED_BY_USER_CONFIG = {
  label: 'Запланирован',
  buttonType: 'refusePlan',
};
const ON_REGISTRATION = {
  label: 'На регистрации',
  buttonType: 'onRegistration',
};

const CURRENT_MONTH_IN_PROGRESS = {
  label: 'В процессе',
  buttonType: 'linkToAdmin',
};

const LAST_MONTH = {
  SCHEDULED: LAST_MONTH_DEFAULT_CONFIG,
  FINISHED: FINISHED_CONFIG,
  WAITING: WAITING_CONFIG,
  APPROVED_BY_MANAGER: WAITING_ADMIN,
  REJECTED_BY_MANAGER,
  ON_REGISTRATION,
  APPROVED: APPROVED_CONFIG,
  REJECTED: REJECTED_CONFIG,
  APPROVED_BY_USER: CURRENT_MONTH_APPROVED_BY_USER_CONFIG,
  REJECTED_BY_USER: APPROVED_CONFIG,
  EXPIRED: EXPIRED_CONFIG,
  WAITING_BY_USER: WAITING_BY_USER_CONFIG,
};

const CURRENT_MONTH = {
  SCHEDULED: CURRENT_MONTH_SCHEDULED_CONFIG,
  FINISHED: FINISHED_CONFIG,
  WAITING: WAITING_CONFIG,
  APPROVED_BY_MANAGER: WAITING_ADMIN,
  REJECTED_BY_MANAGER,
  ON_REGISTRATION,
  APPROVED: APPROVED_CONFIG,
  REJECTED: REJECTED_CONFIG,
  REJECTED_BY_USER: APPROVED_CONFIG,
  WAITING_BY_USER: WAITING_BY_USER_CONFIG,
  APPROVED_BY_USER: CURRENT_MONTH_APPROVED_BY_USER_CONFIG,
  IN_PROGRESS: CURRENT_MONTH_IN_PROGRESS,
  EXPIRED: EXPIRED_CONFIG,
};

const NEXT_MONTH = {
  SCHEDULED: NEXT_MONTH_DEFAULT_CONFIG,
  FINISHED: FINISHED_CONFIG,
  WAITING: WAITING_CONFIG,
  APPROVED_BY_MANAGER: WAITING_ADMIN,
  REJECTED_BY_MANAGER,
  ON_REGISTRATION,
  APPROVED: APPROVED_CONFIG,
  REJECTED: REJECTED_CONFIG,
  REJECTED_BY_USER: APPROVED_CONFIG,
  WAITING_BY_USER: WAITING_BY_USER_CONFIG,
  // APPROVED_BY_USER: NEXT_MONTH_APPROVED_BY_USER_CONFIG
  APPROVED_BY_USER: CURRENT_MONTH_APPROVED_BY_USER_CONFIG,
};

export {
  SCHEDULED,
  FINISHED,
  IN_PROGRESS,
  WAITING,
  APPROVED,
  REJECTED,
  REJECTED_BY_USER,
  ON_REGISTRATION,
  WAITING_BY_USER,
  APPROVED_BY_USER,
  EXPIRED,
  LAST_MONTH,
  CURRENT_MONTH,
  NEXT_MONTH,
  APPROVED_STATUSES,
  PLANNED,
  ON_REGISTRATION_STATUS,
  REJECTED_BY_MANAGER,
};
