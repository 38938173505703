/* eslint-disable */
import React from 'react';

import { Tooltip } from 'antd';

import { MATCHING_ID, OPEN_ID } from '../Question/helper';
import Draggable from '../Question/MatchingQuestion/Draggable';
import Droppable, { DroppableItem } from '../Question/MatchingQuestion/Droppable';
import Answer from './Answer';

import { classNames } from '@shared/utils/styles-utils';

import css from './AnswerInfo.module.scss';

const cn = classNames(css);

export const DetailAnswerItem = ({ questionItem }) => {
  const typeCheckbox = questionItem.isMultipleAnswers ? 'checkbox' : 'radio';

  const isMatching = questionItem.type === MATCHING_ID;

  const answers = !isMatching ? (
    <ul className={css.Question__variants}>
      {questionItem.answers.map(({ answer, isUserChoice, isCorrect, imgUuid, color, id }) => {
        return (
          <Answer
            key={id}
            color={color}
            uuid={imgUuid}
            text={answer}
            inputType={typeCheckbox}
            inputChecked={isUserChoice}
            isCorrect={isCorrect}
          />
        );
      })}
    </ul>
  ) : (
    questionItem.pairAnswers?.map(pair => {
      const question = { ...pair.left, text: pair.left.isText };
      const answer = { ...pair.right, text: pair.right.isText };

      const leftId = String(question.id);
      const rightId = answer.id;

      return (
        <Droppable id={leftId} key={leftId} inResults>
          <DroppableItem question={question} key={leftId} inResults />
          <Draggable answer={answer} key={rightId} inResults />
        </Droppable>
      );
    })
  );

  const isCorrectMatching = questionItem.isCorrectPairs;
  const isNotCorrectSimpleAnswer = !questionItem.isCorrectUserAnswer && !questionItem.customAnswer && !isMatching;
  const isCorrectSimpleAnswer = questionItem.isCorrectUserAnswer && !questionItem.customAnswer && !isMatching;

  return (
    <div className={cn(css.Question)}>
      <div
        className={cn(
          css.Question__body,
          { Question__body_notCorrectAnswer: isNotCorrectSimpleAnswer || (isMatching && !isCorrectMatching) },
          { Question__body_correctAnswer: isCorrectSimpleAnswer || (isMatching && isCorrectMatching) }
        )}
      >
        <Tooltip title={questionItem.question}>
          <p className={css['Question__body_question']}>{questionItem.question}</p>
        </Tooltip>

        {questionItem.type === OPEN_ID && (
          <div className={css.Question__openAnswer}>
            <code>{`"${questionItem.customAnswer.userAnswer}"`}</code>
          </div>
        )}

        {answers}
      </div>
    </div>
  );
};
