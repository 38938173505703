import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeAuthorGroup, deleteGroupUser, setSelectedUser } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar, Popover } from 'antd';
import { FiMoreVertical } from 'react-icons/fi';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const GroupUser = ({ data, chatId }) => {
  const avatar = useImage(data?.local_photo_uuid, 'avatar');
  const dispatch = useDispatch();

  const { t } = useTranslation('chat');
  const { selectedUser } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);

  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleUpdateAuthor = () => {
    dispatch(
      changeAuthorGroup(chatId, data.id, () => {
        dispatch(setSelectedUser({ ...selectedUser, chatAuthorId: data.id }));
      })
    );
  };

  const handleDeleteUser = () => {
    dispatch(deleteGroupUser(chatId, data.id));
  };

  const contentPopover = (
    <>
      <div onClick={handleUpdateAuthor} className={styles.groupUser__element}>
        <p>{t('toOwner')}</p>
      </div>
      <div onClick={handleDeleteUser} className={classNames(styles.groupUser__element, styles.groupUser__element_red)}>
        <p>{t('delete')}</p>
      </div>
    </>
  );

  return (
    <div className={styles.groupUser}>
      <Link to={'/collegues/' + data.id} className={styles.groupUser__box}>
        <Avatar size={38} src={avatar} />
        <p className={styles.groupUser__name}>
          {data.first_name} {data.last_name}
        </p>
      </Link>
      {currentUser?.id === selectedUser?.chatAuthorId && selectedUser?.chatAuthorId !== data.id && (
        <Popover trigger='click' visible={visiblePopover} content={contentPopover} onVisibleChange={setVisiblePopover}>
          <FiMoreVertical size={20} onClick={() => setVisiblePopover(true)} className={styles.groupUser__icon} />
        </Popover>
      )}
      {selectedUser?.chatAuthorId === data.id && <p className={styles.groupUser__owner}>{t('owner')}</p>}
    </div>
  );
};

GroupUser.propTypes = {
  chatId: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    local_photo_uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default memo(GroupUser);
