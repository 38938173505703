/* eslint-disable */
import { USER_LOGOUT_SUCCESS } from '@/store/login/actions';

import {
  CHANGE_USER_PROPERTIES,
  CLEAR_USERS,
  EDIT_USER_FAIL,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  FIRST_LOGIN_FAIL,
  FIRST_LOGIN_START,
  FIRST_LOGIN_SUCCESS,
  GET_ANOTHER_USER_BY_ID_FAIL,
  GET_ANOTHER_USER_BY_ID_START,
  GET_ANOTHER_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_START,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_FAIL,
  GET_USER_POSITION_FAIL,
  GET_USER_POSITION_START,
  GET_USER_POSITION_SUCCESS,
  GET_USER_SKILL_FAIL,
  GET_USER_SKILL_START,
  GET_USER_SKILL_SUCCESS,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  getUserDomainCompanyImages,
  HIDE_TRAJECTORY_MODAL,
  LOADAVATAR_USER_FAIL,
  LOADAVATAR_USER_START,
  SHOW_TRAJECTORY_MODAL,
  UPDATE_AVATAR_USER_SUCCESS,
} from './actions';

import { createReducer, reducerAdapter, updateState } from '@shared/utils/redux-helpers';

import { openDB } from 'idb';

import { IDB_NAME, USER_IDB } from '@/constants/IndexedDB';

const initialState = {
  anotherUser: null,
  currentUser: null,
  positions: null,
  skills: null,
  usersList: null,
  usersListConcat: [],
  usersTotal: null,
  usersPageSize: null,
  usersPageNumber: null,
  isLoading: false,
  isLoadingSkills: false,
  error: false,
  corpCourseBackground: null,
  corpCourseIcon: null,
  isTrajectoryModalVisible: false,
};

const transactionUserIDB = async user => {
  const db = await openDB(IDB_NAME);
  const userIdb = await getUserInDB();
  if (user && !userIdb.length) {
    await db.add(USER_IDB, user);
  }
};

const getUserInDB = async () => {
  const db = await openDB(IDB_NAME);
  if (!db.objectStoreNames.contains(USER_IDB)) return;
  if (!db) return;
  const tx = db.transaction(USER_IDB, 'readwrite');
  const store = tx.objectStore(USER_IDB);
  const user = await store.getAll();
  await tx.done;
  return user;
};

!window.navigator.onLine && getUserInDB();

const actions = {
  [getUserDomainCompanyImages.success]: (state, payload) => updateState(state, payload),
};

const oldReducer = (state = initialState, { type, response, headers, action, properties }) => {
  switch (type) {
    case GET_USER_START:
    case FIRST_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_USER_SUCCESS:
      transactionUserIDB(response);

      if (response) {
        const {
          domainCompany: { hasUserTrajectory },
          member,
          career,
          trajectoryEnable,
        } = response;
        const needTrajectory = !member || (member && member.button !== 'HIDDEN');
        return {
          ...state,
          currentUser: {
            ...response,
            member,
          },
          isLoading: false,
          isTrajectoryModalVisible: trajectoryEnable && hasUserTrajectory && (needTrajectory || (!career && member)),
        };
      }

    case GET_USER_POSITION_START:
      return { ...state, positions: null, isLoading: true, error: false };

    case GET_USERS_START:
      return {
        ...state,
        usersListConcat: action.isStartLoad ? [] : [...state.usersListConcat],
        isLoading: true,
        error: false,
      };

    case LOADAVATAR_USER_START:
      return { ...state, isLoading: true, error: false };

    case GET_USERS_SUCCESS:
      const isSearching = action.searchingStr;
      const usersPageNumber = Number(headers.get('X-Page-Number'));
      const usersListConcatArray = [...state.usersListConcat, ...response];
      const usersListConcat = isSearching ? response : usersListConcatArray;

      return {
        ...state,
        usersList: response,
        isSearching,
        usersListConcat,
        isLoading: false,
        usersTotal: Number(headers.get('X-Total-Elements')) || 0,
        usersPageSize: Number(headers.get('X-Page-Size')) || 0,
        usersPageNumber,
      };

    case CLEAR_USERS:
      return {
        ...state,
        usersListConcat: [],
        usersList: null,
        usersTotal: 0,
        usersPageSize: 0,
        usersPageNumber: 0,
      };

    case GET_USER_POSITION_SUCCESS:
      return { ...state, positions: response, isLoading: false };
    case GET_ANOTHER_USER_BY_ID_START:
      return { ...state, isLoading: true, error: false };
    case GET_ANOTHER_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        anotherUser: response,
      };

    case GET_USER_BY_ID_START:
    case EDIT_USER_START:
      return { ...state, isLoading: true, error: false };

    case GET_USER_BY_ID_SUCCESS:
    case EDIT_USER_SUCCESS:
      return { ...state, currentUser: response, isLoading: false };

    case LOADAVATAR_USER_FAIL:
    case GET_USER_BY_ID_FAIL:
    case EDIT_USER_FAIL:
    case GET_USER_POSITION_FAIL:
    case GET_USER_FAIL:
    case GET_USERS_FAIL:
    case GET_USER_SKILL_FAIL:
    case GET_ANOTHER_USER_BY_ID_FAIL:
    case FIRST_LOGIN_FAIL:
      return { ...state, isLoading: false, error: true };
    case GET_USER_SKILL_START:
      return {
        ...state,
        isLoadingSkills: true,
        error: false,
        skills: null,
      };
    case GET_USER_SKILL_SUCCESS:
      return {
        ...state,
        isLoadingSkills: false,
        error: false,
        skills: response.content,
      };

    case USER_LOGOUT_SUCCESS:
    case UPDATE_AVATAR_USER_SUCCESS:
      return { ...state, currentUser: null };

    case SHOW_TRAJECTORY_MODAL:
      return {
        ...state,
        isTrajectoryModalVisible: true,
      };
    case HIDE_TRAJECTORY_MODAL:
      return {
        ...state,
        isTrajectoryModalVisible: false,
      };

    case CHANGE_USER_PROPERTIES:
      return {
        ...state,
        isLoading: false,
        currentUser: { ...state.currentUser, ...properties },
      };

    case FIRST_LOGIN_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, firstLogin: false }, isLoading: false, error: false };

    default:
      return state;
  }
};

const reducer = createReducer(actions, initialState);

export default reducerAdapter(actions, initialState, reducer, oldReducer);
