import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { finishChecklist, getChecklistInfo, startChecklist } from '@/store/poka/actions';

import { selectLMS } from '@/store/lms/selectors';
import { selectPokaSkills } from '@/store/poka/selectors';

import { Button, Spin, Typography } from 'antd';
import CheckListElement from '@/pages/CollectionMaterialsIDStudy/CheckList/parts/CheckListElement';

import style from './CheckList.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const CheckList = ({ block, studyPlan, getStatistic, getStatisticStart }) => {
  const dispatch = useDispatch();

  const { statistic } = useSelector(selectLMS);
  const { checkListInfo, isLoading } = useSelector(selectPokaSkills);
  const { t } = useTranslation('pokaChecklist');

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  useEffect(() => {
    dispatch(getChecklistInfo(block.blockId, studyPlan.id));

    if (block.isAvailableForStart) {
      dispatch(
        startChecklist(block.blockId, () => {
          getStatisticStart();
        })
      );
    }
  }, [block]);

  const onFinish = () => {
    dispatch(finishChecklist(block.blockId, () => getStatistic(next <= 1)));
  };

  return (
    <div className={style['check-list']}>
      <Typography.Text className={style['check-list__text']}>{block.name}</Typography.Text>
      <Spin spinning={isLoading}>
        {checkListInfo?.checklist?.map((item, index) => (
          <CheckListElement key={index} index={index + 1} {...item} />
        ))}
      </Spin>
      {block.status !== FINISHED && (
        <div className={style['check-list__footer']}>
          <Button onClick={onFinish} type='primary'>
            {t('familiar')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckList;
