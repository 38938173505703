/* eslint-disable */

import React, { Component } from 'react';

import { Badge, Calendar, Layout, Modal } from 'antd';

import MyCalendarDay from './Day';

const { Content } = Layout;

export class MyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };
  }

  getListData = value => {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          { type: 'warning', content: 'Конференция .' },
          { type: 'success', content: 'Встреча.' },
        ];
        break;
      case 10:
        listData = [
          { type: 'warning', content: 'Встреча.' },
          { type: 'success', content: 'Встреча.' },
          { type: 'error', content: 'Конференция.' },
        ];
        break;
      case 15:
        listData = [
          { type: 'warning', content: 'Собрание' },
          { type: 'success', content: 'Встреча' },
          { type: 'error', content: 'This is error event 1.' },
          { type: 'error', content: 'This is error event 2.' },
          { type: 'error', content: 'This is error event 3.' },
          { type: 'error', content: 'This is error event 4.' },
        ];
        break;
      default:
    }
    return listData || [];
  };

  dateCellRender = value => {
    const listData = this.getListData(value);
    return (
      <ul className='events'>
        {listData.map(item => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  getMonthData = value => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  monthCellRender = value => {
    const num = this.getMonthData(value);
    return num ? (
      <div className='notes-month'>
        <section>{num}</section>
        <span>BackLog number</span>
      </div>
    ) : null;
  };

  onSelectDate = event => {
    console.log('onSelectDate', event);
  };

  handleCalendarDayOk = () => {
    console.log('handleCalendarDayOk');
  };

  handleCalendarDayCandel = () => {
    console.log('handleCalendarDayCandel');
    this.setState({
      showDetails: true,
    });
  };

  render() {
    return (
      <Content
        className='App-content'
        style={{
          marginTop: '3rem',
          backgroundColor: 'white',
        }}
      >
        <Calendar
          dateCellRender={this.dateCellRender}
          monthCellRender={this.monthCellRender}
          fullscreen={true}
          onSelect={this.onSelectDate}
        />
        <Modal title='Title' open={false} onOk={this.handleCalendarDayOk} onCancel={this.handleCalendarDayCandel}>
          <MyCalendarDay />
        </Modal>
      </Content>
    );
  }
}

export default MyCalendar;
