import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Input, Row, Select } from 'antd';

import { getDateObj } from '@shared/utils/date-utils';
import { capitalize } from '@shared/utils/string-utils';
import { classNames } from '@shared/utils/styles-utils';

import debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const Filters = ({ filtersOptions, filters: _filters, onChange }) => {
  const { t } = useTranslation('planingFilter');
  const filters = _filters || {};
  const [filterMaterial, setFilterMaterial] = useState(filters.materialName);
  const yearOptions = filtersOptions
    ? _orderBy(filtersOptions.years).map((year, index) => ({
        key: index,
        value: year,
        label: String(year),
      }))
    : [];

  const filtersMonthsOptions = _get(filtersOptions, 'months', []);

  const debounceChangeMaterials = useCallback(debounce(onChange, 1000, { trailing: true }), []);

  useEffect(() => {
    debounceChangeMaterials({ name: filterMaterial?.trim() || undefined });
  }, [filterMaterial]);

  useEffect(() => {
    return () => debounceChangeMaterials.cancel();
  }, [debounceChangeMaterials]);

  const monthOptions = _orderBy(filtersMonthsOptions).map((month, index) => ({
    key: index,
    value: month,
    label: capitalize(
      getDateObj()
        .month(month - 1)
        .format('MMMM')
    ),
  }));

  // const addedByAdminOptions = [
  //   { key: true, value: true, label: t('necessarily') },
  //   { key: false, value: false, label: t('optional') },
  // ];

  const typeLabelsOptions = filtersOptions
    ? filtersOptions.typeLabels.map(type => ({
        key: type.id,
        value: type.id,
        label: type.name,
        name: type.name,
      }))
    : [];

  const resetFilters = () => {
    setFilterMaterial(undefined);
    onChange({
      year: undefined,
      month: undefined,
      topicTypeLabelId: undefined,
      trajectory: undefined,
      addedByAdmin: undefined,
    });
  };

  return (
    <Row className={cn('plan-filters')} justify='space-between'>
      <Button type='link' size='large' onClick={resetFilters} className={cn('plan-filters__reset')}>
        {t('clear')}
      </Button>
      <Row className={cn('plan-filters__controls')} type='flex' justify='space-around' align='middle'>
        <Col className={cn('plan-filters__col')}>
          <Input
            className={cn('plan-filters__search')}
            placeholder={t('placeholderSearch')}
            onChange={e => setFilterMaterial(e.target.value)}
            value={filterMaterial}
            size='large'
            allowClear
          />
        </Col>
        <Col className={cn('plan-filters__col')}>
          <Select
            name='year'
            className={cn('plan-filters__filter')}
            allowClear
            value={filters.year}
            placeholder={t('year')}
            onClear={() => onChange({ ...filters, year: undefined })}
            defaultActiveFirstOption={false}
            disabled={!yearOptions.length}
            options={yearOptions}
            onChange={e => onChange({ ...filters, year: e })}
            popupClassName={cn('select__dropdown')}
            size='large'
          />
        </Col>
        <Col className={cn('plan-filters__col')}>
          <Select
            name='month'
            className={cn('plan-filters__filter')}
            allowClear
            value={filters.month}
            placeholder={t('month')}
            onClear={() => onChange({ ...filters, month: undefined })}
            defaultActiveFirstOption={false}
            disabled={!filtersMonthsOptions.length}
            options={monthOptions}
            onChange={e => onChange({ ...filters, month: e })}
            popupClassName={cn('select__dropdown')}
            size='large'
          />
        </Col>
        <Col className={cn('plan-filters__col')}>
          <Select
            mode='multiple'
            name='topicTypeLabelId'
            className={cn('plan-filters__multi')}
            filterOption={(input, option) => {
              return option.name.toString().toLowerCase().includes(input.toLowerCase());
            }}
            allowClear
            value={filters.topicTypeLabelId}
            placeholder={t('all')}
            defaultActiveFirstOption={false}
            disabled={!typeLabelsOptions.length}
            options={typeLabelsOptions}
            onChange={e => onChange({ ...filters, topicTypeLabelId: e })}
            popupClassName={cn('select__dropdown')}
            size='large'
          />
        </Col>
      </Row>
    </Row>
  );
};

Filters.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  topicTypeLabelId: PropTypes.array,
  trajectory: PropTypes.string,
};
