import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectTalentQ } from '@/store/talentq/selectors';

import { Button, Col, Row, Spin } from 'antd';
import { FiList } from 'react-icons/fi';
import { HiOutlineChartBar } from 'react-icons/hi';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './resultTalentQ.module.scss';

const FROMTEN = '/10';

export const ResultTalentQ = props => {
  const { t } = props;
  const [view, setView] = useState(true);
  const [init, setInit] = useState(false);
  const [desc, setDesc] = useState([]);
  const [arrayCompetence, setArrayCompetence] = useState({
    isStrengths: [],
    isKeyZone: [],
    isAdditionalZone: [],
  });

  const resultCompetence =
    Object.keys(props.scores)?.length > 0 &&
    Object.keys(props.scores)
      .map(key => props.scores[key])
      .reduce((acc, item) => {
        const competence = { ...item.competence, value: item.value };
        return [...acc, competence];
      }, []);

  // eslint-disable-next-line
  const resComp = [];

  useEffect(() => {
    if (!init) {
      const strengthsArray = resComp.length > 0 && resComp[0].map(item => item);
      const strengths =
        Array.isArray(strengthsArray) &&
        strengthsArray.length > 0 &&
        strengthsArray.map(item => {
          return {
            value: item.value,
            description: item.description,
          };
        });

      const keyZoneArray = resComp.length > 0 && resComp[1].map(item => item);
      const keyZone =
        Array.isArray(keyZoneArray) &&
        keyZoneArray.length > 0 &&
        keyZoneArray.map(item => {
          return {
            value: item.value,
            description: item.description,
          };
        });

      const additionalZoneArray = resComp.length > 0 && resComp[2].map(item => item);
      const additionalZone =
        Array.isArray(additionalZoneArray) &&
        additionalZoneArray.length > 0 &&
        additionalZoneArray.map(item => {
          return {
            value: item.value,
            description: item.description,
          };
        });

      setArrayCompetence({
        ...arrayCompetence,
        isStrengths: strengths,
        isKeyZone: keyZone,
        isAdditionalZone: additionalZone,
      });

      const descComp =
        resComp &&
        resComp.length > 0 &&
        resComp
          .reduce((acc, item) => {
            const nameCompetence = item.map(item => {
              return {
                name: item.description ? item.description.split(':')[1] : '-',
                value: item.value,
              };
            });
            return [...acc, nameCompetence];
          }, [])
          .flat();
      setDesc(descComp);
      setInit(true);
    }
  }, [resComp, init, arrayCompetence]);

  const competenceColumns = [
    {
      title: t('strengths'),
      from: 8,
      to: 10,
      id: 1,
      backgroundColor: 'rgba(49, 191, 49, 0.2)',
      borderColor: 'rgba(49, 191, 49, 1)',
    },
    {
      title: t('keyZone'),
      from: 1,
      to: 3,
      id: 2,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
    },
    {
      title: t('additionalZone'),
      from: 4,
      to: 7,
      id: 3,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
    },
  ];

  const options = {
    scale: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 10,
            stepSize: 1,
          },
        },
      ],
    },
  };

  const { str, az, kz } =
    desc &&
    desc.length > 0 &&
    desc.reduce(
      (acc, item) => {
        if (item.value >= 8 && item.value <= 10) {
          acc.str.push(item.value); //STR
          acc.kz.push(0);
          acc.az.push(0);
        }
        if (item.value >= 1 && item.value <= 3) {
          acc.kz.push(item.value); //KZ
          acc.az.push(0);
        }

        if (item.value >= 4 && item.value <= 7) {
          acc.az.push(item.value); //AZ
        }

        return acc;
      },
      {
        str: [],
        kz: [],
        az: [],
      }
    );

  const data = {
    labels: desc && desc.length > 0 && desc.map(item => item.name),
    datasets: [
      {
        label: t('strengths'),
        data: str,
        backgroundColor: '#5FAF2D',
      },
      {
        label: t('keyZone'),
        data: kz,
        backgroundColor: '#CF0819',
      },
      {
        label: t('additionalZone'),
        data: az,
        backgroundColor: '#EDBA00',
      },
    ],
  };

  const changeView = () => setView(!view);

  const render = () => {
    return (
      <>
        <h2 className={css.headerContentCaption}>{t('results')}</h2>
        <div className={css.btnChangeView}>
          <Button type='primary' onClick={changeView}>
            {view ? <FiList size={18} /> : <HiOutlineChartBar size={18} />}
          </Button>
        </div>
        <Spin size='large' spinning={props.isLoadingScore}>
          <Row>
            {competenceColumns.map(item => {
              if (resultCompetence.length) {
                const filteredValue = resultCompetence.filter(
                  count => count.value >= item.from && count.value <= item.to
                );

                resComp.push(filteredValue);

                const resultList = filteredValue.map(item => {
                  const description = item.description ? item.description.split(':')[1] : '-';
                  return (
                    <Col key={item.id} span={8}>
                      <div className={css.text}>
                        {description} - &nbsp;
                        <strong>
                          {item.value}
                          {FROMTEN}
                        </strong>
                      </div>
                    </Col>
                  );
                });

                const finishResult = view ? null : (
                  <Col key={item.id} span={8}>
                    <h3 className={css.headerContentCaptionCol}>{item.title}</h3>
                    {resultList}
                  </Col>
                );
                return finishResult;
              }
              return '';
            })}
          </Row>
        </Spin>
        <Row>{view && Object.keys(props.scores)?.length > 0 && <Bar options={options} data={data} />}</Row>
      </>
    );
  };
  return render();
};

ResultTalentQ.propTypes = {
  t: PropTypes.func,
  currentUser: PropTypes.object,
  domainCompany: PropTypes.object,
  scores: PropTypes.any,
  loading: PropTypes.bool,
  isLoadingScore: PropTypes.bool,
};

const mapStateToProps = createSelector([selectTalentQ], selectTalentQ => ({
  scores: selectTalentQ.scores,
  loading: selectTalentQ.loading,
  isLoadingScore: selectTalentQ.isLoadingScore,
}));

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticTalentq')(ResultTalentQ));
