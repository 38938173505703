import { useTranslation } from 'react-i18next';

import { Button, Modal, Typography } from 'antd';

import css from '../Timer.module.scss';

const { Text } = Typography;

const FinishModal = ({ visible, onSuccess }) => {
  const { t } = useTranslation('video');

  return (
    <Modal open={visible} footer={null} closable={false}>
      <div className={css['Timer__modal']}>
        <Text>{t('timeLeft')}</Text>
        <Button onClick={onSuccess} type='primary' size='large'>
          {t('checkResult')}
        </Button>
      </div>
    </Modal>
  );
};

export default FinishModal;
