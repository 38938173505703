import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'antd';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import PropTypes from 'prop-types';

import css from './StartTest.module.scss';

dayjs.extend(duration);

const { Title, Text } = Typography;

const StartTest = ({
  time,
  text,
  getStatistic,
  blockId,
  getQuestion,
  currentUser,
  getDetails,
  setBlockStart,
  restart,
  setRestart,
  setRestartBlockHandler,
}) => {
  const { t } = useTranslation('video');

  const startTest = () => {
    if (restart) {
      setRestart(false);
      setRestartBlockHandler(blockId);
    } else {
      setBlockStart(blockId, () => {
        getStatistic(true);
        getQuestion(blockId, response => {
          if (!response) {
            getDetails(blockId, currentUser.id);
          }
        });
      });
    }
  };

  const onTextFromTheTime = time => {
    const dayjsTime = dayjs.duration({
      hours: dayjs(time, 'HH:mm:ss').format('HH'),
      minutes: dayjs(time, 'HH:mm:ss').format('mm'),
      seconds: dayjs(time, 'HH:mm:ss').format('ss'),
    });

    if (+dayjsTime.hours() !== 0 || +dayjsTime.days() !== 0) {
      return t('hours');
    } else if (+dayjsTime.minutes() !== 0) {
      return t('minutes');
    } else {
      return t('seconds');
    }
  };

  const render = () => {
    return (
      <div className={css['Modal-for-test']}>
        <Title level={4}>{t('textForModal', { time, text: onTextFromTheTime(time) })}</Title>
        <Text className={css['Modal-for-test__text']}>{text}</Text>
        <Button onClick={startTest} type='primary' size='large'>
          {t('startTest')}
        </Button>
      </div>
    );
  };

  return render();
};

StartTest.propTypes = {
  time: PropTypes.string,
  text: PropTypes.string,
  getStatistic: PropTypes.func,
  blockId: PropTypes.number,
  getQuestion: PropTypes.func,
  currentUser: PropTypes.object,
  getDetails: PropTypes.func,
  setBlockStart: PropTypes.func,
  restart: PropTypes.bool,
  setRestart: PropTypes.func,
  setRestartBlockHandler: PropTypes.func,
};

export default StartTest;
