import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createCareerPlan, editCareerPlan, getGoalCategories } from '@/store/career_plan/actions';
import { getCompetence } from '@/store/competence/actions';

import { selectCareerPlan } from '@/store/career_plan/selectors';
import { selectCompetence } from '@/store/competence/selectors';

import { Button, DatePicker, Flex, Form, Input, Modal, notification, Select } from 'antd';

import { DATE_FORMAT, INCOME_FORMAT, MAX_COMPETENCES, MAX_DESCRIPTION } from '../../constants';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CreateCareerPlanModal.module.scss';

const { TextArea } = Input;

const CreateCareerPlanModal = ({ visible, closeModal, data = null }) => {
  const { t } = useTranslation('careerPlan');
  const dispatch = useDispatch();

  const { isLoading } = useSelector(selectCompetence);
  const { competence } = useSelector(selectCompetence);
  const { goalCategory } = useSelector(selectCareerPlan);
  const [form] = Form.useForm();

  const disabledDate = current => dayjs().subtract(1, 'days') >= current;

  useEffect(() => {
    if (visible && data) {
      const correctGoal = data?.goal.replace(/\s\s+/g, ' ');

      const correctData = {
        deadline: data?.deadline ? dayjs(data?.deadline, INCOME_FORMAT).format(INCOME_FORMAT) : undefined,
        goal: correctGoal,
        competences:
          Array.isArray(data?.competences) && data?.competences?.length > 0
            ? data.competences.map(competence => {
                return competence?.id;
              })
            : [],
        goalCategory: data?.goalCategory?.value,
      };
      form.setFieldsValue({ ...correctData });
    }
  }, [data, visible]);

  useEffect(() => {
    dispatch(getCompetence());
    dispatch(getGoalCategories());
  }, []);

  const submitForm = async () => {
    const sendData = await form.getFieldsValue();

    form.validateFields().then(() => {
      const isEdit = !!data;

      const onSuccess = () => {
        notification.success({
          message: t(isEdit ? 'createModal.careerPlanSuccessEdit' : 'createModal.careerPlanSuccess'),
        });
        closeModal();
      };

      if (isEdit) {
        dispatch(editCareerPlan(data?.id, sendData, () => onSuccess()));
      } else {
        dispatch(createCareerPlan(sendData, () => onSuccess()));
      }
    });
  };

  const competenceOptions = useMemo(() => {
    if (Array.isArray(competence?.content) && competence?.content.length > 0) {
      return competence.content.map(comp => ({
        key: comp?.id,
        label: comp?.name,
        value: comp?.id,
      }));
    }
  }, [competence]);

  const goalCategoryOptions = useMemo(() => {
    if (Array.isArray(goalCategory) && goalCategory.length > 0) {
      return goalCategory.map(comp => ({
        key: comp?.id,
        label: comp?.name,
        value: comp?.value,
      }));
    }
  }, [goalCategory]);

  const title = <p className={css['CreateCareerPlanModal-title']}>{t('createModal.title')}</p>;

  const footer = (
    <>
      <Flex className={css['CreateCareerPlanModal-modal-footer']} align='center' justify='flex-end' gap={24}>
        <Button onClick={closeModal}>{t('createModal.cancel')}</Button>
        <Button type='primary' onClick={submitForm}>
          {t('createModal.save')}
        </Button>
      </Flex>
    </>
  );

  return (
    <Modal
      className={css['CreateCareerPlanModal-modal']}
      open={visible}
      onCancel={closeModal}
      title={title}
      footer={footer}
      width={800}
      loading={isLoading}
    >
      <Form form={form} layout='vertical' className={css['CreateCareerPlanModal-form']}>
        {/* Категория цели */}
        <Form.Item
          label={t('createModal.goalCategory')}
          rules={[
            {
              required: true,
              max: MAX_DESCRIPTION,
              message: t('createModal.goalCategoryError'),
            },
          ]}
          name='goalCategory'
        >
          <Select
            className={css['CreateCareerPlanModal-form-item-select']}
            placeholder={t('createModal.goalCategoryPlaceholder')}
            options={goalCategoryOptions}
          />
        </Form.Item>
        {/* Цель */}
        <Form.Item
          label={t('createModal.goal')}
          rules={[
            {
              required: true,
              max: MAX_DESCRIPTION,
              whitespace: true,
            },
          ]}
          name='goal'
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder={t('createModal.placeholder.goal', { max: MAX_DESCRIPTION })}
            count={{
              max: MAX_DESCRIPTION,
              show: ({ count, maxLength }) => `${count}/${maxLength}`,
            }}
          />
        </Form.Item>
        {/* Компетенции */}
        <Form.Item
          name='competences'
          label={t('createModal.competences')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            className={css['CreateCareerPlanModal-form-item-select']}
            placeholder={t('createModal.competencesPlaceholder')}
            options={competenceOptions}
            mode='multiple'
            showSearch
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            maxCount={MAX_COMPETENCES}
          />
        </Form.Item>
        {/* Компетенции */}
        <Form.Item
          name='deadline'
          label={t('createModal.deadline')}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={value => ({
            value: value && dayjs(value, INCOME_FORMAT),
          })}
          normalize={value => value && dayjs(value).format(INCOME_FORMAT)}
        >
          <DatePicker placeholder={dayjs().format(DATE_FORMAT)} format={DATE_FORMAT} disabledDate={disabledDate} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateCareerPlanModal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    competences: PropTypes.array,
    deadline: PropTypes.string,
    goal: PropTypes.string,
    goalCategory: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    id: PropTypes.number,
  }),
  visible: PropTypes.bool,
};

export default CreateCareerPlanModal;
