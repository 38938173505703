import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';
import { DislikeTwoTone, HourglassOutlined, LikeTwoTone } from '@ant-design/icons';

import style from '../CheckList.module.scss';

const CheckListElement = ({ result, description, index }) => {
  const { t } = useTranslation('pokaChecklist');

  const renderResult = () => {
    switch (result) {
      case true:
        return <LikeTwoTone twoToneColor='limegreen' />;
      case false:
        return <DislikeTwoTone twoToneColor='red' />;
      default:
        return <HourglassOutlined />;
    }
  };

  return (
    <div className={style['check-list__element']}>
      <div className={style['check-list__block']}>
        {renderResult()}
        <Typography.Text>{t('grade')}</Typography.Text>
      </div>
      <Typography.Text className={style['check-list__description']}>{`${index}. ${description}`}</Typography.Text>
    </div>
  );
};

export default CheckListElement;
