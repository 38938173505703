/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetUserIncome, setScopeIncome } from '@/store/income/actions';

import { selectIncome } from '@/store/income/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button } from 'antd';
import { CoinsIcon } from '@/components/elements/coins-icon';
import Modal from '@/components/Modal';

import classNames from 'classnames';
import _throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import css from './IncomeModal.module.scss';

import {
  COMMENT_TOPIC,
  FINISHED_TOPIC,
  LOGIN,
  NEW_TOPIC,
  SUCCESS,
  SUCCESS_BTN,
  UNMODERATE,
  UNMODERATE_BTN,
  WARNING,
} from '@/constants/income-types';
import { TOPIC_MANAGE } from '@/constants/permissions';

const IncomeModal = () => {
  const { t } = useTranslation('income');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { redirectTo, lastIncome, incomeType, scope, incomeContentLabel } = useSelector(selectIncome);
  const { currentUser } = useSelector(selectUsers);

  const [isSuccess, setIsSuccess] = useState(true);
  const [visible, setVisible] = useState(false);
  const [income, setIncome] = useState(0);
  const [incomeTypeModal, setIncomeTypeModal] = useState('');
  const [canEditTopic, setCanEditTopic] = useState(false);

  const headerTitle = isSuccess && scope !== UNMODERATE ? SUCCESS : WARNING;
  const btnLabel = isSuccess && scope !== UNMODERATE ? SUCCESS_BTN : UNMODERATE_BTN;

  useEffect(() => {
    setCanEditTopic(currentUser.authorities.includes(TOPIC_MANAGE));
  }, [currentUser]);

  useEffect(() => {
    if (lastIncome?.id && incomeType && (incomeType !== LOGIN || lastIncome?.createdBy === 'anonymousUser')) {
      setIsSuccess(incomeType === FINISHED_TOPIC || incomeType === COMMENT_TOPIC ? scope !== UNMODERATE : canEditTopic);
      setVisible(true);
      setIncome(lastIncome?.amount);
      setIncomeTypeModal(!isSuccess ? UNMODERATE : incomeType);
    }
  }, [lastIncome]);

  useEffect(() => {
    if (incomeType === NEW_TOPIC && !lastIncome.amount && redirectTo) {
      redirect();
    }
  }, [redirectTo]);

  useEffect(() => {
    if (scope === UNMODERATE) {
      setVisible(true);
      setIncomeTypeModal(UNMODERATE);
      dispatch(setScopeIncome(null));
    }
  }, [scope]);

  const redirect = _throttle(
    () => {
      redirectTo && (navigate ? navigate(redirectTo) : (document.location.pathname = redirectTo));
      dispatch(resetUserIncome());
    },
    1000,
    { trailing: false }
  );

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      width={430}
      hideFooter
      footerBtnOrange
      modificate
      cardOn
      zIndex={1006}
    >
      <div id='incomeModal' data-qa='userCollectionSuccessAddedModal' className={classNames(css.IncomeModal)}>
        <div className={css['IncomeModal-header']}>{t(headerTitle)}</div>
        <div className={css['IncomeModal-text']}>{incomeContentLabel ? incomeContentLabel : t(incomeTypeModal)}</div>
        {isSuccess && incomeTypeModal !== UNMODERATE && !!currentUser && (
          <div className={css['IncomeModal-coins']}>
            <CoinsIcon coinsIcon={currentUser?.domainCompany?.coinIcon} />
            {income}
          </div>
        )}
        <Button onClick={handleCancel} type='primary' size='large'>
          {t(btnLabel)}
        </Button>
      </div>
    </Modal>
  );
};

IncomeModal.propTypes = {
  incomeType: PropTypes.string,
  lastIncome: PropTypes.object,
  redirectTo: PropTypes.string,
  currentUser: PropTypes.object,
  t: PropTypes.func,
  scope: PropTypes.string,
};

export default IncomeModal;
