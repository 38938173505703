/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { activateUser, getUser } from '@/store/users/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './UserProfileModal.module.scss';

export class UserInfoModal extends Component {
  static propTypes = {
    activateUser: PropTypes.func,
    currentUser: PropTypes.object,
    getUser: PropTypes.func,
    onCancel: PropTypes.func,
  };

  handleUserActivation = () => {
    this.props.activateUser(
      this.props.currentUser.id,
      this.props.currentUser.about,
      this.props.currentUser.localPhotoUuid,
      () => this.props.onCancel()
    );
  };

  render() {
    const { currentUser } = this.props;

    return (
      <div className={classNames(css['App-card-item'], css['UserProfile-modal'])}>
        <div className={css['UserProfile-modal-header']}>Добро пожаловать</div>
        <div className={css['UserProfile-modal-item-container']}>
          <div className={css['UserProfile-modal-item']}>
            <div className={css['UserProfile-modal-label']}>Имя</div>
            <div>{currentUser.firstName}</div>
          </div>
          <div className={css['UserProfile-modal-item']}>
            <div className={css['UserProfile-modal-label']}>Фамилия</div>
            <div>{currentUser.lastName}</div>
          </div>
          <div className={css['UserProfile-modal-item']}>
            <div className={css['UserProfile-modal-label']}>Эл.почта</div>
            <div>{currentUser.email}</div>
          </div>
        </div>
        <Button onClick={this.handleUserActivation}>Начать использовать</Button>
      </div>
    );
  }
}

const mapStateToProps = createSelector(usersSelect, users => ({
  currentUser: users.currentUser || {},
}));

const mapActionsToProps = {
  activateUser,
  getUser,
};

export default connect(mapStateToProps, mapActionsToProps)(UserInfoModal);
