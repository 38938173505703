/* eslint-disable */
import { useEffect } from 'react';

import PropTypes from 'prop-types';

import css from './index.module.scss';

import './index.module.scss';

const ArticleFrame = ({ body }) => {
  useEffect(() => {
    const af = document.querySelector('.' + css.ArticleFrame);
    if (af) {
      af.innerHTML = body ? body.replaceAll('\t', '&nbsp;&nbsp;&nbsp;&nbsp;') : '';
      fixImage(af);
    }
  }, [body]);

  const fixImage = block => {
    if (block.firstElementChild) {
      let elem = block.firstElementChild.childNodes;

      const strongText = block.getElementsByTagName('strong');
      if (strongText.length) {
        for (let i = 0; i < strongText.length; i++) {
          strongText[i].style.fontWeight = 700;
        }
      }

      const videoFrame = block.getElementsByClassName('ql-video');
      if (videoFrame.length) {
        for (let i = 0; i < videoFrame.length; i++) {
          videoFrame[i].style.width = '100%';
          videoFrame[i].style.height = '30vh';
        }
      }

      const quoteText = block.getElementsByTagName('blockquote');
      if (quoteText.length) {
        for (let i = 0; i < quoteText.length; i++) {
          quoteText[i].style.padding = '10px 15px';
          quoteText[i].style.borderLeft = '0.5em solid #eee';
        }
      }

      for (let indent = 1; indent <= 8; indent++) {
        const indentElements = block.getElementsByClassName('ql-indent-' + indent);

        if (indentElements.length) {
          for (let i = 0; i < indentElements.length; i++) {
            indentElements[i].style.paddingLeft = 3 * indent + 'em';
          }
        }
      }

      const textCenterAlign = block.getElementsByClassName('ql-align-center');
      if (textCenterAlign.length) {
        for (let i = 0; i < textCenterAlign.length; i++) {
          textCenterAlign[i].style.textAlign = 'center';
        }
      }

      const textRigthAlign = block.getElementsByClassName('ql-align-right');
      if (textRigthAlign.length) {
        for (let i = 0; i < textRigthAlign.length; i++) {
          textRigthAlign[i].style.textAlign = 'right';
        }
      }

      const textJustifyAlign = block.getElementsByClassName('ql-align-justify');
      if (textJustifyAlign.length) {
        for (let i = 0; i < textJustifyAlign.length; i++) {
          textJustifyAlign[i].style.textAlign = 'justify';
        }
      }

      for (let i = 0; i < elem.length; i++) {
        if (elem[i].tagName === 'IMG') {
          let value = elem[i].getAttribute('width');
          elem[i].style.width = value;
        }
      }
    }
  };

  return (
    // TODO: X-Frame-Option need fix (Stan).
    //<iframe
    //    className={css.ArticleFrame}
    //    title={title}
    //    id="articleFrame"
    //    src={url}
    //    referrerPolicy="no-referrer"
    //    sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
    //    height="100%"
    //    width="100%"
    ///>
    <div className='ql-snow'>
      <div className={css.ArticleFrame} />
    </div>
  );
};

ArticleFrame.propTypes = {
  body: PropTypes.string.isRequired,
};

export default ArticleFrame;
