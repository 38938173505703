import React from 'react';
import { useTranslation } from 'react-i18next';

import { Progress } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TrackProgress.module.scss';

const TrackProgress = ({ statPer, finishedTopics, totalTopics, className = '' }) => {
  const { t } = useTranslation('TrackCard');

  return (
    <div className={classNames(css['TrackProgress'], className)}>
      <Progress percent={statPer} showInfo={false} />
      <div className={css['TrackProgress-text']}>
        <div>{t('finished', { total: statPer })}</div>
        <div>
          {finishedTopics}/{totalTopics}
        </div>
      </div>
    </div>
  );
};

TrackProgress.propTypes = {
  statPer: PropTypes.number,
  finishedTopics: PropTypes.number,
  totalTopics: PropTypes.number,
  className: PropTypes.string,
};

export default TrackProgress;
