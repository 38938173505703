/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  checkedIsAvailableForStart,
  getArticle,
  setArticleBlockFinish,
  setArticleBlockStart,
} from '@/store/lms/actions';

import { selectLMS } from '@/store/lms/selectors';

// UI
import { Button } from 'antd';
import ArticleFrame from '@/components/ArticleFrame';
import Condition from '@/components/Condition';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import { API_BASE } from '@shared/constants/config';

import { openDB } from 'idb';

import css from './index.module.scss';

import { ARTICLE_IDB, IDB_NAME } from '@/constants/IndexedDB';
import { FINISHED } from '@/constants/study-plan-statuses';

export const Article = props => {
  const { block, getStatistic, updateChaptersIDB, removeChaptersFromIDB, getStatisticStart } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('article');

  const { statistic, article, arrayChecked } = useSelector(selectLMS);

  const [articleID, setArticleID] = useState(null);
  const [articleBody, setArticleBody] = useState(null);
  const [articleIDB, setArticleIDB] = useState(false);

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;
  useEffect(() => {
    !window.navigator.onLine && getArticleIDB();
  }, [articleID]);

  useEffect(() => {
    if (articleID) {
      checkArticleIDB();
    }
    if (block?.article?.id) {
      if (block.isAvailableForStart && !arrayChecked?.includes(block?.blockId)) {
        dispatch(
          setArticleBlockStart(block.blockId, () => {
            getStatisticStart();
          })
        );
        dispatch(checkedIsAvailableForStart(block?.blockId));
      }
      dispatch(getArticle(block.article.id));
      setArticleBody(article?.body);
      setArticleID(block.article.id);
    }
  }, []);

  const finishArticleBlock = () => {
    dispatch(
      setArticleBlockFinish(block.blockId, () => {
        getStatistic(next <= 1);
        window.scrollTo(0, 0);
      })
    );
  };
  // TODO: X-Frame-Option need fix (Stan)
  const getArticleContent = () => {
    return `${API_BASE}/article/${articleID}/src`;
  };

  const checkArticleIDB = async () => {
    const db = await openDB(IDB_NAME);
    const article = await db.get(ARTICLE_IDB, articleID);
    if (article) {
      setArticleIDB(true);
    } else {
      setArticleIDB(false);
    }
  };

  const getArticleIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (db.objectStoreNames.contains(ARTICLE_IDB)) {
      const articleIDB = await db.get(ARTICLE_IDB, articleID);
      setArticleBody(articleIDB.value);
      if (articleIDB) {
        setArticleIDB(true);
      } else {
        setArticleIDB(false);
      }
    }
  };

  const transactionArticleIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (articleIDB) {
      await db.delete(ARTICLE_IDB, articleID);
      removeChaptersFromIDB();
      setArticleIDB(false);
    } else {
      await db.put(ARTICLE_IDB, {
        value: articleBody,
        id: articleID,
      });
      updateChaptersIDB();
      setArticleIDB(true);
    }
  };

  return (
    <div className={css.ArticleWrapper}>
      <Condition when={block}>
        <div className={css.ArticleTypeTitle}>{t('article')}</div>
        <div className={css.ArticleTitle}>
          {block.name}
          <Button
            onClick={transactionArticleIDB}
            type={articleIDB ? 'danger' : 'primary'}
            icon={articleIDB ? <DeleteOutlined /> : <DownloadOutlined />}
            shape='circle'
            size='large'
          ></Button>
        </div>
      </Condition>
      <div className={css.ArticleContent}>
        {article?.body && (
          <ArticleFrame
            title='Article'
            url={getArticleContent()}
            body={article?.body} // TODO: remove when that fixed
          />
        )}
      </div>
      <div className={css.ArticleButtons}>
        {block.status !== 'FINISHED' && window.navigator.onLine && (
          <Button onClick={finishArticleBlock} type='primary' size='large'>
            {next > 1 ? t('next') : t('end')}
          </Button>
        )}
      </div>
    </div>
  );
};
