/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Col, Modal, Row, Tabs } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { ImFileExcel } from 'react-icons/im';

import { D360GetReport } from '../../../store/d360/Report/actions';
import ReportRadar from './ReportRadar';
import ReportTable from './ReportTable';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const Report = props => {
  const { t } = useTranslation('D360_Report');
  const dispatch = useDispatch();
  const { project } = useSelector(state => state.d360Project);
  const { report, loading } = useSelector(state => state.d360Report);
  const { currentUser } = useSelector(state => state.users);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (!_.isNil(project) && _.isObject(project) && _.has(project, 'id') && _.isNumber(project.id)) {
      dispatch(D360GetReport(project.id));
    }
  }, [props.projectId]);

  useEffect(() => {
    let username = '';
    if (!_.isNil(currentUser) && _.isObject(currentUser)) {
      if (_.has(currentUser, 'lastName') && _.isString(currentUser.lastName) && currentUser.lastName.length > 0) {
        username = currentUser.lastName;
      }

      if (_.has(currentUser, 'firstName') && _.isString(currentUser.firstName) && currentUser.firstName.length > 0) {
        username += ' ' + currentUser.firstName;
      }

      if (_.has(currentUser, 'middleName') && _.isString(currentUser.middleName) && currentUser.middleName.length > 0) {
        username += ' ' + currentUser.middleName;
      }
    }

    setUserName(username);
  }, [currentUser]);

  const onCancel = () => {
    if (!_.isNil(props) && _.isObject(props) && _.has(props, 'onCancel') && _.isFunction(props.onCancel)) {
      props.onCancel();
    }
  };

  const onExcel = () => {
    const excel = new Excel();
    const filename = 'Report_' + dayjs().format('YYYY-MM-DD').toString() + '.xlsx';
    const filtered = ['self', 'manager', 'colleague', 'subordinate', 'cross'];
    const columns = [
      { key: 'name', dataIndex: 'name', title: t('columnCriteria') },
      { key: 'manager', dataIndex: 'manager', title: t('columnManagers') },
      { key: 'subordinate', dataIndex: 'subordinate', title: t('columnSubordinates') },
      { key: 'colleague', dataIndex: 'colleague', title: t('columnColleagues') },
      { key: 'cross', dataIndex: 'cross', title: t('columnOthers') },
      { key: 'self', dataIndex: 'self', title: t('columnSelf') },
      { key: 'median', dataIndex: 'median', title: t('columnMedian') },
    ];

    let currentReport = report;
    currentReport.forEach(object => {
      Object.keys(object).forEach(key => {
        if (filtered.includes(key)) {
          object[key] < 0 ? (object[key] = '') : (object[key] = object[key]);
        }
      });
    });

    excel.addSheet(userName);
    excel.addColumns(columns);
    excel.addDataSource(currentReport);
    excel.saveAs(filename);
  };

  const Footer = () => {
    return (
      <Row>
        <Col span={12} align='left'>
          <Button onClick={onExcel} style={{ color: 'white', backgroundColor: '#076c4b', border: 0 }} loading={loading}>
            <ImFileExcel /> &nbsp; {t('exportToExcel')}
          </Button>
        </Col>
        <Col span={12} align='right'>
          <Button type='primary' onClick={onCancel}>
            {t('close')}
          </Button>
        </Col>
      </Row>
    );
  };

  const itemsTabs = [
    {
      key: 'table',
      label: t('table'),
      children: <ReportTable />,
    },
    {
      key: 'radar',
      label: t('radar'),
      children: <ReportRadar />,
    },
  ];

  return (
    <Modal title={'Отчет'} width={1000} open={props.open} footer={<Footer />} onCancel={onCancel}>
      <Row>
        <Col span={24}>
          <Tabs size='small' items={itemsTabs} />
        </Col>
      </Row>
    </Modal>
  );
};

Report.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Report;
