import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input } from 'antd';
import BannerInput from '@/components/BannerInput';
import { FiCamera } from 'react-icons/fi';

import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const TitleGroup = ({ updateImage, imagePreview, name, setName, onSubmitForm, handleCancelButton }) => {
  const { t } = useTranslation('chat');

  const [form] = Form.useForm();

  useEffect(() => {
    if (name) {
      form.setFieldValue('nameGroup', name);
    }
  }, [name]);

  return (
    <div className={styles.groups__content}>
      <Form form={form} name='title-group' onFinish={onSubmitForm} className={styles.groups__form}>
        <div className={styles.groups__block}>
          <div className={styles.groups__inputPhoto}>
            {imagePreview ? <img className={styles.groups__image} src={imagePreview} alt='' /> : <FiCamera size={26} />}
            <BannerInput
              ghost
              updateImage={updateImage}
              pushError={() => {}}
              typeUpload='image/png, image/jpeg, image/gif,'
            />
          </div>
          <Form.Item
            className={styles.groups__formItem}
            name='nameGroup'
            required
            rules={[{ required: true, message: t('error.0'), whitespace: true }]}
          >
            <Input onChange={e => setName(e.target.value)} placeholder={t('nameGroup')} maxLength={128} />
          </Form.Item>
        </div>
        <div className={styles.groups__block_reverse}>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              {t('ok')}
            </Button>
          </Form.Item>
          <Button onClick={handleCancelButton}>{t('cancel')}</Button>
        </div>
      </Form>
    </div>
  );
};

TitleGroup.propTypes = {
  updateImage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  onSubmitForm: PropTypes.func,
  handleCancelButton: PropTypes.func,
};

export default TitleGroup;
