import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';

import MenteesRequestsModals from './MenteesRequestsModals';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import css from '../MenteesRequests.module.scss';

const ProfileColumn = ({ column }) => {
  const { t } = useTranslation('MenteesRequests');
  const [visible, setVisible] = useState(false);

  const [currenAvatar] = useLocalFileByUUID(column?.photoUuidUser, { defaultImg });

  const avatarJsx = column.userId ? (
    <Link className={css['MenteesRequests-table-item-title']} to={`/collegues/${column.userId}/info`}>
      <Avatar shape='circle' className={css['MenteesRequests-table-item-avatar']} src={currenAvatar} />
    </Link>
  ) : (
    <Avatar shape='circle' className={css['MenteesRequests-table-item-avatar']} src={currenAvatar} />
  );

  const userLinkJsx = column.userId ? (
    <Link className={css['MenteesRequests-table-item-title']} to={`/collegues/${column.userId}/info`}>
      {column?.firstName} {column?.lastName}
    </Link>
  ) : (
    <p className={css['MenteesRequests-table-item-title']}>
      {column?.firstName} {column?.lastName}
    </p>
  );

  return (
    <>
      <MenteesRequestsModals
        type={'REVIEW_REQUEST'}
        data={column}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <div className={css['MenteesRequests-table-container']}>
        {avatarJsx}
        <div className={css['MenteesRequests-table-item']}>
          {userLinkJsx}
          <p className={css['MenteesRequests-table-item-subtitle']}>{column?.position}</p>
          <p className={css['MenteesRequests-table-item-button']} onClick={() => setVisible(true)}>
            {t('table.viewRequest')}
          </p>
        </div>
      </div>
    </>
  );
};

export default ProfileColumn;
