/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFile, getVideoForIDB } from '@/store/files/actions';
import { getVideo, setVideoBlockFinish, setVideoBlockStart } from '@/store/lms/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectLMS, selectVideo } from '@/store/lms/selectors';

import { Button, notification, Spin } from 'antd';
import Condition from '@/components/Condition';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

// import { MediaPlayer, MediaProvider } from '@vidstack/react';
// import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import { openDB } from 'idb';
import { BigPlayButton, ControlBar, PlaybackRateMenuButton, Player } from 'video-react';

import css from './index.module.scss';

import { IDB_NAME, VIDEO_IDB } from '@/constants/IndexedDB';
import { FINISHED } from '@/constants/study-plan-statuses';

// import '@vidstack/react/player/styles/default/theme.css'; // TODO: проверить использование
// import '@vidstack/react/player/styles/default/layouts/video.css'; // TODO: проверить использование

// import { Poster } from '@vidstack/react';

export const Video = props => {
  const { block, getStatistic, updateChaptersIDB, removeChaptersFromIDB } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('video');

  const videoData = useSelector(selectVideo);
  const videoFile = useSelector(selectFiles);
  const { statistic } = useSelector(selectLMS);

  const playerRef = useRef(null);

  const [image, setImage] = useState(null);
  const [isVideoIDB, setIsVideoIDB] = useState(false);
  const [isFinishButtonActive, toggleIsFinishButtonActive] = useState(false);
  const [videoIDB, setVideoIDB] = useState(null); // для проверки чтения из локальной бд плеером видео

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  console.log('videoData', videoData); //

  useEffect(() => {
    checkVideoIDB();
    if (block.coverImage) {
      dispatch(
        getFile(block.coverImage, blob => {
          setImage(URL.createObjectURL(blob));
        })
      );
    }
    if (!block.isAvailableForStart) {
      dispatch(
        getVideo(block.video.id, () => {
          playerRef && playerRef.current?.load();
        })
      );
    }

    if (block.isAvailableForStart) {
      dispatch(setVideoBlockStart(block.blockId, () => getStatistic(true)));
    }
    !window.navigator.onLine && getVideoIDB();
  }, [block.video.id, playerRef]);

  useEffect(() => {
    if (videoFile.videoIDB !== null) {
      addIDBVideo();
    }
  }, [videoFile.videoIDB]);

  const finishVideoBlock = () => {
    dispatch(setVideoBlockFinish(block.blockId, () => getStatistic(next <= 1)));
  };

  const checkVideoIDB = async () => {
    const db = await openDB(IDB_NAME);
    const video = await db.get(VIDEO_IDB, block.video.id);
    if (video) {
      setIsVideoIDB(true);
    } else {
      setIsVideoIDB(false);
    }
  };

  const getVideoIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (db.objectStoreNames.contains(VIDEO_IDB)) {
      const videoFromIDB = await db.get(VIDEO_IDB, block.video.id);
      if (videoFromIDB) {
        setIsVideoIDB(true);
        setVideoIDB(window.URL.createObjectURL(videoFromIDB.value));
      } else {
        setIsVideoIDB(false);
      }
    }
  };

  const transactionVideoIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (!isVideoIDB && videoData.video && videoData.video.url) {
      dispatch(getVideoForIDB(videoData.video.url));
    }

    if (isVideoIDB) {
      await db.delete(VIDEO_IDB, block.video.id);
      removeChaptersFromIDB();
      setIsVideoIDB(false);
    }
  };

  const addIDBVideo = async () => {
    const db = await openDB(IDB_NAME);

    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      const remaining = quota.quota - quota.usage;

      if (videoFile.videoIDB && videoFile.videoIDB.size > remaining) {
        notification['error']({
          message: t('error'),
          description: t('textError'),
          placement: 'radius-bottomright',
        });
        return;
      }
    }
    if (!isVideoIDB) {
      await db.add(VIDEO_IDB, {
        value: videoFile.videoIDB,
        id: block.video.id,
      });
      setIsVideoIDB(true);
      updateChaptersIDB();
    }
  };

  console.log('videoData.video.url', videoData?.video?.url);

  return (
    <div className={css.VideoWrapper}>
      {videoData.isLoading && !videoIDB ? (
        <Spin className={css.SpinVideo} />
      ) : (
        <Condition when={(block && videoData.video) || videoIDB}>
          <div className={css.VideoHeader}>
            <div>
              <div className={css.VideoTypeTitle}>
                {t('video')}
                {videoFile.isLoading && !videoIDB ? (
                  <Spin />
                ) : (
                  <Button
                    onClick={transactionVideoIDB}
                    type={isVideoIDB ? 'danger' : 'primary'}
                    icon={isVideoIDB ? <DeleteOutlined /> : <DownloadOutlined />}
                    shape='circle'
                  ></Button>
                )}
              </div>
              <div className={css.VideoTitle}>{block.name}</div>
            </div>
          </div>
          <div
            className={css.VideoPlayer}
            onContextMenu={e => e.preventDefault()}
            onClick={() => toggleIsFinishButtonActive(true)}
          >
            <Player fluid={false} width='100%' height='100%' ref={playerRef} poster={image}>
              <BigPlayButton position='center' />
              {videoIDB ? (
                <source src={videoIDB} type='video/mp4' />
              ) : (
                <source src={videoData.video && videoData.video.url} type='video/mp4' />
              )}
              <ControlBar>
                <PlaybackRateMenuButton rates={[2, 1.5, 1]} />
              </ControlBar>
            </Player>
          </div>
          <div className={css.VideoButtons}>
            {block.status !== 'FINISHED' && window.navigator.onLine && (
              <Button onClick={finishVideoBlock} type='primary' size='large' disabled={!isFinishButtonActive}>
                {next > 1 ? t('next') : t('end')}
              </Button>
            )}
          </div>

          {/* <MediaPlayer
            // ref={playerRef}
            title={block.name}
            src={videoData.video && videoData.video.url ? videoData.video.url : videoIDB}
            // type='video/mp4'
            // crossorigin
            crossOrigin={'use-credentials'}
          >
            {/* <MediaPlayer title={block.name} src='https://files.vidstack.io/sprite-fight/720p.mp4'> */}
          {/* <MediaProvider>
              <Poster className='vds-poster' src={image} />
            </MediaProvider>
            <DefaultVideoLayout
              thumbnails='https://files.vidstack.io/sprite-fight/thumbnails.vtt'
              icons={defaultLayoutIcons}
            />
          </MediaPlayer> */}
        </Condition>
      )}
    </div>
  );
};
