import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { BarChartOutlined, DefaultCorpIcon, ExternalIcon } from '@/components/TypeBadge/icons';

import { getFileMemo } from '@shared/utils/file-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './TypeBadge.module.scss';

const TypeBadge = ({
  type,
  className,
  noText,
  typeId,
  small,
  portraitPlaceholder,
  onlyIcon,
  domainCompany,
  name,
  isMaterialCard,
}) => {
  const [corpCourseIcon, setCorpCourseIcon] = useState();
  const { t } = useTranslation('typeBadge');

  useEffect(() => {
    const corpCourseIconUrl = _get(domainCompany, 'corpCourseIcon');
    !corpCourseIcon &&
      corpCourseIconUrl &&
      getFileMemo(corpCourseIconUrl).then(response => setCorpCourseIcon(response));
  }, [corpCourseIcon, domainCompany]);

  const checkStringLength = string => {
    if (string.length > 17) {
      return '';
    }
    return string;
  };

  // TODO: Отрефакторить функцию
  // eslint-disable-next-line consistent-return
  const getBadgeContent = (noText, type, typeId) => {
    switch (type) {
      case 'audio':
        return (
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' width='9' height='12' viewBox='0 0 9 12'>
              <path
                d='M1.154 8.527h3.267v-5.12H1.154zM8.171.93l-3.75 2.476v5.12l3.75 2.386z'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
            {!noText && (name || t('audio'))}
          </div>
        );
      case 'book':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <g fill='none'>
                <rect fill='#F6F6F6' width='20' height='20' rx='10' />
                <path
                  d='M10 14s-1.074-.906-2.75-.906c-1.677 0-2.75.906-2.75.906V6.906S5.407 6 7.25 6s2.75.906 2.75.906S10.907 6 12.75 6s2.75.906 2.75.906V14s-1.073-.906-2.75-.906C11.074 13.094 10 14 10 14Z'
                  stroke='#2D3440'
                />
                <path fill='#2D3440' d='M9.5 7h1v7h-1z' />
              </g>
            </svg>
            {!noText && (name || t('book'))}
          </div>
        );
      case 'course':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
              <g fill='none'>
                <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
                <g transform='translate(1.5 2)' stroke='#2D3440'>
                  <rect x='3.5' y='3.5' width='9' height='9' rx='4.5' />
                  <path d='M7.7 3.603Q6.199 5.775 6.199 8.027t1.501 4.56m.6-8.984q1.537 2.2 1.537 4.45T8.3 12.587M3.41 8h9.112' />
                </g>
              </g>
            </svg>
            {!noText && (name || t('course'))}
          </div>
        );

      case 'conference':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
              <g fill='none'>
                <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
                <g transform='translate(1.5 2)'>
                  <rect fill='#2D3440' x='8' y='11' width='1' height='4' rx='.5' />
                  <rect stroke='#2D3440' x='6.5' y='2.5' width='4' height='7' rx='2' />
                  <path d='M12.476 7.5H4.524c.222 2.259 1.928 4 3.976 4s3.754-1.741 3.976-4Z' stroke='#2D3440' />
                </g>
              </g>
            </svg>
            {!noText && (name || t('conference'))}
          </div>
        );

      case 'external':
        return (
          <div>
            <ExternalIcon />
            {!noText && (name || t('course'))}
          </div>
        );

      case 'pokaSkills':
      case 'executive':
        return (
          <div>
            <BarChartOutlined />
            {!noText && (checkStringLength(name) || t('executive'))}
          </div>
        );
      case 'cost':
        return (
          <div>
            {corpCourseIcon ? <img src={corpCourseIcon} alt='' /> : <DefaultCorpIcon />}
            {!noText && (checkStringLength(name) || (isMaterialCard ? t('corpCourseShort') : t('corpCourse')))}
          </div>
        );

      case 'costwithoutapp':
        return (
          <div>
            {corpCourseIcon ? <img src={corpCourseIcon} alt='' /> : <DefaultCorpIcon />}
            {!noText && (checkStringLength(name) || (isMaterialCard ? t('corpCourseShort') : t('corpCourse')))}
          </div>
        );

      case 'event':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <g fill='none'>
                <rect fill='#F6F6F6' width='20' height='20' rx='10' />
                <g transform='translate(5 5)'>
                  <rect stroke='#2D3440' x='.5' y='.5' width='9' height='9' rx='1' />
                  <path fill='#2D3440' d='M0 3h10v1H0z' />
                </g>
              </g>
            </svg>
            {!noText && (name || t('event'))}
          </div>
        );
      case 'game':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <g fill='none'>
                <rect fill='#F6F6F6' width='20' height='20' rx='10' />
                <g transform='translate(2 2)' />
                <g transform='translate(6 5)' stroke='#2D3440'>
                  <rect x='.5' y='.5' width='3' height='3' rx='1' />
                  <rect x='5.5' y='.5' width='3' height='3' rx='1' />
                  <rect x='.5' y='5.5' width='3' height='3' rx='1' />
                  <rect x='5.5' y='5.5' width='3' height='3' rx='1' />
                </g>
              </g>
            </svg>
            {!noText && (name || t('game'))}
          </div>
        );
      case 'img':
        return (
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'>
              <g stroke='#2D3540' fill='none'>
                <path d='M1.142.5h10v8h-10z' />
                <path d='m1.642 8 9-7m0 7-9-7z' opacity='.6' />
              </g>
            </svg>
            {!noText && (name || t('image'))}
          </div>
        );
      case 'presa':
        return (
          <div>
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'>
              <path
                d='M1.142.5h10v6h-10zM4.152 7l-1.51 2.396M8.142 7l1.6 2.396M6.142 7.5v2'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
            {!noText && (name || t('presentation'))}
          </div>
        );
      case 'text':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <g fill='none'>
                <rect fill='#F6F6F6' width='20' height='20' rx='10' />
                <path d='M5.455 7.333h9.09M5.455 10h9.09m-9.09 2.667h5.454' stroke='#2D3440' />
              </g>
            </svg>
            {!noText && (name || t('text'))}
          </div>
        );
      case 'video':
        return (
          <div>
            <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <g fill='none'>
                <rect fill='#F6F6F6' width='20' height='20' rx='10' />
                <g transform='translate(2 2)' />
                <path
                  d='M14.16 10.393a.45.45 0 0 0 0-.786l-5.941-3.3a.45.45 0 0 0-.669.393v6.6a.45.45 0 0 0 .669.394z'
                  stroke='#2D3440'
                />
              </g>
            </svg>
            {!noText && (name || t('video'))}
          </div>
        );
      default:
        break;
    }
    switch (typeId) {
      case 3:
        return (
          <span
          // className={css.IconBack}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='9' height='12' viewBox='0 0 9 12'>
              <path
                d='M1.154 8.527h3.267v-5.12H1.154zM8.171.93l-3.75 2.476v5.12l3.75 2.386z'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
          </span>
        );
      case 16:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <path
                d='M10 14s-1.074-.906-2.75-.906c-1.677 0-2.75.906-2.75.906V6.906S5.407 6 7.25 6s2.75.906 2.75.906S10.907 6 12.75 6s2.75.906 2.75.906V14s-1.073-.906-2.75-.906C11.074 13.094 10 14 10 14Z'
                stroke='#2D3440'
              />
              <path fill='#2D3440' d='M9.5 7h1v7h-1z' />
            </g>
          </svg>
        );
      case 1:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
            <g fill='none'>
              <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
              <g transform='translate(1.5 2)' stroke='#2D3440'>
                <rect x='3.5' y='3.5' width='9' height='9' rx='4.5' />
                <path d='M7.7 3.603Q6.199 5.775 6.199 8.027t1.501 4.56m.6-8.984q1.537 2.2 1.537 4.45T8.3 12.587M3.41 8h9.112' />
              </g>
            </g>
          </svg>
        );

      case 17:
        return corpCourseIcon ? <img src={corpCourseIcon} alt='' /> : <DefaultCorpIcon />;

      case 35:
        return corpCourseIcon ? <img src={corpCourseIcon} alt='' /> : <DefaultCorpIcon />;

      case 8:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(5 5)'>
                <rect stroke='#2D3440' x='.5' y='.5' width='9' height='9' rx='1' />
                <path fill='#2D3440' d='M0 3h10v1H0z' />
              </g>
            </g>
          </svg>
        );
      case 5:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(2 2)' />
              <g transform='translate(6 5)' stroke='#2D3440'>
                <rect x='.5' y='.5' width='3' height='3' rx='1' />
                <rect x='5.5' y='.5' width='3' height='3' rx='1' />
                <rect x='.5' y='5.5' width='3' height='3' rx='1' />
                <rect x='5.5' y='5.5' width='3' height='3' rx='1' />
              </g>
            </g>
          </svg>
        );
      case 6:
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'>
            <g stroke='#2D3540' fill='none'>
              <path d='M1.142.5h10v8h-10z' />
              <path d='m1.642 8 9-7m0 7-9-7z' opacity='.6' />
            </g>
          </svg>
        );
      case 7:
        return (
          <span
          // className={css.IconBack}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'>
              <path
                d='M1.142.5h10v6h-10zM4.152 7l-1.51 2.396M8.142 7l1.6 2.396M6.142 7.5v2'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
          </span>
        );
      case 15:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <path d='M5.455 7.333h9.09M5.455 10h9.09m-9.09 2.667h5.454' stroke='#2D3440' />
            </g>
          </svg>
        );
      case 20:
        return <ExternalIcon />;
      case 4:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(2 2)' />
              <path
                d='M14.16 10.393a.45.45 0 0 0 0-.786l-5.941-3.3a.45.45 0 0 0-.669.393v6.6a.45.45 0 0 0 .669.394z'
                stroke='#2D3440'
              />
            </g>
          </svg>
        );
      case 23:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
            <g fill='none'>
              <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
              <g transform='translate(1.5 2)'>
                <rect fill='#2D3440' x='8' y='11' width='1' height='4' rx='.5' />
                <rect stroke='#2D3440' x='6.5' y='2.5' width='4' height='7' rx='2' />
                <path d='M12.476 7.5H4.524c.222 2.259 1.928 4 3.976 4s3.754-1.741 3.976-4Z' stroke='#2D3440' />
              </g>
            </g>
          </svg>
        );
      case 38:
      case 25:
        return <BarChartOutlined />;
      default:
        break;
    }
  };

  return onlyIcon ? (
    getBadgeContent(noText, type, typeId) || null
  ) : (
    <div
      data-qa='detailCollectionBadgeItem'
      className={classNames(
        className,
        css.TypeBadge,
        { [css.small]: small },
        { [css.portraitPlaceholder]: portraitPlaceholder }
      )}
    >
      {getBadgeContent(noText, type, typeId) || null}
    </div>
  );
};

TypeBadge.propTypes = {
  className: PropTypes.string,
  domainCompany: PropTypes.any,
  name: PropTypes.string,
  noText: PropTypes.bool,
  portraitPlaceholder: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
  typeId: PropTypes.number,
  onlyIcon: PropTypes.bool,
};

const mapStateToProps = createSelector([usersSelect], users => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
}));

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(TypeBadge);
