import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCareerPlan, updateCareerPlan } from '@/store/career_plan/actions';

import { Button, Checkbox, Divider, Flex } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { TASK, TASKS, TOPIC, TOPICS } from '../../constants';
import ActionButtons from '../ActionButtons';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';
import { getDefaultMaterialImage } from '@shared/utils/topic-utils';

import PropTypes from 'prop-types';

import css from '../../CareerPlan.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const TopicItem = ({ topic, currentCompetence, careerPlan, isTask, onEditTask, canEdit }) => {
  const [topicThumb] = useLocalFileByUUID(topic?.thumbFileUuid, {
    defaultImg: getDefaultMaterialImage(topic?.type?.id),
  });
  const { t } = useTranslation('careerPlan');

  const dispatch = useDispatch();
  const [isMobile] = useIsMobile(769);
  const [isMobileTitle] = useIsMobile(1024);

  const checkedItem = topic?.planStatus === FINISHED;

  const onDeleteTopic = (topicId, activeList, competenceId, isTask) => {
    const updatedActiveList = activeList.filter(topic => topic.id !== topicId);

    const correctTopics = updatedActiveList.map(topic => topic.id);

    dispatch(
      updateCareerPlan({ [isTask ? TASKS : TOPICS]: correctTopics, competenceId }, careerPlan?.id, () =>
        dispatch(getCareerPlan())
      )
    );
  };

  const deadlineRender = () => {
    if (topic?.deadline) {
      if (isMobile) {
        return t('deadlineTitleShort', { date: topic?.deadline });
      } else {
        return t('deadlineTitle', { date: topic?.deadline });
      }
    } else {
      return null;
    }
  };

  return (
    <div className={css['CareerPlan-container-competence-content-topic-container']}>
      <div className={css['CareerPlan-container-competence-content-topic-content']}>
        <Link className={css['CareerPlan-container-competence-content-topic']} to={`/collection-topics/${topic.id}`}>
          <Checkbox checked={checkedItem} />
          {!isMobile && (
            <div className={css['CareerPlan-container-competence-content-topic-cover-container']}>
              <img
                className={css['CareerPlan-container-competence-content-topic-cover']}
                src={topicThumb}
                alt='topicThumb'
              />
            </div>
          )}
          <div className={css['CareerPlan-container-competence-content-topic-title']}>
            <p>{isMobileTitle && topic?.name?.length > 90 ? topic?.name.slice(0, 90) + '...' : topic?.name}</p>
            <p>{deadlineRender()}</p>
          </div>
        </Link>
        {canEdit && isMobile && (
          <Button
            onClick={() =>
              onDeleteTopic(topic?.id, currentCompetence?.[isTask ? TASKS : TOPICS], currentCompetence?.id, isTask)
            }
            type='text'
            danger
            icon={<DeleteOutlined />}
          />
        )}
      </div>
      <Flex className={css['CareerPlan-container-competence-content-topic-actionBtn']} align='center' gap={20}>
        <ActionButtons
          type={isTask ? TASK : TOPIC}
          data={topic}
          currentCompetence={currentCompetence}
          careerPlanId={careerPlan?.id}
          onEditTask={onEditTask}
        />
        {/* Удаление */}
        {canEdit && !isMobile && (
          <Button
            onClick={() =>
              onDeleteTopic(topic?.id, currentCompetence?.[isTask ? TASKS : TOPICS], currentCompetence?.id, isTask)
            }
            type='text'
            danger
            icon={<DeleteOutlined />}
          />
        )}
      </Flex>
      {isMobile && <Divider />}
    </div>
  );
};

TopicItem.propTypes = {
  canEdit: PropTypes.bool,
  careerPlan: PropTypes.shape({
    id: PropTypes.number,
  }),
  currentCompetence: PropTypes.shape({
    id: PropTypes.number,
  }),
  isTask: PropTypes.bool,
  onEditTask: PropTypes.func,
  topic: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    thumbFileUuid: PropTypes.string,
    type: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default TopicItem;
