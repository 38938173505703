// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-OmWmrN.user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-gi_iWW .user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-label-IVSK_T[data-value="Montserrat"]:before, .user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-OmWmrN.user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-gi_iWW .user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-item-orbM8X[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-Montserrat-B1JKNo {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header-NM7W_I, .user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header__image-p4yizQ {
  width: 100%;
}

@media (max-width: 1024px) {
  .user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header-NM7W_I {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/BannerUpHeader/BannerUpHeader.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;AACb;;AAUA;EACE;IAPE,aAAa;EACf;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.banner-up-header {\n  width: 100%;\n}\n.banner-up-header__image {\n  width: 100%;\n}\n\n@media (width <= 1024px) {\n  .banner-up-header {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-OmWmrN`,
	"qlPicker": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-OmWmrN`,
	"ql-font": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-gi_iWW`,
	"qlFont": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-gi_iWW`,
	"ql-picker-label": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-label-IVSK_T`,
	"qlPickerLabel": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-label-IVSK_T`,
	"ql-picker-item": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-item-orbM8X`,
	"qlPickerItem": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-picker-item-orbM8X`,
	"ql-font-Montserrat": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-Montserrat-B1JKNo`,
	"qlFontMontserrat": `user-src-components-BannerUpHeader-BannerUpHeader-module__ql-font-Montserrat-B1JKNo`,
	"banner-up-header": `user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header-NM7W_I`,
	"bannerUpHeader": `user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header-NM7W_I`,
	"banner-up-header__image": `user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header__image-p4yizQ`,
	"bannerUpHeaderImage": `user-src-components-BannerUpHeader-BannerUpHeader-module__banner-up-header__image-p4yizQ`
};
export default ___CSS_LOADER_EXPORT___;
