import {
  GET_INFO_EXISTING_MATERIAL_FAIL,
  GET_INFO_EXISTING_MATERIAL_START,
  GET_INFO_EXISTING_MATERIAL_SUCCESS,
  PUT_FINISH_EXISTING_MATERIAL_FAIL,
  PUT_FINISH_EXISTING_MATERIAL_START,
  PUT_FINISH_EXISTING_MATERIAL_SUCCESS,
  PUT_START_EXISTING_MATERIAL_FAIL,
  PUT_START_EXISTING_MATERIAL_START,
  PUT_START_EXISTING_MATERIAL_SUCCESS,
} from './constance';

const initialState = {
  loading: true,
  topic: {},
  blocksCount: 0,
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_INFO_EXISTING_MATERIAL_START:
      return { ...state, loading: true };

    case GET_INFO_EXISTING_MATERIAL_SUCCESS:
      return { ...state, loading: false, topic: response };

    case GET_INFO_EXISTING_MATERIAL_FAIL:
      return { ...state, loading: false, topic: {} };

    case PUT_START_EXISTING_MATERIAL_START:
    case PUT_START_EXISTING_MATERIAL_SUCCESS:
    case PUT_START_EXISTING_MATERIAL_FAIL:
    case PUT_FINISH_EXISTING_MATERIAL_START:
    case PUT_FINISH_EXISTING_MATERIAL_SUCCESS:
    case PUT_FINISH_EXISTING_MATERIAL_FAIL:
      return state;
    default:
      return state;
  }
};
