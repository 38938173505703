/* eslint-disable */
import { GET_CUSTOM_FIELD_FAIL, GET_CUSTOM_FIELD_START, GET_CUSTOM_FIELD_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const getCustomFields = (studentId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/user/${studentId}/custom-fields`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_CUSTOM_FIELD_START, GET_CUSTOM_FIELD_SUCCESS, GET_CUSTOM_FIELD_FAIL],
    onSuccess,
    onError,
  };
};
