import { useTranslation } from 'react-i18next';

import { Button, Modal, Typography } from 'antd';

import PropTypes from 'prop-types';

import css from './ModalWarningForTest.module.scss';

const { Text } = Typography;

const ModalWarningForTest = ({ open, changeVisible }) => {
  const { t } = useTranslation('video');

  const render = () => {
    return (
      <Modal open={open} closable={false} footer={null}>
        <div className={css['Modal']}>
          <Text>{t('warningText')}</Text>
          <Button onClick={() => changeVisible(false)} type='primary' size='large'>
            {t('ok')}
          </Button>
        </div>
      </Modal>
    );
  };

  return render();
};

ModalWarningForTest.propTypes = {
  open: PropTypes.bool.isRequired,
  changeVisible: PropTypes.func.isRequired,
};

export default ModalWarningForTest;
