import { Spin } from 'antd';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import 'react-image-crop/dist/ReactCrop.css';

import defaultImg from '@/pages/Header/PortraitPlaceholder.png';

import css from './styles.module.scss';

const AvatarComponent = props => {
  const [currentAvatar, isLoader] = useLocalFileByUUID(props.user.localPhotoUuid, { defaultImg });

  return (
    <Spin spinning={isLoader}>
      <div
        className={classNames(css['app-profline-avatar__ava'], {
          [css['app-profline-avatar__ava__small']]: props.small,
          [props.className]: !!props.className,
        })}
      >
        <img src={currentAvatar} alt='' />
      </div>
    </Spin>
  );
};

AvatarComponent.propTypes = {
  user: PropTypes.object,
};

export const Avatar = AvatarComponent;
