// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-_0zn3K.user-src-components-CustomEmpty-CustomEmpty-module__ql-font-hdpLw0 .user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-label-HxgF7t[data-value="Montserrat"]:before, .user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-_0zn3K.user-src-components-CustomEmpty-CustomEmpty-module__ql-font-hdpLw0 .user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-item-GfDffq[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-CustomEmpty-CustomEmpty-module__ql-font-Montserrat-jsQJft {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container-Bkhe0t {
  color: #6c737d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
}

.user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_image-pQ6pdu {
  min-height: 60px;
}

@media (min-width: 360px) and (max-width: 768px) {
  .user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_image-pQ6pdu {
    min-height: 40px;
  }
}

.user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_title-JKVCu9 {
  text-align: center;
  text-overflow: ellipsis;
  max-width: 1360px;
  margin: 8px 0;
  font-weight: 550;
  line-height: 24px;
  overflow: hidden;
}

.user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_description-a3VsXN {
  text-align: center;
  max-width: 450px;
  margin-bottom: 8px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/CustomEmpty/CustomEmpty.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,cAAc;EACd,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAMA;EAHE,gBAAgB;AAClB;;AAKA;EACE;IAFE,gBAAgB;EAClB;AACF;;AAIA;EADE,kBAAkB;EAClB,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAGA;EAAE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.CustomEmpty_container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: #6c737d;\n  font-size: 16px;\n}\n.CustomEmpty_container_image {\n  min-height: 60px;\n}\n@media (width >= 360px) and (width <= 768px) {\n  .CustomEmpty_container_image {\n    min-height: 40px;\n  }\n}\n.CustomEmpty_container_title {\n  font-weight: 550;\n  line-height: 24px;\n  margin: 8px 0;\n  text-align: center;\n  max-width: 1360px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.CustomEmpty_container_description {\n  font-weight: 500;\n  text-align: center;\n  margin-bottom: 8px;\n  max-width: 450px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-_0zn3K`,
	"qlPicker": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-_0zn3K`,
	"ql-font": `user-src-components-CustomEmpty-CustomEmpty-module__ql-font-hdpLw0`,
	"qlFont": `user-src-components-CustomEmpty-CustomEmpty-module__ql-font-hdpLw0`,
	"ql-picker-label": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-label-HxgF7t`,
	"qlPickerLabel": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-label-HxgF7t`,
	"ql-picker-item": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-item-GfDffq`,
	"qlPickerItem": `user-src-components-CustomEmpty-CustomEmpty-module__ql-picker-item-GfDffq`,
	"ql-font-Montserrat": `user-src-components-CustomEmpty-CustomEmpty-module__ql-font-Montserrat-jsQJft`,
	"qlFontMontserrat": `user-src-components-CustomEmpty-CustomEmpty-module__ql-font-Montserrat-jsQJft`,
	"CustomEmpty_container": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container-Bkhe0t`,
	"customEmptyContainer": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container-Bkhe0t`,
	"CustomEmpty_container_image": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_image-pQ6pdu`,
	"customEmptyContainerImage": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_image-pQ6pdu`,
	"CustomEmpty_container_title": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_title-JKVCu9`,
	"customEmptyContainerTitle": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_title-JKVCu9`,
	"CustomEmpty_container_description": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_description-a3VsXN`,
	"customEmptyContainerDescription": `user-src-components-CustomEmpty-CustomEmpty-module__CustomEmpty_container_description-a3VsXN`
};
export default ___CSS_LOADER_EXPORT___;
