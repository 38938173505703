/* eslint-disable */
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { getEventParty } from '@/store/study-plan/actions';

import { selectNotifications as notificationsSelect } from '@/store/notifications/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Switch } from 'antd';
import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';

import Input from '../Input';
import Modal from '../Modal';
import ParticipantModalItem from './ParticipantModalItem';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class ParticipantsModal extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    load: PropTypes.func,
    loadMore: PropTypes.func,
    match: PropTypes.object,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    participants: PropTypes.array,
    planStatus: PropTypes.string,
    totalParticipants: PropTypes.number,
    isFav: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      department: '',
      currentUsers: [],
      participantsList: props.participants || [],
    };
  }

  componentDidMount() {
    this.props.load({
      page: 0,
      size: 10,
      isDepartment: this.state.department,
    });
  }

  UNSAFE_componentWillUpdate(newProps, newState) {
    if (newState.searchString !== this.state.searchString) {
      this.props.load({
        page: 0,
        size: 10,
        fullName: newState.searchString,
        isDepartment: this.state.department,
      });
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onChangeSwitch = checked => {
    this.setState({
      department: !!checked,
    });
    this.props.load({
      page: 0,
      size: 10,
      isDepartment: !!checked,
      fullName: this.state.searchString,
    });
  };

  showTotalParticipantsLabel = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  };

  showMessage = () => {
    if (this.state.searchString && this.state.searchString.length > 0) {
      return (
        <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
          {this.props.t('findColleague')} <br />
          {this.props.t('check')}
        </p>
      );
    }
    return (
      <p className={classNames(css['UserItem-list-InfiniteScroll'], css['UserItem-list-InfiniteScroll-notFound'])}>
        {this.props.t('colleagueTopic')}
      </p>
    );
  };

  handleScrollbarUpdate = values => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 100;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t > 1) this.props.loadMore();
  };

  render() {
    const { onCancel, currentUser, participants, t, isFav } = this.props;
    const filterCurrentUsersItems = participants.filter(u => {
      if (u.id !== this.props.currentUser.id) {
        return u;
      }
    });
    const currentUsersItems = filterCurrentUsersItems.map(user => (
      <ParticipantModalItem key={user.id} user={user} getFile={this.props.getFile} />
    ));
    const hasDepartment = currentUser.department && currentUser.department.length > 0;
    const countParticipants = this.props.planStatus ? this.props.totalParticipants - 1 : this.props.totalParticipants;
    return (
      <Modal
        open={this.props.visible}
        onCancel={onCancel}
        hideFooter
        headerFixToModal
        cardOn
        width={600}
        contentClassName={css['App-card-modal']}
      >
        <div className={css['UserItem-shadow-box']}>
          <h3 className={css['UserItem-title']}>
            {t(isFav ? 'favorite' : 'planned', {
              countParticipants,
              coworker: this.showTotalParticipantsLabel(countParticipants, t('colleagues', { returnObjects: true })),
            })}
          </h3>
          <div
            className={classNames(css['UserItem-search'], {
              [css.noMargin]: !hasDepartment,
            })}
          >
            <Input
              onChange={this.handleChange}
              name='searchString'
              value={this.state.searchString}
              dark='true'
              className={css['UserItem-search-input']}
              placeholder={t('search')}
              modificate
            />
          </div>
          {this.props.domainCompany && this.props.domainCompany.hasUserDepartment && hasDepartment && (
            <div>
              <Switch className={css['Switch-input']} onChange={this.onChangeSwitch} />
              <span>{this.props.domainCompany.myDepartmentButtonName}</span>
            </div>
          )}
        </div>
        <div className={css['UserItem-list-box']}>
          <div className={css['UserItem-list']}>
            <Scrollbars
              autoHide
              style={{ width: 575, height: 365, paddingRight: 45 }}
              onUpdate={this.handleScrollbarUpdate}
            >
              {currentUsersItems && currentUsersItems.length > 0 && (
                <div className={css['UserItem-list-InfiniteScroll']}>{currentUsersItems}</div>
              )}
              {currentUsersItems && currentUsersItems.length === 0 && this.showMessage()}
            </Scrollbars>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapActionToProps = {
  getFile,
  getEventParty,
};

const mapStateToProps = createSelector(
  [userTopicsSelect, notificationsSelect, usersSelect],
  (userTopics, notifications, users) => ({
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    currentUser: users.currentUser,
  })
);

export default connect(mapStateToProps, mapActionToProps)(withTranslation('participantsModal')(ParticipantsModal));
