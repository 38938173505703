import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Form } from 'antd';
import LogoCompany from '@/components/LogoCompany/LogoCompany';

import classNames from 'classnames';

import css from './Login.module.scss';

const RegistrationPageSuccess = () => {
  const { navigate, imageBackgroundLogin, imageLogo } = useOutletContext();
  const { t } = useTranslation(['registrationData']);

  const handleChangeRenderPage = flag => {
    localStorage.removeItem('isAuthStarted');
    navigate(`/user-login/${flag}`);
  };

  return (
    <div className={css['App-card-item']}>
      <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
      <Form className={css['LoginPage-form']}>
        <div className={css['LoginPage-form__contentRegistration']}>
          <div className={css['LoginPage-header']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
          </div>
          <div className={css['LoginPage-titleHead']}>{t('lastStep')}</div>
          <div className={css['LoginPage-titleBody']} dangerouslySetInnerHTML={{ __html: t('checkMail') }} />
          <div className={css['LoginPage-label']}>
            <div className={classNames(css.LoginPage__toInfo)} onClick={() => handleChangeRenderPage('auth')}>
              {t('back')}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationPageSuccess;
