import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { getUserDomainCompanyImages } from '@/store/users/actions';
import { getLanguagesFiltered } from '@/store/locales/actions';
import { getCatPoka, getPokaDirSite } from '@/store/poka/actions';
import { getPositions } from '@/store/position/actions';
import { getSearch } from '@/store/search/actions';
import {
  /*getTopicCategories, */
  changeTopicFilter,
  clearTopicFilter,
  // clearTopics,
  getTopicCategoryCompetencies,
  getTopicJobLevels,
  getTopicLevel,
  getTopicMandatory,
  getTopics,
  getTopicTypes,
} from '@/store/topics/actions';

import { selectLocales } from '@/store/locales/selectors';
import { selectPokaSkills } from '@/store/poka/selectors';
import { selectPositions } from '@/store/position/selectors';
import { selectSearchUnion } from '@/store/search/selectors';
import { selectTopics /*selectTopicsCategoriesUnion*/ } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Layout, Pagination, Typography } from 'antd';
import css from '@/pages/CollectionMaterials/CollectionMaterials.module.scss';
import DialogFilterSelectCompetence from '@/pages/CollectionMaterials/DialogFilterSelectCompetence';
import ContextSearch from '@/components/ContextSearch';
import Sort from '@/components/Sort';
import TopicCards from '@/components/TopicCards';

import TopicFilter from '../TopicFilter';

import { parseAllSearchParams } from '@shared/utils/get-query-string';

import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { FILTER_TYPE_COURSES, FILTER_TYPE_EVENTS } from '@/constants/file-types';

const { Content } = Layout;
const { Title } = Typography;

const initialTopicsParams = {
  size: 19,
  page: 1,
  search: '',
  searchName: '',
  skillIds: '',
  sort: 'positions.position,asc&sort=createdDate,desc&sort=id,asc',
  hasEvent: '',
  actual: '',
  startDate: '',
  endDate: '',
};

const initialSearchTopics = {
  authorName: '',
  publisher: '',
  tagName: '',
  skillName: '',
  name: '',
  searchResult: '',
  tagId: '',
  authorId: '',
  skillId: '',
};

const initialTopicFilters = {
  topicTypeId: '',
  typeId: undefined,
  topicLevelId: undefined,
  // topicCategoryName: undefined,
  languageId: undefined,
  free: '',
  hasBlock: '',
  topicSubTypeId: '',
  jobLevelId: null,
  jobFamilyId: null,
  topicCompetenceCategory: undefined,
  topicMandatories: [],
  excludedTopicType: '',
};

const initialAllState = {
  ...initialTopicsParams,
  ...initialSearchTopics,
  ...initialTopicFilters,
};

let controllerTopics = null;

const CollectionMaterials = props => {
  const { t } = useTranslation('collectionMaterials');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    topics,
    level,
    types,
    isLoadingTopicsCatalog,
    topicsTotal,
    topicMandatories,
    jobLevels,
    topicCategoryCompetencies,
    topicFilter,
    topicsPageNumber,
  } = useSelector(selectTopics);

  const searchResults = useSelector(selectSearchUnion);
  // const topicCategories = useSelector(selectTopicsCategoriesUnion);
  const { currentUser } = useSelector(selectUsers);
  const { languagesFiltered } = useSelector(selectLocales);
  const { positions } = useSelector(selectPositions);
  const { pokaCategory, pokaSites } = useSelector(selectPokaSkills);

  const [state, setState] = useState({
    searchingTopics: false,
    global: currentUser?.domainCompany?.global,
    currentTopicId: 0,
    listAllCompetence: [],
    competencesAndCategoriesQuantity: {},
    skillsNames: null,
    restoreFirstRequest: props.location.state?.comeFromHeader,
  });
  const [openDialogFilterSelectCompetence, setOpenDialogFilterSelectCompetence] = useState(false);

  const initialQuerySearchParams = useMemo(() => parseAllSearchParams(document.location.search), []);

  const [queryParams] = useState(initialQuerySearchParams);

  const [topicsParams, setTopicsParams] = useState({
    ...initialAllState,
    ...initialQuerySearchParams,
    ...topicFilter,
  });

  const getSearchDebounced = useCallback(
    _debounce((...args) => {
      dispatch(getSearch(...args));
    }, 400),
    []
  );

  const handleFilterChange = event => {
    setTopicsParams({ ...topicsParams, ...event, page: 1 });
    dispatch(changeTopicFilter(Object.keys(event)[0], Object.values(event)[0]));
  };

  const handleFilterClear = () => {
    const {
      topicCategoryName,
      topicLevelId,
      languageId,
      typeId,
      jobFamilyId,
      topicCompetenceCategory,
      topicSubTypeId,
      topicMandatories,
    } = topicsParams;

    if (
      topicCategoryName !== undefined &&
      topicLevelId !== undefined &&
      languageId !== undefined &&
      typeId !== undefined &&
      jobFamilyId !== undefined &&
      topicCompetenceCategory !== undefined &&
      topicSubTypeId !== undefined &&
      topicMandatories !== undefined
    ) {
      return;
    }
    setTopicsParams(initialAllState);
    dispatch(clearTopicFilter());
    setState(prevState => ({ ...prevState, competencesAndCategoriesQuantity: {} }));
  };

  const changePage = page => {
    window.scrollTo(0, 0);
    setTopicsParams({ ...topicsParams, page });
  };

  const handleChangeSort = sort => {
    setTopicsParams({ ...topicsParams, sort, page: 1, typeId: topicsParams?.typeId });
  };

  const onSearchChange = (value, type) => {
    getSearchDebounced(value, type === 'searchResult' ? '' : type, 'topic');
  };

  const onSearchSubmit = ({ type, value }) => {
    setTopicsParams({ ...topicsParams, ...initialSearchTopics, [type]: value });
  };

  const [selectedItemId, setSelectedItemId] = useState(0);

  const onSearchSuggestClick = (itemId, value, type) => {
    if (!type || type === 'name' || type === 'searchResult') {
      setSelectedItemId(itemId);
    } else {
      setTopicsParams({
        ...topicsParams,
        ...initialSearchTopics,
        searchResult: value,
        [type]: type === 'publisher' ? value : itemId,
      });
    }
  };

  useEffect(() => {
    if (!location.search) {
      setTopicsParams({ ...initialAllState, ...topicFilter });
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedItemId) {
      navigate(`/collection-topics/${selectedItemId}`);
    }
  }, [selectedItemId]);

  const handleSortReset = () => {
    setTopicsParams({ ...topicsParams, sort: initialTopicsParams.sort });
  };

  const getNearestFilterObject = () => {
    switch (topicsParams?.typeId) {
      case FILTER_TYPE_COURSES:
        return [{ name: t('nearest'), value: 'nearEvent,asc' }];
      case FILTER_TYPE_EVENTS:
        return [{ name: t('nearest'), value: 'state,asc&sort=start,asc' }];
      default:
        break;
    }

    return [];
  };

  const closeModalSelectCompetence = () => {
    setOpenDialogFilterSelectCompetence(false);
  };

  const setDataFromModalSelectCompetence = (list, skillsNames) => {
    setState({ ...state, skillsNames });
    closeModalSelectCompetence();
    setTopicsParams({ ...topicsParams, skillIds: list });
  };

  const setListAllCompetence = listAllCompetence => {
    setState({ ...state, listAllCompetence });
  };

  useEffect(() => {
    if (topicsPageNumber) setTopicsParams({ ...topicsParams, page: topicsPageNumber + 1 });
  }, []);

  useEffect(() => {
    if (controllerTopics) controllerTopics.abort();
    controllerTopics = new AbortController();

    dispatch(getTopics(topicsParams, () => {}, controllerTopics.signal));
  }, [topicsParams]);

  useEffect(() => {
    dispatch(getTopicMandatory(false));
    dispatch(getPositions());
    dispatch(getTopicJobLevels());
    dispatch(getTopicCategoryCompetencies());
    dispatch(getCatPoka());
    dispatch(getPokaDirSite());
  }, []);

  useLayoutEffect(() => {
    // dispatch(getUserDomainCompanyImages()); Нужен рефакторинг
    if (languagesFiltered?.length === 0) dispatch(getLanguagesFiltered());
    if (!types) dispatch(getTopicTypes());
    if (!level) dispatch(getTopicLevel());
    if (!topicMandatories) dispatch(getTopicMandatory(false));
    if (!jobLevels) dispatch(getTopicJobLevels());
    if (!positions) dispatch(getCatPoka());
    if (!pokaCategory) dispatch(getPokaDirSite());
    if (!pokaSites) dispatch(getPokaDirSite());
    // в компаннте ModalFlower дергается эта ручка getTopicCategories закомментировал чтоб не дублировался код
    // if (!topics) dispatch(getTopicCategories(currentUser?.domainCompany?.global));
    // return () => dispatch(clearTopics());
  }, []);

  const isActiveFilterSkillIds = Boolean(
    topicsParams.skillIds && Array.isArray(topicsParams.skillIds) && topicsParams.skillIds.length > 0
  );

  const isFilterNotEmpty =
    topicsParams &&
    (topicsParams.name ||
      topicsParams.authorName ||
      topicsParams.publisher ||
      topicsParams.tagName ||
      topicsParams.authorId ||
      topicsParams.tagId ||
      topicsParams.skillId ||
      isActiveFilterSkillIds ||
      topicsParams.skillName ||
      topicsParams.searchResult);

  const labelFilter = isFilterNotEmpty
    ? topicsParams.authorName || topicsParams.authorId
      ? t('author')
      : topicsParams.tagName || topicsParams.tagId
        ? t('tag')
        : topicsParams.publisher
          ? t('company')
          : topicsParams.name
            ? t('name')
            : topicsParams.skillName || topicsParams.skillId || isActiveFilterSkillIds
              ? t('skill')
              : topicsParams.searchResult
                ? ''
                : ''
    : null;

  const searchTypes = [
    { id: '', type: 'searchResult', searchType: 'searchResult', tagType: 'searchResult', name: t('all') },
    { id: 1, type: 'name', searchType: 'TOPIC', tagType: 'name', name: t('selectName') },
    { id: 2, type: 'authorName', searchType: 'AUTHOR', tagType: 'authorId', name: t('selectAuthor') },
    { id: 3, type: 'publisher', searchType: 'PUBLISHER', tagType: 'publisher', name: t('selectCompany') },
    { id: 4, type: 'tagName', searchType: 'TAG', tagType: 'tagId', name: t('selectTag') },
    { id: 5, type: 'skillName', searchType: 'SKILL', tagType: 'skillId', name: t('selectSkillComptences') },
  ];

  const showStartSearch = topicsParams.typeId === 1 || topicsParams.typeId === 2;

  const sortTypes = [
    {
      name: t('popularity'),
      dataQa: 'popularity',
      value: 'positions.position,asc&sort=topicShortTermPopularities.shortTermPopularity,desc&sort=id,asc',
    },
    {
      name: t('rating'),
      dataQa: 'rating',
      value: 'rates.rate,desc&sort=id,asc',
    },
    ...(showStartSearch ? getNearestFilterObject() : []),
    {
      name: t('date'),
      dataQa: 'date',
      value: 'positions.position,asc&sort=createdDate,desc&sort=id,asc',
    },
  ];

  const labelActualSearchType = topicsParams?.searchResult || '';

  const labelSearchText =
    labelActualSearchType.length > 20 ? `${labelActualSearchType.substring(0, 20)}... ` : labelActualSearchType;

  const filterMandatories = topicMandatories?.map(item => ({ name: item.name, value: item.value }));
  const optTopicCompCat = topicCategoryCompetencies?.map(item => ({ name: item.name, value: item.value }));
  const filterPositions = positions?.content?.filter(item => item.name.toLowerCase() !== 'leadership');
  const filterPokaSites = pokaSites?.map(item => ({ name: item.label, value: item.value }));
  const filterPokaCategory = pokaCategory?.map(item => ({ name: item.name, value: item.value }));

  return (
    <Content className={css['App-content']}>
      <div className={css['CollectionMaterials-header-row']}>
        <div className={css['CollectionMaterials-header-text']}>
          <Title level={1}>{t('collection')}</Title>
        </div>

        <div className={css['MyCollection-header-search']}>
          <ContextSearch
            dataQa='collectionTopicSearch'
            navigate={navigate}
            onChange={onSearchChange}
            onSearchSubmit={onSearchSubmit}
            onSearchSuggestClick={onSearchSuggestClick}
            queryParams={queryParams}
            searchResults={searchResults}
            searchTypes={searchTypes}
            isFiltered={isFilterNotEmpty}
            initialQuerySearchParams={initialQuerySearchParams}
          />
        </div>
      </div>
      <TopicFilter
        filter={{
          ...topicsParams,
          filterMandatories,
          positions,
          jobLevels,
          filterPokaCategory,
          filterPokaSites,
        }}
        competencesAndCategoriesQuantity={args => setState({ ...state, competencesAndCategoriesQuantity: args })}
        // topicCategories={topicCategories}
        topicLevel={level}
        onChange={handleFilterChange}
        onClear={handleFilterClear}
        onSortReset={handleSortReset}
        domainCompany={currentUser.domainCompany}
        currentUser={currentUser}
        myCollection={false}
        topicLanguages={languagesFiltered}
        openModalSelectCompetence={() => setOpenDialogFilterSelectCompetence(true)}
        filterPositions={filterPositions}
        jobLevels={jobLevels}
        showAllCategory
        optTopicCompCat={optTopicCompCat}
      />
      {/* <div className={css['CollectionMaterials-sort-block-sortBy']}>{t('sortBy')}</div> */}
      {!isLoadingTopicsCatalog && topics?.length > 0 && (
        <div className={css['CollectionMaterials-sort-block']}>
          <Sort
            activeSort={topicsParams.sort}
            isFilterEmpty={isFilterNotEmpty}
            isLoading={isLoadingTopicsCatalog}
            labelFilter={labelFilter}
            labelSearchText={labelSearchText}
            labelSkillsText={state.skillsNames}
            topicAmount={topicsTotal}
            handleChangeSort={handleChangeSort}
            sortTypes={sortTypes}
          />
        </div>
      )}

      <TopicCards
        isLoading={isLoadingTopicsCatalog}
        topics={topics}
        isAddMaterialButton
        isFilterNotEmpty={isFilterNotEmpty}
        searchText={topicsParams.searchResult}
      />

      {!isLoadingTopicsCatalog && topics?.length > 0 && (
        <Pagination
          current={topicsParams.page}
          total={topicsTotal}
          pageSize={initialTopicsParams.size}
          onChange={changePage}
          showSizeChanger={false}
          className={css['CollectionMaterials-pagination']}
          responsive
          hideOnSinglePage
        />
      )}

      <DialogFilterSelectCompetence
        filter={topicsParams}
        setCompetencesAndCategoriesQuantity={args => setState({ ...state, competencesAndCategoriesQuantity: args })}
        open={openDialogFilterSelectCompetence}
        onCancel={closeModalSelectCompetence}
        onSetSearch={setDataFromModalSelectCompetence}
        onSetListAllCompetence={setListAllCompetence}
        referenceCheckedKeys={state.competencesAndCategoriesQuantity} //Решил костылём сброс выбранных компетенций
      />
    </Content>
  );
};

CollectionMaterials.propTypes = {
  isLoadingTopicsCatalog: PropTypes.bool,
  topics: PropTypes.array,
  topicsTotal: PropTypes.number,
};

export default CollectionMaterials;
