export const filterItems = [
  {
    name: 'filters.needToAgree',
    statuses: [
      'WAITING_MENTEE',
      'WAITING_MENTOR',
      'WAITING_MENTEE_MEETING',
      'WAITING_MENTOR_MEETING',
      'WAITING_MENTOR_DECISION',
      'WAITING_MENTEE_DECISION',
    ],
  },
  {
    name: 'filters.active',
    statuses: ['ACTIVE'],
  },
  {
    name: 'filters.completed',
    statuses: ['FINISHED', 'WAITING_RATING'],
  },
  {
    name: 'filters.rejected',
    statuses: ['REJECTED_BY_MENTOR', 'REJECTED_BY_MENTEE'],
  },
];

export const status = {
  WAITING_MENTOR: 'WAITING_MENTOR',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  REJECTED_BY_MENTOR: 'REJECTED_BY_MENTOR',
  REJECTED_BY_MENTEE: 'REJECTED_BY_MENTEE',
};

export const modalType = {
  REVIEW_REQUEST: 'REVIEW_REQUEST',
  REJECT_CONFIRM: 'REJECT_CONFIRM',
  FINISH_CONFIRM: 'FINISH_CONFIRM',
  REVIEW_CONFIRM: 'REVIEW_CONFIRM',
  RESULTS: 'RESULTS',
};
