/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'antd';

import CompetenciesTagList from '../Competencies/CompetenciesTagList';
import Tag from '../Tag';

import PropTypes from 'prop-types';

import css from './MaterialsTabs.module.scss';

const { Content } = Layout;

const MaterialsTabsAbout = ({ topic: { competences, skills, description } }) => {
  const { t } = useTranslation('materialsTabsAbout');

  return (
    <Content className={css.MaterialsTabsAbout}>
      {skills.length > 0 && (
        <>
          <div className={css.MaterialsTabsAbout__tags}>
            <div className={css.MaterialsTabsAbout__label}>{t('competencies')}</div>
            <span>
              <CompetenciesTagList competencies={competences} />
            </span>
          </div>
          <div className={css.MaterialsTabsAbout__tags}>
            <div className={css.MaterialsTabsAbout__label}>{t('skills')}</div>
            <span data-qa='userCollectionSkillItem'>
              <SkillsList skills={skills} />
            </span>
          </div>
        </>
      )}
      <div data-qa='userCollectionDescriptionItem' className={css['MaterialsTabsAbout-container']}>
        {description}
      </div>
    </Content>
  );
};

MaterialsTabsAbout.propTypes = {
  topic: PropTypes.object,
};

const SkillsList = ({ skills }) => skills.map(skill => <Tag skills key={skill.id} tag={skill} sliceCount={35} />);

export default MaterialsTabsAbout;
