import React from 'react';

import CheckList from '@/pages/CollectionMaterialsIDStudy/CheckList/CheckList';
import Condition from '@/components/Condition';
import CustomEmpty from '@/components/CustomEmpty';

import { Article } from '../article';
import ExistingMaterial from '../ExistingMaterial/ExistingMaterial';
import PokaFiles from '../PokaFiles';
import Presentation from '../Presentation/Presentation';
import { Scorm } from '../scorm';
import { Test } from '../test';
import Tilda from '../tilda';
import { Typeform } from '../typeform';
import { Video } from '../video';

import css from '../CollectionMaterialsIDStudy.module.scss';

const Blocks = ({
  currentTest,
  currentUser,
  getStatistic,
  getBlockStatistic,
  topicId,
  updateChaptersIDB,
  removeChaptersFromIDB,
  reviewModalVisible,
  canRestartBlocks,
  studyPlan,
  getStatisticStart,
}) => (
  <>
    {currentTest.blockType ? (
      <>
        <Condition when={currentTest.blockType.id === 1}>
          <Test
            key={`test-${currentTest.id}`}
            topicId={topicId}
            currentTest={currentTest}
            currentUser={currentUser}
            getStatistic={getStatistic}
            getBlockStatistic={getBlockStatistic}
            canRestartBlocks={canRestartBlocks}
            getStatisticStart={getStatisticStart}
          />
        </Condition>
        <Condition when={currentTest.blockType.id === 2}>
          <Scorm
            key={`scorm-${currentTest.id}`}
            getStatistic={getStatistic}
            block={currentTest}
            topicId={topicId}
            currentUser={currentUser}
            getBlockStatistic={getBlockStatistic}
            canRestartBlocks={canRestartBlocks}
            getStatisticStart={getStatisticStart}
          />
        </Condition>
        <Condition when={currentTest.blockType.id === 3}>
          <Video
            key={`video-${currentTest.id}`}
            getStatistic={getStatistic}
            block={currentTest}
            updateChaptersIDB={updateChaptersIDB}
            removeChaptersFromIDB={removeChaptersFromIDB}
          />
        </Condition>
        <Condition when={currentTest.blockType.id === 4}>
          <Presentation
            key={`presentation-${currentTest.id}`}
            getStatistic={getStatistic}
            block={currentTest}
            updateChaptersIDB={updateChaptersIDB}
            removeChaptersFromIDB={removeChaptersFromIDB}
            reviewModalVisible={reviewModalVisible}
            getStatisticStart={getStatisticStart}
          />
        </Condition>

        <Condition when={currentTest.blockType.id === 5}>
          <Article
            key={`article-${currentTest.id}`}
            getStatistic={getStatistic}
            block={currentTest}
            updateChaptersIDB={updateChaptersIDB}
            removeChaptersFromIDB={removeChaptersFromIDB}
            getStatisticStart={getStatisticStart}
          />
        </Condition>
        <Condition when={currentTest.blockType.id === 6}>
          <Typeform
            key={`typeform-${currentTest.id}`}
            getStatistic={getStatistic}
            block={currentTest}
            getStatisticStart={getStatisticStart}
          />
        </Condition>

        <Condition when={currentTest.blockType.id === 7}>
          <Tilda currentUser={currentUser} getStatistic={getStatistic} block={currentTest} />
        </Condition>

        <Condition when={currentTest.blockType.id === 8}>
          <ExistingMaterial block={currentTest} getStatistic={getStatistic} getStatisticStart={getStatisticStart} />
        </Condition>

        <Condition when={currentTest.blockType.id === 9}>
          <CheckList
            block={currentTest}
            studyPlan={studyPlan}
            getStatistic={getStatistic}
            getStatisticStart={getStatisticStart}
          />
        </Condition>

        <Condition when={currentTest.blockType.id === 10}>
          <PokaFiles block={currentTest} getStatistic={getStatistic} getStatisticStart={getStatisticStart} />
        </Condition>
      </>
    ) : (
      <CustomEmpty className={css['themesEmpty']} />
    )}
  </>
);

export default Blocks;
