import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';

import { eventManagerCourses } from '@/store/course/actions';

import { Button, Input, Modal } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './SendReviewModal.module.scss';

const { TextArea } = Input;

const SendRefuseModal = () => {
  const { t } = useTranslation('sendRefuseModal');
  const { rejectCode, showModal } = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [comment, setComment] = useState('');

  const handleChangeComment = event => {
    setComment(event?.target?.value);
  };

  const handleSendReview = () => {
    dispatch(
      eventManagerCourses(
        rejectCode,
        comment,
        () => showModal(true),
        () => showModal(false)
      )
    );
  };

  useLayoutEffect(() => {
    if (!rejectCode) {
      navigate('/');
    }
  }, []);

  return (
    <Modal open width={604} footer={null} zIndex={1005}>
      <div className={classNames(css.SendReviewModal)}>
        <div className={css['SendReviewModal-header']}>
          <p>{t('reason')}</p>
          <p className={css['SendReviewModal-header-description']}>{t('reasonEmployee')}</p>
        </div>
        <TextArea
          className={css['SendReviewModal-textarea']}
          rows={7}
          placeholder={t('placeholder')}
          onChange={handleChangeComment}
          value={comment}
          maxLength={500}
        />
        <Button onClick={handleSendReview} disabled={!comment} className={css['SendReviewModal-but']} type='primary'>
          {t('reject')}
        </Button>
      </div>
    </Modal>
  );
};

SendRefuseModal.propTypes = {
  rejectCode: PropTypes.string,
  showConfirmErrorModal: PropTypes.func,
  showRejectSuccessModal: PropTypes.func,
};

export default SendRefuseModal;
