import {
  GET_EXECUTIVE_TASK_FAIL,
  GET_EXECUTIVE_TASK_START,
  GET_EXECUTIVE_TASK_SUCCESS,
  GET_EXECUTIVE_TOPIC_INFO_FAIL,
  GET_EXECUTIVE_TOPIC_INFO_START,
  GET_EXECUTIVE_TOPIC_INFO_SUCCESS,
  POST_EXECUTIVE_TASK_FAIL,
  POST_EXECUTIVE_TASK_START,
  POST_EXECUTIVE_TASK_SUCCESS,
  PUT_EXECUTIVE_TASK_FAIL,
  PUT_EXECUTIVE_TASK_START,
  PUT_EXECUTIVE_TASK_SUCCESS,
  PUT_EXECUTIVE_TOPIC_INFO_FAIL,
  PUT_EXECUTIVE_TOPIC_INFO_START,
  PUT_EXECUTIVE_TOPIC_INFO_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const defaultToken = '';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const getExecutiveTopicInfo = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/executive/${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_EXECUTIVE_TOPIC_INFO_START, GET_EXECUTIVE_TOPIC_INFO_SUCCESS, GET_EXECUTIVE_TOPIC_INFO_FAIL],
  onSuccess,
  onError,
});

export const setExecutiveTask = (studyPlanId, answer, fileUuids, pokaAssessorId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive/review`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    fileUuids,
    answer,
    studyPlanId,
    pokaAssessorId,
  },
  types: [POST_EXECUTIVE_TASK_START, POST_EXECUTIVE_TASK_SUCCESS, POST_EXECUTIVE_TASK_FAIL],
  onSuccess,
  onError,
});

export const getExecutiveTask = (studyPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/executive/${studyPlanId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_EXECUTIVE_TASK_START, GET_EXECUTIVE_TASK_SUCCESS, GET_EXECUTIVE_TASK_FAIL],
  onSuccess,
  onError,
});

export const updateExecutiveTask = (studyPlanId, answer, fileUuids, pokaAssessorId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/executive/review`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    fileUuids,
    answer,
    studyPlanId,
    pokaAssessorId,
  },
  types: [PUT_EXECUTIVE_TASK_START, PUT_EXECUTIVE_TASK_SUCCESS, PUT_EXECUTIVE_TASK_FAIL],
  onSuccess,
  onError,
});

export const createExecutiveTopicInfo = body => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/executive`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [POST_EXECUTIVE_TASK_START, POST_EXECUTIVE_TASK_SUCCESS, POST_EXECUTIVE_TASK_FAIL],
});

export const updateExecutiveTopicInfo = (data, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/executive`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  types: [PUT_EXECUTIVE_TOPIC_INFO_START, PUT_EXECUTIVE_TOPIC_INFO_SUCCESS, PUT_EXECUTIVE_TOPIC_INFO_FAIL],
  onSuccess,
  onError,
});
