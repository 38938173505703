/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Tooltip } from 'antd';
import EventCost from '@/components/EventCost';
import { ExclamationCircleFilled } from '@ant-design/icons';

import {
  isConfOrAllCourse,
  isCorpCourseWithOrWithoutApprove,
  isCorpOrExtCourseOrConf,
  isEvent,
  isExternalCourse,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

class EventRow extends Component {
  static propTypes = {
    addToPlan: PropTypes.func,
    event: PropTypes.object,
    eventDisabled: PropTypes.bool,
    isInModal: PropTypes.bool,
    openDate: PropTypes.string,
    participate: PropTypes.bool,
    selected: PropTypes.bool,
    setParams: PropTypes.func,
    topicStatus: PropTypes.string,
    topicType: PropTypes.number,
    currentUser: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.openDate = dayjs(props.openDate);
    this.workDate = !this.openDate || dayjs().format('YYYY-MM-DD');
    this.state = {
      startDate: dayjs(this.workDate, 'YYYY-MM-DD'),
      inProgress:
        dayjs(props.event.endTime, 'YYYY-MM-DD') <=
        dayjs(this.workDate, 'YYYY-MM-DD') >=
        dayjs(props.event.startTime, 'YYYY-MM-DD'),
      correctStartDate: null,
      correctEndDate: null,
      loaded: false,
    };
  }

  getTimeFromMins = mins => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;
    if (hours === 0 && hours === 0) {
      return `${minutes} мин.`;
    }
    if (minutes > 0 && hours !== 0) {
      return `${hours} ${noun(
        hours,
        this.props.t('hour1'),
        this.props.t('hour2'),
        this.props.t('hour3')
      )} ${minutes} ${this.props.t('minutes')}`;
    }
    return `${hours} ${noun(hours, this.props.t('hour1'), this.props.t('hour2'), this.props.t('hour3'))}`;
  };

  componentDidMount() {
    if (this.props.event && this.props.currentUser && !this.state.loaded) {
      const hoursOffset =
        this.props.currentUser.timezone.offset / 60 - this.props.currentUser.domainCompany.timezone.offset / 60;

      this.setState({
        correctStartDate: dayjs(this.props.event.startTime).add(hoursOffset, 'H'),
        correctEndDate: dayjs(this.props.event.endTime).add(hoursOffset, 'H'),
        loaded: true,
      });
    }
  }

  renderInfo = (topicType, duration) => {
    if (!isCorpCourseWithOrWithoutApprove(topicType)) {
      return null;
    }
    return this.getTimeFromMins(duration);
  };

  render() {
    const { event, isInModal, selected, topicType, eventDisabled } = this.props;

    const isPossiblySignUp =
      event.placeCount > 0 || (event.placeCount === 0 && event.approvedCount + event.registerCount === 0);

    const isManyEvents = Array.isArray(event?.periods) && event?.periods?.length > 1;

    const eventExpiredDate = dayjs(event?.deadlineRegistration).diff(dayjs(), 'day') < 0;

    const isConferenceFull = event?.placeCount === 0 && isConfOrAllCourse(topicType);

    const actionButtons =
      !event.active || isConferenceFull ? null : (
        <Button.Group className={css['App-btn-group']}>
          <Button
            className={css['App-btn']}
            onClick={() => this.props.addToPlan(event.startTime)}
            type='primary'
            disabled={this.props.topicStatus === 'DISABLED'}
            size='large'
          >
            {this.props.t('singUp')}
          </Button>
        </Button.Group>
      );

    const isInModalNotManyEventsStr = `${dayjs(event.periods[0].startTime, 'HH:mm:ss').format('HH:mm')} - ${dayjs(
      event.periods[0].endTime,
      'HH:mm:ss'
    ).format('HH:mm')}`;

    const isNotManyEventsContent =
      dayjs(event.startTime, 'D MMM') !== dayjs(event.endTime, 'D MMM')
        ? `${dayjs(this.state.correctStartDate).format('D MMM')}
            - ${dayjs(this.state.correctEndDate).format('D MMM')}`
        : dayjs(this.state.correctStartDate).format('D MMM');

    const cost = event?.cost ? (
      <div className={css['eventRow__col-cost']}>
        <EventCost initialCost={event?.cost} currentUser={this.props.currentUser} />
      </div>
    ) : null;

    const showTooltipFlag = event.placeCount === 0 || eventExpiredDate;

    return (
      <div
        className={classNames(
          css.eventRow,
          { [css.isEnded]: event.ended },
          {
            [css.isFull]:
              !event.ended &&
              (!isPossiblySignUp ||
                this.state.startDate > dayjs(this.props.event.startTime, 'YYYY-MM-DD') ||
                this.state.inProgress ||
                !event.active),
          },
          { [css.isGrey]: isInModal },
          { [css.isParticipate]: event.participate },
          { [css.selected]: selected }
        )}
        {...(this.props.setParams && {
          onClick: () => this.props.setParams(event.id, event.startTime, event),
        })}
      >
        {!isManyEvents ? (
          <div className={css.eventRow__col}>
            {event.participate && (
              <div className={css['participate-icon']}>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
                  <path
                    fill='#FF8C00'
                    d='M4.148 7.883a1.016 1.016 0 0 0-1.452.01 1.055 1.055 0 0 0 .01 1.476l3.127 3.13a1.016 1.016 0 0 0 1.476-.035l6.819-7.513a1.055 1.055 0 0 0-.059-1.475 1.016 1.016 0 0 0-1.452.06l-6.098 6.72-2.37-2.373z'
                  />
                </svg>
              </div>
            )}
            {isNotManyEventsContent}
          </div>
        ) : (
          // Генерация дат при множестве периодов
          <div className={css.eventRow__col}>
            {event.periods.map(period => {
              const lastDate = period.dates[period.dates.length - 1];
              const lastDateString = lastDate ? dayjs(lastDate).format('DD MMM') : '';

              return period.dates.map((date, dateIndex) => {
                const dateString = `${dayjs(date).format('DD MMM')} - ${lastDateString}`;

                return dateIndex === 0 && <p key={dateIndex + date}>{dateString}</p>;
              });
            })}
          </div>
        )}

        {/* Генерация времени если период в событии */}
        {!isManyEvents && isInModal && (
          <div className={classNames(css.eventRow__col, css.eventRow__col_end)}>
            {(isEvent(topicType) || isCorpOrExtCourseOrConf(topicType)) && isInModalNotManyEventsStr}
          </div>
        )}
        {/* Генерация стоимости мероприятия */}
        {cost}
        {/* Генерация времени если множество периодов в событии */}
        {isManyEvents && isInModal && (
          <div className={classNames(css.eventRow__col, css.eventRow__col_end)}>
            {(isEvent(topicType) || isCorpOrExtCourseOrConf(topicType)) &&
              event.periods.map((period, index) => {
                const dateStringFrom = dayjs(
                  Utils.timezoneCounter(dayjs(period.startTime, 'HH:mm:ss'), this.props.currentUser)
                ).format('HH:mm - ');

                const dateStringTo = dayjs(
                  Utils.timezoneCounter(dayjs(period.endTime, 'HH:mm:ss'), this.props.currentUser)
                ).format('HH:mm');

                return (
                  <div key={index}>
                    {dateStringFrom}
                    {dateStringTo}
                  </div>
                );
              })}
          </div>
        )}
        {!isExternalCourse(topicType)
          ? !isInModal && (
              <div className={css.eventRow__col}>{event.duration && this.getTimeFromMins(event.duration)}</div>
            )
          : null}
        {!isInModal && (
          <div className={css.eventRow__col}>
            {!isInModal
              ? event.active
                ? !event.ended
                  ? isPossiblySignUp && !eventDisabled
                    ? this.props.t('groupsSet')
                    : this.props.t('groupDone')
                  : this.props.t('courseEnd')
                : event.ended
                  ? this.props.t('courseEnd')
                  : this.props.t('groupDone')
              : this.renderInfo(topicType, this.props.event.duration)}
          </div>
        )}
        {!isInModal && !event.ended && (
          <div className={classNames(css.eventRow__col, css.eventRow__col_btn)}>
            {!event.ended && !eventDisabled && !!actionButtons ? actionButtons : null}
          </div>
        )}
        {showTooltipFlag && (
          <Tooltip title={this.props.t('tooltipLabel')} zIndex={10001} className={css['eventRow__tooltip']}>
            <ExclamationCircleFilled style={{ color: '#FFB02E', marginLeft: '5px' }} />
          </Tooltip>
        )}
      </div>
    );
  }
}

export default withTranslation('eventRow')(EventRow);
