import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUsers } from '@/store/users/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Alert, Button, Col, Flex, Input, Layout, Row, Switch, Tabs, Typography } from 'antd';

import ViewDefault from './views/default';
import ViewDepartment from './views/department';
import ViewFunction from './views/function';
import ViewPosition from './views/position';

import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './index.module.scss';

const { Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const mobileWidth = 768;

export class Collegues extends Component {
  static propTypes = {
    t: PropTypes.func,
    domainCompany: PropTypes.object,
    isLoading: PropTypes.bool,
    usersList: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentView: 'default',
      hasDepartment: false,
      txtDepartment: '',
      hasPosition: false,
      txtPosition: '',
      hasFunction: false,
      txtFunction: '',
      searchString: '',
      isMyDepartment: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    const { domainCompany } = this.props;

    if (domainCompany) {
      if (
        _.has(domainCompany, 'hasUserDepartment') &&
        _.has(domainCompany, 'departmentSingleNameImenit') &&
        domainCompany.hasUserDepartment === true &&
        domainCompany.departmentSingleNameImenit !== null
      ) {
        this.setState({
          hasDepartment: true,
          txtDepartment: this.capitalizeFirstLetter(domainCompany.departmentSingleNameImenit),
        });
      }

      if (
        _.has(domainCompany, 'hasUserPosition') &&
        _.has(domainCompany, 'positionSingleNameImenit') &&
        domainCompany.hasUserPosition === true &&
        domainCompany.positionSingleNameImenit !== null
      ) {
        this.setState({
          hasPosition: true,
          txtPosition: this.capitalizeFirstLetter(domainCompany.positionSingleNameImenit),
        });
      }

      if (
        _.has(domainCompany, 'hasUserFunction') &&
        _.has(domainCompany, 'functionSingleNameImenit') &&
        domainCompany.hasUserFunction === true &&
        domainCompany.functionSingleNameImenit !== null
      ) {
        this.setState({
          hasFunction: true,
          txtFunction: this.capitalizeFirstLetter(domainCompany.functionSingleNameImenit),
        });
      }
    }
    window.addEventListener('resize', this.updateMobileState);
  }

  updateMobileState = () => this.setState({ isMobile: window.innerWidth < mobileWidth });

  renderAlertText = () => {
    return <span>{this.props.t('descriptionStatistic')}</span>;
  };

  capitalizeFirstLetter(string) {
    if (string && string !== null) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return string;
  }

  onChangeView = value => {
    switch (value) {
      case 'department':
      case 'position':
      case 'function':
        this.setState({ currentView: value, searchString: '' });
        break;
      default:
        this.setState({ currentView: 'default', searchString: '' });
        break;
    }
  };

  onSearch = value => {
    this.props.getUsers({
      flexibleName: value,
      isDepartment: this.state.isMyDepartment,
      status: 'ACTIVE',
      withRewards: this.props.domainCompany?.hasCoins,
    });
  };

  onChange = value => {
    if (value.length) {
      this.setState({ searchString: value });
    } else {
      this.setState({ searchString: '' });
    }
  };

  setIsMyDepartment = e => {
    this.setState({ isMyDepartment: e });
    this.props.getUsers({
      isDepartment: e,
      flexibleName: this.state.searchString,
      status: 'ACTIVE',
    });
  };

  render() {
    const {
      currentView,
      hasDepartment,
      hasPosition,
      hasFunction,
      searchString,
      txtDepartment,
      txtPosition,
      txtFunction,
    } = this.state;

    const { isLoading, t, domainCompany } = this.props;
    const { hasCoins } = domainCompany;

    const itemTabs = [
      ...(hasDepartment || hasPosition || hasFunction
        ? [
            {
              key: 'default',
              label: t('defaultView'),
            },
          ]
        : []),

      ...(hasDepartment
        ? [
            {
              key: 'department',
              label: domainCompany.departmentSingleNameImenit && txtDepartment,
            },
          ]
        : []),

      ...(hasPosition
        ? [
            {
              key: 'position',
              label: domainCompany.positionSingleNameImenit && txtPosition,
            },
          ]
        : []),

      ...(hasFunction
        ? [
            {
              key: 'function',
              label: domainCompany.functionSingleNameImenit && txtFunction,
            },
          ]
        : []),
    ];

    const goToAdminPage = redirect => {
      let DOMAIN = 'https://' + this.props.domainCompany.adminDomain;
      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        DOMAIN = 'http://localhost:3001';
      }
      const URL = `${DOMAIN}/login-through?accessToken=${localStorage.getItem('authorizationToken')}&redirect=${redirect}`;
      window.open(URL, '_blank');
    };

    return (
      <Content className={css['App-content']}>
        <div>
          <Title level={1} className={css['title']}>
            {t('colleagues')}
          </Title>
        </div>

        <Flex wrap gap={'small'}>
          <Button onClick={() => goToAdminPage('/users')}>{t('myTeam')}</Button>
          <Button onClick={() => goToAdminPage('/manage-course')}>{t('approvalOfApplications')}</Button>
          <Button onClick={() => goToAdminPage('/logging')}>{t('learningStatistics')}</Button>
        </Flex>

        <Row align='middle' className={css['block']}>
          <Col flex={3} xl={13} md={24}>
            <Search
              placeholder={t('placeholderSearch')}
              className={'smallSizeSearchButton'}
              onSearch={this.onSearch}
              onChange={e => this.onChange(e.target.value)}
              enterButton
              allowClear
              value={searchString}
              disabled={currentView !== 'default'}
              size='large'
            />
          </Col>
          <Col flex={1} xl={4} lg={10} md={24} offset={1} className={css['blockSwitch']}>
            {domainCompany.hasUserDepartment && (
              <>
                <Switch
                  disabled={currentView !== 'default'}
                  checked={this.state.isMyDepartment}
                  defaultChecked={this.state.isMyDepartment}
                  onClick={e => this.setIsMyDepartment(e)}
                  className={css['switch']}
                />
                {t('myDepartment')} {txtDepartment}
              </>
            )}
          </Col>

          <Col flex={1} xl={9} lg={12}>
            <Tabs defaultActiveKey='default' onChange={this.onChangeView} items={itemTabs} />
          </Col>

          {currentView !== 'default' && (
            <Col className={css['descriptionStatistic']} flex={5}>
              <Alert message={this.renderAlertText()} type='warning' closable />
            </Col>
          )}
        </Row>

        {currentView && currentView === 'department' && (
          <ViewDepartment
            t={t}
            loading={isLoading}
            hasCoins={hasCoins}
            hasDepartment={hasDepartment}
            txtDepartment={txtDepartment}
          />
        )}

        {currentView && currentView === 'position' && (
          // НУЖНО ПЕРЕПИСАТЬ ВЬЮШКИ ПОЗИЦИИ И ФУНКЦИИ
          // <ViewPosition t={t} loading={isLoading} hasPosition={hasPosition} />
          <ViewFunction
            t={t}
            loading={isLoading}
            hasFunction={hasFunction}
            hasCoins={hasCoins}
            txtPosition={txtPosition}
          />
        )}

        {currentView && currentView === 'function' && (
          // <ViewFunction t={t} loading={isLoading} hasFunction={hasFunction} />
          <ViewPosition
            t={t}
            loading={isLoading}
            hasPosition={hasPosition}
            hasCoins={hasCoins}
            txtFunction={txtFunction}
          />
        )}

        {currentView && currentView === 'default' && (
          <ViewDefault
            t={t}
            loading={isLoading}
            hasDepartment={hasDepartment}
            hasPosition={hasPosition}
            hasFunction={hasFunction}
            hasCoins={hasCoins}
            searchString={searchString}
            txtDepartment={txtDepartment}
            txtPosition={txtPosition}
            txtFunction={txtFunction}
            isMyDepartment={this.state.isMyDepartment}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = createSelector([usersSelect], users => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  usersList: users.usersListConcat,
  isLoading: users.isLoading,
}));

const mapActionsToProps = { getUsers };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('colleague')(Collegues));
