import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createTheme, deleteTheme, getThemes, updateTheme } from '@/store/compilations/actions';

import { selectCompilations as compilationsList } from '@/store/compilations/selectors';
import { selectSearch as searchList } from '@/store/search/selectors';
import { selectUsers as usersList } from '@/store/users/selectors';

import { /*Button,*/ Input, Radio, Spin, Tree } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';

import TreeNodeTitle from './TreeNodeTitle';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './ThemesEditor.module.scss';

const { Search } = Input;

class ThemesEditor extends Component {
  constructor(props) {
    super(props);
    const selectedThemeIds = props.themeId || [];
    this.state = {
      isVisible: false,
      isAddTheme: false,
      newThemeName: '',
      isEdit: false,
      errors: {},
      selectedThemeIds,
    };

    this.getThemesDebounced = debounce(props.getThemes, 1000);
  }

  componentDidMount() {
    this.props.getThemes(() => {}, this.props.isFavorite ? this.props.currentUser.id : '');
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.themeId.sort()) !== JSON.stringify(prevProps.themeId.sort())) {
      this.setState({
        selectedThemeIds: this.props.themeId,
      });
    }
  }

  updateTheme = (name, item) =>
    this.props.updateTheme({
      ...item,
      name,
    });

  toggleThemeForm = (parentId, childs = '') => {
    if (!parentId) {
      this.setState({
        isAddTheme: !this.state.isAddTheme,
        [`${parentId}-new`]: '',
        ...(!this.state.isAddTheme && {
          newThemeName: '',
        }),
      });
    } else {
      this.setState({
        [`${parentId}-new`]: '',
        [`isForm${childs}-${parentId}`]: !this.state[`isForm${childs}-${parentId}`],
      });
    }
  };

  onChange = (_, { checkedNodes }) => {
    const checkbleList = checkedNodes
      ? checkedNodes.map(item => ({
          id: item.props.dataRef.id,
          name: item.props.dataRef.name,
        }))
      : [];
    return this.props.updateCheckableElems(checkbleList);
  };

  onChangeRadio = e => {
    if (e.length) {
      this.props.setRadioButtonValue(+e[0]);
    } else {
      e.target && this.props.setRadioButtonValue(e.target.value);
    }
  };

  setThemeName = (e, parentId, childs = '') => {
    const { value } = e.target;
    if (value) {
      const newString = !value.trim().match('[!@#$%^&*(),.?":{}|\\/<>]{2,}');
      if (value.trim().length > 50) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('limit', {
              limit: '50',
            }),
          },
        });
      }
      if (value.trim().length < 2) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('limit', {
              limit: '2',
            }),
          },
        });
      }
      if (!newString) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('error'),
          },
        });
      }
    }
    return this.setState({
      [parentId ? `${parentId}-new` : 'newThemeName']: value,
      errors: {
        ...this.state.errors,
        [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: null,
      },
    });
  };

  renderTreeNodes = data => {
    return data.map(item => {
      const radioTitle = (
        <Radio.Group
          onChange={this.onChangeRadio}
          value={this.props.radioButtonValue}
          className={css.TreeNode__radioGroup}
        >
          <Radio
            value={item.id}
            className={
              item.id === this.props.radioButtonValue
                ? css.TreeNode__radioGroupItemActive
                : css.TreeNode__radioGroupItem
            }
          >
            <TreeNodeTitle
              onlyView={this.props.onlyView}
              isCheckable={this.props.isCheckable}
              isSelected={item.id === this.props.radioButtonValue}
              name={item.name}
              id={item.id}
              error={this.state.errors[`isForm-${item.id}`]}
              onChange={e => this.setThemeName(e, item.id)}
              delTheme={() => this.delTheme(item.id)}
              updateTheme={name => this.updateTheme(name, item)}
            />
          </Radio>
        </Radio.Group>
      );

      return {
        title: radioTitle,
        key: item.id,
        children: item.childs ? this.renderTreeNodes(item.childs) : [],
      };
    });
  };

  setSelectedThemes = themeIds => {
    const { onThemeChange } = this.props;
    this.setState({
      selectedThemeIds: themeIds[0] !== 0 || !themeIds.length ? themeIds : this.state.selectedThemeIds,
    });

    if (themeIds[0] !== 0 || !themeIds.length) {
      this.props.setChosenCompilation({
        ...this.props.searchParams,
        themeId: themeIds,
        page: 0,
      });
    }

    if (onThemeChange) {
      setTimeout(() => {
        onThemeChange();
      }, 0);
    }
  };

  render() {
    // const { selectedThemeIds } = this.state;
    const { themesUsed, isCheckable, handleChangeSearch } = this.props;
    const themes = this.props.themes.sort((a, b) => (a.childs.length > b.childs.length ? -1 : 1));

    const isTreeNotEmpty = themes.length;

    const onSearch = event => {
      handleChangeSearch(event.target.value);

      this.getThemesDebounced(() => {}, '', event.target.value);
    };

    const treeData = this.renderTreeNodes(themes);

    return (
      <div className={classNames(this.props.onlyView && isTreeNotEmpty && css['themesEditorContainer'])}>
        {(isTreeNotEmpty || this.props.searchVal) && (
          <Search onChange={e => onSearch(e)} value={this.props.searchVal} />
        )}
        <div
          className={classNames(isTreeNotEmpty && css.themesEditor, {
            [css.onlyView]: this.props.onlyView,
          })}
        >
          {/* Закомментировано по задаче KAM-4630 */}
          {/* {isTreeNotEmpty ? (
            <Button
              type='link'
              className={classNames(css.seeAll, {
                [css.active]: !selectedThemeIds.length,
              })}
              onClick={() => this.setSelectedThemes([])}
            >
              {this.props.t('all')}
            </Button>
          ) : (
            <Spin spinning={this.props.isLoading}>
              <CustomEmpty className={css['themesEmpty']} />
            </Spin>
          )} */}
          {!isTreeNotEmpty && (
            <Spin spinning={this.props.isLoading}>
              <CustomEmpty className={css['themesEmpty']} />
            </Spin>
          )}
          <Tree
            checkStrictly
            checkable={isCheckable}
            defaultCheckedKeys={themesUsed?.map(theme => `${theme.id}`)}
            onCheck={this.onChange}
            onSelect={selectedKeys => {
              this.onChangeRadio(selectedKeys);
              this.setSelectedThemes([+selectedKeys]);
            }}
            multiple={false}
            treeData={treeData}
            height={300}
          />
        </div>
      </div>
    );
  }
}

ThemesEditor.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  getThemes: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  isCheckable: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isLoading: PropTypes.bool,
  onThemeChange: PropTypes.func,
  onlyView: PropTypes.bool,
  setChosenCompilation: PropTypes.func,
  setRadioButtonValue: PropTypes.func,
  updateCheckableElems: PropTypes.func,
  updateTheme: PropTypes.func,
};

ThemesEditor.defaultProps = {
  isCheckable: false,
};

const mapStateToProps = createSelector(compilationsList, searchList, usersList, (compilations, search, users) => ({
  currentUser: users.currentUser,
  themes: compilations.themes || [],
  themeId: search.searchParams?.themeId || [],
  theme: compilations.theme,
  isLoading: compilations.isLoading,
}));

const mapActionsToProps = {
  getThemes,
  deleteTheme,
  createTheme,
  updateTheme,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('themeEdit')(ThemesEditor));
