export const SEARCH_TYPES = t => [
  {
    id: 1,
    value: 'TITLE',
    label: t('byName'),
  },
  {
    id: 2,
    value: 'TAG',
    label: t('byTag'),
  },
];

export const SORT_TYPES = t => [
  {
    name: t('rate'),
    value: 'rate,desc',
  },
  {
    name: t('date'),
    value: 'createdDate,desc',
  },
];
