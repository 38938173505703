import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from './Participants.module.scss';

const Participants = props => {
  const { participant } = props;

  const [currentAvatar] = useLocalFileByUUID(props.participant.localPhotoUuid || props.participant.photoUuid, {
    defaultImg: PortraitPlaceholder,
  });

  return (
    <Tooltip title={participant && participant ? `${participant.firstName} ${participant.lastName}` : null}>
      <Link to={`/collegues/${participant.id}`} target='_blank' className={css['Avatar-link']}>
        <Avatar key={participant.id} className={css.Avatar} src={currentAvatar} />
      </Link>
    </Tooltip>
  );
};

Participants.propTypes = {
  participant: PropTypes.object,
};

export default Participants;
