/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Button, Card } from 'antd';
import Rate from '@/components/Rate';
import Icon, { HeartFilled, HeartOutlined } from '@ant-design/icons';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './CompilationHeader.module.scss';

class CompilationHeader extends Component {
  static propTypes = {
    compId: PropTypes.number,
    cover: PropTypes.string,
    creBy: PropTypes.object,
    getFile: PropTypes.func,
    hasFavorites: PropTypes.bool,
    navigate: PropTypes.func,
    match: PropTypes.any,
    name: PropTypes.string,
    onFavoriteClick: PropTypes.func,
    rate: PropTypes.number,
    shortDescription: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      cover: null,
    };
  }

  componentDidMount() {
    if (this.props.cover) {
      this.props.getFile(this.props.cover, blob => {
        const cover = URL.createObjectURL(blob);
        this.setState({
          cover,
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.cover !== prevProps.cover) {
      this.props.getFile(this.props.cover, blob => {
        const cover = URL.createObjectURL(blob);
        this.setState({
          cover,
        });
      });
    }
  }

  toggleFavoriteClick = () => {
    const { onFavoriteClick, compId, hasFavorites } = this.props;
    onFavoriteClick({ id: compId, hasFavorites });
  };

  render() {
    const { name, shortDescription, rate, manager = {}, navigate, hasFavorites, t } = this.props;

    const renderImage = (
      <div className={css.Header__image}>
        <img src={this.state.cover} alt='' />
      </div>
    );
    const toMyCollectionButtonExternal = hasFavorites ? (
      <Button type='primary' onClick={this.toggleFavoriteClick} icon={<HeartFilled />} size='large'>
        {t('favorite')}
      </Button>
    ) : (
      <Button type='primary' onClick={this.toggleFavoriteClick} icon={<HeartOutlined />} size='large'>
        {t('toFavorite')}
      </Button>
    );
    return (
      <div className={css.Header}>
        <div className={css.Header__content}>
          <h3 data-qa='userCompilationH3'>{name}</h3>
          <p data-qa='userCompilationShortDescription'>{shortDescription}</p>
        </div>
        <div className={css.Header__content}>
          <div
            className={classNames(
              css.Header__card,
              manager ? css['Header__card-threeComponents'] : css['Header__card-twoComponents']
            )}
          >
            <div className={css.Header__card__col}>
              <div>{t('rate')}</div>
              <Rate className={css.Header__card__col__rating} count={5} value={rate} disabled noChange />
            </div>
            {manager && (
              <div className={css.Header__card__col}>
                <div>{t('expert')}</div>
                <div>
                  <a
                    data-qa='userCompilationManagerName'
                    className={css.Header__expert}
                    onClick={() => navigate(`/collegues/${manager.id}`)}
                  >
                    {manager.name}
                  </a>
                </div>
              </div>
            )}
            <div className={css.Header__card__col}>{toMyCollectionButtonExternal}</div>
          </div>
        </div>
        {renderImage}
      </div>
    );
  }
}

const mapStateToProps = createSelector(() => ({}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('compilation')(CompilationHeader));
