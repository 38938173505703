/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Modal, Table, Tooltip, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import _ from 'lodash';

export const ReportTable = () => {
  const { t } = useTranslation('D360_Report');
  const { report, loading } = useSelector(state => state.d360Report);

  const isCompetence = record => {
    return (
      !_.isNil(record) &&
      _.isObject(record) &&
      _.has(record, 'type') &&
      _.isString(record.type) &&
      record.type.toString().toLowerCase() === 'competence'
    );
  };

  const getBackgroundColor = (maxValue, currentValue) => {
    let result = '#FFFFFF';
    let percent = 0;

    if (currentValue < 0) {
      return 'linear-gradient(0deg, rgba(227,227,227,0.8) 1%, rgba(227,227,227,0.5) 100%)';
    }

    if (typeof maxValue === 'undefined' || isNaN(maxValue)) {
      maxValue = 0;
    }

    if (typeof currentValue === 'undefined' || isNaN(maxValue)) {
      currentValue = 0;
    }

    if (currentValue > 0 && maxValue > 0) {
      percent = (currentValue * 100) / maxValue;
    }

    if (percent < 33) {
      result = 'linear-gradient(0deg, rgba(240,84,98,0.8) 1%, rgba(240,84,98,0.50) 100%)';
    } else if (percent > 29.9 && percent <= 66) {
      result = 'linear-gradient(0deg, rgba(248,208,12,0.8) 1%, rgba(248,208,12,0.5) 100%)';
    } else {
      result = 'linear-gradient(0deg, rgba(29,133,27,0.8) 1%, rgba(29,133,27,0.50) 100%)';
    }

    return result;
  };

  const viewComments = comments => {
    const content = [];
    comments.comments.forEach((e, i) => {
      content.push(<Typography.Paragraph key={`comment_${i}`}>{e.comment}</Typography.Paragraph>);
    });

    Modal.info({
      title: comments.name,
      content: content,
      width: 600,
      onOk() {},
    });
  };

  const columns = [
    {
      title: t('columnCriteria'),
      key: 'criteria',
      dataIndex: 'criteria',
      width: 300,
      render: (name, record) => {
        if (isCompetence(record)) {
          return <div style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{record.name}</div>;
        } else {
          return <div style={{ paddingLeft: 10, fontFamily: 'sans-serif' }}>{record.name}</div>;
        }
      },
    },
    {
      title: t('columnSelf'),
      key: 'self',
      dataIndex: 'self',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (name < 0) name = '-';
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.self),
            },
          },
          children: name,
        };
      },
    },
    {
      // Руководители
      title: t('columnManagers'),
      key: 'manager',
      dataIndex: 'manager',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (name < 0) name = '-';
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.manager),
            },
          },
          children: name,
        };
      },
    },
    {
      // Коллеги
      title: t('columnColleagues'),
      key: 'colleague',
      dataIndex: 'colleague',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (name < 0) name = '-';
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.colleague),
            },
          },
          children: name,
        };
      },
    },
    {
      // Подчиненные
      title: t('columnSubordinates'),
      key: 'subordinate',
      dataIndex: 'subordinate',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (name < 0) name = '-';
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.subordinate),
            },
          },
          children: name,
        };
      },
    },
    {
      // Другие
      title: t('columnOthers'),
      key: 'cross',
      dataIndex: 'cross',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (name < 0) name = '-';
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.cross),
            },
          },
          children: name,
        };
      },
    },
    {
      // Средняя
      title: t('columnMedian'),
      key: 'median',
      dataIndex: 'median',
      align: 'center',
      width: 130,
      render: (name, record) => {
        if (isCompetence(record)) {
          name = <span style={{ fontSize: 16, fontWeight: 'bold' }}>{name}</span>;
        }

        return {
          props: {
            style: {
              background: getBackgroundColor(record.maxscore, record.median),
            },
          },
          children: name,
        };
      },
    },
    // Комментарии
    {
      title: t('columnComments'),
      key: 'comments',
      dataIndex: 'comments',
      align: 'center',
      width: 150,
      render: (name, record) => {
        if (
          !_.isNil(record) &&
          _.isObject(record) &&
          _.has(record, 'comments') &&
          _.has(record, 'type') &&
          record.type === 'skill' &&
          _.isArray(record.comments)
        ) {
          if (record.comments.length > 0 && record.comments?.some(i => i.comment)) {
            return (
              <Tooltip title={t('viewComments')}>
                <Button type='primary' icon={<EyeOutlined />} onClick={() => viewComments(record)} />
              </Tooltip>
            );
          } else {
            return <Button type='primary' icon={<EyeInvisibleOutlined />} disabled={true} />;
          }
        }

        return null;
      },
    },
  ];

  return (
    <Table
      size='small'
      loading={loading}
      scroll={{ x: 1000 }}
      columns={columns}
      dataSource={report}
      rowKey={record => record.id}
      bordered
    />
  );
};

export default ReportTable;
