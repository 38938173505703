/* eslint-disable */
import {
  clearStudyPlanParams,
  clearUserTopicPlan,
  getUserTopicPlan,
  getUserTopicPlanScroll,
  setMaterialLoading,
  setStudyPlanParams,
} from '@/store/study-plan/actions';

import {
  ADD_USER_EVENT_FAIL,
  ADD_USER_EVENT_START,
  ADD_USER_EVENT_SUCCESS,
  CHANGE_VIEW_PLAN,
  CHECK_STUDY_PLAN_FAIL,
  CHECK_STUDY_PLAN_START,
  CHECK_STUDY_PLAN_SUCCESS,
  CHECK_TOPIC_FINISHED_BEFORE_FAIL,
  CHECK_TOPIC_FINISHED_BEFORE_START,
  CHECK_TOPIC_FINISHED_BEFORE_SUCCESS,
  CLEAR_PLAN,
  CREATE_STUDY_PLAN_ANOTHER_FAIL,
  CREATE_STUDY_PLAN_ANOTHER_START,
  CREATE_STUDY_PLAN_ANOTHER_SUCCESS,
  CREATE_STUDY_PLAN_FAIL,
  CREATE_STUDY_PLAN_START,
  CREATE_STUDY_PLAN_SUCCESS,
  DELETE_STUDY_PLAN_FAIL,
  DELETE_STUDY_PLAN_START,
  DELETE_STUDY_PLAN_SUCCESS,
  FINISH_ALL_PLANS_FAIL,
  FINISH_ALL_PLANS_START,
  FINISH_ALL_PLANS_SUCCESS,
  GET_ANOTHER_STUDY_PLAN_DINAMIC_FAIL,
  GET_ANOTHER_STUDY_PLAN_DINAMIC_START,
  GET_ANOTHER_STUDY_PLAN_DINAMIC_SUCCESS,
  GET_ANOTHER_STUDY_PLAN_FAIL,
  GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_FAIL,
  GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_START,
  GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_SUCCESS,
  GET_ANOTHER_STUDY_PLAN_START,
  GET_ANOTHER_STUDY_PLAN_STAT_FAIL,
  GET_ANOTHER_STUDY_PLAN_STAT_START,
  GET_ANOTHER_STUDY_PLAN_STAT_SUCCESS,
  GET_ANOTHER_STUDY_PLAN_SUCCESS,
  GET_ARCHIVE_TOPIC_PLAN_FAIL,
  GET_ARCHIVE_TOPIC_PLAN_START,
  GET_ARCHIVE_TOPIC_PLAN_SUCCESS,
  GET_COUNT_SP_FAIL,
  GET_COUNT_SP_START,
  GET_COUNT_SP_SUCCESS,
  GET_EVENT_PARTY_FAIL,
  GET_EVENT_PARTY_START,
  GET_EVENT_PARTY_SUCCESS,
  GET_PREVIOUS_STUDY_PLANS_FAIL,
  GET_PREVIOUS_STUDY_PLANS_START,
  GET_PREVIOUS_STUDY_PLANS_SUCCESS,
  GET_STUDY_PLAN_DINAMIC_FAIL,
  GET_STUDY_PLAN_DINAMIC_START,
  GET_STUDY_PLAN_DINAMIC_SUCCESS,
  GET_STUDY_PLAN_FAIL,
  GET_STUDY_PLAN_FIRST_MONTH_FAIL,
  GET_STUDY_PLAN_FIRST_MONTH_START,
  GET_STUDY_PLAN_FIRST_MONTH_SUCCESS,
  GET_STUDY_PLAN_NEXT_FAIL,
  GET_STUDY_PLAN_NEXT_START,
  GET_STUDY_PLAN_NEXT_SUCCESS,
  GET_STUDY_PLAN_PREV_FAIL,
  GET_STUDY_PLAN_PREV_START,
  GET_STUDY_PLAN_PREV_SUCCESS,
  GET_STUDY_PLAN_START,
  GET_STUDY_PLAN_STAT_FAIL,
  GET_STUDY_PLAN_STAT_START,
  GET_STUDY_PLAN_STAT_SUCCESS,
  GET_STUDY_PLAN_SUCCESS,
  GET_TOPIC_STUDY_STATUS_FAIL,
  GET_TOPIC_STUDY_STATUS_START,
  GET_TOPIC_STUDY_STATUS_SUCCESS,
  GET_USER_ID_TOPIC_PLAN_FAIL,
  GET_USER_ID_TOPIC_PLAN_START,
  GET_USER_ID_TOPIC_PLAN_SUCCESS,
  GET_WAITING_LIST_FAIL,
  GET_WAITING_LIST_START,
  GET_WAITING_LIST_SUCCESS,
  NOT_UPDATE_BUTTON,
  POST_EVENT_REGISTR_FAIL,
  POST_EVENT_REGISTR_START,
  POST_EVENT_REGISTR_SUCCESS,
  POST_INTEREST_FAIL,
  POST_INTEREST_START,
  POST_INTEREST_SUCCESS,
  POST_WAITING_LIST_FAIL,
  POST_WAITING_LIST_START,
  POST_WAITING_LIST_SUCCESS,
  RESET_CURRENT_PAID_TOPIC_ID,
  SET_CURRENT_PAID_TOPIC_ID,
  UPDATE_BUTTON,
  UPDATE_STUDY_PLAN_DATE_FAIL,
  UPDATE_STUDY_PLAN_DATE_START,
  UPDATE_STUDY_PLAN_DATE_SUCCESS,
  UPDATE_STUDY_PLAN_STATUS_FAIL,
  UPDATE_STUDY_PLAN_STATUS_START,
  UPDATE_STUDY_PLAN_STATUS_SUCCESS,
} from './actions';

import { combineActions, createReducer, reducerAdapter, setState, updateStateFlow } from '@shared/utils/redux-helpers';

import dayjs from 'dayjs';
import _find from 'lodash/find';
import _get from 'lodash/get';

const initialState = {
  studyAnotherPlan: null,
  studyAnotherPlanPageSize: null,
  studyAnotherPlanPageNumber: null,
  studyPlanPageSize: null,
  studyPlanPageNumber: null,
  eventParty: [],
  statAnother: null,
  stat: null,
  firstMonth: null,
  firstMonthAnother: null,
  studyPlanId: [],
  topicWasFinishedBefore: null,
  previousStudyPlansCheckResult: {},
  isLoading: false,
  error: false,
  view: true,
  studyPlanDinamic: [],
  studyPlanDinamicPrev: null,
  studyPlanDinamicNext: null,
  updateButton: false,
  newStatusPlanId: null,
  loadMorePrev: false,
  studyPlan: {},
  params: {},
  loading: {
    studyPlan: {},
    finishedStudyPlan: false,
  },
  pageSize: 0,
  totalElements: 0,
  countStudyPlan: null,
  currentTopicCheck: null,
  waitingList: [],
  currentPaidTopicId: null,
  topicStudyPlanStatus: null,
};

const setStudyPlanLoading =
  value =>
  (state, { name }) =>
    setState(state, ['loading', 'studyPlan', name], value);

const actions = {
  ...combineActions(setStudyPlanLoading(false), [getUserTopicPlan.failure, getUserTopicPlanScroll.failure]),
  [getUserTopicPlan.start]: setStudyPlanLoading(true),
  [getUserTopicPlan.success]: updateStateFlow(
    (state, { name, value }) => setState(state, ['studyPlan', name], value),
    setStudyPlanLoading(false)
  ),
  [clearUserTopicPlan]: (state, { name }) => setState(state, ['studyPlan', name], null),
  [setMaterialLoading]: (state, { name, id, value }) => {
    const materials = _get(state, ['studyPlan', name], []);
    const material = _find(materials, { id });
    material && (material.loading = value);
    return setState(state, ['studyPlan', name], materials);
  },
  [getUserTopicPlanScroll.start]: setStudyPlanLoading(true),
  [getUserTopicPlanScroll.success]: updateStateFlow(
    (state, { name, value }) =>
      setState(state, ['studyPlan', name], {
        data: _get(state, ['studyPlan', name, 'data'], []).concat(value.data),
        meta: value.meta,
      }),
    setStudyPlanLoading(false)
  ),
  [setStudyPlanParams]: (state, { name, value }) => setState(state, ['params', name], value),
  [clearStudyPlanParams]: (state, { name }) => setState(state, ['params', name], null),
};

const oldReducer = (state = initialState, { type, action, response, headers, view }) => {
  switch (type) {
    case GET_USER_ID_TOPIC_PLAN_SUCCESS:
      return {
        ...state,
        studyPlan: response,
        totalElements: Number(headers.get('x-total-elements')) || 0,
        pageSize: Number(headers.get('x-page-size')) || 0,
        mainPage: Number(headers.get('x-page-number')) || 0,
        isLoading: false,
      };

    case GET_ARCHIVE_TOPIC_PLAN_SUCCESS:
      return {
        ...state,
        studyPlan: response.content,
        totalElements: response.totalElements || 0,
        pageSize: response.size || 0,
        mainPage: response.number || 0,
        isLoading: false,
      };

    case GET_EVENT_PARTY_START:
      return {
        ...state,
        eventParty: action.page === 0 ? initialState.eventParty : state.eventParty,
        isLoading: true,
        error: false,
      };
    case GET_STUDY_PLAN_START:
      return {
        ...state,
        stat: null,
        isLoading: true,
        error: false,
      };

    case GET_EVENT_PARTY_SUCCESS:
      const list = [...state.eventParty, ...response.content];
      return {
        ...state,
        eventParty: Number(headers.get('X-Page-Number')) === 0 ? response : list,
        eventPartyTotal: Number(headers.get('X-Total-Elements')) || response.totalElements || 0,
        eventPartyPageSize: Number(headers.get('X-Page-Size')) || response.size || 0,
        eventPartyPageNumber: Number(headers.get('X-Page-Number')) || response.totalPages || 0,
        hasMore: list.length < response.totalElements,
        isLoading: false,
      };
    case GET_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        studyPlan: response,
        studyPlanTotal: Number(headers.get('X-Total-Elements')) || 0,
        studyPlanPageSize: Number(headers.get('X-Page-Size')) || 0,
        studyPlanPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };
    case GET_ANOTHER_STUDY_PLAN_START:
      return {
        ...state,
        studyAnotherPlanTotal: null,
        studyAnotherPlanPageSize: null,
        studyAnotherPlanPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_ANOTHER_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        studyAnotherPlan: response,
        studyAnotherPlanTotal: Number(headers.get('X-Total-Elements')) || 0,
        studyAnotherPlanPageSize: Number(headers.get('X-Page-Size')) || 0,
        studyAnotherPlanPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_STUDY_PLAN_DINAMIC_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_STUDY_PLAN_DINAMIC_SUCCESS:
      if (response && response.length) {
        const plan = {
          id: action.planMonth,
          data: response,
          headers: Number(headers.get('X-Total-Elements')),
        };
        if (dayjs(action.planMonth).format('YYYY-MM') < dayjs().format('YYYY-MM')) {
          return {
            ...state,
            error: false,
            studyPlanDinamic: [plan, ...state.studyPlanDinamic],
            studyPlanDinamicPrev: String(headers.get('x-prev-month')) || '',
            loadMore: !!String(headers.get('x-next-month')),
            isLoading: false,
          };
        }
        if (dayjs(action.planMonth).format('YYYY-MM') > dayjs().format('YYYY-MM')) {
          return {
            ...state,
            error: false,
            studyPlanDinamic: [...state.studyPlanDinamic, plan],
            studyPlanDinamicNext: String(headers.get('x-next-month')) || '',
            loadMore: !!String(headers.get('x-next-month')),
            isLoading: false,
          };
        }
        if (dayjs(action.planMonth).format('YYYY-MM') === dayjs().format('YYYY-MM')) {
          return {
            ...state,
            error: false,
            studyPlanDinamic: [plan],
            studyPlanDinamicPrev: String(headers.get('x-prev-month')) || '',
            studyPlanDinamicNext: String(headers.get('x-next-month')) || '',
            loadMore: !!String(headers.get('x-next-month')),
            loadMorePrev: !!String(headers.get('x-prev-month')),
            isLoading: false,
          };
        }
        return {
          state,
          studyPlanDinamic: [...state.studyPlanDinamic],
          loadMoreNext: !!String(headers.get('x-next-month')),
          loadMore: false,
          isLoading: false,
        };
      }
      return {
        ...state,
        studyPlanDinamic: [...state.studyPlanDinamic],
        error: false,
        isLoading: false,
        studyPlanDinamicPrev: String(headers.get('x-prev-month')) || '',
        studyPlanDinamicNext: String(headers.get('x-next-month')) || '',
        loadMore: !!String(headers.get('x-next-month')),
        loadMorePrev: !!String(headers.get('x-prev-month')),
      };

    case CLEAR_PLAN:
      return { ...state, error: false, isLoading: false, studyPlanDinamic: [], previousStudyPlansCheckResult: {} };

    case GET_ANOTHER_STUDY_PLAN_DINAMIC_START:
      return { ...state, isLoading: true, error: false };

    case GET_ANOTHER_STUDY_PLAN_DINAMIC_SUCCESS:
      let planAnother = {};
      if (response && response.length) {
        planAnother = { id: action.planMonth, data: response };
      }
      if (dayjs(action.planMonth).format('YYYY-MM') < dayjs().format('YYYY-MM')) {
        return {
          ...state,
          error: false,
          studyPlanDinamic: [planAnother, ...state.studyPlanDinamic],
          isLoading: false,
        };
      }
      return {
        ...state,
        error: false,
        studyPlanDinamic: [...state.studyPlanDinamic, planAnother],
        isLoading: false,
      };

    case CREATE_STUDY_PLAN_ANOTHER_START:
    case GET_STUDY_PLAN_PREV_START:
    case GET_STUDY_PLAN_NEXT_START:
    case CREATE_STUDY_PLAN_START:
    case GET_USER_ID_TOPIC_PLAN_START:
    case GET_ARCHIVE_TOPIC_PLAN_START:
    case GET_COUNT_SP_START:
    case POST_INTEREST_START:
    case GET_WAITING_LIST_START:
    case POST_EVENT_REGISTR_START:
    case POST_WAITING_LIST_START:
    case GET_TOPIC_STUDY_STATUS_START:
      return { ...state, isLoading: true, error: false };

    case GET_STUDY_PLAN_PREV_SUCCESS:
      if (response.data && response.data.length) {
        const plan = {
          id: action.planMonth,
          data: JSON.parse(response.data),
          headers: Number(headers.get('X-Total-Elements')),
        };
        return {
          ...state,
          error: false,
          studyPlanDinamic: [plan, ...state.studyPlanDinamic],
          studyPlanDinamicPrev: String(headers.get('x-prev-month')) || '',
          loadMorePrev: !!headers.get('x-prev-month'),
          isLoading: false,
        };
      }
      return {
        ...state,
        studyPlanDinamic: [...state.studyPlanDinamic],
        error: false,
        isLoading: false,
        loadMorePrev: false,
      };

    case GET_TOPIC_STUDY_STATUS_SUCCESS:
      return { ...state, topicStudyPlanStatus: response, isLoading: false, error: false };

    case GET_STUDY_PLAN_NEXT_SUCCESS:
    case CREATE_STUDY_PLAN_SUCCESS:
      return { ...state, newStatusPlanId: response, isLoading: false };

    case CREATE_STUDY_PLAN_ANOTHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CHECK_STUDY_PLAN_START:
    case UPDATE_STUDY_PLAN_STATUS_START:
    case UPDATE_STUDY_PLAN_DATE_START:
    case FINISH_ALL_PLANS_START:
    case DELETE_STUDY_PLAN_START:
    case ADD_USER_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case CHECK_STUDY_PLAN_SUCCESS:
      return { ...state, isLoading: false, studyPlanId: response };

    case UPDATE_BUTTON:
      return { ...state, updateButton: true };

    case NOT_UPDATE_BUTTON:
      return { ...state, updateButton: false };

    case UPDATE_STUDY_PLAN_STATUS_SUCCESS:
      return { ...state, isLoading: false, newStatusPlanId: response };

    case UPDATE_STUDY_PLAN_DATE_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_STUDY_PLAN_START:
      return { ...state, isLoading: true, error: false };
    case POST_INTEREST_SUCCESS:
    case POST_EVENT_REGISTR_SUCCESS:
    case POST_WAITING_LIST_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case DELETE_STUDY_PLAN_SUCCESS:

    case GET_STUDY_PLAN_STAT_START:
      return { ...state, stat: null, isLoading: true, error: false };

    case GET_STUDY_PLAN_STAT_SUCCESS:
      return { ...state, stat: response, isLoading: false };

    case GET_WAITING_LIST_SUCCESS:
      return { ...state, waitingList: response, isLoading: false };

    case GET_STUDY_PLAN_FIRST_MONTH_START:
      return { ...state, firstMonth: null, isLoading: true, error: false };

    case GET_STUDY_PLAN_FIRST_MONTH_SUCCESS:
      return {
        ...state,
        firstMonth: response && response[0] && response[0].date,
        isLoading: false,
      };
    case GET_ANOTHER_STUDY_PLAN_STAT_START:
      return { ...state, statAnother: null, isLoading: true, error: false };

    case GET_ANOTHER_STUDY_PLAN_STAT_SUCCESS:
      return { ...state, statAnother: response, isLoading: false };

    case GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_START:
      return {
        ...state,
        firstMonthAnother: null,
        isLoading: true,
        error: false,
      };

    case GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_SUCCESS:
      return {
        ...state,
        firstMonthAnother: response && response[0] && response[0].date,
        isLoading: false,
      };

    case FINISH_ALL_PLANS_SUCCESS:
      return { ...state, isLoading: false };

    case CHECK_TOPIC_FINISHED_BEFORE_START:
    case GET_PREVIOUS_STUDY_PLANS_START:
      return { ...state, isLoading: true, error: false };

    case CHECK_TOPIC_FINISHED_BEFORE_SUCCESS:
      return { ...state, isLoading: false, topicWasFinishedBefore: response };

    case GET_PREVIOUS_STUDY_PLANS_SUCCESS:
      return { ...state, isLoading: false, previousStudyPlansCheckResult: response };

    case CHANGE_VIEW_PLAN:
      return { ...state, isLoading: false, error: false, view };

    case GET_COUNT_SP_SUCCESS:
      return { ...state, isLoading: false, countStudyPlan: response };

    case GET_EVENT_PARTY_FAIL:
    case CREATE_STUDY_PLAN_ANOTHER_FAIL:
    case GET_STUDY_PLAN_PREV_FAIL:
    case GET_STUDY_PLAN_NEXT_FAIL:
    case GET_STUDY_PLAN_FAIL:
    case GET_STUDY_PLAN_DINAMIC_FAIL:
    case CREATE_STUDY_PLAN_FAIL:
    case UPDATE_STUDY_PLAN_STATUS_FAIL:
    case UPDATE_STUDY_PLAN_DATE_FAIL:
    case DELETE_STUDY_PLAN_FAIL:
    case GET_STUDY_PLAN_STAT_FAIL:
    case FINISH_ALL_PLANS_FAIL:
    case GET_STUDY_PLAN_FIRST_MONTH_FAIL:
    case GET_ANOTHER_STUDY_PLAN_FAIL:
    case GET_ANOTHER_STUDY_PLAN_DINAMIC_FAIL:
    case GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_FAIL:
    case GET_ANOTHER_STUDY_PLAN_STAT_FAIL:
    case CHECK_TOPIC_FINISHED_BEFORE_FAIL:
    case GET_PREVIOUS_STUDY_PLANS_FAIL:
    case GET_USER_ID_TOPIC_PLAN_FAIL:
    case GET_ARCHIVE_TOPIC_PLAN_FAIL:
    case GET_COUNT_SP_FAIL:
    case POST_INTEREST_FAIL:
    case GET_WAITING_LIST_FAIL:
    case POST_EVENT_REGISTR_FAIL:
    case POST_WAITING_LIST_FAIL:
    case GET_TOPIC_STUDY_STATUS_FAIL:
    case CHECK_STUDY_PLAN_FAIL:
    case ADD_USER_EVENT_FAIL:
      return { ...state, error: true, isLoading: false };

    case SET_CURRENT_PAID_TOPIC_ID:
      return {
        ...state,
        currentPaidTopicId: response,
      };

    case RESET_CURRENT_PAID_TOPIC_ID:
      return {
        ...state,
        currentPaidTopicId: null,
      };

    case ADD_USER_EVENT_START:
      return { ...state, isLoading: true, error: false };

    case ADD_USER_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case ADD_USER_EVENT_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};

const reducer = createReducer(actions, initialState);

export default reducerAdapter(actions, initialState, reducer, oldReducer);
