import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { getFile } from '@/store/files/actions';

import TrackProgress from '@/components/TrackProgress';

import emptyCover from './emptyCover.png';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './TrackCard.module.scss';

const TrackCard = ({ track }) => {
  const { t } = useTranslation('TrackCard');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cover, setCover] = useState(null);
  const [statPer, setStatPer] = useState(0);

  // Получение картинки
  useEffect(() => {
    track.coverImage && dispatch(getFile(track.coverImage, img => setCover(URL.createObjectURL(img))));
  }, [track.coverImage]);

  // Считаем статистику
  useEffect(() => {
    if (track?.finishedTopics && track?.totalTopics) {
      setStatPer(Math.ceil((track.finishedTopics / track.totalTopics) * 100));
    }
  }, []);

  const onTrackClick = () => {
    navigate(`/track/${track?.trackId}`, {
      state: { ...track, cover },
    });
  };

  return (
    <div className={css['TrackCard-container']} onClick={onTrackClick}>
      <img className={css['TrackCard-cover']} src={cover ?? emptyCover} alt='TrackCardCover' />
      <div className={css['TrackCard-container-text']}>
        <div>
          <p className={css['TrackCard-title']}>{track?.trackName}</p>
          <p>{track?.shortDescription}</p>
        </div>
        <p>{t('deadline', { deadline: dayjs(track?.startDate).format('LL') })}</p>
      </div>

      <div className={css['TrackCard-stat']}>
        <div className={css['TrackCard-stat-status']}>{track?.statusName}</div>
        <TrackProgress statPer={statPer} finishedTopics={track?.finishedTopics} totalTopics={track?.totalTopics} />
      </div>
    </div>
  );
};

TrackCard.propTypes = {
  track: PropTypes.object,
};

export default TrackCard;
