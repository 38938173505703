import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { setSelectedUser } from '@/store/chat/actions';

import { Avatar } from 'antd';

import useImage from '@shared/hooks/useImage';

import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const SearchElement = ({ data, toAdd }) => {
  const avatarSrc = useImage(data.localPhotoUuid, 'avatar');
  const dispatch = useDispatch();

  const selectUser = () => {
    if (!toAdd) {
      dispatch(
        setSelectedUser({
          firstName: data.firstName,
          lastName: data.lastName,
          photo: data.localPhotoUuid,
          id: data.id,
        })
      );
    }
  };

  return (
    <div className={styles.searchElement} onClick={selectUser}>
      <Avatar src={avatarSrc} />
      <p className={styles.searchElement__name}>
        {data.firstName} {data.lastName}
      </p>
    </div>
  );
};

SearchElement.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    localPhotoUuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  toAdd: PropTypes.bool,
};

SearchElement.defaultProp = {
  toAdd: false,
};

export default memo(SearchElement);
