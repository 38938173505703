// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-wX2XrL.user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-ZfdL2p .user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-label-Zc8l6v[data-value="Montserrat"]:before, .user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-wX2XrL.user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-ZfdL2p .user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-item-pl5o5X[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-Montserrat-d23zT3 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-o4CJIF {
  color: var(--personalColorDark);
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

@media (min-width: 360px) and (max-width: 576px) {
  .user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-o4CJIF {
    font-size: 12px;
  }
}

.user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-icon-CLPjal {
  margin-right: 8px;
  font-size: 32px;
}

@media (min-width: 360px) and (max-width: 576px) {
  .user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-icon-CLPjal {
    font-size: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Mentoring/MentorTag/MentorTag.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,+BAA+B;EAC/B,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAMA;EACE;IAHE,eAAe;EACjB;AACF;;AAKA;EAFE,iBAAiB;EACjB,eAAe;AACjB;;AAIA;EACE;IADE,eAAe;EACjB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.MentorTag {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--personalColorDark);\n  font-size: 16px;\n  font-weight: 700;\n  margin-left: 10px;\n}\n@media (width >= 360px) and (width <= 576px) {\n  .MentorTag {\n    font-size: 12px;\n  }\n}\n.MentorTag-icon {\n  font-size: 32px;\n  margin-right: 8px;\n}\n@media (width >= 360px) and (width <= 576px) {\n  .MentorTag-icon {\n    font-size: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-wX2XrL`,
	"qlPicker": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-wX2XrL`,
	"ql-font": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-ZfdL2p`,
	"qlFont": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-ZfdL2p`,
	"ql-picker-label": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-label-Zc8l6v`,
	"qlPickerLabel": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-label-Zc8l6v`,
	"ql-picker-item": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-item-pl5o5X`,
	"qlPickerItem": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-picker-item-pl5o5X`,
	"ql-font-Montserrat": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-Montserrat-d23zT3`,
	"qlFontMontserrat": `user-src-components-Mentoring-MentorTag-MentorTag-module__ql-font-Montserrat-d23zT3`,
	"MentorTag": `user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-o4CJIF`,
	"mentorTag": `user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-o4CJIF`,
	"MentorTag-icon": `user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-icon-CLPjal`,
	"mentorTagIcon": `user-src-components-Mentoring-MentorTag-MentorTag-module__MentorTag-icon-CLPjal`
};
export default ___CSS_LOADER_EXPORT___;
