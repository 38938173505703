import ContentPokaSkills from './Content/ContentPokaSkills';
import FiltersPokaSkills from './Content/FiltersPokaSkills';

const PokaSkills = () => {
  return (
    <div>
      <FiltersPokaSkills />
      <ContentPokaSkills />
    </div>
  );
};

export default PokaSkills;
