export const TOPIC = 'TOPIC';
export const NEW_TOPIC = 'NEW_TOPIC';
export const FINISHED_TOPIC = 'FINISHED_TOPIC';
export const LOGIN = 'LOGIN';
export const UNMODERATE = 'UNMODERATE';
export const UNMODERATE_BTN = 'UNMODERATE_BTN';
export const SUCCESS_BTN = 'SUCCESS_BTN';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const COMMENT_TOPIC = 'COMMENT_TOPIC';
export const MENTOR_COMPLETE = 'MENTOR_COMPLETE';
