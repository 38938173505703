// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-BadgeModal-style-module__ql-picker-n51LHi.user-src-components-BadgeModal-style-module__ql-font-VpNYJp .user-src-components-BadgeModal-style-module__ql-picker-label-WGayS4[data-value="Montserrat"]:before, .user-src-components-BadgeModal-style-module__ql-picker-n51LHi.user-src-components-BadgeModal-style-module__ql-font-VpNYJp .user-src-components-BadgeModal-style-module__ql-picker-item-x8xggQ[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-BadgeModal-style-module__ql-font-Montserrat-VNJnCB {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-BadgeModal-style-module__badge-modal-bnmv64 {
  flex-wrap: wrap;
  justify-items: center;
  gap: 36px;
  max-width: 800px;
  padding: 0 16px 40px;
  display: flex;
  overflow: hidden scroll;
}

.user-src-components-BadgeModal-style-module__badge-modal-bnmv64::-webkit-scrollbar {
  width: 0;
}

.user-src-components-BadgeModal-style-module__badge-modal__element-svh_g3 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.user-src-components-BadgeModal-style-module__badge-modal__no-data-fuLXrs {
  text-align: center;
  width: 100%;
}

.user-src-components-BadgeModal-style-module__element__image-yRyawV {
  width: 100px;
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/BadgeModal/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,eAAe;EACf,qBAAqB;EACrB,SAAS;EACT,gBAAgB;EAChB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB;;AAOA;EAJE,QAAQ;AACV;;AAMA;EAHE,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAKA;EAFE,kBAAkB;EAClB,WAAW;AACb;;AAKA;EAFE,YAAY;EACZ,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.badge-modal {\n  display: flex;\n  flex-wrap: wrap;\n  max-width: 800px;\n  gap: 36px;\n  justify-items: center;\n  padding: 0 16px 40px;\n  overflow: hidden;\n  overflow-y: scroll;\n}\n.badge-modal::-webkit-scrollbar {\n  width: 0;\n}\n.badge-modal__element {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n.badge-modal__no-data {\n  width: 100%;\n  text-align: center;\n}\n\n.element__image {\n  width: 100px;\n  height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-BadgeModal-style-module__ql-picker-n51LHi`,
	"qlPicker": `user-src-components-BadgeModal-style-module__ql-picker-n51LHi`,
	"ql-font": `user-src-components-BadgeModal-style-module__ql-font-VpNYJp`,
	"qlFont": `user-src-components-BadgeModal-style-module__ql-font-VpNYJp`,
	"ql-picker-label": `user-src-components-BadgeModal-style-module__ql-picker-label-WGayS4`,
	"qlPickerLabel": `user-src-components-BadgeModal-style-module__ql-picker-label-WGayS4`,
	"ql-picker-item": `user-src-components-BadgeModal-style-module__ql-picker-item-x8xggQ`,
	"qlPickerItem": `user-src-components-BadgeModal-style-module__ql-picker-item-x8xggQ`,
	"ql-font-Montserrat": `user-src-components-BadgeModal-style-module__ql-font-Montserrat-VNJnCB`,
	"qlFontMontserrat": `user-src-components-BadgeModal-style-module__ql-font-Montserrat-VNJnCB`,
	"badge-modal": `user-src-components-BadgeModal-style-module__badge-modal-bnmv64`,
	"badgeModal": `user-src-components-BadgeModal-style-module__badge-modal-bnmv64`,
	"badge-modal__element": `user-src-components-BadgeModal-style-module__badge-modal__element-svh_g3`,
	"badgeModalElement": `user-src-components-BadgeModal-style-module__badge-modal__element-svh_g3`,
	"badge-modal__no-data": `user-src-components-BadgeModal-style-module__badge-modal__no-data-fuLXrs`,
	"badgeModalNoData": `user-src-components-BadgeModal-style-module__badge-modal__no-data-fuLXrs`,
	"element__image": `user-src-components-BadgeModal-style-module__element__image-yRyawV`,
	"elementImage": `user-src-components-BadgeModal-style-module__element__image-yRyawV`
};
export default ___CSS_LOADER_EXPORT___;
