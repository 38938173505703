import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Button, Form, Input } from 'antd';
import LogoCompany from '@/components/LogoCompany';

import css from './Login.module.scss';

const RecoveryPage = () => {
  const { imageBackgroundLogin, imageLogo, restoreUserRecaptcha, isSending, navigate } = useOutletContext();
  const { t } = useTranslation(['login']);

  const handleSubmit = formData => {
    restoreUserRecaptcha(formData.email);
  };

  const handleChangeRenderPage = flag => {
    navigate(`/user-login/${flag}`);
  };

  return (
    <div className={css['App-card-item']}>
      <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
      <Form className={css['LoginPage-form']} onFinish={handleSubmit}>
        <div className={css['LoginPage-form__contentRecovery']}>
          <div className={css['LoginPage-header']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
          </div>
          <div className={css['LoginPage-titleHead']}>{t('forgotPassword')}</div>
          <div className={css['LoginPage-titleBody']}>
            {t('recoveryDescription1')} <br />
            {t('recoveryDescription2')}
          </div>
          <Form.Item
            colon={false}
            name='email'
            className={css['LoginPage-form-item']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t('mailRequired'),
              },
              {
                type: 'email',
                message: t('checkMail'),
              },
            ]}
          >
            <Input placeholder={t('mail')} />
          </Form.Item>
          <Form.Item className={css['LoginPage-form-google']}>
            <Button size='large' type={'primary'} htmlType='submit' disabled={isSending}>
              {t('recovery')}
            </Button>
          </Form.Item>
          <div className={css['LoginPage-label']}>
            <div className={css.LoginPage__toInfo} onClick={() => handleChangeRenderPage('auth')}>
              {t('goBack')}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RecoveryPage;
