import { API_URL } from '@shared/constants/config';

export const subscribeDevice = async () => {
  try {
    switch (Notification.permission) {
      case 'denied':
        throw new Error('Push messages are blocked.');
      case 'granted':
        console.log('Разрешение на отправку уведомлений --- есть! ---');
        break;
      default:
        await new Promise((resolve, reject) => {
          Notification.requestPermission(result => {
            if (result !== 'granted') {
              reject(new Error('Разрешение на отправку уведомлений --- не получено! ---'));
            }

            console.log('Разрешение на отправку уведомлений --- получено! ---');
            resolve();
          });
        });
    }

    try {
      const reg = await navigator.serviceWorker.ready;
      const publicVapidKey = 'BMabvjkYpEhaHrItZsCvCBcezJs3ZfBbCMEX3F1LtQKhRSC_Xl2J1aF9q9jclkGyYM8JkpX4Q9PrphC2ic7OPV8';

      const subscription = await reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey,
      });

      const subscribeObject = JSON.parse(JSON.stringify(subscription));

      console.log('subscription NEW VERSION-----------', subscribeObject);

      let key = subscribeObject.keys?.p256dh;
      let auth = subscribeObject.keys?.auth;

      // console.log('subscription OLD VERSION-----------', subscription);

      const body = JSON.stringify({
        endpoint: subscription.endpoint,
        key: key,
        auth: auth,
      });

      console.log('body', body);

      if (navigator.userAgent.includes('Chrome') || window.matchMedia('(display-mode: standalone)').matches) {
        fetch(`${API_URL}/notifications/subscribe`, {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('authorizationToken'),
          },
          body,
        });
      }
    } catch (err) {
      console.error('init and fetch ', err);
    }
  } catch (err) {
    console.error('subscribeDevice() ', err);
  }
};

export const unsubscribeDevice = async () => {
  try {
    // Get the current service worker registration
    const reg = await navigator.serviceWorker.ready;

    // Get the active push subscription
    const subscription = await reg.pushManager.getSubscription();

    if (subscription) {
      // Unsubscribe the device
      await subscription.unsubscribe();
      //   const unsubscribed = await subscription.unsubscribe();

      //   if (unsubscribed) {
      //     console.log('Unsubscribed from push notifications successfully.');

      //     // // Optionally notify your backend server to remove the subscription
      //     // const body = JSON.stringify({
      //     //   endpoint: subscription.endpoint,
      //     // });

      //     // if (navigator.userAgent.includes('Chrome') || window.matchMedia('(display-mode: standalone)').matches) {
      //     //   await fetch(`${API_URL}/notifications/unsubscribe`, {
      //     //     method: 'POST',
      //     //     headers: {
      //     //       Accept: '*/*',
      //     //       'Content-Type': 'application/json',
      //     //       Authorization: localStorage.getItem('authorizationToken'),
      //     //     },
      //     //     body,
      //     //   });
      //     // }
      //   } else {
      //     console.error('Failed to unsubscribe from push notifications.');
      //   }
    } else {
      console.log('No active subscription found.');
    }
  } catch (err) {
    console.error('unsubscribeDevice() ', err);
  }
};
