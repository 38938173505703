// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal.custom__modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 25px 50px #00000029;
}

.ant-modal.custom__modal .modal_healer {
  display: none;
}

.Modal-close {
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Modal/Modal-css.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,MAAM;AACR","sourcesContent":[".ant-modal.custom__modal {\n  background-color: #fff;\n  padding: 30px;\n  border-radius: 10px;\n  box-shadow: 0 25px 50px 0 rgba(0, 0, 0, 16%);\n}\n\n.ant-modal.custom__modal .modal_healer {\n  display: none;\n}\n\n.Modal-close {\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
