/* eslint-disable */
import CalendarMaterials from '@/pages/CalendarMaterials';
import CareerPlan from '@/pages/CareerPlan';
import Chat from '@/pages/Chat/Chat';
import Collection from '@/pages/Collection';
import CollectionMaterials from '@/pages/CollectionMaterials';
import CollectionMaterialsID from '@/pages/CollectionMaterialsID';
import { CollectionMaterialsIDStudy } from '@/pages/CollectionMaterialsIDStudy';
import Collegues from '@/pages/Collegues';
import ColleguesProfile, { colleguesLoader } from '@/pages/ColleguesProfile';
import MyMaterialList from '@/pages/ColleguesProfile/MyMaterialList';
import MyRecommendedList from '@/pages/ColleguesProfile/MyRecommendedList';
import PlanningAnother from '@/pages/ColleguesProfile/Planning';
import RecommendedList from '@/pages/ColleguesProfile/RecommendedList';
import CompilationId from '@/pages/CompilationDetail';
import Compilations from '@/pages/Compilations';
import D360Project from '@/pages/D360/Project';
import D360Projects from '@/pages/D360/Projects';
import D360Survey from '@/pages/D360/Survey';
import DiagnosticContainer from '@/pages/Diagnostic';
import DiagnosticPolus from '@/pages/DiagnosticPolus';
import DiagnosticTalentQ from '@/pages/DiagnosticTalentQ';
import Faq from '@/pages/Faq';
import Library from '@/pages/Library';
import RegistrationPageDataAbout from '@/pages/Login/RegistrationPageDataAbout';
import Mentors from '@/pages/Mentors';
import MentorsID from '@/pages/MentorsID';
import MyCalendar from '@/pages/MyCalendar';
import MyCollection from '@/pages/MyCollection';
import MyCollectionCompilations from '@/pages/MyCollection/Compilcations';
import MyCollectionMaterials from '@/pages/MyCollection/Materials';
import MyNotifications from '@/pages/MyNotifications';
import MyProfile from '@/pages/MyProfile';
import ProfileInfo from '@/pages/MyProfile/Info';
import MentorTab from '@/pages/MyProfile/Mentoring/MentorTab';
import MentorTabComments from '@/pages/MyProfile/Mentoring/MentorTabComments';
import MyAdded from '@/pages/MyProfile/RecommendedTopic/MyAdded';
import MyRecommendations from '@/pages/MyProfile/RecommendedTopic/MyRecommendations';
import Recommended from '@/pages/MyProfile/RecommendedTopic/Recommended';
import Settings from '@/pages/MyProfile/Settings';
import News from '@/pages/News';
import NewsId from '@/pages/NewsId';
import OfflineMaterials from '@/pages/OfflineMaterials';
import PlanningFinishedPage from '@/pages/planning-finished-page';
import PlanningPage from '@/pages/planning-page';
import { permissionHandler } from '@/pages/Root/permissionHandler';
import Shop from '@/pages/Shop';
import ShopId from '@/pages/ShopId';
import Store from '@/pages/Store';
import TrackId from '@/pages/TrackId/TrackId';

import { ACCESS_TO_USERS_MYPOLLS } from '@/constants/permissions';

// const isBidzaar = window?.location?.hostname?.includes('bidzaar');

const defaultPath = {
  index: true,
  // Component: isBidzaar ? permissionHandler(PlanningPage) : permissionHandler(CollectionMaterials),
  Component: permissionHandler(CollectionMaterials),
};

const offlineMaterials = {
  path: 'offline-materials',
  Component: permissionHandler(OfflineMaterials),
};
const d360Projects = {
  path: 'd360',
  Component: permissionHandler(D360Projects, { permissions: ACCESS_TO_USERS_MYPOLLS }),
};

const d360Project = {
  path: 'd360/:id',
  Component: permissionHandler(D360Project, { permissions: ACCESS_TO_USERS_MYPOLLS }),
};

const d360Survey = {
  path: 'd360/:id/survey',
  Component: permissionHandler(D360Survey, { permissions: ACCESS_TO_USERS_MYPOLLS }),
};

const collectionTopics = {
  path: 'collection-topics',
  Component: permissionHandler(CollectionMaterials),
};

const calendarTopics = {
  path: 'calendar',
  Component: permissionHandler(CalendarMaterials),
};

const myCollection = {
  path: 'my-collection',
  Component: permissionHandler(MyCollection),
  children: [
    {
      index: true,
      Component: permissionHandler(MyCollectionMaterials),
    },
    {
      path: 'materials',
      Component: permissionHandler(MyCollectionMaterials),
    },
    {
      path: 'compilations',
      Component: permissionHandler(MyCollectionCompilations),
    },
  ],
  companyFlag: 'hasCompilation',
  props: {
    isFavorite: true,
  },
};

const myCalendar = {
  path: 'my-calendar',
  Component: permissionHandler(MyCalendar),
};

const myCollectionCompilations = {
  path: 'my-collection-compilations',
  Component: permissionHandler(Compilations),
  companyFlag: 'hasCompilation',
  props: {
    isFavorite: true,
  },
};

const myProfile = {
  path: 'my-profile',
  Component: MyProfile,
  children: [
    {
      index: true,
      Component: ProfileInfo,
    },
    {
      path: 'info',
      Component: ProfileInfo,
    },
    {
      path: 'mentoring',
      Component: MentorTab,
    },
    {
      path: 'mentoring-comments',
      Component: MentorTabComments,
    },
    {
      path: 'recommended',
      Component: Recommended,
    },
    {
      path: 'my-recommendations',
      Component: MyRecommendations,
    },
    {
      path: 'added',
      Component: MyAdded,
    },
    {
      path: 'settings',
      Component: Settings,
    },
  ],
};

const myNotifications = {
  path: 'my-notifications',
  Component: permissionHandler(MyNotifications),
};

const shop = {
  path: 'shop',
  Component: permissionHandler(Shop),
};

const shopId = {
  path: 'shop/:id',
  Component: permissionHandler(ShopId),
};

const planning = {
  path: 'planning',
  Component: permissionHandler(PlanningPage),
};

const planningFinished = {
  path: 'planning/finished',
  Component: permissionHandler(PlanningFinishedPage),
};

const compilation = {
  path: 'compilations',
  Component: permissionHandler(Compilations),
};

const compilationDetile = {
  path: 'compilations/:id',
  Component: permissionHandler(CompilationId),
};

const diagnostic = {
  path: 'diagnostic',
  Component: permissionHandler(DiagnosticContainer),
};

const diagnosticPolus = {
  path: 'diagnostic/polus',
  Component: permissionHandler(DiagnosticPolus),
};

const diagnosticTalentQ = {
  path: 'diagnostic/talentq',
  Component: permissionHandler(DiagnosticTalentQ),
};

const collection = {
  path: 'collection',
  Component: permissionHandler(Collection),
};

const collectionTopicsID = {
  path: 'collection-topics/:id',
  Component: permissionHandler(CollectionMaterialsID),
};

const collectionTopicsIDStudy = {
  path: 'collection-topics/:id/study',
  Component: permissionHandler(CollectionMaterialsIDStudy),
};

const collectionTopicsIDAbout = {
  path: 'collection-topics/:id/about',
  Component: permissionHandler(CollectionMaterialsID),
};

const collectionTopicsIDReview = {
  path: 'collection-topics/:id/review',
  Component: permissionHandler(CollectionMaterialsID),
};

const collectionTopicsIDFile = {
  path: 'collection-topics/:id/file',
  Component: permissionHandler(CollectionMaterialsID),
};

const collectionTopicsIDComment = {
  path: 'collection-topics/:id/comment',
  Component: permissionHandler(CollectionMaterialsID),
};

const collegues = {
  path: 'collegues',
  Component: permissionHandler(Collegues),
};

const colleguesProfile = {
  path: 'collegues/:id',
  Component: permissionHandler(ColleguesProfile),
  loader: colleguesLoader,
  children: [
    {
      index: true,
      // Component: permissionHandler(ProfileInfo),
    },
    {
      path: 'info',
      Component: permissionHandler(ProfileInfo),
    },
    {
      path: 'recommended',
      Component: permissionHandler(RecommendedList),
    },
    {
      path: 'my-recommendations',
      Component: permissionHandler(MyRecommendedList),
    },
    {
      path: 'added',
      Component: permissionHandler(MyMaterialList),
    },
    {
      path: 'collegues-planning',
      Component: permissionHandler(PlanningAnother, { isAnother: true }),
    },
    {
      path: 'finished',
      Component: permissionHandler(PlanningAnother, { finished: true, isAnother: true }),
    },
  ],
};

const colleguesProfileAdded = {
  path: 'collegues/:id/added',
  Component: permissionHandler(ColleguesProfile),
};

const faq = {
  path: 'faq',
  Component: permissionHandler(Faq),
};
const store = {
  path: 'store',
  Component: permissionHandler(Store),
};

const library = {
  path: 'library',
  Component: permissionHandler(Library),
};

const mentors = {
  path: 'mentors',
  Component: permissionHandler(Mentors),
};

const mentorId = {
  path: 'mentors/:id',
  Component: permissionHandler(MentorsID),
};

const trackId = {
  path: 'track/:id',
  Component: permissionHandler(TrackId),
};

const news = {
  path: 'news',
  Component: permissionHandler(News),
};

const newsId = {
  path: 'news/:id',
  Component: permissionHandler(NewsId),
};

const registrationPageDataAbout = {
  path: 'personal-data/registration-page-data-about',
  Component: permissionHandler(RegistrationPageDataAbout),
};

const chatPersonal = {
  exact: true,
  path: 'chat/personal',
  Component: permissionHandler(Chat),
};

const chatGroup = {
  exact: true,
  path: 'chat/groups',
  Component: permissionHandler(Chat),
};

const chatMentor = {
  exact: true,
  path: `chat/:id`,
  Component: permissionHandler(Chat),
};

const careerPlan = {
  exact: true,
  path: 'career-plan',
  Component: permissionHandler(CareerPlan),
};

// const defaultRoute = isBidzaar ? '/planning' : '/collection-topics';
const defaultRoute = '/collection-topics';

const allRoutes = [
  defaultPath,
  myCalendar,
  myCollection,
  myCollectionCompilations,
  myNotifications,

  diagnostic,
  diagnosticPolus,
  diagnosticTalentQ,
  myProfile,
  planning,
  planningFinished,
  compilation,
  compilationDetile,
  collection,
  calendarTopics,
  collectionTopics,
  collectionTopicsID,
  collectionTopicsIDComment,
  collectionTopicsIDFile,
  collectionTopicsIDReview,
  collectionTopicsIDStudy,
  collectionTopicsIDAbout,
  collegues,
  faq,
  colleguesProfile,
  colleguesProfileAdded,
  store,
  library,
  d360Projects,
  d360Project,
  d360Survey,
  offlineMaterials,
  mentors,
  mentorId,
  shop,
  shopId,
  news,
  newsId,
  trackId,
  registrationPageDataAbout,
  chatPersonal,
  chatGroup,
  chatMentor,
  careerPlan,
];

export {
  myCollection,
  myCollectionCompilations,
  myCalendar,
  planning,
  planningFinished,
  diagnostic,
  diagnosticPolus,
  diagnosticTalentQ,
  collection,
  compilation,
  compilationDetile,
  calendarTopics,
  collectionTopics,
  collectionTopicsID,
  collectionTopicsIDStudy,
  collectionTopicsIDAbout,
  collectionTopicsIDReview,
  collectionTopicsIDFile,
  collectionTopicsIDComment,
  faq,
  collegues,
  colleguesProfile,
  colleguesProfileAdded,
  store,
  offlineMaterials,
  mentors,
  mentorId,
  library,
  myNotifications,
  /* Profile */
  myProfile,
  /* Diagnostic 360 */
  d360Projects,
  d360Project,
  d360Survey,
  defaultRoute,
  /* Shop */
  shop,
  shopId,
  trackId,
  news,
  newsId,
  registrationPageDataAbout,
  chatPersonal,
  chatGroup,
  chatMentor,
  careerPlan,
};

export default allRoutes;
