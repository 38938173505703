export const getLinkFromUrl = links => {
  const link =
    links && links.length !== 0
      ? links[0].url.indexOf('youtube.com') !== -1
        ? links[0].url.substring(links[0].url.indexOf('watch') + 8)
        : links[0].url.indexOf('youtu.be') !== -1
          ? links[0].url.substring(links[0].url.indexOf('be/') + 3)
          : ''
      : '';

  return link && link.length !== 0 && link.indexOf('&') !== -1
    ? link.substring(0, link.indexOf('&') || link.index('?'))
    : link;
};

export const shareLink = () => {
  const url = `https://work.facebook.com/sharer.php?display=popup&u=${document.location.href}`;
  const options = {
    toolbar: 0,
    status: 0,
    resizable: 1,
    width: 600,
    height: 500,
  };

  const optionsString = Object.keys(options)
    .map(k => `${k}=${options[k]}`)
    .join('&');
  window.open(url, 'sharer', optionsString);
};

export const getStudyUrl = () => {
  let url = document.location.pathname;
  if (url.substring(url.length - 1) === '/') {
    return url + 'study';
  }

  return url + '/study';
};
