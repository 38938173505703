/* eslint-disable */
import React, { Component } from 'react';

import { Divider } from 'antd';

import FileInput from '../FileInput/FileInput';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import css from './ExecutiveFinishedScreen.module.scss';

export default class ExecutiveForm extends Component {
  static propTypes = {
    windowTitle: PropTypes.string,
    review: PropTypes.string,
    reviewPlaceHolder: PropTypes.string,
    addFileButtonText: PropTypes.string,
    files: PropTypes.array,
    onFinish: PropTypes.func,
    disabled: PropTypes.bool,
    needFile: PropTypes.bool,
    topicExecutiveId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      needFile: this.props.needFile || false,
      review: this.props.review || '',
      files: this.props.files || [],
    };
  }

  handleReviewChange(e) {
    const review = String(e.value).replaceAll(';', '');
    this.setState({ review });
  }

  handleFileAttach = (files, name) => {
    if (Array.isArray(files)) {
      //const filesData = files.map(file=> file && file.uuid );
      this.setState({ files });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.needFile) {
      if (
        Array.isArray(this.state.files) &&
        this.state.files.length > 0 &&
        this.state.review &&
        this.state.review.length
      ) {
        this.props.onFinish(true, this.props.topicExecutiveId, this.state.review, this.state.files);
        return;
      }
    } else {
      if (this.state.review && this.state.review.length) {
        this.props.onFinish(true, this.props.topicExecutiveId, this.state.review, this.state.files);
        return;
      }
    }

    this.props.onFinish(false, this.props.topicExecutiveId, this.state.review, this.state.files);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.needFile !== this.state.needFile) {
      this.setState({ needFile: nextProps.needFile });
    }
    if (nextProps.review !== this.state.review) {
      this.setState({ review: nextProps.review });
    }
    if (!isEqual(nextProps.files, this.state.files) && Array.isArray(nextProps.files)) {
      this.setState({ files: nextProps.files });
    }
  }

  render() {
    return (
      <div className={css.ExecutiveFinishedScreen}>
        <div style={{ position: 'relative', display: 'none' }}>
          <svg
            style={{ position: 'absolute', top: '0', left: '0' }}
            width={24}
            height={24}
            xmlns='http://www.w3.org/2000/svg'
            className='icon'
            viewBox='0 0 1024 1024'
          >
            <path
              fill='#4bbd5c'
              d='M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 0 0-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z'
            />
          </svg>
          <h1
            style={{
              fontSize: '13px',
              lineHeight: '12px',
              color: '#999',
              textTransform: 'uppercase',
              marginLeft: '30px',
              paddingTop: '7px',
              fontWeight: 500,
            }}
          >
            {this.props.windowTitle}
          </h1>
          <Divider />
        </div>

        <textarea
          className={css.ExecutiveFinishedScreenInput}
          rows={12}
          maxLength={10000}
          name='executiveReview'
          value={this.state.review || ''}
          placeholder={this.props.reviewPlaceHolder}
          onChange={e => {
            this.handleReviewChange(e.target);
          }}
          readOnly={this.props.disabled}
          disabled={this.props.disabled}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {this.state.needFile && (
            <FileInput
              onChange={this.handleFileAttach}
              label=''
              required={true}
              fileLoading={true}
              fileLoadingImage={false}
              name='executiveFileAttached'
              buttonAddText={this.props.addFileButtonText}
              value={this.state.files}
              files={this.state.files}
              disabled={this.props.disabled}
            />
          )}
        </div>
      </div>
    );
  }
}
