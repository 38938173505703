import { GET_SKILLS_FAIL, GET_SKILLS_START, GET_SKILLS_SUCCESS } from './constants';

const initialState = {
  skills: null,
  isLoading: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_SKILLS_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        skills: response,
        isLoading: false,
      };

    case GET_SKILLS_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
