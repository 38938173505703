/* eslint-disable */
import {
  noActionTypesApiError,
  noBodyApiError,
  noMethodApiError,
  noServerApiError,
  noUrlApiError,
  sendFailAction,
  sendStartAction,
  sendSuccessAction,
} from './ApiSupport';

import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import apiService from '@shared/services/api-service';

import _isFunction from 'lodash/isFunction';

const allowedMethods = [GET, POST, PUT, DELETE];
const requiresBody = [PUT, POST];

const Api = store => next => async action => {
  /** *************** */
  if (action.skip) {
    return next(action); // todo DELETE AFTER STORE REFACTORING
  }
  /** ************** */

  if (action.type !== REST_API) {
    return next(action);
  }
  if (!~allowedMethods.indexOf(action.method)) {
    return next(noMethodApiError(action));
  }
  if (typeof action.url !== 'string' || action.url.length === 0) {
    return next(noUrlApiError(action));
  }
  if (
    typeof action.body !== 'object' &&
    ~requiresBody.indexOf(action.method) &&
    action.headers['Content-Type'] === 'application/json'
  ) {
    return next(noBodyApiError(action));
  }
  if (!Array.isArray(action.types) || action.types.length !== 3) {
    return next(noActionTypesApiError(action));
  }

  try {
    store.dispatch(sendStartAction(action));

    if (action.headers.Authorization === undefined) {
      action.headers.Authorization = '';
    }

    const { data, headers } = await apiService.request({
      baseURL: '',
      url: action.url,
      method: action.method,
      headers: action.headers,
      data: action.body,
      responseType: action.responseType || 'json',
      fullResponse: true,
      withoutRefresh: !!action.withoutRefresh,
      signal: action?.signal,
    });

    const responseData = typeof data === 'string' ? { data } : data;
    const responseHeaders = {
      get(name) {
        return headers[String(name).toLowerCase()];
      },
    };

    store.dispatch(sendSuccessAction(action, responseData, responseHeaders));

    _isFunction(action.onSuccess) && action.onSuccess(responseData, responseHeaders);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    const {
      response: { status, statusText, data },
    } = error;
    const message = (data && data.message) || '';
    _isFunction(action.onError) && action.onError({ statusText, status });
    store.dispatch(sendFailAction(action, message));
    throw next(noServerApiError(status));
  }
};

export default Api;
