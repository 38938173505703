/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import Tag from '@/components/Tag';

import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

class Info extends Component {
  static propTypes = {
    fullDescription: PropTypes.string,
    skills: PropTypes.array,
    dataqa: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { fullDescription, skills, dataqa } = this.props;
    return (
      <div className={css.Info}>
        <SkillsList skills={skills} />
        <div>
          <h2 data-qa={`${dataqa}-learnTo`}>{this.props.t('learnTo')}</h2>
          <p className={css.Info__desc} data-qa={`${dataqa}-fullDescription`}>
            {fullDescription}
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation('compilation')(Info);

const SkillsList = ({ skills }) => {
  const { t } = useTranslation('compilation');
  return (
    skills.length > 0 && (
      <div className={css.Info__tags}>
        <h2>{t('skills')}</h2>
        <span>
          {skills.map(skill => (
            <Tag orange noDel skills key={skill.id} tag={skill} />
          ))}
        </span>
      </div>
    )
  );
};
