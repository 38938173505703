import { Tooltip } from 'antd';
import { MAX_TOPIC_TITLE_LENGTH } from '@/pages/CareerPlan/constants';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import { getDefaultMaterialImage } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from '../TopicsCareerPlanModal.module.scss';

const TopicModalItem = ({ topic, onTopicClick, isActive = false, isSelected = false }) => {
  const [coverImg] = useLocalFileByUUID(topic?.thumbFileUuid, { defaultImg: getDefaultMaterialImage(topic?.type?.id) });

  return (
    <>
      {isActive ? (
        <Tooltip
          key={topic?.id}
          className={css['TopicsCareerPlanModal-topic-img-mini']}
          title={topic?.name}
          onClick={() => onTopicClick(topic)}
        >
          <img className={css['TopicsCareerPlanModal-topic-img-mini']} src={coverImg} alt='topicThumb' />
          <AiOutlineClose className={css['TopicsCareerPlanModal-topic-img-mini-close']} />
        </Tooltip>
      ) : (
        <div key={topic?.id} className={css['TopicsCareerPlanModal-topic']} onClick={() => onTopicClick(topic)}>
          <div className={css['TopicsCareerPlanModal-topic-img-container']}>
            <img className={css['TopicsCareerPlanModal-topic-img']} src={coverImg} alt='topicThumb' />
          </div>
          <div className={css['TopicsCareerPlanModal-topic-block']}>
            <Tooltip title={topic?.name?.length > MAX_TOPIC_TITLE_LENGTH && topic?.name}>
              <p className={css['TopicsCareerPlanModal-topic-title']}>
                {Utils.trString(topic?.name, MAX_TOPIC_TITLE_LENGTH)}
              </p>
            </Tooltip>
            <div
              className={
                css[
                  isSelected
                    ? 'TopicsCareerPlanModal-topic-img-select--active'
                    : 'TopicsCareerPlanModal-topic-img-select'
                ]
              }
            >
              <AiOutlineCheck color='white' />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TopicModalItem.propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onTopicClick: PropTypes.func,
  topic: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    thumbFileUuid: PropTypes.any,
    type: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default TopicModalItem;
