/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { getBookAddresses, updateBookAddresses } from '@/store/books/actions';
import { getBlockStatistic } from '@/store/lms/actions';
import { clearPlan, createStudyPlan } from '@/store/study-plan/actions';
import { getStatisticTopics } from '@/store/user-topics/actions';
import { getUserById } from '@/store/users/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectUserTopics } from '@/store/user-topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Col, Layout, Row } from 'antd';
import AddressBook from '@/components/AddressBook/AddressBook';
import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction';

// import TabsLink from '@/components/TabsLink/TabsLink';

import ColleguesProfileHeader from './ColleguesProfileHeader';

import store from '@/store';
import Utils from '@/Utils';

import css from './ColleguesProfile.module.scss';

const { Content } = Layout;

const ColleguesProfile = () => {
  const location = useLocation();
  const { t } = useTranslation('colleaguesProfile');
  const { t: t2 } = useTranslation('collectionMaterials');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { anotherUser, currentUser } = useSelector(selectUsers);
  const { statistic } = useSelector(selectUserTopics);
  const { previousStudyPlansCheckResult } = useSelector(selectStudyPlan);

  const [placement, setPlacement] = useState(location.pathname.replace(`/collegues/${anotherUser.id}`, '') || '/info');

  const [addressId, setAddressId] = useState(0);
  const [addressState, setAddressState] = useState({
    isAddressBookVisible: false,
    calendarModalInfo: {},
    addresses: [],
    flagNow: false,
  });
  const [isModalCurrentPaidTopicOpen, setIsModalCurrentPaidTopicOpen] = useState(false);
  const [isModalPaidTopicRestrictionOpen, setIsModalPaidTopicRestrictionOpen] = useState(false);
  const [currentPaidTopicId, setCurrentPaidTopicId] = useState(null);
  const [currentPaidTopicName, setCurrentPaidTopicName] = useState('');
  const [handleStudyPlanClickState, setHandleStudyPlanClickState] = useState(null);

  useEffect(() => {
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      !previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setCurrentPaidTopicName(previousStudyPlansCheckResult?.topicName);
      setCurrentPaidTopicId(previousStudyPlansCheckResult?.topicId);
      setIsModalCurrentPaidTopicOpen(true);
    } else if (
      !previousStudyPlansCheckResult?.availableCreation &&
      previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setIsModalPaidTopicRestrictionOpen(true);
    }
  }, [previousStudyPlansCheckResult]);

  const handleAddressBookSubmit = currentAddressId => {
    const { topicId, type, startDate, topicInfo } = addressState?.calendarModalInfo;

    setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] });
    setAddressId(currentAddressId > 0 ? currentAddressId : 0);

    if (topicInfo.withNoEvents && addressState.flagNow) {
      addToPlanNowRequest(topicId, topicInfo.duration ?? 0);
      setAddressState({ flagNow: false });
    } else {
      showCalendarModal(topicId, type, startDate, topicInfo);
    }
  };

  const addToPlanNowRequest = (topicId, topicDuration = 0) => {
    const date = Utils.getPlanDate(topicDuration);

    dispatch(
      getBlockStatistic({
        topicId,
        userId: currentUser.id,
        onSuccess: response => {
          const blocksCount = response?.countBlocks || 0;

          if (addressId > 0) {
            dispatch(updateBookAddresses(addressId, currentUser.id));
            setAddressId(0);
          }

          dispatch(
            createStudyPlan(currentUser.id, topicId, date, '', () => {
              navigate(Utils.getNavigateUrl(topicId, blocksCount));
            })
          );
        },
      })
    );
  };

  // можно будет добавить параметр userId для конкретного пользователя
  const onAddToPlanNow = topic => {
    if (topic.qiwiLibrary) {
      const withNoEvents = Utils.isWithoutEvents(topic);

      const topicInfo = {
        id: topic.id,
        independent: topic.independent,
        startDate: topic.startDate,
        topicCost: topic.cost,
        topicDates: Array.isArray(topic.dates) && topic.dates.map(date => ({ dates: date.startDate })),
        link: topic.firstLink || topic.link || (topic.links && topic.links?.[0]?.url),
        eventCount: topic.futureEvents,
        topicComment: topic.comment,
        withNoEvents,
        duration: topic.duration,
      };

      dispatch(
        getBookAddresses(
          topic.id,
          data => {
            const addresses = data.map(item => ({
              id: item.id,
              address: item.addressDto.address,
              accessible: item.accessible,
            }));

            if (addresses.length > 0) {
              setAddressState({
                addresses,
                isAddressBookVisible: true,
                calendarModalInfo: {
                  topicId: topic.id,
                  type: topic.type,
                  startDate: topic.startDate,
                  topicInfo,
                },
                flagNow: true,
              });
              return;
            }

            addToPlanNowRequest(topic.id, topic.duration);
          },
          () => setAddressState({ ...addressState, addresses: [] })
        )
      );
    } else {
      addToPlanNowRequest(topic.id, topic.duration);
    }
  };

  const handleModalCurrentPaidTopicClose = () => {
    setIsModalCurrentPaidTopicOpen(false);
    dispatch(clearPlan());
  };

  const handleModalPaidTopicRestrictionClose = () => {
    setIsModalPaidTopicRestrictionOpen(false);
    dispatch(clearPlan());
  };

  return (
    <Content>
      <Row className={css['app-profline__box']}>
        <Col span={24} className={css['app-profline__container']}>
          <div className={css['app-profline__card']}>
            <div>
              <ColleguesProfileHeader user={anotherUser} />
              {/* <TabsLink  закомментировано по задаче https://kampus.atlassian.net/browse/KAM-5999
                basePage={`/collegues/${anotherUser.id}`}
                isProfile={true}
                placement={placement}
                setPlacement={setPlacement}
                tabsList={[
                  {
                    url: '/info',
                    name: t('information'),
                  },
                  {
                    url: '/recommended',
                    name: t('recommendedToMe'),
                    total: statistic?.recommendation !== 0 ? statistic?.recommendation : '',
                  },
                  {
                    url: '/my-recommendations',
                    name: t('iRecommended'),
                    total: statistic?.recommended !== 0 ? statistic.recommended : '',
                  },
                  {
                    url: '/added',
                    name: t('added'),
                    total: statistic?.created !== 0 ? statistic.created : '',
                  },
                  {
                    url: '/collegues-planning',
                    name: t('plan'),
                  },
                  {
                    url: '/finished',
                    name: t('complete'),
                  },
                ]}
                isAnother
              />
              <div className={css['app-profline__content']}>
                <Outlet
                  context={{
                    user: anotherUser,
                    hasAdditionalInfo: currentUser.domainCompany.hasAdditionalInfo,
                    onAddToPlanNow: topic => onAddToPlanNow(topic),
                    addressId: addressId,
                    setAddressId: setAddressId,
                    isAnother: true,
                    noEdit: true,
                    setHandleStudyPlanClickState: setHandleStudyPlanClickState,
                  }}
                />
              </div>*/}
              <AddressBook
                textWindowTitle={t2('addressBookWindowTitle')}
                textSubmitButton={t2('addressBookSubmitButton')}
                textAccessible={t2('addressBookaccessible')}
                textNotAccessible={t2('addressBookNotAccessible')}
                textReadOnline={t2('addressBookReadOnline')}
                onButtonClose={() =>
                  setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] })
                }
                onButtonSubmit={handleAddressBookSubmit}
                open={addressState.isAddressBookVisible}
                data={addressState?.addresses}
              />
              <ModalCurrentPaidTopic
                open={isModalCurrentPaidTopicOpen}
                onClose={handleModalCurrentPaidTopicClose}
                currentPaidTopicId={currentPaidTopicId}
                currentPaidTopicName={currentPaidTopicName}
              />
              <ModalPaidTopicRestriction
                open={isModalPaidTopicRestrictionOpen}
                onClose={handleModalPaidTopicRestrictionClose}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

const colleguesLoader = async props => {
  await store.dispatch(getUserById(true, props.params.id));
  await store.dispatch(getStatisticTopics(props.params.id));

  return null;
};

export { colleguesLoader };

export default ColleguesProfile;
