import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import PropTypes from 'prop-types';

import css from './ModalCloseIcon.module.scss';

const ModalCloseIcon = ({ onClick, className, tooltipText }) => {
  const { t } = useTranslation('collectionMaterialId');
  return (
    <Tooltip title={tooltipText || t('close')}>
      <div className={`${css['ModalCloseIcon']} ${className}`} onClick={onClick}>
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 18 17'>
          <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='currentColor' fill='none' />
        </svg>
      </div>
    </Tooltip>
  );
};

ModalCloseIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default ModalCloseIcon;
