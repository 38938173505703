/* eslint-disable */
import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ru_RU';

import { ThemeProvider } from './ThemeContext';

import { ServiceWorkerProvider } from '@shared/context/ServiceWorkerContext';

import store from '@/store';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
// Было добавленно определение withResolvers для старых версий браузера по задаче https://kampus.atlassian.net/browse/KAM-5273

if (typeof Promise.withResolvers === 'undefined' && window) {
  window.Promise.withResolvers = function () {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

const Root = () => {
  useLayoutEffect(() => {
    //Ручка для редиректа, после авторизации
    if (!document.location.pathname.includes('/user-login')) {
      localStorage.setItem('currentRedirect', document.location.pathname);
    }
  }, []);

  return (
    <ConfigProvider locale={locale}>
      <ServiceWorkerProvider>
        <Provider store={store}>
          <ThemeProvider />
        </Provider>
      </ServiceWorkerProvider>
    </ConfigProvider>
  );
};

export default Root;
