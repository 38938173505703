/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import { ACTIVE } from '@shared/constants/topic-statuses';
import getQueryString from '@shared/utils/get-query-string';

export const GET_TOPIC_TYPES_START = 'GET_TOPIC_TYPES_START';
export const GET_TOPIC_TYPES_SUCCESS = 'GET_TOPIC_TYPES_SUCCESS';
export const GET_TOPIC_TYPES_FAIL = 'GET_TOPIC_TYPES_FAIL';
export const GET_TOPIC_SUBTYPES_START = 'GET_TOPIC_SUBTYPES_START';
export const GET_TOPIC_SUBTYPES_SUCCESS = 'GET_TOPIC_SUBTYPES_SUCCESS';
export const GET_TOPIC_SUBTYPES_FAIL = 'GET_TOPIC_SUBTYPES_FAIL';
export const GET_TOPIC_CATEGORY_START = 'GET_TOPIC_CATEGORY_START';
export const GET_TOPIC_CATEGORY_SUCCESS = 'GET_TOPIC_CATEGORY_SUCCESS';
export const GET_TOPIC_CATEGORY_FAIL = 'GET_TOPIC_CATEGORY_FAIL';
export const GET_TOPIC_LEVEL_START = 'GET_TOPIC_LEVEL_START';
export const GET_TOPIC_LEVEL_SUCCESS = 'GET_TOPIC_LEVEL_SUCCESS';
export const GET_TOPIC_LEVEL_FAIL = 'GET_TOPIC_LEVEL_FAIL';
export const POST_TOPIC_START = 'POST_TOPIC_START';
export const POST_TOPIC_SUCCESS = 'POST_TOPIC_SUCCESS';
export const POST_TOPIC_FAIL = 'POST_TOPIC_FAIL';
export const GET_TOPIC_VIDEO_TYPES_START = 'GET_TOPIC_VIDEO_TYPES_START';
export const GET_TOPIC_VIDEO_TYPES_SUCCESS = 'GET_TOPIC_VIDEO_TYPES_SUCCESS';
export const GET_TOPIC_VIDEO_TYPES_FAIL = 'GET_TOPIC_VIDEO_TYPES_FAIL';
export const GET_TOPIC_CLASSES_START = 'GET_TOPIC_CLASSES_START';
export const GET_TOPIC_CLASSES_SUCCESS = 'GET_TOPIC_CLASSES_SUCCESS';
export const GET_TOPIC_CLASSES_FAIL = 'GET_TOPIC_CLASSES_FAIL';
export const CLEAR_TOPICS = 'CLEAR_TOPICS';
export const GET_TOPICS_START = 'GET_TOPICS_START';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'GET_TOPICS_FAIL';
export const GET_TOPIC_BY_ID_START = 'GET_TOPIC_BY_ID_START';
export const GET_TOPIC_BY_ID_SUCCESS = 'GET_TOPIC_BY_ID_SUCCESS';
export const GET_TOPIC_BY_ID_FAIL = 'GET_TOPIC_BY_ID_FAIL';
export const GET_TOPIC_BY_AUTHOR_START = 'GET_TOPIC_BY_AUTHOR_START';
export const GET_TOPIC_BY_AUTHOR_SUCCESS = 'GET_TOPIC_BY_AUTHOR_SUCCESS';
export const GET_TOPIC_BY_AUTHOR_FAIL = 'GET_TOPIC_BY_AUTHOR_FAIL';
export const GET_TOPIC_CRE_BY_CURUSER_START = 'GET_TOPIC_CRE_BY_CURUSER_START';
export const GET_TOPIC_CRE_BY_CURUSER_SUCCESS = 'GET_TOPIC_CRE_BY_CURUSER_SUCCESS';
export const GET_TOPIC_CRE_BY_CURUSER_FAIL = 'GET_TOPIC_CRE_BY_CURUSER_FAIL';
export const CHANGE_TOPIC_FILTER = 'CHANGE_TOPIC_FILTER';
export const CHANGE_TOPIC_FILTER_BLOCK = 'CHANGE_TOPIC_FILTER_BLOCK';
export const POST_TOPIC_VIEWS_START = 'POST_TOPIC_VIEWS_START';
export const POST_TOPIC_VIEWS_SUCCESS = 'POST_TOPIC_VIEWS_SUCCESS';
export const POST_TOPIC_VIEWS_FAIL = 'POST_TOPIC_VIEWS_FAIL';
export const UPDATE_TOPIC_START = 'UPDATE_TOPIC_START';
export const UPDATE_TOPIC_SUCCESS = 'UPDATE_TOPIC_SUCCESS';
export const UPDATE_TOPIC_FAIL = 'UPDATE_TOPIC_FAIL';
export const GET_TOPIC_COMMENTS_START = 'GET_TOPIC_COMMENTS_START';
export const GET_TOPIC_COMMENTS_SUCCESS = 'GET_TOPIC_COMMENTS_SUCCESS';
export const GET_TOPIC_COMMENTS_FAIL = 'GET_TOPIC_COMMENTS_FAIL';
export const GET_TOPIC_MENTIONS_START = 'GET_TOPIC_MENTIONS_START';
export const GET_TOPIC_MENTIONS_SUCCESS = 'GET_TOPIC_MENTIONS_SUCCESS';
export const GET_TOPIC_MENTIONS_FAIL = 'GET_TOPIC_MENTIONS_FAIL';
export const POST_TOPIC_COMMENT_START = 'POST_TOPIC_COMMENT_START';
export const POST_TOPIC_COMMENT_SUCCESS = 'POST_TOPIC_COMMENT_SUCCESS';
export const POST_TOPIC_COMMENT_FAIL = 'POST_TOPIC_COMMENT_FAIL';
export const CHECK_TOPIC_COMMENT_START = 'CHECK_TOPIC_COMMENT_START';
export const CHECK_TOPIC_COMMENT_SUCCESS = 'CHECK_TOPIC_COMMENT_SUCCESS';
export const CHECK_TOPIC_COMMENT_FAIL = 'CHECK_TOPIC_COMMENT_FAIL';
export const DELETE_AUTHORS_START = 'DELETE_AUTHORS_START';
export const DELETE_AUTHORS_SUCCESS = 'DELETE_AUTHORS_SUCCESS';
export const DELETE_AUTHORS_FAIL = 'DELETE_AUTHORS_FAIL';
export const DELETE_TOPIC_COMMENT_START = 'DELETE_TOPIC_COMMENT_START';
export const DELETE_TOPIC_COMMENT_SUCCESS = 'DELETE_TOPIC_COMMENT_SUCCESS';
export const DELETE_TOPIC_COMMENT_FAIL = 'DELETE_TOPIC_COMMENT_FAIL';
export const RESTORE_TOPIC_COMMENT_START = 'RESTORE_TOPIC_COMMENT_START';
export const RESTORE_TOPIC_COMMENT_SUCCESS = 'RESTORE_TOPIC_COMMENT_SUCCESS';
export const RESTORE_TOPIC_COMMENT_FAIL = 'RESTORE_TOPIC_COMMENT_FAIL';
export const UPDATE_TOPIC_COMMENT_START = 'UPDATE_TOPIC_COMMENT_START';
export const UPDATE_TOPIC_COMMENT_SUCCESS = 'UPDATE_TOPIC_COMMENT_SUCCESS';
export const UPDATE_TOPIC_COMMENT_FAIL = 'UPDATE_TOPIC_COMMENT_FAIL';
export const UPDATE_TOPIC_MENTION_START = 'UPDATE_TOPIC_MENTION_START';
export const UPDATE_TOPIC_MENTION_SUCCESS = 'UPDATE_TOPIC_MENTION_SUCCESS';
export const UPDATE_TOPIC_MENTION_FAIL = 'UPDATE_TOPIC_MENTION_FAIL';
export const CLEAR_TOPIC_COMMENT = 'CLEAR_TOPIC_COMMENT';
export const CLEAR_TOPIC_MENTION = 'CLEAR_TOPIC_MENTION';
export const CLEAR_TOPIC_FILTER = 'CLEAR_TOPIC_FILTER';
export const TOPIC_VALIDATION_FAIL = 'TOPIC_VALIDATION_FAIL';
export const SHOW_SEND_REVIEW_MODAL = 'SHOW_SEND_REVIEW_MODAL';
export const HIDE_SEND_REVIEW_MODAL = 'HIDE_SEND_REVIEW_MODAL';
export const ADD_COMMENT_TO_LIST = 'ADD_COMMENT_TO_LIST';
export const GET_TOPIC_CRE_BY_ANOTHER_USER_START = 'GET_TOPIC_CRE_BY_ANOTHER_USER_START';
export const GET_TOPIC_CRE_BY_ANOTHER_USER_SUCCESS = 'GET_TOPIC_CRE_BY_ANOTHER_USER_SUCCESS';
export const GET_TOPIC_CRE_BY_ANOTHER_USER_FAIL = 'GET_TOPIC_CRE_BY_ANOTHER_USER_FAIL';

export const GET_TOPICS_COMPILATION_START = 'GET_TOPICS_COMPILATION_START';
export const GET_TOPICS_COMPILATION_SUCCESS = 'GET_TOPICS_COMPILATION_SUCCESS';
export const GET_TOPICS_COMPILATION_FAIL = 'GET_TOPICS_COMPILATION_FAIL';
export const CLEAR_TOPICS_COMPILATION = 'CLEAR_TOPICS_COMPILATION';

export const CHECK_TOPIC_DUPLICATE_START = 'CHECK_TOPIC_DUPLICATE_START';
export const CHECK_TOPIC_DUPLICATE_SUCCESS = 'CHECK_TOPIC_DUPLICATE_SUCCESS';
export const CHECK_TOPIC_DUPLICATE_FAIL = 'CHECK_TOPIC_DUPLICATE_FAIL';

export const GET_ALPINA_LINK_START = 'GET_ALPINA_LINK_START';
export const GET_ALPINA_LINK_SUCCESS = 'GET_ALPINA_LINK_SUCCESS';
export const GET_ALPINA_LINK_FAIL = 'GET_ALPINA_LINK_FAIL';

export const CLEAR_SUBTYPES = 'CLEAR_SUBTYPES';
export const CLEAR_DUPLICATE = 'CLEAR_DUPLICATE';

export const GET_COUNT_COMMENT_START = 'GET_COUNT_COMMENT_START';
export const GET_COUNT_COMMENT_SUCCESS = 'GET_COUNT_COMMENT_SUCCESS';
export const GET_COUNT_COMMENT_FAIL = 'GET_COUNT_COMMENT_FAIL';

export const GET_COUNT_REVIEW_START = 'GET_COUNT_REVIEW_START';
export const GET_COUNT_REVIEW_SUCCESS = 'GET_COUNT_REVIEW_SUCCESS';
export const GET_COUNT_REVIEW_FAIL = 'GET_COUNT_REVIEW_FAIL';

export const UPDATE_TOPIC_VISIBILITY_START = 'UPDATE_TOPIC_VISIBILITY_START';
export const UPDATE_TOPIC_VISIBILITY_SUCCESS = 'UPDATE_TOPIC_VISIBILITY_SUCCESS';
export const UPDATE_TOPIC_VISIBILITY_FAIL = 'UPDATE_TOPIC_VISIBILITY_FAIL';

export const GET_TOPIC_COMPETENCIES_START = 'GET_TOPIC_COMPETENCIES_START';
export const GET_TOPIC_COMPETENCIES_SUCCESS = 'GET_TOPIC_COMPETENCIES_SUCCESS';
export const GET_TOPIC_COMPETENCIES_FAIL = 'GET_TOPIC_COMPETENCIES_FAIL';

export const GET_TOPIC_MANDATORY_START = 'GET_TOPIC_MANDATORY_START';
export const GET_TOPIC_MANDATORY_SUCCESS = 'GET_TOPIC_MANDATORY_SUCCESS';
export const GET_TOPIC_MANDATORY_FAIL = 'GET_TOPIC_MANDATORY_FAIL';

export const GET_TOPIC_JOB_LEVEL_START = 'GET_TOPIC_JOB_LEVEL_START';
export const GET_TOPIC_JOB_LEVEL_SUCCESS = 'GET_TOPIC_JOB_LEVEL_SUCCESS';
export const GET_TOPIC_JOB_LEVEL_FAIL = 'GET_TOPIC_JOB_LEVEL_FAIL';

export const POST_TOPIC_COMMENT_REPLY_START = 'POST_TOPIC_COMMENT_REPLY_START';
export const POST_TOPIC_COMMENT_REPLY_SUCCESS = 'POST_TOPIC_COMMENT_REPLY_SUCCESS';
export const POST_TOPIC_REPLY_COMMENT_FAIL = 'POST_TOPIC_REPLY_COMMENT_FAIL';

export const ADD_MENTIONS_TO_LIST = 'ADD_MENTIONS_TO_LIST';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

const getTopicFilterApiValues = filter => {
  const { tag, ...rest } = filter;
  return tag && tag.id ? { ...rest, tagId: tag.id } : rest;
};

const getTopicBodyApiValues = body => {
  const skillNames = body.skills && body.skills.map(skill => skill.name.toLowerCase());
  const tagNames = body.tagNames && body.tagNames.map(tag => tag.name.toLowerCase());
  const authorNames =
    body.authors &&
    body.authors.map(author => {
      if (author.name) {
        return author.name;
      }
      return author;
    });
  return {
    ...body,
    tagNames,
    skillNames,
    authorNames,
  };
};

export const getTopicTypes = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_TYPES_START, GET_TOPIC_TYPES_SUCCESS, GET_TOPIC_TYPES_FAIL],
  onSuccess,
});

export const getAlpinaCredentials = (domain, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/alpina/link`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ALPINA_LINK_START, GET_ALPINA_LINK_SUCCESS, GET_ALPINA_LINK_FAIL],
  onSuccess,
  onError,
});

export const getAlpinaLink = (url, onSuccess) => ({
  type: REST_API,
  method: GET,
  url,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ALPINA_LINK_START, GET_ALPINA_LINK_SUCCESS, GET_ALPINA_LINK_FAIL],
  onSuccess,
});

export const checkDuplicate = (params, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/duplicate${getQueryString({ size: 10 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CHECK_TOPIC_DUPLICATE_START, CHECK_TOPIC_DUPLICATE_SUCCESS, CHECK_TOPIC_DUPLICATE_FAIL],
  onSuccess,
});

export const clearDuplicate = () => ({
  type: CLEAR_DUPLICATE,
});

export const getTopicSubTypes = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_TOPIC_SUBTYPES_START, GET_TOPIC_SUBTYPES_SUCCESS, GET_TOPIC_SUBTYPES_FAIL],
});

export const clearSubType = () => ({
  type: CLEAR_SUBTYPES,
});

export const clearTopics = () => ({
  type: CLEAR_TOPICS,
});

export const getTopics = (filter = {}, onSuccess, signal, isList) => {
  const isFavorites = filter.recommend === false;
  const apiFilter = getTopicFilterApiValues(filter);
  const externalLogin = !isFavorites && localStorage.getItem('externalLogin');
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}${isFavorites ? '/user' : ''}/topic${getQueryString({
      size: externalLogin ? 20 : 19,
      status: ACTIVE,
      isPersonalized: true,
      // isVisible: true, после теста видимости топиков удалить
      ...apiFilter,
      page: apiFilter.page - 1,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPICS_START, GET_TOPICS_SUCCESS, GET_TOPICS_FAIL],
    onSuccess,
    isFavorites,
    signal,
    isList,
  };
};

export const getTopicsCompilation = (filter = {}, onSuccess) => {
  const apiFilter = getTopicFilterApiValues(filter);
  const externalLogin = localStorage.getItem('externalLogin');
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic${getQueryString({
      size: externalLogin ? 20 : 19,
      sort: 'position,asc&sort=activity,desc',
      status: ACTIVE,
      isPersonalized: true,
      // isVisible: true, после теста видимости топиков удалить
      ...apiFilter,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPICS_COMPILATION_START, GET_TOPICS_COMPILATION_SUCCESS, GET_TOPICS_COMPILATION_FAIL],
    onSuccess,
  };
};

export const clearTopicsCompilation = () => ({
  type: CLEAR_TOPICS_COMPILATION,
});

export const getTopicById = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_BY_ID_START, GET_TOPIC_BY_ID_SUCCESS, GET_TOPIC_BY_ID_FAIL],
  onSuccess,
  onError,
});

export const getTopicOffline = response => ({
  type: GET_TOPIC_BY_ID_SUCCESS,
  response,
});

export const getTopicByAuthor = apiFilter => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic${getQueryString({ ...apiFilter, status: ACTIVE })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_BY_AUTHOR_START, GET_TOPIC_BY_AUTHOR_SUCCESS, GET_TOPIC_BY_AUTHOR_FAIL],
});

export const getTopicCreByCurUser = (apiFilter, isAnother, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic${getQueryString({
    sort: 'createdDate,desc',
    ...apiFilter,
    status: ACTIVE,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: !isAnother
    ? [GET_TOPIC_CRE_BY_CURUSER_START, GET_TOPIC_CRE_BY_CURUSER_SUCCESS, GET_TOPIC_CRE_BY_CURUSER_FAIL]
    : [GET_TOPIC_CRE_BY_ANOTHER_USER_START, GET_TOPIC_CRE_BY_ANOTHER_USER_SUCCESS, GET_TOPIC_CRE_BY_ANOTHER_USER_FAIL],
});

export const getTopicCategories = (isGlobal, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/category${getQueryString({ isGlobal })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_CATEGORY_START, GET_TOPIC_CATEGORY_SUCCESS, GET_TOPIC_CATEGORY_FAIL],
  onSuccess,
});

export const getTopicVideoTypes = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/type/video`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_VIDEO_TYPES_START, GET_TOPIC_VIDEO_TYPES_SUCCESS, GET_TOPIC_VIDEO_TYPES_FAIL],
});

export const getTopicClasses = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/class`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_CLASSES_START, GET_TOPIC_CLASSES_SUCCESS, GET_TOPIC_CLASSES_FAIL],
});

export const getTopicLevel = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/level`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_LEVEL_START, GET_TOPIC_LEVEL_SUCCESS, GET_TOPIC_LEVEL_FAIL],
  onSuccess,
});

export const createTopic = (topic = {}, onSuccess, onError, hasManager) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(topic, hasManager),
  types: [POST_TOPIC_START, POST_TOPIC_SUCCESS, POST_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const editTopic = ({ id, ...topic }, onSuccess, onError, hasManager) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(id)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(topic, hasManager),
  types: [UPDATE_TOPIC_START, UPDATE_TOPIC_SUCCESS, UPDATE_TOPIC_FAIL],
  onSuccess,
  onError,
});

export const increaseTopicViews = id => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${encodeURI(id)}/click`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_TOPIC_VIEWS_START, POST_TOPIC_VIEWS_SUCCESS, POST_TOPIC_VIEWS_FAIL],
});

export const getTopicComments = (topicId, page, mention = false, size = 10, onSuccess) =>
  topicId && {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/comment${getQueryString({
      topicId,
      page,
      mention,
      size,
      sort: 'createdDate,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPIC_COMMENTS_START, GET_TOPIC_COMMENTS_SUCCESS, GET_TOPIC_COMMENTS_FAIL],
    onSuccess,
  };

export const clearTopicComment = () => ({
  type: CLEAR_TOPIC_COMMENT,
});

export const clearTopicMention = () => ({
  type: CLEAR_TOPIC_MENTION,
});

export const getTopicMentions = (topicId, page, mention = true, size = 10, onSuccess) =>
  topicId && {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/comment${getQueryString({
      topicId,
      page,
      mention,
      size,
      sort: 'createdDate,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_TOPIC_MENTIONS_START, GET_TOPIC_MENTIONS_SUCCESS, GET_TOPIC_MENTIONS_FAIL],
    onSuccess,
  };

export const createTopicComment = (comment, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(comment),
  types: [POST_TOPIC_COMMENT_START, POST_TOPIC_COMMENT_SUCCESS, POST_TOPIC_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const deleteTopicComment = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/comment/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_TOPIC_COMMENT_START, DELETE_TOPIC_COMMENT_SUCCESS, DELETE_TOPIC_COMMENT_FAIL],
  onSuccess,
});

export const restoreTopicComment = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/comment/restore/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [RESTORE_TOPIC_COMMENT_START, RESTORE_TOPIC_COMMENT_SUCCESS, RESTORE_TOPIC_COMMENT_FAIL],
  onSuccess,
});

export const updateTopicComment = (id, comment, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/comment/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(comment),
  types: [UPDATE_TOPIC_COMMENT_START, UPDATE_TOPIC_COMMENT_SUCCESS, UPDATE_TOPIC_COMMENT_FAIL],
  onSuccess,
});

export const updateTopicMention = (id, comment, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/comment/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: getTopicBodyApiValues(comment),
  types: [UPDATE_TOPIC_MENTION_START, UPDATE_TOPIC_MENTION_SUCCESS, UPDATE_TOPIC_MENTION_FAIL],
  onSuccess,
});

export const checkTopicComment = (topicId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/comment/mention/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_TOPIC_COMMENT_START, CHECK_TOPIC_COMMENT_SUCCESS, CHECK_TOPIC_COMMENT_FAIL],
  onSuccess,
});

export const deleteAuthors = (name, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/author${getQueryString({ name: encodeURIComponent(name) })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_AUTHORS_START, DELETE_AUTHORS_SUCCESS, DELETE_AUTHORS_FAIL],
  onSuccess,
});

export const changeTopicFilter = (name, value, onSuccess) => ({
  type: CHANGE_TOPIC_FILTER,
  name,
  value,
  onSuccess,
});

export const changeTopicFilterBlock = (name, value) => ({
  type: CHANGE_TOPIC_FILTER_BLOCK,
  name,
  value,
});

export const clearTopicFilter = () => ({
  type: CLEAR_TOPIC_FILTER,
});

export const failTopicValidation = errorMessage => ({
  errorMessage,
  type: TOPIC_VALIDATION_FAIL,
});

export const showSendReviewModal = (topicId, isPayCourses) => ({
  type: SHOW_SEND_REVIEW_MODAL,
  value: topicId,
  isPayCourses,
});

export const hideSendReviewModal = () => ({
  type: HIDE_SEND_REVIEW_MODAL,
});

export const addTopicCommentsToList = comment => ({
  type: ADD_COMMENT_TO_LIST,
  comment,
});

export const getCountComment = (params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/comment/count${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COUNT_COMMENT_START, GET_COUNT_COMMENT_SUCCESS, GET_COUNT_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const getCountReview = (params, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/comment/count${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COUNT_REVIEW_START, GET_COUNT_REVIEW_SUCCESS, GET_COUNT_REVIEW_FAIL],
  onSuccess,
  onError,
});

export const editTopicVisibility = (id, isVisible, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/${id}/visible${getQueryString({ isVisible })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  isVisible,
  types: [UPDATE_TOPIC_VISIBILITY_START, UPDATE_TOPIC_VISIBILITY_SUCCESS, UPDATE_TOPIC_VISIBILITY_FAIL],
  onSuccess,
  onError,
});

export const getTopicCategoryCompetencies = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/competency`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_COMPETENCIES_START, GET_TOPIC_COMPETENCIES_SUCCESS, GET_TOPIC_COMPETENCIES_FAIL],
});

export const getTopicMandatory = isAdminPart => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/mandatory${getQueryString({ isAdminPart })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_MANDATORY_START, GET_TOPIC_MANDATORY_SUCCESS, GET_TOPIC_MANDATORY_FAIL],
});

export const getTopicJobLevels = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/jobLevel`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TOPIC_JOB_LEVEL_START, GET_TOPIC_JOB_LEVEL_SUCCESS, GET_TOPIC_JOB_LEVEL_FAIL],
});

export const createTopicCommentReply = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/comment/reply`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [POST_TOPIC_COMMENT_REPLY_START, POST_TOPIC_COMMENT_REPLY_SUCCESS, POST_TOPIC_REPLY_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const addTopicMentionsToList = mention => ({
  type: ADD_MENTIONS_TO_LIST,
  mention,
});
