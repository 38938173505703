export const REQUEST_SEND = 'requestSend';
export const REQUEST_MENTORING = 'requestMentoring';
export const WAITING_LIST = 'waitingList';
export const WAITING_MENTOR = 'WAITING_MENTOR';
export const ACTIVE = 'ACTIVE';
export const ON_WAITING_LIST = 'ON_WAITING_LIST';
export const CANCEL_REQUEST_MENTORING = 'CANCEL_REQUEST_MENTORING';
export const CLOSE = 'close';
export const FINISHED = 'FINISHED';
export const REJECTED_BY_MENTOR = 'REJECTED_BY_MENTOR';
