/* eslint-disable */

import { GET_CHAPTERS_FAIL, GET_CHAPTERS_START, GET_CHAPTERS_SUCCESS } from './actions';

import _sortBy from 'lodash/sortBy';

const initialState = {
  chapters: [],
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_CHAPTERS_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case GET_CHAPTERS_SUCCESS:
      const chapters = response
        .map(chapter => {
          const blocks = chapter.blocks.filter(block => !block.isDeleted);
          return { ...chapter, blocks };
        })
        .filter(data => data.active && data.blocks.length);
      return {
        ...state,
        chapters: _sortBy(chapters, ['position']),
        isLoading: false,
        error: false,
      };

    case GET_CHAPTERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    default:
      return state;
  }
};
