/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AutosizeInput from 'react-input-autosize';

import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ThemesEditor.module.scss';

class TreeNodeTitle extends Component {
  static propTypes = {
    delTheme: PropTypes.func,
    error: PropTypes.string,
    getThemes: PropTypes.func,
    id: PropTypes.number,
    isCheckable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isValid: PropTypes.func,
    key: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onlyView: PropTypes.bool,
    themes: PropTypes.array,
    updateTheme: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isEdit: false,
      name: props.name,
      error: {},
    };
  }

  onEdit = () =>
    this.setState(
      {
        isEdit: !this.state.isEdit,
      },
      () => {
        if (this.state.isEdit) {
          this.nameInput.focus();
        }
      }
    );

  handleChange = event => {
    const { value } = event.target;
    this.props.onChange(event);
    this.setState({ name: value });
  };

  updateTheme = () => {
    this.props.updateTheme(this.state.name);
    this.onEdit();
  };

  render() {
    const { id, error, isCheckable, onlyView, isSelected } = this.props;
    const { name } = this.state;

    return (
      <div
        key={id}
        className={classNames(
          css.TreeNode__title,
          { [css.onlyView]: onlyView },
          { [css.active]: isSelected },
          { [css.onlyView__long]: name.length >= 25 }
        )}
      >
        {this.state.isEdit ? (
          <AutosizeInput
            className={css.TreeNode__input}
            placeholder={this.props.t('theme')}
            name='theme'
            value={name}
            maxLength={50}
            onChange={this.handleChange}
            ref={input => {
              this.nameInput = input;
            }}
          />
        ) : (
          name
        )}
        {!onlyView && (
          <div className={css.TreeNode__actions}>
            {!isCheckable &&
              (!this.state.isEdit ? (
                <EditOutlined className={css.TreeNode__btns} onClick={this.onEdit} />
              ) : (
                <CheckOutlined
                  className={classNames(css.TreeNode__btns, {
                    [css.TreeNode__btns__error]: !!error,
                  })}
                  onClick={this.updateTheme}
                />
              ))}
            {!isCheckable && !this.state.isEdit && (
              <CloseOutlined onClick={this.props.delTheme} className={css.TreeNode__btns} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('treeNodeTitle')(TreeNodeTitle);
