import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkPreviousStudyPlans, clearPlan } from '@/store/study-plan/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';

import { Button } from 'antd';
import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction';
import AudienceConfirmationModal from '@/components/ModalTargetAudience/Modal';

import { PlanStatus } from '@shared/constants/plan';
import {
  isAnyCourseOrConference,
  isConference,
  isCorporateCourse,
  isExecutiveTask,
  isExternalCourse,
  shouldCheckPreviousStudyPlans,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';

import css from '../CollectionMaterialsIDStudy.module.scss';

import { APPROVED, APPROVED_STATUSES, FINISHED, WAITING, WAITING_BY_USER } from '@/constants/study-plan-statuses';

const TopicStudyInfo = ({
  t,
  topic,
  statistic,
  isFinished,
  isLoading,
  topicTypeId,
  alreadyFinished,
  topicPlanStatus,
  next,
  handleEndMaterialButton,
  handleEndMaterialButtonOK,
  setShowCalendar,
  isExecutiveFinished,
  handleAddToPlanNow,
}) => {
  const [autoFinish, setAutoFinish] = useState(false);
  const [isAudienceConfirmationModalOpen, setIsAudienceConfirmationModalOpen] = useState(false);
  const [isModalCurrentPaidTopicOpen, setIsModalCurrentPaidTopicOpen] = useState(false);
  const [isModalPaidTopicRestrictionOpen, setIsModalPaidTopicRestrictionOpen] = useState(false);

  const [currentPaidTopicId, setCurrentPaidTopicId] = useState(null);
  const [currentPaidTopicName, setCurrentPaidTopicName] = useState('');

  const dispatch = useDispatch();
  const { previousStudyPlansCheckResult } = useSelector(selectStudyPlan);

  const withNoEvents = Utils.isWithoutEvents(topic);

  useEffect(() => {
    if (
      !isLoading &&
      topicPlanStatus === 'inPlan' &&
      statistic &&
      isNot25Type &&
      !next &&
      !isFinished &&
      !alreadyFinished &&
      statistic.isAvailableForViewBlocks &&
      !autoFinish
    ) {
      setAutoFinish(true);
      handleEndMaterialButtonOK();
    }
  }, [statistic]);

  useEffect(() => {
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      !previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setCurrentPaidTopicName(previousStudyPlansCheckResult?.topicName);
      setCurrentPaidTopicId(previousStudyPlansCheckResult?.topicId);
      setIsModalCurrentPaidTopicOpen(true);
    }
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setIsModalPaidTopicRestrictionOpen(true);
    }
    if (previousStudyPlansCheckResult?.availableCreation) {
      setShowCalendar();
    }
  }, [previousStudyPlansCheckResult]);

  if (isLoading) return null;

  const isNot25Type = !isExecutiveTask(topicTypeId);
  const isCorpCourse = isCorporateCourse(topicTypeId);

  const isWaiting = topic?.planStatus?.planStatus === WAITING;
  const isApprovedStatus = topic?.planStatus?.planStatus === APPROVED;
  const isApproved = APPROVED_STATUSES.includes(topic?.planStatus?.planStatus);

  const handleModalConfirm = () => {
    setIsAudienceConfirmationModalOpen(false);
    if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
    } else {
      setShowCalendar();
    }
  };

  const handleModalCancel = () => {
    setIsAudienceConfirmationModalOpen(false);
  };

  const handleStudyPlanClickWithCheck = () => {
    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      setIsAudienceConfirmationModalOpen(true);
    } else if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
    } else {
      setShowCalendar();
    }
  };

  const handleModalCurrentPaidTopicClose = () => {
    setIsModalCurrentPaidTopicOpen(false);
    dispatch(clearPlan());
  };

  const handleModalPaidTopicRestrictionClose = () => {
    setIsModalPaidTopicRestrictionOpen(false);
    dispatch(clearPlan());
  };

  const isExternalOrConference = isExternalCourse(topic?.type?.id) || isConference(topic?.type?.id);

  if (isWaiting && (isCorpCourse || isExternalOrConference)) {
    return <div className={css['Content-left-col-finished']}>{t('materialWaiting')}</div>;
  }

  if (
    (isApproved && isCorpCourse) ||
    (!statistic.isAvailableForViewBlocks &&
      (topicPlanStatus === WAITING_BY_USER || isApprovedStatus) &&
      isExternalOrConference)
  ) {
    return <div className={css['Content-left-col-finished']}>{t('approvedCorpCourse')}</div>;
  }

  if ((isFinished || alreadyFinished) && isNot25Type) {
    return <div className={css['Content-left-col-finished']}>{t('materialEnded')}</div>;
  }

  if (topicPlanStatus === 'inPlan' && statistic && isNot25Type) {
    if (!statistic.isAvailableForViewBlocks) {
      return <div className={css['Content-left-col-finished']}>{t('wait')}</div>;
    }
  }

  if (topicPlanStatus === 'addToPlan') {
    return (
      <div className={css['Content-redirect']}>
        {withNoEvents && (
          <Button className='add_to_plan_button' type='primary' size='large' onClick={handleAddToPlanNow}>
            {t('addToPlanNow')}
          </Button>
        )}
        <Button className='add_to_plan_button' type='primary' size='large' onClick={handleStudyPlanClickWithCheck}>
          {withNoEvents ? t('addToPlan') : t('addToPlanOld')}
        </Button>
        <AudienceConfirmationModal
          open={isAudienceConfirmationModalOpen}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
        <ModalCurrentPaidTopic
          open={isModalCurrentPaidTopicOpen}
          onClose={handleModalCurrentPaidTopicClose}
          currentPaidTopicId={currentPaidTopicId}
          currentPaidTopicName={currentPaidTopicName}
        />
        <ModalPaidTopicRestriction
          open={isModalPaidTopicRestrictionOpen}
          onClose={handleModalPaidTopicRestrictionClose}
        />
      </div>
    );
  }

  if (topicPlanStatus === 'unactive') {
    return (
      <div className={css['Content-redirect']}>
        <Button disabled size='large' type='primary'>
          {t('unActive')}
        </Button>
      </div>
    );
  }

  if (isNot25Type || topicPlanStatus !== 'inPlan') {
    return null;
  }

  if (statistic?.isAvailableForViewBlocks && !next && !isFinished && !alreadyFinished && isExecutiveFinished) {
    return (
      <Button
        onClick={() => handleEndMaterialButton()}
        className={css['endMaterialCenterButton']}
        size='large'
        type='primary'
      >
        {t('endExecutiveTask')}
      </Button>
    );
  }

  if (
    !next &&
    topic?.planStatus?.planStatus === PlanStatus.REJECTED_BY_MANAGER &&
    !isFinished &&
    !alreadyFinished &&
    isExecutiveFinished
  ) {
    return (
      <Button
        onClick={() => handleEndMaterialButton()}
        className={css['endMaterialCenterButton']}
        size='large'
        type='primary'
      >
        {t('endExecutiveTask')}
      </Button>
    );
  }

  if (statistic && topic?.planStatus) {
    if (topic.planStatus.planStatus === PlanStatus.WAITING) {
      return <div className={css['Content-left-col-finished']}>{t('executiveWait')}</div>;
    }

    if (topic.planStatus.planStatus === PlanStatus.REJECTED_BY_MANAGER) {
      return <div className={css['Content-left-col-finished']}>{t('executiveRejected')}</div>;
    }

    if (topic.planStatus.planStatus === PlanStatus.FINISHED) {
      return <div className={css['Content-left-col-finished']}>{t('materialEnded')}</div>;
    }
  }

  return null;
};

export default TopicStudyInfo;
