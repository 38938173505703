/* eslint-disable */
import React, { Component } from 'react';

import { Rate } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Rate.module.scss';

export default class CustomRate extends Component {
  static propTypes = {
    className: PropTypes.string,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    name: PropTypes.string,
    noChange: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.string,
    value: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  static sizes = {
    lg: 'lg',
    md: 'md',
  };

  handleChange = value => {
    const { onChange, name } = this.props;
    onChange({ name, value });
  };

  render() {
    const { icon, className, dark, noChange, disabled, size, ...rest } = this.props;

    return (
      <Rate
        {...rest}
        allowHalf={disabled}
        disabled={disabled}
        className={classNames(
          css.Rate,
          className,
          { [css.dark]: dark },
          { [css.noChange]: noChange },
          { [css[size]]: size }
        )}
        onChange={this.handleChange}
      />
    );
  }
}
