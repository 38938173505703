import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearPokaSkillsFilters,
  getCatPoka,
  getPokaSkills,
  getPokaStatus,
  savePokaSkillsFilters,
} from '@/store/poka/actions';

import { selectPokaSkills } from '@/store/poka/selectors';

import { Button, Col, Input, Row, Select } from 'antd';

import useIsMobile from '@shared/hooks/useIsMobile';

import Utils from '@/Utils';
import _ from 'lodash';

import css from '../PokaSkills.module.scss';

const FiltersPokaSkills = () => {
  const { t } = useTranslation('pokaSkills');
  const dispatch = useDispatch();

  const { /*pokaSkillsSort,*/ pokaStatus, pokaCategory } = useSelector(selectPokaSkills);

  const [filters, setFilters] = useState({ topicName: '', pokaPlanStatus: '', categories: [], studyPlanStatus: '' });
  const [isMobile] = useIsMobile(576);

  const deleteEmpty = arr => {
    let copyData = _.cloneDeep(arr);

    for (let key in copyData) {
      if (!copyData[key]) {
        delete copyData[key];
      }

      if (Array.isArray(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }
    return copyData;
  };

  const optStudyPlanStatus = [
    {
      value: 'WAITING',
      label: t('statusWaiting'),
    },
    {
      value: 'REJECTED_BY_MANAGER',
      label: t('statusRejectedByManager'),
    },
  ];

  const onSubmitFilters = () => {
    const data = deleteEmpty(filters);

    dispatch(savePokaSkillsFilters(data));
    dispatch(getPokaSkills(data, 0 /*pokaSkillsSort*/));
  };

  const onResetFilters = () => {
    setFilters({ topicName: '', pokaPlanStatus: '', categories: [], studyPlanStatus: '' });
    dispatch(getPokaSkills({}, 0));
    dispatch(clearPokaSkillsFilters());
  };

  useEffect(() => {
    if (!pokaStatus.length) dispatch(getPokaStatus());
    if (!pokaCategory.length) dispatch(getCatPoka());
    dispatch(getPokaSkills({}));
  }, []);

  return (
    <div className={css['Filters']}>
      <Row gutter={[8, 16]} justify='end'>
        {/* Поиск */}
        <Col xs={24} md={12}>
          <Input
            onChange={e => setFilters({ ...filters, topicName: e.target.value })}
            value={filters.topicName}
            placeholder={t('placeholderSearch')}
            allowClear
          />
        </Col>

        {/* Категория навыка */}
        <Col xs={24} md={12} className={css['Filters-block-col']}>
          <Select
            className={css['Filters-block-select']}
            value={filters.categories}
            allowClear
            showSearch
            onChange={e => setFilters({ ...filters, categories: e })}
            optionFilterProp='label'
            placeholder={t('placeholderCatSkillsSelect')}
            options={Utils.renderOptions(pokaCategory)}
            mode='multiple'
          />
        </Col>

        {/* Статус */}
        <Col xs={24} md={12}>
          <Select
            className={css['Filters-block-select']}
            value={filters.pokaPlanStatus || undefined}
            allowClear
            showSearch
            onChange={e => setFilters({ ...filters, pokaPlanStatus: e })}
            optionFilterProp='label'
            placeholder={t('placeholderStatusSelect')}
            options={Utils.renderOptions(pokaStatus)}
          />
        </Col>

        {/* Статус валидации */}
        <Col xs={24} md={12} className={css['Filters-block-col']}>
          <Select
            className={css['Filters-block-select']}
            value={filters.studyPlanStatus || undefined}
            allowClear
            showSearch
            onChange={e => setFilters({ ...filters, studyPlanStatus: e })}
            optionFilterProp='label'
            placeholder={t('placeholderStudyStatusSelect')}
            options={optStudyPlanStatus}
          />
        </Col>

        <Col xs={isMobile && 24}>
          <Button onClick={onResetFilters} className={css['Filters-button']} size={!isMobile && 'large'}>
            {t('cancel')}
          </Button>
        </Col>

        <Col xs={isMobile && 24}>
          <Button
            type='primary'
            onClick={onSubmitFilters}
            className={css['Filters-button']}
            size={!isMobile && 'large'}
          >
            {t('approve')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FiltersPokaSkills;
