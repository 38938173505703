import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { clearSelectedUser } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';

import { Avatar } from 'antd';
import { FiChevronLeft } from 'react-icons/fi';

import GroupModal from './GroupModal';

import useImage from '@shared/hooks/useImage';

import styles from '../Messages.module.scss';

const Header = () => {
  const { selectedUser } = useSelector(selectChat);
  const { t } = useTranslation('chat');

  const avatar = useImage(selectedUser?.photo, 'avatar');
  const dispatch = useDispatch();

  const [visibleGroupModal, setVisibleGroupModal] = useState(false);

  const handleBack = () => dispatch(clearSelectedUser());

  const renderGroupOrUser = () => {
    if (selectedUser.chatName) {
      return (
        <div className={styles.header__link} onClick={() => setVisibleGroupModal(true)}>
          <Avatar size={36} src={avatar} />
          <p className={styles.header__name}>{selectedUser.chatName}</p>
        </div>
      );
    } else {
      return (
        <Link to={'/collegues/' + selectedUser.id} className={styles.header__link}>
          <Avatar size={36} src={avatar} />
          <p className={styles.header__name}>
            {selectedUser.firstName} {selectedUser.lastName}
          </p>
        </Link>
      );
    }
  };

  return (
    <div className={styles.header}>
      {renderGroupOrUser()}
      <div onClick={handleBack} className={styles.header__back}>
        <FiChevronLeft size={16} />
        <p>{t('back')}</p>
      </div>
      {!!selectedUser?.chatName && visibleGroupModal && (
        <GroupModal onClose={() => setVisibleGroupModal(false)} visible={visibleGroupModal} />
      )}
    </div>
  );
};

export default memo(Header);
