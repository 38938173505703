import React from 'react';

import classNames from 'classnames';
import DOMPurify from 'dompurify';
import showdown from 'showdown';

import css from './MarkdownViewer.module.scss';

function MarkdownViewer({ children, className, ignoreSanitize }) {
  const rawHTML = children;

  if (ignoreSanitize) {
    return <iframe srcDoc={rawHTML} className={classNames(className, css['ignoreSanitize'])}></iframe>;
  }

  const converter = new showdown.Converter();
  const html = converter.makeHtml(rawHTML);
  const sanitizedHTML = DOMPurify.sanitize(html);

  return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>;
}

export default MarkdownViewer;
