import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { selectUsers } from '@/store/users/selectors';

import { isNotContain } from '@shared/utils/permissionUtils';

const throw404Error = () => new Response('Not Found', { status: 404 });

export const permissionHandler =
  (Component, config = {}) =>
  props => {
    const { permissions, ...otherConfig } = config;

    const { currentUser } = useSelector(selectUsers);

    if (permissions) {
      if (Array.isArray(permissions)) {
        if (permissions.find(per => isNotContain(currentUser, per))) throw throw404Error();
      } else {
        if (isNotContain(currentUser, permissions)) throw throw404Error();
      }
    }

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} {...otherConfig} navigate={navigate} location={location} params={params} />;
  };
