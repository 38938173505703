/* eslint-disable */
import {
  CREATE_ANOTHER_PROFILE_STUDY_PLAN_START,
  CREATE_ANOTHER_PROFILE_STUDY_PLAN_SUCCESS,
  DELETE_PROFILE_USER_TOPIC_SUCCESS,
  POST_USER_TOPICS_SUCCESS,
} from '../user-topics/actions';
import {
  ADD_COMMENT_TO_LIST,
  ADD_MENTIONS_TO_LIST,
  CHANGE_TOPIC_FILTER,
  CHECK_TOPIC_COMMENT_FAIL,
  CHECK_TOPIC_COMMENT_START,
  CHECK_TOPIC_COMMENT_SUCCESS,
  CHECK_TOPIC_DUPLICATE_FAIL,
  CHECK_TOPIC_DUPLICATE_START,
  CHECK_TOPIC_DUPLICATE_SUCCESS,
  CLEAR_DUPLICATE,
  CLEAR_SUBTYPES,
  CLEAR_TOPIC_COMMENT,
  CLEAR_TOPIC_FILTER,
  CLEAR_TOPIC_MENTION,
  CLEAR_TOPICS,
  CLEAR_TOPICS_COMPILATION,
  DELETE_AUTHORS_FAIL,
  DELETE_AUTHORS_START,
  DELETE_AUTHORS_SUCCESS,
  DELETE_TOPIC_COMMENT_FAIL,
  DELETE_TOPIC_COMMENT_START,
  DELETE_TOPIC_COMMENT_SUCCESS,
  GET_COUNT_COMMENT_FAIL,
  GET_COUNT_COMMENT_START,
  GET_COUNT_COMMENT_SUCCESS,
  GET_COUNT_REVIEW_FAIL,
  GET_COUNT_REVIEW_START,
  GET_COUNT_REVIEW_SUCCESS,
  GET_TOPIC_BY_AUTHOR_START,
  GET_TOPIC_BY_AUTHOR_SUCCESS,
  GET_TOPIC_BY_ID_FAIL,
  GET_TOPIC_BY_ID_START,
  GET_TOPIC_BY_ID_SUCCESS,
  GET_TOPIC_CATEGORY_FAIL,
  GET_TOPIC_CATEGORY_START,
  GET_TOPIC_CATEGORY_SUCCESS,
  GET_TOPIC_CLASSES_FAIL,
  GET_TOPIC_CLASSES_START,
  GET_TOPIC_CLASSES_SUCCESS,
  GET_TOPIC_COMMENTS_FAIL,
  GET_TOPIC_COMMENTS_START,
  GET_TOPIC_COMMENTS_SUCCESS,
  GET_TOPIC_COMPETENCIES_FAIL,
  GET_TOPIC_COMPETENCIES_START,
  GET_TOPIC_COMPETENCIES_SUCCESS,
  GET_TOPIC_CRE_BY_ANOTHER_USER_FAIL,
  GET_TOPIC_CRE_BY_ANOTHER_USER_START,
  GET_TOPIC_CRE_BY_ANOTHER_USER_SUCCESS,
  GET_TOPIC_CRE_BY_CURUSER_FAIL,
  GET_TOPIC_CRE_BY_CURUSER_START,
  GET_TOPIC_CRE_BY_CURUSER_SUCCESS,
  GET_TOPIC_JOB_LEVEL_FAIL,
  GET_TOPIC_JOB_LEVEL_START,
  GET_TOPIC_JOB_LEVEL_SUCCESS,
  GET_TOPIC_LEVEL_FAIL,
  GET_TOPIC_LEVEL_START,
  GET_TOPIC_LEVEL_SUCCESS,
  GET_TOPIC_MANDATORY_FAIL,
  GET_TOPIC_MANDATORY_START,
  GET_TOPIC_MANDATORY_SUCCESS,
  GET_TOPIC_MENTIONS_FAIL,
  GET_TOPIC_MENTIONS_START,
  GET_TOPIC_MENTIONS_SUCCESS,
  GET_TOPIC_SUBTYPES_FAIL,
  GET_TOPIC_SUBTYPES_START,
  GET_TOPIC_SUBTYPES_SUCCESS,
  GET_TOPIC_TYPES_FAIL,
  GET_TOPIC_TYPES_START,
  GET_TOPIC_TYPES_SUCCESS,
  GET_TOPIC_VIDEO_TYPES_FAIL,
  GET_TOPIC_VIDEO_TYPES_START,
  GET_TOPIC_VIDEO_TYPES_SUCCESS,
  GET_TOPICS_COMPILATION_FAIL,
  GET_TOPICS_COMPILATION_START,
  GET_TOPICS_COMPILATION_SUCCESS,
  GET_TOPICS_FAIL,
  GET_TOPICS_START,
  GET_TOPICS_SUCCESS,
  HIDE_SEND_REVIEW_MODAL,
  POST_TOPIC_COMMENT_FAIL,
  POST_TOPIC_COMMENT_REPLY_START,
  POST_TOPIC_COMMENT_REPLY_SUCCESS,
  POST_TOPIC_COMMENT_START,
  POST_TOPIC_COMMENT_SUCCESS,
  POST_TOPIC_FAIL,
  POST_TOPIC_REPLY_COMMENT_FAIL,
  POST_TOPIC_START,
  POST_TOPIC_SUCCESS,
  POST_TOPIC_VIEWS_FAIL,
  POST_TOPIC_VIEWS_START,
  POST_TOPIC_VIEWS_SUCCESS,
  RESTORE_TOPIC_COMMENT_FAIL,
  RESTORE_TOPIC_COMMENT_START,
  RESTORE_TOPIC_COMMENT_SUCCESS,
  SHOW_SEND_REVIEW_MODAL,
  UPDATE_TOPIC_COMMENT_FAIL,
  UPDATE_TOPIC_COMMENT_START,
  UPDATE_TOPIC_COMMENT_SUCCESS,
  UPDATE_TOPIC_FAIL,
  UPDATE_TOPIC_MENTION_FAIL,
  UPDATE_TOPIC_MENTION_START,
  UPDATE_TOPIC_MENTION_SUCCESS,
  UPDATE_TOPIC_START,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_VISIBILITY_FAIL,
  UPDATE_TOPIC_VISIBILITY_START,
  UPDATE_TOPIC_VISIBILITY_SUCCESS,
} from './actions';

const initialState = {
  tags: null,
  topicsAll: [],
  topics: null,
  topicsTotal: null,
  topicsPageSize: null,
  topicsPageNumber: null,
  topicsCompilation: null,
  topicsCompilationTotal: null,
  topicsCompilationPageSize: null,
  topicsCompilationPageNumber: null,
  topic: null,
  topicComments: [],
  topicCommentsHasMore: true,
  topicCommentsTotal: 0,
  topicCommentsPage: 0,
  topicMentions: [],
  topicMentionsHasMore: true,
  topicMentionsTotal: 0,
  topicMentionsPage: 0,
  types: null,
  subtypes: null,
  categories: null,
  topicVideoTypes: null,
  topicClasses: null,
  level: null,
  views: null,
  isSendReviewModalVisible: false,
  sendReviewTopicId: null,
  isLoading: false,
  isLoadingTopicsCatalog: false,
  isLoadingDuplicate: false,
  isLoadingTopicsByCurUser: false,
  duplicate: [],
  isPayCourses: null,
  error: false,
  topicsByUser: {
    elements: [],
    hasMore: false,
  },
  topicsByCurUser: {
    elements: [],
    hasMore: true,
  },
  topicsByAnotherUser: {
    elements: [],
    hasMore: true,
    page: 0,
  },
  topicsByAnotherUserTotal: 0,
  topicsByAnotherUserPageSize: 0,
  topicFilter: {},
  topicFilterBlock: {},
  countComment: null,
  countReview: null,
  topicCategoryCompetencies: [],
  topicMandatories: [],
  jobLevels: [],
  isLoadingAll: false,
};

export default (
  state = initialState,
  { type, response, name, value, isPayCourses, headers, action, comment, mention }
) => {
  switch (type) {
    case CHECK_TOPIC_DUPLICATE_START:
      return {
        ...state,
        isLoading: true,
        isLoadingDuplicate: true,
        error: false,
      };

    case GET_TOPIC_TYPES_START:
      return { ...state, types: null, isLoading: true, error: false };

    case GET_TOPIC_TYPES_SUCCESS:
      return { ...state, types: response, isLoading: false };

    case CHECK_TOPIC_DUPLICATE_SUCCESS:
      return {
        ...state,
        duplicate: response,
        isLoading: false,
        isLoadingDuplicate: false,
      };

    case CLEAR_DUPLICATE:
      return { ...state, duplicate: null };

    case GET_TOPIC_SUBTYPES_START:
      return { ...state, subtypes: null, isLoading: true, error: false };

    case CLEAR_SUBTYPES:
      return { ...state, subtypes: null };

    case GET_TOPIC_SUBTYPES_SUCCESS:
      return { ...state, subtypes: response, isLoading: false };

    case GET_TOPIC_COMPETENCIES_START:
    case GET_TOPIC_MANDATORY_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPIC_JOB_LEVEL_START:
      return { ...state, isLoading: true, jobLevels: [] };

    case CLEAR_TOPICS:
      return {
        ...state,
        isLoadingTopicsCatalog: false,
        isLoading: false,
        error: false,
        topicsAll: [],
        topics: [],
        topicsTotal: 0,
        topicsPageSize: 0,
        topicsPageNumber: 0,
      };

    case CREATE_ANOTHER_PROFILE_STUDY_PLAN_START:
    case UPDATE_TOPIC_MENTION_START:
    case UPDATE_TOPIC_COMMENT_START:
    case RESTORE_TOPIC_COMMENT_START:
    case DELETE_TOPIC_COMMENT_START:
    case DELETE_AUTHORS_START:
    case GET_COUNT_COMMENT_START:
    case GET_COUNT_REVIEW_START:
      return { ...state, isLoadingTopicsCatalog: true, error: false };

    case GET_TOPICS_START:
      return { ...state, isLoadingTopicsCatalog: true, error: false, isLoadingAll: true };

    case GET_TOPICS_FAIL:
      return {
        ...state,
        error: true,
        topics: [],
        topicsTotal: null,
        topicsPageSize: null,
        topicsPageNumber: null,
        isLoadingTopicsCatalog: false,
        isLoadingAll: false,
      };

    case GET_TOPICS_COMPILATION_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPICS_COMPILATION_FAIL:
      return {
        ...state,
        error: true,
        topicsCompilation: [],
        topicsCompilationTotal: null,
        topicsCompilationPageSize: null,
        topicsCompilationPageNumber: null,
        isLoading: false,
      };

    case CLEAR_TOPICS_COMPILATION:
      return {
        ...state,
        error: false,
        topicsCompilation: [],
        topicsCompilationTotal: null,
        topicsCompilationPageSize: null,
        topicsCompilationPageNumber: null,
        isLoading: false,
      };

    case GET_TOPICS_COMPILATION_SUCCESS:
      return {
        ...state,
        topicsCompilation:
          Number(headers.get('X-Page-Number')) === 0 ? response : [...state.topicsCompilation, ...response],
        topicsCompilationTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsCompilationPageSize: Number(headers.get('X-Page-Size')) || 0,
        topicsCompilationPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_TOPICS_SUCCESS:
      const topics = action.isFavorites ? response.map(liked => liked.topic) : response;

      return {
        ...state,
        topicsAll: action.isList ? topics : [...state.topicsAll, ...topics],
        topics,
        topicsTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsPageSize: Number(headers.get('X-Page-Size')) || 0,
        topicsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoadingTopicsCatalog: false,
        isLoadingAll: false,
      };

    case GET_TOPIC_LEVEL_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPIC_LEVEL_SUCCESS:
      return { ...state, isLoading: false, error: false, level: response };

    case GET_TOPIC_LEVEL_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_TOPIC_BY_ID_START:
      return { ...state, topic: null, isLoading: true, error: false };

    case GET_TOPIC_BY_ID_SUCCESS:
      return {
        ...state,
        topic: response,
        isLoading: false,
      };

    case GET_TOPIC_BY_AUTHOR_START:
      return { ...state, isLoading: true, error: false };
    case GET_TOPIC_BY_AUTHOR_SUCCESS:
      return {
        ...state,
        topicsByUser: {
          elements:
            Number(headers.get('X-Page-Number')) === 0 ? response : state.topicsByUser.elements.concat(response),
          hasMore: Number(headers.get('X-Total-Elements')) > state.topicsByUser.elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        topicsByUserTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsByUserPageSize: Number(headers.get('X-Page-Size')) || 0,
        topicsByUserPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_TOPIC_CRE_BY_ANOTHER_USER_START:
    case GET_TOPIC_CRE_BY_CURUSER_START:
      return { ...state, isLoadingTopicsByCurUser: true, hasMore: false, error: false };
    case GET_TOPIC_CRE_BY_CURUSER_SUCCESS:
      return {
        ...state,
        topicsByCurUser: {
          elements:
            Number(headers.get('X-Page-Number')) === 0 ? response : state.topicsByCurUser.elements.concat(response),
          hasMore: Number(headers.get('X-Total-Elements')) > state.topicsByCurUser.elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        topicsByCurUserTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsByCurUserPageSize: Number(headers.get('X-Page-Size')) || 0,
        isLoadingTopicsByCurUser: false,
      };

    case GET_TOPIC_CRE_BY_CURUSER_FAIL:
      return { ...state, isLoadingTopicsByCurUser: false, hasMore: false, error: true };

    case GET_TOPIC_CRE_BY_ANOTHER_USER_SUCCESS:
      const elements =
        Number(headers.get('X-Page-Number')) === 0 ? response : state.topicsByAnotherUser.elements.concat(response);
      return {
        ...state,
        topicsByAnotherUser: {
          elements,
          hasMore: Number(headers.get('X-Total-Elements')) > elements.length,
          page: Number(headers.get('X-Page-Number')) || 0,
        },
        topicsByAnotherUserTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicsByAnotherUserPageSize: Number(headers.get('X-Page-Size')) || 0,
        isLoading: false,
      };

    case CREATE_ANOTHER_PROFILE_STUDY_PLAN_SUCCESS: {
      const newList = state.topicsByAnotherUser.elements.map(item => {
        if (item.id === response.topic.id) {
          const correctedTopic = {
            ...response.topic,
            planStatus: {
              ...response.topic.planStatus,
              planStatus: response.status,
            },
          };
          return correctedTopic;
        }
        return item;
      });
      return {
        ...state,
        topicsByAnotherUser: {
          ...state.topicsByAnotherUser,
          elements: newList,
        },
        isLoading: false,
      };
    }
    case POST_USER_TOPICS_SUCCESS:
      if (action.listName === 'anotherMyMaterial') {
        const anotherMyMaterialList = state.topicsByAnotherUser.elements.map(item => {
          if (item.id === response[0].userTopic.topic.id) {
            return response[0].userTopic.topic;
          }
          return item;
        });
        return {
          ...state,
          topicsByAnotherUser: {
            ...state.topicsByAnotherUser,
            elements: anotherMyMaterialList,
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
      };

    case DELETE_PROFILE_USER_TOPIC_SUCCESS:
      if (action.listName === 'anotherMyMaterial') {
        const anotherMyMaterialList = state.topicsByAnotherUser.elements.map(item => {
          if (item.id === response.topic.id) {
            return response.topic;
          }
          return item;
        });
        return {
          ...state,
          topicsByAnotherUser: {
            ...state.topicsByAnotherUser,
            elements: anotherMyMaterialList,
          },
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
      };

    case GET_TOPIC_CATEGORY_START:
      return { ...state, categories: null, isLoading: true, error: false };

    case GET_TOPIC_CATEGORY_SUCCESS:
      return { ...state, categories: response, isLoading: false };

    case GET_TOPIC_VIDEO_TYPES_START:
      return { ...state, topicVideoTypes: null, isLoading: true, error: false };

    case GET_TOPIC_VIDEO_TYPES_SUCCESS:
      return { ...state, topicVideoTypes: response, isLoading: false };

    case GET_TOPIC_CLASSES_START:
      return { ...state, topicClasses: null, isLoading: true, error: false };

    case GET_TOPIC_CLASSES_SUCCESS:
      return { ...state, topicClasses: response, isLoading: false };

    case UPDATE_TOPIC_VISIBILITY_START:
    case POST_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_TOPIC_VISIBILITY_SUCCESS:
    case RESTORE_TOPIC_COMMENT_SUCCESS:
    case DELETE_TOPIC_COMMENT_SUCCESS:
    case DELETE_AUTHORS_SUCCESS:
    case POST_TOPIC_SUCCESS:
      return { ...state, isLoading: false };

    case UPDATE_TOPIC_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_TOPIC_SUCCESS:
      return { ...state, topic: response, isLoading: false };

    case POST_TOPIC_VIEWS_START:
      return { ...state, isLoading: true, error: false, views: null };

    case POST_TOPIC_VIEWS_SUCCESS:
      return { ...state, isLoading: false, views: response };

    case GET_TOPIC_MENTIONS_START:
    case GET_TOPIC_COMMENTS_START:
      return { ...state, isLoading: true, error: false };

    case GET_TOPIC_COMMENTS_SUCCESS:
      if (state.topicComments.length) {
        return {
          ...state,
          topicComments: state.topicComments.concat(response),
          topicCommentsHasMore: Number(headers.get('X-Total-Elements')) > state.topicComments.length,
          topicCommentsTotal: Number(headers.get('X-Total-Elements')) || 0,
          topicCommentsPage: Number(headers.get('X-Total-Pages')) || 0,
          topicCommentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoadingTopicComments: false,
          isLoading: false,
          error: false,
        };
      }
      return {
        ...state,
        topicComments: response,
        topicCommentsTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicCommentsPage: Number(headers.get('X-Total-Pages')) || 0,
        topicCommentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
        isLoadingTopicComments: false,
        error: false,
      };

    case ADD_COMMENT_TO_LIST:
      return {
        ...state,
        topicComments: state.topicComments.concat(comment),
        topicCommentsTotal: state.topicCommentsTotal + 1,
      };
    case UPDATE_TOPIC_COMMENT_SUCCESS:
      const result = state.topicComments.filter(item => {
        return item.id !== response.id;
      });
      result.push(response);
      return {
        ...state,
        topicComments: result,
        isLoading: false,
        error: false,
      };
    case UPDATE_TOPIC_MENTION_SUCCESS:
      const resultMention = state.topicMentions.filter(item => {
        return item.id !== response.id;
      });
      resultMention.push(response);
      return {
        ...state,
        topicMentions: resultMention,
        isLoading: false,
        error: false,
      };
    case CLEAR_TOPIC_COMMENT:
      return {
        ...state,
        topicComments: [],
        topicCommentsHasMore: false,
        topicCommentsTotal: 0,
        topicCommentsPage: 0,
        topicCommentsPageNumber: 0,
      };
    case CLEAR_TOPIC_MENTION:
      return {
        ...state,
        topicMentions: [],
        topicMentionsHasMore: false,
        topicMentionsTotal: 0,
        topicMentionsPage: 0,
        topicMentionsPageNumber: 0,
      };
    case GET_TOPIC_MENTIONS_SUCCESS:
      if (state.topicMentions.length) {
        return {
          ...state,
          topicMentions: state.topicMentions.concat(response),
          topicMentionsHasMore: Number(headers.get('X-Total-Elements')) > state.topicMentions.length,
          topicMentionsTotal: Number(headers.get('X-Total-Elements')) || 0,
          topicMentionsPage: Number(headers.get('X-Total-Pages')) || 0,
          topicMentionsPageNumber: Number(headers.get('X-Page-Number')) || 0,
          isLoading: false,
          error: false,
        };
      }
      return {
        ...state,
        topicMentions: response,
        topicMentionsTotal: Number(headers.get('X-Total-Elements')) || 0,
        topicMentionsPage: Number(headers.get('X-Total-Pages')) || 0,
        topicMentionsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
        error: false,
      };

    case POST_TOPIC_COMMENT_START:
    case POST_TOPIC_COMMENT_REPLY_START:
      return { ...state, isLoading: true, error: false };

    case POST_TOPIC_COMMENT_SUCCESS:
    case POST_TOPIC_COMMENT_REPLY_SUCCESS:
      return { ...state, isLoading: false };

    case UPDATE_TOPIC_VISIBILITY_FAIL:
    case POST_TOPIC_FAIL:
      return { ...state, error: response, isLoading: false };

    case CHECK_TOPIC_COMMENT_START:
      return { ...state, isLoading: true, error: false };
    case CHECK_TOPIC_COMMENT_SUCCESS:
      return { ...state, isLoading: false, error: false };
    case CHECK_TOPIC_DUPLICATE_FAIL:
      return {
        error: true,
        isLoading: false,
        isLoadingDuplicate: false,
      };

    case GET_TOPIC_COMPETENCIES_SUCCESS:
      return { ...state, isLoading: false, topicCategoryCompetencies: response };

    case GET_TOPIC_MANDATORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        topicMandatories: response,
      };

    case GET_TOPIC_JOB_LEVEL_SUCCESS:
      return { ...state, isLoading: false, jobLevels: response };

    case GET_TOPIC_BY_ID_FAIL:
    case UPDATE_TOPIC_MENTION_FAIL:
    case UPDATE_TOPIC_COMMENT_FAIL:
    case RESTORE_TOPIC_COMMENT_FAIL:
    case DELETE_TOPIC_COMMENT_FAIL:
    case GET_TOPIC_CRE_BY_ANOTHER_USER_FAIL:
    case DELETE_AUTHORS_FAIL:
    case CHECK_TOPIC_COMMENT_FAIL:
    case GET_TOPIC_TYPES_FAIL:
    case GET_TOPIC_SUBTYPES_FAIL:
    case GET_TOPIC_CATEGORY_FAIL:
    case GET_TOPIC_VIDEO_TYPES_FAIL:
    case POST_TOPIC_VIEWS_FAIL:
    case UPDATE_TOPIC_FAIL:
    case GET_TOPIC_CLASSES_FAIL:
    case GET_TOPIC_MENTIONS_FAIL:
    case GET_TOPIC_COMMENTS_FAIL:
    case POST_TOPIC_COMMENT_FAIL:
    case GET_COUNT_COMMENT_FAIL:
    case GET_COUNT_REVIEW_FAIL:
    case POST_TOPIC_REPLY_COMMENT_FAIL:
    case GET_TOPIC_COMPETENCIES_FAIL:
    case GET_TOPIC_MANDATORY_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_TOPIC_JOB_LEVEL_FAIL:
      return { ...state, error: true, isLoading: false };

    case CHANGE_TOPIC_FILTER:
      const topicFilter = { ...state.topicFilter, [name]: value };
      return { ...state, topicFilter };

    case CLEAR_TOPIC_FILTER:
      return { ...state, topicFilter: initialState.topicFilter };

    case SHOW_SEND_REVIEW_MODAL:
      return {
        ...state,
        isSendReviewModalVisible: true,
        sendReviewTopicId: value,
        isPayCourses,
      };

    case HIDE_SEND_REVIEW_MODAL:
      return {
        ...state,
        isSendReviewModalVisible: false,
        sendReviewTopicId: null,
      };

    case GET_COUNT_COMMENT_SUCCESS:
      return { ...state, countComment: response, isLoading: false };

    case GET_COUNT_REVIEW_SUCCESS:
      return { ...state, countReview: response, isLoading: false };

    case ADD_MENTIONS_TO_LIST:
      return {
        ...state,
        topicMentions: state.topicMentions.concat(mention),
        topicMentionsTotal: state.topicMentionsTotal + 1,
      };

    default:
      return state;
  }
};
