import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { clearQuestionNumber } from '@/store/lms/actions';

import { Button } from 'antd';

import Poll from '../Poll/Poll';
import { CurrentResultStatus } from './CurrentResultStatus';
import { TestDetailsActions } from './TestDetailsActions';
import { TestDetailsAnswersList } from './TestDetailsAnswersList';
import { TestDetailsInfo } from './TestDetailsInfo';
import { BlockStatus } from './types';

import css from '../Details/Details.module.scss';

export const BlockStudyResultScreen = ({ data, block, setFinished, repeatTest, haveATimer, canRestartBlocks }) => {
  const { t } = useTranslation('details');
  const dispatch = useDispatch();

  useEffect(() => {
    const finishBlocks = localStorage.getItem('finishBlocks');
    if (data.status === BlockStatus.FINISHED) {
      localStorage.removeItem('finishBlocks');
    } else {
      if (!finishBlocks || !JSON.parse(finishBlocks)?.blocks.includes(data.blockId)) {
        const obj = {
          blocks: !finishBlocks ? [data.blockId] : [...JSON.parse(finishBlocks)?.blocks, data.blockId],
        };
        localStorage.setItem('finishBlocks', JSON.stringify(obj));
      }
    }
  }, []);

  const retryTestClick = () => {
    repeatTest(haveATimer() ? true : block.blockId);
    dispatch(clearQuestionNumber());
  };

  const actionsTestButtons = () => {
    if (!data) return;
    const buttons = [];

    const notStartedTest = data.status === BlockStatus.NOT_STARTED && !data.resultTest.review && !data.resultTest.poll;

    const isPassed = data.resultTest.countCorrectUserAnswers >= data.resultTest.pass;

    const showNextBtn = notStartedTest && (isPassed || (!isPassed && !block.isAvailableForReStart));

    const showRetryBtn = (data.status !== BlockStatus.WAITING && block.isAvailableForReStart) || canRestartBlocks;

    if (data.status === BlockStatus.NOT_STARTED && data.resultTest.review && !data.resultTest.poll) {
      buttons.push(
        <Button
          className={css['Details-button']}
          onClick={() => setFinished(data.blockId)}
          key={'send_to_rewiev'}
          type='primary'
          size='large'
        >
          {t('send_to_rewiev')}
        </Button>
      );
    }
    if (showNextBtn) {
      buttons.push(
        <Button
          className={css['Details-button']}
          onClick={() => setFinished(data.blockId)}
          key={'next'}
          type='primary'
          size='large'
        >
          {t('next')}
        </Button>
      );
    }
    if (showRetryBtn) {
      buttons.push(
        <Button
          className={css['Details-button']}
          onClick={retryTestClick}
          key={'retryTest'}
          type='primary'
          size='large'
        >
          {t('retryTest')}
        </Button>
      );
    }
    return buttons;
  };

  if (!data) {
    return <>Empty</>;
  }

  const currentResultStat = <CurrentResultStatus detailsTest={data} />;

  return (
    <div className={css.Details}>
      <TestDetailsInfo testData={data} block={block} currentResultStat={currentResultStat} />
      <TestDetailsActions actionsTestButtons={actionsTestButtons()} />
      {!data.resultTest.poll && data.resultTest.extendedResults ? (
        <TestDetailsAnswersList questionAnswerList={data.resultTest.questions} />
      ) : (
        ''
      )}
      {data.resultTest.poll ? <Poll setFinished={setFinished} data={data} /> : ''}
    </div>
  );
};
