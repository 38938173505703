import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCanBeMentor } from '@/store/mentoring_profile/actions';
import { getMentors } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Empty, Input, Layout, Pagination, Spin, Typography } from 'antd';
import { Content } from '@/components/Layout';
import CardAddMentor from '@/components/Mentoring/CardMentor/CardAddMentor';
import CardMentor from '@/components/Mentoring/CardMentor/CardMentor';

import Filters from './Content/Filters';

import useDebounce from '@shared/hooks/useDebounce';

import { selectMentoring } from '@/store/mentoring_profile/selector';
import classNames from 'classnames';

import css from './Mentors.module.scss';

const { Search } = Input;
const { Title } = Typography;

const Mentors = () => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const {
    mentorsCard,
    mentorsCardTotal,
    mentorsCardPageSize,
    mentorsCardCurrentPage,
    mentorsFilters,
    isLoadingCards,
    mentorsCardTotalPages,
  } = useSelector(selectMentors);
  const { canBeMentor, sizePage } = useSelector(selectMentoring);

  const [fullName, setFullName] = useState('');

  const debounceFullName = useDebounce(fullName, 350);

  useEffect(() => {
    dispatch(getCanBeMentor());
  }, [dispatch]);

  const prevController = useRef();

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;

    if (prevController?.current) {
      prevController.current.abort();
    }
    prevController.current = controller;
    if (sizePage) {
      dispatch(getMentors(mentorsFilters.filters ? mentorsFilters.filters : {}, '', sizePage, '', signal));
    }
  }, [mentorsFilters.filters, sizePage]);

  const onSortSlot = () => {
    dispatch(
      getMentors(mentorsFilters.filters ? mentorsFilters.filters : {}, mentorsCardCurrentPage, sizePage, [
        'availableSlots,desc',
      ])
    );
  };

  const changePage = pagination => {
    dispatch(getMentors(mentorsFilters.filters ? mentorsFilters.filters : {}, pagination - 1, sizePage));
  };

  const searchMentors = e => {
    let searchString = e?.target?.value;

    setFullName(searchString);
  };

  const onDeleteFullName = () => {
    setFullName('');
  };

  const renderMentorsCard =
    mentorsCard?.length &&
    mentorsCard.map(item => {
      let username = (item.lastName || '') + ' ' + (item.firstName || '').trim();
      return (
        <CardMentor
          competencies={item.competences}
          fullName={username}
          position={item.position}
          availableSlots={item.availableSlots}
          mentorId={item.id}
          key={item.id}
          mentoringStatus={item.mentoringStatus}
          photoUuid={item.photoUuid}
          photoUuidUser={item.photoUuidUser}
        />
      );
    });

  return (
    <Layout>
      <Content>
        <div className={css['Content-header']}>
          <div className={css['Content-header-title']}>
            <Title level={1}>{t('title')}</Title>
          </div>
          <div>
            <Search
              className={classNames(css['Content-header-search'], 'smallSizeSearchButton')}
              size='large'
              placeholder={t('searchPlaceholder')}
              onChange={searchMentors}
              value={fullName}
              enterButton
            />
          </div>
        </div>
        <Filters fullName={debounceFullName} onDeleteFullName={onDeleteFullName} />

        <Spin spinning={isLoadingCards} className={css['Content-spin']} size='large'>
          {mentorsCardTotal > 0 && (
            <div className={css['Content-sort']}>
              <span>{t('sort')}</span>
              <span className={css['Content-sort-value']} onClick={onSortSlot}>
                {t('sortSlot')}
              </span>
            </div>
          )}

          <div className={classNames(mentorsCardTotal > 0 && css['Content-cards'])}>
            {canBeMentor && mentorsCardTotal > 0 && <CardAddMentor />}
            {mentorsCardTotal > 0 && renderMentorsCard}
          </div>
        </Spin>

        {!mentorsCardTotal && !isLoadingCards && (
          <div className={css['Content-block']}>
            {canBeMentor && <CardAddMentor />}
            <Empty className={css['Content-block-empty']} description={t('empty')} />
          </div>
        )}

        {mentorsCardTotalPages > 1 && !isLoadingCards && (
          <Pagination
            current={mentorsCardCurrentPage + 1}
            total={mentorsCardTotal}
            pageSize={mentorsCardPageSize}
            onChange={changePage}
            className={css['Content-pagination']}
            showSizeChanger={false}
            responsive
            hideOnSinglePage
          />
        )}
      </Content>
    </Layout>
  );
};

export default Mentors;
