export const GET_MENTORS_START = 'GET_MENTORS_START';
export const GET_MENTORS_SUCCESS = 'GET_MENTORS_SUCCESS';
export const GET_MENTORS_FAIL = 'GET_MENTORS_FAIL';

export const GET_MENTORS_COMPANY_START = 'GET_MENTORS_COMPANY_START';
export const GET_MENTORS_COMPANY_SUCCESS = 'GET_MENTORS_COMPANY_SUCCESS';
export const GET_MENTORS_COMPANY_FAIL = 'GET_MENTORS_COMPANY_FAIL';

export const GET_COMPETENCE_MENTORS_START = 'GET_COMPETENCE_MENTORS_START';
export const GET_COMPETENCE_MENTORS_SUCCESS = 'GET_COMPETENCE_MENTORS_SUCCESS';
export const GET_COMPETENCE_MENTORS_FAIL = 'GET_COMPETENCE_MENTORS_FAIL';

export const GET_MENTORS_DEPARTMENT_START = 'GET_MENTORS_DEPARTMENT_START';
export const GET_MENTORS_DEPARTMENT_SUCCESS = 'GET_MENTORS_DEPARTMENT_SUCCESS';
export const GET_MENTORS_DEPARTMENT_FAIL = 'GET_MENTORS_DEPARTMENT_FAIL';

export const GET_MENTORS_FUNCTION_START = 'GET_MENTORS_FUNCTION_START';
export const GET_MENTORS_FUNCTION_SUCCESS = 'GET_MENTORS_FUNCTION_SUCCESS';
export const GET_MENTORS_FUNCTION_FAIL = 'GET_MENTORS_FUNCTION_FAIL';

export const REQUEST_MENTEE_START = 'REQUEST_MENTEE_START';
export const REQUEST_MENTEE_SUCCESS = 'REQUEST_MENTEE_SUCCESS';
export const REQUEST_MENTEE_FAIL = 'REQUEST_MENTEE_FAIL';

export const GET_MENTOR_ID_START = 'GET_MENTOR_ID_START';
export const GET_MENTOR_ID_SUCCESS = 'GET_MENTOR_ID_SUCCESS';
export const GET_MENTOR_ID_FAIL = 'GET_MENTOR_ID_FAIL';

export const GET_MENTOR_COMMENTS_START = 'GET_MENTOR_COMMENTS_START';
export const GET_MENTOR_COMMENTS_SUCCESS = 'GET_MENTOR_COMMENTS_SUCCESS';
export const GET_MENTOR_COMMENTS_FAIL = 'GET_MENTOR_COMMENTS_FAIL';

export const POST_MENTOR_COMMENT_START = 'POST_MENTOR_COMMENT_START';
export const POST_MENTOR_COMMENT_SUCCESS = 'POST_MENTOR_COMMENT_SUCCESS';
export const POST_MENTOR_COMMENT_FAIL = 'POST_MENTOR_COMMENT_FAIL';

export const PUT_MENTOR_COMMENT_START = 'PUT_MENTOR_COMMENT_START';
export const PUT_MENTOR_COMMENT_SUCCESS = 'PUT_MENTOR_COMMENT_SUCCESS';
export const PUT_MENTOR_COMMENT_FAIL = 'PUT_MENTOR_COMMENT_FAIL';

export const DELETE_MENTOR_COMMENT_START = 'DELETE_MENTOR_COMMENT_START';
export const DELETE_MENTOR_COMMENT_SUCCESS = 'DELETE_MENTOR_COMMENT_SUCCESS';
export const DELETE_MENTOR_COMMENT_FAIL = 'DELETE_MENTOR_COMMENT_FAIL';

export const RESTORE_MENTOR_COMMENT_START = 'RESTORE_MENTOR_COMMENT_START';
export const RESTORE_MENTOR_COMMENT_SUCCESS = 'RESTORE_MENTOR_COMMENT_SUCCESS';
export const RESTORE_MENTOR_COMMENT_FAIL = 'RESTORE_MENTOR_COMMENT_FAIL';

export const GET_MENTOR_COMMENTS_COUNT_START = 'GET_MENTOR_COMMENTS_COUNT_START';
export const GET_MENTOR_COMMENTS_COUNT_SUCCESS = 'GET_MENTOR_COMMENTS_COUNT_SUCCESS';
export const GET_MENTOR_COMMENTS_COUNT_FAIL = 'GET_MENTOR_COMMENTS_COUNT_FAIL';

export const AWAIT_MENTOR_START = 'AWAIT_MENTOR_START';
export const AWAIT_MENTOR_SUCCESS = 'AWAIT_MENTOR_SUCCESS';
export const AWAIT_MENTOR_FAIL = 'AWAIT_MENTOR_FAIL';

export const GET_MENTOR_GRATITUDE_START = 'GET_MENTOR_GRATITUDE_START';
export const GET_MENTOR_GRATITUDE_SUCCESS = 'GET_MENTOR_GRATITUDE_SUCCESS';
export const GET_MENTOR_GRATITUDE_FAIL = 'GET_MENTOR_GRATITUDE_FAIL';

export const UPDATE_MENTOR_GRATITUDE_START = 'UPDATE_MENTOR_GRATITUDE_START';
export const UPDATE_MENTOR_GRATITUDE_SUCCESS = 'UPDATE_MENTOR_GRATITUDE_SUCCESS';
export const UPDATE_MENTOR_GRATITUDE_FAIL = 'UPDATE_MENTOR_GRATITUDE_FAIL';

export const SAVE_MENTORS_FILTERS = 'SAVE_MENTORS_FILTERS';

export const CLEAR_MENTORS_FILTERS = 'CLEAR_MENTORS_FILTERS';

export const CLEAR_COMMENTS_GRATITUDE_STATE = 'CLEAR_COMMENTS_GRATITUDE_STATE';

export const GET_SETTINGS_LIMIT_MENTORS_START = 'GET_SETTINGS_LIMIT_MENTORS_START';
export const GET_SETTINGS_LIMIT_MENTORS_SUCCESS = 'GET_SETTINGS_LIMIT_MENTORS_SUCCESS';
export const GET_SETTINGS_LIMIT_MENTORS_FAIL = 'GET_SETTINGS_LIMIT_MENTORS_FAIL';
