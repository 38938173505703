// my-profile/my-recommendations
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';

import { getMyRecommendedNews } from '@/store/user-topics/actions';

import { selectUserTopics } from '@/store/user-topics/selectors';

import { FloatButton, Layout, Radio, Spin } from 'antd';
import NewsElement from '@/pages/MyProfile/News/NewsElement';
import CustomEmpty from '@/components/CustomEmpty';
import ListItem from '@/components/MaterialListItem';

import dayjs from 'dayjs';

import css from '../info.module.scss';

const GROUP_TYPES = {
  MATERIALS: 'materials',
  NEWS: 'news',
};

const { Content } = Layout;
const { Button, Group } = Radio;

const MyRecommendedTopic = () => {
  const {
    dataqa,
    profileId,
    onFavoriteClick,
    updateInfinityScrollMyRecommendations,
    onStudyPlanClick,
    onDeleteClick,
    externalLogin,
    openDate,
  } = useOutletContext();
  const { t } = useTranslation('recommendedTopic');
  const dispatch = useDispatch();

  const [currentType, setCurrentType] = useState(GROUP_TYPES.MATERIALS);

  const { myRecommendations, isMyRecommendationsLoading } = useSelector(selectUserTopics);

  const listMaterials = myRecommendations?.elements?.map((item, index) => (
    <ListItem
      dataqa={dataqa}
      key={`${item.id}-${index}`}
      user={item.user}
      topic={item.topic}
      profileId={profileId}
      onFavoriteClick={onFavoriteClick}
      theLast={myRecommendations?.elements?.length - 1 === index}
      {...(item.topic
        ? {
            checkIsEnd:
              item.topic.type &&
              item.topic.type.id === 8 &&
              dayjs(`${item.topic.endDate} ${item.topic.endTime}`).isBefore(dayjs()),
          }
        : {
            checkIsEnd: item.type && item.type.id === 8 && dayjs(`${item.endDate} ${item.endTime}`).isBefore(dayjs()),
          })}
      onStudyPlanClick={onStudyPlanClick}
      onDeleteClick={onDeleteClick}
      page={myRecommendations.page}
      typeList='myRecommended'
      recUsers={item.recUsers}
      lastRec={item.recUsers.filter(rec => rec.id === item.recBy)}
      isExternalLogin={externalLogin}
      hideActionButtons
      openDate={openDate}
    />
  ));

  useEffect(() => {
    dispatch(getMyRecommendedNews(0));
  }, []);

  return (
    <Content className={css.RecommendedTopic}>
      <div className={css.RecommendedTopic__content}>
        <Group
          value={currentType}
          onChange={event => setCurrentType(event.target.value)}
          className={css.RecommendedTopic__group}
        >
          <Button key='GROUP_TYPES.MATERIALS' value={GROUP_TYPES.MATERIALS}>
            {t('materials')}
          </Button>
          <Button key='GROUP_TYPES.NEWS' value={GROUP_TYPES.NEWS}>
            {t('news')}
          </Button>
        </Group>

        {currentType === GROUP_TYPES.NEWS ? (
          <NewsElement
            type='myRecommended'
            emptyContent={{
              title: t('noRecomendations'),
              description: t('description1News'),
              btnText: t('goToNews'),
            }}
          />
        ) : (
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={e => {
              if (!isMyRecommendationsLoading) updateInfinityScrollMyRecommendations(e);
            }}
            hasMore={myRecommendations.hasMore}
            threshold={250}
            loader={
              <div key='unique-loader' className={css.RecommendedTopic__loader}>
                <Spin size='large' spinning={isMyRecommendationsLoading} />
              </div>
            }
          >
            {myRecommendations.elements?.length === 0 && !isMyRecommendationsLoading ? (
              <div className={css.RecommendedTopic__message}>
                <CustomEmpty title={t('noRecomendations')} description={t('description1')}>
                  <Link to='/collection-topics'>
                    <Button size='large' type='primary'>
                      {t('goToPlan')}
                    </Button>
                  </Link>
                </CustomEmpty>
              </div>
            ) : (
              listMaterials
            )}
          </InfiniteScroll>
        )}
      </div>
      <FloatButton.BackTop />
    </Content>
  );
};

export default MyRecommendedTopic;
