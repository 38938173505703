/* eslint-disable */
import { API_BASE, API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';
export const LOGOUT_GOOGLE_START = 'LOGOUT_GOOGLE_START';
export const LOGOUT_GOOGLE_SUCCESS = 'LOGOUT_GOOGLE_SUCCESS';
export const LOGOUT_GOOGLE_FAIL = 'LOGOUT_GOOGLE_FAIL';
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const EXTERNAL_LOGIN = 'EXTERNAL_LOGIN';
export const EXTERNAL_LOGOUT = 'EXTERNAL_LOGOUT';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const REFRESH_LOGIN_START = 'REFRESH_LOGIN_START';
export const REFRESH_LOGIN_SUCCESS = 'REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAIL = 'REFRESH_LOGIN_FAIL';
export const UPDATE_LOGIN_EMPTY = 'UPDATE_LOGIN_EMPTY';

export const CLEAR_WELCOME_MODAL = 'CLEAR_WELCOME_MODAL';
export const CLEAR_REMINDER_MODAL = 'CLEAR_REMINDER_MODAL';

export const MASK_IP_CHECK_START = 'MASK_IP_CHECK_START';
export const MASK_IP_CHECK_SUCCESS = 'MASK_IP_CHECK_SUCCESS';
export const MASK_IP_CHECK_FAIL = 'MASK_IP_CHECK_FAIL';

export const ACTUAL_LOGIN_START = 'ACTUAL_LOGIN_START';
export const ACTUAL_LOGIN_SUCCESS = 'ACTUAL_LOGIN_SUCCESS';
export const ACTUAL_LOGIN_FAIL = 'ACTUAL_LOGIN_FAIL';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const RESTORE_START = 'RESTORE_START';
export const RESTORE_SUCCESS = 'RESTORE_SUCCESS';
export const RESTORE_FAIL = 'RESTORE_FAIL';

export const REGISTER_DATA_START = 'REGISTER_DATA_START';
export const REGISTER_DATA_SUCCESS = 'REGISTER_DATA_SUCCESS';
export const REGISTER_DATA_FAIL = 'REGISTER_DATA_FAIL';

export const RESTORE_DATA_START = 'RESTORE_DATA_START';
export const RESTORE_DATA_SUCCESS = 'RESTORE_DATA_SUCCESS';
export const RESTORE_DATA_FAIL = 'RESTORE_DATA_FAIL';

export const BIND_ACCOUNT_START = 'BIND_ACCOUNT_START';
export const BIND_ACCOUNT_SUCCESS = 'BIND_ACCOUNT_SUCCESS';
export const BIND_ACCOUNT_FAIL = 'BIND_ACCOUNT_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};
const defaultToken = '';

export const login = (password, username, verifyCaptcha, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/login${getQueryString({
    'g-recaptcha-response': verifyCaptcha,
  })}`,
  headers,
  body: { password, username },
  onSuccess,
  onError,
  types: [LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL],
});

export const logout = onSuccess => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/logout`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'text',
  types: [USER_LOGOUT_START, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL],
  onSuccess,
});

export const bindGoogleAccount = (onSuccess, provider = 'google') => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/oauth2/bind/${provider}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken'),
  },
  responseType: 'text',
  types: [BIND_ACCOUNT_START, BIND_ACCOUNT_SUCCESS, BIND_ACCOUNT_FAIL],
  onSuccess,
});

export const logoutGoogle = (token, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_BASE}/api/v1/login/bind`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'text',
  types: [LOGIN_GOOGLE_START, LOGIN_GOOGLE_SUCCESS, LOGIN_GOOGLE_FAIL],
  onSuccess,
});

export const refreshLogin = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/login/refresh`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('refreshToken'),
  },
  types: [REFRESH_LOGIN_START, REFRESH_LOGIN_SUCCESS, REFRESH_LOGIN_FAIL],
  onSuccess,
  onError,
});

export const loginGoogle = (provider, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/oauth2/authorization/${provider}`,
  headers: {
    ...headers,
    credentials: 'same-origin',
  },
  responseType: 'text',
  onSuccess,
  onError,
  types: [LOGIN_GOOGLE_START, LOGIN_GOOGLE_SUCCESS, LOGIN_GOOGLE_FAIL],
});

export const maskIpCheck = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/mask/check`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [MASK_IP_CHECK_START, MASK_IP_CHECK_SUCCESS, MASK_IP_CHECK_FAIL],

  onSuccess,
  onError,
});

export const registerUser = (email, password, verifyCaptcha, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/register${getQueryString({
    'g-recaptcha-response': verifyCaptcha,
  })}`,
  headers,
  body: { email, password },
  onSuccess,
  onError,
  types: [REGISTER_START, REGISTER_SUCCESS, REGISTER_FAIL],
});

export const registerUserData = (firstName, lastName, token, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_BASE}/api/v1/register`,
  headers: { ...headers, Authorization: token },
  body: {
    firstName,
    lastName,
  },
  types: [REGISTER_DATA_START, REGISTER_DATA_SUCCESS, REGISTER_DATA_FAIL],
  onSuccess,
  onError,
});

export const restoreUser = (email, verifyCaptcha, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/restore${getQueryString({
    email,
    'g-recaptcha-response': verifyCaptcha,
  })}`,
  headers,
  onSuccess,
  onError,
  types: [RESTORE_START, RESTORE_SUCCESS, RESTORE_FAIL],
});

export const restoreUserData = (password, token, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_BASE}/api/v1/restore`,
  headers: { ...headers, Authorization: token || defaultToken },
  body: {
    password,
  },
  types: [RESTORE_DATA_START, RESTORE_DATA_SUCCESS, RESTORE_DATA_FAIL],
  onSuccess,
});

export const actualLogin = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  // url: API_URL + '/login/check',
  url: `${API_URL}/mask/check`,
  headers: {
    ...headers,
    // Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [ACTUAL_LOGIN_START, ACTUAL_LOGIN_SUCCESS, ACTUAL_LOGIN_FAIL],
  onSuccess,
  onError,
});

export const actualLoginRegisterRestore = (token, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/login/check`,
  headers: { ...headers, Authorization: token },
  withoutRefresh: true,
  types: [ACTUAL_LOGIN_START, ACTUAL_LOGIN_SUCCESS, ACTUAL_LOGIN_FAIL],
  onSuccess,
  onError,
});

export const registerKampusUser = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/info`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  types: [REGISTER_START, REGISTER_SUCCESS, REGISTER_FAIL],
});

export const updateLoginEmpty = () => ({
  type: UPDATE_LOGIN_EMPTY,
});

export const externalLogin = () => ({
  type: EXTERNAL_LOGIN,
});

export const externalLogout = () => ({
  type: EXTERNAL_LOGOUT,
});

export const googleLoginFail = () => ({
  type: GOOGLE_LOGIN_FAIL,
});

export const googleLoginSuccess = () => ({
  type: GOOGLE_LOGIN_SUCCESS,
});

export const clearWelcomeModal = () => ({
  type: CLEAR_WELCOME_MODAL,
});

export const clearReminderModal = () => ({
  type: CLEAR_REMINDER_MODAL,
});

export const MODALS = {
  WELCOME: 'WELCOME',
  TEST_REMINDER: 'TEST_REMINDER',
};
