/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { finishExistingMaterial, getInfoTopic, startExistingMaterial } from '@/store/existing-material/actions';
// Запросы
import { getFile } from '@/store/files/actions';
import { getBlock } from '@/store/lms/actions';

import { selectTopicInfo as selectTopic } from '@/store/existing-material/selectors';

// Компоненты
import { Button, notification, Spin, Tooltip } from 'antd';

import { getDefaultMaterialImage } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './ExistingMaterial.module.scss';

export const ExistingMaterial = props => {
  const [image, setImage] = useState('');
  const [isChangeBlock, setIsChangeBlock] = useState(false);
  const [isFinishBlock, setIsFinishBlock] = useState(false);

  const MAX_TOPIC_NAME_LENGTH = 40;

  useEffect(() => {
    setIsChangeBlock(true);
    props.getBlock(props.block.blockId, ({ existingTopicId }) => {
      props.getInfoTopic(existingTopicId, () => setIsChangeBlock(false));
    });
  }, [props.block]);

  useEffect(() => {
    if (props.topicInfo.coverFileUuid) {
      props.getFile(props.topicInfo.coverFileUuid, blob => setImage(URL.createObjectURL(blob)));
    }

    if (props.topicInfo.type && props.topicInfo.type.id && !props.topicInfo.coverFileUuid) {
      setImage(getDefaultMaterialImage(props.topicInfo.type.id));
    }
  }, [props.topicInfo]);

  const startBlock = () => {
    if (props.block.isAvailableForStart) {
      props.startExistingMaterial(props.block.blockId, () => {
        document.location.replace(`/collection-topics/${props.topicInfo.id}`);
        props.getStatisticStart();
      });
    } else {
      document.location.replace(`/collection-topics/${props.topicInfo.id}`);
    }
  };

  const finishBlock = () => {
    props.finishExistingMaterial(
      props.block.blockId,
      () => {
        notification.success({
          message: props.t('successTitle'),
          description: props.t('successDescription'),
        });
        setIsFinishBlock(true);
        document.location.reload();
      },
      () => {
        notification.error({
          message: props.t('errorTitle'),
          description: props.t('errorDescription'),
        });
      }
    );
  };

  const render = () => {
    if (props.topicInfo.name && !isChangeBlock) {
      return (
        <div className={css['material']}>
          <div onClick={() => startBlock()} className={css['material__image-box']}>
            <img
              className={css['material__image']}
              style={{ backgroundColor: props.topicInfo.coverColor }}
              src={image}
              alt=' '
            ></img>
          </div>
          <div className={css['material__info']}>
            {props.topicInfo.name.length > MAX_TOPIC_NAME_LENGTH ? (
              <Tooltip className={css['material__caption']} title={props.topicInfo.name}>
                {props.topicInfo.name}
              </Tooltip>
            ) : (
              <span onClick={() => startBlock()} className={css['material__caption']}>
                {props.topicInfo.name}
              </span>
            )}
            <Button
              className={css['material__button']}
              onClick={() => finishBlock()}
              size='large'
              type='primary'
              danger
              disabled={props.block.isAvailableForStart || isFinishBlock || props.block.status === 'FINISHED'}
            >
              {props.t('finish')}
            </Button>
          </div>
        </div>
      );
    } else if (props.loading || isChangeBlock) {
      return (
        <div className={css['material__loading-box']}>
          <Spin
            tip={props.t('loading')}
            className={css['material__loading']}
            style={{ fontSize: 24, color: 'var(--personalColor)' }}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return render();
};

ExistingMaterial.proptypes = {
  block: PropTypes.object.isRequired,
  getInfoTopic: PropTypes.func.isRequired,
  getBlock: PropTypes.func.isRequired,
  startExistingMaterial: PropTypes.func.isRequired,
  finishExistingMaterial: PropTypes.func.isRequired,
  getDefaultMaterialImage: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  topicInfo: PropTypes.object.isRequired,
  getStatisticStart: PropTypes.func,
};

const mapStateToProps = createSelector(selectTopic, existingMaterial => ({
  topicInfo: existingMaterial.topic,
  loading: existingMaterial.loading,
}));

const mapActionToProps = {
  getInfoTopic,
  getBlock,
  startExistingMaterial,
  finishExistingMaterial,
  getDefaultMaterialImage,
  getFile,
};

export default connect(mapStateToProps, mapActionToProps)(withTranslation('existingMaterial')(ExistingMaterial));
