// HOOKS
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';

// STORE
import { getPublicFile, getPublicFileOffline } from '@/store/files/actions';
import {
  checkedIsAvailableForStart,
  getPresentation,
  getPresentationOffline,
  setPresentationBlockFinish,
  setPresentationBlockStart,
} from '@/store/lms/actions';

import { selectFiles } from '@/store/files/selectors';
import { selectLMS } from '@/store/lms/selectors';

// COMPONENTS
import { Button, Spin, Typography } from 'antd';
import Condition from '@/components/Condition';

import Controller from './parts/Controller/Controller';

import classNames from 'classnames';
import { openDB } from 'idb';
// ADDITIONAL IMPORTS
import PropTypes from 'prop-types';

import css from './Presentation.module.scss';

import { IDB_NAME, PRESENTATION_FILE_IDB, PRESENTATION_IDB } from '@/constants/IndexedDB';
import { FINISHED } from '@/constants/study-plan-statuses';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Text } = Typography;

const MOBILE_SIZE = 992;
const MAX_TIME_TO_SWIPE = 250;
const MIN_TIME_TO_SWIPE = 50;
const MAX_COUNT_RERENDER = 50;
const DEFAULT_ZOOM = 1;
let xDown = null;
let yDown = null;
let xDiff = null;
let yDiff = null;
let interval = null;
let ms = 0;

const Presentation = ({
  block,
  getStatistic,
  updateChaptersIDB,
  removeChaptersFromIDB,
  reviewModalVisible,
  getStatisticStart,
}) => {
  const { t } = useTranslation('presentation');
  const dispatch = useDispatch();

  const [pages, setPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);
  const [isPresentationIDB, setIsPresentationIDB] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [zoom, setZoom] = useState(0);
  const [presentationIsRender, setPresentationIsRender] = useState(false);
  const [countRerender, setCountRerender] = useState(MAX_COUNT_RERENDER);
  const content = React.useRef();
  const main = React.useRef();

  const { presentation, statistic, arrayChecked } = useSelector(selectLMS);
  const { filePublic, isLoadingPublic } = useSelector(selectFiles);

  const isMobile = useMemo(() => MOBILE_SIZE >= screenWidth, [screenWidth]);

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  useEffect(() => {
    checkPresentationIDB();
    setPageNumber(1);
    setPresentationIsRender(false);
    !window.navigator.onLine && getPresentationIDB();
    if (block?.presentation?.uuid && block?.presentation?.id) {
      dispatch(getPresentation(block.presentation.id));
      dispatch(getPublicFile(block.presentation.uuid));
    }
    if (block?.isAvailableForStart && !arrayChecked?.includes(block?.blockId)) {
      dispatch(
        setPresentationBlockStart(block.blockId, () => {
          getStatisticStart();
        })
      );
      dispatch(checkedIsAvailableForStart(block?.blockId));
    }
  }, [block]);

  useEffect(() => {
    if (!isLoadingPublic) {
      setZoom(DEFAULT_ZOOM);
    }
  }, [isLoadingPublic]);

  useEffect(() => {
    if (window.innerWidth !== screenWidth) {
      setScreenWidth(window.innerWidth);
    }
  }, [isMobile]);

  useEffect(() => {
    const canvas = document.getElementsByTagName('canvas')[0];
    if (
      content.current?.offsetHeight &&
      canvas?.offsetHeight &&
      main.current?.offsetWidth &&
      !presentationIsRender &&
      countRerender > 0
    ) {
      if (canvas.offsetWidth > main.current?.offsetWidth || canvas.offsetHeight > content.current.offsetHeight) {
        setZoom(zoom - 0.05);
        setCountRerender(countRerender - 1);
      } else if (
        Math.abs(canvas.offsetWidth - main.current?.offsetWidth) >= 200 ||
        Math.abs(canvas.offsetHeight - content.current.offsetHeight) >= 200
      ) {
        setZoom(zoom + 0.05);
        setCountRerender(countRerender - 1);
      } else {
        setCountRerender(MAX_COUNT_RERENDER);
        setPresentationIsRender(true);
      }
    } else if (countRerender === 0) {
      setCountRerender(MAX_COUNT_RERENDER);
      setPresentationIsRender(true);
    }
  }, [zoom, content, main, presentationIsRender]);

  useEffect(() => {
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchend', handleTouchEnd, false);
    document.addEventListener('keydown', onKeyboardControl);
    document.addEventListener('fullscreenchange', fullscreenHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenHandler);
    document.addEventListener('mozfullscreenchange', fullscreenHandler);
    document.addEventListener('MSFullscreenChange', fullscreenHandler);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove, false);
      document.removeEventListener('touchstart', handleTouchStart, false);
      document.removeEventListener('touchend', handleTouchEnd, false);
      document.removeEventListener('keydown', onKeyboardControl);
      document.removeEventListener('fullscreenchange', fullscreenHandler);
      document.removeEventListener('webkitfullscreenchange', fullscreenHandler);
      document.removeEventListener('mozfullscreenchange', fullscreenHandler);
      document.removeEventListener('MSFullscreenChange', fullscreenHandler);
    };
  }, [pageNumber, zoom, fullscreen]);

  const fullscreenHandler = () => {
    setZoom(DEFAULT_ZOOM);
    setPresentationIsRender(false);
    setFullscreen(!fullscreen);
  };

  const windowWidth = !isMobile ? window.innerWidth - 450 : window.innerWidth - 100;

  const onKeyboardControl = e => {
    if (!reviewModalVisible) {
      switch (e.code) {
        case 'KeyF':
          onFullscreen();
          break;

        case 'ArrowRight':
          changePage('nextPage');
          break;

        case 'ArrowLeft':
          changePage('prevPage');
          break;

        case 'ArrowUp':
          changeZoom('zoomIn');
          break;

        case 'ArrowDown':
          changeZoom('zoomOut');
          break;
        default:
          break;
      }
    }
  };

  const changePage = option => {
    switch (option) {
      case 'firstPage':
        setPageNumber(1);
        break;
      case 'lastPage':
        setPageNumber(pages);
        break;
      case 'nextPage':
        if (pageNumber !== pages) {
          setPageNumber(pageNumber + 1);
        }
        break;
      case 'prevPage':
        if (pageNumber !== 1) {
          setPageNumber(pageNumber - 1);
        }
        break;
      default:
        break;
    }
  };

  const changeZoom = option => {
    switch (option) {
      case 'zoomIn':
        setZoom(zoom + 0.1);
        break;
      case 'zoomOut':
        if (zoom.toFixed(1) !== '0.1') {
          setZoom(zoom - 0.1);
        }
        break;
      default:
        break;
    }
  };

  function timer() {
    interval = interval = setInterval(() => {
      ms += 50;
    }, 50);
  }

  function handleTouchStart(evt) {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
    timer();
  }

  function handleTouchEnd() {
    if (!xDown || !yDown) {
      return;
    }

    if (Math.abs(xDiff) > Math.abs(yDiff) && ms <= MAX_TIME_TO_SWIPE && ms >= MIN_TIME_TO_SWIPE) {
      if (xDiff > 0 && pageNumber !== pages) {
        setPageNumber(pageNumber + 1);
      } else if (pageNumber !== 1) {
        setPageNumber(pageNumber - 1);
      }
    }

    xDown = null;
    yDown = null;
    clearInterval(interval);
    ms = 0;
  }

  function handleTouchMove(evt) {
    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    xDiff = xDown - xUp;
    yDiff = yDown - yUp;
  }

  const finishBlock = () => {
    dispatch(setPresentationBlockFinish(block.blockId, () => getStatistic(next <= 1)));
  };

  const documentLoadSuccess = documentState => {
    setPages(documentState.numPages);
  };

  const onFullscreen = () => {
    let pdfWrapper = main.current;
    if (!fullscreen && pdfWrapper) {
      if (pdfWrapper.requestFullscreen) {
        pdfWrapper.requestFullscreen();
      } else if (pdfWrapper.msRequestFullscreen) {
        pdfWrapper.msRequestFullscreen();
      } else if (pdfWrapper.webkitRequestFullscreen) {
        /* IE11 */
        pdfWrapper.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  const checkPresentationIDB = async () => {
    const db = await openDB(IDB_NAME);
    const presentation = await db.get(PRESENTATION_IDB, block.presentation.id);
    if (presentation) {
      setIsPresentationIDB(true);
    } else {
      setIsPresentationIDB(false);
    }
  };

  const getPresentationIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (db.objectStoreNames.contains(PRESENTATION_IDB)) {
      const presIDB = await db.get(PRESENTATION_IDB, block.presentation.id);
      const presFileIDB = await db.get(PRESENTATION_FILE_IDB, block.presentation.id);
      if (presIDB) {
        dispatch(getPresentationOffline(presIDB.value));
        dispatch(getPublicFileOffline(presFileIDB.value));
      }
    }
  };

  const transactionPresentationIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (isPresentationIDB) {
      await db.delete(PRESENTATION_IDB, block.presentation.id);
      await db.delete(PRESENTATION_FILE_IDB, block.presentation.id);
      removeChaptersFromIDB();
      setIsPresentationIDB(false);
    } else {
      updateChaptersIDB();
      setIsPresentationIDB(true);
      await db.add(PRESENTATION_IDB, {
        value: presentation,
        id: block.presentation.id,
      });
      await db.add(PRESENTATION_FILE_IDB, {
        value: filePublic,
        id: block.presentation.id,
      });
    }
  };

  const changeZoomCanvas = () => {
    if (!presentationIsRender) {
      setZoom(DEFAULT_ZOOM);
    }
  };

  const render = () => {
    return (
      <div
        ref={main}
        className={fullscreen ? classNames(css['Presentation'], css['Presentation-fullscreen']) : css['Presentation']}
      >
        <Condition when={block}>
          <div className={css['Presentation__title']} id={'Title'}>
            <Text>{t('presentation')}</Text>
            <div className={css['Presentation__button-download']}>
              <Button
                onClick={transactionPresentationIDB}
                type={isPresentationIDB ? 'danger' : 'primary'}
                icon={isPresentationIDB ? <DeleteOutlined /> : <DownloadOutlined />}
                size='large'
                shape='circle'
              />
            </div>
          </div>
          <div className={css['Presentation__name']} id={'Name'}>
            <Text>{block.name}</Text>
          </div>
        </Condition>
        <div ref={content} className={css[fullscreen ? 'Presentation__content-fullscreen' : 'Presentation__content']}>
          {isLoadingPublic ? (
            <Spin />
          ) : (
            <Document file={filePublic} onLoadSuccess={documentLoadSuccess}>
              <Page
                width={fullscreen ? windowWidth : 700}
                pageNumber={pageNumber}
                scale={parseFloat(zoom)}
                onLoadSuccess={() => changeZoomCanvas()}
              />
            </Document>
          )}
        </div>
        {!isLoadingPublic && (
          <Controller
            fullscreen={fullscreen}
            changePage={changePage}
            changeZoom={changeZoom}
            changeFullscreen={onFullscreen}
          />
        )}
        {!isLoadingPublic && (
          <div className={css['Presentation__pages']}>
            <Text>{pageNumber}</Text>
            <span>/</span>
            <Text>{pages}</Text>
          </div>
        )}
        {block.status !== 'FINISHED' && !fullscreen && window.navigator.onLine && (
          <Button type='primary' size='large' onClick={finishBlock}>
            {next > 1 ? t('next') : t('end')}
          </Button>
        )}
      </div>
    );
  };

  return render();
};

Presentation.propTypes = {
  block: PropTypes.object.isRequired,
  getStatistic: PropTypes.func.isRequired,
  removeChaptersFromIDB: PropTypes.func.isRequired,
  updateChaptersIDB: PropTypes.func.isRequired,
  reviewModalVisible: PropTypes.bool.isRequired,
};

export default Presentation;
