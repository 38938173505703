import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Avatar, Button, Input } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import Comment from '@/components/Comment';
import Rate from '@/components/Rate';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import debounce from '@shared/utils/debounce';

import PropTypes from 'prop-types';

import css from './Comments.module.scss';

const { TextArea } = Input;

const Comments = props => {
  const { t } = props;
  const [state, setState] = useState({
    commentPage: 1,
    rate: null,
    isReviewed: props.hasMention || false,
    hideActions: true,
  });

  const [textAreaValue, setTextAreaValue] = useState('');

  const getComments = debounce(props.getComments, 1000);

  const [currenAvatar] = useLocalFileByUUID(props.currentUser.localPhotoUuid || props.currentUser.photoUuid, {
    defaultImg,
  });

  useEffect(() => {
    if (props.compId) {
      getComments(
        {
          compilationId: props.compId,
        },
        response => setState({ ...state, hasMore: !!response.length })
      );
    }
  }, []);

  const changeComment = event => {
    setTextAreaValue(event.target.value);
  };

  const handleAddComment = () => {
    if (textAreaValue) {
      props.createCompilationComments(
        {
          compilationId: +props.compId,
          rate: state.rate,
          text: textAreaValue,
        },
        () => {
          setState({
            ...state,
            isReviewed: props.hasMention || state.isReviewed || state.rate !== null,
            rate: null,
          });
          setTextAreaValue('');
          getComments(
            {
              page: 0,
              compilationId: props.compId,
            },
            res => setState({ ...state, hasMore: !!res.length })
          );
        }
      );
    }
  };

  const editCommentStart = commentId => {
    setState({ ...state, editableComment: +commentId });
  };

  const editCommentFinish = () => {
    setState({ ...state, editableComment: 0 });
  };

  const changePageComment = () => {
    getComments({
      compilationId: props.compId,
      page: +props.pageCompilationComments + 1,
    });
  };

  const changeRate = event => {
    const rate = event.value;
    setState({ ...state, rate });
  };

  const compareNumeric = (a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
  };

  const onCommentUpdate = (id, params, onSuccess) => {
    props.updateCompilationComment(
      id,
      {
        ...params,
        topicId: +props.compId,
      },
      onSuccess
    );
  };

  const onCommentDelete = (id, onSuccess) => {
    props.deleteCompilationComment(id, onSuccess);
  };

  const onCommentRestore = (id, onSuccess) => {
    props.restoreCompilationComment(id, onSuccess);
  };

  const arr = props.comments;
  if (arr) {
    arr.sort(compareNumeric);
  }

  const comments = arr.map(comment => (
    <Comment
      key={comment.id}
      comment={comment}
      review={comment.rate !== null}
      editCommentStart={editCommentStart}
      editableComment={state.editableComment}
      currentUserId={props.currentUserId}
      getFile={props.getFile}
      updateComment={onCommentUpdate}
      deleteComment={onCommentDelete}
      restoreComment={onCommentRestore}
      editCommentFinish={editCommentFinish}
      hideActions={state.hideActions}
    />
  ));
  return (
    <div className={css.MaterialsTabsAboutBlock}>
      <div className={css['MaterialsTabsAbout-container']}>
        <div className={css['MaterialsTabsAbout-comment']}>
          <Avatar className={css['MaterialsTabsAbout-avatar']} src={currenAvatar} />
          <div className={css['MaterialsTabsAbout-comment-block']}>
            <TextArea
              className={css['MaterialsTabsAbout-form-textarea']}
              autoSize={{ minRows: 4 }}
              onChange={changeComment}
              value={textAreaValue}
              maxLength={1500}
              placeholder={t('comment')}
            />
            <div className={css['MaterialsTabsAbout-comment-feedback']}>
              <Button
                type='primary'
                className={css['MaterialsTabsAbout-comment-feedback-block-button']}
                onClick={handleAddComment}
                disabled={!textAreaValue}
                size='large'
              >
                {t('send')}
              </Button>
              {!state.isReviewed && (
                <div>
                  <div className={css['MaterialsTabsAbout-comment-feedback-rate-label']}>{t('feedback')}</div>
                  <Rate
                    disabled={state.isReviewed}
                    onChange={changeRate}
                    count={5}
                    value={state.rate}
                    size={Rate.sizes.lg}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <InfiniteScroll
          className={css.MaterialsTabsAboutBlock}
          initialLoad={false}
          loadMore={props.compId && changePageComment}
          hasMore={props.hasMoreCompilationComments}
          threshold={300}
          useWindow
        >
          {comments}
        </InfiniteScroll>
      </div>
    </div>
  );
};

Comments.propTypes = {
  comments: PropTypes.array,
  commentsCount: PropTypes.number,
  commentsPage: PropTypes.number,
  compId: PropTypes.number,
  createCompilationComments: PropTypes.func,
  currentUser: PropTypes.object,
  currentUserId: PropTypes.number,
  deleteCompilationComment: PropTypes.func,
  getComments: PropTypes.func,
  getFile: PropTypes.func,
  hasMention: PropTypes.bool,
  hasMoreCompilationComments: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAddComment: PropTypes.func,
  pageCompilationComments: PropTypes.number,
  rate: PropTypes.number,
  restoreCompilationComment: PropTypes.func,
  topicId: PropTypes.string,
  updateCompilationComment: PropTypes.func,
};

export default withTranslation('comments')(Comments);
