import MarsBanner from './assets/mars-banner.svg';

import styles from './BannerUpHeader.module.scss';

const BannerUpHeader = () => {
  return (
    <div className={styles['banner-up-header']}>
      <img className={styles['banner-up-header__image']} src={MarsBanner} alt='' />
    </div>
  );
};

export default BannerUpHeader;
