import { API_D360_URL } from '../api_service';
import {
  D360_VOTE_FAIL,
  D360_VOTE_START,
  D360_VOTE_SUCCESS,
  D360_VOTE_UPDATE_FAIL,
  D360_VOTE_UPDATE_START,
  D360_VOTE_UPDATE_SUCCESS,
} from './constants';

import { POST, PUT, REST_API } from '@shared/constants/rest-api';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360Vote = (projectId, measurableId, criteriaId, scaleId, scoreId, comment, onSuccess, onError) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_D360_URL}/employee/vote`,
    body: {
      projectId: projectId,
      measurableId: measurableId,
      criteriaId: criteriaId,
      scaleId: scaleId,
      scoreId: scoreId,
      comment: comment,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_VOTE_START, D360_VOTE_SUCCESS, D360_VOTE_FAIL],
    onSuccess,
    onError,
  };
};

export const D360VoteUpdate = (projectId, measurableId, criteriaId, scaleId, scoreId, comment, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_D360_URL}/employee/vote/update`,
    body: {
      projectId: projectId,
      measurableId: measurableId,
      criteriaId: criteriaId,
      scaleId: scaleId,
      scoreId: scoreId,
      comment: comment,
    },
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_VOTE_UPDATE_START, D360_VOTE_UPDATE_SUCCESS, D360_VOTE_UPDATE_FAIL],
    onSuccess,
    onError,
  };
};
