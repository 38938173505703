import {
  CLEAR_POKA_SKILLS_FILTERS,
  FINISH_POKA_CHECKLIST_FAIL,
  FINISH_POKA_CHECKLIST_START,
  FINISH_POKA_CHECKLIST_SUCCESS,
  GET_POKA_ASSESSOR_FAIL,
  GET_POKA_ASSESSOR_START,
  GET_POKA_ASSESSOR_SUCCESS,
  GET_POKA_CATEGORY_FAIL,
  GET_POKA_CATEGORY_START,
  GET_POKA_CATEGORY_SUCCESS,
  GET_POKA_CHECKLIST_FAIL,
  GET_POKA_CHECKLIST_START,
  GET_POKA_CHECKLIST_SUCCESS,
  GET_POKA_DIR_SITE_FAIL,
  GET_POKA_DIR_SITE_START,
  GET_POKA_DIR_SITE_SUCCESS,
  GET_POKA_SKILLS_FAIL,
  GET_POKA_SKILLS_START,
  GET_POKA_SKILLS_SUCCESS,
  GET_POKA_STATUS_FAIL,
  GET_POKA_STATUS_START,
  GET_POKA_STATUS_SUCCESS,
  SAVE_POKA_SKILLS_FILTERS,
  SAVE_POKA_SKILLS_SORT,
  SET_IS_FAVOURITE,
  START_POKA_CHECKLIST_FAIL,
  START_POKA_CHECKLIST_START,
  START_POKA_CHECKLIST_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

// получение топиков Пока
export const getPokaSkills = (body, page, sort, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/poka/state${getQueryString({ page, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [GET_POKA_SKILLS_START, GET_POKA_SKILLS_SUCCESS, GET_POKA_SKILLS_FAIL],
  onSuccess,
  onError,
});

// получение статусов Пока
export const getPokaStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/plan/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_STATUS_START, GET_POKA_STATUS_SUCCESS, GET_POKA_STATUS_FAIL],
  onSuccess,
  onError,
});

// получение категорий Пока
export const getCatPoka = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/category`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_CATEGORY_START, GET_POKA_CATEGORY_SUCCESS, GET_POKA_CATEGORY_FAIL],
  onSuccess,
  onError,
});

export const getPokaAssessor = (userId, topicId) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/state${getQueryString({ userId, topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_ASSESSOR_START, GET_POKA_ASSESSOR_SUCCESS, GET_POKA_ASSESSOR_FAIL],
});

export const getChecklistInfo = (blockId, studyPlanId) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/block/checklist/${blockId}${getQueryString({ studyPlanId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_CHECKLIST_START, GET_POKA_CHECKLIST_SUCCESS, GET_POKA_CHECKLIST_FAIL],
});

export const startChecklist = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/${blockId}/checklist/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_POKA_CHECKLIST_START, START_POKA_CHECKLIST_SUCCESS, START_POKA_CHECKLIST_FAIL],
});

export const finishChecklist = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/${blockId}/checklist/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [FINISH_POKA_CHECKLIST_START, FINISH_POKA_CHECKLIST_SUCCESS, FINISH_POKA_CHECKLIST_FAIL],
  onSuccess,
});

export const getPokaDirSite = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/dir/site`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POKA_DIR_SITE_START, GET_POKA_DIR_SITE_SUCCESS, GET_POKA_DIR_SITE_FAIL],
});

// сохранить сортировку Поки
export const savePokaSkillsSort = value => ({
  type: SAVE_POKA_SKILLS_SORT,
  value,
});

// сохранение фильтров Поки
export const savePokaSkillsFilters = value => ({
  type: SAVE_POKA_SKILLS_FILTERS,
  value,
});

// удалить фильтры Поки
export const clearPokaSkillsFilters = () => ({
  type: CLEAR_POKA_SKILLS_FILTERS,
});

// перезаписать isFavourite в состоянии
export const setIsFavourite = value => ({
  type: SET_IS_FAVOURITE,
  value,
});
