/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { getCompetences, getRecommendation } from '@/store/talentq/actions';

import { selectTalentQ } from '@/store/talentq/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Row, Select, Spin } from 'antd';

import MaterialCard from '../../../components/MaterialCard';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './compilationTalentQ.module.scss';

const { Option } = Select;

export const CompilationTalentQ = props => {
  const { t } = props;
  const FIRSTVALUE = t('selectCompetence');
  const LOWPRIORITY = 'lowPriority';
  const MEDIUMPRIORITY = 'mediumPriority';
  const LOW = 'low';
  const MEDIUM = 'middle';

  useEffect(() => {
    props.getRecommendation(LOW);
  }, [props.getRecommendation]);

  useEffect(() => {
    props.getCompetences(LOW);
  }, [props.getCompetences]);

  const [all, setAll] = useState({
    isLow: true,
    isMedium: false,
    isCompetence: false,
    filterCompetence: [],
    toggleClass: LOWPRIORITY,
    valueSelect: FIRSTVALUE,
  });

  const showRec = rec => {
    props.getRecommendation(rec);
    props.getCompetences(rec);
    setAll({
      ...all,
      isLow: rec === LOW,
      isMedium: rec === MEDIUM,
      toggleClass: rec === LOW ? LOWPRIORITY : MEDIUMPRIORITY,
      isCompetence: false,
      valueSelect: FIRSTVALUE,
    });
  };

  function onChangeCompetence(value, option) {
    const selectValue = option?.props?.children;
    const result =
      Array.isArray(props.recommendation) &&
      props.recommendation
        .filter(topic => topic.competencies.includes(selectValue))
        .map(topic => {
          return (
            <Col key={topic.id} span={6}>
              {drawTopic(topic)}
            </Col>
          );
        });
    setAll({
      isLow: false,
      isMedium: false,
      isCompetence: true,
      filterCompetence: result || [],
      toggleClass: '',
      valueSelect: value,
    });
  }

  const drawTopic = topic => {
    topic.thumbFileUuid = topic.cover_file_uuid;
    return (
      <div style={{ margin: '10px' }}>
        <MaterialCard
          key={topic.id}
          topic={topic}
          userTopicId={topic.id}
          currentUser={props.currentUser}
          getFile={props.getFile}
          domainCompany={props.domainCompany}
          thumbFileUuid={topic.thumbFileUuid}
        />
      </div>
    );
  };

  const resultRec =
    props.recommendation &&
    Array.isArray(props.recommendation) &&
    props.recommendation.map(topic => {
      return (
        <Col key={topic.id} span={6}>
          {drawTopic(topic)}
        </Col>
      );
    });

  const render = () => {
    return (
      <>
        <div className={css['Compilation-header']}>
          <h2>{t('compilations')}</h2>
        </div>

        <Spin size='large' spinning={props.loading || props.isLoadingRec}>
          <Row className={css.bar}>
            <Col span={24} className={css.blockFilter}>
              <Button
                size='large'
                name={LOWPRIORITY}
                type={all.toggleClass === LOWPRIORITY ? 'primary' : 'default'}
                onClick={() => showRec(LOW)}
              >
                {t('priorityZone')}
              </Button>
              <Button
                size='large'
                name={MEDIUMPRIORITY}
                type={all.toggleClass === MEDIUMPRIORITY ? 'primary' : 'default'}
                className={classNames(css['btnAdditional'])}
                onClick={() => showRec(MEDIUM)}
              >
                {t('additionalZone')}
              </Button>
              <Select
                size='large'
                className={css['selectCustom']}
                onChange={onChangeCompetence}
                value={all.valueSelect}
              >
                {props.competences.map((item, index) => (
                  <Option key={index}>{item}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row className={css['Topics']}>
            {(all.isLow || all.isMedium) && resultRec}
            {all.isCompetence && all.filterCompetence}
          </Row>
        </Spin>
      </>
    );
  };

  return render();
};

CompilationTalentQ.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  getFile: PropTypes.func,
  domainCompany: PropTypes.object,
  recommendation: PropTypes.array,
  competences: PropTypes.array,
  getRecommendation: PropTypes.func,
  getCompetences: PropTypes.func,
  isLoadingRec: PropTypes.bool,
};

const mapStateToProps = createSelector([selectTalentQ, selectUsers], (selectTalentQ, user) => ({
  loading: selectTalentQ?.loading,
  recommendation: selectTalentQ.recommendation,
  competences: selectTalentQ.competences,
  currentUser: user.currentUser,
  domainCompany: user?.currentUser?.domainCompany,
  isLoadingRec: selectTalentQ?.isLoadingRec,
}));

const mapActionsToProps = { getFile, getRecommendation, getCompetences };

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticTalentq')(CompilationTalentQ));
