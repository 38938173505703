/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { eventRequestRefuseUI } from '@/store/course/actions';

import { Button, Modal } from 'antd';

import useFormatCost from '@shared/hooks/useFormatCost';

import PropTypes from 'prop-types';

import css from './PenaltyModal.module.scss';

export const CancelWithPenaltyModal = props => {
  const { t } = useTranslation('penaltyModal');
  const dispatch = useDispatch();

  const formattedCost = useFormatCost(props.initialCostPenalty, props.currentUser);

  const confPenalty = () => {
    dispatch(eventRequestRefuseUI(props.material?.id, () => props.onOkPenalty()));
  };

  return (
    <Modal width={550} open={props.open} zIndex={10000} footer={null} onCancel={props.onCancel}>
      <div className={css['penaltyModal-body']}>
        <div>{t('main', { penaltyCancelCost: formattedCost })}</div>
        <div className={css['penaltyModal-body-alert']}>{t('alertText')}</div>
        <div>{t('confText')}</div>
      </div>
      <div className={css['penaltyModal-footer']}>
        <Button onClick={confPenalty}>{t('okText')}</Button>
        <Button onClick={props.onCancel} type='primary'>
          {t('cancelText')}
        </Button>
      </div>
    </Modal>
  );
};

CancelWithPenaltyModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  material: PropTypes.any,
};
