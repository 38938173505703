/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Steps } from 'antd';
import { ImStatsBars2 } from 'react-icons/im';

import { extractUsername } from '../../utils';

import PropTypes from 'prop-types';

import css from '../../css/styles.module.scss';

const { Step } = Steps;

export const MeasurableCard = props => {
  const { t } = useTranslation('D360_MeasurableCard');

  const cardTitle = (
    <>
      <ImStatsBars2 />
      &nbsp;{t('title')}
    </>
  );

  return (
    <Card
      className={css.MeasurableCard}
      title={cardTitle}
      loading={props.loading || false}
      style={{ marginTop: '2rem' }}
    >
      <Steps
        className={css.MeasurableCardSteps}
        direction='vertical'
        current={props.current}
        onChange={props.onChange}
        size='small'
      >
        {Array.isArray(props.measurables) &&
          props.measurables.map((measurable, index) => {
            return (
              <Step
                className={css.MeasurableCardStep}
                key={'measurable_' + index}
                status={
                  props.current === index
                    ? 'process'
                    : props.remainingMeasurables?.some(e => e.id === measurable.id)
                      ? 'wait'
                      : 'finish'
                }
                title={extractUsername(measurable.student)}
              />
            );
          })}
      </Steps>
    </Card>
  );
};

MeasurableCard.propTypes = {
  measurables: PropTypes.array.isRequired,
  remainingMeasurables: PropTypes.array,
  current: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MeasurableCard;
