/* eslint-disable */
import { GET_COMPETENCIES__FAIL, GET_COMPETENCIES__SUCCESS, GET_COMPETENCIES_START } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCompetencies = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${id}/competences`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCIES_START, GET_COMPETENCIES__SUCCESS, GET_COMPETENCIES__FAIL],
  onSuccess,
});
