/* eslint-disable */
import {
  CLEAR_NOTIFICATIONS,
  CLEAR_UNREAD_NOTIFICATIONS,
  GET_LIST_NOTIFICATIONS_FAIL,
  GET_LIST_NOTIFICATIONS_START,
  GET_LIST_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION_BY_ID_FAIL,
  GET_NOTIFICATION_BY_ID_START,
  GET_NOTIFICATION_BY_ID_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  PUT_READ_ALL_NOTIFICATIONS_FAIL,
  PUT_READ_ALL_NOTIFICATIONS_START,
  PUT_READ_ALL_NOTIFICATIONS_SUCCESS,
  PUT_READ_NOTIFICATION_BY_ID_FAIL,
  PUT_READ_NOTIFICATION_BY_ID_START,
  PUT_READ_NOTIFICATION_BY_ID_SUCCESS,
  SET_UNREAD_NOTIFICATIONS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getNotifications = (params, onSuccess, signal) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/notifications${getQueryString({
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  signal,
  types: [GET_NOTIFICATIONS_START, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL],
});

export const getNotificationById = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/notifications/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  valueId: id,
  types: [GET_NOTIFICATION_BY_ID_START, GET_NOTIFICATION_BY_ID_SUCCESS, GET_NOTIFICATION_BY_ID_FAIL],
});

export const putReadNotificationById = id => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/notifications/${id}/read`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  valueId: id,
  types: [PUT_READ_NOTIFICATION_BY_ID_START, PUT_READ_NOTIFICATION_BY_ID_SUCCESS, PUT_READ_NOTIFICATION_BY_ID_FAIL],
});

export const putReadAllNotifications = onSuccess => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/notifications/read`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [PUT_READ_ALL_NOTIFICATIONS_START, PUT_READ_ALL_NOTIFICATIONS_SUCCESS, PUT_READ_ALL_NOTIFICATIONS_FAIL],
});

export const getListNotifications = (onSuccess, onError, signal) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/notifications/list`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  signal,
  types: [GET_LIST_NOTIFICATIONS_START, GET_LIST_NOTIFICATIONS_SUCCESS, GET_LIST_NOTIFICATIONS_FAIL],
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});

export const clearUnReadNotifications = () => ({
  type: CLEAR_UNREAD_NOTIFICATIONS,
});

export const setUnReadNotifications = (params, isIncrement) => ({
  type: SET_UNREAD_NOTIFICATIONS,
  value: params,
  isIncrement,
});
