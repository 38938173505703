import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createChatGroupMentor } from '@/store/chat/actions';
import { acceptRequestStatus } from '@/store/mentoring_profile/actions';

import { Button, Table, Tooltip } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import { filterItems, status } from './MenteesRequestsParts/constants';
import MenteesRequestsModals from './MenteesRequestsParts/MenteesRequestsModals';
import ProfileColumn from './MenteesRequestsParts/ProfileColumn';

import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import css from './MenteesRequests.module.scss';

const MenteesRequests = ({ requests, setFilterStatus, filterStatus, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('MenteesRequests');
  const [modalType, setModalType] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentData, setCurrentData] = useState([]);

  const [isMobile] = useIsMobile(768);
  const [isTablet] = useIsMobile(1200);
  const navigate = useNavigate();

  const renderActionButtons = column => {
    const rejectReason = column?.rejectReason && (
      <Tooltip
        title={t('table.rejectReason', {
          reason: column?.rejectReason === 'OTHER' ? column?.rejectReasonOther : t(column?.rejectReason),
        })}
        placement='bottom'
      >
        <InfoCircleOutlined className={css['MenteesRequests-table-status-icon']} />
      </Tooltip>
    );

    const openModal = type => {
      setVisibleModal(true);
      setModalType(type);
      setCurrentData(column);
    };

    const confirmMentoring = () => {
      dispatch(
        acceptRequestStatus({ id: column?.id }, res => {
          dispatch(createChatGroupMentor(res.id, [res.userId]));
        })
      );
    };

    switch (column.status) {
      case status.WAITING_MENTOR:
        return (
          <div className={css['MenteesRequests-block-buttons']}>
            <div className={css['MenteesRequests-table-action-buttons']}>
              <Button icon={<CloseOutlined />} onClick={() => openModal('REJECT_CONFIRM')} size='large'>
                {t('table.reject')}
              </Button>
              <Button onClick={confirmMentoring} icon={<CheckOutlined />} size='large' type='primary'>
                {t('table.confirm')}
              </Button>
            </div>
          </div>
        );

      case status.ACTIVE:
        return (
          <div className={css['MenteesRequests-block-buttons']}>
            <p className={classNames(css['MenteesRequests-table-status'], css['MenteesRequests-table-status-active'])}>
              {t('table.activeFrom', { from: dayjs(column?.activatedTimestamp).format('LL') })}
            </p>
            <div className={css['MenteesRequests-table-action-buttons']}>
              <Button onClick={() => openModal('FINISH_CONFIRM')} size='large' type='primary'>
                {t('table.finish')}
              </Button>
              <Button size='large' type='primary' onClick={() => navigate('/chat/' + column.id)}>
                {t('table.chat')}
              </Button>
            </div>
          </div>
        );

      case status.REJECTED_BY_MENTOR:
      case status.REJECTED_BY_MENTEE:
        return (
          <div className={css['MenteesRequests-block-buttons']}>
            <p
              className={classNames(css['MenteesRequests-table-status'], css['MenteesRequests-table-status-finished'])}
            >
              {t('table.finished')}
              {rejectReason}
            </p>
          </div>
        );

      case status.FINISHED:
        return (
          <div className={css['MenteesRequests-block-buttons']}>
            <p
              className={classNames(css['MenteesRequests-table-status'], css['MenteesRequests-table-status-finished'])}
            >
              {t('table.finished')}
              {rejectReason}
            </p>
            <div className={css['MenteesRequests-table-action-buttons']}>
              {!column?.reviewByMentor && (
                <Button onClick={() => openModal('REVIEW_CONFIRM')} type='primary' size='large'>
                  {t('table.survey')}
                </Button>
              )}
              <Button onClick={() => openModal('RESULTS')} type='primary' size='large'>
                {t('table.results')}
              </Button>
            </div>
          </div>
        );

      default:
        return <></>;
    }
  };

  const columnsDesktop = [
    {
      title: t('table.fioAndPosition'),
      dataIndex: 'fioAndPosition',
      width: '300px',
      render: column => <ProfileColumn column={column} />,
    },
    {
      title: t('table.company'),
      dataIndex: 'company',
      width: '120px',
      render: column => <p>{column}</p>,
    },
    {
      title: t('table.department'),
      dataIndex: 'department',
      width: '140px',
      render: column => <p>{column}</p>,
    },
    {
      title: t('table.function'),
      dataIndex: 'function',
      width: '140px',
      render: column => <p>{column}</p>,
    },
    {
      dataIndex: 'action',
      width: '300px',
      render: column => <>{renderActionButtons(column)}</>,
    },
  ];

  const columnsTablet = [
    {
      title: t('table.fioAndPosition'),
      dataIndex: 'fioAndPosition',
      render: column => <ProfileColumn column={column} />,
    },
    {
      title: t('table.company'),
      dataIndex: 'company',
      render: column => <p className={css['MenteesRequests-table-company']}>{column}</p>,
    },
    {
      title: t('table.department'),
      dataIndex: 'department',
      render: column => <p>{column}</p>,
    },
    {
      title: t('table.function'),
      dataIndex: 'function',
      render: column => <p>{column}</p>,
    },
  ];

  const data = requests?.map(item => {
    return {
      fioAndPosition: {
        firstName: item?.firstNameMentee,
        lastName: item?.lastNameMentee,
        position: item?.positionMentee,
        photoUuidUser: item?.photoUuidUser,
        request: item?.request,
        experience: item?.experience,
        userId: item.userId,
        contact: item.contact,
        status: item.status,
      },
      company: item?.companyMentee,
      department: item?.departmentMentee,
      function: item?.functionMentee,
      key: item?.id,
      action: item,
    };
  });

  const filterItemsRender = () =>
    filterItems.map((item, index) => {
      const isActive = isEqual(item.statuses, filterStatus);
      return (
        <div
          className={classNames(
            css['MenteesRequests-filter-item'],
            isActive && css['MenteesRequests-filter-item-active']
          )}
          onClick={() => (isActive ? setFilterStatus([]) : setFilterStatus(item.statuses))}
          key={index}
        >
          {t(item.name)}
          {isActive && <CloseOutlined className={css['MenteesRequests-filter-item-icon']} />}
        </div>
      );
    });

  const renderMobileBlockInfo = (translate, value) => (
    <div className={css['MenteesRequests-block']}>
      <p className={css['MenteesRequests-caption']}>{translate}</p>
      <p className={css['MenteesRequests-value']}>{value}</p>
    </div>
  );

  const renderMobile = () => {
    return data.length ? (
      data.map((elem, index) => (
        <React.Fragment key={index}>
          <ProfileColumn column={elem.fioAndPosition} />
          {elem.company && renderMobileBlockInfo(t('table.company'), elem.company)}
          {elem.department && renderMobileBlockInfo(t('table.department'), elem.department)}
          {elem.function && renderMobileBlockInfo(t('table.function'), elem.function)}
          <>{renderActionButtons(elem.action)}</>
        </React.Fragment>
      ))
    ) : (
      <CustomEmpty />
    );
  };

  const renderDesktop = () => (
    <Table
      className={css['MenteesRequests-table']}
      expandable={
        isTablet && {
          defaultExpandAllRows: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusCircleOutlined onClick={e => onExpand(record, e)} />
            ) : (
              <PlusCircleOutlined onClick={e => onExpand(record, e)} />
            ),
          expandedRowRender: record => (
            <div className={css['MenteesRequests-expand']}>{renderActionButtons(record.action)}</div>
          ),
        }
      }
      columns={isTablet ? columnsTablet : columnsDesktop}
      dataSource={data}
      size='small'
      bordered={false}
      pagination={false}
      loading={isLoading}
    />
  );

  return (
    <div className={css['MenteesRequests-container']}>
      <MenteesRequestsModals
        type={modalType}
        data={currentData}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      />
      <div className={css['MenteesRequests-filter']}>{filterItemsRender()}</div>
      <div className={css['MenteesRequests-requests']}>{isMobile ? renderMobile() : renderDesktop()}</div>
    </div>
  );
};

MenteesRequests.propTypes = {
  requests: PropTypes.array,
  setFilterStatus: PropTypes.func,
  filterStatus: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MenteesRequests;
