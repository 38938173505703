/* eslint-disable */
import React, { Component } from 'react';

import { Checkbox } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Checkbox.module.scss';

export default class CustomCheckbox extends Component {
  static propTypes = {
    big: PropTypes.bool,
    className: PropTypes.string,
    dark: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    textValue: PropTypes.bool,
    value: PropTypes.bool,
    dataqa: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value && this.state.value !== newProps.value) {
      this.setState({ value: newProps.value || false });
    }
  }

  handleChange = event => {
    const { onChange, name } = this.props;
    const value = event.target.checked;
    this.setState({ value }, onChange({ name, value }));
  };

  render() {
    const { className, textValue, label, dark, big, dataqa, ...rest } = this.props;
    const labelSpan = label ? <span className={css['Checkbox-label']}>{label}</span> : null;

    return (
      <div className={classNames({ [css['Checkbox-wrapper']]: textValue })}>
        <Checkbox
          data-qa={dataqa}
          className={classNames(css.Checkbox, className, {
            [css['Checkbox-dark']]: dark,
            [css['Checkbox-big']]: big,
          })}
          {...rest}
          onChange={this.handleChange}
          checked={this.state.value}
        >
          {labelSpan}
        </Checkbox>
      </div>
    );
  }
}
