/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_LOCALES_START = 'GET_LOCALES_START';
export const GET_LOCALES_SUCCESS = 'GET_LOCALES_SUCCESS';
export const GET_LOCALES_FAIL = 'GET_LOCALES_FAIL';

export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_FAIL = 'SET_LOCALE_FAIL';

export const GET_LANGUAGES_START = 'GET_LANGUAGES_START';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAIL = 'GET_LANGUAGES_FAIL';

export const GET_LANGUAGES_FILTERED_START = 'GET_LANGUAGES_FILTERED_START';
export const GET_LANGUAGES_FILTERED_SUCCESS = 'GET_LANGUAGES_FILTERED_SUCCESS';
export const GET_LANGUAGES_FILTERED_FAIL = 'GET_LANGUAGES_FILTERED_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getLocales = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/locale/language`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_LOCALES_START, GET_LOCALES_SUCCESS, GET_LOCALES_FAIL],
});

export const setUserLanguage = (data, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/language`,
  body: data,
  headers: {
    ...headers,
    'Content-Type': 'text/plain',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [SET_LOCALE_START, SET_LOCALE_SUCCESS, SET_LOCALE_FAIL],
});

export const getLanguages = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/language`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_LANGUAGES_START, GET_LANGUAGES_SUCCESS, GET_LANGUAGES_FAIL],
});

export const getLanguagesFiltered = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/language?haveVisibleTopics=true`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_LANGUAGES_FILTERED_START, GET_LANGUAGES_FILTERED_SUCCESS, GET_LANGUAGES_FILTERED_FAIL],
});
