export const APPROVED_BY_USER = 'APPROVED_BY_USER';
export const FINISHED = 'FINISHED';
export const APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN';
export const APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER';
export const REJECTED = 'REJECTED';
export const WAITING_BY_USER = 'WAITING_BY_USER';

export const TASK = 'task';
export const TOPIC = 'topic';
export const MENTORS = 'mentors';

export const TASKS = 'tasks';
export const TOPICS = 'topics';

// Создание и редактирование ИПР
export const MAX_DESCRIPTION = 1500;
export const MAX_COMPETENCES = 3;

export const INCOME_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT = 'll';

// Материалы и задачи
export const MAX_TOPIC_TITLE_LENGTH = 50;

// Менторы
export const MENTORS_SIZE = 10;
export const MAX_MENTORS_SELECTED = 3;

export const MAX_COMPETENCE_NAME = 20;
