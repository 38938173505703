// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-p_LC3K.user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Zii0o0 .user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-label-YnYfHd[data-value="Montserrat"]:before, .user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-p_LC3K.user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Zii0o0 .user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-item-HhglPw[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Montserrat-EdbvyL {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-NewsId-parts-RateModal-RateModal-module__modal-wiIMIC {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/NewsId/parts/RateModal/RateModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.modal {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-p_LC3K`,
	"qlPicker": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-p_LC3K`,
	"ql-font": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Zii0o0`,
	"qlFont": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Zii0o0`,
	"ql-picker-label": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-label-YnYfHd`,
	"qlPickerLabel": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-label-YnYfHd`,
	"ql-picker-item": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-item-HhglPw`,
	"qlPickerItem": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-picker-item-HhglPw`,
	"ql-font-Montserrat": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Montserrat-EdbvyL`,
	"qlFontMontserrat": `user-src-pages-NewsId-parts-RateModal-RateModal-module__ql-font-Montserrat-EdbvyL`,
	"modal": `user-src-pages-NewsId-parts-RateModal-RateModal-module__modal-wiIMIC`
};
export default ___CSS_LOADER_EXPORT___;
