import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getLanguagesFiltered } from '@/store/locales/actions';
import { getSearchBooks } from '@/store/search/actions';
import { clearTopics, getTopics } from '@/store/topics/actions';

import { selectLocales } from '@/store/locales/selectors';
import { selectSearchUnion } from '@/store/search/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Layout, Pagination, Typography } from 'antd';
import ContextSearch from '@/components/ContextSearch';
import Sort from '@/components/Sort';
import TopicCards from '@/components/TopicCards';

import { parseSearchParams } from '@shared/utils/get-query-string';

import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './Library.module.scss';

const { Content } = Layout;
const { Title } = Typography;

const initialTopicsParams = {
  size: 20,
  page: 1,
  status: 'ACTIVE',
  topicTypeId: 16,
  qiwiLibrary: true,
};

const initialSearchTopics = { searchResult: '' };

const initialAllState = { ...initialTopicsParams, ...initialSearchTopics };

let controllerTopics = null;

const Library = props => {
  const { t } = useTranslation('collectionMaterials');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { topics, isLoadingTopicsCatalog, topicsTotal } = useSelector(selectTopics);

  const searchResults = useSelector(selectSearchUnion);
  const { currentUser } = useSelector(selectUsers);
  const { languagesFiltered } = useSelector(selectLocales);

  // eslint-disable-next-line
  const [state, setState] = useState({
    searchingTopics: false,
    global: currentUser?.domainCompany?.global,
    currentTopicId: 0,
    listAllCompetence: [],
    competencesAndCategoriesQuantity: {},
    skillsNames: null,
    restoreFirstRequest: props.location.state?.comeFromHeader,
  });

  const initialQuerySearchParams = useMemo(() => parseSearchParams(document.location.search), []);

  const [topicsParams, setTopicsParams] = useState({
    ...initialAllState,
    ...initialQuerySearchParams,
  });

  const getSearchDebounced = useCallback(
    _debounce((...args) => {
      dispatch(getSearchBooks(...args));
    }, 1000),
    []
  );

  const changePage = page => {
    window.scrollTo(0, 0);
    setTopicsParams({ ...topicsParams, page });
  };

  const handleChangeSort = sort => {
    setTopicsParams({ ...topicsParams, sort, page: 1, typeId: topicsParams?.typeId });
  };

  const onSearchChange = value => {
    getSearchDebounced(value);
  };

  const onSearchSubmit = ({ value }) => {
    setTopicsParams({ ...topicsParams, ...initialSearchTopics, searchResult: value });
  };

  const getSearchText = () => {
    // Поиск лейблов компетенций (скила)
    const listAllCompetence = state.listAllCompetence || [];
    const skillIds = topicsParams?.skillIds || [];
    const labelSkills = skillIds
      .map(skillId => {
        let competenceSkill = {};

        // Поиск скила по вложенности
        // eslint-disable-next-line
        listAllCompetence.find(t => {
          if (!!t && Array.isArray(t.skills) && t.skills.length > 0) {
            const skill = t.skills.find(b => +b.id === +skillId);
            if (skill) {
              competenceSkill = skill;
            }
          }
        });
        return competenceSkill.name || '';
      })
      .join('', '');
    // ---------------

    const currentSearchText = labelSkills || topicsParams.searchResult || '';
    const labelSearchText =
      currentSearchText.length > 20 ? `${currentSearchText.substring(0, 20)}... ` : currentSearchText;

    return {
      currentSearchText,
      labelSearchText: labelSearchText,
    };
  };

  useLayoutEffect(() => {
    if (languagesFiltered?.length === 0) dispatch(getLanguagesFiltered());

    return () => dispatch(clearTopics());
  }, []);

  useEffect(() => {
    if (controllerTopics) controllerTopics.abort();
    controllerTopics = new AbortController();

    dispatch(getTopics(topicsParams, () => {}, controllerTopics.signal));
  }, [topicsParams]);

  const isFilterNotEmpty = topicsParams && topicsParams.searchResult;

  const searchTypes = [{ id: '', type: '', name: t('all') }];

  const sortTypes = [
    {
      name: t('popularity'),
      dataQa: 'popularity',
      value: 'positions.position,asc&sort=topicShortTermPopularities.shortTermPopularity,desc&sort=id,asc',
    },
    {
      name: t('rating'),
      dataQa: 'rating',
      value: 'rates.rate,desc&sort=id,asc',
    },
    {
      name: t('date'),
      dataQa: 'date',
      value: 'positions.position,asc&sort=createdDate,desc&sort=id,asc',
    },
  ];

  const { currentSearchText, labelSearchText } = getSearchText();

  return (
    <Content className={css['App-content']}>
      <div className={css['CollectionMaterials-header-row']}>
        <div className={css['CollectionMaterials-header-text']}>
          <Title level={1}>{t('library')}</Title>
        </div>

        <div className={css['MyCollection-header-search']}>
          <ContextSearch
            dataQa='collectionTopicSearch'
            navigate={navigate}
            onChange={onSearchChange}
            onSearchSubmit={onSearchSubmit}
            onSearchSuggestClick={itemId => navigate(`/collection-topics/${itemId}`)}
            initialQuerySearchParams={initialQuerySearchParams}
            searchResults={searchResults}
            searchTypes={searchTypes}
            isFiltered={isFilterNotEmpty}
          />
        </div>
      </div>
      <div className={css['CollectionMaterials-sort-block']}>
        <Sort
          activeSort={topicsParams.sort}
          isFilterEmpty={isFilterNotEmpty}
          isLoading={isLoadingTopicsCatalog}
          labelSearchText={labelSearchText}
          labelSkillsText={state.skillsNames}
          topicAmount={topicsTotal}
          handleChangeSort={handleChangeSort}
          sortTypes={sortTypes}
        />
      </div>

      <TopicCards
        isLoading={isLoadingTopicsCatalog}
        topics={topics}
        isFilterNotEmpty={isFilterNotEmpty}
        searchText={currentSearchText}
        isLibrary
      />

      {!isLoadingTopicsCatalog && topics?.length > 0 && (
        <Pagination
          current={topicsParams.page}
          total={topicsTotal}
          pageSize={initialTopicsParams.size}
          onChange={changePage}
          showSizeChanger={false}
          className={css['CollectionMaterials-pagination']}
          responsive
          hideOnSinglePage
        />
      )}
    </Content>
  );
};

Library.propTypes = {
  isLoadingTopicsCatalog: PropTypes.bool,
  topics: PropTypes.array,
  topicsTotal: PropTypes.number,
};

export default Library;
