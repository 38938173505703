/* eslint-disable */
import {
  GET_INCOME_FAIL,
  GET_INCOME_START,
  GET_INCOME_SUCCESS,
  GET_USER_EXTERNAL_INCOME_FAIL,
  GET_USER_EXTERNAL_INCOME_START,
  GET_USER_EXTERNAL_INCOME_SUCCESS,
  GET_USER_INCOME_FAIL,
  GET_USER_INCOME_START,
  GET_USER_INCOME_SUCCESS,
  RESET_SCOPE_INCOME,
  RESET_USER_INCOME,
  SET_SCOPE_INCOME,
} from '@/store/income/actions';

const initialState = {
  isLoading: false,
  error: false,
  lastIncome: null,
  incomeType: '',
  incomeContentLabel: null,
  redirectTo: '',
  userIncome: [],
  userIncomeTotal: 0,
  userIncomePageSize: 0,
  userIncomePageNumber: 0,
  externalIncome: null,
  scope: '',
};

export default (state = initialState, { type, response, action, headers }) => {
  switch (type) {
    case GET_INCOME_START:
      return {
        ...state,
        isLoading: true,
        error: false,
        lastIncome: null,
        incomeType: '',
      };

    case GET_INCOME_SUCCESS:
      return {
        ...state,
        redirectTo: action.redirectTo,
        isLoading: false,
        lastIncome: response && response[0],
        incomeType: action.incomeType,
      };

    case GET_INCOME_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case GET_USER_EXTERNAL_INCOME_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_USER_EXTERNAL_INCOME_SUCCESS:
      return {
        ...state,
        externalIncome: response,
        isLoading: false,
        error: false,
      };
    case GET_USER_EXTERNAL_INCOME_FAIL:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case GET_USER_INCOME_START:
      return {
        ...state,
        userIncome: [],
        isLoading: true,
        error: false,
        lastIncome: null,
        incomeType: '',
      };

    case GET_USER_INCOME_SUCCESS:
      return {
        ...state,
        userIncome: response,
        lastIncome: response && response[0],
        incomeType: action.incomeType,
        incomeContentLabel: action.incomeContentLabel,
        redirectTo: action.redirectTo || '',
        userIncomeTotal: Number(headers.get('X-Total-Elements')) || 0,
        userIncomePageSize: Number(headers.get('X-Page-Size')) || 0,
        userIncomePageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };
    case RESET_USER_INCOME:
      return {
        ...state,
        incomeType: '',
        redirectTo: '',
      };
    case GET_USER_INCOME_FAIL:
      return {
        ...state,
        error: true,
        isLoading: false,
      };

    case SET_SCOPE_INCOME:
      return {
        ...state,
        scope: action.scope,
      };

    case RESET_SCOPE_INCOME:
      return {
        ...state,
        scope: '',
      };

    default:
      return state;
  }
};
