// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-p7gAWm.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-wArKVk .user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-label-iZ3fLr[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-p7gAWm.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-wArKVk .user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-item-WbUBlA[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-Montserrat-T7wcyP {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFrame-OGtw07 {
  border: 1px solid #c3d4dc;
  width: 100%;
  min-height: 400px;
  max-height: 100%;
  margin-top: 20px;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFrame_FullscreenMobile-njAxA8 {
  z-index: 98;
  border: none;
  width: 100%;
  height: 100%;
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaWrapper-qIxOs_ {
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaButtons-E7NuQ4 {
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 40px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaButtons-E7NuQ4 button {
  min-width: 160px;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFullscreenButton-E4Q_vf {
  z-index: 99;
}

.user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFullscreenButton_active-niBCW8 {
  position: fixed;
  top: 20px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/tilda/index.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAOA;EAJE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;AACT;;AAOA;EAJE,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;EACb,aAAa;AACf;;AAOA;EAJE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAMA;EAHE,gBAAgB;AAClB;;AAMA;EAHE,WAAW;AACb;;AAKA;EAFE,eAAe;EACf,SAAS;AACX","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable selector-pseudo-class-no-unknown */\n.tildaFrame {\n  width: 100%;\n  min-height: 400px;\n  max-height: 100%;\n  border: 1px solid #c3d4dc;\n  margin-top: 20px;\n}\n.tildaFrame_FullscreenMobile {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 98;\n  margin-top: 0;\n  border: none;\n}\n\n.tildaWrapper {\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.tildaButtons {\n  display: flex;\n  margin-top: 48px;\n  justify-content: center;\n  margin-bottom: 40px;\n}\n.tildaButtons button {\n  min-width: 160px;\n}\n\n.tildaFullscreenButton {\n  z-index: 99;\n}\n.tildaFullscreenButton_active {\n  position: fixed;\n  top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-p7gAWm`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-p7gAWm`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-wArKVk`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-wArKVk`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-label-iZ3fLr`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-label-iZ3fLr`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-item-WbUBlA`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-picker-item-WbUBlA`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-Montserrat-T7wcyP`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__ql-font-Montserrat-T7wcyP`,
	"tildaFrame": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFrame-OGtw07`,
	"tildaFrame_FullscreenMobile": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFrame_FullscreenMobile-njAxA8`,
	"tildaFrameFullscreenMobile": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFrame_FullscreenMobile-njAxA8`,
	"tildaWrapper": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaWrapper-qIxOs_`,
	"tildaButtons": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaButtons-E7NuQ4`,
	"tildaFullscreenButton": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFullscreenButton-E4Q_vf`,
	"tildaFullscreenButton_active": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFullscreenButton_active-niBCW8`,
	"tildaFullscreenButtonActive": `user-src-pages-CollectionMaterialsIDStudy-tilda-index-module__tildaFullscreenButton_active-niBCW8`
};
export default ___CSS_LOADER_EXPORT___;
