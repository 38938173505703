export const GET_MENTEE_START = 'GET_MENTEE_START';
export const GET_MENTEE_SUCCESS = 'GET_MENTEE_SUCCESS';
export const GET_MENTEE_FAIL = 'GET_MENTEE_FAIL';

export const GET_MENTEE_AVAILABLE_STATUSES_START = 'GET_MENTEE_AVAILABLE_STATUSES_START';
export const GET_MENTEE_AVAILABLE_STATUSES_SUCCESS = 'GET_MENTEE_AVAILABLE_STATUSES_SUCCESS';
export const GET_MENTEE_AVAILABLE_STATUSES_FAIL = 'GET_MENTEE_AVAILABLE_STATUSES_FAIL';

export const REJECT_MENTEE_START = 'PUT_REJECT_MENTEE_START';
export const REJECT_MENTEE_SUCCESS = 'PUT_REJECT_MENTEE_SUCCESS';
export const REJECT_MENTEE_FAIL = 'PUT_REJECT_MENTEE_FAIL';

export const FINISH_MENTEE_START = 'FINISH_MENTEE_START';
export const FINISH_MENTEE_SUCCESS = 'FINISH_MENTEE_SUCCESS';
export const FINISH_MENTEE_FAIL = 'FINISH_MENTEE_FAIL';

export const REVIEW_MENTEE_START = 'REVIEW_MENTEE_START';
export const REVIEW_MENTEE_SUCCESS = 'REVIEW_MENTEE_SUCCESS';
export const REVIEW_MENTEE_FAIL = 'REVIEW_MENTEE_FAIL';
