import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { setSearchParams } from '@/store/search/actions';
import { getTagName } from '@/store/tags/actions';

import Search from './Search';

import getQueryString from '@shared/utils/get-query-string';

class ContextSearch extends PureComponent {
  constructor(props) {
    super(props);
    const { itemId, itemTag, type, searchResult } = props?.initialQuerySearchParams || {};

    this.state = {
      location: document.location.search,
      type: (type && props.searchTypes.find(item => item.type === type)) || props.searchTypes[0],
      value: props?.queryParams ? props?.queryParams[type] : '',
      itemTag: itemTag || '',
      itemId: itemId || undefined,
      showClearButton: false,
      hasSearched: false,
    };
  }

  getCurrentSearchType = () => this.props.searchTypes.find(el => el.type === this.state?.type?.type).searchType;

  updateTagName = tagId => {
    this.props.getTagName(tagId, ({ name }) => {
      this.onChange(name);
    });
  };

  updateSearchQuery = () => {
    const { value, itemId, itemTag, type } = this.state;

    this.props.navigate({
      search: getQueryString({
        [type ? type.type : 'search']: value,
        [itemTag]: itemTag === 'publisher' ? value : itemId,
        type: type && type.type,
      }),
    });
  };

  onChange = value => {
    this.setState({ value }, () => {
      this.props.onChange(value, this.getCurrentSearchType());
    });
  };

  onTypeChange = type => {
    const findSearcType = this.props.searchTypes.find(el => el.type === type);
    if (!findSearcType) return;
    this.setState(
      {
        type: { ...findSearcType },
      },
      () => {
        if (this.state.value) this.props.onChange(this.state.value, this.getCurrentSearchType());
      }
    );
  };

  onSearchSuggestClick = (suggestItem, suggestType) => {
    const { searchTypes } = this.props;
    const itemType =
      searchTypes.find(item => item.name.toLowerCase().includes(suggestItem.type.toLowerCase())) || suggestType;

    const itemTag = itemType?.tagType || itemType?.type || '';

    this.setState(
      {
        itemTag,
        itemId: suggestItem.id,
        value: suggestItem.name,
      },
      this.updateSearchQuery
    );

    this.props.onSearchSuggestClick(suggestItem.id, suggestItem.name, itemTag);
  };

  onSubmit = value => {
    const { type } = this.state;

    if (!value && !this.state.hasSearched) {
      return;
    }

    this.props.onSearchSubmit({
      type: type?.type,
      value,
    });

    if (!value) {
      this.setState(
        {
          value: '',
          type: this.props.searchTypes[0],
          itemId: undefined,
          itemTag: '',
          hasSearched: false,
        },
        this.updateSearchQuery
      );
    } else {
      this.updateSearchQuery();
      this.setState({ hasSearched: true });
    }
  };

  isDirty = () => {
    return this.props.isFiltered;
  };

  componentDidMount() {
    if (this.state.itemTag === 'TAG' && this.state.itemId && !this.state.value) {
      this.updateTagName(this.state.itemId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.searchTypes?.[0].name !== this.props?.searchTypes?.[0].name) {
      this.setState({ type: this.props.searchTypes[0] });
    }

    if (document.location.search) {
      this.setState({ location: document.location.search });
    }

    if (
      prevProps.queryParams !== this.props.queryParams ||
      (document.location.search !== this.state.location && !document.location.search)
    ) {
      const { queryParams } = this.props;
      const { type } = this.state;
      const newValue = queryParams?.[type?.type] || '';
      this.setState({ value: newValue, location: document.location.search, type: this.props.searchTypes[0] });
    }
  }

  render() {
    const { searchResults, searchTypes, dataQa, isNews, searchType } = this.props;

    const { type, value, itemId, hasSearched } = this.state;

    const data =
      itemId && searchResults
        ? searchResults.filter(values => values?.name?.toLocaleLowerCase().includes(value?.toLocaleLowerCase()))
        : searchResults;

    return (
      <Search
        data-qa={dataQa}
        results={data}
        onChange={this.onChange}
        onSearchSuggestClick={this.onSearchSuggestClick}
        onSearchTypeChange={this.onTypeChange}
        onSubmit={this.onSubmit}
        types={searchTypes}
        searchText={value}
        searchType={type}
        showClearButton={this.isDirty()}
        isFavorite={this.props.isFavorite}
        isNews={isNews}
        hasSearched={hasSearched}
      />
    );
  }
}

const mapActionsToProps = {
  getTagName,
  setSearchParams,
};

export default connect(null, mapActionsToProps)(ContextSearch);
