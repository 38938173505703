import {
  GET_INFO_EXISTING_MATERIAL_FAIL,
  GET_INFO_EXISTING_MATERIAL_START,
  GET_INFO_EXISTING_MATERIAL_SUCCESS,
  PUT_FINISH_EXISTING_MATERIAL_FAIL,
  PUT_FINISH_EXISTING_MATERIAL_START,
  PUT_FINISH_EXISTING_MATERIAL_SUCCESS,
  PUT_START_EXISTING_MATERIAL_FAIL,
  PUT_START_EXISTING_MATERIAL_START,
  PUT_START_EXISTING_MATERIAL_SUCCESS,
} from './constance';

import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getInfoTopic = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/${encodeURI(topicId)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_INFO_EXISTING_MATERIAL_START, GET_INFO_EXISTING_MATERIAL_SUCCESS, GET_INFO_EXISTING_MATERIAL_FAIL],
  onSuccess,
  onError,
});

export const startExistingMaterial = (blockId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/existingMaterial/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [PUT_START_EXISTING_MATERIAL_START, PUT_START_EXISTING_MATERIAL_SUCCESS, PUT_START_EXISTING_MATERIAL_FAIL],
  onSuccess,
  onError,
});

export const finishExistingMaterial = (blockId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/existingMaterial/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [PUT_FINISH_EXISTING_MATERIAL_START, PUT_FINISH_EXISTING_MATERIAL_SUCCESS, PUT_FINISH_EXISTING_MATERIAL_FAIL],
  onSuccess,
  onError,
});
