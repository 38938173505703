import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectNotifications } from '@/store/notifications-new/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Badge, Typography } from 'antd';
import { HiDotsHorizontal, HiOutlineBookOpen, HiOutlineCollection, HiOutlineStatusOffline } from 'react-icons/hi';

import { domainCompanyid } from '@/utils/domainCompanyId';
import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';

import css from './MobileNav.module.scss';

const MobileNav = ({ pathname, openBurgerMenu, setOpenBurgerMenu }) => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const { t } = useTranslation('header');
  const { unReadNotifications } = useSelector(selectNotifications);
  const { currentUser } = useSelector(selectUsers);

  const [isMobile] = useIsMobile(540);

  const onRedirect = path => {
    navigate(path);
    if (openBurgerMenu) setOpenBurgerMenu(false);
  };

  return (
    <div id='mobile-nav' className={css['MobileNav']}>
      {currentUser?.domainCompany?.id !== domainCompanyid.BIDZAAR_ID && (
        <div
          onClick={() => onRedirect('/collection-topics')}
          className={classNames(
            css['MobileNav__item'],
            pathname.includes('collection-topics') && css['MobileNav__item_active']
          )}
        >
          <HiOutlineCollection size={isMobile ? 22 : 28} />
          <Text className={css['MobileNav__item__text']} strong>
            {t('collectionTopics')}
          </Text>
        </div>
      )}
      <div
        onClick={() => onRedirect('/planning')}
        className={classNames(css['MobileNav__item'], pathname.includes('planning') && css['MobileNav__item_active'])}
      >
        <HiOutlineBookOpen size={isMobile ? 22 : 28} />
        <Text className={css['MobileNav__item__text']} strong>
          {t('planning')}
        </Text>
      </div>
      <div
        onClick={() => onRedirect('/offline-materials')}
        className={classNames(
          css['MobileNav__item'],
          pathname.includes('offline-materials') && css['MobileNav__item_active']
        )}
      >
        <HiOutlineStatusOffline size={isMobile ? 22 : 28} />
        <Text className={css['MobileNav__item__text']} strong>
          {t('offlineMaterials')}
        </Text>
      </div>
      <div
        className={
          openBurgerMenu ? classNames(css['MobileNav__item'], css['MobileNav__item_active']) : css['MobileNav__item']
        }
        onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
      >
        <Badge
          className={css['MobileNav__item--bell']}
          count={unReadNotifications?.length ? unReadNotifications.length : 0}
          overflowCount={99}
          offset={[5, -7]}
        >
          <HiDotsHorizontal size={isMobile ? 22 : 28} />
          <Text className={css['MobileNav__item__text']} strong>
            {t('more')}
          </Text>
        </Badge>
      </div>
    </div>
  );
};

export default MobileNav;
