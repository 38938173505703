/* eslint-disable */
// @ts-nocheck
import aviImage from '@/assets/images/avi.svg';
import docImage from '@/assets/images/doc.svg';
import docxImage from '@/assets/images/docx.svg';
import epbImage from '@/assets/images/epub.svg';
import excel from '@/assets/images/excel.svg';
import fb2Image from '@/assets/images/fb2.svg';
import gifImage from '@/assets/images/gif.svg';
import jpgImage from '@/assets/images/jpg.svg';
import mobImage from '@/assets/images/mobi.svg';
import movImage from '@/assets/images/mov.svg';
import mp4Image from '@/assets/images/mp4.svg';
import pdfImage from '@/assets/images/pdf.svg';
import pngImage from '@/assets/images/png.svg';
import pptImage from '@/assets/images/ppt.svg';
import pptxImage from '@/assets/images/pptx.svg';
import undefinedImage from '@/assets/images/undefined.svg';
import zipImage from '@/assets/images/zip.svg';

export const FILE_IMAGE = [
  {
    type: 'ZIP',
    image: zipImage,
  },
  {
    type: 'AVI',
    image: aviImage,
  },
  {
    type: 'PPT',
    image: pptImage,
  },
  {
    type: 'PPTX',
    image: pptxImage,
  },
  {
    type: 'PDF',
    image: pdfImage,
  },
  {
    type: 'DOC',
    image: docImage,
  },
  {
    type: 'DOCX',
    image: docxImage,
  },
  {
    type: 'EPUB',
    image: epbImage,
  },
  {
    type: 'MP4',
    image: mp4Image,
  },
  {
    type: 'MOBI',
    image: mobImage,
  },
  {
    type: 'MOV',
    image: movImage,
  },
  {
    type: 'JPG',
    image: jpgImage,
  },
  {
    type: 'PNG',
    image: pngImage,
  },
  {
    type: 'GIF',
    image: gifImage,
  },
  {
    type: 'FB2',
    image: fb2Image,
  },
  {
    type: 'UNKNOWN',
    image: undefinedImage,
  },
  {
    type: 'UNDEFINED',
    image: undefinedImage,
  },
  {
    type: 'XLS',
    image: excel,
  },
  {
    type: 'XLSX',
    image: excel,
  },
];
