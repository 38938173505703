/* eslint-disable */
import {
  FINISHED_TOPIC,
  LOGIN,
  NEW_TOPIC,
  SUCCESS,
  SUCCESS_BTN,
  UNMODERATE,
  UNMODERATE_BTN,
  WARNING,
} from '@/constants/income-types';

export default {
  [SUCCESS]: 'Поздравляем!',
  [SUCCESS_BTN]: 'Спасибо!',
  [UNMODERATE_BTN]: 'Ясненько',
  [WARNING]: 'Внимание!',
  [UNMODERATE]: 'Коины за материал будут начислены после успешного прохождения модерации',
  [LOGIN]: 'За вход в систему тебе полагается вознаграждение.',
  [NEW_TOPIC]: 'За добавление материала на платформу тебе полагается вознаграждение. Чем больше материалов, тем лучше!',
  [FINISHED_TOPIC]: 'За прохождение материала тебе полагается вознаграждение.',
};
