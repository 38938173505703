/* eslint-disable */
/* react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearUserTopicPlan, getUserTopicPlan, setStudyPlanParams } from '@/store/study-plan/actions';
import { createProfileUserTopic, deleteProfileUserTopic } from '@/store/user-topics/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import { Pagination, Spin } from 'antd';

import { Card } from './card';
import { Filters } from './filters';
import { Modals } from './modals';

import { PlanStatus } from '@shared/constants/plan';
import studyPlanService from '@shared/services/study-plan-service';
import { classNames } from '@shared/utils/styles-utils';

import _get from 'lodash/get';

import styles from './styles.module.scss';

const cn = classNames(styles);

const PlanningFinishedPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('planingFinished');

  const currentUser = useSelector(selectCurrentUser);

  const planName = 'finishedStudyPlan';

  const {
    studyPlan,
    loading: {
      studyPlan: { [planName]: loading },
    },
    params: { [planName]: studyPlanParams },
  } = useSelector(selectStudyPlan);

  const [modal, setModal] = useState();
  const [filters, setFilters] = useState({ ...studyPlanParams, addedByAdmin: undefined });
  const [page, setPage] = useState(0);
  const [materialLoading, setMaterialLoading] = useState();
  const [filtersOptions, setFiltersOptions] = useState();

  const getStudyPlan = params => {
    const _params = {
      userId: currentUser.id,
      size: 15,
      planStatus: PlanStatus.FINISHED,
      page,
      ...filters,
      ...params,
    };

    dispatch(
      getUserTopicPlan({
        name: planName,
        fullResponse: true,
        params: _params,
      })
    );

    dispatch(setStudyPlanParams({ name: planName, value: _params }));
  };

  const pagination = _get(studyPlan[planName], 'meta', {});
  const plan = _get(studyPlan[planName], 'data');

  useEffect(() => {
    studyPlanService.getUserPlanFilter(currentUser.id).then(setFiltersOptions);

    getStudyPlan(studyPlanParams || {});

    return () => {
      dispatch(clearUserTopicPlan({ name: planName }));
    };
  }, []);

  const showList = !!(plan && plan.length);

  const onAction = ({ action, material }) => {
    switch (action) {
      case 'favorite':
        dispatch(createProfileUserTopic(currentUser.id, material.topic.id, 'anotherPlan'));
        break;
      case 'notFavorite':
        dispatch(deleteProfileUserTopic(material.topic.id, true, true, 'anotherPlan'));
        break;
      case 'review':
      case 'recommend':
        setModal({ action, material });
        break;
      default:
        break;
    }
  };

  const onCloseModal = () => setModal(null);

  const onConfirmModal = () => {
    const {
      material: { id },
      action,
    } = modal;
    onCloseModal();
    if (action === 'review') {
      setMaterialLoading(id);
    }
    getStudyPlan();
  };

  const onChangeFilters = changedFilters => {
    setFilters(changedFilters);
    setPage(0);
    getStudyPlan({ ...changedFilters, page: 0 });
  };

  const onChangePage = page => {
    setModal(null);
    const _page = page - 1;
    setPage(_page);
    window.scrollTo(0, 0);
    getStudyPlan({ page: _page });
  };

  return (
    <div className={cn('planning-finished-page')}>
      <div className={cn('planning-finished-page__header')}>
        <h1>{t('completedMaterials')}</h1>
      </div>

      <Filters
        filters={filters}
        filtersOptions={filtersOptions}
        onChange={onChangeFilters}
        hasTrajectory={currentUser.domainCompany.hasUserTrajectory}
        currentUser={currentUser}
      />

      {loading && (
        <div className={cn('planning-finished-page__spinner')}>
          <Spin
            wrapperClassName={cn({
              'planning-finished-page__spinner_full': !showList,
            })}
          />
        </div>
      )}

      {showList && !loading ? (
        <div className={cn('planning-finished-page__body')}>
          <div className={cn('planning-finished-page__list')}>
            {plan.map(material => (
              <Card
                key={material.id}
                material={material}
                loading={materialLoading === material.id}
                onAction={onAction}
                currentUser={currentUser}
              />
            ))}
          </div>

          {pagination.total > pagination.pageSize && (
            <Pagination
              className={cn('planning-finished-page__pagination')}
              current={pagination.currentPage + 1}
              total={pagination.total}
              pageSize={pagination.pageSize}
              onChange={onChangePage}
              showSizeChanger={false}
              responsive
              hideOnSinglePage
            />
          )}

          {modal && <Modals modal={modal} onConfirm={onConfirmModal} onClose={onCloseModal} open={Boolean(modal)} />}
        </div>
      ) : (
        !loading && (
          <div className={cn('planning-finished-page__body', 'planning-finished-page__body_empty')}>
            {t('completedArea')} <br />
            {t('description')}
          </div>
        )
      )}
    </div>
  );
};

export default PlanningFinishedPage;
