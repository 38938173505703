import dayjs from 'dayjs';

import { ADD_REPEAT } from '@/constants/studyPlanStatus';

const EMPLOYEE_ROLE_ID = 4;
const MANAGER_ROLE_ID = 3;
const ADMIN_ROLE_ID = 2;
const SUPERADMIN_ROLE_ID = 1;
export default class Utils {
  static trString(str, len) {
    let sliced = str.slice(0, len);
    if (sliced.length < str.length) {
      sliced += '...';
    }

    return sliced;
  }

  static timezoneCounter(time, user) {
    return dayjs(time).add(user.timezone.offset / 60 - user.domainCompany.timezone.offset / 60, 'h');
  }

  static getPlanDate(topicDuration) {
    const durationDays = Math.ceil(topicDuration / 1440);
    const nowDate = dayjs();
    const date = nowDate.add(durationDays, 'days').format('YYYY-MM-DD');

    return date;
  }

  static getNavigateUrl(topicId, blocksCount) {
    const topicUrl = `/collection-topics/${topicId}`;
    const studyUrl = topicUrl + '/study';

    return blocksCount > 0 ? studyUrl : topicUrl;
  }

  static isWithoutEvents(topic) {
    return topic && topic?.type && topic.type.withEvents === false;
  }

  static isAdmin(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === ADMIN_ROLE_ID) || currentUser.roleId === ADMIN_ROLE_ID
    );
  }

  static isSuperAdmin(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === SUPERADMIN_ROLE_ID) ||
      currentUser.roleId === SUPERADMIN_ROLE_ID
    );
  }

  static isAdminOrSuperAdmin(currentUser) {
    if (Utils.isAdmin(currentUser) || Utils.isSuperAdmin(currentUser)) {
      return true;
    }

    return false;
  }

  static isManager(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === MANAGER_ROLE_ID) ||
      currentUser.roleId === MANAGER_ROLE_ID
    );
  }

  static isEmployee(currentUser) {
    return (
      (currentUser?.parentRoleId && currentUser.parentRoleId === EMPLOYEE_ROLE_ID) ||
      currentUser.roleId === EMPLOYEE_ROLE_ID
    );
  }

  static renderOptions(arr) {
    return Array.isArray(arr) && arr.length > 0 && arr.map(i => ({ label: i.label, value: i.value }));
  }

  static checkTitleAddToPlan(topic, translator) {
    if (topic?.userPlanType === ADD_REPEAT) {
      return 'retake';
    } else {
      return translator || 'planned';
    }
  }

  static trStringFile(str, len) {
    if (str.length <= len) return str;

    const dotIndex = str.lastIndexOf('.');
    if (dotIndex === -1 || dotIndex === 0) {
      // Если нет расширения файла
      return str.slice(0, len) + '...';
    }

    const extension = str.slice(dotIndex);
    const baseLength = len - extension.length;

    const truncatedBase = str.slice(0, baseLength);
    return `${truncatedBase}...${extension}`;
  }
}
