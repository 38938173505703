/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  clearCompilations,
  clearPage,
  createCompilation,
  getCompilation,
  getCompilations,
} from '@/store/compilations/actions';
import { createFileImage, getFile } from '@/store/files/actions';
import {
  clearSearchParams,
  clearSearchText,
  getSearch,
  setFindSearchText,
  setSearchParams,
  setSearchText,
} from '@/store/search/actions';
import { getTopicsCompilation } from '@/store/topics/actions';

import { selectCompilations as compilationsSelect } from '@/store/compilations/selectors';
import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectSearch as searchSelect } from '@/store/search/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Col, Flex, FloatButton, Layout, Pagination, Row, Spin, Typography } from 'antd';
import CompilationCard from '@/components/CompilationCard';
import ContextSearch from '@/components/ContextSearch';
import Sort from '@/components/Sort';
import ThemesEditor from '@/components/ThemesEditor';
import ThemesEditorMobile from '@/components/ThemesEditorMobile';
import { AiOutlineFilter } from 'react-icons/ai';

import CompilationsEmptyPlaceholder from './CompilationsEmptyPlaceholder';
import DialogFilterSelectCompilation from './DialogFilterSelectCompilation';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './Compilations.module.scss';

import { COMPILATIONS_TYPE } from '@/constants/topic-types';

const { Content } = Layout;
const { Title } = Typography;

class Compilations extends Component {
  static propTypes = {
    clearCompilations: PropTypes.func,
    clearSearchParams: PropTypes.func,
    compilations: PropTypes.array,
    compilationsPage: PropTypes.number,
    compilationsPageNumber: PropTypes.number,
    compilationsPageSize: PropTypes.number,
    compilationsTotal: PropTypes.number,
    getCompilation: PropTypes.func,
    getCompilations: PropTypes.func,
    clearPage: PropTypes.func,
    getTopicsCompilation: PropTypes.func,
    getSearch: PropTypes.func,
    navigate: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    loadMore: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    searchFindSearchText: PropTypes.object,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    topics: PropTypes.array,
    topicsTotal: PropTypes.number,
  };

  constructor(props) {
    super(props);

    const initialSort = props.isFavorite ? 'createdDate,desc' : 'rate,desc';

    this.state = {
      isVisible: true,
      innerWidth: window.innerWidth,
      editable: null,
      chosenCompilation: null,
      radioButtonValue: 0,
      openDialogFilterSelectCompetence: false,
      activeSort: (props.searchParams && props.searchParams.sort) || initialSort,
      favParams: {
        ...(props.isFavorite
          ? {
              userId: props.currentUserId,
            }
          : {}),
      },
      themeId: (props.searchParams && props.searchParams.themeId) || '',
      searchVal: '',
      location: location.search,
    };
    // this.historyListnerUnmount = props.navigate.listen(this.routerListener);

    props.setSearchParams({
      sort: this.state.activeSort,
      ...this.props.searchParams,
    });

    if (document.location?.fromHeader) {
      props.clearCompilations();
      this.onSearchClear(true);
      setTimeout(() => {
        this.loadCompilations();
      }, 0);
    } else {
      this.loadCompilations();
    }

    this.getSearchDebounced = debounce(props.getSearch, 1000);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ innerWidth: window.innerWidth });
  }

  setChosenCompilation = arg => {
    this.setState({ chosenCompilation: arg });
  };

  nullifyRadioButtonValue = () => {
    this.setState({
      radioButtonValue: 0,
    });
  };

  setRadioButtonValue = arg => {
    this.setState({
      radioButtonValue: arg,
    });
  };

  closeModalSelectCompetence = () => {
    this.setState({ openDialogFilterSelectCompetence: false });
  };

  openModalSelectCompetence = () => {
    this.setState({ openDialogFilterSelectCompetence: true });
  };

  componentWillUnmount() {
    // this.historyListnerUnmount();
    this.props.clearPage();
    this.onSearchClear(true);
    this.props.clearCompilations();
  }

  handleChangeSearch = searchVal => this.setState({ searchVal });

  componentDidUpdate(prevProps, prevState) {
    if (!!location.search && location.search !== prevState.location) {
      this.setState({ location: location.search });
    }

    if (location.search !== prevState.location && !location.search) {
      this.props.setSearchParams({ ...this.props.searchParams, searchResult: undefined });
      this.setState({ location: '' });
    }

    if (prevProps.searchParams && this.props.searchParams !== prevProps.searchParams) {
      this.loadCompilations();
    }
  }

  routerListener = location => {
    if (!location.pathname.includes('compilations')) {
      this.props.clearSearchParams();
    }
  };

  loadCompilations = debounce(() => {
    this.props.getCompilations({
      page: this.props.compilationsPageNumber,
      size: 10,
      ...this.state.favParams,
      ...this.props.searchParams,
      sort: this.state.activeSort,
    });
  }, 400);

  toggleModal = params => {
    if (params && params.editable && !this.state.isVisible) {
      this.props.getCompilation(params.editable, res => {
        const { topics, themes, shortDescription, name, fullDescription, cover } = res;
        this.setState({
          editable: {
            id: params.editable,
            topics,
            themes,
            shortDescription,
            name,
            fullDescription,
            cover,
          },
          isVisible: true,
        });
      });
    } else {
      this.setState({
        isVisible: !this.state.isVisible,
        editable: !this.state.isVisible && !params.editable && null,
      });
    }
  };

  handlePageChange = page => {
    window.scrollTo(0, 0);
    this.props.getCompilations({
      size: this.props.compilationsPageSize,
      ...this.state.favParams,
      ...this.props.searchParams,
      page: page - 1,
    });
  };

  handleChangeSort = sort => {
    this.props.setSearchParams({ ...this.props.searchParams, sort });
    this.setState({ changePageLoad: true, activeSort: sort }, this.loadCompilations);
  };

  clearThemes = () => {
    this.props.setSearchParams({ ...this.props.searchParams, themeId: [] });
  };

  onSearchChange = debounce((value, type) => {
    this.props.setSearchText(value);
    this.getSearchDebounced(value.trim(), type, 'compilation', this.props.isFavorite);
  }, 400);

  onSearchSubmit = ({ value, type }) => {
    const cleanSearchParams = this.onSearchClear(true);
    const searchParams = {
      ...cleanSearchParams,
    };

    switch (type) {
      case 'EXPERT':
        searchParams.manager = value;
        break;
      case 'SKILL':
        searchParams.skillName = value;
        break;
      case 'TAG':
        searchParams.tagName = value;
        break;
      case 'COMPILATION':
        searchParams.name = value;
        break;
      default:
        this.props.setSearchText(value);
        searchParams.searchResult = value;
        break;
    }

    this.props.setSearchParams(searchParams);
  };

  onSearchSuggestClick = (itemId, value, type) => {
    const cleanSearchParams = this.onSearchClear(true);

    const searchParams = {
      ...cleanSearchParams,
      searchResult: value,
    };

    switch (type) {
      case 'EXPERT':
        searchParams.creById = itemId;
        break;
      case 'TAG':
        searchParams.tagId = itemId;
        break;
      case 'SKILL':
        searchParams.skillId = itemId;
        break;
      default:
        this.props.navigate(`/compilations/${itemId}`);
        break;
    }

    this.props.setSearchParams(searchParams);
    this.props.getCompilations({
      page: 0,
      ...this.state.favParams,
      ...searchParams,
    });
  };

  onSearchClear = isSetOnly => {
    const searchParams = {
      ...this.props.searchParams,
      creById: null,
      creBy: null,
      skillId: null,
      skillName: null,
      name: null,
      searchResult: null,
      manager: null,
    };
    this.props.setSearchParams(searchParams);
    this.props.clearSearchText();
    if (isSetOnly !== true) {
      this.props.getCompilations({
        page: 0,
        ...this.state.favParams,
        ...searchParams,
      });
    }

    return searchParams;
  };

  getFilterLabel = () => {
    if (this.props.searchParams) {
      if (this.props.searchParams.creBy || this.props.searchParams.creById) {
        return this.props.t('expert');
      }
      if (this.props.searchParams.name) {
        return this.props.t('name');
      }
      if (this.props.searchParams.skillName || this.props.searchParams.skillId) {
        return this.props.t('skill');
      }

      return '';
    }
    return '';
  };

  render() {
    const {
      compilations,
      navigate,
      isLoading,
      isLoadingFile,
      isLoadingFileImage,
      compilationsPageNumber,
      compilationsPageSize,
      compilationsTotal,
      isFavorite,
      searchParams,
      t,
    } = this.props;

    const isFilterNotEmpty =
      searchParams &&
      (searchParams.creBy ||
        searchParams.creById ||
        searchParams.skillId ||
        searchParams.skillName ||
        searchParams.searchResult ||
        searchParams.name);

    const searchText =
      (searchParams &&
        (searchParams.creBy || searchParams.skillName || searchParams.searchResult || searchParams.name)) ||
      '';

    const labelFilter = this.getFilterLabel();

    const labelSearchText = searchText.length > 20 ? `${searchText.substring(0, 20)}... ` : searchText;

    const pagination = (
      <div className={css['Compilation-pagination']}>
        <Pagination
          current={compilationsPageNumber + 1}
          total={compilationsTotal}
          pageSize={compilationsPageSize}
          onChange={this.handlePageChange}
          showSizeChanger={false}
          responsive
          hideOnSinglePage
        />
      </div>
    );

    const compilationCard = item => (
      <CompilationCard
        dataqa='userCompilationCard'
        searchParams={this.props.searchParams}
        setSearchParams={this.props.setSearchParams}
        onEdit={() => this.toggleModal({ editable: item.id })}
        compilation={item}
        key={item.id}
        navigate={navigate}
        isFavorite={this.props.isFavorite}
      />
    );

    const compilationsList = compilations?.map(item => compilationCard(item));

    const compilationsListMobile = compilations?.map(item => (
      <div key={item.id} className={css['Compilation-col']}>
        {compilationCard(item)}
      </div>
    ));

    const onClearFilters = () => {
      this.clearThemes();
      this.nullifyRadioButtonValue();
    };

    const searchTypes = [
      {
        id: COMPILATIONS_TYPE(t).length + 1,
        type: 'searchResult',
        name: t('all'),
      },
      ...COMPILATIONS_TYPE(t).map((item, index) => ({
        id: index + 1,
        type: item.type,
        name: item.name,
      })),
    ];

    const sortTypes = [
      { name: t('rate'), value: 'rate,desc' },
      { name: t('date'), value: 'createdDate,desc' },
    ];

    const isDesktopCondition = this.state.innerWidth >= 1025;
    const isMobileCondition = this.state.innerWidth >= 320 && this.state.innerWidth <= 767;

    return (
      <Content
        className={classNames(css['App-content'], {
          [css.Favorite]: this.props.isFavorite,
        })}
      >
        {isDesktopCondition && (
          <div className={css.Compilation__slider}>
            <Layout className={css['Compilation-themes-layout']}>
              <Content className={css.Compilation__themes}>
                {!isFavorite && (
                  <Title
                    level={1}
                    className={classNames(css['Compilation-header-row'], {
                      [css.mini]: isFavorite,
                    })}
                  >
                    {isFavorite ? t('favoriteCompilations') : t('compilations')}
                  </Title>
                )}
                <ThemesEditor
                  dataqa='compilationsTheme'
                  isFavorite={isFavorite}
                  onlyView
                  clearSearchParams={this.props.clearSearchParams}
                  setSearchParams={this.props.setSearchParams}
                  searchParams={this.props.searchParams}
                />
              </Content>
            </Layout>
          </div>
        )}
        <Layout
          className={classNames(css['Compilation-layout'], {
            [css['Compilation-favorite']]: isFavorite,
          })}
        >
          <Content className={css.Compilation}>
            <div className={css['Compilation-header-row']}>
              <ContextSearch
                dataqa='compilationsSearch'
                navigate={this.props.navigate}
                onChange={this.onSearchChange}
                onClear={this.onSearchClear}
                onSearchSubmit={this.onSearchSubmit}
                onSearchSuggestClick={this.onSearchSuggestClick}
                searchResults={this.props.searchResults}
                searchTypes={searchTypes}
                isFiltered={isFilterNotEmpty}
                isFavorite={this.props.isFavorite}
                withTimeout
              />
            </div>
            {!isDesktopCondition && (
              <>
                <Row className={css['TopicFilter-row']}>
                  <Col className={css['TopicFilter-col']} span={24}>
                    <Row className={css['TopicFilter__row-clear']}>
                      {!isMobileCondition && (
                        <Col className={css['TopicFilter-label']}>
                          <div className={css['TopicFilter-filter-competences-note']}>{t('refineSearchCriteria')}</div>

                          <div className={css['TopicFilter-label-clear2']} onClick={onClearFilters}>
                            {t('clearFilter')}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col span={24} className={css['TopicFilter-filter']}>
                        <>
                          <div
                            className={css['TopicFilter-filter-competencyFilterButton']}
                            onClick={this.openModalSelectCompetence}
                          >
                            {this.props?.searchParams?.themeId?.length ? (
                              <span className={css['TopicFilter-filter-competencyFilterButton-dot']} />
                            ) : (
                              <></>
                            )}
                            <AiOutlineFilter />
                            {isMobileCondition ? t('filterByThemes') : t('filterByThemesCompilations')}
                          </div>
                          <div
                            className={css['TopicFilter-filter-competencyFilterButton-tablet']}
                            onClick={this.openModalSelectCompetence}
                          >
                            {this.props?.searchParams?.themeId?.length ? (
                              <span className={css['TopicFilter-filter-competencyFilterButton-dot']} />
                            ) : (
                              <></>
                            )}
                            <AiOutlineFilter />
                            {t('filterByThemes')}
                          </div>
                          <div className={css['TopicFilter-filter-competences-note-chosen']}>
                            {this.props?.searchParams?.themeId?.length
                              ? `Выбрано: ${compilations?.length} подборок`
                              : `Выбраны все подборки`}
                          </div>
                          <Row
                            className={css['TopicFilter-row-container-desktop']}
                            type='flex'
                            justify='space-between'
                            align='middle'
                            style={{ flex: 1 }}
                          >
                            <Col flex={1}>
                              <div className={css['TopicFilter-filter-label']}>{t('description')}</div>
                            </Col>
                            {Boolean(this.props.openModalSelectCompetence) && (
                              <Col style={{ marginRight: 8 }}>
                                <Button onClick={this.props.openModalSelectCompetence} id='choiceCompetence'>
                                  {t('choiceCompetence')}
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <div className={css['TopicFilter-sortBy']}>{t('sortBy')}</div> */}
              </>
            )}

            <Sort
              activeSort={this.state.activeSort}
              isFilterEmpty={isFilterNotEmpty}
              isLoading={this.props.isLoading}
              labelFilter={labelFilter}
              labelSearchText={labelSearchText}
              topicAmount={compilations.length}
              handleChangeSort={this.handleChangeSort}
              changePageLoad={this.state.changePageLoad}
              sortTypes={sortTypes}
              dataqa='compilationSort'
            />
            <div>
              {isLoading ? (
                <Spin className={css['TopicFilter-spin']} spinning={isLoading} size='large' />
              ) : (
                <>
                  {compilations.length > 0 ? (
                    <div>
                      {isDesktopCondition ? (
                        <>{compilationsList}</>
                      ) : (
                        <Flex
                          className={css['TopicFilter-compilationsList-mobile']}
                          align='center'
                          justify='center'
                          wrap='wrap'
                        >
                          {compilationsListMobile}
                        </Flex>
                      )}

                      {pagination}
                    </div>
                  ) : !isLoading && !isLoadingFileImage && !isLoadingFile && compilations.length === 0 ? (
                    this.props.searchFindSearchText === null && !this.props.searchParams.themeId ? (
                      <CompilationsEmptyPlaceholder isFavorite={isFavorite} isSearching={Boolean(isFilterNotEmpty)} />
                    ) : (
                      <div className={css.notFound}>
                        {t('expertsCompilation')}
                        <br /> {t('read')}
                        <a onClick={this.clearThemes}>{t('themes')}</a>
                      </div>
                    )
                  ) : null}
                </>
              )}
            </div>

            <DialogFilterSelectCompilation
              stuff={
                <ThemesEditorMobile
                  className={css.CompilationEditor}
                  isFavorite={isFavorite}
                  setChosenCompilation={this.setChosenCompilation}
                  radioButtonValue={this.state.radioButtonValue}
                  setRadioButtonValue={this.setRadioButtonValue}
                  onlyView
                  clearSearchParams={this.props.clearSearchParams}
                  setSearchParams={this.props.setSearchParams}
                  searchParams={this.props.searchParams}
                  searchVal={this.state.searchVal}
                  handleChangeSearch={this.handleChangeSearch}
                />
              }
              setSearchParams={this.props.setSearchParams}
              searchParams={this.props.searchParams}
              nullifyRadioButtonValue={this.nullifyRadioButtonValue}
              chosenCompilation={this.state.chosenCompilation}
              open={this.state.openDialogFilterSelectCompetence}
              setRadioButtonValue={this.setRadioButtonValue}
              onCancel={this.closeModalSelectCompetence}
            />
          </Content>
        </Layout>
        <FloatButton.BackTop />
      </Content>
    );
  }
}

const mapStateToProps = createSelector(
  topicsSelect,
  filesSelect,
  compilationsSelect,
  searchSelect,
  usersSelect,
  (topics, files, compilations, search, users) => ({
    topics: topics.topicsCompilation,
    isLoading: compilations.isLoadingCompilations,
    pageSize: topics.topicsCompilationPageSize,
    topicsTotal: topics.topicsCompilationTotal,
    isLoadingFileImage: files.isLoadingImage,
    isLoadingFile: files.isLoading,
    compilations: compilations.compilations,
    compilationsTotal: compilations.compilationsTotal || 0,
    compilationsPageSize: compilations.compilationsPageSize || 0,
    compilationsPageNumber: compilations.compilationsPageNumber || 0,
    searchFindSearchText: search.searchFindSearchText,
    loadMore: compilations.compilations.hasMore,
    compilationsPage: compilations.compilations.page,
    pageNumber: topics.topicsCompilationPageNumber || 0,
    searchResults: search.search,
    searchParams: search.searchParams,
    searchText: search.searchText,
    searchUserText: search.searchUserText,
    currentUserId: users.currentUser && users.currentUser.id,
  })
);

const mapActionsToProps = {
  createFileImage,
  createCompilation,
  setSearchParams,
  clearSearchParams,
  getCompilation,
  getFile,
  getCompilations,
  clearPage,
  getTopicsCompilation,
  setFindSearchText,
  getSearch,
  clearSearchText,
  setSearchText,
  clearCompilations,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['compilations', 'constants'])(Compilations));
