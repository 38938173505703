/* eslint-disable */

/* шаблоны сертификатов  */
import { GET_CERTIFICATE_FAIL, GET_CERTIFICATE_START, GET_CERTIFICATE_SUCCESS } from './constants';

const initialState = {
  certificate: null,
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_CERTIFICATE_START:
      return { ...state, isLoading: true };

    case GET_CERTIFICATE_SUCCESS:
      return { ...state, certificate: response, isLoading: false, error: false };

    case GET_CERTIFICATE_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};
