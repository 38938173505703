/* eslint-disable */
import {
  ACCEPT_REQUEST_STATUS_FAIL,
  ACCEPT_REQUEST_STATUS_START,
  ACCEPT_REQUEST_STATUS_SUCCESS,
  CREATE_MENTOR_FAIL,
  CREATE_MENTOR_START,
  CREATE_MENTOR_SUCCESS,
  FINISH_REQUEST_STATUS_FAIL,
  FINISH_REQUEST_STATUS_START,
  FINISH_REQUEST_STATUS_SUCCESS,
  GET_CAN_BE_MENTOR_FAIL,
  GET_CAN_BE_MENTOR_START,
  GET_CAN_BE_MENTOR_SUCCESS,
  GET_CURRENT_MENTOR_FAIL,
  GET_CURRENT_MENTOR_START,
  GET_CURRENT_MENTOR_SUCCESS,
  GET_MENTEES_REQUESTS_FAIL,
  // UPDATE_MENTOR_PHOTO_START,
  // UPDATE_MENTOR_PHOTO_SUCCESS,
  // UPDATE_MENTOR_PHOTO_FAIL,
  // SET_MENTOR_STATUS_START,
  // SET_MENTOR_STATUS_SUCCESS,
  // SET_MENTOR_STATUS_FAIL,
  // SET_MENTOR_STATUS_BULK_START,
  // SET_MENTOR_STATUS_BULK_SUCCESS,
  // SET_MENTOR_STATUS_BULK_FAIL,
  GET_MENTEES_REQUESTS_START,
  GET_MENTEES_REQUESTS_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_START,
  GET_MENTORS_SUCCESS,
  REJECT_REQUEST_STATUS_FAIL,
  REJECT_REQUEST_STATUS_START,
  REJECT_REQUEST_STATUS_SUCCESS,
  REVIEW_REQUEST_STATUS_FAIL,
  REVIEW_REQUEST_STATUS_START,
  REVIEW_REQUEST_STATUS_SUCCESS,
} from './constants';

// Существующие статусы PENDING, ACTIVE, REJECTED, UNACTIVE

const initialState = {
  currentMentor: [],
  status: '',
  canBeMentor: false,
  activeMenteesCount: 0,
  isLoading: false,
  isError: false,
  menteesRequests: [],
  sizePage: null,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_MENTORS_START:
    case CREATE_MENTOR_START:
    case GET_CURRENT_MENTOR_START:
    case GET_CAN_BE_MENTOR_START:
    case GET_MENTEES_REQUESTS_START:
    case ACCEPT_REQUEST_STATUS_START:
    case FINISH_REQUEST_STATUS_START:
    case REVIEW_REQUEST_STATUS_START:
    case REJECT_REQUEST_STATUS_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_MENTORS_SUCCESS:
      return {
        ...state,
        currentMentor: response,
        isLoading: false,
        isError: false,
      };

    case GET_MENTEES_REQUESTS_SUCCESS:
      return {
        ...state,
        menteesRequests: response,
        isLoading: false,
        isError: false,
      };

    case ACCEPT_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        menteesRequests: state.menteesRequests.map(item => {
          if (item.id === response.id) {
            return response;
          } else {
            return item;
          }
        }),
        isLoading: false,
        isError: false,
        activeMenteesCount: state.activeMenteesCount + 1,
      };

    case REVIEW_REQUEST_STATUS_SUCCESS:
    case FINISH_REQUEST_STATUS_SUCCESS:
      const isFinish = type === FINISH_REQUEST_STATUS_SUCCESS;

      return {
        ...state,
        menteesRequests: state.menteesRequests.map(item => {
          if (item.id === response.id) {
            return response;
          } else {
            return item;
          }
        }),
        isLoading: false,
        isError: false,
        activeMenteesCount: isFinish ? state.activeMenteesCount - 1 : state.activeMenteesCount,
      };

    case REJECT_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        menteesRequests: state.menteesRequests.map(item => {
          if (item.id === response.id) {
            return response;
          } else {
            return item;
          }
        }),
        isLoading: false,
        isError: false,
      };

    case GET_CURRENT_MENTOR_SUCCESS:
      return {
        ...state,
        status: response.status,
        currentMentor: response,
        isLoading: false,
        isError: false,
        activeMenteesCount: response.activeMenteesCount,
      };

    case CREATE_MENTOR_SUCCESS:
      return {
        ...state,
        status: response.status,
        canBeMentor: false,
        isLoading: false,
        isError: false,
      };

    case GET_CAN_BE_MENTOR_SUCCESS:
      return {
        ...state,
        canBeMentor: response,
        isLoading: false,
        isError: false,
        sizePage: response === true ? 19 : 20,
      };

    case CREATE_MENTOR_FAIL:
    case GET_MENTORS_FAIL:
    case GET_CURRENT_MENTOR_FAIL:
    case GET_CAN_BE_MENTOR_FAIL:
    case GET_MENTEES_REQUESTS_FAIL:
    case ACCEPT_REQUEST_STATUS_FAIL:
    case FINISH_REQUEST_STATUS_FAIL:
    case REVIEW_REQUEST_STATUS_FAIL:
    case REJECT_REQUEST_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};
