import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { clearPlan, getUserTopicPlan, getUserTopicPlanScroll } from '@/store/study-plan/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';

import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction';

import { Card } from '../card';

import { classNames } from '@shared/utils/styles-utils';

import _get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const Plan = ({ isAnother, showCalendarModal, planName, planParams = {}, userId, currentUser }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('plan');

  const [currentPaidTopicId, setCurrentPaidTopicId] = useState(null);
  const [currentPaidTopicName, setCurrentPaidTopicName] = useState('');
  const [isModalCurrentPaidTopicOpen, setIsModalCurrentPaidTopicOpen] = useState(false);
  const [isModalPaidTopicRestrictionOpen, setIsModalPaidTopicRestrictionOpen] = useState(false);

  const [handleStudyPlanClickState, setHandleStudyPlanClickState] = useState(null);
  const [handleStudyPlanClickExecuted, setHandleStudyPlanClickExecuted] = useState(false);

  const {
    previousStudyPlansCheckResult,
    studyPlan,
    loading: { studyPlan: loadingObj },
  } = useSelector(selectStudyPlan);

  const params = {
    userId,
    size: 15,
    sort: 'startDate,desc',
    ...planParams,
  };

  const loadMore = page =>
    dispatch(
      getUserTopicPlanScroll({
        name: planName,
        params: {
          ...params,
          page: page - 1,
          isPersonalized: true,
        },
      })
    );

  useEffect(() => {
    dispatch(
      getUserTopicPlan({
        params: {
          ...params,
          isPersonalized: true,
        },
        name: planName,
        fullResponse: true,
      })
    );
  }, []);

  useEffect(() => {
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      !previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0 &&
      !handleStudyPlanClickExecuted
    ) {
      setCurrentPaidTopicName(previousStudyPlansCheckResult?.topicName);
      setCurrentPaidTopicId(previousStudyPlansCheckResult?.topicId);
      setIsModalCurrentPaidTopicOpen(true);
    }
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setIsModalPaidTopicRestrictionOpen(true);
    }
    if (previousStudyPlansCheckResult?.availableCreation) {
      setHandleStudyPlanClickExecuted(true);
      handleStudyPlanClickState();
    }
  }, [previousStudyPlansCheckResult, handleStudyPlanClickState, handleStudyPlanClickExecuted]);

  const loading = loadingObj[planName];

  const pagination = _get(studyPlan[planName], 'meta', {});
  const plan = _get(studyPlan[planName], 'data');

  const showList = !!(plan && plan.length);

  const hasMore = !loading && pagination.total > (pagination.currentPage + 1) * pagination.pageSize;

  const handleModalCurrentPaidTopicClose = () => {
    setIsModalCurrentPaidTopicOpen(false);
    setHandleStudyPlanClickExecuted(false);
    dispatch(clearPlan());
  };

  const handleModalPaidTopicRestrictionClose = () => {
    setIsModalPaidTopicRestrictionOpen(false);
    dispatch(clearPlan());
  };

  return (
    <div className={cn('plan')}>
      {showList ? (
        <div className={cn('plan__body')}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={loadMore}
            hasMore={hasMore}
            pageStart={1}
            threshold={10}
            className={cn('plan__list')}
          >
            {plan.map(material => (
              <Card
                key={material.id}
                material={material}
                isAnother={isAnother}
                showCalendarModal={showCalendarModal}
                currentUser={currentUser}
                topicCost={material.topic.cost}
                topicTypeApproved={material.topic.type.approved}
                topicTypewithEvents={material.topic.type.withEvents}
                setHandleStudyPlanClickState={setHandleStudyPlanClickState}
                setHandleStudyPlanClickExecuted={setHandleStudyPlanClickExecuted}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        !loading && (
          <div className={cn('plan__body_empty')}>
            {t('completeMaterials')} <br /> {t('feedback')}
          </div>
        )
      )}
      {isModalCurrentPaidTopicOpen && (
        <ModalCurrentPaidTopic
          open={isModalCurrentPaidTopicOpen}
          onClose={handleModalCurrentPaidTopicClose}
          currentPaidTopicName={currentPaidTopicName}
          currentPaidTopicId={currentPaidTopicId}
        />
      )}

      {isModalPaidTopicRestrictionOpen && (
        <ModalPaidTopicRestriction
          open={isModalPaidTopicRestrictionOpen}
          onClose={handleModalPaidTopicRestrictionClose}
        />
      )}
    </div>
  );
};

Plan.propTypes = {
  isAnother: PropTypes.bool,
  showCalendarModal: PropTypes.any,
  planParams: PropTypes.object,
  planName: PropTypes.string,
  userId: PropTypes.number,
  currentUser: PropTypes.object,
};
