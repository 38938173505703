import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addNewUsers, deleteChatGroupById, getUsersInGroup, updateChatGroup } from '@/store/chat/actions';
import { createFileImage } from '@/store/files/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar, Button, Form, message, Modal, Popconfirm, Popover } from 'antd';
import { FiEdit2, FiUserPlus, FiUsers } from 'react-icons/fi';

import TitleGroup from '../../GroupsList/components/TitleGroup';
import UsersList from '../../GroupsList/components/UsersList';
import GroupUser from './GroupUser';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const RENDER = {
  INFO: 'INFO',
  USERS: 'USERS',
  EDIT: 'EDIT',
};

const GroupModal = ({ visible, onClose }) => {
  const { t } = useTranslation('chat');
  const { selectedUser, chatUsers } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);

  const [render, setRender] = useState(RENDER.INFO);
  const [selectedUserState, setSelectedUserState] = useState([]);
  const [chatInfo, setChatInfo] = useState({
    chatName: undefined,
    chatPhoto: null,
    chatUsersList: [],
  });
  const [chatPhotoBlob, setChatPhotoBlob] = useState(null);
  const [chatPhotoPreview, setChatPhotoPreview] = useState(null);

  const dispatch = useDispatch();
  const avatarChat = useImage(selectedUser.photo);
  const form = Form.useForm();

  useEffect(() => {
    dispatch(getUsersInGroup(selectedUser?.chatId));
  }, []);

  useEffect(() => {
    const chatUsersList = chatUsers.map(user => ({
      id: user.id,
      firstName: user.first_name,
      lastName: user.lastName,
    }));

    setChatInfo({
      chatName: selectedUser.chatName,
      chatPhoto: selectedUser.photo,
      chatUsersList,
    });
  }, [selectedUser, chatUsers]);

  const handleDeleteGroup = () => {
    dispatch(deleteChatGroupById(selectedUser.chatId), () => {
      message.success(t('deleteGroupSuccess'));
    });
  };

  const handleCancelButton = () => {
    setRender(RENDER.INFO);
  };

  const handleOkButton = () => {
    if (render === RENDER.USERS) {
      setRender(RENDER.INFO);
      dispatch(
        addNewUsers(
          selectedUser.chatId,
          selectedUserState.map(user => user.id),
          () => {
            dispatch(getUsersInGroup(selectedUser?.chatId));
          }
        )
      );
    }

    if (render === RENDER.EDIT) {
      const updateGroup = res => {
        dispatch(
          updateChatGroup({
            chatId: selectedUser.chatId,
            chatName: chatInfo.chatName,
            chatPhotoUuid: res?.uuid ?? chatInfo.chatPhoto,
          })
        );

        setRender(RENDER.INFO);
      };

      if (chatPhotoBlob) {
        const formData = new FormData();
        formData.append('file', chatPhotoBlob);

        dispatch(createFileImage(formData, res => updateGroup(res)));
      } else {
        updateGroup();
      }
    }
  };

  const renderFooter = () => {
    if (render === RENDER.INFO || render === RENDER.EDIT) {
      return null;
    }

    return (
      <div className={styles.groupModal__footer}>
        <Button className={styles.groupModal__popoverElement} onClick={handleCancelButton} ghost>
          {t('cancel')}
        </Button>
        <Button onClick={handleOkButton} className={styles.groupModal__popoverElement} type='primary'>
          {t('ok')}
        </Button>
      </div>
    );
  };

  const handleSelectUser = newUser => {
    if (selectedUserState.filter(user => user.id === newUser.id).length) {
      setSelectedUserState(selectedUserState.filter(user => user.id !== newUser.id));
    } else {
      setSelectedUserState(prev => [...prev, newUser]);
    }
  };

  const updateChatPhoto = blob => {
    setChatPhotoBlob(blob);
    setChatPhotoPreview(URL.createObjectURL(blob));
  };

  const contentPopover = (
    <>
      <div className={styles.groupModal__popoverElement} onClick={() => setRender(RENDER.EDIT)}>
        <p>{t('editGroup.0')}</p>
      </div>
      <div className={classNames(styles.groupModal__popoverElement, styles.groupModal__popoverElement_red)}>
        <Popconfirm title={t('deleteGroup')} okText={t('yes')} cancelText={t('no')} onConfirm={handleDeleteGroup}>
          <p>{t('editGroup.1')}</p>
        </Popconfirm>
      </div>
    </>
  );

  const renderBlock = () => {
    if (render === RENDER.INFO) {
      return (
        <div className={styles.groupModal}>
          <div className={styles.groupModal__header}>
            <div className={styles.groupModal__block}>
              <Avatar src={avatarChat} size={50} />
              <div className={styles.groupModal__box}>
                <p className={styles.groupModal__name}>{selectedUser.chatName}</p>
                <p className={styles.groupModal__countUsers}>
                  {chatUsers.length} {t('users')}
                </p>
              </div>
            </div>
            {currentUser?.id === selectedUser?.chatAuthorId && (
              <Popover trigger='click' content={contentPopover}>
                <FiEdit2 className={styles.groupModal__icon} size={14} />
              </Popover>
            )}
          </div>
          <div className={styles.groupModal__element}>
            <div className={styles.groupModal__container}>
              <FiUsers size={20} />
              <p>
                {chatUsers.length} {t('users')}
              </p>
            </div>
            {currentUser?.id === selectedUser?.chatAuthorId && (
              <FiUserPlus size={20} onClick={() => setRender(RENDER.USERS)} className={styles.groupModal__icon} />
            )}
          </div>
          <div className={styles.groupModal__users}>
            {chatUsers.map(user => (
              <GroupUser key={user.id} chatId={selectedUser.chatId} data={user} />
            ))}
          </div>
        </div>
      );
    }

    if (render === RENDER.EDIT) {
      return (
        <TitleGroup
          form={form}
          name={chatInfo.chatName}
          imagePreview={chatPhotoPreview ?? avatarChat}
          setName={newName => setChatInfo({ ...chatInfo, chatName: newName })}
          updateImage={updateChatPhoto}
          handleCancelButton={onClose}
          onSubmitForm={handleOkButton}
        />
      );
    }

    return (
      <UsersList
        defaultSelectedUser={chatInfo.chatUsersList}
        selectedUser={selectedUserState}
        onSelectUser={handleSelectUser}
      />
    );
  };

  return (
    <Modal open={visible} footer={renderFooter()} title={t('titleGroup')} onCancel={onClose} width={500}>
      {renderBlock()}
    </Modal>
  );
};

GroupModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
  }),
};

export default memo(GroupModal);
