import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCareerPlanStatus } from '@/store/career_plan/actions';
import { clearStudyPlanParams } from '@/store/study-plan/actions';

import { selectCareerPlan } from '@/store/career_plan/selectors';

import { APPROVED_BY_ADMIN, APPROVED_BY_MANAGER, APPROVED_BY_USER } from '@/pages/CareerPlan/constants';
import { CarryOutOutlined, CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';

import { Widget } from '../widget';

import { formatFullDate, formatTime } from '@shared/utils/date-utils';
import { pluralize } from '@shared/utils/string-utils';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const formatWidgetDate = date => `${formatFullDate(date)} в ${formatTime(date)}`;

export const ActivityWidget = ({ activity, user }) => {
  const [correctTime, setCorrectTime] = useState();
  const { t } = useTranslation('widget');
  const navigate = useNavigate();
  let text = activity ? formatWidgetDate(correctTime) : t('notPlaned');
  const onClick = activity && (() => navigate(`/collection-topics/${activity.id}`));

  useEffect(() => {
    if (activity && user) {
      setCorrectTime(
        dayjs(activity.dateTime).add(user.timezone.offset / 60 - user.domainCompany.timezone.offset / 60, 'h')
      );
    }
  }, [activity, user]);

  return <Widget icon={<CheckCircleOutlined />} title={t('nearest')} text={text} onClick={onClick} />;
};

export const FinishedWidget = ({ count = 0 }) => {
  const { t } = useTranslation('widget');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const text = count ? `${count} ${pluralize(count, t('material', { returnObjects: true }))}` : t('noMaterials');

  const onClick = count
    ? () => {
        dispatch(clearStudyPlanParams({ name: 'finishedStudyPlan' }));
        navigate('/planning/finished');
      }
    : undefined;

  return <Widget icon={<CarryOutOutlined />} title={t('completed')} text={text} onClick={onClick} />;
};

export const CareerPlanWidget = () => {
  const { t } = useTranslation('widget');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { statusText, status } = useSelector(selectCareerPlan);
  const [careerPlanStatus, setCareerPlanStatus] = useState();

  const onClick = () => navigate('/career-plan');

  useEffect(() => {
    dispatch(getCareerPlanStatus());
  }, []);

  useEffect(() => {
    switch (status) {
      case APPROVED_BY_USER:
        return setCareerPlanStatus(t('careerPlanApprovedByUser'));

      case APPROVED_BY_ADMIN:
      case APPROVED_BY_MANAGER:
        return setCareerPlanStatus(t('careerPlanApprovedAdmin'));

      default:
        return setCareerPlanStatus(statusText ? statusText : t('careerPlanEmpty'));
    }
  }, [statusText]);

  return <Widget icon={<CopyOutlined />} title={t('careerPlan')} text={careerPlanStatus} onClick={onClick} />;
};

ActivityWidget.propTypes = {
  activity: PropTypes.any,
  user: PropTypes.any,
};

FinishedWidget.propTypes = {
  count: PropTypes.number,
};
