import { deleteDB, openDB } from 'idb';

export const IDB_NAME = 'newIDB';
export const TOPICS_IDB = 'topics';
export const COMPANY_IDB = 'company';
export const TOPICS_IMAGES_IDB = 'image_topics';
export const ARTICLE_IDB = 'article';
export const PRESENTATION_IDB = 'presentation';
export const PRESENTATION_FILE_IDB = 'presentation_file';
export const VIDEO_IDB = 'video';
export const USER_IDB = 'user';
export const CHAPTERS_IDB = 'chapters';
export const STATISTIC_IDB = 'statistic';

export async function deleteIDB() {
  await deleteDB('LocalDB');
  await deleteDB('topicDB');
  await deleteDB('IDB');
}

export async function createIDB() {
  // https://web.dev/learn/pwa/offline-data/
  // Using https://github.com/jakearchibald/idb

  await openDB(IDB_NAME, 2, {
    upgrade(db, oldVersion) {
      // eslint-disable-next-line
      switch (oldVersion) {
        case 0:
        case 1:
        case 2:
          db.createObjectStore(TOPICS_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(TOPICS_IMAGES_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(ARTICLE_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(PRESENTATION_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(PRESENTATION_FILE_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(VIDEO_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(COMPANY_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(USER_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(CHAPTERS_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });

          db.createObjectStore(STATISTIC_IDB, {
            autoIncrement: true,
            keyPath: 'id',
          });
      }
    },
  });
}
