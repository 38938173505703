import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearPlan, setCurrentPaidTopicId } from '@/store/study-plan/actions';

import { Button, Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './ModalCurrentPaidTopic.module.scss';

const ModalCurrentPaidTopic = ({ open, onClose, currentPaidTopicName, currentPaidTopicId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modalCurrentPaidTopic');
  const navigate = useNavigate();

  const truncateName = name => {
    return name?.length > 10 ? `${name.slice(0, 10)}...` : name;
  };

  const truncatedTopicName = truncateName(currentPaidTopicName);

  const onGoToTopic = () => {
    dispatch(clearPlan());
    dispatch(setCurrentPaidTopicId(currentPaidTopicId));
    navigate(`/planning`);
  };

  const footer = (
    <div className={css['ModalCurrentPaidTopic-footer']}>
      <Button key='goto' onClick={onGoToTopic} className={css['ModalCurrentPaidTopic-btn']} size='large'>
        {t('gotoTopic1')} "{truncatedTopicName}" {t('gotoTopic2')}
      </Button>
      <Button key='cancel' type='primary' onClick={onClose} className={css['ModalCurrentPaidTopic-btn']} size='large'>
        {t('cancelEnrollment')}
      </Button>
    </div>
  );

  return (
    <Modal open={open} onCancel={onClose} className={css['ModalCurrentPaidTopic']} footer={footer} centered={true}>
      <div className={css['ModalCurrentPaidTopic-body']}>
        <div className={css['ModalCurrentPaidTopic-text']}>
          {t('cannotEnroll')} "{currentPaidTopicName}".
        </div>
        <div className={css['ModalCurrentPaidTopic-text']}>
          {t('cancelPrevious1')} "{currentPaidTopicName}"{t('cancelPrevious2')}
        </div>
      </div>
    </Modal>
  );
};

ModalCurrentPaidTopic.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  currentPaidTopicName: PropTypes.string,
  currentPaidTopicId: PropTypes.number,
};

export default ModalCurrentPaidTopic;
