/* eslint-disable */
import { GET_FAQ_FAIL, GET_FAQ_START, GET_FAQ_SUCCESS } from './actions';

const initialState = {
  isLoading: false,
  faq: null,
  error: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_FAQ_START:
      return { ...state, isLoading: true, faq: null, error: false };

    case GET_FAQ_SUCCESS:
      return { ...state, isLoading: false, faq: response, error: false };

    case GET_FAQ_FAIL:
      return { ...state, isLoading: false, faq: null, error: true };

    default:
      return state;
  }
};
