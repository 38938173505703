import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Rate, Typography } from 'antd';

import PropTypes from 'prop-types';

import styles from './RateModal.module.scss';

const { Title } = Typography;

const RateModal = ({ visible, onCancel, onOk }) => {
  const { t } = useTranslation('newsId');
  const [rateValue, setRateValue] = useState(0);

  return (
    <Modal open={visible} onCancel={onCancel} onOk={onOk} footer={null}>
      <div className={styles.modal}>
        <Title level={3}>{t('modalRate')}</Title>
        <Rate onChange={value => setRateValue(value)} />
        <Button disabled={!rateValue} onClick={() => onOk(rateValue)} size='large' type='primary'>
          {t('buttonRate')}
        </Button>
      </div>
    </Modal>
  );
};

RateModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};

export default RateModal;
