/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Form, Input, TimePicker } from 'antd';

import CustomDateSomePicker from '@shared/components/DatePicker/DateSomePicker';
import { isConference, isEvent } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './ScheduleCustom.module.scss';

const { TextArea } = Input;

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';

const isString = value => typeof value === 'string';

class ScheduleCustom extends Component {
  static propTypes = {
    curTime: PropTypes.object,
    currentUser: PropTypes.object,
    dates: PropTypes.array,
    error: PropTypes.bool,
    ids: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    topic: PropTypes.object,
    value: PropTypes.object,
  };

  componentDidMount() {
    const { currentUser, value, ids, onChange } = this.props;

    const startTimeValue = value?.startTime
      ? value.startTime
      : currentUser?.domainCompany?.eventStartTime
        ? currentUser?.domainCompany.eventStartTime
        : undefined;

    const endTimeValue = value?.endTime
      ? value.endTime
      : currentUser?.domainCompany?.eventEndTime
        ? currentUser?.domainCompany.eventEndTime
        : undefined;

    const startTimeForFormat = startTimeValue ? dayjs(startTimeValue, timeFormat) : startTimeValue;

    const endTimeForFormat = endTimeValue ? dayjs(endTimeValue, timeFormat) : endTimeValue;

    onChange(ids, 'startTime', startTimeForFormat);
    onChange(ids, 'endTime', endTimeForFormat);
  }

  changeStartTime = (time, timeString) => {
    const { value, ids, onChange } = this.props;

    let startTimeValue = dayjs().set(timeFormat, timeString);
    const isDateExist = Boolean(value?.dates?.[0]);
    const endTime = value?.endTime;

    const firstDateString =
      isString(value?.dates?.[0]) && isDateExist ? value?.dates?.[0] : dayjs(value?.dates?.[0]).format(dateFormat);

    const fullEndDateTime =
      firstDateString && isString(endTime) && endTime ? dayjs(`${firstDateString}T${endTime}`) : endTime;

    if (isDateExist && firstDateString) {
      startTimeValue = dayjs(`${firstDateString}T${timeString}`);
      const endDate = startTimeValue.add(5, 'minute');

      if (endDate.isAfter(fullEndDateTime)) {
        onChange(ids, 'endTime', endDate);
      }
    }

    onChange(ids, 'startTime', startTimeValue);
  };

  changeEndTime = (time, timeString) => {
    this.props.onChange(this.props.ids, 'endTime', time);
  };

  changePlace = event => {
    this.props.onChange(this.props.ids, 'place', event.target.value);
  };

  changeDates = event => {
    const { onChange, ids, value } = this.props;

    const dayjsDates = event.map(day => {
      if (isString(day)) {
        return dayjs(day, dateFormat);
      }

      if (dayjs.isDayjs(day)) {
        return day;
      }

      return dayjs(day);
    });

    const includesToday = dayjsDates.find(day => dayjs().format(dateFormat) === day.format(dateFormat));

    if (includesToday) {
      const endDateTime = dayjs.isDayjs(value?.endTime)
        ? value.endTime
        : value?.endTime
          ? dayjs().set(timeFormat, value.endTime)
          : dayjs().add(5, 'minutes');

      if (endDateTime.isBefore(dayjs())) {
        onChange(ids, 'startTime', dayjs());
        onChange(ids, 'endTime', dayjs().add(5, 'minutes'));
        return;
      }

      onChange(ids, 'startTime', dayjs());
    }

    onChange(ids, 'dates', event);
  };

  shouldDisableHours = () => {
    const { dates } = this.props.value;
    if (!dates) {
      return false;
    }
    const isCurDate = dates.filter(date => dayjs(date).isSame(dayjs(), 'day'));
    return !!isCurDate.length;
  };

  checkDisabledHours = timeType => {
    const { value, curTime } = this.props;
    let currHH = 0;

    if (timeType === 'endTime') {
      const isDateExist = Boolean(value?.dates?.[0]);
      const firstDateString =
        isString(value?.dates?.[0]) && isDateExist
          ? value?.dates?.[0]
          : isDateExist
            ? dayjs(value.dates[0]).format(dateFormat)
            : dayjs().format(dateFormat);

      const onlyToday =
        value?.dates?.length === 1 && value?.dates?.[0] && firstDateString === dayjs().format(dateFormat);

      const startTimeString =
        value?.startTime && isString(value?.startTime)
          ? value.startTime
          : value?.startTime
            ? value.startTime.format(timeFormat)
            : dayjs().format(timeFormat);

      if (onlyToday) {
        currHH = Number(curTime.format('H'));
      } else if (value?.startTime) {
        currHH = Number(dayjs(`${firstDateString}T${startTimeString}`).format('H'));
      } else {
        currHH = 0;
      }
    } else {
      currHH = this.shouldDisableHours() ? Number(curTime.format('H')) : 0;
    }

    const disabledHours = [];
    let i = 0;

    while (i < currHH && i < 24) {
      disabledHours.push(i);
      i++;
    }

    return disabledHours;
  };

  checkDisabledMinutes = (timeType, selectedHour) => {
    let curHH = this.shouldDisableHours() ? Number(this.props.curTime.format('HH')) : 0;
    let curMM;
    let i = 0;

    const disabledMinutes = [];
    const startHH = (this.props.value?.startTime && Number(dayjs(this.props.value?.startTime).format('HH'))) || 0;

    curMM = this.shouldDisableHours() ? Number(this.props.curTime.format('m')) : 0;

    if (timeType === 'endTime') {
      if (selectedHour === startHH) {
        const isDateExist = Boolean(this.props.value?.dates?.[0]);
        const firstDateString =
          isString(this.props.value?.dates?.[0]) && isDateExist
            ? this.props.value?.dates?.[0]
            : isDateExist
              ? dayjs(this.props.value.dates[0]).format(dateFormat)
              : dayjs().format(dateFormat);

        const startTimeString =
          this.props.value?.startTime && isString(this.props.value?.startTime)
            ? this.props.value.startTime
            : this.props.value?.startTime
              ? this.props.value.startTime.format(timeFormat)
              : dayjs().format(timeFormat);

        curMM = this.props.value?.startTime && Number(dayjs(`${firstDateString}T${startTimeString}`).format('m')) + 5;
      }
    }

    if (selectedHour === curHH || selectedHour === startHH) {
      while (i < curMM && i < 60) {
        disabledMinutes.push(i);
        i++;
      }
    }

    return disabledMinutes;
  };

  render() {
    const { currentUser, value, topic, t } = this.props;

    const startDateTime = topic?.startDate && topic?.startTime ? dayjs(`${topic.startDate}T${topic.startTime}`) : null;

    const disabledHoursStart =
      (isEvent(topic.typeId) || isConference(topic.typeId)) &&
      this.shouldDisableHours() &&
      startDateTime?.isSame(dayjs(), 'day');

    const dateIsBeforeStart =
      (isEvent(topic.typeId) || isConference(topic.typeId)) &&
      this.shouldDisableHours() &&
      startDateTime?.isBefore(dayjs(), 'day');

    const disabledHoursEnd = isEvent(topic.typeId) || isConference(topic.typeId);
    const dateIsBeforeEnd = (isEvent(topic.typeId) || isConference(topic.typeId)) && !value?.startTime;

    const startDateTimeFormat = dayjs(
      value.startTime
        ? value.startTime
        : currentUser?.domainCompany?.eventStartTime
          ? currentUser.domainCompany.eventStartTime
          : null,
      timeFormat
    );

    const endDateTimeFormat = dayjs(
      value.endTime
        ? value.endTime
        : currentUser?.domainCompany?.eventEndTime
          ? currentUser.domainCompany.eventEndTime
          : null,
      timeFormat
    );

    return (
      <div className={css.ScheduleCustomItem}>
        <div className={css['ScheduleCustomItem-dates']}>
          <Form.Item label={t('time')} schedule required>
            <div className={css['ScheduleCustomItem-dates-date']}>
              <div
                className={classNames(
                  { [css['ScheduleCustomItem-error']]: this.props.error && this.props.value?.endTime === '' },
                  css.HoursMinutesInput
                )}
              >
                <TimePicker
                  className={classNames(css['HoursMinutesTimePicker-modificate'])}
                  format={timeFormat}
                  onChange={this.changeStartTime}
                  name='startTime'
                  value={startDateTimeFormat}
                  disabled={dateIsBeforeStart}
                  disabledTime={() => ({
                    disabledHours: disabledHoursStart ? () => this.checkDisabledHours('startTime') : () => [],
                    disabledMinutes: disabledHoursStart
                      ? selectedHour => this.checkDisabledMinutes('startTime', selectedHour)
                      : () => [],
                  })}
                />
              </div>
              <div
                className={classNames(
                  { [css['ScheduleCustomItem-error']]: this.props.error && this.props.value?.endTime === '' },
                  css.HoursMinutesInput
                )}
              >
                <TimePicker
                  className={classNames(css['HoursMinutesTimePicker-modificate'])}
                  format={timeFormat}
                  onChange={this.changeEndTime}
                  name='endTime'
                  value={endDateTimeFormat}
                  disabled={dateIsBeforeEnd}
                  disabledTime={() => ({
                    disabledHours: disabledHoursEnd ? () => this.checkDisabledHours('endTime') : () => [],
                    disabledMinutes: disabledHoursEnd
                      ? selectedHour => this.checkDisabledMinutes('endTime', selectedHour)
                      : () => [],
                  })}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item label={t('place')} required>
            <TextArea
              className={classNames(css['ScheduleCustomItem-textarea'], {
                [css['ScheduleCustomItem-textarea-error']]: this.props.error && value?.place === '',
              })}
              rows={5}
              name='description'
              placeholder={t('address')}
              onChange={this.changePlace}
              value={value?.place}
              maxLength={100}
            />
          </Form.Item>
        </div>
        <div className={css['ScheduleCustomItem-calendar']}>
          <CustomDateSomePicker
            value={value?.dates}
            name='date'
            error={this.props.error && !value?.dates?.length}
            onChange={this.changeDates}
            disabledDays={this.props.disabledDays}
            long
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('scheduleCustom')(ScheduleCustom);
