import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import { Progress } from 'antd';

import PropTypes from 'prop-types';

import css from './ShowProgress.module.scss';

const ShowProgress = ({ steps = 2, completedSteps = 1 }) => {
  const { t } = useTranslation('showProgress');
  const percent = Math.floor((completedSteps * 100) / steps);
  const { currentUser } = useSelector(selectUsers);

  const render = () => {
    return (
      <>
        <Progress
          showInfo={false}
          percent={percent}
          strokeColor={(currentUser.domainCompany && currentUser.domainCompany.colour) || '#ff8c00'}
        />
        <div className={css.ProgressData}>
          <span>{`${percent}%  ${t('end')}`}</span>
          <span>{`${completedSteps}/${steps}`}</span>
        </div>
      </>
    );
  };

  return render();
};

ShowProgress.propTypes = {
  steps: PropTypes.number,
  completedSteps: PropTypes.number,
};

export default ShowProgress;
