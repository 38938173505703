import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getFile } from '@/store/files/actions';

import { selectFiles } from '@/store/files/selectors';

import { Tooltip } from 'antd';
import TypeBadge from '@/components/TypeBadge';
import { AiOutlineCheck } from 'react-icons/ai';

import { FINISHED } from './constants';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';

import Utils from '@/Utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import css from './MaterialCard.module.scss';

const MAX_TITLE_LENGTH = 45;

const MaterialCard = ({ topic, trackId }) => {
  const dispatch = useDispatch();
  const [cover, setCover] = useState(null);
  const { isLoadingGetFile } = useSelector(selectFiles);

  useEffect(() => {
    if (!isEmpty(topic.thumbFileUuid)) {
      dispatch(getFile(topic.thumbFileUuid, cover => setCover(URL.createObjectURL(cover))));
    } else {
      const topicImage = topic.type && TOPIC_IMAGE.find(type => type.id === topic.type.id);
      topicImage.image && setCover(topicImage.image);
    }
  }, [topic?.thumbFileUuid]);

  const isFinished = topic.UserPlanStatus === FINISHED;

  return (
    <Link
      to={{
        pathname: `/collection-topics/${topic.id}`,
      }}
      state={{ fromTrackId: trackId }}
      className={css['MaterialCard']}
    >
      {
        <div className={classNames(css['MaterialCard-checkbox'], isFinished && css['MaterialCard-checkbox-active'])}>
          {isFinished && <AiOutlineCheck size={18} />}
        </div>
      }
      <div className={css['MaterialCard-cover--block']}>
        <div className={css['MaterialCard-cover-container']}>
          {cover && !isLoadingGetFile ? (
            <img className={css['MaterialCard-cover']} src={cover} alt='MaterialCover' />
          ) : (
            <Skeleton height={105} width={200} />
          )}
          {topic.type.id && <TypeBadge className={css['MaterialCard-type']} typeId={topic.type.id} />}
        </div>
        <div className={css['MaterialCard-cover--title']}>
          <p className={css['MaterialCard-label']}>{topic.typeLabel?.name}</p>
          <Tooltip title={topic?.name.length > MAX_TITLE_LENGTH && topic?.name}>
            <p className={css['MaterialCard-title']}>{Utils.trString(topic?.name, MAX_TITLE_LENGTH)}</p>
          </Tooltip>
        </div>
      </div>
    </Link>
  );
};

MaterialCard.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    thumbFileUuid: PropTypes.string,
    type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      approved: PropTypes.bool,
      withEvents: PropTypes.bool,
    }),
    typeLabel: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      topicTypeId: PropTypes.number,
    }),
    position: PropTypes.number,
    userPlanStatus: PropTypes.string,
  }),
};

export default MaterialCard;
