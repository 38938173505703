import { TASKS, TOPICS } from '@/pages/CareerPlan/constants';

import {
  APPROVE_CAREER_PLAN_FAIL,
  APPROVE_CAREER_PLAN_START,
  APPROVE_CAREER_PLAN_SUCCESS,
  CREATE_CAREER_PLAN_FAIL,
  CREATE_CAREER_PLAN_START,
  CREATE_CAREER_PLAN_SUCCESS,
  CREATE_CAREER_PLAN_TASK_FAIL,
  CREATE_CAREER_PLAN_TASK_START,
  CREATE_CAREER_PLAN_TASK_SUCCESS,
  CREATE_MENTOR_CAREER_PLAN_FAIL,
  CREATE_MENTOR_CAREER_PLAN_START,
  CREATE_MENTOR_CAREER_PLAN_SUCCESS,
  GET_CAREER_PLAN_FAIL,
  GET_CAREER_PLAN_SETTINGS_FAIL,
  GET_CAREER_PLAN_SETTINGS_START,
  GET_CAREER_PLAN_SETTINGS_SUCCESS,
  GET_CAREER_PLAN_START,
  GET_CAREER_PLAN_STATUS_FAIL,
  GET_CAREER_PLAN_STATUS_START,
  GET_CAREER_PLAN_STATUS_SUCCESS,
  GET_CAREER_PLAN_SUCCESS,
  GET_GOAL_CATEGORY_FAIL,
  GET_GOAL_CATEGORY_START,
  GET_GOAL_CATEGORY_SUCCESS,
  RETURN_CAREER_PLAN_FAIL,
  RETURN_CAREER_PLAN_START,
  RETURN_CAREER_PLAN_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCareerPlanStatus = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan/status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CAREER_PLAN_STATUS_START, GET_CAREER_PLAN_STATUS_SUCCESS, GET_CAREER_PLAN_STATUS_FAIL],
  onSuccess,
});

export const getGoalCategories = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan/goalcategory`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_GOAL_CATEGORY_START, GET_GOAL_CATEGORY_SUCCESS, GET_GOAL_CATEGORY_FAIL],
  onSuccess,
});

export const getCareerPlan = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/career-plan`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CAREER_PLAN_START, GET_CAREER_PLAN_SUCCESS, GET_CAREER_PLAN_FAIL],
  onSuccess,
});

export const getCareerPlanSettings = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/company/settings/ipr`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CAREER_PLAN_SETTINGS_START, GET_CAREER_PLAN_SETTINGS_SUCCESS, GET_CAREER_PLAN_SETTINGS_FAIL],
  onSuccess,
});

export const createCareerPlan = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CAREER_PLAN_START, CREATE_CAREER_PLAN_SUCCESS, CREATE_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const editCareerPlan = (id, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CAREER_PLAN_START, CREATE_CAREER_PLAN_SUCCESS, CREATE_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const updateMentorCareerPlan = (body, careerPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${careerPlanId}/mentors`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_MENTOR_CAREER_PLAN_START, CREATE_MENTOR_CAREER_PLAN_SUCCESS, CREATE_MENTOR_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const createCareerPlanTask = (body, careerPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/career-plan/${careerPlanId}/tasks/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CAREER_PLAN_TASK_START, CREATE_CAREER_PLAN_TASK_SUCCESS, CREATE_CAREER_PLAN_TASK_FAIL],
  onSuccess,
  onError,
});

export const updateCareerPlan = (body, careerPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${careerPlanId}/${body.tasks ? TASKS : TOPICS}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CAREER_PLAN_START, CREATE_CAREER_PLAN_SUCCESS, CREATE_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const approveCareerPlan = (careerPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${careerPlanId}/approve`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [APPROVE_CAREER_PLAN_START, APPROVE_CAREER_PLAN_SUCCESS, APPROVE_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const menteeCareerPlan = (careerPlanId, body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${careerPlanId}/mentee`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_CAREER_PLAN_START, CREATE_CAREER_PLAN_SUCCESS, CREATE_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});

export const returnCareerPlan = (careerPlanId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/career-plan/${careerPlanId}/edit`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [RETURN_CAREER_PLAN_START, RETURN_CAREER_PLAN_SUCCESS, RETURN_CAREER_PLAN_FAIL],
  onSuccess,
  onError,
});
