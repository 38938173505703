import React from 'react';

import { Plan } from '../plan';

import { PlanStatus } from '@shared/constants/plan';

import PropTypes from 'prop-types';

export const StudyPlanFinished = ({ isAnother, showCalendarModal, currentUserId, currentUser }) => {
  const name = 'studyPlanFinished';
  const params = {
    planStatus: PlanStatus.FINISHED,
  };

  return (
    <Plan
      isAnother={isAnother}
      showCalendarModal={showCalendarModal}
      planParams={params}
      planName={name}
      userId={currentUserId}
      currentUser={currentUser}
    />
  );
};

StudyPlanFinished.propTypes = {
  isAnother: PropTypes.bool,
  showCalendarModal: PropTypes.any,
  currentUserId: PropTypes.number,
  currentUser: PropTypes.object,
};
