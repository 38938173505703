/* eslint-disable */
import {
  CREATE_CAREER_PLAN_FAIL,
  CREATE_CAREER_PLAN_START,
  CREATE_CAREER_PLAN_SUCCESS,
  CREATE_CAREER_PLAN_TASK_FAIL,
  CREATE_CAREER_PLAN_TASK_START,
  CREATE_CAREER_PLAN_TASK_SUCCESS,
  CREATE_MENTOR_CAREER_PLAN_FAIL,
  CREATE_MENTOR_CAREER_PLAN_START,
  CREATE_MENTOR_CAREER_PLAN_SUCCESS,
  GET_CAREER_PLAN_FAIL,
  GET_CAREER_PLAN_SETTINGS_FAIL,
  GET_CAREER_PLAN_SETTINGS_START,
  GET_CAREER_PLAN_SETTINGS_SUCCESS,
  GET_CAREER_PLAN_START,
  GET_CAREER_PLAN_STATUS_FAIL,
  GET_CAREER_PLAN_STATUS_START,
  GET_CAREER_PLAN_STATUS_SUCCESS,
  GET_CAREER_PLAN_SUCCESS,
  GET_GOAL_CATEGORY_FAIL,
  GET_GOAL_CATEGORY_START,
  GET_GOAL_CATEGORY_SUCCESS,
} from './constants';

const initialState = {
  status: null,
  statusText: '',
  careerPlan: {},
  isLoading: false,
  goalCategory: '',
  requiredIpr: null,
  isLoadingMentors: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case CREATE_CAREER_PLAN_START:
    case GET_CAREER_PLAN_STATUS_START:
    case CREATE_CAREER_PLAN_TASK_START:
    case CREATE_CAREER_PLAN_TASK_FAIL:
    case CREATE_CAREER_PLAN_TASK_SUCCESS:
    case CREATE_CAREER_PLAN_FAIL:
    case GET_CAREER_PLAN_STATUS_FAIL:
    case GET_GOAL_CATEGORY_FAIL:
    case GET_GOAL_CATEGORY_START:
      return {
        ...state,
      };

    case GET_CAREER_PLAN_SETTINGS_START:
    case GET_CAREER_PLAN_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CAREER_PLAN_SETTINGS_SUCCESS:
      return {
        ...state,
        ...response,
        isLoading: false,
      };

    case GET_CAREER_PLAN_SETTINGS_FAIL:
    case GET_CAREER_PLAN_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case GET_CAREER_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        status: response?.status,
        statusText: response?.statusText,
      };

    case GET_GOAL_CATEGORY_SUCCESS:
      return {
        ...state,
        goalCategory: response,
      };

    case GET_CAREER_PLAN_SUCCESS:
    case CREATE_CAREER_PLAN_SUCCESS:
      return {
        ...state,
        status: response.status,
        careerPlan: response,
        statusText: response?.statusText,
        isLoading: false,
      };

    case CREATE_MENTOR_CAREER_PLAN_START:
      return {
        ...state,
        isLoadingMentors: true,
      };

    case CREATE_MENTOR_CAREER_PLAN_SUCCESS:
      return {
        ...state,
        status: response.status,
        careerPlan: response,
        statusText: response?.statusText,
        isLoadingMentors: false,
      };

    case CREATE_MENTOR_CAREER_PLAN_FAIL:
      return {
        ...state,
        isLoadingMentors: false,
      };

    default:
      return state;
  }
};
