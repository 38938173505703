/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_CHAPTERS_START = 'GET_CHAPTERS_START';
export const GET_CHAPTERS_SUCCESS = 'GET_CHAPTERS_SUCCESS';
export const GET_CHAPTERS_FAIL = 'GET_CHAPTERS_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getChapters = (topicIds, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/chapter?topicId=${topicIds}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicIds,
  },
  onSuccess,
  types: [GET_CHAPTERS_START, GET_CHAPTERS_SUCCESS, GET_CHAPTERS_FAIL],
});

export const getChaptersOffline = response => ({
  type: GET_CHAPTERS_SUCCESS,
  response,
});
