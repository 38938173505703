/* eslint-disable */
import {
  CLEAR_COMPILATIONS,
  CLEAR_COMPILATIONS_COMMENTS,
  CLEAR_PAGE,
  DELETE_COMPILATION_FAIL,
  DELETE_COMPILATION_START,
  DELETE_COMPILATION_SUCCESS,
  DELETE_COMPILATIONS_COMMENT_FAIL,
  DELETE_COMPILATIONS_COMMENT_START,
  DELETE_COMPILATIONS_COMMENT_SUCCESS,
  DELETE_THEME_FAIL,
  DELETE_THEME_START,
  DELETE_THEME_SUCCESS,
  GET_COMPILATION_FAIL,
  GET_COMPILATION_START,
  GET_COMPILATION_SUCCESS,
  GET_COMPILATIONS_COMMENTS_FAIL,
  GET_COMPILATIONS_COMMENTS_START,
  GET_COMPILATIONS_COMMENTS_SUCCESS,
  GET_COMPILATIONS_FAIL,
  GET_COMPILATIONS_START,
  GET_COMPILATIONS_SUCCESS,
  GET_THEMES_FAIL,
  GET_THEMES_START,
  GET_THEMES_SUCCESS,
  POST_COMPILATION_FAIL,
  POST_COMPILATION_START,
  POST_COMPILATION_SUCCESS,
  POST_COMPILATIONS_COMMENT_FAIL,
  POST_COMPILATIONS_COMMENT_START,
  POST_COMPILATIONS_COMMENT_SUCCESS,
  POST_THEME_FAIL,
  POST_THEME_START,
  POST_THEME_SUCCESS,
  RESTORE_COMPILATIONS_COMMENT_FAIL,
  RESTORE_COMPILATIONS_COMMENT_START,
  RESTORE_COMPILATIONS_COMMENT_SUCCESS,
  TOGGLE_FAVORITE_COMPILATIONS_FAIL,
  TOGGLE_FAVORITE_COMPILATIONS_START,
  TOGGLE_FAVORITE_COMPILATIONS_SUCCESS,
  UPDATE_COMPILATION_FAIL,
  UPDATE_COMPILATION_START,
  UPDATE_COMPILATION_SUCCESS,
  UPDATE_COMPILATIONS_COMMENT_FAIL,
  UPDATE_COMPILATIONS_COMMENT_START,
  UPDATE_COMPILATIONS_COMMENT_SUCCESS,
  UPDATE_THEME_FAIL,
  UPDATE_THEME_START,
  UPDATE_THEME_SUCCESS,
} from './actions';

import { uniqueArrayByKey } from '@/utils/collections';

const initialState = {
  /* compilations: {
    elements: [],
    hasMore: false,
    page: 0
  }, */
  compilation: null,
  compilations: [],
  compilationsTotal: 0,
  compilationsPageNumber: 0,
  compilationsPageSize: 0,
  compilationComments: [],
  pageCompilationComments: 0,
  hasMoreCompilationComments: true,
  themes: [],
  theme: {},
  isLoading: false,
  isLoadingCompilations: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case TOGGLE_FAVORITE_COMPILATIONS_START:
    case RESTORE_COMPILATIONS_COMMENT_START:
    case DELETE_COMPILATIONS_COMMENT_START:
    case UPDATE_COMPILATIONS_COMMENT_START:
    case POST_COMPILATIONS_COMMENT_START:
    case GET_COMPILATIONS_COMMENTS_START:
    case DELETE_COMPILATION_START:
    case UPDATE_COMPILATION_START:
    case POST_COMPILATION_START:
      return { ...state, isLoading: true, error: false };

    case CLEAR_COMPILATIONS_COMMENTS:
      return {
        ...state,
        compilationComments: [],
        totalCompilationComments: null,
        hasMoreCompilationComments: null,
        pageCompilationComments: 0,
        isLoading: false,
      };

    case GET_COMPILATIONS_COMMENTS_SUCCESS:
      const pageNumber = Number(headers.get('x-page-number'));
      const totalComments = Number(headers.get('x-total-elements'));
      return {
        ...state,
        compilationComments: pageNumber === 0 ? response : [...state.compilationComments, ...response],
        totalCompilationComments: totalComments,
        hasMoreCompilationComments: totalComments > state.compilationComments.length && response.length !== 0,
        pageCompilationComments: pageNumber || 0,
        isLoading: false,
      };

    case TOGGLE_FAVORITE_COMPILATIONS_SUCCESS:
      return { ...state, error: false, isLoading: false };
    case RESTORE_COMPILATIONS_COMMENT_SUCCESS:
      return {
        ...state,
        compilation: {
          ...state.compilation,
          commentCount: state.compilation.commentCount + 1,
        },
        error: false,
        isLoading: false,
      };
    case DELETE_COMPILATIONS_COMMENT_SUCCESS:
      return {
        ...state,
        compilation: {
          ...state.compilation,
          commentCount: state.compilation.commentCount - 1,
        },
        error: false,
        isLoading: false,
      };
    case GET_COMPILATION_START:
      return { ...state, compilation: null, isLoading: true, error: false };
    case DELETE_COMPILATION_SUCCESS:
    case UPDATE_COMPILATION_SUCCESS:
    case GET_COMPILATION_SUCCESS:
    case POST_COMPILATION_SUCCESS:
      return { ...state, compilation: response, isLoading: false };

    case TOGGLE_FAVORITE_COMPILATIONS_FAIL:
    case RESTORE_COMPILATIONS_COMMENT_FAIL:
    case DELETE_COMPILATIONS_COMMENT_FAIL:
    case UPDATE_COMPILATIONS_COMMENT_FAIL:
    case GET_COMPILATIONS_COMMENTS_FAIL:
    case DELETE_COMPILATION_FAIL:
    case UPDATE_COMPILATION_FAIL:
    case GET_COMPILATION_FAIL:
    case POST_COMPILATION_FAIL:
      return { ...state, error: true, isLoading: false };

    case CLEAR_COMPILATIONS:
      return {
        ...state,
        compilations: [],
        isLoadingCompilations: false,
        compilationsTotal: 0,
        compilationsPageNumber: 0,
        compilationsPageSize: 0,
        error: false,
      };

    case GET_COMPILATIONS_START:
      return { ...state, isLoadingCompilations: true, error: false };

    case GET_COMPILATIONS_SUCCESS:
      const uniqueCompilations = uniqueArrayByKey(response, 'id');

      return {
        ...state,
        compilations: uniqueCompilations,
        compilationsTotal: Number(headers.get('X-Total-Elements')) || 0,
        compilationsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        compilationsPageSize: Number(headers.get('X-Page-Size')) || 0,
        isLoadingCompilations: false,
      };

    case GET_COMPILATIONS_FAIL:
      return { ...state, error: true, isLoadingCompilations: false };

    case DELETE_THEME_START:
      return { ...state, isLoading: true, error: false };

    case POST_COMPILATIONS_COMMENT_SUCCESS:
      const compilationComments = [...state.compilationComments.filter(item => item.id !== response.id), response];
      return {
        ...state,
        compilation: {
          ...state.compilation,
          commentCount: state.compilation.commentCount + 1,
        },
        compilationComments,
        error: false,
        isLoading: false,
      };

    case DELETE_THEME_SUCCESS:
      return { ...state, error: false, isLoading: false };
    case UPDATE_COMPILATIONS_COMMENT_SUCCESS:
      const result = state.compilationComments.filter(item => {
        return item.id !== response.id;
      });
      result.push(response);
      return {
        ...state,
        compilationComments: result,
        isLoading: false,
        error: false,
      };

    case POST_COMPILATIONS_COMMENT_FAIL:
    case DELETE_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_THEMES_START:
      return { ...state, isLoading: true, error: false };

    case GET_THEMES_SUCCESS:
      return { ...state, themes: response, isLoading: false };

    case GET_THEMES_FAIL:
      return { ...state, error: true, isLoading: false };

    case UPDATE_THEME_START:
      return { ...state, isLoading: true, error: false };

    case UPDATE_THEME_SUCCESS:
      return { ...state, theme: response, isLoading: false };

    case UPDATE_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case POST_THEME_START:
      return { ...state, isLoading: true, error: false };

    case POST_THEME_SUCCESS:
      return { ...state, theme: response, isLoading: false };

    case POST_THEME_FAIL:
      return { ...state, error: true, isLoading: false };

    case CLEAR_PAGE:
      return { ...state, compilationsPageNumber: initialState.compilationsPageNumber };

    default:
      return state;
  }
};
