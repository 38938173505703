import React from 'react';

import { Modal, Tooltip, Typography } from 'antd';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const BadgeElement = ({ mini = false, small = false, data }) => {
  const [open, setOpen] = React.useState(false);

  const image = useImage(data?.imageUuid);

  const renderSmall = () => <img src={image} alt='' />;

  const renderMini = () => (
    <div className={classNames(style['badge-element'], style['badge-element--mini'])} onClick={() => setOpen(true)}>
      <Tooltip title={data?.name}>
        <img src={image} alt='' />
      </Tooltip>
    </div>
  );

  const renderBig = () => (
    <div className={style['badge-element']}>
      <Tooltip title={data?.description}>
        <img src={image} alt='' />
      </Tooltip>
      <Typography.Text>{data?.name}</Typography.Text>
    </div>
  );

  if (mini) {
    return (
      <>
        {renderMini()}
        <Modal title={data?.name} open={open} onCancel={() => setOpen(false)} footer={null} width={300}>
          <div className={style['badge-element__modal']}>
            <img src={image} alt='' />
            <Typography.Text>{data?.description}</Typography.Text>
          </div>
        </Modal>
      </>
    );
  } else if (small) {
    return renderSmall();
  } else {
    return renderBig();
  }
};

BadgeElement.propTypes = {
  mini: PropTypes.bool,
  small: PropTypes.bool,
  data: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    imageUuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default BadgeElement;
