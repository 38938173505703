import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';
import { HiOutlineEmojiHappy } from 'react-icons/hi';

import css from './SubModals.module.scss';

const SubModals = ({ visible, changeVisible }) => {
  const { t } = useTranslation('subModals');

  return (
    <Modal open={visible} width={600} title={null} footer={null} onCancel={() => changeVisible()}>
      <div className={css['SubModals-container']}>
        <HiOutlineEmojiHappy color='var(--personalColor)' size={168} />
        <div className={css['SubModals-container-text']}>
          <p className={css['SubModals-container-text-title']}>{t('successTitle')}</p>
          <p className={css['SubModals-container-text-subtitle']}>{t('successSubtitle')}</p>
        </div>
        <Button onClick={() => changeVisible()}>{t('successOk')}</Button>
      </div>
    </Modal>
  );
};

export default SubModals;
