/* eslint-disable */
import React, { Component } from 'react';

import { TimePicker } from 'antd';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './HoursMinutesInput.module.scss';

const format = 'HH:mm';

class HoursMinutesInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    createTopic: PropTypes.bool,
    curTime: PropTypes.object,
    currentUser: PropTypes.object,
    dateIsBefore: PropTypes.bool,
    disabledHours: PropTypes.bool,
    ids: PropTypes.number,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    time: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      time: props.time,
    };
  }

  componentDidMount() {
    if (this.props.createTopic && this.props.time) {
      this.setState({ time: this.props.time });
      this.props.onChange(dayjs(this.props.time, 'HH:mm'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.state.time) {
      this.setState({ time: nextProps.time });
    }
  }

  handleChange = time => {
    const { onChange } = this.props;
    onChange(dayjs(time.$d, 'HH:mm'));
    this.setState({ time: time });
  };

  checkDisabledHours = () => {
    const curHH = Number(this.props.curTime.format('HH'));
    const disabledHours = [];
    let i = 0;
    while (i < curHH) {
      disabledHours.push(i);
      i++;
    }
    return disabledHours;
  };

  checkDisabledMinutes = () => {
    const curHH = Number(this.props.curTime.format('HH'));
    const inputHH = Number(dayjs(this.state.time, 'HH'));
    const curMM = Number(this.props.curTime.format('mm'));
    const disabledMinutes = [];
    let i = 0;
    if (inputHH === curHH) {
      while (i < curMM) {
        disabledMinutes.push(i);
        i++;
      }
    }
    return disabledMinutes;
  };

  render() {
    const { className, disabledHours, dateIsBefore } = this.props;
    return (
      <div className={classNames(className, css.HoursMinutesInput)} id={`${this.props.ids}clock`}>
        <TimePicker
          disabled={dateIsBefore}
          disabledTime={() => ({
            disabledHours: disabledHours ? this.checkDisabledHours : () => [],
            disabledMinutes: disabledHours ? this.checkDisabledMinutes : () => [],
          })}
          format={format}
          placeholder='00:00'
          value={this.state.time && dayjs(this.state.time, format)}
          onChange={this.handleChange}
          getPopupContainer={() => document.getElementById(`${this.props.ids}clock`)}
          className={classNames({
            [css['HoursMinutesTimePicker-modificate']]: this.props.modificate,
          })}
        />
      </div>
    );
  }
}

export default HoursMinutesInput;
