/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import ScheduleItemCustom from './ScheduleItemCustom';

// import reset from '../../image/reset-icon-copy.svg';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './ScheduleCustom.module.scss';

import add from '@/assets/images/add.svg';

const { TabPane } = Tabs;

class ScheduleCustom extends Component {
  static propTypes = {
    className: PropTypes.string,
    curTime: PropTypes.object,
    currentUser: PropTypes.object,
    error: PropTypes.any,
    handleAddSchedule: PropTypes.func,
    handleDeleteSchedule: PropTypes.func,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onDateChange: PropTypes.func,
    topic: PropTypes.object,
    value: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSchedule: '0',
    };
  }

  changeTabs = event => {
    this.setState({
      activeSchedule:
        event.action !== 'remove'
          ? event.targetKey
          : +this.state.activeSchedule < +event.targetKey
            ? this.state.activeSchedule
            : (+this.state.activeSchedule - 1).toString(),
    });
    if (event.action === 'remove') {
      this.handleDeleteSchedule(event.targetKey.toString());
    }
  };

  handleAddSchedule = () => {
    this.props.handleAddSchedule();
    this.setState({ activeSchedule: this.props.value.length.toString() });
  };

  handleDeleteSchedule = item => {
    this.props.handleDeleteSchedule(item);
    if (item.toString() === this.state.activeSchedule) {
      if (item === '0') {
        this.setState({
          activeSchedule: (+this.state.activeSchedule + 1).toString(),
        });
      } else {
        this.setState({
          activeSchedule: (+this.state.activeSchedule - 1).toString(),
        });
      }
    }
    if (this.state.activeSchedule === '0') {
      this.setState({ activeSchedule: '0' });
    }
  };

  render() {
    const tabPane = this.props.value.map((item, index) => {
      const dt = [];

      item.dates &&
        item.dates.map(i => {
          dt.push(dayjs(i).toDate());
        });

      const date = {
        dates: dt,
        startTime: item.startTime,
        endTime: item.endTime,
        place: item.place,
      };

      return {
        key: `${index}`,
        label: (
          <p>
            {this.props.t('schedule')} {index ? index + 1 : ''}
          </p>
        ),
        children: (
          <ScheduleItemCustom
            key={index}
            ids={index}
            currentUser={this.props.currentUser}
            error={this.props.error && this.props.error.indexOf(index) !== -1}
            value={date}
            name={this.props.name}
            onChange={this.props.onChange}
            onDateChange={this.props.onDateChange}
            topic={this.props.topic}
            curTime={this.props.curTime}
            disabledDays={this.props.disabledDays}
          />
        ),
      };
    });

    return (
      <div
        className={classNames({
          [css['AddSchedule-errorPlace']]: this.props.error,
        })}
      >
        <Tabs
          className={classNames(css.Tabs, {
            [css['AddSchedule-error']]: this.props.error && this.props.error.length,
          })}
          type={this.props.value.length > 1 ? 'editable-card' : 'line'}
          onEdit={(targetKey, action) => this.changeTabs({ targetKey, action })}
          onTabClick={key => this.changeTabs({ targetKey: key })}
          activeKey={this.state.activeSchedule}
          hideAdd
          items={tabPane}
        />
        <div
          className={classNames(css.AddSchedule, {
            [css['AddSchedule-right']]: this.props.value.length > 1,
          })}
          onClick={this.handleAddSchedule}
        >
          {this.props.value.length <= 1 ? this.props.t('additionalSchedule') : ''}
          <img src={add} alt='add' />
        </div>
      </div>
    );
  }
}

export default withTranslation('scheduleCustom')(ScheduleCustom);
