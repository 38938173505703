/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

import { GET_USER_BADGE_FAIL, GET_USER_BADGE_START, GET_USER_BADGE_SUCCESS } from '@/store/badge/constants';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getUserBadge = page => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/reward/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_BADGE_START, GET_USER_BADGE_SUCCESS, GET_USER_BADGE_FAIL],
});
