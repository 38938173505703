import { Link } from 'react-router-dom';

import { Avatar } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import styles from './News.module.scss';

const NewsUserElement = ({ newsData, userData }) => {
  const [avatar] = useLocalFileByUUID(userData.localPhotoUuid, { defaultImg: PortraitPlaceholder });

  return (
    <div className={styles.user}>
      <div className={styles.user__data}>
        <Avatar src={avatar} size='large' />
        <Link className={styles.user__name} to={'/collegues/' + userData.userId}>
          {userData.firstName} {userData.lastName}
        </Link>
      </div>
      <Link className={styles.user__newsName} to={'/news/' + newsData.newsId}>
        {newsData.newsName}
      </Link>
    </div>
  );
};

NewsUserElement.propTypes = {
  newsData: PropTypes.shape({
    newsId: PropTypes.number.isRequired,
    newsName: PropTypes.string.isRequired,
  }),
  userData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
  }),
};

export default NewsUserElement;
