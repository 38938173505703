import React from 'react';

import { Alert } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import Resources from '@/resources';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

// eslint-disable-next-line
export default ({ className, message = '', type, showIcon = true, description, ...props }) => (
  <Alert
    className={cn('alert', { 'alert_without-message': !message }, className)}
    message={message}
    description={description}
    type={type}
    showIcon={showIcon}
    closeText={
      // eslint-disable-next-line
      type === 'warning' ? <Resources.icons.WarningClose /> : undefined
    }
    {...props}
  />
);

Alert.propTypes = {
  type: PropTypes.string,
  closeText: PropTypes.element,
  message: PropTypes.element,
  description: PropTypes.element,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};
