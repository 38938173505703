import {
  AWAIT_MENTOR_FAIL,
  AWAIT_MENTOR_START,
  AWAIT_MENTOR_SUCCESS,
  CLEAR_COMMENTS_GRATITUDE_STATE,
  CLEAR_MENTORS_FILTERS,
  DELETE_MENTOR_COMMENT_FAIL,
  DELETE_MENTOR_COMMENT_START,
  DELETE_MENTOR_COMMENT_SUCCESS,
  GET_COMPETENCE_MENTORS_FAIL,
  GET_COMPETENCE_MENTORS_START,
  GET_COMPETENCE_MENTORS_SUCCESS,
  GET_MENTOR_COMMENTS_COUNT_FAIL,
  GET_MENTOR_COMMENTS_COUNT_START,
  GET_MENTOR_COMMENTS_COUNT_SUCCESS,
  GET_MENTOR_COMMENTS_FAIL,
  GET_MENTOR_COMMENTS_START,
  GET_MENTOR_COMMENTS_SUCCESS,
  GET_MENTOR_GRATITUDE_FAIL,
  GET_MENTOR_GRATITUDE_START,
  GET_MENTOR_GRATITUDE_SUCCESS,
  GET_MENTOR_ID_FAIL,
  GET_MENTOR_ID_START,
  GET_MENTOR_ID_SUCCESS,
  GET_MENTORS_COMPANY_FAIL,
  GET_MENTORS_COMPANY_START,
  GET_MENTORS_COMPANY_SUCCESS,
  GET_MENTORS_DEPARTMENT_FAIL,
  GET_MENTORS_DEPARTMENT_START,
  GET_MENTORS_DEPARTMENT_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_FUNCTION_FAIL,
  GET_MENTORS_FUNCTION_START,
  GET_MENTORS_FUNCTION_SUCCESS,
  GET_MENTORS_START,
  GET_MENTORS_SUCCESS,
  GET_SETTINGS_LIMIT_MENTORS_FAIL,
  GET_SETTINGS_LIMIT_MENTORS_START,
  GET_SETTINGS_LIMIT_MENTORS_SUCCESS,
  POST_MENTOR_COMMENT_FAIL,
  POST_MENTOR_COMMENT_START,
  POST_MENTOR_COMMENT_SUCCESS,
  PUT_MENTOR_COMMENT_FAIL,
  PUT_MENTOR_COMMENT_START,
  PUT_MENTOR_COMMENT_SUCCESS,
  REQUEST_MENTEE_FAIL,
  REQUEST_MENTEE_START,
  REQUEST_MENTEE_SUCCESS,
  RESTORE_MENTOR_COMMENT_FAIL,
  RESTORE_MENTOR_COMMENT_START,
  RESTORE_MENTOR_COMMENT_SUCCESS,
  SAVE_MENTORS_FILTERS,
  UPDATE_MENTOR_GRATITUDE_FAIL,
  UPDATE_MENTOR_GRATITUDE_START,
  UPDATE_MENTOR_GRATITUDE_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getMentors = (params, page, size, sort, signal, onSuccess, onError, hasChangedFilters) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor${getQueryString({ page, size, sort })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [GET_MENTORS_START, GET_MENTORS_SUCCESS, GET_MENTORS_FAIL],
  onSuccess,
  onError,
  signal,
  hasChangedFilters,
});

export const getMentorsCompany = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/company${getQueryString({})}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_COMPANY_START, GET_MENTORS_COMPANY_SUCCESS, GET_MENTORS_COMPANY_FAIL],
  onSuccess,
  onError,
});

export const getMentorsCompetence = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/competence`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_MENTORS_START, GET_COMPETENCE_MENTORS_SUCCESS, GET_COMPETENCE_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const getMentorsDepartments = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/department`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_DEPARTMENT_START, GET_MENTORS_DEPARTMENT_SUCCESS, GET_MENTORS_DEPARTMENT_FAIL],
  onSuccess,
  onError,
});

export const getMentorsFunctions = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/function`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTORS_FUNCTION_START, GET_MENTORS_FUNCTION_SUCCESS, GET_MENTORS_FUNCTION_FAIL],
  onSuccess,
  onError,
});

export const requestMentee = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentee`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [REQUEST_MENTEE_START, REQUEST_MENTEE_SUCCESS, REQUEST_MENTEE_FAIL],
  onSuccess,
  onError,
});

export const getMentorID = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/active/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTOR_ID_START, GET_MENTOR_ID_SUCCESS, GET_MENTOR_ID_FAIL],
  onSuccess,
  onError,
});

export const getMentorComments = (mentorId, page, size, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/comment${getQueryString({ mentorId, page, size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTOR_COMMENTS_START, GET_MENTOR_COMMENTS_SUCCESS, GET_MENTOR_COMMENTS_FAIL],
  onSuccess,
  onError,
});

export const getMentorCommentsCount = (mentorId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/comment/count${getQueryString({ mentorId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTOR_COMMENTS_COUNT_START, GET_MENTOR_COMMENTS_COUNT_SUCCESS, GET_MENTOR_COMMENTS_COUNT_FAIL],
  onSuccess,
  onError,
});

export const createMentorComment = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/mentor/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [POST_MENTOR_COMMENT_START, POST_MENTOR_COMMENT_SUCCESS, POST_MENTOR_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const updateMentorComment = (commentId, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/comment/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [PUT_MENTOR_COMMENT_START, PUT_MENTOR_COMMENT_SUCCESS, PUT_MENTOR_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const deleteMentorComment = (commentId, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/mentor/comment/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_MENTOR_COMMENT_START, DELETE_MENTOR_COMMENT_SUCCESS, DELETE_MENTOR_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const restoreMentorComment = (commentId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/comment/restore/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [RESTORE_MENTOR_COMMENT_START, RESTORE_MENTOR_COMMENT_SUCCESS, RESTORE_MENTOR_COMMENT_FAIL],
  onSuccess,
  onError,
});

export const awaitMentor = (params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentee/status/wait`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [AWAIT_MENTOR_START, AWAIT_MENTOR_SUCCESS, AWAIT_MENTOR_FAIL],
  onSuccess,
  onError,
});

export const getMentorGratitude = (mentorId, page, size, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/gratitude${getQueryString({ mentorId, page, size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_MENTOR_GRATITUDE_START, GET_MENTOR_GRATITUDE_SUCCESS, GET_MENTOR_GRATITUDE_FAIL],
  onSuccess,
  onError,
});

export const updateMentorGratitude = (commentId, params, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/mentor/gratitude/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [UPDATE_MENTOR_GRATITUDE_START, UPDATE_MENTOR_GRATITUDE_SUCCESS, UPDATE_MENTOR_GRATITUDE_FAIL],
  onSuccess,
  onError,
});

export const getLimitSettings = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/mentor/company/settings`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SETTINGS_LIMIT_MENTORS_START, GET_SETTINGS_LIMIT_MENTORS_SUCCESS, GET_SETTINGS_LIMIT_MENTORS_FAIL],
  onSuccess,
  onError,
});

export const clearCommentsGratitudeState = () => ({
  type: CLEAR_COMMENTS_GRATITUDE_STATE,
});

export const saveMentorsFilters = value => ({
  type: SAVE_MENTORS_FILTERS,
  value,
});

export const clearMentorsFilters = value => ({
  type: CLEAR_MENTORS_FILTERS,
  value,
});

// ${getQueryString({})}
