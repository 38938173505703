// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-Collegues-views-Views-module__ql-picker-Kjgp58.user-src-pages-Collegues-views-Views-module__ql-font-dN7GTR .user-src-pages-Collegues-views-Views-module__ql-picker-label-HNszZc[data-value="Montserrat"]:before, .user-src-pages-Collegues-views-Views-module__ql-picker-Kjgp58.user-src-pages-Collegues-views-Views-module__ql-font-dN7GTR .user-src-pages-Collegues-views-Views-module__ql-picker-item-NforDN[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-Collegues-views-Views-module__ql-font-Montserrat-u9NTaF {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-Collegues-views-Views-module__Views-tableWrapper-l1wLLi {
  background-color: #fff;
}

.user-src-pages-Collegues-views-Views-module__Badge-KwcOWJ {
  display: flex;
}

.user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En:nth-child(2) img {
  left: -7px;
}

.user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En:nth-child(3) img {
  left: -14px;
}

.user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En:nth-child(4) img {
  left: -21px;
}

.user-src-pages-Collegues-views-Views-module__Badge__pointer-KhmHsQ {
  cursor: pointer;
}

.user-src-pages-Collegues-views-Views-module__Badge__count-A7_xAD {
  border: 1px solid var(--personalColor);
  color: var(--personalColor);
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  left: -20px;
}

.user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En {
  width: 30px;
  height: 30px;
  position: relative;
}

.user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En img {
  z-index: 2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/Collegues/views/Views.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;AACxB;;AAQA;EALE,aAAa;AACf;;AAOA;EAJE,UAAU;AACZ;;AAMA;EAHE,WAAW;AACb;;AAKA;EAFE,WAAW;AACb;;AAIA;EADE,eAAe;AACjB;;AAGA;EAAE,sCAAsC;EACtC,2BAA2B;EAC3B,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AACA;EAEE,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.Views-tableWrapper {\n  background-color: white;\n}\n\n.Badge {\n  display: flex;\n}\n.Badge__icon:nth-child(2) img {\n  left: -7px;\n}\n.Badge__icon:nth-child(3) img {\n  left: -14px;\n}\n.Badge__icon:nth-child(4) img {\n  left: -21px;\n}\n.Badge__pointer {\n  cursor: pointer;\n}\n.Badge__count {\n  border: 1px solid var(--personalColor);\n  border-radius: 50%;\n  position: absolute;\n  left: -20px;\n  color: var(--personalColor);\n  width: 30px;\n  height: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n}\n.Badge__icon {\n  width: 30px;\n  height: 30px;\n  position: relative;\n}\n.Badge__icon img {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 2;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-Collegues-views-Views-module__ql-picker-Kjgp58`,
	"qlPicker": `user-src-pages-Collegues-views-Views-module__ql-picker-Kjgp58`,
	"ql-font": `user-src-pages-Collegues-views-Views-module__ql-font-dN7GTR`,
	"qlFont": `user-src-pages-Collegues-views-Views-module__ql-font-dN7GTR`,
	"ql-picker-label": `user-src-pages-Collegues-views-Views-module__ql-picker-label-HNszZc`,
	"qlPickerLabel": `user-src-pages-Collegues-views-Views-module__ql-picker-label-HNszZc`,
	"ql-picker-item": `user-src-pages-Collegues-views-Views-module__ql-picker-item-NforDN`,
	"qlPickerItem": `user-src-pages-Collegues-views-Views-module__ql-picker-item-NforDN`,
	"ql-font-Montserrat": `user-src-pages-Collegues-views-Views-module__ql-font-Montserrat-u9NTaF`,
	"qlFontMontserrat": `user-src-pages-Collegues-views-Views-module__ql-font-Montserrat-u9NTaF`,
	"Views-tableWrapper": `user-src-pages-Collegues-views-Views-module__Views-tableWrapper-l1wLLi`,
	"viewsTableWrapper": `user-src-pages-Collegues-views-Views-module__Views-tableWrapper-l1wLLi`,
	"Badge": `user-src-pages-Collegues-views-Views-module__Badge-KwcOWJ`,
	"badge": `user-src-pages-Collegues-views-Views-module__Badge-KwcOWJ`,
	"Badge__icon": `user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En`,
	"badgeIcon": `user-src-pages-Collegues-views-Views-module__Badge__icon-YAp5En`,
	"Badge__pointer": `user-src-pages-Collegues-views-Views-module__Badge__pointer-KhmHsQ`,
	"badgePointer": `user-src-pages-Collegues-views-Views-module__Badge__pointer-KhmHsQ`,
	"Badge__count": `user-src-pages-Collegues-views-Views-module__Badge__count-A7_xAD`,
	"badgeCount": `user-src-pages-Collegues-views-Views-module__Badge__count-A7_xAD`
};
export default ___CSS_LOADER_EXPORT___;
