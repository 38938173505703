import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectPokaSkills } from '@/store/poka/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';

import { Tag, Typography } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

import PokaComments from './components/PokaComments';
import { renderIcon, setColorTag } from './constants';

import IconPokaStatus from '@shared/components/IconPokaStatus';
import { TAG_POKA_STATUS } from '@shared/components/PieMars/constants';
import { PlanStatus } from '@shared/constants/plan';

import PropTypes from 'prop-types';

import style from './style.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const PokaHeader = ({ topic }) => {
  const { t } = useTranslation('pokaHeader');
  const { studyPlanId } = useSelector(selectStudyPlan);
  const { assessor } = useSelector(selectPokaSkills);

  const [commentsOpen, setCommentsOpen] = useState(false);

  const commentsStatuses = [FINISHED, PlanStatus.REJECTED_BY_MANAGER];

  return (
    <div className={style['poka-header']}>
      <Typography.Title>{topic?.name}</Typography.Title>
      <div className={style['poka-header__info']}>
        {assessor?.pokaPlanStatus?.value && assessor?.pokaPlanStatus?.label && (
          <Tag className={style['poka-header__tag']} color={TAG_POKA_STATUS[assessor.pokaPlanStatus.value]}>
            <IconPokaStatus studyPlanStatus={studyPlanId?.[0].status} />
            {assessor.pokaPlanStatus.label}
          </Tag>
        )}
        {studyPlanId?.[0]?.status && commentsStatuses.includes(studyPlanId[0].status) && (
          <CommentOutlined className={style['poka-header__icon']} onClick={() => setCommentsOpen(true)} />
        )}
      </div>
      <PokaComments open={commentsOpen} onCancel={() => setCommentsOpen(false)} />
    </div>
  );
};

PokaHeader.propTypes = {
  topic: PropTypes.object,
};

export default PokaHeader;
