/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Tooltip } from 'antd';
import useIsMobileClass from '@/components/isMobileClass';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Tag.module.scss';

export default class Tag extends Component {
  static propTypes = {
    active: PropTypes.bool,
    activeTag: PropTypes.bool,
    filterCatalog: PropTypes.bool,
    isAnother: PropTypes.bool,
    noMargin: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    skills: PropTypes.bool,
    tag: PropTypes.object,
    sliceCount: PropTypes.number, // Если мы хотим обрезать строку и показать Tooltip
  };

  static defaultProps = {
    onClick: () => {},
    tag: {},
    sliceCount: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: useIsMobileClass(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ isMobile: useIsMobileClass() });
  }

  handleTagDelete = event => {
    event.stopPropagation();
    const { tag, onDelete } = this.props;
    onDelete(event, tag.id);
  };

  handleTagClick = event => {
    const { tag, onClick } = this.props;
    onClick(event, tag);
  };

  getTagName = name => {
    let returnName = this.state.isMobile ? `${name.substr(0, 20)}` : name;

    if (!this.props.sliceCount || this.props.sliceCount === 0) {
      if (returnName.length < name.length) {
        return (returnName += '...');
      } else {
        return returnName;
      }
    }

    let sliced = returnName.slice(0, this.props.sliceCount);
    if (sliced.length < returnName.length || returnName.length < name.length) {
      sliced += '...';
    }

    return sliced;
  };

  render() {
    const deleteButton = this.props.onDelete ? (
      <button className={css['delete-button']} onClick={this.handleTagDelete}>
        <svg xmlns='http://www.w3.org/2000/svg' width='9' height='8' viewBox='0 0 18 17'>
          <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='currentColor' fill='none' />
        </svg>
      </button>
    ) : null;

    return (
      <span>
        <div
          className={classNames(
            css.Tag,
            { [css.active]: this.props.active },
            { [css.noMargin]: this.props.noMargin },
            { [css.FilterCatalog]: this.props.filterCatalog },
            { [css.ActiveTag]: this.props.activeTag },
            { [css.Skills]: this.props.skills },
            { [css.noHover]: this.props.noHover }
          )}
          onClick={this.handleTagClick}
        >
          {!this.props.isAnother && deleteButton}

          {Boolean(this.props.sliceCount && this.props.sliceCount > 0) ? (
            <Tooltip title={this.props.tag.name}>
              <span>{this.getTagName(this.props.tag.name)}</span>
            </Tooltip>
          ) : (
            <span>{this.getTagName(this.props.tag.name)}</span>
          )}
        </div>
      </span>
    );
  }
}
