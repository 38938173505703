/* Тут нужен рефакторинг. Модалочки и их логика перенесена из старого кода. */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { freeBook, getAddressInUse } from '@/store/books/actions';
import { eventRequestConfirmUI, eventRequestFinish, eventRequestRefuseUI } from '@/store/course/actions';
import { getUserIncome2 as getIncome } from '@/store/income/actions';
import {
  createStudyPlan,
  deleteStudyPlan,
  sendNotificationStudyPlanFinished,
  updateStudyPlanDate,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import { showSendReviewModal } from '@/store/topics/actions';
import { getUserById } from '@/store/users/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import { Button, message } from 'antd';
import CalendarModal from '@/pages/CalendarModal';
import css from '@/pages/Planning/Planning.module.scss';
import { CancelWithPenaltyModal } from '@/components/CancelWithPenaltyModal';
import { ResultCancelModal } from '@/components/CancelWithPenaltyModal/ResultCancelReg';
import Modal from '@/components/Modal';

import {
  getWithoutSchedule,
  isBook,
  isConference,
  isConfOrAllCourse,
  isCorpCourseWithOrWithoutApprove,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourse,
  isCorpOrExtCourseOrConf,
} from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { FINISHED_TOPIC } from '@/constants/income-types';
import { FINISHED } from '@/constants/study-plan-statuses';

const BaseModal = ({ visible = true, onOk, onCancel, title, okText, ...props }) => {
  return (
    <Modal
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      contentClassName={classNames(css['App-card-item'])}
      title={title}
      okText={okText}
      footerBtnOrange
      cardOn
      manualCancel
      isPlanningPageAction
      classNameFooter={css['App-content-modal-footer']}
      {...props}
    />
  );
};

const CompleteModal = props => {
  const { t } = useTranslation('planingModals');

  if (isBook(props.topic?.type?.id) && props.topic.qiwiLibrary && props.bookAddress) {
    return (
      <>
        <BaseModal title={t('endMaterial')} okText={t('end')} {...props}></BaseModal>
        <Modal
          open={props.confirmModalVisible}
          title={t('returnBook', { address: props.bookAddress })}
          contentClassName={classNames(css['App-card-item'])}
          okText={t('returnBookYes', { address: props.bookAddress })}
          cancelText={t('returnBookNo')}
          onOk={props.completeBook}
          onCancel={props.hideConfirmBookModal}
          footerBtnOrange
          isPlanningPageAction
          cardOn
          manualCancel
        />
      </>
    );
  }

  return <BaseModal title={t('endMaterial')} okText={t('end')} {...props} />;
};

const DidNotGoModal = props => {
  const { t } = useTranslation('planingModals');
  return (
    <BaseModal title={t('confirmReject')} okText={t('submit')} {...props}>
      <p>{t('rejected')}</p>
    </BaseModal>
  );
};

const RemovePlanModal = props => {
  const { t } = useTranslation('planingModals');

  if (isBook(props.topic?.type?.id) && props.topic.qiwiLibrary && props.bookAddress) {
    return (
      <>
        <BaseModal title={t('deleted')} okText={t('delete')} {...props} />;
        <Modal
          open={props.confirmModalVisible}
          title={t('returnBook', { address: props.bookAddress })}
          contentClassName={classNames(css['App-card-item'])}
          okText={t('returnBookYes', { address: props.bookAddress })}
          cancelText={t('returnBookNo')}
          onOk={props.completeBook}
          onCancel={props.hideConfirmBookModal}
          footerBtnOrange
          isPlanningPageAction
          cardOn
          manualCancel
        />
      </>
    );
  }
  return <BaseModal title={t('deleted')} okText={t('delete')} {...props} />;
};

const WillGoModal = props => {
  const { t } = useTranslation('planingModals');
  return <BaseModal title={t('confirmGo')} okText={t('submit')} {...props} />;
};

const CantGoModal = ({ topicTypeId, ...props }) => {
  const { t } = useTranslation('planingModals');
  const okText = isConference(topicTypeId) ? t('submit') : t('ok');

  const title = isConference(topicTypeId) ? t('confirmReject') : t('redirectEvent');

  const text = isConference(topicTypeId) ? (
    t('rejected')
  ) : (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: t('description') }} />
  );

  const width = isConference(topicTypeId) ? 520 : 560;

  return (
    <BaseModal title={title} okText={okText} width={width} {...props}>
      <p>{text}</p>
    </BaseModal>
  );
};

const RescheduleModal = ({ plan, onOk, onCancel }) => {
  const { t } = useTranslation('planingModals');
  return (
    <CalendarModal
      open
      planInfo={plan}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('reschedule')}
      title={t('choosePlan')}
      planningTopicId={plan.topic.id}
      rest={{ planId: plan.id }}
      topicInfo={plan.topic}
      eventTypeId={plan.topic.type.id}
      headerFixToModal
      hasEvents={plan.topic && plan.topic.futureEvents}
      selectMonthEvents={isCorpOrExtCourse(plan.topic.type.id)}
    />
  );
};

const EventRequestErrorModal = ({ onOk }) => {
  const { t } = useTranslation('planingModals');
  return (
    <Modal
      open
      onCancel={onOk}
      width={430}
      hideFooter
      title={t('status')}
      onOk={onOk}
      okText={t('ok')}
      footerBtnOrange
      isPlanningPageAction
      cardOn
      manualCancel
    >
      <div className={css['Planning-topic-event-text']}>{t('info')}</div>
      <Button onClick={() => onOk()} type='primary' className={css['Modal-mailOk']} size='large'>
        {t('ok')}
      </Button>
    </Modal>
  );
};
// eslint-disable-next-line
const EventRequestErrorMentionModal = ({ onOk, visible, ...props }) => {
  const { t } = useTranslation('planingModals');
  return (
    <Modal
      {...props}
      open
      onCancel={onOk}
      width={470}
      hideFooter
      title={t('feedback')}
      onOk={onOk}
      okText={t('ok')}
      footerBtnOrange
      isPlanningPageAction
      cardOn
      manualCancel
    >
      <div className={css['Planning-topic-event-text']}>{t('comment')}</div>
      <Button onClick={() => onOk()} type='primary' className={css['Modal-mailOk']} size='large'>
        {t('gotIt')}
      </Button>
    </Modal>
  );
};

const EventRequestConfirmModal = ({ onOk, refuse, ...props }) => {
  const { t } = useTranslation('planingModals');
  const title = refuse ? t('youRejected') : t('youApproved');
  return (
    <Modal
      {...props}
      open
      onCancel={onOk}
      width={430}
      hideFooter
      title={title}
      onOk={onOk}
      okText='OK'
      footerBtnOrange
      isPlanningPageAction
      cardOn
      manualCancel
    >
      <Button onClick={() => onOk()} type='primary' className={css['Modal-mailOk']} size='large'>
        {t('ok')}
      </Button>
    </Modal>
  );
};

const EventRequestNoVisitModal = ({ onOk, ...props }) => {
  const { t } = useTranslation('planingModals');
  return (
    <Modal
      {...props}
      open
      onCancel={onOk}
      width={450}
      hideFooter
      title={t('ok')}
      onOk={onOk}
      okText={t('ok')}
      footerBtnOrange
      isPlanningPageAction
      cardOn
      manualCancel
    >
      <Button onClick={() => onOk()} type='primary' className={css['Modal-mailOk']} size='large'>
        {t('ok')}
      </Button>
    </Modal>
  );
};

export const RescheduleConfirmModal = ({ plan, onOk, onCancel, ...props }) => {
  const { t } = useTranslation('planingModals');
  return (
    <Modal
      {...props}
      open
      onOk={onOk}
      onCancel={onCancel}
      contentClassName={classNames(css['App-card-item'])}
      title={t('rescheduleCourse')}
      okText={t('reschedule')}
      footerBtnOrange
      cardOn
      manualCancel
      isPlanningPageAction
      zIndex={4100}
    >
      <p style={{ textAlign: 'center' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: t('rescheduleYear') }} />
        {plan.status !== 'APPROVED_BY_MANAGER' && (
          // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: t('description3') }} />
        )}
      </p>
    </Modal>
  );
};

export const Modals = ({ modal, onConfirm, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studyPlanState = useSelector(selectStudyPlan);
  const currentUser = useSelector(selectCurrentUser);

  const [rescheduleConfirmModal, setRescheduleConfirmModal] = useState();
  const [loading, setLoading] = useState(false);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [bookAddress, setBookAddress] = useState('');

  const [penaltyVision, setPenaltyVision] = useState(false);
  const [confirmPenaltyModal, setConfirmPenaltyModal] = useState(false);
  const [resultVision, setResultVision] = useState(false);

  const { action, material, blockName } = modal;

  const planId = _get(material, 'id');
  const topic = _get(material, 'topic');
  const topicId = _get(topic, 'id');
  const topicTypeId = _get(topic, 'type.id');

  dayjs.extend(isSameOrBefore);

  const checkShowPenalty =
    isConfOrAllCourse(material?.topic?.type?.id) &&
    material?.event &&
    dayjs(material.event.deadlineCancellation).startOf('day').isSameOrBefore(dayjs().startOf('day')) &&
    material.status !== 'WAITING_BY_USER';

  const initialCostPenalty = material?.event?.cancellationPenalty;

  useEffect(() => {
    if (isBook(topic?.type?.id) && topic.qiwiLibrary) {
      dispatch(
        getAddressInUse(
          topic.id,
          res => {
            if (res?.address) {
              setBookAddress(res.address);
            }
          },
          err => message.error(err)
        )
      );
    }
  }, [isBook, topic?.type?.id, topic?.qiwiLibrary]);

  const _onConfirm = () => onConfirm(blockName, action);

  const dispatchGetIncome = ({ topicId, onSuccess }) => {
    if (!currentUser?.domainCompany?.hasCoins) return;

    dispatch(
      getIncome({
        userId: currentUser.id,
        topicId,
        bonus: 'TOPIC_COMPLETE',
        incomeType: FINISHED_TOPIC,
        onSuccess: onSuccess ? onSuccess : null,
      })
    );
  };

  const setPreview = () => {
    if (!isCorpOrExtCourseOrConf(topicTypeId) || !isCorporateCourseWithoutApprove(topicTypeId)) {
      _onConfirm();
      dispatch(showSendReviewModal(topicId, { studyPlanId: planId }));
    } else {
      onClose();
      dispatchGetIncome({
        topicId,
        onSuccess: () => dispatch(getUserById(false, currentUser.id)),
      });

      dispatch(
        showSendReviewModal(topicId, {
          studyPlanId: planId,
          currentUserId: currentUser.id,
          activeMonth: dayjs().format('YYYY-MM-DD'),
          viewPlan: !studyPlanState.view,
          callback: () => _onConfirm(),
        })
      );
    }
  };

  const showConfirmBookModal = () => setConfirmModalVisible(true);
  const hideConfirmBookModal = () => {
    onClose();
    setConfirmModalVisible(false);
  };

  // вернуть книгу
  const completeBook = type => {
    const body = { topicId: topic.id, userIds: [currentUser.id] };

    dispatch(
      freeBook(
        body,
        () => {
          switch (type) {
            case 'removeFromPlan':
              dispatch(
                deleteStudyPlan(planId, () => {
                  setLoading(false);
                  _onConfirm();
                })
              );
              break;

            case 'complete':
              // TODO: COMPLETE MATERIAL!
              dispatch(
                updateStudyPlanStatus(planId, FINISHED, () => {
                  dispatch(sendNotificationStudyPlanFinished());
                  setPreview();
                  dispatchGetIncome({ topicId });

                  dispatch(getUserById(false, currentUser.id));
                })
              );
              break;

            default:
              break;
          }

          hideConfirmBookModal();
          setBookAddress('');
        },
        err => message.error(err)
      )
    );
  };

  const onOkPenalty = () => {
    setConfirmPenaltyModal(true);
    setPenaltyVision(false);
    setResultVision(true);
  };

  const onOk = (_topicId, planningMonths = [], comment, withoutSchedule) => {
    setLoading(true);
    switch (action) {
      case 'complete':
        if (topic.type.approved) {
          setPreview();
        } else {
          /* prettier-ignore */
          if (isBook(topic?.type?.id) && topic.qiwiLibrary && bookAddress){
            showConfirmBookModal();
            return;
          }
          // TODO: COMPLETE MATERIAL!
          dispatch(
            updateStudyPlanStatus(planId, FINISHED, () => {
              dispatch(sendNotificationStudyPlanFinished());
              setPreview();
              dispatchGetIncome({ topicId });

              dispatch(getUserById(false, currentUser.id));
            })
          );
        }
        break;
      case 'didNotGo':
        dispatch(eventRequestFinish(planId, false, _onConfirm));
        break;
      case 'removeFromPlan':
        if (isBook(topic?.type?.id) && topic.qiwiLibrary && bookAddress) {
          showConfirmBookModal();
          return;
        }

        dispatch(
          deleteStudyPlan(planId, () => {
            setLoading(false);
            _onConfirm();
            onClose();
          })
        );
        break;
      case 'iWillGo':
        dispatch(
          eventRequestConfirmUI(planId, () => {
            setLoading(false);
            _onConfirm();
          })
        );
        break;
      case 'iCantGo':
        dispatch(eventRequestRefuseUI(planId, () => _onConfirm()));
        break;
      case 'reschedule':
        const isStatusAvailable =
          material &&
          (material.status === 'APPROVED' ||
            material.status === 'APPROVED_BY_MANAGER' ||
            material.status === 'REJECTED_BY_USER' ||
            material.status === 'APPROVED_BY_USER' ||
            material.status === 'WAITING_BY_USER' ||
            material.status === 'ON_REGISTRATION');

        const isYearChanged = material && dayjs(planningMonths[0], 'YYYY') !== dayjs(material.startDate, 'YYYY');

        const isNeededTopic = material && isCorpOrExtCourse(topicTypeId);

        if ((blockName === 'planned' || blockName === 'isFilter') && !confirmPenaltyModal && checkShowPenalty) {
          setPenaltyVision(true);
          break;
        }

        if (isStatusAvailable && isYearChanged && isNeededTopic) {
          setLoading(false);
          setRescheduleConfirmModal({
            topicId,
            planningMonths,
            comment,
            withoutSchedule,
            planId,
            plan: material,
          });
        } else {
          if (planningMonths[0]) {
            dispatch(
              updateStudyPlanDate(
                planId,
                planningMonths[0],
                comment,
                () => _onConfirm(),
                getWithoutSchedule(
                  topicTypeId,
                  withoutSchedule || (isCorpCourseWithOrWithoutApprove(topicTypeId) && topic.eventCount === 0)
                )
              )
            );
          }
          for (let i = 1; i < planningMonths.length; i++) {
            dispatch(
              createStudyPlan(
                currentUser.id,
                topicId,
                planningMonths[i],
                comment,
                () => _onConfirm(),
                false,
                getWithoutSchedule(topicTypeId, !!withoutSchedule)
              )
            );
          }
        }
        break;
      case 'eventRequestNoVisit':
      case 'eventRequestConfirm':
      case 'eventRequestRefuse':
      case 'eventRequestErrorMention':
      case 'eventRequestError':
        navigate('/planning');
        onClose();
        break;

      default:
        onClose();
        break;
    }
  };

  const onConfirmReschedule = () => {
    setLoading(true);
    if (rescheduleConfirmModal.planningMonths[0]) {
      dispatch(
        updateStudyPlanDate(
          rescheduleConfirmModal.planId,
          rescheduleConfirmModal.planningMonths[0],
          rescheduleConfirmModal.comment,
          () => {
            setRescheduleConfirmModal(null);
            _onConfirm();
          },
          getWithoutSchedule(
            topicTypeId,
            rescheduleConfirmModal.withoutSchedule ||
              (isCorpCourseWithOrWithoutApprove(topicTypeId) && !topic.eventCount)
          )
        )
      );
    }

    for (let i = 1; i < rescheduleConfirmModal.planningMonths.length; i++) {
      dispatch(
        createStudyPlan(
          currentUser.id,
          rescheduleConfirmModal.topicId,
          rescheduleConfirmModal.planningMonths[i],
          rescheduleConfirmModal.comment,
          () => {
            setRescheduleConfirmModal(null);
            _onConfirm();
          },
          false,
          getWithoutSchedule(topicTypeId, rescheduleConfirmModal.withoutSchedule)
        )
      );
    }
  };

  switch (action) {
    case 'complete':
      if (topic.type.approved) {
        setPreview();
        return null;
      }

      return (
        <CompleteModal
          loading={loading}
          onOk={onOk}
          onCancel={onClose}
          topic={topic}
          completeBook={() => completeBook('complete')}
          confirmModalVisible={confirmModalVisible}
          hideConfirmBookModal={hideConfirmBookModal}
          bookAddress={bookAddress}
        />
      );
    case 'didNotGo':
      return <DidNotGoModal loading={loading} onOk={onOk} onCancel={onClose} />;

    case 'removeFromPlan':
      return (
        <>
          {penaltyVision && (
            <CancelWithPenaltyModal
              material={material}
              currentUser={currentUser}
              open={penaltyVision}
              onCancel={() => setPenaltyVision(false)}
              onOkPenalty={onOkPenalty}
              initialCostPenalty={initialCostPenalty}
            />
          )}
          {resultVision && (
            <ResultCancelModal material={material} open={resultVision} onCancel={() => setResultVision(false)} />
          )}
          <RemovePlanModal
            topic={topic}
            bookAddress={bookAddress}
            loading={loading}
            onOk={onOk}
            onCancel={onClose}
            confirmModalVisible={confirmModalVisible}
            hideConfirmBookModal={hideConfirmBookModal}
            completeBook={() => completeBook('removeFromPlan')}
          />
        </>
      );

    case 'iWillGo':
      return <WillGoModal loading={loading} onOk={onOk} onCancel={onClose} />;

    case 'iCantGo':
      if (
        (blockName === 'waitAction' || blockName === 'isFilter' || blockName === 'planned') &&
        !confirmPenaltyModal &&
        checkShowPenalty &&
        !penaltyVision
      ) {
        setPenaltyVision(true);
      }
      return (
        <>
          {!penaltyVision && !checkShowPenalty && (
            <CantGoModal topicTypeId={topicTypeId} loading={loading} onOk={onOk} onCancel={onClose} />
          )}
          {penaltyVision && (
            <CancelWithPenaltyModal
              material={material}
              currentUser={currentUser}
              open={penaltyVision}
              onCancel={() => {
                onClose();
                setPenaltyVision(false);
              }}
              onOkPenalty={onOkPenalty}
              initialCostPenalty={initialCostPenalty}
            />
          )}
          {resultVision && (
            <ResultCancelModal
              material={material}
              open={resultVision}
              onAccept={() => {
                onClose();
                setResultVision(false);
                _onConfirm();
              }}
              onFinish={() => {
                onClose();
                _onConfirm();
              }}
            />
          )}
        </>
      );

    case 'reschedule':
      return (
        <>
          <RescheduleModal loading={loading} plan={material} onOk={onOk} onCancel={onClose} />
          {penaltyVision && (
            <CancelWithPenaltyModal
              material={material}
              currentUser={currentUser}
              open={penaltyVision}
              onCancel={() => setPenaltyVision(false)}
              onOkPenalty={onOkPenalty}
              initialCostPenalty={initialCostPenalty}
            />
          )}
          {resultVision && (
            <ResultCancelModal material={material} open={resultVision} onCancel={() => setResultVision(false)} />
          )}
          <RescheduleModal loading={loading} plan={material} onOk={onOk} onCancel={onClose} />
          {!!rescheduleConfirmModal && (
            <RescheduleConfirmModal
              loading={loading}
              plan={rescheduleConfirmModal.plan}
              onOk={onConfirmReschedule}
              onCancel={() => setRescheduleConfirmModal(null)}
            />
          )}
        </>
      );

    case 'eventRequestError':
      return <EventRequestErrorModal loading={loading} onOk={onOk} />;

    case 'eventRequestConfirm':
      return <EventRequestConfirmModal loading={loading} onOk={onOk} />;

    case 'eventRequestRefuse':
      return <EventRequestConfirmModal loading={loading} onOk={onOk} refuse />;

    case 'eventRequestErrorMention':
      return <EventRequestErrorMentionModal loading={loading} onOk={onOk} />;

    case 'eventRequestNoVisit':
      return <EventRequestNoVisitModal loading={loading} onOk={onOk} />;

    default:
      return null;
  }
};

Modals.propTypes = {
  loading: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  okText: PropTypes.string,
  width: PropTypes.number,
};
