/* eslint-disable */
import { SET_DEFAULT_URL_LOGIN } from '../company/actions';
import {
  ACTUAL_LOGIN_FAIL,
  ACTUAL_LOGIN_START,
  ACTUAL_LOGIN_SUCCESS,
  CLEAR_REMINDER_MODAL,
  CLEAR_WELCOME_MODAL,
  EXTERNAL_LOGIN,
  EXTERNAL_LOGOUT,
  GOOGLE_LOGIN_FAIL,
  LOGIN_FAIL,
  LOGIN_GOOGLE_FAIL,
  LOGIN_GOOGLE_START,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_GOOGLE_FAIL,
  LOGOUT_GOOGLE_START,
  LOGOUT_GOOGLE_SUCCESS,
  MASK_IP_CHECK_FAIL,
  MASK_IP_CHECK_START,
  MASK_IP_CHECK_SUCCESS,
  MODALS,
  REFRESH_LOGIN_FAIL,
  REFRESH_LOGIN_START,
  REFRESH_LOGIN_SUCCESS,
  REGISTER_DATA_SUCCESS,
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,
  UPDATE_LOGIN_EMPTY,
} from './actions';

const initialState = {
  isLoading: false,
  isRefreshing: false,
  firstLogin: false,
  error: null,
  googleLogin: null,
  oauthUserData: null,
  errorGoogleLogin: false,
  externalLogin: localStorage.getItem('externalLogin'),
  modals: [],
};

export default (state = initialState, { type, response, link }) => {
  switch (type) {
    case LOGIN_START:
      return { ...state, isLoading: true, firstLogin: false, error: null };

    case REGISTER_DATA_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        firstLogin: response.data === '0',
        isLoading: false,
        modals: response.paramModal || [],
      };

    case LOGIN_FAIL:
      return {
        ...state,
        error: response,
        isLoading: false,
      };

    case REGISTER_START:
      return { ...state, isLoading: true, error: null };

    case REGISTER_SUCCESS:
      return { ...state, isLoading: false, error: null };

    case REGISTER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: response,
      };

    case LOGIN_GOOGLE_START:
      return { ...state, errorGoogleLogin: false, isLoading: true };

    case LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        errorGoogleLogin: false,
        isLoading: false,
        googleLogin: response.data,
      };

    case LOGIN_GOOGLE_FAIL:
      return {
        ...state,
        isLoading: false,
        errorGoogleLogin: true,
      };

    case LOGOUT_GOOGLE_START:
      return {
        ...state,
        isLoading: true,
        errorGoogleLogin: false,
      };

    case LOGOUT_GOOGLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorGoogleLogin: false,
        googleLogin: '',
      };

    case LOGOUT_GOOGLE_FAIL:
      return {
        ...state,
        isLoading: false,
        errorGoogleLogin: true,
      };

    case GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        googleLogin: '',
      };

    case EXTERNAL_LOGIN:
      return {
        ...state,
        externalLogin: localStorage.getItem('externalLogin'),
      };

    case EXTERNAL_LOGOUT:
      return {
        ...state,
        externalLogin: null,
      };

    case ACTUAL_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        oauthUserData: null,
        error: false,
      };

    case MASK_IP_CHECK_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case REFRESH_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        isRefreshing: true,
        error: false,
      };

    case ACTUAL_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        oauthUserData: response,
        error: false,
      };

    case MASK_IP_CHECK_SUCCESS:
      return { ...state, isLoading: false, error: false };
    case REFRESH_LOGIN_SUCCESS:
      return { ...state, isLoading: false, isRefreshing: false, error: false };
    case ACTUAL_LOGIN_FAIL:
      return { ...state, isLoading: false, oauthUserData: null, error: true };
    case MASK_IP_CHECK_FAIL:
      return { ...state, isLoading: false, error: true };
    case REFRESH_LOGIN_FAIL:
      return { ...state, isLoading: false, isRefreshing: false, error: true };
    case SET_DEFAULT_URL_LOGIN:
      return { ...state, error: null };
    case UPDATE_LOGIN_EMPTY:
      return { ...state, error: null };
    case CLEAR_WELCOME_MODAL:
      return { ...state, modals: state.modals.filter(name => name !== MODALS.WELCOME) };
    case CLEAR_REMINDER_MODAL:
      return { ...state, modals: state.modals.filter(name => name !== MODALS.TEST_REMINDER) };
    default:
      return state;
  }
};
