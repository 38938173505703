/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_BOOK_ADDRESSES_START = 'GET_BOOK_ADDRESSES_START';
export const GET_BOOK_ADDRESSES_SUCCESS = 'GET_BOOK_ADDRESSES_SUCCESS';
export const GET_BOOK_ADDRESSES_FAIL = 'GET_BOOK_ADDRESSES_FAIL';

export const FREE_BOOK_START = 'FREE_BOOK_START';
export const FREE_BOOK_SUCCESS = 'FREE_BOOK_SUCCESS';
export const FREE_BOOK_FAIL = 'FREE_BOOK_FAIL';

export const PUT_BOOK_ADDRESS_START = 'PUT_BOOK_ADDRESS_START';
export const PUT_BOOK_ADDRESS_SUCCESS = 'PUT_BOOK_ADDRESS_SUCCESS';
export const PUT_BOOK_ADDRESS_FAIL = 'PUT_BOOK_ADDRESS_FAIL';

export const GET_BOOK_ADDRESS_IN_USE_START = 'GET_BOOK_ADDRESS_IN_USE_START';
export const GET_BOOK_ADDRESS_IN_USE_SUCCESS = 'GET_BOOK_ADDRESS_IN_USE_SUCCESS';
export const GET_BOOK_ADDRESS_IN_USE_FAIL = 'GET_BOOK_ADDRESS_IN_USE_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getBookAddresses = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/book/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BOOK_ADDRESSES_START, GET_BOOK_ADDRESSES_SUCCESS, GET_BOOK_ADDRESSES_FAIL],
  onSuccess,
  onError,
});

/**
 * body:
 * { topicId: number, userIds: number[] } для определенных пользователей
 * { topicId: number, forAll: boolean } для всех пользователей
 * @param {object} body
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export const freeBook = (body, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/book/free`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  onSuccess,
  onError,
  types: [FREE_BOOK_START, FREE_BOOK_SUCCESS, FREE_BOOK_FAIL],
});

export const updateBookAddresses = (addressId, userId, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/book/update`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id: addressId,
    userId,
  },
  onSuccess,
  onError,
  types: [PUT_BOOK_ADDRESS_START, PUT_BOOK_ADDRESS_SUCCESS, PUT_BOOK_ADDRESS_FAIL],
});

export const getAddressInUse = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/book/address/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_BOOK_ADDRESS_IN_USE_START, GET_BOOK_ADDRESS_IN_USE_SUCCESS, GET_BOOK_ADDRESS_IN_USE_FAIL],
  onSuccess,
  onError,
});
