/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select as AntSelect, Spin } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import Resources from '@/resources';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

const { Option } = AntSelect;

export const Select = ({
  name,
  onSearch,
  value,
  options = [],
  onChange,
  showSearch,
  notFoundPlaceholder,
  filterOption,
  className,
  noDefault,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();
  const [searchOptions, setSearchOptions] = useState(options);
  const { t } = useTranslation();

  const _onChange = (value, options) => {
    const label = options && options.props && options.props.children;
    onSearch && setSearchOptions([]);
    setQuery('');
    onChange({ name, value, label });
  };

  const _filterOption =
    !onSearch &&
    (filterOption ||
      (showSearch ? (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true));

  const _onSearch =
    onSearch &&
    (query => {
      setQuery(query);

      if (!query || (query && query.length < 3)) {
        searchOptions.length && setSearchOptions([]);
        return;
      }

      setLoading(true);

      onSearch(query)
        .then(response => setSearchOptions(response))
        .finally(() => setLoading(false));
    });

  const _options = onSearch ? searchOptions : options;

  const _notFoundContent =
    query && query.length > 2 && !_options.length && !loading ? (
      t('nothingFounded')
    ) : loading ? (
      <Spin />
    ) : (
      notFoundPlaceholder || t('startSearch')
    );

  return (
    <AntSelect
      showSearch={showSearch}
      // eslint-disable-next-line
      allowClear={{
        clearIcon: <Resources.icons.Clear className={cn('select__clear-icon')} />,
      }}
      value={value}
      notFoundContent={_notFoundContent}
      defaultActiveFirstOption={false}
      {...props}
      onSearch={_onSearch}
      onChange={_onChange}
      filterOption={_filterOption}
      className={cn({ select: !noDefault }, { 'select_no-value': !value }, className)}
      popupClassName={cn('select__dropdown')}
      loading={loading}
    >
      {_options.map(option => (
        <Option value={option.value} key={option.value} disabled={option.disabled}>
          {option.label}
        </Option>
      ))}
    </AntSelect>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  notFoundPlaceholder: PropTypes.string,
  noDefault: PropTypes.bool,
};
