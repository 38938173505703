// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-H6ndTi.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-SMDGZq .user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-label-PpXUV1[data-value="Montserrat"]:before, .user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-H6ndTi.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-SMDGZq .user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-item-YRNWR0[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-Montserrat-I5M5Lg {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-_oiYoI {
  z-index: 1001;
  margin: 24px 24px 16px;
}

.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-alert-MeU6Z9 {
  margin: 16px 0;
}

.user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-footer-T5RmF0 {
  justify-content: space-between;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/CancelWithPenaltyModal/PenaltyModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,aAAa;EACb,sBAAsB;AACxB;;AAOA;EAJE,cAAc;AAChB;;AAMA;EAHE,8BAA8B;EAC9B,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.penaltyModal-body {\n  margin: 24px 24px 16px;\n  z-index: 1001;\n}\n.penaltyModal-body-alert {\n  margin: 16px 0;\n}\n.penaltyModal-footer {\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-H6ndTi`,
	"qlPicker": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-H6ndTi`,
	"ql-font": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-SMDGZq`,
	"qlFont": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-SMDGZq`,
	"ql-picker-label": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-label-PpXUV1`,
	"qlPickerLabel": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-label-PpXUV1`,
	"ql-picker-item": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-item-YRNWR0`,
	"qlPickerItem": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-picker-item-YRNWR0`,
	"ql-font-Montserrat": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-Montserrat-I5M5Lg`,
	"qlFontMontserrat": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__ql-font-Montserrat-I5M5Lg`,
	"penaltyModal-body": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-_oiYoI`,
	"penaltyModalBody": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-_oiYoI`,
	"penaltyModal-body-alert": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-alert-MeU6Z9`,
	"penaltyModalBodyAlert": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-body-alert-MeU6Z9`,
	"penaltyModal-footer": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-footer-T5RmF0`,
	"penaltyModalFooter": `user-src-components-CancelWithPenaltyModal-PenaltyModal-module__penaltyModal-footer-T5RmF0`
};
export default ___CSS_LOADER_EXPORT___;
