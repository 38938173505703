import { useEffect, useState } from 'react';

const useFormatCost = (initialCost, currentUser) => {
  const [formattedCost, setFormattedCost] = useState('');

  useEffect(() => {
    const getCost = () => {
      let defaultCurrency = {
        id: '',
        code: '',
        name: '',
        nominal: 1,
        value: 1,
      };

      let convertedCost = 0;
      let currencyCode = 'RUB';

      // Получаем текущую валюту компании
      if (
        currentUser?.currencies &&
        Array.isArray(currentUser?.currencies) &&
        !!currentUser?.domainCompany?.currencyId
      ) {
        const { currencies } = currentUser;
        const { currencyId } = currentUser.domainCompany;

        if (!Number.isNaN(Number(currencyId))) {
          currencies.forEach(currency => {
            if (currency.id === currencyId) {
              defaultCurrency = currency;
              currencyCode = currency?.code || 'RUB';
            }
          });
        }
      }

      if (initialCost) {
        if (defaultCurrency.nominal > 0) {
          convertedCost = initialCost / defaultCurrency.nominal;
        }
      }

      const formatted = Intl.NumberFormat('ru-RU', {
        style: 'currency',
        maximumSignificantDigits: 21,
        currency: currencyCode,
      }).format(convertedCost);

      setFormattedCost(formatted);
    };

    getCost();
  }, [initialCost, currentUser]);

  return formattedCost;
};

export default useFormatCost;
