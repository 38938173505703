import React, { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';

import { Button, Divider, Modal } from 'antd';

import useIsMobile from '@shared/hooks/useIsMobile';

import cn from 'classnames';
import _ from 'lodash';

import css from './Diagnostic.module.scss';

const ResultsModal = ({ visible, onOk, currentRole, currentUser }) => {
  const labels = ['Осознанность', 'Гибкость', 'Системность'];
  let currentStats = [];
  const minStats = [];
  const [isMobile] = useIsMobile(767);

  if (
    !_.isEmpty(currentUser) &&
    currentRole &&
    currentRole.minAwareness !== null &&
    currentRole.minMentalFlexibility !== null &&
    currentRole.minInvolvement !== null
  ) {
    currentStats.push(currentUser.awareness, currentUser.mentalFlexibility, currentUser.involvement);
    minStats.push(currentRole.minAwareness, currentRole.minMentalFlexibility, currentRole.minInvolvement);

    currentStats = currentStats.map(val => {
      return Math.round(val);
    });
  }

  const radarData = {
    labels: labels,
    datasets: [
      {
        label: 'Текущие показатели',
        data: currentStats,
        backgroundColor: 'rgba(150, 205, 115, 0.3)',
        borderColor: 'rgba(150, 205, 115, 1)',
        borderWidth: 2,
        pointRadius: 6,
        pointBackgroundColor: 'rgba(150, 205, 115, 1)',
        pointHoverRadius: 10,
      },
      {
        label: 'Необходимый минимум',
        data: minStats,
        backgroundColor: 'rgba(65, 141, 223, 0.1)',
        borderColor: 'rgba(65, 141, 223, 1)',
        borderWidth: 2,
        pointRadius: 6,
        pointBackgroundColor: 'rgba(65, 141, 223, 1)',
        pointHoverRadius: 10,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        pointLabels: {
          fontSize: 12,
        },
        ticks: {
          maxTicksLimit: 1,
          backdropColor: 'rgba(255, 255, 255, 0)',
          z: 2,
        },
      },
    },
  };

  const resultsProgressBars = useMemo(
    () =>
      currentStats.map((item, index) => (
        <div key={index}>
          <p>{labels[index]}:</p>
          <div className={css['ResultsModal-progress-container']}>
            <div className={css[`ResultsModal-progress-${item}`]}>{item}</div>
            <div
              className={cn(
                css[`ResultsModal-progress-${minStats[index]}`],
                css[`ResultsModal-progress-${minStats[index]}-min`]
              )}
            >
              {minStats[index]}
            </div>
          </div>
        </div>
      )),
    [currentStats, minStats, labels]
  );

  return (
    <>
      <Modal
        className={css['ResultsModal']}
        title='Расшифровка результатов'
        open={visible}
        onOk={onOk}
        onCancel={onOk}
        footer={[
          <Button key='ok' onClick={onOk} type='primary' size='large'>
            Закрыть
          </Button>,
        ]}
      >
        <div className={css['ResultsModal-container']}>
          <p className={css['ResultsModal-bold']}>Ты прошел(-ла) тест для диагностики мышления. Что дальше?</p>
          Система автоматически составила для тебя индивидуальную подборку материалов:
          <br />
          — для развития soft skills на основе теста (универсальных мягких навыков);
          <br />
          — для развития hard skills на основе указанной тобой должности (навыки и знания, специфичные для твоей
          профессии и позиции).
          <br />
          <p className={css['ResultsModal-bold']}>Что измеряет тест</p>
          Тест дает результат по трем комплексным шкалам:
          <br />
          <ol className={css['ResultsModal-list']}>
            <li>
              осознанность (включает критическое мышление, рефлексию, эмоциональный интеллект, принятие решений и др.);
            </li>
            <li>
              системность (включает внимание, концентрацию, память, аналитическое мышление, структурирование информации
              и др.);
            </li>
            <li>гибкость (включает креативность, обучаемость, адаптивность и др.).</li>
          </ol>
          Обрати внимание, что шкалы комплексные и отражают группу навыков. Также у разных позиций в компании разный
          целевой уровень показателей мышления.
          <br />
          <p className={css['ResultsModal-bold']}>Твои результаты:</p>
          {isMobile ? (
            <div className={css['ResultsModal-progress']}>
              <p className={cn(css['ResultsModal-progress-legend-current'], css['ResultsModal-progress-legend'])}>
                Текущие показатели
              </p>
              <p className={cn(css['ResultsModal-progress-legend-min'], css['ResultsModal-progress-legend'])}>
                Необходимый минимум
              </p>
              <Divider />
              {resultsProgressBars}
            </div>
          ) : (
            <div className={css['ResultsModal-radar']}>
              <Radar data={radarData} options={options} />
            </div>
          )}
          <p className={css['ResultsModal-underText']}>
            Для развития soft skills важна регулярность и достаточное количество времени. Через полгода мы предложим
            тебе пройти диагностику снова и проверить свой прогресс. <br />
            <br />
            Закрой расшифровку результатов, чтобы посмотреть свои рекомендации во вкладках “Курсы” и “Материалы”.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ResultsModal;
