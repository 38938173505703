import { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { changeVisibleWidgetNews, getNewsForWidget } from '@/store/news/actions';

import { selectNews } from '@/store/news/selectors';

import { Spin, Tooltip } from 'antd';
import { FiArrowRight } from 'react-icons/fi';
import { IoCloseOutline, IoNewspaperOutline } from 'react-icons/io5';

import NewsElement from './parts/NewsElement';

import classNames from 'classnames';

import styles from './NewsWidget.module.scss';

const NewsWidget = () => {
  const list = useRef();
  const dispatch = useDispatch();

  const { newsListWidget, widgetNewsVisible, isLoading } = useSelector(selectNews);

  const countNews = useMemo(() => newsListWidget.length, [newsListWidget]);

  const { t } = useTranslation('news');

  useEffect(() => {
    dispatch(
      getNewsForWidget(res => {
        if (res?.content?.length > 0 && !import.meta.env.DEV) {
          dispatch(changeVisibleWidgetNews());
        }
      })
    );
  }, []);

  const renderWidget = () => {
    if (isLoading.getNewsForWidget && widgetNewsVisible) {
      return <Spin className={styles.widget__spin} style={{ fontSize: 24 }} />;
    } else if (!isLoading.getNewsForWidget && widgetNewsVisible && newsListWidget.length) {
      return newsListWidget.map((element, index) => <NewsElement data={element} key={index} />);
    } else if (!isLoading.getNewsForWidget && widgetNewsVisible) {
      return (
        <div
          className={
            widgetNewsVisible ? classNames(styles.widget__noNews, styles.widget__noNews_open) : styles.widget__noNews
          }
        >
          {t('noNews')}
        </div>
      );
    }
  };

  const onClickWidget = () => {
    dispatch(changeVisibleWidgetNews());
    if (widgetNewsVisible) {
      dispatch(getNewsForWidget());
    }
  };

  return (
    <div className={styles.widget} onClick={onClickWidget}>
      {widgetNewsVisible ? (
        <IoCloseOutline className={styles.widget__icon} size={24} />
      ) : (
        <IoNewspaperOutline className={styles.widget__icon} size={20} />
      )}
      <Tooltip title={!widgetNewsVisible ? t('infoWidgetOpen') : false} placement='left'>
        <div
          ref={list}
          className={
            widgetNewsVisible
              ? classNames(styles.widget__list, styles.widget__list_open, styles['widget__list_' + countNews])
              : styles.widget__list
          }
        >
          {renderWidget()}
        </div>
      </Tooltip>
      <Link
        to='/news'
        className={
          widgetNewsVisible
            ? classNames(styles.widget__goNews, styles.widget__goNews_open, styles['widget__goNews_' + countNews])
            : styles.widget__goNews
        }
      >
        {t('goToNews')} <FiArrowRight />
      </Link>
    </div>
  );
};

export default memo(NewsWidget);
