import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Skeleton } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TabsLink.module.scss';

export const TabsLink = props => {
  const location = useLocation();

  const renderLabel = (item, key) => {
    if (item?.loading) {
      return (
        <Skeleton
          key={`tab_skeleton${key}`}
          className={css['tab-skeleton']}
          paragraph={false}
          loading={item.loading}
        ></Skeleton>
      );
    } else {
      return (
        <div
          className={classNames(css['tab-link'], { [css.maxOverflowScreen]: props.isProfile })}
          key={`tab_routs${key}`}
        >
          {item.name}
          {item.total && (
            <span className={css.new}>
              <p className={css.count}>{item.total}</p>{' '}
              {item.name === props.t('recommended') && !props.isAnother && item.newValue !== 0 && (
                <div className={css.new__status} />
              )}
            </span>
          )}
        </div>
      );
    }
  };

  const items = props?.tabsList?.map((item, key) => {
    return {
      label: renderLabel(item, key),
      key: item.url,
    };
  });

  return (
    <div className={css.tab}>
      <div className={css['tab-link']}>
        {items.map((element, index) => {
          const link = props?.basePage + element.key;
          const isActive = link === location.pathname || (index === 0 && location.pathname === props.basePage);

          return (
            <Link
              className={classNames(css['tab-link__element'], { [css['tab-link__element_active']]: isActive })}
              to={link}
              key={index}
            >
              {element.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

TabsLink.propTypes = {
  basePage: PropTypes.string,
  domainCompany: PropTypes.object,
  isAnother: PropTypes.bool,
  newValue: PropTypes.func,
  tabsList: PropTypes.array,
  total: PropTypes.number,
  userId: PropTypes.number,
  isProfile: PropTypes.bool,
};

export default withTranslation('tabsRouting')(TabsLink);
