import {
  CHECK_TEST_FAIL,
  CHECK_TEST_START,
  CHECK_TEST_SUCCESS,
  GET_CAREER_FAIL,
  GET_CAREER_START,
  GET_CAREER_SUCCESS,
  GET_DEPARTMENT_LIST_FAIL,
  GET_DEPARTMENT_LIST_START,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_POSTION_LIST_FAIL,
  GET_POSTION_LIST_START,
  GET_POSTION_LIST_SUCCESS,
  GET_RESULTS_FAIL,
  GET_RESULTS_START,
  GET_RESULTS_SUCCESS,
  GET_TEST_FAIL,
  GET_TEST_START,
  GET_TEST_SUCCESS,
  RESET_TEST_DATA,
  SET_CAREER_FAIL,
  SET_CAREER_START,
  SET_CAREER_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  test: {},
  testData: null,
  error: false,
  isCareerSet: false,
  isCareerLoading: false,
  careerList: null,
  results: {},
  departmentsList: [],
  positionsList: [],
  loadingDepartments: false,
  loadingCareers: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_TEST_START:
      return { ...state, isLoading: true, test: {}, error: false };

    case GET_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        test: response,
        isCareerSet: false,
        error: false,
      };

    case GET_TEST_FAIL:
      return { ...state, isLoading: false, test: {}, error: true };

    case CHECK_TEST_START:
      return { ...state, isLoading: true, testData: null, error: false };

    case CHECK_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        testData: response,
        isCareerSet: false,
        error: false,
      };

    case CHECK_TEST_FAIL:
      return { ...state, isLoading: false, testData: null, error: true };

    case RESET_TEST_DATA:
      return { ...state, test: {}, isCareerSet: false };

    case SET_CAREER_START:
      return { ...state, isLoading: true, error: false };

    case SET_CAREER_SUCCESS:
      return { ...state, isCareerSet: true, isLoading: false, error: true };

    case SET_CAREER_FAIL:
      return { ...state, isLoading: false, error: true };

    case GET_DEPARTMENT_LIST_START:
      return {
        ...state,
        loadingDepartments: true,
        error: false,
      };

    case GET_CAREER_START:
    case GET_POSTION_LIST_START:
      return {
        ...state,
        loadingCareers: true,
        isCareerLoading: true,
        careerList: null,
        error: false,
      };

    case GET_CAREER_SUCCESS:
      return {
        ...state,
        isCareerLoading: false,
        careerList: response,
        error: false,
      };

    case GET_CAREER_FAIL:
    case GET_DEPARTMENT_LIST_FAIL:
    case GET_POSTION_LIST_FAIL:
      return {
        ...state,
        isCareerLoading: false,
        careerList: null,
        error: true,
      };

    case GET_RESULTS_SUCCESS:
      return { ...state, isLoading: false, results: response };

    case GET_RESULTS_FAIL:
      return { ...state, isLoading: false };

    case GET_RESULTS_START:
      return { ...state, isLoading: false };

    case GET_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        loadingDepartments: false,
        departmentsList: response.map(item => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      };

    case GET_POSTION_LIST_SUCCESS:
      return {
        ...state,
        loadingCareers: false,
        positionsList: response.map(item => ({
          id: item.id,
          label: item.name,
          value: item.id,
        })),
      };

    default:
      return state;
  }
};
