/* eslint-disable */
import {
  FINISH_MENTEE_FAIL,
  FINISH_MENTEE_START,
  FINISH_MENTEE_SUCCESS,
  GET_MENTEE_AVAILABLE_STATUSES_FAIL,
  GET_MENTEE_AVAILABLE_STATUSES_START,
  GET_MENTEE_AVAILABLE_STATUSES_SUCCESS,
  GET_MENTEE_FAIL,
  GET_MENTEE_START,
  GET_MENTEE_SUCCESS,
  REJECT_MENTEE_FAIL,
  REJECT_MENTEE_START,
  REJECT_MENTEE_SUCCESS,
  REVIEW_MENTEE_FAIL,
  REVIEW_MENTEE_START,
  REVIEW_MENTEE_SUCCESS,
} from './constants';

const initialState = {
  mentees: [],
  availableStatuses: [],
  isLoading: false,
  isLoadingMentee: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case REJECT_MENTEE_START:
    case REVIEW_MENTEE_START:
    case GET_MENTEE_AVAILABLE_STATUSES_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_MENTEE_START:
      return { ...state, isLoadingMentee: true };

    case FINISH_MENTEE_START:
      return {
        ...state,
        isLoading: false,
      };

    case GET_MENTEE_SUCCESS:
      return {
        ...state,
        mentees: response,
        isLoadingMentee: false,
      };

    case GET_MENTEE_AVAILABLE_STATUSES_SUCCESS:
      return {
        ...state,
        availableStatuses: response,
        isLoading: false,
      };

    case REJECT_MENTEE_SUCCESS:
    case FINISH_MENTEE_SUCCESS:
    case REVIEW_MENTEE_SUCCESS:
      return { ...state, isLoading: false };

    case REJECT_MENTEE_FAIL:
    case REVIEW_MENTEE_FAIL:
    case FINISH_MENTEE_FAIL:
    case GET_MENTEE_FAIL:
    case GET_MENTEE_AVAILABLE_STATUSES_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case GET_MENTEE_FAIL:
      return { ...state, isLoadingMentee: false };

    default:
      return state;
  }
};
