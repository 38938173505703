// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-k7HDfb.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-aNFvhu .user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-label-NzgIOj[data-value="Montserrat"]:before, .user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-k7HDfb.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-aNFvhu .user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-item-OYDo2s[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-Montserrat-zaNKPX {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Compilation-header-b9Zvj3 {
  margin: 24px 0;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__bar-po_7Mz {
  background-color: #fff;
  border-radius: 8px;
  margin: 24px 0 36px;
  padding: 24px;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__tag-Bi_cAe {
  background-color: #dadada;
  border-radius: .3rem;
  margin-right: .5rem;
  padding: .3rem;
  font-size: 16px;
  display: inline-block;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__btnAdditional-YxDWp4 {
  margin: 0 16px;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__selectCustom-BH8tYN {
  width: 100%;
  max-width: 680px;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__blockFilter-k5ZY52 {
  display: flex;
}

.user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Topics-v6zfWe {
  padding-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/DiagnosticTalentQ/CompilationTalentQ/compilationTalentQ.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,cAAc;AAChB;;AAOA;EAJE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf;;AAOA;EAJE,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,qBAAqB;AACvB;;AAOA;EAJE,cAAc;AAChB;;AAOA;EAJE,WAAW;EACX,gBAAgB;AAClB;;AAOA;EAJE,aAAa;AACf;;AAOA;EAJE,kBAAkB;AACpB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Compilation-header {\n  margin: 24px 0;\n}\n\n.bar {\n  background-color: white;\n  padding: 24px;\n  border-radius: 8px;\n  margin: 24px 0 36px;\n}\n\n.tag {\n  background-color: #dadada;\n  padding: 0.3rem;\n  font-size: 16px;\n  display: inline-block;\n  margin-right: 0.5rem;\n  border-radius: 0.3rem;\n}\n\n.btnAdditional {\n  margin: 0 16px;\n}\n\n.selectCustom {\n  max-width: 680px;\n  width: 100%;\n}\n\n.blockFilter {\n  display: flex;\n}\n\n.Topics {\n  padding-left: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-k7HDfb`,
	"qlPicker": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-k7HDfb`,
	"ql-font": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-aNFvhu`,
	"qlFont": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-aNFvhu`,
	"ql-picker-label": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-label-NzgIOj`,
	"qlPickerLabel": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-label-NzgIOj`,
	"ql-picker-item": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-item-OYDo2s`,
	"qlPickerItem": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-picker-item-OYDo2s`,
	"ql-font-Montserrat": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-Montserrat-zaNKPX`,
	"qlFontMontserrat": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__ql-font-Montserrat-zaNKPX`,
	"Compilation-header": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Compilation-header-b9Zvj3`,
	"compilationHeader": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Compilation-header-b9Zvj3`,
	"bar": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__bar-po_7Mz`,
	"tag": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__tag-Bi_cAe`,
	"btnAdditional": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__btnAdditional-YxDWp4`,
	"selectCustom": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__selectCustom-BH8tYN`,
	"blockFilter": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__blockFilter-k5ZY52`,
	"Topics": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Topics-v6zfWe`,
	"topics": `user-src-pages-DiagnosticTalentQ-CompilationTalentQ-compilationTalentQ-module__Topics-v6zfWe`
};
export default ___CSS_LOADER_EXPORT___;
