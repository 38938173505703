/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { registerKampusUser, registerUser } from '@/store/login/actions';
import { getDepartmentList, getPositionList } from '@/store/trajectory/actions';
import { setAvaToCache } from '@/store/user-topics/actions';
import {
  editAboutUser,
  getUser,
  getUserPositions,
  loadAvatarUser,
  overwriteValuesUser,
  updateAvatarUser,
} from '@/store/users/actions';

import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectTrajectory } from '@/store/trajectory/selectors';
import { selectUsers as userSelect } from '@/store/users/selectors';

import { Button, Form, Input, Layout, Select, Spin } from 'antd';
import AvatarInput from '@/components/AvatarInput/AvatarInput';
import LogoCompany from '@/components/LogoCompany/LogoCompany';
import { CloseOutlined } from '@ant-design/icons';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';

import css from './Login.module.scss';

const { TextArea } = Input;
const { Content } = Layout;

const SPP_ID = 5652;
const PATH_ABOUT_PAGE = '/personal-data/registration-page-data-about';
class RegistrationPageDataAbout extends Component {
  static propTypes = {
    changeRenderPage: PropTypes.func,
    currentUser: PropTypes.object,
    editAboutUser: PropTypes.func,
    errorNum: PropTypes.number,
    getFile: PropTypes.func,
    getUser: PropTypes.func,
    imageBackgroundLogin: PropTypes.any,
    imageLogo: PropTypes.any,
    loadAvatarUser: PropTypes.func,
    login: PropTypes.func,
    loginError: PropTypes.bool,
    overwriteValuesUser: PropTypes.func,
    registerUser: PropTypes.func,
    updateAvatarUser: PropTypes.func,
    updateLoginEmpty: PropTypes.func,
    oauthUserData: PropTypes.object,
    edit: PropTypes.bool,
    onClose: PropTypes.func,
    setAvaToCache: PropTypes.func,
    getUserPositions: PropTypes.func,
    registerKampusUser: PropTypes.func,
    isNotFixed: PropTypes.bool,
    editProfile: PropTypes.bool, // проверка на вызов с моего профиля
  };

  constructor(props) {
    super(props);

    this.state = {
      currentUser: props.currentUser,
      about: props.currentUser?.about || '',
      localPhotoUuid: null,
      imageSrc: null,
      department: props.currentUser?.departmentInternalDto
        ? {
            key: props.currentUser.departmentInternalDto.id,
            name: props.currentUser.departmentInternalDto.name,
          }
        : undefined,
      career: props.currentUser?.functionInternalDto
        ? {
            key: props.currentUser.functionInternalDto.id,
            name: props.currentUser.functionInternalDto.name,
          }
        : undefined,
      function: undefined,
      careerTxt: '',
      departmentTxt: '',
      functionTxt: '',
      phoneTxt: '',
      companyTxt: '',
      profileEdit: props.profileEdit,
      isKampus: document.location.hostname === 'td.kampus.team',
      errors: {},
      isRegister: false,
    };
  }

  componentDidMount() {
    if (document.location.pathname === '/personal-data/registration-page-data-about') {
      this.setState({ isRegister: true });
    }

    const profileEdit = !!this.props.currentUser?.domainCompany?.profileEdit;

    this.setState({ profileEdit });

    if (this.props.currentUser && !this.props.profileEdit) {
      this.setState({
        careerTxt: this.props.currentUser.functionInternalDto?.name || '',
        departmentTxt: this.props.currentUser.departmentInternalDto?.name || '',
        functionTxt: this.props.currentUser.function || '',
      });
    }

    if (this.props.oauthUserData?.photo) {
      this.getOauthAvatar();
    }

    if (this.props.currentUser?.domainCompany?.hasUserPosition && (this.props.profileEdit || profileEdit)) {
      this.props.getPositionList();
    }

    if (this.props.currentUser?.domainCompany?.hasUserDepartment && (this.props.profileEdit || profileEdit)) {
      this.props.getDepartmentList();
    }

    if (this.props.currentUser?.domainCompany?.hasUserFunction && (this.props.profileEdit || profileEdit)) {
      this.props.getUserPositions({ size: 1000 });
    }

    const currentFunction = this.props.currentUser?.function || '';
    const functionIndex = this.props.functionsList?.findIndex(value => value === currentFunction);

    const functionKey = isNumber(functionIndex) && functionIndex !== -1 ? functionIndex + 1 : null;

    if (functionKey !== null && functionKey >= 0) {
      this.setState({ function: { key: functionKey, label: currentFunction } });
    }

    if (this.state.isKampus && this.props.currentUser) {
      this.setState({
        careerTxt: this.props.currentUser.position || '',
        companyTxt: this.props.currentUser.company || '',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isKampus !== this.state.isKampus) {
      if (this.state.isKampus && this.props.currentUser) {
        this.setState({
          careerTxt: this.props.currentUser.position || '',
          companyTxt: this.props.currentUser.company || '',
        });
      }
    }

    if (prevProps.functionsList.length !== this.props.functionsList.length) {
      const currentFunction = this.props.currentUser?.function || '';
      const functionIndex = this.props.functionsList?.findIndex(item => item.value === currentFunction);

      const functionKey = isNumber(functionIndex) && functionIndex !== -1 ? functionIndex + 1 : null;

      if (functionKey !== null && functionKey >= 0) {
        this.setState({ function: { key: functionKey, label: currentFunction } });
      }
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeSelect = (value, name) => {
    this.setState(prevState => ({ errors: { ...prevState.errors, [name]: '' }, [name]: value }));
  };

  validateRequired = () => {
    let valid = true;

    if (!this.state.isKampus) {
      if (!this.state.career) {
        this.setState(prevState => ({ errors: { ...prevState.errors, career: this.props.t('requiredText') } }));
        valid = false;
      }

      if (!this.state.department) {
        this.setState(prevState => ({ errors: { ...prevState.errors, department: this.props.t('requiredText') } }));

        valid = false;
      }

      if (!this.state.function) {
        this.setState(prevState => ({ errors: { ...prevState.errors, function: this.props.t('requiredText') } }));
        valid = false;
      }
    }

    if (this.state.isKampus) {
      const onlyNumbersString = this.state.phoneTxt.replace(/\D/g, '');
      const minPhoneLength = 11;

      const cleanCareerTxt = this.state.careerTxt.trim();
      const cleanCompanyTxt = this.state.companyTxt.trim();

      if (onlyNumbersString.length < minPhoneLength && document.location.pathname === PATH_ABOUT_PAGE) {
        this.setState(prevState => ({ errors: { ...prevState.errors, phoneTxt: this.props.t('requiredText') } }));
        valid = false;
      }

      if (!cleanCareerTxt && document.location.pathname === PATH_ABOUT_PAGE) {
        this.setState(prevState => ({ errors: { ...prevState.errors, careerTxt: this.props.t('requiredText') } }));
        valid = false;
      }

      if (!cleanCompanyTxt && document.location.pathname === PATH_ABOUT_PAGE) {
        this.setState(prevState => ({ errors: { ...prevState.errors, companyTxt: this.props.t('requiredText') } }));
        valid = false;
      }
    }

    return valid;
  };

  handleChangeRenderPage = () => {
    const { currentUser } = this.props;
    localStorage.setItem('welcome', 'true');

    const oldValue = currentUser;

    const localPhotoUuid = this.state.localPhotoUuid ? this.state.localPhotoUuid : currentUser.localPhotoUuid;
    const about = this.state.about;
    const companyId = currentUser?.domainCompany?.id;

    const newValue = {
      localPhotoUuid,
      about,
      id: currentUser.id,
      firstName: currentUser.firstName,
      secondName: currentUser.secondName,
      departmentInternalId: this.state.department ? this.state.department.key : null,
      functionInternalId: this.state.career ? this.state.career.key : null,
      managerIds: currentUser?.managers?.map(manager => manager.id) || [],
      managerId: currentUser?.managers?.length && currentUser.managers[0].id,
      function: this.state.function ? this.state.function.label : null,
    };

    if (this.state.isRegister) {
      delete oldValue.login;
    }

    if ((SPP_ID === +companyId || this.state.isKampus) && !this.validateRequired()) return;

    if (this.state.isKampus) {
      const onlyNumbersString = '+' + this.state.phoneTxt.replace(/\D/g, '');
      const cleanCareerTxt = this.state.careerTxt.trim();
      const cleanCompanyTxt = this.state.companyTxt.trim();

      const body = {
        phone: onlyNumbersString,
        position: cleanCareerTxt,
        company: cleanCompanyTxt,
        localPhotoUuid,
      };

      this.props.registerKampusUser(body, () => {
        if (this.props.edit && this.props.onClose) {
          this.props.onClose();
        } else {
          this.props.navigate('/planning');
        }
      });
    }

    if (!this.state.isKampus) {
      this.props.overwriteValuesUser(oldValue, newValue, () => {
        if (this.props.edit && this.props.onClose) {
          this.props.onClose();
        } else {
          this.props.navigate('/planning');
        }
      });
    }
  };

  getOauthAvatar = () => {
    const { oauthUserData } = this.props;

    this.getFile(oauthUserData.photo);
  };

  getFile = uuid => {
    this.props.getFile(uuid, res => {
      this.setState({
        imageSrc: URL.createObjectURL(res),
        localPhotoUuid: uuid,
      });
    });
  };

  changeInput = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(prevState => ({ errors: { ...prevState.errors, [name]: '' }, [name]: value }));
  };

  render() {
    const { t } = this.props;

    return (
      <Content
        className={classNames({
          [css.LoginContent]: this.props.isNotFixed,
          [css.LoginContentAbsolute]: !this.props.isNotFixed,
          [css['LoginContent-edit']]: this.props.edit,
        })}
      >
        <div
          className={classNames({
            [css['App-card-item-register']]: this.state.isRegister,
            [css['App-card-item-edit']]: this.props.edit,
            [css['App-card-item__myProfile']]: this.props.editProfile,
          })}
        >
          {this.state.profileEdit ? (
            <>
              {(this.props.loadingDepartments || this.props.loadingCareers || !this.props.currentUser) && (
                <div className={css.loader}>
                  <Spin />
                </div>
              )}
            </>
          ) : null}

          <img className={css.imageBackgroundLogin} src={this.props.imageBackgroundLogin} alt='' />
          <Form className={css['LoginPage-form']}>
            <div className={css['LoginPage-form__contentRegistrationData']}>
              {this.props.imageLogo && (
                <div className={css['LoginPage-header']}>
                  <div className={css['LoginPage-header__logo']}>
                    <LogoCompany imageLogo={this.props.imageLogo} />
                  </div>
                </div>
              )}
              {!this.state.isKampus && (
                <>
                  <div className={classNames(css['LoginPage-titleHead'], css['LoginPage-titleHead-modificate'])}>
                    {t('profile')}
                  </div>
                  <div className={css['LoginPage-titleBody']}>
                    {t('about')}
                    <br />
                  </div>
                  <div className={classNames(css['avatar'])}>
                    <AvatarInput
                      modificate
                      imageSrc={this.state.imageSrc}
                      user={this.props.currentUser}
                      getFile={this.props.getFile}
                      getPhoto={this.getFile}
                      loadAvatarUser={this.props.loadAvatarUser}
                    />
                  </div>

                  <Form.Item
                    label={t('login')}
                    colon={false}
                    labelAlign={'left'}
                    className={css['LoginPage-dictionaries-email']}
                  >
                    {this.props.currentUser.login}
                  </Form.Item>

                  <Form.Item
                    label={t('email')}
                    colon={false}
                    labelAlign={'left'}
                    className={css['LoginPage-dictionaries-email']}
                  >
                    <div>{this.props.currentUser.email}</div>
                  </Form.Item>

                  {this.state.currentUser?.domainCompany?.hasUserPosition && (
                    <div className={css['LoginPage-dictionaries']}>
                      <div className={css['LoginPage-dictionaries-label']}>
                        {this.state.currentUser.domainCompany.positionSingleNameImenit}
                      </div>
                      {this.state.profileEdit && this.props.positionsList?.length ? (
                        <Form.Item
                          help={this.state.errors.career}
                          validateStatus={this.state.errors.career ? 'error' : ''}
                          className={classNames(css['select'], {
                            [css.error]: this.state.errors.career,
                          })}
                          hasFeedback
                        >
                          <Select
                            allowClear={{ clearIcon: <CloseOutlined /> }}
                            showSearch
                            placeholder={t('put')}
                            notFoundContent={t('noResult')}
                            onChange={value => this.handleChangeSelect(value, 'career')}
                            options={this.props.positionsList?.map(option => ({
                              label: option.label,
                              value: option.value,
                            }))}
                            value={this.state.career}
                            className={classNames(css['select'], css['select-full'])}
                            labelInValue
                          />
                        </Form.Item>
                      ) : (
                        <Input className={classNames(css['LoginPage--select'])} value={this.state.careerTxt} disabled />
                      )}
                    </div>
                  )}
                  {this.state.currentUser?.domainCompany?.hasUserDepartment && (
                    <div className={css['LoginPage-dictionaries']}>
                      <div className={css['LoginPage-dictionaries-label']}>
                        {this.state.currentUser.domainCompany.departmentSingleNameImenit}
                      </div>
                      {this.state.profileEdit && this.props.departmentsList?.length ? (
                        <Form.Item
                          help={this.state.errors.department}
                          validateStatus={this.state.errors.department ? 'error' : ''}
                          className={classNames(css['select'], {
                            [css.error]: this.state.errors.department,
                          })}
                          hasFeedback
                        >
                          <Select
                            allowClear={{ clearIcon: <CloseOutlined /> }}
                            showSearch
                            placeholder={t('put')}
                            notFoundContent={t('noResult')}
                            onChange={value => this.handleChangeSelect(value, 'department')}
                            value={this.state.department}
                            options={this.props.departmentsList?.map(option => ({
                              label: option.label,
                              value: option.value,
                            }))}
                            className={classNames(css['select'], css['select-full'])}
                            labelInValue
                          />
                        </Form.Item>
                      ) : (
                        <Input
                          className={classNames(css['LoginPage--select'])}
                          value={this.state.departmentTxt}
                          disabled
                        />
                      )}
                    </div>
                  )}
                  {this.props.currentUser?.domainCompany?.hasUserFunction && (
                    <div className={css['LoginPage-dictionaries']}>
                      <div className={css['LoginPage-dictionaries-label']}>
                        {this.state.currentUser.domainCompany.functionSingleNameImenit}
                      </div>
                      {this.state.profileEdit && this.props.functionsList?.length ? (
                        <Form.Item
                          help={this.state.errors.function}
                          validateStatus={this.state.errors.function ? 'error' : ''}
                          className={classNames(css['select'], {
                            [css.error]: this.state.errors.function,
                          })}
                          hasFeedback
                        >
                          <Select
                            allowClear={{ clearIcon: <CloseOutlined /> }}
                            showSearch
                            placeholder={t('put')}
                            notFoundContent={t('noResult')}
                            onChange={value => this.handleChangeSelect(value, 'function')}
                            value={this.state.function}
                            options={this.props.functionsList?.map(option => ({
                              label: option.label,
                              value: option.value,
                            }))}
                            className={css['select']}
                            labelInValue
                          />
                        </Form.Item>
                      ) : (
                        <Input
                          className={classNames(css['LoginPage--select'])}
                          value={this.state.functionTxt}
                          disabled
                        />
                      )}
                    </div>
                  )}
                  {/* о себе */}
                  <div className={css['LoginPage-dictionaries']}>
                    <div className={css['LoginPage-dictionaries-label']}>{t('aboutYourself1')}</div>
                    <TextArea
                      name='about'
                      className={classNames(css['LoginPage-form-textAbout'], {
                        [css['LoginPage-form-textAbout-edit']]: this.props.edit,
                      })}
                      rows={4}
                      placeholder={t('aboutYourself2')}
                      onChange={this.changeInput}
                      value={this.state.about}
                      maxLength={1000}
                    />
                  </div>
                </>
              )}

              {this.state.isKampus && (
                <>
                  <div className={classNames(css['avatar'])}>
                    <AvatarInput
                      modificate
                      imageSrc={this.state.imageSrc}
                      user={this.props.currentUser}
                      getFile={this.props.getFile}
                      getPhoto={this.getFile}
                      loadAvatarUser={this.props.loadAvatarUser}
                    />
                  </div>
                  {!this.props.edit && (
                    <div className={css['LoginPage-dictionaries']}>
                      <div className={css['LoginPage-dictionaries-label']}>{t('phone')}</div>
                      <Form.Item
                        help={this.state.errors.phoneTxt}
                        validateStatus={this.state.errors.phoneTxt ? 'error' : ''}
                        className={classNames(css['select'], {
                          [css.error]: this.state.errors.phoneTxt,
                        })}
                        hasFeedback
                      >
                        <Input
                          name='phoneTxt'
                          className={css['select']}
                          value={this.state.phoneTxt}
                          onChange={this.changeInput}
                          maxLength={25}
                          placeholder={t('placeholderPhone')}
                          type='tel'
                        />
                      </Form.Item>
                    </div>
                  )}

                  <div className={css['LoginPage-dictionaries']}>
                    <div className={css['LoginPage-dictionaries-label']}>{t('position')}</div>
                    <Form.Item
                      help={this.state.errors.careerTxt}
                      validateStatus={this.state.errors.careerTxt ? 'error' : ''}
                      className={classNames(css['select'], {
                        [css.error]: this.state.errors.careerTxt,
                      })}
                      hasFeedback
                    >
                      <Input
                        name='careerTxt'
                        className={css['select']}
                        value={this.state.careerTxt}
                        onChange={this.changeInput}
                      />
                    </Form.Item>
                  </div>

                  <div className={css['LoginPage-dictionaries']}>
                    <div className={css['LoginPage-dictionaries-label']}>{t('company')}</div>
                    <Form.Item
                      help={this.state.errors.companyTxt}
                      validateStatus={this.state.errors.companyTxt ? 'error' : ''}
                      className={classNames(css['select'], {
                        [css.error]: this.state.errors.companyTxt,
                      })}
                      hasFeedback
                    >
                      <Input
                        name='companyTxt'
                        className={css['select']}
                        value={this.state.companyTxt}
                        onChange={this.changeInput}
                      />
                    </Form.Item>
                  </div>
                </>
              )}

              <Form.Item className={classNames(css['LoginPage-form-google'], css.confirm)}>
                <div className={css['LoginPage-form-FooterButtonsDesktop']}>
                  <Button size='large' type='primary' onClick={this.handleChangeRenderPage} htmlType='submit'>
                    {t('done')}
                  </Button>
                </div>
                <div
                  className={classNames(css['LoginPage-form-FooterButtonsMobile'], {
                    [css['LoginPage-form-FooterButtonsMobile-edit']]: this.props.edit,
                  })}
                >
                  <Button size='large' onClick={this.props.onClose}>
                    {t('cancel')}
                  </Button>
                  <Button type='primary' size='large' onClick={this.handleChangeRenderPage}>
                    {t('apply')}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Content>
    );
  }
}
const mapStateToProps = createSelector(
  [loginSelect, userSelect, selectTrajectory],
  (loginState, userState, selectTrajectory) => ({
    errorNum: loginState.errorNum,
    loginError: loginState.error,
    oauthUserData: loginState.oauthUserData,
    currentUser: userState.currentUser,
    departmentsList: selectTrajectory.departmentsList,
    positionsList: selectTrajectory.positionsList,
    loadingDepartments: selectTrajectory.loadingDepartments,
    loadingCareers: selectTrajectory.loadingCareers,
    functionsList:
      userState.positions?.content?.map(el => ({
        id: el.id,
        label: el.name,
        value: el.name,
      })) || [],
  })
);

const mapActionsToProps = {
  registerUser,
  getUser,
  getFile,
  editAboutUser,
  loadAvatarUser,
  updateAvatarUser,
  overwriteValuesUser,
  getDepartmentList,
  getPositionList,
  setAvaToCache,
  getUserPositions,
  registerKampusUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation('registrationData')(RegistrationPageDataAbout));
