/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLocales, setUserLanguage } from '@/store/locales/actions';
import { getTimezones } from '@/store/timezones/actions';
import { getUserTimezone, setUserTimezone } from '@/store/user-timezone/actions';
import { changeUserProperties } from '@/store/users/actions';

import { selectLocales } from '@/store/locales/selectors';
import { selectTimezones } from '@/store/timezones/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Layout, Row } from 'antd';
import Modal from '@/components/Modal';
import Select from '@/components/Select';

import useIsMobile from '@shared/hooks/useIsMobile';
import { changeLanguage } from '@shared/utils/changeLanguage';

import classNames from 'classnames';
import dayjs from 'dayjs';
import _find from 'lodash/find';
import _get from 'lodash/get';
import PT from 'prop-types';

import css from './info.module.scss';

import { KAMPUS_DEV_DOMAIN } from '@/constants/global';

const { Content } = Layout;

const RecommendedTopic = ({ bindGoogleAccount, externalLogin, logoutGoogleSuccess, navigate }) => {
  const { t, i18n } = useTranslation('profile');
  const dispatch = useDispatch();

  const { locales } = useSelector(selectLocales);
  const { timezones } = useSelector(selectTimezones);
  const { currentUser } = useSelector(selectUsers);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('currentLanguage') || 'ru_RU');

  const [timezoneValue, setTimezoneValue] = useState(currentUser.timezone.name || '');
  const [isOpen, setOpen] = useState(false);
  const [useFilter, setUseFilter] = useState(false);
  const [fakeOAuth, setFakeOAuth] = useState(localStorage.getItem('externalLogin'));

  useEffect(() => {
    dispatch(getLocales());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserTimezone());
  }, [dispatch]);

  const [isMobile] = useIsMobile(992);

  const onConfirm = () => {
    bindGoogleAccount((body, headers) => {
      document.location.replace(headers.get('Location'));
    });
  };

  const onUnbind = () => logoutGoogleSuccess(localStorage.getItem('authorizationToken'));

  const isInternalLogin = useMemo(() => {
    const login = currentUser.login.includes('@') ? currentUser.login : null;

    const providers = _get(currentUser, 'domainCompany.providers', []);

    return login
      ? !!_find(providers, provider => _find(_get(provider, 'domains', []), domain => login.includes(domain)))
      : false;
  }, [currentUser]);

  const localesOptions = useMemo(
    () =>
      locales.map(data => ({
        id: data.language,
        value: data.language,
        name: data.languageDesc,
      })),
    [locales]
  );

  const onSearch = event => {
    setTimezoneValue(event);
    setUseFilter(true);
  };

  const filteredTimezone = timezones.filter(timezone => {
    return timezone.name.toLowerCase().includes(timezoneValue.toLowerCase());
  });

  const changeLocale = useCallback(
    ({ value }) => {
      setCurrentLanguage(value);
      changeLanguage(i18n, dayjs, value);

      // Лишний запрос на бэк на смену локали, нужно уточнить насколько оно нужно на бэке и по необходимости удалить
      dispatch(setUserLanguage(value));
      dispatch(changeUserProperties({ language: value }));
    },
    [setUserLanguage]
  );

  const changeTimezone = useCallback(
    ({ value }) => {
      dispatch(setUserTimezone(value.id));
      dispatch(changeUserProperties({ timezone: value }));
    },
    [setUserTimezone]
  );

  const handleFakeOAuth = () => {
    if (!fakeOAuth) {
      localStorage.setItem('externalLogin', true);
    } else {
      localStorage.removeItem('externalLogin');
    }
    document.location.reload();
  };

  const addSpace = str => str.split('/').join(' / ');

  const convertedTimezones = v =>
    v?.map(i => {
      i.name = addSpace(i.name);
      return i;
    });

  return (
    <Content>
      {/* {!( // 4450 попросили закомментировать временно данный блок, тк в будущем может пригодиться
        _get(currentUser, 'domainCompany.providers', []).filter(
          (provider) => provider.type === 'INTERNAL'
        ).length !== 0
      ) && (
        <Row className={css['app-profline-tabs']}>
          <Col span={2} />
          <Col span={10}>
            <h3 className={css['app-profline-info__title']}>
              {t('homeAccess')}
            </h3>
            {currentUser.googleId || isInternalLogin ? (
              <div className={css.google__box}>
                <div className={css.google__wrapFlex}>
                  <img src={GoogleImg} alt='' />
                  <div>
                    <Tooltip title={currentUser.social}>
                      <p className={css.google__text}>{t('currentMail')}</p>
                      <p className={css.google__mail}>
                        {currentUser.googleId || currentUser.login}
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  {!externalLogin && !isInternalLogin && (
                    <Button  google onClick={onUnbind}>
                      {t('googleAcc')}
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <p className={css['app-profline-info__text']}>
                  {t('lan1')} <br /> {t('lan2')}
                </p>
                <Button  google onClick={() => setOpen(true)}>
                  {t('addGoogle')}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      )} */}
      <div className={css['app-profline-info__settingsContainer']}>
        <Row style={{ marginBottom: '30px' }}>
          <Col span={11}>
            <h3 className={css['app-profline-info__title']}>{t('changeTimeZone')}</h3>
            <Select
              showSearch
              filterOption={false}
              onSearch={event => onSearch(event)}
              defaultActiveFirstOption={false}
              name='timezone'
              notFoundPlaceholder={t('noResults')}
              value={convertedTimezones([currentUser.timezone])[0].name}
              onChange={changeTimezone}
              options={useFilter ? convertedTimezones(filteredTimezone) : convertedTimezones(timezones)}
              className={css['app-profline-info__select']}
              style={{ width: 240 }}
              modificate
              size='large'
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '30px' }}>
          <Col span={11}>
            <h3 className={css['app-profline-info__title']}>
              {isMobile ? t('changeLanguageMobile') : t('changeLanguage')}
            </h3>
            <Select
              id={'changeLanguage'}
              name='locales'
              notFoundPlaceholder={t('noResults')}
              onChange={changeLocale}
              value={currentLanguage}
              options={localesOptions}
              className={css['app-profline-info__select']}
              disabled={localesOptions.length < 2}
              modificate
              size='large'
            />
          </Col>
        </Row>
        {(document.location.hostname === 'localhost' ||
          document.location.hostname === KAMPUS_DEV_DOMAIN ||
          document.location.hostname === `cloud-stage.${KAMPUS_DEV_DOMAIN}`) && (
          <Row>
            <Col span={11}>
              <h3 className={css['app-profline-info__title']}>{t('simulationExternalLogin')}</h3>
              <Button onClick={handleFakeOAuth} size='large' type='primary'>
                {fakeOAuth ? t('notActive') : t('active')}
              </Button>
            </Col>
          </Row>
        )}
      </div>
      <Modal
        open={isOpen}
        onCancel={() => setOpen(false)}
        title={t('access')}
        contentClassName={classNames(css['App-card-item'], css['Planning-topic-modal'])}
        footerBtnOrange
        modificate
        cardOn
        okText={t('next')}
        onOk={onConfirm}
      >
        <p className={css['app-profline-info__text']}>{t('vpn')}</p>
      </Modal>
    </Content>
  );
};

RecommendedTopic.propTypes = {
  currentUser: PT.object,
  externalLogin: PT.string,
  loginGoogleFailure: PT.func,
  loginGoogleSuccess: PT.func,
  logoutGoogle: PT.func,
  logoutGoogleSuccess: PT.func,
  navigate: PT.func,
};

export default RecommendedTopic;
