/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Card, Col, Progress, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { ImStatsBars2 } from 'react-icons/im';

import usePercent from '../../hooks/usePercent';

import PropTypes from 'prop-types';

import css from '../../css/styles.module.scss';

export const ProgressCard = props => {
  const { t } = useTranslation('D360_InfoCard');
  const percent = usePercent(props.questionCount, props.answerCount);

  return (
    <Card
      title={<h3 className={css.InfoCardTitle}>{props.title || ''}</h3>}
      className={css.InfoCard}
      loading={props.loading || false}
      extra={
        <Link to={`/d360`}>
          <Button icon={<LeftOutlined />} shape='circle' size='small' type='primary' />
        </Link>
      }
    >
      {props && props.total !== null && props.percent !== null && (
        <Row>
          <Col span={24}>
            <Progress
              type='line'
              showInfo={false}
              percent={percent}
              strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}
            />
          </Col>
          <Col span={12}>
            {Number.parseInt(percent.toString(), 10)}% {t('completed')}
          </Col>
          <Col span={12} align='right'>
            {props.answerCount || 0}/{props.questionCount || 0}
          </Col>
        </Row>
      )}
    </Card>
  );
};

ProgressCard.propTypes = {
  questionCount: PropTypes.number,
  answerCount: PropTypes.number,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

export default ProgressCard;
