/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile, getPublicFile } from '@/store/files/actions';

import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Layout, Steps, Tooltip } from 'antd';
import { Content } from '@/components/Layout';
import { BiArchiveIn, BiFilterAlt } from 'react-icons/bi';
import { FcBullish } from 'react-icons/fc';

import CompetenceList from './CompetenceList';
import RecommendationList from './RecommendationList';
import ResultList from './ResultList';
import SkillList from './SkillList';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

const { Step } = Steps;

export class DiagnosticPolus extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    currentUser: PropTypes.object,
    getFile: PropTypes.func,
    getPublicFile: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      selectedCompetence: null,
      selectedSkills: [],
      selectorWindowVisible: true,
      recommendationVisible: false,
      downloading: false,
      reportUUID: null,
      reviewUUID: null,
      reportFileName: 'Report File',
      reviewFileName: 'Review File',
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
  }

  isButtonNextDisabled = () => {
    const { currentStep, selectedCompetence, selectedSkills } = this.state;

    if (currentStep === 0 && selectedCompetence !== null) {
      return false;
    }

    if (currentStep === 1 && selectedSkills.length > -1) {
      return false;
    }

    return true;
  };

  stepNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };

  stepBack = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  };

  stepDone = () => {
    this.setState({
      recommendationVisible: true,
      selectorWindowVisible: false,
    });
  };

  handleOnSelectCompetence = competence => {
    this.setState({ selectedCompetence: competence });
  };

  handleOnSelectSkills = skills => {
    this.setState({ selectedSkills: skills });
  };

  handleDownloadReport = () => {
    const { reportUUID, reportFileName } = this.state;
    this.setState({ downloading: true });

    this.props.getFile(
      reportUUID,
      blob => {
        let url = window.URL.createObjectURL(blob);
        //window.open(url);
        let a = document.createElement('a');
        a.href = url;
        a.download = reportFileName;
        a.click();
        this.setState({ downloading: false });
      },
      () => {
        this.setState({ downloading: false });
      }
    );
  };

  handleDownloadReview = () => {
    const { reviewUUID, reviewFileName } = this.state;
    this.setState({ downloading: true });

    this.props.getFile(
      reviewUUID,
      blob => {
        let url = window.URL.createObjectURL(blob);
        //window.open(url);
        let a = document.createElement('a');
        a.href = url;
        a.download = reviewFileName;
        a.click();
        this.setState({ downloading: false });
      },
      () => {
        this.setState({ downloading: false });
      }
    );
  };

  handleToggleSelectorWindow = () => {
    const { selectorWindowVisible } = this.state;
    if (selectorWindowVisible) {
      this.setState({ selectorWindowVisible: false });
    } else {
      this.setState({ selectorWindowVisible: true });
    }
  };

  render() {
    const { t, currentUser } = this.props;
    const {
      currentStep,
      selectedCompetence,
      selectedSkills,
      recommendationVisible,
      selectorWindowVisible,
      downloading,
      reportUUID,
      reviewUUID,
    } = this.state;

    const steps = [
      {
        title: t('selectCompetence'),
        content: <CompetenceList onSelectCompetence={this.handleOnSelectCompetence} currentUser={currentUser} t={t} />,
      },
      {
        title: t('selectSkills'),
        content: (
          <SkillList
            onSelectSkills={this.handleOnSelectSkills}
            currentUser={currentUser}
            competence={selectedCompetence}
            t={t}
          />
        ),
      },
      {
        title: t('checkResult'),
        content: <ResultList competence={selectedCompetence} skills={selectedSkills} t={t} />,
      },
    ];

    return (
      <Layout>
        <Content>
          <div>
            <h2
              style={{
                marginBottom: '2rem',
                borderBottom: '1px dotted #ddd',
                display: 'inline-block',
              }}
            >
              <FcBullish />
              &nbsp;{t('assesment')}
            </h2>
          </div>

          <div
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {reviewUUID && (
              <Tooltip title={t('reviewTooltip')}>
                <Button
                  type='danger'
                  onClick={() => this.handleDownloadReview()}
                  loading={downloading}
                  disabled={downloading}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {!downloading && <BiArchiveIn />}
                  &nbsp;
                  {t('review')}
                </Button>
              </Tooltip>
            )}

            {reportUUID && (
              <Tooltip title={t('reportTooltip')}>
                <Button
                  type='primary'
                  onClick={() => this.handleDownloadReport()}
                  loading={downloading}
                  disabled={downloading}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginLeft: '0.5rem',
                  }}
                >
                  {!downloading && <BiArchiveIn />}
                  &nbsp;
                  {t('report')}
                </Button>
              </Tooltip>
            )}

            <Button
              disabled={!recommendationVisible}
              style={{
                marginLeft: '0.5rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              onClick={() => this.handleToggleSelectorWindow()}
            >
              <BiFilterAlt />
              &nbsp;
              {t('summary')}
            </Button>
          </div>

          {selectorWindowVisible && (
            <div>
              <Steps current={currentStep}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div
                className='steps-content'
                style={{
                  marginTop: '2rem',
                  height: '100%',
                  minHeight: '100%',
                  backgroundColor: 'white',
                }}
              >
                {steps[currentStep].content}
              </div>
              <div className='steps-actions' style={{ marginTop: '2rem', textAlign: 'center' }}>
                <Button typeof='default' onClick={() => this.stepBack()} disabled={!(currentStep > 0)}>
                  {t('prevButton')}
                </Button>

                <Button
                  type='primary'
                  onClick={() => this.stepDone()}
                  disabled={!(currentStep === steps.length - 1)}
                  style={{ margin: '0 1rem' }}
                >
                  {t('doneButton')}
                </Button>

                <Button type='primary' onClick={() => this.stepNext()} disabled={this.isButtonNextDisabled()}>
                  {t('nextButton')}
                </Button>
              </div>
            </div>
          )}

          {recommendationVisible && <RecommendationList currentUser={currentUser} skills={selectedSkills} t={t} />}
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = createSelector([usersSelect], users => ({
  currentUser: users.currentUser,
}));

const mapActionsToProps = {
  getFile,
  getPublicFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticPolus')(DiagnosticPolus));
