/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

const DialogFilterSelectCompilation = ({
  open,
  onCancel,
  stuff,
  chosenCompilation,
  nullifyRadioButtonValue,
  searchParams,
  setSearchParams,
  setRadioButtonValue,
}) => {
  const { t } = useTranslation('compilations');
  const [applyFlag, setApplyFlag] = useState(false);

  useEffect(() => {
    if (!chosenCompilation?.themeId?.length) {
      setApplyFlag(false);
      nullifyRadioButtonValue();
    }
  }, [chosenCompilation]);

  const cancelHandler = () => {
    onCancel();
    if (!applyFlag) nullifyRadioButtonValue();
    if (searchParams?.themeId?.[0]) setRadioButtonValue(searchParams.themeId[0]);
  };

  const applyHandler = () => {
    setSearchParams(chosenCompilation);
    setApplyFlag(true);
    onCancel();
  };

  const modalFooter = (
    <div>
      <Button onClick={cancelHandler} type='secondary'>
        {t('cancel')}
      </Button>
      <Button onClick={applyHandler} type='primary'>
        {t('apply')}
      </Button>
    </div>
  );

  return (
    <Modal
      width={800}
      open={open}
      title={'Выберите темы подборок'}
      cardOn={true}
      modificate={true}
      footer={modalFooter}
      onCancel={cancelHandler}
      zIndex={3000}
    >
      <div>{stuff}</div>
    </Modal>
  );
};

export default DialogFilterSelectCompilation;
