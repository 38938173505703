// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-QigZud.user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-gyaigu .user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-label-ovCwyu[data-value="Montserrat"]:before, .user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-QigZud.user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-gyaigu .user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-item-jOX8s9[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-Montserrat-xmD5XR {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-MarkdownViewer-MarkdownViewer-module__ignoreSanitize-xu3xVy {
  border: 0;
  min-height: 600px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/MarkdownViewer/MarkdownViewer.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,SAAS;EACT,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ignoreSanitize {\n  min-height: 600px;\n  border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-QigZud`,
	"qlPicker": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-QigZud`,
	"ql-font": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-gyaigu`,
	"qlFont": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-gyaigu`,
	"ql-picker-label": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-label-ovCwyu`,
	"qlPickerLabel": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-label-ovCwyu`,
	"ql-picker-item": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-item-jOX8s9`,
	"qlPickerItem": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-picker-item-jOX8s9`,
	"ql-font-Montserrat": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-Montserrat-xmD5XR`,
	"qlFontMontserrat": `user-src-components-MarkdownViewer-MarkdownViewer-module__ql-font-Montserrat-xmD5XR`,
	"ignoreSanitize": `user-src-components-MarkdownViewer-MarkdownViewer-module__ignoreSanitize-xu3xVy`
};
export default ___CSS_LOADER_EXPORT___;
