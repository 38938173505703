import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { clearFoundGroups, clearGroups } from '@/store/chat/actions';

import { ASIDE_ELEMENTS } from './constants.jsx';

import classNames from 'classnames';

import styles from './Aside.module.scss';

const Aside = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation('chat');

  const handleClearGroups = path => {
    if (path === location.pathname) return;
    dispatch(clearGroups());
    dispatch(clearFoundGroups());
  };

  const renderElements = () => {
    return ASIDE_ELEMENTS.map((element, index) => (
      <Link
        className={classNames(styles.aside__element, {
          [styles.aside__element_active]: element.path === location.pathname,
        })}
        key={index}
        to={element.path}
        onClick={() => handleClearGroups(element.path)}
      >
        {element.image}
        <p className={styles.aside__text}>{t(element.text)}</p>
      </Link>
    ));
  };

  return <div className={styles.aside}>{renderElements()}</div>;
};

export default Aside;
