// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-NewContextSearch-style-module__ql-picker-lkYcTD.user-src-components-NewContextSearch-style-module__ql-font-UU2Mnc .user-src-components-NewContextSearch-style-module__ql-picker-label-O9vdHU[data-value="Montserrat"]:before, .user-src-components-NewContextSearch-style-module__ql-picker-lkYcTD.user-src-components-NewContextSearch-style-module__ql-font-UU2Mnc .user-src-components-NewContextSearch-style-module__ql-picker-item-NdJvzj[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-NewContextSearch-style-module__ql-font-Montserrat-E7jPMO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-NewContextSearch-style-module__context-search-ilbnwx {
  width: 100%;
  display: flex;
}

@media (min-width: 320px) and (max-width: 768px) {
  .user-src-components-NewContextSearch-style-module__context-search-ilbnwx {
    flex-direction: column;
  }
}

.user-src-components-NewContextSearch-style-module__context-search__form-item-miHHSx:nth-child(2) {
  width: 100%;
}

.user-src-components-NewContextSearch-style-module__context-search__input-LmeF06 {
  border-radius: 0 !important;
}

@media (min-width: 320px) and (max-width: 768px) {
  .user-src-components-NewContextSearch-style-module__context-search__input-LmeF06 {
    width: 80%;
    padding: 10px 0 4px;
    border-radius: var(--ant-border-radius-lg) 0 0 var(--ant-border-radius-lg) !important;
  }
}

.user-src-components-NewContextSearch-style-module__context-search__select-qFwyq4 div {
  border-radius: var(--ant-border-radius-lg) 0 0 var(--ant-border-radius-lg) !important;
  min-width: 160px !important;
}

@media (min-width: 320px) and (max-width: 768px) {
  .user-src-components-NewContextSearch-style-module__context-search__select-qFwyq4 div {
    border-radius: var(--ant-border-radius-lg) !important;
  }
}

.user-src-components-NewContextSearch-style-module__context-search__button-aaqDWx {
  border-radius: 0 var(--ant-border-radius-lg) var(--ant-border-radius-lg) 0;
  min-width: 60px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/NewContextSearch/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,aAAa;AACf;;AAMA;EACE;IAHE,sBAAsB;EACxB;AACF;;AAKA;EAFE,WAAW;AACb;;AAIA;EADE,2BAA2B;AAC7B;;AAGA;EACE;IAAE,UAAU;IACV,mBAAmB;IACnB,qFAAqF;EACvF;AACF;;AAEA;EACE,qFAAqF;EACrF,2BAA2B;AAC7B;;AACA;EACE;IAEE,qDAAqD;EACvD;AACF;;AAAA;EAGE,0EAA0E;EAC1E,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.context-search {\n  display: flex;\n  width: 100%;\n}\n@media (width >= 320px) and (max-width: 768px) {\n  .context-search {\n    flex-direction: column;\n  }\n}\n.context-search__form-item:nth-child(2) {\n  width: 100%;\n}\n.context-search__input {\n  border-radius: 0 !important;\n}\n@media (width >= 320px) and (max-width: 768px) {\n  .context-search__input {\n    width: 80%;\n    border-radius: var(--ant-border-radius-lg) 0 0 var(--ant-border-radius-lg) !important;\n    padding: 10px 0 4px;\n  }\n}\n.context-search__select div {\n  border-radius: var(--ant-border-radius-lg) 0 0 var(--ant-border-radius-lg) !important;\n  min-width: 160px !important;\n}\n@media (width >= 320px) and (max-width: 768px) {\n  .context-search__select div {\n    border-radius: var(--ant-border-radius-lg) !important;\n  }\n}\n.context-search__button {\n  border-radius: 0 var(--ant-border-radius-lg) var(--ant-border-radius-lg) 0;\n  min-width: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-NewContextSearch-style-module__ql-picker-lkYcTD`,
	"qlPicker": `user-src-components-NewContextSearch-style-module__ql-picker-lkYcTD`,
	"ql-font": `user-src-components-NewContextSearch-style-module__ql-font-UU2Mnc`,
	"qlFont": `user-src-components-NewContextSearch-style-module__ql-font-UU2Mnc`,
	"ql-picker-label": `user-src-components-NewContextSearch-style-module__ql-picker-label-O9vdHU`,
	"qlPickerLabel": `user-src-components-NewContextSearch-style-module__ql-picker-label-O9vdHU`,
	"ql-picker-item": `user-src-components-NewContextSearch-style-module__ql-picker-item-NdJvzj`,
	"qlPickerItem": `user-src-components-NewContextSearch-style-module__ql-picker-item-NdJvzj`,
	"ql-font-Montserrat": `user-src-components-NewContextSearch-style-module__ql-font-Montserrat-E7jPMO`,
	"qlFontMontserrat": `user-src-components-NewContextSearch-style-module__ql-font-Montserrat-E7jPMO`,
	"context-search": `user-src-components-NewContextSearch-style-module__context-search-ilbnwx`,
	"contextSearch": `user-src-components-NewContextSearch-style-module__context-search-ilbnwx`,
	"context-search__form-item": `user-src-components-NewContextSearch-style-module__context-search__form-item-miHHSx`,
	"contextSearchFormItem": `user-src-components-NewContextSearch-style-module__context-search__form-item-miHHSx`,
	"context-search__input": `user-src-components-NewContextSearch-style-module__context-search__input-LmeF06`,
	"contextSearchInput": `user-src-components-NewContextSearch-style-module__context-search__input-LmeF06`,
	"context-search__select": `user-src-components-NewContextSearch-style-module__context-search__select-qFwyq4`,
	"contextSearchSelect": `user-src-components-NewContextSearch-style-module__context-search__select-qFwyq4`,
	"context-search__button": `user-src-components-NewContextSearch-style-module__context-search__button-aaqDWx`,
	"contextSearchButton": `user-src-components-NewContextSearch-style-module__context-search__button-aaqDWx`
};
export default ___CSS_LOADER_EXPORT___;
