/* eslint-disable */
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Alert } from 'antd';

import _ from 'lodash';

const MAX_COMPETENCES_FOR_RADAR = 25;
const MAX_SCALES_FOR_RADAR = 1;

export const ReportRadar = () => {
  const { t } = useTranslation('D360_Report');
  const { project } = useSelector(state => state.d360Project);
  const { report } = useSelector(state => state.d360Report);

  const getRadarData = () => {
    let competencesFilter = report.filter(a => a.type === 'competence');

    const selfData = [];
    const managersData = [];
    const subordinateData = [];
    const colleaguesData = [];
    const othersData = [];
    const averageData = [];

    let competences = competencesFilter.map(e => {
      selfData.push(_.isNumber(e.self) ? e.self : 0);
      managersData.push(_.isNumber(e.manager) ? e.manager : 0);
      subordinateData.push(_.isNumber(e.subordinate) ? e.subordinate : 0);
      colleaguesData.push(_.isNumber(e.colleague) ? e.colleague : 0);
      othersData.push(_.isNumber(e.cross) ? e.cross : 0);
      averageData.push(_.isNumber(e.median) ? e.median : 0);
      return e.name;
    });

    const data = {
      labels: competences,
      datasets: [
        {
          label: 'Самооценка',
          data: selfData,
          backgroundColor: 'rgba(34,84,192,.1)',
          borderColor: 'rgb(34,84,192)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(34,84,192)',
          pointHoverRadius: 10,
          fill: false,
        },
        {
          label: 'Руководители',
          data: managersData,
          backgroundColor: 'rgba(231,103,58,.1)',
          borderColor: 'rgb(231,103,58)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(231,103,58)',
          pointHoverRadius: 10,
        },
        {
          label: 'Подчиненные',
          data: subordinateData,
          backgroundColor: 'rgba(162,199,12,.1)',
          borderColor: 'rgb(162,199,12)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(162,199,12)',
          pointHoverRadius: 10,
        },
        {
          label: 'Коллеги',
          data: colleaguesData,
          backgroundColor: 'rgba(121,4,149,.1)',
          borderColor: 'rgb(121,4,149)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(121,4,149)',
          pointHoverRadius: 10,
        },
        {
          label: 'Другие',
          data: othersData,
          backgroundColor: 'rgba(167,3,95,0.1)',
          borderColor: 'rgb(167,3,95)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(167,3,95)',
          pointHoverRadius: 10,
        },
        {
          label: 'Средняя',
          data: averageData,
          backgroundColor: 'rgba(154,154,154,.1)',
          borderColor: 'rgb(154,154,154)',
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: 'rgb(154,154,154)',
          pointHoverRadius: 10,
        },
      ],
    };

    return data;
  };

  const getRadarOptions = () => {
    const competencesFilter = report.filter(a => a.type === 'competence');
    let maxTicks = 0;
    competencesFilter.forEach(e => {
      if (e.maxscore > maxTicks) {
        maxTicks = e.maxscore;
      }
    });

    return {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => data['labels'][tooltipItem[0]['index']],
        },
      },
      scale: {
        pointLabels: {
          fontSize: 14,
          fontStyle: '300',
          fontColor: 'rgba(0,0,0,1)',
          fontFamily: "'Lato', sans-serif'",
        },
        ticks: {
          min: 0,
          max: maxTicks,
          stepSize: 1,
          showLabelBackdrop: false,
          backgroundColor: 'rgba(203, 197, 11, 1)',
        },
        angleLines: {
          color: 'rgba(0,0,0,.3)',
          lineWidth: 1,
        },
        gridLines: {
          color: 'rgba(0,0,0,.3)',
          lineWidth: 1,
        },
      },
    };
  };

  const RenderRadar = () => {
    if (!_.isNil(project)) {
      if (_.isObject(project)) {
        if (
          _.has(project, 'competenceCount') &&
          _.isNumber(project.competenceCount) &&
          project.competenceCount > MAX_COMPETENCES_FOR_RADAR
        ) {
          return <Alert style={{ marginTop: 20 }} type='warning' showIcon message={t('radarNotAvailable1')} />;
        }

        if (
          _.has(project, 'scaleCount') &&
          _.isNumber(project.scaleCount) &&
          project.scaleCount === MAX_SCALES_FOR_RADAR
        ) {
          return <Radar data={getRadarData()} options={getRadarOptions()} width={980} height={490} />;
        } else {
          return <Alert style={{ marginTop: 20 }} type='warning' showIcon message={t('radarNotAvailable2')} />;
        }
      }
    }

    return null;
  };

  return <RenderRadar />;
};

export default ReportRadar;
