/* eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';

import 'scorm-again';
import '@shared/services/i18n';

import Root from '@/pages/Root';

import 'react-notifications/lib/notifications.css';
import 'video-react/dist/video-react.css';
import '@shared/styles/month-picker.css';
import '@/styles/index.css';
import '@shared/styles/style.css';
import '@shared/services/init-dayjs';
import '@sharedPartner/fonts/index.scss';

document.title = process.env?.VITE_APP_TITLE || 'KAMPUS';
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Root />);
