/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';

import MaterialCost from '../MaterialCard/MaterialCost';

import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }
  if (Array.isArray(field)) {
    return !field.length;
  }
  return !field;
};

export class FieldItem extends Component {
  static propTypes = {
    field: PropTypes.object,
    getFile: PropTypes.func,
    handleAuthorClick: PropTypes.func,
    typeId: PropTypes.number,
    value: PropTypes.any,
    currentUser: PropTypes.object,
    topic: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { value, field, typeId } = this.props;
    if (isFieldEmpty(value)) {
      return null;
    }
    if (field.type === 'files') {
      const files =
        Array.isArray(value) &&
        value.map(file => (
          <div
            key={file.uuid}
            className={css['Details-topic-file']}
            target='_blank'
            onClick={() =>
              this.props.getFile(file.uuid, res => {
                this.setState({ fileSrc: URL.createObjectURL(res) });
              })
            }
          >
            {file.name}
            <svg xmlns='http://www.w3.org/2000/svg' width='8' height='9' viewBox='0 0 8 9'>
              <path d='m1 4.5 2.86 2.86L6.72 4.5m-2.97 2V1.444' stroke='currentColor' fill='none' />
            </svg>
          </div>
        ));
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{files}</div>
        </div>
      );
    }
    if (field.type === 'tagsInput') {
      const tags =
        Array.isArray(value) &&
        value.map((tag, index) => (
          <a key={index} href={tag.url} className={css['Details-topic-link']} target='_blank'>
            {tag.name}
          </a>
        ));
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div className={css['Details-value']}>{tags}</div>
        </div>
      );
    }
    if (field.type === 'authorsInput') {
      const author =
        Array.isArray(value) &&
        value.map(auth => (
          <div
            key={auth.id}
            className={css['Details-topic-authors']}
            onClick={() => this.props.handleAuthorClick(auth)}
          >
            {auth.name}
          </div>
        ));
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div className={css['Details-value']}>{author}</div>
        </div>
      );
    }
    if (field.type === 'timeInput') {
      if (typeId === 20 || isCorpCourseWithOrWithoutApprove(typeId) || typeId === 1) {
        const mins = Number(value) || 0;
        const hours = Math.trunc(mins / 60);
        const minutes = mins % 60;
        if (hours === 0 && hours === 0) {
          return (
            <div className={css['Details-card-item']}>
              <div className={css['Details-label']}>{field.label}</div>
              <div>{`${minutes} ${this.props.t('minutes')}`}</div>
            </div>
          );
        }
        if (minutes > 0 && hours !== 0) {
          return (
            <div className={css['Details-card-item']}>
              <div className={css['Details-label']}>{field.label}</div>
              <div>{`${hours} ${noun(
                hours,
                this.props.t('hour1'),
                this.props.t('hour2'),
                this.props.t('hour3')
              )} ${minutes} ${this.props.t('minutes')}`}</div>
            </div>
          );
        }
        return (
          <div className={css['Details-card-item']}>
            <div className={css['Details-label']}>{field.label}</div>
            <div>{`${hours} ${noun(hours, this.props.t('hour1'), this.props.t('hour2'), this.props.t('hour3'))}`}</div>
          </div>
        );
      }
      let minutes = Number(value) || 0;
      const weeks = Math.floor(minutes / (24 * 60 * 7));
      const weeksString = weeks ? `${weeks} ${noun(weeks, this.props.t('week1'), this.props.t('week2'))}` : '';
      minutes -= weeks * 24 * 60 * 7;
      const days = Math.floor(minutes / (24 * 60));
      const daysString = days ? `${days} ${noun(days, this.props.t('day1'), this.props.t('day2'))}` : '';
      minutes -= days * 24 * 60;
      const hours = Math.floor(minutes / 60);
      const hoursString = hours
        ? `${hours} ${noun(hours, this.props.t('hour1'), this.props.t('hour2'), this.props.t('hour3'))}`
        : '';
      minutes -= hours * 60;
      const minutesString = minutes ? `${minutes} ${this.props.t('minutes')}` : '';
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{[weeksString, daysString, hoursString, minutesString].join(' ').replace(/\s+/g, ' ')}</div>
        </div>
      );
    }
    if (field.type === 'numberInput') {
      const placeholder = field.apiField === 'cost' ? this.props.t('free') : '';
      const label = field.apiField === 'cost' && typeId === 20 ? this.props.t('maxCost') : field.label;
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{label}</div>
          <div>
            <MaterialCost topic={this.props.topic} currentUser={this.props.currentUser} />
          </div>
        </div>
      );
    }
    if (field.type === 'checkbox') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{value ? this.props.t('yes') : this.props.t('no')}</div>
        </div>
      );
    }
    if (field.type === 'calendar') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{dayjs(value).format('DD.MM.YYYY')}</div>
        </div>
      );
    }
    if (field.type === 'year') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{dayjs(value).format('YYYY')}</div>
        </div>
      );
    }
    if (field.type === 'yearIssued') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{dayjs(value).format('YYYY')}</div>
        </div>
      );
    }
    if (field.type === 'videoStyleselector' || field.type === 'topicClassSelector') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{value && value.name}</div>
        </div>
      );
    }
    if (field.type === 'dateTimeInput') {
      return (
        <div className={css['Details-card-item']}>
          <div className={css['Details-label']}>{field.label}</div>
          <div>{dayjs(value, 'HH:mm').format('HH:mm')}</div>
        </div>
      );
    }

    return (
      <div className={css['Details-card-item']}>
        <div className={css['Details-label']}>{field.label}</div>
        <div>{value}</div>
      </div>
    );
  }
}
export default withTranslation('fieldItem')(FieldItem);
