import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar, Button, Input, Typography } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import { FiEdit2, FiX } from 'react-icons/fi';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from './Comment.module.scss';

const { Text } = Typography;
const { TextArea } = Input;

const Comment = ({ data, actions, currentUser }) => {
  const [currentAvatar] = useLocalFileByUUID(data?.user?.localPhotoUuid, { defaultImg });

  const [textAreaValue, setTextAreaValue] = useState(data?.text);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { t } = useTranslation('commentsComponent');

  const handleDelete = () => {
    setIsDeleted(true);
    actions.onDelete(data.id);
  };

  const handleUpdate = () => {
    setIsUpdate(false);
    actions.onUpdate(data.id, textAreaValue.trim());
  };

  const handleRestore = () => {
    setIsDeleted(false);
    actions.onRestore(data.id);
  };

  useEffect(() => {
    setTextAreaValue(data?.text);
  }, [data]);

  const renderComment = () => {
    if (isDeleted) {
      return (
        <div className={styles.comment__restore}>
          <Text className={styles.comment__text}>{t('commentDelete')}</Text>
          <Text onClick={handleRestore} className={classNames(styles.comment__text, styles.comment__text_blue)}>
            {t('restore')}
          </Text>
        </div>
      );
    } else if (isUpdate) {
      return (
        <div className={styles.comment__changes}>
          <TextArea
            className={styles.comment__textarea}
            value={textAreaValue}
            onChange={event => setTextAreaValue(event.target.value)}
            maxLength={1500}
          />
          <div className={styles.comment__buttons}>
            <Button onClick={handleUpdate} disabled={!textAreaValue?.trim()} type='primary' size='large'>
              {t('save')}
            </Button>
            <Button onClick={() => setIsUpdate(false)} size='large'>
              {t('cancel')}
            </Button>
          </div>
        </div>
      );
    } else {
      return <Text className={styles.comment__text}>{textAreaValue}</Text>;
    }
  };

  return (
    <div className={styles.comment}>
      <Link to={`/collegues/${data?.user?.id}`}>
        <Avatar size={32} src={currentAvatar} alt='' />
      </Link>
      <div className={styles.comment__block}>
        <div className={styles.comment__header}>
          <Text className={styles.comment__name}>
            {data?.user?.firstName} {data?.user?.lastName}
          </Text>
          {!isDeleted && !isUpdate && currentUser.id === data?.user.id && (
            <div className={styles.comment__actions}>
              <FiEdit2 onClick={() => setIsUpdate(true)} className={styles.comment__action} size={14} />
              <FiX onClick={handleDelete} className={styles.comment__action} size={18} />
            </div>
          )}
        </div>
        <div className={styles.comment__main}>
          <Text className={styles.comment__date}>{dayjs(data?.createdDate).format('LL')}</Text>
          {renderComment()}
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      localPhotoUuid: PropTypes.string.isRequired,
    }),
  }),
  actions: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
  }),
};

export default memo(Comment);
