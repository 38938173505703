import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectUsers = state => state.users;
export const selectCurrentUser = state => _get(state, 'users.currentUser');
export const selectUserDomainCompany = state => _get(state, 'users.currentUser.domainCompany');

const selectCorpCourseBackground = state => _get(state, 'users.corpCourseBackground');
const selectCorpCourseIcon = state => _get(state, 'users.corpCourseIcon');

export const selectUserDomainCompanyImages = createSelector(
  [selectCorpCourseBackground, selectCorpCourseIcon],
  (corpCourseBackground, corpCourseIcon) => ({
    corpCourseBackground,
    corpCourseIcon,
  })
);
