/* eslint-disable */
import React, { Component } from 'react';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Input.module.scss';

export default class CustomInput extends Component {
  static propTypes = {
    calendarIcon: PropTypes.any,
    className: PropTypes.string,
    dataqa: PropTypes.string,
    dark: PropTypes.any,
    deletable: PropTypes.bool,
    handleInputDelete: PropTypes.func,
    linkItem: PropTypes.bool,
    modificate: PropTypes.bool,
    noCursor: PropTypes.bool,
    onChange: PropTypes.func,
    onFocusDeleteInputConfirm: PropTypes.func,
    placeholder: PropTypes.string,
    showPassword: PropTypes.bool,
  };

  render() {
    const {
      className,
      dataqa,
      dark,
      modificate,
      calendarIcon,
      noCursor,
      onChange,
      linkItem,
      handleInputDelete,
      ...rest
    } = this.props;
    if (linkItem) {
      const deleteButton = this.props.deletable ? (
        <div
          className={css['delete-button']}
          onClick={handleInputDelete}
          onMouseEnter={this.props.onFocusDeleteInputConfirm}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='9' height='8' viewBox='0 0 18 17'>
            <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='currentColor' fill='none' />
          </svg>
        </div>
      ) : null;
      return (
        <div className={css.block}>
          <Input
            {...rest}
            onChange={onChange}
            placeholder={this.props.placeholder}
            className={classNames(className, css.Input, { [css.dark]: dark }, { [css.modificate]: modificate })}
          />
          {deleteButton}
        </div>
      );
    }
    return (
      <div className={classNames({ [css.calendarIconBox]: calendarIcon })}>
        <Input
          {...rest}
          data-qa={dataqa}
          onChange={onChange}
          placeholder={this.props.placeholder}
          className={classNames(
            className,
            css.Input,
            { [css.dark]: dark },
            { [css.modificate]: modificate },
            { [css.showPassword]: this.props.showPassword },
            { [css.noCursor]: noCursor }
          )}
        />
        <div className={classNames({ [css.calendarIcon]: calendarIcon })}>{calendarIcon}</div>
      </div>
    );
  }
}
