// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-pesa6_.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-MzuMXB .user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-label-VYirnQ[data-value="Montserrat"]:before, .user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-pesa6_.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-MzuMXB .user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-item-rFSzRb[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-Montserrat-_ZQyPL {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutive-HyA41m {
  background-color: #fff;
}

.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveRow-zeZFbu {
  margin-top: .8rem;
}

.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemTitle-nRN9fW {
  font-weight: 500;
}

.user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemBody-gD4g3V {
  text-align: justify;
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/MaterialsTabs/MaterialsTabsExecutive.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;AACxB;;AAOA;EAJE,iBAAiB;AACnB;;AAOA;EAJE,gBAAgB;AAClB;;AAOA;EAJE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.MaterialsTabsExecutive {\n  background-color: #fff;\n}\n\n.MaterialsTabsExecutiveRow {\n  margin-top: 0.8rem;\n}\n\n.MaterialsTabsExecutiveItemTitle {\n  font-weight: 500;\n}\n\n.MaterialsTabsExecutiveItemBody {\n  font-weight: 300;\n  text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-pesa6_`,
	"qlPicker": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-pesa6_`,
	"ql-font": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-MzuMXB`,
	"qlFont": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-MzuMXB`,
	"ql-picker-label": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-label-VYirnQ`,
	"qlPickerLabel": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-label-VYirnQ`,
	"ql-picker-item": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-item-rFSzRb`,
	"qlPickerItem": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-picker-item-rFSzRb`,
	"ql-font-Montserrat": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-Montserrat-_ZQyPL`,
	"qlFontMontserrat": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__ql-font-Montserrat-_ZQyPL`,
	"MaterialsTabsExecutive": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutive-HyA41m`,
	"materialsTabsExecutive": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutive-HyA41m`,
	"MaterialsTabsExecutiveRow": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveRow-zeZFbu`,
	"materialsTabsExecutiveRow": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveRow-zeZFbu`,
	"MaterialsTabsExecutiveItemTitle": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemTitle-nRN9fW`,
	"materialsTabsExecutiveItemTitle": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemTitle-nRN9fW`,
	"MaterialsTabsExecutiveItemBody": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemBody-gD4g3V`,
	"materialsTabsExecutiveItemBody": `user-src-components-MaterialsTabs-MaterialsTabsExecutive-module__MaterialsTabsExecutiveItemBody-gD4g3V`
};
export default ___CSS_LOADER_EXPORT___;
