// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-D360-Survey-index-module__ql-picker-iJNIpy.user-src-pages-D360-Survey-index-module__ql-font-kDOhgs .user-src-pages-D360-Survey-index-module__ql-picker-label-ggE1im[data-value="Montserrat"]:before, .user-src-pages-D360-Survey-index-module__ql-picker-iJNIpy.user-src-pages-D360-Survey-index-module__ql-font-kDOhgs .user-src-pages-D360-Survey-index-module__ql-picker-item-_yFJIa[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-D360-Survey-index-module__ql-font-Montserrat-YiZDzz {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-D360-Survey-index-module__RightPane-zNZCiu {
  margin-left: 2rem;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/D360/Survey/index.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.RightPane {\n  margin-left: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-D360-Survey-index-module__ql-picker-iJNIpy`,
	"qlPicker": `user-src-pages-D360-Survey-index-module__ql-picker-iJNIpy`,
	"ql-font": `user-src-pages-D360-Survey-index-module__ql-font-kDOhgs`,
	"qlFont": `user-src-pages-D360-Survey-index-module__ql-font-kDOhgs`,
	"ql-picker-label": `user-src-pages-D360-Survey-index-module__ql-picker-label-ggE1im`,
	"qlPickerLabel": `user-src-pages-D360-Survey-index-module__ql-picker-label-ggE1im`,
	"ql-picker-item": `user-src-pages-D360-Survey-index-module__ql-picker-item-_yFJIa`,
	"qlPickerItem": `user-src-pages-D360-Survey-index-module__ql-picker-item-_yFJIa`,
	"ql-font-Montserrat": `user-src-pages-D360-Survey-index-module__ql-font-Montserrat-YiZDzz`,
	"qlFontMontserrat": `user-src-pages-D360-Survey-index-module__ql-font-Montserrat-YiZDzz`,
	"RightPane": `user-src-pages-D360-Survey-index-module__RightPane-zNZCiu`,
	"rightPane": `user-src-pages-D360-Survey-index-module__RightPane-zNZCiu`
};
export default ___CSS_LOADER_EXPORT___;
