/* eslint-disable */
import {
  GET_POSITION_FAIL,
  GET_POSITION_START,
  GET_POSITION_SUCCESS,
  GET_POSITIONS_FAIL,
  GET_POSITIONS_START,
  GET_POSITIONS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getPositions = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/position${getQueryString({
    sort: 'name,asc',
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSITIONS_START, GET_POSITIONS_SUCCESS, GET_POSITIONS_FAIL],
  onSuccess,
});

export const getPositionsByUserName = (name = '', topicId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/search/user/positions${getQueryString({
    name,
    topicId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_POSITION_START, GET_POSITION_SUCCESS, GET_POSITION_FAIL],
  onSuccess,
});
