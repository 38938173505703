/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { checkedIsAvailableForStart, getTypeform, setTypeformFinish, setTypeformStart } from '@/store/lms/actions';

import { selectLMS } from '@/store/lms/selectors';

import { Button } from 'antd';
import Condition from '@/components/Condition';

import css from './index.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

export const Typeform = ({ block, getStatistic, getStatisticStart }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('typeform');

  const { statistic, arrayChecked } = useSelector(selectLMS);

  const [link, setLink] = useState(null);

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  useEffect(() => {
    if (block.blockId) {
      dispatch(
        getTypeform(block.blockId, res => {
          setLink(res.link);
        })
      );
    }
    if (block.isAvailableForStart && !arrayChecked?.includes(block?.blockId)) {
      dispatch(
        setTypeformStart(block.blockId, () => {
          getStatisticStart();
        })
      );
      dispatch(checkedIsAvailableForStart(block?.blockId));
    }
  }, [block]);

  const finishTypeformBlock = () => {
    if (block.status === 'NOT_STARTED') {
      dispatch(setTypeformFinish(block.blockId, () => getStatistic(next <= 1)));
    }
  };

  return (
    <div className={css['Typeform-wrapper']}>
      <Condition when={block}>
        <div className={css['Typeform-header']}>
          <div>
            <div className={css['Typeform-typeTitle']}>{t('typeform')}</div>
            <div className={css['Typeform-title']}>{block.name}</div>
            <div className={css['Typeform-text']}>{t('typeformText')}</div>
          </div>
        </div>
        <div className={css['Typeform-button-wrapper']}>
          {block.status !== 'FINISHED' ? (
            <Button
              size='large'
              className={css['Typeform-button']}
              onClick={finishTypeformBlock}
              href={link}
              target='_blank'
              type='primary'
            >
              {next > 1 ? t('next') : t('end')}
            </Button>
          ) : (
            <Button size='large' className={css['Typeform-button']} href={link} target='_blank' type='primary'>
              {t('restart')}
            </Button>
          )}
        </div>
      </Condition>
    </div>
  );
};
