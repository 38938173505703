import dayjs from 'dayjs';

export const getDateObj = dayjs;

export const formatFullDate = date => dayjs(date).format('D MMMM YYYY');

export const formatShortDate = date => dayjs(date).format('MMMM YYYY');

export const formatTime = (date, format) => dayjs(date, format).format('HH:mm');

export const currentFullYear = () => dayjs().format('YYYY');
