/* eslint-disable */
import { API_BASE } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';

export const GET_FAQ_START = 'GET_FAQ_START';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getFaq = onSuccess => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/faq`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_FAQ_START, GET_FAQ_SUCCESS, GET_FAQ_FAIL],
});

export const putFaq = data => ({
  type: REST_API,
  method: PUT,
  url: `${API_BASE}/api/v1/faq/${data.id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    ...data,
  },
  types: [GET_FAQ_START, GET_FAQ_SUCCESS, GET_FAQ_FAIL],
});
