import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';
import { createAsyncAction } from '@shared/utils/redux-helpers';

import { ACTIVE } from '@/constants/user-statuses';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_POSITION_START = 'GET_USER_POSITION_START';
export const GET_USER_POSITION_SUCCESS = 'GET_USER_POSITION_SUCCESS';
export const GET_USER_POSITION_FAIL = 'GET_USER_POSITION_FAIL';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const GET_USER_BY_ID_START = 'GET_USER_BY_ID_START';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_ANOTHER_USER_BY_ID_START = 'GET_ANOTHER_USER_BY_ID_START';
export const GET_ANOTHER_USER_BY_ID_SUCCESS = 'GET_ANOTHER_USER_BY_ID_SUCCESS';
export const GET_ANOTHER_USER_BY_ID_FAIL = 'GET_ANOTHER_USER_BY_ID_FAIL';

export const LOADAVATAR_USER_START = 'LOADAVATAR_USER_START';
export const LOADAVATAR_USER_SUCCESS = 'LOADAVATAR_USER_SUCCESS';
export const LOADAVATAR_USER_FAIL = 'LOADAVATAR_USER_FAIL';

export const UPDATE_AVATAR_USER_START = 'UPDATE_AVATAR_USER_START';
export const UPDATE_AVATAR_USER_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_USER_FAIL = 'UPDATE_AVATAR_FAIL';

export const GET_USER_SKILL_START = 'GET_USER_SKILL_START';
export const GET_USER_SKILL_SUCCESS = 'GET_USER_SKILL_SUCCESS';
export const GET_USER_SKILL_FAIL = 'GET_USER_SKILL_FAIL';

export const CLEAR_USERS = 'CLEAR_USERS';

export const SHOW_TRAJECTORY_MODAL = 'SHOW_TRAJECTORY_MODAL';
export const HIDE_TRAJECTORY_MODAL = 'HIDE_TRAJECTORY_MODAL';

export const LOGIN_REDIRECT_START = 'LOGIN_REDIRECT_START';
export const LOGIN_REDIRECT_SUCCESS = 'LOGIN_REDIRECT_SUCCESS';
export const LOGIN_REDIRECT_FAIL = 'LOGIN_REDIRECT_FAIL';

export const GET_FUNCTION_START = 'GET_FUNCTION_START';
export const GET_FUNCTION_SUCCESS = 'GET_FUNCTION_SUCCESS';
export const GET_FUNCTION_FAIL = 'GET_FUNCTION_FAIL';

export const FIRST_LOGIN_START = 'FIRST_LOGIN_START';
export const FIRST_LOGIN_SUCCESS = 'FIRST_LOGIN_SUCCESS';
export const FIRST_LOGIN_FAIL = 'FIRST_LOGIN_FAIL';

export const CHANGE_USER_PROPERTIES = 'CHANGE_USER_PROPERTIES';

const headers = {
  Accept: 'application/json',
};

const defaultToken = '';

export const getUser = ({ onSuccess, onError } = {}, token) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    Authorization: token || localStorage.getItem('authorizationToken'),
  },
  types: [GET_USER_START, GET_USER_SUCCESS, GET_USER_FAIL],
  onSuccess,
  onError,
  withoutRefresh: true,
});

export const getUsers = (params, onSuccess, onError, searching, isStartLoad) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/users${getQueryString({
    sort: 'lastName,asc',
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USERS_START, GET_USERS_SUCCESS, GET_USERS_FAIL],
  searchingStr: searching,
  isStartLoad,
  onSuccess,
  onError,
});

export const clearUsers = () => ({
  type: CLEAR_USERS,
});

export const getUserById = (isAnotherUser, userId) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(userId)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: !isAnotherUser
    ? [GET_USER_BY_ID_START, GET_USER_BY_ID_SUCCESS, GET_USER_BY_ID_FAIL]
    : [GET_ANOTHER_USER_BY_ID_START, GET_ANOTHER_USER_BY_ID_SUCCESS, GET_ANOTHER_USER_BY_ID_FAIL],
});

export const getUserPositions = ({ size }) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/position${getQueryString({
    size: size || 20,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_POSITION_START, GET_USER_POSITION_SUCCESS, GET_USER_POSITION_FAIL],
});

export const activateUser = (userId, about, localPhotoUuid, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    'Content-Type': 'application/json',
  },
  body: {
    id: userId,
    about,
    localPhotoUuid,
    status: ACTIVE,
  },
  types: [EDIT_USER_START, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
  onSuccess,
});

export const editAboutUser = (userId, about, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { id: userId, about },
  types: [EDIT_USER_START, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
  onSuccess,
});

export const updateAvatarUser = params => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [UPDATE_AVATAR_USER_START, UPDATE_AVATAR_USER_SUCCESS, UPDATE_AVATAR_USER_FAIL],
});

export const overwriteValuesUser = (oldValues, newValues, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user`,
  headers: {
    ...headers,
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { ...oldValues, ...newValues },
  types: [EDIT_USER_START, EDIT_USER_SUCCESS, EDIT_USER_FAIL],
  onSuccess,
});

export const loadAvatarUser = (file, posX, posY, width, height, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/service/file${getQueryString({
    posX,
    posY,
    width,
    height,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: file,
  requestType: 'formData',
  types: [LOADAVATAR_USER_START, LOADAVATAR_USER_SUCCESS, LOADAVATAR_USER_FAIL],
  onSuccess,
  onError,
});

export const getSkillsUser = (userId, ids) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/skill${getQueryString({ userId, ids })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_SKILL_START, GET_USER_SKILL_SUCCESS, GET_USER_SKILL_FAIL],
});

export const loginRedirect = (username, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/login/redirect`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { username },
  onSuccess,
  types: [LOGIN_REDIRECT_START, LOGIN_REDIRECT_SUCCESS, LOGIN_REDIRECT_FAIL],
});

export const firstLoginUpdate = (onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/update/first-login`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [FIRST_LOGIN_START, FIRST_LOGIN_SUCCESS, FIRST_LOGIN_FAIL],
  onSuccess,
  onError,
});

export const updateCoins = () => ({
  type: GET_USER_SUCCESS,
});

export const showTrajectoryModal = () => ({
  type: SHOW_TRAJECTORY_MODAL,
});

export const hideTrajectoryModal = () => ({
  type: HIDE_TRAJECTORY_MODAL,
});

export const changeUserProperties = properties => ({
  type: CHANGE_USER_PROPERTIES,
  properties,
});

export const getUserDomainCompanyImages = createAsyncAction('getUserDomainCompanyImages');
