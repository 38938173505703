import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getFile } from '@/store/files/actions';
import { getProductById } from '@/store/shop/actions';

import { selectShop } from '@/store/shop/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Divider, Layout, Row, Spin, Tabs, Tooltip } from 'antd';
import { CoinsIcon } from '@/components/elements';
import { Content } from '@/components/Layout';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import PurchaseModal from '@/components/PurchaseModal';
import { AiOutlineEye } from 'react-icons/ai';

import TabAbout from './Tabs/TabAbout';

import classNames from 'classnames';
// import TabReviews from './Tabs/TabReviews'; 2 ЭТАП
import PropTypes from 'prop-types';

import css from './ShopId.module.scss';

const ShopId = ({ navigate }) => {
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('about');
  const { id } = useParams();
  const { product, isLoading } = useSelector(selectShop);
  const { currentUser } = useSelector(selectUsers);
  const dispatch = useDispatch();
  const { t } = useTranslation('shopId');

  useEffect(() => {
    // Создаём контроллер отмены запроса
    let controller = new AbortController();
    let signal = controller.signal;

    if (id) dispatch(getProductById(id, signal));

    // При "выходе" из компонента мы отменяем запрос на бэк
    return () => controller.abort();
  }, [id, dispatch]);

  useEffect(() => {
    // TODO: Возможно можно, как-то вынести AbortController из компонентов в api-service, чтобы не повторяться ¯\_(ツ)_/¯
    let controller = new AbortController();
    let signal = controller.signal;

    if (product?.photoUuid) {
      dispatch(
        getFile(
          product?.photoUuid,
          res => setImage(URL.createObjectURL(res)),
          () => setImageError(true),
          signal
        )
      );
    }

    return () => {
      controller.abort();
      setImage(null);
    };
  }, [product?.photoUuid, dispatch]);

  const changeIsVisible = () => setIsVisible(!isVisible);

  const renderButton = () => {
    switch (true) {
      case product?.available === false || currentUser?.account < product?.cost:
        return <p className={css['ShopId-right-message']}>{t('notEnoughBalance')}</p>;

      case product?.available === true:
        return (
          <Button className={css['ShopId-right-button']} onClick={() => changeIsVisible()} type='primary' size='large'>
            {t('buy')}
          </Button>
        );

      default:
        break;
    }
  };

  const tabItems = [
    {
      key: 'about',
      label: t('tabAbout'),
      children: <TabAbout description={product?.description} />,
    },
  ];

  return (
    <Layout>
      <Content>
        <Row className={css['ShopId-container']}>
          {!isLoading ? (
            <>
              <PurchaseModal visible={isVisible} changeVisible={changeIsVisible} product={product} productImg={image} />
              <div className={css['ShopId-container-header']}>
                <div className={css['ShopId-container-header-text']}>
                  <p className={css['ShopId-container-header-title']}>{product?.name}</p>
                  <p className={css['ShopId-container-header-subtitle']}>{t('subtitle')}</p>
                </div>
                <Tooltip title={t('close')}>
                  <ModalCloseIcon onClick={() => navigate('/shop')} />
                </Tooltip>
              </div>
              <Col span={18} className={css['ShopId-left']}>
                {product?.photoUuid && (
                  <img
                    className={classNames(
                      css['ShopId-left-image'],
                      (image || imageError) && css['ShopId-left-image-loaded']
                    )}
                    src={image}
                    alt='ProductImg'
                  />
                )}
                <div className={css['ShopId-left-detail']}>
                  <Tooltip placement='topRight' title={t('viewed')}>
                    <div className={css['ShopId-left-detail-viewed']}>
                      <AiOutlineEye size={22} />
                      <span className={css['ShopId-left-detail-viewed-value']}>{product?.viewed}</span>
                    </div>
                  </Tooltip>
                </div>
                <Tabs
                  className={css['ShopId-left-tabs']}
                  activeKey={activeTab}
                  onChange={key => setActiveTab(key)}
                  items={tabItems}
                />
              </Col>
              <Col span={6} className={css['ShopId-right']}>
                <div className={css['ShopId-right-detail']}>
                  <span className={css['ShopId-right-detail-title']}>{t('price')}</span>
                  <div className={css['ShopId-right-detail-value']}>
                    <span className={css['ShopId-right-detail-value-cost']}>{product?.cost}</span>
                    <CoinsIcon
                      uuidCoinsIcon={currentUser.domainCompany.coinIcon}
                      className={css['ShopId-right-detail-value-coin']}
                    />
                  </div>
                </div>
                {renderButton()}
                <Divider className={css['ShopId-right-detail-divider']} />
                <div className={css['ShopId-right-detail']}>
                  <span className={css['ShopId-right-detail-title']}>{t('availability')}</span>
                  <div className={css['ShopId-right-detail-value']}>{t('count', { count: product?.count })}</div>
                </div>
                {product?.bought > 0 && (
                  <div className={css['ShopId-right-detail']}>
                    <span className={css['ShopId-right-detail-title']}>{t('alreadyBought')}</span>
                    <div className={css['ShopId-right-detail-value']}>{t('bought', { bought: product?.bought })}</div>
                  </div>
                )}
                <Divider className={css['ShopId-right-detail-divider']} />
              </Col>
            </>
          ) : (
            <Spin />
          )}
        </Row>
      </Content>
    </Layout>
  );
};

ShopId.propTypes = {
  navigate: PropTypes.func,
};

export default ShopId;
