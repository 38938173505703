/* eslint-disable */
import React, { Component } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export default class ScrollArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    courses: PropTypes.bool,
    dateEvent: PropTypes.any,
    disabledSelect: PropTypes.bool,
    displayFormat: PropTypes.string,
    monthClassName: PropTypes.string,
    monthCount: PropTypes.number,
    openDate: PropTypes.any,
    outputFormat: PropTypes.string,
    quarterEndClassName: PropTypes.string,
    renderMonth: PropTypes.func.isRequired,
    startDate: PropTypes.any,
  };

  static defaultProps = {
    monthCount: 12,
    startDate: dayjs(),
  };

  render() {
    const {
      className,
      displayFormat,
      outputFormat,
      startDate,
      monthCount,
      monthClassName,
      renderMonth,
      quarterEndClassName,
      disabledSelect,
      dateEvent,
      openDate,
    } = this.props;
    const month = dayjs(startDate);
    const mainDate = dayjs(openDate).isAfter(dayjs(), 'day') ? openDate : dayjs();
    const currentDate = !disabledSelect
      ? this.props.courses
        ? dayjs(mainDate, 'day').add(5, 'days')
        : dayjs()
      : dayjs(dateEvent);
    const content = [];

    for (let i = 0; i < monthCount; i++) {
      const currentMonth = month.add(i, 'month');
      const monthProps = {
        displayFormat: currentMonth.format(displayFormat),
        outputFormat: currentMonth.format(outputFormat),
        isPast: currentMonth.isBefore(currentDate, 'month'),
        isCurrent: currentMonth.isSame(currentDate, 'month'),
      };
      const isEndOfQuarter = !((currentMonth.month() + 1) % 3);

      content.push(
        <div
          className={classNames(monthClassName, {
            [quarterEndClassName]: isEndOfQuarter,
          })}
          key={monthProps.outputFormat}
        >
          {renderMonth(monthProps)}
        </div>
      );
    }
    return <div className={className}>{content}</div>;
  }
}
