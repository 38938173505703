/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { createStudyPlan } from '@/store/study-plan/actions';
import { createUserTopic, deleteUserTopic } from '@/store/user-topics/actions';

import { selectOpenDate } from '@/store/course/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Alert, Card, Col, Divider, notification, Row, Spin, Tag } from 'antd';
import MaterialCard from '@/components/MaterialCard';

import CalendarModal from '../CalendarModal/index.jsx';

import { getWithoutSchedule, isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { SCHEDULED } from '@/constants/study-plan-statuses';

export class RecommendationList extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    createStudyPlan: PropTypes.func,
    deleteUserTopic: PropTypes.func,
    externalLogin: PropTypes.string,
    createUserTopic: PropTypes.func,
    getFile: PropTypes.func,
    domainCompany: PropTypes.object,
    corpCourseBackground: PropTypes.string,
    skills: PropTypes.array,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      isFavs: [],
      isInPlan: [],
      isLoading: true,
      calendarModalVisible: false,
      noticed: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { currentUser } = this.props;
    const { isFavs, isInPlan } = this.state;

    let skills = this.skillsToArrayIds();

    skills = JSON.stringify(skills);
    this.setState({ isLoading: true });
  }

  /**
   *
   * @returns {Array}
   */
  skillsToArrayIds = () => {
    const { skills } = this.props;
    let skillsIds = [];
    if (Array.isArray(skills) && skills.length) {
      skills.map(skill => {
        skillsIds.push(skill.key);
      });
    }

    return skillsIds;
  };

  /**
   *
   * @param topicId
   */
  favorite = topicId => {
    const { currentUser, createUserTopic } = this.props;
    createUserTopic(currentUser.id, topicId, () => {
      this.setState({
        isFavs: { ...this.state.isFavs, [topicId]: true },
      });
    });
  };

  /**
   *
   * @param topicId
   */
  unfavorite = topicId => {
    const { deleteUserTopic } = this.props;
    deleteUserTopic(topicId, true, true, () => {
      this.setState({
        isFavs: { ...this.state.isFavs, [topicId]: false },
      });
    });
  };

  /**
   *
   * @param topic
   * @param type
   * @param startDate
   * @param topicInfo
   */
  showCalendarModal = (topic, type, startDate, topicInfo) => {
    this.setState({
      calendarModalVisible: true,
      planningTopicId: topic,
      planningTopicType: type,
      topicInfo,
      startDate,
    });
  };

  /**
   *
   */
  handleStudyPlanClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    webinarDateTime,
    planInfo,
    selectedId
  ) => {
    const { createStudyPlan, currentUser } = this.props;
    const { planningTopicType, isInPlan } = this.state;

    for (let i = 0; i < planningMonths.length; i++) {
      createStudyPlan(
        currentUser.id,
        topicId,
        planningMonths[i],
        comment,
        () => {
          this.setState({
            isInPlan: { ...isInPlan, [topicId]: true },
          });

          this.handleCalendarCancel();
        },
        false,
        getWithoutSchedule(planningTopicType.id, withoutSchedule),
        undefined,
        selectedId
      );
    }
  };

  /**
   *
   */
  handleCalendarCancel = () => {
    this.setState({ calendarModalVisible: false });
  };

  /**
   *
   * @returns {*}
   */
  calendarModal = () => {
    const { t } = this.props;
    const { calendarModalVisible, topicInfo, planningTopicType, planningTopicId, startDate } = this.state;
    const disabledSelect = planningTopicType && planningTopicType.id === 8;

    return (
      <CalendarModal
        topicInfo={topicInfo}
        comment={topicInfo && topicInfo.topicComment}
        eventTypeId={planningTopicType && planningTopicType.id}
        disabledSelect={disabledSelect}
        dateEvent={dayjs(startDate).format('D MMMM YYYY')}
        title={!disabledSelect ? t('selectPlan') : t('scheduleEvent')}
        visible={calendarModalVisible}
        onOk={this.handleStudyPlanClick}
        onCancel={this.handleCalendarCancel}
        okText={t('add')}
        {...(planningTopicType &&
          planningTopicType.id === 20 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
        {...(planningTopicType &&
          planningTopicType.id === 8 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
        planningTopicId={planningTopicId}
        hasEvents={topicInfo && topicInfo.eventCount !== 0}
        selectMonthEvents={
          planningTopicType &&
          (isCorpCourseWithOrWithoutApprove(planningTopicType.id) || (planningTopicType && planningTopicType.id === 20))
        }
      />
    );
  };

  /**
   *
   * @param topic
   * @returns {*}
   */
  drawTopic = topic => {
    const {
      currentUser,
      deleteUserTopic,
      externalLogin,
      createUserTopic,
      getFile,
      domainCompany,
      corpCourseBackground,
    } = this.props;

    const { isFavs, isInPlan } = this.state;

    const openDate = _get(this.props, 'openDate.openDate');
    const topicId = topic.id;

    return (
      <div style={{ margin: '10px' }}>
        <MaterialCard
          topic={topic}
          isFav={isFavs && isFavs[topicId]}
          isInPlan={isInPlan && isInPlan[topicId]}
          userTopicId={topicId}
          onDeleteClick={deleteUserTopic}
          onDeleteClickCatalog={this.unfavorite}
          currentUser={currentUser}
          isExternalLogin={externalLogin}
          onFavoriteClick={createUserTopic}
          onFavoriteClickCatalog={this.favorite}
          onStudyPlanClick={this.showCalendarModal}
          getFile={getFile}
          domainCompany={domainCompany}
          openDate={openDate}
          corpCourseBackground={corpCourseBackground}
        />
      </div>
    );
  };

  showNotice = () => {
    const { t } = this.props;

    notification.success({
      placement: 'topRight',
      duration: 4.5,
      message: t('note'),
      description: t('recommendationAlert'),
    });

    clearInterval(this.notice);
  };

  createNotice = () => {
    if (typeof this.notice === 'undefined') {
      this.notice = window.setInterval(this.showNotice, 1000);
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const { topics, isLoading, noticed } = this.state;
    const { t } = this.props;
    return (
      <>
        <Row>
          {isLoading && (
            <Col
              style={{
                textAlign: 'center',
                minHeight: '15rem',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin size='large' tip={t('loading')} />
            </Col>
          )}
          {Array.isArray(topics) &&
            topics.map(topic => {
              return <Col span={6}>{this.drawTopic(topic)}</Col>;
            })}
        </Row>

        {this.createNotice()}
        {this.calendarModal()}
      </>
    );
  }
}

const mapStateToProps = createSelector(
  [topicsSelect, usersSelect, loginSelect, selectOpenDate],
  (topics, users, login, openDate) => ({
    openDate,
    currentUser: users.currentUser,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    externalLogin: login.externalLogin,
    corpCourseBackground: users.corpCourseBackground,
  })
);

const mapActionsToProps = {
  getFile,
  deleteUserTopic,
  createUserTopic,
  createStudyPlan,
};

export default connect(mapStateToProps, mapActionsToProps)(RecommendationList);
