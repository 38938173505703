import DefaultCoins from '@shared/assets/icons/default-coins-icon.svg?react';

import AccountCircle from '@/assets/icons/account_circle.svg?react';
import ActivityWidgetIcon from '@/assets/icons/activity-widget-icon.svg?react';
import ArrowUp from '@/assets/icons/arrowUp.svg?react';
import Bookmarks from '@/assets/icons/bookmarks.svg?react';
import Cannot from '@/assets/icons/cannot-icon.svg?react';
import Check from '@/assets/icons/check-icon.svg?react';
import Clear from '@/assets/icons/clear.svg?react';
import CloseIcon from '@/assets/icons/close-icon.svg?react';
import FinishedWidgetIcon from '@/assets/icons/finished-widget-icon.svg?react';
import Home from '@/assets/icons/home.svg?react';
import Info from '@/assets/icons/info-icon.svg?react';
import Move from '@/assets/icons/move-icon.svg?react';
import Plus from '@/assets/icons/plus.svg?react';
import WarningClose from '@/assets/icons/warning-close-icon.svg?react';

export {
  Clear,
  FinishedWidgetIcon,
  ActivityWidgetIcon,
  DefaultCoins,
  Move,
  Plus,
  ArrowUp,
  AccountCircle,
  Home,
  Bookmarks,
  Info,
  Check,
  Cannot,
  WarningClose,
  CloseIcon,
};
