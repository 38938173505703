import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './Modal.module.scss';

const AudienceConfirmationModal = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation('AudienceConfirmationModal');

  const footer = (
    <div className={css['Modal-footer']}>
      <Button key='submit' type='primary' onClick={onConfirm} className={css['Modal-btn']} size='large'>
        {t('yesButton')}
      </Button>
      <Button key='back' type='primary' onClick={onCancel} className={css['Modal-btn']} size='large'>
        {t('noButton')}
      </Button>
    </div>
  );

  return (
    <Modal open={open} onOk={onConfirm} onCancel={onCancel} className={css['Modal']} footer={footer} centered={true}>
      <div className={css['Modal-body']}>
        <div>{t('modalMessage1')}</div>
        <br />
        <div>{t('modalMessage2')}</div>
      </div>
    </Modal>
  );
};

AudienceConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AudienceConfirmationModal;
