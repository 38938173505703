// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.notification-container {
  font-size: 12px;
  top: auto;
  bottom: 0;
}

.app-body-main {
  width: 100%;
}

#root {
  width: 100%;
  min-height: 100vh;
}

#root > .ant-layout {
  min-height: 100vh;
}

.ant-dropdown .ant-table-filter-dropdown {
  width: 450px;
}

.ant-tooltip-inner {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 320px) and (max-width: 991px) {
  .ant-dropdown .ant-table-filter-dropdown {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .ant-input-search-button {
    width: 3em;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ;;AAGA;EAAE,SAAS;EACT,UAAU;AACZ;;AAOA;EAJE,aAAa;AACf;;AAOA;EAJE,eAAe;EACf,SAAS;EACT,SAAS;AACX;;AAOA;EAJE,WAAW;AACb;;AAOA;EAJE,WAAW;EACX,iBAAiB;AACnB;;AAOA;EAJE,iBAAiB;AACnB;;AAOA;EAJE,YAAY;AACd;;AAOA;EAJE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAOA;EACE;IAJE,YAAY;EACd;AACF;;AAOA;EACE;IAJE,UAAU;EACZ;AACF","sourcesContent":["html,\nbody {\n  margin: 0;\n  padding: 0;\n  min-height: 100vh;\n}\n\nh1,\nh2,\nh3,\nh4 {\n  margin: 0;\n  padding: 0;\n}\n\n/* КОСТЫЛЬ ЧТОБ СКРЫТЬ OVERLAY ERROR REACT WINDOW */\niframe#webpack-dev-server-client-overlay {\n  display: none;\n}\n\n.notification-container {\n  top: auto;\n  bottom: 0;\n  font-size: 12px;\n}\n\n.app-body-main {\n  width: 100%;\n}\n\n#root {\n  width: 100%;\n  min-height: 100vh;\n}\n\n#root > .ant-layout {\n  min-height: 100vh;\n}\n\n.ant-dropdown .ant-table-filter-dropdown {\n  width: 450px;\n}\n\n.ant-tooltip-inner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (width >= 320px) and (width <= 991px) {\n  .ant-dropdown .ant-table-filter-dropdown {\n    width: 200px;\n  }\n}\n\n@media (width <= 992px) {\n  .ant-input-search-button {\n    width: 3em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
