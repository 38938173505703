export const D360_MODE_EXPERIMENTAL = 'Experimental';
export const D360_MODE_ALPHA = 'Alpha Version';
export const D360_MODE_BETA = 'Beta Version';
export const D360_MODE_RELEASE = 'Version 1.0';
export const D360_MODE_DEFAULT = D360_MODE_EXPERIMENTAL;
export const D360_MODE = D360_MODE_BETA;

export const PROJECT_STATUS_ACTIVE = 1;
export const PROJECT_STATUS_INACTIVE = 2;
export const PROJECT_STATUS_DRAFT = 3;
export const PROJECT_STATUS_UNKNOWN = 666;
