/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getFile } from '@/store/files/actions';
import {
  checkedIsAvailableForStart,
  clearDetails,
  clearQuestion,
  clearQuestionNumber,
  getBlock,
  getDetails,
  getQuestion,
  setAnswer,
  setBlockFinish,
  setBlockRestart,
  setBlockStart,
  setQuestionNumber,
} from '@/store/lms/actions';

import { selectLMS } from '@/store/lms/selectors';

import { Spin } from 'antd';

import { BlockStudyResultScreen } from './BlockStudyResultScreen/index.jsx';
import Question from './Question';
import StartTest from './StartTest/StartTest';
import Timer from './Timer/Timer';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';

import css from '../CollectionMaterialsIDStudy.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const TestStudy = ({
  topicId,
  next,
  block,
  currentTest,
  question,
  details,
  currentUser,
  getQuestion,
  setBlockStart,
  setAnswer,
  getFile,
  getDetails,
  setBlockFinish,
  setBlockRestart,
  getStatistic,
  getBlock,
  clearDetails,
  clearQuestion,
  currentQuestionNumber,
  canRestartBlocks,
  getStatisticStart,
}) => {
  const [lockUI, setLockUI] = useState(false);
  const [restart, setRestart] = useState(false);
  const { arrayChecked } = useSelector(selectLMS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTest) {
      if (
        currentTest.test.countQuestions === currentTest.test.countUserAnswers ||
        currentTest.test.status === 'FINISHED'
      ) {
        getDetails(currentTest.blockId, currentUser.id);
        getBlock(currentTest.blockId);
      } else {
        getBlock(currentTest.blockId, res => {
          if (
            currentTest.isAvailableForStart &&
            (res.test?.time === '00:00:00' || res.test?.time === null) &&
            !arrayChecked?.includes(currentTest.blockId)
          ) {
            setBlockStart(currentTest.blockId, () => {
              getStatisticStart();
            });
            dispatch(checkedIsAvailableForStart(currentTest?.blockId));
          } else if (!currentTest.isAvailableForStart || arrayChecked?.includes(currentTest.blockId)) {
            const finishBlocks = localStorage.getItem('finishBlocks');
            if (!finishBlocks || !JSON.parse(finishBlocks)?.blocks?.includes(currentTest.blockId)) {
              getQuestion(
                currentTest.blockId,
                response => {
                  if (!response) getDetails(currentTest.blockId, currentUser.id);
                },
                () => getDetails(currentTest.blockId, currentUser.id)
              );
            } else {
              getDetails(currentTest.blockId, currentUser.id);
            }
          }
        }); // Получаем block, чтобы узнать, есть ли у теста время
      }
      setLockUI(false);
    }
    return () => {
      setRestart(false);
      clearDetails();
      clearQuestion();
    };
  }, [currentTest]);

  const setAnswerHandler = useCallback(
    data => {
      setLockUI(true);
      setAnswer(data, response => {
        setLockUI(false);
        if (response?.data?.length) {
          getQuestion(currentTest.blockId);
        } else {
          getStatistic(true);
        }
      });
    },
    [setAnswer, currentTest]
  );

  const setFinishBlockHandler = useCallback(
    id => {
      setLockUI(true);
      setBlockFinish(id, () => {
        getStatistic(next <= 1);
        setLockUI(false);
      });
    },
    [topicId, currentUser, currentTest, next]
  );

  const setRestartBlockHandler = useCallback(
    blockId => {
      setLockUI(true);
      setBlockRestart(blockId, '', () => {
        getStatistic(true);
        setLockUI(false);
        const finishBlocks = localStorage.getItem('finishBlocks');
        let arr = finishBlocks ? JSON.parse(finishBlocks).blocks : [];
        if (arr.length) {
          arr = arr.filter(el => el !== blockId);
          localStorage.setItem('finishBlocks', JSON.stringify({ blocks: arr }));
        }
      });
    },
    [topicId, currentUser, currentTest]
  );

  const setCurrentQuestionNumber = newNumber => dispatch(setQuestionNumber(newNumber));
  const setClearQuestionNumber = () => dispatch(clearQuestionNumber());

  return restart ? (
    <StartTest
      time={block?.test?.time}
      text={block?.test?.text}
      getStatistic={getStatistic}
      blockId={currentTest.blockId}
      getQuestion={getQuestion}
      currentUser={currentUser}
      getDetails={getDetails}
      setBlockStart={setBlockStart}
      setRestart={setRestart}
      setRestartBlockHandler={setRestartBlockHandler}
      restart={restart}
    />
  ) : !question && !details ? (
    <div className={classNames(css['Content-main-preloader'])}>
      {block &&
      block.test &&
      block.test.time !== '00:00:00' &&
      block.test.time !== null &&
      currentTest.isAvailableForStart ? (
        <StartTest
          time={block?.test?.time}
          text={block?.test?.text}
          getStatistic={getStatistic}
          blockId={currentTest.blockId}
          getQuestion={getQuestion}
          currentUser={currentUser}
          getDetails={getDetails}
          setBlockStart={setBlockStart}
        />
      ) : (
        <Spin />
      )}
    </div>
  ) : !details ? (
    <div>
      {question?.time !== null && question?.time !== '00:00:00' && (
        <div className={css['Content-main__timer']}>
          <Timer time={question.time} getDetails={getDetails} blockId={currentTest.blockId} currentUser={currentUser} />
        </div>
      )}
      <Question
        data={question}
        block={block}
        setAnswer={setAnswerHandler}
        test={currentTest}
        getFile={getFile}
        lockUI={lockUI}
        currentQuestionNumber={currentQuestionNumber}
        setCurrentQuestionNumber={setCurrentQuestionNumber}
        clearQuestionNumber={setClearQuestionNumber}
      />
    </div>
  ) : (
    <BlockStudyResultScreen
      data={details}
      block={currentTest}
      setFinished={setFinishBlockHandler}
      repeatTest={res => {
        if (block?.test.time !== '00:00:00' && block?.test.time !== null) {
          setRestart(res);
        } else {
          setRestartBlockHandler(res);
        }
      }}
      haveATimer={() => block?.test.time !== '00:00:00' && block?.test.time !== null}
      canRestartBlocks={canRestartBlocks}
    />
  );
};

const mapStateToProps = createSelector(
  [selectLMS],
  ({ isLoading, question, details, block, currentQuestionNumber, statistic }) => ({
    question,
    details,
    block,
    currentQuestionNumber,
    next: statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length,
  })
);

const mapActionsToProps = {
  getQuestion,
  setBlockStart,
  setAnswer,
  getFile,
  getDetails,
  setBlockFinish,
  setBlockRestart,
  getBlock,
  clearDetails,
  clearQuestion,
  setQuestionNumber,
  clearQuestionNumber,
};

export const Test = connect(mapStateToProps, mapActionsToProps)(TestStudy);
