// import { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getPokaSkills /*savePokaSkillsSort*/ } from '@/store/poka/actions';

import { selectPokaSkills } from '@/store/poka/selectors';

import { Empty, Pagination, Spin } from 'antd';

import PokaItem from './PokaItem';

// import classNames from 'classnames';

import css from '../PokaSkills.module.scss';

const ContentPokaSkills = () => {
  // const { t } = useTranslation('pokaSkills');
  const dispatch = useDispatch();

  // const [activeSort, setActiveSort] = useState({ date: false });

  const {
    pokaSkillsFilters,
    // pokaSkillsSort,
    pokaSkills,
    pokaSkiilsTotal,
    pokaSkiilsPageSize,
    pokaSkiilsCurrentPage,
    isLoadingPoka,
  } = useSelector(selectPokaSkills);

  // const sortDate = () => {
  //   setActiveSort({ date: !activeSort.date });

  //   if (!activeSort.date) {
  //     dispatch(savePokaSkillsSort('date,desc'));
  //     dispatch(getPokaSkills(pokaSkillsFilters ?? {}, 0, 'date,desc'));
  //   } else {
  //     dispatch(savePokaSkillsSort(''));
  //     dispatch(getPokaSkills(pokaSkillsFilters ?? {}, 0));
  //   }
  // };

  const renderPokaItems = () => {
    if (pokaSkills?.length > 0) return pokaSkills.map((item, index) => <PokaItem data={item} key={index} />);
  };

  const onChangePagination = pagination => {
    dispatch(getPokaSkills(pokaSkillsFilters ?? {}, pagination - 1 /*pokaSkillsSort*/));
  };

  return (
    <div>
      <div className={css['Sort']}>
        {/* <div>{t('sort')}</div>
        <div className={classNames(css['Sort-date'], activeSort.date && css['Sort-active'])} onClick={sortDate}>
          {t('sortDate')}
        </div> */}
      </div>
      <div className={css['Content']}>
        <div className={css['Content-list']}>
          <Spin spinning={isLoadingPoka}>{pokaSkills.length > 0 ? renderPokaItems() : <Empty />}</Spin>
        </div>
        <div className={css['Content-pagination']}>
          <Pagination
            total={pokaSkiilsTotal}
            pageSize={pokaSkiilsPageSize}
            current={pokaSkiilsCurrentPage + 1}
            showSizeChanger={false}
            responsive
            hideOnSinglePage
            onChange={onChangePagination}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentPokaSkills;
