/* eslint-disable */
import { D360_LOAD_PROJECTS_FAIL, D360_LOAD_PROJECTS_START, D360_LOAD_PROJECTS_SUCCESS } from './constants';

const initialState = {
  loading: false,
  projects: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case D360_LOAD_PROJECTS_START:
      return { ...state, loading: true };
    case D360_LOAD_PROJECTS_SUCCESS:
      return { ...state, loading: false, projects: response.data };
    case D360_LOAD_PROJECTS_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
