/* eslint-disable */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  clearNotifications,
  getListNotifications,
  getNotificationById,
  getNotifications,
  putReadAllNotifications,
  putReadNotificationById,
} from '@/store/notifications-new/actions';

import { selectNotifications } from '@/store/notifications-new/selectors';

import { Button, Input, Layout, Modal, Select, Spin, Switch, Tooltip, Typography } from 'antd';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import { useNotificationTypes } from '@/components/NotificationTypes';
import { RightCircleTwoTone } from '@ant-design/icons';
import { PiCircle, PiCircleFill } from 'react-icons/pi';

import ModalNotificationById from './ModalNotificationById';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import css from './MyNotifications.module.scss';

const { Content } = Layout;
const { Search } = Input;
const { Title } = Typography;

const initOptions = [
  { type: 'GENERAL', options: [{ value: 'DEFAULT' }] },
  {
    type: 'TEMPLATE',
    options: [
      { value: 'ADD_TOPIC_TO_PLAN' },
      { value: 'PRACTICAL_TASK_SUCCESS_MAIL' },
      { value: 'PRACTICAL_TASK_FAILED_MAIL' },
      { value: 'INVITATION' },
    ],
  },
  {
    type: 'CUSTOM',
    options: [{ value: 'CUSTOM' }],
  },
];

let onceUpdate = true;

const convertToCorrectType = (type, value) => {
  switch (type) {
    case 'boolean':
      return value.toLowerCase() === 'true';
    case 'number':
      return Number(value);
    default:
      return value.toString();
  }
};

const defaultParams = {
  unreadOnly: false,
  size: 20,
  type: '',
  value: '',
};

const searchToObject = searchString => {
  const params = new URLSearchParams(searchString);
  const currentParams = {};
  for (const [key, value] of params.entries()) {
    if (defaultParams.hasOwnProperty(key)) currentParams[key] = convertToCorrectType(typeof defaultParams[key], value);
  }
  return currentParams;
};

let currentController = null;

const MyNotifications = () => {
  const { search } = useLocation();

  const { t } = useTranslation('myNotification');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { notifications, notification, isNotificationLoading, selectOptions } = useSelector(selectNotifications);

  const [params, setParams] = useState({ ...searchToObject(location.search), page: 0 });
  const [searchValue, setSearchValue] = useState(searchToObject(location.search).value);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [isNotEndScroll, setNotEndScroll] = useState(true);
  const isNotEndScrollRef = useRef(true);
  const currentScrollYPosition = useRef(0);

  const MAX_MOBILE_WIDTH = 992;

  const resetNotifications = args => {
    isNotEndScrollRef.current = true;
    if (Object.keys(args).some(el => args[el] !== params[el])) {
      dispatch(clearNotifications());
    }

    if (!args?.value) {
      setSearchValue('');
    }

    const queryParams = { ...params, ...args, page: 0 };
    setParams(queryParams);
    // eslint-disable-next-line
    const { page, ...otherParams } = queryParams;
    const currentParams = new URLSearchParams(otherParams);

    navigate({ search: currentParams.toString() });
  };

  const onChangeFilterByType = status => {
    resetNotifications({ type: status === 'DEFAULT' ? '' : status });
  };

  const closeModal = () => {
    // eslint-disable-next-line
    const { page, ...otherParams } = params;
    const urlParams = new URLSearchParams(otherParams);
    navigate({
      search: urlParams.toString(),
    });
    window.scrollTo(0, currentScrollYPosition.current);
    setModalVisible(false);
  };

  const onReadNotificationById = id => event => {
    event.stopPropagation();
    dispatch(putReadNotificationById(id));
  };

  const onSearch = currentValue => {
    resetNotifications({ value: currentValue });
  };

  const onChangeDebounce = useCallback(
    debounce(value => {
      onSearch(value);
    }, 600),
    [params]
  );

  const markAllAsRead = () => {
    dispatch(
      putReadAllNotifications(() => {
        resetNotifications({ ...defaultParams });
      })
    );
  };

  const onChangeSearchText = event => {
    setSearchValue(event.target.value);
  };

  const currentSelectOptions = useMemo(() => {
    return (selectOptions?.length > 0 ? selectOptions : initOptions).map(({ type, options }) => ({
      type,
      label: t('filterType' + type),
      options: options
        // временное решение, как сделают бэк вернуть как было
        .filter(i => i.value !== 'SCORE_360')
        .map(({ value }) => ({ value, label: t('filterBy' + value) })),
      // options: options.map(({ value }) => ({ value, label: t('filterBy' + value) })),
    }));
  }, [selectOptions]);

  useEffect(() => {
    onChangeDebounce(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (search) {
      const searchObject = Object.fromEntries(new URLSearchParams(search));
      const modalId = searchObject['modal_id'];
      window.scrollTo(0, currentScrollYPosition.current);
      if (modalId) {
        setModalVisible(true);
        dispatch(
          getNotificationById(modalId, () => {
            dispatch(putReadNotificationById(modalId));
          })
        );
      }
    }
  }, [search]);

  useEffect(() => {
    if (isNotEndScrollRef.current) {
      if (currentController) currentController.abort();

      currentController = new AbortController();
      let signal = currentController.signal;

      dispatch(
        getNotifications(
          { ...params },
          success => {
            setTimeout(() => (onceUpdate = true), 100);
            const isNotEnd = success.length > 0;
            isNotEndScrollRef.current = isNotEnd;
            setNotEndScroll(isNotEnd);
          },
          signal
        )
      );
    }
  }, [params]);

  useEffect(() => {
    dispatch(getListNotifications());

    const onCheckMobile = () => {
      setIsMobile(window.innerWidth);
    };

    const onScrollUpdate = () => {
      currentScrollYPosition.current = window.scrollY;
      if (window.scrollY + window.innerHeight > document.body.scrollHeight - 40 && onceUpdate) {
        setParams(prev => ({ ...prev, page: prev.page + 1 }));
        onceUpdate = false;
      }
    };

    document.addEventListener('touchmove', onScrollUpdate);
    document.addEventListener('scroll', onScrollUpdate);
    window.addEventListener('resize', onCheckMobile);
    onCheckMobile();

    return () => {
      window.removeEventListener('resize', onCheckMobile);
      document.removeEventListener('touchmove', onScrollUpdate);
      document.removeEventListener('scroll', onScrollUpdate);
      onChangeDebounce.cancel();
    };
  }, []);

  const isMobileSize = isMobile <= MAX_MOBILE_WIDTH;

  const SearchComponent = isMobileSize ? (
    <>
      <Search
        placeholder={t('searchPlaceholder')}
        className={css['myNotification--container__search']}
        size={'large'}
        onSearch={value => value && onSearch(value)}
        onChange={onChangeSearchText}
        value={searchValue}
        loading={isNotificationLoading}
        enterButton
        allowClear
      />
      <Select
        className={css['myNotification--container__search--select']}
        value={params.type || 'DEFAULT'}
        onChange={onChangeFilterByType}
        loading={isNotificationLoading}
        options={currentSelectOptions}
      />
    </>
  ) : (
    <Search
      placeholder={t('searchPlaceholder')}
      className={css['myNotification--container__search']}
      size={'large'}
      onSearch={value => value && onSearch(value)}
      onChange={onChangeSearchText}
      value={searchValue}
      loading={isNotificationLoading}
      addonBefore={
        <Select
          className={css['myNotification--container__search--select']}
          value={params.type || 'DEFAULT'}
          onChange={onChangeFilterByType}
          loading={isNotificationLoading}
          options={currentSelectOptions}
        />
      }
      enterButton
      allowClear
    />
  );

  return (
    <Content className={css['myNotification']}>
      <Title className={css['myNotification--title']} level={1}>
        {t('title')}
      </Title>
      <div className={css['myNotification--container']}>
        <div className={css['myNotification--container-top']}>
          <Button
            className={css['myNotification--container-top-reset']}
            type='link'
            onClick={() => resetNotifications({ ...defaultParams })}
          >
            {t('reset')}
          </Button>
        </div>

        {SearchComponent}

        <div className={css['myNotification--container-filter']}>
          <div className={css['myNotification--container-filter__body']}>
            <div className={css['myNotification--container-filter__body-item']}>
              <p className={css['myNotification--container-filter--txt']}>{t('filterUnread')}</p>
              <Switch
                className={css['myNotification--container-filter--switch']}
                onChange={e => resetNotifications({ unreadOnly: e })}
                checked={params.unreadOnly}
                disabled={isNotificationLoading}
              />
            </div>
            <div className={css['myNotification--container-filter__body-item']}>
              <p
                className={classNames([
                  css['myNotification--container-filter--link'],
                  css['myNotification--container-filter--txt'],
                  isNotificationLoading && css['myNotification--container-filter--link-inActive'],
                ])}
                onClick={markAllAsRead}
              >
                {t('txtReadAll')}
              </p>
            </div>
          </div>
        </div>

        <div className={css['myNotification--container-notifications']}>
          {notifications?.length > 0 &&
            notifications.map(item => {
              const subject =
                !!item?.subject && item?.subject.length > 150 && !isMobile
                  ? item.subject.slice(0, 147) + '...'
                  : !!item?.subject && item?.subject.length > 50 && isMobile
                    ? item.subject.slice(0, 40) + '...'
                    : item.subject;

              const { IconNotification, TagNotification } = useNotificationTypes({
                type: item.groupTemplateType,
                className: css['myNotification--container-notifications__item--icon__img'],
                t,
              });

              return (
                <div
                  className={classNames(
                    css['myNotification--container-notifications__item'],
                    item.unread && css['myNotification--unread']
                  )}
                  key={'notification-item-' + item.id}
                  onClick={() => {
                    navigate({
                      search: `modal_id=${item.id}`,
                    });
                  }}
                >
                  <div className={css['myNotification--container-notifications__item--icon']}>
                    <IconNotification />
                  </div>

                  <div className={css['myNotification--container-notifications__item-type']}>
                    <TagNotification />
                    <div className={css['myNotification--container-notifications__item-type__time']}>
                      {dayjs(item.timestamp).fromNow()}
                    </div>
                  </div>

                  {/* <div
                    className={css['myNotification--container-notifications__item--read']}
                    onClick={item.unread ? onReadNotificationById(item.id) : undefined}
                  >
                    {item.unread ? (
                      <Tooltip title={t('markAsRead')}>
                        <PiCircleFill />
                      </Tooltip>
                    ) : (
                      <PiCircle />
                    )}
                  </div> */}

                  <div className={css['myNotification--container-notifications__item-block']}>
                    <div className={css['myNotification--container-notifications__item--subject']}>{subject}</div>
                  </div>

                  <Tooltip title={t('more')}>
                    <RightCircleTwoTone className={css['myNotification--container-notifications__item__more']} />
                  </Tooltip>
                </div>
              );
            })}
        </div>

        <div className={css['myNotification--container-spin']}>
          {isNotEndScroll || isNotificationLoading ? (
            <Spin size='large' spinning={isNotificationLoading} />
          ) : (
            <div>{t('notUpdateText')}</div>
          )}
        </div>
      </div>

      <Modal
        centered
        open={isModalVisible}
        width={isMobile ? '95%' : '70%'}
        style={{ maxWidth: notification.content ? ' 900px' : '600px' }}
        footer={null}
        closeIcon={
          <ModalCloseIcon
            onClick={closeModal}
            className={css['myNotification--close']}
            tooltipText={t('closeNotification')}
          />
        }
      >
        <ModalNotificationById />
      </Modal>
    </Content>
  );
};

export default MyNotifications;
