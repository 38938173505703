import React from 'react';

import { Col, Row } from 'antd';

import { Card } from '../card';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const CardsBlock = ({ name, currentUser, title, cards = [], actions, onAction, buttonsAddedByAdmin }) => {
  return (
    <div className={cn('cards-block')}>
      <Row className={cn('cards-block-title')}>
        <Col>
          <h2>{title}</h2>
        </Col>
        <Col>
          <Row className={cn('cards-block-title-filter')}>{buttonsAddedByAdmin}</Row>
        </Col>
      </Row>

      <div className={cn('cards-block__cards')}>
        {cards.map(card => (
          <Card
            key={card.id}
            blockName={name}
            material={card}
            actions={actions}
            onAction={onAction}
            currentUser={currentUser}
          />
        ))}
      </div>
    </div>
  );
};

CardsBlock.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.any,
  actions: PropTypes.any,
  onAction: PropTypes.any,
  name: PropTypes.string,
  currentUser: PropTypes.object,
};
