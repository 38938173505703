import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Typography } from 'antd';
import Rate from '@/components/Rate';

import useImage from '@shared/hooks/useImage';

import PropTypes from 'prop-types';

import styles from './NewsCard.module.scss';

import Article from '@/assets/icons/article.svg';

const { Title, Text } = Typography;

const NewsCard = ({ data }) => {
  const image = useImage(data?.photoUuid);

  const { t } = useTranslation('news');
  const navigate = useNavigate();

  return (
    <div className={styles.card} onClick={() => navigate(`/news/${data?.id}`)}>
      <div className={styles.card__wrapper}>
        {image ? (
          <img src={image} alt='' className={styles.card__image} />
        ) : (
          <div className={styles.card__default}>
            <img className={styles.card__default_image} src={Article} alt='' />
          </div>
        )}
      </div>
      <div className={styles.card__info}>
        <div className={styles.card__info__header}>
          <Title level={4} className={styles.card__name}>
            {data?.title}
          </Title>
          <div className={styles.card__info__block}>
            <Text className={styles.card__title}>{t('rate')}</Text>
            <Rate value={data?.rate} disabled className={styles.card__rate} />
          </div>
        </div>

        <div className={styles.card__additional}>
          <div className={styles.card__element__author}>
            <Text className={styles.card__title}>{t('author')}</Text>
            <Link to={`/collegues/${data?.authorDto.id}`} className={styles.card__text_author}>
              {data?.authorDto.name}
            </Link>
          </div>
          <div className={styles.card__element}>
            <Text className={styles.card__title}>{t('date')}</Text>
            <Text className={styles.card__text}>{data?.createdDate}</Text>
          </div>
          <div className={styles.card__element}>
            <Text className={styles.card__title}>{t('comments')}</Text>
            <Text className={styles.card__text}>{data?.countComments}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    countComments: PropTypes.number,
    imageUuid: PropTypes.string,
    title: PropTypes.string,
    authorDto: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    createdDate: PropTypes.string,
    rate: PropTypes.number,
  }),
};

export default memo(NewsCard);
