/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import { ACTIVE } from '@shared/constants/topic-statuses';
import getQueryString from '@shared/utils/get-query-string';

export const GET_DIAGNOSTIC_COURSE_START = 'GET_DIAGNOSTIC_COURSE_START';
export const GET_DIAGNOSTIC_COURSE_SUCCESS = 'GET_DIAGNOSTIC_COURSE_SUCCESS';
export const GET_DIAGNOSTIC_COURSE_FAIL = 'GET_DIAGNOSTIC_COURSE_FAIL';

export const GET_DIAGNOSTIC_STUDY_START = 'GET_DIAGNOSTIC_STUDY_START';
export const GET_DIAGNOSTIC_STUDY_SUCCESS = 'GET_DIAGNOSTIC_STUDY_SUCCESS';
export const GET_DIAGNOSTIC_STUDY_FAIL = 'GET_DIAGNOSTIC_STUDY_FAIL';

export const CHANGE_DIAGNOSTIC_VALUE = 'CHANGE_DIAGNOSTIC_VALUE';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};
const defaultToken = '';

export const getDiagnosticCourse = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/individual-plan${getQueryString({
      size: 60,
      countHard: 16,
      countSoft: 44,
      status: ACTIVE,
      isVisible: true,
      topicTypeId: '1&topicTypeId=20&topicTypeId=17',
      ...params,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_DIAGNOSTIC_COURSE_START, GET_DIAGNOSTIC_COURSE_SUCCESS, GET_DIAGNOSTIC_COURSE_FAIL],
    onSuccess,
  };
};

export const getDiagnosticStudy = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/individual-plan${getQueryString({
      size: 60,
      countHard: 20,
      countSoft: 40,
      status: ACTIVE,
      isVisible: true,
      topicTypeId: '16&topicTypeId=4&topicTypeId=15',
      ...params,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_DIAGNOSTIC_STUDY_START, GET_DIAGNOSTIC_STUDY_SUCCESS, GET_DIAGNOSTIC_STUDY_FAIL],
    onSuccess,
  };
};

export const changeValue = (name, value) => ({
  type: CHANGE_DIAGNOSTIC_VALUE,
  response: { name, value },
});
