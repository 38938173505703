import { isConference } from './topic-utils';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import _get from 'lodash/get';

dayjs.extend(isSameOrAfter);

export const checkActiveCourse = ({ topic, openDate }, isTemplate) => {
  const topicTypeId = _get(topic, 'type.id');
  if (!isConference(topicTypeId)) {
    return true;
  }
  const { startDate, startTime, independent } = topic;
  const startDateObj = dayjs(`${startDate} ${startTime}`);

  return (
    (independent || (openDate ? dayjs(startDateObj).isSameOrAfter(openDate, 'day') : true)) &&
    dayjs()
      .add(isTemplate ? 0 : 6, 'days')
      .isSameOrBefore(startDateObj, 'day')
  );
};
