/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Spin, Tooltip } from 'antd';
import useIsMobileClass from '@/components/isMobileClass';

import { Card } from '../Layout';
import Rate from '../Rate';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import classNames from 'classnames';
import _ from 'lodash';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './CompilationCard.module.scss';

import defaultPlaceholderImage from '@/assets/images/no-bg.svg';

const CompilationCard = ({ compilation, dataqa, searchParams, setSearchParams, shortDescription, isFavorite }) => {
  const [themes, setThemes] = useState(compilation.themes);
  const [isTextOverflown, setIsTextOverflown] = useState(false);
  const themesContainerRef = useRef(null);
  const { t } = useTranslation('compilationCard');
  const navigate = useNavigate();

  const [coverImage, isLoadingCoverImage] = useLocalFileByUUID(compilation.cover, {
    defaultImg: defaultPlaceholderImage,
  });

  useEffect(() => {
    if (themesContainerRef.current) {
      const isOverflown = themesContainerRef.current.offsetWidth < themesContainerRef.current.scrollWidth;
      if (isOverflown !== isTextOverflown) {
        setIsTextOverflown(isOverflown);
      }
    }
  }, [themes, isTextOverflown]);

  const renderThemes = () => {
    const maxLenThemes = useIsMobileClass() ? 25 : 35;
    const themesArr = themes.map(theme => theme.name);
    const isMoreThemes = themesArr.join()?.length > maxLenThemes;
    const themesViewLength = isMoreThemes ? themesArr.join().slice(0, maxLenThemes).split(',').length : themes.length;
    const themesView = themes.slice(0, themesViewLength);
    const isShotThemesLengthVal = themesView.map(theme => theme.name).join().length > maxLenThemes;
    const difference = themes.length - themesViewLength;

    const list = isShotThemesLengthVal
      ? [
          ...themesView.slice(0, themesView.length - 1),
          themesView[themesView.length - 1] && {
            id: themesView[themesView.length - 1].id,
            name: themesView[themesView.length - 1].name,
          },
        ]
      : themesView;
    return (
      <div className={classNames(css.CompilationCard__infoCard__card__themesHeader)}>
        <div
          className={classNames(css.CompilationCard__infoCard__card__themesHeader__themesPreview)}
          ref={themesContainerRef}
        >
          {list.map((theme, key) => (
            <span key={`${key}-${theme.id}`}>
              {isTextOverflown && key === themesViewLength - 1 ? (
                <Tooltip title={theme.name} mouseEnterDelay={0.3}>
                  <a
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        themeId: [theme.id],
                        page: 0,
                      })
                    }
                  >
                    {theme.name}
                  </a>
                </Tooltip>
              ) : (
                <a
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      themeId: [theme.id],
                      page: 0,
                    })
                  }
                >
                  {theme.name}
                </a>
              )}
              {list?.length > key + 1 && ', '}
            </span>
          ))}
        </div>
        {isShotThemesLengthVal && difference ? (
          <div className={css.CompilationCard__seeMore}>
            <span>{t('more', { difference })}</span>
            <Card className={css.CompilationCard__seeMore__card}>
              {themes.slice(themes.length - difference, themes.length).map((theme, key) => (
                <span key={`${key}-${theme.id}`}>
                  <a
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        themeId: [theme.id],
                      })
                    }
                  >
                    {theme.name}
                  </a>
                  {difference > key + 1 && ', '}
                </span>
              ))}
            </Card>
          </div>
        ) : null}
      </div>
    );
  };

  const handleReferrerLink = () => localStorage.setItem('referrer', document.location.pathname);

  const handleLink = () => {
    navigate(`/compilations/${compilation.id}`);
  };

  const mainNameMaxLen = useIsMobileClass() ? 20 : 90;
  const mainDiscMaxLen = useIsMobileClass() ? 30 : 90;

  return (
    <Card
      className={classNames(css.CompilationCard, {
        [css.Favorite]: isFavorite,
      })}
      onMouseDown={handleReferrerLink}
    >
      <div className={css.CompilationCard__content}>
        <div className={css.CompilationCard__cover} onClick={handleLink}>
          <Spin spinning={isLoadingCoverImage} wrapperClassName={css.CompilationCard__cover__spin}>
            <img src={coverImage} alt='' />
          </Spin>
        </div>
        <div className={css.CompilationCard__info}>
          <div>
            <div data-qa={`${dataqa}-themes`} className={css.CompilationCard__themes}>
              {renderThemes()}
            </div>
            <Link
              data-qa={`${dataqa}-name`}
              className={css.CompilationCard__name}
              to={`/compilations/${compilation.id}`}
            >
              {compilation.name?.length > mainNameMaxLen ? (
                <Tooltip title={compilation.name}>{`${compilation.name.substr(0, mainNameMaxLen)}...`}</Tooltip>
              ) : (
                compilation.name
              )}
            </Link>
            <div data-qa={`${dataqa}-shortDescription`} className={css.CompilationCard__sub}>
              {shortDescription?.length > mainDiscMaxLen
                ? shortDescription.substr(0, mainDiscMaxLen) + '...'
                : shortDescription}
            </div>
          </div>
          <Card className={css.CompilationCard__infoCard} style={{ borderRadius: 8 }}>
            <div
              className={classNames(css.CompilationCard__infoCard__card, {
                [css.Favorite__cardInfo]: isFavorite,
              })}
            >
              <div
                className={classNames(
                  css.CompilationCard__infoCard__card__col,
                  css.CompilationCard__infoCard__card__col__sizeCount
                )}
              >
                <div className={css.CompilationCard__infoCard__card__col__count}>{t('count')}</div>
                <p className={css.CompilationCard__infoCard__card__col__count__value}>
                  {`${compilation.count} `}
                  {noun(compilation.count, t('material'), t('material2'), t('material3'))}
                </p>
              </div>
              <div
                className={classNames(
                  css.CompilationCard__infoCard__card__col,
                  css.CompilationCard__infoCard__card__col__sizeRate
                )}
              >
                <div className={css.CompilationCard__infoCard__card__col__rate}>{t('rate')}</div>
                <Rate noChange count={5} value={compilation.rate} disabled />
              </div>
              {compilation.manager && (
                <div
                  className={classNames(
                    css.CompilationCard__infoCard__card__col,
                    css.CompilationCard__infoCard__card__col__sizeExpert
                  )}
                >
                  <div className={css.CompilationCard__infoCard__card__col__expert}>{t('expert')}</div>
                  <div
                    data-qa={`${dataqa}-expert`}
                    className={css.CompilationCard__autor}
                    onClick={() => navigate(`/collegues/${compilation.manager.id}`)}
                  >
                    {compilation.manager.name.length > 24
                      ? compilation.manager.name.split(0, 21) + '...'
                      : compilation.manager.name}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
};

CompilationCard.propTypes = {
  compilation: PropTypes.object,
  dataqa: PropTypes.string,
  searchParams: PropTypes.object,
  setSearchParams: PropTypes.func,
  shortDescription: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default CompilationCard;
