// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-Z50vnT.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-xtBLY0 .user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-label-S6w_Tl[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-Z50vnT.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-xtBLY0 .user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-item-q9RZlb[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-Montserrat-XxWo4k {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test-jV38T3 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test__text-rmN9lz {
  width: 100%;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/test/StartTest/StartTest.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,WAAW;EACX,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Modal-for-test {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n.Modal-for-test__text {\n  font-size: 16px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-Z50vnT`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-Z50vnT`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-xtBLY0`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-xtBLY0`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-label-S6w_Tl`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-label-S6w_Tl`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-item-q9RZlb`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-picker-item-q9RZlb`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-Montserrat-XxWo4k`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__ql-font-Montserrat-XxWo4k`,
	"Modal-for-test": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test-jV38T3`,
	"modalForTest": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test-jV38T3`,
	"Modal-for-test__text": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test__text-rmN9lz`,
	"modalForTestText": `user-src-pages-CollectionMaterialsIDStudy-test-StartTest-StartTest-module__Modal-for-test__text-rmN9lz`
};
export default ___CSS_LOADER_EXPORT___;
