/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getBookAddresses, updateBookAddresses } from '@/store/books/actions';
import { getFile } from '@/store/files/actions';
import { getBlockStatistic } from '@/store/lms/actions';
import { clearPlan, createStudyPlan } from '@/store/study-plan/actions';
import { clearSubType } from '@/store/topics/actions';
import { createUserTopic, deleteUserTopic } from '@/store/user-topics/actions';

import { selectOpenDate } from '@/store/course/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Spin, Tooltip } from 'antd';
import CalendarModal from '@/pages/CalendarModal/index.jsx';
import MaterialsEmptyPlaceholder from '@/pages/CollectionMaterials/MaterialsEmptyPlaceholder';
import AddressBook from '@/components/AddressBook/AddressBook';
import MaterialCard from '@/components/MaterialCard';
import TopicAddModal from '@/components/TopicAddModal';

import ModalCurrentPaidTopic from '../ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '../ModalPaidTopicRestriction/ModalPaidTopicRestriction';
import ParticipationConfirmationModal from '../ModalTargetAudienceApproval/Modal';

import useIsMobile from '@shared/hooks/useIsMobile';
import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
  isEvent,
  isExternalCourse,
} from '@shared/utils/topic-utils';

import Resources from '@/resources';
import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './TopicCards.module.scss';

const TopicCards = ({
  isLoading,
  isAddMaterialButton,
  isFavorite,
  topics,
  isFilterNotEmpty,
  searchText,
  isLibrary,
}) => {
  const { t } = useTranslation('collectionMaterials');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser, corpCourseBackground } = useSelector(selectUsers);
  const { externalLogin } = useSelector(selectLogin);
  const openDate = useSelector(selectOpenDate);
  const { previousStudyPlansCheckResult } = useSelector(selectStudyPlan);

  const [calendarModal, setCalendarModal] = useState({
    visible: false,
    planningTopicId: null,
    planningTopicType: {},
    topicInfo: null,
    startDate: null,
  });
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [isFavs, setIsFavs] = useState({});
  const [addressState, setAddressState] = useState({
    isAddressBookVisible: false,
    calendarModalInfo: {},
    addresses: [],
    flagNow: false,
  });
  const [addressId, setAddressId] = useState(0);
  const [isInPlan, setIsInPlan] = useState({});
  const [showParticipationConfirmationModal, setShowParticipationConfirmationModal] = useState(false);
  const [currentPaidTopicId, setCurrentPaidTopicId] = useState(null);
  const [currentPaidTopicName, setCurrentPaidTopicName] = useState('');
  const [isModalCurrentPaidTopicOpen, setIsModalCurrentPaidTopicOpen] = useState(false);
  const [isModalPaidTopicRestrictionOpen, setIsModalPaidTopicRestrictionOpen] = useState(false);
  const [handleStudyPlanClickState, setHandleStudyPlanClickState] = useState(null);

  useEffect(() => {
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      !previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setCurrentPaidTopicName(previousStudyPlansCheckResult?.topicName);
      setCurrentPaidTopicId(previousStudyPlansCheckResult?.topicId);
      setIsModalCurrentPaidTopicOpen(true);
    }
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setIsModalPaidTopicRestrictionOpen(true);
    }
    if (previousStudyPlansCheckResult?.availableCreation && typeof handleStudyPlanClickState === 'function') {
      handleStudyPlanClickState();
    }
  }, [previousStudyPlansCheckResult]);
  const [tablet] = useIsMobile(992);

  const handleFavoriteClick = topicId => {
    dispatch(
      createUserTopic(currentUser.id, topicId, () => {
        setIsFavs({ ...isFavs, [topicId]: true });
      })
    );
  };

  const handleDeleteClick = topicId => {
    dispatch(
      deleteUserTopic(topicId, true, true, () => {
        setIsFavs({ ...isFavs, [topicId]: false });
      })
    );
  };

  const handleStudyPlanClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    topicTargetAudience, // для MARS
    planInfo, // для MARS
    selectedId // для MARS
  ) => {
    for (let i = 0; i < planningMonths.length; i++) {
      dispatch(
        createStudyPlan(
          currentUser.id,
          topicId,
          planningMonths[i],
          comment,
          () => {
            if (addressId > 0) {
              dispatch(updateBookAddresses(addressId, currentUser.id));
              setAddressId(0);
            }

            setIsInPlan({ ...isInPlan, [topicId]: true });
            closeCalendarModal();

            if (
              (topicTargetAudience === false && isAnyCourseOrConference(calendarModal?.planningTopicType?.id)) ||
              (!topicTargetAudience &&
                Array.isArray(topics) &&
                topics.some(
                  t =>
                    t.id === topicId &&
                    t.targetAudience === false &&
                    isAnyCourseOrConference(calendarModal?.planningTopicType?.id)
                ))
            ) {
              setShowParticipationConfirmationModal(true);
            }
          },
          false,
          getWithoutSchedule(calendarModal?.planningTopicType?.id, withoutSchedule),
          null, // для MARS
          selectedId // для MARS
        )
      );
    }
  };

  const handleParticipationConfirmationModalCancel = () => {
    setShowParticipationConfirmationModal(false);
  };

  const handleModalCurrentPaidTopicClose = () => {
    setIsModalCurrentPaidTopicOpen(false);
    dispatch(clearPlan());
  };

  const handleModalPaidTopicRestrictionClose = () => {
    setIsModalPaidTopicRestrictionOpen(false);
    dispatch(clearPlan());
  };

  const handleAddressBookSubmit = currentAddressId => {
    const { topicId, type, startDate, topicInfo } = addressState?.calendarModalInfo;

    setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] });
    setAddressId(currentAddressId > 0 ? currentAddressId : 0);

    if (topicInfo.withNoEvents && addressState.flagNow) {
      addToPlanNowRequest(topicId, topicInfo.duration);
      setAddressState({ flagNow: false });
    } else {
      showCalendarModal(topicId, type, startDate, topicInfo);
    }
  };

  const handleCancel = () => {
    setAddModalVisible(false);
    dispatch(clearSubType());
  };

  const handleTopicAddSuccess = () => {
    handleCancel();
  };

  const showCalendarModal = (topicId, type, startDate, topicInfo) => {
    setCalendarModal({
      visible: true,
      planningTopicId: topicId,
      planningTopicType: type,
      topicInfo,
      startDate,
    });
  };

  const closeCalendarModal = () => {
    setCalendarModal({
      visible: false,
      planningTopicId: null,
      planningTopicType: {},
      topicInfo: null,
      startDate: null,
    });
  };

  const showAddressBookModal = (topicId, type, startDate, topicInfo) => {
    dispatch(
      getBookAddresses(
        topicId,
        data => {
          const addresses = data.map(item => ({
            id: item.id,
            address: item.addressDto.address,
            accessible: item.accessible,
          }));

          if (addresses.length > 0) {
            setAddressState({
              addresses,
              isAddressBookVisible: true,
              calendarModalInfo: { topicId, type, startDate, topicInfo },
            });
            return;
          }

          showCalendarModal(topicId, type, startDate, topicInfo);

          setAddressState({ ...addressState, calendarModalInfo: {} });
        },
        () => setAddressState({ ...addressState, addresses: [] })
      )
    );
  };

  const addToPlanNowRequest = (topicId, topicDuration = 0) => {
    const date = Utils.getPlanDate(topicDuration);

    dispatch(
      getBlockStatistic({
        topicId,
        userId: currentUser.id,
        onSuccess: response => {
          const blocksCount = response?.countBlocks || 0;

          if (addressId > 0) {
            dispatch(updateBookAddresses(addressId, currentUser.id));
            setAddressId(0);
          }

          dispatch(
            createStudyPlan(currentUser.id, topicId, date, '', () => {
              navigate(Utils.getNavigateUrl(topicId, blocksCount));
            })
          );
        },
      })
    );
  };

  const onAddToPlanNow = topic => {
    if (topic.qiwiLibrary) {
      const withNoEvents = Utils.isWithoutEvents(topic);
      const topicInfo = {
        id: topic.id,
        independent: topic.independent,
        startDate: topic.startDate,
        topicCost: topic.cost,
        topicDates: Array.isArray(topic.dates) && topic.dates.map(date => ({ dates: date.startDate })),
        link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
        eventCount: topic.futureEvents,
        topicComment: topic.comment,
        withNoEvents,
        duration: topic.duration,
      };

      dispatch(
        getBookAddresses(
          topic.id,
          data => {
            const addresses = data.map(item => ({
              id: item.id,
              address: item.addressDto.address,
              accessible: item.accessible,
            }));

            if (addresses.length > 0) {
              setAddressState({
                addresses,
                isAddressBookVisible: true,
                calendarModalInfo: {
                  topicId: topic.id,
                  type: topic.type,
                  startDate: topic.startDate,
                  topicInfo,
                },
                flagNow: true,
              });
              return;
            }

            addToPlanNowRequest(topic.id, topic.duration);
          },
          () => setAddressState({ ...addressState, addresses: [] })
        )
      );
    } else {
      addToPlanNowRequest(topic.id, topic.duration);
    }
  };

  const disabledSelect = calendarModal?.planningTopicType?.id === 8;

  const AllCards = (
    <>
      {topics?.map((topic, key) => (
        <div
          key={`topic_${key}${topic.id}`}
          className={classNames(css['TopicCards-material-card'], {
            [css.favoriteCard]: isFavorite,
          })}
        >
          <MaterialCard
            dataQa='topic'
            topic={topic}
            isFav={isFavs && isFavs[topic.id]}
            isInPlan={isInPlan[topic.id]}
            userTopicId={topic.id}
            onDeleteClick={(...args) => dispatch(deleteUserTopic(args))}
            onDeleteClickCatalog={handleDeleteClick}
            onFavoriteClickCatalog={handleFavoriteClick}
            currentUser={currentUser}
            isExternalLogin={Boolean(externalLogin)}
            onFavoriteClick={(...args) => dispatch(createUserTopic(args))}
            onStudyPlanClick={topic.qiwiLibrary ? showAddressBookModal : showCalendarModal}
            getFile={(...args) => {
              dispatch(getFile(...args));
            }}
            domainCompany={currentUser.domainCompany}
            openDate={openDate?.openDate}
            corpCourseBackground={corpCourseBackground}
            onAddToPlanNow={() => onAddToPlanNow(topic)}
            setHandleStudyPlanClickState={setHandleStudyPlanClickState}
          />
        </div>
      ))}
    </>
  );

  const showAddMaterial =
    !tablet && isAddMaterialButton && !isLoading && currentUser?.domainCompany?.topicTypes?.length !== 0;

  return (
    <>
      <Spin
        spinning={isLoading}
        size={'large'}
        wrapperClassName={classNames(
          css['TopicCards-material-cards'],
          isLibrary && topics?.length === 0 && css['TopicCards-library'],
          isFavorite && css['TopicCards-material-cards-favorite']
        )}
      >
        <div
          className={classNames(
            css['TopicCards-alignmentBlock'],
            !topics?.length && css['TopicCards-alignmentBlock-noTopics']
          )}
        >
          {showAddMaterial && (
            <div className={css['TopicCards-material-card']}>
              <div
                className={classNames(css['TopicCards-add-material'], css['App-card-item'])}
                data-qa='userCollectionTopicsAddMaterialCard'
                onClick={() => setAddModalVisible(true)}
              >
                <Tooltip title={t('addToCollection')}>
                  <div className={css['TopicCards-add-material-icon']}>
                    <div className={css['TopicCards-add-material-icon-content']}>
                      <Resources.icons.Plus />
                    </div>
                  </div>
                </Tooltip>
                <h3>{t('addMaterial')}</h3>
              </div>
            </div>
          )}
          {topics?.length > 0
            ? AllCards
            : !isLoading && (
                <MaterialsEmptyPlaceholder
                  isExternalLogin={Boolean(externalLogin)}
                  isFavorite={isFavorite}
                  isSearching={isFilterNotEmpty == true}
                  searchName={searchText}
                  isLibrary={isLibrary}
                />
              )}
        </div>
      </Spin>

      {addModalVisible && (
        <TopicAddModal visible={addModalVisible} onTopicAddSuccess={handleTopicAddSuccess} onCancel={handleCancel} />
      )}

      {isModalCurrentPaidTopicOpen && (
        <ModalCurrentPaidTopic
          open={isModalCurrentPaidTopicOpen}
          onClose={handleModalCurrentPaidTopicClose}
          currentPaidTopicName={currentPaidTopicName}
          currentPaidTopicId={currentPaidTopicId}
        />
      )}

      {isModalPaidTopicRestrictionOpen && (
        <ModalPaidTopicRestriction
          open={isModalPaidTopicRestrictionOpen}
          onClose={handleModalPaidTopicRestrictionClose}
        />
      )}

      {showParticipationConfirmationModal && (
        <ParticipationConfirmationModal
          open={showParticipationConfirmationModal}
          onClose={handleParticipationConfirmationModalCancel}
        />
      )}

      {calendarModal.visible && (
        <CalendarModal
          topicInfo={calendarModal.topicInfo}
          comment={calendarModal?.topicInfo?.topicComment}
          eventTypeId={calendarModal?.planningTopicType?.id}
          disabledSelect={disabledSelect}
          dateEvent={dayjs(calendarModal.startDate).format('YYYY-MM-DD')}
          title={!disabledSelect ? t('selectPlan') : t('scheduleEvent')}
          open={calendarModal.visible}
          onOk={handleStudyPlanClick}
          onCancel={closeCalendarModal}
          dates={calendarModal?.topicInfo?.topicDates}
          okText={t('add')}
          {...(isExternalCourse(calendarModal?.planningTopicType?.id) && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
          {...(isEvent(calendarModal?.planningTopicType?.id) && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
          planningTopicId={calendarModal.planningTopicId}
          hasEvents={calendarModal?.topicInfo?.eventCount !== 0}
          selectMonthEvents={
            isCorpCourseWithOrWithoutApprove(calendarModal?.planningTopicType?.id) ||
            isExternalCourse(calendarModal?.planningTopicType?.id)
          }
          currentUser={currentUser}
        />
      )}

      {addressState.isAddressBookVisible && (
        <AddressBook
          textWindowTitle={t('addressBookWindowTitle')}
          textSubmitButton={t('addressBookSubmitButton')}
          textAccessible={t('addressBookaccessible')}
          textNotAccessible={t('addressBookNotAccessible')}
          textReadOnline={t('addressBookReadOnline')}
          onButtonClose={() => setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] })}
          onButtonSubmit={handleAddressBookSubmit}
          open={addressState.isAddressBookVisible}
          data={addressState?.addresses}
        />
      )}
    </>
  );
};

TopicCards.propTypes = {
  isLoading: PropTypes.bool,
  track: PropTypes.object,
  isLibrary: PropTypes.bool,
};

export default TopicCards;
