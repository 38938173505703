// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-planning-page-cards-block-styles-module__ql-picker-IRNP1J.user-src-pages-planning-page-cards-block-styles-module__ql-font-y81wjS .user-src-pages-planning-page-cards-block-styles-module__ql-picker-label-FbNE8y[data-value="Montserrat"]:before, .user-src-pages-planning-page-cards-block-styles-module__ql-picker-IRNP1J.user-src-pages-planning-page-cards-block-styles-module__ql-font-y81wjS .user-src-pages-planning-page-cards-block-styles-module__ql-picker-item-gA7yKg[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-planning-page-cards-block-styles-module__ql-font-Montserrat-TRm4PF {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block-imWEze > h2 {
  letter-spacing: normal;
  color: #000;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-pages-planning-page-cards-block-styles-module__cards-block-imWEze > h2 {
    font-size: 16px;
  }
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block-title-CqwmpB {
  justify-content: space-between;
  margin: 16px 0;
  display: flex;
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block-title-filter-isRjOl {
  gap: 8px;
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block__cards-N8PpWF {
  flex-direction: column;
  display: flex;
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block__cards-N8PpWF > div:not(:last-child) {
  margin-bottom: 12px;
}

.user-src-pages-planning-page-cards-block-styles-module__cards-block-close-UD7m4e {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/planning-page/cards-block/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAMA;EACE;IAHE,eAAe;EACjB;AACF;;AAKA;EAFE,8BAA8B;EAC9B,cAAc;EACd,aAAa;AACf;;AAIA;EADE,QAAQ;AACV;;AAGA;EAAE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AACA;EAEE,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.cards-block > h2 {\n  font-weight: 700;\n  margin-bottom: 12px;\n  font-size: 20px;\n  line-height: 1.4;\n  letter-spacing: normal;\n  color: #000;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .cards-block > h2 {\n    font-size: 16px;\n  }\n}\n.cards-block-title {\n  margin: 16px 0;\n  display: flex;\n  justify-content: space-between;\n}\n.cards-block-title-filter {\n  gap: 8px;\n}\n.cards-block__cards {\n  display: flex;\n  flex-direction: column;\n}\n.cards-block__cards > div:not(:last-child) {\n  margin-bottom: 12px;\n}\n.cards-block-close {\n  margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-IRNP1J`,
	"qlPicker": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-IRNP1J`,
	"ql-font": `user-src-pages-planning-page-cards-block-styles-module__ql-font-y81wjS`,
	"qlFont": `user-src-pages-planning-page-cards-block-styles-module__ql-font-y81wjS`,
	"ql-picker-label": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-label-FbNE8y`,
	"qlPickerLabel": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-label-FbNE8y`,
	"ql-picker-item": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-item-gA7yKg`,
	"qlPickerItem": `user-src-pages-planning-page-cards-block-styles-module__ql-picker-item-gA7yKg`,
	"ql-font-Montserrat": `user-src-pages-planning-page-cards-block-styles-module__ql-font-Montserrat-TRm4PF`,
	"qlFontMontserrat": `user-src-pages-planning-page-cards-block-styles-module__ql-font-Montserrat-TRm4PF`,
	"cards-block": `user-src-pages-planning-page-cards-block-styles-module__cards-block-imWEze`,
	"cardsBlock": `user-src-pages-planning-page-cards-block-styles-module__cards-block-imWEze`,
	"cards-block-title": `user-src-pages-planning-page-cards-block-styles-module__cards-block-title-CqwmpB`,
	"cardsBlockTitle": `user-src-pages-planning-page-cards-block-styles-module__cards-block-title-CqwmpB`,
	"cards-block-title-filter": `user-src-pages-planning-page-cards-block-styles-module__cards-block-title-filter-isRjOl`,
	"cardsBlockTitleFilter": `user-src-pages-planning-page-cards-block-styles-module__cards-block-title-filter-isRjOl`,
	"cards-block__cards": `user-src-pages-planning-page-cards-block-styles-module__cards-block__cards-N8PpWF`,
	"cardsBlockCards": `user-src-pages-planning-page-cards-block-styles-module__cards-block__cards-N8PpWF`,
	"cards-block-close": `user-src-pages-planning-page-cards-block-styles-module__cards-block-close-UD7m4e`,
	"cardsBlockClose": `user-src-pages-planning-page-cards-block-styles-module__cards-block-close-UD7m4e`
};
export default ___CSS_LOADER_EXPORT___;
