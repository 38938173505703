// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-UserLayout-userLayout-module__ql-picker-I5vZGk.user-src-pages-UserLayout-userLayout-module__ql-font-xC1lCE .user-src-pages-UserLayout-userLayout-module__ql-picker-label-gRqErg[data-value="Montserrat"]:before, .user-src-pages-UserLayout-userLayout-module__ql-picker-I5vZGk.user-src-pages-UserLayout-userLayout-module__ql-font-xC1lCE .user-src-pages-UserLayout-userLayout-module__ql-picker-item-YWNMVL[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-UserLayout-userLayout-module__ql-font-Montserrat-EOVOHU {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-UserLayout-userLayout-module__content-iECCZM {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 2.5em 1em;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/UserLayout/userLayout.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.content {\n  width: 100%;\n  max-width: 1300px;\n  margin: auto;\n  padding: 2.5em 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-UserLayout-userLayout-module__ql-picker-I5vZGk`,
	"qlPicker": `user-src-pages-UserLayout-userLayout-module__ql-picker-I5vZGk`,
	"ql-font": `user-src-pages-UserLayout-userLayout-module__ql-font-xC1lCE`,
	"qlFont": `user-src-pages-UserLayout-userLayout-module__ql-font-xC1lCE`,
	"ql-picker-label": `user-src-pages-UserLayout-userLayout-module__ql-picker-label-gRqErg`,
	"qlPickerLabel": `user-src-pages-UserLayout-userLayout-module__ql-picker-label-gRqErg`,
	"ql-picker-item": `user-src-pages-UserLayout-userLayout-module__ql-picker-item-YWNMVL`,
	"qlPickerItem": `user-src-pages-UserLayout-userLayout-module__ql-picker-item-YWNMVL`,
	"ql-font-Montserrat": `user-src-pages-UserLayout-userLayout-module__ql-font-Montserrat-EOVOHU`,
	"qlFontMontserrat": `user-src-pages-UserLayout-userLayout-module__ql-font-Montserrat-EOVOHU`,
	"content": `user-src-pages-UserLayout-userLayout-module__content-iECCZM`
};
export default ___CSS_LOADER_EXPORT___;
