/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getAssessmentUrl, getScore } from '@/store/talentq/actions';

import { selectTalentQ } from '@/store/talentq/selectors';

import { Layout, notification } from 'antd';
import { Content } from '@/components/Layout';

import Result from './CheckResult/CheckResult';
import Welcome from './Welcome/Welcome';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

const DiagnosticTalentQ = props => {
  const { t } = props;
  const [welcome, setWelcome] = useState(true);
  const [result, setResult] = useState(false);

  useEffect(() => {
    props.getAssessmentUrl();
  }, [props.getAssessmentUrl]);

  const checkResult = () => {
    props.getScore();
    setWelcome(false);
    setResult(true);
  };

  const startTest = () => {
    if (props.selectTalentQ && props.selectTalentQ.assessmentUrl && props.selectTalentQ.assessmentUrl.url) {
      window.open(props.selectTalentQ.assessmentUrl.url, '_blank');
    } else {
      notification.warning({
        message: t('titleNot'),
        description: t('titleDesc'),
      });
    }
  };

  return (
    <Layout>
      <Content>
        {welcome && <Welcome t={t} onButtonTest={startTest} onButtonResult={checkResult} />}
        {result && <Result t={t} />}
      </Content>
    </Layout>
  );
};

DiagnosticTalentQ.propTypes = {
  onCloseModalTalentQ: PropTypes.func,
  onShowModalTalentQ: PropTypes.func,
  t: PropTypes.func,
  visible: PropTypes.bool,
  getAssessmentUrl: PropTypes.func,
  checkResult: PropTypes.func,
  startTest: PropTypes.func,
  selectTalentQ: PropTypes.object,
};

const mapStateToProps = createSelector([selectTalentQ], selectTalentQ => ({
  selectTalentQ: selectTalentQ,
}));

const mapActionsToProps = {
  getAssessmentUrl,
  getScore,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticTalentq')(DiagnosticTalentQ));
