export const GET_TRACKS_START = 'GET_TRAKS_START';
export const GET_TRACKS_SUCCESS = 'GET_TRAKS_SUCCESS';
export const GET_TRACKS_FAIL = 'GET_TRAKS_FAIL';

export const GET_TRACK_BY_ID_START = 'GET_TRACK_BY_ID_START';
export const GET_TRACK_BY_ID_SUCCESS = 'GET_TRACK_BY_ID_SUCCESS';
export const GET_TRACK_BY_ID_FAIL = 'GET_TRACK_BY_ID_FAIL';

export const GET_TRACKS_STATUS_START = 'GET_TRACKS_STATUS_START';
export const GET_TRACKS_STATUS_SUCCESS = 'GET_TRACKS_STATUS_SUCCESS';
export const GET_TRACKS_STATUS_FAIL = 'GET_TRACKS_STATUS_FAIL';
