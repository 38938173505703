import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Button, Checkbox, Form, Input } from 'antd';
import LogoCompany from '@/components/LogoCompany/LogoCompany';

import { domainCompanyid } from '@/utils/domainCompanyId';

import classNames from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';

import css from './Login.module.scss';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('registration');

  const {
    company,
    imageBackgroundLogin,
    imageLogo,
    registerUserRecaptcha, // Example additional context values
  } = useOutletContext();

  const [domainsInformation, setDomainsInformation] = useState({});
  console.log('company', company);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  const handleSubmit = formData => {
    registerUserRecaptcha(formData.email, formData.newPassword);
  };

  const handleChangeRenderPage = flag => {
    navigate(`/user-login/${flag}`);
  };

  const isKampus = document.location.hostname === 'td.kampus.team';

  useLayoutEffect(() => {
    const emailDomains = get(find(company?.providers, { type: 'INTERNAL' }), 'domains', []);
    const showDomain = emailDomains?.map(domain => `@${domain}`);
    const showDomainLabel = showDomain?.join(', ');
    setDomainsInformation({
      emailDomains: emailDomains,
      showDomainsError:
        emailDomains.length !== 1 ? `${t('domains')} ${showDomainLabel}` : `${t('domain')} ${showDomainLabel}`,
    });
  }, []);

  return (
    <div className={css['App-card-item']}>
      <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
      <Form className={css['LoginPage-form']} onFinish={handleSubmit}>
        <div className={css['LoginPage-form__content']}>
          <div className={css['LoginPage-header']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
            {company?.id !== domainCompanyid.ABSOLUTE_ID && <div className={css['LoginPage-slogan']}>{t('smart')}</div>}
          </div>
          <div className={classNames(css['LoginPage-titleHead'], css['LoginPage-titleHead-modificate'])}>
            {t('registration')}
          </div>
          <Form.Item
            colon={false}
            name='email'
            className={css['LoginPage-form-item']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t('mailRequired'),
              },
              {
                type: 'email',
                message: t('checkMail'),
              },
              {
                message: domainsInformation?.showDomainsError,
                validator: (_, value) => {
                  if (value) {
                    const indexEmail = value.indexOf('@');
                    const subEmail = value.substring(indexEmail + 1);
                    if (
                      domainsInformation?.emailDomains.length === 0 ||
                      domainsInformation?.emailDomains.findIndex(item => item === subEmail) !== -1
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input placeholder={t('mail')} />
          </Form.Item>
          <Form.Item
            colon={false}
            name='newPassword'
            className={css['LoginPage-form-item']}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: t('passwordRequired'),
              },
              {
                min: 6,
                max: 25,
                message: t('passwordLength'),
              },
              {
                pattern: /^[a-zA-Z0-9!@#$%^&*]/,
                message: t('passwordWrong'),
              },
            ]}
          >
            <Input.Password placeholder={t('password')} className={css['LoginPage-form-item-password']} />
          </Form.Item>
          {isKampus && (
            <Form.Item className={css['LoginPage-form-checkbox']}>
              <Checkbox checked={acceptPrivacy} onChange={() => setAcceptPrivacy(!acceptPrivacy)} />
              &nbsp; Нажимая на кнопку, вы даете согласие на обработку персональных данных, а также соглашаетесь с
              &nbsp;<a href='https://kampus.team/privacy'>политикой конфиденциальности</a>
            </Form.Item>
          )}
          <Form.Item className={css['LoginPage-form-google']}>
            <Button size='large' type={'primary'} htmlType='submit' disabled={isKampus ? !acceptPrivacy : false}>
              {t('singUp')}
            </Button>
          </Form.Item>
          <div className={css['LoginPage-label']}>
            <div className={css.LoginPage__toInfo} onClick={() => handleChangeRenderPage('auth')}>
              {t('alreadyGotAcc')}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationPage;
