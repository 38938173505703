// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-dropdown-menu-styles-module__ql-picker-x5emhq.user-src-components-dropdown-menu-styles-module__ql-font-b7KfsJ .user-src-components-dropdown-menu-styles-module__ql-picker-label-gD2jCO[data-value="Montserrat"]:before, .user-src-components-dropdown-menu-styles-module__ql-picker-x5emhq.user-src-components-dropdown-menu-styles-module__ql-font-b7KfsJ .user-src-components-dropdown-menu-styles-module__ql-picker-item-QRtpL9[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-dropdown-menu-styles-module__ql-font-Montserrat-FxDAtB {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-dropdown-menu-styles-module__dropdown-menu-iU2BpR {
  margin: auto;
}

.user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe {
  background: none;
  border: none;
  height: 23px;
  display: flex;
}

.user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe:hover {
  cursor: pointer;
}

.user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe span {
  background-color: #999;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  transform: rotate(-90deg);
}

.user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe span:not(:last-child) {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/dropdown-menu/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,YAAY;AACd;;AAOA;EAJE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B;;AAIA;EADE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.dropdown-menu {\n  margin: auto;\n}\n\n.ant-dropdown-link {\n  display: flex;\n  background: none;\n  border: none;\n  height: 23px;\n}\n.ant-dropdown-link:hover {\n  cursor: pointer;\n}\n.ant-dropdown-link span {\n  width: 5px;\n  height: 5px;\n  transform: rotate(-90deg);\n  background-color: #999;\n  border-radius: 50%;\n}\n.ant-dropdown-link span:not(:last-child) {\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-dropdown-menu-styles-module__ql-picker-x5emhq`,
	"qlPicker": `user-src-components-dropdown-menu-styles-module__ql-picker-x5emhq`,
	"ql-font": `user-src-components-dropdown-menu-styles-module__ql-font-b7KfsJ`,
	"qlFont": `user-src-components-dropdown-menu-styles-module__ql-font-b7KfsJ`,
	"ql-picker-label": `user-src-components-dropdown-menu-styles-module__ql-picker-label-gD2jCO`,
	"qlPickerLabel": `user-src-components-dropdown-menu-styles-module__ql-picker-label-gD2jCO`,
	"ql-picker-item": `user-src-components-dropdown-menu-styles-module__ql-picker-item-QRtpL9`,
	"qlPickerItem": `user-src-components-dropdown-menu-styles-module__ql-picker-item-QRtpL9`,
	"ql-font-Montserrat": `user-src-components-dropdown-menu-styles-module__ql-font-Montserrat-FxDAtB`,
	"qlFontMontserrat": `user-src-components-dropdown-menu-styles-module__ql-font-Montserrat-FxDAtB`,
	"dropdown-menu": `user-src-components-dropdown-menu-styles-module__dropdown-menu-iU2BpR`,
	"dropdownMenu": `user-src-components-dropdown-menu-styles-module__dropdown-menu-iU2BpR`,
	"ant-dropdown-link": `user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe`,
	"antDropdownLink": `user-src-components-dropdown-menu-styles-module__ant-dropdown-link-bUlYhe`
};
export default ___CSS_LOADER_EXPORT___;
