// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Competencies-CompetenciesTag-module__ql-picker-yVyE2M.user-src-components-Competencies-CompetenciesTag-module__ql-font-Fcufax .user-src-components-Competencies-CompetenciesTag-module__ql-picker-label-NyTZBL[data-value="Montserrat"]:before, .user-src-components-Competencies-CompetenciesTag-module__ql-picker-yVyE2M.user-src-components-Competencies-CompetenciesTag-module__ql-font-Fcufax .user-src-components-Competencies-CompetenciesTag-module__ql-picker-item-awYbzW[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Competencies-CompetenciesTag-module__ql-font-Montserrat-gHTFo4 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Competencies-CompetenciesTag-module__CompetenceTag-hHTloX {
  background-color: var(--personalColor);
  white-space: nowrap;
  letter-spacing: 1.5px;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  padding: 0 15px;
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 34px;
  display: inline-block;
  position: relative;
}

.user-src-components-Competencies-CompetenciesTag-module__CompetenceTag-hHTloX:not(:last-child) {
  margin-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Competencies/CompetenciesTag.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sCAAsC;EACtC,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAOA;EAJE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.CompetenceTag {\n  padding: 0 15px;\n  margin-top: 7px;\n  display: inline-block;\n  position: relative;\n  border-radius: 5px;\n  background-color: var(--personalColor);\n  height: 34px;\n  white-space: nowrap;\n  font-size: 13px;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 34px;\n  letter-spacing: 1.5px;\n  color: white;\n  border: none;\n}\n.CompetenceTag:not(:last-child) {\n  margin-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-yVyE2M`,
	"qlPicker": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-yVyE2M`,
	"ql-font": `user-src-components-Competencies-CompetenciesTag-module__ql-font-Fcufax`,
	"qlFont": `user-src-components-Competencies-CompetenciesTag-module__ql-font-Fcufax`,
	"ql-picker-label": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-label-NyTZBL`,
	"qlPickerLabel": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-label-NyTZBL`,
	"ql-picker-item": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-item-awYbzW`,
	"qlPickerItem": `user-src-components-Competencies-CompetenciesTag-module__ql-picker-item-awYbzW`,
	"ql-font-Montserrat": `user-src-components-Competencies-CompetenciesTag-module__ql-font-Montserrat-gHTFo4`,
	"qlFontMontserrat": `user-src-components-Competencies-CompetenciesTag-module__ql-font-Montserrat-gHTFo4`,
	"CompetenceTag": `user-src-components-Competencies-CompetenciesTag-module__CompetenceTag-hHTloX`,
	"competenceTag": `user-src-components-Competencies-CompetenciesTag-module__CompetenceTag-hHTloX`
};
export default ___CSS_LOADER_EXPORT___;
