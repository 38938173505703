/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import css from './AnswerInfo.module.scss';

const cn = classNames(css);

function Answer(props) {
  const [image, setImage] = useState();

  useEffect(() => {
    props.uuid && props.getFile(props.uuid, blob => setImage(URL.createObjectURL(blob)));
  }, []);

  const render = () => {
    const { inputType, inputChecked, isCorrect, text, color } = props;

    return (
      <li key={text} className={css.Question__variants__variant}>
        <label
          className={cn(css.Question__variants__variant__label, {
            Question__variants__variant__label_notCorrect: !isCorrect && inputChecked,
          })}
        >
          {image && (
            <div className={cn(css.Question__variants__variant__label__image)} style={{ backgroundColor: color }}>
              <img style={{ backgroundColor: color }} src={image} alt='' />
            </div>
          )}
          <div className={cn(css.Question__variants__variant__label__box)}>
            <input type={inputType} checked={inputChecked} readOnly />
            <span>{text}</span>
          </div>
        </label>
      </li>
    );
  };

  return render();
}

Answer.propTypes = {
  color: PropTypes.string,
  uuid: PropTypes.string,
  text: PropTypes.string,
  inputType: PropTypes.string,
  inputChecked: PropTypes.bool,
  isCorrect: PropTypes.bool,
};

const mapActionToProps = {
  getFile,
};

export default connect(null, mapActionToProps)(Answer);
