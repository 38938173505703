/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { Layout } from 'antd';

import css from './Store.module.scss';

const { Content } = Layout;

export default class Store extends Component {
  render() {
    return (
      <Content className={css['App-content']}>
        <h1>Лавка</h1>
        <div className={css['App-card-item']}>
          <div className={css['Store-content']}>
            <h3 className={css['Store-header']}>Привет!</h3>
            <p className={css['Store-text']}>
              Этот раздел еще будет дополняться, но пока важная информация для тебя...
              <br />
              Зарабатывай как можно больше Коинов, становись одним из самых активных пользователей Kampus и тогда ты
              станешь участником розыгрыша ценных и крутых призов!
            </p>
            <h3 className={css['Store-header']}>Как можно заработать Коины?</h3>
            <p className={css['Store-text']}>
              1. За вход на портал(не более 1 раза в сутки) - 10 коинов
              <br />
              2. За загрузку на портал нового материала - 100 коинов
              <br />
              3. За выполнение(завершение) материала - 50 коинов
            </p>
          </div>
        </div>
      </Content>
    );
  }
}
