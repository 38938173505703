/* eslint-disable */
import { GET_FUNCTIONS_FAIL, GET_FUNCTIONS_START, GET_FUNCTIONS_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getFunctions = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/functionInternal${getQueryString({
    sort: 'name,asc',
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_FUNCTIONS_START, GET_FUNCTIONS_SUCCESS, GET_FUNCTIONS_FAIL],
  onSuccess,
});
