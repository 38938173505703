/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { changeTopicFilter, getTopicCategories } from '@/store/topics/actions';

import { selectTopicsCategoriesUnion as topicCategoriesSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import Modal from '@/components/Modal';

import AboutItem from './AboutItem';
import FlowerItem from './FlowerItem';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './ModalFlower.module.scss';

export class ModalFlower extends Component {
  static propTypes = {
    categories: PropTypes.array,
    changeTopicFilter: PropTypes.func,
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    getTopicCategories: PropTypes.func,
    navigate: PropTypes.func,
    modalFlower: PropTypes.bool,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      about: [],
      flowerId: 1,
      categories: [],
    };
  }

  componentDidMount() {
    if (!this.props.categories) {
      this.props.getTopicCategories(this.props.domainCompany?.global);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      const aboutCategories =
        this.props.categories &&
        this.props.categories.length &&
        this.props.categories.map(categories => {
          return {
            id: categories.id,
            mainIcon: categories.mainIcon,
            background: categories.background,
            description: categories.description,
            name: categories.name,
          };
        });
      this.setState({
        categories: this.props.categories,
        about: aboutCategories,
      });
    }
  }

  changeFlowerID = id => {
    this.setState({ flowerId: id });
  };

  handleChangeLink = link => {
    const { navigate, onCancel } = this.props;
    this.props.changeTopicFilter('page', 0);
    this.props.changeTopicFilter('topicCategoryId', link);
    navigate('/collection-topics');
    onCancel();
  };

  showFlowerItem = () => {
    const { categories } = this.props;
    return (
      categories &&
      categories.map(item => (
        <FlowerItem
          key={item.id}
          item={item}
          handleChangeLink={this.handleChangeLink}
          changeFlowerID={this.changeFlowerID}
        />
      ))
    );
  };

  render() {
    const { flowerId, about } = this.state;
    const { modalFlower, onCancel } = this.props;
    const flowerImage = flowerId && about && about.find(flower => flower.id === flowerId);
    return (
      <Modal
        className={css['ModalFlower-modal']}
        open={modalFlower}
        width={1152}
        hideFooter
        flower
        maskClosable={false}
        closableControl
        zIndex={1005}
        onCancel={onCancel}
      >
        <div className={css.ModalFlower}>
          <div className={css['ModalFlower-label']}>Что хочешь изучать сегодня?</div>
          <div className={css['ModalFlower-body']}>
            <div className={css['ModalFlower-body-blockButton']}>{this.showFlowerItem()}</div>
            {flowerImage && (
              <AboutItem
                key={flowerImage && flowerImage.length && flowerImage[0].id}
                item={flowerImage}
                getFile={this.props.getFile}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector([usersSelect, topicCategoriesSelect], (users, categories) => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  categories,
}));

const mapActionsToProps = {
  changeTopicFilter,
  getTopicCategories,
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(ModalFlower);
