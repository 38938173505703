import { useTranslation } from 'react-i18next';

import { Avatar, Tooltip } from 'antd';
import { MAX_COMPETENCE_NAME } from '@/pages/CareerPlan/constants';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from '../MentorsCareerPlanModal.module.scss';

const MentorItemModal = ({ mentor, active, small = false, onClickMentor, disabled }) => {
  const { t } = useTranslation('careerPlan');
  const [mentorAvatar] = useLocalFileByUUID(mentor?.photoUuid || mentor?.localPhotoUuid, {
    defaultImg: PortraitPlaceholder,
  });

  const fullName = mentor?.firstName + ' ' + mentor?.lastName;
  const isActive = active.some(e => e?.id === mentor?.id);
  const isAvailable = mentor?.availableSlots !== 0;

  const tooltipTitle = title => {
    if (!isAvailable) {
      return t('mentorsModal.disabled');
    } else if (disabled) {
      return t('deleteMentor');
    } else {
      return title;
    }
  };

  if (small) {
    return (
      <div>
        <Tooltip placement='bottom' title={tooltipTitle(mentor?.firstName + ' ' + mentor?.lastName)}>
          <div className={css['MentorsModal-footer-mentors-item']} onClick={() => !disabled && onClickMentor(mentor)}>
            {!disabled && (
              <div className={css['MentorsModal-footer-mentors-item-remove']}>
                <AiOutlineClose />
              </div>
            )}
            <Avatar shape='circle' src={mentorAvatar} />
          </div>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <Tooltip title={tooltipTitle()}>
        <div
          key={mentor?.id}
          className={classNames(
            css['MentorsModal-mentors-card'],
            (!isAvailable || disabled) && css['MentorsModal-mentors-card-disabled']
          )}
          onClick={() => isAvailable && !disabled && onClickMentor(mentor)}
        >
          <Avatar className={css['MentorsModal-mentors-card-avatar']} size='large' shape='circle' src={mentorAvatar} />
          <div className={css['MentorsModal-mentors-card-info']}>
            <p className={css['MentorsModal-mentors-card-info-fullname']}>{fullName}</p>
            <div className={css['MentorsModal-mentors-card-info-competence']}>
              {mentor?.competences?.map(item => (
                <Tooltip key={item?.id} title={item?.name?.length >= MAX_COMPETENCE_NAME && item?.name}>
                  <div className={css['MentorsModal-mentors-card-info-competence-item']}>
                    {Utils.trString(item?.name, MAX_COMPETENCE_NAME)}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          <div
            className={classNames(
              css['MentorsModal-mentors-card-check'],
              isActive && css['MentorsModal-mentors-card-check-active']
            )}
          >
            <AiOutlineCheck color={isActive ? '#fff' : '#212121'} />
          </div>
        </div>
      </Tooltip>
    );
  }
};

MentorItemModal.propTypes = {
  active: PropTypes.array,
  mentor: PropTypes.shape({
    availableSlots: PropTypes.number,
    competences: PropTypes.array,
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    localPhotoUuid: PropTypes.string,
    photoUuid: PropTypes.string,
  }),
  onClickMentor: PropTypes.func,
  small: PropTypes.bool,
};

export default MentorItemModal;
