/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { getEventParty } from '@/store/study-plan/actions';

import { selectStudyPlan as planSelect } from '@/store/study-plan/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Col, Layout, Spin } from 'antd';

import PartyUser from './PartyUser';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './MaterialsTabs.module.scss';

const { Content } = Layout;

class MaterialsTabsParty extends Component {
  static propTypes = {
    currentUserId: PropTypes.number,
    domainCompany: PropTypes.object,
    eventParty: PropTypes.array,
    eventPartyPageNumber: PropTypes.number,
    eventPartyPageSize: PropTypes.number,
    eventPartyTotal: PropTypes.number,
    getEventParty: PropTypes.func,
    getFile: PropTypes.func,
    hasMore: PropTypes.bool,
    topic: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemsPerPage: 10,
      hasMoreItems: true,
      records: 10,
      page: 0,
    };
  }

  componentDidMount() {
    this.props.getEventParty(this.props.topic.id, {
      page: this.state.page,
      size: this.state.itemsPerPage,
      active: true,
    });
  }

  showUser = users => {
    if (users.length > 0) {
      return users.map((item, index) => (
        <PartyUser
          key={`PartyUser-${index}`}
          domainCompany={this.props.domainCompany}
          getFile={this.props.getFile}
          user={item.user}
        />
      ));
    }
    return <div>{this.props.t('noUsers')}</div>;
  };

  loadMore = () => {
    if (this.state.records < this.props.eventPartyTotal) {
      setTimeout(() => {
        this.setState({
          records: this.state.records + this.state.itemsPerPage,
          page: this.state.page + 1,
        });
        this.props.getEventParty(this.props.topic.id, {
          page: this.state.page,
          size: this.state.itemsPerPage,
          active: true,
        });
      }, 1000);
    }
  };

  render() {
    const { eventParty, hasMore, eventPartyPageNumber, t } = this.props;

    const spinner = (
      <Col className={css.loading} key={this.state.page}>
        <Spin size='large' tip={t('loading')} style={{ color: 'var(--personalColorDark)' }} />
      </Col>
    );

    return (
      <Content className={css.MaterialsTabsParty}>
        <div className={css['MaterialsTabsParty-container']}>
          <InfiniteScroll
            className={css.MaterialsTabsAboutBlock}
            pageStart={eventPartyPageNumber}
            initialLoad={false}
            loadMore={this.loadMore}
            hasMore={hasMore}
            threshold={300}
            useWindow
            loader={spinner}
          >
            {this.showUser(eventParty)}
          </InfiniteScroll>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = createSelector([planSelect, usersSelect], (studyPlan, users) => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  eventParty: studyPlan.eventParty,
  eventPartyTotal: studyPlan.eventPartyTotal,
  eventPartyPageSize: studyPlan.eventPartyPageSize,
  eventPartyPageNumber: studyPlan.eventPartyPageNumber,
  hasMore: studyPlan.hasMore,
}));

const mapActionsToProps = {
  getEventParty,
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('materialsTabsParty')(MaterialsTabsParty));
