import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { rejectMentee } from '@/store/mentee/actions';
import {
  clearCommentsGratitudeState,
  getMentorComments,
  getMentorCommentsCount,
  getMentorGratitude,
  getMentorID,
} from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Avatar, Button, Col, Layout, Row, Spin, Tabs, Tooltip } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import { Content } from '@/components/Layout';
import {
  ACTIVE,
  CANCEL_REQUEST_MENTORING,
  CLOSE,
  ON_WAITING_LIST,
  REQUEST_MENTORING,
  REQUEST_SEND,
  WAITING_LIST,
  WAITING_MENTOR,
} from '@/components/Mentoring/constants';
import ModalActions from '@/components/Mentoring/MentorActionModal/ModalActions';
import MentorTag from '@/components/Mentoring/MentorTag';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import { StopOutlined } from '@ant-design/icons';

import TabAboutMentor from './Tabs/TabAboutMentor';
import TabCommentsMentor from './Tabs/TabCommentsMentor';
import TabThanksMentor from './Tabs/TabThanksMentor';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import { mentors } from '@/router/routes';
import PropTypes from 'prop-types';

import css from './MentorsID.module.scss';

const MentorsID = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // при размонтирование компонента очищаем в сторе комменатриев и благодарностей
    return () => {
      dispatch(clearCommentsGratitudeState());
    };
  }, [dispatch]);

  const [mentorId, setMentorId] = useState(null);
  const { isLoadingMentorsID, mentorData, mentorsFilters, mentorsGratitudeTotal, mentorsCommentsCount } =
    useSelector(selectMentors);

  const { firstName, lastName, availableSlots, mentoringStatus, photoUuid, photoUuidUser, maxSlots, menteeId } =
    mentorData;
  const fullNameMentor = `${firstName || ''} ${lastName || ''}`.trim();
  const [isAction, setIsAction] = useState({
    visible: false,
    action: '',
  });

  useEffect(() => {
    if (props?.params?.id) {
      setMentorId(+props.params.id);
    }
  }, [props]);

  useEffect(() => {
    if (mentorId) {
      dispatch(getMentorID(mentorId));
      dispatch(getMentorComments(mentorId, 0, 5)); // mentorId, page, size
      dispatch(getMentorGratitude(mentorId, 0, 5)); // mentorId, page, size
      dispatch(getMentorCommentsCount(mentorId));
    }
  }, [dispatch, mentorId]);

  const showModal = action => setIsAction({ ...isAction, visible: true, action });

  const closeMentorID = () => navigate(`/${mentors.path}`, mentorsFilters);

  const onCloseModalActions = (block, close) => {
    if ((block === REQUEST_SEND && close !== CLOSE) || block === ON_WAITING_LIST) {
      dispatch(getMentorID(mentorId));
    }

    setIsAction({ ...isAction, visible: false });
  };

  const onCancelRequestMentoring = () => {
    dispatch(
      rejectMentee(menteeId, () => {
        dispatch(getMentorID(mentorId));
        setIsAction({ ...isAction, visible: false });
      })
    );
  };

  const renderButton = () => {
    // запросить менторинг
    if (availableSlots > 0 && mentoringStatus !== ACTIVE && mentoringStatus !== WAITING_MENTOR) {
      return (
        <Button
          onClick={() => showModal(REQUEST_MENTORING)}
          className={css['Content-right-button-request']}
          type='primary'
          size='large'
        >
          {t('requestMentoring')}
        </Button>
      );
    }

    // встать в лист ожидания
    if (
      availableSlots === 0 &&
      mentoringStatus !== ON_WAITING_LIST &&
      mentoringStatus !== WAITING_MENTOR &&
      mentoringStatus !== ACTIVE
    ) {
      return (
        <Button
          className={css['Content-right-button-request']}
          onClick={() => showModal(WAITING_LIST)}
          type='primary'
          size='large'
        >
          {t('waitingList')}
        </Button>
      );
    }

    // Запрошено
    if (mentoringStatus === WAITING_MENTOR) {
      return (
        <Button className={css['Content-right-button-request']} disabled size='large'>
          {t('retrieved')}
        </Button>
      );
    }

    // В процессе
    if (mentoringStatus === ACTIVE) {
      return (
        <Button className={css['Content-right-button-request']} disabled size='large'>
          {t('inProcess')}
        </Button>
      );
    }

    // в листе ожидания
    if (mentoringStatus === ON_WAITING_LIST && availableSlots === 0) {
      return (
        <div className={css['Content-right-waiting']}>
          <div size='large' className={css['Content-right-waiting-group']}>
            <Button disabled size='large' type='primary'>
              {t('awaitMentor')}
            </Button>
            <Tooltip
              title={t('awaitCancelTooltip')}
              placement='bottom'
              className={css['Content-right-waiting-group-tooltip']}
            >
              <Button
                onClick={() => showModal(CANCEL_REQUEST_MENTORING)}
                icon={<StopOutlined className={css['Content-right-waiting-group-icon']} />}
                type='link'
                size='large'
              ></Button>
            </Tooltip>
          </div>
        </div>
      );
    }
  };

  const [currentAvatar] = useLocalFileByUUID(photoUuid || photoUuidUser, { defaultImg });

  const itemsTabs = [
    {
      key: 'tabMentor',
      label: t('tabMentor'),
      children: <TabAboutMentor mentorId={mentorId} />,
    },
    {
      key: 'tabThank',
      label: t('tabThank', { total: mentorsGratitudeTotal }),
      children: <TabThanksMentor mentorId={mentorId} />,
    },
    {
      key: 'tabComment',
      label: t('tabComment', { total: mentorsCommentsCount }),
      children: <TabCommentsMentor mentorId={mentorId} />,
    },
  ];

  return (
    <Layout>
      <Content>
        {isLoadingMentorsID ? (
          <Spin spinning={isLoadingMentorsID} size='large' className={css['Content-spin']} />
        ) : (
          <div className={css['Content']}>
            <Row type='flex' align='middle' justify='space-between'>
              <Col span={20}>
                <div className={css['Content-header']}>
                  <div className={css['Content-header-title']}>{fullNameMentor}</div>
                  <MentorTag />
                </div>
              </Col>
              <Col>
                <Tooltip title={t('close')}>
                  <ModalCloseIcon onClick={closeMentorID} className={css['Content-header-close']} />
                </Tooltip>
              </Col>
            </Row>
            <Row type='flex' align='middle' justify='space-between' className={css['Content-main']}>
              <Col>
                <Avatar size={120} src={currentAvatar} alt='avatar' />
              </Col>
              <Col className={css['Content-aside']}>
                {mentoringStatus === 'ACTIVE' && (
                  <Button
                    onClick={() => navigate('/chat/' + menteeId)}
                    className={css['Content-right-button-request']}
                    type='primary'
                    size='large'
                  >
                    {t('chat')}
                  </Button>
                )}
                {renderButton()}
                <div>
                  <div className={css['Content-aside-text']}>{t('descSlot')}</div>
                  <div className={css['Content-aside-count']}>
                    {t('totalSlots', {
                      availableSlots: availableSlots,
                      totalSlots: maxSlots,
                    })}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ width: '100%' }} className={css['Content-tabs']}>
                <Tabs defaultActiveKey={'tabMentor'} items={itemsTabs} />
              </Col>
            </Row>
          </div>
        )}

        {isAction.visible && (
          <ModalActions
            open={isAction.visible}
            onClose={onCloseModalActions}
            action={isAction.action}
            mentorId={mentorId}
            onCancelRequestMentoring={onCancelRequestMentoring}
          />
        )}
      </Content>
    </Layout>
  );
};

MentorsID.propTypes = {
  mentorId: PropTypes.number,
};

export default MentorsID;
