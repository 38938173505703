import { Tag } from 'antd';
import {
  CalendarOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  MailOutlined,
  MessageOutlined,
  ProjectOutlined,
  ReadOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  StarOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';

import classNames from 'classnames';

import css from './style.module.scss';

export const useNotificationTypes = ({ type, className, t }) => {
  let CurrentIcon;
  let style = {
    color: '#fff',
    borderColor: '#000',
    backgroundColor: '#000',
  };
  let label = '';
  switch (type) {
    case 'PLAN':
      CurrentIcon = ReadOutlined;
      style = {
        color: '#13C2C2',
        borderColor: '#87E8DE',
        backgroundColor: '#E6FFFB',
      };
      label = t('notificationsType.0');
      break;
    case 'COURSE_EVENT':
      CurrentIcon = CalendarOutlined;
      style = {
        color: '#1677FF',
        borderColor: '#91CAFF',
        backgroundColor: '#E6F4FF',
      };
      label = t('notificationsType.1');
      break;
    case 'FEEDBACK':
      CurrentIcon = MessageOutlined;
      style = {
        color: '#FAAD14',
        borderColor: '#FFE58F',
        backgroundColor: '#FFFBE6',
      };
      label = t('notificationsType.3');
      break;
    case 'MENTORING':
      CurrentIcon = UserSwitchOutlined;
      style = {
        color: '#52C41A',
        borderColor: '#B7EB8F',
        backgroundColor: '#F6FFED',
      };
      label = t('notificationsType.4');
      break;
    case 'DIAGNOSTICS':
      CurrentIcon = ProjectOutlined;
      style = {
        color: '#2F54EB',
        borderColor: '#ADC6FF',
        backgroundColor: '#F0F5FF',
      };
      label = t('notificationsType.2');
      break;

    case 'COURSE_REQUEST':
      CurrentIcon = FileSyncOutlined;
      style = {
        color: '#30B0C7',
        borderColor: '#30B0C7',
        backgroundColor: '#30B0C71A',
      };
      label = t('notificationsType.5');
      break;

    case 'PRACTICAL_TASK':
      CurrentIcon = FileDoneOutlined;
      style = {
        color: '#EB2F96',
        borderColor: '#FFADD2',
        backgroundColor: '#FFF0F6',
      };
      label = t('notificationsType.6');
      break;

    case 'PROFILE_MANAGE':
      CurrentIcon = SolutionOutlined;
      style = {
        color: '#FA8C16',
        borderColor: '#FFD591',
        backgroundColor: '#FFF7E6',
      };
      label = t('notificationsType.7');
      break;

    case 'REWARD_STORE':
      CurrentIcon = ShoppingCartOutlined;
      style = {
        color: '#F5222D',
        borderColor: '#FFA39E',
        backgroundColor: '#FFF1F0',
      };
      label = t('notificationsType.8');
      break;

    case '360_SCORE':
      CurrentIcon = ProjectOutlined;
      style = {
        color: '#722ED1',
        borderColor: '#D3ADF7',
        backgroundColor: '#F9F0FF',
      };
      label = t('notificationsType.13');
      break;

    case 'BADGES':
      CurrentIcon = StarOutlined;
      style = {
        color: '#FA541C',
        borderColor: '#FFBB96',
        backgroundColor: '#FFF2E8',
      };
      label = t('notificationsType.9');
      break;

    case 'CAREER_PLAN':
      CurrentIcon = ReadOutlined;
      style = {
        color: '#CDCD1C',
        borderColor: '#CDCD1C',
        backgroundColor: '#FFFFEA',
      };
      label = t('notificationsType.10');
      break;

    case 'INSPECTORS':
      CurrentIcon = FileDoneOutlined;
      style = {
        color: '#EE9A77',
        borderColor: '#EE9A77',
        backgroundColor: '#FFD2BF',
      };
      label = t('notificationsType.11');
      break;

    default:
      CurrentIcon = MailOutlined;
      style = {
        color: '#1677FF',
        borderColor: '#91CAFF',
        backgroundColor: '#E6F4FF',
      };
      label = t('notificationsType.12');
  }

  return {
    IconNotification: () => <CurrentIcon className={classNames(css['currentIcon'], className)} />,
    TagNotification: () => (
      <Tag className={css['currentTag']} style={{ ...style }}>
        {label}
      </Tag>
    ),
  };
};
