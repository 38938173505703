import unionBy from 'lodash/unionBy';

export const selectTopics = state => state.topics;

export const isLoadingSelectTopics = state => state.topics.isLoading;

export const selectTopicsCategoriesUnion = state => {
  if (!state.topics.categories) return state.topics.categories;

  const shared = state.topics.categories
    .filter(category => category.sharing)
    .map(category => ({ ...category, ids: [category.id] }));

  const local = state.topics.categories
    .filter(category => !category.sharing)
    .map(category => {
      const ids = [category.id];
      const sameName = shared.find(data => data.name === category.name);
      if (sameName) {
        ids.push(sameName.id);
      }
      return { ...category, ids };
    });

  return unionBy([...local, ...shared], 'name');
};
