// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-O5Ak3h.user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-GRBU1d .user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-label-hdK3yt[data-value="Montserrat"]:before, .user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-O5Ak3h.user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-GRBU1d .user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-item-f8Z4wo[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-Montserrat-T22wx_ {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-ZqgVZK {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 92px 24px 24px;
  display: flex;
}

.user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-lYXkep {
  text-align: center;
  color: #222429;
  margin: 50px 0;
}

.user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-title-GkDALu {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
}

.user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-subtitle-KPCPDl {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/PurchaseModal/SubModals/SubModals.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAMA;EAHE,kBAAkB;EAClB,cAAc;EACd,cAAc;AAChB;;AAKA;EAFE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAIA;EADE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.SubModals-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 92px 24px 24px;\n}\n.SubModals-container-text {\n  text-align: center;\n  color: #222429;\n  margin: 50px 0;\n}\n.SubModals-container-text-title {\n  font-size: 20px;\n  line-height: 28px;\n  margin-bottom: 16px;\n  font-weight: 900;\n}\n.SubModals-container-text-subtitle {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-O5Ak3h`,
	"qlPicker": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-O5Ak3h`,
	"ql-font": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-GRBU1d`,
	"qlFont": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-GRBU1d`,
	"ql-picker-label": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-label-hdK3yt`,
	"qlPickerLabel": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-label-hdK3yt`,
	"ql-picker-item": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-item-f8Z4wo`,
	"qlPickerItem": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-picker-item-f8Z4wo`,
	"ql-font-Montserrat": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-Montserrat-T22wx_`,
	"qlFontMontserrat": `user-src-components-PurchaseModal-SubModals-SubModals-module__ql-font-Montserrat-T22wx_`,
	"SubModals-container": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-ZqgVZK`,
	"subModalsContainer": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-ZqgVZK`,
	"SubModals-container-text": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-lYXkep`,
	"subModalsContainerText": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-lYXkep`,
	"SubModals-container-text-title": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-title-GkDALu`,
	"subModalsContainerTextTitle": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-title-GkDALu`,
	"SubModals-container-text-subtitle": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-subtitle-KPCPDl`,
	"subModalsContainerTextSubtitle": `user-src-components-PurchaseModal-SubModals-SubModals-module__SubModals-container-text-subtitle-KPCPDl`
};
export default ___CSS_LOADER_EXPORT___;
