import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Popover } from 'antd';

import { classNames } from '@shared/utils/styles-utils';
import { isConference, isEvent } from '@shared/utils/topic-utils';

import Resources from '@/resources';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const Schedule = ({ schedule, onClose, user }) => {
  const { periods = [], topicTypeId, comment } = schedule;
  const { t } = useTranslation('schedule');
  const [loaded, setLoaded] = useState(false);

  const noDate = false; // todo ?

  const getPlace = (place, eventLink) =>
    place ? (
      eventLink ? (
        <a className={cn('schedule__link')} target='_blank' rel='noopener noreferrer' href={eventLink}>
          {t('eventLink')}
        </a>
      ) : (
        <span>{place}</span>
      )
    ) : (
      t('notIndicated')
    );

  const getScheduleTitle = topicTypeId => {
    return t('schedule', {
      type: isConference(topicTypeId) ? t('conference') : isEvent(topicTypeId) ? t('event') : t('flow'),
    });
  };

  return (
    <div className={cn('schedule')}>
      {/* eslint-disable-next-line */}
      <a className={cn('schedule__close')} onClick={onClose}>
        {/* eslint-disable-next-line */}
        <Resources.icons.CloseIcon />
      </a>
      <div className={cn('schedule__title')}>{getScheduleTitle(topicTypeId)}</div>
      <div
        className={cn('schedule__row', 'schedule__row_header', {
          'schedule__row_no-date': noDate,
        })}
      >
        <span>{t('date')}</span>
        <span>{t('time')}</span>
        <span>{t('place')}</span>
      </div>
      {periods.map(period => {
        const { id, date, startTime, endTime, place, eventLink } = period;

        let fullStartDate =
          user &&
          dayjs(`${date}T${startTime}`).add(user.timezone.offset / 60 - user.domainCompany.timezone.offset / 60, 'h');

        let fullStartEndTime =
          user &&
          dayjs(`${date}T${endTime}`).add(user.timezone.offset / 60 - user.domainCompany.timezone.offset / 60, 'h');

        user && !loaded && setLoaded(true);

        return (
          <div key={id} className={cn('schedule__row', { 'schedule__row_no-date': noDate })}>
            <span>{dayjs(fullStartDate).format('DD.MM.YYYY')}</span>
            <span>{`${dayjs(fullStartDate).format('HH:mm')} - ${dayjs(fullStartEndTime).format('HH:mm')}`}</span>
            <span>{getPlace(place, eventLink)}</span>
          </div>
        );
      })}
      {comment && <div className={cn('schedule__row', 'schedule__row_comment')}>{comment}</div>}
    </div>
  );
};

export const SchedulePopover = ({ buttonText, schedule, user }) => {
  const { t } = useTranslation('planingFinished');
  const [visible, setVisible] = useState(false);

  const onClose = () => setVisible(false);

  return (
    <Popover
      content={<Schedule onClose={onClose} schedule={schedule} user={user} />}
      trigger='click'
      open={visible}
      onOpenChange={setVisible}
      placement='bottomLeft'
      overlayClassName={cn('schedule__overlay')}
    >
      <Button type='link' className={cn('schedule__btn')} size='small'>
        {buttonText || t('showSchedule')}
      </Button>
    </Popover>
  );
};

SchedulePopover.propTypes = {
  buttonText: PropTypes.string,
  shedule: PropTypes.element,
  user: PropTypes.object,
};
