import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import { useSelector } from 'react-redux';

import { selectUsers } from '@/store/users/selectors';

import { Avatar, Button, Input, Spin } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';

import Comment from './parts/Comment';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import styles from './Comments.module.scss';

const { TextArea } = Input;

const Comments = memo(({ actions, placeholder, commentsList, isLoading, totalElements }) => {
  const [textAreaValue, setTextAreaValue] = useState(null);

  const { t } = useTranslation('commentsComponent');

  const { currentUser } = useSelector(selectUsers);

  const [currentAvatar] = useLocalFileByUUID(currentUser.localPhotoUuid, { defaultImg });

  useEffect(() => {
    actions.onRead();
  }, []);

  const sendComment = () => {
    if (textAreaValue) {
      actions.onCreate(textAreaValue.trim());
      setTextAreaValue(null);
    }
  };

  return (
    <div className={styles.comments}>
      <div className={styles.comments__create}>
        <div className={styles.comments__comment}>
          <Avatar src={currentAvatar} size={40} />
          <TextArea
            className={styles.comments__textarea}
            value={textAreaValue}
            onChange={event => setTextAreaValue(event.target.value)}
            placeholder={placeholder}
            maxLength={1500}
          />
        </div>
        <Button
          disabled={!textAreaValue?.trim()}
          onClick={sendComment}
          className={styles.comments__button}
          type='primary'
          size='large'
        >
          {t('send')}
        </Button>
      </div>
      <InfiniteScroll
        className={styles.comments__list}
        initialLoad={false}
        loadMore={actions.onRead}
        hasMore={!isLoading && totalElements > commentsList.length}
      >
        {commentsList.map((data, index) => (
          <Comment data={data} actions={actions} key={index} currentUser={currentUser} />
        ))}
        {isLoading && <Spin />}
      </InfiniteScroll>
    </div>
  );
});

Comments.propTypes = {
  actions: PropTypes.shape({
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRead: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
  }),
  commentsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalElements: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  canCreate: PropTypes.bool,
};

Comments.defaultProps = {
  canCreate: true,
};

export default Comments;
