import {
  defaultToken,
  GET_CERTIFICATE_FAIL,
  GET_CERTIFICATE_START,
  GET_CERTIFICATE_SUCCESS,
  headers,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

export const getCertificate = (id, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/certificate/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_CERTIFICATE_START, GET_CERTIFICATE_SUCCESS, GET_CERTIFICATE_FAIL],
  onSuccess,
  onError,
});
