// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-elements-button-plan-styles-module__ql-picker-cJf_VI.user-src-components-elements-button-plan-styles-module__ql-font-Tas6Ve .user-src-components-elements-button-plan-styles-module__ql-picker-label-ertMZ2[data-value="Montserrat"]:before, .user-src-components-elements-button-plan-styles-module__ql-picker-cJf_VI.user-src-components-elements-button-plan-styles-module__ql-font-Tas6Ve .user-src-components-elements-button-plan-styles-module__ql-picker-item-s3C8s4[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-elements-button-plan-styles-module__ql-font-Montserrat-dqvAKN {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-elements-button-plan-styles-module__button-plan-xQAKbD {
  margin-left: 8px;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-components-elements-button-plan-styles-module__button-plan-xQAKbD {
    margin-bottom: 16px;
  }
}

@media (max-width: 1025px) {
  .user-src-components-elements-button-plan-styles-module__button-plan-xQAKbD {
    margin-left: 1%;
  }
}

.user-src-components-elements-button-plan-styles-module__button-plan__favorite-qmNQSe {
  transition: none;
}

.user-src-components-elements-button-plan-styles-module__button-plan__favorite_only-fYUbm6 {
  text-align: center;
  width: 40px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/elements/button-plan/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EACE;IAHE,mBAAmB;EACrB;AACF;;AAKA;EACE;IAFE,eAAe;EACjB;AACF;;AAIA;EADE,gBAAgB;AAClB;;AAGA;EAAE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.button-plan {\n  margin-left: 8px;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .button-plan {\n    margin-bottom: 16px;\n  }\n}\n@media (width <= 1025px) {\n  .button-plan {\n    margin-left: 1%;\n  }\n}\n.button-plan__favorite {\n  transition: none;\n}\n.button-plan__favorite_only {\n  width: 40px;\n  text-align: center;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-elements-button-plan-styles-module__ql-picker-cJf_VI`,
	"qlPicker": `user-src-components-elements-button-plan-styles-module__ql-picker-cJf_VI`,
	"ql-font": `user-src-components-elements-button-plan-styles-module__ql-font-Tas6Ve`,
	"qlFont": `user-src-components-elements-button-plan-styles-module__ql-font-Tas6Ve`,
	"ql-picker-label": `user-src-components-elements-button-plan-styles-module__ql-picker-label-ertMZ2`,
	"qlPickerLabel": `user-src-components-elements-button-plan-styles-module__ql-picker-label-ertMZ2`,
	"ql-picker-item": `user-src-components-elements-button-plan-styles-module__ql-picker-item-s3C8s4`,
	"qlPickerItem": `user-src-components-elements-button-plan-styles-module__ql-picker-item-s3C8s4`,
	"ql-font-Montserrat": `user-src-components-elements-button-plan-styles-module__ql-font-Montserrat-dqvAKN`,
	"qlFontMontserrat": `user-src-components-elements-button-plan-styles-module__ql-font-Montserrat-dqvAKN`,
	"button-plan": `user-src-components-elements-button-plan-styles-module__button-plan-xQAKbD`,
	"buttonPlan": `user-src-components-elements-button-plan-styles-module__button-plan-xQAKbD`,
	"button-plan__favorite": `user-src-components-elements-button-plan-styles-module__button-plan__favorite-qmNQSe`,
	"buttonPlanFavorite": `user-src-components-elements-button-plan-styles-module__button-plan__favorite-qmNQSe`,
	"button-plan__favorite_only": `user-src-components-elements-button-plan-styles-module__button-plan__favorite_only-fYUbm6`,
	"buttonPlanFavoriteOnly": `user-src-components-elements-button-plan-styles-module__button-plan__favorite_only-fYUbm6`
};
export default ___CSS_LOADER_EXPORT___;
