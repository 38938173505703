import React from 'react';

import dayjs from 'dayjs';

function DayPicker({ renderDays, choosedDate }) {
  const daysInMonth = dayjs(choosedDate).daysInMonth();
  const choosedDay = dayjs(choosedDate).format('D');

  const displayDays = () => {
    const content = [];
    for (let i = 1; i <= daysInMonth; i++) {
      content.push(renderDays(i, choosedDay));
    }
    return content;
  };

  return <>{displayDays()}</>;
}

export default DayPicker;
