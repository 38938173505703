// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-CsmH8b.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-cs0NY4 .user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-label-OTFdLQ[data-value="Montserrat"]:before, .user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-CsmH8b.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-cs0NY4 .user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-item-_bLzFa[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-Montserrat-9UxXEO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreen-AvGs5Q {
  width: 100%;
  height: 100%;
  padding: 0;
}

.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreenInput-Wcm3Uy {
  resize: none;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: .1rem;
  width: 100%;
  padding: 17px;
  font-size: 13px;
  overflow-y: auto;
}

.user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreenInput-Wcm3Uy:hover, .user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreenInput-Wcm3Uy:focus {
  box-shadow: none;
  border-color: #dadada;
  outline: 0;
}

textarea {
  font-family: inherit;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ExecutiveFinishedScreen/ExecutiveFinishedScreen.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAQA;EALE,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,oBAAoB;EACpB,WAAW;EACX,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAOA;EAJE,gBAAgB;EAChB,qBAAqB;EACrB,UAAU;AACZ;;AAOA;EAJE,oBAAoB;AACtB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.ExecutiveFinishedScreen {\n  padding: 0; /* 48px before */\n  height: 100%;\n  width: 100%;\n}\n\n.ExecutiveFinishedScreenInput {\n  background-color: #f2f2f2;\n  border: 1px solid #f2f2f2;\n  border-radius: 0.1rem;\n  padding: 17px 17px;\n  width: 100%;\n  font-size: 13px;\n  overflow-y: auto;\n  resize: none;\n}\n.ExecutiveFinishedScreenInput:hover, .ExecutiveFinishedScreenInput:focus {\n  border-color: #dadada;\n  outline: 0;\n  box-shadow: none;\n}\n\n:global textarea {\n  font-family: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-CsmH8b`,
	"qlPicker": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-CsmH8b`,
	"ql-font": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-cs0NY4`,
	"qlFont": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-cs0NY4`,
	"ql-picker-label": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-label-OTFdLQ`,
	"qlPickerLabel": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-label-OTFdLQ`,
	"ql-picker-item": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-item-_bLzFa`,
	"qlPickerItem": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-picker-item-_bLzFa`,
	"ql-font-Montserrat": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-Montserrat-9UxXEO`,
	"qlFontMontserrat": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ql-font-Montserrat-9UxXEO`,
	"ExecutiveFinishedScreen": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreen-AvGs5Q`,
	"executiveFinishedScreen": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreen-AvGs5Q`,
	"ExecutiveFinishedScreenInput": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreenInput-Wcm3Uy`,
	"executiveFinishedScreenInput": `user-src-components-ExecutiveFinishedScreen-ExecutiveFinishedScreen-module__ExecutiveFinishedScreenInput-Wcm3Uy`
};
export default ___CSS_LOADER_EXPORT___;
