/* eslint-disable */
import React, { useLayoutEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';

import { selectCompany } from '@/store/company/selectors';

import { Button, Layout, Spin } from 'antd';
import LogoCompany from '@/components/LogoCompany/LogoCompany';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

import logoDefault from '@/assets/images/logo_default.png';

const { Content } = Layout;

const ConfirmMessage = props => {
  const { eventRequest, message } = useOutletContext();
  const navigate = useNavigate();
  const { company } = useSelector(selectCompany);

  const [imageLogo] = useLocalFileByUUID(company.logo, {
    publish: true,
    defaultImg: logoDefault,
  });

  const [imageBackgroundLogin] = useLocalFileByUUID(company.background, {
    publish: true,
    isDefaultNull: true,
  });

  useLayoutEffect(() => {
    if (!eventRequest) {
      navigate('/');
    }
  }, []);

  return (
    <Layout className={css.ConfirmMessage}>
      <Content className={css['ConfirmMessage-content']}>
        <Spin size='large' spinning={!eventRequest}>
          <div className={css['App-card-item']}>
            <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
            <div className={css['ConfirmMessage-header']}>
              <div className={css['ConfirmMessage-header__logo']}>
                <LogoCompany imageLogo={imageLogo} />
              </div>
              <div className={css['ConfirmMessage-header-title']}>{message?.title}</div>
            </div>
            <div className={css['ConfirmMessage-body']}>
              <div className={css['ConfirmMessage-body-text']}>{message?.subtitle}</div>
              <Button size='large' type='primary' onClick={() => navigate('/')} className={css.Button}>
                {props.t('goTo')}
              </Button>
            </div>
          </div>
        </Spin>
      </Content>
    </Layout>
  );
};

ConfirmMessage.propTypes = {
  eventRequest: PropTypes.string,
  imageBackgroundLogin: PropTypes.any,
  imageLogo: PropTypes.any,
  message: PropTypes.object,
};

export default withTranslation('compilation')(ConfirmMessage);
