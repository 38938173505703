// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-MyProfile-News-News-module__ql-picker-TWG5Tv.user-src-pages-MyProfile-News-News-module__ql-font-fXAgN6 .user-src-pages-MyProfile-News-News-module__ql-picker-label-lMj3Nh[data-value="Montserrat"]:before, .user-src-pages-MyProfile-News-News-module__ql-picker-TWG5Tv.user-src-pages-MyProfile-News-News-module__ql-font-fXAgN6 .user-src-pages-MyProfile-News-News-module__ql-picker-item-UNwyqR[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-MyProfile-News-News-module__ql-font-Montserrat-sp3Lgv {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-MyProfile-News-News-module__user-g2VfVr {
  align-items: center;
  gap: 100px;
  height: 80px;
  display: flex;
}

.user-src-pages-MyProfile-News-News-module__user-g2VfVr:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.user-src-pages-MyProfile-News-News-module__user__data-MWMK1R {
  align-items: center;
  gap: 10px;
  display: flex;
}

.user-src-pages-MyProfile-News-News-module__user__name-q8wKY8, .user-src-pages-MyProfile-News-News-module__user__newsName-ukWkDe {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  font-size: 14px;
  overflow: hidden;
}

.user-src-pages-MyProfile-News-News-module__newsElement-zyoFLn {
  display: block;
}

.user-src-pages-MyProfile-News-News-module__newsElement_visible-mEwMW9 {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/MyProfile/News/News.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAMA;EAHE,gCAAgC;AAClC;;AAKA;EAFE,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAIA;EADE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAWA;EARE,cAAc;AAChB;;AAUA;EAPE,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.user {\n  display: flex;\n  gap: 100px;\n  align-items: center;\n  height: 80px;\n}\n.user:not(:last-child) {\n  border-bottom: 1px solid #ebebeb;\n}\n.user__data {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n.user__name {\n  width: 200px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-size: 14px;\n}\n.user__newsName {\n  width: 200px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-size: 14px;\n}\n\n.newsElement {\n  display: block;\n}\n.newsElement_visible {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-MyProfile-News-News-module__ql-picker-TWG5Tv`,
	"qlPicker": `user-src-pages-MyProfile-News-News-module__ql-picker-TWG5Tv`,
	"ql-font": `user-src-pages-MyProfile-News-News-module__ql-font-fXAgN6`,
	"qlFont": `user-src-pages-MyProfile-News-News-module__ql-font-fXAgN6`,
	"ql-picker-label": `user-src-pages-MyProfile-News-News-module__ql-picker-label-lMj3Nh`,
	"qlPickerLabel": `user-src-pages-MyProfile-News-News-module__ql-picker-label-lMj3Nh`,
	"ql-picker-item": `user-src-pages-MyProfile-News-News-module__ql-picker-item-UNwyqR`,
	"qlPickerItem": `user-src-pages-MyProfile-News-News-module__ql-picker-item-UNwyqR`,
	"ql-font-Montserrat": `user-src-pages-MyProfile-News-News-module__ql-font-Montserrat-sp3Lgv`,
	"qlFontMontserrat": `user-src-pages-MyProfile-News-News-module__ql-font-Montserrat-sp3Lgv`,
	"user": `user-src-pages-MyProfile-News-News-module__user-g2VfVr`,
	"user__data": `user-src-pages-MyProfile-News-News-module__user__data-MWMK1R`,
	"userData": `user-src-pages-MyProfile-News-News-module__user__data-MWMK1R`,
	"user__name": `user-src-pages-MyProfile-News-News-module__user__name-q8wKY8`,
	"userName": `user-src-pages-MyProfile-News-News-module__user__name-q8wKY8`,
	"user__newsName": `user-src-pages-MyProfile-News-News-module__user__newsName-ukWkDe`,
	"userNewsName": `user-src-pages-MyProfile-News-News-module__user__newsName-ukWkDe`,
	"newsElement": `user-src-pages-MyProfile-News-News-module__newsElement-zyoFLn`,
	"newsElement_visible": `user-src-pages-MyProfile-News-News-module__newsElement_visible-mEwMW9`,
	"newsElementVisible": `user-src-pages-MyProfile-News-News-module__newsElement_visible-mEwMW9`
};
export default ___CSS_LOADER_EXPORT___;
