/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getCompetencies } from '@/store/user_competencies/actions';
import { getSkills } from '@/store/user_skills/actions';

import { competencies } from '@/store/user_competencies/selectors';
import { selectCustomField } from '@/store/user_customfield/selectors';
import { skills } from '@/store/user_skills/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Col, Layout, Row, Skeleton } from 'antd';
import TagCustom from '@/components/Tag';

import css from './info.module.scss';

const { Content } = Layout;

export const ProfileInfo = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();

  const customField = useSelector(selectCustomField);
  const compSelect = useSelector(competencies);
  const usersSelect = useSelector(selectUsers);
  const colleguesSkills = useSelector(skills);

  const location = useLocation();
  const isCollegues = location.pathname.includes('/collegues');

  const user = isCollegues ? usersSelect?.anotherUser : usersSelect?.currentUser;

  const userId = useMemo(() => {
    return user?.managers?.find(i => i.id)?.id;
  }, [user]);

  useEffect(() => {
    if (isCollegues && user?.id) {
      dispatch(getSkills(user.id));
      dispatch(getCompetencies(user.id));
    }
  }, [isCollegues, user?.id]);

  const defaultContent = (
    <Col span={17}>
      <div className={css['app-profline-info__title']}>{t('about')}</div>
      <div className={css['app-profline-info__text--grey']}>{t('learn')}</div>
    </Col>
  );

  const contentTitle = <div className={css['app-profline-info__title']}>{t('about')}</div>;

  const competenciesTag =
    Array.isArray(compSelect?.competencies) &&
    compSelect.competencies.map(item => <TagCustom skills key={item.id} tag={item} sliceCount={32} />);

  const skillsTag =
    Array.isArray(usersSelect?.skills) &&
    usersSelect.skills.map(item => <TagCustom skills key={item.id} tag={item} sliceCount={32} />);

  const skillsColleaguesTag =
    Array.isArray(colleguesSkills?.skills) &&
    colleguesSkills.skills.map(item => <TagCustom skills key={item.id} tag={item} sliceCount={32} />);

  const competenciesColleguesTag =
    Array.isArray(compSelect?.competencies) &&
    compSelect.competencies.map(item => <TagCustom skills key={item.id} tag={item} sliceCount={32} />);

  const customFieldsText =
    Array.isArray(customField?.customField) &&
    customField.customField.map(item => {
      if (item.customFieldValue && !item.isDeleted) {
        switch (item.customFieldValue) {
          case 'true':
            return (
              <p key={item.customFieldId}>
                {item.customFieldName}: {t('yes')}
              </p>
            );
          case 'false':
            return (
              <p key={item.customFieldId}>
                {item.customFieldName}: {t('no')}
              </p>
            );
          default:
            return (
              <p key={item.customFieldId}>
                {item.customFieldName}: {item.customFieldValue}
              </p>
            );
        }
      }
    });

  const cardSkills = (
    <Row className={css['App-cards']}>
      <Col span={18} id='mySkillsBlock'>
        <h3 className={css['app-profline-info__title']}>{isCollegues ? t('skillsColleagues') : t('skills')}</h3>
        <Skeleton loading={usersSelect.isLoadingSkills || colleguesSkills.isLoading}>
          {isCollegues ? skillsColleaguesTag : skillsTag}
        </Skeleton>
      </Col>
    </Row>
  );
  const cardCompetencies = (
    <Row className={css['App-cards']}>
      <Col span={18} id='myCompetenciesBlock'>
        <h3 className={css['app-profline-info__title']}>
          {isCollegues ? t('competenciesColleagues') : t('competencies')}
        </h3>
        <Skeleton loading={compSelect.isLoading}>{isCollegues ? competenciesColleguesTag : competenciesTag}</Skeleton>
      </Col>
    </Row>
  );

  const cardCustomFields = (
    <Row>
      <Col span={18}>
        <div className={css['app-profline-info__text']}>{customFieldsText}</div>
      </Col>
    </Row>
  );

  const manager = user?.manager && (
    <Row>
      <Col>
        <div className={css['app-profline-info__text']}>
          <h3 className={css['app-profline-info__title']}>{t('userManager')}</h3>
          <Link to={`/collegues/${userId}`}>{user.manager}</Link>
        </div>
      </Col>
    </Row>
  );

  return (
    <Content className={css['app-profline-info']}>
      <div>
        {/*О себе*/}
        <Row className={css['App-cards']}>
          {user?.about ? (
            <div id='aboutMeBlock'>
              {contentTitle}
              <Skeleton loading={usersSelect.isLoading}>
                <div className={css['app-profline-info__text']}>{user?.about}</div>
              </Skeleton>
            </div>
          ) : (
            defaultContent
          )}
          {/* кастомные поля */}
        </Row>
        {/* руководитель */}
        {manager}
        <Row>
          <Skeleton loading={customField.loading}>{cardCustomFields}</Skeleton>
        </Row>
        {/* компетенции */}
        {cardCompetencies}
        {/* навыки */}
        {cardSkills}
      </div>
    </Content>
  );
};

export default ProfileInfo;
