import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getUserBadge } from '@/store/badge/actions';

import { selectBadge } from '@/store/badge/selectors';

import { Modal } from 'antd';
import BadgeElement from '@/components/BadgeElement';

import PropTypes from 'prop-types';

import style from './style.module.scss';

const BadgeModal = ({ open, onCancel, withOutAction = false, data }) => {
  const { t } = useTranslation('badgeModal');
  const { badges } = useSelector(selectBadge);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!withOutAction) dispatch(getUserBadge());
  }, []);

  const renderBadges = () => {
    if (data?.length) {
      return data?.map((elem, index) => <BadgeElement data={elem} key={index} />);
    } else if (badges?.length) {
      return badges?.map((elem, index) => <BadgeElement data={elem} key={index} />);
    } else {
      return <div className={style['badge-modal__no-data']}>{t('noBadge')}</div>;
    }
  };

  return (
    <Modal footer={null} width={800} title={t('title')} open={open} onCancel={onCancel}>
      <div className={style['badge-modal']}>{renderBadges()}</div>
    </Modal>
  );
};

BadgeModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  withOutAction: PropTypes.bool,
  data: PropTypes.array,
};

export default BadgeModal;
