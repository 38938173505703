// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-i3bjyb.user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-lB28zp .user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-label-ToUyI3[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-i3bjyb.user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-lB28zp .user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-item-bvHxbB[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-Montserrat-PH0aDO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__Modal-lUIOCJ {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px;
  font-size: 18px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/test/ModalWarningForTest/ModalWarningForTest.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,aAAa;EACb,eAAe;EACf,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Modal {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n  justify-content: center;\n  font-size: 18px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-i3bjyb`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-i3bjyb`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-lB28zp`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-lB28zp`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-label-ToUyI3`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-label-ToUyI3`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-item-bvHxbB`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-picker-item-bvHxbB`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-Montserrat-PH0aDO`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__ql-font-Montserrat-PH0aDO`,
	"Modal": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__Modal-lUIOCJ`,
	"modal": `user-src-pages-CollectionMaterialsIDStudy-test-ModalWarningForTest-ModalWarningForTest-module__Modal-lUIOCJ`
};
export default ___CSS_LOADER_EXPORT___;
