/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import { setTildaBlockFinish, setTildaBlockStart } from '@/store/lms/actions';
import { getTilda } from '@/store/tilda/actions';

import { selectLMS } from '@/store/lms/selectors';

import { Button, Col, Row, Tooltip } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './index.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

export const Tilda = props => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();

  const { statistic } = useSelector(selectLMS);

  const [homePage, setHomePage] = useState(null);
  const [tilda, setTilda] = useState(null);
  const [domain, setDomain] = useState(null);
  const [isMobile] = useIsMobile(768);
  const [isFullscreenMobile, setIsFullscreenMobile] = useState(false);

  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  useEffect(() => {
    props.getTilda(props.block.tildaId, success => {
      setTilda(success);
    });

    if (props?.currentUser?.domainCompany?.domain) {
      setDomain(props.currentUser.domainCompany.domain);
    }

    if (props?.block && props.block.isAvailableForStart) {
      dispatch(setTildaBlockStart(props.block.blockId, () => props.getStatistic(true)));
    }
  }, []);

  useEffect(() => {
    if (tilda && tilda.pages && typeof tilda.pages === 'object') {
      Object.keys(tilda.pages).forEach(k => {
        setHomePage(k);
      });
    }
  }, [tilda]);

  const onFinish = () => {
    dispatch(setTildaBlockFinish(props.block.blockId, () => props.getStatistic(next <= 1)));
  };

  const openFullscreen = () => {
    const iframe = document.getElementById('scormIframe');

    if (!isMobile) {
      const requestMethod = iframe.requestFullscreen;
      requestMethod && requestMethod.call(iframe);
    } else {
      setIsFullscreenMobile(!isFullscreenMobile);
    }
  };

  useEffect(() => {
    !isMobile && isFullscreenMobile && setIsFullscreenMobile(false);
  }, [isMobile]);

  const render = () => {
    return (
      <>
        <Row>
          <Col span={24} className={css.tildaWrapper}>
            <Tooltip title={!isMobile ? t('fullscreen') : ''}>
              <Button
                className={classNames(
                  css.tildaFullscreenButton,
                  isMobile && isFullscreenMobile && css.tildaFullscreenButton_active
                )}
                icon={isMobile && isFullscreenMobile ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                onClick={openFullscreen}
                shape='circle'
                size='large'
                type='primary'
              />
            </Tooltip>
            <iframe
              allow=''
              className={classNames(css.tildaFrame, isMobile && isFullscreenMobile && css.tildaFrame_FullscreenMobile)}
              id='scormIframe'
              src={`https://${domain}/data/tilda/${homePage}`}
              referrerPolicy='no-referrer'
              height='100%'
              width='100%'
              scrolling='yes'
              allowFullScreen
            />
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24} align='center'>
            <div className={css.tildaButtons}>
              {props.block.status !== 'FINISHED' && (
                <Button onClick={onFinish} type='primary' size='large' style={{ marginRight: 20 }}>
                  {next > 1 ? t('next') : t('end')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return render();
};

Tilda.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = {};

const mapActionsToProps = {
  getTilda,
};

export default connect(null, mapActionsToProps)(Tilda);
