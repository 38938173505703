import { memo } from 'react';
import { Link } from 'react-router-dom';

import useImage from '@shared/hooks/useImage';

import PropTypes from 'prop-types';

import styles from './NewsElement.module.scss';

import Article from '@/assets/icons/article.svg';

const NewsElement = ({ data }) => {
  const image = useImage(data?.photoUuid);

  return (
    <Link to={'/news/' + data.id} className={styles.news}>
      {image ? (
        <img src={image} className={styles.news__image} alt='' />
      ) : (
        <div className={styles.news__default}>
          <img className={styles.news__default_image} src={Article} alt='' />
        </div>
      )}
      <h4 className={styles.news__caption}>{data.title}</h4>
    </Link>
  );
};

NewsElement.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    photoUuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default memo(NewsElement);
