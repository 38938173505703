/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getDepartments } from '@/store/department/actions';

import { selectDepartments } from '@/store/department/selectors';

import { Col, Row, Table, Tooltip } from 'antd';
import { HiOutlineBadgeCheck, HiOutlineClipboardCheck, HiOutlineClock } from 'react-icons/hi';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css2 from '../index.module.scss';
import css from './Views.module.scss';

export const ViewDepartment = props => {
  const [filterDepartment, setFilterDepartment] = useState([]);

  useEffect(() => {
    props.getDepartments({ hasEmployees: true });
  }, []);

  useEffect(() => {
    if (props.departments && Array.isArray(props.departments)) {
      setFilterDepartment(
        props.departments.map(item => {
          const resultDepartments = {};
          resultDepartments.text = item.name;
          resultDepartments.value = item.name;
          return resultDepartments;
        })
      );
    }
  }, [props.departments]);

  const getColumns = () => {
    const { t, hasDepartment, hasCoins, txtDepartment } = props;

    let columns = [];

    if (hasDepartment === true) {
      columns.push({
        title: txtDepartment,
        dataIndex: 'department',
        key: 'department',
        filters: filterDepartment,
        filterMultiple: false,
      });
    }

    columns.push({
      title: t('users'),
      dataIndex: 'employees',
      key: 'employees',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: true,
    });

    if (hasCoins) {
      columns.push({
        title: t('score'),
        dataIndex: 'totalCoins',
        key: 'totalCoins',
        align: 'center',
        defaultSortOrder: 'descend',
        sorter: true,
      });
    }

    columns.push({
      title: t('statistics'),
      dataIndex: 'statistics',
      key: 'statistics',
      width: 180,
    });

    return columns;
  };

  const getData = () => {
    const { t, departments } = props;
    if (departments) {
      return departments.map(item => {
        if (item.name === null) {
          item.name = t('noData');
        }

        return {
          department: item.name,
          totalCoins: item.coins,
          employees: item.numberOfEmployees,
          statistics: (
            <div className={css2['userStatistics']}>
              <Tooltip placement='top' title={t('finished')}>
                <div className={css2['iconGreen']}>
                  <HiOutlineBadgeCheck />
                  &nbsp;{item.finished}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('planned')}>
                <div className={css2['iconBlue']}>
                  <HiOutlineClock />
                  &nbsp;{item.planned}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('created')}>
                <div className={css2['iconRed']}>
                  <HiOutlineClipboardCheck />
                  &nbsp;{item.created}
                </div>
              </Tooltip>
            </div>
          ),
          key: item.id,
        };
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    props.getDepartments({
      hasEmployees: true,
      page: pagination.current - 1,
      name: filters.hasOwnProperty('department') ? filters.department?.[0] : '',
      sort: sorter.hasOwnProperty('order') ? `${sorter.columnKey},${sorter.order == 'ascend' ? 'desc' : 'asc'}` : '',
    });
  };

  const pagination =
    props.departmentsTotal > props.departmentsPageSize
      ? {
          current: props.departmentsPageNumber + 1,
          total: props.departmentsTotal,
          pageSize: props.departmentsPageSize,
          showSizeChanger: false,
          responsive: true,
          hideOnSinglePage: true,
        }
      : false;

  const render = () => {
    return (
      <Row className={css['Views-tableWrapper']}>
        <Col span={24} style={{ padding: '1rem' }}>
          <Table
            // className={css['Views-table']}
            columns={getColumns()}
            dataSource={getData()}
            loading={props.isLoading}
            bordered={true}
            pagination={pagination}
            onChange={onChangeTable}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    );
  };
  return render();
};

ViewDepartment.propTypes = {
  t: PropTypes.func,
  hasDepartment: PropTypes.bool,
  isLoading: PropTypes.bool,
  getDepartments: PropTypes.func,
  departments: PropTypes.array,
  txtDepartment: PropTypes.string,
};

const mapStateToProps = createSelector([selectDepartments], selectDepartments => ({
  departments: selectDepartments.departments,
  departmentsTotal: selectDepartments.departmentsTotal,
  departmentsPageSize: selectDepartments.departmentsPageSize,
  departmentsPageNumber: selectDepartments.departmentsPageNumber,
}));
const mapActionsToProps = {
  getDepartments,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('colleague')(ViewDepartment));
