/* eslint-disable */
// @ts-nocheck
import { PixelCrop } from 'react-image-crop';

export async function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  canvas.width = (crop.width / (crop.height / 120)) * 2;
  canvas.height = 120 * 2;

  ctx.save();

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    (Math.trunc(crop.width / (crop.height / 120)) + 0.5) * 2,
    (120 + 0.5) * 2
  );
  ctx.restore();
}
