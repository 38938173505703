import {
  GET_ASSESSMENT_URL_FAIL,
  GET_ASSESSMENT_URL_START,
  GET_ASSESSMENT_URL_SUCCESS,
  GET_COMPETNCES_FAIL,
  GET_COMPETNCES_START,
  GET_COMPETNCES_SUCCESS,
  GET_RECOMMENDATION_FAIL,
  GET_RECOMMENDATION_START,
  GET_RECOMMENDATION_SUCCESS,
  GET_REPORT_PDF_FAIL,
  GET_REPORT_PDF_START,
  GET_REPORT_PDF_SUCCESS,
  GET_SCORE_FAIL,
  GET_SCORE_START,
  GET_SCORE_SUCCESS,
} from './constants';

const initialState = {
  assessmentUrl: {},
  scores: [],
  student: {},
  loading: false,
  recommendation: [],
  competences: [],
  isLoadingRec: false,
  isLoadingScore: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_ASSESSMENT_URL_START:
    case GET_COMPETNCES_START:
      return { ...state, loading: true };

    case GET_SCORE_START:
      return { ...state, isLoadingScore: true };

    case GET_RECOMMENDATION_START:
      return { ...state, isLoadingRec: true };

    case GET_ASSESSMENT_URL_FAIL:
    case GET_COMPETNCES_FAIL:
      return { ...state, loading: false };

    case GET_SCORE_FAIL:
      return { ...state, isLoadingScore: false };

    case GET_RECOMMENDATION_FAIL:
      return { ...state, isLoadingRec: false };

    case GET_ASSESSMENT_URL_SUCCESS:
      return { ...state, loading: false, assessmentUrl: response };

    case GET_SCORE_SUCCESS:
      return {
        ...state,
        isLoadingScore: false,
        scores: response['scores:'],
        student: response['student:'],
      };

    case GET_RECOMMENDATION_SUCCESS:
      return { ...state, isLoadingRec: false, recommendation: response };

    case GET_COMPETNCES_SUCCESS:
      return { ...state, loading: false, competences: response };

    case GET_REPORT_PDF_SUCCESS:
    case GET_REPORT_PDF_START:
    case GET_REPORT_PDF_FAIL:
    default:
      return state;
  }
};
