/* eslint-disable */
import {
  GET_TRACK_BY_ID_FAIL,
  GET_TRACK_BY_ID_START,
  GET_TRACK_BY_ID_SUCCESS,
  GET_TRACKS_FAIL,
  GET_TRACKS_START,
  GET_TRACKS_STATUS_FAIL,
  GET_TRACKS_STATUS_START,
  GET_TRACKS_STATUS_SUCCESS,
  GET_TRACKS_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  tracks: [],
  track: {},
  currentPage: 0,
  pageSize: 20,
  totalElements: 0,
  totalPages: 0,
  statuses: [],
};

export default (state = initialState, { type, response, value, headers }) => {
  switch (type) {
    case GET_TRACKS_STATUS_START:
    case GET_TRACK_BY_ID_START:
    case GET_TRACKS_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_TRACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        tracks: response?.content,
        currentPage: response.number,
        pageSize: response.size,
        totalElements: response.totalElements,
        totalPages: response.totalPages,
      };

    case GET_TRACK_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        track: response,
      };

    case GET_TRACKS_STATUS_SUCCESS:
      return {
        ...state,
        statuses: response,
      };

    case GET_TRACKS_STATUS_FAIL:
    case GET_TRACK_BY_ID_FAIL:
    case GET_TRACKS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return { ...state };
  }
};
