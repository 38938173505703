/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import css from './HorizontalScroll.module.scss';

export default class HorizontalScroll extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLeftArrowDisabled: true,
      isRightArrowDisabled: false,
    };
  }

  setContentRef = content => (this.content = content);

  scroll = step => {
    if (this.content) {
      const scrollLeft = this.content.scrollLeft + step;
      this.content.scrollLeft = scrollLeft;
      this.setState({
        isLeftArrowDisabled: scrollLeft <= 0,
        isRightArrowDisabled: scrollLeft >= this.content.clientWidth,
      });
    }
  };

  scrollRighrt = () => {
    this.scroll(100);
  };

  scrollLeft = () => {
    this.scroll(-100);
  };

  render() {
    return (
      <div className={css.scroll}>
        <CaretLeftOutlined onClick={this.scrollLeft} disabled={this.state.isLeftArrowDisabled} />
        <div className={css['scroll-content']} ref={this.setContentRef}>
          {this.props.children}
        </div>
        <CaretRightOutlined onClick={this.scrollRighrt} disabled={this.state.isRightArrowDisabled} />
      </div>
    );
  }
}
