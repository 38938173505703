import { GET_COUNT_COMMENT_FAIL, GET_COUNT_COMMENT_START } from '@/store/topics/actions';

import {
  CHANGE_WIDGET_NEWS_MODAL_VISIBLE,
  CLEAR_NEWS_ID,
  CREATE_NEWS_COMMENT_SUCCESS,
  GET_COUNT_NEWS_COMMENT_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  GET_NEWS_BY_ID_START,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_FAIL,
  GET_NEWS_FOR_WIDGET_FAIL,
  GET_NEWS_FOR_WIDGET_START,
  GET_NEWS_FOR_WIDGET_SUCCESS,
  GET_NEWS_START,
  GET_NEWS_SUCCESS,
  READ_NEWS_COMMENT_FAIL,
  READ_NEWS_COMMENT_START,
  READ_NEWS_COMMENT_SUCCESS,
  SET_RATE_FAIL,
  SET_RATE_START,
  SET_RATE_SUCCESS,
  SET_VIEWED_FAIL,
  SET_VIEWED_START,
  SET_VIEWED_SUCCESS,
  UPDATE_NEWS_VISIBILITY_FAIL,
  UPDATE_NEWS_VISIBILITY_START,
  UPDATE_NEWS_VISIBILITY_SUCCESS,
} from './constants';

const initialState = {
  isLoading: {
    getNews: false,
    getNewsById: false,
    getNewsForWidget: false,
    getComments: false,
    createComment: false,
  },
  isError: {
    getNews: false,
    getNewsById: false,
    getComments: false,
    getNewsForWidget: false,
  },
  pagination: {
    newsList: {
      total: 0,
      size: 0,
      currentPage: 0,
    },
    commentsList: {
      total: 0,
      size: 0,
      currentPage: 0,
    },
  },
  newsList: [],
  newsListWidget: [],
  newsData: null,
  commentsList: [],
  countComments: null,
  widgetNewsVisible: false,
  newsFilters: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_NEWS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNews: true },
        isError: { ...state.isError, getNews: false },
        pagination: { ...state.pagination, newsList: { size: 0, total: 0, currentPage: 0 } },
        newsList: [],
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNews: false },
        isError: { ...state.isError, getNews: false },
        pagination: {
          ...state.pagination,
          newsList: {
            size: response.size,
            total: response.totalElements,
            currentPage: response.number,
          },
        },
        newsList: response.content,
      };

    case GET_NEWS_FOR_WIDGET_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsForWidget: true },
        newsListWidget: [],
      };

    case GET_NEWS_FOR_WIDGET_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsForWidget: false },
        newsListWidget: response.content,
      };

    case GET_NEWS_FOR_WIDGET_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsForWidget: false },
        isError: { ...state.isError, getNewsForWidget: true },
      };

    case GET_NEWS_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNews: false },
        isError: { ...state.isError, getNews: true },
        pagination: { ...state.pagination, newsList: { size: 0, total: 0, currentPage: 0 } },
        newsList: [],
      };

    case GET_NEWS_BY_ID_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsById: true },
        isError: { ...state.isError, getNewsById: false },
        newsData: null,
      };

    case GET_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsById: false },
        isError: { ...state.isError, getNewsById: false },
        newsData: response,
      };

    case GET_NEWS_BY_ID_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, getNewsById: false },
        isError: { ...state.isError, getNewsById: true },
        newsData: null,
      };

    case READ_NEWS_COMMENT_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getComments: true },
        isError: { ...state.isError, getComments: false },
        pagination: { ...state.pagination, commentsList: { size: 0, total: 0, currentPage: 0 } },
        commentsList: [...state.commentsList],
      };

    case READ_NEWS_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getComments: false },
        isError: { ...state.isError, getComments: false },
        pagination: {
          ...state.pagination,
          commentsList: {
            size: response.size,
            total: response.totalElements,
            currentPage: response.number,
          },
        },
        commentsList: [...state.commentsList, ...response.content],
      };

    case READ_NEWS_COMMENT_FAIL:
      return {
        ...state,
        isLoading: { ...state.isLoading, getComments: false },
        isError: { ...state.isError, getComments: true },
        pagination: { ...state.pagination, commentsList: { size: 0, total: 0, currentPage: 0 } },
        commentsList: [],
      };

    case CREATE_NEWS_COMMENT_SUCCESS:
      return {
        ...state,
        commentsList: [response, ...state.commentsList],
        countComments: state.countComments + 1,
      };

    case GET_COUNT_NEWS_COMMENT_SUCCESS:
      return { ...state, countComments: response };

    case CHANGE_WIDGET_NEWS_MODAL_VISIBLE:
      return { ...state, widgetNewsVisible: !state.widgetNewsVisible };

    case SET_RATE_SUCCESS:
      return { ...state, newsData: { ...state.newsData, hasRated: true, rate: response?.rate } };

    case SET_VIEWED_SUCCESS:
      return { ...state, newsData: { ...state.newsData, viewed: true } };

    case GET_COUNT_COMMENT_START:
    case GET_COUNT_COMMENT_FAIL:
    case SET_RATE_START:
    case SET_RATE_FAIL:
    case SET_VIEWED_START:
    case SET_VIEWED_FAIL:
    case UPDATE_NEWS_VISIBILITY_START:
    case UPDATE_NEWS_VISIBILITY_SUCCESS:
    case UPDATE_NEWS_VISIBILITY_FAIL:
      return { ...state };

    case CLEAR_NEWS_ID:
      return { ...state, newsData: null };

    default:
      return { ...state };
  }
};
