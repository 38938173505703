// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-UkKNMn.user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-lh5Hsq .user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-label-FWE5XK[data-value="Montserrat"]:before, .user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-UkKNMn.user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-lh5Hsq .user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-item-nJpGXx[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-Montserrat-dDm9RT {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__body_empty-CGURDZ {
  text-align: center;
  color: #666;
  width: 470px;
  margin: 128px auto;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
}

.user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__list-tXvUeb > div:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/ColleguesProfile/Planning/plan/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAMA;EAHE,gCAAgC;AAClC","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.plan__body_empty {\n  font-weight: 300;\n  margin: 128px auto;\n  width: 470px;\n  text-align: center;\n  font-size: 14px;\n  line-height: 1.43;\n  color: #666;\n}\n.plan__list > div:not(:last-child) {\n  border-bottom: 1px solid #e6e6e6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-UkKNMn`,
	"qlPicker": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-UkKNMn`,
	"ql-font": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-lh5Hsq`,
	"qlFont": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-lh5Hsq`,
	"ql-picker-label": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-label-FWE5XK`,
	"qlPickerLabel": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-label-FWE5XK`,
	"ql-picker-item": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-item-nJpGXx`,
	"qlPickerItem": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-picker-item-nJpGXx`,
	"ql-font-Montserrat": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-Montserrat-dDm9RT`,
	"qlFontMontserrat": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__ql-font-Montserrat-dDm9RT`,
	"plan__body_empty": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__body_empty-CGURDZ`,
	"planBodyEmpty": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__body_empty-CGURDZ`,
	"plan__list": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__list-tXvUeb`,
	"planList": `user-src-pages-ColleguesProfile-Planning-plan-styles-module__plan__list-tXvUeb`
};
export default ___CSS_LOADER_EXPORT___;
