/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import Answer from './Answer';
import { BlockWithOpenAnswer } from './BlockWithOpenAnswer';
import { getLetter, MATCHING_ID, MULTIPLE_ID, OPEN_ID, SINGLE_ID } from './helper';
import MatchingQuestion from './MatchingQuestion';

import { shuffle } from 'lodash';

import css from './Question.module.scss';

const Question = ({
  data,
  setAnswer,
  test,
  getFile,
  lockUI,
  block,
  currentQuestionNumber,
  setCurrentQuestionNumber,
  clearQuestionNumber,
}) => {
  const [image, setImage] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [answersArr, setAnswersArr] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const { t } = useTranslation('question');

  const padding = 40;
  const modalStyle = { display: 'flex' };
  const modalBodyStyle = { padding };

  useEffect(() => {
    if (data?.position !== undefined) {
      setCurrentQuestionNumber(data.position + 1);
    }
  }, []);

  useEffect(() => {
    renderAnswer();
    if (data.imgUuid) {
      getFile(data.imgUuid, blob => {
        setImage(URL.createObjectURL(blob));
      });
    } else {
      setImage(null);
    }
  }, [data]);

  const multiple = useMemo(() => (data.multipleAnswers ? t('multiple') : t('one')), [data, t]);

  const renderAnswer = () => {
    if (data?.answers?.length) {
      let arr = data.answers;

      if (block?.test?.mixAnswers) {
        arr = shuffle(arr);
      }

      if (data.type !== MATCHING_ID) {
        setAnswersArr(arr);
      }
    }
  };

  const answerClickHandler = useCallback(
    id => {
      if (!data.multipleAnswers) {
        setAnswers([id]);
      } else if (answers.includes(id)) {
        setAnswers(answers.filter(item => item !== id));
      } else {
        setAnswers([...answers, id]);
      }
    },
    [answers]
  );

  const questionNumbers = t('questions', {
    position: currentQuestionNumber, // data.position + 1,
    countQuestions: test.test.countQuestions,
  });

  const confirmAnswerClick = useCallback(
    isMatchingQuestion => {
      if (isMatchingQuestion) {
        setAnswer({ questionId: data.id, userAnswerPairs: answers, testId: test?.test?.testId });
      } else {
        setAnswer({ questionId: data.id, userAnswerIds: answers, testId: test?.test?.testId });
      }

      nextCurrent();
    },
    [answers]
  );

  const nextCurrent = () => {
    if (currentQuestionNumber < test.test.countQuestions) {
      setCurrentQuestionNumber(currentQuestionNumber + 1);
    }

    if (currentQuestionNumber >= test.test.countQuestions) {
      clearQuestionNumber();
    }
  };

  const showModal = () => setVisibleModal(true);
  const hideModal = () => setVisibleModal(false);

  const handleImageLoad = () => {
    const { naturalHeight, naturalWidth } = imageRef.current;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const isMatchingQuestion = data.type === MATCHING_ID;

  return (
    <div className={css.Question}>
      <div className={css['Question-wrapper']}>
        <div className={css['Question-block-name']}>{test?.name}</div>
        <div className={css['Question-count']}>{questionNumbers}</div>
        {image && (
          <div>
            <img className={css['Question-image']} src={image} alt='' onClick={showModal} />
          </div>
        )}
        <div className={css['Question-title']}>{data.question}</div>
      </div>
      {(data.type === SINGLE_ID || data.type === MULTIPLE_ID) && (
        <>
          <div className={css['Question-multiple']}>{multiple}</div>
          <div className={css['Question-answers']}>
            {answersArr.map((answer, index) => (
              <Answer
                key={answer.id}
                data={answer}
                onClick={answerClickHandler}
                checked={answers.includes(answer.id)}
                getFile={getFile}
                letter={getLetter(index)}
              />
            ))}
          </div>
          <div className={css['Question-confirmButton']}>
            <Button
              onClick={() => confirmAnswerClick()}
              disabled={!answers.length || lockUI}
              type='primary'
              size='large'
            >
              {t('confirm')}
            </Button>
          </div>
        </>
      )}
      {data.type === OPEN_ID && (
        <BlockWithOpenAnswer data={data} setAnswer={setAnswer} nextCurrent={nextCurrent} test={test} />
      )}
      {isMatchingQuestion && (
        <>
          <div className={css['Question-multiple']}>{t('matching')}</div>
          <MatchingQuestion
            data={data}
            confirmAnswerClick={() => confirmAnswerClick(true)}
            setAnswers={setAnswers}
            lockUI={lockUI}
          />
        </>
      )}
      <Modal
        width={padding * 2 + imageDimensions.width}
        open={visibleModal}
        onCancel={hideModal}
        footer={null}
        style={modalStyle}
        styles={{ body: modalBodyStyle }}
      >
        <img src={image} alt='' ref={imageRef} onLoad={handleImageLoad} />
      </Modal>
    </div>
  );
};

export default Question;
