// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-O5P4Lc.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Z6eXoR .user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-label-bhc_cY[data-value="Montserrat"]:before, .user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-O5P4Lc.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Z6eXoR .user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-item-OK3wZU[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Montserrat-IU3J_a {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__headerContentCaption-Bra3jb {
  margin-bottom: 16px;
  text-align: center !important;
}

.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__headerContentCaptionCol-VhVQGw {
  margin-bottom: 16px;
  text-decoration: underline;
  font-weight: 300 !important;
}

.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__text-yODKAi {
  width: 350px;
  font-size: 14px;
}

.user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__btnChangeView-RyyKqS {
  justify-content: flex-end;
  margin-bottom: 8px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/DiagnosticTalentQ/ResultTalentQ/resultTalentQ.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,6BAA6B;AAC/B;;AAMA;EAHE,mBAAmB;EACnB,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAMA;EAHE,YAAY;EACZ,eAAe;AACjB;;AAMA;EAHE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.headerContentCaption {\n  text-align: center !important;\n  margin-bottom: 16px;\n}\n.headerContentCaptionCol {\n  font-weight: 300 !important;\n  margin-bottom: 16px;\n  text-decoration: underline;\n}\n\n.text {\n  font-size: 14px;\n  width: 350px;\n}\n\n.btnChangeView {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-O5P4Lc`,
	"qlPicker": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-O5P4Lc`,
	"ql-font": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Z6eXoR`,
	"qlFont": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Z6eXoR`,
	"ql-picker-label": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-label-bhc_cY`,
	"qlPickerLabel": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-label-bhc_cY`,
	"ql-picker-item": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-item-OK3wZU`,
	"qlPickerItem": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-picker-item-OK3wZU`,
	"ql-font-Montserrat": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Montserrat-IU3J_a`,
	"qlFontMontserrat": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__ql-font-Montserrat-IU3J_a`,
	"headerContentCaption": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__headerContentCaption-Bra3jb`,
	"headerContentCaptionCol": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__headerContentCaptionCol-VhVQGw`,
	"text": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__text-yODKAi`,
	"btnChangeView": `user-src-pages-DiagnosticTalentQ-ResultTalentQ-resultTalentQ-module__btnChangeView-RyyKqS`
};
export default ___CSS_LOADER_EXPORT___;
