import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setReplyMessage } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar } from 'antd';
import { textModificator } from '@/pages/Chat/parts/Messages/components/textModificator';
import { FiCopy, FiCornerUpLeft } from 'react-icons/fi';

import File from './File';
import Photo from './Photo';

import useImage from '@shared/hooks/useImage';
import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

let pressTimer;

const Message = ({ data }) => {
  const { selectedUser, messages } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation('chat');

  const [visiblePopover, setVisiblePopover] = useState(false);
  const [upMessage, setUpMessage] = useState(false);

  const popoverRef = useRef(null);
  const messageRef = useRef(null);
  const messageContentRef = useRef(null);
  const messageBoxRef = useRef(null);
  const avatar = useImage(data?.user_local_photo_uuid, 'avatar');
  const dispatch = useDispatch();
  const [isTablet] = useIsMobile(1201);

  useEffect(() => {
    if (isTablet) {
      messageRef.current?.addEventListener('touchstart', handleMouseDown);
      messageRef.current?.addEventListener('touchend', handleMouseUp);
    } else {
      messageRef.current?.addEventListener('contextmenu', handleRightClick);
    }
    document.addEventListener('click', handleLeftClick);

    return () => {
      if (isTablet) {
        messageRef.current?.removeEventListener('touchstart', handleMouseDown);
        messageRef.current?.removeEventListener('touchend', handleMouseUp);
      } else {
        messageRef.current?.removeEventListener('contextmenu', handleRightClick);
      }
      document.removeEventListener('click', handleLeftClick);
    };
  }, [messageRef, popoverRef, isTablet]);

  useEffect(() => {
    if (messageContentRef.current) {
      if (data.content) {
        messageContentRef.current.innerHTML = textModificator(data.content);
      } else {
        messageContentRef.current.innerHTML = '';
      }
    }
  }, [messageContentRef]);

  const handleMouseDown = event => {
    pressTimer = setTimeout(() => {
      setVisiblePopover(true);
      if (window.screen.height - event.touches[0].screenY < 100 || messageBoxRef.current.offsetHeight > 400) {
        setUpMessage(true);
      }
      document.body.style.overflow = 'hidden';
    }, 500);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer);
  };

  const handleRightClick = event => {
    event.preventDefault();
    // Закрываем все открытые popover
    messageBoxRef?.current?.click();

    const popover = popoverRef.current;

    if (messageBoxRef.current?.offsetTop < 0) {
      popover.style.top = messageBoxRef.current?.getBoundingClientRect().bottom - 150 + 'px';
    } else if (messageBoxRef.current?.getBoundingClientRect().top < 500) {
      popover.style.top = event.pageY - 335 + 'px';
    } else {
      popover.style.top = event.pageY - 335 + 'px';
    }

    if (messageBoxRef.current.offsetLeft < 500) {
      popover.style.left =
        messageBoxRef.current.offsetLeft +
        messageBoxRef.current.offsetWidth +
        messageBoxRef.current.clientLeft +
        10 +
        'px';
    } else {
      popover.style.left = messageBoxRef.current.offsetLeft - 144 + 'px';
    }

    if (event?.button === 2) setVisiblePopover(true);
  };

  const handleLeftClick = () => {
    setVisiblePopover(false);
    setUpMessage(false);
    document.body.style.overflow = 'auto';
  };

  const replyMessage = useMemo(() => {
    if (data.forwarded_message_id) {
      return messages.find(message => message.message_id === data.forwarded_message_id);
    } else {
      return null;
    }
  }, []);

  const isNotCurrentUser = useMemo(() => currentUser.id !== data.user_id, []);

  const handleReply = () => {
    dispatch(
      setReplyMessage({
        content: data.content,
        messageId: data.message_id,
        userId: data.user_id,
        userFirstName: data.user_first_name,
        userLastName: data.user_last_name,
        files: data.files,
      })
    );
    setVisiblePopover(false);
  };

  const handleCopyText = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(data.content ?? '');
    }
  };

  const contentPopover = (
    <>
      <div className={styles.message__popoverContent} onClick={handleReply}>
        <FiCornerUpLeft />
        <p>{t('reply')}</p>
      </div>
      <div className={styles.message__popoverContent} onClick={handleCopyText}>
        <FiCopy />
        <p>{t('copy')}</p>
      </div>
    </>
  );

  const renderFiles = () => {
    if (data.files.length) {
      return data.files.map((elem, index) => {
        if (elem.fileType.includes('image')) {
          return <Photo fileData={elem} key={index} />;
        } else {
          return <File fileName={elem.fileName} fileUuid={elem.fileUuid} key={index} />;
        }
      });
    } else {
      return null;
    }
  };

  const renderFileText = () => {
    if (replyMessage?.files?.length >= 2) {
      return t('files.0');
    } else if (replyMessage?.files?.length === 1) {
      if (replyMessage?.files[0].fileType.includes('image')) {
        return t('files.2');
      } else {
        return t('files.1');
      }
    }
  };

  return (
    <div className={classNames(styles.message, { [styles.message_currentUser]: currentUser.id === data.user_id })}>
      <div ref={messageBoxRef} className={styles.message__box}>
        {isNotCurrentUser && <Avatar src={avatar} />}
        <div
          className={classNames(styles.message__content, {
            [styles.message__content_currentUser]: !isNotCurrentUser,
            [styles.message__content_active]: visiblePopover && isTablet,
            [styles.message__content_up]: upMessage,
          })}
          ref={messageRef}
        >
          {replyMessage && (
            <div
              className={classNames(styles.message__reply, { [styles.message__reply_currentUser]: !isNotCurrentUser })}
            >
              <div className={styles.message__name}>
                {replyMessage.user_id === currentUser.id ? (
                  <>
                    {currentUser.firstName} {currentUser.lastName}
                  </>
                ) : (
                  <>
                    {replyMessage.user_first_name} {replyMessage.user_last_name}
                  </>
                )}
              </div>
              <p className={styles.message__replyContent}>
                {replyMessage?.content ? replyMessage.content : renderFileText()}
              </p>
            </div>
          )}
          {selectedUser?.chatAuthorId && isNotCurrentUser && (
            <p className={styles.message__name}>
              {data.user_first_name} {data.user_last_name}
            </p>
          )}
          {!!data.files?.length && <div className={styles.message__files}>{renderFiles()}</div>}
          <div className={styles.message__container}>
            <p ref={messageContentRef} />
            <p className={styles.message__time}>{dayjs(data.createdAt).format('HH:mm')}</p>
          </div>
        </div>
        <div
          ref={popoverRef}
          className={classNames(styles.popover, {
            [styles.popover_visible]: visiblePopover,
            [styles.popover_up]: upMessage,
          })}
        >
          {contentPopover}
        </div>
      </div>
      {isTablet && <div className={classNames(styles.overlay, { [styles.overlay_visible]: visiblePopover })} />}
    </div>
  );
};

Message.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    createdAt: PropTypes.string,
    message_id: PropTypes.number,
    user_id: PropTypes.number,
    forwarded_message_id: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    user_first_name: PropTypes.string,
    user_last_name: PropTypes.string,
    user_local_photo_uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    files: PropTypes.arrayOf(
      PropTypes.shape({
        fileName: PropTypes.string,
        fileUuid: PropTypes.string,
        fileType: PropTypes.string,
      })
    ),
  }),
};

export default memo(Message);
