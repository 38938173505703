/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './UserProfileModal.module.scss';

class WelcomeModal extends Component {
  static propTypes = {
    goToNextStep: PropTypes.func,
  };

  render() {
    const { goToNextStep, t } = this.props;

    return (
      <div className={classNames(css['App-card-item'], css['UserProfile-modal'])}>
        <div className={css['UserProfile-modal-header']}>{t('welcome')}</div>
        <div className={css['UserProfile-modal-text']}>{t('description')}</div>
        <Button onClick={goToNextStep}>{t('go')}</Button>
      </div>
    );
  }
}

export default withTranslation('welcomeModal2')(WelcomeModal);
