import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedUser } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar } from 'antd';
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from 'react-icons/io5';

import useImage from '@shared/hooks/useImage';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const PersonalGroupElement = ({ data, isMentoring }) => {
  const avatar = useImage(data?.userData?.local_photo_uuid, 'avatar');
  const dispatch = useDispatch();

  const { selectedUser } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);
  const { t } = useTranslation('chat');

  const isSelectedChat = data?.chatId === selectedUser?.chatId;

  const isMessageToday = useMemo(() => {
    const date = new Date();
    const format = 'DD:MM:YYYY';
    if (data.lastMessage) {
      return dayjs(date).format(format) === dayjs(data.lastMessage.createdAt).format(format);
    } else {
      return false;
    }
  }, [data.lastMessage]);

  const isMessageOnTheWeek = useMemo(() => {
    const date = new Date();
    if (data.lastMessage) {
      return (
        dayjs(date).week() === dayjs(data.lastMessage.createdAt).week() &&
        dayjs(date).year() === dayjs(data.lastMessage.createdAt).year()
      );
    } else {
      return false;
    }
  }, [data.lastMessage]);

  const handleSelectGroup = () => {
    if (!isSelectedChat) {
      dispatch(
        setSelectedUser({
          firstName: data.userData.first_name,
          lastName: data.userData.last_name,
          id: data.userData.id,
          photo: data.userData.local_photo_uuid,
          chatId: data.chatId,
          isMentoring: isMentoring,
        })
      );
    }
  };

  const renderDate = () => {
    if (!data?.lastMessage?.hasOwnProperty('createdAt')) return null;
    if (isMessageToday) return dayjs(data?.lastMessage?.createdAt).format('HH:mm');
    else if (isMessageOnTheWeek) return dayjs(data?.lastMessage?.createdAt).format('dd');
    else return dayjs(data?.lastMessage?.createdAt).format('DD.MM.YYYY');
  };

  const renderStatus = () => {
    if (data.countNotReadMessage) {
      return <div className={styles.groupElement__noRead}>{data.countNotReadMessage}</div>;
    } else if (data.lastMessage?.is_read && Number(data.lastMessage?.user_id) === currentUser.id) {
      return (
        <IoCheckmarkDoneSharp
          size={14}
          className={classNames(styles.groupElement__icon, { [styles.groupElement__icon_active]: isSelectedChat })}
        />
      );
    } else if (Number(data.lastMessage?.user_id) === currentUser.id) {
      return (
        <IoCheckmarkSharp
          size={14}
          className={classNames(styles.groupElement__icon, { [styles.groupElement__icon_active]: isSelectedChat })}
        />
      );
    }
  };

  const renderFileText = () => {
    if (data.lastMessage?.files?.length >= 2) {
      return t('files.0');
    } else if (data.lastMessage?.files?.length === 1) {
      if (data.lastMessage.files[0].fileType.includes('image')) {
        return t('files.2');
      } else {
        return t('files.1');
      }
    }
  };

  return (
    <div
      className={classNames(styles.groupElement, {
        [styles.groupElement_active]: isSelectedChat && selectedUser && data,
      })}
      onClick={handleSelectGroup}
    >
      <div className={styles.groupElement__header}>
        <div className={styles.groupElement__box}>
          <Avatar size={32} src={avatar} />
          <p className={styles.groupElement__name}>
            {data.userData?.first_name} {data.userData?.last_name}
          </p>
        </div>
        <p className={styles.groupElement__date}>{renderDate()}</p>
      </div>
      <div className={styles.groupElement__content}>
        <p className={styles.groupElement__message}>
          {data?.lastMessage?.content ? data.lastMessage.content : renderFileText()}
        </p>
        {renderStatus()}
      </div>
    </div>
  );
};

PersonalGroupElement.propTypes = {
  data: PropTypes.shape({
    chatId: PropTypes.number,
    lastMessage: PropTypes.shape({
      content: PropTypes.string,
      createdAt: PropTypes.string,
      user_id: PropTypes.number,
      is_read: PropTypes.bool,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          fileName: PropTypes.string,
          fileUuid: PropTypes.string,
          fileType: PropTypes.string,
        })
      ),
    }),
    userData: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      local_photo_uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      id: PropTypes.number,
    }),
    countNotReadMessage: PropTypes.number,
  }),
  isMentoring: PropTypes.bool,
};

export default memo(PersonalGroupElement);
