import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './ModalPaidTopicRestriction.module.scss';

const ModalPaidTopicRestriction = ({ open, onClose }) => {
  const { t } = useTranslation('modalPaidTopicRestriction');

  const footer = (
    <div className={css['ModalPaidTopicRestriction-footer']}>
      <Button key='ok' type='primary' onClick={onClose} className={css['modalPaidTopicRestriction-btn']} size='large'>
        {t('ok')}
      </Button>
    </div>
  );

  return (
    <Modal open={open} onCancel={onClose} className={css['ModalPaidTopicRestriction']} footer={footer} centered={true}>
      <div className={css['ModalPaidTopicRestriction-body']}>
        <div className={css['ModalPaidTopicRestriction-text']}>{t('cannotAddNew')}</div>
        <div className={css['ModalPaidTopicRestriction-text']}>
          {t('contactTrainingDept')}{' '}
          <a href='mailto:MarsUniversityRussia@effem.com' className={css['ModalPaidTopicRestriction-link']}>
            {t('trainingDeptLink')}
          </a>
        </div>
      </div>
    </Modal>
  );
};

ModalPaidTopicRestriction.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalPaidTopicRestriction;
