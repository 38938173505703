/* eslint-disable */
import { GET_DEPARTMENTS_FAIL, GET_DEPARTMENTS_START, GET_DEPARTMENTS_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getDepartments = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/departmentInternal${getQueryString({
    sort: 'name,asc',
    ...params,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_DEPARTMENTS_START, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAIL],
  onSuccess,
});
