/* eslint-disable */
import { CREATE_STUDY_PLAN_SUCCESS } from '@/store/study-plan/actions';
import { DELETE_USER_TOPIC_SUCCESS, POST_USER_TOPICS_SUCCESS } from '@/store/user-topics/actions';

import { TopicType } from '@/pages/Diagnostic/interfaces';

import {
  CHANGE_DIAGNOSTIC_VALUE,
  GET_DIAGNOSTIC_COURSE_START,
  GET_DIAGNOSTIC_COURSE_SUCCESS,
  GET_DIAGNOSTIC_STUDY_START,
  GET_DIAGNOSTIC_STUDY_SUCCESS,
} from './actions';

const initialState = {
  topics: [],
  type: TopicType.Course,
  tags: [],
  isLoading: false,
};

const changeTopicFavourite = (arr, value) => {
  return arr.map(item => {
    if (item.id === value.id) {
      return { ...item, isFavourite: value.isFavourite };
    }
    return item;
  });
};

const changeTopic = (arr, value, status) => {
  return arr.map(item => {
    if (item.id === value.id) {
      return { ...item, ...value, planStatus: { ...item.planStatus, planStatus: status } };
    }
    return item;
  });
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_DIAGNOSTIC_COURSE_START:
    case GET_DIAGNOSTIC_STUDY_START:
      return { ...state, isLoading: true };

    case GET_DIAGNOSTIC_COURSE_SUCCESS:
    case GET_DIAGNOSTIC_STUDY_SUCCESS:
      return {
        ...state,
        topics: response,
        isLoading: false,
      };

    case CHANGE_DIAGNOSTIC_VALUE:
      const { name, value } = response;
      return { ...state, [name]: value };

    case POST_USER_TOPICS_SUCCESS:
      return { ...state, topics: changeTopicFavourite(state.topics, response[0].userTopic.topic) };
    case DELETE_USER_TOPIC_SUCCESS:
      return { ...state, topics: changeTopicFavourite(state.topics, response.topic) };
    case CREATE_STUDY_PLAN_SUCCESS:
      return { ...state, topics: changeTopic(state.topics, response.topic, response.status) };

    default:
      return state;
  }
};
