/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getOpenDate } from '@/store/course/actions';
import {
  checkWaitingListInfo,
  eventRegistrAction,
  showInterestAction,
  waitingListAction,
} from '@/store/study-plan/actions';

import { selectCourse as courseSelect } from '@/store/course/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { message, Modal as ModalAntd, Skeleton } from 'antd';
import Modal from '@/components/Modal';

import CalendarTableModal from './CalendarModal';
import CalendarModalData from './CalendarModalData';
import ConfirmModal from './ConfirmModal';
import EventModal from './EventModal';

import {
  isConference,
  isConfOrAllCourse,
  isCorpCourseWithOrWithoutApprove,
  isCorpOrExtCourseWithOrWithoutApp,
  isEvent,
  isEventOrConference,
  isExternalCourse,
  isTestCourses,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

class CalendarModal extends Component {
  currentYear = Number(dayjs().format('YYYY'));

  constructor(props) {
    super(props);
    this.state = {
      selectedYear: this.currentYear,
      visibleModalData: !props.hasEvents,
      hasEventsFlag: !!props.hasEvents,
      showInterest: false,
      independent: true,
      showCalendarTableModal: false,
      comment: props.comment || '',
    };
  }

  componentDidMount() {
    this.props.getOpenDate();
    if (this.props?.topicInfo?.id && isConfOrAllCourse(this.props.eventTypeId)) {
      this.props.checkWaitingListInfo(this.props?.topicInfo?.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { open, hasEvents, topicInfo } = this.props;
    if (open && open !== prevProps.open) {
      this.setState({
        selectedYear: this.currentYear,
        visibleModalData: !hasEvents,
        independent: topicInfo && topicInfo.independent,
        showCalendarTableModal: false,
      });
    }
  }

  handleCalendarModalData = ({ hasEventsFlag, showInterest, comment }) => {
    if (this.state.showCalendarTableModal) {
      this.setState({
        showCalendarTableModal: false,
        comment,
      });
    } else {
      this.setState({
        hasEventsFlag: hasEventsFlag,
        showInterest: showInterest,
        visibleModalData: !this.state.visibleModalData,
      });
    }
  };

  scheduleButtonHandler = ({ comment }) => {
    this.setState({
      comment,
      showCalendarTableModal: true,
      hasEventsFlag: true,
    });
  };

  onOkWaitFunc = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    webinarDateTime,
    planInfo,
    selectedId,
    topicTargetAudience,
    eventHavePlace
  ) => {
    if (
      isCorpOrExtCourseWithOrWithoutApp(this.props.eventTypeId) &&
      (!this.props.hasEvents || selectedId === undefined)
    ) {
      this.props.showInterestAction(
        this.props.topicInfo?.id,
        () => {
          ModalAntd.success().update({
            title: this.props.t('showInterestTitle1'),
            content: isConference(this.props.eventTypeId)
              ? this.props.t('showInterestContent2')
              : this.props.t('showInterestContent1'),
            zIndex: 10001,
            footer: null,
            closable: true,
            onCancel: this.props.onCancel(),
          });
        },
        err => {
          message.error(this.props.t('error'));
        }
      );
    } else if (isTestCourses(this.props.eventTypeId) && eventHavePlace) {
      this.props.waitingListAction(
        selectedId,
        () => {
          ModalAntd.success().update({
            title: this.props.t('waitingListTitle1'),
            content: this.props.t('waitingListContent1'),
            zIndex: 10001,
            footer: null,
            closable: true,
            onCancel: this.props.onCancel(),
          });
        },
        err => {
          message.error(this.props.t('error'));
        }
      );
    } else {
      this.props.eventRegistrAction(
        selectedId,
        () => {
          this.props.onOk(topicId, planningMonths, comment, withoutSchedule, webinarDateTime, planInfo, selectedId);
        },
        err => {
          message.error(this.props.t('error'));
        }
      );
    }
  };

  render() {
    const {
      open,
      onCancel,
      disabledSelect,
      dateEvent,
      eventTypeId,
      selectMonthEvents,
      hasEvents,
      openDate,
      isLoading,
      toCalendar,
      dates,
      checkEventDate,
      t,
      isMyProfile,
    } = this.props;

    const { visibleModalData, showCalendarTableModal } = this.state;

    const year = !disabledSelect ? this.state.selectedYear.toString() : dayjs(dateEvent).format('YYYY') || '';

    const currentDates = [];

    dates &&
      Array.isArray(dates) &&
      dates.forEach(item => {
        if (item.dates) {
          if (Array.isArray(item.dates)) {
            item.dates.forEach(date => {
              currentDates.push({ date, endTime: item.endTime, startTime: item.startTime });
            });
          }

          if (typeof item.dates === 'string') {
            currentDates.push({ date: item.dates });
          }
        }
      });

    return (
      <Modal
        open={open}
        onCancel={onCancel}
        width={isEvent(eventTypeId) ? 470 : 600}
        hideFooter
        headerFixToModal
        centered
        zIndex={4000}
      >
        <Skeleton loading={isLoading} className={css['Skeleton']}>
          {!isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && !isEventOrConference(eventTypeId) && (
            <CalendarTableModal
              {...this.props}
              {...(isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && {
                onOk: this.onOkWaitFunc,
                okText: !hasEvents || checkEventDate ? t('showInterest') : t('showSignUp'),
              })}
              noEvent
              openDate={openDate}
              isLoadingPlan={isLoading}
              selectMonthEvents={selectMonthEvents}
              dayPicker={true}
            />
          )}
          {isEventOrConference(eventTypeId) && open && (
            <ConfirmModal
              {...this.props}
              {...(isTestCourses(eventTypeId) && {
                onOk: this.onOkWaitFunc,
                okText: t('showSignUp'),
              })}
              eventTypeId={eventTypeId}
              comment={this.props.comment}
              isLoadingPlan={isLoading}
              dates={currentDates}
              isMyProfile
            />
          )}
          {((isCorpCourseWithOrWithoutApprove(eventTypeId) && (visibleModalData || toCalendar)) ||
            showCalendarTableModal ||
            this.state.showInterest) && (
            <CalendarTableModal
              {...this.props}
              {...(isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && {
                onOk: this.onOkWaitFunc,
                okText: !hasEvents || checkEventDate ? t('showInterest') : t('showSignUp'),
              })}
              comment={this.props.comment || this.state.comment}
              openDate={this.state.independent ? dayjs().format('YYYY-MM-DD') : openDate}
              courses
              planInfo={this.props.planInfo}
              isLoadingPlan={this.props.isLoading}
              visibleModalData={visibleModalData}
              dateEvent={year}
              toPrev={this.handleCalendarModalData}
              hasEventsFlag={this.state.hasEventsFlag}
              showInterest={this.state?.showInterest}
              showPrevLink={showCalendarTableModal}
              hasEvents={hasEvents}
              selectMonthEvents={selectMonthEvents}
              dayPicker={false}
            />
          )}
          {!showCalendarTableModal &&
            isExternalCourse(eventTypeId) &&
            (visibleModalData || toCalendar) &&
            !this.state.showInterest && (
              <CalendarModalData
                {...this.props}
                {...(isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && {
                  onOk: this.onOkWaitFunc,
                  okText: !hasEvents || checkEventDate ? t('showInterest') : t('showSignUp'),
                })}
                comment={this.props.comment || this.state.comment}
                openDate={this.state.independent ? dayjs().format('YYYY-MM-DD') : openDate}
                isLoadingPlan={this.props.isLoading}
                visibleModalData={visibleModalData}
                planInfo={this.props.planInfo}
                dateEvent={year}
                toPrev={this.handleCalendarModalData}
                hasEventsFlag={this.state.hasEventsFlag}
                hasEvents={hasEvents}
                scheduleButtonHandler={this.scheduleButtonHandler}
              />
            )}
          {!toCalendar && isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && !visibleModalData && (
            <EventModal
              isLoadingPlan={isLoading}
              planInfo={this.props.planInfo}
              handleCalendarModalData={this.handleCalendarModalData}
              topicTargetAudience={this.props.topicInfo.targetAudience}
              {...this.props}
              {...(isCorpOrExtCourseWithOrWithoutApp(eventTypeId) && {
                onOk: this.onOkWaitFunc,
                okText: !hasEvents || checkEventDate ? t('showInterest') : t('showSignUp'),
              })}
            />
          )}
        </Skeleton>
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  [studyPlanSelect, topicsSelect, userTopicsSelect, courseSelect, usersSelect],
  (plan, topics, users, course, userTopics) => ({
    isLoading: plan.isLoading || topics.isLoading || userTopics.isLoading,
    openDate: (course.openDate && course.openDate.openDate) || null,
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    waitingInfo: plan.waitingInfo,
    waitingList: plan.waitingList,
  })
);

const mapActionsToProps = {
  getOpenDate,
  showInterestAction,
  waitingListAction,
  eventRegistrAction,
  checkWaitingListInfo,
};

CalendarModal.propTypes = {
  getOpenDate: PropTypes.func,
  comment: PropTypes.string,
  dateEvent: PropTypes.any,
  disabledSelect: PropTypes.bool,
  domainCompany: PropTypes.any,
  hasEvents: PropTypes.any,
  headerFixToModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  noComment: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.any,
  onOk: PropTypes.any,
  openDate: PropTypes.any,
  planInfo: PropTypes.any,
  planningTopicId: PropTypes.number,
  rest: PropTypes.any,
  title: PropTypes.string,
  toCalendar: PropTypes.bool,
  checkEventDate: PropTypes.bool,
  topicInfo: PropTypes.any,
  open: PropTypes.bool,
  eventTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectMonthEvents: PropTypes.bool,
  currentUser: PropTypes.object,
  dates: PropTypes.any,
  showInterestAction: PropTypes.func,
  waitingListAction: PropTypes.func,
  eventRegistrAction: PropTypes.func,
  checkWaitingListInfo: PropTypes.func,
  waitingInfo: PropTypes.any,
  waitingList: PropTypes.any,
  isMyProfile: PropTypes.bool,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['collectionMaterialId', 'constants'])(CalendarModal));
