const regex = /((http|https):\/\/)?([\w-]+\.)*[\w-]+\.[\w]{2,}(\/[^\s]*)?/gi;

export const textModificator = text => {
  // Выделение ссылки в сообщении
  let resultText = text.replace(regex, function (url) {
    return `<a href="${url.includes('http') ? url : 'https://' + url}" rel="noopener" target="_blank">${url}</a>`;
  });

  // Перенос на другую строку
  resultText = resultText.replace(/\n/g, '<br/>');

  return resultText;
};
