/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { eventRequestFinish } from '@/store/course/actions';
import { getFile } from '@/store/files/actions';
import {
  checkTopicWasFinished,
  clearPlan,
  getStudyPlan,
  updateButtonFunc,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import { createTopicComment, getTopicById, getTopicComments } from '@/store/topics/actions';

import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Input, Rate } from 'antd';
import Modal from '@/components/Modal';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './SendReviewModal.module.scss';

const { TextArea } = Input;

export class SendReviewModal2 extends Component {
  static propTypes = {
    checkTopicWasFinished: PropTypes.func,
    clearPlan: PropTypes.func,
    confirmModal: PropTypes.bool,
    createTopicComment: PropTypes.func,
    currentUserId: PropTypes.number,
    eventRequestFinish: PropTypes.func,
    getFile: PropTypes.func,
    getStudyPlan: PropTypes.func,
    getTopicById: PropTypes.func,
    getTopicComments: PropTypes.func,
    hideSendReviewModal: PropTypes.func,
    navigate: PropTypes.func,
    isPayCourses: PropTypes.object,
    isSendReviewModalVisible: PropTypes.bool,
    sendReviewTopicId: PropTypes.number,
    updateButtonFunc: PropTypes.func,
    updateStudyPlanStatus: PropTypes.func,
    studyPlan: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      rate: 0,
      confirmModalVisible: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isSendReviewModalVisible && !this.props.isSendReviewModalVisible) {
      this.clearForm();
    }

    if (
      prevProps?.isSendReviewModalVisible !== this.props?.isSendReviewModalVisible &&
      !this.props?.isSendReviewModalVisible
    ) {
      this.setState({ confirmModalVisible: false, loading: false });
    }
  }

  changeComment = event => {
    this.setState({
      comment: event.target.value,
    });
  };

  clearForm = () => {
    this.setState({
      comment: '',
      rate: 0,
    });
  };

  changeRate = event => {
    this.setState({ rate: event });
  };

  handleSendReview = () => {
    this.setState({ loading: true });
    const { comment, rate } = this.state;
    const { currentUserId, sendReviewTopicId, isPayCourses, studyPlan } = this.props;

    //console.log('handleSendReview user, topic', currentUserId, sendReviewTopicId);

    this.props.createTopicComment(
      {
        topicId: sendReviewTopicId,
        studyPlanId: studyPlan?.[0]?.id,
        text: comment,
        rate,
      },
      () => {
        this.props.getTopicComments(sendReviewTopicId);
        if (isPayCourses && isPayCourses.currentUserId) {
          this.updatePayCourses();
        } else if (isPayCourses && isPayCourses.callback) {
          this.handleFinalCancel();
          isPayCourses.callback();
        } else {
          this.handleFinalCancel();
        }
      },
      () => {
        this.handleFinalCancel();
      }
    );
  };

  updatePayCourses = () => {
    this.props.eventRequestFinish(this.props.isPayCourses.studyPlanId, true, () => {
      if (this.props.isPayCourses.isTopic) {
        this.props.updateButtonFunc();
      } else {
        // this.getStudyPlan();
      }
      this.handleFinalCancel();
      this.props.isPayCourses.callback && this.props.isPayCourses.callback();
    });
  };

  // getStudyPlan = () => {
  //   if (!this.props.isPayCourses.viewPlan) {
  //     this.props.clearPlan();
  //   } else {
  //     this.props.getStudyPlan(
  //       false,
  //       this.props.isPayCourses.currentUserId,
  //       this.props.isPayCourses.activeMonth,
  //       this.props.isPayCourses.page
  //     );
  //   }
  // };

  handleCancel = () => {
    this.setState({ confirmModalVisible: true });
  };

  hideConfirm = () => {
    this.setState({ confirmModalVisible: false });
  };

  handleFinalCancel = () => {
    this.setState({ confirmModalVisible: false, loading: false });
    this.props.hideSendReviewModal();
    //this.props.isPayCourses.onCancel && this.props.isPayCourses.onCancel();
  };

  render() {
    const { t } = this.props;
    const { comment, rate, loading } = this.state;

    return (
      <>
        <Modal
          open={this.props.isSendReviewModalVisible}
          onCancel={rate || comment ? this.handleCancel : this.handleFinalCancel}
          width={604}
          hideFooter
          zIndex={1005}
          footerBtnOrange
          modificate
          cardOn
        >
          <div className={classNames(css.SendReviewModal)}>
            <div className={css['SendReviewModal-header']}>
              <p>{t('leaveFeedback')}</p>
            </div>
            <TextArea
              className={css['SendReviewModal-textarea']}
              rows={7}
              placeholder={t('feedback')}
              onChange={this.changeComment}
              value={comment}
              maxLength={1500}
            />
            <div className={css['SendReviewModal-rate']}>
              <span>{t('rating')}</span>
              <Rate
                className={css['SendReviewModal-rate_main']}
                onChange={this.changeRate}
                allowHalf={false}
                disabled={false}
                value={rate}
              />
            </div>
            <Button onClick={this.handleSendReview} disabled={!rate || !comment || loading} size='large' type='primary'>
              {t('save')}
            </Button>
          </div>
        </Modal>
        <Modal
          open={this.state.confirmModalVisible}
          onOk={this.handleFinalCancel}
          onCancel={this.hideConfirm}
          title={t('withoutSave')}
          okText={t('cancel')}
          zIndex={1005}
          footerBtnOrange
          modificate
          cardOn
          manualCancel
        />
      </>
    );
  }
}

const mapStateToProps = createSelector([topicsSelect, usersSelect], (topics, users) => ({
  isPayCourses: topics.isPayCourses,
  currentUserId: users.currentUser && users.currentUser.id,
}));

const mapActionsToProps = {
  createTopicComment,
  getStudyPlan,
  getFile,
  clearPlan,
  getTopicById,
  updateStudyPlanStatus,
  updateButtonFunc,
  getTopicComments,
  eventRequestFinish,
  checkTopicWasFinished,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('sendReviewModal')(SendReviewModal2));
