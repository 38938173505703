import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { clearCommentsGratitudeState, getMentorComments } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Pagination, Spin } from 'antd';
import CommentRateMentor from '@/components/Mentoring/CommentRateMentor';
import { HiOutlineEmojiSad } from 'react-icons/hi';

import { selectMentoring } from '@/store/mentoring_profile/selector';

import css from './MentorTab.module.scss';

const MentorTabComments = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('MentorTab');
  const {
    commentsPagination,
    isLoading,
    mentorsCommentsTotal,
    mentorsCommentsCurrentPage,
    mentorsCommentsTotalPages,
    mentorsCommentsPageSize,
  } = useSelector(selectMentors);

  const { currentMentor } = useSelector(selectMentoring);

  useEffect(() => {
    dispatch(getMentorComments(currentMentor.id, 0, 10));

    return () => {
      // при размонтирование компонента очищаем в сторе комменатриев и благодарностей
      dispatch(clearCommentsGratitudeState());
    };
  }, [dispatch, currentMentor?.id]);

  const renderComments =
    commentsPagination?.length &&
    commentsPagination.map((item, index) => {
      let userName = `${item.user.firstName || ''} ${item.user.lastName || ''}`;

      return (
        <CommentRateMentor
          userName={userName}
          text={item.text}
          date={item.createdDate}
          photoUuid={item.user.photoUuid}
          localPhotoUuid={item.user.localPhotoUuid}
          key={index}
          commentId={item.id}
          canDelete={false}
          modified={item.modified}
        />
      );
    });

  const changePage = pagination => {
    dispatch(getMentorComments(currentMentor.id, pagination - 1, 10));
  };

  const emptyData = (
    <div className={css['MentorTab-empty']}>
      <div className={css['MentorTab-empty-message']}>
        <HiOutlineEmojiSad size={108} />
        <p className={css['MentorTab-empty-emptyHeader']}>{t('nothingYet')}</p>
        <p className={css['MentorTab-empty-description']}>{t('description3')}</p>
      </div>
    </div>
  );

  return (
    <div className={css['MentorTab']}>
      {isLoading ? (
        <Spin spinning={isLoading} size='large' className={css['MentorTab-comments-spin']} />
      ) : (
        <div className={css['MentorTab-comments']}>
          <div>{commentsPagination?.length > 0 ? renderComments : emptyData}</div>
          <div>
            {mentorsCommentsTotalPages > 1 && !isLoading && (
              <Pagination
                current={mentorsCommentsCurrentPage + 1}
                total={mentorsCommentsTotal}
                pageSize={mentorsCommentsPageSize}
                onChange={changePage}
                className={css['MentorTab-comments-pagination']}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MentorTabComments;
