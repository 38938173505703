import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeValue, getDiagnosticCourse, getDiagnosticStudy } from '@/store/diagnostic/actions';
import { getFile } from '@/store/files/actions';
import { createStudyPlan } from '@/store/study-plan/actions';
import { createUserTopic, deleteUserTopic } from '@/store/user-topics/actions';

import { selectDiagnostic } from '@/store/diagnostic/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Col, Row, Spin } from 'antd';
import CalendarModal from '@/pages/CalendarModal';
import { CourseFilters, FilterServerId, getName, StudyFilters, TopicType } from '@/pages/Diagnostic/interfaces';
import MaterialCard from '@/components/MaterialCard';
import Tag from '@/components/Tag';

import ResultsModal from './ResultsModal';

import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './Diagnostic.module.scss';

const calendarInitialValues = {
  calendarModalVisible: false,
  planningTopicId: 0,
  planningTopicType: '',
  topicInfo: null,
  startDate: null,
};

export const Diagnostic = ({
  domainCompany,
  currentUser,
  externalLogin,
  topics,
  isLoading,
  getDiagnosticCourse,
  getDiagnosticStudy,
  createStudyPlan,
  createUserTopic,
  deleteUserTopic,
  getFile,
  corpCourseBackground,
  changeValue,
  type,
  tags,
}) => {
  const [calendarData, setCalendarData] = useState(calendarInitialValues);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('diagnostic');

  // eslint-disable-next-line
  const FiltersNaming = {
    Electron: t('electron'),
    External: t('external'),
    Corporate: t('corporate'),
    Books: t('books'),
    Video: t('video'),
    Article: t('article'),
  };

  const getTopics = useMemo(() => {
    if (type === TopicType.Course) {
      return getDiagnosticCourse;
    }
    return getDiagnosticStudy;
  }, [getDiagnosticCourse, getDiagnosticStudy, type]);

  const getFilter = useMemo(() => {
    return _.join(
      tags.map(tag => getName(FilterServerId, tag)),
      '&topicTypeId='
    );
  }, [tags]);

  useEffect(() => {
    getTopics(getFilter ? { topicTypeId: getFilter } : {});
  }, [getFilter, getTopics]);

  const changeToCourse = useCallback(() => {
    changeValue('tags', []);
    changeValue('type', TopicType.Course);
  }, [changeValue]);

  const changeToStudy = useCallback(() => {
    changeValue('tags', []);
    changeValue('type', TopicType.Study);
  }, [changeValue]);

  const showCalendarModal = useCallback((topicId, type, startDate, topicInfo) => {
    setCalendarData({
      calendarModalVisible: true,
      planningTopicId: topicId,
      planningTopicType: type,
      topicInfo,
      startDate,
    });
  }, []);

  const handleCloseModal = useCallback(() => setCalendarData(calendarInitialValues), []);

  const handleStudyPlanClick = useCallback(
    (topicId, planningMonths = [], comment) => {
      for (let i = 0; i < planningMonths.length; i++) {
        createStudyPlan(currentUser.id, topicId, planningMonths[i], comment, handleCloseModal);
      }
    },
    [createStudyPlan, currentUser.id, handleCloseModal]
  );

  const getTags = useMemo(() => {
    const data = type === TopicType.Course ? CourseFilters : StudyFilters;
    return Object.keys(data).map(name => {
      return (
        <Tag
          key={name}
          tag={{ name: getName(FiltersNaming, name) }}
          activeTag={tags.includes(name)}
          filterCatalog
          onClick={() => changeValue('tags', tags.includes(name) ? _.without(tags, name) : [...tags, name])}
        />
      );
    });
  }, [FiltersNaming, changeValue, tags, type]);

  const topicCards =
    topics &&
    topics.map(topic => (
      <div key={`topic_${topic.id}`} className={css['CollectionMaterials-material-card']}>
        <MaterialCard
          topic={topic}
          userTopicId={topic.id}
          currentUser={currentUser}
          isExternalLogin={externalLogin}
          onFavoriteClick={createUserTopic}
          onDeleteClick={deleteUserTopic}
          onStugetTopicTypesdyPlanClick={null}
          getFile={getFile}
          domainCompany={domainCompany}
          corpCourseBackground={corpCourseBackground}
          onStudyPlanClick={showCalendarModal}
          isRecommended
        />
      </div>
    ));

  const disabledSelect = calendarData.planningTopicType && calendarData.planningTopicType.id === 8;

  const resultsModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <div className={css.TopicFilter}>
        <Row className={css['TopicFilter-row']}>
          <Col span={24}>
            <Row type='flex' align='middle' justify='space-between'>
              <Row type='flex' align='middle'>
                <Button selected={type === TopicType.Course} onClick={changeToCourse} type='primary' size='large'>
                  {t('courses')}
                </Button>
                <div className={css['TopicFilter-buttonMargin']}>
                  <Button selected={type === TopicType.Study} onClick={changeToStudy} type='primary' size='large'>
                    {t('materials')}
                  </Button>
                </div>
              </Row>
              <div>
                <Button className={css['TopicFilter-buttonResults']} onClick={resultsModal} type='primary' size='large'>
                  {t('results')}
                </Button>
                <ResultsModal
                  visible={modalOpen}
                  onOk={resultsModal}
                  currentRole={
                    currentUser && currentUser.career && currentUser.career.conventusRole
                      ? currentUser.career.conventusRole
                      : ''
                  }
                  currentUser={currentUser}
                />
              </div>
            </Row>
            <Row type='flex' align='middle' className={css['TopicFilter-bottomFilter']}>
              {getTags}
            </Row>
          </Col>
        </Row>
      </div>
      <div />
      <div>
        {isLoading ? (
          <div className={css['TopicFilter-spinner']}>
            <Spin size={'large'} />
          </div>
        ) : topicCards.length !== 0 ? (
          <div className={css['CollectionMaterials-material-cards']}>{topicCards}</div>
        ) : (
          <div className={css['TopicFilter-NegativeSearch']}>
            <p className={css['TopicFilter-NegativeSearch__text']}>{t('noResult')}</p>
            <p className={css['TopicFilter-NegativeSearch__text']}>{t('try')}</p>
            <Button
              className={css['TopicFilter-buttonResults']}
              type='primary'
              size='large'
              onClick={() => navigate('/collection-topics')}
            >
              {t('goTo')}
            </Button>
          </div>
        )}
      </div>

      <CalendarModal
        topicInfo={calendarData.topicInfo}
        comment={calendarData.topicInfo && calendarData.topicInfo.topicComment}
        eventTypeId={calendarData.planningTopicType && calendarData.planningTopicType.id}
        disabledSelect={!!disabledSelect}
        dateEvent={dayjs(calendarData.startDate).format('D MMMM YYYY') || ''}
        title={!disabledSelect ? t('selectPlan') : t('scheduleEvent')}
        open={calendarData.calendarModalVisible}
        onOk={handleStudyPlanClick}
        onCancel={handleCloseModal}
        okText={t('add')}
        {...(calendarData.planningTopicType &&
          calendarData.planningTopicType.id === 20 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
        {...(calendarData.planningTopicType &&
          calendarData.planningTopicType.id === 8 && {
            okText: t('addToPlan'),
            headerFixToModal: true,
          })}
        planningTopicId={calendarData.planningTopicId}
        hasEvents={(calendarData.topicInfo && calendarData.topicInfo.eventCount) || 0}
        selectMonthEvents={
          !!(
            calendarData.planningTopicType &&
            (isCorpCourseWithOrWithoutApprove(calendarData.planningTopicType.id) ||
              (calendarData.planningTopicType && calendarData.planningTopicType.id === 20))
          )
        }
      />
    </>
  );
};

const mapStateToProps = createSelector([usersSelect, selectDiagnostic, selectLogin], (users, diagnostic, login) => ({
  domainCompany: users.currentUser && users.currentUser.domainCompany,
  currentUser: users.currentUser,
  isLoading: diagnostic.isLoading,
  topics: diagnostic.topics,
  type: diagnostic.type,
  tags: diagnostic.tags,
  externalLogin: login.externalLogin,
  corpCourseBackground: users.corpCourseBackground,
}));

const mapActionsToProps = {
  getDiagnosticCourse,
  getDiagnosticStudy,
  getFile,
  createUserTopic,
  deleteUserTopic,
  changeValue,
  createStudyPlan,
};

Diagnostic.propTypes = {
  getDiagnosticCourse: PropTypes.func,
  getDiagnosticStudy: PropTypes.func,
  createUserTopic: PropTypes.func,
  deleteUserTopic: PropTypes.func,
  createStudyPlan: PropTypes.func,
  changeValue: PropTypes.func,
  getFile: PropTypes.func,
  domainCompany: PropTypes.any,
  externalLogin: PropTypes.string,
  currentUser: PropTypes.any,
  topics: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  tags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  corpCourseBackground: PropTypes.any,
};

export default connect(mapStateToProps, mapActionsToProps)(Diagnostic);
