/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Select } from 'antd';
import TypeBadge from '@/components/TypeBadge';

import { BarChartOutlined } from '../TypeBadge/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Select.module.scss';

const { Option } = Select;

class CustomSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    dataqa: PropTypes.string,
    contextSearch: PropTypes.bool,
    contextSearchActive: PropTypes.any,
    dark: PropTypes.bool,
    short: PropTypes.bool,
    disabled: PropTypes.bool,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    typeId: PropTypes.bool,
    typeSelect: PropTypes.bool,
    value: PropTypes.any,
    white: PropTypes.bool,
    noMargin: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
  };

  handleChange = value => {
    const { onChange, name } = this.props;
    if (value === '') {
      onChange({ name, value });
    } else {
      onChange({
        name,
        value:
          name === 'timezone'
            ? this.props?.options?.find(item => {
                return item.id === value;
              })
            : parseInt(value) || value,
      });
    }
  };

  // TODO: REFACTOR ICONS!
  getBadgeContent = type => {
    switch (type) {
      case 3:
        return (
          <span className={css.IconBack}>
            <svg xmlns='http://www.w3.org/2000/svg' width='9' height='12' viewBox='0 0 9 12'>
              <path
                d='M1.154 8.527h3.267v-5.12H1.154zM8.171.93l-3.75 2.476v5.12l3.75 2.386z'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
          </span>
        );
      case 16:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <path
                d='M10 14s-1.074-.906-2.75-.906c-1.677 0-2.75.906-2.75.906V6.906S5.407 6 7.25 6s2.75.906 2.75.906S10.907 6 12.75 6s2.75.906 2.75.906V14s-1.073-.906-2.75-.906C11.074 13.094 10 14 10 14Z'
                stroke='#2D3440'
              />
              <path fill='#2D3440' d='M9.5 7h1v7h-1z' />
            </g>
          </svg>
        );
      case 1:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
            <g fill='none'>
              <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
              <g transform='translate(1.5 2)' stroke='#2D3440'>
                <rect x='3.5' y='3.5' width='9' height='9' rx='4.5' />
                <path d='M7.7 3.603Q6.199 5.775 6.199 8.027t1.501 4.56m.6-8.984q1.537 2.2 1.537 4.45T8.3 12.587M3.41 8h9.112' />
              </g>
            </g>
          </svg>
        );
      case 17:
        return <TypeBadge typeId={17} onlyIcon />;
      case 8:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(5 5)'>
                <rect stroke='#2D3440' x='.5' y='.5' width='9' height='9' rx='1' />
                <path fill='#2D3440' d='M0 3h10v1H0z' />
              </g>
            </g>
          </svg>
        );
      case 5:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(2 2)' />
              <g transform='translate(6 5)' stroke='#2D3440'>
                <rect x='.5' y='.5' width='3' height='3' rx='1' />
                <rect x='5.5' y='.5' width='3' height='3' rx='1' />
                <rect x='.5' y='5.5' width='3' height='3' rx='1' />
                <rect x='5.5' y='5.5' width='3' height='3' rx='1' />
              </g>
            </g>
          </svg>
        );
      case 6:
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'>
            <g stroke='#2D3540' fill='none'>
              <path d='M1.142.5h10v8h-10z' />
              <path d='m1.642 8 9-7m0 7-9-7z' opacity='.6' />
            </g>
          </svg>
        );
      case 7:
        return (
          <span className={css.IconBack}>
            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 12 10'>
              <path
                d='M1.142.5h10v6h-10zM4.152 7l-1.51 2.396M8.142 7l1.6 2.396M6.142 7.5v2'
                stroke='#2D3540'
                fill='none'
              />
            </svg>
          </span>
        );
      case 15:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <path d='M5.455 7.333h9.09M5.455 10h9.09m-9.09 2.667h5.454' stroke='#2D3440' />
            </g>
          </svg>
        );
      case 20:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(2 2)' />
              <g stroke='#2D3440'>
                <path d='M13.17 9.185v3.953c0 .7-1.412 1.575-3.153 1.575s-3.153-.873-3.153-1.575V9.185' />
                <path d='M10.017 11.108 5.03 8.34l4.987-2.768 4.987 2.768z' />
              </g>
            </g>
          </svg>
        );
      case 4:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none'>
              <rect fill='#F6F6F6' width='20' height='20' rx='10' />
              <g transform='translate(2 2)' />
              <path
                d='M14.16 10.393a.45.45 0 0 0 0-.786l-5.941-3.3a.45.45 0 0 0-.669.393v6.6a.45.45 0 0 0 .669.394z'
                stroke='#2D3440'
              />
            </g>
          </svg>
        );
      case 23:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/1999/xlink'>
            <g fill='none'>
              <path d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0' fill='#F6F6F6' />
              <g transform='translate(1.5 2)'>
                <rect fill='#2D3440' x='8' y='11' width='1' height='4' rx='.5' />
                <rect stroke='#2D3440' x='6.5' y='2.5' width='4' height='7' rx='2' />
                <path d='M12.476 7.5H4.524c.222 2.259 1.928 4 3.976 4s3.754-1.741 3.976-4Z' stroke='#2D3440' />
              </g>
            </g>
          </svg>
        );
      case 25:
        return <BarChartOutlined />;
      default:
        break;
    }
  };

  render() {
    const {
      className,
      options,
      dark,
      white,
      modificate,
      typeId,
      contextSearch,
      contextSearchActive,
      typeSelect,
      short,
      disabled,
      noMargin,
      dataqa,
      value,
      size,
      ...rest
    } = this.props;
    const optionElements = options?.map(option => ({ label: option.name, value: option.id }));

    if (rest.value) {
      rest.value = rest.value.toString();
    }

    return (
      <div
        className={classNames(css.Select, className, {
          [css.dark]: dark,
          [css.white]: white,
          [css.noMargin]: noMargin,
          [css.modificate]: modificate,
          [css.contextSearch]: contextSearch,
          [css.contextSearchActive]: contextSearchActive,
          [css.disabled]: disabled,
          [css.short]: short,
        })}
      >
        <Select
          data-qa={`${dataqa}-dropdown`}
          disabled={disabled}
          options={optionElements}
          placeholder={this.props.placeholder || this.props.t('all')}
          onChange={this.handleChange}
          value={value}
          popupClassName={classNames(
            css['Select-dropdown'],
            { [css.dark]: dark },
            { [css.white]: white },
            { [css.modificate]: modificate },
            { [css.contextSearch]: contextSearch },
            { [css.contextSearchActive]: contextSearchActive }
          )}
          notFoundContent={null}
          size={size}
        ></Select>
      </div>
    );
  }
}

export default withTranslation()(CustomSelect);
