import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearMentorsFilters,
  getMentors,
  getMentorsCompany,
  getMentorsCompetence,
  getMentorsDepartments,
  getMentorsFunctions,
  saveMentorsFilters,
} from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import { Button, Col, Row, Select } from 'antd';
import { HiChevronDown } from 'react-icons/hi';

import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from '../Mentors.module.scss';

const { Option } = Select;

const Filters = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMentorsCompany());
    dispatch(getMentorsCompetence());
    dispatch(getMentorsDepartments());
    dispatch(getMentorsFunctions());
  }, [dispatch]);

  const { mentorsCompany, mentorsCompetence, mentorsDepartment, mentorsFunction } = useSelector(selectMentors);

  const [data, setData] = useState({
    companies: [],
    competences: {
      ids: [],
      names: [],
    },
    fullName: '',
    departments: [],
    functions: [],
  });

  const [isMobile] = useIsMobile(671);
  const [visibleFilterMenu, setVisibleFilterMenu] = useState(false);

  useEffect(() => {
    if (props?.fullName.length > 3) {
      setData({ ...data, fullName: props.fullName });
    } else if (props?.fullName.length === 0) {
      setData({ ...data, fullName: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fullName]);

  const resetFilters = () => {
    setData({
      companies: [],
      competences: {
        ids: [],
        names: [],
      },
      fullName: '',
      departments: [],
      functions: [],
    });
    props.onDeleteFullName();
    dispatch(clearMentorsFilters({}));
    dispatch(getMentors({}));
  };

  useEffect(() => {
    let copyData = _.cloneDeep(data);
    for (let key in copyData) {
      if (!copyData[key] || (copyData[key].hasOwnProperty('ids') && !copyData[key].ids)) {
        delete copyData[key];
      } else if (copyData[key]?.ids) {
        copyData[key] = copyData[key].ids;
      }
      if (Array.isArray(copyData[key]) && copyData[key].length === 0) {
        delete copyData[key];
      }
    }

    dispatch(saveMentorsFilters(copyData));
  }, [data]);

  const generateOptions = items => {
    return (
      items?.length &&
      items.map((item, index) => (
        <Option key={item} id={index}>
          {item}
        </Option>
      ))
    );
  };

  const optionCompany = generateOptions(mentorsCompany);
  const optionDepartment = generateOptions(mentorsDepartment);
  const optionFunction = generateOptions(mentorsFunction);

  const optionCompetence =
    mentorsCompetence?.length &&
    mentorsCompetence.map(item => {
      return (
        <Option key={item.id} value={item.name}>
          {item.name}
        </Option>
      );
    });

  const templateSelect = [
    {
      label: t('company'),
      value: data.companies,
      onChange: e => setData({ ...data, companies: e }),
      option: optionCompany,
      placeholder: t('placeholderCompnay'),
    },
    {
      label: t('competencies'),
      value: data.competences.names,
      onChange: (e, item) => {
        const ids = item?.map(el => +el.key);
        setData({ ...data, competences: { ids: ids, names: e } });
      },
      option: optionCompetence,
      placeholder: t('placeholderCompetencies'),
    },
    {
      label: t('department'),
      value: data.departments,
      onChange: e => setData({ ...data, departments: e }),
      option: optionDepartment,
      placeholder: t('placeholderDepartments'),
    },
    {
      label: t('function'),
      value: data.functions,
      onChange: e => setData({ ...data, functions: e }),
      option: optionFunction,
      placeholder: t('placeholderFunctions'),
    },
  ];

  const renderSelect = templateSelect.map((item, index) => {
    return (
      <Select
        mode='multiple'
        allowClear={true}
        showSearch={true}
        className={css['Filters-block-select']}
        onChange={item.onChange}
        value={item.value || undefined}
        placeholder={item.placeholder}
        size={'large'}
      >
        {item.option}
      </Select>
    );
  });

  const renderMobile = () => {
    return (
      <div className={css['Filters-block-menu']}>
        <div onClick={() => setVisibleFilterMenu(!visibleFilterMenu)} className={css['Filters-block-menu-header']}>
          {t('filters')}
          <HiChevronDown
            size={26}
            className={
              visibleFilterMenu
                ? classNames(css['Filters-block-menu-header-icon'], css['Filters-block-menu-header-icon--active'])
                : css['Filters-block-menu-header-icon']
            }
          />
        </div>
        <div
          className={
            visibleFilterMenu
              ? classNames(css['Filters-block-menu-main'], css['Filters-block-menu-main--active'])
              : css['Filters-block-menu-main']
          }
        >
          <Row className={css['Filters']}>
            <Row className={css['Filters-row']}>
              <Col className={css['Filters-desc']}>{t('filtersDesc')}</Col>
              <Button className={css['Filters-reset']} type='link' onClick={resetFilters}>
                {isMobile ? t('resetFiltersMobile') : t('resetFilters')}
              </Button>
            </Row>
            <Col className={css['Filters-block']}>{renderSelect}</Col>
          </Row>
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <Row className={css['Filters']}>
        <Row className={css['Filters-row']}>
          <Col className={css['Filters-desc']}>{t('filtersDesc')}</Col>
          <Col>
            <Button type='link' onClick={resetFilters} className={css['Filters-reset']}>
              {isMobile ? t('resetFiltersMobile') : t('resetFilters')}
            </Button>
          </Col>
        </Row>
        <Row className={css['Filters-row']}>{renderSelect}</Row>
      </Row>
    );
  };

  return isMobile ? renderMobile() : render();
};

Filters.propTypes = {
  onDeleteFullName: PropTypes.func,
  fullName: PropTypes.string,
};

export default Filters;
