import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Modal, Select } from 'antd';

import style from '../style.module.scss';

const AssessorsModal = ({ open, onCancel, assessors, endMaterial }) => {
  const [value, setValue] = useState(undefined);

  const { t } = useTranslation('assessorsModal');

  const onFinish = () => {
    endMaterial(false, value);
    onCancel();
  };

  const footer = (
    <div className={style['assessors-modal__buttons']}>
      <Button onClick={onCancel}>{t('cancel')}</Button>
      <Button type='primary' onClick={onFinish}>
        {t('send')}
      </Button>
    </div>
  );

  return (
    <Modal footer={footer} open={open} onCancel={onCancel} title={t('title')}>
      <Form.Item layout='vertical' label={t('selectLabel')}>
        <Select
          onSelect={setValue}
          value={value}
          placeholder={t('selectPlaceholder')}
          options={assessors?.map(({ id, name }) => ({ label: name, value: id }))}
        />
      </Form.Item>
    </Modal>
  );
};

export default AssessorsModal;
