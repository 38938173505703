/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Avatar, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css2 from '../FileInput/FileInput.module.scss';
import css from './AvatarInput.module.scss';

import 'react-image-crop/dist/ReactCrop.css';

import Modal from '../Modal';

import { COVER_IMAGE_TYPES } from '@shared/constants/image-types';

import galleryImage from '@/assets/images/gallery.png';

class AvatarInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    fileInfo: PropTypes.object,
    getPhoto: PropTypes.func,
    imageSrc: PropTypes.string,
    img: PropTypes.any,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    loadAvatarUser: PropTypes.func,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    overwriteValuesUser: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      previewImage: null,
      isOpen: false,
      imageUrl: null,
      crop: {
        x: 20,
        y: 10,
        width: 30,
        // height: 30,
        aspect: 1,
      },
      newImg: null,
      error: false,
      imageSrc: props.imageSrc,
      maskClosable: true,
      modalError: false,
      currentUser: this.props.user,
    };
  }

  componentDidMount() {
    if (this.props.user) {
      if (this.props.user.localPhotoUuid) {
        this.props.getPhoto(this.props.user.localPhotoUuid);
      } else if (this.props.user.photoUuid) {
        this.props.getPhoto(this.props.user.photoUuid);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isLoading !== this.state.loadImg) {
      setTimeout(() => {
        this.setState({ loadImg: newProps.isLoading });
      }, 300);
    }
    if (newProps.imageSrc !== this.props.imageSrc) {
      this.setState({ imageSrc: newProps.imageSrc });
    }
  }

  setInputRef = input => {
    const a = COVER_IMAGE_TYPES.join(', ');
    input && input.setAttribute('accept', a);
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  getImgSize = img => {
    const i = new Image();
    i.src = img;
    const that = this;
    i.onload = function () {
      that.setState({
        sizes: {
          naturalWidth: i.width,
          naturalHeight: i.height,
        },
      });
    };
  };

  onChange = evt => {
    evt.preventDefault();
    const file = evt.target.files[0];
    this.getImgSize(window.URL.createObjectURL(evt.target.files[0]));
    if (file) {
      if (!COVER_IMAGE_TYPES.includes(file.type) || file.size > 10000000) {
        this.setState({ modalError: true });
      } else {
        this.setState({
          image: window.URL.createObjectURL(evt.target.files[0]),
          imageUrl: evt.target.files[0],
          isOpen: true,
        });
      }
    }
    evt.target.value = null;
  };

  onChangeCrop = crop => {
    if (crop.width < 30) {
      this.setState({ maskClosable: false });
      return;
    }
    this.setState({
      maskClosable: false,
      crop,
      width: crop.width < 20 ? 20 : crop.width,
    });
  };

  onComplete = () => {
    setTimeout(() => {
      this.setState({ maskClosable: true });
    }, 200);
  };

  getCroppedImg = () => {
    this.setState({ error: false, loadImg: true });
    const pixelCrop = this.state.crop;
    const formData = new FormData();
    formData.append('file', this.state.imageUrl);
    const switchSize = pixelCrop.width > pixelCrop.height ? pixelCrop.width : pixelCrop.height;
    this.props.loadAvatarUser(
      formData,
      Math.round((this.state.sizes.naturalWidth * pixelCrop.x) / 100),
      Math.round((this.state.sizes.naturalHeight * pixelCrop.y) / 100),
      Math.round((this.state.sizes.naturalHeight * switchSize) / 100),
      Math.round((this.state.sizes.naturalHeight * switchSize) / 100),
      res => {
        if (this.props.overwriteValuesUser) {
          this.props.overwriteValuesUser(this.state.currentUser, {
            localPhotoUuid: res.uuid,
          });
        }
        this.props.getPhoto(res.uuid, true);
        this.setState({ loadImg: false });
        this.resetModal();
      },
      res => {
        if (res.status === 400) {
          this.setState({
            loadImg: false,
            error: this.props.t('error'),
          });
        }
      }
    );
  };

  resetModal = () =>
    this.setState({
      previewImage: null,
      isOpen: !this.state.isOpen,
      imageUrl: null,
      crop: {
        x: 20,
        y: 10,
        width: 30,
        // height: 30,
        aspect: 1,
      },
      newImg: null,
      error: false,
    });

  userAva = () => {
    return (
      <div className={css['app-profline-avatar']}>
        <img src={this.props.img} alt='' />
      </div>
    );
  };

  onCancel = () => {
    this.hideConfirmDialog();
    this.openFileDialog();
  };

  hideConfirmDialog = () => {
    this.setState({ modalError: false });
  };

  render() {
    const { className, t } = this.props;
    const content = (
      <div className={css['ImageInput-content']}>
        <div className={css['ImageInput-block']}>
          {this.state.imageSrc ? (
            <Avatar className={css['ImageInput-avatar']} src={this.state.imageSrc} />
          ) : (
            <div className={css['ImageInput-avatar']}>
              <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'>
                <g fill='#D1D1D1' opacity='.9'>
                  <path d='M41.568 14.292q-1.431-1.44-3.455-1.44l-4.622.062-.974-2.61q-.363-.94-1.327-1.622Q30.227 8 29.214 8H19.44q-1.013 0-1.976.682-.964.681-1.327 1.622l-.974 2.61h-4.276q-2.024 0-3.455 1.44Q6 15.792 6 17.826v17.26q0 2.034 1.432 3.473t3.456 1.44l27.225-.061q2.024 0 3.455-1.44T43 35.024V17.766q0-2.034-1.432-3.474m-11.2 18.209q-2.51 2.524-6.041 2.524t-6.042-2.524q-2.51-2.524-2.51-6.074 0-3.552 2.51-6.075t6.042-2.524q3.531 0 6.042 2.524t2.51 6.075q0 3.55-2.51 6.074z' />
                  <path d='M24.327 20.935q-2.272 0-3.885 1.622-1.614 1.621-1.613 3.906 0 2.283 1.613 3.906 1.613 1.62 3.885 1.621t3.885-1.621q1.613-1.623 1.613-3.906 0-2.285-1.613-3.906-1.613-1.623-3.885-1.622' />
                </g>
              </svg>
            </div>
          )}
        </div>
        <div className={css.ImageBlock}>
          <Button
            htmlFor='cover-img-file-input'
            className={css.uploadProfileImage}
            onClick={this.openFileDialog}
            disabled={this.props.isLoadingFile || this.props.isLoadingFileImage}
          >
            <DownloadOutlined />
            {t('loadImage')}
          </Button>
          <p className={css.ImageText}>{t('formats')}</p>
        </div>
      </div>
    );

    return (
      <div className={classNames(css.ImageInput, className)}>
        {content}
        <input type='file' id='cover-img-file-input' ref={this.setInputRef} onChange={this.onChange} />
        <Modal
          className={css['modal-content--closeEdit']}
          open={this.state.isOpen}
          onCancel={this.resetModal}
          closableControl
          headerFixToModal
          maskClosable={this.state.maskClosable}
          hideFooter
        >
          <div className={css['modal-content--box']}>
            <div className={css['modal-content__title']}>{t('changeImage')}</div>

            {this.state.isOpen && (
              <div className={css['modal-content__crop']}>
                <ReactCrop
                  src={this.state.image}
                  crop={this.state.crop}
                  onChange={this.onChangeCrop}
                  onComplete={this.onComplete}
                  onImageLoaded={this.onImageLoaded}
                  minWidth={30}
                  className={css.ReactCrop}
                  keepSelection
                />
              </div>
            )}
            {this.state.newImg && this.userAva()}
            <p className={css['modal-content__note']}>
              {' '}
              {this.state.error ? (
                <div className={css['modal-content__note--error']}>{this.state.error}</div>
              ) : (
                t('maxSize')
              )}
            </p>
            <div className={css['modal-content__footer']}>
              <Button disabled={this.state.loadImg} onClick={this.getCroppedImg}>
                {t('save')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          modificate
          fileModal
          open={this.state.modalError}
          onOk={this.onCancel}
          onCancel={this.hideConfirmDialog}
          okText={t('add')}
        >
          <div className={css2['App-card-label']}>{t('fileTypes')}</div>
          <div className={css2['App-card-info']}>
            <img src={galleryImage} alt='' />
            <div className={css2['App-card-info-item']}>
              <div className={css2['App-card-info-item-block']}>
                <h2>{t('image')}</h2>
                <div className={css2['App-card-info-little']}>{t('maxDescription')}</div>
              </div>
              <h3>{t('files')}</h3>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation('avatar')(AvatarInput);
