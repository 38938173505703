import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserIncome2 as getIncome } from '@/store/income/actions';
import { showSendReviewModal } from '@/store/topics/actions';
import { createUserTopicToMultipleUsers, sendNotification } from '@/store/user-topics/actions';
import { getUserById, getUsers } from '@/store/users/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import UsersModal from '@/components/UsersModal/UsersModal';

import { isCorpOrExtCourseOrConf } from '@shared/utils/topic-utils';

import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { FINISHED_TOPIC } from '@/constants/income-types';
import { RECOMMENDED } from '@/constants/user-topic-results';

export const Modals = ({ modal, onConfirm, onClose, open }) => {
  const dispatch = useDispatch();
  const studyPlanState = useSelector(selectStudyPlan);
  const currentUser = useSelector(selectCurrentUser);

  const { action, material } = modal;

  const topicTypeId = _get(material, 'topic.type.id');
  const topicId = _get(material, 'topic.id');

  useEffect(() => {
    if (open && action === 'recommend') {
      _getUsers();
    }
  }, [dispatch, open, action]);

  const _getUsers = (
    params = { size: 10, page: 0, status: 'ACTIVE' },
    onSuccess,
    onError,
    searching = false,
    isStartLoad = true
  ) => {
    dispatch(getUsers(params, onSuccess, onError, searching, isStartLoad));
  };

  switch (action) {
    case 'recommend':
      const onOk = userIds => {
        if (!userIds) {
          return;
        }
        const sendNotificationAboutTopics = topics => {
          onClose();
          if (topics.some(topic => topic.result === RECOMMENDED)) {
            dispatch(sendNotification(RECOMMENDED));
          }
          topics.forEach(topic => {
            if (topic.result !== RECOMMENDED) {
              const { firstName, lastName } = topic.userTopic.user;
              dispatch(sendNotification(topic.result, `${firstName} ${lastName}`));
            }
          });
        };

        dispatch(createUserTopicToMultipleUsers(userIds, topicId, sendNotificationAboutTopics));
      };

      const _getUsers = () =>
        dispatch(
          getUsers(
            { size: 10, page: 0 },
            () => {},
            () => {},
            false,
            true
          )
        );

      return <UsersModal visible={open} onCancel={onClose} onOk={onOk} getUsers={_getUsers} topic={material.topic} />;

    case 'review':
      if (open) {
        if (!isCorpOrExtCourseOrConf(topicTypeId)) {
          dispatch(
            showSendReviewModal(topicId, {
              callback: onConfirm,
              studyPlanId: material.id,
            })
          );
        } else {
          onClose();
          dispatch(
            showSendReviewModal(topicId, {
              studyPlanId: material.id,
              currentUserId: currentUser.id,
              activeMonth: dayjs().format('YYYY-MM-DD'),
              viewPlan: !studyPlanState.view,
              callback: () => {
                onConfirm();
                if (!currentUser?.domainCompany?.hasCoins) return;

                dispatch(
                  getIncome({
                    userId: currentUser.id,
                    topicId,
                    bonus: 'TOPIC_COMPLETE',
                    incomeType: FINISHED_TOPIC,
                    onSuccess: () => {
                      dispatch(getUserById(false, currentUser.id));
                    },
                  })
                );
              },
            })
          );
        }
      }
      return null;
    default:
      return null;
  }
};

Modals.propTypes = {
  modal: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};
