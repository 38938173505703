import { getUserDomainCompanyImages } from '@/store/users/actions';

import { getUserTopicPlan, getUserTopicPlanScroll } from './actions';

import studyPlanService from '@shared/services/study-plan-service';

import { call, put, takeEvery } from 'redux-saga/effects';

const getMeta = headers => ({
  total: +headers['x-total-elements'] || 0,
  pageSize: +headers['x-page-size'] || 0,
  currentPage: +headers['x-page-number'] || 0,
});

function* fetchUserTopicPlan({ payload }) {
  if (!payload.withoutLoading) {
    yield put(getUserTopicPlan.start(payload));
  }

  try {
    yield put(getUserDomainCompanyImages()); // QQ-478 (4)
    const fullResponse = !!payload.fullResponse;
    const response = yield call(studyPlanService.getUserTopicPlan, payload.params, {
      fullResponse,
    });

    yield put(
      getUserTopicPlan.success({
        name: payload.name,
        value: fullResponse ? { meta: getMeta(response.headers), data: response.data } : response,
      })
    );
  } catch {
    yield put(getUserTopicPlan.failure(payload));
  }
}

function* fetchUserTopicPlanScroll({ payload }) {
  yield put(getUserTopicPlanScroll.start(payload));
  try {
    const response = yield call(studyPlanService.getUserTopicPlan, payload.params, {
      fullResponse: true,
    });
    yield put(
      getUserTopicPlanScroll.success({
        name: payload.name,
        value: { meta: getMeta(response.headers), data: response.data },
      })
    );
  } catch {
    yield put(getUserTopicPlanScroll.failure(payload));
  }
}

// eslint-disable-next-line
export default [
  takeEvery(getUserTopicPlan.type, fetchUserTopicPlan),
  takeEvery(getUserTopicPlanScroll.type, fetchUserTopicPlanScroll),
];
