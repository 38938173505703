// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-ArWb2K.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-GRG7se .user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-label-saeBP0[data-value="Montserrat"]:before, .user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-ArWb2K.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-GRG7se .user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-item-FFfwRn[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-Montserrat-gqsvtL {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ-Se6zxM {
  flex-direction: column;
  align-items: center;
  padding: 26px 0;
  display: flex;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Layout-Gabe0A {
  min-height: 650px;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Title-ToeHdt {
  font-size: 32px !important;
  font-weight: 800 !important;
  line-height: 36px !important;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_SubTitle-ZRr4_P {
  margin-top: 24px;
  text-align: center !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29px !important;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row-fVqXmD {
  text-align: center;
  width: 650px;
  margin-bottom: 24px;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Image-MEBjUG {
  width: 500px;
  margin: 48px auto 2rem;
  display: block;
}

.user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Label-cOenAD {
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/DiagnosticTalentQ/Welcome/welcome.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAMA;EAHE,iBAAiB;AACnB;;AAKA;EAFE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAIA;EADE,gBAAgB;EAChB,6BAA6B;EAC7B,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAGA;EAAE,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,cAAc;AAChB;;AACA;EAEE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.DiagnosticTalentQ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 26px 0;\n}\n.DiagnosticTalentQ_Layout {\n  min-height: 650px;\n}\n.DiagnosticTalentQ_Title {\n  font-size: 32px !important;\n  font-weight: 800 !important;\n  line-height: 36px !important;\n}\n.DiagnosticTalentQ_SubTitle {\n  margin-top: 24px;\n  font-size: 24px !important;\n  line-height: 29px !important;\n  text-align: center !important;\n  font-weight: 500 !important;\n}\n.DiagnosticTalentQ_Row {\n  text-align: center;\n  width: 650px;\n  margin-bottom: 24px;\n}\n.DiagnosticTalentQ_Row_Image {\n  margin: 48px auto 2rem;\n  width: 500px;\n  display: block;\n}\n.DiagnosticTalentQ_Row_Label {\n  height: 40px;\n  font-size: 16px;\n  font-weight: 300;\n  line-height: 1.5;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-ArWb2K`,
	"qlPicker": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-ArWb2K`,
	"ql-font": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-GRG7se`,
	"qlFont": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-GRG7se`,
	"ql-picker-label": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-label-saeBP0`,
	"qlPickerLabel": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-label-saeBP0`,
	"ql-picker-item": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-item-FFfwRn`,
	"qlPickerItem": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-picker-item-FFfwRn`,
	"ql-font-Montserrat": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-Montserrat-gqsvtL`,
	"qlFontMontserrat": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__ql-font-Montserrat-gqsvtL`,
	"DiagnosticTalentQ": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ-Se6zxM`,
	"diagnosticTalentQ": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ-Se6zxM`,
	"DiagnosticTalentQ_Layout": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Layout-Gabe0A`,
	"diagnosticTalentQLayout": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Layout-Gabe0A`,
	"DiagnosticTalentQ_Title": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Title-ToeHdt`,
	"diagnosticTalentQTitle": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Title-ToeHdt`,
	"DiagnosticTalentQ_SubTitle": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_SubTitle-ZRr4_P`,
	"diagnosticTalentQSubTitle": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_SubTitle-ZRr4_P`,
	"DiagnosticTalentQ_Row": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row-fVqXmD`,
	"diagnosticTalentQRow": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row-fVqXmD`,
	"DiagnosticTalentQ_Row_Image": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Image-MEBjUG`,
	"diagnosticTalentQRowImage": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Image-MEBjUG`,
	"DiagnosticTalentQ_Row_Label": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Label-cOenAD`,
	"diagnosticTalentQRowLabel": `user-src-pages-DiagnosticTalentQ-Welcome-welcome-module__DiagnosticTalentQ_Row_Label-cOenAD`
};
export default ___CSS_LOADER_EXPORT___;
