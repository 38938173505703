/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { D360LoadProject } from '@/store/d360/Project/actions';
import { D360Vote, D360VoteUpdate } from '@/store/d360/Vote/actions';

import { Button, Col, Pagination, Row } from 'antd';

import { Content } from '../../../components/Layout';
import { isArray, isBool, isNum, isObject, isString } from '../utils';
import CriteriaCard from './components/CriteriaCard';
import MeasurableCard from './components/MeasurableCard';
import ProgressCard from './components/ProgressCard';
import Success from './components/Success';

import css from '../css/styles.module.scss';

export const Survey = props => {
  const dispatch = useDispatch();
  const survey = useSelector(state => state.d360Project);

  const [projectId, setProjectId] = useState(undefined);
  const [projectName, setProjectName] = useState('');
  const [questionCount, setQuestionCount] = useState(0);
  const [answerCount, setAnswerCount] = useState(0);
  const [remainingQuestions, setRemainingQuestions] = useState([]);
  const [remainingQuestionIndexes, setRemainingQuestionIndexes] = useState([]);

  const [measurables, setMeasurables] = useState([]);
  const [remainingMeasurables, setRemainingMeasurables] = useState([]);
  const [currentMeasurable, setCurrentMeasurable] = useState(undefined);
  const [measurableCounter, setMeasurableCounter] = useState(0);
  const [isFinishedMeasurable, setIsFinishedMeasurable] = useState(false);

  const [criteria, setCriteria] = useState([]);
  const [currentCriterion, setCurrentCriterion] = useState(undefined);

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [isViewMode, setViewMode] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const calculateNextQuestion = () => {
    if (remainingQuestions[0]) {
      const currentQuestionIndex = survey.project.criteria.findIndex(e => e.id === remainingQuestions[0].id);
      onView(currentQuestionIndex + 1);
    } else {
      onView(1);
    }
  };

  const successVote = (response, isCreated) => {
    const currentQuestionIndex = remainingQuestions.findIndex(e => e.id === currentCriterion.id);
    const currentPaginationIndex = survey.project.criteria.findIndex(e => e.id === currentCriterion.id);

    let newResult = result;
    const newRemainingQuestions = remainingQuestions;

    if (isCreated) {
      newResult = [...result, response.data];
      setResult(curRes => [...curRes, response.data]);
      setRemainingQuestionIndexes([...remainingQuestionIndexes, currentPaginationIndex + 1]);
      newRemainingQuestions.splice(currentQuestionIndex, 1);
      setRemainingQuestions(newRemainingQuestions);
    } else {
      const indexUpdateResult = result.findIndex(e => e.id === response.data.id);
      newResult[indexUpdateResult] = {
        ...newResult[indexUpdateResult],
        comment: response.data.comment,
        d360_score_id: response.data.d360_score_id,
      };
      const updateResult = newResult;
      setResult(updateResult);
    }

    if (newRemainingQuestions.length === 0) {
      const findNextMeasurable = measurables.findIndex(m =>
        survey.project.criteria.some(
          c => !newResult.some(r => c.id === r.d360_criteria_id && m.id === r.d360_measurable_id)
        )
      );

      const newRemainingMeasurables = remainingMeasurables;
      const spliceIndex = remainingMeasurables.findIndex(e => e.id === currentMeasurable.id);

      if (spliceIndex !== -1) {
        newRemainingMeasurables.splice(spliceIndex, 1);

        setRemainingMeasurables(newRemainingMeasurables);
      }

      if (findNextMeasurable !== -1) {
        setMeasurableCounter(findNextMeasurable);
        setCurrentMeasurable(measurables[findNextMeasurable]);
      }
    }

    nextQuestion(isCreated);
  };

  const onCriteriaCardChange = (scaleId, scoreId, comment) => {
    if (remainingQuestions.some(e => e.id === currentCriterion.id)) {
      dispatch(
        D360Vote(projectId, currentMeasurable.id, currentCriterion.id, scaleId, scoreId, comment, response =>
          successVote(response, true)
        )
      );
    } else {
      dispatch(
        D360VoteUpdate(projectId, currentMeasurable.id, currentCriterion.id, scaleId, scoreId, comment, response =>
          successVote(response, false)
        )
      );
    }
  };

  const onView = index => {
    setPageNumber(index);
    setCurrentCriterion(criteria[index - 1]);
  };

  const nextQuestion = isCreated => {
    const currentQuestion = remainingQuestions.findIndex(e => e.id === currentCriterion.id);

    if (isCreated) {
      const countNextAnswerCount = answerCount + 1;

      if (countNextAnswerCount === survey.project.respondentQuestionCount) {
        setIsFinished(true);
      }

      if (countNextAnswerCount === survey.project.respondentQuestionCount - 1) {
        setIsFinishedMeasurable(true);
      }

      setAnswerCount(countNext => countNext + 1);
    }

    if (currentQuestion !== -1) {
      const newRemainingQuestions = remainingQuestions.filter(e => e.id !== currentCriterion.id);
      setRemainingQuestions(newRemainingQuestions);
    }

    calculateNextQuestion();
  };

  useEffect(() => {
    if (survey?.project?.criteria) {
      calculateNextQuestion();
      setLoading(false);
    }
  }, [remainingQuestions]);

  useEffect(() => {
    if ('project' in survey && isObject(survey.project)) {
      const project = survey.project;

      setCriteria(project.criteria);

      const remainingQuestions = project.criteria.filter(
        e => !result.some(p => e?.id === p.d360_criteria_id && currentMeasurable?.id === p.d360_measurable_id)
      );

      setRemainingQuestions(remainingQuestions);
      setQuestionCount(project.respondentQuestionCount);

      if (survey.project.respondentQuestionCount - 1 === survey.project.respondentProjectResultCount) {
        setIsFinishedMeasurable(true);
      }

      if (project?.respondentProjectStatus) {
        const isCurrentViewMode = project.respondentProjectStatus === 'has_finished';
        setViewMode(isCurrentViewMode);
      }

      //fillable
      const initRemQIndex = project.criteria.reduce((acc, cur, index) => {
        const curIndex = result.some(
          p => cur.id === p.d360_criteria_id && currentMeasurable.id === p.d360_measurable_id
        );
        return curIndex ? [...acc, index + 1] : acc;
      }, []);

      setRemainingQuestionIndexes(initRemQIndex);
    }
  }, [currentMeasurable]);

  // Parse project
  useEffect(() => {
    if (isObject(survey)) {
      if ('loading' in survey && isBool(survey.loading)) {
        setLoading(survey.loading);
        if (survey.loading === true) return;
      }

      if ('project' in survey && isObject(survey.project)) {
        const project = survey.project;

        if ('name' in project && isString(project.name)) {
          setProjectName(project.name);
        }

        if ('measurables' in project && isArray(project.measurables)) {
          setMeasurables(project.measurables);

          const findNextMeasurable = survey.project.measurables.findIndex(m =>
            survey.project.criteria.some(
              c => !survey.project.result.some(r => c.id === r.d360_criteria_id && m.id === r.d360_measurable_id)
            )
          );

          const currentRemainingMeasurables = survey.project.measurables.filter(m =>
            survey.project.criteria.some(
              c => !survey.project.result.some(r => c.id === r.d360_criteria_id && m.id === r.d360_measurable_id)
            )
          );

          setRemainingMeasurables(currentRemainingMeasurables);

          setResult(project.result);
          setAnswerCount(survey.project.respondentProjectResultCount);

          if (findNextMeasurable !== -1) {
            setMeasurableCounter(findNextMeasurable);
            setCurrentMeasurable(survey.project.measurables[findNextMeasurable]);
          } else {
            setMeasurableCounter(0);
            setCurrentMeasurable(survey.project.measurables[0]);
          }
        }
      }
    }
  }, [survey]);

  // Load project
  useEffect(() => {
    const projectId = Number.parseInt(props.params.id, 10);
    if (typeof projectId === 'number' && projectId > 0) {
      setProjectId(projectId);
      dispatch(D360LoadProject(projectId));
    }
  }, [props.params.id]);

  return (
    <Content>
      <Row gutter={[32, 32]}>
        <Col span={8}>
          <ProgressCard questionCount={questionCount} answerCount={answerCount} title={projectName} loading={loading} />

          <MeasurableCard
            loading={loading}
            measurables={measurables}
            remainingMeasurables={remainingMeasurables}
            current={measurableCounter}
            onChange={e => {
              setMeasurableCounter(e);
              setCurrentMeasurable(survey.project.measurables[e]);
            }}
          />
        </Col>

        {isFinished === false && (
          <Col span={16}>
            <CriteriaCard
              loading={loading}
              measurable={currentMeasurable}
              measurableCount={measurables.length}
              criteria={currentCriterion}
              result={result}
              isLastQuestion={remainingQuestions?.length === 1}
              isFinishedMeasurable={isFinishedMeasurable}
              isViewMode={isViewMode}
              onChange={onCriteriaCardChange}
            />

            <Col span={24} align='center'>
              <div className={css.Pagination}>
                <Pagination
                  current={pageNumber}
                  pageSize={1}
                  total={survey?.project?.criteria.length}
                  onChange={e => onView(e)}
                  showSizeChanger={false}
                  disabled={loading}
                  itemRender={(page, type, originalElement) => {
                    if (type === 'prev' || type === 'next') return undefined;

                    return (
                      <span className={remainingQuestionIndexes.includes(page) ? 'active' : ''}>{originalElement}</span>
                    );
                  }}
                  responsive
                  hideOnSinglePage
                />
              </div>
            </Col>
          </Col>
        )}

        {isFinished === true && (
          <Col span={16}>
            <Success />
          </Col>
        )}
      </Row>
    </Content>
  );
};

export default Survey;
