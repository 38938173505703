/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';

import PropTypes from 'prop-types';

export class FieldItemFileItem extends Component {
  static propTypes = {
    getFile: PropTypes.func,
    name: PropTypes.string,
    uuid: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      fileSrc: null,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.uuid) {
      this.props.getFile(this.props.uuid, res => {
        this.setState({ fileSrc: URL.createObjectURL(res) });
      });
    }
  }

  exportFiles = () => {
    if (this.props.uuid) {
      this.props.getFile(this.props.uuid, res => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, this.props.name);
        }
        const objectURL = URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        tempLink.style = 'display: none';
        tempLink.href = objectURL;
        tempLink.download = this.props.name;
        document.body.appendChild(tempLink);
        tempLink.click();
        setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(objectURL);
        }, 1000);
      });
    }
  };

  render() {
    return (
      <div>
        <div onClick={this.exportFiles} className={css['Details-topic-file']}>
          {this.props.name}
          <svg xmlns='http://www.w3.org/2000/svg' width='8' height='9' viewBox='0 0 8 9'>
            <path d='m1 4.5 2.86 2.86L6.72 4.5m-2.97 2V1.444' stroke='currentColor' fill='none' />
          </svg>
        </div>
      </div>
    );
  }
}
export default FieldItemFileItem;
