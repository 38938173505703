import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getMenteesRequests } from '@/store/mentoring_profile/actions';

import { selectCurrentUser } from '@/store/users/selectors';

import { Button, notification, Spin, Typography } from 'antd';
import MentorRequestModal from '@/components/Mentoring/MentorRequestModal';
// Иконки меторинга
import { HiOutlineAcademicCap, HiOutlineBadgeCheck, HiOutlineClock, HiOutlineXCircle } from 'react-icons/hi';

import MenteesRequests from './MenteesRequests.jsx';

import { selectMentoring } from '@/store/mentoring_profile/selector';

import css from './MentorTab.module.scss';

const MentorTab = () => {
  const { t } = useTranslation('MentorTab');
  const { Text } = Typography;
  const [visible, setVisible] = useState(false);
  const [filterStatus, setFilterStatus] = useState([]);
  const { canBeMentor, status, isError, isLoading, menteesRequests, currentMentor } = useSelector(selectMentoring);
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  // При ошибке запроса на менторинг - возникает уведомление для пользователя
  useEffect(() => {
    isError &&
      notification.error({
        message: t('error.message'),
        description: t('error.description'),
        placement: 'bottomLeft',
      });
  }, [isError, t]);

  useEffect(() => {
    status === 'ACTIVE' && dispatch(getMenteesRequests(filterStatus));
  }, [status, dispatch, filterStatus]);

  const mentorStatusRender = () => {
    switch (true) {
      case status === 'PENDING':
        return (
          <>
            <HiOutlineClock color='var(--personalColor)' size={120} className={css['MentorTab-image']} />
            <Text strong className={css['MentorTab-text']}>
              {t('inModirateMentorRequest')}
            </Text>
          </>
        );
      case status === 'ACTIVE':
        return currentMentor?.availableSlots !== currentMentor?.maxSlots ||
          currentMentor?.completedMenteesCount ||
          currentMentor?.activeMenteesCount ? (
          <MenteesRequests
            requests={menteesRequests}
            setFilterStatus={status => setFilterStatus(status)}
            filterStatus={filterStatus}
            isLoading={isLoading}
          />
        ) : (
          <>
            <HiOutlineBadgeCheck color='var(--personalColor)' size={120} className={css['MentorTab-image']} />
            <Text strong className={css['MentorTab-text']}>
              {t('successMentorRequest.0')} <br /> {t('successMentorRequest.1')}
            </Text>
          </>
        );
      case status === 'REJECTED':
        return (
          <>
            <HiOutlineXCircle color='var(--personalColor)' size={120} className={css['MentorTab-image']} />
            <Text strong className={css['MentorTab-text']}>
              {t('failMentorRequest.0')} <br />{' '}
              {t('failMentorRequest.1', {
                reason: currentMentor?.rejectReason,
              })}
            </Text>
            <Button loading={isLoading} onClick={() => setVisible(true)} type='primary' size='large'>
              {t('makeRequest')}
            </Button>
          </>
        );
      case canBeMentor === true:
        return (
          <>
            <HiOutlineAcademicCap color='var(--personalColor)' size={120} className={css['MentorTab-image']} />
            <Text strong className={css['MentorTab-text']}>
              {currentUser?.mentorStatus === 'INACTIVE' ? t('mentorRequestInactive') : t('makeMentorRequest')}
            </Text>
            <Button
              className={css['MentorTab-btn']}
              loading={isLoading}
              onClick={() => setVisible(true)}
              type='primary'
              size='large'
            >
              {t('makeRequest')}
            </Button>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <MentorRequestModal open={visible} onClose={() => setVisible(false)} />
      <Spin spinning={isLoading} className={css['MentorTab-content']} size={'large'}>
        <div className={css['MentorTab-container']}>{mentorStatusRender()}</div>
      </Spin>
    </>
  );
};

export default MentorTab;
