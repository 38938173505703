import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { Layout, Typography } from 'antd';
import TabsRouting from '@/components/TabsRouting';

import css from './MyCollection.module.scss';

const { Content } = Layout;
const { Title } = Typography;

const MyCollection = () => {
  const { t } = useTranslation('collectionMaterials');
  const location = useLocation();
  const [placement, setPlacement] = useState(location.pathname.replace('/my-collection', '') || '/materials');

  return (
    <Content className={css['App-content']}>
      <div className={css['MyCollection-header-row']}>
        <div className={css['MyCollection-header-text']}>
          <Title level={1}>{t('favorite')}</Title>
        </div>

        <TabsRouting
          basePage='/my-collection'
          tabsList={[
            {
              url: '/materials',
              name: t('materials'),
            },
            {
              url: '/compilations',
              name: t('compilations'),
            },
          ]}
          placement={placement}
          setPlacement={setPlacement}
          myCollection
          noArrows
        />
        <div>
          <Outlet />
        </div>
      </div>
    </Content>
  );
};

export default MyCollection;
