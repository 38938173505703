import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { changeTopicFilter, getTopics } from '@/store/topics/actions';
import { checkTest, getCareerList, getTest, resetTest, setCareer, testError } from '@/store/trajectory/actions';
import { getUser, hideTrajectoryModal } from '@/store/users/actions';

import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import selectTrajectory from '@/store/trajectory/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Col, Form, Row, Select, Spin } from 'antd';
import Modal from '@/components/Modal';

import doc from './img/Doc.svg';
import spin from './img/Refresh.svg';
import star from './img/Star.svg';

import useIsMobile from '@shared/hooks/useIsMobile';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TrajectoryModal.module.scss';

import final from '@/assets/images/trajectory2.svg';
import refresh from '@/assets/images/trajectory3.svg';

export const TestStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROCESS: 'IN_PROCESS',
  FINISHED: 'FINISHED',
};

const TrajectoryModal = ({
  isTrajectoryModalVisible,
  hideTrajectoryModal,
  getTest,
  checkTest,
  setCareer,
  link,
  isLoading,
  disableButton,
  testData,
  testError,
  member,
  career,
  isCareerSet,
  getCareerList,
  careerList,
  userId,
  getUser,
  hasUserTrajectory,
  resetTest,
}) => {
  const { t } = useTranslation('trajectoryModal');
  const [careerName, setCareerName] = useState();
  const [formError, setFormError] = useState('');
  const [refreshShow, setRefreshShow] = useState(false);
  const [careerOptions, setcareerOptions] = useState([]);
  const [isMobile] = useIsMobile(640);
  let modal;

  useEffect(() => {
    if (!link) {
      getTest();
    }
  }, [getTest, link]);

  useEffect(() => {
    if (testData && testData.status !== TestStatus.FINISHED) {
      testError();
    }
  }, [testData, testError]);

  useEffect(() => {
    if (!career && userId && hasUserTrajectory) {
      getCareerList();
    }
  }, [career, getCareerList, hasUserTrajectory, userId]);

  useEffect(() => {
    if (careerList) {
      setcareerOptions(
        careerList.map(item => ({
          id: item.id,
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [careerList]);

  const handleCareerChange = value => {
    setCareerName(value);
    setFormError('');
  };

  const handleCareerSearch = (value, option) => option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0;

  const handleCareer = () => {
    setFormError('');
    if (careerName) {
      setCareer(careerName.key);
    } else {
      setFormError(t('choose'));
    }
  };

  const handleClose = () => {
    hideTrajectoryModal();
    getUser();
  };

  const testResScreen = (
    <>
      <h1 className={css.TrajectoryModal__title}>{t('require')}</h1>
      <div className={classNames(css.TrajectoryModal__text, css.TrajectoryModal__text_gray)}>
        {t('recommendations')}
      </div>

      <Form className={css.TrajectoryModal__form}>
        <Form.Item
          colon={false}
          className={classNames(css['TrajectoryModal__form-item'], {
            [css.TrajectoryModal__error]: formError,
          })}
          help={formError}
        >
          <Select
            name='career'
            allowClear
            showSearch
            placeholder={t('type')}
            notFoundContent={t('noResults')}
            onChange={handleCareerChange}
            filterOption={handleCareerSearch}
            value={careerName}
            options={careerOptions?.map(option => ({ label: option.label, value: option.value, key: option.value }))}
            className={css.TrajectoryModal__select}
            labelInValue
            disabled={isLoading}
          />
        </Form.Item>
        <div className={css.TrajectoryModal__form__btn}>
          <Button
            className={classNames(css.TrajectoryModal__btn, css.TrajectoryModal__btn_mt)}
            onClick={handleCareer}
            disabled={disableButton}
            type='primary'
            size='large'
          >
            {t('continue')}
          </Button>
        </div>
      </Form>
    </>
  );

  const closeModal = () => {
    setRefreshShow(false);
    window.open(link, '_blank');
  };

  const refreshScreen = (
    <>
      <h1 className={css.TrajectoryModal__title}>{t('tooltip')}</h1>
      <Row align='middle' className={css.TrajectoryModal__refresh} justify='space-between'>
        {!isMobile && (
          <Col span={10}>
            <img src={refresh} alt={t('refresh')} />
          </Col>
        )}
        <Col span={isMobile ? 30 : 14}>
          <div className={classNames(css.TrajectoryModal__text_refresh, css.TrajectoryModal__text_left)}>
            {t('afterEnd')}
            <strong> {t('diagnostic')}</strong>
            {t('button')}
            <strong>{t('checkResults')}</strong> {t('end')}
          </div>
        </Col>
      </Row>
      <div className={css.TrajectoryModal__text_desc}>
        <div>{t('description')}</div>
      </div>
      <Button
        className={css.TrajectoryModal__btn}
        onClick={closeModal}
        target='_blank'
        rel='noopener noreferrer'
        disabled={disableButton}
        type='primary'
        size='large'
      >
        {t('startTest')}
      </Button>
    </>
  );

  const handleHide = () => {
    if (modal === testResScreen) {
      return;
    }
    if (modal === refreshScreen) {
      setRefreshShow(false);
      return;
    }
    hideTrajectoryModal();
    resetTest();
  };

  const firstScreen = (
    <div className={css.FirstScreenContainer}>
      <h2 className={css.TrajectoryModal__title}>{t('andTest')}</h2>
      <h2 className={css.TrajectoryModal__title}>{t('professionalSkills')}</h2>
      <div className={css.FirstScreenContainer__row}>
        <div className={css.FirstScreenContainer__col}>
          <img className={css.FirstScreenContainer__image} src={doc} alt='doc' />
          <div className={css.TrajectoryModal__label}>{t('response')}</div>
        </div>
        <div className={css.FirstScreenContainer__col}>
          <img className={css.FirstScreenContainer__image} src={spin} alt='spin' />
          <div className={css.TrajectoryModal__label}>{t('results')}</div>
        </div>
        <div className={css.FirstScreenContainer__col}>
          <img className={css.FirstScreenContainer__image} src={star} alt='star' />
          <div className={css.TrajectoryModal__label}>{t('recommendation')}</div>
        </div>
      </div>
      <Row className={css.FirstScreenContainer__rowBtn}>
        <Button
          className={css.TrajectoryModal__btn}
          onClick={() => setRefreshShow(true)}
          target='_blank'
          rel='noopener noreferrer'
          disabled={disableButton}
          type='primary'
          size='large'
        >
          {t('endTest')}
        </Button>
        <div className={css.FirstScreenContainer__label}>{t('backTo')}</div>
      </Row>
      <Row className={css.FirstScreenContainer__rowBtn}>
        <Button
          className={css.TrajectoryModal__btn}
          onClick={checkTest}
          disabled={disableButton}
          type='primary'
          size='large'
        >
          {t('checkResults2')}
        </Button>
        <div className={css.FirstScreenContainer__label}>{t('testComplete')}</div>
      </Row>
    </div>
  );

  const finalScreen = (
    <>
      <h1 className={css.TrajectoryModal__title}>{t('done')}</h1>
      <div className={css.TrajectoryModal__text}>
        {t('specialForYou')}
        <br /> {t('special')}
      </div>
      <img className={css.TrajectoryModal__finalImg} src={final} alt={t('recommendation2')} />
      <div className={css.TrajectoryModal__text}>{t('now')}</div>
      <Button className={css.TrajectoryModal__btn} onClick={handleClose} type='primary' size='large'>
        {t('show')}
      </Button>
    </>
  );

  let modalWidth = 604;
  if (refreshShow) {
    modal = refreshScreen;
    modalWidth = 800;
  } else if (isCareerSet || (testData && testData.status === TestStatus.FINISHED && career)) {
    modal = finalScreen;
  } else if (
    (testData && testData.status === TestStatus.FINISHED) ||
    (member && member.count > 0 && !career && !link)
  ) {
    modal = testResScreen;
  } else {
    return firstScreen;
  }

  return (
    <Modal
      className={css.TrajectoryModal}
      open={isTrajectoryModalVisible}
      width={modalWidth}
      hideFooter
      zIndex={1005}
      onCancel={handleHide}
      modificate
      closeButton={modal === testResScreen}
    >
      <div className={css.TrajectoryModalContainer}>
        {isLoading && (
          <div className={css.TrajectoryModal__loader}>
            <Spin />
          </div>
        )}

        <div className={css.TrajectoryModal__finalModal}>{modal}</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = createSelector([usersSelect, selectTrajectory, topicsSelect], (users, trajectory, topics) => ({
  isTrajectoryModalVisible: users.isTrajectoryModalVisible,
  link: trajectory.test.url,
  isLoading: trajectory.isCareerLoading,
  disableButton: trajectory.isLoading,
  testData: trajectory.testData,
  isCareerSet: trajectory.isCareerSet,
  member: users.currentUser.member,
  userId: users.currentUser.id,
  career: users.currentUser.career,
  hasUserTrajectory: users.currentUser.domainCompany.hasUserTrajectory,
  topicFilter: topics.topicFilter,
  careerList: trajectory.careerList,
}));

const mapActionsToProps = {
  hideTrajectoryModal,
  getTest,
  checkTest,
  testError,
  setCareer,
  getTopics,
  changeTopicFilter,
  getCareerList,
  getUser,
  resetTest,
};

TrajectoryModal.propTypes = {
  isTrajectoryModalVisible: PropTypes.bool,
  hideTrajectoryModal: PropTypes.func,
  getTest: PropTypes.func,
  checkTest: PropTypes.func,
  testError: PropTypes.func,
  getCareerList: PropTypes.func,
  getUser: PropTypes.func,
  resetTest: PropTypes.func,
  getTopics: PropTypes.func,
  changeTopicFilter: PropTypes.func,
  setCareer: PropTypes.func,
  link: PropTypes.string,
  isLoading: PropTypes.bool,
  disableButton: PropTypes.bool,
  testData: PropTypes.any,
  member: PropTypes.any,
  career: PropTypes.any,
  isCareerSet: PropTypes.bool,
  topicFilter: PropTypes.object,
  careerList: PropTypes.array,
  userId: PropTypes.number,
  hasUserTrajectory: PropTypes.bool,
};

export default connect(mapStateToProps, mapActionsToProps)(TrajectoryModal);
