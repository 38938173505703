// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-Footer-Footer-module__ql-picker-O4vw78.user-src-pages-Footer-Footer-module__ql-font-Xkn_rZ .user-src-pages-Footer-Footer-module__ql-picker-label-JjpqJH[data-value="Montserrat"]:before, .user-src-pages-Footer-Footer-module__ql-picker-O4vw78.user-src-pages-Footer-Footer-module__ql-font-Xkn_rZ .user-src-pages-Footer-Footer-module__ql-picker-item-OMx7qg[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-Footer-Footer-module__ql-font-Montserrat-JM3kWv {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-Footer-Footer-module__App-footer-MJqby8 {
  background-color: #f5f5f5;
  padding: 0;
}

.user-src-pages-Footer-Footer-module__App-footer-menu-fIDVRa {
  flex-wrap: wrap;
  max-width: 1360px;
  min-height: 100px;
  margin: auto;
  padding: 40px 60px;
  display: flex;
}

@media (max-width: 992px) {
  .user-src-pages-Footer-Footer-module__App-footer-menu-fIDVRa {
    flex-direction: column;
    padding: 40px 3%;
  }
}

.user-src-pages-Footer-Footer-module__App-footer-menu-item-fYmIHJ {
  color: #2d3540;
  margin-right: 50px;
  font-size: 13px;
  font-weight: 500;
  line-height: 40px;
}

.user-src-pages-Footer-Footer-module__App-footer-menu-item-fYmIHJ:hover {
  color: var(--personalColor);
}

@media (max-width: 992px) {
  .user-src-pages-Footer-Footer-module__App-footer-menu-item-fYmIHJ {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/Footer/Footer.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,yBAAyB;EACzB,UAAU;AACZ;;AAOA;EAJE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAMA;EACE;IAHE,sBAAsB;IACtB,gBAAgB;EAClB;AACF;;AAKA;EAFE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAIA;EADE,2BAA2B;AAC7B;;AAGA;EACE;IAAE,aAAa;EACf;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.App-footer {\n  padding: 0;\n  background-color: #f5f5f5;\n}\n.App-footer-menu {\n  min-height: 100px;\n  padding: 40px 60px;\n  display: flex;\n  flex-wrap: wrap;\n  max-width: 1360px;\n  margin: auto;\n}\n@media (max-width: 992px) {\n  .App-footer-menu {\n    padding: 40px 3%;\n    flex-direction: column;\n  }\n}\n.App-footer-menu-item {\n  color: #2d3540;\n  font-size: 13px;\n  line-height: 40px;\n  font-weight: 500;\n  margin-right: 50px;\n}\n.App-footer-menu-item:hover {\n  color: var(--personalColor);\n}\n@media (max-width: 992px) {\n  .App-footer-menu-item {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-Footer-Footer-module__ql-picker-O4vw78`,
	"qlPicker": `user-src-pages-Footer-Footer-module__ql-picker-O4vw78`,
	"ql-font": `user-src-pages-Footer-Footer-module__ql-font-Xkn_rZ`,
	"qlFont": `user-src-pages-Footer-Footer-module__ql-font-Xkn_rZ`,
	"ql-picker-label": `user-src-pages-Footer-Footer-module__ql-picker-label-JjpqJH`,
	"qlPickerLabel": `user-src-pages-Footer-Footer-module__ql-picker-label-JjpqJH`,
	"ql-picker-item": `user-src-pages-Footer-Footer-module__ql-picker-item-OMx7qg`,
	"qlPickerItem": `user-src-pages-Footer-Footer-module__ql-picker-item-OMx7qg`,
	"ql-font-Montserrat": `user-src-pages-Footer-Footer-module__ql-font-Montserrat-JM3kWv`,
	"qlFontMontserrat": `user-src-pages-Footer-Footer-module__ql-font-Montserrat-JM3kWv`,
	"App-footer": `user-src-pages-Footer-Footer-module__App-footer-MJqby8`,
	"appFooter": `user-src-pages-Footer-Footer-module__App-footer-MJqby8`,
	"App-footer-menu": `user-src-pages-Footer-Footer-module__App-footer-menu-fIDVRa`,
	"appFooterMenu": `user-src-pages-Footer-Footer-module__App-footer-menu-fIDVRa`,
	"App-footer-menu-item": `user-src-pages-Footer-Footer-module__App-footer-menu-item-fYmIHJ`,
	"appFooterMenuItem": `user-src-pages-Footer-Footer-module__App-footer-menu-item-fYmIHJ`
};
export default ___CSS_LOADER_EXPORT___;
