/* eslint-disable */
export const selectSearch = state => state.search;

export const selectSearchUnion = state => {
  if (!state.search.search) return state.search.search;
  return state.search.search.reduce((acc, current) => {
    const value = acc.find(({ type, name }) => type === current.type && name === current.name);
    if (value) {
      value.ids.push(current.id);
    } else {
      acc.push({ ...current, ids: [current.id] });
    }
    return acc;
  }, []);
};
