/* eslint-disable */
import {
  DELETE_FILE_FAIL,
  DELETE_FILE_START,
  DELETE_FILE_SUCCESS,
  GET_ALLFILE_FAIL,
  GET_ALLFILE_START,
  GET_ALLFILE_SUCCESS,
  GET_FILE_FAIL,
  GET_FILE_START,
  GET_FILE_SUCCESS,
  GET_FILES_FAIL,
  GET_FILES_START,
  GET_FILES_SUCCESS,
  GET_PUBLIC_FILE_FAIL,
  GET_PUBLIC_FILE_START,
  GET_PUBLIC_FILE_SUCCESS,
  GET_VIDEO_IDB_FAIL,
  GET_VIDEO_IDB_START,
  GET_VIDEO_IDB_SUCCESS,
  IN_CACHE_FILE,
  POST_FILE_FAIL,
  POST_FILE_IMAGE_FAIL,
  POST_FILE_IMAGE_START,
  POST_FILE_IMAGE_SUCCESS,
  POST_FILE_START,
  POST_FILE_SUCCESS,
} from './actions';

const initialState = {
  info: null,
  isLoading: false,
  isLoadingGetFile: true,
  isLoadingImage: false,
  error: false,
  imgSrc: null,
  file: null,
  fileZip: null,
  videoIDB: null,
  isLoadingUuid: [],
  files: {},
  cacheFiles: {},
};

const findIndexAndDelete = (array, uuid) => {
  const indexUuid = array.indexOf(uuid);
  return array.splice(indexUuid, 1);
};

export default (state = initialState, { type, response, action, values }) => {
  switch (type) {
    case POST_FILE_START:
      return { ...state, isLoading: true, error: false };

    case POST_FILE_SUCCESS:
      return { ...state, info: response, isLoading: false };

    case POST_FILE_FAIL:
      return { ...state, error: true, isLoading: false };
    case DELETE_FILE_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_FILE_SUCCESS:
      return { ...state, info: response, isLoading: false };

    case DELETE_FILE_FAIL:
      return { ...state, error: true, isLoading: false };

    case POST_FILE_IMAGE_START:
      return { ...state, isLoadingImage: true, error: false };

    case POST_FILE_IMAGE_SUCCESS:
      return { ...state, info: response, isLoadingImage: false };

    case POST_FILE_IMAGE_FAIL:
      return { ...state, error: true, isLoadingImage: false };

    case GET_FILE_START:
      return {
        ...state,
        isLoading: true,
        isLoadingGetFile: true,
        error: false,
      };

    case GET_VIDEO_IDB_START:
      return {
        ...state,
        isLoading: true,
        isLoadingGetFile: true,
        error: false,
      };

    case GET_PUBLIC_FILE_START:
      return { ...state, isLoading: true, isLoadingPublic: true, error: false };

    case GET_ALLFILE_START:
      return { ...state, isLoadingAll: true, error: false };

    case GET_FILE_SUCCESS:
      return {
        ...state,
        file: response,
        isLoading: false,
        isLoadingGetFile: false,
      };

    case GET_FILES_START:
      return {
        ...state,
        isLoading: true,
        error: false,
        isLoadingUuid: [...state.isLoadingUuid, action.uuid],
      };

    case GET_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingUuid: findIndexAndDelete(state.isLoadingUuid, action.uuid),
        files: {
          ...state.files,
          [action.uuid]: URL.createObjectURL(response),
        },
      };

    case GET_FILES_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingUuid: findIndexAndDelete(state.isLoadingUuid, action.uuid),
      };

    case GET_VIDEO_IDB_SUCCESS:
      return {
        ...state,
        videoIDB: response,
        isLoading: false,
        isLoadingPublic: false,
      };

    case GET_PUBLIC_FILE_SUCCESS:
      return {
        ...state,
        filePublic: response,
        isLoading: false,
        isLoadingPublic: false,
      };

    case GET_ALLFILE_SUCCESS:
      return { ...state, fileZip: response, isLoadingAll: false };

    case GET_PUBLIC_FILE_FAIL:
      return { ...state, error: true, file: response, isLoading: false, isLoadingPublic: false };

    case GET_FILE_FAIL:
    case GET_VIDEO_IDB_FAIL:
      return { ...state, error: true, file: response, isLoading: false, isLoadingGetFile: false };

    case GET_ALLFILE_FAIL:
      return { ...state, error: true, file: response, isLoadingAll: false };

    case IN_CACHE_FILE:
      return { ...state, cacheFiles: { ...state.cacheFiles, [values.name]: values.urlImg } };

    default:
      return state;
  }
};
