import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { getMyRecommendedNews } from '@/store/user-topics/actions';

import { selectTopics } from '@/store/topics/selectors';

import { FloatButton, Layout, Radio, Spin } from 'antd';
import NewsElement from '@/pages/MyProfile/News/NewsElement';
import CustomEmpty from '@/components/CustomEmpty';
import ListItem from '@/components/MaterialListItem';

import css from '../info.module.scss';

const GROUP_TYPES = {
  MATERIALS: 'materials',
  NEWS: 'news',
};

const { Content } = Layout;
const { Button, Group } = Radio;

const MyAdded = () => {
  const {
    dataqa,
    profileId,
    onFavoriteClick,
    updateInfinityScrollTopicCreByCurUser,
    onStudyPlanClick,
    onDeleteClick,
    externalLogin,
    hideActionButtons,
    openDate,
  } = useOutletContext();
  const { t } = useTranslation('recommendedTopic');
  const dispatch = useDispatch();

  const [currentType, setCurrentType] = useState(GROUP_TYPES.MATERIALS);

  const { topicsByCurUser, isLoadingTopicsByCurUser } = useSelector(selectTopics);

  const listMaterials = topicsByCurUser?.elements?.map((item, index) => (
    <ListItem
      dataqa={dataqa}
      key={`${item.id}-${index}`}
      user={item.creBy}
      topic={item}
      profileId={profileId}
      onFavoriteClick={onFavoriteClick}
      theLast={topicsByCurUser?.elements?.length - 1 === index}
      onStudyPlanClick={onStudyPlanClick}
      onDeleteClick={onDeleteClick}
      page={topicsByCurUser.page}
      typeList='myMaterial'
      recUsers={item?.recUsers}
      lastRec={item?.recUsers?.filter(rec => rec.id === item.recBy)}
      isexternalLogin={externalLogin}
      hideActionButtons={hideActionButtons}
      openDate={openDate}
    />
  ));

  useEffect(() => {
    dispatch(getMyRecommendedNews(0));
  }, []);

  useEffect(() => {
    console.log('isLoadingTopicsByCurUser --', isLoadingTopicsByCurUser);
  }, [isLoadingTopicsByCurUser]);

  return (
    <Content className={css.RecommendedTopic}>
      <div className={css.RecommendedTopic__content}>
        <Group
          value={currentType}
          onChange={event => setCurrentType(event.target.value)}
          className={css.RecommendedTopic__group}
        >
          <Button key='GROUP_TYPES.MATERIALS' value={GROUP_TYPES.MATERIALS}>
            {t('materials')}
          </Button>
          <Button key='GROUP_TYPES.NEWS' value={GROUP_TYPES.NEWS}>
            {t('news')}
          </Button>
        </Group>

        {currentType === GROUP_TYPES.NEWS ? (
          <NewsElement
            type='myMaterial'
            emptyContent={{
              description: t('description4News'),
            }}
          />
        ) : (
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={e => {
              if (!isLoadingTopicsByCurUser) updateInfinityScrollTopicCreByCurUser(e);
            }}
            hasMore={topicsByCurUser.hasMore}
            threshold={250}
            loader={
              <div key='unique-loader' className={css.RecommendedTopic__loader}>
                <Spin size='large' spinning={isLoadingTopicsByCurUser} />
              </div>
            }
          >
            {topicsByCurUser.elements?.length === 0 && !isLoadingTopicsByCurUser ? (
              <CustomEmpty title={t('knowledgeBaseIsEmpty')} description={t('description4')} />
            ) : (
              listMaterials
            )}
          </InfiniteScroll>
        )}
      </div>
      <FloatButton.BackTop />
    </Content>
  );
};

export default MyAdded;
