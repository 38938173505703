// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Layout-Layout-module__ql-picker-OKGW75.user-src-components-Layout-Layout-module__ql-font-si9iZl .user-src-components-Layout-Layout-module__ql-picker-label-yhfSo1[data-value="Montserrat"]:before, .user-src-components-Layout-Layout-module__ql-picker-OKGW75.user-src-components-Layout-Layout-module__ql-font-si9iZl .user-src-components-Layout-Layout-module__ql-picker-item-f83jdt[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Layout-Layout-module__ql-font-Montserrat-jhqtRL {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Layout-Layout-module__Layout-nOWjFr {
  height: 100%;
}

.user-src-components-Layout-Layout-module__Card-NmaLSf {
  background-color: #fff;
  border-radius: 2px;
  padding: 40px;
  box-shadow: 0 1px 3px #0000000f;
}

.user-src-components-Layout-Layout-module__Card-NmaLSf:last-of-type {
  height: 100%;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF {
  width: 100%;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF h1, .user-src-components-Layout-Layout-module__Content-PmFKdF h2, .user-src-components-Layout-Layout-module__Content-PmFKdF h3, .user-src-components-Layout-Layout-module__Content-PmFKdF h4 {
  text-align: left;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF h1 {
  margin-bottom: 32px;
  font-size: 32px;
  font-weight: 100;
  line-height: 36px;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF h3 {
  font-size: 17px;
  font-weight: 900;
  line-height: 22px;
}

.user-src-components-Layout-Layout-module__Content-PmFKdF h4 {
  font-size: 11px;
  font-weight: 900;
  line-height: 13px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,YAAY;AACd;;AAQA;EALE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,+BAA+B;AACjC;;AAOA;EAJE,YAAY;AACd;;AAOA;EAJE,WAAW;AACb;;AAMA;EAHE,gBAAgB;AAClB;;AAQA;EALE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAOA;EAJE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAMA;EAHE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAKA;EAFE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.Layout {\n  height: 100%;\n}\n\n.Card {\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);\n  padding: 40px;\n  background-color: #fff;\n  border-radius: 2px;\n}\n.Card:last-of-type {\n  height: 100%;\n}\n\n.Content {\n  width: 100%;\n}\n.Content h1,\n.Content h2,\n.Content h3,\n.Content h4 {\n  text-align: left;\n}\n.Content h1 {\n  margin-bottom: 32px;\n  font-size: 32px;\n  font-weight: 100;\n  line-height: 36px;\n}\n.Content h2 {\n  font-size: 24px;\n  line-height: 29px;\n  font-weight: 500;\n}\n.Content h3 {\n  font-size: 17px;\n  line-height: 22px;\n  font-weight: 900;\n}\n.Content h4 {\n  font-size: 11px;\n  line-height: 13px;\n  font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Layout-Layout-module__ql-picker-OKGW75`,
	"qlPicker": `user-src-components-Layout-Layout-module__ql-picker-OKGW75`,
	"ql-font": `user-src-components-Layout-Layout-module__ql-font-si9iZl`,
	"qlFont": `user-src-components-Layout-Layout-module__ql-font-si9iZl`,
	"ql-picker-label": `user-src-components-Layout-Layout-module__ql-picker-label-yhfSo1`,
	"qlPickerLabel": `user-src-components-Layout-Layout-module__ql-picker-label-yhfSo1`,
	"ql-picker-item": `user-src-components-Layout-Layout-module__ql-picker-item-f83jdt`,
	"qlPickerItem": `user-src-components-Layout-Layout-module__ql-picker-item-f83jdt`,
	"ql-font-Montserrat": `user-src-components-Layout-Layout-module__ql-font-Montserrat-jhqtRL`,
	"qlFontMontserrat": `user-src-components-Layout-Layout-module__ql-font-Montserrat-jhqtRL`,
	"Layout": `user-src-components-Layout-Layout-module__Layout-nOWjFr`,
	"layout": `user-src-components-Layout-Layout-module__Layout-nOWjFr`,
	"Card": `user-src-components-Layout-Layout-module__Card-NmaLSf`,
	"card": `user-src-components-Layout-Layout-module__Card-NmaLSf`,
	"Content": `user-src-components-Layout-Layout-module__Content-PmFKdF`,
	"content": `user-src-components-Layout-Layout-module__Content-PmFKdF`
};
export default ___CSS_LOADER_EXPORT___;
