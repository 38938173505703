// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-WptNq_.shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-gjA60M .shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-label-s2cPNg[data-value="Montserrat"]:before, .shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-WptNq_.shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-gjA60M .shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-item-Hfx8eT[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-Montserrat-s1_lZQ {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-f7RUnu {
  justify-content: end;
  margin-bottom: 32px;
  display: flex;
}

@media (min-width: 320px) and (max-width: 992px) {
  .shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-f7RUnu {
    justify-content: start;
    margin: 4px 0;
  }
}

.shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-icon-LH4DVm {
  color: #1b1f26b8;
  margin-right: 4px;
  font-size: 26px;
}
`, "",{"version":3,"sources":["webpack://./shared/components/IconPokaStatus/IconPokaStatus.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;AACf;;AAMA;EACE;IAHE,sBAAsB;IACtB,aAAa;EACf;AACF;;AAKA;EAFE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.IconPoka-item {\n  display: flex;\n  justify-content: end;\n  margin-bottom: 32px;\n}\n@media (min-width: 320px) and (max-width: 992px) {\n  .IconPoka-item {\n    justify-content: start;\n    margin: 4px 0;\n  }\n}\n.IconPoka-item-icon {\n  font-size: 26px;\n  color: rgba(27, 31, 38, 0.7215686275);\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-WptNq_`,
	"qlPicker": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-WptNq_`,
	"ql-font": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-gjA60M`,
	"qlFont": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-gjA60M`,
	"ql-picker-label": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-label-s2cPNg`,
	"qlPickerLabel": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-label-s2cPNg`,
	"ql-picker-item": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-item-Hfx8eT`,
	"qlPickerItem": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-picker-item-Hfx8eT`,
	"ql-font-Montserrat": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-Montserrat-s1_lZQ`,
	"qlFontMontserrat": `shared-components-IconPokaStatus-IconPokaStatus-module__ql-font-Montserrat-s1_lZQ`,
	"IconPoka-item": `shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-f7RUnu`,
	"iconPokaItem": `shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-f7RUnu`,
	"IconPoka-item-icon": `shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-icon-LH4DVm`,
	"iconPokaItemIcon": `shared-components-IconPokaStatus-IconPokaStatus-module__IconPoka-item-icon-LH4DVm`
};
export default ___CSS_LOADER_EXPORT___;
