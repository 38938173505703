// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Notifications-Notifications-module__ql-picker-KZqjXf.user-src-components-Notifications-Notifications-module__ql-font-FaRcVm .user-src-components-Notifications-Notifications-module__ql-picker-label-dJ3F9I[data-value="Montserrat"]:before, .user-src-components-Notifications-Notifications-module__ql-picker-KZqjXf.user-src-components-Notifications-Notifications-module__ql-font-FaRcVm .user-src-components-Notifications-Notifications-module__ql-picker-item-jft6Q3[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Notifications-Notifications-module__ql-font-Montserrat-M4GfJf {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Notifications-Notifications-module__notifications-QOZo0f {
  width: 100%;
}

.user-src-components-Notifications-Notifications-module__notifications--box-YKDZOO {
  cursor: pointer;
}

.user-src-components-Notifications-Notifications-module__notifications--message-MoayZB {
  color: var(--ant-color-text);
}

.user-src-components-Notifications-Notifications-module__notifications--message-MoayZB:hover {
  color: var(--ant-color-text) !important;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;AACb;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,4BAA4B;AAC9B;;AAIA;EADE,uCAAuC;AACzC","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.notifications {\n  width: 100%;\n}\n.notifications--box {\n  cursor: pointer;\n}\n.notifications--message {\n  color: var(--ant-color-text);\n}\n.notifications--message:hover {\n  color: var(--ant-color-text) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Notifications-Notifications-module__ql-picker-KZqjXf`,
	"qlPicker": `user-src-components-Notifications-Notifications-module__ql-picker-KZqjXf`,
	"ql-font": `user-src-components-Notifications-Notifications-module__ql-font-FaRcVm`,
	"qlFont": `user-src-components-Notifications-Notifications-module__ql-font-FaRcVm`,
	"ql-picker-label": `user-src-components-Notifications-Notifications-module__ql-picker-label-dJ3F9I`,
	"qlPickerLabel": `user-src-components-Notifications-Notifications-module__ql-picker-label-dJ3F9I`,
	"ql-picker-item": `user-src-components-Notifications-Notifications-module__ql-picker-item-jft6Q3`,
	"qlPickerItem": `user-src-components-Notifications-Notifications-module__ql-picker-item-jft6Q3`,
	"ql-font-Montserrat": `user-src-components-Notifications-Notifications-module__ql-font-Montserrat-M4GfJf`,
	"qlFontMontserrat": `user-src-components-Notifications-Notifications-module__ql-font-Montserrat-M4GfJf`,
	"notifications": `user-src-components-Notifications-Notifications-module__notifications-QOZo0f`,
	"notifications--box": `user-src-components-Notifications-Notifications-module__notifications--box-YKDZOO`,
	"notificationsBox": `user-src-components-Notifications-Notifications-module__notifications--box-YKDZOO`,
	"notifications--message": `user-src-components-Notifications-Notifications-module__notifications--message-MoayZB`,
	"notificationsMessage": `user-src-components-Notifications-Notifications-module__notifications--message-MoayZB`
};
export default ___CSS_LOADER_EXPORT___;
