import React from 'react';

import { DetailAnswerItem } from './AnswerInfo';

import css from '../Details/Details.module.scss';

export const TestDetailsAnswersList = ({ questionAnswerList }) => {
  return (
    <div className={css['Details-questions']}>
      {questionAnswerList.map(questionItem => {
        return <DetailAnswerItem key={questionItem.id} questionItem={questionItem} />;
      })}
    </div>
  );
};
