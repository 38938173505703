/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';
import { createAction, createAsyncAction } from '@shared/utils/redux-helpers';

export const CREATE_STUDY_PLAN_START = 'CREATE_STUDY_PLAN_START';
export const CREATE_STUDY_PLAN_SUCCESS = 'CREATE_STUDY_PLAN_SUCCESS';
export const CREATE_STUDY_PLAN_FAIL = 'CREATE_STUDY_PLAN_FAIL';
export const GET_STUDY_PLAN_START = 'GET_STUDY_PLAN_START';
export const GET_STUDY_PLAN_SUCCESS = 'GET_STUDY_PLAN_SUCCESS';
export const GET_STUDY_PLAN_FAIL = 'GET_STUDY_PLAN_FAIL';
export const GET_ANOTHER_STUDY_PLAN_START = 'GET_ANOTHER_STUDY_PLAN_START';
export const GET_ANOTHER_STUDY_PLAN_SUCCESS = 'GET_ANOTHER_STUDY_PLAN_SUCCESS';
export const GET_ANOTHER_STUDY_PLAN_FAIL = 'GET_ANOTHER_STUDY_PLAN_FAIL';
export const GET_STUDY_PLAN_DINAMIC_START = 'GET_STUDY_PLAN_DINAMIC_START';
export const GET_STUDY_PLAN_DINAMIC_SUCCESS = 'GET_STUDY_PLAN_DINAMIC_SUCCESS';
export const GET_STUDY_PLAN_DINAMIC_FAIL = 'GET_STUDY_PLAN_DINAMIC_FAIL';
export const GET_ANOTHER_STUDY_PLAN_DINAMIC_START = 'GET_ANOTHER_STUDY_PLAN_DINAMIC_START';
export const GET_ANOTHER_STUDY_PLAN_DINAMIC_SUCCESS = 'GET_ANOTHER_STUDY_PLAN_DINAMIC_SUCCESS';
export const GET_ANOTHER_STUDY_PLAN_DINAMIC_FAIL = 'GET_ANOTHER_STUDY_PLAN_DINAMIC_FAIL';
export const UPDATE_STUDY_PLAN_STATUS_START = 'UPDATE_STUDY_PLAN_STATUS_START';
export const UPDATE_STUDY_PLAN_STATUS_SUCCESS = 'UPDATE_STUDY_PLAN_STATUS_SUCCESS';
export const UPDATE_STUDY_PLAN_STATUS_FAIL = 'UPDATE_STUDY_PLAN_STATUS_FAIL';
export const UPDATE_STUDY_PLAN_DATE_START = 'UPDATE_STUDY_PLAN_DATE_START';
export const UPDATE_STUDY_PLAN_DATE_SUCCESS = 'UPDATE_STUDY_PLAN_DATE_SUCCESS';
export const UPDATE_STUDY_PLAN_DATE_FAIL = 'UPDATE_STUDY_PLAN_DATE_FAIL';
export const UPDATE_STUDY_PLAN_FINISHED = 'UPDATE_STUDY_PLAN_FINISHED';
export const DELETE_STUDY_PLAN_START = 'DELETE_STUDY_PLAN_START';
export const DELETE_STUDY_PLAN_SUCCESS = 'DELETE_STUDY_PLAN_SUCCESS';
export const DELETE_STUDY_PLAN_FAIL = 'DELETE_STUDY_PLAN_FAIL';
export const GET_STUDY_PLAN_STAT_START = 'GET_STUDY_PLAN_STAT_START';
export const GET_STUDY_PLAN_STAT_SUCCESS = 'GET_STUDY_PLAN_STAT_SUCCESS';
export const GET_STUDY_PLAN_STAT_FAIL = 'GET_STUDY_PLAN_STAT_FAIL';
export const GET_ANOTHER_STUDY_PLAN_STAT_START = 'GET_ANOTHER_STUDY_PLAN_STAT_START';
export const GET_ANOTHER_STUDY_PLAN_STAT_SUCCESS = 'GET_ANOTHER_STUDY_PLAN_STAT_SUCCESS';
export const GET_ANOTHER_STUDY_PLAN_STAT_FAIL = 'GET_ANOTHER_STUDY_PLAN_STAT_FAIL';
export const FINISH_ALL_PLANS_START = 'FINISH_ALL_PLANS_START';
export const FINISH_ALL_PLANS_SUCCESS = 'FINISH_ALL_PLANS_SUCCESS';
export const FINISH_ALL_PLANS_FAIL = 'FINISH_ALL_PLANS_FAIL';
export const GET_STUDY_PLAN_FIRST_MONTH_START = 'GET_STUDY_PLAN_FIRST_MONTH_START';
export const GET_STUDY_PLAN_FIRST_MONTH_SUCCESS = 'GET_STUDY_PLAN_FIRST_MONTH_SUCCESS';
export const GET_STUDY_PLAN_FIRST_MONTH_FAIL = 'GET_STUDY_PLAN_FIRST_MONTH_FAIL';
export const GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_START = 'GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_START';
export const GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_SUCCESS = 'GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_SUCCESS';
export const GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_FAIL = 'GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_FAIL';
export const CHECK_STUDY_PLAN_START = 'CHECK_STUDY_PLAN_START';
export const CHECK_STUDY_PLAN_SUCCESS = 'CHECK_STUDY_PLAN_SUCCESS';
export const CHECK_STUDY_PLAN_FAIL = 'CHECK_STUDY_PLAN_FAIL';
export const CHECK_TOPIC_FINISHED_BEFORE_START = 'CHECK_TOPIC_FINISHED_BEFORE_START';
export const CHECK_TOPIC_FINISHED_BEFORE_SUCCESS = 'CHECK_TOPIC_FINISHED_BEFORE_SUCCESS';
export const CHECK_TOPIC_FINISHED_BEFORE_FAIL = 'CHECK_TOPIC_FINISHED_BEFORE_FAIL';
export const GET_STUDY_PLAN_PREV_START = 'GET_STUDY_PLAN_PREV_START';
export const GET_STUDY_PLAN_PREV_SUCCESS = 'GET_STUDY_PLAN_PREV_SUCCESS';
export const GET_STUDY_PLAN_PREV_FAIL = 'GET_STUDY_PLAN_PREV_FAIL';
export const GET_STUDY_PLAN_NEXT_START = 'GET_STUDY_PLAN_NEXT_START';
export const GET_STUDY_PLAN_NEXT_SUCCESS = 'GET_STUDY_PLAN_NEXT_SUCCESS';
export const GET_STUDY_PLAN_NEXT_FAIL = 'GET_STUDY_PLAN_NEXT_FAIL';
export const CANCEL_STUDY_PLAN = 'CANCEL_STUDY_PLAN';
export const CLEAR_PLAN = 'CLEAR_PLAN';
export const CHANGE_VIEW_PLAN = 'CHANGE_VIEW_PLAN';
export const UPDATE_BUTTON = 'UPDATE_BUTTON';
export const NOT_UPDATE_BUTTON = 'NOT_UPDATE_BUTTON';

export const CREATE_STUDY_PLAN_ANOTHER_START = 'CREATE_STUDY_PLAN_ANOTHER_START';
export const CREATE_STUDY_PLAN_ANOTHER_SUCCESS = 'CREATE_STUDY_PLAN_ANOTHER_SUCCESS';
export const CREATE_STUDY_PLAN_ANOTHER_FAIL = 'CREATE_STUDY_PLAN_ANOTHER_FAIL';

export const GET_EVENT_PARTY_START = 'GET_EVENT_PARTY_START';
export const GET_EVENT_PARTY_SUCCESS = 'GET_EVENT_PARTY_SUCCESS';
export const GET_EVENT_PARTY_FAIL = 'GET_EVENT_PARTY_FAIL';

export const GET_USER_ID_TOPIC_PLAN_START = 'GET_USER_ID_TOPIC_PLAN_START';
export const GET_USER_ID_TOPIC_PLAN_SUCCESS = 'GET_USER_ID_TOPIC_PLAN_SUCCESS';
export const GET_USER_ID_TOPIC_PLAN_FAIL = 'GET_USER_ID_TOPIC_PLAN_FAIL';

export const GET_COUNT_SP_START = 'GET_COUNT_SP_START';
export const GET_COUNT_SP_SUCCESS = 'GET_COUNT_SP_SUCCESS';
export const GET_COUNT_SP_FAIL = 'GET_COUNT_SP_FAIL';

export const GET_PREVIOUS_STUDY_PLANS_START = 'GET_PREVIOUS_STUDY_PLANS_START';
export const GET_PREVIOUS_STUDY_PLANS_SUCCESS = 'GET_PREVIOUS_STUDY_PLANS_SUCCESS';
export const GET_PREVIOUS_STUDY_PLANS_FAIL = 'GET_PREVIOUS_STUDY_PLANS_FAIL';

export const POST_INTEREST_START = 'POST_INTEREST_START';
export const POST_INTEREST_SUCCESS = 'POST_INTEREST_SUCCESS';
export const POST_INTEREST_FAIL = 'POST_INTEREST_FAIL';

export const POST_EVENT_REGISTR_START = 'POST_EVENT_REGISTR_START';
export const POST_EVENT_REGISTR_SUCCESS = 'POST_EVENT_REGISTR_SUCCESS';
export const POST_EVENT_REGISTR_FAIL = 'POST_EVENT_REGISTR_FAIL';

export const POST_WAITING_LIST_START = 'POST_WAITING_LIST_START';
export const POST_WAITING_LIST_SUCCESS = 'POST_WAITING_LIST_SUCCESS';
export const POST_WAITING_LIST_FAIL = 'POST_WAITING_LIST_FAIL';

export const GET_WAITING_LIST_START = 'GET_WAITING_LIST_START';
export const GET_WAITING_LIST_SUCCESS = 'GET_WAITING_LIST_SUCCESS';
export const GET_WAITING_LIST_FAIL = 'GET_WAITING_LIST_FAIL';
export const GET_ARCHIVE_TOPIC_PLAN_START = 'GET_ARCHIVE_TOPIC_PLAN_START';
export const GET_ARCHIVE_TOPIC_PLAN_SUCCESS = 'GET_ARCHIVE_TOPIC_PLAN_SUCCESS';
export const GET_ARCHIVE_TOPIC_PLAN_FAIL = 'GET_ARCHIVE_TOPIC_PLAN_FAIL';

export const GET_TOPIC_STUDY_STATUS_START = 'GET_TOPIC_STUDY_STATUS_START';
export const GET_TOPIC_STUDY_STATUS_SUCCESS = 'GET_TOPIC_STUDY_STATUS_SUCCESS';
export const GET_TOPIC_STUDY_STATUS_FAIL = 'GET_TOPIC_STUDY_STATUS_FAIL';

export const ADD_USER_EVENT_START = 'ADD_USER_EVENT_START';
export const ADD_USER_EVENT_SUCCESS = 'ADD_USER_EVENT_SUCCESS';
export const ADD_USER_EVENT_FAIL = 'ADD_USER_EVENT_FAIL';

export const SET_CURRENT_PAID_TOPIC_ID = 'SET_CURRENT_PAID_TOPIC_ID';

export const RESET_CURRENT_PAID_TOPIC_ID = 'RESET_CURRENT_PAID_TOPIC_ID';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getStudyPlan = (isAnotherUser, userId, planMonth = '', page = 0, size = 20, trackId) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan${getQueryString({
    userId,
    planMonth,
    page,
    sort: 'id,asc',
    size,
    trackId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: isAnotherUser
    ? [GET_ANOTHER_STUDY_PLAN_START, GET_ANOTHER_STUDY_PLAN_SUCCESS, GET_ANOTHER_STUDY_PLAN_FAIL]
    : [GET_STUDY_PLAN_START, GET_STUDY_PLAN_SUCCESS, GET_STUDY_PLAN_FAIL],
});

export const getEventParty = (id, params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/participant/${id}${getQueryString({
    ...params,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  page: params.page,
  types: [GET_EVENT_PARTY_START, GET_EVENT_PARTY_SUCCESS, GET_EVENT_PARTY_FAIL],
  onSuccess,
});

export const getStudyPlanDinamic = (isAnotherUser, userId, planMonth = '', onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/scroll${getQueryString({
    userId,
    planMonth,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  planMonth,
  types: isAnotherUser
    ? [
        GET_ANOTHER_STUDY_PLAN_DINAMIC_START,
        GET_ANOTHER_STUDY_PLAN_DINAMIC_SUCCESS,
        GET_ANOTHER_STUDY_PLAN_DINAMIC_FAIL,
      ]
    : [GET_STUDY_PLAN_DINAMIC_START, GET_STUDY_PLAN_DINAMIC_SUCCESS, GET_STUDY_PLAN_DINAMIC_FAIL],
  onSuccess,
});

export const getStudyPlanStat = (isAnotherUser, userId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(userId)}/plan/stat`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: isAnotherUser
    ? [GET_ANOTHER_STUDY_PLAN_STAT_START, GET_ANOTHER_STUDY_PLAN_STAT_SUCCESS, GET_ANOTHER_STUDY_PLAN_STAT_FAIL]
    : [GET_STUDY_PLAN_STAT_START, GET_STUDY_PLAN_STAT_SUCCESS, GET_STUDY_PLAN_STAT_FAIL],
  onSuccess,
});

export const getStudyPlanFirstMonth = (isAnotherUser, userId) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan${getQueryString({
    userId,
    sort: 'date,asc',
    page: 0,
    size: 1,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: isAnotherUser
    ? [
        GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_START,
        GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_SUCCESS,
        GET_ANOTHER_STUDY_PLAN_FIRST_MONTH_FAIL,
      ]
    : [GET_STUDY_PLAN_FIRST_MONTH_START, GET_STUDY_PLAN_FIRST_MONTH_SUCCESS, GET_STUDY_PLAN_FIRST_MONTH_FAIL],
});

export const getStudyPlanPrev = (userId, planMonth, onSuccess) => ({
  type: REST_API,
  method: GET,
  // url: API_URL + '/user/topic/plan' + getQueryString({userId, planMonth}),
  url: `${API_URL}/user/topic/plan/scroll${getQueryString({
    userId,
    planMonth,
    sort: 'id,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'text',
  planMonth,
  types: [GET_STUDY_PLAN_PREV_START, GET_STUDY_PLAN_PREV_SUCCESS, GET_STUDY_PLAN_PREV_FAIL],
  onSuccess,
});

export const getStudyPlanNext = (month, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/next${getQueryString({ month })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'text',
  onSuccess,
  types: [GET_STUDY_PLAN_NEXT_START, GET_STUDY_PLAN_NEXT_SUCCESS, GET_STUDY_PLAN_NEXT_FAIL],
});

// нужно поменять, сделать аргументом 1 объект
export const createStudyPlan = (
  userId,
  topicId,
  planDate,
  comment,
  onSuccess,
  isAnother,
  withoutSchedule,
  webinarDateTime,
  eventId
) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/topic/plan`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    userId,
    topicId,
    date: planDate,
    comment,
    withoutSchedule,
    webinarDateTime,
    eventId,
  },
  onSuccess,
  month: planDate,
  types: !isAnother
    ? [CREATE_STUDY_PLAN_START, CREATE_STUDY_PLAN_SUCCESS, CREATE_STUDY_PLAN_FAIL]
    : [CREATE_STUDY_PLAN_ANOTHER_START, CREATE_STUDY_PLAN_ANOTHER_SUCCESS, CREATE_STUDY_PLAN_ANOTHER_FAIL],
});

export const checkStudyPlan = ({
  userId,
  topicId,
  isCurrent,
  planMonth = '',
  planStatus = '',
  onSuccess,
  withTrackInfo,
}) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan${getQueryString({
    userId,
    topicId,
    isCurrent,
    planMonth,
    planStatus,
    withTrackInfo,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_STUDY_PLAN_START, CHECK_STUDY_PLAN_SUCCESS, CHECK_STUDY_PLAN_FAIL],
  onSuccess,
});

export const updateStudyPlanStatus = (planId, planStatus, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/topic/plan/${encodeURI(planId)}/${encodeURI(planStatus)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [UPDATE_STUDY_PLAN_STATUS_START, UPDATE_STUDY_PLAN_STATUS_SUCCESS, UPDATE_STUDY_PLAN_STATUS_FAIL],
  onSuccess,
});

export const finishAllPlansForTopics = (planId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/topic/plan/${encodeURI(planId)}/finishAll`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  responseType: 'text',
  types: [FINISH_ALL_PLANS_START, FINISH_ALL_PLANS_SUCCESS, FINISH_ALL_PLANS_FAIL],
});

export const updateStudyPlanDate = (planId, planDate, comment, onSuccess, withoutSchedule, eventId) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/user/topic/plan/date`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    comment,
    id: planId,
    startDate: planDate,
    withoutSchedule,
    eventId,
  },
  onSuccess,
  month: planDate,
  types: [UPDATE_STUDY_PLAN_DATE_START, UPDATE_STUDY_PLAN_DATE_SUCCESS, UPDATE_STUDY_PLAN_DATE_FAIL],
});

export const addUserEvent = (id, requestId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/event/${id}/request/add`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: requestId,
  types: [ADD_USER_EVENT_START, ADD_USER_EVENT_SUCCESS, ADD_USER_EVENT_FAIL],
  onSuccess,
});

export const deleteStudyPlan = (planId, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/topic/plan/${encodeURI(planId)}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  responseType: 'text',
  onSuccess,
  types: [DELETE_STUDY_PLAN_START, DELETE_STUDY_PLAN_SUCCESS, DELETE_STUDY_PLAN_FAIL],
});

export const checkTopicWasFinished = topicId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/finishBefore${getQueryString({ topicId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [CHECK_TOPIC_FINISHED_BEFORE_START, CHECK_TOPIC_FINISHED_BEFORE_SUCCESS, CHECK_TOPIC_FINISHED_BEFORE_FAIL],
});
export const notUpdateButtonFunc = () => ({
  type: NOT_UPDATE_BUTTON,
});
export const updateButtonFunc = () => ({
  type: UPDATE_BUTTON,
});
export const cancelStudyPlan = () => ({
  type: CANCEL_STUDY_PLAN,
});
export const changeViewPage = view => ({
  type: CHANGE_VIEW_PLAN,
  view,
});
export const clearPlan = () => ({
  type: CLEAR_PLAN,
});
export const sendNotificationStudyPlanFinished = () => ({
  type: UPDATE_STUDY_PLAN_FINISHED,
});

export const getUserTopicPlan = createAsyncAction('getUserTopicPlan');
export const clearUserTopicPlan = createAction('clearUserTopicPlan');
export const setStudyPlanParams = createAction('setStudyPlanParams');
export const clearStudyPlanParams = createAction('clearStudyPlanParams');
export const getUserTopicPlanScroll = createAsyncAction('getUserTopicPlanScroll');
export const setMaterialLoading = createAsyncAction('setMaterialLoading');

export const getUserIdTopicPlan = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan${getQueryString({ ...params, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_USER_ID_TOPIC_PLAN_START, GET_USER_ID_TOPIC_PLAN_SUCCESS, GET_USER_ID_TOPIC_PLAN_FAIL],
});

export const getCountStudyPlans = () => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/status/count`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COUNT_SP_START, GET_COUNT_SP_SUCCESS, GET_COUNT_SP_FAIL],
});

export const checkPreviousStudyPlans = topicId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/check/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PREVIOUS_STUDY_PLANS_START, GET_PREVIOUS_STUDY_PLANS_SUCCESS, GET_PREVIOUS_STUDY_PLANS_FAIL],
});
export const getUserIdArchiveTopicPlan = params => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/archive${getQueryString({ ...params, size: 20 })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_ARCHIVE_TOPIC_PLAN_START, GET_ARCHIVE_TOPIC_PLAN_SUCCESS, GET_ARCHIVE_TOPIC_PLAN_FAIL],
});

export const showInterestAction = (topicId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/waiting/interest/${topicId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  onError,
  types: [POST_INTEREST_START, POST_INTEREST_SUCCESS, POST_INTEREST_FAIL],
});

export const eventRegistrAction = (eventId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/waiting/registration/${eventId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  onError,
  types: [POST_EVENT_REGISTR_START, POST_EVENT_REGISTR_SUCCESS, POST_EVENT_REGISTR_FAIL],
});

export const waitingListAction = (eventId, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/waiting/list/${eventId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  onError,
  types: [POST_WAITING_LIST_START, POST_WAITING_LIST_SUCCESS, POST_WAITING_LIST_FAIL],
});

export const checkWaitingListInfo = topicId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/topic/waiting/${topicId}`,

  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_WAITING_LIST_START, GET_WAITING_LIST_SUCCESS, GET_WAITING_LIST_FAIL],
});

export const getUserTopicStudyPlanStatus = (params, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/state${getQueryString({ ...params })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },

  onSuccess,
  types: [GET_TOPIC_STUDY_STATUS_START, GET_TOPIC_STUDY_STATUS_SUCCESS, GET_TOPIC_STUDY_STATUS_FAIL],
});

export const setCurrentPaidTopicId = topicId => {
  return { type: 'SET_CURRENT_PAID_TOPIC_ID', response: topicId };
};

export const resetCurrentPaidTopicId = () => ({ type: 'RESET_CURRENT_PAID_TOPIC_ID' });
