/* eslint-disable */
import { GET_CUSTOM_FIELD_FAIL, GET_CUSTOM_FIELD_START, GET_CUSTOM_FIELD_SUCCESS } from './constants';

const initialState = {
  loading: false,
  customField: null,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    // Query START
    case GET_CUSTOM_FIELD_START:
      return { ...state, loading: true };

    case GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        customField: response,
      };

    // Query ERROR
    case GET_CUSTOM_FIELD_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
