/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classNames from 'classnames';

import css from './UserProfileModal.module.scss';

class RulesModal extends Component {
  constructor(props) {
    super(props);
    this.state = { slide: 0 };
  }

  slides = () => [
    {
      header: this.props.t('welcome'),
      text: this.props.t('welcomeDescription'),
    },
    {
      header: this.props.t('topics'),
      text: this.props.t('topicsDescription'),
    },
    {
      header: this.props.t('plan'),
      text: this.props.t('planDescription'),
    },
    {
      header: this.props.t('coins'),
      text: this.props.t('coinsDescription'),
    },
  ];

  goToNextSlide = () => {
    this.setState(state => ({ slide: state.slide + 1 }));
  };

  goToPreviousSlide = () => {
    this.setState(state => ({ slide: state.slide - 1 }));
  };

  render() {
    const currentSlide = slides()[this.state.slide] || {};
    const slideDots = slides().map((item, index) => {
      return index === this.state.slide ? (
        <svg
          key={index}
          className={classNames(css['UserProfile-modal-slide-dot'], css.current)}
          width='9px'
          height='9px'
          viewBox='0 0 24 24'
        >
          <path fill='currentColor' d='M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' />
        </svg>
      ) : (
        <svg key={index} className={css['UserProfile-modal-slide-dot']} width='9' height='9' viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12 20a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2'
          />
        </svg>
      );
    });
    const leftArrow =
      this.state.slide > 0 ? (
        <div
          className={classNames(css['UserProfile-modal-slide-arrow'], css['UserProfile-modal-slide-arrow-left'])}
          onClick={this.goToPreviousSlide}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='11' height='22' viewBox='0 0 11 22'>
            <path stroke='currentColor' fill='none' d='M9.864 21 1 11 9.864 1' />
          </svg>
        </div>
      ) : null;
    const rightArrow =
      this.state.slide + 1 < slides().length ? (
        <div
          className={classNames(css['UserProfile-modal-slide-arrow'], css['UserProfile-modal-slide-arrow-right'])}
          onClick={this.goToNextSlide}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='11' height='22' viewBox='0 0 11 22'>
            <path stroke='currentColor' fill='none' d='m1 21 8.864-10L1 1' />
          </svg>
        </div>
      ) : null;

    return (
      <div className={classNames(css['App-card-item'], css['UserProfile-modal'])}>
        <div className={classNames(css['UserProfile-modal-header'], css['UserProfile-modal-header-center'])}>
          {currentSlide.header}
        </div>
        <div className={css['UserProfile-modal-slide']}>{currentSlide.text}</div>
        {leftArrow}
        {rightArrow}
        <div className={css['UserProfile-modal-slide-dots-wrapper']}>
          <div className={css['UserProfile-modal-slide-dots']}>{slideDots}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('rulesModal')(RulesModal);
