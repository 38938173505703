import { useNavigate } from 'react-router';

import Compilations from '@/pages/Compilations';

const MyCollectionCompilations = (...props) => {
  const navigate = useNavigate();

  return <Compilations {...props} navigate={navigate} isFavorite />;
};

export default MyCollectionCompilations;
