import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import { useDispatch, useSelector } from 'react-redux';

import { getMentorGratitude, updateMentorGratitude } from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';

import CommentRateMentor from '@/components/Mentoring/CommentRateMentor';
import { HiOutlineEmojiSad } from 'react-icons/hi';

import PropTypes from 'prop-types';

import css from './Tabs.module.scss';

const TabThanksMentor = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation('mentors');
  const { gratitude, mentorsGratitudeTotal, mentorsGratitudeCurrentPage, isLoading } = useSelector(selectMentors);

  const loadMore = () => {
    if (props.mentorId) {
      dispatch(getMentorGratitude(props.mentorId, mentorsGratitudeCurrentPage + 1, 5));
    }
  };

  const tabEditComment = (commentId, params, func) => {
    dispatch(
      updateMentorGratitude(commentId, { text: params.text }, () => {
        func();
      })
    );
  };

  const emptyData = (
    <div className={css['Tab-thanks-empty']}>
      <div className={css['Tab-thanks-empty-message']}>
        <HiOutlineEmojiSad size={108} />
        <p className={css['Tab-thanks-empty-emptyHeader']}>{t('noGratitude')}</p>
        <p className={css['Tab-thanks-empty-description']}>{t('descNoGratitude')}</p>
      </div>
    </div>
  );

  const renderGratitude =
    gratitude?.length &&
    gratitude.map((item, index) => {
      let userName = `${item.user.firstName || ''} ${item.user.lastName || ''}`;

      return (
        <CommentRateMentor
          userName={userName}
          text={item.text}
          date={item.createdDate}
          photoUuid={item.user.photoUuid}
          localPhotoUuid={item.user.localPhotoUuid}
          key={index}
          editable={item.editable}
          commentId={item.id}
          modified={item.modified}
          mentorId={props.mentorId}
          canDelete={false}
          tabEditComment={tabEditComment}
        />
      );
    });

  return (
    <div className={css['Tab']}>
      <div className={css['Tab-thanks']}>
        <InfiniteScroll
          pageStart={mentorsGratitudeCurrentPage}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={mentorsGratitudeTotal > gratitude.length && !isLoading}
          threshold={300}
          useWindow
        >
          {renderGratitude.length ? renderGratitude : emptyData}
        </InfiniteScroll>
      </div>
    </div>
  );
};

TabThanksMentor.propTypes = {
  mentorId: PropTypes.number,
};

export default TabThanksMentor;
