// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-TracksModal-TrackModal-module__ql-picker-urJ5_x.user-src-components-TracksModal-TrackModal-module__ql-font-EbJ5Il .user-src-components-TracksModal-TrackModal-module__ql-picker-label-ICO0UD[data-value="Montserrat"]:before, .user-src-components-TracksModal-TrackModal-module__ql-picker-urJ5_x.user-src-components-TracksModal-TrackModal-module__ql-font-EbJ5Il .user-src-components-TracksModal-TrackModal-module__ql-picker-item-aHS8AR[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-TracksModal-TrackModal-module__ql-font-Montserrat-hS5vB7 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-header-RtcN9t {
  flex-direction: column;
  display: flex;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-header-title-w31ZYy {
  font-size: 16px;
  font-weight: 500;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-header-subtitle-xJxxDn {
  font-size: 12px;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-container-oYJfl_ {
  max-height: 300px;
  overflow-y: auto;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-item-qiFUON {
  color: #212121;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  display: flex;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-item-qiFUON:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-item-qiFUON:last-child {
  padding-bottom: 0;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-Yw8ag9 {
  font-size: 14px;
  color: #c41313 !important;
}

.user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-active-mPSXwY {
  color: #2bc413 !important;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/TracksModal/TrackModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;EACtB,aAAa;AACf;;AAOA;EAJE,eAAe;EACf,gBAAgB;AAClB;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,iBAAiB;EACjB,gBAAgB;AAClB;;AAIA;EADE,cAAc;EACd,8BAA8B;EAC9B,mBAAmB;EACnB,4BAA4B;EAC5B,eAAe;EACf,aAAa;AACf;;AAMA;EAHE,gCAAgC;AAClC;;AAKA;EAFE,iBAAiB;AACnB;;AAIA;EADE,eAAe;EACf,yBAAyB;AAC3B;;AAGA;EAAE,yBAAyB;AAC3B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.TrackModal-header {\n  display: flex;\n  flex-direction: column;\n}\n.TrackModal-header-title {\n  font-size: 16px;\n  font-weight: 500;\n}\n.TrackModal-header-subtitle {\n  font-size: 12px;\n}\n.TrackModal-container {\n  max-height: 300px;\n  overflow-y: auto;\n}\n.TrackModal-item {\n  padding: 16px 16px 16px 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 16px;\n  color: #212121;\n}\n.TrackModal-item:hover {\n  color: var(\"personalColor\");\n}\n.TrackModal-item:not(:last-child) {\n  border-bottom: 1px #e8e8e8 solid;\n}\n.TrackModal-item:last-child {\n  padding-bottom: 0;\n}\n.TrackModal-item-status {\n  color: #c41313 !important;\n  font-size: 14px;\n}\n.TrackModal-item-status-active {\n  color: #2bc413 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-TracksModal-TrackModal-module__ql-picker-urJ5_x`,
	"qlPicker": `user-src-components-TracksModal-TrackModal-module__ql-picker-urJ5_x`,
	"ql-font": `user-src-components-TracksModal-TrackModal-module__ql-font-EbJ5Il`,
	"qlFont": `user-src-components-TracksModal-TrackModal-module__ql-font-EbJ5Il`,
	"ql-picker-label": `user-src-components-TracksModal-TrackModal-module__ql-picker-label-ICO0UD`,
	"qlPickerLabel": `user-src-components-TracksModal-TrackModal-module__ql-picker-label-ICO0UD`,
	"ql-picker-item": `user-src-components-TracksModal-TrackModal-module__ql-picker-item-aHS8AR`,
	"qlPickerItem": `user-src-components-TracksModal-TrackModal-module__ql-picker-item-aHS8AR`,
	"ql-font-Montserrat": `user-src-components-TracksModal-TrackModal-module__ql-font-Montserrat-hS5vB7`,
	"qlFontMontserrat": `user-src-components-TracksModal-TrackModal-module__ql-font-Montserrat-hS5vB7`,
	"TrackModal-header": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-RtcN9t`,
	"trackModalHeader": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-RtcN9t`,
	"TrackModal-header-title": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-title-w31ZYy`,
	"trackModalHeaderTitle": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-title-w31ZYy`,
	"TrackModal-header-subtitle": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-subtitle-xJxxDn`,
	"trackModalHeaderSubtitle": `user-src-components-TracksModal-TrackModal-module__TrackModal-header-subtitle-xJxxDn`,
	"TrackModal-container": `user-src-components-TracksModal-TrackModal-module__TrackModal-container-oYJfl_`,
	"trackModalContainer": `user-src-components-TracksModal-TrackModal-module__TrackModal-container-oYJfl_`,
	"TrackModal-item": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-qiFUON`,
	"trackModalItem": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-qiFUON`,
	"TrackModal-item-status": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-Yw8ag9`,
	"trackModalItemStatus": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-Yw8ag9`,
	"TrackModal-item-status-active": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-active-mPSXwY`,
	"trackModalItemStatusActive": `user-src-components-TracksModal-TrackModal-module__TrackModal-item-status-active-mPSXwY`
};
export default ___CSS_LOADER_EXPORT___;
