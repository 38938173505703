/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import InputList from './InputList';

import { isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './TopicAddModal.module.scss';

import { TOPIC_SUBTYPES, TOPIC_TYPES } from '@/constants/topic-types';

const { TabPane } = Tabs;

export class AdditionalInputCard extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    editable: PropTypes.bool,
    files: PropTypes.array,
    handleAddSchedule: PropTypes.func,
    handleDeleteSchedule: PropTypes.func,
    onAuthorsChange: PropTypes.func,
    onChange: PropTypes.func,
    onChangeSchedule: PropTypes.func,
    onFilesChange: PropTypes.func,
    onLinksChange: PropTypes.func,
    topic: PropTypes.object,
    topicSubTypeId: PropTypes.number,
    topicSubTypes: PropTypes.array,
    topicTypeId: PropTypes.number,
    topicVideoTypes: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const topicType = TOPIC_TYPES(this.props.t).find(type => type.id === props.topicTypeId);
    this.state = {
      topicType: topicType || {},
      curTime: dayjs(),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.topicTypeId !== newProps.topicTypeId) {
      const topicType = TOPIC_TYPES(this.props.t).find(type => type.id === newProps.topicTypeId) || {};
      this.setState({ topicType });
    }
  }

  handleTabChange = tab => {
    this.props.onChange({ name: 'subTypeId', value: Number(tab) });
  };

  render() {
    const { topicType } = this.state;
    const { topicSubTypes, topicTypeId, topicSubTypeId, editable } = this.props;

    if (!topicSubTypes) {
      return null;
    }

    const tabs = topicSubTypes
      .map(tab => {
        const topicSubType = TOPIC_SUBTYPES(this.props.t).find(subtype => subtype.id === tab.id) || {};
        return topicSubType.active ? (
          <TabPane tab={tab.name} key={tab.id.toString()}>
            <InputList currentUser={this.props.currentUser} fields={topicSubType.fields} {...this.props} />
          </TabPane>
        ) : null;
      })
      .filter(tab => !!tab);

    if (tabs.length) {
      return (
        <div className={classNames(css['App-card-item'], css['Add-modal'])}>
          <Tabs
            animated={false}
            defaultActiveKey={
              (!editable && topicTypeId === 20 && '22') ||
              (!editable && isCorpCourseWithOrWithoutApprove(topicTypeId) && '19') ||
              (topicSubTypeId && topicSubTypeId.toString())
            }
            {...(editable && {
              activeKey: topicSubTypeId && topicSubTypeId.toString(),
            })}
            className={css['Add-modal-tabs']}
            onChange={this.handleTabChange}
          >
            {tabs}
          </Tabs>
        </div>
      );
    }
    return (
      <div className={classNames(css['App-card-item'], css['Add-modal'])}>
        <InputList
          currentUser={this.props.currentUser}
          curTime={this.state.curTime}
          fields={topicType.fields}
          topicType={topicType}
          files={this.props.files}
          {...this.props}
        />
      </div>
    );
  }
}

export default withTranslation(['constants', 'constants'])(AdditionalInputCard);
