/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { checkPreviousStudyPlans, clearPlan } from '@/store/study-plan/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';

import { Button, message, Tooltip } from 'antd';
import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction';
import AudienceConfirmationModal from '@/components/ModalTargetAudience/Modal';
import TypeBadge from '@/components/TypeBadge';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import {
  getDefaultMaterialImage,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

import { ADD_REPEAT } from '@/constants/studyPlanStatus';
import { TOPIC_TYPES } from '@/constants/topic-types';

class MaterialCard extends Component {
  static propTypes = {
    addToFavorites: PropTypes.func,
    isCheckCoursePlan: PropTypes.bool,
    isCheckEnd: PropTypes.bool,
    coverFileUuid: PropTypes.string,
    domainCompany: PropTypes.object,
    eventTopicInfo: PropTypes.func,
    isExternalLogin: PropTypes.bool,
    getFile: PropTypes.func,
    getTopicById: PropTypes.func,
    isInFavorites: PropTypes.bool,
    isInPlan: PropTypes.bool,
    name: PropTypes.string,
    onStudyPlanClick: PropTypes.func,
    planStatus: PropTypes.object,
    removeFromFavorites: PropTypes.func,
    startDate: PropTypes.string,
    startTime: PropTypes.string,
    status: PropTypes.string,
    topic: PropTypes.object,
    type: PropTypes.object,
    onAddToPlanNow: PropTypes.func,
    checkPreviousStudyPlans: PropTypes.func,
    currentPaidTopicName: PropTypes.string,
    currentPaidTopicId: PropTypes.number,
    previousStudyPlans: PropTypes.object,
    clearPlan: PropTypes.func,
    checkPreviousStudyPlansFunc: PropTypes.func,
    clickTopicId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      cover:
        props.topic.type && isCorpCourseWithOrWithoutApprove(props.topic.type.id)
          ? this.props.corpCourseBackground || getDefaultMaterialImage(props.topic.type.id)
          : TOPIC_IMAGE.find(type => type.id === props.topic.type.id).image,
      schedule: null,
      readyToBackground: false,
      isAudienceConfirmationModalOpen: false,
      isModalCurrentPaidTopicOpen: false,
      isModalPaidTopicRestrictionOpen: false,
      previousStudyPlans: {},
      clickTopicId: null,
    };
  }

  componentDidMount() {
    const { topic } = this.props;
    this.getTopicInfo(topic);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.corpCourseBackground !== prevProps.corpCourseBackground) {
      const { topic } = this.props;
      if (!topic.thumbFileUuid && isCorpCourseWithOrWithoutApprove(topic.type.id)) {
        const cover = this.props.corpCourseBackground || getDefaultMaterialImage(props.topic.type.id);
        this.setState({ cover });
      }
    }
  }

  checkPreviousStudyPlansFunc = topic => {
    if (isAnyCourseOrConference(topic.type.id)) {
      this.props.checkPreviousStudyPlans(topic.id);
      this.setState({ clickTopicId: topic.id });
      return true;
    }
    return false;
  };

  handleStudyPlanClick = () => {
    const { onStudyPlanClick, topic, t } = this.props;

    const isEventType = topic?.type?.id === 8;

    if (topic.isTopicFull && isEventType) {
      message.warning(t('topicFull'));
      return;
    }

    const withNoEvents = Utils.isWithoutEvents(topic);

    if (onStudyPlanClick) {
      onStudyPlanClick(topic.id, topic.type, topic.startDate, {
        id: topic.id,
        independent: topic.independent,
        startDate: topic.startDate,
        topicCost: topic.cost,
        type: topic.type,
        link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
        eventCount: topic.futureEvents,
        topicComment: topic.comment,
        duration: Number(topic.duration),
        withNoEvents,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.previousStudyPlans?.previousStudyPlansCheckResult !==
        this.props.previousStudyPlans?.previousStudyPlansCheckResult &&
      newProps.topic.id === this.state.clickTopicId
    ) {
      const availableCreation = newProps.previousStudyPlans?.previousStudyPlansCheckResult?.availableCreation;
      const currentStudyPlanEnded = newProps.previousStudyPlans?.previousStudyPlansCheckResult?.currentStudyPlanEnded;
      const currentStudyPlan = newProps.previousStudyPlans?.previousStudyPlansCheckResult?.currentStudyPlan > 0;

      if (!availableCreation && !currentStudyPlanEnded && currentStudyPlan) {
        this.setState({ isModalCurrentPaidTopicOpen: true, currentTopicId: newProps.topic.id });
      }
      if (!availableCreation && currentStudyPlanEnded && currentStudyPlan) {
        this.setState({ isModalPaidTopicRestrictionOpen: true, currentTopicId: newProps.topic.id });
      }
      if (availableCreation) {
        this.handleStudyPlanClick();
      }
    }
  }

  handleModalConfirm = () => {
    const { topic } = this.props;
    this.setState({ isAudienceConfirmationModalOpen: false });
    if (this.checkPreviousStudyPlansFunc(topic)) {
      return;
    }
    this.handleStudyPlanClick();
  };

  handleModalCancel = () => {
    this.setState({ isAudienceConfirmationModalOpen: false });
  };

  handleStudyPlanClickWithCheck = () => {
    const { topic } = this.props;
    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      this.setState({ isAudienceConfirmationModalOpen: true });
      return;
    }
    if (this.checkPreviousStudyPlansFunc(topic)) {
      return;
    }
    this.handleStudyPlanClick();
  };

  handleModalCurrentPaidTopicClose = () => {
    this.setState({ isModalCurrentPaidTopicOpen: false });
    this.props.clearPlan();
  };

  handleModalPaidTopicRestrictionClose = () => {
    this.setState({ isModalPaidTopicRestrictionOpen: false });
    this.props.clearPlan();
  };

  getTopicInfo = topic => {
    if (this.props.isExternalLogin && topic.scope !== 'EXTERNAL') {
      const topicImage = TOPIC_IMAGE.find(type => type.id === 404);
      this.setState({ cover: topicImage && topicImage.image });
    } else {
      const thumbUuid = topic.thumbFileUuid;
      if (thumbUuid) {
        this.props.getFile(thumbUuid, blob => {
          const cover = URL.createObjectURL(blob);
          if (cover) {
            this.setState({ cover, readyToBackground: true });
          }
        });
      } else if (isCorpCourseWithOrWithoutApprove(topic.type.id)) {
        const cover = this.props.corpCourseBackground || getDefaultMaterialImage(this.props.topic.type.id);
        this.setState({ cover });
      }
    }
    if (topic.type && (isCorpCourseWithOrWithoutApprove(topic.type.id) || topic.type.id === 20) && topic.event) {
      this.props.eventTopicInfo(topic.event.id, schedule => this.setState({ schedule }));
      this.setState({ eventNotFound: false });
    } else {
      this.setState({ eventNotFound: true });
    }
  };

  render() {
    const {
      topic,
      name,
      type,
      planStatus,
      status,
      isCheckEnd,
      isInPlan,
      isCheckCoursePlan,
      isExternalLogin,
      isInFavorites,
      openDate,
      t,
    } = this.props;

    const {
      cover,
      isAudienceConfirmationModalOpen,
      isModalCurrentPaidTopicOpen,
      isModalPaidTopicRestrictionOpen,
      currentPaidTopicName,
      currentPaidTopicId,
    } = this.state;

    const withNoEvents = Utils.isWithoutEvents(topic);

    const topicType = type && _.find(TOPIC_TYPES(this.props.t), item => item.id === type.id);
    const addToFavorites = () => this.props.addToFavorites(topic.id);
    const removeFromFavorites = () => this.props.removeFromFavorites(topic.id);

    const modal = isAudienceConfirmationModalOpen && (
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={this.handleModalConfirm}
        onCancel={this.handleModalCancel}
      />
    );

    const modalCurrentPaidTopic = (
      <ModalCurrentPaidTopic
        open={isModalCurrentPaidTopicOpen}
        onClose={this.handleModalCurrentPaidTopicClose}
        currentPaidTopicName={currentPaidTopicName}
        currentPaidTopicId={currentPaidTopicId}
      />
    );

    const modalPaidTopicRestriction = (
      <ModalPaidTopicRestriction
        open={isModalPaidTopicRestrictionOpen}
        onClose={this.handleModalPaidTopicRestrictionClose}
      />
    );

    const toMyCollectionButton = isInFavorites ? (
      <Button
        className={classNames(css['App-btn'], css['App-btn-ghost'], css['App-favorite'], {
          [css['single-heart-position']]: topic.scope === 'INTERNAL' && !isExternalLogin,
        })}
        type='link'
        onClick={removeFromFavorites}
        icon={<HeartFilled className={css['App-btn-icon']} />}
        size='large'
      ></Button>
    ) : (
      <Button
        className={classNames(css['App-btn'], css['App-btn-ghost'], {
          [css['single-heart-position']]: topic.scope === 'INTERNAL' && !isExternalLogin,
        })}
        onClick={addToFavorites}
        type='link'
        icon={<HeartOutlined className={css['App-btn-icon']} />}
        size='large'
      ></Button>
    );

    const isEndedCourse = dayjs(`${topic.endDate} ${topic.endTime}`).isSameOrBefore(dayjs());

    const addToPlanBlock = withNoEvents ? (
      <div className={css['App-btn-container']}>
        <Button
          className={classNames(css['App-btn'], css['App-btn-first'])}
          onClick={this.props.onAddToPlanNow}
          type='primary'
          disabled={status === 'DISABLED'}
          size='large'
        >
          {t('addToPlanNow')}
        </Button>
        <Button
          className={css['App-btn']}
          onClick={this.handleStudyPlanClick}
          type='primary'
          disabled={status === 'DISABLED'}
          size='large'
        >
          {t('addToPlanLater')}
        </Button>
      </div>
    ) : (
      <Button
        className={css['App-btn']}
        onClick={this.handleStudyPlanClickWithCheck}
        type='primary'
        disabled={status === 'DISABLED'}
        size='large'
      >
        {t('addToPlan')}
      </Button>
    );

    const retakeTopic = topic?.userPlanType === ADD_REPEAT;

    const actionButtons =
      topic.scope === 'INTERNAL' && !isExternalLogin ? (
        <div>{toMyCollectionButton}</div>
      ) : (status === 'DISABLED' && isCheckCoursePlan) || isCheckEnd || isEndedCourse ? (
        <div className={css['App-btn-group']}>
          <Button
            className={classNames(css['App-btn'], css['App-btn-minWidth'], css['MaterialCard-noActive-button'])}
            type='primary'
            disabled
            size='large'
          >
            <div className={css['App-btn-noActive']} />
            {t('unActive')}
          </Button>
          {toMyCollectionButton}
        </div>
      ) : (planStatus && planStatus.planStatus) || isInPlan ? (
        <div className={css['App-btn-group']}>
          <Button
            className={classNames(css['App-btn'], { [css['App-btn-success']]: !retakeTopic })}
            type={retakeTopic && 'primary'}
            ghost={!retakeTopic}
            onClick={retakeTopic && this.handleStudyPlanClickWithCheck}
            size='large'
          >
            {t(Utils.checkTitleAddToPlan(topic))}
          </Button>
          {toMyCollectionButton}
        </div>
      ) : (
        <div className={css['App-btn-group']}>
          {addToPlanBlock}
          {toMyCollectionButton}
        </div>
      );
    const typeBadge = topicType && (
      <TypeBadge small noText portraitPlaceholder={false} type={topicType.code} name={type.name} />
    );

    const ellipsisName = name.length < 150 ? name : `${name.substring(0, 150)}...`;

    return (
      <div className={css.UserCard} key={topic.id}>
        <div className={css.UserCard__content}>
          <div
            className={css.UserCard__image}
            style={this.state.readyToBackground && cover ? { backgroundColor: topic.coverColor } : {}}
          >
            <img src={cover} alt='' />
            {typeBadge}
            {topic.cost > 0 && <div className={css.UserCard__cost}>{topic.cost} ₽</div>}
          </div>
          <div className={css.UserCard__title}>
            {!isExternalLogin && topic.scope === 'INTERNAL' ? (
              <Tooltip title={t('blocked')} placement='top'>
                <div className={css['UserCard__title-disabled']}>
                  <Tooltip title={name.length > 150 && name} placement='bottom'>
                    {ellipsisName}
                  </Tooltip>
                </div>
              </Tooltip>
            ) : (
              <Link to={`/collection-topics/${topic.id}`}>
                {name.length < 150 ? name : <Tooltip title={name}>{ellipsisName}</Tooltip>}
              </Link>
            )}
          </div>
        </div>
        {actionButtons}
        {modal}
        {modalCurrentPaidTopic}
        {modalPaidTopicRestriction}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  previousStudyPlans: selectStudyPlan(state),
});

const mapDispatchToProps = dispatch => ({
  checkPreviousStudyPlans: topicId => dispatch(checkPreviousStudyPlans(topicId)),
  clearPlan: () => dispatch(clearPlan()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['listItem', 'constants'])(MaterialCard));
