import React from 'react';
import { useTranslation } from 'react-i18next';

import css from '../Details/Details.module.scss';

export const TestDetailsInfo = ({ testData, block, currentResultStat }) => {
  const { t } = useTranslation('details');
  // const withPercentResult = testData.resultTest.type !== 2;
  const isPoll = testData.resultTest.poll;
  const resultPercent = testData.resultTest.countCorrectUserAnswers
    ? Math.floor((testData.resultTest.countCorrectUserAnswers * 100) / testData.resultTest.countQuestions)
    : 0;

  const retry =
    block &&
    block.isAvailableForReStart &&
    block.test.allowedCountTry &&
    block.test.allowedCountTry - block.test.numberTry;

  const retryDone = block && block.test.allowedCountTry > 1 && block.test.allowedCountTry <= block.test.numberTry;

  return (
    <div className={css['Details-resultInfo']}>
      <div className={css['Details-resultInfo-title']}>
        {t('youEndTest')}
        <div className={css['Details-resultInfo-title-subTitle']}>{`"${testData.blockName}"`}</div>
        {!isPoll && (
          <>
            <div className={css['Details-resultInfo-result']}>{t('result')}</div>
            <div className={css['Details-percent']}>{resultPercent}%</div>
          </>
        )}
      </div>
      <div>{currentResultStat}</div>
      {!isPoll && (
        <div className={css['Details-count']}>
          <span>
            {t('right', {
              countCorrectUserAnswers: testData.resultTest.countCorrectUserAnswers,
              countQuestions: testData.resultTest.countQuestions,
            })}
          </span>
        </div>
      )}
      <div className={css['Details-count']}>
        <span>{retry ? t('retry', { retry }) : retryDone && t('noRetry')}</span>
      </div>
    </div>
  );
};
