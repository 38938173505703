import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './Poll.module.scss';

export const Poll = props => {
  const { t } = useTranslation('poll');

  const setFinished = () => {
    if (props && props.setFinished && typeof props.setFinished === 'function') {
      props.setFinished(props.data.blockId);
    }
  };

  const render = () => {
    return (
      <Modal
        className={css['modal-poll-modal']}
        open={props.data.status !== 'FINISHED' && props.data.status !== 'WAITING'}
        footer={null}
        closable={false}
      >
        <div className={css['modal-poll']}>
          <div className={css['modal-poll__text']}>
            <p>{t('thanks')}</p>
            <p>{t('accepted')}</p>
          </div>
          <Button type='primary' size='large' onClick={setFinished}>
            {t('end')}
          </Button>
        </div>
      </Modal>
    );
  };

  return render();
};

Poll.propTypes = {
  setFinished: PropTypes.func,
  data: PropTypes.object,
};

export default Poll;
