/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getExecutiveTopicInfo } from '@/store/executive/actions';

import PropTypes, { resetWarningCache } from 'prop-types';

import css from './ExecutiveTaskData.module.scss';

class ExecutiveTaskData extends Component {
  static propTypes = {
    topicID: PropTypes.number,
    companyID: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      resultInfo: null, // Результаты
      activityInfo: null, // Деятельность
      indicatorInfo: null, // KPI Показатели
      needfile: false, // Требуется / Не требуется приложить файл
    };
  }

  componentDidMount() {
    this.props.getExecutiveTopicInfo(
      this.props.topicID,
      res => {
        this.setState({
          resultInfo: res.resultInfo,
          activityInfo: res.activityInfo,
          indicatorInfo: res.indicatorInfo,
          duration: res.duration,
          needfile: res.requiredFile,
        });
      },
      error => {
        // TODO: Обработка ошибки...
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className={css.ExecutiveTaskData}>
        <div>
          <h3>{t('activity')}</h3>
          {this.state.activityInfo}
        </div>

        <div>
          <h3>{t('results')}</h3>
          {this.state.resultInfo}
        </div>

        {this.state.indicatorInfo && (
          <div>
            <h3>{t('indicators')}</h3>
            {this.state.indicatorInfo}
          </div>
        )}

        <div>
          <h3>{t('duration')}</h3>
          {this.state.duration} {t('duration_min')}
        </div>

        <div>
          <h3>{t('needfile')}</h3>
          {this.state.needfile ? t('needfile_yes') : t('needfile_no')}
        </div>
      </div>
    );
  }
}

const mapStateToProps = null;
const mapActionsToProps = {
  getExecutiveTopicInfo,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('ExecutiveTaskData')(ExecutiveTaskData));
