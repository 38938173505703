// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-elements-button-more-styles-module__ql-picker-ZYea8w.user-src-components-elements-button-more-styles-module__ql-font-TQOMEr .user-src-components-elements-button-more-styles-module__ql-picker-label-ypF5qO[data-value="Montserrat"]:before, .user-src-components-elements-button-more-styles-module__ql-picker-ZYea8w.user-src-components-elements-button-more-styles-module__ql-font-TQOMEr .user-src-components-elements-button-more-styles-module__ql-picker-item-Chyh1b[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-elements-button-more-styles-module__ql-font-Montserrat-u6qqeK {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-elements-button-more-styles-module__button-more-FbFliB {
  background: none;
  border: none;
  height: 23px;
  display: flex;
}

.user-src-components-elements-button-more-styles-module__button-more-FbFliB:hover {
  cursor: pointer;
}

.user-src-components-elements-button-more-styles-module__button-more-FbFliB span {
  background-color: #999;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  transform: rotate(-90deg);
}

.user-src-components-elements-button-more-styles-module__button-more-FbFliB span:not(:last-child) {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/elements/button-more/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAMA;EAHE,eAAe;AACjB;;AAKA;EAFE,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B;;AAIA;EADE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.button-more {\n  display: flex;\n  background: none;\n  border: none;\n  height: 23px;\n}\n.button-more:hover {\n  cursor: pointer;\n}\n.button-more span {\n  width: 5px;\n  height: 5px;\n  transform: rotate(-90deg);\n  background-color: #999;\n  border-radius: 50%;\n}\n.button-more span:not(:last-child) {\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-elements-button-more-styles-module__ql-picker-ZYea8w`,
	"qlPicker": `user-src-components-elements-button-more-styles-module__ql-picker-ZYea8w`,
	"ql-font": `user-src-components-elements-button-more-styles-module__ql-font-TQOMEr`,
	"qlFont": `user-src-components-elements-button-more-styles-module__ql-font-TQOMEr`,
	"ql-picker-label": `user-src-components-elements-button-more-styles-module__ql-picker-label-ypF5qO`,
	"qlPickerLabel": `user-src-components-elements-button-more-styles-module__ql-picker-label-ypF5qO`,
	"ql-picker-item": `user-src-components-elements-button-more-styles-module__ql-picker-item-Chyh1b`,
	"qlPickerItem": `user-src-components-elements-button-more-styles-module__ql-picker-item-Chyh1b`,
	"ql-font-Montserrat": `user-src-components-elements-button-more-styles-module__ql-font-Montserrat-u6qqeK`,
	"qlFontMontserrat": `user-src-components-elements-button-more-styles-module__ql-font-Montserrat-u6qqeK`,
	"button-more": `user-src-components-elements-button-more-styles-module__button-more-FbFliB`,
	"buttonMore": `user-src-components-elements-button-more-styles-module__button-more-FbFliB`
};
export default ___CSS_LOADER_EXPORT___;
