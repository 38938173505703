export const GET_CAREER_PLAN_STATUS_START = 'GET_CAREER_PLAN_STATUS_START';
export const GET_CAREER_PLAN_STATUS_SUCCESS = 'GET_CAREER_PLAN_STATUS_SUCCESS';
export const GET_CAREER_PLAN_STATUS_FAIL = 'GET_CAREER_PLAN_STATUS_FAIL';

export const GET_CAREER_PLAN_START = 'GET_CAREER_PLAN_START';
export const GET_CAREER_PLAN_SUCCESS = 'GET_CAREER_PLAN_SUCCESS';
export const GET_CAREER_PLAN_FAIL = 'GET_CAREER_PLAN_FAIL';

export const CREATE_CAREER_PLAN_START = 'CREATE_CAREER_PLAN_START';
export const CREATE_CAREER_PLAN_SUCCESS = 'CREATE_CAREER_PLAN_SUCCESS';
export const CREATE_CAREER_PLAN_FAIL = 'CREATE_CAREER_PLAN_FAIL';

export const CREATE_CAREER_PLAN_TASK_START = 'CREATE_CAREER_PLAN_TASK_START';
export const CREATE_CAREER_PLAN_TASK_SUCCESS = 'CREATE_CAREER_PLAN_TASK_SUCCESS';
export const CREATE_CAREER_PLAN_TASK_FAIL = 'CREATE_CAREER_PLAN_TASK_FAIL';

export const APPROVE_CAREER_PLAN_START = 'APPROVE_CAREER_PLAN_START';
export const APPROVE_CAREER_PLAN_SUCCESS = 'APPROVE_CAREER_PLAN_SUCCESS';
export const APPROVE_CAREER_PLAN_FAIL = 'APPROVE_CAREER_PLAN_FAIL';

export const RETURN_CAREER_PLAN_START = 'RETURN_CAREER_PLAN_START';
export const RETURN_CAREER_PLAN_SUCCESS = 'RETURN_CAREER_PLAN_SUCCESS';
export const RETURN_CAREER_PLAN_FAIL = 'RETURN_CAREER_PLAN_FAIL';

export const GET_GOAL_CATEGORY_START = 'GET_GOAL_CATEGORY_START';
export const GET_GOAL_CATEGORY_SUCCESS = 'GET_GOAL_CATEGORY_SUCCESS';
export const GET_GOAL_CATEGORY_FAIL = 'GET_GOAL_CATEGORY_FAIL';

export const GET_CAREER_PLAN_SETTINGS_START = 'GET_CAREER_PLAN_SETTINGS_START';
export const GET_CAREER_PLAN_SETTINGS_SUCCESS = 'GET_CAREER_PLAN_SETTINGS_SUCCESS';
export const GET_CAREER_PLAN_SETTINGS_FAIL = 'GET_CAREER_PLAN_SETTINGS_FAIL';

export const CREATE_MENTOR_CAREER_PLAN_START = 'CREATE_MENTOR_CAREER_PLAN_START';
export const CREATE_MENTOR_CAREER_PLAN_SUCCESS = 'CREATE_MENTOR_CAREER_PLAN_SUCCESS';
export const CREATE_MENTOR_CAREER_PLAN_FAIL = 'CREATE_MENTOR_CAREER_PLAN_FAIL';
