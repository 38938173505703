/* eslint-disable */
import { D360_GET_REPORT_FAIL, D360_GET_REPORT_START, D360_GET_REPORT_SUCCESS } from './constants';

const initialState = {
  loading: false,
  report: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case D360_GET_REPORT_START:
      return { ...state, loading: true, report: [] };
    case D360_GET_REPORT_SUCCESS:
      return { ...state, loading: false, report: response.data };
    case D360_GET_REPORT_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
