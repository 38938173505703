/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_SEARCH_START = 'GET_SEARCH_START';
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const GET_SEARCH_FAIL = 'GET_SEARCH_FAIL';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_USER_SEARCH_TEXT = 'SET_USER_SEARCH_TEXT';
export const CLEAR_SEARCH_USER_TEXT = 'CLEAR_SEARCH_USER_TEXT';
export const CLEAR_SEARCH_TEXT = 'CLEAR_SEARCH_TEXT';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS';
export const SET_FIND_SEARCH_TEXT = 'SET_FIND_SEARCH_TEXT';

export const GET_SEARCH_BOOKS_START = 'GET_SEARCH_BOOKS_START';
export const GET_SEARCH_BOOKS_SUCCESS = 'GET_SEARCH_BOOKS_SUCCESS';
export const GET_SEARCH_BOOKS_FAIL = 'GET_SEARCH_BOOKS_FAIL';

const headers = {
  Accept: 'application/json',
};

const defaultToken = '';

export const getSearch = (value, type, section = '', isFavorite) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/search/${isFavorite ? 'user/' : ''}${section}${getQueryString({ value, type })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SEARCH_START, GET_SEARCH_SUCCESS, GET_SEARCH_FAIL],
});

export const clearSearchResult = () => ({
  type: CLEAR_SEARCH_RESULT,
});

export const clearSearchUserText = () => ({
  type: CLEAR_SEARCH_USER_TEXT,
});

export const clearSearchText = () => ({
  type: CLEAR_SEARCH_TEXT,
});

export const setSearchText = text => ({
  type: SET_SEARCH_TEXT,
  text,
});

export const setUserSearchText = text => ({
  type: SET_USER_SEARCH_TEXT,
  text,
});

export const clearSearchParams = () => ({
  type: CLEAR_SEARCH_PARAMS,
});

export const setFindSearchText = text => ({
  type: SET_FIND_SEARCH_TEXT,
  text,
});

export const setSearchParams = params => ({
  type: SET_SEARCH_PARAMS,
  params,
});

export const getSearchBooks = value => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/search/topic${getQueryString({
    value,
    topicTypeId: 16,
    qiwiLibrary: true,
    type: 'TOPIC',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SEARCH_BOOKS_START, GET_SEARCH_BOOKS_SUCCESS, GET_SEARCH_BOOKS_FAIL],
});
