import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { clearGroups, clearPersonalGroups, clearSelectedUser } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';

import { Layout } from 'antd';

import GroupsList from './parts/GroupsList/GroupsList';
import Messages from './parts/Messages/Messages';

import useIsMobile from '@shared/hooks/useIsMobile';

import PropTypes from 'prop-types';

import styles from './Chat.module.scss';

const Chat = () => {
  const { selectedUser } = useSelector(selectChat);

  const { socket } = useOutletContext();

  const [isMobile] = useIsMobile(769);
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.getElementById('Footer')) document.getElementById('Footer').style.display = 'none';

    return () => {
      if (document.getElementById('Footer')) document.getElementById('Footer').style.display = 'flex';
      dispatch(clearSelectedUser());
      dispatch(clearGroups());
      dispatch(clearPersonalGroups());
    };
  }, []);

  const renderMobile = () => {
    if (selectedUser) {
      return <Messages socket={socket} />;
    } else {
      return <GroupsList />;
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <GroupsList />
        <Messages socket={socket} />
      </>
    );
  };

  return (
    <Layout.Content className={styles.chat}>
      <div className={styles.chat__content}>{isMobile ? renderMobile() : renderDesktop()}</div>
    </Layout.Content>
  );
};

Chat.propTypes = {
  socket: PropTypes.shape({
    emit: PropTypes.func,
  }),
};

export default Chat;
