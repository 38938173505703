import { PlanStatus } from '@shared/constants/plan';
import {
  isConference,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourse,
  isCorpOrExtCourseOrConf,
  isEvent,
} from '@shared/utils/topic-utils';

import Resources from '@/resources';
import { isEmpty, some } from 'lodash';

import { STUDY_PLAN_DELETE_BY_ADMIN } from '@/constants/permissions';

export const planStatusForRequest = [
  'SCHEDULED, PLANNED, WAITING, APPROVED, APPROVED_BY_MANAGER, REJECTED, REJECTED_BY_MANAGER, WAITING_BY_USER, APPROVED_BY_USER, ON_REGISTRATION, REJECTED_BY_USER, IN_PROGRESS, SKIPPED_BY_USER, EXPIRED',
];

export const mentorStatuses = {
  ACTIVE: 'ACTIVE',
  WAITING_MENTOR_MEETING: 'WAITING_MENTOR_MEETING',
  WAITING_MENTOR: 'WAITING_MENTOR',
  WAITING_MENTOR_DECISION: 'WAITING_MENTOR_DECISION',
  REJECTED_BY_MENTOR: 'REJECTED_BY_MENTOR',
  REJECTED_BY_MENTEE: 'REJECTED_BY_MENTEE',
  ON_WAITING_LIST: 'ON_WAITING_LIST',
  FINISHED: 'FINISHED',
};

export const rejectReasonStatuses = {
  OTHER: 'OTHER',
  NO_HELP: 'NO_HELP',
  NO_COMPETENCE: 'NO_COMPETENCE',
  NO_TIME: 'NO_TIME',
};

export const tabsKeys = {
  education: 'education',
  mentoring: 'mentoring',
  tracks: 'tracks',
  pokaskills: 'pokaskills',
};

export const actions = (t, hasManyTracks = false, isMobile) => {
  return {
    reschedule: {
      name: 'reschedule',
      label: t('reschedule'),
      icon: Resources.icons.Move,
    },
    removeFromPlan: {
      name: 'removeFromPlan',
      label: isMobile ? t('removeFromPlanMobile') : t('removeFromPlan'),
      icon: Resources.icons.Cannot,
    },
    connectionWithQampus: {
      name: 'connectionWithQampus',
      label: t('connectionWithQampus'),
    },
    iWillGo: {
      name: 'iWillGo',
      label: t('iWillGo'),
      icon: Resources.icons.Check,
    },
    iCantGo: {
      name: 'iCantGo',
      label: t('iCantGo'),
      icon: Resources.icons.Cannot,
    },
    complete: {
      name: 'complete',
      label: t('complete'),
      icon: Resources.icons.Check,
    },
    didNotGo: {
      name: 'didNotGo',
      label: t('didNotGo'),
      icon: Resources.icons.Cannot,
    },
    goToStudy: {
      name: 'goToStudy',
      label: isMobile ? t('goToStudyMobile') : t('goToStudy'),
      icon: Resources.icons.Check,
    },
    goToTrack: {
      name: 'goToTrack',
      label: hasManyTracks ? t('lookTracks') : t('goToTrack'),
    },
  };
};

const isMaterialAvailable = tracks => {
  if (Array.isArray(tracks) && tracks.length > 0) {
    return some(tracks, { available: true });
  } else {
    return true;
  }
};

const isManyTracks = tracks => Array.isArray(tracks) && tracks.length > 1;

export const studyPlanBlocks = (t, disableDeletingFromPlan, currentUser, isMobile) => [
  {
    name: 'isFilter',
    title: t('isFilter'),
    params: {},
    actions: ({ typeId, status, showStudyButton, addedByAdmin, track }) => {
      switch (status) {
        case PlanStatus.IN_PROGRESS: {
          const buttonObject = {
            main: [],
            additional: [actions(t, false, isMobile).removeFromPlan],
          };

          const isCorpCourseOrEvent =
            isCorpOrExtCourseOrConf(typeId) || isEvent(typeId) || isCorporateCourseWithoutApprove(typeId);

          if (isCorpCourseOrEvent && !isEmpty(track)) {
            if (showStudyButton && isMaterialAvailable(track)) {
              buttonObject.main.push(actions(t, false, isMobile).goToStudy);
            }

            buttonObject.additional.push(actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack);
            return buttonObject;
          }

          if (showStudyButton && isMaterialAvailable(track) && !isEmpty(track)) {
            buttonObject.additional.push(actions(t, isManyTracks(track)).goToTrack);
            return buttonObject;
          }

          if (isCorpCourseOrEvent && isMaterialAvailable(track)) {
            if (showStudyButton) {
              buttonObject.main.push(actions(t, false, isMobile).goToStudy);
            }

            buttonObject.additional.push(actions(t).connectionWithQampus);
            return buttonObject;
          }

          if (showStudyButton && isMaterialAvailable(track)) {
            buttonObject.main.push(actions(t, false, isMobile).goToStudy);
            return buttonObject;
          }

          if (buttonObject.main.length !== 0 || buttonObject.additional.length !== 0) {
            return buttonObject;
          }

          return null;
        }

        case PlanStatus.PLANNED:
          if (isEvent(typeId) && !isEmpty(track)) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && !isEmpty(track)) {
            return { additional: [actions(t, isManyTracks(track)).goToTrack] };
          }
          if (isEvent(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN)) {
            return { additional: [actions(t, false, isMobile).removeFromPlan] };
          }
          return null;

        case PlanStatus.WAITING:
        case PlanStatus.APPROVED:
          if ((isCorpOrExtCourse(typeId) && !isEmpty(track)) || (isConference(typeId) && !isEmpty(track))) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && !isEmpty(track)) {
            return { additional: [actions(t, isManyTracks(track)).goToTrack] };
          }
          if (isCorpOrExtCourse(typeId)) {
            if (isConference(typeId)) {
              return {
                additional:
                  disableDeletingFromPlan && addedByAdmin
                    ? [actions(t).connectionWithQampus]
                    : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
                main: [],
              };
            } else {
              return {
                additional:
                  disableDeletingFromPlan && addedByAdmin
                    ? [actions(t).connectionWithQampus]
                    : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
                main: [actions(t).reschedule],
              };
            }
          }
          if (isConference(typeId)) {
            return {
              main: disableDeletingFromPlan && addedByAdmin ? [] : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN)) {
            return { additional: [actions(t, false, isMobile).removeFromPlan] };
          }
          return null;

        case PlanStatus.APPROVED_BY_MANAGER:
          if ((isCorpOrExtCourse(typeId) && !isEmpty(track)) || (isConference(typeId) && !isEmpty(track))) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && !isEmpty(track)) {
            return { additional: [actions(t, isManyTracks(track)).goToTrack] };
          }
          if (isCorpOrExtCourse(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
              main: [actions(t).reschedule],
            };
          }
          if (isConference(typeId)) {
            return {
              main: disableDeletingFromPlan && addedByAdmin ? [] : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN)) {
            return { additional: [actions(t, false, isMobile).removeFromPlan] };
          }
          return null;

        case PlanStatus.REJECTED_BY_MANAGER:
        case PlanStatus.REJECTED:
        case PlanStatus.REJECTED_BY_USER:
          if (
            (isCorpOrExtCourse(typeId) || isConference(typeId) || isCorporateCourseWithoutApprove(typeId)) &&
            !isEmpty(track)
          ) {
            return {
              main: [],
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && !isEmpty(track)) {
            return { additional: [actions(t, isManyTracks(track)).goToTrack] };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).connectionWithQampus],
              main:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule]
                  : [actions(t).reschedule, actions(t, false, isMobile).removeFromPlan],
            };
          }
          if (isConference(typeId)) {
            return {
              main: disableDeletingFromPlan && addedByAdmin ? [] : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN)) {
            return { additional: isEmpty(track) ? [actions(t, false, isMobile).removeFromPlan] : [] };
          }
          return null;

        case PlanStatus.WAITING_BY_USER:
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [actions(t).iWillGo, actions(t).iCantGo],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).reschedule, actions(t).connectionWithQampus],
              main: [actions(t).iWillGo, actions(t).iCantGo],
            };
          }
          if (isConference(typeId)) {
            return {
              main: [actions(t).iWillGo, actions(t).iCantGo],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.ON_REGISTRATION:
        case PlanStatus.APPROVED_BY_USER:
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: isMaterialAvailable(track) ? [actions(t).didNotGo] : [],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).reschedule, actions(t).connectionWithQampus],
              main: [actions(t).iCantGo],
            };
          }
          if (isConference(typeId)) {
            return {
              main: [actions(t).iCantGo],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.EXPIRED:
          if ((isCorpOrExtCourseOrConf(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [
                ...(isMaterialAvailable(track) ? [actions(t).didNotGo] : []),
                actions(t).connectionWithQampus,
                actions(t, isManyTracks(track)).goToTrack,
              ],
            };
          }
          if (isCorpOrExtCourseOrConf(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional: [actions(t).didNotGo, actions(t).connectionWithQampus],
            };
          }
          if (isEvent(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional: [actions(t).connectionWithQampus],
            };
          }
          if (showStudyButton && isMaterialAvailable(track)) {
            return { main: [actions(t, false, isMobile).goToStudy] };
          }
          return null;

        case PlanStatus.SCHEDULED:
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && !isEmpty(track)) {
            return { additional: [actions(t, isManyTracks(track)).goToTrack] };
          }
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).reschedule],
            };
          }
          if (currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN)) {
            return { additional: [actions(t, false, isMobile).removeFromPlan] };
          }
          if (showStudyButton && isMaterialAvailable(track)) {
            return { main: [actions(t, false, isMobile).goToStudy] };
          }
          return null;
        default:
          return null;
      }
    },
  },
  {
    name: 'inProgress',
    title: t('inProgress'),
    params: {
      planStatus: PlanStatus.IN_PROGRESS,
      sort: 'startDate,desc',
    },
    actions: ({ typeId, status, showStudyButton, track }) => {
      switch (status) {
        case PlanStatus.IN_PROGRESS: {
          const buttonObject = {
            main: [],
            additional: [actions(t, false, isMobile).removeFromPlan],
          };

          if (showStudyButton && isMaterialAvailable(track)) {
            buttonObject.main.push(actions(t, false, isMobile).goToStudy);
          }

          if (isCorpOrExtCourseOrConf(typeId) || isEvent(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            buttonObject.additional.push(actions(t).connectionWithQampus);

            if (!isEmpty(track)) {
              buttonObject.additional.push(actions(t, isManyTracks(track)).goToTrack);
            }
          }

          if (buttonObject.main.length !== 0 || buttonObject.additional.length !== 0) return buttonObject;

          return null;
        }

        default:
          return null;
      }
    },
  },
  {
    name: 'waitAction',
    title: t('waitAction'),
    params: {
      waiting: true,
      sort: 'info.value,desc',
    },
    actions: ({ typeId, status, showStudyButton, addedByAdmin, track }) => {
      switch (status) {
        case PlanStatus.WAITING:
        case PlanStatus.APPROVED:
          if ((isCorpOrExtCourse(typeId) && !isEmpty(track)) || (isConference(typeId) && !isEmpty(track))) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [],
            };
          }
          if (isCorpOrExtCourse(typeId)) {
            if (isConference(typeId)) {
              return {
                additional:
                  disableDeletingFromPlan && addedByAdmin
                    ? [actions(t).connectionWithQampus]
                    : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
                main: [],
              };
            } else {
              return {
                additional:
                  disableDeletingFromPlan && addedByAdmin
                    ? [actions(t).connectionWithQampus]
                    : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
                main: [],
              };
            }
          }
          if (isConference(typeId)) {
            return {
              main:
                disableDeletingFromPlan && addedByAdmin && isEmpty(track)
                  ? []
                  : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;

        case PlanStatus.APPROVED_BY_MANAGER:
          if ((isCorpOrExtCourse(typeId) && !isEmpty(track)) || (isConference(typeId) && !isEmpty(track))) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [],
            };
          }
          if (isCorpOrExtCourse(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
              main: [actions(t).reschedule],
            };
          }
          if (isConference(typeId)) {
            return {
              main:
                disableDeletingFromPlan && addedByAdmin && isEmpty(track)
                  ? []
                  : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;

        case PlanStatus.REJECTED_BY_MANAGER:
        case PlanStatus.REJECTED:
        case PlanStatus.REJECTED_BY_USER:
          if (
            (isCorpOrExtCourse(typeId) || isConference(typeId) || isCorporateCourseWithoutApprove(typeId)) &&
            !isEmpty(track)
          ) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).connectionWithQampus],
              main:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).reschedule],
            };
          }
          if (isConference(typeId)) {
            return {
              main: disableDeletingFromPlan && addedByAdmin ? [] : [actions(t, false, isMobile).removeFromPlan],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.WAITING_BY_USER:
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [actions(t).iWillGo, actions(t).iCantGo],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).reschedule, actions(t).connectionWithQampus],
              main: [actions(t).iWillGo, actions(t).iCantGo],
            };
          }
          if (isConference(typeId)) {
            return {
              main: [actions(t).iWillGo, actions(t).iCantGo],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.ON_REGISTRATION:
        case PlanStatus.APPROVED_BY_USER:
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
              main: [actions(t).iCantGo],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [actions(t).reschedule, actions(t).connectionWithQampus],
              main: [actions(t).iCantGo],
            };
          }
          if (isConference(typeId)) {
            return {
              main: [actions(t).iCantGo],
              additional: [actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.EXPIRED:
          if ((isCorpOrExtCourseOrConf(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [
                actions(t).didNotGo,
                actions(t).connectionWithQampus,
                actions(t, isManyTracks(track)).goToTrack,
              ],
            };
          }

          if (isCorpOrExtCourseOrConf(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional: [actions(t).didNotGo, actions(t).connectionWithQampus],
            };
          }

          if (isEvent(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional: [actions(t).connectionWithQampus],
            };
          }
          if (showStudyButton && isMaterialAvailable(track)) {
            return { main: [actions(t, false, isMobile).goToStudy] };
          }

          return null;

        case PlanStatus.SCHEDULED:
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).reschedule],
            };
          }
          if (showStudyButton && isMaterialAvailable(track)) {
            return { main: [actions(t, false, isMobile).goToStudy] };
          }
          return null;
        default:
          return null;
      }
    },
  },
  {
    name: 'planned',
    title: t('planned'),
    params: {
      planning: true,
      sort: ['startDate,asc', 'id,asc'],
    },
    actions: ({ typeId, status, showStudyButton, addedByAdmin, track }) => {
      switch (status) {
        case PlanStatus.WAITING:
        case PlanStatus.APPROVED:
          if (isConference(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t).connectionWithQampus],
            };
          }

          if (isConference(typeId) && !isEmpty(track)) {
            return {
              additional: disableDeletingFromPlan &&
                addedByAdmin && [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: disableDeletingFromPlan &&
                addedByAdmin && [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule, actions(t).connectionWithQampus]
                  : [
                      actions(t).reschedule,
                      actions(t, false, isMobile).removeFromPlan,
                      actions(t).connectionWithQampus,
                    ],
            };
          }
          if (isConference(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t).reschedule, actions(t).connectionWithQampus],
            };
          }
          return null;

        case PlanStatus.APPROVED_BY_MANAGER:
        case PlanStatus.REJECTED_BY_USER:
          if (isConference(typeId) && !isEmpty(track)) {
            return {
              additional: disableDeletingFromPlan &&
                addedByAdmin && [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: disableDeletingFromPlan &&
                addedByAdmin && [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule, actions(t).connectionWithQampus]
                  : [
                      actions(t).reschedule,
                      actions(t, false, isMobile).removeFromPlan,
                      actions(t).connectionWithQampus,
                    ],
            };
          }
          if (isConference(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t).reschedule, actions(t).connectionWithQampus],
            };
          }
          return null;
        case PlanStatus.ON_REGISTRATION:
        case PlanStatus.APPROVED_BY_USER:
          if (isEvent(typeId) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if ((isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: [
                actions(t).iCantGo,
                actions(t).connectionWithQampus,
                actions(t, isManyTracks(track)).goToTrack,
              ],
            };
          }
          if (isCorpOrExtCourse(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional: [
                actions(t).iCantGo,
                ...(isEmpty(track) ? [actions(t).reschedule] : []),
                actions(t).connectionWithQampus,
              ],
            };
          }
          if (isConference(typeId)) {
            return { additional: [actions(t).iCantGo, actions(t).connectionWithQampus] };
          }
          return null;
        case PlanStatus.PLANNED:
          if ((isEvent(typeId) || isCorporateCourseWithoutApprove(typeId)) && !isEmpty(track)) {
            return {
              additional: addedByAdmin && [actions(t).connectionWithQampus, actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (isEvent(typeId) || isCorporateCourseWithoutApprove(typeId)) {
            return {
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).connectionWithQampus]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).connectionWithQampus],
            };
          }
          return null;

        case PlanStatus.SCHEDULED:
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId) && !isEmpty(track)) {
            return {
              main:
                (showStudyButton && isMaterialAvailable(track) && [actions(t, false, isMobile).goToStudy]) ||
                (!showStudyButton && isMaterialAvailable(track) && [actions(t).complete]),
              additional: [actions(t, isManyTracks(track)).goToTrack],
            };
          }
          if (!isCorpOrExtCourseOrConf(typeId) && !isEvent(typeId)) {
            return {
              main: [
                showStudyButton && isMaterialAvailable(track)
                  ? actions(t, false, isMobile).goToStudy
                  : actions(t).complete,
              ],
              additional:
                disableDeletingFromPlan && addedByAdmin
                  ? [actions(t).reschedule]
                  : [actions(t, false, isMobile).removeFromPlan, actions(t).reschedule],
            };
          }
          if (showStudyButton && isMaterialAvailable(track)) {
            return { main: [actions(t, false, isMobile).goToStudy] };
          }
          return null;
        default:
          return null;
      }
    },
  },
  {
    name: 'archive',
    title: t('archive'),
    params: {
      sort: ['startDate,asc', 'id,asc'],
    },
  },
];
