import {
  GET_POSITION_FAIL,
  GET_POSITION_START,
  GET_POSITION_SUCCESS,
  GET_POSITIONS_FAIL,
  GET_POSITIONS_START,
  GET_POSITIONS_SUCCESS,
} from './constants';

const initialState = {
  positions: null,
  positionsTotal: null,
  positionsPageSize: null,
  positionsPageNumber: null,
  isLoading: false,
  error: false,
  searchPositions: [],
};

// eslint-disable-next-line
export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_POSITIONS_START:
      return {
        ...state,
        positions: null,
        positionsTotal: null,
        positionsPageSize: null,
        positionsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: response,
        positionsTotal: Number(headers.get('X-Total-Elements')) || response.totalElements || 0,
        positionsPageSize: Number(headers.get('X-Page-Size')) || response.size || 0,
        positionsPageNumber: Number(headers.get('X-Page-Number')) || response.number || 0,
        isLoading: false,
      };

    case GET_POSITIONS_FAIL:
    case GET_POSITION_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_POSITION_START:
      return { ...state, error: false, isLoading: true };

    case GET_POSITION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        searchPositions: response,
      };

    default:
      return state;
  }
};
