import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectChat } from '@/store/chat/selectors';

import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';

import PropTypes from 'prop-types';

import styles from './Messages.module.scss';

const Messages = ({ socket }) => {
  const { selectedUser } = useSelector(selectChat);
  const { t } = useTranslation('chat');

  return (
    <div className={styles.messages}>
      {!selectedUser ? (
        <div className={styles.messages__noUser}>
          <p className={styles.messages__text}>{t('noUser')}</p>
        </div>
      ) : (
        <div className={styles.messages__content}>
          <Header />
          <Main socket={socket} />
          <Footer socket={socket} />
        </div>
      )}
    </div>
  );
};

Messages.propTypes = {
  socket: PropTypes.shape({
    emit: PropTypes.func,
  }),
};

export default Messages;
