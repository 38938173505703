import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  approveCareerPlan,
  getCareerPlan,
  getCareerPlanSettings,
  getCareerPlanStatus,
  returnCareerPlan,
} from '@/store/career_plan/actions';
import { getTopicById } from '@/store/topics/actions';

import { selectCareerPlan } from '@/store/career_plan/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Flex,
  Layout,
  notification,
  Progress,
  Row,
  Spin,
  Steps,
} from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import { Content } from '@/components/Layout';
import TopicAddModal from '@/components/TopicAddModal';
import { DownCircleOutlined, InfoCircleFilled, InfoCircleTwoTone, ReadOutlined } from '@ant-design/icons';

import {
  APPROVED_BY_ADMIN,
  APPROVED_BY_MANAGER,
  APPROVED_BY_USER,
  DATE_FORMAT,
  FINISHED,
  INCOME_FORMAT,
  MAX_MENTORS_SELECTED,
  MENTORS,
  REJECTED,
  TASKS,
  TOPICS,
  WAITING_BY_USER,
} from './constants';
import CreateCareerPlanModal from './parts/CreateCareerPlanModal';
import MentorItem from './parts/MentorItem';
import MentorsCareerPlanModal from './parts/MentorsCareerPlanModal';
import TopicItem from './parts/TopicItem';
import TopicsCareerPlanModal from './parts/TopicsCareerPlanModal';

import useIsMobile from '@shared/hooks/useIsMobile';

import dayjs from 'dayjs';
import { isEmpty, map, some } from 'lodash';

import css from './CareerPlan.module.scss';

const CareerPlan = () => {
  const { t } = useTranslation('careerPlan');
  const { status, careerPlan, requiredIpr, isLoading, isLoadingMentors } = useSelector(selectCareerPlan);
  const { topic } = useSelector(selectTopics);
  const { currentUser } = useSelector(selectUsers);
  const [modalsVisible, setModalsVisible] = useState({
    creationPlan: false,
    mentors: false,
    tasks: false,
    topics: false,
  });
  const [currentCompetence, setCurrentCompetence] = useState(null);
  const [isEditTask, setIsEditTask] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [isTablet] = useIsMobile(993);
  const [isMobile] = useIsMobile(769);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HAS_MENTORING = currentUser.domainCompany?.hasMentors;

  useEffect(() => {
    if (!currentUser.domainCompany?.hasIpr) navigate('/');

    dispatch(getCareerPlan());
    dispatch(getCareerPlanSettings());
  }, []);

  const changeVisible = (name, competence) => {
    setModalsVisible(prevState => ({ ...prevState, [name]: !modalsVisible[name] }));

    if (competence) setCurrentCompetence(competence);

    if (name === TASKS) {
      if (isEditTask) setIsEditTask(false);

      if (modalsVisible[name]) {
        dispatch(getCareerPlan());
      }
    }
  };

  const validateCareerPlan = () => {
    const allTopics = map(careerPlan?.competences, TOPICS).flat();
    const allTasks = map(careerPlan?.competences, TASKS).flat();
    const allMentors = map(careerPlan?.competences, MENTORS).flat();

    const isInPlan = {
      topics: !some(allTopics, { planStatus: null }),
      tasks: !some(allTasks, { planStatus: null }),
      mentors: HAS_MENTORING && !some(allMentors, { mentoringStatusDto: null }),
    };
    const isAnyHas = allTopics?.length > 0 || allTasks?.length > 0 || allMentors?.length > 0;

    switch (requiredIpr) {
      case true:
        if (
          allTopics?.length > 0 &&
          isInPlan.topics &&
          allTasks?.length > 0 &&
          isInPlan.tasks &&
          (HAS_MENTORING ? allMentors?.length > 0 && isInPlan.mentors : true)
        ) {
          return true;
        } else {
          notification.error({ message: t('fillAll'), description: t('empty') });
        }

        break;

      case false:
        if (
          (allTopics?.length > 0 ? isInPlan.topics : true) &&
          (allTasks?.length > 0 ? isInPlan.tasks : true) &&
          (allMentors?.length > 0 ? HAS_MENTORING && isInPlan.mentors : true) &&
          isAnyHas
        ) {
          return true;
        } else {
          notification.error({ message: t('fillAll'), description: t('empty') });
        }

        break;

      default:
        return false;
    }
  };

  const onSubmitCareerPlan = () => {
    if (validateCareerPlan()) {
      dispatch(
        approveCareerPlan(careerPlan?.id, () => {
          dispatch(
            getCareerPlanStatus(() => {
              dispatch(getCareerPlan());
            })
          );
        })
      );
    }
  };

  const onReturnCareerPlan = () => {
    dispatch(
      returnCareerPlan(careerPlan?.id, () => {
        dispatch(
          getCareerPlanStatus(() => {
            dispatch(getCareerPlan());
          })
        );
      })
    );
  };

  const onEditTask = id => {
    dispatch(getTopicById(id, () => changeVisible(TASKS)));
    setIsEditTask(true);
  };

  const onOpenMaterialsModal = (competence, isTasks) => {
    changeVisible(TOPICS, competence);
    setIsTasks(isTasks);
  };

  const getCurrentStep = useMemo(() => {
    switch (status) {
      case FINISHED:
        return 4;
      case APPROVED_BY_ADMIN:
      case APPROVED_BY_MANAGER:
        return 3;
      case APPROVED_BY_USER:
      case REJECTED:
        return 1;

      default:
        return 0;
    }
  }, [status]);

  // Скрываем статус, если
  useEffect(() => {
    if (status) setShowAlert(false);
  }, [status]);

  const renderPage = useMemo(() => {
    const canEdit = status === WAITING_BY_USER || status === REJECTED;

    // Генерация элементов для Collapse
    const collapseItems = careerPlan?.competences?.map(competence => ({
      key: competence?.id,
      className: css['CareerPlan-container-competence-item'],
      label: (
        <>
          <p className={css['CareerPlan-container-competence-item-title']}>{competence?.name}</p>
          <p className={css['CareerPlan-container-competence-item-description']}>{t('competenceDescription')}</p>
        </>
      ),
      children: (
        <>
          {/* Практические задания */}
          <div className={css['CareerPlan-container-competence-item-header']}>
            {isEmpty(competence?.tasks) ? (
              <Flex gap={16}>
                <Checkbox checked />
                {t('emptyTasks')}
              </Flex>
            ) : (
              <span className={css['CareerPlan-container-competence-item-text']}>
                {t('tasks', { count: competence?.tasks?.length })}
              </span>
            )}
            {competence?.tasks?.length <= 50 && canEdit && (
              <Flex className={css['CareerPlan-container-competence-item-header-button']} align='center' gap={20}>
                <Button
                  type={competence?.tasks.length > 0 ? 'primary' : 'default'}
                  onClick={() => onOpenMaterialsModal(competence, true)}
                >
                  {t('choose')}
                </Button>
                <Button
                  type={competence?.tasks.length > 0 ? 'primary' : 'default'}
                  onClick={() => changeVisible(TASKS, competence)}
                >
                  {t('create')}
                </Button>
              </Flex>
            )}
          </div>
          <div className={css['CareerPlan-container-competence-content']}>
            {competence?.tasks?.length > 0 &&
              competence?.tasks.map(task => (
                <TopicItem
                  key={task?.id}
                  topic={task}
                  currentCompetence={competence}
                  careerPlan={careerPlan}
                  onEditTask={() => onEditTask(task.id)}
                  canEdit={canEdit}
                  isTask
                />
              ))}
          </div>

          {/* Менторы */}
          {HAS_MENTORING && (
            <>
              <Divider className={css['CareerPlan-container-divider']} />

              <div className={css['CareerPlan-container-competence-item-header']}>
                {isEmpty(competence?.mentors) ? (
                  <Flex gap={16}>
                    <Checkbox checked />
                    {t('emptyMentors', { max: MAX_MENTORS_SELECTED })}
                  </Flex>
                ) : (
                  <span className={css['CareerPlan-container-competence-item-text']}>
                    {t('mentors', { count: competence?.mentors?.length })}
                  </span>
                )}
                {canEdit && (
                  <Button
                    className={css['CareerPlan-container-competence-item-header-button']}
                    onClick={() => changeVisible(MENTORS, competence)}
                    type={competence?.mentors.length > 0 ? 'primary' : 'default'}
                    loading={isLoadingMentors}
                  >
                    {competence?.mentors?.length > 0 ? t('edit') : t('choose')}
                  </Button>
                )}
              </div>
              <div className={css['CareerPlan-container-competence-content']}>
                {Array.isArray(competence?.mentors) &&
                  competence?.mentors.length > 0 &&
                  competence?.mentors
                    .sort((a, b) => a.id - b.id)
                    .map(mentor => (
                      <MentorItem
                        key={mentor.id}
                        mentor={mentor}
                        competence={competence}
                        careerPlan={careerPlan}
                        canEdit={canEdit}
                      />
                    ))}
              </div>
            </>
          )}

          <Divider className={css['CareerPlan-container-divider']} />

          {/* Материалы */}
          <div className={css['CareerPlan-container-competence-item-header']}>
            {isEmpty(competence?.topics) ? (
              <Flex gap={16}>
                <Checkbox checked />
                {t('emptyTopics')}
              </Flex>
            ) : (
              <span className={css['CareerPlan-container-competence-item-text']}>
                {t('topics', { count: competence?.topics?.length })}
              </span>
            )}
            {canEdit && (
              <Button
                className={css['CareerPlan-container-competence-item-header-button']}
                onClick={() => onOpenMaterialsModal(competence, false)}
                type={competence?.topics.length > 0 ? 'primary' : 'default'}
              >
                {competence?.topics?.length > 0 ? t('edit') : t('choose')}
              </Button>
            )}
          </div>
          <div className={css['CareerPlan-container-competence-content']}>
            {competence?.topics?.length > 0 &&
              competence?.topics.map(topic => {
                return (
                  <TopicItem
                    key={topic?.id}
                    topic={topic}
                    currentCompetence={competence}
                    careerPlan={careerPlan}
                    isTask={false}
                    canEdit={canEdit}
                    onEditTask={() => onEditTask(topic.id)}
                  />
                );
              })}
          </div>
        </>
      ),
    }));

    switch (status) {
      case APPROVED_BY_USER:
      case FINISHED:
      case APPROVED_BY_ADMIN:
      case APPROVED_BY_MANAGER:
      case REJECTED:
      case WAITING_BY_USER:
        return (
          <div className={css['CareerPlan-container']}>
            {/* Создание и редактирование ИПР */}
            {modalsVisible?.creationPlan && (
              <CreateCareerPlanModal
                closeModal={() => changeVisible('creationPlan')}
                visible={modalsVisible?.creationPlan}
                data={status !== FINISHED && careerPlan}
              />
            )}

            {/* Выбор материала */}
            {modalsVisible.topics && (
              <TopicsCareerPlanModal
                visible={modalsVisible?.topics}
                closeModal={() => changeVisible(TOPICS)}
                currentCompetence={currentCompetence}
                data={careerPlan}
                isTasks={isTasks}
              />
            )}

            {/* Выбор менторов */}
            {modalsVisible?.mentors && (
              <MentorsCareerPlanModal
                closeModal={() => changeVisible(MENTORS)}
                visible={modalsVisible?.mentors}
                clearCompetence={() => setCurrentCompetence(null)}
                currentCompetence={currentCompetence}
                data={careerPlan}
              />
            )}

            {/* Создание/Редактирование развивающей задачи */}
            {modalsVisible.tasks && (
              <TopicAddModal
                visible={modalsVisible.tasks}
                onCancel={() => changeVisible(TASKS)}
                competenceId={currentCompetence?.id}
                careerPlan={careerPlan}
                topic={isEditTask && topic}
                editable={isEditTask}
                isIPR
                withoutDublicate
              />
            )}

            <div className={css['CareerPlan-container-steps']}>
              <Steps
                current={getCurrentStep}
                size='small'
                items={[
                  {
                    title: t('draft'),
                  },
                  {
                    title: t('inCoordination'),
                  },
                  {
                    title: t('accepted'),
                  },
                  {
                    title: t('inProgress'),
                  },
                  {
                    title: t('finished'),
                  },
                ]}
              />
            </div>

            <div className={css['CareerPlan-container-header']}>
              <div className={css['CareerPlan-container-header-top']}>
                <p className={css['CareerPlan-container-header-title']}>{t('summaryInfo')}</p>
                {(canEdit || status === FINISHED) && !isMobile && (
                  <Button
                    className={css['CareerPlan-container-header-button']}
                    onClick={() => changeVisible('creationPlan')}
                    type='primary'
                  >
                    {t(status === FINISHED ? 'startCareerPlan' : 'editCareerPlan')}
                  </Button>
                )}
              </div>

              <Divider className={css['CareerPlan-container-divider']} />

              {/* Header с общей информацией */}

              <div className={css['CareerPlan-container-header-bottom']}>
                {/* Дедлайн */}
                <Row className={css['CareerPlan-container-header-bottom-block']}>
                  <Col span={isTablet ? 24 : 3} className={css['CareerPlan-container-header-bottom-block-left']}>
                    <p>{t('deadline')}</p>
                  </Col>
                  <Col span={21}>
                    <p>{dayjs(careerPlan?.deadline, INCOME_FORMAT).format(DATE_FORMAT)}</p>
                  </Col>
                </Row>

                {/* Категория цели */}
                <Row className={css['CareerPlan-container-header-bottom-block']}>
                  <Col span={isTablet ? 24 : 3} className={css['CareerPlan-container-header-bottom-block-left']}>
                    {t('goalCategory')}
                  </Col>
                  <Col span={21}>
                    <p>{careerPlan?.goalCategory?.name}</p>
                  </Col>
                </Row>

                {/* Цель */}
                <Row className={css['CareerPlan-container-header-bottom-block']}>
                  <Col span={isTablet ? 24 : 3} className={css['CareerPlan-container-header-bottom-block-left']}>
                    {t('goal')}
                  </Col>
                  <Col span={21}>
                    <p>{careerPlan?.goal}</p>
                  </Col>
                </Row>

                {/* Комментарий */}
                {status === REJECTED && (
                  <Row className={css['CareerPlan-container-header-bottom-block']}>
                    <Col span={isTablet ? 24 : 3} className={css['CareerPlan-container-header-bottom-block-left']}>
                      {t('comment')}
                    </Col>
                    <Col span={21}>
                      <p>{careerPlan?.rejectComment}</p>
                    </Col>
                  </Row>
                )}

                {/* Прогресс */}
                {status === FINISHED && (
                  <Row className={css['CareerPlan-container-header-bottom-block']}>
                    <Col span={isTablet ? 24 : 3} className={css['CareerPlan-container-header-bottom-block-left']}>
                      {t('progress')}
                    </Col>
                    <Col span={isTablet ? 24 : 21} className={css['CareerPlan-container-header-bottom-block-progress']}>
                      <Progress percent={careerPlan?.progress} />
                    </Col>
                  </Row>
                )}
                {(canEdit || status === FINISHED) && isMobile && (
                  <Button
                    className={css['CareerPlan-container-header-button-isMobile']}
                    onClick={() => changeVisible('creationPlan')}
                    type='primary'
                  >
                    {t(status === FINISHED ? 'startCareerPlan' : 'editCareerPlan')}
                  </Button>
                )}
              </div>
            </div>

            <div>
              <Collapse
                className={css['CareerPlan-container-competence']}
                defaultActiveKey={careerPlan?.competences?.map(competence => competence.id)}
                expandIconPosition='end'
                items={collapseItems}
                expandIcon={({ isActive }) => <DownCircleOutlined rotate={isActive && 180} />}
                ghost
              />
            </div>
            <div className={css['CareerPlan-container-footer']}>
              {canEdit && (
                <Button type='primary' size='large' onClick={onSubmitCareerPlan}>
                  {t('send')}
                </Button>
              )}
              {status === APPROVED_BY_USER && (
                <Button type='primary' size='large' onClick={onReturnCareerPlan}>
                  {t('return')}
                </Button>
              )}
            </div>
          </div>
        );

      default:
        return (
          <>
            <div className={css['CareerPlan-container-empty']}>
              <CreateCareerPlanModal
                closeModal={() => changeVisible('creationPlan')}
                visible={modalsVisible?.creationPlan}
              />
              <CustomEmpty
                withoutTitle
                description={t('emptyDescription')}
                image={
                  <div className={css['CareerPlan-container-empty-icon']}>
                    <ReadOutlined />
                  </div>
                }
                isLoading={isLoading}
              >
                <Button type='primary' onClick={() => changeVisible('creationPlan')}>
                  {t('startCareerPlan')}
                </Button>
              </CustomEmpty>
            </div>
          </>
        );
    }
  }, [status, modalsVisible, careerPlan, isMobile, isTablet]);

  const changeAlertVisible = () => setShowAlert(!showAlert);

  return (
    <Layout>
      <Content className={css['CareerPlan-layout']}>
        <div className={css['CareerPlan-header']}>
          <div className={css['CareerPlan-header-title']}>
            <h1>{t('title')}</h1>
            <InfoCircleTwoTone onClick={changeAlertVisible} className={css['CareerPlan-header-icon']} />
          </div>
        </div>
        {showAlert && (
          <Alert
            className={css['CareerPlan-container-empty-alert']}
            afterClose={changeAlertVisible}
            closable
            type='info'
            message={<div className={css['CareerPlan-container-empty-alert-text']}>{t('infoText')}</div>}
            showIcon
            icon={<InfoCircleFilled className={css['CareerPlan-container-empty-alert-icon']} />}
          />
        )}
        <Spin spinning={isLoading}>
          <div className={css['CareerPlan-container']}>{renderPage}</div>
        </Spin>
      </Content>
    </Layout>
  );
};

export default CareerPlan;
