// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Checkbox-Checkbox-module__ql-picker-pV5opt.user-src-components-Checkbox-Checkbox-module__ql-font-WqK2PF .user-src-components-Checkbox-Checkbox-module__ql-picker-label-wm59Mf[data-value="Montserrat"]:before, .user-src-components-Checkbox-Checkbox-module__ql-picker-pV5opt.user-src-components-Checkbox-Checkbox-module__ql-font-WqK2PF .user-src-components-Checkbox-Checkbox-module__ql-picker-item-zSY6JJ[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Checkbox-Checkbox-module__ql-font-Montserrat-wQQvBt {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-kZWim4 {
  color: #000;
  border: none;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  font-size: 13px;
  display: inline-flex;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-kZWim4 > span {
  padding: 0;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-big-co1GCp {
  margin-bottom: 10px;
  font-size: 16px;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-label-lGXxgs {
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-value-label-lkNYK3 {
  color: #000;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-wrapper-uD5D3E {
  align-items: center;
  margin-left: 25%;
  display: flex;
}

.user-src-components-Checkbox-Checkbox-module__Checkbox-dark-vnEDEW {
  color: #2d3540;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,oBAAoB;AACtB;;AAMA;EAHE,UAAU;AACZ;;AAKA;EAFE,mBAAmB;EACnB,eAAe;AACjB;;AAIA;EADE,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAGA;EAAE,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AACA;EAEE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Checkbox {\n  display: inline-flex;\n  border-radius: 3px;\n  height: 18px;\n  width: 18px;\n  border: none;\n  color: #000;\n  font-size: 13px;\n}\n.Checkbox > span {\n  padding: 0;\n}\n.Checkbox-big {\n  font-size: 16px;\n  margin-bottom: 10px;\n}\n.Checkbox-label {\n  padding-right: 20px;\n  padding-left: 8px;\n  white-space: nowrap;\n}\n.Checkbox-value-label {\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #000;\n  padding-left: 8px;\n}\n.Checkbox-wrapper {\n  display: flex;\n  align-items: center;\n  margin-left: 25%;\n}\n.Checkbox-dark {\n  background-color: #e6e6e6;\n  border: 1px solid #e6e6e6;\n  color: #2d3540;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Checkbox-Checkbox-module__ql-picker-pV5opt`,
	"qlPicker": `user-src-components-Checkbox-Checkbox-module__ql-picker-pV5opt`,
	"ql-font": `user-src-components-Checkbox-Checkbox-module__ql-font-WqK2PF`,
	"qlFont": `user-src-components-Checkbox-Checkbox-module__ql-font-WqK2PF`,
	"ql-picker-label": `user-src-components-Checkbox-Checkbox-module__ql-picker-label-wm59Mf`,
	"qlPickerLabel": `user-src-components-Checkbox-Checkbox-module__ql-picker-label-wm59Mf`,
	"ql-picker-item": `user-src-components-Checkbox-Checkbox-module__ql-picker-item-zSY6JJ`,
	"qlPickerItem": `user-src-components-Checkbox-Checkbox-module__ql-picker-item-zSY6JJ`,
	"ql-font-Montserrat": `user-src-components-Checkbox-Checkbox-module__ql-font-Montserrat-wQQvBt`,
	"qlFontMontserrat": `user-src-components-Checkbox-Checkbox-module__ql-font-Montserrat-wQQvBt`,
	"Checkbox": `user-src-components-Checkbox-Checkbox-module__Checkbox-kZWim4`,
	"checkbox": `user-src-components-Checkbox-Checkbox-module__Checkbox-kZWim4`,
	"Checkbox-big": `user-src-components-Checkbox-Checkbox-module__Checkbox-big-co1GCp`,
	"checkboxBig": `user-src-components-Checkbox-Checkbox-module__Checkbox-big-co1GCp`,
	"Checkbox-label": `user-src-components-Checkbox-Checkbox-module__Checkbox-label-lGXxgs`,
	"checkboxLabel": `user-src-components-Checkbox-Checkbox-module__Checkbox-label-lGXxgs`,
	"Checkbox-value-label": `user-src-components-Checkbox-Checkbox-module__Checkbox-value-label-lkNYK3`,
	"checkboxValueLabel": `user-src-components-Checkbox-Checkbox-module__Checkbox-value-label-lkNYK3`,
	"Checkbox-wrapper": `user-src-components-Checkbox-Checkbox-module__Checkbox-wrapper-uD5D3E`,
	"checkboxWrapper": `user-src-components-Checkbox-Checkbox-module__Checkbox-wrapper-uD5D3E`,
	"Checkbox-dark": `user-src-components-Checkbox-Checkbox-module__Checkbox-dark-vnEDEW`,
	"checkboxDark": `user-src-components-Checkbox-Checkbox-module__Checkbox-dark-vnEDEW`
};
export default ___CSS_LOADER_EXPORT___;
