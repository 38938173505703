/* eslint-disable */

import React from 'react';

import useFormatCost from '@shared/hooks/useFormatCost';

import PropTypes from 'prop-types';

import css from './styles.module.scss';

const EventCost = ({ initialCost, currentUser }) => {
  const formattedCost = useFormatCost(initialCost, currentUser);

  return <div className={css['eventCost-badge']}>{formattedCost}</div>;
};

EventCost.propTypes = {
  event: PropTypes.any,
  currentUser: PropTypes.any,
};

export default EventCost;
