import React from 'react';

import QuestionCard from './QuestionCard';

import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './MatchingQuestion.module.scss';

const Droppable = ({ id, children, inResults }) => {
  const { isOver, setNodeRef } = useDroppable({ id });

  const root = document.body;
  const styleRoot = getComputedStyle(root);
  const color = `${styleRoot.getPropertyValue('--personalColor')}3A`;

  const style = {
    backgroundColor: isOver ? color : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classNames(css['droppable'], { [css['droppable-results']]: inResults })}
    >
      {children}
    </div>
  );
};

export const DroppableItem = ({ question, inResults }) => {
  return (
    <div className={classNames(css['droppable-item'], { [css['droppable-item-results']]: inResults })}>
      <QuestionCard question={question} inResults={inResults} />
    </div>
  );
};

Droppable.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Droppable;
