/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Modal from '@/components/Modal';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './CoinInfoModal.module.scss';

class CoinInfoModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    visible: PropTypes.bool,
  };

  render() {
    const { onCancel, visible, t } = this.props;

    return (
      <Modal open={visible} onCancel={onCancel} width={683} hideFooter zIndex={1005}>
        <div className={classNames(css['App-card-item'], css.HelpModal)}>
          <div className={css['HelpModal-header']}>{t('information')}</div>
          <div className={css['HelpModal-text']}>
            <ol type='1'>
              <li>{t('li1')}</li>
              <li>{t('li2')}</li>
              <li>{t('li3')}</li>
            </ol>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('coinInfoModal')(CoinInfoModal);
