import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import PropTypes from 'prop-types';

import css from './Modal.module.scss';

const ParticipationConfirmationModal = ({ open, onClose }) => {
  const { t } = useTranslation('nonTargetAudienceApprovalModal');

  const footer = (
    <div className={css['Modal-footer']}>
      <Button key='submit' type='primary' onClick={onClose} className={css['Modal-btn']} size='large'>
        {t('button')}
      </Button>
    </div>
  );

  return (
    <Modal open={open} onCancel={onClose} className={css['Modal']} footer={footer} centered={true}>
      <div className={css['Modal-body']}>
        <div className={css['Modal-title']}>{t('title')}</div>
        <div className={css['Modal-text']}>{t('thankYou')}</div>
        <div className={css['Modal-text']}>{t('mainText')}</div>
      </div>
    </Modal>
  );
};

ParticipationConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ParticipationConfirmationModal;
