import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Col, Layout, Row } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import MaterialCard from '@/components/MaterialCard';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { openDB } from 'idb';
import PropTypes from 'prop-types';

import css from './OfflineMaterials.module.scss';

import { COMPANY_IDB, IDB_NAME, TOPICS_IDB, USER_IDB } from '@/constants/IndexedDB';

const { Content } = Layout;

const OfflineMaterials = props => {
  const [dataIDB, setDataIDB] = useState({
    topics: [],
    user: {},
    company: {},
  });

  useEffect(() => {
    getAllDataIDB();
    // нужно будет пофиксить массив зависимостей!!!
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllDataIDB = async () => {
    const db = await openDB(IDB_NAME);
    if (
      !db ||
      (!db.objectStoreNames.contains(TOPICS_IDB) &&
        db.objectStoreNames.contains(USER_IDB) &&
        db.objectStoreNames.contains(COMPANY_IDB))
    ) {
      return;
    }

    const topics = await db.getAll(TOPICS_IDB);
    const userFromIDB = await db.getAll(USER_IDB);
    const companyFromIDB = await db.getAll(COMPANY_IDB);

    setDataIDB({
      ...dataIDB,
      topics,
      user: userFromIDB[0],
      company: companyFromIDB[0],
    });
  };

  const topicCards =
    dataIDB.topics.length &&
    dataIDB.topics.map((topic, key) => {
      return (
        <Col key={`topic_${key}${topic.id}`} className={css['OfflineMaterials-material-card']}>
          <MaterialCard
            offline
            topic={topic}
            userTopicId={topic.id}
            currentUser={dataIDB.user}
            domainCompany={dataIDB.user.domainCompany}
          />
        </Col>
      );
    });
  const main = topicCards.length ? (
    topicCards
  ) : (
    <div className={css['OfflineMaterials-material-cards-customEmpty']}>
      <CustomEmpty description={props.t('noTopics')} />
    </div>
  );
  return (
    <Content className={css['OfflineMaterials']}>
      <Row>
        <Col
          className={classNames(
            [css['OfflineMaterials-header']],
            !topicCards?.length && [css['OfflineMaterials-header-emptyContent']]
          )}
        >
          <h1>{props.t('title')}</h1>
        </Col>
        <Col className={css['OfflineMaterials-material-cards']}>{main}</Col>
      </Row>
    </Content>
  );
};

OfflineMaterials.propTypes = {
  getFile: PropTypes.func,
};

const mapStateToProps = createSelector([], () => ({}));

const mapActionsToProps = {
  getFile,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('OfflineMaterials')(OfflineMaterials));
