import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ExpandableInfo.module.scss';

const ExpandableInfo = ({ label, qa, data, isOpen, onToggle, onClose, t }) => {
  const maxLength = 34;

  const strng = data.join(', ');
  const commaIndex = strng.slice(0, maxLength).lastIndexOf(',');
  const hiddenCount = data.length - strng.slice(0, commaIndex !== -1 ? commaIndex : maxLength).split(',').length;

  return (
    <div className={classNames(css['Details-card-item'])}>
      <div className={classNames(css['Details-label'])}>{label}</div>
      <div data-qa={qa} className={classNames(css['Details-topic'])}>
        {strng.length < maxLength && strng}
        {strng.length > maxLength && !isOpen && (
          <>
            <span>{commaIndex !== -1 ? strng.slice(0, commaIndex) : strng.slice(0, maxLength)}</span>
            <span onClick={onToggle} className={classNames(css['Details-properties-button'])}>
              {t('showMore')} {hiddenCount}
            </span>
          </>
        )}

        {strng.length > maxLength && isOpen && (
          <>
            <span>{strng}</span>
            <span onClick={onClose} className={classNames(css['Details-properties-button'])}>
              {t('hide')}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

ExpandableInfo.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ExpandableInfo;
