import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { HiOutlineEmojiSad } from 'react-icons/hi';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './CustomEmpty.module.scss';

const CustomEmpty = ({ image, title, description, withoutTitle, children, className, isLoading = false }) => {
  const { t } = useTranslation('customEmpty');

  // Рендер картинки
  const imageRender = () => {
    if (!image) return <HiOutlineEmojiSad size={100} />; // Дефолтное изображение

    if (typeof image === 'string') {
      return <img className={css['CustomEmpty_container_image']} src={image} alt='empty' />;
    } else {
      return image;
    }
  };

  return (
    <div className={classNames(css['CustomEmpty_container'], className)}>
      {isLoading ? (
        <Spin spinning />
      ) : (
        <>
          {imageRender()}
          {!withoutTitle && <p className={css['CustomEmpty_container_title']}>{title ?? t('defaultTitle')}</p>}
          <p className={css['CustomEmpty_container_description']}>{description ?? t('defaultDescription')}</p>
          {children}
        </>
      )}
    </div>
  );
};

CustomEmpty.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Картинка
  title: PropTypes.string, // Заголовок
  description: PropTypes.string, // Описание
  withoutTitle: PropTypes.bool, // При необходимости можно убрать заголовок
  children: PropTypes.any, // При необходимости добавить любой другой компонент в контейнер
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default CustomEmpty;
