import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  clearFoundGroups,
  clearSearchUsers,
  getChatGroups,
  getChatMentorGroups,
  searchGroups,
  searchUsers,
} from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Input } from 'antd';

import Aside from '../Aside/Aside';
import GroupElement from './components/GroupElement';
import NewGroup from './components/NewGroup';
import PersonalGroupElement from './components/PersonalGroupElement';
import SearchElement from './components/SearchElement';

import useDebounce from '@shared/hooks/useDebounce';

import styles from './GroupsList.module.scss';

const GroupsList = () => {
  const [searchValue, setSearchValue] = useState(undefined);

  const location = useLocation();
  const dispatch = useDispatch();
  const debounceSearchValue = useDebounce(searchValue, 700);

  const { t } = useTranslation('chat');
  const { foundUsers, selectedUser, groups, foundGroups } = useSelector(selectChat);
  const { currentUser } = useSelector(selectUsers);

  const isPersonal = useMemo(() => {
    return location.pathname.includes('chat/personal');
  }, [location.pathname]);

  const isMentoring = useMemo(() => {
    return !location.pathname.includes('personal') && !location.pathname.includes('groups');
  }, [location.pathname]);

  useEffect(() => {
    if (isMentoring) {
      const requestId = location.pathname.replace('/chat/', '');
      dispatch(getChatMentorGroups(requestId));
    } else {
      dispatch(getChatGroups(isPersonal));
    }
    dispatch(clearSearchUsers());
  }, [location.pathname]);

  useEffect(() => {
    if (debounceSearchValue) {
      if (isPersonal) {
        dispatch(searchUsers(debounceSearchValue, 0, true));
      } else {
        dispatch(searchGroups(debounceSearchValue));
      }
    } else {
      if (isPersonal) {
        dispatch(clearSearchUsers());
      } else {
        dispatch(clearFoundGroups());
      }
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    setSearchValue(undefined);
  }, [selectedUser]);

  const foundUsersFiltered = useMemo(() => {
    if (foundUsers?.length) {
      const idSetGroups = groups?.length ? new Set(groups?.map(group => group.userData.id)) : new Set();
      const idSetFound = new Set(foundUsers?.map(user => user.id));

      // Добавляю id currentUser - чтобы его нельзя было найти в поиске
      idSetGroups.add(currentUser.id);

      const hasGroups = groups?.length ? groups?.filter(group => idSetFound.has(group.userData.id)) : [];
      const notHasGroups = foundUsers?.filter(user => !idSetGroups.has(user.id));
      return [hasGroups, notHasGroups];
    } else {
      return [];
    }
  }, [foundUsers]);

  const renderGroups = () => {
    if (foundUsersFiltered.length) {
      return (
        <>
          {foundUsersFiltered[0]?.map((group, index) => (
            <PersonalGroupElement key={index} data={group} />
          ))}
          {!!foundUsersFiltered[0].length && !!foundUsersFiltered[1].length && (
            <div className={styles.groups__global}>{t('inCompany')}</div>
          )}
          {foundUsersFiltered[1]?.map((user, index) => (
            <SearchElement key={index} data={user} />
          ))}
        </>
      );
    }

    if (foundGroups.length) {
      return foundGroups?.map((group, index) => <GroupElement key={index} data={group} />);
    }

    if (!foundUsers?.length && searchValue && debounceSearchValue) {
      return (
        <p
          dangerouslySetInnerHTML={{ __html: t(isPersonal ? 'notFoundUsers' : 'notFoundGroups') }}
          className={styles.groups__notFound}
        />
      );
    }

    if (groups?.length) {
      if (isPersonal || isMentoring) {
        return groups?.map((group, index) => (
          <PersonalGroupElement isMentoring={isMentoring} key={index} data={group} />
        ));
      } else {
        return groups?.map((group, index) => <GroupElement key={index} data={group} />);
      }
    }

    return null;
  };

  return (
    <div className={styles.groups}>
      <div className={styles.groups__header}>
        <p>{t('message')}</p>
        {!isPersonal && !isMentoring && <NewGroup />}
      </div>
      {!isMentoring && <Aside />}
      {!isMentoring && (
        <div className={styles.groups__search}>
          <Input.Search
            value={searchValue}
            placeholder={t('searchPlaceholder')}
            onChange={event => setSearchValue(event.target.value)}
          />
        </div>
      )}
      <div className={styles.groups__main}>{renderGroups()}</div>
    </div>
  );
};

export default GroupsList;
