import {
  CHANGE_WIDGET_NEWS_MODAL_VISIBLE,
  CLEAR_NEWS_ID,
  CREATE_NEWS_COMMENT_FAIL,
  CREATE_NEWS_COMMENT_START,
  CREATE_NEWS_COMMENT_SUCCESS,
  CREATE_NEWS_FAIL,
  CREATE_NEWS_START,
  CREATE_NEWS_SUCCESS,
  DELETE_NEWS_COMMENT_FAIL,
  DELETE_NEWS_COMMENT_START,
  DELETE_NEWS_COMMENT_SUCCESS,
  GET_COUNT_NEWS_COMMENT_FAIL,
  GET_COUNT_NEWS_COMMENT_START,
  GET_COUNT_NEWS_COMMENT_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  GET_NEWS_BY_ID_START,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_FAIL,
  GET_NEWS_FOR_WIDGET_FAIL,
  GET_NEWS_FOR_WIDGET_START,
  GET_NEWS_FOR_WIDGET_SUCCESS,
  GET_NEWS_START,
  GET_NEWS_SUCCESS,
  READ_NEWS_COMMENT_FAIL,
  READ_NEWS_COMMENT_START,
  READ_NEWS_COMMENT_SUCCESS,
  RECOMMENDED_USERS_NEWS_FAIL,
  RECOMMENDED_USERS_NEWS_START,
  RECOMMENDED_USERS_NEWS_SUCCESS,
  RESTORE_NEWS_COMMENT_FAIL,
  RESTORE_NEWS_COMMENT_START,
  RESTORE_NEWS_COMMENT_SUCCESS,
  SET_RATE_FAIL,
  SET_RATE_START,
  SET_RATE_SUCCESS,
  SET_VIEWED_FAIL,
  SET_VIEWED_START,
  SET_VIEWED_SUCCESS,
  UPDATE_NEWS_COMMENT_FAIL,
  UPDATE_NEWS_COMMENT_START,
  UPDATE_NEWS_COMMENT_SUCCESS,
  UPDATE_NEWS_VISIBILITY_FAIL,
  UPDATE_NEWS_VISIBILITY_START,
  UPDATE_NEWS_VISIBILITY_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

/** @param newsId: number  **/
/** @param page: number **/
export const getComments = (newsId, page) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/comment${getQueryString({
    newsId,
    page,
    size: 10,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [READ_NEWS_COMMENT_START, READ_NEWS_COMMENT_SUCCESS, READ_NEWS_COMMENT_FAIL],
});

/** @param params: { newsId: number, text: string }  **/
export const createComment = params => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CREATE_NEWS_COMMENT_START, CREATE_NEWS_COMMENT_SUCCESS, CREATE_NEWS_COMMENT_FAIL],
});

/**
 * @param commentId: number
 * @param params: { newsId: number, text: string }
 **/
export const updateComment = (commentId, params) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/comment/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [UPDATE_NEWS_COMMENT_START, UPDATE_NEWS_COMMENT_SUCCESS, UPDATE_NEWS_COMMENT_FAIL],
});

/**
 * @param commentId: number,
 **/
export const deleteComment = commentId => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/news/comment/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_NEWS_COMMENT_START, DELETE_NEWS_COMMENT_SUCCESS, DELETE_NEWS_COMMENT_FAIL],
});

export const restoreComment = commentId => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/comment/restore/${commentId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [RESTORE_NEWS_COMMENT_START, RESTORE_NEWS_COMMENT_SUCCESS, RESTORE_NEWS_COMMENT_FAIL],
});

export const getCountComments = newsId => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/comment/count${getQueryString({ newsId })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COUNT_NEWS_COMMENT_START, GET_COUNT_NEWS_COMMENT_SUCCESS, GET_COUNT_NEWS_COMMENT_FAIL],
});

export const getNews = (page = 0, params) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news${getQueryString({
    size: 10,
    sort: params.sort,
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    value: params.value,
    type: params.type,
  },
  types: [GET_NEWS_START, GET_NEWS_SUCCESS, GET_NEWS_FAIL],
});

export const getNewsForWidget = onSuccess => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news${getQueryString({
    size: 3,
    page: 0,
    sort: 'createdDate,desc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { viewed: false },
  types: [GET_NEWS_FOR_WIDGET_START, GET_NEWS_FOR_WIDGET_SUCCESS, GET_NEWS_FOR_WIDGET_FAIL],
  onSuccess,
});

/** @param newsId: number  **/
export const getNewsById = (newsId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/news/${newsId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_NEWS_BY_ID_START, GET_NEWS_BY_ID_SUCCESS, GET_NEWS_BY_ID_FAIL],
  onSuccess,
});

export const createNews = (params, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news/admin/create`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: params,
  types: [CREATE_NEWS_START, CREATE_NEWS_SUCCESS, CREATE_NEWS_FAIL],
  onSuccess,
  onError,
});

/** @param body: obj */

/** @param newsId: number */

export const setRate = (body, newsId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/${newsId}/rate`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [SET_RATE_START, SET_RATE_SUCCESS, SET_RATE_FAIL],
  onSuccess,
});

/** @param newsId: number */

/** @param userIds: number[] */
export const recommendNews = (newsId, userIds, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/news/${newsId}/recommend`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: { userIds },
  types: [RECOMMENDED_USERS_NEWS_START, RECOMMENDED_USERS_NEWS_SUCCESS, RECOMMENDED_USERS_NEWS_FAIL],
  onSuccess,
  onError,
});

export const setViewedNews = (newsId, body, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/${newsId}/view`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [SET_VIEWED_START, SET_VIEWED_SUCCESS, SET_VIEWED_FAIL],
  onSuccess,
});

export const changeVisibleWidgetNews = () => ({
  type: CHANGE_WIDGET_NEWS_MODAL_VISIBLE,
});

export const clearNewsId = () => ({
  type: CLEAR_NEWS_ID,
});

export const editNewsVisibility = (id, isVisible, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/news/admin/visible/${id}${getQueryString({ isVisible })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [UPDATE_NEWS_VISIBILITY_START, UPDATE_NEWS_VISIBILITY_SUCCESS, UPDATE_NEWS_VISIBILITY_FAIL],
  onSuccess,
  onError,
});
