// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-Chat-Chat-module__ql-picker-vOPGz4.user-src-pages-Chat-Chat-module__ql-font-ftugjE .user-src-pages-Chat-Chat-module__ql-picker-label-HBKpQe[data-value="Montserrat"]:before, .user-src-pages-Chat-Chat-module__ql-picker-vOPGz4.user-src-pages-Chat-Chat-module__ql-font-ftugjE .user-src-pages-Chat-Chat-module__ql-picker-item-CW4Gj0[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-Chat-Chat-module__ql-font-Montserrat-lqHJWx {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-Chat-Chat-module__chat-lBaApV {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100dvh - 150px);
  display: flex;
  max-width: none !important;
}

.user-src-pages-Chat-Chat-module__chat__content-mh5IRc {
  background: #fff;
  border-radius: 24px;
  width: 98%;
  height: 98%;
  display: flex;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .user-src-pages-Chat-Chat-module__chat-lBaApV {
    height: calc(100dvh - 200px);
  }

  .user-src-pages-Chat-Chat-module__chat__content-mh5IRc {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .user-src-pages-Chat-Chat-module__chat-lBaApV {
    height: calc(100dvh - 190px);
  }

  .user-src-pages-Chat-Chat-module__chat__content-mh5IRc {
    width: 100%;
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/Chat/Chat.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,4BAA4B;EAC5B,aAAa;EACb,0BAA0B;AAC5B;;AAMA;EAHE,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAMA;EACE;IAHE,4BAA4B;EAC9B;;EAKA;IAFE,WAAW;IACX,YAAY;EACd;AACF;;AAIA;EACE;IADE,4BAA4B;EAC9B;;EAGA;IAAE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.chat {\n  width: 100%;\n  height: calc(100dvh - 150px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  max-width: none !important;\n}\n.chat__content {\n  width: 98%;\n  height: 98%;\n  background: white;\n  border-radius: 24px;\n  display: flex;\n  overflow: hidden;\n}\n\n@media (min-width: 768px) and (width <= 1024px) {\n  .chat {\n    height: calc(100dvh - 200px);\n  }\n  .chat__content {\n    width: 100%;\n    height: 100%;\n  }\n}\n@media (width >= 320px) and (max-width: 768px) {\n  .chat {\n    height: calc(100dvh - 190px);\n  }\n  .chat__content {\n    width: 100%;\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-Chat-Chat-module__ql-picker-vOPGz4`,
	"qlPicker": `user-src-pages-Chat-Chat-module__ql-picker-vOPGz4`,
	"ql-font": `user-src-pages-Chat-Chat-module__ql-font-ftugjE`,
	"qlFont": `user-src-pages-Chat-Chat-module__ql-font-ftugjE`,
	"ql-picker-label": `user-src-pages-Chat-Chat-module__ql-picker-label-HBKpQe`,
	"qlPickerLabel": `user-src-pages-Chat-Chat-module__ql-picker-label-HBKpQe`,
	"ql-picker-item": `user-src-pages-Chat-Chat-module__ql-picker-item-CW4Gj0`,
	"qlPickerItem": `user-src-pages-Chat-Chat-module__ql-picker-item-CW4Gj0`,
	"ql-font-Montserrat": `user-src-pages-Chat-Chat-module__ql-font-Montserrat-lqHJWx`,
	"qlFontMontserrat": `user-src-pages-Chat-Chat-module__ql-font-Montserrat-lqHJWx`,
	"chat": `user-src-pages-Chat-Chat-module__chat-lBaApV`,
	"chat__content": `user-src-pages-Chat-Chat-module__chat__content-mh5IRc`,
	"chatContent": `user-src-pages-Chat-Chat-module__chat__content-mh5IRc`
};
export default ___CSS_LOADER_EXPORT___;
