import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from 'antd';

import Books from './assets/books.svg';

import css from './WelcomeModal.module.scss';

const WelcomeModal = props => {
  const { t } = useTranslation('WelcomeModalTD');
  const navigate = useNavigate();

  const TUTORIAL_ID = 1053;

  const onLook = () => {
    props.onClose();
    if (process.env.VITE_APP_IS_PROD) {
      navigate(`collection-topics/${TUTORIAL_ID}`);
    }
  };

  return (
    <Modal open={props.visible} footer={null} closable={false} width={750} className={css['WelcomeModalTD']}>
      <div className={css['WelcomeModalTD-modal']}>
        <img src={Books} alt='books' />
        <h1>{t('welcome')}</h1>
        <p className={css['WelcomeModalTD-description']}>{t('description')}</p>
        <div className={css['WelcomeModalTD-buttons']}>
          <Button className={css['WelcomeModalTD-button']} type='primary' onClick={onLook}>
            {t('buttonLook')}
          </Button>
          <Button className={css['WelcomeModalTD-button']} type='primary' onClick={props.onClose}>
            {t('buttonGo')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
