import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectMentors } from '@/store/mentors/selectors';

import Tag from '@/components/Tag';

import css from './Tabs.module.scss';

const TabAboutMentor = () => {
  const { t } = useTranslation('mentors');
  const { mentorData } = useSelector(selectMentors);
  const { position, competences, personalInfo, company, department } = mentorData;

  const tagCompetences =
    competences?.length &&
    competences.map(item => {
      return <Tag key={item.id} tag={item} noHover />;
    });

  return (
    <div className={css['Tab']}>
      <div className={css['Tab-about']}>
        {position && (
          <div className={css['Tab-about-position']}>
            <div className={css['Tab-about-position-label']}>{t('position')}</div>
            <div className={css['Tab-about-position-value']}>{position}</div>
          </div>
        )}

        {department && (
          <div className={css['Tab-about-department']}>
            <div className={css['Tab-about-department-label']}>{t('department')}</div>
            <div className={css['Tab-about-department-value']}>{department}</div>
          </div>
        )}

        {mentorData.function && (
          <div className={css['Tab-about-function']}>
            <div className={css['Tab-about-function-label']}>{t('function')}</div>
            <div className={css['Tab-about-function-value']}>{mentorData.function}</div>
          </div>
        )}

        {company && (
          <div className={css['Tab-about-company']}>
            <div className={css['Tab-about-company-label']}>{t('company')}</div>
            <div className={css['Tab-about-company-value']}>{company}</div>
          </div>
        )}

        <div className={css['Tab-about-competence']}>
          <div className={css['Tab-about-competence-label']}>{t('competence')}</div>
          <div className={css['Tab-about-competence-value']}>{tagCompetences}</div>
        </div>
        <div className={css['Tab-about-desc']}>
          <div className={css['Tab-about-desc-label']}>{t('aboutMentor')}</div>
          <div className={css['Tab-about-desc-value']}>{personalInfo}</div>
        </div>
      </div>
    </div>
  );
};

export default TabAboutMentor;
