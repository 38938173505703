/* eslint-disable */
/*

 */
import React, { Component } from 'react';

import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';

import FieldItemFileItem from './FieldItemFileItem';

import PropTypes from 'prop-types';

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }
  if (Array.isArray(field)) {
    return !field.length;
  }
  return !field;
};

export class FieldItemFile extends Component {
  static propTypes = {
    field: PropTypes.object,
    getFile: PropTypes.func,
    value: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  showFileList = () => {
    const files =
      Array.isArray(this.props.value) &&
      this.props.value.map(file => (
        <FieldItemFileItem key={file.uuid} uuid={file.uuid} getFile={this.props.getFile} name={file.name} />
      ));
    return files;
  };

  render() {
    const { value, field } = this.props;
    if (isFieldEmpty(value)) {
      return null;
    }
    return (
      <div className={css['Details-card-item']}>
        <div className={css['Details-label']}>{field.label}</div>
        <div>{this.showFileList()}</div>
      </div>
    );
  }
}
export default FieldItemFile;
