/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { D360LoadProject } from '@/store/d360/Project/actions';
import { getFile } from '@/store/files/actions';

import { Button, Card, Col, Divider, Progress, Row, Spin, Tabs, Tag, Tooltip } from 'antd';
import { EyeOutlined, ForwardOutlined, InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { FiX } from 'react-icons/fi';
import { ImStatsBars2 } from 'react-icons/im';

import { Content } from '../../../components/Layout';
import Report from '../Report';
import { extractUsername, isArray, isBool, isNum, isObject, isString, randomValueFromArray } from '../utils';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import dayjs from 'dayjs';
import _ from 'lodash';

import css from '../css/styles.module.scss';

import defaultPicture from '@/assets/images/360/default.png';

// Constants
const DATE_FORMAT = 'YYYY-MM-DD';

export const Project = props => {
  const { t } = useTranslation('D360_Project');
  const dispatch = useDispatch();

  const survey = useSelector(state => state.d360Project);
  const { report, loading: reportLoading } = useSelector(state => state.d360Report);

  const [id, setId] = useState(undefined);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [start, setStart] = useState('');
  const [finish, setFinish] = useState('');
  const [measurables, setMeasurables] = useState([]);
  const [author, setAuthor] = useState('');
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [answersCount, setAnswerCount] = useState(0);
  const [respondentProjectStatus, setRespondentProjectStatus] = useState('not_started');
  const [hasReport, setHasReport] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const [cover, isCoverLoading] = useLocalFileByUUID(survey?.project?.file_uuid, { defaultImg: defaultPicture });

  useEffect(() => {
    if (props?.params?.id) {
      setLoading(true);
      dispatch(
        D360LoadProject(Number.parseInt(props.params.id, 10), () => {
          setLoading(false);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isObject(survey)) {
      if ('project' in survey && isObject(survey.project)) {
        const project = survey.project;

        if ('id' in project && isNum(project.id)) {
          setId(project.id);
        }

        if ('has_report' in project && project.has_report === true) {
          setHasReport(true);
        } else {
          setHasReport(false);
        }

        if ('name' in project && isString(project.name)) {
          setName(project.name);
        }

        if ('description' in project && isString(project.description)) {
          setDescription(project.description);
        }

        if ('start' in project && isString(project.start)) {
          setStart(dayjs(project.start).format(DATE_FORMAT).toString());
        }

        if ('finish' in project && isString(project.finish)) {
          setFinish(dayjs(project.finish).format(DATE_FORMAT).toString());
        }

        if ('respondentQuestionCount' in project && isNum(project.respondentQuestionCount)) {
          setQuestionCount(project.respondentQuestionCount);
        }

        if ('respondentProjectResultCount' in project && isNum(project.respondentProjectResultCount)) {
          setAnswerCount(project.respondentProjectResultCount);
        }

        if ('author' in project && isObject(project.author)) {
          setAuthor(extractUsername(project.author));
        }

        if ('measurables' in project && isArray(project.measurables)) {
          setMeasurables(project.measurables);
        }

        if ('respondentProjectStatus' in project && isString(project.respondentProjectStatus)) {
          switch (project.respondentProjectStatus) {
            case 'not_started':
            case 'has_started':
            case 'has_finished':
              setRespondentProjectStatus(project.respondentProjectStatus);
              break;
          }
        }
      }
    }
  }, [survey.project, dispatch]);

  useEffect(() => {
    if (isNum(questionCount) && isNum(answersCount) && answersCount > 0) {
      setPercent(Math.round((answersCount * 100) / questionCount));
    } else {
      setPercent(0);
    }
  }, [questionCount, answersCount]);

  const randomTagColor = () => {
    const colors = [
      '#d84352',
      '#34ba9b',
      '#d46eaa',
      '#e7553e',
      '#3aadd8',
      '#e2b691',
      '#f5b946',
      '#4988d9',
      '#88c053',
      '#9479da',
      '#424852',
    ];

    return randomValueFromArray(colors);
  };

  const getButtonIcon = () => {
    return respondentProjectStatus === 'not_started' ? (
      <PlayCircleOutlined />
    ) : respondentProjectStatus === 'has_started' ? (
      <ForwardOutlined />
    ) : (
      <EyeOutlined />
    );
  };

  const renderTags = () => {
    return measurables.map((measurable, index) => {
      return (
        <Tooltip title={measurable.student.position} key={index}>
          <Tag className={css.ProjectTag} color={randomTagColor()}>
            {extractUsername(measurable.student)}
          </Tag>
        </Tooltip>
      );
    });
  };

  const tabsItems = [
    {
      key: '1',
      label: t('about'),
      children: <p style={{ whiteSpace: 'break-spaces', padding: '20px 0' }}>{description}</p>,
    },
    {
      key: '2',
      label: t('measurables'),
      children: renderTags(),
    },
  ];

  return (
    <Content>
      <Card className={css.Project} loading={loading}>
        <Row gutter={1}>
          <Col span={24}>
            <h1 className={css.ProjectTitle}>{name}</h1>
            <div className={css.ProjectIcon}>
              <ImStatsBars2 />
              &nbsp;&nbsp;{t('title')}&nbsp;360&deg;
            </div>
            <Link to={`/d360`}>
              <Button type='default' className={css.ProjectClose} shape='circle'>
                <FiX size='1rem' />
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col span={15}>
            <Spin spinning={isCoverLoading}>
              <img src={cover} className={css.ProjectImage} alt='' />
            </Spin>
            <Tabs items={tabsItems}></Tabs>
          </Col>

          <Col span={9} className={css.ProjectRightPane}>
            {_.isArray(measurables) && measurables.length > 0 && (
              <Link to={`/d360/${id}/survey`}>
                <Button type='primary' block={true} icon={getButtonIcon()}>
                  {respondentProjectStatus === 'not_started'
                    ? t('begin')
                    : respondentProjectStatus === 'has_started'
                      ? t('continue')
                      : t('result')}
                </Button>
              </Link>
            )}

            <Button
              style={{
                marginTop: 10,
                backgroundColor:
                  hasReport === false || (Array.isArray(report) && !report?.length) ? '#868686' : '#4d059c',
                color: 'white',
              }}
              block={true}
              icon={<InfoCircleOutlined />}
              disabled={hasReport === false || (Array.isArray(report) && !report?.length)}
              onClick={() => setShowReport(true)}
              loading={reportLoading}
            >
              {t('report')}
            </Button>

            <Row className={css.ProjectProgressRow}>
              <Col span={24}>
                <Progress type='line' showInfo={false} percent={percent} />
              </Col>
              <Col span={12}>{`${percent}% ${t('completed')}`}</Col>
              <Col span={12} align='right'>
                {answersCount}/{questionCount}
              </Col>
            </Row>

            <Divider />

            <Row className={css.ProjectDetailRow}>
              <Col span={24} className={css.ProjectDetailKey}>
                {t('author')}
              </Col>
              <Col span={24} className={css.ProjectDetailVal}>
                {author}
              </Col>
            </Row>

            <Row className={css.ProjectDetailRow}>
              <Col span={24} className={css.ProjectDetailKey}>
                {t('startDate')}
              </Col>
              <Col span={24} className={css.ProjectDetailVal}>
                {start}
              </Col>
            </Row>

            <Row className={css.ProjectDetailRow}>
              <Col span={24} className={css.ProjectDetailKey}>
                {t('endDate')}
              </Col>
              <Col span={24} className={css.ProjectDetailVal}>
                {finish}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {hasReport === true && <Report open={showReport} onCancel={() => setShowReport(false)} projectId={id} />}
    </Content>
  );
};

export default Project;
