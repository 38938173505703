/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './NumberInput.module.scss';

class NumberInputCost extends Component {
  static propTypes = {
    classNames: PropTypes.string,
    dark: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    showValue: PropTypes.string,
    value: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      showValue: props.value && +props.value,
    };
  }

  componentDidMount() {
    this.setState({
      showValue: this.props.value ? this.props.value.toLocaleString() : null,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value && this.state.value !== newProps.value) {
      this.setState({ value: newProps.value });
    }
  }

  handleChange = event => {
    const value = parseInt(event.target.value.replace(/\D/g, ''));
    const { name, onChange, min, max } = this.props;
    const newValue = isNaN(value) ? undefined : value;
    if ((typeof min === 'number' && newValue < min) || (typeof max === 'number' && newValue > max)) {
      return;
    }
    this.setState(
      { value: newValue, showValue: newValue && newValue.toLocaleString() },
      onChange({ name, value: newValue })
    );
  };

  render() {
    const { modificate, dark, name } = this.props;

    return (
      <div
        className={classNames(css.NumberInput, {
          [css.dark]: dark,
          [css.modificate]: modificate,
        })}
      >
        <Input
          name={name}
          data-qa='collectionCoastInput'
          placeholder='10 000'
          type='text'
          onChange={this.handleChange}
          value={this.state.showValue}
          maxLength={10}
          size='large'
        />
        <div className={css.labelInput}>{this.props.t('currency')}</div>
      </div>
    );
  }
}

export default withTranslation()(NumberInputCost);
