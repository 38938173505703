/* eslint-disable */
import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './User.module.scss';

const LEN_SHOW_TOOLTIP = 30;

export default class User extends Component {
  static propTypes = {
    active: PropTypes.bool,
    getFile: PropTypes.func,
    getPhoto: PropTypes.func,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    selected: PropTypes.bool,
    setAvaToCache: PropTypes.func,
    user: PropTypes.object,
  };

  static defaultProps = {
    onClick: () => {},
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: this.props.isLoading });
    }, 800);
    this.getFile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      setTimeout(() => {
        this.setState({ isLoading: nextProps.isLoading });
      }, 500);
    }
  }

  handleUserDelete = event => {
    event.stopPropagation();
    const { user, onDelete } = this.props;
    onDelete(user.id);
  };

  handleUserClick = event => {
    event.stopPropagation();
    const { user, onClick, onDelete } = this.props;
    if (!this.props.selected) {
      const userParams = { ...user, avatar: this.state.avatar };
      return onClick(userParams);
    }

    return onDelete(user.id);
  };

  getFile = () => {
    if (!this.props.user.avatar) {
      if (this.props.user.localPhotoUuid || this.props.user.photoUuid) {
        this.props.getFile(this.props.user.localPhotoUuid || this.props.user.photoUuid, blob => {
          const imageUrl = URL.createObjectURL(blob);
          this.setState({ avatar: imageUrl });
        });
      }
    }
  };

  // localPhotoUuid
  render() {
    const { active, selected } = this.props;
    const { isLoading } = this.state;
    const deleteButton = this.props.active ? (
      <button className={css['delete-button']} onClick={this.handleUserDelete}>
        <svg xmlns='http://www.w3.org/2000/svg' width='9' height='8' viewBox='0 0 18 17'>
          <path d='m2.143 1.143 13.903 13.903m-.189-13.903L1.954 15.046' stroke='currentColor' fill='none' />
        </svg>
      </button>
    ) : null;
    const avatarImg = active ? this.props.user.avatar : this.state.avatar;
    if (isLoading && active) return null;

    const showTooltip = this.props.user?.position?.length > LEN_SHOW_TOOLTIP;
    const positionElement = showTooltip ? (
      <Tooltip title={this.props.user.position} placement='topLeft'>
        <div className={css['User-position']}>{this.props.user.position}</div>
      </Tooltip>
    ) : (
      <div className={css['User-position']}>{this.props.user.position}</div>
    );

    const lastName = this.props.user?.lastName ? this.props.user.lastName : '';
    const firstName = this.props.user?.firstName ? this.props.user.firstName : '';
    const userName = `${lastName.substr(0, 1)}${firstName.substr(0, 1)}`;

    return (
      <div
        className={classNames(css.User, {
          [css.active]: active,
          [css.selectedUser]: selected,
        })}
        onClick={this.handleUserClick}
      >
        {deleteButton}
        <div className={css['User-component']}>
          {!active && (
            <div className={css['User-avatar']}>
              {avatarImg || active || !isLoading ? (
                avatarImg ? (
                  <img src={avatarImg || PortraitPlaceholder} alt='' />
                ) : (
                  <div className={css['User-abc-ava']}>{userName}</div>
                )
              ) : (
                <Skeleton duration={0.8} circle height={40} width={40} />
              )}
            </div>
          )}
          {!isLoading ? (
            <div>
              <div
                className={classNames(css['User-name'], {
                  [css['User-active']]: active,
                })}
              >
                {`${lastName} ${firstName}`}
              </div>
              {!active && this.props.user.position && positionElement}
            </div>
          ) : !active ? (
            <Skeleton duration={0.8} count={1} width={150} />
          ) : null}
          {!active && <div className={css['User-company']}>{this.props.user.company}</div>}
          {!active && (
            <div
              className={classNames(css['User-selected'], {
                [css.selectedItem]: selected,
              })}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
                <path d='M4.148 7.883a1.016 1.016 0 0 0-1.452.01 1.055 1.055 0 0 0 .01 1.476l3.127 3.13a1.016 1.016 0 0 0 1.476-.035l6.819-7.513a1.055 1.055 0 0 0-.059-1.475 1.016 1.016 0 0 0-1.452.06l-6.098 6.72-2.37-2.373z' />
              </svg>
            </div>
          )}
        </div>
      </div>
    );
  }
}
