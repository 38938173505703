import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import { useDispatch, useSelector } from 'react-redux';

import {
  createMentorComment,
  deleteMentorComment,
  getMentorComments,
  getMentorCommentsCount,
  restoreMentorComment,
  updateMentorComment,
} from '@/store/mentors/actions';

import { selectMentors } from '@/store/mentors/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar, Button, Input } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import CommentRateMentor from '@/components/Mentoring/CommentRateMentor';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from './Tabs.module.scss';

const { TextArea } = Input;

const TabCommentsMentor = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const { comments, mentorsCommentsTotal, mentorsCommentsCurrentPage, isLoading } = useSelector(selectMentors);
  const { currentUser } = useSelector(selectUsers);

  const [currentAvatar] = useLocalFileByUUID(currentUser?.localPhotoUuid, { defaultImg });

  const createComment = () => {
    const params = {
      mentorId: props.mentorId,
      text: comment,
    };

    dispatch(
      createMentorComment(params, () => {
        setComment('');
        dispatch(getMentorCommentsCount(props.mentorId));
      })
    );
  };

  const deleteComment = commentId => {
    dispatch(deleteMentorComment(commentId));
  };

  const tabEditComment = (commentId, params, func) => {
    dispatch(
      updateMentorComment(commentId, params, () => {
        func();
      })
    );
  };

  const tabRestoreComment = (commentId, func) => {
    dispatch(
      restoreMentorComment(commentId, () => {
        func();
      })
    );
  };

  const loadMore = () => {
    if (props.mentorId) {
      dispatch(getMentorComments(props.mentorId, mentorsCommentsCurrentPage + 1, 5));
    }
  };

  const renderComments =
    comments?.length &&
    comments.map((item, index) => {
      let userName = `${item.user.firstName || ''} ${item.user.lastName || ''}`;

      return (
        <CommentRateMentor
          userName={userName}
          text={item.text}
          date={item.createdDate}
          photoUuid={item.user.photoUuid}
          localPhotoUuid={item.user.localPhotoUuid}
          key={index}
          editable={item.editable}
          commentId={item.id}
          modified={item.modified}
          mentorId={props.mentorId}
          canDelete={true}
          deleteComment={deleteComment}
          tabEditComment={tabEditComment}
          tabRestoreComment={tabRestoreComment}
        />
      );
    });

  return (
    <div className={css['Tab']}>
      <div className={css['Tab-comments']}>
        <div className={css['Tab-comments-create']}>
          <Avatar className={css['Tab-comments-create-avatar']} src={currentAvatar} size={48} />
          <TextArea
            className={css['Tab-comments-create-area']}
            onChange={e => setComment(e?.target?.value)}
            placeholder={t('placeHolderComments')}
            rows={4}
            maxLength={1500}
            value={comment}
          />
        </div>

        <div className={css['Tab-comments-action']}>
          <Button disabled={!comment} onClick={createComment} type='primary'>
            {t('sendComment')}
          </Button>
        </div>
        <InfiniteScroll
          pageStart={mentorsCommentsCurrentPage}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={mentorsCommentsTotal > comments.length && !isLoading}
          threshold={300}
          useWindow
        >
          {comments?.length > 0 && renderComments}
        </InfiniteScroll>
      </div>
    </div>
  );
};

TabCommentsMentor.propTypes = {
  mentorId: PropTypes.number,
};

export default TabCommentsMentor;
