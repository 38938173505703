/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';

import { Alert, Layout } from 'antd';

import Comment from '../Comment';

import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import css from './MaterialsTabs.module.scss';

const { Content } = Layout;

class MaterialsTabsReview extends Component {
  static propTypes = {
    clearTopicMention: PropTypes.func,
    createTopicComment: PropTypes.func,
    currentUser: PropTypes.object,
    getFile: PropTypes.func,
    getTopicMentions: PropTypes.func,
    isLoading: PropTypes.bool,
    mentions: PropTypes.object,
    topic: PropTypes.object,
    updateMention: PropTypes.func,
    restoreComment: PropTypes.func,
    deleteComment: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      editableMention: 0,
      hideActions: true,
    };
    this.getTopicMentions = throttle(this.props.getTopicMentions, 1000);
  }

  componentDidMount() {
    this.props.clearTopicMention();
    this.getTopicMentions(this.props.topic.id, 0, true, 10, response => {
      this.setState({ hasMore: !!response.length });
    });
  }

  loadMore = () => {
    this.getTopicMentions(this.props.topic.id, this.props.mentions.mentionsPageNumber + 1, true, 10, response => {
      this.setState({ hasMore: !!response.length });
    });
  };

  editCommentStart = commentId => {
    this.setState({ editableMention: +commentId });
  };

  editCommentFinish = () => {
    this.setState({ editableMention: 0 });
  };

  compareNumeric = (a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
    return 0;
  };

  onCommentUpdate = (id, params, onSuccess) => {
    this.props.updateMention(
      id,
      {
        ...params,
        topicId: +this.props.topic.id,
      },
      onSuccess
    );
  };

  onHideActions = value => {
    this.setState({ hideActions: value });
  };

  render() {
    const arr = this.props.mentions && this.props.mentions.mentions;
    arr.sort(this.compareNumeric);
    const mentions =
      this.props.mentions &&
      this.props.mentions.mentions
        .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        .map(mention => (
          <Comment
            review
            currentUserId={this.props.currentUser.id}
            key={mention.id}
            getFile={this.props.getFile}
            updateComment={this.onCommentUpdate}
            editableComment={this.state.editableMention}
            editCommentStart={this.editCommentStart}
            editCommentFinish={this.editCommentFinish}
            currentUser={this.props.currentUser}
            comment={mention}
            onHideActions={this.onHideActions}
            hideActions={this.state.hideActions}
            topicId={this.props.topic.id}
            restoreComment={this.props.restoreComment}
            deleteComment={this.props.deleteComment}
          />
        ));
    return (
      <Content className={css.MaterialsTabsAboutBlock}>
        {!window.navigator.onLine && (
          <Alert message='Вы находитесь в режиме ОФФЛАЙН, просмотр данного контента недоступен' type='warning' />
        )}
        <div className={css['MaterialsTabsAbout-container']}>
          <InfiniteScroll
            className={css.MaterialsTabsAboutBlock}
            pageStart={this.props.mentions.mentionsPageNumber}
            initialLoad={false}
            loadMore={this.loadMore}
            hasMore={this.state.hasMore && !this.props.isLoading}
            threshold={300}
            useWindow
          >
            {mentions && mentions.length
              ? mentions
              : !this.props.isLoading && (
                  <div className={css['MaterialsTabsAbout-container-noInfo']}>{this.props.t('endMaterial')}</div>
                )}
          </InfiniteScroll>
        </div>
      </Content>
    );
  }
}

export default withTranslation('materialsTabsReview')(MaterialsTabsReview);
