/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Participant from './Participant';
import ParticipantsModal from './ParticipantsModal';

import PropTypes from 'prop-types';

import css from './Participants.module.scss';

class Participants extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    isFav: PropTypes.bool,
    participants: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    planStatus: PropTypes.string,
    topicId: PropTypes.string,
    totalParticipants: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      participantModalVisible: false,
      totalParticipants: props.totalParticipants,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.totalParticipants !== this.props.totalParticipants) {
      this.setState({ totalParticipants: newProps.totalParticipants });
    }
  }

  showAvatar = () => {
    let avatarList = [];
    const { isFav } = this.props;
    const avatarWithoutCurrentUser =
      this.props.participants &&
      Array.isArray(this.props.participants) &&
      this.props.participants.filter(user => {
        if (!isFav) {
          if ((user && user.user && user.user.id) !== this.props.currentUser.id) {
            return user;
          }
        } else if ((user && user.id) !== this.props.currentUser.id) {
          return user;
        }
      });
    if (avatarWithoutCurrentUser && avatarWithoutCurrentUser.length > 4) {
      avatarList = Array.isArray(avatarWithoutCurrentUser) && avatarWithoutCurrentUser.slice(0, 4);
    } else {
      avatarList = Array.isArray(avatarWithoutCurrentUser) && avatarWithoutCurrentUser.map(res => res);
    }
    const avatarFinelList =
      Array.isArray(avatarList) &&
      avatarList.map(res => {
        if (!isFav) {
          return <Participant key={`${res?.id}-${res?.user?.id}`} participant={res?.user} />;
        }
        return <Participant key={res.id} participant={res} />;
      });
    return avatarFinelList;
  };

  showLabel = () => {
    if (this.props.planStatus) {
      if (this.state.totalParticipants > 5) {
        return (
          <p className={css.Label} onClick={this.showParticipantModal}>
            {this.props.t('more2', { count: this.state.totalParticipants - 5 })}
          </p>
        );
      }
      return null;
    }
    if (this.state.totalParticipants > 4) {
      return (
        <p className={css.Label} onClick={this.showParticipantModal}>
          {this.props.t('more2', { count: this.state.totalParticipants - 4 })}
        </p>
      );
    }
    return null;
  };

  showParticipantModal = () => {
    this.setState({ participantModalVisible: true });
  };

  hideParticipantsModal = () => {
    this.setState({ participantModalVisible: false });
  };

  render() {
    return (
      <div className={css.AvatarLabelBlock}>
        {this.showAvatar()}
        {this.showLabel()}
        <ParticipantsModal
          currentUser={this.props.currentUser}
          visible={this.state.participantModalVisible}
          topicId={this.props.topicId}
          planStatus={this.props.planStatus}
          onCancel={this.hideParticipantsModal}
          onOk={this.handleRecommendPost}
          totalParticipants={this.props.totalParticipants}
          participants={this.props.participants.map(user => ({ user }))}
        />
      </div>
    );
  }
}

export default withTranslation()(Participants);
