import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteChatGroupByRequestId } from '@/store/chat/actions';
import { getUserIncome2 as getIncome } from '@/store/income/actions';
import { finishRequestStatus, rejectRequestStatus, surveyRequestStatus } from '@/store/mentoring_profile/actions';

import { selectCurrentUser } from '@/store/users/selectors';

import { Button, Input, Modal, Radio, Rate, Typography } from 'antd';
import { ACTIVE, FINISHED } from '@/components/Mentoring/constants';

import { modalType } from './constants';

import css from './MenteesRequestsModals.module.scss';

import { MENTOR_COMPLETE } from '@/constants/income-types';

const MenteesRequestsModals = ({ type, data, visible, onClose }) => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const { t } = useTranslation('MenteesRequests');
  const [rejectValue, setRejectValue] = useState('NO_HELP');
  const [rejectValueOther, setRejectValueOther] = useState('');
  const dispatch = useDispatch();
  const defaultFinishData = {
    rating: 0,
    reviewComment: '',
  };
  const [finishData, setFinishData] = useState(defaultFinishData);
  const currentUser = useSelector(selectCurrentUser);

  const finishMentoring = isReview => {
    if (isReview) {
      dispatch(surveyRequestStatus({ ...finishData, id: data?.id }));
    } else {
      dispatch(
        finishRequestStatus({ ...finishData, id: data?.id }, () => {
          if (!currentUser?.domainCompany?.hasCoins) return;

          dispatch(
            getIncome({
              userId: currentUser.id,
              bonus: 'MENTOR_COMPLETE',
              incomeType: MENTOR_COMPLETE,
              incomeContentLabel: t('commentMentorMessage'),
            })
          );
        })
      );
    }
    dispatch(deleteChatGroupByRequestId(data?.id));
    onCancel(modalType.FINISH_CONFIRM);
  };

  const onCancel = type => {
    switch (type) {
      case modalType.REJECT_CONFIRM:
        onClose();
        setRejectValue('NO_HELP');
        setRejectValueOther('');
        break;
      case modalType.REVIEW_CONFIRM:
      case modalType.FINISH_CONFIRM:
        setFinishData(defaultFinishData);
        onClose();
        break;
      case modalType.RESULTS:
      case modalType.REVIEW_REQUEST:
        onClose();
        break;
      default:
        onClose();
        break;
    }
  };

  const renderModal = () => {
    switch (type) {
      case modalType.REVIEW_REQUEST:
        const showContact = (data?.status === FINISHED || data?.status === ACTIVE) && data?.contact;

        return (
          <Modal
            open={visible}
            title={
              <Title level={4} className={css['MenteesRequestModals-modal-title']}>
                {t('modals.request')}
              </Title>
            }
            width={600}
            onCancel={() => onCancel(modalType.REVIEW_REQUEST)}
            footer={null}
          >
            <div className={css['MenteesRequestModals-container']}>
              <div className={css['MenteesRequestModals-item']}>
                <p className={css['MenteesRequestModals-title']}>{t('modals.aboutMentee')}</p>
                <p className={css['MenteesRequestModals-text']}>{data?.experience}</p>
              </div>
              <div className={css['MenteesRequestModals-item']}>
                <p className={css['MenteesRequestModals-title']}>{t('modals.requestDescription')}</p>
                <p className={css['MenteesRequestModals-text']}>{data?.request}</p>
              </div>

              {showContact && (
                <div className={css['MenteesRequestModals-item']}>
                  <p className={css['MenteesRequestModals-title']}>{t('modals.contact')}</p>
                  <p className={css['MenteesRequestModals-text']}>{data?.contact}</p>
                </div>
              )}
            </div>
          </Modal>
        );

      case modalType.REJECT_CONFIRM:
        return (
          <Modal
            open={visible}
            title={
              <div>
                <Title className={css['MenteesRequestModals-modal-title']} level={4}>
                  {t('modals.rejectReason.0')}
                </Title>
                <Text>{t('modals.rejectReason.1')}</Text>
              </div>
            }
            width={600}
            onCancel={() => onCancel(modalType.REJECT_CONFIRM)}
            footer={
              <>
                <Button
                  className={css['MenteesRequestModals-button']}
                  onClick={() => onCancel(modalType.REJECT_CONFIRM)}
                  size='large'
                >
                  {t('modals.cancel')}
                </Button>
                <Button
                  className={css['MenteesRequestModals-button']}
                  onClick={() => {
                    dispatch(
                      rejectRequestStatus({
                        id: data?.id,
                        rejectReason: rejectValue,
                        ...(rejectValue === 'OTHER' && { rejectReasonOther: rejectValueOther }),
                      })
                    );
                    onCancel(modalType.FINISH_CONFIRM);
                  }}
                  disabled={rejectValue === 'OTHER' && rejectValueOther?.length <= 0}
                  size='large'
                  type='primary'
                >
                  {t('modals.send')}
                </Button>
              </>
            }
          >
            <div className={css['MenteesRequestModals-container']}>
              <Radio.Group
                value={rejectValue}
                onChange={e => {
                  setRejectValue(e.target.value);
                  e.target.value !== 'OTHER' && setRejectValueOther('');
                }}
              >
                <Radio value={'NO_HELP'} className={css['MenteesRequestModals-radio']}>
                  <Text strong>{t('NO_HELP')}</Text>
                </Radio>
                <Radio value={'NO_COMPETENCE'} className={css['MenteesRequestModals-radio']}>
                  <Text strong>{t('NO_COMPETENCE')}</Text>
                </Radio>
                <Radio value={'NO_TIME'} className={css['MenteesRequestModals-radio']}>
                  <Text strong>{t('NO_TIME')}</Text>
                </Radio>
                <Radio value={'OTHER'} className={css['MenteesRequestModals-radio']}>
                  <Text strong>{t('OTHER')}</Text>
                </Radio>
              </Radio.Group>
              {rejectValue === 'OTHER' && (
                <TextArea
                  className={css['MenteesRequestModals-textArea']}
                  placeholder={t('modals.textAreaPlaceholder')}
                  maxLength={1000}
                  onChange={e => setRejectValueOther(e.target.value)}
                  value={rejectValueOther}
                />
              )}
            </div>
          </Modal>
        );

      case modalType.REVIEW_CONFIRM:
      case modalType.FINISH_CONFIRM:
        const isReview = type === modalType.REVIEW_CONFIRM;

        return (
          <Modal
            open={visible}
            width={600}
            onCancel={() => onCancel(modalType.FINISH_CONFIRM)}
            title={
              <div>
                <Title className={css['MenteesRequestModals-modal-title']} level={4}>
                  {isReview ? t('modals.areYouReadyToSurvey') : t('modals.areYouReadyToFinish')}
                </Title>
                <Text>{t('modals.rateMentee')}</Text>
              </div>
            }
            footer={
              <>
                <Button
                  className={css['MenteesRequestModals-button']}
                  onClick={() => onCancel(modalType.FINISH_CONFIRM)}
                  size='large'
                >
                  {t('modals.notReadyToFinish')}
                </Button>
                <Button
                  className={css['MenteesRequestModals-button']}
                  onClick={() => finishMentoring(isReview)}
                  disabled={!finishData?.reviewComment || !finishData?.rating}
                  size='large'
                  type='primary'
                >
                  {isReview ? t('modals.readyToSurvey') : t('modals.readyToFinish')}
                </Button>
              </>
            }
          >
            <div className={css['MenteesRequestModals-container']}>
              <TextArea
                placeholder={t('modals.tellAboutMentee')}
                className={css['MenteesRequestModals-textArea']}
                value={finishData?.reviewComment}
                onChange={e => setFinishData({ ...finishData, reviewComment: e.target.value })}
                maxLength={1000}
              />
              <Rate value={finishData?.rating} onChange={val => val && setFinishData({ ...finishData, rating: val })} />
            </div>
          </Modal>
        );

      case modalType.RESULTS:
        return (
          <Modal
            open={visible}
            onCancel={() => onCancel(modalType.RESULTS)}
            title={
              <Title className={css['MenteesRequestModals-modal-title']} level={4}>
                {t('modals.gratitude')}
              </Title>
            }
            footer={
              <Button
                className={css['MenteesRequestModals-button']}
                size='large'
                onClick={() => onCancel(modalType.RESULTS)}
              >
                {t('modals.close')}
              </Button>
            }
          >
            <div className={css['MenteesRequestModals-container']}>
              <div className={css['MenteesRequestModals-item']}>
                <p className={css['MenteesRequestModals-title']}>{t('modals.menteeReview')}</p>
                <p className={css['MenteesRequestModals-text']}>{data?.gratitudeByMentee ?? t('modals.empty')}</p>
              </div>
              <div className={css['MenteesRequestModals-item']}>
                <p className={css['MenteesRequestModals-title']}>{t('modals.mentorReview')}</p>
                <p className={css['MenteesRequestModals-text']}>{data?.reviewByMentor}</p>
                <Rate value={data?.ratingByMentor} disabled />
              </div>
            </div>
          </Modal>
        );

      default:
        return <></>;
    }
  };

  return renderModal();
};

export default MenteesRequestsModals;
