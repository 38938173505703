/* eslint-disable */
import { Component } from 'react';
import { Link } from 'react-router-dom';

import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Participants.module.scss';

export default class ParticipantModalItem extends Component {
  static propTypes = {
    getFile: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getFile();
  }

  getFile = () => {
    if (this.props.user?.localPhotoUuid || this.props.user?.photoUuid) {
      this.props.getFile(this.props.user.localPhotoUuid || this.props.user.photoUuid, blob => {
        const imageUrl = URL.createObjectURL(blob);
        this.setState({ avatar: imageUrl });
      });
    }
  };

  render() {
    const lastName = this.props.user?.lastName ? this.props.user.lastName : '';
    const firstName = this.props.user?.firstName ? this.props.user.firstName : '';
    const userName = `${lastName.substr(0, 1)}${firstName.substr(0, 1)}`;

    return (
      <div className={classNames(css.User)}>
        <Link to={`/collegues/${this.props.user.id}`} target='_blank'>
          <div className={css['User-avatar']}>
            {this.state.avatar ? (
              <img src={this.state.avatar || PortraitPlaceholder} alt='' />
            ) : (
              <div className={css['User-abc-ava']}>{userName}</div>
            )}
          </div>
        </Link>
        <Link to={`/collegues/${this.props.user.id}`} target='_blank'>
          <p className={css['User-link']}>{`${lastName} ${firstName}`}</p>
        </Link>
      </div>
    );
  }
}
