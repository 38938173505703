/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import css from './ModalFlower.module.scss';

export class FlowerItem extends Component {
  static propTypes = {
    changeFlowerID: PropTypes.func,
    handleChangeLink: PropTypes.func,
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item } = this.props;
    return (
      <div
        className={css.Flower}
        onMouseEnter={() => this.props.changeFlowerID(item.id)}
        onClick={() => this.props.handleChangeLink(item.id)}
      >
        <div>
          <h2 className={css['Flower-label']}>{item.name}</h2>
        </div>
      </div>
    );
  }
}

export default FlowerItem;
