/* eslint-disable */
import { CLEAR_QUESTION_NUMBER, SET_QUESTION_NUMBER } from './constants/questions';

import { API_URL } from '@shared/constants/config';
import { GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_INFO_BLOCK_START = 'GET_INFO_BLOCK_START';
export const GET_INFO_BLOCK_SUCCESS = 'GET_INFO_BLOCK_SUCCESS';
export const GET_INFO_BLOCK_FAIL = 'GET_INFO_BLOCK_FAIL';

export const GET_QUESTION_START = 'GET_QUESTION_START';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAIL = 'GET_QUESTION_FAIL';

export const GET_BLOCKS_START = 'GET_BLOCKS_START';
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS';
export const GET_BLOCKS_FAIL = 'GET_BLOCKS_FAIL';

export const GET_BLOCK_DETAILS_START = 'GET_BLOCK_DETAILS_START';
export const GET_BLOCK_DETAILS_SUCCESS = 'GET_BLOCK_DETAILS_SUCCESS';
export const GET_BLOCK_DETAILS_FAIL = 'GET_BLOCK_DETAILS_FAIL';

export const START_BLOCKS_START = 'START_BLOCKS_START';
export const START_BLOCKS_SUCCESS = 'START_BLOCKS_SUCCESS';
export const START_BLOCKS_FAIL = 'START_BLOCKS_FAIL';

export const FINISH_BLOCKS_START = 'FINISH_BLOCKS_START';
export const FINISH_BLOCKS_SUCCESS = 'FINISH_BLOCKS_SUCCESS';
export const FINISH_BLOCKS_FAIL = 'FINISH_BLOCKS_FAIL';

export const RESTART_BLOCKS_START = 'RESTART_BLOCKS_START';
export const RESTART_BLOCKS_SUCCESS = 'RESTART_BLOCKS_SUCCESS';
export const RESTART_BLOCKS_FAIL = 'RESTART_BLOCKS_FAIL';

export const SET_ANSWER_START = 'SET_ANSWER_START';
export const SET_ANSWER_SUCCESS = 'SET_ANSWER_SUCCESS';
export const SET_ANSWER_FAIL = 'SET_ANSWER_FAIL';

export const GET_BLOCK_STATISTIC_START = 'GET_BLOCK_STATISTIC_START';
export const GET_BLOCK_STATISTIC_SUCCESS = 'GET_BLOCK_STATISTIC_SUCCESS';
export const GET_BLOCK_STATISTIC_FAIL = 'GET_BLOCK_STATISTIC_FAIL';

export const GET_SCORM_START = 'GET_SCORM_START';
export const GET_SCORM_SUCCESS = 'GET_SCORM_SUCCESS';
export const GET_SCORM_FAIL = 'GET_SCORM_FAIL';

export const START_SCORM_BLOCK_START = 'START_SCORM_BLOCK_START';
export const START_SCORM_BLOCK_SUCCESS = 'START_SCORM_BLOCK_SUCCESS';
export const START_SCORM_BLOCK_FAIL = 'START_SCORM_BLOCK_FAIL';

export const RESTART_SCORM_BLOCK_START = 'RESTART_SCORM_BLOCK_START';
export const RESTART_SCORM_BLOCK_SUCCESS = 'RESTART_SCORM_BLOCK_SUCCESS';
export const RESTART_SCORM_BLOCK_FAIL = 'RESTART_SCORM_BLOCK_FAIL';

export const FINISH_SCORM_BLOCK_START = 'FINISH_SCORM_BLOCK_START';
export const FINISH_SCORM_BLOCK_SUCCESS = 'FINISH_SCORM_BLOCK_SUCCESS';
export const FINISH_SCORM_BLOCK_FAIL = 'FINISH_SCORM_BLOCK_FAIL';

export const SEND_SCORM_BLOCK_STATISTICS_START = 'SEND_SCORM_BLOCK_STATISTICS_START';
export const SEND_SCORM_BLOCK_STATISTICS_SUCCESS = 'SEND_SCORM_BLOCK_STATISTICS_SUCCESS';
export const SEND_SCORM_BLOCK_STATISTICS_FAIL = 'SEND_SCORM_BLOCK_STATISTICS_FAIL';

export const GET_VIDEO_START = 'GET_VIDEO_START';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAIL = 'GET_VIDEO_FAIL';

export const START_VIDEO_BLOCK_START = 'START_VIDEO_BLOCK_START';
export const START_VIDEO_BLOCK_SUCCESS = 'START_VIDEO_BLOCK_SUCCESS';
export const START_VIDEO_BLOCK_FAIL = 'START_VIDEO_BLOCK_FAIL';

export const FINISH_VIDEO_BLOCK_START = 'FINISH_VIDEO_BLOCK_START';
export const FINISH_VIDEO_BLOCK_SUCCESS = 'FINISH_VIDEO_BLOCK_SUCCESS';
export const FINISH_VIDEO_BLOCK_FAIL = 'FINISH_VIDEO_BLOCK_FAIL';

/**
 * Article block
 */
export const GET_ARTICLE_START = 'GET_ARTICLE_START';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAIL = 'GET_ARTICLE_FAIL';

export const START_ARTICLE_BLOCK_START = 'START_ARTICLE_BLOCK_START';
export const START_ARTICLE_BLOCK_SUCCESS = 'START_ARTICLE_BLOCK_SUCCESS';
export const START_ARTICLE_BLOCK_FAIL = 'START_ARTICLE_BLOCK_FAIL';

export const FINISH_ARTICLE_BLOCK_START = 'FINISH_ARTICLE_BLOCK_START';
export const FINISH_ARTICLE_BLOCK_SUCCESS = 'FINISH_ARTICLE_BLOCK_SUCCESS';
export const FINISH_ARTICLE_BLOCK_FAIL = 'FINISH_ARTICLE_BLOCK_FAIL';

/**
 * TypeForm block
 */
export const GET_TYPEFORM_START = 'GET_TYPEFORM_START';
export const GET_TYPEFORM_SUCCESS = 'GET_TYPEFORM_SUCCESS';
export const GET_TYPEFORM_FAIL = 'GET_TYPEFORM_FAIL';

export const SET_TYPEFORM_FINISH_START = 'SET_TYPEFORM_FINISH_START';
export const SET_TYPEFORM_FINISH_SUCCESS = 'SET_TYPEFORM_FINISH_SUCCESS';
export const SET_TYPEFORM_FINISH_FAIL = 'SET_TYPEFORM_FINISH_FAIL';

export const SET_TYPEFORM_START_START = 'SET_TYPEFORM_START_START';
export const SET_TYPEFORM_START_SUCCESS = 'SET_TYPEFORM_START_SUCCESS';
export const SET_TYPEFORM_START_FAIL = 'SET_TYPEFORM_START_FAIL';

/**
 * Tilda block
 */
export const START_TILDA_BLOCK_START = 'START_TILDA_BLOCK_START';
export const START_TILDA_BLOCK_SUCCESS = 'START_TILDA_BLOCK_SUCCESS';
export const START_TILDA_BLOCK_FAIL = 'START_TILDA_BLOCK_FAIL';

export const FINISH_TILDA_BLOCK_START = 'FINISH_TILDA_BLOCK_START';
export const FINISH_TILDA_BLOCK_SUCCESS = 'FINISH_TILDA_BLOCK_SUCCESS';
export const FINISH_TILDA_BLOCK_FAIL = 'FINISH_TILDA_BLOCK_FAIL';

/**
 * Presentation block
 */
export const GET_PRESENTATION_START = 'GET_PRESENTATION_START';
export const GET_PRESENTATION_SUCCESS = 'GET_PRESENTATION_SUCCESS';
export const GET_PRESENTATION_FAIL = 'GET_PRESENTATION_FAIL';

export const START_PRESENTATION_BLOCK_START = 'START_PRESENTATION_BLOCK_START';
export const START_PRESENTATION_BLOCK_SUCCESS = 'START_PRESENTATION_BLOCK_SUCCESS';
export const START_PRESENTATION_BLOCK_FAIL = 'START_PRESENTATION_BLOCK_FAIL';

export const FINISH_PRESENTATION_BLOCK_START = 'FINISH_PRESENTATION_BLOCK_START';
export const FINISH_PRESENTATION_BLOCK_SUCCESS = 'FINISH_PRESENTATION_BLOCK_SUCCESS';
export const FINISH_PRESENTATION_BLOCK_FAIL = 'FINISH_PRESENTATION_BLOCK_FAIL';

export const CLEAR_DETAILS_SUCCESS = 'CLEAR_DETAILS_SUCCESS';
export const CLEAR_QUESTION_SUCCESS = 'CLEAR_QUESTION_SUCCESS';

export const CHECKED_AVAILABLE_SUCCESS = 'CHECKED_AVAILABLE_SUCCESS';

// Poka files block
export const GET_POKA_FILE_START = 'GET_POKA_FILE_START';
export const GET_POKA_FILE_SUCCESS = 'GET_POKA_FILE_SUCCESS';
export const GET_POKA_FILE_FAIL = 'GET_POKA_FILE_FAIL';

export const START_POKA_FILE_BLOCK_START = 'START_POKA_FILE_BLOCK_START';
export const START_POKA_FILE_BLOCK_SUCCESS = 'START_POKA_FILE_BLOCK_SUCCESS';
export const START_POKA_FILE_BLOCK_FAIL = 'START_POKA_FILE_BLOCK_FAIL';

export const FINISH_POKA_FILE_BLOCK_START = 'FINISH_POKA_FILE_BLOCK_START';
export const FINISH_POKA_FILE_BLOCK_SUCCESS = 'FINISH_POKA_FILE_BLOCK_SUCCESS';
export const FINISH_POKA_FILE_BLOCK_FAIL = 'FINISH_POKA_FILE_BLOCK_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getBlockStatistic = ({ topicId, userId, planId, onSuccess }) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/result/statistic${getQueryString({
    topicId,
    userId,
    planId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_BLOCK_STATISTIC_START, GET_BLOCK_STATISTIC_SUCCESS, GET_BLOCK_STATISTIC_FAIL],
});

export const getBlockStatisticOffline = response => ({
  type: GET_BLOCK_STATISTIC_SUCCESS,
  response,
});

export const getBlocks = topicIds => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block?topicId=${topicIds}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    topicIds,
  },
  types: [GET_BLOCKS_START, GET_BLOCKS_SUCCESS, GET_BLOCKS_FAIL],
});

export const getQuestion = (blockId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/${blockId}/question`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  types: [GET_QUESTION_START, GET_QUESTION_SUCCESS, GET_QUESTION_FAIL],
});

export const getDetails = (blockId, userId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/result/detail${getQueryString({
    blockId,
    userId,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_BLOCK_DETAILS_START, GET_BLOCK_DETAILS_SUCCESS, GET_BLOCK_DETAILS_FAIL],
});

export const setBlockStart = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_BLOCKS_START, START_BLOCKS_SUCCESS, START_BLOCKS_FAIL],
});

export const setBlockFinish = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_BLOCKS_START, FINISH_BLOCKS_SUCCESS, FINISH_BLOCKS_FAIL],
});

export const setBlockRestart = (blockId, blockType, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}${blockType}/restart`, // blockType === '' для теста
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [RESTART_BLOCKS_START, RESTART_BLOCKS_SUCCESS, RESTART_BLOCKS_FAIL],
});

export const setAnswer = (data, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/test/answer`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  types: [SET_ANSWER_START, SET_ANSWER_SUCCESS, SET_ANSWER_FAIL],
});

export const getScorm = (scormId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/scorm/${scormId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_SCORM_START, GET_SCORM_SUCCESS, GET_SCORM_FAIL],
});

export const setScormBlockStart = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/scorm/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_SCORM_BLOCK_START, START_SCORM_BLOCK_SUCCESS, START_SCORM_BLOCK_FAIL],
});

export const setScormBlockFinish = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/scorm/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_SCORM_BLOCK_START, FINISH_SCORM_BLOCK_SUCCESS, FINISH_SCORM_BLOCK_FAIL],
});

export const setScormBlockRestart = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/scorm/restart`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [RESTART_SCORM_BLOCK_START, RESTART_SCORM_BLOCK_SUCCESS, RESTART_SCORM_BLOCK_FAIL],
});

export const sendScormBlockStatistics = (id, data, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/block/${id}/scorm/result`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: data,
  onSuccess,
  onError,
  types: [SEND_SCORM_BLOCK_STATISTICS_START, SEND_SCORM_BLOCK_STATISTICS_SUCCESS, SEND_SCORM_BLOCK_STATISTICS_FAIL],
});

export const getVideo = (videoId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/video/${videoId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_VIDEO_START, GET_VIDEO_SUCCESS, GET_VIDEO_FAIL],
});

export const setVideoBlockStart = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/video/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_VIDEO_BLOCK_START, START_VIDEO_BLOCK_SUCCESS, START_VIDEO_BLOCK_FAIL],
});

export const setVideoBlockFinish = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/video/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_VIDEO_BLOCK_START, FINISH_VIDEO_BLOCK_SUCCESS, FINISH_VIDEO_BLOCK_FAIL],
});

export const getArticle = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/article/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_ARTICLE_START, GET_ARTICLE_SUCCESS, GET_ARTICLE_FAIL],
});

export const setArticleBlockStart = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${id}/article/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_ARTICLE_BLOCK_START, START_ARTICLE_BLOCK_SUCCESS, START_ARTICLE_BLOCK_FAIL],
});

export const setArticleBlockFinish = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${id}/article/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_ARTICLE_BLOCK_START, FINISH_ARTICLE_BLOCK_SUCCESS, FINISH_ARTICLE_BLOCK_FAIL],
});

export const getPokaFiles = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/poka/block/file/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_POKA_FILE_START, GET_POKA_FILE_SUCCESS, GET_POKA_FILE_FAIL],
});

export const setPokaFilesBlockStart = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/${id}/file/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_POKA_FILE_BLOCK_START, START_POKA_FILE_BLOCK_SUCCESS, START_POKA_FILE_BLOCK_FAIL],
});

export const setPokaFilesBlockFinish = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/poka/block/${id}/file/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_POKA_FILE_BLOCK_START, FINISH_POKA_FILE_BLOCK_SUCCESS, FINISH_POKA_FILE_BLOCK_FAIL],
});

export const getPresentation = (pid, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/presentation/${pid}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  types: [GET_PRESENTATION_START, GET_PRESENTATION_SUCCESS, GET_PRESENTATION_FAIL],
});

export const getPresentationOffline = response => ({
  type: GET_PRESENTATION_SUCCESS,
  response,
});

export const setPresentationBlockStart = (bid, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${bid}/presentation/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_PRESENTATION_BLOCK_START, START_PRESENTATION_BLOCK_SUCCESS, START_PRESENTATION_BLOCK_FAIL],
});

export const setPresentationBlockFinish = (bid, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${bid}/presentation/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_PRESENTATION_BLOCK_START, FINISH_PRESENTATION_BLOCK_SUCCESS, FINISH_PRESENTATION_BLOCK_FAIL],
});

export const getTypeform = (blockId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/${blockId}/typeform`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TYPEFORM_START, GET_TYPEFORM_SUCCESS, GET_TYPEFORM_FAIL],
  onSuccess,
});

export const setTypeformFinish = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/typeform/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [SET_TYPEFORM_FINISH_START, SET_TYPEFORM_FINISH_SUCCESS, SET_TYPEFORM_FINISH_FAIL],
  onSuccess,
});

export const setTypeformStart = (blockId, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${blockId}/typeform/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [SET_TYPEFORM_START_START, SET_TYPEFORM_START_SUCCESS, SET_TYPEFORM_START_FAIL],
  onSuccess,
});

export const setTildaBlockStart = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${id}/tilda/start`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [START_TILDA_BLOCK_START, START_TILDA_BLOCK_SUCCESS, START_TILDA_BLOCK_FAIL],
});

export const setTildaBlockFinish = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/block/${id}/tilda/finish`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  onSuccess,
  types: [FINISH_TILDA_BLOCK_START, FINISH_TILDA_BLOCK_SUCCESS, FINISH_TILDA_BLOCK_FAIL],
});

export const getBlock = (blockId, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/block/${blockId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_INFO_BLOCK_START, GET_INFO_BLOCK_SUCCESS, GET_INFO_BLOCK_FAIL],
  onSuccess,
  onError,
});

export const clearDetails = () => ({
  type: CLEAR_DETAILS_SUCCESS,
});

export const clearQuestion = () => ({
  type: CLEAR_QUESTION_SUCCESS,
});

// установка номера текущего вопроса
export const setQuestionNumber = newNumber => ({
  type: SET_QUESTION_NUMBER,
  response: newNumber,
});

// сброс номера текущего вопроса
export const clearQuestionNumber = () => ({
  type: CLEAR_QUESTION_NUMBER,
});

export const checkedIsAvailableForStart = blockId => ({
  type: CHECKED_AVAILABLE_SUCCESS,
  blockId: blockId,
});
