/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { eventRequestConfirmUI, eventRequestFinish, eventRequestRefuseUI } from '@/store/course/actions';
import { getIncome } from '@/store/income/actions';
import {
  checkPreviousStudyPlans,
  checkStudyPlan,
  clearPlan,
  deleteStudyPlan,
  finishAllPlansForTopics,
  getStudyPlan,
  getStudyPlanDinamic,
  sendNotificationStudyPlanFinished,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import { checkTopicComment, showSendReviewModal } from '@/store/topics/actions';
import {
  createProfileUserTopic,
  createUserTopicToMultipleUsers,
  deleteProfileUserTopic,
  sendNotification,
} from '@/store/user-topics/actions';
import { getUserById, getUsers } from '@/store/users/actions';

import { selectOpenDate } from '@/store/course/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button } from 'antd';
import AudienceConfirmationModal from '@/components/ModalTargetAudience/Modal';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import { checkActiveCourse } from '@shared/utils/course-utils';
import { isAnyCourseOrConference, shouldCheckPreviousStudyPlans } from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

import css from './Planning.module.scss';

export class StudyPlanButton extends Component {
  static propTypes = {
    actionType: PropTypes.string,
    activeMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checkCoursePlan: PropTypes.bool,
    checkIsEnd: PropTypes.bool,
    checkStudyPlan: PropTypes.func,
    checkPreviousStudyPlans: PropTypes.func,
    checkTopicComment: PropTypes.func,
    clearPlan: PropTypes.func,
    courseStatus: PropTypes.string,
    createProfileUserTopic: PropTypes.func,
    createToPlan: PropTypes.func,
    createUserTopicToMultipleUsers: PropTypes.func,
    currentUserId: PropTypes.number,
    deleteProfileUserTopic: PropTypes.func,
    deleteStudyPlan: PropTypes.func,
    externalLogin: PropTypes.bool,
    finishAllPlansForTopics: PropTypes.func,
    getIncome: PropTypes.func,
    getStudyPlan: PropTypes.func,
    getStudyPlanDinamic: PropTypes.func,
    handleCreateToPlanClick: PropTypes.func,
    isAnother: PropTypes.bool,
    isTopic: PropTypes.bool,
    mention: PropTypes.bool,
    page: PropTypes.number,
    plan: PropTypes.object,
    planId: PropTypes.number,
    planMonth: PropTypes.string,
    sendNotification: PropTypes.func,
    showCalendarModal: PropTypes.func,
    showSendReviewModal: PropTypes.func,
    topicId: PropTypes.number,
    viewPlan: PropTypes.bool,
    onAddToPlanNow: PropTypes.func,
    withNoEvents: PropTypes.bool,
    setHandleStudyPlanClickState: PropTypes.func,
    setHandleStudyPlanClickExecuted: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.throttleGetStudyPlan = throttle(this.props.getStudyPlan, 300);
    this.state = {
      showFinishButtons: false,
      errorMention: true,
      usersModalVisible: false,
      mention: props.mention,
      isFavourite: props.isFavourite,
      planStatus: _get(props, 'plan.topic.planStatus.planStatus'),
      isAudienceConfirmationModalOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.actionType === 'setReviewPlan') {
      this.props.checkTopicComment(this.props.topicId, response => {
        if (response) {
          this.setState({ errorMention: true });
        } else {
          this.setState({ errorMention: false });
        }
      });
    }
    if (this.props.mention) {
      this.setState({ mention: this.props.mention });
    }
  }

  handleFavoriteClick = () => {
    this.props.createProfileUserTopic(this.props.currentUserId, this.props.topicId, 'anotherPlan', () => {
      this.setState({ isFavourite: true });
    });
  };

  handleDeleteClick = () => {
    this.props.deleteProfileUserTopic(this.props.topicId, true, true, 'anotherPlan', () => {
      this.setState({ isFavourite: false });
    });
  };

  getStudyPlan = () => {
    if (this.props.viewPlan) {
      this.props.clearPlan();
    } else {
      this.throttleGetStudyPlan(
        false,
        this.props.currentUserId,
        dayjs(this.props.activeMonth, 'YYYY-MM-DD'),
        this.props.page
      );
    }
  };

  handleClick = () => {
    this.setState({ isAudienceConfirmationModalOpen: false });
    this.props.showCalendarModal(
      this.props.planId,
      this.props.topicId,
      this.props.plan.topic,
      true,
      this.props.plan.topic.type,
      this.props.plan.topic.startDate,
      planStatus => {
        this.setState({ planStatus });
      }
    );
  };

  handleModalConfirm = () => {
    this.setState({ isAudienceConfirmationModalOpen: true });
    const { setHandleStudyPlanClickState, checkPreviousStudyPlans, setHandleStudyPlanClickExecuted } = this.props;

    if (shouldCheckPreviousStudyPlans(topic)) {
      checkPreviousStudyPlans(topic.id);
      setHandleStudyPlanClickExecuted(true);
      if (typeof setHandleStudyPlanClickState === 'function') {
        setHandleStudyPlanClickState(() => this.handleClick);
      }
    } else {
      this.handleClick();
    }
  };

  handleModalCancel = () => {
    this.setState({ isAudienceConfirmationModalOpen: false });
  };

  handleClickWithCheck = () => {
    const { topicType, setHandleStudyPlanClickExecuted, setHandleStudyPlanClickState, checkPreviousStudyPlans } =
      this.props;
    const { topic } = this.props.plan;

    if (topic.targetAudience === false && isAnyCourseOrConference(topicType)) {
      this.setState({ isAudienceConfirmationModalOpen: true });
    }
    if (isAnyCourseOrConference(topic.type.id) && topic.type.approved && topic.type.withEvents && topic.cost > 0) {
      checkPreviousStudyPlans(topic.id);
      setHandleStudyPlanClickExecuted(true);
      if (typeof setHandleStudyPlanClickState === 'function') {
        setHandleStudyPlanClickState(() => this.handleClick);
      }
    } else {
      this.handleClick();
    }
  };

  render() {
    const {
      plan,
      isAnother,
      externalLogin,
      checkIsEnd,
      checkCoursePlan,
      favouriteClass,
      t,
      withNoEvents,
      onAddToPlanNow,
    } = this.props;
    const { isFavourite, planStatus, isAudienceConfirmationModalOpen } = this.state;

    const openDate = _get(this.props, 'openDate, openDate');
    const isExternalLogin = plan.topic.scope !== 'EXTERNAL' && externalLogin;
    const toMyCollectionButton = isFavourite ? (
      <Button
        className={classNames(
          css['App-btn'],
          css['App-btn-ghost'],
          css['App-favorite'],
          isExternalLogin && favouriteClass
        )}
        type='link'
        onClick={this.handleDeleteClick}
        size='large'
        icon={<HeartFilled className={css['App-btn-icon']} />}
      ></Button>
    ) : (
      <Button
        className={classNames(css['App-btn'], css['App-btn-ghost'], isExternalLogin && favouriteClass)}
        onClick={this.handleFavoriteClick}
        type='link'
        size='large'
        icon={<HeartOutlined className={css['App-btn-icon']} />}
      ></Button>
    );

    const isActiveCourse = checkActiveCourse({ topic: plan.topic, openDate });

    const modal = isAudienceConfirmationModalOpen && (
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={this.handleModalConfirm}
        onCancel={this.handleModalCancel}
      />
    );

    const actionButtons = planStatus ? (
      <div className={css['App-btn-group']}>
        <Button
          disabled={(plan.topic.status === 'DISABLED' && checkCoursePlan) || checkIsEnd || !isActiveCourse}
          className={classNames(css['App-btn'], css['App-btn-success'])}
          ghost
          size='large'
        >
          {(plan.topic.status === 'DISABLED' && checkCoursePlan) || checkIsEnd || !isActiveCourse ? (
            <div className={css['App-btn-flexWrap']}>
              <div className={css['App-btn-noActive']} />
              {t('unActive')}
            </div>
          ) : (
            <div className={css['App-btn-flex']}>{t('added')}</div>
          )}
        </Button>
        {toMyCollectionButton}
      </div>
    ) : (
      <div className={css['App-btn-group']}>
        {withNoEvents && onAddToPlanNow && (
          <Button
            disabled={plan.topic.status === 'DISABLED' || checkIsEnd || !isActiveCourse}
            className={classNames(css['App-btn'], css['App-btn-first'])}
            onClick={onAddToPlanNow}
            type='primary'
            size='large'
          >
            <div className={css['App-btn-flex']}>{t('addNow')}</div>
          </Button>
        )}
        <Button
          disabled={plan.topic.status === 'DISABLED' || checkIsEnd || !isActiveCourse}
          className={css['App-btn']}
          onClick={this.handleClickWithCheck}
          type='primary'
          size='large'
        >
          {plan.topic.status === 'DISABLED' || checkIsEnd || !isActiveCourse ? (
            <div className={css['App-btn-flexWrap']}>
              <div className={css['App-btn-noActive']} />
              {t('unActive')}
            </div>
          ) : (
            <div className={css['App-btn-flex']}>
              {/* <svg width='10px' height='6px' viewBox='0 0 10 6' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                <g id='Symbols' strokeWidth='1' fillRule='evenodd' strokeLinecap='square'>
                  <g id='button/block/plan_favorite' transform='translate(-15.000000, -26.000000)' strokeWidth='2'>
                    <g id='icons/button/to_plan' transform='translate(13.000000, 22.000000)'>
                      <path d='M3.5,5 L10.5,5' id='Line' />
                      <path d='M3.5,9 L8,9' id='Line' />
                    </g>
                  </g>
                </g>
              </svg> */}
              {t(withNoEvents && onAddToPlanNow ? 'addLater' : 'add')}
            </div>
          )}
        </Button>
        {toMyCollectionButton}
        {modal}
      </div>
    );

    if (isAnother) {
      return !externalLogin || plan.topic.scope === 'EXTERNAL' ? actionButtons : toMyCollectionButton;
    }
    return null;
  }
}

const mapStateToProps = createSelector(
  [usersSelect, studyPlanSelect, loginSelect, selectOpenDate],
  (users, studyPlan, login, openDate) => ({
    openDate,
    currentUserId: users.currentUser && users.currentUser.id,
    viewPlan: studyPlan.view,
    usersList: users.usersList && users.usersList,
    usersPageSize: users.usersPageSize,
    usersPageNumber: users.usersPageNumber,
    usersTotal: users.usersTotal,
    externalLogin: login.externalLogin,
    updateButton: studyPlan.updateButton,
    studyPlan: studyPlan,
  })
);

const mapActionsToProps = {
  eventRequestConfirmUI,
  eventRequestRefuseUI,
  updateStudyPlanStatus,
  deleteStudyPlan,
  checkStudyPlan,
  clearPlan,
  checkTopicComment,
  getStudyPlan,
  getStudyPlanDinamic,
  createProfileUserTopic,
  finishAllPlansForTopics,
  getIncome,
  getUserById,
  deleteProfileUserTopic,
  eventRequestFinish,
  sendNotification,
  sendNotificationStudyPlanFinished,
  showSendReviewModal,
  createUserTopicToMultipleUsers,
  getUsers,
  checkPreviousStudyPlans,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('studyPlanButton')(StudyPlanButton));
