/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Card, Result } from 'antd';

import css from '../index.module.scss';

export const Success = props => {
  const { t } = useTranslation('D360_Success');

  const render = () => {
    return (
      <Card className={css.StatusCard}>
        <Result
          status='success'
          title={t('title')}
          subTitle={t('content')}
          extra={[
            <Link to={`/d360`} key={1}>
              <Button type='primary' key='console'>
                {t('backToPolls')}
              </Button>
            </Link>,
          ]}
        />
      </Card>
    );
  };

  return render();
};

export default Success;
