/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_COMPETENCE_START = 'GET_COMPETENCE_START';
export const GET_COMPETENCE_SUCCESS = 'GET_COMPETENCE_SUCCESS';
export const GET_COMPETENCE_FAIL = 'GET_COMPETENCE_FAIL';

export const GET_COMPETENCE_SKILL_START = 'GET_COMPETENCE_SKILL_START';
export const GET_COMPETENCE_SKILL_SUCCESS = 'GET_COMPETENCE_SKILL_SUCCESS';
export const GET_COMPETENCE_SKILL_FAIL = 'GET_COMPETENCE_SKILL_FAIL';

export const GET_COMPETENCE_SKILLS_START = 'GET_COMPETENCE_SKILLS_START';
export const GET_COMPETENCE_SKILLS_SUCCESS = 'GET_COMPETENCE_SKILLS_SUCCESS';
export const GET_COMPETENCE_SKILLS_FAIL = 'GET_COMPETENCE_SKILLS_FAIL';

export const CREATE_COMPETENCE_START = 'CREATE_COMPETENCE_START';
export const CREATE_COMPETENCE_SUCCESS = 'CREATE_COMPETENCE_SUCCESS';
export const CREATE_COMPETENCE_FAIL = 'CREATE_COMPETENCE_FAIL';

export const ADD_COMPETENCE_SKILLS_START = 'CREATE_COMPETENCE_SKILLS_START';
export const ADD_COMPETENCE_SKILLS_SUCCESS = 'CREATE_COMPETENCE_SKILLS_SUCCESS';
export const ADD_COMPETENCE_SKILLS_FAIL = 'CREATE_COMPETENCE_SKILLS_FAIL';

export const DELETE_COMPETENCE_START = 'DELETE_COMPETENCE_START';
export const DELETE_COMPETENCE_SUCCESS = 'DELETE_COMPETENCE_SUCCESS';
export const DELETE_COMPETENCE_FAIL = 'DELETE_COMPETENCE_FAIL';

export const EDIT_COMPETENCE_START = 'EDIT_COMPETENCE_START';
export const EDIT_COMPETENCE_SUCCESS = 'EDIT_COMPETENCE_SUCCESS';
export const EDIT_COMPETENCE_FAIL = 'EDIT_COMPETENCE_FAIL';

export const GET_COMPETENCE_MORE_START = 'GET_COMPETENCE_MORE_START';
export const GET_COMPETENCE_MORE_FAIL = 'GET_COMPETENCE_MORE_FAIL';
export const GET_COMPETENCE_MORE_SUCCESS = 'GET_COMPETENCE_MORE_SUCCESS';

export const CLEAR_COMPETENCE_SKILL = 'CLEAR_COMPETENCE_SKILL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getCompetence = (name = '', page = 0, onSuccess, isGlobal = false) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence${getQueryString({
    name: name && name.toLowerCase(),
    sort: 'name,asc',
    page: Number(page),
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_START, GET_COMPETENCE_SUCCESS, GET_COMPETENCE_FAIL],
  onSuccess,
});

export const getCompetenceMore = (page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence${getQueryString({
    sort: 'name,asc',
    page: Number(page),
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_MORE_START, GET_COMPETENCE_MORE_SUCCESS, GET_COMPETENCE_MORE_FAIL],
});

export const getCompetenceWithSkills = (page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence/skill${getQueryString({
    page,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_SKILL_START, GET_COMPETENCE_SKILL_SUCCESS, GET_COMPETENCE_SKILL_FAIL],
});

export const getCompetenceSkills = (id, withoutTopics) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/competence/${id}/skill${getQueryString({ withoutTopics })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_COMPETENCE_SKILLS_START, GET_COMPETENCE_SKILLS_SUCCESS, GET_COMPETENCE_SKILLS_FAIL],
});

export const createCompetence = (name, skills, is_hard_skill, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/competence`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    name: name,
    listSkillId: skills,
    isHardSkill: is_hard_skill,
  },
  onSuccess,
  onError,
  types: [CREATE_COMPETENCE_START, CREATE_COMPETENCE_SUCCESS, CREATE_COMPETENCE_FAIL],
});

export const addCompetenceSkills = (id, skills = [], onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/competence/${id}/skill${getQueryString({
    skillIds: skills,
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    skillIds: skills,
  },
  onSuccess,
  onError,
  types: [ADD_COMPETENCE_SKILLS_START, ADD_COMPETENCE_SKILLS_SUCCESS, ADD_COMPETENCE_SKILLS_FAIL],
});

export const deleteCompetence = (id, onSuccess, onError) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/competence/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  onSuccess,
  onError,
  responseType: 'text',
  types: [DELETE_COMPETENCE_START, DELETE_COMPETENCE_SUCCESS, DELETE_COMPETENCE_FAIL],
});

export const editCompetence = (id, name, skills, is_hard_skill, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/competence/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {
    id: id,
    name: name,
    listSkillId: skills,
    isHardSkill: is_hard_skill,
  },
  onSuccess,
  types: [EDIT_COMPETENCE_START, EDIT_COMPETENCE_SUCCESS, EDIT_COMPETENCE_FAIL],
});

export const clearCompetenceWithSkills = () => ({
  type: CLEAR_COMPETENCE_SKILL,
});
