import { GET_FUNCTIONS_FAIL, GET_FUNCTIONS_START, GET_FUNCTIONS_SUCCESS } from './constants';

const initialState = {
  functions: null,
  functionsTotal: null,
  functionsPageSize: null,
  functionsPageNumber: null,
  isLoading: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_FUNCTIONS_START:
      return {
        ...state,
        functions: null,
        functionsTotal: null,
        functionsPageSize: null,
        functionsPageNumber: null,
        isLoading: true,
      };

    case GET_FUNCTIONS_SUCCESS:
      return {
        ...state,
        functions: response,
        functionsTotal: Number(headers.get('X-Total-Elements')) || 0,
        functionsPageSize: Number(headers.get('X-Page-Size')) || 0,
        functionsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_FUNCTIONS_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
