export const ACCESS_TO_ADMIN_FROM_USERS = 'ACCESS_TO_ADMIN_FROM_USERS';

export const STUDY_PLAN_DELETE_BY_ADMIN = 'STUDY_PLAN_DELETE_BY_ADMIN';

export const USER_SIGN_IN_ANOTHER_USER = 'USER_SIGN_IN_ANOTHER_USER';

export const TOPIC_MANAGE = 'TOPIC_MANAGE';

export const USER_ROLE_ID = 4;

export const TOPIC_COMMENT_REPLY = 'TOPIC_COMMENT_REPLY';

export const ACCESS_TO_USERS_MYPOLLS = 'ACCESS_TO_USERS_MYPOLLS';
