/* eslint-disable */
import { D360_LOAD_PROJECT_FAIL, D360_LOAD_PROJECT_START, D360_LOAD_PROJECT_SUCCESS } from './constants';

const initialState = {
  loading: false,
  project: undefined,
  ppp: 0,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case D360_LOAD_PROJECT_START:
      return { ...state, loading: true, project: undefined };

    case D360_LOAD_PROJECT_SUCCESS:
      return { ...state, loading: false, project: response.data };

    case D360_LOAD_PROJECT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
