/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getExecutiveTopicInfo } from '@/store/executive/actions';

import { Alert, Col, Layout, Row } from 'antd';

import { MaterialsTabs } from './MaterialsTabs';

import PropTypes from 'prop-types';

import css from './MaterialsTabsExecutive.module.scss';

const { Content } = Layout;

class MaterialsTabsExecutive extends Component {
  static propTypes = {
    topic: PropTypes.object,
    companyID: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      results: null,
      activity: null,
      indicators: null,
      duration: 0,
      needfile: false,
    };

    this.loadData();
  }

  loadData() {
    this.props.getExecutiveTopicInfo(
      this.props.topic.id,
      res => {
        this.setState({
          results: res.resultInfo,
          activity: res.activityInfo,
          indicators: res.indicatorInfo,
          duration: res.duration,
          needfile: res.requiredFile,
        });
      },
      error => {
        // error not handled yet
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Content className={css.MaterialsTabsExecutive}>
        {!window.navigator.onLine && (
          <Alert message='Вы находитесь в режиме ОФФЛАЙН, просмотр данного контента недоступен' type='warning' />
        )}
        {this.state.activity !== null && this.state.activity.length > 0 && (
          <Row className={css.MaterialsTabsExecutiveRow}>
            <Col className={css.MaterialsTabsExecutiveItemTitle} span={8}>
              {t('activity')}
            </Col>
            <Col className={css.MaterialsTabsExecutiveItemBody} span={16}>
              {this.state.activity}
            </Col>
          </Row>
        )}

        {this.state.results !== null && this.state.results.length > 0 && (
          <Row className={css.MaterialsTabsExecutiveRow}>
            <Col className={css.MaterialsTabsExecutiveItemTitle} span={8}>
              {t('results')}
            </Col>
            <Col className={css.MaterialsTabsExecutiveItemBody} span={16}>
              {this.state.results}
            </Col>
          </Row>
        )}

        {this.state.indicators !== null && this.state.indicators.length > 0 && (
          <Row className={css.MaterialsTabsExecutiveRow}>
            <Col className={css.MaterialsTabsExecutiveItemTitle} span={8}>
              {t('indicators')}
            </Col>
            <Col className={css.MaterialsTabsExecutiveItemBody} span={16}>
              {this.state.indicators}
            </Col>
          </Row>
        )}

        <Row className={css.MaterialsTabsExecutiveRow}>
          <Col className={css.MaterialsTabsExecutiveItemTitle} span={8}>
            {t('duration')}
          </Col>
          <Col className={css.MaterialsTabsExecutiveItemBody} span={16}>
            {this.state.duration} {t('duration_min')}
          </Col>
        </Row>

        <Row className={css.MaterialsTabsExecutiveRow}>
          <Col className={css.MaterialsTabsExecutiveItemTitle} span={8}>
            {t('needfile')}
          </Col>
          <Col className={css.MaterialsTabsExecutiveItemBody} span={16}>
            {this.state.needfile ? t('needfile_yes') : t('needfile_no')}
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = null;

const mapAcitonToProps = {
  getExecutiveTopicInfo,
};

export default connect(
  mapStateToProps,
  mapAcitonToProps
)(withTranslation('materialsExecutive')(MaterialsTabsExecutive));
