/* eslint-disable */

import {
  GET_BOOK_ADDRESS_IN_USE_FAIL,
  GET_BOOK_ADDRESS_IN_USE_START,
  GET_BOOK_ADDRESS_IN_USE_SUCCESS,
  GET_BOOK_ADDRESSES_FAIL,
  GET_BOOK_ADDRESSES_START,
  GET_BOOK_ADDRESSES_SUCCESS,
} from './actions';

const initialState = {
  addresses: [],
  addressInUse: {},
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_BOOK_ADDRESSES_START:
    case GET_BOOK_ADDRESS_IN_USE_START:
      return { ...state, isLoading: true, error: false };

    case GET_BOOK_ADDRESSES_SUCCESS:
      return { ...state, addresses: response, isLoading: false, error: false };

    case GET_BOOK_ADDRESS_IN_USE_SUCCESS:
      return { ...state, addressInUse: response, isLoading: false, error: false };

    case GET_BOOK_ADDRESSES_FAIL:
    case GET_BOOK_ADDRESS_IN_USE_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};
