// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ShowProgress-ShowProgress-module__ql-picker-AHh76b.user-src-components-ShowProgress-ShowProgress-module__ql-font-_3rzWi .user-src-components-ShowProgress-ShowProgress-module__ql-picker-label-U7cCsA[data-value="Montserrat"]:before, .user-src-components-ShowProgress-ShowProgress-module__ql-picker-AHh76b.user-src-components-ShowProgress-ShowProgress-module__ql-font-_3rzWi .user-src-components-ShowProgress-ShowProgress-module__ql-picker-item-RBAgx2[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ShowProgress-ShowProgress-module__ql-font-Montserrat-VQJiFG {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ShowProgress-ShowProgress-module__ProgressData-VheBLH {
  justify-content: space-between;
  align-items: space-between;
  letter-spacing: .2px;
  text-transform: uppercase;
  color: #999;
  width: 100%;
  margin-top: 4px;
  font-size: 10px;
  display: flex;
}

@media (max-width: 992px) {
  .user-src-components-ShowProgress-ShowProgress-module__ProgressData-VheBLH {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ShowProgress/ShowProgress.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,8BAA8B;EAC9B,0BAA0B;EAC1B,oBAAoB;EACpB,yBAAyB;EACzB,WAAW;EACX,WAAW;EACX,eAAe;EACf,eAAe;EACf,aAAa;AACf;;AAMA;EACE;IAHE,eAAe;EACjB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ProgressData {\n  display: flex;\n  justify-content: space-between;\n  align-items: space-between;\n  font-size: 10px;\n  letter-spacing: 0.2px;\n  text-transform: uppercase;\n  color: #999;\n  width: 100%;\n  margin-top: 4px;\n}\n@media (max-width: 992px) {\n  .ProgressData {\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-AHh76b`,
	"qlPicker": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-AHh76b`,
	"ql-font": `user-src-components-ShowProgress-ShowProgress-module__ql-font-_3rzWi`,
	"qlFont": `user-src-components-ShowProgress-ShowProgress-module__ql-font-_3rzWi`,
	"ql-picker-label": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-label-U7cCsA`,
	"qlPickerLabel": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-label-U7cCsA`,
	"ql-picker-item": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-item-RBAgx2`,
	"qlPickerItem": `user-src-components-ShowProgress-ShowProgress-module__ql-picker-item-RBAgx2`,
	"ql-font-Montserrat": `user-src-components-ShowProgress-ShowProgress-module__ql-font-Montserrat-VQJiFG`,
	"qlFontMontserrat": `user-src-components-ShowProgress-ShowProgress-module__ql-font-Montserrat-VQJiFG`,
	"ProgressData": `user-src-components-ShowProgress-ShowProgress-module__ProgressData-VheBLH`,
	"progressData": `user-src-components-ShowProgress-ShowProgress-module__ProgressData-VheBLH`
};
export default ___CSS_LOADER_EXPORT___;
