import React from 'react';

import css from '../Details/Details.module.scss';

export const TestDetailsActions = ({ actionsTestButtons }) => {
  return (
    <div className={css['Details-buttons']}>
      {actionsTestButtons.map(btn => {
        return btn;
      })}
    </div>
  );
};
