import { API_D360_URL } from '../api_service';
import { D360_LOAD_PROJECT_FAIL, D360_LOAD_PROJECT_START, D360_LOAD_PROJECT_SUCCESS } from './constants';

import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360LoadProject = (projectId, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/employee/project${getQueryString({ projectId })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_LOAD_PROJECT_START, D360_LOAD_PROJECT_SUCCESS, D360_LOAD_PROJECT_FAIL],
    onSuccess,
  };
};
