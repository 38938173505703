import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

// eslint-disable-next-line
export default ({ className, type, to, onClick, ...props }) => {
  const navigate = useNavigate();

  const _onClick = e => (to ? navigate(to) : onClick && onClick(e));

  return (
    <Button
      size='large'
      shape={type !== 'link' ? 'round' : undefined}
      type={type}
      onClick={_onClick}
      {...props}
      className={cn('button', className)}
    />
  );
};

Button.propTypes = {
  to: PropTypes.string,
};
