import { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { deleteFile } from '@/store/files/actions';

import { Button, Input, Modal } from 'antd';

import File from './File';
import FileInput from './FileInput';
import Photo from './Photo';

import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const ModalWithFile = ({ visible, onClose, sendMessage, setFilesData, valueInput, setValueInput, filesData }) => {
  const { t } = useTranslation('chat');

  const textAreaRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    textAreaRef?.current?.focus();
  }, [textAreaRef]);

  const photoUrls = useMemo(() => {
    if (!filesData.length) return [];

    return filesData
      .map(file => {
        if (!file.fileType.includes('image')) return null;
        return {
          src: URL.createObjectURL(file.fileBlob),
          uuid: file.fileUuid,
        };
      })
      .filter(elem => elem !== null);
  }, [filesData]);

  const handleSendMessage = () => {
    sendMessage();
    onClose();
  };

  const handleDeleteFile = uuid => {
    dispatch(deleteFile(uuid));
    setFilesData(filesData.filter(file => file.fileUuid !== uuid));
  };

  const handleDeleteAllFiles = () => {
    filesData.forEach(file => {
      dispatch(deleteFile(file.fileUuid));
    });
  };

  const handleClose = () => {
    handleDeleteAllFiles();
    onClose();
  };

  const handleAddFile = data => {
    setFilesData([...filesData, data]);
  };

  const renderFooter = (
    <div className={styles.modalWithFile__footer}>
      <FileInput isButton setFileData={handleAddFile} />
      <div className={styles.modalWithFile__buttons}>
        <Button onClick={handleSendMessage} type='primary'>
          {t('send')}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal open={visible} onCancel={handleClose} footer={renderFooter} title={t('sendImage')} width={360}>
      <div className={styles.modalWithFile}>
        <div className={styles.modalWithFile__images}>
          {photoUrls.map((photo, index) => (
            <Photo src={photo.src} uuid={photo.uuid} key={index} handleDelete={handleDeleteFile} />
          ))}
        </div>
        <div className={styles.modalWithFile__files}>
          {filesData.map((file, index) => {
            if (file.fileType.includes('image')) return null;
            return (
              <File
                canDelete
                handleDelete={handleDeleteFile}
                fileName={file.fileName}
                fileUuid={file.fileUuid}
                key={index}
              />
            );
          })}
        </div>
        <Input.TextArea
          maxLength={4096}
          className={styles.modalWithFile__textArea}
          placeholder={t('placeholderMessage')}
          value={valueInput}
          ref={textAreaRef}
          onChange={event => setValueInput(event.target.value)}
        />
      </div>
    </Modal>
  );
};

ModalWithFile.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  setFilesData: PropTypes.func,
  sendMessage: PropTypes.func,
  valueInput: PropTypes.string,
  setValueInput: PropTypes.func,
  filesData: PropTypes.array,
};

export default memo(ModalWithFile);
