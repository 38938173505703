import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCareerPlan } from '@/store/career_plan/actions';
import { createStudyPlan } from '@/store/study-plan/actions';
import { getTopicById } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button } from 'antd';
import CalendarModal from '@/pages/CalendarModal';
import {
  ACTIVE,
  FINISHED,
  REJECTED_BY_MENTOR,
  REQUEST_MENTORING,
  WAITING_MENTOR,
} from '@/components/Mentoring/constants';
import ModalActions from '@/components/Mentoring/MentorActionModal/ModalActions';
import { AiOutlineCheck } from 'react-icons/ai';

import { MENTORS, TASK, TOPIC } from '../../constants';
import AddToPlan from './AddToPlan';

import PropTypes from 'prop-types';

import css from './ActionButtons.module.scss';

const ActionButtons = ({ type, data, currentCompetence, careerPlanId, onEditTask }) => {
  const { t } = useTranslation(['mentors', 'collectionMaterialId']);
  const dispatch = useDispatch();
  const { topic, isLoading } = useSelector(selectTopics);
  const { currentUser } = useSelector(selectUsers);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isAction, setIsAction] = useState({
    visible: false,
    action: '',
    mentorId: null,
  });

  const showModal = action => {
    switch (type) {
      case MENTORS:
        setIsAction({
          ...isAction,
          visible: true,
          action,
          mentorId: data.id,
        });
        break;
      case TASK:
      case TOPIC:
        setIsCalendarVisible(!isCalendarVisible);
        break;

      default:
        break;
    }
  };

  const onCloseModalActions = () => {
    setIsAction({
      ...isAction,
      visible: false,
    });
  };

  const handleStudyPlanClick = topic => {
    dispatch(
      getTopicById(topic?.id, () => {
        showModal();
      })
    );
  };

  const onAddTopic = (e, date, comment) => {
    const correctDate = date.toString();
    dispatch(
      createStudyPlan(currentUser?.id, data?.id, correctDate, comment, () => {
        showModal();
        dispatch(getCareerPlan());
      })
    );
  };

  const actionButtonFunc = status => showModal(status);

  // Кнопки добавитить в план
  const actionButtons = useMemo(() => {
    if (data.planStatus) {
      return (
        <Button.Group className={css['ActionButtons-group']}>
          <Button disabled>{t('collectionMaterialId:addedToPlan')}</Button>
        </Button.Group>
      );
    } else {
      return (
        <Button.Group className={css['ActionButtons-group']}>
          <Button className={css['ActionButtons-button']} onClick={() => handleStudyPlanClick(data)}>
            {data.planStatus === 'DISABLED' ? (
              <div>{t('collectionMaterialId:unActive')}</div>
            ) : (
              <div>
                <AddToPlan />
                {t('collectionMaterialId:addToPlan')}
              </div>
            )}
          </Button>
        </Button.Group>
      );
    }
  }, [data, isLoading]);

  const buttonType = useMemo(() => {
    switch (type) {
      case MENTORS:
        const renderButton = () => {
          // запросить менторинг
          if (!data.mentoringStatusDto) {
            return (
              <Button className={css['ActionButtons-button']} onClick={() => actionButtonFunc(REQUEST_MENTORING)}>
                {t('mentors:requestMentoring')}
              </Button>
            );
          }

          // Завершено
          if (data.mentoringStatusDto?.value === FINISHED) {
            return (
              <Button className={css['ActionButtons-button']} disabled>
                <AiOutlineCheck />
                {t('mentors:finished')}
              </Button>
            );
          }

          // Запрошено
          if (data.mentoringStatusDto?.value === WAITING_MENTOR) {
            return (
              <Button className={css['ActionButtons-button']} disabled>
                {t('mentors:retrieved')}
              </Button>
            );
          }

          // В процессе
          if (data.mentoringStatusDto?.value === ACTIVE) {
            return (
              <Button className={css['ActionButtons-button']} disabled>
                {t('mentors:inProcess')}
              </Button>
            );
          }

          if (data.mentoringStatusDto?.value === REJECTED_BY_MENTOR) {
            return (
              <Button className={css['ActionButtons-button']} disabled>
                {t('mentors:rejectMentor')}
              </Button>
            );
          }
        };
        return renderButton();

      case TOPIC:
        return actionButtons;

      case TASK:
        const createByCurrentUser = data?.creBy === currentUser.id;

        if (createByCurrentUser && data.planStatus !== null) {
          return (
            <Button onClick={onEditTask} className={css['ActionButtons-button']}>
              {t('collectionMaterialId:editTask')}
            </Button>
          );
        } else {
          return actionButtons;
        }

      default:
        break;
    }
  }, [type, data]);

  return (
    <>
      {buttonType}
      {isAction.visible && (
        <ModalActions
          open={isAction.visible}
          onClose={onCloseModalActions}
          action={isAction.action}
          mentorId={isAction.mentorId}
          competenceId={currentCompetence?.id}
          careerPlanId={careerPlanId}
          isIPR
        />
      )}
      {(type === TOPIC || type === TASK) && isCalendarVisible && (
        <CalendarModal
          title={t('mentors:pickDateTitle')}
          okText={t('mentors:pickDateOk')}
          open={isCalendarVisible}
          eventTypeId={topic?.type?.id}
          onCancel={showModal}
          onOk={(e, date, comment) => onAddTopic(e, date, comment)}
        />
      )}
    </>
  );
};

ActionButtons.propTypes = {
  careerPlanId: PropTypes.number,
  currentCompetence: PropTypes.shape({
    id: PropTypes.number,
  }),
  data: PropTypes.shape({
    creBy: PropTypes.number,
    id: PropTypes.number,
    mentoringStatusDto: PropTypes.object,
    planStatus: PropTypes.string,
  }),
  onEditTask: PropTypes.func,
  type: PropTypes.string,
};

export default ActionButtons;
