/* eslint-disable */
import { API_BASE, API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_INFO_COMPANY_START = 'GET_INFO_COMPANY_START';
export const GET_INFO_COMPANY_SUCCESS = 'GET_INFO_COMPANY_SUCCESS';
export const GET_INFO_COMPANY_FAIL = 'GET_INFO_COMPANY_FAIL';

export const SET_RESTORE_TOKEN = 'SET_RESTORE_TOKEN';
export const SET_REGISTER_TOKEN = 'SET_REGISTER_TOKEN';

export const DELETE_RESTORE_TOKEN = 'DELETE_RESTORE_TOKEN';
export const DELETE_REGISTER_TOKEN = 'DELETE_REGISTER_TOKEN';

export const SET_DEFAULT_URL_LOGIN = 'SET_DEFAULT_URL_LOGIN';

export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_SUBCOMPANIES_START = 'GET_SUBCOMPANIES_START';
export const GET_SUBCOMPANIES_SUCCESS = 'GET_SUBCOMPANIES_SUCCESS';
export const GET_SUBCOMPANIES_FAIL = 'GET_SUBCOMPANIES_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getInfoCompany = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_BASE}/api/v1/company/domain`,
  headers: { ...headers },
  types: [GET_INFO_COMPANY_START, GET_INFO_COMPANY_SUCCESS, GET_INFO_COMPANY_FAIL],
  onSuccess,
  onError,
});

export const setRestoreToken = restoreToken => ({
  type: SET_RESTORE_TOKEN,
  restoreToken,
});

export const setRegisterToken = registerToken => ({
  type: SET_REGISTER_TOKEN,
  registerToken,
});

export const deleteRestoreToken = () => ({
  type: DELETE_RESTORE_TOKEN,
});

export const deleteRegisterToken = () => ({
  type: DELETE_REGISTER_TOKEN,
});

export const setDefaultUrlLogin = url => ({
  type: SET_DEFAULT_URL_LOGIN,
  url,
});

export const getCompaniesByUserName = (name = '', topicId, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/search/user/companies${getQueryString({
      name,
      topicId,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_SUBCOMPANIES_START, GET_SUBCOMPANIES_SUCCESS, GET_SUBCOMPANIES_FAIL],
    onSuccess,
  };
};
