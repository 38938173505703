/* eslint-disable */
import { GET_FAQ_LINKS_FAIL, GET_FAQ_LINKS_START, GET_FAQ_LINKS_SUCCESS } from './actions';

const initialState = {
  isLoading: false,
  error: false,
  links: [],
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_FAQ_LINKS_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case GET_FAQ_LINKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        links: response,
        error: false,
      };
    case GET_FAQ_LINKS_FAIL:
      return {
        ...state,
        isLoading: false,
        links: [],
        error: true,
      };
    default:
      return state;
  }
};
