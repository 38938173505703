import React from 'react';

import { Col, Modal, Row } from 'antd';

import PropTypes from 'prop-types';

export function Purpose(props) {
  return (
    <Modal
      open={props.visible}
      closable={true}
      title={props.title}
      centered={true}
      destroyOnClose={true}
      footer={null}
      onCancel={props.onClose}
    >
      <Row style={{ overflowY: 'auto', maxHeight: '30rem' }}>
        <Col span={24} className='ant-col-puropse-modal'>
          {props.content}
        </Col>
      </Row>
    </Modal>
  );
}

Purpose.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Purpose;
