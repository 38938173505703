/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getEventsGrours } from '@/store/course/actions';

import { Button, Input, Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { isConference, isEventOrConference, isTestCourses } from '@shared/utils/topic-utils';

import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import { ON_WAITING_LIST /*REGISTERED*/ } from '@/constants/waiting-list-status';

const { TextArea } = Input;

const ConfirmModal = props => {
  const { okText, comment, eventTypeId, onCancel, isLoadingPlan, dates, topicInfo, waitingList, isMyProfile } = props;
  const { t } = useTranslation('confirmModal');
  const dispatch = useDispatch();

  const topicStartDateFormat = isMyProfile
    ? dayjs(props.topicInfo.startDate).format('YYYY-MM-DD')
    : props.topicInfo.startDate;

  const dateEventFormat = isMyProfile
    ? dayjs(props.dateEvent, 'D MMMM YYYY').format('YYYY-MM-DD')
    : dayjs(props.dateEvent).format('YYYY-MM-DD');

  const [selectedWebinarDate, setSelectedWebinarDate] = useState('');
  const [selectedMonths, setSelectedMonths] = useState(
    !props.disabledSelect ? [topicStartDateFormat] : [dateEventFormat]
  );

  const [commentVal, setCommentVal] = useState('');
  const [event, setEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [dateEventRenderList, setdateEventRenderList] = useState([]);

  const text = isConference(eventTypeId) ? t('conf1') : t('conf2');
  const eventExpiredDate = selectedEvent?.deadlineRegistration
    ? dayjs(selectedEvent?.deadlineRegistration).diff(dayjs(), 'day') < 0
    : false;

  useEffect(() => {
    if (isConference(eventTypeId)) {
      dispatch(
        /**
         *@param resp { [year:number]: eventObject }
         */
        getEventsGrours({ topicId: topicInfo?.id }, resp => {
          const currentYear = dayjs().format('YYYY');
          const pushArr = [];
          const firstYear = Object.keys(resp).find(year => year >= currentYear);
          const currentYearResp = resp[currentYear];
          const firstYearResp = resp[firstYear];

          const resultEvents = currentYearResp ? currentYearResp : firstYearResp;

          const firstEvent = resultEvents?.find(event =>
            event.periods?.find(period => period.dates?.find(date => date === topicInfo?.startDate))
          );

          if (!firstEvent) throw new Error('Нет событий');

          resultEvents?.forEach(events => {
            events.periods?.forEach(period => {
              period.dates.forEach(date =>
                pushArr.push({
                  date,
                  endTime: period.endTime,
                  startTime: period.startTime,
                  eventId: events.id,
                  placeCount: events.placeCount,
                  deadlineRegistration: events.deadlineRegistration,
                })
              );
            });
          });

          setEvent(currentYearResp);
          setdateEventRenderList(pushArr);
          setSelectedEventId(firstEvent?.id);
        })
      );
    }
  }, []);

  useEffect(() => {
    setSelectedEvent(event?.find(event => event.id === selectedEventId));
  }, [selectedEventId]);

  useEffect(() => {
    topicInfo &&
      Array.isArray(topicInfo.webinarCreateEventResponse) &&
      topicInfo.webinarCreateEventResponse.length > 0 &&
      topicInfo.webinarCreateEventResponse.every(item => {
        if (dayjs(item.startsAt).format('YYYY-MM-DD') === selectedMonths[0]) {
          setSelectedWebinarDate(item.startsAt);

          return false;
        } else {
          setSelectedWebinarDate('');
          return true;
        }
      });
  }, [selectedMonths]);

  const handleOkClick = () => {
    const { onOk, planningTopicId, topicInfo, rest } = props;

    const months = selectedMonths.length > 0 ? selectedMonths : [topicInfo.startDate];

    if (onOk) {
      onOk(
        planningTopicId,
        months,
        commentVal,
        false,
        selectedWebinarDate,
        rest,
        selectedEventId,
        false,
        selectedEvent?.placeCount === 0 || eventExpiredDate
      );
    }
  };

  const onChangeComment = event => setCommentVal(_get(event, 'target.value', ''));

  const selectConfMonths = date => {
    setSelectedMonths([date.date]);
    setSelectedEventId(date?.eventId);
  };

  const checkStatusWaitingList = () => {
    const eventWaitSatus = waitingList?.find(item => item?.courseEventId === selectedEventId)?.status;
    // const checkRegistration = waitingList?.find(item => item?.status === REGISTERED); закомментировано в рамказ задачи https://kampus.atlassian.net/browse/KAM-5718

    const disableWaitingList =
      eventWaitSatus === ON_WAITING_LIST && (selectedEvent?.placeCount === 0 || eventExpiredDate);

    if (disableWaitingList) {
      return true;
    } else {
      return false;
    }
  };

  const currentDates = Array.isArray(dates) && dates.length > 1 && !isConference(eventTypeId) && (
    <>
      {dates
        .map(item => ({ ...item, date: dayjs(item.date) }))
        .sort((a, b) => a.date.isBefore(b.date))
        .map((date, index) => {
          const dateEvent = event?.find(event =>
            event.periods.find(period => period.dates.find(item => item === date.date))
          );

          if (date) {
            const selectedMonthsString = selectedMonths.toString();
            const dateDateString = date?.date?.toString();
            const isActive = selectedMonthsString === dateDateString;

            return (
              <div
                className={classNames(isActive && css['Calendar-modal-date_active'], css['Calendar-modal-date'])}
                onClick={e => {
                  e.stopPropagation();
                  setSelectedMonths([date.date]);
                }}
                key={index}
              >
                <div>
                  <span>{dayjs(date.date, 'YYYY-MM-DD').format('LL')}</span>
                  {dateEvent?.placeCount === 0 && isTestCourses(topicInfo?.type?.id) && (
                    <Tooltip title={t('tooltipLabel')} zIndex={10001}>
                      <ExclamationCircleFilled style={{ color: '#FFB02E', marginLeft: '5px' }} />
                    </Tooltip>
                  )}
                </div>
                {isEventOrConference(topicInfo?.type?.id) && (
                  <span>
                    {' '}
                    {`${dayjs(date?.startTime, 'HH:mm:ss').format('LT')} - ${dayjs(date?.endTime, 'HH:mm:ss').format(
                      'LT'
                    )}`}
                  </span>
                )}
              </div>
            );
          }
        })}
    </>
  );

  return (
    <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
      <div className={classNames(css['Calendar-modal-header'], css['Calendar-modal-header--ext'])}>
        {text}
        <br /> {dayjs(selectedMonths.toString()).format('D MMMM YYYY')}?
      </div>
      {comment && !isConference(eventTypeId) && <div className={css['Calendar-modal-comment']}>{comment}</div>}
      {isConference(eventTypeId) && (
        <div className={css['Calendar-modal__row__field-marginTop']}>
          <TextArea
            className={css.Comment}
            placeholder={t('comment')}
            rows={3}
            onChange={onChangeComment}
            value={commentVal}
            maxLength={500}
          />
        </div>
      )}
      {Array.isArray(dateEventRenderList) && dateEventRenderList.length > 1 && isConference(eventTypeId) && (
        <>
          {dateEventRenderList
            .sort((a, b) => new dayjs(a.date) - new dayjs(b.date))
            .map((date, index) => {
              if (date) {
                const expiredDate = dayjs(date?.deadlineRegistration).diff(dayjs(), 'day') < 0;
                const selectedRow = selectedMonths.toString() === date.date && selectedEventId === date.eventId;
                const timeRange = `${dayjs(date?.startTime, 'HH:mm').format('HH:mm')} - ${dayjs(date?.endTime, 'HH:mm:ss').format('HH:mm')}`;

                return (
                  <div
                    className={classNames([
                      { [css['Calendar-modal-date_active']]: selectedRow },
                      css['Calendar-modal-date'],
                    ])}
                    onClick={() => selectConfMonths(date)}
                    key={index}
                  >
                    <div>
                      <span>{dayjs(date?.date).format('D MMM YYYY')}</span>
                      {(date?.placeCount === 0 || expiredDate) && isTestCourses(topicInfo?.type?.id) && (
                        <Tooltip title={t('tooltipLabel')} zIndex={10001}>
                          <ExclamationCircleFilled style={{ color: '#FFB02E', marginLeft: '5px' }} />
                        </Tooltip>
                      )}
                    </div>
                    <span>{timeRange}</span>
                  </div>
                );
              }
            })}
        </>
      )}
      {currentDates}
      <div className={css['Calendar-modal__row__btn-box']}>
        <Button
          disabled={isLoadingPlan || checkStatusWaitingList()}
          className={css['Calendar-modal-btn__orange']}
          type='primary'
          onClick={handleOkClick}
          size='large'
        >
          {selectedEvent?.placeCount === 0 || eventExpiredDate ? t('waitList') : okText}
        </Button>
        <span className={css['Calendar-modal__row__btn-box-cancel']} onClick={onCancel}>
          {t('cancel')}
        </span>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  comment: PropTypes.string,
  dateEvent: PropTypes.string,
  disabledSelect: PropTypes.bool,
  domainCompany: PropTypes.any,
  eventTypeId: PropTypes.number,
  headerFixToModal: PropTypes.bool,
  isLoadingPlan: PropTypes.bool,
  noComment: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.any,
  onOk: PropTypes.any,
  planningTopicId: PropTypes.number,
  rest: PropTypes.any,
  title: PropTypes.string,
  topicInfo: PropTypes.any,
  t: PropTypes.any,
  dates: PropTypes.any,
};

export default ConfirmModal;
