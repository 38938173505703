import { Dropdown } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const DropdownMenu = ({ className, menu, onClick }) => (
  <Dropdown
    menu={{ items: menu, onClick }}
    trigger={['click']}
    placement='bottomRight'
    overlayClassName={cn('dropdown-menu')}
  >
    <button type='button' className={cn('ant-dropdown-link', className)} onClick={onClick}>
      <span />
      <span />
      <span />
    </button>
  </Dropdown>
);

DropdownMenu.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
};
