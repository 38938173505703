// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]:before, .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.ql-font-Montserrat {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.smallSizeSearchButton .ant-input-search-button {
  padding: 0 15px;
}

.menuOverflowChange.ant-menu-item, .ant-menu-inline.ant-menu-root .menuOverflowChange.ant-menu-item > .ant-menu-title-content {
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./user/src/styles/main.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,eAAe;AACjB;;AAQA;EALE,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.smallSizeSearchButton .ant-input-search-button {\n  padding: 0 15px;\n}\n\n.menuOverflowChange.ant-menu-item,\n.ant-menu-inline.ant-menu-root .menuOverflowChange.ant-menu-item > .ant-menu-title-content {\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
