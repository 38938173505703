import PropTypes from 'prop-types';

const useIsMobileClass = (maxvalue = 992, minvalue = 0) => {
  let isMobile = false;
  if (minvalue > 0) {
    isMobile = window.innerWidth <= maxvalue && window.innerWidth >= minvalue;
  } else {
    isMobile = window.innerWidth <= maxvalue;
  }

  return isMobile;
};

useIsMobileClass.propTypes = {
  maxvalue: PropTypes.number,
  minvalue: PropTypes.number,
};

export default useIsMobileClass;
