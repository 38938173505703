// /my-profile/recommended
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { getBookAddresses, updateBookAddresses } from '@/store/books/actions';
import { getBlockStatistic } from '@/store/lms/actions';
import { clearPlan, createStudyPlan } from '@/store/study-plan/actions';
import { getAnotherRecommendedNews } from '@/store/user-topics/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectUserTopics } from '@/store/user-topics/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import { FloatButton, Layout, Radio, Spin } from 'antd';
import NewsElement from '@/pages/MyProfile/News/NewsElement';
import AddressBook from '@/components/AddressBook/AddressBook';
import CustomEmpty from '@/components/CustomEmpty';
import ListItem from '@/components/MaterialListItem';
import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction';

import Utils from '@/Utils';
import dayjs from 'dayjs';

import css from '../info.module.scss';

const GROUP_TYPES = {
  MATERIALS: 'materials',
  NEWS: 'news',
};

const { Content } = Layout;
const { Button, Group } = Radio;

const RecommendedTopic = () => {
  const {
    dataqa,
    profileId,
    onFavoriteClick,
    updateInfinityScrollRecommended,
    onStudyPlanClick,
    onDeleteClick,
    externalLogin,
    hideActionButtons,
    openDate,
    addressId,
    setAddressId,
  } = useOutletContext();
  const { t } = useTranslation('recommendedTopic');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recommended, isRecommendedLoading } = useSelector(selectUserTopics);
  const currentUser = useSelector(selectCurrentUser);
  const { previousStudyPlansCheckResult } = useSelector(selectStudyPlan);

  const [currentType, setCurrentType] = useState(GROUP_TYPES.MATERIALS);
  const [addressState, setAddressState] = useState({
    isAddressBookVisible: false,
    calendarModalInfo: {},
    addresses: [],
    flagNow: false,
  });
  const [currentPaidTopicId, setCurrentPaidTopicId] = useState(null);
  const [currentPaidTopicName, setCurrentPaidTopicName] = useState('');
  const [isModalCurrentPaidTopicOpen, setIsModalCurrentPaidTopicOpen] = useState(false);
  const [isModalPaidTopicRestrictionOpen, setIsModalPaidTopicRestrictionOpen] = useState(false);
  const [handleStudyPlanClickState, setHandleStudyPlanClickState] = useState(null);

  useEffect(() => {
    dispatch(getAnotherRecommendedNews(0));
  }, []);

  useEffect(() => {
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      !previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setCurrentPaidTopicName(previousStudyPlansCheckResult?.topicName);
      setCurrentPaidTopicId(previousStudyPlansCheckResult?.topicId);
      setIsModalCurrentPaidTopicOpen(true);
    }
    if (
      !previousStudyPlansCheckResult?.availableCreation &&
      previousStudyPlansCheckResult?.currentStudyPlanEnded &&
      previousStudyPlansCheckResult?.currentStudyPlan > 0
    ) {
      setIsModalPaidTopicRestrictionOpen(true);
    }
    if (previousStudyPlansCheckResult?.availableCreation) {
      handleStudyPlanClickState();
    }
  }, [previousStudyPlansCheckResult]);

  const handleModalCurrentPaidTopicClose = () => {
    setIsModalCurrentPaidTopicOpen(false);
    dispatch(clearPlan());
  };

  const handleModalPaidTopicRestrictionClose = () => {
    setIsModalPaidTopicRestrictionOpen(false);
    dispatch(clearPlan());
  };

  const showCalendarModal = onStudyPlanClick;

  const showAddressBookModal = (topicId, type, startDate, topicInfo) => {
    dispatch(
      getBookAddresses(
        topicId,
        data => {
          const addresses = data.map(item => ({
            id: item.id,
            address: item.addressDto.address,
            accessible: item.accessible,
          }));

          if (addresses.length > 0) {
            setAddressState({
              addresses,
              isAddressBookVisible: true,
              calendarModalInfo: { topicId, type, startDate, topicInfo },
            });
            return;
          }

          showCalendarModal(topicId, type, startDate, topicInfo);

          setAddressState({ ...addressState, calendarModalInfo: {} });
        },
        () => setAddressState({ ...addressState, addresses: [] })
      )
    );
  };

  const onCloseAddressBook = () => {
    setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] });
  };

  const handleAddressBookSubmit = currentAddressId => {
    const { topicId, type, startDate, topicInfo } = addressState?.calendarModalInfo;

    setAddressState({ isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] });
    setAddressId(currentAddressId > 0 ? currentAddressId : 0);

    if (topicInfo.withNoEvents && addressState.flagNow) {
      addToPlanNow(topicId, topicInfo.duration);
      setAddressState({ flagNow: false });
    } else {
      showCalendarModal(topicId, type, startDate, topicInfo);
    }
  };

  const addToPlanNow = (topicId, topicDuration = 0) => {
    const date = Utils.getPlanDate(topicDuration);

    dispatch(
      getBlockStatistic({
        topicId,
        userId: currentUser.id,
        onSuccess: response => {
          const blocksCount = response?.countBlocks || 0;

          if (addressId > 0) {
            dispatch(updateBookAddresses(addressId, currentUser.id));
            setAddressId(0);
          }

          dispatch(
            createStudyPlan(currentUser.id, topicId, date, '', () => {
              navigate(Utils.getNavigateUrl(topicId, blocksCount));
            })
          );
        },
      })
    );
  };

  const onAddToPlanNow = topic => {
    if (topic.qiwiLibrary) {
      const withNoEvents = Utils.isWithoutEvents(topic);
      const topicInfo = {
        id: topic.id,
        independent: topic.independent,
        startDate: topic.startDate,
        topicCost: topic.cost,
        topicDates: Array.isArray(topic.dates) && topic.dates.map(date => ({ dates: date.startDate })),
        link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
        eventCount: topic.futureEvents,
        topicComment: topic.comment,
        withNoEvents,
        duration: topic.duration,
      };

      dispatch(
        getBookAddresses(
          topic.id,
          data => {
            const addresses = data.map(item => ({
              id: item.id,
              address: item.addressDto.address,
              accessible: item.accessible,
            }));

            if (addresses.length > 0) {
              setAddressState({
                addresses,
                isAddressBookVisible: true,
                calendarModalInfo: {
                  topicId: topic.id,
                  type: topic.type,
                  startDate: topic.startDate,
                  topicInfo,
                },
                flagNow: true,
              });
              return;
            }

            addToPlanNow(topic.id, topic.duration);
          },
          () => setAddressState({ ...addressState, addresses: [] })
        )
      );
    } else {
      addToPlanNow(topic.id, topic.duration);
    }
  };

  const listMaterials = recommended?.elements?.map((item, index) => {
    const onStudyPlanClickItem = item.qiwiLibrary || item.topic?.qiwiLibrary ? showAddressBookModal : onStudyPlanClick;

    const topic = item?.topic || item;
    if (item?.topic) {
      return (
        <ListItem
          dataqa={dataqa}
          key={`${item.id}-${index}`}
          user={item.user}
          topic={item.topic}
          profileId={profileId}
          onFavoriteClick={onFavoriteClick}
          theLast={recommended?.elements?.length - 1 === index}
          {...(item.topic
            ? {
                checkIsEnd: item.topic.type?.id === 8 && dayjs(item.topic.startDate).isBefore(dayjs()),
              }
            : {
                checkIsEnd: item.type?.id === 8 && dayjs(item.startDate).isBefore(dayjs()),
              })}
          onStudyPlanClick={onStudyPlanClickItem}
          onDeleteClick={onDeleteClick}
          page={recommended.page}
          typeList='recommended'
          recUsers={item?.recUsers}
          lastRec={item?.recUsers?.filter(rec => rec.id === item.recBy)}
          isExternalLogin={externalLogin}
          hideActionButtons={hideActionButtons}
          openDate={openDate}
          onAddToPlanNow={() => onAddToPlanNow(topic)}
          setHandleStudyPlanClickState={setHandleStudyPlanClickState}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <Content className={css.RecommendedTopic}>
      <div className={css.RecommendedTopic__content}>
        <Group
          value={currentType}
          onChange={event => setCurrentType(event.target.value)}
          className={css.RecommendedTopic__group}
        >
          <Button key='GROUP_TYPES.MATERIALS' value={GROUP_TYPES.MATERIALS}>
            {t('materials')}
          </Button>
          <Button key='GROUP_TYPES.NEWS' value={GROUP_TYPES.NEWS}>
            {t('news')}
          </Button>
        </Group>

        {currentType === GROUP_TYPES.NEWS ? (
          <NewsElement
            type='recommended'
            emptyContent={{
              title: t('nothingYet'),
              description: t('description3News'),
            }}
          />
        ) : (
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={e => {
              updateInfinityScrollRecommended(e);
            }}
            hasMore={recommended.hasMore && !isRecommendedLoading}
            threshold={250}
            loader={
              <div key='unique-loader' className={css.RecommendedTopic__loader}>
                <Spin size='large' spinning={isRecommendedLoading} />
              </div>
            }
          >
            {recommended.elements?.length === 0 && !isRecommendedLoading ? (
              <CustomEmpty title={t('nothingYet')} description={t('description3')} />
            ) : (
              listMaterials
            )}
          </InfiniteScroll>
        )}
      </div>
      <FloatButton.BackTop />
      <AddressBook
        textWindowTitle={t('collectionMaterials:addressBookWindowTitle')}
        textSubmitButton={t('collectionMaterials:addressBookSubmitButton')}
        textAccessible={t('collectionMaterials:addressBookaccessible')}
        textNotAccessible={t('collectionMaterials:addressBookNotAccessible')}
        textReadOnline={t('collectionMaterials:addressBookReadOnline')}
        onButtonClose={onCloseAddressBook}
        onButtonSubmit={handleAddressBookSubmit}
        open={addressState.isAddressBookVisible}
        data={addressState?.addresses}
      />
      {isModalCurrentPaidTopicOpen && (
        <ModalCurrentPaidTopic
          open={isModalCurrentPaidTopicOpen}
          onClose={handleModalCurrentPaidTopicClose}
          currentPaidTopicName={currentPaidTopicName}
          currentPaidTopicId={currentPaidTopicId}
        />
      )}

      {isModalPaidTopicRestrictionOpen && (
        <ModalPaidTopicRestriction
          open={isModalPaidTopicRestrictionOpen}
          onClose={handleModalPaidTopicRestrictionClose}
        />
      )}
    </Content>
  );
};

export default RecommendedTopic;
