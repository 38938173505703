/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getEventsGrours, getOpenDate } from '@/store/course/actions';

import { selectCourse as courseSelect } from '@/store/course/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import { Button, Input, Spin } from 'antd';
import EventRow from '@/components/EventRow';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import {
  isCorpCourseWithOrWithoutApprove,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourseWithOrWithoutApp,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import { ON_WAITING_LIST /*REGISTERED */ } from '@/constants/waiting-list-status';

const { TextArea } = Input;

class EventModal extends Component {
  static propTypes = {
    activeEvents: PropTypes.object,
    dateEvent: PropTypes.any,
    disabledSelect: PropTypes.bool,
    eventTypeId: PropTypes.number,
    getEventsGrours: PropTypes.func,
    getOpenDate: PropTypes.func,
    handleCalendarModalData: PropTypes.func,
    headerFixToModal: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingGroup: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    noComment: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.any,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    title: PropTypes.string,
    topicInfo: PropTypes.object,
    visible: PropTypes.bool,
    currentUser: PropTypes.object,
    topicTargetAudience: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.currentYear = Number(dayjs().format('YYYY'));
    this.state = {
      comment: '',
      yearIndex: 0,
      years: [],
      selectedYear: null,
      activeEvents: props.activeEvents,
      selectedMonths: props.activeEvents &&
        props.activeEvents[this.currentYear] && [
          dayjs(props.activeEvents[this.currentYear][0].startTime).format('YYYY-MM-DD'),
        ],
      selected: {
        [props.activeEvents && props.activeEvents[this.currentYear] && props.activeEvents[this.currentYear][0].id]:
          true,
      },
      selectedId: null,
      selectedEvent:
        props.activeEvents && props.activeEvents?.[this.currentYear] && props.activeEvents?.[this.currentYear]?.[0],
    };
  }

  componentDidMount() {
    this.props.getOpenDate();
    this.props.getEventsGrours({ topicId: this.props.topicInfo.id }, res => {
      this.years = res && Object.keys(res);
      const [firstList] = res?.[this.years?.[0]];
      if (firstList?.id && firstList?.startTime) {
        this.setState({
          years: this.years,
          selectedYear: this.years[0],
          activeEvents: res,
          selectedMonths: [dayjs(firstList.startTime).format('YYYY-MM-DD')],
          selected: {
            [firstList.id]: true,
          },
          selectedId: firstList.id,
          selectedEvent: firstList,
        });
      } else {
        this.props.handleCalendarModalData({ hasEventsFlag: false });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(newProps, nextState) {
    if (newProps.topicInfo.id !== this.props.topicInfo.id) {
      this.props.getEventsGrours({ topicId: newProps.topicInfo.id });
    }
    if (
      newProps.activeEvents &&
      newProps.activeEvents !== this.props.activeEvents &&
      Object.keys(newProps.activeEvents) &&
      Object.keys(newProps.activeEvents).length
    ) {
      this.years = Object.keys(newProps.activeEvents);
      this.setState({
        years: this.years,
        selectedYear: this.years[this.state.yearIndex],
        activeEvents: newProps.activeEvents,
        selectedMonths: newProps.activeEvents
          ? [
              dayjs(
                newProps.activeEvents[this.years[this.state.yearIndex]][0] &&
                  newProps.activeEvents[this.years[this.state.yearIndex]][0].startTime
              ).format('YYYY-MM-DD'),
            ]
          : dayjs().format('YYYY-MM-DD'),
        selected: {
          [newProps.activeEvents[this.years[this.state.yearIndex]][0].id]: true,
        },
      });
    }
    if (
      newProps.activeEvents &&
      this.years &&
      nextState.selectedYear !== this.state.selectedYear &&
      Object.keys(newProps.activeEvents) &&
      Object.keys(newProps.activeEvents).length
    ) {
      if (
        (newProps.activeEvents[this.years[this.state.yearIndex]] &&
          newProps.activeEvents[this.years[this.state.yearIndex]].length) !== 1
      ) {
        this.setState({
          activeEvents: newProps.activeEvents,
          selectedMonths: [],
          selected: {},
          btnDisabled: true,
        });
      } else {
        this.setState({
          selectedMonths: [
            dayjs(newProps.activeEvents[this.years[this.state.yearIndex]][0].startTime).format('YYYY-MM-DD'),
          ],
          selected: {
            [newProps.activeEvents && newProps.activeEvents[this.years[this.state.yearIndex]][0].id]: true,
          },
          btnDisabled: false,
        });
      }
    }
    if (newProps.visible !== this.props.visible && !newProps.visible) {
      this.setState({
        selectedYear: this.years && this.years[this.state.yearIndex],
        comment: '',
        selectedMonths: [],
      });
    }
  }

  increaseYear = () => {
    this.setState(state => ({
      yearIndex: state.yearIndex + 1,
      selectedYear: this.years[state.yearIndex + 1],
    }));
  };

  decreaseYear = () => {
    this.setState(state => ({
      yearIndex: state.yearIndex - 1,
      selectedYear: this.years[state.yearIndex - 1],
    }));
  };

  setParams = (id, date, event) => {
    this.setState({
      selected: {
        [id]: true,
      },
      selectedId: id,
      selectedMonths: [dayjs(date).format('YYYY-MM-DD')],
      btnDisabled: false,
      selectedEvent: event,
    });
  };

  changeComment = event => {
    const text = event.target.value;
    this.setState({ comment: text });
  };

  handleOkClick = () => {
    const { onOk, planningTopicId, rest, planInfo, topicTargetAudience } = this.props; // для MARS
    const { selectedId, selectedEvent } = this.state; // для MARS

    const eventExpiredDate = dayjs(selectedEvent?.deadlineRegistration).diff(dayjs(), 'day') < 0; // для MARS

    if (onOk) {
      onOk(
        planningTopicId,
        this.state.selectedMonths,
        this.state.comment,
        false,
        rest,
        planInfo,
        selectedId, // для MARS
        topicTargetAudience, // для MARS
        selectedEvent?.placeCount === 0 || eventExpiredDate // для MARS
      );
    }
  };

  checkStatusWaitingList = () => {
    const eventWaitSatus = this.props.waitingList?.find(item => item?.courseEventId === this.state?.selectedId)?.status;
    // const checkRegistration = this.props.waitingList?.find(item => item?.status === REGISTERED); закомментировано в рамказ задачи https://kampus.atlassian.net/browse/KAM-5718
    const eventExpiredDate = dayjs(this.state.selectedEvent?.deadlineRegistration).diff(dayjs(), 'day') < 0;

    const disableWaitingList =
      eventWaitSatus === ON_WAITING_LIST && (this.state.selectedEvent?.placeCount === 0 || eventExpiredDate);

    if (disableWaitingList) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { okText, eventTypeId, isLoading, isLoadingPlan, isLoadingGroup, t } = this.props;
    const { activeEvents, selected, yearIndex, selectedYear, selectedEvent } = this.state;

    const eventExpiredDate = dayjs(selectedEvent?.deadlineRegistration).diff(dayjs(), 'day') < 0;

    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        <div
          className={classNames(
            css['Calendar-modal-header'],
            css['Calendar-modal-header--ext'],
            css['Calendar-modal-header--flex']
          )}
        >
          <div>
            {t('learningStart')} <br />
            {isCorpCourseWithOrWithoutApprove(eventTypeId) ? t('corpCourse') : t('course')}?
          </div>
          <div>
            <div className={classNames(css['Calendar-modal-year'], css['Calendar-modal-year--ext'])}>
              <CaretLeftOutlined onClick={this.scrollLeft} disabled={this.state.isLeftArrowDisabled} />
              <div className={classNames(css['Calendar-modal-year-value'], css['Calendar-modal-year--ext-value'])}>
                {this.state.years[yearIndex]}
              </div>
              <CaretRightOutlined onClick={this.scrollRighrt} disabled={this.state.isRightArrowDisabled} />
            </div>
          </div>
        </div>
        {isLoadingGroup ? (
          <Spin />
        ) : (
          <div className={css['Calendar-modal-eventBox']}>
            {selectedYear &&
              activeEvents[selectedYear].map(event =>
                event.active ? (
                  <EventRow
                    key={event.id}
                    event={event}
                    setParams={this.setParams}
                    selected={selected && selected[event.id]}
                    topicType={eventTypeId}
                    isInModal
                    currentUser={this.props.currentUser}
                  />
                ) : null
              )}
          </div>
        )}
        <div className={css['Calendar-modal__row__field-marginTop']}>
          <TextArea
            className={css.Comment}
            placeholder={t('comment')}
            rows={3}
            onChange={this.changeComment}
            value={this.state.comment}
            maxLength={500}
          />
        </div>
        <div className={classNames(css['Calendar-modal__row__btn-box'], css.toCenter)}>
          <Button
            disabled={
              isLoadingPlan ||
              isLoading ||
              this.state.btnDisabled ||
              isLoadingGroup ||
              !this.state.selectedId ||
              this.checkStatusWaitingList()
            }
            className={css['Calendar-modal-btn__orange']}
            type='primary'
            onClick={this.handleOkClick}
            size='large'
          >
            {selectedEvent?.placeCount === 0 || eventExpiredDate ? t('waitingList') : okText}
          </Button>
          <Button
            onClick={() =>
              this.props.handleCalendarModalData({
                hasEventsFlag: true,
                showInterest: isCorpOrExtCourseWithOrWithoutApp(eventTypeId),
              })
            }
            size='large'
          >
            {isCorpOrExtCourseWithOrWithoutApp(eventTypeId) ? t('showInterest') : t('otherDate')}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  [courseSelect, studyPlanSelect, selectTopics, userTopicsSelect, selectCurrentUser],
  (course, plan, topics, userTopics, currentUser) => ({
    activeEvents: course.eventsGroups,
    openDate: course.openDate,
    isLoading: plan.isLoading || topics.isLoading || userTopics.isLoading,
    isLoadingGroup: course.isLoading,
    currentUser,
  })
);

const mapActionsToProps = {
  getEventsGrours,
  getOpenDate,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('eventModal')(EventModal));
