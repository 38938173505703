import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  GET_PRODUCT_BY_ID_FAIL,
  GET_PRODUCT_BY_ID_START,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getProducts = (page = 0, term, size = 20, signal) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product${getQueryString({ page, term, size })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_PRODUCTS_START, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL],
  signal,
});

export const getProductById = (id, signal) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/product/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  signal,
  types: [GET_PRODUCT_BY_ID_START, GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCT_BY_ID_FAIL],
});

export const createOrder = (body, onSuccess, onError) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/product/order`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body,
  types: [CREATE_ORDER_START, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAIL],
  onSuccess,
  onError,
});
