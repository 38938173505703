export const GET_EXECUTIVE_TOPIC_INFO_START = 'GET_EXECUTIVE_TOPIC_INFO_START';
export const GET_EXECUTIVE_TOPIC_INFO_SUCCESS = 'GET_EXECUTIVE_TOPIC_INFO_SUCCESS';
export const GET_EXECUTIVE_TOPIC_INFO_FAIL = 'GET_EXECUTIVE_TOPIC_INFO_FAIL';

export const POST_EXECUTIVE_TASK_START = 'POST_EXECUTIVE_TASK_START';
export const POST_EXECUTIVE_TASK_SUCCESS = 'POST_EXECUTIVE_TASK_SUCCESS';
export const POST_EXECUTIVE_TASK_FAIL = 'POST_EXECUTIVE_TASK_FAIL';

export const GET_EXECUTIVE_TASK_START = 'GET_EXECUTIVE_TASK_START';
export const GET_EXECUTIVE_TASK_SUCCESS = 'GET_EXECUTIVE_TASK_SUCCESS';
export const GET_EXECUTIVE_TASK_FAIL = 'GET_EXECUTIVE_TASK_FAIL';

export const PUT_EXECUTIVE_TASK_START = 'PUT_EXECUTIVE_TASK_START';
export const PUT_EXECUTIVE_TASK_SUCCESS = 'PUT_EXECUTIVE_TASK_SUCCESS';
export const PUT_EXECUTIVE_TASK_FAIL = 'PUT_EXECUTIVE_TASK_FAIL';

export const POST_EXECUTIVE_TOPIC_INFO_START = 'POST_EXECUTIVE_TOPIC_INFO_START';
export const POST_EXECUTIVE_TOPIC_INFO_SUCCESS = 'POST_EXECUTIVE_TOPIC_INFO_SUCCESS';
export const POST_EXECUTIVE_TOPIC_INFO_FAIL = 'POST_EXECUTIVE_TOPIC_INFO_FAIL';

export const PUT_EXECUTIVE_TOPIC_INFO_START = 'PUT_EXECUTIVE_TOPIC_INFO_START';
export const PUT_EXECUTIVE_TOPIC_INFO_SUCCESS = 'PUT_EXECUTIVE_TOPIC_INFO_SUCCESS';
export const PUT_EXECUTIVE_TOPIC_INFO_FAIL = 'PUT_EXECUTIVE_TOPIC_INFO_FAIL';
