import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from 'antd';
import Certificate from '@/components/Certificate';
import { MaterialCard } from '@/components/material-card';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import useIsMobile from '@shared/hooks/useIsMobile';
import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import { FINISHED } from '@/constants/study-plan-statuses';

const cn = classNames(styles);

export const Card = ({ material, loading, onAction, currentUser }) => {
  const { t } = useTranslation('card');
  const [isMobile, setIsMobile] = useIsMobile(768);
  const [orientation, setOrientation] = useState(768);
  const [favorite, setFavorite] = useState(material.topic.isFavourite);

  const onRecommend = () => onAction({ action: 'recommend', material });

  const onFavorite = value => {
    onAction({ action: value ? 'favorite' : 'notFavorite', material });
    setFavorite(!favorite);
  };

  const onReview = () => onAction({ action: 'review', material });

  const topicId = material?.topic?.id;

  const showCertificateBack = material?.showCertificate;
  const isFinishedMaterial = material?.status === FINISHED;
  const showCertificate = isFinishedMaterial && showCertificateBack;

  const checkMobileCert = event => {
    setIsMobile(event.target.innerWidth < orientation);
  };

  const checkOrientCert = value => {
    setOrientation(value);
  };

  useEffect(() => {
    window.addEventListener('resize', checkMobileCert);

    return () => {
      window.removeEventListener('resize', checkMobileCert);
    };
  }, []);

  const mainButton = !material.mention ? (
    <Button onClick={onReview} disabled={loading} className={cn('finished-card__button')} type='primary' size='large'>
      {t('feedback')}
    </Button>
  ) : currentUser.domainCompany.showColleagues ? (
    <Button onClick={onRecommend} className={cn('finished-card__button')} type='primary' size='large'>
      {t('recommendation')}
    </Button>
  ) : null;

  return (
    <div className={cn('finished-card-main')}>
      <MaterialCard
        currentUser={currentUser}
        material={material}
        className={cn('finished-card-matCard')}
        asideClass={cn('finished-card__aside')}
        small
        isPlanninFinished
        mdPoster={5}
        mdCentr={13}
      />
      {/* Костыль чтобы не поехала верстка для "Мой план" */}
      <div className={cn('finished-card-buttonBlock')}>
        {showCertificate && (
          <Certificate
            className={cn('finished-card__certificate')}
            topicId={topicId}
            isMobile={isMobile}
            setIsMobile={checkOrientCert}
          />
        )}

        <Flex align='center' className={cn('finished-card__buttonGroup')}>
          {mainButton}
          <Button
            onClick={onFavorite}
            type='link'
            size='large'
            className={cn('finished-card__button__favorite', {
              'finished-card__button__favorite_only': mainButton === null,
            })}
            icon={
              favorite ? <HeartFilled style={{ fontSize: '24px' }} /> : <HeartOutlined style={{ fontSize: '24px' }} />
            }
          ></Button>
        </Flex>
      </div>
    </div>
  );
};

Card.propTypes = {
  material: PropTypes.object,
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
};
