import React, { useMemo } from 'react';

import { Popover } from 'antd';

import { classNames } from '@shared/utils/styles-utils';

import Resources from '@/resources';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const StatusPopover = ({ status }) => {
  const content = useMemo(
    () => (
      <>
        <h4 className={cn('status-popover__title')}>{status.nameUser}</h4>
        <p className={cn('status-popover__description')}>{status.description}</p>
      </>
    ),
    [status]
  );

  return (
    <Popover
      content={content}
      overlayClassName={cn('status-popover')}
      placement='bottomRight'
      trigger='hover'
      className={cn('status-popover__status')}
    >
      <span>
        <Resources.icons.Info />
      </span>
    </Popover>
  );
};

StatusPopover.propTypes = {
  status: PropTypes.any,
};
