/* eslint-disable */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import classNames from 'classnames';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './styles.module.scss';

const Sort = props => {
  const {
    isFilterEmpty,
    isLoading,
    labelFilter,
    labelSearchText,
    labelSkillsText,
    topicAmount,
    handleChangeSort,
    activeSort,
    sortTypes,
    dataqa,
  } = props;

  const { t } = useTranslation('sort');

  const isSearchActive = isFilterEmpty && !isLoading;

  return (
    <div className={css.Sort}>
      {isSearchActive && (
        <div className={css['Sort-resultSearch']}>
          <Tooltip title={labelSkillsText?.length >= 40 && labelSkillsText}>
            {t('match', {
              labelFilter,
              labelSearchText:
                labelSearchText ||
                (labelSkillsText?.length >= 40 ? `${labelSkillsText.slice(0, 40)}...` : labelSkillsText),
              topicAmount,
              result: noun(Number(topicAmount), t('matches1'), t('matches2'), t('matches3')),
            })}
          </Tooltip>
        </div>
      )}
      <div className={css['Sort-block']}>
        <div className={css['Sort-label']}>{t('sort')}</div>
        {sortTypes.map((sort, index) => (
          <Fragment key={sort.value}>
            <div
              data-qa={`${dataqa}-sortingBtn-${sort.name}`}
              className={classNames(css['Sort-point'], {
                [css['Sort-active']]: activeSort === sort.value,
              })}
              onClick={() => handleChangeSort(sort.value)}
            >
              {sort.name}
            </div>
            {index + 1 < sortTypes.length && <div className={css['Sort-point']}>|</div>}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

Sort.propTypes = {
  activeSort: PropTypes.string,
  isFilterEmpty: PropTypes.any,
  isLoading: PropTypes.bool,
  dataqa: PropTypes.string,
  labelFilter: PropTypes.string,
  labelSearchText: PropTypes.string,
  topicAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelSkillsText: PropTypes.string,
  handleChangeSort: PropTypes.func,
  sortTypes: PropTypes.array,
};

export default Sort;
