import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { addTopicCommentsToList, addTopicMentionsToList, createTopicCommentReply } from '@/store/topics/actions';

import { Avatar, Button, Col, Input, Row, Tooltip } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';
import { HiOutlineReply, HiPencil, HiX } from 'react-icons/hi';

import Rate from '../Rate';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import classNames from 'classnames';
import dayjs from 'dayjs';

import materialItemCss from '../MaterialListItem/styles.module.scss';
import css from './Comment.module.scss';

import { TOPIC_COMMENT_REPLY } from '@/constants/permissions';
import lock from '@/assets/images/lock.svg';

const { TextArea } = Input;

const STATUS_DELETED = 'DELETED';

const Comment = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const DELETED_USER = props.comment?.user?.status === STATUS_DELETED;

  const [state, setState] = useState({
    btnClick: false,
    textComment: props.comment.text,
    isReply: false,
    textReply: '',
    isEdit: false,
  });

  const [currentAvatar] = useLocalFileByUUID(props.comment.user.localPhotoUuid || props.comment.user.photoUuid, {
    defaultImg,
  });

  const changeComment = event => {
    setState({ ...state, textComment: event.target.value });
  };

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      textComment: props.comment.text,
    }));
  }, [props.comment.text, state.deletable]);

  const deleteComment = () => {
    props.deleteComment &&
      props.deleteComment(props.comment.id, () => {
        setState({ ...state, deletable: true });
      });
  };

  const restoreComment = () => {
    props.restoreComment(props.comment.id, () => {
      setState({ ...state, deletable: false });
    });
  };

  const updateComment = () => {
    setState({ ...state, btnClick: true });
    props.updateComment(
      props.comment.id,
      {
        userId: props.currentUserId,
        text: state.textComment.trim(),
      },
      () => {
        setState({ ...state, btnClick: false, isEdit: false });
        props.editCommentFinish();
        props.onHideActions(true);
      }
    );
  };

  const cancelChange = () => {
    setState({ ...state, textComment: props.comment.text, isEdit: false });
    props.editCommentFinish();
    props.onHideActions(true);
  };

  const replyComment = () => {
    props.onHideActions(false);
    setState({ ...state, isReply: true });
  };

  const sendReply = () => {
    setState({ btnClick: true });
    const params = {
      topicId: props.topicId,
      replyTo: props.comment.id, // id comment
      text: state.textReply.trim(),
    };

    dispatch(
      createTopicCommentReply(params, response => {
        setState({ ...state, btnClick: false, isReply: false, textReply: '' });
        props.onHideActions(true);
        if (props.review) {
          dispatch(addTopicMentionsToList(response));
        } else {
          dispatch(addTopicCommentsToList(response));
        }
      })
    );
  };

  const cancelReply = () => {
    setState({ ...state, textReply: '', isReply: false });
    props.onHideActions(true);
  };

  const renderReplyBlock = (userId, userFirstName, userLastName, createdDate, text, modified, rate, isReply) => {
    const isDeletedComment = props.comment?.commentDto?.status === STATUS_DELETED;
    const createdDateText = dayjs(createdDate).format('DD MMMM YYYY');

    return (
      <div
        className={classNames(
          css['Comment-reply-replyBlock'],
          state.isReply && css['Comment-reply-replyBlock-isReply']
        )}
      >
        <div className={css['Comment-reply-replyBlock-content']}>
          <div className={css['Comment-reply-replyBlock-content-header']}>
            <Link to={`/collegues/${userId}`}>{`${userFirstName} ${userLastName}`}</Link>
          </div>
          <div className={css['Comment-reply-replyBlock-content-date']}>
            {createdDateText}
            {rate && (
              <div className={classNames(css['Comment-rate'], css['Comment-reply-replyBlock-content-date-rate'])}>
                <Rate count={5} value={rate} disabled noChange />
              </div>
            )}
          </div>

          <div className={css['Comment-reply-replyBlock-content-value']}>
            {isDeletedComment && !isReply ? t('deletedComment') : text}
            {modified && <span className={css['Comment-text-modified']}>{t('modif')}</span>}
          </div>
        </div>
      </div>
    );
  };

  const editComment = () => {
    props.editCommentStart(props.comment.id);
    setState({ ...state, isEdit: true });
    props.onHideActions(false);
  };

  const onChangeTextReply = e => setState({ ...state, textReply: e?.target?.value });

  const renderActions = (onChange, defaultValue, clickApprove, disabledApprove, textApprove, clickCancel) => {
    return (
      <div className={css['MaterialsTabsAbout-comment']}>
        <div className={css['MaterialsTabsAbout-comment-block']}>
          <TextArea
            className={css['MaterialsTabsAbout-form-textarea']}
            autoSize={{ minRows: 4 }}
            onChange={onChange}
            defaultValue={defaultValue}
            maxLength={1500}
          />
          <div className={css['Comment-buttonBlock']}>
            <Button
              className={classNames(css['MaterialsTabsAbout-comment-block-button'])}
              onClick={clickApprove}
              disabled={disabledApprove}
              type='primary'
              size='large'
            >
              {textApprove}
            </Button>
            <div className={css['Comment-buttonBlock-cancelButton']} onClick={clickCancel}>
              {t('cancel')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div key={props.comment.id} className={css.Comment}>
      <Link to={`/collegues/${props.comment.user.id}`}>
        <Avatar
          className={classNames(css['Comment-avatar'], {
            [materialItemCss.DeleteUser]: DELETED_USER,
          })}
          src={currentAvatar}
        />
        {DELETED_USER && (
          <Tooltip title={t('userDeleted')}>
            <div className={classNames(materialItemCss['DeleteUser-key'], materialItemCss['DeleteUser-key-fix3'])}>
              <img key='deleteUser' src={lock} alt='' />
            </div>
          </Tooltip>
        )}
      </Link>

      <div className={css['Comment-reply']}>
        <Row justify='space-between'>
          <Col xs={18} sm={20} xl={21}>
            {!state.isReply && (
              <>
                <div className={css['Comment-block-header']}>
                  <Link to={`/collegues/${props.comment.user.id}`}>
                    {`${props.comment.user.firstName} ${props.comment.user.lastName}`}
                  </Link>
                </div>
                <div className={css['Comment-nameBlock']}>
                  <div className={css['Comment-nameBlock-date']}>
                    {dayjs(props.comment.createdDate).format('DD MMMM YYYY')}
                  </div>
                  {props.comment.rate && !props.comment.commentDto && (
                    <div className={css['Comment-rate']}>
                      <Rate count={5} value={props.comment.rate} disabled noChange />
                    </div>
                  )}
                </div>
              </>
            )}

            {props.comment?.commentDto?.id &&
              !state.isReply &&
              renderReplyBlock(
                props.comment?.commentDto?.user.id,
                props.comment?.commentDto?.user?.firstName,
                props.comment?.commentDto?.user?.lastName,
                props.comment?.commentDto?.createdDate,
                props.comment?.commentDto?.text,
                props.comment?.commentDto?.modified,
                props.comment?.commentDto?.rate
              )}

            {!state.deletable && !state.isEdit && !state.isReply && (
              <div>
                {state.textComment}
                {props.comment.modified && <span className={css['Comment-text-modified']}>{t('modif')}</span>}
              </div>
            )}

            {state.isReply &&
              renderReplyBlock(
                props.comment?.user.id,
                props.comment?.user?.firstName,
                props.comment?.user?.lastName,
                props.comment?.createdDate,
                props.comment?.text,
                props.comment?.modified,
                props.comment?.rate,
                state.isReply
              )}

            {/* Редактирование комментария*/}
            {state.isEdit &&
              !state.isReply &&
              renderActions(
                changeComment,
                props.comment.text,
                updateComment,
                !state.textComment?.trim() || state.btnClick,
                t('save'),
                cancelChange
              )}

            {state.deletable && (
              <div className={css['Comment-deleteBlock']}>
                {t('commentWasDeleted')}
                <div className={css['Comment-deleteBlock-restore']} onClick={restoreComment}>
                  {t('restore')}
                </div>
              </div>
            )}

            {/* Отправка отзыва*/}
            {state.isReply &&
              renderActions(
                onChangeTextReply,
                undefined,
                sendReply,
                !state.textReply.trim() || state.btnClick,
                t('replyComment'),
                cancelReply
              )}
          </Col>
          {props.hideActions && (
            <Col xs={6} sm={4} xl={3}>
              <div className={css['Comment-actions']}>
                {!state?.deletable &&
                  props.topicId &&
                  ((props.review &&
                    props.currentUser?.authorities?.includes(TOPIC_COMMENT_REPLY) &&
                    props.currentUser.roleId !== 4 &&
                    props.currentUser.parentRoleId !== 4) ||
                    !props.review) && (
                    <Tooltip title={t('replyComment')}>
                      <HiOutlineReply className={css['Comment-actions-reply']} onClick={replyComment} />
                    </Tooltip>
                  )}

                {!(props.currentUserId !== props.comment.user.id || state.deletable || props.editableComment) && (
                  <div className={css['Comment-actions-hide']}>
                    <Tooltip title={t('editComment')}>
                      <HiPencil className={css['Comment-actions-hide-edit']} onClick={editComment} />
                    </Tooltip>
                    {(!props.review || (!props.comment.rate && props.comment.commentDto)) && (
                      <Tooltip title={t('deleteComment')}>
                        <HiX className={css['Comment-actions-hide-delete']} onClick={deleteComment} />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default withTranslation('comment')(Comment);
