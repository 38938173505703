/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { GET, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const EVENT_REQUEST_CONFIRM_START = 'EVENT_REQUEST_CONFIRM_START';
export const EVENT_REQUEST_CONFIRM_SUCCESS = 'EVENT_REQUEST_CONFIRM_SUCCESS';
export const EVENT_REQUEST_CONFIRM_FAIL = 'EVENT_REQUEST_CONFIRM_FAIL';
export const EVENT_REQUEST_REFUSE_START = 'EVENT_REQUEST_REFUSE_START';
export const EVENT_REQUEST_REFUSE_SUCCESS = 'EVENT_REQUEST_REFUSE_SUCCESS';
export const EVENT_REQUEST_REFUSE_FAIL = 'EVENT_REQUEST_REFUSE_FAIL';
export const EVENT_REQUEST_CONFIRM_UI_START = 'EVENT_REQUEST_CONFIRM_UI_START';
export const EVENT_REQUEST_CONFIRM_UI_SUCCESS = 'EVENT_REQUEST_CONFIRM_UI_SUCCESS';
export const EVENT_REQUEST_CONFIRM_UI_FAIL = 'EVENT_REQUEST_CONFIRM_UI_FAIL';
export const EVENT_REQUEST_REFUSE_UI_START = 'EVENT_REQUEST_REFUSE_UI_START';
export const EVENT_REQUEST_REFUSE_UI_SUCCESS = 'EVENT_REQUEST_REFUSE_UI_SUCCESS';
export const EVENT_REQUEST_REFUSE_UI_FAIL = 'EVENT_REQUEST_REFUSE_UI_FAIL';
export const EVENT_REQUEST_FINISH_START = 'EVENT_REQUEST_FINISH_START';
export const EVENT_REQUEST_FINISH_SUCCESS = 'EVENT_REQUEST_FINISH_SUCCESS';
export const EVENT_REQUEST_FINISH_FAIL = 'EVENT_REQUEST_FINISH_FAIL';
export const EVENT_TOPIC_INFO_START = 'EVENT_TOPIC_INFO_START';
export const EVENT_TOPIC_INFO_SUCCESS = 'EVENT_TOPIC_INFO_SUCCESS';
export const EVENT_TOPIC_INFO_FAIL = 'EVENT_TOPIC_INFO_FAIL';
export const GET_COURSES_COST_START = 'GET_COURSES_COST_START';
export const GET_COURSES_COST_SUCCESS = 'GET_COURSES_COST_SUCCESS';
export const GET_COURSES_COST_FAIL = 'GET_COURSES_COST_FAIL';

export const GET_EVENT_IN_COURSE_START = 'GET_EVENT_IN_COURSE_START';
export const GET_EVENT_IN_COURSE_SUCCESS = 'GET_EVENT_IN_COURSE_SUCCESS';
export const GET_EVENT_IN_COURSE_FAIL = 'GET_EVENT_IN_COURSE_FAIL';

export const GET_ACTIVE_EVENT_IN_COURSE_START = 'GET_ACTIVE_EVENT_IN_COURSE_START';
export const GET_ACTIVE_EVENT_IN_COURSE_SUCCESS = 'GET_ACTIVE_EVENT_IN_COURSE_SUCCESS';
export const GET_ACTIVE_EVENT_IN_COURSE_FAIL = 'GET_ACTIVE_EVENT_IN_COURSE_FAIL';

export const GET_ENDED_EVENT_IN_COURSE_START = 'GET_ENDED_EVENT_IN_COURSE_START';
export const GET_ENDED_EVENT_IN_COURSE_SUCCESS = 'GET_ENDED_EVENT_IN_COURSE_SUCCESS';
export const GET_ENDED_EVENT_IN_COURSE_FAIL = 'GET_ENDED_EVENT_IN_COURSE_FAIL';

export const GET_EVENTS_IN_GROUPS_COURSE_START = 'GET_EVENTS_IN_GROUPS_COURSE_START';
export const GET_EVENTS_IN_GROUPS_COURSE_SUCCESS = 'GET_EVENTS_IN_GROUPS_COURSE_SUCCESS';
export const GET_EVENTS_IN_GROUPS_COURSE_FAIL = 'GET_EVENTS_IN_GROUPS_COURSE_FAIL';

export const GET_OPEN_DATE_START = 'GET_OPEN_DATE_START';
export const GET_OPEN_DATE_SUCCESS = 'GET_OPEN_DATE_SUCCESS';
export const GET_OPEN_DATE_FAIL = 'GET_OPEN_DATE_FAIL';

export const REJECT_MANAGER_COURSES_START = 'REJECT_MANAGER_COURSES_START';
export const REJECT_MANAGER_COURSES_SUCCESS = 'REJECT_MANAGER_COURSES_SUCCESS';
export const REJECT_MANAGER_COURSES_FAIL = 'REJECT_MANAGER_COURSES_FAIL';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export const eventRequestConfirm = (code, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/event/request/confirm/code/${code}`,
    headers: {
      ...headers,
    },
    body: {},
    types: [EVENT_REQUEST_CONFIRM_START, EVENT_REQUEST_CONFIRM_SUCCESS, EVENT_REQUEST_CONFIRM_FAIL],
    onSuccess,
    onError,
  };
};

export const eventRequestRefuse = (code, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/event/request/refuse/code/${code}`,
    headers: {
      ...headers,
    },
    body: {},
    types: [EVENT_REQUEST_REFUSE_START, EVENT_REQUEST_REFUSE_SUCCESS, EVENT_REQUEST_REFUSE_FAIL],
    onSuccess,
    onError,
  };
};

export const eventRequestConfirmUI = (id, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/event/request/confirm/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    body: {},
    types: [EVENT_REQUEST_CONFIRM_UI_START, EVENT_REQUEST_CONFIRM_UI_SUCCESS, EVENT_REQUEST_CONFIRM_UI_FAIL],
    onSuccess,
  };
};

export const eventRequestRefuseUI = (id, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/event/request/refuse/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    body: {},
    types: [EVENT_REQUEST_REFUSE_UI_START, EVENT_REQUEST_REFUSE_UI_SUCCESS, EVENT_REQUEST_REFUSE_UI_FAIL],
    onSuccess,
  };
};

export const eventTopicInfo = (eventId, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/event/schedule${getQueryString({ eventId })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [EVENT_TOPIC_INFO_START, EVENT_TOPIC_INFO_SUCCESS, EVENT_TOPIC_INFO_FAIL],
    onSuccess,
    onError,
  };
};

export const getApprovedCoursesCost = (year, onSuccess, onError) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/cost/${year}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_COURSES_COST_START, GET_COURSES_COST_SUCCESS, GET_COURSES_COST_FAIL],
    onSuccess,
    onError,
  };
};

export const eventRequestFinish = (id, status, onSuccess, onError) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/courses/request/finish${getQueryString({
      id,
      complete: status,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    body: {},
    types: [EVENT_REQUEST_FINISH_START, EVENT_REQUEST_FINISH_SUCCESS, EVENT_REQUEST_FINISH_FAIL],
    onSuccess,
    onError,
  };
};
export const eventManagerCourses = (code, comment, onSuccess, onError) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/courses/request/update/${code}`,
  headers: { ...headers },
  body: {
    comment,
  },
  onSuccess,
  onError,
  types: [REJECT_MANAGER_COURSES_START, REJECT_MANAGER_COURSES_SUCCESS, REJECT_MANAGER_COURSES_FAIL],
});

export const getEventsInCourse = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/topic/event${getQueryString({
      ...params,
      sort: 'started,desc&sort=near,asc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: params.isEnded
      ? [GET_ENDED_EVENT_IN_COURSE_START, GET_ENDED_EVENT_IN_COURSE_SUCCESS, GET_ENDED_EVENT_IN_COURSE_FAIL]
      : [GET_EVENT_IN_COURSE_START, GET_EVENT_IN_COURSE_SUCCESS, GET_EVENT_IN_COURSE_FAIL],
    onSuccess,
  };
};

export const getActiveEventsInCourse = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/topic/event${getQueryString({
      ...params,
      sort: 'endTime,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_ACTIVE_EVENT_IN_COURSE_START, GET_ACTIVE_EVENT_IN_COURSE_SUCCESS, GET_ACTIVE_EVENT_IN_COURSE_FAIL],
    onSuccess,
  };
};

export const getEventsGrours = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/topic/event/${params.topicId}${getQueryString({
      sort: 'endTime,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_EVENTS_IN_GROUPS_COURSE_START, GET_EVENTS_IN_GROUPS_COURSE_SUCCESS, GET_EVENTS_IN_GROUPS_COURSE_FAIL],
    onSuccess,
  };
};

export const getOpenDate = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/courses/param`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken'),
    },
    types: [GET_OPEN_DATE_START, GET_OPEN_DATE_SUCCESS, GET_OPEN_DATE_FAIL],
    onSuccess,
  };
};
