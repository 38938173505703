import React from 'react';

import { Plan } from '../plan';

import { PlanStatus } from '@shared/constants/plan';

import PropTypes from 'prop-types';

export const StudyPlan = ({ isAnother, showCalendarModal, currentUserId, currentUser }) => {
  const name = 'studyPlan';

  const params = {
    planStatus: [
      PlanStatus.SCHEDULED,
      PlanStatus.PLANNED,
      PlanStatus.WAITING,
      PlanStatus.APPROVED,
      PlanStatus.APPROVED_BY_MANAGER,
      PlanStatus.REJECTED,
      PlanStatus.REJECTED_BY_MANAGER,
      PlanStatus.WAITING_BY_USER,
      PlanStatus.APPROVED_BY_USER,
      PlanStatus.ON_REGISTRATION,
      PlanStatus.REJECTED_BY_USER,
      PlanStatus.IN_PROGRESS,
      PlanStatus.SKIPPED_BY_USER,
      PlanStatus.EXPIRED,
    ].toString(),
  };

  return (
    <Plan
      isAnother={isAnother}
      showCalendarModal={showCalendarModal}
      planName={name}
      planParams={params}
      userId={currentUserId}
      currentUser={currentUser}
    />
  );
};

StudyPlan.propTypes = {
  isAnother: PropTypes.bool,
  showCalendarModal: PropTypes.any,
  currentUserId: PropTypes.number,
  currentUser: PropTypes.object,
};
