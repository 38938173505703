/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createTheme, deleteTheme, getThemes, updateTheme } from '@/store/compilations/actions';

import { selectCompilations as compilationsList } from '@/store/compilations/selectors';
import { selectSearch as searchList } from '@/store/search/selectors';
import { selectUsers as usersList } from '@/store/users/selectors';

import { Tree } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import TreeNodeTitle from './TreeNodeTitle';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

import css from './ThemesEditor.module.scss';

class ThemesEditor extends Component {
  static propTypes = {
    clearSearchParams: PropTypes.func,
    createTheme: PropTypes.func,
    currentUser: PropTypes.object,
    deleteTheme: PropTypes.func,
    getThemes: PropTypes.func,
    isCheckable: PropTypes.bool,
    isFavorite: PropTypes.bool,
    onlyView: PropTypes.bool,
    onThemeChange: PropTypes.func,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    themeId: PropTypes.array,
    themes: PropTypes.array,
    themesUsed: PropTypes.array,
    updateCheckableElems: PropTypes.func,
    updateTheme: PropTypes.func,
    dataqa: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const selectedThemeIds = props.themeId || [];
    this.state = {
      isVisible: false,
      isAddTheme: false,
      newThemeName: '',
      isEdit: false,
      errors: {},
      selectedThemeIds,
      expandedThemes: this.getExpandedThemes(props.themes, selectedThemeIds),
    };
  }

  componentDidMount() {
    this.props.getThemes(() => {}, this.props.isFavorite ? this.props.currentUser.id : '');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(this.props.themeId.sort()) !== JSON.stringify(prevProps.themeId.sort())) {
      this.setState({
        selectedThemeIds: this.props.themeId,
        expandedThemes: _uniq([
          ...this.getExpandedThemes(this.props.themes, this.props.themeId),
          ...this.state.expandedThemes,
        ]),
      });
    }
  }

  updateTheme = (name, item) => {
    return this.props.updateTheme({
      ...item,
      name,
    });
  };

  toggleThemeForm = (parentId, childs = '') => {
    if (!parentId) {
      this.setState({
        isAddTheme: !this.state.isAddTheme,
        [`${parentId}-new`]: '',
        ...(!this.state.isAddTheme && {
          newThemeName: '',
        }),
      });
    } else {
      this.setState({
        [`${parentId}-new`]: '',
        [`isForm${childs}-${parentId}`]: !this.state[`isForm${childs}-${parentId}`],
      });
    }
  };

  createTheme = (nameStr, parentId) => {
    const name = nameStr.trim();
    return this.props.createTheme({ parentId, name }, () => {
      this.props.getThemes(() => {}, this.props.isFavorite ? this.props.currentUser.id : '');
      if (parentId) {
        this.toggleThemeForm(parentId);
      } else {
        this.toggleThemeForm();
      }
    });
  };

  delTheme = id =>
    this.props.deleteTheme(
      id,
      this.props.getThemes(() => {}, this.props.isFavorite ? this.props.currentUser.id : '')
    );

  onChange = (checkableElems, { checkedNodes }) => {
    const checkbleList = checkedNodes
      ? checkedNodes.map(item => ({
          id: item.props.dataRef.id,
          name: item.props.dataRef.name,
        }))
      : [];
    return this.props.updateCheckableElems(checkbleList);
  };

  setThemeName = (e, parentId, childs = '') => {
    const { value } = e.target;
    if (value) {
      const newString = !value.trim().match('[!@#$%^&*(),.?":{}|\\/<>]{2,}');
      if (value.trim().length > 50) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('limit', {
              limit: '50',
            }),
          },
        });
      }
      if (value.trim().length < 2) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('limit', {
              limit: '2',
            }),
          },
        });
      }
      if (!newString) {
        return this.setState({
          [parentId ? `${parentId}-new` : 'newThemeName']: value,
          errors: {
            ...this.state.errors,
            [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: this.props.t('error'),
          },
        });
      }
    }
    return this.setState({
      [parentId ? `${parentId}-new` : 'newThemeName']: value,
      errors: {
        ...this.state.errors,
        [parentId ? `isForm${childs}-${parentId}` : 'newThemeName']: null,
      },
    });
  };

  renderForm = (parentId, childs) => (
    <div>
      {!this.state[`isForm${childs}-${parentId}`] && (
        <a href='#' className={css.TreeNode__btns} onClick={() => this.toggleThemeForm(parentId, childs)}>
          {this.props.t('add')}
        </a>
      )}
      {this.state[`isForm${childs}-${parentId}`] && (
        <div className={css.TreeNode__actions__box}>
          <div className={css.TreeNode__btns__box}>
            <a
              onClick={() => this.createTheme(this.state[`${parentId}-new`], parentId)}
              className={css.TreeNode__btns}
              disabled={this.state.errors[`isForm${childs}-${parentId}`]}
            >
              <CheckOutlined />
              {this.props.t('create')}
            </a>
            <a
              onClick={() => this.toggleThemeForm(parentId, childs)}
              className={classNames(css.TreeNode__btns, css.TreeNode__btns__cancel)}
            >
              {this.props.t('cancel')}
            </a>
          </div>
        </div>
      )}
    </div>
  );

  renderTreeNodes = (data, withAdd = false, selectedThemeIds) => {
    return data.map(item => {
      const title = (
        <TreeNodeTitle
          dataqa={this.props.dataqa}
          onlyView={this.props.onlyView}
          isCheckable={this.props.isCheckable}
          isSelected={selectedThemeIds.includes(item.id)}
          name={item.name}
          id={item.id}
          error={this.state.errors[`isForm-${item.id}`]}
          onChange={e => this.setThemeName(e, item.id)}
          delTheme={() => this.delTheme(item.id)}
          updateTheme={name => this.updateTheme(name, item)}
        />
      );

      const node = {
        title: title,
        key: item.id,
        isLeaf: item.childs.length === 0,
        className: css.TreeNode,
        ...item,
      };

      if (item.childs && item.childs.length > 0) {
        node.children = this.renderTreeNodes(item.childs, false, selectedThemeIds);
      }

      if (withAdd && !this.props.onlyView) {
        if (!node.children) node.children = [];
        node.children.push({
          title: this.renderForm(item.id, 'childs'),
          key: `${item.id}-add`,
          className: css.TreeNode,
          disableCheckbox: true,
        });
      }

      return node;
    });
  };

  getThemeForm = () => {
    return this.state.isAddTheme ? (
      <div className={css.TreeNode__actions__box}>
        <div>
          <a
            onClick={() => this.createTheme(this.state.newThemeName)}
            className={css.TreeNode__btns}
            disabled={this.state.errors.newThemeName}
          >
            <CheckOutlined />
            {this.props.t('create')}
          </a>
          <a
            onClick={() => this.toggleThemeForm()}
            className={classNames(css.TreeNode__btns, css.TreeNode__btns__cancel)}
          >
            {this.props.t('cancel')}
          </a>
        </div>
      </div>
    ) : null;
  };

  getExpandedThemes = (themes, selectedThemeIds) => {
    return themes.reduce((result, theme) => {
      if (theme.childs.some(childTheme => selectedThemeIds.includes(childTheme.id))) {
        result.push(`${theme.id}`);
      }
      return result;
    }, []);
  };

  setExpandedThemes = expandedThemes => {
    this.setState({ expandedThemes });
  };

  setSelectedThemes = themeIds => {
    const { onThemeChange } = this.props;

    this.setState({
      selectedThemeIds: themeIds[0] !== 0 || !themeIds.length ? themeIds : this.state.selectedThemeIds,
    });

    if (themeIds[0] !== 0 || !themeIds.length) {
      this.props.setSearchParams({
        ...this.props.searchParams,
        themeId: themeIds,
        page: 0,
      });
    }

    if (onThemeChange) {
      setTimeout(() => {
        onThemeChange();
      }, 0);
    }
  };

  render() {
    const { selectedThemeIds, expandedThemes } = this.state;
    const { themesUsed, isCheckable } = this.props;
    const themes = this.props.themes.sort((a, b) => a.position - b.position);

    const isTreeNotEmpty = themes.length;
    const themeForm = this.getThemeForm();

    const treeData = this.renderTreeNodes(themes, true, selectedThemeIds);

    return (
      <div className={classNames({ [css.onlyView]: this.props.onlyView, [css.favorite]: this.props.isFavorite })}>
        {/* Закомментировано по задаче KAM-4630 */}
        {/* {this.props.onlyView && isTreeNotEmpty ? (
          <a
            className={classNames(css.seeAll, {
              [css.active]: !selectedThemeIds.length,
            })}
            onClick={() => this.setSelectedThemes([])}
          >
            {this.props.t('all')}
          </a>
        ) : null} */}
        <Tree
          key={selectedThemeIds}
          className={css['Tree']}
          checkStrictly
          checkable={isCheckable}
          defaultCheckedKeys={themesUsed && themesUsed.map(theme => `${theme.id}`)}
          expandedKeys={expandedThemes}
          onExpand={this.setExpandedThemes}
          onCheck={this.onChange}
          {...(this.props.onlyView && {
            onSelect: e => this.setSelectedThemes([+e]),
          })}
          treeData={treeData}
        />
        {!this.props.onlyView && themeForm}
        {!this.state.isAddTheme && !this.props.onlyView && (
          <a className={css.TreeNode__btns} onClick={() => this.toggleThemeForm()}>
            {this.props.t('addTheme')}
          </a>
        )}
      </div>
    );
  }
}

ThemesEditor.defaultProps = {
  isCheckable: false,
};

const mapStateToProps = createSelector(compilationsList, searchList, usersList, (compilations, search, users) => ({
  currentUser: users.currentUser,
  themes: compilations.themes || [],
  themeId: (search.searchParams && search.searchParams.themeId) || [],
  theme: compilations.theme,
}));

const mapActionsToProps = {
  getThemes,
  deleteTheme,
  createTheme,
  updateTheme,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('themeEdit')(ThemesEditor));
