export const TopicType = {
  Course: 'Course',
  Study: 'Study',
};

export const CourseFilters = {
  Electron: 'Electron',
  External: 'External',
  Corporate: 'Corporate',
};

// hardcoded on backend
export const FilterServerId = {
  Electron: 1,
  External: 20,
  Corporate: 17,
  Books: 16,
  Video: 4,
  Article: 15,
};

export const StudyFilters = {
  Books: 'Books',
  Video: 'Video',
  Article: 'Article',
};

export function getName(obj, key) {
  return obj[key];
}
