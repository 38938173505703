import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setIsFavourite } from '@/store/poka/actions';
import { createUserTopic, deleteUserTopic } from '@/store/user-topics/actions';

import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Divider, Row, Tag, Tooltip } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import IconPokaStatus from '@shared/components/IconPokaStatus';
import PieMars from '@shared/components/PieMars';
import { TAG_POKA_STATUS } from '@shared/components/PieMars/constants';

import Utils from '@/Utils';
import classNames from 'classnames';

import css from '../PokaSkills.module.scss';

const PokaItem = ({ data }) => {
  const { t } = useTranslation('pokaSkills');
  const dispatch = useDispatch();

  const { topicName, topicId, categories, endorsementLevel, pokaPlanStatus, expiryDate, isFavorite, studyPlanStatus } =
    data;

  const { currentUser } = useSelector(selectUsers);

  const renderCatSkills = () => {
    const renderText =
      Array.isArray(categories) && categories.length > 0 && categories.map(cat => cat.label).join(', ');

    if (renderText.length > 65) {
      return <Tooltip title={renderText}>{Utils.trString(renderText, 65)}</Tooltip>;
    } else {
      return renderText;
    }
  };

  const renderTopicName = () => {
    if (topicName?.length > 65) {
      return <Tooltip title={topicName}>{Utils.trString(topicName, 55)}</Tooltip>;
    } else {
      return topicName;
    }
  };

  const renderTags = () => {
    return (
      <div className={classNames(css['PokaItem-item'], !pokaPlanStatus && css['PokaItem-item-noStatus'])}>
        {pokaPlanStatus && (
          <Tag color={TAG_POKA_STATUS[pokaPlanStatus?.value]} className={css['PokaItem-item-tag']}>
            <IconPokaStatus studyPlanStatus={studyPlanStatus} />
            {pokaPlanStatus?.label}
          </Tag>
        )}
      </div>
    );
  };

  const addHeart = () => {
    dispatch(
      createUserTopic(currentUser.id, topicId, res => {
        if (res && res[0]) {
          const tpc = { topicId: res[0].userTopic.topic.id, isFavourite: res[0].userTopic.topic.isFavourite };
          dispatch(setIsFavourite(tpc));
        }
      })
    );
  };

  const deleteHeart = () => {
    dispatch(
      deleteUserTopic(topicId, true, true, res => {
        if (res) {
          const tpc = { topicId: res.topic.id, isFavourite: res.topic.isFavourite };
          dispatch(setIsFavourite(tpc));
        }
      })
    );
  };

  const renderDeadline = () => {
    return (
      <div className={css['PokaItem-item']}>
        <Tag className={css['PokaItem-item-tagDate']}>
          {t('dateTerm')}
          {expiryDate}
        </Tag>
        {isFavorite ? (
          <HeartFilled className={css['PokaItem-item-heart']} onClick={deleteHeart} />
        ) : (
          <HeartOutlined className={css['PokaItem-item-heart']} onClick={addHeart} />
        )}
      </div>
    );
  };

  return (
    <div className={css['PokaItem']}>
      <Row>
        {/* Левая часть */}
        <Col md={24} lg={18}>
          {/* Заголовок */}
          <Row>
            <Link to={`/collection-topics/${topicId}/study`}>
              <Col className={css['PokaItem-header']}>{renderTopicName()}</Col>
            </Link>
          </Row>

          {/* Категория навыка */}
          <Row className={css['PokaItem-block']}>
            <Col xs={24} sm={5} className={css['PokaItem-title']}>
              {t('categorySkills')}
            </Col>
            <Col xs={24} sm={19} className={css['PokaItem-valueCategory']}>
              {renderCatSkills()}
            </Col>
          </Row>

          {/* Оценка*/}
          <Row>
            <Col xs={24} sm={5} className={css['PokaItem-title']}>
              {t('assessment')}
            </Col>
            <Col xs={24} sm={19} className={css['PokaItem-value']}>
              <div className={css['PokaItem-value-img']}>
                <PieMars
                  value={endorsementLevel?.value}
                  isActive={endorsementLevel?.value}
                  read
                  pokaStatus={pokaPlanStatus?.value}
                />
                {endorsementLevel?.label}
              </div>
            </Col>
          </Row>

          {/* История валидаций */}
          <Row>
            <Col>
              <Button className={css['PokaItem-button']} type='link'>
                {t('historyValidation')}
              </Button>
            </Col>
          </Row>
        </Col>

        {/* Правая часть */}
        <Col md={24} lg={6}>
          <Row>
            <Col span={24}>{renderTags()}</Col>
            <Col span={24}>{renderDeadline()}</Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default PokaItem;
