// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-KDfpx_.user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-bXUKFs .user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-label-RLxGda[data-value="Montserrat"]:before, .user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-KDfpx_.user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-bXUKFs .user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-item-dVKcBo[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-Montserrat-FEk_kw {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-HorizontalScroll-HorizontalScroll-module__scroll-J6YkwI {
  display: flex;
}

.user-src-components-HorizontalScroll-HorizontalScroll-module__scroll-content-B8vemx {
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/HorizontalScroll/HorizontalScroll.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,aAAa;AACf;;AAOA;EAJE,kBAAkB;AACpB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.scroll {\n  display: flex;\n}\n.scroll-content {\n  overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-KDfpx_`,
	"qlPicker": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-KDfpx_`,
	"ql-font": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-bXUKFs`,
	"qlFont": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-bXUKFs`,
	"ql-picker-label": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-label-RLxGda`,
	"qlPickerLabel": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-label-RLxGda`,
	"ql-picker-item": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-item-dVKcBo`,
	"qlPickerItem": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-picker-item-dVKcBo`,
	"ql-font-Montserrat": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-Montserrat-FEk_kw`,
	"qlFontMontserrat": `user-src-components-HorizontalScroll-HorizontalScroll-module__ql-font-Montserrat-FEk_kw`,
	"scroll": `user-src-components-HorizontalScroll-HorizontalScroll-module__scroll-J6YkwI`,
	"scroll-content": `user-src-components-HorizontalScroll-HorizontalScroll-module__scroll-content-B8vemx`,
	"scrollContent": `user-src-components-HorizontalScroll-HorizontalScroll-module__scroll-content-B8vemx`
};
export default ___CSS_LOADER_EXPORT___;
