/* eslint-disable */
import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';

function formatDateDayjs(date, outputFormat, locales) {
  const lang = locales === 'ru_RU' ? 'ru' : locales === 'en_US' ? 'en' : 'ru';

  const value = dayjs(date).locale(lang);

  if (!value.isValid() || !date) {
    return null;
  }

  return outputFormat ? value.format(outputFormat) : value.toISOString();
}

export { formatDateDayjs };
