// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-WQiH1C.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-eNBj6Q .user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-label-VSNTmn[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-WQiH1C.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-eNBj6Q .user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-item-NuLlew[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-Montserrat-xlTz8s {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-wrapper-xDjD4e {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 48px;
  display: flex;
}

@media (max-width: 992px) {
  .user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-wrapper-xDjD4e {
    padding: 24px;
  }
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-header-UZsjFF {
  justify-content: space-between;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-typeTitle-IcdkE6 {
  text-transform: uppercase;
  color: #999;
  font-size: 10px;
  line-height: 12px;
}

@media (max-width: 992px) {
  .user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-typeTitle-IcdkE6 {
    font-size: 12px;
  }
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-title-xvl311 {
  color: #000;
  margin-top: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-text-uSyJSA {
  margin-top: 50px;
  font-size: 16px;
}

.user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-button-wrapper-eSgI6N {
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 40px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/typeform/index.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAMA;EACE;IAHE,aAAa;EACf;AACF;;AAKA;EAFE,8BAA8B;EAC9B,aAAa;AACf;;AAIA;EADE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAGA;EACE;IAAE,eAAe;EACjB;AACF;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AACA;EAEE,gBAAgB;EAChB,eAAe;AACjB;;AAAA;EAGE,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Typeform-wrapper {\n  display: flex;\n  flex-direction: column;\n  padding: 48px;\n  height: 100%;\n  width: 100%;\n}\n@media (max-width: 992px) {\n  .Typeform-wrapper {\n    padding: 24px;\n  }\n}\n.Typeform-header {\n  display: flex;\n  justify-content: space-between;\n}\n.Typeform-typeTitle {\n  text-transform: uppercase;\n  font-size: 10px;\n  line-height: 12px;\n  color: #999;\n}\n@media (max-width: 992px) {\n  .Typeform-typeTitle {\n    font-size: 12px;\n  }\n}\n.Typeform-title {\n  margin-top: 12px;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 24px;\n  color: #000;\n}\n.Typeform-text {\n  margin-top: 50px;\n  font-size: 16px;\n}\n.Typeform-button-wrapper {\n  display: flex;\n  justify-content: center;\n  margin-top: 200px;\n  margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-WQiH1C`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-WQiH1C`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-eNBj6Q`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-eNBj6Q`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-label-VSNTmn`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-label-VSNTmn`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-item-NuLlew`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-picker-item-NuLlew`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-Montserrat-xlTz8s`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__ql-font-Montserrat-xlTz8s`,
	"Typeform-wrapper": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-wrapper-xDjD4e`,
	"typeformWrapper": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-wrapper-xDjD4e`,
	"Typeform-header": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-header-UZsjFF`,
	"typeformHeader": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-header-UZsjFF`,
	"Typeform-typeTitle": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-typeTitle-IcdkE6`,
	"typeformTypeTitle": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-typeTitle-IcdkE6`,
	"Typeform-title": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-title-xvl311`,
	"typeformTitle": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-title-xvl311`,
	"Typeform-text": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-text-uSyJSA`,
	"typeformText": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-text-uSyJSA`,
	"Typeform-button-wrapper": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-button-wrapper-eSgI6N`,
	"typeformButtonWrapper": `user-src-pages-CollectionMaterialsIDStudy-typeform-index-module__Typeform-button-wrapper-eSgI6N`
};
export default ___CSS_LOADER_EXPORT___;
