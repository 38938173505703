/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Layout } from 'antd';

import PropTypes from 'prop-types';

import css from './Footer.module.scss';

const { Footer } = Layout;

export class AppFooter extends Component {
  static propTypes = {
    domainCompany: PropTypes.object,
    footerLinks: PropTypes.array,
  };

  render() {
    return (
      <Footer id='Footer' className={css['App-footer']}>
        <div className={css['App-footer-menu']}>
          <LinksList links={this.props.footerLinks} />
        </div>
      </Footer>
    );
  }
}

const LinksList = ({ links }) =>
  links.map(({ url, id, name }) => {
    if (url[0] === '/')
      return (
        <Link key={id} to={url} className={css['App-footer-menu-item']}>
          {name}
        </Link>
      );

    return (
      <a key={id} href={url} className={css['App-footer-menu-item']}>
        {name}
      </a>
    );
  });

export default AppFooter;
