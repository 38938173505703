import React, { useState } from 'react';

import { Button as AntButton } from 'antd';
import { Button } from '@/components/elements';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const ButtonPlan = ({ children, onFavorite, isFavorite }) => {
  const [favorite, setFavorite] = useState(isFavorite);

  const _onFavorite = () => {
    setFavorite(!favorite);
    onFavorite && onFavorite(!favorite);
  };

  return (
    <AntButton.Group className={cn('button-plan')}>
      {children}
      <Button
        onClick={_onFavorite}
        shape='round'
        className={cn(
          'button-plan__favorite',
          { 'button-plan__favorite_only': !children },
          { 'button-plan__favorite_favorited': favorite }
        )}
      >
        {favorite ? <HeartFilled /> : <HeartOutlined />}
      </Button>
    </AntButton.Group>
  );
};

ButtonPlan.propTypes = {
  isFavorite: PropTypes.bool,
  onFavorite: PropTypes.func,
  children: PropTypes.element,
};
