import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { freeBook, getAddressInUse, getBookAddresses, updateBookAddresses } from '@/store/books/actions';
import { getChapters } from '@/store/chapters/actions';
import { eventRequestFinish } from '@/store/course/actions';
import { setExecutiveTask, updateExecutiveTask } from '@/store/executive/actions';
import { getUserIncome2 as getIncome } from '@/store/income/actions';
import { getBlockStatistic } from '@/store/lms/actions';
import { getPlan } from '@/store/plan/actions';
import { getPokaAssessor } from '@/store/poka/actions';
import {
  checkStudyPlan,
  createStudyPlan,
  sendNotificationStudyPlanFinished,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import { getTopicById } from '@/store/topics/actions';

import { selectChapters } from '@/store/chapters/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectLMS } from '@/store/lms/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Col, Layout, message, Row, Spin, Tabs } from 'antd';
import CalendarModal from '@/pages/CalendarModal';
import AddressBook from '@/components/AddressBook/AddressBook';
import ExecutiveFinishedScreen from '@/components/ExecutiveFinishedScreen/ExecutiveFinishedScreen';
import ExecutiveTaskData from '@/components/ExecutiveTaskData/ExecutiveTaskData';
import CustomModal from '@/components/Modal/Modal';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal';
import ShowProgress from '@/components/ShowProgress/ShowProgress';

import SendReviewModal2 from '../SendReviewModal2';
import Blocks from './Blocks';
import ChaptersPreview from './ChaptersPreview/ChaptersPreview';
import PokaHeader from './PokaHeader';
import PokaInfoBlock from './PokaInfoBlock';
import Courses from './test/Courses';
import ModalWarningForTest from './test/ModalWarningForTest/ModalWarningForTest';
import TopicStudyInfo from './TopicStudyInfo';

import { PlanStatus } from '@shared/constants/plan';
import {
  isAnyCourseOrConference,
  isBook,
  isConference,
  isCorporateCourseWithoutApprove,
  isEvent,
  isEventOrConference,
  isExecutiveTask,
  isExternalCourse,
  isSkills,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { openDB } from 'idb';
import _get from 'lodash/get';
import _has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import css from './CollectionMaterialsIDStudy.module.scss';

import { FINISHED_TOPIC } from '@/constants/income-types';
import { CHAPTERS_IDB, IDB_NAME } from '@/constants/IndexedDB';
import { FINISHED, WAITING, WAITING_BY_USER } from '@/constants/study-plan-statuses';

const { Content } = Layout;

const CollectionMaterialsStudy = props => {
  const {
    topic,
    statistic,
    isLoading,
    currentUser,
    getBlockStatistic,
    getChapters,
    getTopicById,
    chapters,
    createStudyPlan,
    checkStudyPlan,
  } = props;
  const { t } = useTranslation('collectionMaterialsStudy');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentTest, setCurrentTest] = useState(null);
  const [firstActiveTest, setFirstActiveTest] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [topicPlanStatus, setTopicPlanStatus] = useState(null);
  const [endMaterialVisibleModal, setEndMaterialVisibleModal] = useState(false);

  const [topicExecutiveId, setTopicExecutiveId] = useState(null);
  const [isExecutiveFinished, setIsExecutiveFinished] = useState(false);
  const [executiveReview, setExecutiveReview] = useState(null);
  const [executiveFiles, setExecutiveFiles] = useState([]);
  const [alreadyFinished, setAlreadyFinished] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [activeExecutiveTab, setActiveExecutiveTab] = useState('1');

  const [visibleModalWarning, setVisibleModalWarning] = useState(false);

  const [showConfirmBook, setShowConfirmBook] = useState(false);
  const [bookAddress, setBookAddress] = useState('');

  const [blockCount, setBlockCount] = useState(0);

  const [bookData, setBookData] = useState({
    addressId: 0,
    addresses: [],
    isAddressBookVisible: false,
    flagNow: false,
  });

  const [showParticipationConfirmationModal, setShowParticipationConfirmationModal] = useState(false);

  const topicId = props.params.id;

  const {
    studyPlan,
    studyPlanId,
    loading: { studyPlan: loading },
  } = useSelector(studyPlanSelect);

  const topicTypeId = _get(topic, 'type.id');

  const disabledSelect = topicTypeId === 8;
  const isTopicType25 = topicTypeId === 25;
  const isCorpCourse = topicTypeId === 17;

  const changeBlockHandler = data => {
    const { question, details } = props;

    if (
      data.blockId !== currentTest.blockId &&
      (question?.time === null ||
        question?.time === '00:00:00' ||
        (question?.time !== '00:00:00' && currentTest.isAvailableForStart) ||
        details ||
        !question)
    ) {
      if ((topic && !_has(topic, 'followSequence')) || (topic && topic.followSequence === null)) {
        topic.followSequence = false;
      }

      if (
        (topic && !topic.followSequence) ||
        (topic.followSequence && data.blockId === firstActiveTest) ||
        data.status === FINISHED
      ) {
        setCurrentTest(data);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (topic.followSequence) {
        NotificationManager.error(t('followSequence'));
      }
    } else if (
      (question?.time !== null || question?.time !== '00:00:00') &&
      !currentTest.isAvailableForStart &&
      currentTest.status !== 'FINISHED' &&
      data.blockId !== currentTest.blockId
    ) {
      setVisibleModalWarning(true);
    }
  };

  const isFinished = () => {
    if (topic?.planStatus) {
      return Boolean(topic.planStatus.planStatus === FINISHED || topic.planStatus.planStatus === WAITING);
    }

    return false;
  };

  const getStatisticStart = () => {
    checkStudyPlan({
      userId: currentUser?.id,
      topicId,
      isCurrent: true,
      withTrackInfo: true,
      onSuccess: () => {},
    });
  };

  const getStatistic = (current = false, chaptersResult = chapters) => {
    const blockId = currentTest?.blockId;

    setCurrentTest(null);

    checkStudyPlan({
      userId: currentUser?.id,
      topicId,
      isCurrent: true,
      withTrackInfo: true,
      onSuccess: res => {
        const resultPlanId = res?.[0]?.id;

        getBlockStatistic({
          topicId,
          userId: currentUser.id,
          planId: null || resultPlanId,
          onSuccess: response => {
            if (current && !topic.followSequence) {
              const active = response.blocksStat.find(item => item.blockId === blockId);
              setCurrentTest(active);
            } else {
              const blocksSortedByChapters = chaptersResult.map(chapter =>
                chapter.blocks.map(block => ({
                  ...block,
                  ...response.blocksStat.find(statistic => statistic.blockId === block.id),
                }))
              );

              const flatBlocks = blocksSortedByChapters.flat();
              const active = flatBlocks.find(item => item.status !== FINISHED);
              const blockToSet = active ? active : response.blocksStat?.[0];

              setCurrentTest(blockToSet);

              if (response.countBlocks) {
                setBlockCount(response.countBlocks);
              }

              if (Array.isArray(response.blocksStat) && response.blocksStat.length) {
                setFirstActiveTest(active ? active.id : response.blocksStat[0].id);
              }
            }
          },
        });
      },
    });
  };

  useEffect(() => {
    if (isCorporateCourseWithoutApprove(topic?.type?.id) || isEmpty(studyPlanId)) {
      checkStudyPlan({
        userId: currentUser?.id,
        topicId,
        isCurrent: true,
        withTrackInfo: true,
      });
    }
  }, [topic]);

  useEffect(() => {
    if (!window.navigator.onLine) {
      const { planStatus } = topic;

      if (planStatus?.planStatus) {
        if (
          planStatus.planStatus === WAITING_BY_USER &&
          (isExternalCourse(topic?.type?.id) || isConference(topic?.type?.id))
        ) {
          setTopicPlanStatus(WAITING_BY_USER);
        } else {
          setTopicPlanStatus('inPlan');
        }
      }
      let blocksNumber = 0;
      for (let chapter of chapters) {
        blocksNumber += chapter.blocks.length;
      }
      setBlockCount(blocksNumber);
      setCurrentTest(statistic.blocksStat[0]);
    } else {
      loadData();
    }
  }, [topicId, currentUser]);

  useEffect(() => {
    if (isBook(topic?.type?.id) && topic.qiwiLibrary) {
      dispatch(
        getBookAddresses(
          topicId,
          data => {
            const addresses = data.map(item => ({
              id: item.id,
              address: item.addressDto.address,
              accessible: item.accessible,
            }));

            setBookData(prevBookData => ({ ...prevBookData, addresses }));
          },
          () => setBookData(prevBookData => ({ ...prevBookData, addresses: [] }))
        )
      );

      dispatch(
        getAddressInUse(
          topic.id,
          res => {
            if (res?.address) {
              setBookAddress(res.address);
            }
          },
          err => message.error(err)
        )
      );
    }
  }, [isBook, topic?.type?.id, topic?.qiwiLibrary]);

  const updateChaptersIDB = async () => {
    const chapter =
      currentTest &&
      chapters
        .map(chapter => {
          const blocks = chapter.blocks.filter(block => block.id === currentTest.id);
          return { ...chapter, blocks };
        })
        .find(chapter => chapter.blocks.length);
    const db = await openDB(IDB_NAME);
    const topicId = document.location.href.split('/').at(-2);
    let chaptersIDB = [];
    const testChaptersIDB = await db.get(CHAPTERS_IDB, +topicId);
    if (testChaptersIDB) {
      chaptersIDB = testChaptersIDB.value;
    }
    const currentChapter = chaptersIDB.find(item => item.position === chapter.position);
    if (currentChapter) {
      currentChapter.blocks.push(chapter.blocks[0]);
    } else {
      chaptersIDB.push(chapter);
    }
    await db.put(CHAPTERS_IDB, {
      value: chaptersIDB,
      id: +topicId,
    });
  };

  const removeChaptersFromIDB = async () => {
    const db = await openDB(IDB_NAME);
    const topicId = document.location.href.split('/').at(-2);
    const testChaptersIDB = await db.get(CHAPTERS_IDB, +topicId);
    if (!testChaptersIDB) return;
    const chaptersIDB = testChaptersIDB.value;
    await db.delete(CHAPTERS_IDB, +topicId);
    const newChapters = chaptersIDB
      .map(chapter => {
        const blocks = chapter.blocks.filter(chapterBlock => chapterBlock.id !== currentTest.id);
        return { ...chapter, blocks };
      })
      .filter(chapter => chapter.blocks.length);
    if (newChapters.length) {
      await db.put(CHAPTERS_IDB, {
        value: newChapters,
        id: +topicId,
      });
    }
  };

  const loadData = () => {
    getTopicById(topicId, topic => {
      const { planStatus } = topic;
      const topicTypeId = _get(topic, 'type.id');

      if (topicTypeId !== 25) {
        setIsExecutiveFinished(true);
      }

      const correctDate = isEventOrConference(topicTypeId)
        ? dayjs(`${topic.endDate + ' ' + topic.endTime}`, 'YYYY-MM-DD HH:mm').format()
        : '';

      const checkIsEnd =
        isEventOrConference(topicTypeId) &&
        planStatus &&
        !planStatus.planStatus &&
        dayjs(correctDate).isBefore(dayjs());

      const checkCoursePlan = topic.type && planStatus && !planStatus.planStatus;

      let type;

      if (planStatus && ((topic.status === t('deactivated') && checkCoursePlan) || checkIsEnd)) {
        type = 'unactive';
      } else if (planStatus?.planStatus) {
        type = 'inPlan';
      } else if (planStatus) {
        type = 'addToPlan';
      }

      if (
        planStatus?.planStatus === WAITING_BY_USER &&
        (isConference(topic?.type?.id) || isExternalCourse(topic?.type?.id))
      ) {
        type = WAITING_BY_USER;
      }

      setTopicPlanStatus(type);
    });

    getChapters(topicId, chaptersResult => {
      const chapters = chaptersResult
        .map(chapter => {
          const blocks = chapter.blocks.filter(block => !block.isDeleted);
          return { ...chapter, blocks };
        })
        .filter(data => data.active && data.blocks.length);
      getStatistic(false, _sortBy(chapters, ['position']));
    });
  };

  const onExecutiveTaskFinished = (finished, teid, review, files) => {
    setExecutiveReview(review);
    setTopicExecutiveId(teid);
    setExecutiveFiles(files);
    setIsExecutiveFinished(finished);
  };

  const next = useMemo(
    () => statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length,
    [statistic]
  );

  const handleStudyPlanClick = useCallback(
    (topicId, planningMonths = [], comment, withoutSchedule, webinarDateTime, planInfo, selectedId) => {
      for (let i = 0; i < planningMonths.length; i++) {
        createStudyPlan(
          currentUser.id,
          topicId,
          planningMonths[i],
          comment,
          () => {
            setShowCalendar(false);

            if (topic && topic?.targetAudience === false && isAnyCourseOrConference(topic?.type?.id)) {
              setShowParticipationConfirmationModal(true);
            }

            loadData();
          },
          undefined,
          undefined,
          undefined,
          selectedId
        );
      }
      if (bookData.addressId > 0) {
        dispatch(updateBookAddresses(bookData.addressId, currentUser.id));
        setBookData(prevBookData => ({ ...prevBookData, addressId: 0 }));
      }
    },
    [createStudyPlan, currentUser.id, bookData, topic]
  );

  const topicPlanStatusPlanStatus = topic?.planStatus?.planStatus;
  const isWaitingStatus = topicPlanStatusPlanStatus === WAITING;

  const topicInPlan = topicPlanStatus === 'inPlan';
  const modalParams = {
    okText: t('addToPlan'),
    headerFixToModal: true,
  };

  const topicInfo = {
    id: _get(topic, 'id'),
    independent: _get(topic, 'independent'),
    startDate: _get(topic, 'startDate'),
    topicCost: _get(topic, 'cost'),
    link: topic && (topic.firstLink || topic.link || (topic.links && topic.links[0] && topic.links[0].url)),
    eventCount: _get(topic, 'futureEvents'),
    topicComment: _get(topic, 'comment'),
  };

  const handleEndMaterialButtonOK = () => {
    if (isBook(topic?.type?.id) && topic.qiwiLibrary && bookAddress) {
      setShowConfirmBook(true);
      return;
    }

    endMaterial();
  };

  const handleEndMaterialButton = () => setEndMaterialVisibleModal(true);
  const handleHideSendReviewModal = () => setReviewModalVisible(false);

  const handleParticipationConfirmationModalCancel = () => {
    setShowParticipationConfirmationModal(false);
  };

  const endMaterial = (withOutValidation, pokaAssessor) => {
    const endDateFormat =
      topic?.endDate &&
      topic?.endTime &&
      dayjs(`${topic?.endDate + ' ' + topic?.endTime}`, 'YYYY-MM-DD HH:mm').format();
    const lastDayCoursePeriod = studyPlanId[0]?.event?.periods.slice(-1)[0];
    const checkExpiredPreiod = dayjs(dayjs()).isAfter(
      dayjs(`${lastDayCoursePeriod?.date + lastDayCoursePeriod?.endTime}`, 'YYYY-MM-DD HH:mm').format()
    );

    const checkEndTopic = isEvent(topic?.type.id)
      ? dayjs(endDateFormat).isBefore(dayjs())
      : isCorporateCourseWithoutApprove(topic?.type.id)
        ? checkExpiredPreiod
        : true;

    if (topic?.id && currentUser.id) {
      if (isBook(topic.type?.id) && topic.qiwiLibrary && bookAddress) {
        const body = { topicId: topic.id, userIds: [currentUser.id] };

        dispatch(
          freeBook(body, res => {
            // on success
            setShowConfirmBook(false);
            dispatch(
              getPlan(topic.id, currentUser.id, true, data => {
                if (data[0]?.id) {
                  let planId = data[0].id;

                  dispatch(
                    updateStudyPlanStatus(planId, FINISHED, () => {
                      dispatchGetIncome(topicId);
                      dispatch(sendNotificationStudyPlanFinished());
                      setEndMaterialVisibleModal(false);
                      setAlreadyFinished(true);
                      localStorage.removeItem('finishBlocks');
                    })
                  );

                  setReviewModalVisible(true);
                }
              })
            );
            setBookAddress('');
          })
        );

        return;
      }

      dispatch(
        getPlan(topic.id, currentUser.id, true, data => {
          if (data[0]?.id) {
            let planId = data[0].id;

            if (topic?.type?.approved || isCorporateCourseWithoutApprove(topic?.type?.id)) {
              dispatch(
                eventRequestFinish(planId, true, () => {
                  dispatchGetIncome(topicId);
                })
              );
              setEndMaterialVisibleModal(false);
              dispatch(sendNotificationStudyPlanFinished());

              // Topic ?
            } else {
              if (isTopicType25 || isSkills(topic?.type?.id)) {
                const status = withOutValidation ? FINISHED : WAITING;
                dispatch(
                  updateStudyPlanStatus(data[0].id, status, res => {
                    let files = executiveFiles && executiveFiles.map(file => file.uuid);
                    if (topic && topic.planStatus && topic.planStatus.planStatus !== PlanStatus.REJECTED_BY_MANAGER) {
                      dispatch(
                        setExecutiveTask(res.id, executiveReview, files, pokaAssessor, () => {
                          setAlreadyFinished(true);
                          document.location.reload();
                        })
                      );
                    } else {
                      dispatch(
                        updateExecutiveTask(res.id, executiveReview, files, pokaAssessor, () => {
                          setAlreadyFinished(true);
                          document.location.reload();
                        })
                      );
                    }
                    setEndMaterialVisibleModal(false);
                  })
                );
              } else {
                if (checkEndTopic) {
                  dispatch(
                    updateStudyPlanStatus(data[0].id, FINISHED, () => {
                      dispatchGetIncome(topicId);
                      dispatch(sendNotificationStudyPlanFinished());
                      setEndMaterialVisibleModal(false);
                      setAlreadyFinished(true);
                      localStorage.removeItem('finishBlocks');
                    })
                  );
                }
              }
              if (checkEndTopic && !isSkills(topic?.type?.id)) {
                setReviewModalVisible(true);
              }
            }

            if (isSkills(topic?.type?.id)) dispatch(getPokaAssessor(currentUser.id, topic?.id));

            if (!isSkills(topic?.type?.id)) {
              dispatch(
                getIncome({
                  userId: currentUser.id,
                  topicId: topicId,
                  bonus: 'TOPIC_COMPLETE',
                  incomeType: FINISHED_TOPIC,
                })
              );
            }
          }
        })
      );
    }
  };

  const showCalendarModal = () => (
    <CalendarModal
      topicInfo={topicInfo}
      comment={topicInfo && topicInfo.topicComment}
      eventTypeId={topicTypeId}
      disabledSelect={!!disabledSelect}
      dateEvent={dayjs(_get(topic, 'startDate'), 'D MMMM YYYY') || ''}
      title={!disabledSelect ? t('selectPlan') : t('planingEvent')}
      open={showCalendar}
      onOk={handleStudyPlanClick}
      onCancel={() => setShowCalendar(false)}
      okText={t('add')}
      {...(topicTypeId === 20 && modalParams)}
      {...(disabledSelect && modalParams)}
      planningTopicId={topic?.id}
      hasEvents={topicInfo?.eventCount || 0}
      selectMonthEvents={!!(topic?.type && (isCorpCourse || topicTypeId === 20))}
      currentUser={currentUser}
    />
  );

  const visibleParticipationConfirmationModal = () =>
    showParticipationConfirmationModal && (
      <ParticipationConfirmationModal
        open={showParticipationConfirmationModal}
        onClose={handleParticipationConfirmationModalCancel}
      />
    );

  const handleAddToPlanNow = () => {
    if (!topicId) {
      message('ERROR');
      return;
    }

    const date = Utils.getPlanDate(topic.duration);

    dispatch(createStudyPlan(currentUser.id, topicId, date, '', loadData));
  };

  const showAddressBookModal = (flagNow = false) => {
    if (bookData.addresses.length > 0) {
      setBookData(prevBookData => ({
        ...prevBookData,
        isAddressBookVisible: true,
        flagNow,
      }));
      return;
    }

    const withNoEvents = Utils.isWithoutEvents(topic);

    if (withNoEvents && flagNow) {
      handleAddToPlanNow();
      setBookData(prevBookData => ({ ...prevBookData, flagNow: false }));
    } else {
      setShowCalendar(true);
    }
  };

  const handleAddressBookClose = () => setBookData(prevBookData => ({ ...prevBookData, isAddressBookVisible: false }));

  const handleAddressBookSubmit = addressId => {
    setBookData(prevBookData => ({
      ...prevBookData,
      addresses: [],
      isAddressBookVisible: false,
      addressId: addressId > 0 ? addressId : 0,
    }));

    const withNoEvents = Utils.isWithoutEvents(topic);

    if (withNoEvents && bookData.flagNow) {
      handleAddToPlanNow();
      setBookData(prevBookData => ({ ...prevBookData, flagNow: false }));
    } else {
      setShowCalendar(true);
    }
  };

  const showCoursesList = place => (
    <div
      className={classNames(
        {
          [css['Content-left-col-courses']]: place === 'left',
          [css['Content-bottom-col']]: place === 'bottom',
        },
        css['Content-background']
      )}
      id={'CoursesList'}
    >
      <Courses
        chapters={chapters}
        data={statistic?.blocksStat}
        currentTestId={currentTest?.blockId || currentTest?.id}
        onChange={changeBlockHandler}
      />
      <ModalWarningForTest open={visibleModalWarning} changeVisible={setVisibleModalWarning} />
    </div>
  );

  const canRestartBlocks =
    (isSkills(topicTypeId) || isExecutiveTask(topicTypeId)) &&
    topicPlanStatusPlanStatus === PlanStatus.REJECTED_BY_MANAGER;

  const showBlocks = (
    <Blocks
      currentTest={currentTest}
      next={next}
      getStatistic={getStatistic}
      getStatisticStart={getStatisticStart}
      getBlockStatistic={getBlockStatistic}
      currentUser={currentUser}
      topicId={topicId}
      updateChaptersIDB={updateChaptersIDB}
      removeChaptersFromIDB={removeChaptersFromIDB}
      reviewModalVisible={reviewModalVisible || activeExecutiveTab === '3'}
      canRestartBlocks={canRestartBlocks}
      studyPlan={studyPlanId[0]}
    />
  );

  const isPreloader = (topicInPlan && !currentTest) || (!topicInPlan && !chapters);
  const canView = topicInPlan && (statistic?.isAvailableForViewBlocks || isSkills(topic?.type?.id));

  const test2 = isPreloader ? (
    <div className={classNames(css['Content-main-preloader'])}>
      <Spin />
    </div>
  ) : blockCount && canView ? (
    showBlocks
  ) : (
    <ChaptersPreview chapters={chapters} topic={topic} loadData={loadData} />
  );

  const tabItems = [
    {
      key: '1',
      label: t('executiveTaskAbout'),
      children: <ExecutiveTaskData topicID={parseInt(topicId, 10)} companyID={currentUser.domainCompany.id} />,
    },
    ...(blockCount
      ? [
          {
            key: '2',
            label: t('executiveAttachedMaterials'),
            children: test2,
          },
        ]
      : []),
    {
      key: '3',
      label: t('executiveTaskReport'),
      children: (
        <ExecutiveFinishedScreen
          onFinish={onExecutiveTaskFinished}
          disabled={isFinished()}
          topic={topic}
          user={currentUser}
          files={executiveFiles}
          review={executiveReview}
        />
      ),
    },
  ];

  const showTopicStudyContent = (
    <>
      {/* левая часть */}
      <Col sm={24} md={24} lg={8} id='ContentLeft' className={css['Content-left-col']}>
        {isSkills(topic?.type?.id) ? (
          <>
            <PokaInfoBlock
              addToPlan={() => setShowCalendar(true)}
              topic={topic}
              statistic={statistic}
              endMaterial={endMaterial}
            />
            {window.outerWidth > 992 && topicInPlan && showCoursesList('left')}
          </>
        ) : (
          <>
            <div className={classNames(css['Content-left-col-description'], css['Content-background'])}>
              {topic && <h3>{topic.name}</h3>}
              <Link to={`/collection-topics/${topicId}`} className={css['Content-left-col-description-back']}>
                {t('toMaterial')}
              </Link>
              {isCorpCourse && isWaitingStatus && t('inCoordination')}
              {(!isCorpCourse || !isWaitingStatus) &&
                !isLoading &&
                statistic &&
                ((isTopicType25 && blockCount > 0) || !isTopicType25) && (
                  <ShowProgress steps={statistic.countBlocks} completedSteps={statistic.countFinishedBlocks} />
                )}
              <TopicStudyInfo
                t={t}
                topic={topic}
                statistic={statistic}
                isFinished={isFinished()}
                isLoading={isLoading}
                topicTypeId={topicTypeId}
                alreadyFinished={alreadyFinished}
                topicPlanStatus={topicPlanStatus}
                next={next}
                handleEndMaterialButton={handleEndMaterialButton}
                handleEndMaterialButtonOK={handleEndMaterialButtonOK}
                setShowCalendar={() => (topic?.qiwiLibrary ? showAddressBookModal() : setShowCalendar(true))}
                isExecutiveFinished={isExecutiveFinished}
                handleAddToPlanNow={topic?.qiwiLibrary ? () => showAddressBookModal(true) : handleAddToPlanNow}
              />
              <AddressBook
                textWindowTitle={t('addressBookWindowTitle')}
                textSubmitButton={t('addressBookSubmitButton')}
                textAccessible={t('addressBookaccessible')}
                textNotAccessible={t('addressBookNotAccessible')}
                textReadOnline={t('addressBookReadOnline')}
                onButtonClose={handleAddressBookClose}
                onButtonSubmit={handleAddressBookSubmit}
                open={bookData.isAddressBookVisible}
                data={bookData.addresses}
              />
            </div>
            {window.outerWidth > 992 && topicInPlan && showCoursesList('left')}
          </>
        )}
      </Col>
      {/* правая часть */}
      {isTopicType25 ? (
        <div
          className={classNames(
            css['Content-main'],
            css['Content-background'],
            css['Content-background__executiveTask']
          )}
          id={'StudyContent'}
        >
          <div className={css['Content-container']}>
            <Tabs
              activeKey={activeExecutiveTab}
              onChange={key => setActiveExecutiveTab(key)}
              className={css['Content-tabs']}
              size='large'
              items={tabItems}
            />
          </div>
          {showCalendarModal()}
        </div>
      ) : (
        <Col
          sm={24}
          md={24}
          lg={16}
          className={classNames(css['Content-main'], css['Content-background'])}
          id={'StudyContent'}
        >
          {isPreloader ? (
            <div className={classNames(css['Content-main-preloader'])}>
              <Spin />
            </div>
          ) : canView ? (
            showBlocks
          ) : (
            <ChaptersPreview
              chapters={chapters}
              topic={topic}
              handleAddToPlanNow={topic?.qiwiLibrary ? () => showAddressBookModal(true) : handleAddToPlanNow}
              setShowCalendar={() => (topic?.qiwiLibrary ? showAddressBookModal() : setShowCalendar(true))}
              isLoading={isLoading}
            />
          )}
          {showCalendarModal()}
          {visibleParticipationConfirmationModal()}
        </Col>
      )}
    </>
  );

  const showStandardTopic = () => {
    return (
      <>
        <Content className={classNames(css.Content, { [css['Content-executive']]: isTopicType25 })}>
          <CustomModal
            title={t('endMaterialModalTitle')}
            okText={t('endMaterialModalOK')}
            onOk={handleEndMaterialButtonOK}
            onCancel={() => setEndMaterialVisibleModal(false)}
            open={endMaterialVisibleModal}
            classNameFooter={css['Content-modal-footer']}
            manualCancel={true}
          />
          {isSkills(topic?.type?.id) && <PokaHeader topic={topic} />}
          {isTopicType25 ? showTopicStudyContent : <Row gutter={{ sm: 0, md: 0, lg: 48 }}>{showTopicStudyContent}</Row>}
          {window.outerWidth < 992 &&
            topicInPlan &&
            // Дубликат для адаптива
            (isTopicType25 ? (
              showCoursesList('bottom')
            ) : (
              <Col sm={24} md={24} lg={8} className={css['Content-bottom-col']}>
                {showCoursesList('left')}
              </Col>
            ))}
        </Content>

        {!isTopicType25 && (
          <SendReviewModal2
            currentUserId={currentUser.id}
            confirmModal={true}
            sendReviewTopicId={Number(topicId)}
            isSendReviewModalVisible={reviewModalVisible}
            hideSendReviewModal={handleHideSendReviewModal}
            studyPlan={studyPlanId}
          />
        )}

        <CustomModal
          open={showConfirmBook}
          title={t('returnBook', { address: bookAddress })}
          contentClassName={classNames(css['App-card-item'])}
          okText={t('returnBookYes', { address: bookAddress })}
          cancelText={t('returnBookNo')}
          onOk={endMaterial}
          onCancel={() => setShowConfirmBook(false)}
          footerBtnOrange
          modificate
          cardOn
          manualCancel
        />
      </>
    );
  };

  const showEmptyTopic = () => {
    if (topicTypeId) {
      return (
        <div className={classNames(css.Content, css.EmptyTopicPage)}>
          <p>{t('emptyTopicText')}</p>
          <Button
            className={css['TopicFilter-buttonResults']}
            type='primary'
            size='large'
            onClick={() => navigate(`/collection-topics/${topicId}`)}
          >
            {t('toMaterial')}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classNames(css.Content, css.EmptyTopicPage)}>
          <Spin />
        </div>
      );
    }
  };

  if (!isLoading && !statistic.countBlocks && !isTopicType25) {
    return showEmptyTopic();
  } else {
    return showStandardTopic();
  }
};

CollectionMaterialsStudy.propTypes = {
  topic: PropTypes.object,
  statistic: PropTypes.object,
  isLoading: PropTypes.bool,
  question: PropTypes.any,
  currentUser: PropTypes.object,
  chapters: PropTypes.array,
  studyPlanId: PropTypes.array,
  selectFiles: PropTypes.any,
  getBlockStatistic: PropTypes.func,
  getTopicById: PropTypes.func,
  getChapters: PropTypes.func,
  createStudyPlan: PropTypes.func,
  setExecutiveTask: PropTypes.func,
  updateExecutiveTask: PropTypes.func,
  checkStudyPlan: PropTypes.func,
};

const mapStateToProps = createSelector(
  [selectTopics, selectLMS, selectUsers, selectChapters, studyPlanSelect, selectFiles],
  ({ topic }, { statistic, isLoading, question }, { currentUser }, { chapters }, studyPlan, selectFiles) => ({
    topic,
    statistic,
    isLoading,
    question,
    currentUser,
    chapters,
    studyPlanId: studyPlan.studyPlanId,
  })
);

const mapActionsToProps = {
  getBlockStatistic,
  getTopicById,
  getChapters,
  createStudyPlan,
  setExecutiveTask,
  updateExecutiveTask,
  checkStudyPlan,
};
export const CollectionMaterialsIDStudy = connect(mapStateToProps, mapActionsToProps)(CollectionMaterialsStudy);
