/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Card, Col, Input, Radio, Row } from 'antd';
import RadioGroup from 'antd/es/radio/group';
import { CaretRightOutlined } from '@ant-design/icons';
import { FiUser } from 'react-icons/fi';

import { extractUsername, isArray, isBool, isNum, isObject, isString } from '../../utils';

import PropTypes from 'prop-types';

import css from '../../css/styles.module.scss';

const { TextArea } = Input;

export const CriteriaCard = props => {
  const voter = useSelector(state => state.d360Vote);
  const survey = useSelector(state => state.d360Project);

  const [loading, setLoading] = useState(false);
  const [measurable, setMeasurable] = useState(undefined);
  const [measurableName, setMeasurableName] = useState('');
  const [measurablePosition, setMeasurablePosition] = useState('');
  const [scaleId, setScaleId] = useState(undefined);
  const [scoreId, setScoreId] = useState(undefined);
  const [comment, setComment] = useState('');
  const { t } = useTranslation('D360_CriteriaCard');
  const [competenceName, setCompetenceName] = useState('');
  const [skillName, setSkillName] = useState('');
  const [scores, setScores] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isViewMode, setIsViewMode] = useState(false);
  const [result, setResult] = useState([]);
  const [criteria, setCriteria] = useState(undefined);

  useEffect(() => {
    let s,
      v = false;
    if (isObject(voter) && 'loading' in voter && isBool(voter.loading)) {
      v = voter.loading;
    }

    if (isObject(survey) && 'loading' in survey && isBool(survey.loading)) {
      s = survey.loading;
    }

    s === true || v === true ? setLoading(true) : setLoading(false);
  }, [voter, survey]);

  useEffect(() => {
    if (isObject(criteria) && isObject(measurable)) {
      const resultByMeasurable = result.find(
        e => e.d360_measurable_id === measurable.id && e.d360_criteria_id === criteria.id
      );

      if (resultByMeasurable) {
        setScoreId(resultByMeasurable.d360_score_id);
        setComment(resultByMeasurable.comment);
      } else {
        setScoreId(undefined);
        setComment('');
      }
    }
  }, [isViewMode, result, measurable, criteria]);

  useEffect(() => {
    if (isViewMode === false) setDisableSubmit(typeof scoreId === 'undefined');
  }, [scoreId]);

  useEffect(() => {
    if (isObject(props) && 'result' in props) {
      if (isArray(props.result)) {
        setResult(props.result);
      }
    }

    if (isObject(props) && 'measurable' in props) {
      setMeasurable(props.measurable);
      if (isObject(props.measurable) && 'student' in props.measurable) {
        if (isObject(props.measurable.student)) {
          setMeasurableName(extractUsername(props.measurable.student));
          if ('position' in props.measurable.student && isString(props.measurable.student.position)) {
            setMeasurablePosition(props.measurable.student.position);
          }
        }
      }
    }

    if (isObject(props) && 'isViewMode' in props && isBool(props.isViewMode)) {
      setIsViewMode(props.isViewMode);
    }
    if (isObject(props) && 'criteria' in props && isObject(props.criteria)) {
      setCriteria(props.criteria);

      if (
        'competence' in props.criteria &&
        isObject(props.criteria.competence) &&
        'name' in props.criteria.competence &&
        isString(props.criteria.competence.name)
      ) {
        setCompetenceName(props.criteria.competence.name);
      }

      if (
        'skill' in props.criteria &&
        isObject(props.criteria.skill) &&
        'name' in props.criteria.skill &&
        isString(props.criteria.skill.name)
      ) {
        setSkillName(props.criteria.skill.name);
      }

      if ('scale' in props.criteria && isObject(props.criteria.scale)) {
        if ('id' in props.criteria.scale && isNum(props.criteria.scale.id)) {
          setScaleId(props.criteria.scale.id);
        }

        if ('values' in props.criteria.scale && isArray(props.criteria.scale.values)) {
          setScores(props.criteria.scale.values);
        }
      }
    }
  }, [props]);

  const onChange = scoreId => {
    setScoreId(scoreId);
    setDisableSubmit(false);
  };

  const onSubmitClick = () => {
    if (typeof props.onChange === 'function') {
      setScoreId(undefined);
      setComment('');
      props.onChange(scaleId, scoreId, comment);
    }
  };

  const title = (
    <Row style={{ fontSize: '14px' }}>
      <Col span={12}>
        <span className={css.CriteriaCardMeasurableKey}>
          <FiUser /> &nbsp;{t('cardTitle')}:
        </span>
        <span className={css.CriteriaCardMeasurableVal}>{measurableName}</span>
      </Col>
      <Col span={12}>
        <div className={css.CriteriaCardMeasurableKey} style={{ textAlign: 'right' }}>
          {measurablePosition}
        </div>
      </Col>
    </Row>
  );

  return (
    <Card className={css.CriteriaCard} title={title} loading={loading}>
      <Row>
        <Col span={24}>
          <h2 className={css.CriteriaCardCompetence}>
            <p style={{ fontSize: 14 }}>
              {' '}
              <CaretRightOutlined /> {t('competence')}
            </p>
            {competenceName}
            <p style={{ paddingTop: 10, fontSize: 14, lineHeight: '1.2', color: 'rgb(125,125,125)' }}>
              {props?.criteria?.competence_description}
            </p>
          </h2>

          <h2 style={{ marginTop: '1rem' }} className={css.CriteriaCardCompetence}>
            <p style={{ fontSize: 14 }}>
              {' '}
              <CaretRightOutlined /> {t('skill')}
            </p>
            {skillName}
            <p style={{ paddingTop: 10, fontSize: 14, lineHeight: '1.2', color: 'rgb(125,125,125)' }}>
              {props?.criteria?.skill_description}
            </p>
          </h2>

          <div className={css.CriteriaCardOptions}>
            <RadioGroup
              size='large'
              buttonStyle='solid'
              onChange={e => onChange(e.target.value)}
              disabled={isViewMode}
              value={scoreId}
            >
              {scores.map((score, index) => {
                return score.indicator === 'CAN_SKIP_INDICATOR' ? (
                  <Radio key={'score_' + index} value={score.id} className={css.CriteriaCardRadio}>
                    {t('CAN_SKIP_INDICATOR')}
                  </Radio>
                ) : (
                  <Radio key={'score_' + index} value={score.id} className={css.CriteriaCardRadio}>
                    {score.indicator}
                  </Radio>
                );
              })}
            </RadioGroup>
          </div>
          <Row style={{ margin: 15 }}>
            <Col span={24}>
              <TextArea
                rows={4}
                placeholder={t('comment')}
                onChange={e => setComment(e.target.value)}
                value={comment}
                maxLength={4096}
                disabled={isViewMode}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ padding: '1rem' }}>
          <Row>
            <Col span={24} align='right'>
              {/* {props.isLastQuestion ? (
                <Button
                  onClick={onSubmitClick}
                  type='primary'
                  disabled={disableSubmit}
                  style={{
                    height: 'fit-content',
                    width: '240px',
                    whiteSpace: 'normal',
                    padding: '5px',
                  }}
                >
                  {t('nextButtonAlt')}
                </Button>
              ) : (
                <Button onClick={onSubmitClick} type='primary' disabled={disableSubmit}>
                  {t('nextButton')}
                </Button>
              )} */}

              {isViewMode === false && props.isFinishedMeasurable ? (
                <Button onClick={onSubmitClick} type='primary'>
                  {t('finishButton')}
                </Button>
              ) : (
                <Button onClick={onSubmitClick} type='primary' disabled={disableSubmit}>
                  {t('nextButton')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

CriteriaCard.propTypes = {
  measurable: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  criteria: PropTypes.object,
  result: PropTypes.array.isRequired,
  isFinishedMeasurable: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  isViewMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CriteriaCard;
