/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputItem from './InputItem';

import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './TagsInput.module.scss';

const TagsInputLink = ({ modificate, placeholder, error, topicType, value, onChange = () => {}, name }) => {
  const [tags, setTags] = useState(value || ['']);
  const { t } = useTranslation('input');

  useEffect(() => {
    setTags(value || ['']);
  }, [value]);

  const handleClose = ind => () => {
    const newTags = tags.slice();
    newTags.splice(ind, 1);
    setTags(newTags);
    onChange({ name, value: newTags });
  };

  const handleInputConfirm = (value, ind) => {
    const newTags = tags.slice();
    newTags.splice(ind, 1, value);
    setTags(newTags);
    onChange({ name, value: newTags });
  };

  const handleAddLink = () => setTags([...tags, '']);

  const handleAddLinkDebounce = _debounce(handleAddLink, 300);

  return (
    <div className={css.TagInput}>
      <TagList
        tags={tags}
        modificate={modificate}
        placeholder={placeholder}
        handleInputConfirm={handleInputConfirm}
        error={error}
        topicType={topicType}
        handleClose={handleClose}
      />
      {tags && tags.length <= 10 && (
        <div className={css.addLink}>
          <div className={classNames(css['addLink-button'])} onClick={handleAddLinkDebounce}>
            {t('addMore')}
          </div>
        </div>
      )}
    </div>
  );
};

const TagList = ({ tags, modificate, placeholder, handleInputConfirm, error, topicType, handleClose }) => {
  const { t } = useTranslation('input');
  return tags.map((tag, idx) => (
    <InputItem
      modificate={modificate}
      tag={tag}
      placeholder={placeholder}
      handleInputConfirm={handleInputConfirm}
      key={`tag-${idx}`}
      id={idx}
      linkItem
      errorRequiredIfEmpty={error === t('error')}
      error={error && error.indexOf(idx)}
      topicType={topicType}
      $deletable={idx !== 0}
      handleInputDelete={handleClose(idx)}
    />
  ));
};

TagsInputLink.propTypes = {
  deleteAuthors: PropTypes.func,
  error: PropTypes.array,
  modificate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  topic: PropTypes.object,
  topicType: PropTypes.object,
  value: PropTypes.array,
};

export default TagsInputLink;
