// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-fxXLYQ.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-cVJgr0 .user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-label-olv5vp[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-fxXLYQ.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-cVJgr0 .user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-item-DE8xtP[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-Montserrat-p74sOr {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list-DkWDoU {
  flex-direction: column;
  gap: 24px;
  height: 100%;
  padding: 48px;
  display: flex;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list-DkWDoU {
    padding: 24px;
  }
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__text-pVF8_0 {
  font-size: 28px;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__description-XG5pr5 {
  color: #000000e0;
  background: #00000005;
  width: auto;
  padding: 0 8px;
  font-size: 16px;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__element-kIsl4a {
  margin-bottom: 24px;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__footer-pQ36qQ {
  justify-content: center;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__block-flW272 {
  gap: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__block-flW272 span {
  color: #000000b2;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/CheckList/CheckList.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAMA;EACE;IAHE,aAAa;EACf;AACF;;AAKA;EAFE,eAAe;AACjB;;AAIA;EADE,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;EACX,cAAc;EACd,eAAe;AACjB;;AAGA;EAAE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;AACf;;AACA;EAEE,QAAQ;EACR,mBAAmB;EACnB,eAAe;EACf,aAAa;AACf;;AAAA;EAGE,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.check-list {\n  padding: 48px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .check-list {\n    padding: 24px;\n  }\n}\n.check-list__text {\n  font-size: 28px;\n}\n.check-list__description {\n  background: rgba(0, 0, 0, 0.0196078431);\n  font-size: 16px;\n  padding: 0 8px;\n  width: auto;\n  color: rgba(0, 0, 0, 0.8784313725);\n}\n.check-list__element {\n  margin-bottom: 24px;\n}\n.check-list__footer {\n  display: flex;\n  justify-content: center;\n}\n.check-list__block {\n  display: flex;\n  gap: 8px;\n  font-size: 20px;\n  margin-bottom: 16px;\n}\n.check-list__block span {\n  color: rgba(0, 0, 0, 0.6980392157);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-fxXLYQ`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-fxXLYQ`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-cVJgr0`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-cVJgr0`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-label-olv5vp`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-label-olv5vp`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-item-DE8xtP`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-picker-item-DE8xtP`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-Montserrat-p74sOr`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__ql-font-Montserrat-p74sOr`,
	"check-list": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list-DkWDoU`,
	"checkList": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list-DkWDoU`,
	"check-list__text": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__text-pVF8_0`,
	"checkListText": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__text-pVF8_0`,
	"check-list__description": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__description-XG5pr5`,
	"checkListDescription": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__description-XG5pr5`,
	"check-list__element": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__element-kIsl4a`,
	"checkListElement": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__element-kIsl4a`,
	"check-list__footer": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__footer-pQ36qQ`,
	"checkListFooter": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__footer-pQ36qQ`,
	"check-list__block": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__block-flW272`,
	"checkListBlock": `user-src-pages-CollectionMaterialsIDStudy-CheckList-CheckList-module__check-list__block-flW272`
};
export default ___CSS_LOADER_EXPORT___;
