// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-BadgeElement-style-module__ql-picker-r_odT7.user-src-components-BadgeElement-style-module__ql-font-z1b8Fw .user-src-components-BadgeElement-style-module__ql-picker-label-VjEmjS[data-value="Montserrat"]:before, .user-src-components-BadgeElement-style-module__ql-picker-r_odT7.user-src-components-BadgeElement-style-module__ql-font-z1b8Fw .user-src-components-BadgeElement-style-module__ql-picker-item-y6xlq4[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-BadgeElement-style-module__ql-font-Montserrat-TVlL0U {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-BadgeElement-style-module__badge-element-YhBdVT {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 120px;
  display: flex;
}

.user-src-components-BadgeElement-style-module__badge-element-YhBdVT img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.user-src-components-BadgeElement-style-module__badge-element--mini-aAldTB {
  margin: 0 10px;
}

.user-src-components-BadgeElement-style-module__badge-element--mini-aAldTB img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.user-src-components-BadgeElement-style-module__badge-element__modal-h8LT_I {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.user-src-components-BadgeElement-style-module__badge-element__modal-h8LT_I img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/BadgeElement/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,aAAa;AACf;;AAMA;EAHE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAKA;EAFE,cAAc;AAChB;;AAIA;EADE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAGA;EAAE,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.badge-element {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  max-width: 120px;\n  gap: 10px;\n}\n.badge-element img {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n}\n.badge-element--mini {\n  margin: 0 10px;\n}\n.badge-element--mini img {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n}\n.badge-element__modal {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  align-items: center;\n  gap: 20px;\n}\n.badge-element__modal img {\n  width: 200px;\n  height: 200px;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-BadgeElement-style-module__ql-picker-r_odT7`,
	"qlPicker": `user-src-components-BadgeElement-style-module__ql-picker-r_odT7`,
	"ql-font": `user-src-components-BadgeElement-style-module__ql-font-z1b8Fw`,
	"qlFont": `user-src-components-BadgeElement-style-module__ql-font-z1b8Fw`,
	"ql-picker-label": `user-src-components-BadgeElement-style-module__ql-picker-label-VjEmjS`,
	"qlPickerLabel": `user-src-components-BadgeElement-style-module__ql-picker-label-VjEmjS`,
	"ql-picker-item": `user-src-components-BadgeElement-style-module__ql-picker-item-y6xlq4`,
	"qlPickerItem": `user-src-components-BadgeElement-style-module__ql-picker-item-y6xlq4`,
	"ql-font-Montserrat": `user-src-components-BadgeElement-style-module__ql-font-Montserrat-TVlL0U`,
	"qlFontMontserrat": `user-src-components-BadgeElement-style-module__ql-font-Montserrat-TVlL0U`,
	"badge-element": `user-src-components-BadgeElement-style-module__badge-element-YhBdVT`,
	"badgeElement": `user-src-components-BadgeElement-style-module__badge-element-YhBdVT`,
	"badge-element--mini": `user-src-components-BadgeElement-style-module__badge-element--mini-aAldTB`,
	"badgeElementMini": `user-src-components-BadgeElement-style-module__badge-element--mini-aAldTB`,
	"badge-element__modal": `user-src-components-BadgeElement-style-module__badge-element__modal-h8LT_I`,
	"badgeElementModal": `user-src-components-BadgeElement-style-module__badge-element__modal-h8LT_I`
};
export default ___CSS_LOADER_EXPORT___;
