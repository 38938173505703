/* eslint-disable */
import {
  DELETE_TAG_FAIL,
  DELETE_TAG_START,
  DELETE_TAG_SUCCESS,
  GET_ANOTHER_TAGS_FAIL,
  GET_ANOTHER_TAGS_START,
  GET_ANOTHER_TAGS_SUCCESS,
  GET_TAG_NAME_FAIL,
  GET_TAG_NAME_START,
  GET_TAG_NAME_SUCCESS,
  GET_TAGS_FAIL,
  GET_TAGS_START,
  GET_TAGS_SUCCESS,
  POST_TAG_FAIL,
  POST_TAG_START,
  POST_TAG_SUCCESS,
  SEARCH_TAGS_FAIL,
  SEARCH_TAGS_START,
  SEARCH_TAGS_SUCCESS,
} from './actions';

const initialState = {
  tags: null,
  tagsTotal: null,
  tagsPageSize: null,
  tagsPageNumber: null,
  tagsSearch: null,
  tagsSearchTotal: null,
  tagsSearchPageSize: null,
  tagsSearchPageNumber: null,
  tagsAnother: null,
  tagsAnotherTotal: null,
  tagsAnotherPageSize: null,
  tagsAnotherPageNumber: null,
  colleguesUserId: null,
  isLoading: false,
  error: false,
};

export default (state = initialState, { type, response, action, headers }) => {
  switch (type) {
    case GET_TAGS_START:
      return {
        ...state,
        tags: null,
        tagsTotal: null,
        tagsPageSize: null,
        tagsPageNumber: null,
        isLoading: true,
        error: false,
      };

    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: response,
        tagsTotal: Number(headers.get('X-Total-Elements')) || 0,
        tagsPageSize: Number(headers.get('X-Page-Size')) || 0,
        tagsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };
    case GET_TAGS_FAIL:
      return { ...state, error: true, isLoading: false };
    case GET_ANOTHER_TAGS_START:
      return {
        ...state,
        tagsAnother: null,
        tagsAnotherTotal: null,
        tagsAnotherPageSize: null,
        tagsAnotherPageNumber: null,
        colleguesUserId: null,
        isLoading: true,
        error: false,
      };

    case GET_ANOTHER_TAGS_SUCCESS:
      return {
        ...state,
        tagsAnother: response,
        tagsAnotherTotal: Number(headers.get('X-Total-Elements')) || 0,
        tagsAnotherPageSize: Number(headers.get('X-Page-Size')) || 0,
        tagsAnotherPageNumber: Number(headers.get('X-Page-Number')) || 0,
        colleguesUserId: action.userId,
        isLoading: false,
      };

    case GET_ANOTHER_TAGS_FAIL:
      return { ...state, error: true, isLoading: false };
    case SEARCH_TAGS_START:
      return {
        ...state,
        tagsSearch: null,
        tagsSearchTotal: null,
        tagsSearchPageSize: null,
        tagsSearchPageNumber: null,
        isLoading: true,
        error: false,
      };

    case SEARCH_TAGS_SUCCESS:
      return {
        ...state,
        tagsSearch: response,
        tagsSearchTotal: Number(headers.get('X-Total-Elements')) || 0,
        tagsSearchPageSize: Number(headers.get('X-Page-Size')) || 0,
        tagsSearchPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case SEARCH_TAGS_FAIL:
      return { ...state, error: true, isLoading: false };

    case GET_TAG_NAME_START:
    case POST_TAG_START:
      return { ...state, isLoading: true, error: false };
    case GET_TAG_NAME_SUCCESS:
    case POST_TAG_SUCCESS:
      return { ...state, isLoading: false };

    case GET_TAG_NAME_FAIL:
    case POST_TAG_FAIL:
      return { ...state, error: true, isLoading: false };

    case DELETE_TAG_START:
      return { ...state, isLoading: true, error: false };

    case DELETE_TAG_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_TAG_FAIL:
      return { ...state, error: true, isLoading: false };

    default:
      return state;
  }
};
