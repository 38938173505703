/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateBookAddresses } from '@/store/books/actions';
import {
  eventRequestConfirm,
  eventRequestFinish,
  eventRequestRefuse,
  getApprovedCoursesCost,
} from '@/store/course/actions';
import {
  changeViewPage,
  clearPlan,
  createStudyPlan,
  getStudyPlan,
  getStudyPlanDinamic,
  getStudyPlanFirstMonth,
  updateStudyPlanDate,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import { checkTopicComment, showSendReviewModal } from '@/store/topics/actions';

import { selectCourse as courseSelect } from '@/store/course/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectStudyPlan as studuPlanSelect } from '@/store/study-plan/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, FloatButton, Layout, Pagination, Spin, Table, Tooltip } from 'antd';
import { StudyPlan } from '@/pages/ColleguesProfile/Planning/study-plan';
import Scroll from '@/components/HorizontalScroll';
import Modal from '@/components/Modal';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal';
import MonthList from '@/components/MonthList';
import Rate from '@/components/Rate';
import TopicAddModal from '@/components/TopicAddModal';
import TypeBadge from '@/components/TypeBadge';

import CalendarModal from '../../CalendarModal/index.jsx';
import MyCollectionModal from './MyCollectionModal';
import { StudyPlanFinished } from './study-plan-finished';
import StudyPlanButton from './StudyPlanButton';

import { withOutletContext } from '@/utils/WithOutletContext';
import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import css from './Planning.module.scss';

import { CURRENT_MONTH, LAST_MONTH, NEXT_MONTH } from '@/constants/study-plan-statuses';
import { TOPIC_TYPES } from '@/constants/topic-types';

export class Planning extends Component {
  static propTypes = {
    changeViewPage: PropTypes.func,
    checkTopicComment: PropTypes.func,
    clearPlan: PropTypes.func,
    coursesCost: PropTypes.object,
    createStudyPlan: PropTypes.func,
    currentUserId: PropTypes.number,
    event: PropTypes.bool,
    eventRequestConfirm: PropTypes.func,
    eventRequestFinish: PropTypes.func,
    eventRequestRefuse: PropTypes.func,
    externalLogin: PropTypes.string,
    getApprovedCoursesCost: PropTypes.func,
    getStudyPlan: PropTypes.func,
    getStudyPlanDinamic: PropTypes.func,
    getStudyPlanFirstMonth: PropTypes.func,
    navigate: PropTypes.func,
    isAnother: PropTypes.bool,
    location: PropTypes.object,
    showSendReviewModal: PropTypes.func,
    isLoading: PropTypes.any,
    studyPlan: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    studyPlanFirstMonth: PropTypes.string,
    studyPlanPageNumber: PropTypes.number,
    studyPlanPageSize: PropTypes.number,
    studyPlanTotal: PropTypes.number,
    updateStudyPlanDate: PropTypes.func,
    updateStudyPlanStatus: PropTypes.func,
    viewPlan: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.currentMonth = dayjs().format('YYYY-MM');
    this.getStudyPlan = throttle(this.props.getStudyPlan, 300);
    this.state = {
      calendarModalVisible: false,
      active: this.currentMonth,
      activeMonthName: dayjs().format('MMMM YYYY'),
      eventRequest: null,
      viewPage: this.props.viewPlan,
      coursesCost: this.props.coursesCost || 0,
      isAddToPlan: false,
      userId: !props.isAnother ? props.currentUserId : props.anotherUserId,
      planningTopicType: {},
      startDate: 0,
      calendarModalCallback: null,
      isParticipationConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    if (document.location.search.indexOf('confirm') !== -1) {
      this.props.eventRequestConfirm(
        document.location.search.substring(document.location.search.indexOf('code=') + 5),
        () => {
          this.setState({ eventRequest: 'confirm' });
        },
        () => {
          this.setState({ eventRequest: 'error' });
        }
      );
    }
    if (document.location.search.indexOf('refuse') !== -1) {
      this.props.eventRequestRefuse(
        document.location.search.substring(document.location.search.indexOf('code=') + 5),
        () => {
          this.setState({ eventRequest: 'refuse' });
        },
        () => {
          this.setState({ eventRequest: 'error' });
        }
      );
    }
    if (document.location.search.indexOf('planId') !== -1) {
      if (document.location.search.indexOf('topicId') !== -1) {
        this.props.checkTopicComment(
          document.location.search.substr(
            document.location.search.indexOf('topicId=') + 8,
            document.location.search.indexOf('&userId=') - (document.location.search.indexOf('topicId=') + 8)
          ),
          response => {
            if (response) {
              if (response === 1) {
                this.setState({ eventRequest: 'errorMention' });
              }
              if (response === 2) {
                this.setState({ eventRequest: 'error' });
              }
            } else {
              this.props.showSendReviewModal(
                document.location.search.substr(
                  document.location.search.indexOf('topicId=') + 8,
                  document.location.search.indexOf('&userId=') - (document.location.search.indexOf('topicId=') + 8)
                ),
                {
                  studyPlanId: document.location.search.substring(document.location.search.indexOf('planId=') + 7),
                  currentUserId: document.location.search.substring(document.location.search.indexOf('userId=') + 7),
                  viewPlan: false,
                }
              );
            }
          }
        );
      } else {
        this.props.eventRequestFinish(
          document.location.search.substring(document.location.search.indexOf('planId=') + 7),
          false,
          () => {
            this.setState({ eventRequest: 'noVisit' });
          },
          () => {
            this.setState({ eventRequest: 'error' });
          }
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.viewPlan !== this.props.view) {
      this.setState({ viewPage: newProps.viewPlan });
    }
  }

  currentMonth = undefined;

  columns = () => [
    {
      title: '№',
      key: 'number',
      render: (text, record, index) => (
        <span className={css['Planning-table-cell-number']}>
          {this.props.studyPlanPageNumber === 0 ? index + 1 : this.props.studyPlanPageNumber * 20 + index + 1}
        </span>
      ),
    },
    {
      title: this.props.t('title'),
      dataIndex: 'topic',
      key: 'name',
      className: css['Planning-table-cell-name'],
      render: topic =>
        this.props.externalLogin && topic.scope !== 'EXTERNAL' ? (
          <Tooltip title={this.props.t('blocked')}>{topic.name}</Tooltip>
        ) : (
          <Link to={`/collection-topics/${topic.id}`}>{topic.name}</Link>
        ),
    },
    {
      title: this.props.t('type'),
      dataIndex: 'topic',
      key: 'type',
      render: topic => {
        const topicType = topic.type && TOPIC_TYPES(this.props.t).find(type => type.id === topic.type.id);
        return topicType ? <TypeBadge type={topicType.code} name={topic.type.name} /> : null;
      },
    },
    {
      title: this.props.t('rate'),
      dataIndex: 'topic',
      key: 'rate',
      className: css['Planning-table-cell-rate'],
      render: topic => <Rate count={5} value={Math.round(topic.rate)} disabled />,
    },
    {
      title: this.props.t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        const planningDate = this.state.active;
        const config =
          planningDate === this.currentMonth
            ? CURRENT_MONTH
            : planningDate < this.currentMonth
              ? LAST_MONTH
              : NEXT_MONTH;
        return (
          <span>
            {record.status === 'IN_PROGRESS' && record.topic && isCorpCourseWithOrWithoutApprove(record.topic.type.id)
              ? this.props.t('process')
              : config && config[status] && config[status].label}
          </span>
        );
      },
    },
    {
      key: 'action',
      dataIndex: 'status',
      className: css['Planning-table-cell-action'],
      render: (status, record) => {
        const planningDate = this.state.active;
        const config =
          planningDate === this.currentMonth
            ? CURRENT_MONTH
            : planningDate < this.currentMonth
              ? LAST_MONTH
              : NEXT_MONTH;
        return record.status === 'IN_PROGRESS' &&
          record.topic &&
          isCorpCourseWithOrWithoutApprove(record.topic.type.id) ? null : (
          <StudyPlanButton
            topicType={record.topic.type.id}
            topicWasFinishedBefore={record.topic.isFinishedBefore}
            planId={record.id}
            plan={record}
            topicId={record.topic.id}
            showCalendarModal={this.showCalendarModal}
            actionType={config && config[status] && config[status].buttonType}
            activeMonth={this.state.active}
            page={this.props.studyPlanPageNumber}
          />
        );
      },
    },
  ];

  handleActiveTabChange = (month, monthName) => {
    this.setState({ active: month, activeMonthName: monthName });
    this.getStudyPlan(false, this.state.userId, dayjs(month, 'YYYY-MM-DD'));
  };

  handleCalendarCancel = () => {
    this.setState({
      calendarModalVisible: false,
      calendarModalCallback: null,
    });
  };

  calendarModalCallback = response => {
    const { calendarModalCallback } = this.state;
    return calendarModalCallback && calendarModalCallback(_get(response, 'topic.planStatus.planStatus'));
  };

  handleChangeDateClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    { planId } = {},
    webinarDateTime
  ) => {
    if (planningMonths[0]) {
      this.props.updateStudyPlanDate(planId, planningMonths[0], comment, () => {
        if (!this.props.viewPlan) {
          this.getStudyPlan(false, this.props.currentUserId, dayjs(this.state.active, 'YYYY-MM-DD'));
        } else {
          this.props.clearPlan();
        }
        this.handleCalendarCancel();
      });
    }
    for (let i = 1; i < planningMonths.length; i++) {
      this.props.createStudyPlan(
        this.props.currentUserId,
        topicId,
        planningMonths[i],
        comment,
        () => {
          this.handleCalendarCancel();
        },
        false,
        getWithoutSchedule(this.state.planningTopicType.id, withoutSchedule),
        webinarDateTime
      );
    }
  };

  createToPlan = (topicId, planningMonths = [], comment, withoutSchedule) => {
    const { currentUserId, contextValue } = this.props;
    const { addressId, setAddressId } = contextValue;

    const topic = this.state.topicInfo;

    for (let i = 0; i < planningMonths.length; i++) {
      this.props.createStudyPlan(
        currentUserId,
        topicId,
        planningMonths[i],
        comment,
        response => {
          if (addressId > 0) {
            dispatch(updateBookAddresses(addressId, currentUserId));
            setAddressId(0);
          }

          this.calendarModalCallback(response);
          this.handleCalendarCancel();

          if (topic && topic.targetAudience === false && isAnyCourseOrConference(topic?.type?.id)) {
            this.setState({ isParticipationConfirmationModalOpen: true });
          }
        },
        false,
        getWithoutSchedule(this.state.planningTopicType.id, withoutSchedule)
      );
    }
  };

  showCalendarModal = (planId, topicId, topicInfo, isAddToPlan, type, startDate, cb) => {
    this.setState({
      calendarModalVisible: true,
      planningTopicId: topicId,
      planId,
      isAddToPlan: isAddToPlan || false,
      planningTopicType: type,
      startDate,
      topicInfo: { ...topicInfo, topicCost: topicInfo.cost },
      calendarModalCallback: cb,
    });
  };

  toggleTopicModalVisibility = () => {
    this.setState(state => ({ topicModalVisible: !state.topicModalVisible }));
  };

  showMyCollectionModal = () => {
    this.setState({
      topicModalVisible: false,
      myCollectionModalVisible: true,
    });
  };

  showTopicAddModal = () => {
    this.setState({
      topicModalVisible: false,
      topicAddModalVisible: true,
    });
  };

  hideTopicModals = () => {
    this.setState({
      topicModalVisible: false,
      topicAddModalVisible: false,
      myCollectionModalVisible: false,
    });
  };

  handleTopicAddSuccess = (newTopic = {}) => {
    this.hideTopicModals();
    const planningMonth = dayjs(this.state.active, 'YYYY-MM-DD');
    this.props.createStudyPlan(
      this.state.userId,
      newTopic.id,
      planningMonth,
      '',
      () => {
        this.getStudyPlan(false, this.state.userId, dayjs(this.state.active, 'YYYY-MM-DD'));
      },
      false,
      false
    );
  };

  renderMonthCard = ({ displayFormat, isPast, isCurrent, outputFormat }) => {
    const activeTab = outputFormat === this.state.active ? <div className={css.rectangle} /> : null;
    return (
      <div
        onClick={() => this.handleActiveTabChange(outputFormat, displayFormat)}
        className={classNames({ [css.past]: isPast, [css.current]: isCurrent })}
      >
        {activeTab}
        <div className={css.month}>{displayFormat}</div>
      </div>
    );
  };

  changePage = page => {
    this.getStudyPlan(false, this.state.userId, dayjs(this.state.active, 'YYYY-MM-DD'), page - 1);
  };

  closeEventRequest = () => {
    this.props.navigate('/planning');
    this.props.clearPlan();
    this.setState({ eventRequest: null });
  };

  getStudyPlan = () => {
    this.getStudyPlan(false, this.state.userId, dayjs(this.state.active, 'YYYY-MM-DD'));
  };

  handleParticipationConfirmationModalCancel = () => {
    this.setState({ isParticipationConfirmationModalOpen: false });
  };

  render() {
    const { studyPlan, studyPlanPageNumber, studyPlanPageSize, studyPlanTotal, studyPlanFirstMonth, isAnother, t } =
      this.props;
    const studyPlanAddButton =
      this.state.active > this.currentMonth ? (
        <div className={css['Planning-add-button']}>
          <Tooltip title={t('addingToPlan')}>
            <Button onClick={this.toggleTopicModalVisibility}>{t('addMaterial')}</Button>
          </Tooltip>
        </div>
      ) : null;
    const disabledSelect = this.state.planningTopicType && this.state.planningTopicType.id === 8;
    const studyPlanTable = studyPlan.length ? (
      <Table
        rowKey='id'
        isAnother={isAnother}
        dataSource={studyPlan}
        columns={this.columns()}
        pagination={false}
        className={css['Planning-table']}
      />
    ) : (
      t('selectedMonth')
    );
    const pagination =
      studyPlanPageSize && studyPlanTotal && studyPlanTotal > studyPlanPageSize ? (
        <Pagination
          isAnother={isAnother}
          current={studyPlanPageNumber + 1}
          total={studyPlanTotal}
          pageSize={studyPlanPageSize}
          onChange={this.changePage}
          className={css['Planning-pagination']}
        />
      ) : null;

    const participationConfirmationModal = this.state.isParticipationConfirmationModalOpen && (
      <ParticipationConfirmationModal
        open={this.state.isParticipationConfirmationModalOpen}
        onClose={this.handleParticipationConfirmationModalCancel}
      />
    );

    return (
      <Spin
        spinning={this.props.finished ? this.props.isLoadingFinished : this.props.isLoading}
        className={css['App-content']}
      >
        {!isAnother && (
          <div className={css['App-contentBox']}>
            <div>
              <h1>{t('myPlan')}</h1>
              <p className={css.Planning__approved}>
                {t('approved', {
                  date: dayjs(this.state.active, 'YYYY'),
                  data: !this.state.coursesCost ? 0 : this.state.coursesCost.toLocaleString('ru-RU'),
                })}
              </p>
            </div>
          </div>
        )}
        <div className={!isAnother ? css['App-card-item'] : ''}>
          {!this.state.viewPage ? (
            <div className={css['Planning-tabs']}>
              <Scroll>
                <MonthList
                  isAnother={isAnother}
                  outputFormat='YYYY-MM'
                  displayFormat='MMMM YYYY'
                  className={css['Planning-tabs-quarter']}
                  quarterEndClassName={css['Planning-tabs-quarter-end']}
                  monthClassName={css['Calendar-modal-month-container']}
                  renderMonth={this.renderMonthCard}
                  displayBeforeStart={studyPlanFirstMonth}
                />
              </Scroll>
              <div className={css['Planning-tab-content']}>
                <h1>{this.state.activeMonthName}</h1>
                {studyPlanTable}
                {studyPlanAddButton}
                {pagination}
              </div>
            </div>
          ) : (
            <div className={!isAnother ? css['Planning-tab-content'] : ''}>
              {this.props.finished ? (
                <StudyPlanFinished
                  showCalendarModal={this.showCalendarModal}
                  {...this.props}
                  currentUserId={this.state.userId}
                />
              ) : (
                <div className={css['PlanningMonth-table']}>
                  <StudyPlan
                    isAnother={isAnother}
                    showCalendarModal={this.showCalendarModal}
                    currentUserId={this.state.userId}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.calendarModalVisible && (
          <CalendarModal
            onOk={!this.state.isAddToPlan ? this.handleChangeDateClick : this.createToPlan}
            onCancel={this.handleCalendarCancel}
            okText={t('add')}
            planningTopicId={this.state.planningTopicId}
            rest={{ planId: this.state.planId }}
            open={this.state.calendarModalVisible}
            disabledSelect={disabledSelect}
            dateEvent={dayjs(this.state.startDate).format('D MMMM YYYY')}
            title={!disabledSelect ? t('choosePlan') : t('planning')}
            topicInfo={this.state.topicInfo}
            eventTypeId={this.state.planningTopicType && this.state.planningTopicType.id}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 20 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 8 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            hasEvents={this.state.topicInfo && this.state.topicInfo.futureEvents}
            selectMonthEvents={
              this.state.planningTopicType &&
              (isCorpCourseWithOrWithoutApprove(this.state.planningTopicType.id) ||
                this.state.planningTopicType.id === 20)
            }
          />
        )}

        <Modal open={this.state.eventRequest === 'error'} onCancel={this.closeEventRequest} width={400} hideFooter>
          <div className={classNames(css['App-card-item'], css['Planning-topic-event'])}>
            <div className={css['Planning-topic-event-text']}>
              {t('oops')}
              <br /> {t('statusMaterial')}
            </div>
            <Button onClick={this.closeEventRequest} type='primary'>
              {t('understand')}
            </Button>
          </div>
        </Modal>
        <Modal open={this.state.eventRequest === 'noVisit'} onCancel={this.closeEventRequest} width={400} hideFooter>
          <div className={classNames(css['App-card-item'], css['Planning-topic-event'])}>
            <div className={css['Planning-topic-event-text']}>Отсутствие в курсе успешно отмечено.</div>
            <Button onClick={this.closeEventRequest} type='primary'>
              {t('ok')}
            </Button>
          </div>
        </Modal>
        <Modal
          open={this.state.eventRequest === 'errorMention'}
          onCancel={this.closeEventRequest}
          width={400}
          hideFooter
        >
          <div className={classNames(css['App-card-item'], css['Planning-topic-event'])}>
            <div className={css['Planning-topic-event-text']}>{t('before')}</div>
            <Button onClick={this.closeEventRequest} type='primary'>
              {t('understand')}
            </Button>
          </div>
        </Modal>
        <Modal
          open={this.state.eventRequest === 'refuse' || this.state.eventRequest === 'confirm'}
          onCancel={this.closeEventRequest}
          width={400}
          hideFooter
        >
          <div className={classNames(css['App-card-item'], css['Planning-topic-event'])}>
            <div className={css['Planning-topic-event-text']}>
              {t('invitation')}
              {this.props.event
                ? this.state.eventRequest === 'refuse'
                  ? t('rejected')
                  : this.state.eventRequest === 'confirm'
                    ? t('approvedSuccessfully')
                    : null
                : null}
            </div>
            <Button onClick={this.closeEventRequest} type='primary'>
              {t('ok')}
            </Button>
          </div>
        </Modal>
        <Modal open={this.state.topicModalVisible} onCancel={this.toggleTopicModalVisibility} width={600} hideFooter>
          <div className={classNames(css['App-card-item'], css['Planning-topic-modal'])}>
            <div className={css['Planning-topic-modal-header']}>{t('addMaterialToPlan')}</div>
            <Button.Group className={css['App-btn-group']}>
              {!this.props.externalLogin && (
                <Button onClick={this.showTopicAddModal} className={css['App-btn']} type='primary'>
                  {t('addNew')}
                </Button>
              )}
              <Button onClick={this.showMyCollectionModal} className={css['App-btn']} type='primary'>
                {t('find')}
              </Button>
            </Button.Group>
          </div>
        </Modal>

        {this.state.topicAddModalVisible ? (
          <TopicAddModal visible onTopicAddSuccess={this.handleTopicAddSuccess} onCancel={this.hideTopicModals} />
        ) : null}

        <MyCollectionModal
          externalLogin={this.props.externalLogin}
          visible={this.state.myCollectionModalVisible}
          onTopicSelect={this.handleTopicAddSuccess}
          onCancel={this.hideTopicModals}
        />
        {participationConfirmationModal}
        <FloatButton.BackTop>
          <div className={css['App-content-slide']}>
            <div className={css['App-content-slide-size']}>
              <svg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
                <g fill='none'>
                  <circle cx='32' cy='32' r='32' />
                  <path
                    d='M33 25.037V41a1 1 0 0 1-2 0V25.037l-5.269 5.645a1 1 0 0 1-1.462-1.364l7-7.5a1 1 0 0 1 1.462 0l7 7.5a1 1 0 0 1-1.462 1.364z'
                    fill='#FFF'
                  />
                </g>
              </svg>
            </div>
          </div>
        </FloatButton.BackTop>
      </Spin>
    );
  }
}

const mapStateToProps = createSelector(
  [studuPlanSelect, usersSelect, loginSelect, courseSelect],
  (studyPlan, users, login, course) => ({
    studyPlan: studyPlan.studyPlan || [],
    studyPlanPageNumber: studyPlan.studyPlanPageNumber || 0,
    studyPlanPageSize: studyPlan.studyPlanPageSize,
    studyPlanTotal: studyPlan.studyPlanTotal,
    viewPlan: studyPlan.view,
    studyPlanFirstMonth: studyPlan.firstMonth,
    externalLogin: login.externalLogin,
    studyPlan22: studyPlan,
    isLoading: studyPlan.loading?.studyPlan?.studyPlan,
    isLoadingFinished: studyPlan.loading?.studyPlan.studyPlanFinished,
    currentUserId: users.currentUser && users.currentUser.id,
    anotherUserId: users.anotherUser && users.anotherUser.id,
    event: course.event,
    coursesCost: course.coursesCost,
  })
);

const mapActionsToProps = {
  eventRequestConfirm,
  clearPlan,
  checkTopicComment,
  eventRequestFinish,
  eventRequestRefuse,
  getStudyPlan,
  showSendReviewModal,
  getStudyPlanDinamic,
  changeViewPage,
  getStudyPlanFirstMonth,
  createStudyPlan,
  getApprovedCoursesCost,
  updateStudyPlanStatus,
  updateStudyPlanDate,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withTranslation(['planning', 'constants']),
  withOutletContext
)(Planning);
