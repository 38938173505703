// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-ASuYwD.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-m3ctBv .user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-label-OSKmLl[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-ASuYwD.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-m3ctBv .user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-item-FkcmcN[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-Montserrat-bHbIlx {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-oiCo4K {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 200px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-modal-tmA7Cp {
  width: 420px !important;
}

@media (max-width: 992px) {
  .user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-oiCo4K {
    width: auto;
    margin: 0;
  }
}

.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__text-Zs9o8X {
  text-align: center;
  font-size: 11pt;
}

@media (max-width: 992px) {
  .user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__text-Zs9o8X {
    font-size: 16pt;
  }
}

.user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__button-fncgL_ {
  background-color: var(--personalColor);
  border-color: var(--personalColor);
  border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/test/Poll/Poll.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,uBAAuB;AACzB;;AAKA;EACE;IAFE,WAAW;IACX,SAAS;EACX;AACF;;AAIA;EADE,kBAAkB;EAClB,eAAe;AACjB;;AAGA;EACE;IAAE,eAAe;EACjB;AACF;;AAEA;EACE,sCAAsC;EACtC,kCAAkC;EAClC,mBAAmB;AACrB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.modal-poll {\n  width: 400px;\n  height: 200px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n}\n.modal-poll-modal {\n  width: 420px !important;\n}\n@media (max-width: 992px) {\n  .modal-poll {\n    width: auto;\n    margin: 0;\n  }\n}\n.modal-poll__text {\n  font-size: 11pt;\n  text-align: center;\n}\n@media (max-width: 992px) {\n  .modal-poll__text {\n    font-size: 16pt;\n  }\n}\n.modal-poll__button {\n  background-color: var(--personalColor);\n  border-color: var(--personalColor);\n  border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-ASuYwD`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-ASuYwD`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-m3ctBv`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-m3ctBv`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-label-OSKmLl`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-label-OSKmLl`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-item-FkcmcN`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-picker-item-FkcmcN`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-Montserrat-bHbIlx`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__ql-font-Montserrat-bHbIlx`,
	"modal-poll": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-oiCo4K`,
	"modalPoll": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-oiCo4K`,
	"modal-poll-modal": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-modal-tmA7Cp`,
	"modalPollModal": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll-modal-tmA7Cp`,
	"modal-poll__text": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__text-Zs9o8X`,
	"modalPollText": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__text-Zs9o8X`,
	"modal-poll__button": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__button-fncgL_`,
	"modalPollButton": `user-src-pages-CollectionMaterialsIDStudy-test-Poll-Poll-module__modal-poll__button-fncgL_`
};
export default ___CSS_LOADER_EXPORT___;
