import { API_D360_URL } from '../api_service';
import { D360_LOAD_PROJECTS_FAIL, D360_LOAD_PROJECTS_START, D360_LOAD_PROJECTS_SUCCESS } from './constants';

import { GET, REST_API } from '@shared/constants/rest-api';

const headers = { Accept: '*/*', 'Content-Type': 'application/json' };
const defaultToken = '';

export const D360LoadProjects = () => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_D360_URL}/employee/projects`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [D360_LOAD_PROJECTS_START, D360_LOAD_PROJECTS_SUCCESS, D360_LOAD_PROJECTS_FAIL],
  };
};
