// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-Comments-Comments-module__ql-picker-plA5LI.user-src-components-Comments-Comments-module__ql-font-TJpvaz .user-src-components-Comments-Comments-module__ql-picker-label-Hxm3uv[data-value="Montserrat"]:before, .user-src-components-Comments-Comments-module__ql-picker-plA5LI.user-src-components-Comments-Comments-module__ql-font-TJpvaz .user-src-components-Comments-Comments-module__ql-picker-item-h6wfjn[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-Comments-Comments-module__ql-font-Montserrat-_vOTBM {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-Comments-Comments-module__comments-Ovv_Tq {
  padding: 16px;
}

@media (max-width: 992px) {
  .user-src-components-Comments-Comments-module__comments-Ovv_Tq {
    padding: 1em 0 1em 1em;
  }
}

.user-src-components-Comments-Comments-module__comments__create-g74RQZ {
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  display: flex;
}

.user-src-components-Comments-Comments-module__comments__list-uMo5Jr {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.user-src-components-Comments-Comments-module__comments__spin-ZIQzte {
  justify-content: center;
  display: flex;
}

.user-src-components-Comments-Comments-module__comments__button-lx5V91 {
  align-self: flex-end;
}

@media (max-width: 992px) {
  .user-src-components-Comments-Comments-module__comments__button-lx5V91 {
    width: 12em;
    font-size: 1em;
  }
}

.user-src-components-Comments-Comments-module__comments__comment-bpNur3 {
  gap: 20px;
  display: flex;
}

.user-src-components-Comments-Comments-module__comments__textarea-eS2Jbf {
  background-color: #f5f5f5;
  width: calc(100% - 60px);
  padding: 10px 18px;
}

@media (max-width: 992px) {
  .user-src-components-Comments-Comments-module__comments__textarea-eS2Jbf {
    width: calc(100% - 1em - 46px);
    max-width: none;
    padding: .25em .5em;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/Comments/Comments.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,aAAa;AACf;;AAMA;EACE;IAHE,sBAAsB;EACxB;AACF;;AAKA;EAFE,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,aAAa;AACf;;AAIA;EADE,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAGA;EAAE,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,oBAAoB;AACtB;;AACA;EACE;IAEE,WAAW;IACX,cAAc;EAChB;AACF;;AAAA;EAGE,SAAS;EACT,aAAa;AACf;;AADA;EAIE,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;AACpB;;AAFA;EACE;IAKE,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;EACrB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.comments {\n  padding: 16px;\n}\n@media (max-width: 992px) {\n  .comments {\n    padding: 1em 0 1em 1em;\n  }\n}\n.comments__create {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  margin-bottom: 40px;\n}\n.comments__list {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.comments__spin {\n  display: flex;\n  justify-content: center;\n}\n.comments__button {\n  align-self: flex-end;\n}\n@media (max-width: 992px) {\n  .comments__button {\n    width: 12em;\n    font-size: 1em;\n  }\n}\n.comments__comment {\n  display: flex;\n  gap: 20px;\n}\n.comments__textarea {\n  background-color: #f5f5f5;\n  padding: 10px 18px;\n  width: calc(100% - 60px);\n}\n@media (max-width: 992px) {\n  .comments__textarea {\n    max-width: none;\n    width: calc(100% - 1em - 46px);\n    padding: 0.25em 0.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-Comments-Comments-module__ql-picker-plA5LI`,
	"qlPicker": `user-src-components-Comments-Comments-module__ql-picker-plA5LI`,
	"ql-font": `user-src-components-Comments-Comments-module__ql-font-TJpvaz`,
	"qlFont": `user-src-components-Comments-Comments-module__ql-font-TJpvaz`,
	"ql-picker-label": `user-src-components-Comments-Comments-module__ql-picker-label-Hxm3uv`,
	"qlPickerLabel": `user-src-components-Comments-Comments-module__ql-picker-label-Hxm3uv`,
	"ql-picker-item": `user-src-components-Comments-Comments-module__ql-picker-item-h6wfjn`,
	"qlPickerItem": `user-src-components-Comments-Comments-module__ql-picker-item-h6wfjn`,
	"ql-font-Montserrat": `user-src-components-Comments-Comments-module__ql-font-Montserrat-_vOTBM`,
	"qlFontMontserrat": `user-src-components-Comments-Comments-module__ql-font-Montserrat-_vOTBM`,
	"comments": `user-src-components-Comments-Comments-module__comments-Ovv_Tq`,
	"comments__create": `user-src-components-Comments-Comments-module__comments__create-g74RQZ`,
	"commentsCreate": `user-src-components-Comments-Comments-module__comments__create-g74RQZ`,
	"comments__list": `user-src-components-Comments-Comments-module__comments__list-uMo5Jr`,
	"commentsList": `user-src-components-Comments-Comments-module__comments__list-uMo5Jr`,
	"comments__spin": `user-src-components-Comments-Comments-module__comments__spin-ZIQzte`,
	"commentsSpin": `user-src-components-Comments-Comments-module__comments__spin-ZIQzte`,
	"comments__button": `user-src-components-Comments-Comments-module__comments__button-lx5V91`,
	"commentsButton": `user-src-components-Comments-Comments-module__comments__button-lx5V91`,
	"comments__comment": `user-src-components-Comments-Comments-module__comments__comment-bpNur3`,
	"commentsComment": `user-src-components-Comments-Comments-module__comments__comment-bpNur3`,
	"comments__textarea": `user-src-components-Comments-Comments-module__comments__textarea-eS2Jbf`,
	"commentsTextarea": `user-src-components-Comments-Comments-module__comments__textarea-eS2Jbf`
};
export default ___CSS_LOADER_EXPORT___;
