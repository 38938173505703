/* eslint-disable */
import React, { Component } from 'react';

import { Tooltip } from 'antd';

import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../MaterialsTabs/MaterialsTabs.module.scss';

import { FILE_IMAGE } from '@/constants/file-image';

export default class FileItem extends Component {
  static propTypes = {
    file: PropTypes.object,
    getFile: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      btnClick: false,
    };
  }

  UNSAFE_componentWillMount() {}

  exportFileOffline = () => {
    const blob = this.props.file.blob;
    let url = window.URL.createObjectURL(blob);
    let anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'DiagnosticResult';
    anchor.click();
  };

  exportFile = () => {
    if (!window.navigator.onLine) {
      this.exportFileOffline();
      return;
    }
    this.setState({ btnClick: true });
    const validateName = this.props.file.name.replace(/[^A-Za-zА-Яа-яЁё0-9 ]/g, '');
    const format = this.props.file.type.toLowerCase();
    const validName = `${validateName.replace(new RegExp(`${format}$`), '')}.${format}`;
    if (this.props.file.uuid) {
      this.props.getFile(this.props.file.uuid, res => {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, validName);
        }
        const objectURL = URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        tempLink.style = 'display: none';
        tempLink.href = objectURL;
        tempLink.download = validName;
        document.body.appendChild(tempLink);
        tempLink.click();
        setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(objectURL);
        }, 1000);
        this.setState({ btnClick: false });
      });
    }
  };

  bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  };

  render() {
    const { file } = this.props;

    const fileType = file.type && FILE_IMAGE.find(type => type.type === file.type);
    const fileImage = fileType ? <img src={fileType.image} alt='' /> : null;

    const fileName =
      file.name && file.name.length > 40 ? (
        <Tooltip title={file.name}>
          <div
            className={classNames(css['FileItem-name'], {
              [css['FileItem-name-disabled']]: this.state.btnClick,
            })}
            onClick={this.exportFile}
          >
            {`${file.name.substring(0, 40)}...${file.type.toLowerCase()}`}
          </div>
        </Tooltip>
      ) : (
        <div
          className={classNames(css['FileItem-name'], {
            [css['FileItem-name-disabled']]: this.state.btnClick,
          })}
          onClick={this.exportFile}
        >
          {file.name}
        </div>
      );

    return (
      <div className={css.FileItem}>
        <div className={css['FileItem-block']}>
          <div className={css['FileItem-image']}>{fileImage}</div>
          {fileName}
        </div>
        <div className={css['FileItem-info']}>
          <div className={css['FileItem-size']}>{this.bytesToSize(file.size)}</div>
          <div className={css['FileItem-date']}>{dayjs(file.createdDate).format('DD.MM.YYYY')}</div>
        </div>
      </div>
    );
  }
}
