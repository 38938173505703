/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const GET_TAGS_START = 'GET_TAGS_START';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
export const POST_TAG_START = 'POST_TAG_START';
export const POST_TAG_SUCCESS = 'POST_TAG_SUCCESS';
export const POST_TAG_FAIL = 'POST_TAG_FAIL';
export const DELETE_TAG_START = 'DELETE_TAG_START';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
export const SEARCH_TAGS_START = 'SEARCH_TAGS_START';
export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';
export const SEARCH_TAGS_FAIL = 'SEARCH_TAGS_FAIL';
export const GET_ANOTHER_TAGS_START = 'GET_ANOTHER_TAGS_START';
export const GET_ANOTHER_TAGS_SUCCESS = 'GET_ANOTHER_TAGS_SUCCESS';
export const GET_ANOTHER_TAGS_FAIL = 'GET_ANOTHER_TAGS_FAIL';
export const GET_TAG_NAME_START = 'GET_TAG_NAME_START';
export const GET_TAG_NAME_SUCCESS = 'GET_TAG_NAME_SUCCESS';
export const GET_TAG_NAME_FAIL = 'GET_TAG_NAME_FAIL';

const headers = {
  Accept: 'application/json',
};

const defaultToken = '';

export const getTags = (isAnotherUser, userId, page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${encodeURI(userId)}/tag${getQueryString({ page })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  userId,
  types: !isAnotherUser
    ? [GET_TAGS_START, GET_TAGS_SUCCESS, GET_TAGS_FAIL]
    : [GET_ANOTHER_TAGS_START, GET_ANOTHER_TAGS_SUCCESS, GET_ANOTHER_TAGS_FAIL],
});

export const getTagName = (tagId, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/tag/${tagId}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TAG_NAME_START, GET_TAG_NAME_SUCCESS, GET_TAG_NAME_FAIL],
  onSuccess,
});

export const searchTags = (name = '', page = 0) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/tag${getQueryString({
    name: name && name.toLowerCase(),
    page,
    sort: 'name,asc',
  })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [SEARCH_TAGS_START, SEARCH_TAGS_SUCCESS, SEARCH_TAGS_FAIL],
});

export const deleteTag = (id = 0, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/user/tag/${encodeURI(id)}`,
  id,
  responseType: 'text',
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_TAG_START, DELETE_TAG_SUCCESS, DELETE_TAG_FAIL],
  onSuccess,
});

export const createTag = (userId, name = '', onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/${encodeURI(userId)}/tag/${encodeURI(name && name.toLowerCase())}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_TAG_START, POST_TAG_SUCCESS, POST_TAG_FAIL],
  onSuccess,
});

export const createMultipleTags = (userId, names = '', onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/user/${encodeURI(userId)}/tag${getQueryString({ names })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [POST_TAG_START, POST_TAG_SUCCESS, POST_TAG_FAIL],
  onSuccess,
});
