/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectNotifications } from '@/store/notifications-new/selectors';

import { Divider, Layout, Skeleton } from 'antd';
import MarkdownViewer from '@/components/MarkdownViewer';

import classNames from 'classnames';
import dayjs from 'dayjs';

import css from '../MyNotifications.module.scss';

const { Content } = Layout;

const ModalNotificationById = () => {
  const { t } = useTranslation('myNotification');
  const { notification, isNotificationByIdLoading } = useSelector(selectNotifications);

  return (
    <Content
      className={classNames([css['myNotification'], notification.content && css['myNotification-with-content']])}
    >
      <div className={css['myNotification--modal']}>
        <h1>{t('titleByOne')}</h1>

        {isNotificationByIdLoading ? (
          <div className={css['myNotification--modal__skeleton']}>
            <Skeleton active />
          </div>
        ) : (
          <div className={css['myNotification--modal-block']}>
            <div className={css['myNotification--modal-block__desc']}>
              <span>{t('nameSubject')}</span> <br />
              {notification.subject}
            </div>

            <Divider />

            <MarkdownViewer
              children={notification.content}
              className={css['myNotification--modal-block__iframe']}
              ignoreSanitize={notification.type !== 'CUSTOM'}
            />

            <Divider />

            {notification.url && (
              <div>
                <a className={css['myNotification--modal-block__link']} href={notification.url}>
                  {t('openBtn')}
                </a>
              </div>
            )}

            <div className={css['myNotification--modal-block__time']}>
              {t('time')}
              <span>{dayjs(notification.timestamp).format('DD-MM-YYYY, HH:mm')}</span>
            </div>
          </div>
        )}
      </div>
    </Content>
  );
};

export default ModalNotificationById;
