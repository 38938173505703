/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  addNewGroup,
  addNewMessage,
  addNewMessageInChat,
  addNewPersonalGroup,
  checkNewMessage,
  deleteChatGroup,
  readMessagesInChat,
  setSelectedUser,
  updateChatGroupInfo,
} from '@/store/chat/actions';
import { getFile } from '@/store/files/actions';
import { getUserIncome } from '@/store/income/actions';
import { logout } from '@/store/login/actions';
import { clearSearchParams, clearSearchText, clearSearchUserText } from '@/store/search/actions';
import { clearTopicFilter, clearTopics } from '@/store/topics/actions';
import { clearUserTopicFilter, setAvaToCache } from '@/store/user-topics/actions';

import { selectBadge } from '@/store/badge/selectors';
import { selectChat } from '@/store/chat/selectors';
import { selectFiles } from '@/store/files/selectors';
import { selectLogin } from '@/store/login/selectors';
import { selectNotifications } from '@/store/notifications-new/selectors';
import { selectUserTopics } from '@/store/user-topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Badge, Dropdown, Flex, Layout, Menu, Row, Spin, Tooltip } from 'antd';
import { Avatar } from '@/components';
import BadgeElement from '@/components/BadgeElement';
import BadgeModal from '@/components/BadgeModal';
import { MenuBurger } from '@/components/BurgerMenu';
import { CoinsIcon } from '@/components/elements';
import { useNotificationTypes } from '@/components/NotificationTypes';
import { ThunderboltOutlined } from '@ant-design/icons';
import { BsChat } from 'react-icons/bs';
import { CgLogOut } from 'react-icons/cg';
import { FiInbox } from 'react-icons/fi';
import {
  HiOutlineBell,
  HiOutlineCash,
  HiOutlineStar,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
  HiOutlineViewGrid,
} from 'react-icons/hi';
import { IoCalendarOutline } from 'react-icons/io5';
import { VscBell } from 'react-icons/vsc';

import defaultLogo from '../../../projects/mars/src/assets/icons/logo.svg';
import CoinHistoryModal from '../CoinHistoryModal';
import CoinInfoModal from '../CoinInfoModal';
import ModalFlower from '../ModalFlower';
import { absLinkTemplate } from './AbsolutLinks';
import TradeLink from './TradeLink';

import { clearStorage } from '@/utils/clearStorage';
import { domainCompanyid } from '@/utils/domainCompanyId';
import { unsubscribeDevice } from '@/utils/pushManager';
import { TopicsFilterTypes } from '@/utils/topics-collection-context';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';
import { isNotContain } from '@shared/utils/permissionUtils';

import { d360Projects, diagnostic, diagnosticPolus, diagnosticTalentQ } from '@/router/routes';
import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import css from './Header.module.scss';

import { ACCESS_TO_ADMIN_FROM_USERS, ACCESS_TO_USERS_MYPOLLS } from '@/constants/permissions';
import logoDefault from '@/assets/images/logo_default.png';

const { Header } = Layout;

const MAX_LENGTH_NAME = 17;
const DOMAIN_TOCHKA = 'tochka.kampus.team';
const URL_SOFT_SKILL_TOCHKA = 'https://soft-skills.tochka.com/edu';
const HIDE_SUBCOMPANY_IDS = [1401, 6952]; // 6952 - Лабмедиа

export const AppHeader = ({
  // domainCompany,
  // currentUser,
  // getPublicFile,
  // getFile,
  // getUserIncome,
  // externalLogin,
  // externalLogout,
  // clearTopicFilter,
  // clearSearchText,
  // clearSearchParams,
  // clearUserTopicFilter,
  // clearSearchUserText,
  // cachedFiles,
  // setAvaToCache,
  openBurgerMenu,
  setOpenBurgerMenu,
  // notifications,
  // isNotificationsLoading,
  socket,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [coinInfoModalVisible, setCoinInfoModalVisible] = useState(false);
  const [coinHistoryModalVisible, setCoinHistoryModalVisible] = useState(false);
  const [badgeModalVisible, setBadgeModalVisible] = useState(false);
  const [modalFlower, setModalFlower] = useState(false);
  const [activeTopMenuKeys, setActiveTopMenuKeys] = useState('');
  const [allTradeLinks, setAllTradeLinks] = useState([]);

  // const { cacheFiles } = useSelector(selectFiles);
  const { currentUser } = useSelector(selectUsers);
  // const { externalLogin } = useSelector(selectLogin);
  // const { cachedFiles } = useSelector(selectUserTopics);
  const { unReadNotifications, isUnReadNotificationLoading } = useSelector(selectNotifications);
  const { selectedUser, countNewMessage } = useSelector(selectChat);
  const { badges } = useSelector(selectBadge);

  const [visible, setVisible] = useState(false);
  const { t } = useTranslation('header');

  const menuId = 'main-menu';
  const isBidzaar = currentUser?.domainCompany?.id === domainCompanyid.BIDZAAR_ID;
  const isAbsolute = currentUser?.domainCompany?.id === domainCompanyid.ABSOLUTE_ID;

  const [topBanner] = useLocalFileByUUID(currentUser?.domainCompany?.banner?.uuid, {
    isDefaultNull: true,
  });

  // const [imageLogo, isLoadingImageLogo] = useLocalFileByUUID(currentUser?.domainCompany?.logo, {
  //   defaultImg: logoDefault,
  //   isDefaultNull: true,
  // });

  const DIAGNOSTICS = [
    { id: 1, key: diagnostic.path, name: 'diagnostic' },
    { id: 2, key: d360Projects.path, name: 'diagnostic_d360' },
    { id: 3, key: diagnosticPolus.path, name: 'diagnostic_polus' },
    { id: 4, key: diagnosticTalentQ.path, name: 'diagnostic_gpm' },
  ];
  const DIAGNOSTIC_POLUS = 3;
  const [isMobile] = useIsMobile(1024);

  useEffect(() => {
    const currentActiveTopMenuKey = location.pathname === '/library' ? 'Libraruru' : location.pathname;
    setActiveTopMenuKeys(currentActiveTopMenuKey);
  }, [location.pathname]);

  useEffect(() => {
    onHandlerMessage();

    return () => {
      offHandlerMessage();
    };
  }, [selectedUser, location, socket]);

  useEffect(() => {
    if (document.location.pathname !== '/' && !document.location.pathname.includes('collection-topics')) {
      dispatch(clearTopics());
    }
  }, [document.location.pathname]);

  const onHandlerMessage = () => {
    socket?.on('newMessage', newMessage => {
      if (newMessage?.chat_id === selectedUser?.chatId) {
        dispatch(addNewMessageInChat(newMessage));
      } else {
        dispatch(addNewMessage(newMessage));
      }
    });
    socket?.on('newPersonalGroup', newGroup => {
      if (location.pathname.includes('chat/personal')) {
        dispatch(addNewPersonalGroup(newGroup));

        if (selectedUser.id === newGroup.userData.id) {
          dispatch(setSelectedUser({ ...selectedUser, chatId: newGroup.chatId }));
          dispatch(addNewMessageInChat(newGroup?.lastMessage));
        }
      }
    });

    socket?.on('onReadMessages', data => {
      dispatch(readMessagesInChat(data));
    });

    socket?.on('newGroup', newGroup => {
      if (location.pathname.includes('chat/groups')) dispatch(addNewGroup(newGroup));
    });

    socket?.on('deleteGroup', data => {
      if (location.pathname.includes('chat/groups')) dispatch(deleteChatGroup(data));
    });

    socket?.on('updateGroup', newData => {
      if (location.pathname.includes('chat/groups')) dispatch(updateChatGroupInfo(newData));
    });
  };

  const offHandlerMessage = () => {
    socket?.off('newMessage');
    socket?.off('newPersonalGroup');
    socket?.off('newGroup');
    socket?.off('readMessage');
    socket?.off('deleteGroup');
    socket?.off('updateGroup');
  };

  useEffect(() => {
    dispatch(checkNewMessage());
  }, []);

  const handleCancel = () => {
    setCoinInfoModalVisible(false);
    setCoinHistoryModalVisible(false);
  };

  const openCoinInfoModal = () => {
    setCoinInfoModalVisible(true);
  };

  const openCoinHistoryModal = () => {
    setCoinHistoryModalVisible(true);
    dispatch(getUserIncome(currentUser.id, 0));
  };

  const openBadgeModal = () => {
    setBadgeModalVisible(true);
  };

  const onLogOut = () => {
    socket?.disconnect();
    unsubscribeDevice();
    dispatch(
      logout(() => {
        clearStorage();
        navigate('/user-login');
      })
    );
  };

  const handleCloseFlower = () => {
    setModalFlower(false);
  };

  const clearFilterCollection = () => {
    dispatch(clearUserTopicFilter());
    dispatch(clearSearchUserText());
  };

  const myPlanMenuFunc = () => {
    const children = [];

    if (currentUser?.trajectoryEnable) {
      children.push({
        key: '/diagnostic',
        label: t(correctDiagnosticPlan.name),
        onClick: () => {
          handleProfileMenuClick(true);
          navigate(correctDiagnosticPlan.key);
        },
        disabled: !currentUser.domainCompany?.diagnosticType?.id,
      });

      if (currentUser.domainCompany?.diagnosticType?.id === DIAGNOSTIC_POLUS) {
        children.push({
          key: '/diagnostic',
          label: t('diagnostic'),
          onClick: () => {
            handleProfileMenuClick(true);
            navigate('/diagnostic');
          },
        });
      }
    }

    children.push(planningItem);

    if (currentUser?.domainCompany?.hasIpr) {
      children.push(planningIpr);
    }

    return children;
  };

  const avatar = <Avatar className={css['App-avatar']} user={currentUser} small />;

  const planningItem = {
    key: '/planning',
    label: t('planning'),
    onClick: () => {
      handleProfileMenuClick(true);
      navigate('/planning');
    },
  };

  const planningIpr = {
    key: '/career-plan',
    label: t('careerPlan'),
    onClick: () => navigate('/career-plan'),
  };

  const correctDiagnosticPlan = DIAGNOSTICS.find(el => el.id === currentUser?.domainCompany?.diagnosticType?.id);

  const myPlanChildren = myPlanMenuFunc();

  const trajectoryEnableItems =
    myPlanChildren.length === 1
      ? { ...planningItem, label: t('plan') }
      : {
          key: 'mainSubMenu-1',
          label: t('plan'),
          children: myPlanChildren,
        };

  const knowledgeBase = {
    key: '/',
    label: t('collectionTopics'),
    children: [
      {
        key: '/collection-topics',
        label: t('collectionTopics'),
        onClick: () => {
          handleProfileMenuClick(true);
          navigate('/collection-topics');
        },
      },
      {
        key: '/calendar',
        label: t('eventCalendar'),
        onClick: () => {
          handleProfileMenuClick(true);
          navigate('/calendar');
        },
      },
    ],
  };

  const renderAbs = () => {
    return absLinkTemplate.map(item => ({
      key: item.key,
      label: t(item.label),
      children: item.children.map(k => ({
        key: k.childKey,
        label: (
          <Link to={k.to} onClick={() => handleProfileMenuClick(true)}>
            {t(k.childTranslate)}
          </Link>
        ),
      })),
    }));
  };

  useEffect(() => {
    if (currentUser.domainCompany.tradeLinks) {
      const result = currentUser.domainCompany.tradeLinks.map((tradeLink, tradelinkIndex) => {
        if (tradeLink.name && tradeLink.additionalName) {
          return {
            key: tradeLink.additionalName + tradelinkIndex + Symbol('uniq').toString(),
            label: (
              <Tooltip placement='bottom' title={tradeLink.tooltip}>
                <>
                  {tradeLink.url.includes('http') ? (
                    <TradeLink
                      id={tradeLink.id}
                      url={tradeLink.url}
                      name={currentUser.language === 'ru_RU' ? tradeLink.name : tradeLink.additionalName}
                      domain={currentUser.domainCompany.domain}
                      onClick={() => handleProfileMenuClick(true)}
                    />
                  ) : (
                    <Link to={tradeLink.url} onClick={() => handleProfileMenuClick(true)}>
                      {currentUser.language === 'ru_RU' ? tradeLink.name : tradeLink.additionalName}{' '}
                    </Link>
                  )}
                </>
              </Tooltip>
            ),
          };
        }
      });
      setAllTradeLinks(result);
    }
  }, [currentUser.language, currentUser?.domainCompany?.tradeLinks]);

  const firstItem = isBidzaar ? trajectoryEnableItems : knowledgeBase;
  const thirdItem = isBidzaar ? knowledgeBase : trajectoryEnableItems;

  const topMainMenu = [
    firstItem,
    ...(currentUser?.domainCompany?.hasCompilation
      ? [
          {
            key: '/compilations',
            label: t('compilations'),
            onClick: () => {
              handleProfileMenuClick(true);
              navigate('/compilations');
            },
          },
        ]
      : []),
    ...(currentUser?.domainCompany?.hasMentors
      ? [
          {
            key: '/mentors',
            label: t('mentors'),
            onClick: () => {
              clearFilterCollection();
              handleProfileMenuClick(true);
              navigate('/mentors');
            },
          },
        ]
      : []),
    thirdItem,
    ...(currentUser?.domainCompany?.hasShop
      ? [
          {
            key: '/shop',
            label: t('shop'),
            onClick: () => {
              handleProfileMenuClick(true);
              navigate('/shop');
            },
          },
        ]
      : []),
    ...(currentUser?.domainCompany?.domain === DOMAIN_TOCHKA
      ? [
          {
            key: '/dTochka',
            label: t('softSkills'),
            onClick: () => {
              handleProfileMenuClick(false);
              window.open(URL_SOFT_SKILL_TOCHKA, '_blank');
            },
          },
        ]
      : []),
    ...(isAbsolute ? renderAbs() : []),
    ...allTradeLinks,
  ];

  const domain = _.get(currentUser?.domainCompany, 'adminDomain');
  const onClickHandler = useCallback(() => setVisible(!visible), [visible]);
  const [showNotificationContent, changeShowNotificationContent] = useState(false);
  const myTeamDisabled = isMobile || !Utils.isManager(currentUser) || !currentUser?.domainCompany?.showColleagues;

  const goToAdminPage = redirect => {
    let DOMAIN = `https://${domain}`;

    if (document.location.hostname === 'localhost' || document.location.hostname === '127.0.0.1') {
      DOMAIN = 'http://localhost:3001';
    }

    const URL = `${DOMAIN}/login-through?accessToken=${localStorage.getItem('authorizationToken')}&redirect=${redirect}`;
    window.open(URL, '_blank');
  };

  const onMyTeamClick = () => {
    handleProfileMenuClick();
    if (Utils.isManager(currentUser)) {
      goToAdminPage('/');
      return;
    }
    navigate('/collegues');
  };

  const dropdownMenu = [
    ...(currentUser?.authorities.includes(ACCESS_TO_ADMIN_FROM_USERS) && !isMobile
      ? [
          {
            key: 0,
            disabled: !currentUser?.authorities.includes(ACCESS_TO_ADMIN_FROM_USERS),
            icon: <HiOutlineViewGrid />,
            onClick: () => {
              handleProfileMenuClick();
              goToAdminPage('/');
            },
            label: Utils.isManager(currentUser) ? t('managerOffice') : t('admin'),
          },
          { type: 'divider' },
        ]
      : []),

    {
      key: 1,
      icon: <HiOutlineUserCircle />,
      onClick: () => {
        handleProfileMenuClick();
        navigate('/my-profile');
      },
      label: t('myProfile'),
    },
    {
      key: 2,
      icon: <FiInbox />,
      onClick: () => {
        handleProfileMenuClick();
        navigate('/news');
      },
      label: t('news'),
    },
    ...(currentUser?.domainCompany?.hasCoins
      ? [
          {
            key: 3,
            icon: <HiOutlineCash />,
            onClick: () => {
              handleProfileMenuClick();
              openCoinHistoryModal();
            },
            label: t('myCoins'),
          },
          {
            key: 4,
            icon: <ThunderboltOutlined />,
            onClick: () => {
              handleProfileMenuClick();
              openBadgeModal();
            },
            label: t('badge'),
          },
        ]
      : []),

    // {
    //   key: 5,
    //   icon: <HiOutlineBell />,
    //   onClick: () => {
    //     handleProfileMenuClick();
    //     navigate('/my-notifications');
    //   },
    //   className: 'menuOverflowChange',
    //   label: (
    //     <Badge
    //       className={css['Dropdown__notifications-bell']}
    //       count={unReadNotifications?.length ? unReadNotifications.length : 0}
    //       overflowCount={99}
    //       offset={[5, -7]}
    //     >
    //       {t('notifications')}
    //     </Badge>
    //   ),
    // },
    ...(currentUser.domainCompany?.hasChat
      ? [
          {
            key: 6,
            icon: <BsChat />,
            onClick: () => {
              handleProfileMenuClick();
              navigate('/chat/personal');
            },
            label: t('chat'),
          },
        ]
      : []),
    // ...(!isMobile && currentUser?.domainCompany?.id && !HIDE_SUBCOMPANY_IDS.includes(currentUser.domainCompany.id)
    //   ? [
    //       {
    //         key: 7,
    //         disabled: isNotContain(currentUser, ACCESS_TO_USERS_MYPOLLS),
    //         icon: <HiOutlineUserCircle />,
    //         onClick: () => {
    //           handleProfileMenuClick();
    //           navigate('/d360');
    //         },
    //         label: t('myPolls'),
    //       },
    //     ]
    //   : []),
    {
      key: 8,
      icon: <HiOutlineStar />,
      onClick: () => {
        handleProfileMenuClick();
        navigate('/my-collection');
      },
      label: t('favorites'),
    },
    // Отображаем меню Коллеги если установлен флаг domainCompany.showColleagues
    ...(!myTeamDisabled
      ? [
          {
            key: 9,
            icon: <HiOutlineUserGroup />,
            onClick: onMyTeamClick,
            label: t('myColleagues'),
          },
        ]
      : []),
    {
      type: 'divider',
    },
  ];

  const dropdownMenuExit = {
    key: 10,
    icon: <CgLogOut />,
    onClick: onLogOut,
    label: t('exit'),
  };

  const mobileMenu = [
    {
      key: 'profile',
      className: css['App-mobile-header-dropdown-item'],
      onClick: () => navigate('/my-profile'),
      label: (
        <span className={css['App-personal-info']}>
          <div className={classNames(css['App-personal-name'])} id='App-personal-name'>
            {currentUser &&
              currentUser.firstName &&
              currentUser.lastName &&
              `${currentUser.firstName} ${currentUser.lastName}`}
            {currentUser?.domainCompany?.hasCoins && (
              <div className={classNames(css['App-personal-coins'])}>
                <div className={css['App-personal-coins-icon']}>
                  <CoinsIcon uuidCoinsIcon={currentUser.domainCompany.coinIcon} />
                </div>
                {_.isNumber(currentUser.account) ? currentUser.account : ''}
              </div>
            )}
          </div>
        </span>
      ),
    },
    {
      type: 'divider',
    },
    ...dropdownMenu,
    ...topMainMenu,
    {
      type: 'divider',
    },
    dropdownMenuExit,
  ];

  const mobileMenuBurger = (
    <Menu
      className={css['App-mobile-header-mainmenu']}
      mode={'inline'}
      items={mobileMenu}
      onClick={() => setOpenBurgerMenu(false)}
    />
  );

  const notificationContent = (
    <div className={css['Notification-content']}>
      {unReadNotifications?.length > 0 ? (
        <>
          {unReadNotifications.slice(0, 10).map((itemNotification, index) => {
            const { IconNotification } = useNotificationTypes({
              type: itemNotification.groupTemplateType,
              className: css['Notification-content__item--icon'],
              t,
            });
            return (
              <div
                onClick={() => {
                  navigate(`/my-notifications?modal_id=${itemNotification.id}`);
                  changeShowNotificationContent(false);
                }}
                className={css['Notification-content__item']}
                key={'itemNotification' + index}
              >
                <IconNotification />
                <div className={css['Notification-content__item-block']}>
                  <div className={css['Notification-content__item-block--text']}>
                    {!!itemNotification?.subject && itemNotification?.subject.length > 40
                      ? itemNotification?.subject.slice(0, 37) + '...'
                      : itemNotification?.subject}
                  </div>

                  <div className={css['Notification-content__item-block--time']}>
                    {dayjs(itemNotification.timestamp).fromNow(true)}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={classNames(css['Notification-content__item'], css['Notification-content--noNew'])}>
          {t('noNewNotification')}
        </div>
      )}
      <div
        onClick={() => {
          navigate('/my-notifications');
          changeShowNotificationContent(false);
        }}
        className={css['Notification-content__item--last']}
        key={'itemNotification-default'}
        align='center'
      >
        {t('showAll')}
      </div>
    </div>
  );

  const handleProfileMenuClick = (topMenu = false) => {
    if (!topMenu) {
      onClickHandler();
    }
    dispatch(clearTopicFilter());
    dispatch(clearSearchText());
    dispatch(clearSearchParams());
  };

  const renderName = () => {
    if (currentUser?.firstName?.length + currentUser?.lastName?.length > MAX_LENGTH_NAME) {
      return `${currentUser.firstName[0]}. ${currentUser?.lastName}`;
    } else {
      return `${currentUser?.firstName} ${currentUser?.lastName}`;
    }
  };

  const renderBadges = () => {
    if (badges?.length > 3) {
      return (
        <Flex>
          {badges?.slice(0, 3)?.map((elem, index) => (
            <div key={index} className={css['App-personal-badge-icon']}>
              <BadgeElement key={index} small data={elem} />
            </div>
          ))}
          <div className={css['App-personal-badge-icon']}>
            <div className={css['App-personal-badge-count']}>{badges.slice(3).length}</div>
          </div>
        </Flex>
      );
    } else {
      return badges?.map((elem, index) => (
        <div key={index} className={css['App-personal-badge-icon']}>
          <BadgeElement small data={elem} />
        </div>
      ));
    }
  };

  return (
    <div>
      <Layout>
        <Header id='HeaderMobile' className={css['App-mobile']}>
          {openBurgerMenu && (
            <div className={css['App-mobile-header-shadow']} onClick={() => setOpenBurgerMenu(false)}>
              Shadow
            </div>
          )}
          <Row className={css['App-mobile-container']} type='flex' justify='space-between' align='middle'>
            <Link to='/planning' className={css['App-mobile-header-link']}>
              <img className={css['App-mobile-header-logo']} src={defaultLogo} />
            </Link>

            <div className={css['App-mobile-header-dropdown']}>{avatar}</div>
            <MenuBurger open={openBurgerMenu} id={menuId} content={mobileMenuBurger} />
          </Row>
        </Header>

        <Header className={css['App-header']}>
          <Flex justify='space-between' align='end' className={css['App-header-content']}>
            <Flex
              justify='flex-start'
              align='center'
              className={classNames(css['App-header-item__first'], isAbsolute && css['App-header-item__first__abs'])}
            >
              <Link to='/collection-topics' className={css['App-header-link']}>
                <img className={css['App-logo']} src={defaultLogo} />
              </Link>

              <Menu
                items={topMainMenu}
                selectedKeys={activeTopMenuKeys}
                className={css['App-header-item__menu']}
                mode='horizontal'
              />
            </Flex>
            <Flex justify='flex-end' align='center' className={css['App-header-item__end']}>
              <Link align='center' justify='center' to='/calendar' className={css['Chat']}>
                <IoCalendarOutline size={26} />
              </Link>
              {currentUser?.domainCompany?.hasChat && (
                <Link align='center' justify='center' to='/chat/personal' className={css['Chat']}>
                  <BsChat size={26} />
                  {countNewMessage !== 0 && (
                    <div
                      className={classNames(
                        css['Chat__icon'],
                        currentUser?.domainCompany?.id &&
                          domainCompanyid.ALM_ID.includes(currentUser.domainCompany.id) &&
                          css['Chat__icon__alm']
                      )}
                    ></div>
                  )}
                </Link>
              )}
              <Spin size='small' spinning={isUnReadNotificationLoading}>
                <Dropdown
                  dropdownRender={() => notificationContent}
                  placement='bottom'
                  trigger={['click']}
                  onOpenChange={function (event) {
                    changeShowNotificationContent(!showNotificationContent);
                  }}
                  open={showNotificationContent}
                  className={css['Notification']}
                  dropdownAlign={{ offset: [50, 50] }}
                >
                  <Badge
                    className={css['Notification--bell']}
                    count={unReadNotifications?.length ? unReadNotifications.length : 0}
                    overflowCount={99}
                  >
                    <VscBell className={css['Notification--bell__icon']} />
                  </Badge>
                </Dropdown>
              </Spin>
              <Dropdown
                menu={{
                  items: [...dropdownMenu, dropdownMenuExit],
                  onClick: handleProfileMenuClick,
                }}
                placement='bottomRight'
                trigger={['click']}
                onOpenChange={() => setVisible(false)}
                open={visible}
                overlayClassName={css['Dropdown-menu']}
              >
                <div className={css['App-personal']} onClick={onClickHandler} style={{ cursor: 'pointer' }}>
                  {avatar}
                  <span className={css['App-personal-info']}>
                    <div className={classNames(css['App-personal-name'])} id='App-personal-name' data-qa='userBlock'>
                      {renderName()}
                      {currentUser?.domainCompany && currentUser.domainCompany.hasCoins && (
                        <div className={classNames(css['App-personal-coins'])}>
                          <div className={css['App-personal-coins-icon']}>
                            <CoinsIcon uuidCoinsIcon={currentUser.domainCompany.coinIcon} />
                          </div>
                          {_.isNumber(currentUser.account) ? currentUser.account : ''}
                        </div>
                      )}
                      {currentUser?.domainCompany?.hasCoins && (
                        <div className={css['App-personal-badge']}>{renderBadges()}</div>
                      )}
                    </div>
                  </span>
                </div>
              </Dropdown>
            </Flex>
          </Flex>

          <CoinInfoModal visible={coinInfoModalVisible} onCancel={handleCancel} />

          <CoinHistoryModal open={coinHistoryModalVisible} onCancel={handleCancel} student={currentUser.id} />

          <ModalFlower navigate={navigate} modalFlower={modalFlower} onCancel={handleCloseFlower} />

          <BadgeModal open={badgeModalVisible} onCancel={() => setBadgeModalVisible(false)} />
        </Header>

        {currentUser?.domainCompany?.banner?.showBanner && !location.pathname.includes('chat') && topBanner && (
          <a href={currentUser.domainCompany?.banner?.link} target='_blank' className={css['App--banner']}>
            <img src={topBanner} className={css['App--banner-img']} />
          </a>
        )}
      </Layout>
    </div>
  );
};

export default AppHeader;
