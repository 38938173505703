/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { Spin } from 'antd';

import { PROJECT_STATUS_INACTIVE } from './constants';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

import css from '../css/styles.module.scss';

import DefaultCover from '@/assets/images/360/default.png';

export const ProjectCardCover = props => {
  const [currentCover, isCurrentCoverLoader] = useLocalFileByUUID(props.uuid, { defaultImg: DefaultCover });

  const render = () => {
    return (
      <div
        className={css.ProjectCardCover}
        style={{
          cursor: props.status === PROJECT_STATUS_INACTIVE ? 'default' : 'pointer',
        }}
      >
        <Spin spinning={isCurrentCoverLoader}>
          <img className={css.ProjectCardCoverImage} src={currentCover} alt='' />
        </Spin>
      </div>
    );
  };

  return render();
};

ProjectCardCover.propTypes = {
  uuid: PropTypes.any,
  status: PropTypes.number.isRequired,
  projectId: PropTypes.any,
};

const mapDispatchToProps = {
  getFile,
};

export default connect(null, mapDispatchToProps)(ProjectCardCover);
