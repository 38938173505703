/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { checkPreviousStudyPlans, getUserTopicStudyPlanStatus } from '@/store/study-plan/actions';

import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';

import { Button, message, Tooltip } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import AudienceConfirmationModal from '../ModalTargetAudience/Modal';
import Rate from '../Rate';
import TypeBadge from '../TypeBadge';
import MaterialCost from './MaterialCost';

import { TOPIC_IMAGE } from '@shared/constants/topic-image';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import { checkActiveCourse } from '@shared/utils/course-utils';
import {
  isAnyCourseOrConference,
  isBook,
  isConference,
  isCorpCourseWithOrWithoutApprove,
  isCourse,
  isEvent,
  isExternalCourse,
  shouldCheckPreviousStudyPlans,
} from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import { noun } from 'plural-ru';
import PropTypes from 'prop-types';

import css from './MaterialCard.module.scss';

import { ADD_REPEAT } from '@/constants/studyPlanStatus';
import { TOPIC_FIELD, TOPIC_TYPES } from '@/constants/topic-types';
import FireBadge from '@/assets/images/fire-badge.svg';
import RecommendedBadge from '@/assets/images/recommended-flag.svg';

const currentTopicImageById = id => TOPIC_IMAGE.find(type => type.id === id);

const MaterialCard = props => {
  const {
    topic,
    onDeleteClick,
    className,
    domainCompany,
    inMyCollection,
    hideActionButtons,
    onClick,
    isExternalLogin,
    isInPlan,
    openDate,
    onDeleteClickCatalog,
    userTopicId,
    currentUser,
    onFavoriteClick,
    onFavoriteClickCatalog,
    onStudyPlanClick,
    onAddToPlanNow,
    setHandleStudyPlanClickState,
  } = props;

  const { t } = useTranslation(['materialCard', 'constants']);
  const dispatch = useDispatch();

  const [deleted, setDeleted] = useState(false);
  const [correctFullDate, setCorrectFullDate] = useState('');
  const [isAudienceConfirmationModalOpen, setIsAudienceConfirmationModalOpen] = useState(false);

  const { newStatusPlanId, topicStudyPlanStatus } = useSelector(studyPlanSelect);
  const [checkRetake, setCheckRetake] = useState({});

  const defaultImg =
    !isExternalLogin && topic.scope === 'INTERNAL'
      ? currentTopicImageById(404).image
      : isCorpCourseWithOrWithoutApprove(topic.type?.id)
        ? currentTopicImageById(topic.type?.id)?.imageCompany
        : currentTopicImageById(topic.type?.id)?.image;

  const [currentImgBackground, isLoadingImgBackground] = useLocalFileByUUID(topic?.thumbFileUuid, {
    defaultImg,
  });

  useEffect(() => {
    if (!!newStatusPlanId && newStatusPlanId?.topic?.id === topic.id) {
      dispatch(
        getUserTopicStudyPlanStatus(
          {
            userId: currentUser?.id,
            topicId: topic.id,
            isAdmin: false,
          },
          res => {
            setCheckRetake({ userPlanType: res?.type });
          }
        )
      );
    }
  }, [newStatusPlanId]);

  useEffect(() => {
    if (topic.startDate && topic.startTime) {
      setCorrectFullDate(
        dayjs(`${topic.startDate}T${topic.startTime}`).add(
          currentUser.timezone.offset / 60 - domainCompany?.timezone?.offset / 60,
          'h'
        )
      );
    }
  }, [topic.startDate, topic.startTime]);

  const handleFavoriteClick = () => {
    if (onFavoriteClick) {
      if (props.inMyCollection || props.isRecommended) {
        onFavoriteClick(props.currentUser.id, topic.id, () => {
          setDeleted(false);
        });
      } else {
        onFavoriteClickCatalog(topic.id);
        setDeleted(false);
      }
    }
  };

  const handleStudyPlanClickState = () => {
    if (topic.isTopicFull) {
      message.warning(t('topicFull'));
      return;
    }

    const withNoEvents = Utils.isWithoutEvents(topic);

    onStudyPlanClick(topic.id, topic.type, topic.startDate, {
      id: topic.id,
      independent: topic.independent,
      startDate: topic.startDate,
      type: topic.type,
      topicCost: topic.cost,
      topicDates: Array.isArray(topic.dates) && topic.dates.map(date => ({ dates: date.startDate })),
      link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
      eventCount: topic.futureEvents,
      topicComment: topic.comment,
      duration: topic.duration,
      withNoEvents,
    });
  };

  const handleModalConfirm = () => {
    setIsAudienceConfirmationModalOpen(false);
    if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
      setHandleStudyPlanClickState(() => handleStudyPlanClickState);
    } else {
      handleStudyPlanClickState();
    }
  };

  const handleModalCancel = () => {
    setIsAudienceConfirmationModalOpen(false);
  };

  const handleStudyPlanClickWithCheck = () => {
    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      setIsAudienceConfirmationModalOpen(true);
    } else if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
      setHandleStudyPlanClickState(() => handleStudyPlanClickState);
    } else {
      handleStudyPlanClickState();
    }
  };

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      if (props.inMyCollection || props.isRecommended) {
        onDeleteClick(props.topic && props.topic.id, true, true, () => {
          setDeleted(true);
        });
      } else {
        onDeleteClickCatalog(userTopicId);
        setDeleted(true);
      }
    }
  };

  const setDuration = mins => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;

    if (hours === 0) {
      return `${minutes} ${t('minutes')}`;
    }
    if (minutes > 0 && hours !== 0) {
      return `${hours} ${noun(hours, t('hour1'), t('hour2'), t('hour3'))} ${minutes} ${t('minutes')}`;
    }
    return `${hours} ${noun(hours, t('hour1'), t('hour2'), t('hour3'))}`;
  };

  const isInternalScope = !isExternalLogin && topic.scope === 'INTERNAL';

  const retakeTopic = checkRetake?.userPlanType
    ? topicStudyPlanStatus.type === ADD_REPEAT
    : topic?.userPlanType === ADD_REPEAT;

  const toMyCollectionButton =
    !deleted && (inMyCollection || topic.isFavourite || props.isFav) ? (
      <Button
        className={classNames(css['App-btn'], css['App-btn-ghost'], css['App-favorite'], {
          [css['single-heart-position']]: isInternalScope,
        })}
        type='link'
        onClick={handleDeleteClick}
        icon={<HeartFilled className={css['App-btn-icon']} />}
        size='large'
      ></Button>
    ) : (
      <Button
        className={classNames(css['App-btn'], css['App-btn-ghost'], {
          [css['single-heart-position']]: isInternalScope,
        })}
        onClick={handleFavoriteClick}
        type='link'
        icon={<HeartOutlined className={css['App-btn-icon']} />}
        size='large'
      ></Button>
    );

  const correctDate =
    isEvent(topic.type?.id) || isConference(topic.type?.id)
      ? dayjs(`${props.topic.endDate + ' ' + props.topic.endTime}`, 'YYYY-MM-DD HH:mm').format()
      : '';

  const checkIsEnd =
    (isEvent(topic.type?.id) || isConference(topic.type?.id)) &&
    topic.planStatus &&
    !topic.planStatus.planStatus &&
    dayjs(correctDate).isBefore(dayjs());

  const checkCoursePlan = topic.type && topic.planStatus && !topic.planStatus.planStatus;
  const isDisabled = topic.status === 'DISABLED';
  const addToPlanType = isDisabled ? 'default' : 'primary';

  const withEventsFalse = Utils.isWithoutEvents(topic); // нужна строгая проверка на false
  const addToPlanBlock = withEventsFalse ? (
    <div className={css['App-btn-container']}>
      <Button
        className={classNames(css['App-btn'], css['App-btn-first'])}
        onClick={onAddToPlanNow}
        type={addToPlanType}
        disabled={isDisabled}
        size='large'
      >
        {t('startNow')}
      </Button>
      <Button
        className={classNames(css['App-btn'], css['App-btn-second'])}
        onClick={handleStudyPlanClickWithCheck}
        type={addToPlanType}
        disabled={isDisabled}
        size='large'
      >
        {t('startLater')}
      </Button>
    </div>
  ) : (
    <Button
      className={css['App-btn']}
      onClick={handleStudyPlanClickWithCheck}
      type={addToPlanType}
      disabled={isDisabled}
      size='large'
    >
      {t('addToPlan')}
    </Button>
  );

  const actionButtons = hideActionButtons ? null : (isDisabled && checkCoursePlan) ||
    checkIsEnd ||
    (!_get(topic, 'planStatus.planStatus') && !checkActiveCourse({ topic, openDate }, 'isTemplate')) ? (
    <div className={css['App-btn-group']}>
      <Button
        className={classNames(css['App-btn'], css['App-btn-minWidth'], css['MaterialCard-noActive-button'])}
        type='primary'
        disabled
        size='large'
      >
        <div className={css['MaterialCard-noActive']} />
        {t('unActive')}
      </Button>
      {toMyCollectionButton}
    </div>
  ) : topic?.planStatus?.planStatus || isInPlan ? (
    <div className={css['App-btn-group']}>
      <Button
        className={classNames(css['App-btn'], {
          [css['App-btn-success']]: !retakeTopic,
        })}
        onClick={retakeTopic && handleStudyPlanClickWithCheck}
        size='large'
        type={retakeTopic && 'primary'}
        ghost={!retakeTopic}
      >
        {t(Utils.checkTitleAddToPlan(checkRetake?.userPlanType ? checkRetake : topic))}
      </Button>
      {toMyCollectionButton}
    </div>
  ) : (
    <div className={css['App-btn-group']}>
      {addToPlanBlock}
      {toMyCollectionButton}
    </div>
  );

  const topicType = topic.type && TOPIC_TYPES(t).find(type => type.id === topic.type.id);
  const typeField = topic.type && TOPIC_FIELD(t).find(type => type.id === topic.type.id);

  const typeBadge = topicType ? <TypeBadge type={topicType.code} name={topic.type.name} isMaterialCard={true} /> : null;

  const typeFieldRate =
    typeField &&
    typeField.fields.find(field => {
      const { type } = field;
      if (topic[type] && (topic[type].length || topic[type] > 0)) {
        return field.type;
      }
    });
  const topicSubType = topic?.subType?.id;

  const truncatedName = topic.name && topic.name.length > 67 ? `${topic.name.substr(0, 67)}...` : topic.name;
  const rate = <Rate count={5} value={Math.round(topic.rate)} disabled />;
  const typeFieldRateLabel = typeFieldRate ? typeFieldRate.name : null;
  const tooltipTypeFieldRateValue = typeFieldRateLabel
    ? typeFieldRate.type === 'authors'
      ? topic[typeFieldRate.type].length > 1
        ? topic[typeFieldRate.type].map(name => name.name)
        : null
      : null
    : null;
  const typeFieldRateValue = typeFieldRateLabel ? (
    typeFieldRate.type === 'authors' ? (
      topic[typeFieldRate.type].length > 1 ? (
        <Tooltip title={tooltipTypeFieldRateValue.join(', ')}>
          {`${topic[typeFieldRate.type][0].name} ${t('other')}`}
        </Tooltip>
      ) : (
        <Tooltip title={topic[typeFieldRate.type][0].name}>{topic[typeFieldRate.type][0].name}</Tooltip>
      )
    ) : typeFieldRate.type === 'startDate' ? (
      dayjs(topic[typeFieldRate.type]).format('DD MMMM YYYY')
    ) : (
      topic[typeFieldRate.type]
    )
  ) : null;

  // СТОИМОСТЬ {showCost(topic.cost.toLocaleString())}
  const cost =
    topic?.type &&
    topic?.cost &&
    (isCorpCourseWithOrWithoutApprove(topic.type?.id) ||
      isEvent(topic.type.id) ||
      isBook(topic.type.id) ||
      isExternalCourse(topic.type.id) ||
      isConference(topic.type.id)) ? (
      <div className={css['MaterialCard-costLabel']}>
        <MaterialCost topic={topic} currentUser={currentUser} />
      </div>
    ) : null;

  const fixMaterial = topic ? (
    <div className={css['MaterialCard-fixMaterial']}>
      {props.isRecommended && (
        <img src={RecommendedBadge} alt={t('spacialForYou')} className={css['MaterialCard-fixMaterial-icon']} />
      )}
      {!!topic.position && (
        <img src={FireBadge} alt='fix' className={classNames(css['MaterialCard-fixMaterial-fire'])} />
      )}
    </div>
  ) : null;

  const imageStyle = {
    backgroundColor: !isLoadingImgBackground && topic?.thumbFileUuid ? topic.coverColor : '#efefef',
  };

  const onlineParagraph = (
    <p className={css.headerSubType} style={{ color: '#4bbd5c' }}>
      {t('online')}
    </p>
  );

  const fullTimeParagraph = (
    <p className={css.headerSubType} style={{ color: '#05b' }}>
      {t('fullTime')}
    </p>
  );

  const linkName = (
    <Link to={`/collection-topics/${props.topic.id}`} className={css.header}>
      {truncatedName}
    </Link>
  );

  const textName = <div className={classNames(css.header, css.nonePointer)}>{truncatedName}</div>;

  return (
    <div className={classNames(className, css.MaterialCard, css['App-card-item'])} onClick={onClick}>
      {/* Блок картинки */}
      {onClick || isInternalScope ? (
        <div className={css['MaterialCard-image']}>
          <img src={currentImgBackground} alt='' />
        </div>
      ) : (
        <Link to={`/collection-topics/${props.topic.id}`}>
          <div className={classNames(css['MaterialCard-image'], css.ImageLink)} style={imageStyle}>
            <div
              className={classNames(css['MaterialCard-image'], css.ImageLink, {
                [css.hasBg]: !isLoadingImgBackground && topic?.thumbFileUuid && topic?.coverColor?.length > 0,
              })}
              style={imageStyle}
            >
              <img src={currentImgBackground} alt='' />
            </div>
            {fixMaterial}
            {cost}
          </div>
        </Link>
      )}

      {/* Название и то что ниже */}
      <div className={css['MaterialCard-block']}>
        {topicSubType === 21 || topicSubType === 18 || topicSubType === 37
          ? onlineParagraph
          : topicSubType === 22 || topicSubType === 19 || topicSubType === 36
            ? fullTimeParagraph
            : null}
        {topic.name.length > 70 ? (
          <Tooltip title={topic.name}>{onClick || isInternalScope ? textName : linkName}</Tooltip>
        ) : onClick || isInternalScope ? (
          textName
        ) : (
          linkName
        )}
        {isInternalScope ? (
          <div className={css['MaterialCard-container-personal']}>
            <div
              className={classNames(className, css['MaterialCard-personal'], {
                [css['MaterialCard-personal-internal']]: !props.hideActionButtons,
                [css['MaterialCard-personal-internal2']]: props.hideActionButtons,
              })}
            >
              <div className={css.labelNotContent}>{t('blocked')}</div>
            </div>

            {toMyCollectionButton}
          </div>
        ) : (
          <div className={css['MaterialCard-container-personal']}>
            <div className={css['MaterialCard-personal']}>
              <div className={css.row}>
                <div className={css.label}>{t('type')}</div>
                <div className={css.value}>{typeBadge}</div>
              </div>
              <div className={css.row}>
                <div className={css.label}>{t('rate')}</div>
                <div className={css.value}>{rate}</div>
              </div>
              <div className={css.row}>
                <div className={css.label}>
                  {!isConference(topic.type.id) && !isEvent(topic.type.id)
                    ? topic.duration
                      ? isExternalCourse(topic.type.id) ||
                        isCorpCourseWithOrWithoutApprove(topic.type.id) ||
                        isCourse(topic.type.id)
                        ? t('capacity')
                        : t('duration')
                      : typeFieldRateLabel
                    : t('startDate')}
                </div>
                <div className={css.value}>
                  {!isConference(topic.type.id) && !isEvent(topic.type.id)
                    ? topic.duration
                      ? setDuration(topic.duration)
                      : typeFieldRateValue
                    : dayjs(correctFullDate).format('DD.MM.YYYY')}
                </div>
              </div>
            </div>
            {!props.offline && actionButtons}
          </div>
        )}
      </div>
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </div>
  );
};

MaterialCard.propTypes = {
  className: PropTypes.string,
  currentUser: PropTypes.object,
  deletable: PropTypes.bool,
  domainCompany: PropTypes.object,
  isExternalLogin: PropTypes.bool,
  getFile: PropTypes.func,
  hideActionButtons: PropTypes.bool,
  inMyCollection: PropTypes.bool,
  isFav: PropTypes.bool,
  isInPlan: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDeleteClickCatalog: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  onFavoriteClickCatalog: PropTypes.func,
  onStudyPlanClick: PropTypes.func,
  topic: PropTypes.object,
  userTopicId: PropTypes.number,
  isRecommended: PropTypes.bool,
};

export default MaterialCard;
