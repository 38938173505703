// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-CLRVwf.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-JEu7dr .user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-label-zOZxcn[data-value="Montserrat"]:before, .user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-CLRVwf.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-JEu7dr .user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-item-QhnddT[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-Montserrat-pGs17Q {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S {
  padding-top: 24px;
  display: block;
}

.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S > div {
  text-align: justify;
}

.user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S > div > h3 {
  text-transform: capitalize;
  margin-top: 1rem;
  margin-bottom: .3rem;
}

@media (max-width: 992px) {
  .user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S > div > h3 {
    font-weight: 550;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ExecutiveTaskData/ExecutiveTaskData.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,iBAAiB;EACjB,cAAc;AAChB;;AAOA;EAJE,mBAAmB;AACrB;;AAOA;EAJE,0BAA0B;EAC1B,gBAAgB;EAChB,oBAAoB;AACtB;;AAMA;EACE;IAHE,gBAAgB;EAClB;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ExecutiveTaskData {\n  display: block;\n  padding-top: 24px;\n}\n\n.ExecutiveTaskData > div {\n  text-align: justify;\n}\n\n.ExecutiveTaskData > div > h3 {\n  margin-top: 1rem;\n  margin-bottom: 0.3rem;\n  text-transform: capitalize;\n}\n@media (max-width: 992px) {\n  .ExecutiveTaskData > div > h3 {\n    font-weight: 550;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-CLRVwf`,
	"qlPicker": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-CLRVwf`,
	"ql-font": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-JEu7dr`,
	"qlFont": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-JEu7dr`,
	"ql-picker-label": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-label-zOZxcn`,
	"qlPickerLabel": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-label-zOZxcn`,
	"ql-picker-item": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-item-QhnddT`,
	"qlPickerItem": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-picker-item-QhnddT`,
	"ql-font-Montserrat": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-Montserrat-pGs17Q`,
	"qlFontMontserrat": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ql-font-Montserrat-pGs17Q`,
	"ExecutiveTaskData": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S`,
	"executiveTaskData": `user-src-components-ExecutiveTaskData-ExecutiveTaskData-module__ExecutiveTaskData-zNoN2S`
};
export default ___CSS_LOADER_EXPORT___;
