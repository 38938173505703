// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-lS8opK.user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-RGcVIB .user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-label-woPeM_[data-value="Montserrat"]:before, .user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-lS8opK.user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-RGcVIB .user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-item-My3Xmi[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-Montserrat-TDzsID {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__App-card-item-v6tl9F {
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  padding: 40px;
  box-shadow: 0 1px 3px #0000000f;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-gJ6lr7 {
  margin-bottom: 1px;
  padding-top: 27px;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-header-Wj16QP {
  color: #000;
  font-size: 21px;
  font-weight: 800;
  line-height: 25px;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-text-jYNHWG {
  color: #000;
  margin-top: 13px;
}

.user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-text-jYNHWG ol {
  list-style: decimal;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CoinInfoModal/CoinInfoModal.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,+BAA+B;AACjC;;AAQA;EALE,kBAAkB;EAClB,iBAAiB;AACnB;;AAOA;EAJE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAMA;EAHE,WAAW;EACX,gBAAgB;AAClB;;AAKA;EAFE,mBAAmB;AACrB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.App-card-item {\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);\n  padding: 40px;\n  background-color: #fff;\n  border-radius: 8px;\n  height: 100%;\n}\n\n.HelpModal {\n  padding-top: 27px;\n  margin-bottom: 1px;\n}\n.HelpModal-header {\n  font-weight: 800;\n  line-height: 25px;\n  font-size: 21px;\n  color: black;\n}\n.HelpModal-text {\n  margin-top: 13px;\n  color: black;\n}\n.HelpModal-text ol {\n  list-style: decimal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-lS8opK`,
	"qlPicker": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-lS8opK`,
	"ql-font": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-RGcVIB`,
	"qlFont": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-RGcVIB`,
	"ql-picker-label": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-label-woPeM_`,
	"qlPickerLabel": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-label-woPeM_`,
	"ql-picker-item": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-item-My3Xmi`,
	"qlPickerItem": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-picker-item-My3Xmi`,
	"ql-font-Montserrat": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-Montserrat-TDzsID`,
	"qlFontMontserrat": `user-src-pages-CoinInfoModal-CoinInfoModal-module__ql-font-Montserrat-TDzsID`,
	"App-card-item": `user-src-pages-CoinInfoModal-CoinInfoModal-module__App-card-item-v6tl9F`,
	"appCardItem": `user-src-pages-CoinInfoModal-CoinInfoModal-module__App-card-item-v6tl9F`,
	"HelpModal": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-gJ6lr7`,
	"helpModal": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-gJ6lr7`,
	"HelpModal-header": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-header-Wj16QP`,
	"helpModalHeader": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-header-Wj16QP`,
	"HelpModal-text": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-text-jYNHWG`,
	"helpModalText": `user-src-pages-CoinInfoModal-CoinInfoModal-module__HelpModal-text-jYNHWG`
};
export default ___CSS_LOADER_EXPORT___;
