import { useTranslation } from 'react-i18next';

import NewContextSearch from '@/components/NewContextSearch';
import Sort from '@/components/Sort';

// import { FiPlus } from 'react-icons/fi';

import { SEARCH_TYPES, SORT_TYPES } from './constants';

import PropTypes from 'prop-types';

import styles from './Filter.module.scss';

// eslint-disable-next-line
export const Filter = ({ changeParams, params, navigate, openModal, placeHolder }) => {
  const { t } = useTranslation('news');

  const handleSearchSubmit = ({ value, type }) => {
    changeParams({ ...params, value, type });
  };

  return (
    <div className={styles.filter}>
      <NewContextSearch searchTypes={SEARCH_TYPES(t)} onSearchSubmit={handleSearchSubmit} placeHolder={placeHolder} />
      <div className={styles.filter__footer}>
        <Sort
          sortTypes={SORT_TYPES(t)}
          activeSort={params.sort}
          handleChangeSort={sort => changeParams({ ...params, sort })}
        />
        {/* Закомментировано по задаче KAM-4630 */}
        {/* <div className={styles.filter__add} onClick={openModal}>
          <div className={styles.filter__plus}>
            <FiPlus />
          </div>
          <p className={styles.filter__text}>{t('createNews')}</p>
        </div> */}
      </div>
    </div>
  );
};

Filter.propTypes = {
  params: PropTypes.shape({
    sort: PropTypes.string.isRequired,
  }),
  navigate: PropTypes.func.isRequired,
  changeParams: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
};

export default Filter;
