/* eslint-disable */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, redirect, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';

import { getFaqLinks } from '@/store/footer/actions';
import { getIncome } from '@/store/income/actions';
import { firstLoginUpdate, getUser } from '@/store/users/actions';

import { selectError } from '@/store/error/selectors';
import { selectFooter } from '@/store/footer/selectors';
import { selectNotifications } from '@/store/notifications/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Layout } from 'antd';
import BannerUpHeader from '@/components/BannerUpHeader/BannerUpHeader';
import MobileNav from '@/components/MobileNav/MobileNav';
import NewsWidget from '@/components/NewsWidget';
import Notifications from '@/components/Notifications';
import WelcomeModal from '@/components/WelcomeModal';

import Footer from '../Footer';
import Header from '../Header';
import IncomeModal from '../IncomeModal';
import ProfileModal from '../ProfileModal';
import SendReviewModal from '../SendReviewModal';

import { clearStorage } from '@/utils/clearStorage';
import { changeLanguage } from '@shared/utils/changeLanguage';

import allRoutes, { collectionTopics, defaultRoute, planning } from '@/router/routes';
import store from '@/store';
import dayjs from 'dayjs';
import i18n from 'i18next';
import _ from 'lodash';
import _get from 'lodash/get';
import { io } from 'socket.io-client';

import { LOGIN } from '@/constants/income-types';
import { NEW } from '@/constants/user-statuses';

import '@shared/controllers/registerAllCharts.js';

import css from './userLayout.module.scss';

const { Content } = Layout;

const jivaCompanies = [
  {
    company: 'severstal',
    companyId: 6953,
    src: `//code.jivo.ru/widget/OGfsNzLK9b`,
    id: 'severstal-script',
    onLoad: () => console.log('SeverStal Jivo script loaded!'),
  },
];

const addScript = ({ src, id, onLoad, content, isHeader }) => {
  const existing = document.getElementById(id);
  if (existing) {
    return existing;
  } else {
    const script = document.createElement('script');
    if (src) script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
    };
    script.onerror = () => console.log('Error Jiva');

    script.innerHTML = content;

    if (isHeader) {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    return script;
  }
};

export const userChildren = allRoutes;

export const userLoader = async () => {
  const hasToken = localStorage.getItem('authorizationToken');

  if (hasToken && !store.getState().users.currentUser) {
    const userActions = {
      onSuccess: response => {
        store.dispatch(getFaqLinks());
        changeLanguage(i18n, dayjs, response.language);

        const isExternalLogin = _get(response, 'domainCompany.providers', []).filter(
          provider => provider.type === 'OAUTH'
        ).length;

        isExternalLogin ? localStorage.setItem('externalLogin', 'true') : localStorage.removeItem('externalLogin');
      },
      onError: error => {
        if (error.status === 401) clearStorage();
        return null;
      },
    };

    await store.dispatch(getUser(userActions));
  } else if (!hasToken) {
    return redirect('/user-login/');
  }

  return null;
};

let socket;

export default function UserLayout() {
  const { t } = useTranslation(['routes', 'notifications']);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { currentUser } = useSelector(selectUsers);
  const { errorStatus } = useSelector(selectError);
  const { i18n } = useTranslation();
  const { links } = useSelector(selectFooter);
  const currentNotifications = useSelector(selectNotifications);

  const [message, setMessage] = useState(null);
  const [eventRequest, setEventRequest] = useState(null);
  const [rejectCode, setRejectCode] = useState(
    new URL(document.location.href).searchParams.get('manager-reject') || ''
  );
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [visibleWelcomeModal, setVisibleWelcomeModal] = useState(true);
  const [isRootInjection, setIsRootInjection] = useState(false);
  const [visibleBurgerMenu, setVisibleBurgerMenu] = useState(false);
  const [routesConfig, setRoutesConfig] = useState([]);
  const [showNewsWidget, setShowNewsWidget] = useState(false);

  const loaderData = useLoaderData();

  const handleCancel = () => {
    this.setProfileModalVisible(false);
    if (currentUser?.firstLogin) {
      dispatch(getIncome(currentUser.id, LOGIN));
    }
  };

  const createNotification = ({ type, message }) => {
    switch (type) {
      case 'success':
        return NotificationManager.success(message);
      case 'warning':
        return NotificationManager.warning(message);
      case 'error':
        return NotificationManager.error(message);
      default:
        return NotificationManager.info(message);
    }
  };

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  useEffect(() => {
    if (currentNotifications.type) {
      createNotification({
        type: currentNotifications.type,
        message:
          currentNotifications.errorMessage ||
          t('notifications:' + currentNotifications.action, {
            data: currentNotifications.data,
          }),
      });
    }
  }, [currentNotifications]);

  useEffect(() => {
    if (localStorage.getItem('authorizationToken') && currentUser.domainCompany.hasChat) {
      socket = io.connect(window.location.hostname === 'localhost' ? 'https://kampus.local' : '/', {
        transports: ['websocket'],
        path: '/api/v3/socket',
        auth: {
          token: localStorage.getItem('authorizationToken'),
        },
      });
    }
  }, [localStorage.getItem('authorizationToken')]);

  useEffect(() => {
    if (location?.pathname) {
      const isShow = [`/${collectionTopics.path}`, `/${planning.path}`, '/'].includes(location.pathname);
      setShowNewsWidget(isShow);
    }
  }, [location]);

  useEffect(() => {
    if (currentUser.status === NEW) {
      setProfileModalVisible(true);
    }

    const currentCompany = jivaCompanies.filter(jiv => jiv.companyId === currentUser.domainCompany.id);
    currentCompany.map(item => {
      addScript({
        src: item.src,
        id: item.id,
        onLoad: item.onLoad,
      });
    });

    const yandexMetrika = [
      {
        content: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(98080124, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true })`,
        id: 'yandex-metrika-script-2',
        onLoad: () => console.log('Yandex metrika script loaded!'),
        errorMsg: 'Yandex metrika load error',
      },
    ];

    if (
      import.meta.env.PROD &&
      currentUser.domainCompany.id === 2 &&
      currentUser.domainCompany.domain.includes('tochka.kampus.team')
    ) {
      yandexMetrika.map(item => {
        addScript({
          id: item.id,
          onLoad: item.onLoad,
          content: item.content,
          isHeader: true,
        });
      });
    }
  }, []);

  const onClose = () => {
    dispatch(
      firstLoginUpdate(
        () => setVisibleWelcomeModal(false),
        () => createNotification({ type: 'error', message: t('notifications:firstLoginError') })
      )
    );
  };

  const hasCoins = currentUser?.domainCompany?.hasCoins;

  return (
    <>
      <Layout>
        <BannerUpHeader />
        <Header socket={socket} openBurgerMenu={visibleBurgerMenu} setOpenBurgerMenu={e => setVisibleBurgerMenu(e)} />
        <MobileNav
          openBurgerMenu={visibleBurgerMenu}
          setOpenBurgerMenu={e => setVisibleBurgerMenu(e)}
          pathname={location.pathname}
        />

        <Layout.Content className={css['content']}>
          <Outlet context={{ socket }} />
        </Layout.Content>

        <Footer domainCompany={currentUser?.domainCompany} footerLinks={links} />
      </Layout>

      <Notifications />
      {showNewsWidget && <NewsWidget />}

      <ProfileModal visible={profileModalVisible} onCancel={handleCancel} />
      <SendReviewModal />
      {hasCoins && <IncomeModal />}
      <NotificationContainer />

      {currentUser?.firstLogin && <WelcomeModal visible={visibleWelcomeModal} onClose={onClose} />}
    </>
  );
}
