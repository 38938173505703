// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectUserTopics } from '@/store/user-topics/selectors';

import { Radio, Spin } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';

import NewsUserElement from './NewsUserElement';

import PropTypes from 'prop-types';

import styles from './News.module.scss';

const { Button } = Radio;

const NewsElement = ({ type, emptyContent }) => {
  const {
    myRecommendationsNewsIsLoading,
    myRecommendationsNews,
    myAnotherRecommendationsNewsIsLoading,
    myAnotherRecommendationsNews,
  } = useSelector(selectUserTopics);

  const renderUsers = () => {
    if (type === 'recommended' || type === 'myMaterial') {
      if (myAnotherRecommendationsNews[0]?.length === 0) {
        return <CustomEmpty title={emptyContent?.title} description={emptyContent?.description} />;
      } else {
        return myAnotherRecommendationsNews?.map(elemNews =>
          elemNews.map(elemArr =>
            elemArr.users?.map((elemUser, index) => (
              <NewsUserElement
                key={index}
                newsData={{ newsId: elemArr.newsId, newsName: elemArr.newsName }}
                userData={elemUser}
              />
            ))
          )
        );
      }
    } else if (type === 'myRecommended') {
      if (myRecommendationsNews[0]?.length === 0) {
        return (
          <CustomEmpty title={emptyContent?.title} description={emptyContent?.description}>
            <Link to='/news'>
              <Button size='large' type='primary'>
                {emptyContent?.btnText}
              </Button>
            </Link>
          </CustomEmpty>
        );
      }
      return myRecommendationsNews?.map(elemNews =>
        elemNews.map(elemArr =>
          elemArr.users?.map((elemUser, index) => {
            return (
              <NewsUserElement
                key={`myRecommended-${elemUser.userId}-${index}`}
                newsData={{ newsId: elemArr.newsId, newsName: elemArr.newsName }}
                userData={elemUser}
              />
            );
          })
        )
      );
    }
  };

  return (
    <div className={styles.newsElement}>
      {myRecommendationsNewsIsLoading || myAnotherRecommendationsNewsIsLoading ? <Spin /> : renderUsers()}
    </div>
  );
};

NewsElement.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NewsElement;
