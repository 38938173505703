/* react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAlpinaLink } from '@/store/topics/actions';

import { selectUserDomainCompanyImages } from '@/store/users/selectors';

import { Col, Row, Tag, Tooltip } from 'antd';
import { SchedulePopover } from '@/components';
import { Alert } from '@/components/elements';

import MaterialCost from '../MaterialCard/MaterialCost';

import useIsMobile from '@shared/hooks/useIsMobile';
import userStorage from '@shared/services/user-storage';
import { formatFullDate } from '@shared/utils/date-utils';
import { getFileMemo } from '@shared/utils/file-utils';
import { classNames } from '@shared/utils/styles-utils';
import { getDefaultMaterialImage, getMaterialIcon, isCorpCourseWithOrWithoutApprove } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import _get from 'lodash/get';
import noun from 'plural-ru';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const MaterialCard = ({
  material,
  className,
  asideClass,
  excludeParts = [],
  small,
  children,
  currentUser,
  dataqa,
  isPlanninFinished,
  mdPoster,
  mdCentr,
  mdActions,
}) => {
  const { corpCourseBackground, corpCourseIcon } = useSelector(selectUserDomainCompanyImages);
  const { t } = useTranslation('materialCard');

  const { topic, event, startDate, addedByAdmin, inIpr } = material;

  const blocked = topic.scope !== 'EXTERNAL' && userStorage.getItem('externalLogin');

  const topicTypeId = _get(topic, 'type.id');

  const defaultMaterialImage = blocked
    ? getDefaultMaterialImage('404')
    : isCorpCourseWithOrWithoutApprove(topicTypeId)
      ? corpCourseBackground || getDefaultMaterialImage(topicTypeId)
      : getDefaultMaterialImage(topicTypeId);

  const [image, setImage] = useState(defaultMaterialImage);
  const [posterColor, setPosterColor] = useState('');
  const [correctDate, setCorrectDate] = useState();
  const [smartCorrectDate, setSmartCorrectDate] = useState();
  const dispatch = useDispatch();

  const materialIcon = isCorpCourseWithOrWithoutApprove(topicTypeId)
    ? corpCourseIcon || getMaterialIcon(topicTypeId)
    : getMaterialIcon(topicTypeId);

  const [isMobile] = useIsMobile(768);
  const mobileNoChildren = isMobile || !children;
  const mdCentrWidth = mdCentr || 11;
  const mdActionsWidth = mdActions || 5;
  const mdColspan3 = 3;
  const mdColspan13 = 13;

  useEffect(() => {
    const { topic, event } = material;
    if (topic.scope !== 'EXTERNAL' && userStorage.getItem('externalLogin')) {
      return;
    }

    if (topic.thumbFileUuid) {
      getFileMemo(topic.thumbFileUuid).then(img => {
        setImage(img);
        setPosterColor(topic.coverColor || '');
      });
    }

    if (currentUser) {
      let fullDate = '';
      const hoursOffset = currentUser.timezone.offset / 60 - currentUser.domainCompany.timezone.offset / 60;

      if (topic?.dates?.length > 0) {
        fullDate = `${topic.dates[0].startDate}T${topic.dates[0].startTime}`;
        setCorrectDate(dayjs(fullDate).add(hoursOffset, 'h'));
      } else if (event !== null) {
        fullDate = `${event.periods[0].date}T${event.periods[0].startTime}`;
        setCorrectDate(dayjs(fullDate).add(hoursOffset, 'h'));
      } else if (topic.startDate !== null && topic.startTime !== null) {
        fullDate = `${topic.startDate}T${topic.startTime}`;
        setCorrectDate(dayjs(fullDate));
      } else if (startDate !== null) {
        fullDate = `${startDate}T00:00`;
        setCorrectDate('');
        setSmartCorrectDate(dayjs(fullDate));
      }
    } else {
      setCorrectDate('');
      setSmartCorrectDate('');
    }
  }, [currentUser, material, event, startDate]);

  const showWarningAlert = false; // todo этап 2

  const schedule = useMemo(() => {
    return event
      ? !!event.periods && {
          topicTypeId,
          comment: event.comment,
          periods: event.periods,
        }
      : topic.dates && topic.dates.length
        ? {
            topicTypeId,
            comment: topic.comment,
            periods: topic.dates.map(date => ({
              id: date.id,
              date: date.startDate,
              startTime: date.startTime,
              endTime: date.endTime,
              place: date.place,
              eventLink: date.eventLink,
            })),
          }
        : null;
  }, [event, topic.comment, topic.dates, topicTypeId]);

  const alpineRedirect = link => {
    if (link.undeletable) {
      dispatch(getAlpinaLink(link.url, res => res.url && window.open(res.url, '_blank')));
    } else {
      window.open(link.url, '_blank');
    }
  };

  const titleSize = small && excludeParts.includes('footer') ? 100 : 50;

  const topicName = topic.name.length > titleSize ? Utils.trString(topic.name, titleSize) : topic.name;

  const title = (
    <Tooltip title={topic.name} placement='bottom' className={cn('material-card__title-short')}>
      {topicName}
    </Tooltip>
  );
  const findAlpinaLink = links => {
    let elem = null;
    let data = [];
    links.map(link => {
      if (link.name.includes('alpina') && !link.name.includes('alpinabook')) {
        data.push(link);
      }
      return data;
    });
    if (data.length !== 0) {
      elem = (
        <span className={cn('material-card__link')} onClick={() => alpineRedirect(data[0])}>
          {data[0].name}
        </span>
      );
    } else {
      elem = (
        <span className={cn('material-card__link')} onClick={() => alpineRedirect(links[0])}>
          {links[0].name}
        </span>
      );
    }
    return elem;
  };

  const corpIconCompany = typeof materialIcon === 'function' ? materialIcon() : <img src={materialIcon} alt='' />;
  return (
    <div
      className={cn('material-card', { 'material-card_blocked': blocked }, { 'material-card_small': small }, className)}
    >
      <Row className={cn('material-card__content', { 'material-card__content_finished': isPlanninFinished })}>
        {/* Картинка */}
        {!isMobile && (
          <Col
            md={mdPoster || 3}
            className={cn('material-card__poster', {
              'material-card__poster_default': !topic.thumbFileUuid,
            })}
            style={{ backgroundColor: posterColor }}
          >
            <img src={image} className={cn('material-card__image')} alt='' />
            <span className={cn('material-card__material-icon')}>{corpIconCompany}</span>
            {!small && !!topic.cost && (
              <span className={cn('material-card__price')}>
                <MaterialCost topic={topic} currentUser={currentUser} />
              </span>
            )}
          </Col>
        )}
        {/* Иконка для мобильной версии, картинку выше скрываю */}
        {isMobile && (
          <Col xs={2} sm={2} md={0}>
            <span className={cn('material-card__material-icon')}>{corpIconCompany}</span>
          </Col>
        )}

        {/* центр карточки */}
        <Col
          xs={22}
          sm={22}
          md={mobileNoChildren ? mdColspan3 + mdCentrWidth : mdCentrWidth}
          lg={mobileNoChildren ? mdActionsWidth + mdColspan13 : mdColspan13}
          className={cn('material-card__body', { 'material-card__body_finished': isPlanninFinished })}
        >
          {/* Заголовок */}

          <div
            className={cn('material-card__titleCenter', { 'material-card__titleCenter-finnished': isPlanninFinished })}
          >
            <div className={cn('material-card__titleCenter-tagBlock')}>
              {!isEmpty(material.trackInfo) && (
                <Tooltip
                  title={
                    <>
                      {t('usedInTrack', { name: material.trackInfo?.[0].trackName })}
                      {material.trackInfo.length > 1 &&
                        noun(
                          material.trackInfo.length - 1,
                          t('usedInTrackMore.0', { count: material.trackInfo.length - 1 }),
                          t('usedInTrackMore.1', { count: material.trackInfo.length - 1 }),
                          t('usedInTrackMore.2', { count: material.trackInfo.length - 1 })
                        )}
                    </>
                  }
                >
                  <Tag className={cn('material-card__tag')}>{t('track')}</Tag>
                </Tooltip>
              )}
              {!!material?.multiStudyPlan && (
                <Tooltip title={t('otherPassegesTitle')}>
                  <Tag color='red'>{t('otherPasseges')}</Tag>
                </Tooltip>
              )}
              {addedByAdmin ? (
                <Tag className={cn('material-card__tag__necessarily')}>{t('necessarily')}</Tag>
              ) : (
                <Tag className={cn('material-card__tag__optional')}>{t('optional')}</Tag>
              )}
              {inIpr && <Tag color='green'>{t('careerPlan')}</Tag>}
            </div>
            <p className={cn('material-card__title')}>
              {blocked ? (
                <Tooltip title={t('blocked')} placement='top'>
                  {title}
                </Tooltip>
              ) : (
                <Link data-qa={`${dataqa}-name`} to={`/collection-topics/${topic.id}`} state={{ fromPlanning: true }}>
                  {title}
                </Link>
              )}
            </p>
            {/* местро встречи (под заголовком) */}
            {topic.meetPlace !== null ? <div className={cn('material-card__description')}>{topic.meetPlace}</div> : ''}
            {topic.links.length !== 0 ? findAlpinaLink(topic.links) : ''}
            {!excludeParts.includes('footer') && (
              <div className={cn('material-card__footer')}>
                {correctDate !== '' ? (
                  <p
                    className={cn('material-card__date')}
                  >{`${formatFullDate(correctDate)} ${t('at')} ${dayjs(correctDate).format('HH:mm')}`}</p>
                ) : (
                  <div className={cn('material-card__date')}>{`${formatFullDate(smartCorrectDate)}`}</div>
                )}
                {/* SITE */}
                {topic.type.withEvents && currentUser?.timezone?.site && (
                  <div className={cn('material-card__site')}>{`(${currentUser?.timezone?.site})`}</div>
                )}
                {/* Расписание */}
                {schedule && <SchedulePopover schedule={schedule} user={currentUser} />}
              </div>
            )}
          </div>

          {isMobile && children && <div className={cn('material-card__mobileActions')}>{children}</div>}
        </Col>
        {/* Правая часть */}
        {!isMobile && children && (
          <Col md={mdColspan3} lg={mdActionsWidth} className={cn('material-card__aside', asideClass)}>
            {children}
          </Col>
        )}
      </Row>

      {showWarningAlert && (
        <Alert className={cn('material-card__alert')} type='warning' description={t('limit')} closable />
      )}
    </div>
  );
};

MaterialCard.propTypes = {
  material: PropTypes.shape({
    id: PropTypes.number,
    info: PropTypes.shape({
      description: PropTypes.string,
      nameUser: PropTypes.string,
      nameAdmin: PropTypes.string,
    }),
    status: PropTypes.string,
    topic: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      scope: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        pay: PropTypes.bool,
      }),
      cost: PropTypes.number,
      status: PropTypes.string,
      planStatus: PropTypes.shape({
        count: PropTypes.number,
        planStatus: PropTypes.string,
      }),
      viewers: PropTypes.shape({
        count: PropTypes.number,
        viewed: PropTypes.bool,
      }),
      isFavourite: PropTypes.bool,
      meetPlace: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      links: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      eventCount: PropTypes.number,
      futureEvents: PropTypes.number,
      independent: PropTypes.bool,
      comment: PropTypes.any,
      dates: PropTypes.array,
      thumbFileUuid: PropTypes.any,
      coverColor: PropTypes.string,
      isFinishedBefore: PropTypes.bool,
      subType: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
  }),
  className: PropTypes.string,
  small: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  asideClass: PropTypes.string,
  excludeParts: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.any,
  isPlanninFinished: PropTypes.bool,
};
