import React from 'react';

import { classNames } from '@shared/utils/styles-utils';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const ButtonMore = ({ onClick, className }) => (
  <button type='button' className={cn('button-more', className)} onClick={onClick}>
    <span />
    <span />
    <span />
  </button>
);

ButtonMore.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
