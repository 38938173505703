/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import { connect } from 'react-redux';

import { updateBookAddresses } from '@/store/books/actions';
import { getFile } from '@/store/files/actions';
import { getTopics } from '@/store/topics/actions';
import {
  createProfileStudyPlan,
  createProfileUserTopic,
  deleteProfileUserTopic,
  getRecommendedUserTopics,
  setAvaToCache,
} from '@/store/user-topics/actions';

import { selectUserTopics as userTopicsSelect } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { FloatButton, Layout, Row, Spin } from 'antd';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal';

import ListItem from '../../../components/MaterialListItem/index';
import CalendarModal from '../../CalendarModal/index.jsx';

import { withOutletContext } from '@/utils/WithOutletContext';
import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';
import PT from 'prop-types';
import { compose } from 'redux';

import css from './styles.module.scss';

import { SCHEDULED } from '@/constants/study-plan-statuses';

const { Content } = Layout;

class MyRecommendedTable extends Component {
  static propTypes = {
    creBy: PT.number,
    createProfileStudyPlan: PT.func,
    createProfileUserTopic: PT.func,
    currentUser: PT.object,
    deleteProfileUserTopic: PT.func,
    externalLogin: PT.any,
    getFile: PT.func,
    getPhoto: PT.func,
    getRecommendedUserTopics: PT.func,
    getTopics: PT.func,
    handleDeleteClick: PT.func,
    hasMore: PT.bool,
    hideActionButtons: PT.bool,
    isLoading: PT.bool,
    list: PT.array,
    loadMore: PT.func,
    onDeleteClick: PT.func,
    onDeleteClickCatalog: PT.func,
    onFavoriteClick: PT.func,
    onFavoriteClickCatalog: PT.func,
    onStudyPlanClick: PT.func,
    onlyTopic: PT.bool,
    profileId: PT.number,
    recBy: PT.number,
    recommended: PT.array,
    recommendedAnotherPageNumber: PT.number,
    recommendedAnotherTotal: PT.number,
    setAvaToCache: PT.func,
    user: PT.object,
    userId: PT.number,
    setHandleStudyPlanClickState: PT.func,
  };

  constructor(props) {
    super(props);
    this.reqAvatar = {};
    this.getRecommendedUserTopics = throttle(props.getRecommendedUserTopics, 1300);
    this.state = {
      userId: props.userId,
      calendarModalVisible: false,
      isLoadingFlag: true,
      numberPage: 0,
      isParticipationConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.state.userId) {
      this.getRecommendedUserTopics(
        { recBy: this.state.userId, page: 0, size: 15 },
        true,
        true,
        () => {},
        'anotherMyRecomended'
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.hasMore || (!newProps.isLoading && newProps.recommended && newProps.recommended.length !== 0)) {
      this.setState({ isLoadingFlag: false });
    } else {
      this.setState({ isLoadingFlag: true });
    }
  }

  loadMore = (page, hasMore) => {
    const { numberPage, userId } = this.state;
    if ((hasMore || this.props.hasMore) && numberPage !== page) {
      this.getRecommendedUserTopics({ recBy: userId, page, size: 15 }, true, true, () => {}, 'anotherMyRecomended');
      this.setState({ numberPage: page });
    }
  };

  getFile = (id, uuid, reset) => {
    if (this.reqAvatar[id] && !reset) {
      return null;
    }
    if (this.props.cachedFiles[id] && !reset) {
      return this.props.cachedFiles;
    }
    this.reqAvatar = { ...this.reqAvatar, [id]: true };
    this.props.getFile(uuid, res => {
      this.reqAvatar = { ...this.reqAvatar, [id]: false };
      this.props.setAvaToCache(id, URL.createObjectURL(res));
    });
  };

  handleFavoriteClick = topicId => {
    this.props.createProfileUserTopic(this.props.currentUser.id, topicId, 'anotherMyRecomended', () => {});
  };

  handleDeleteClick = topicId => {
    this.props.deleteProfileUserTopic(topicId, true, true, 'anotherMyRecomended', () => {});
  };

  showCalendarModal = (topicId, type, startDate, topicInfo) => {
    this.setState({
      topicInfo,
      calendarModalVisible: true,
      planningTopicId: topicId,
      planningTopicType: type,
      startDate,
    });
  };

  handleStudyPlanClick = (topicId, planningMonths = [], comment, withoutSchedule) => {
    const topic = this.props.recommended.find(item => item.topic.id === topicId)?.topic;

    const { currentUser, contextValue } = this.props;
    const { addressId, setAddressId } = contextValue;

    for (let i = 0; i < planningMonths.length; i++) {
      this.props.createProfileStudyPlan(
        currentUser.id,
        topicId,
        planningMonths[i],
        SCHEDULED,
        comment,
        () => {
          if (addressId > 0) {
            dispatch(updateBookAddresses(addressId, currentUser.id));
            setAddressId(0);
          }

          // this.props.getTopics({}, this.state.page - 1);
          this.handleCalendarCancel();

          if (topic && topic.targetAudience === false && isAnyCourseOrConference(topic?.type?.id)) {
            this.setState({ isParticipationConfirmationModalOpen: true });
          }
        },
        false,
        getWithoutSchedule(this.state.planningTopicType.id, withoutSchedule)
      );
    }
  };

  handleCalendarCancel = () => {
    this.setState({ calendarModalVisible: false });
  };

  handleParticipationConfirmationModalCancel = () => {
    this.setState({ isParticipationConfirmationModalOpen: false });
  };

  render() {
    const { contextValue, t, hasMore } = this.props;

    const { onAddToPlanNow, setHandleStudyPlanClickState } = contextValue;

    const loader = hasMore && (
      <div key={`loader_${this.props.recommendedAnotherPageNumber}`}>
        <Spin />
      </div>
    );
    const disabledSelect = this.state.planningTopicType && this.state.planningTopicType.id === 8;
    const list =
      this.props.recommended &&
      this.props.recommended.length > 0 &&
      this.props.recommended.map((item, key) => (
        <ListItem
          key={`${item.id}-${key}`}
          user={item.user}
          profileId={this.props.profileId}
          typeList='myRecommended'
          onFavoriteClick={this.handleFavoriteClick}
          checkIsEnd={
            item.topic.type &&
            (item.topic.type.id === 8 || item.topic.type.id === 23) &&
            item.topic.planStatus &&
            !item.topic.planStatus.planStatus &&
            dayjs(`${item.topic.endDate} ${item.topic.endTime}`).isSameOrBefore(dayjs())
          }
          checkCoursePlan={item.topic.type && item.topic.planStatus && !item.topic.planStatus.planStatus}
          setNoViewedValue={this.setNoViewedValue}
          cachedFiles={this.props.cachedFiles}
          theLast={this.props.recommended.length - 1 === key}
          recUsers={item.recUsers}
          getPhoto={this.getFile}
          topic={item.topic}
          avatar={this.props.cachedFiles[item.id]}
          onStudyPlanClick={this.showCalendarModal}
          onDeleteClick={this.handleDeleteClick}
          page={this.props.recommendedAnotherPageNumber}
          isExternalLogin={this.props.externalLogin}
          hideActionButtons={this.props.hideActionButtons}
          onAddToPlanNow={() => onAddToPlanNow(item.topic)}
          isAnother
          setHandleStudyPlanClickState={setHandleStudyPlanClickState}
        />
      ));

    const participationConfirmationModal = this.state.isParticipationConfirmationModalOpen && (
      <ParticipationConfirmationModal
        open={this.state.isParticipationConfirmationModalOpen}
        onClose={this.handleParticipationConfirmationModalCancel}
      />
    );

    return (
      <Content className={css.RecommendedTopic}>
        <Spin spinning={this.state.isLoadingFlag}>
          <Row>
            {this.props.recommended && this.props.recommended.length === 0 ? (
              <div className={css.RecommendedTopic__message}>
                <p>{t('materialsColleagues')}</p>
              </div>
            ) : (
              <InfiniteScroll
                className={css.InfiniteScroll}
                initialLoad={false}
                loadMore={() => this.loadMore(this.props.recommendedAnotherPageNumber + 1)}
                hasMore={this.props.hasMore}
                threshold={50}
                loader={loader}
              >
                {list}
              </InfiniteScroll>
            )}
          </Row>
        </Spin>
        <FloatButton.BackTop />
        {this.state.calendarModalVisible && (
          <CalendarModal
            open={this.state.calendarModalVisible}
            onOk={this.handleStudyPlanClick}
            onCancel={this.handleCalendarCancel}
            disabledSelect={disabledSelect}
            dateEvent={dayjs(this.state.startDate, 'D MMMM YYYY')}
            title={!disabledSelect ? t('selectPlan') : t('planingEvent')}
            okText={t('add')}
            planningTopicId={this.state.planningTopicId}
            topicInfo={this.state.topicInfo}
            eventTypeId={this.state.planningTopicType && this.state.planningTopicType.id}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 20 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 8 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            hasEvents={this.state.topicInfo && this.state.topicInfo.eventCount !== 0}
            selectMonthEvents={
              this.state.planningTopicType &&
              (isCorpCourseWithOrWithoutApprove(this.state.planningTopicType.id) ||
                this.state.planningTopicType.id === 20)
            }
          />
        )}

        {participationConfirmationModal}
      </Content>
    );
  }
}

const mapStateToProps = createSelector([usersSelect, userTopicsSelect], (users, userTopics) => ({
  currentUser: users.currentUser,
  userId: users.anotherUser && users.anotherUser.id,
  recommended: (userTopics.myRecommendationsAnother && userTopics.myRecommendationsAnother.elements) || [],
  hasMore: userTopics.myRecommendationsAnother && userTopics.myRecommendationsAnother.hasMore,
  recommendedAnotherPageNumber: userTopics.myRecommendationsAnother && userTopics.myRecommendationsAnother.page,
  cachedFiles: userTopics.cachedFiles || {},
  recommendedAnotherTotal: userTopics.myRecommendationsAnotherTotal,
}));

const mapActionsToProps = {
  getRecommendedUserTopics,
  setAvaToCache,
  createProfileUserTopic,
  deleteProfileUserTopic,
  createProfileStudyPlan,
  getTopics,
  getFile,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withTranslation('materialList'),
  withOutletContext
)(MyRecommendedTable);
