import { GET_DEPARTMENTS_FAIL, GET_DEPARTMENTS_START, GET_DEPARTMENTS_SUCCESS } from './constants';

const initialState = {
  departments: null,
  departmentsTotal: null,
  departmentsPageSize: null,
  departmentsPageNumber: null,
  isLoading: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case GET_DEPARTMENTS_START:
      return {
        ...state,
        departments: null,
        departmentsTotal: null,
        departmentsPageSize: null,
        departmentsPageNumber: null,
        isLoading: true,
      };

    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: response,
        departmentsTotal: Number(headers.get('X-Total-Elements')) || 0,
        departmentsPageSize: Number(headers.get('X-Page-Size')) || 0,
        departmentsPageNumber: Number(headers.get('X-Page-Number')) || 0,
        isLoading: false,
      };

    case GET_DEPARTMENTS_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
