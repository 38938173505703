/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearCompetenceWithSkills,
  getCompetence,
  getCompetenceMore,
  getCompetenceSkills,
  getCompetenceWithSkills,
} from '@/store/competence/actions';
import { getSkillsUser } from '@/store/users/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Flex, Modal, Spin, Tree } from 'antd';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import css from './DialogFilterSelectCompetence.module.scss';

const DialogFilterSelectCompetence = ({ open, onCancel, onSetSearch, referenceCheckedKeys }) => {
  const { t } = useTranslation('dialogFilterSelectCompetence');
  const dispatch = useDispatch();

  const { skills } = useSelector(selectUsers);
  const { competenceWithSkillsContent, competenceWithSkillsHasMore } = useSelector(selectCompetence);

  const [page, setPage] = useState(0);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedNames, setCheckedNames] = useState([]);

  useEffect(() => {
    setCheckedKeys(referenceCheckedKeys);
  }, [referenceCheckedKeys]);

  useEffect(() => {
    dispatch(getCompetenceWithSkills());

    return () => {
      dispatch(clearCompetenceWithSkills());
    };
  }, [getCompetence]);

  const onExpand = expandedKeysValue => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const setSearch = () => {
    let list = checkedKeys.reduce((acc, cur) => {
      if (cur.includes('--')) return [...acc, cur.split('--')[1]];
      return [...acc];
    }, []);

    list = [...new Set(list)];

    dispatch(getSkillsUser(null, list));
    onSetSearch(list, checkedNames);
  };

  const loadFunc = pageNumber => {
    dispatch(getCompetenceWithSkills(pageNumber));
  };

  const getTreeData = competenceWithSkillsContent?.map(item => ({
    title: item.name,
    key: `competence-${item.id}`,
    children:
      item?.skills?.length > 0
        ? item.skills.map(skill => ({
            key: `competence-${item.id}--${skill.id}`,
            title: skill.name,
          }))
        : null,
  }));

  const onCheck = (checkedKeysValue, ref) => {
    setCheckedKeys(checkedKeysValue);
    let currentCheckedNodes = ref.checkedNodes.reduce((acc, cur) => {
      if (!cur.hasOwnProperty('children')) return [...acc, cur.title];
      return [...acc];
    }, []);

    currentCheckedNodes = [...new Set(currentCheckedNodes)].join(', ');

    setCheckedNames(currentCheckedNodes);
  };

  return (
    <Modal
      width={800}
      open={open}
      title={
        <Flex justify={'center'} align={'center'}>
          {t('title')}
        </Flex>
      }
      onCancel={onCancel}
      footer={null}
    >
      <div className={css['Add-modal-form']}>
        <div style={{ height: '500px', overflow: 'auto' }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadFunc}
            hasMore={competenceWithSkillsHasMore}
            loader={<Spin key='spin-01' />}
            useWindow={false}
          >
            <Tree
              className={css['filter-select-competence__ant-tree']}
              treeData={getTreeData}
              checkable
              autoExpandParent={autoExpandParent}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              onSelect={selectedKeysValue => setSelectedKeys(selectedKeysValue)}
              selectedKeys={selectedKeys}
            />
          </InfiniteScroll>
        </div>
        <div className={css['App-card-item-submitButton']}>
          <Button disabled={isEmpty(checkedKeys)} onClick={setSearch}>
            {t('search')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DialogFilterSelectCompetence.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  getSkillsUser: PropTypes.func,
};

export default DialogFilterSelectCompetence;
