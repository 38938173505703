// Global
import React from 'react';

// Component
import { Button, Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import useIsMobile from '@shared/hooks/useIsMobile';

import PropTypes from 'prop-types';

import styles from './style.module.scss';

const NewContextSearch = props => {
  const [form] = Form.useForm();
  const [isMobile] = useIsMobile(769);

  // * Получение searchParams из текущего URL
  React.useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    form.setFieldValue('type', params.get('type') ?? props.searchTypes[0].value);
    form.setFieldValue('value', params.get('value'));

    if (params.get('type') || params.get('value')) form.submit();
  }, []);

  const appendSearchParams = ({ type, value }) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    type ? params.set('type', type) : params.delete('type');
    value ? params.set('value', value) : params.delete('value');

    url.search = params.toString();
    window.history.pushState({}, '', url.toString());
  };

  const onFinish = formData => {
    appendSearchParams(formData);
    props.onSearchSubmit(formData);
  };

  return (
    <Form name='context-search' form={form} className={styles['context-search']} onFinish={onFinish}>
      {isMobile && (
        <Form.Item name='value' className={styles['context-search__form-item']}>
          <Input allowClear size='large' className={styles['context-search__input']} placeholder={props.placeHolder} />

          <Button type='primary' htmlType='submit' size='large' className={styles['context-search__button']}>
            <SearchOutlined />
          </Button>
        </Form.Item>
      )}
      <Form.Item name='type' className={styles['context-search__form-item']}>
        <Select size='large' className={styles['context-search__select']} options={props.searchTypes} />
      </Form.Item>
      {!isMobile && (
        <>
          <Form.Item name='value' className={styles['context-search__form-item']}>
            <Input
              allowClear
              size='large'
              className={styles['context-search__input']}
              placeholder={props.placeHolder}
            />
          </Form.Item>
          <Form.Item className={styles['context-search__form-item']}>
            <Button type='primary' htmlType='submit' size='large' className={styles['context-search__button']}>
              <SearchOutlined />
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

NewContextSearch.propTypes = {
  searchTypes: PropTypes.array,
  onSearchSubmit: PropTypes.func,
  placeHolder: PropTypes.string,
};

export default NewContextSearch;
