import React from 'react';

import defaultIcon from '@shared/assets/icons/default-coins-icon.svg';
import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import PropTypes from 'prop-types';

const CoinsIcon = ({ uuidCoinsIcon, className }) => {
  const [coinsIcon, isLoading] = useLocalFileByUUID(uuidCoinsIcon, { defaultImg: defaultIcon });

  return <img src={coinsIcon} alt='' className={className} style={{ opacity: isLoading ? '0' : '1' }} />;
};

CoinsIcon.propTypes = {
  className: PropTypes.string,
  uuidCoinsIcon: PropTypes.string,
};

export { CoinsIcon };
