/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Form } from 'antd';
import LogoCompany from '@/components/LogoCompany';

import classNames from 'classnames';

import css from './Login.module.scss';

const RecoveryPageSuccess = () => {
  const { imageBackgroundLogin, imageLogo } = useOutletContext();
  const { t } = useTranslation(['registration']);
  const navigate = useNavigate();

  const handleChangeRenderPage = () => {
    localStorage.removeItem('isAuthStarted');
    navigate('/user-login');
  };

  return (
    <div className={css['App-card-item']}>
      <img className={css.imageBackgroundLogin} src={imageBackgroundLogin} alt='' />
      <Form className={css['LoginPage-form']}>
        <div className={css['LoginPage-form__contentRecovery']}>
          <div className={css['LoginPage-header']}>
            <div className={css['LoginPage-header__logo']}>
              <LogoCompany imageLogo={imageLogo} />
            </div>
          </div>
          <div className={css['LoginPage-titleHead']}>{t('mailSend')}</div>
          <div className={css['LoginPage-titleBody']}>
            {t('instruction')}
            <br />
            {t('recover')}
          </div>
          <div className={css['LoginPage-subTitleBody']}>
            {t('mailCheck')}
            <br />
            {t('usersCheck')}
          </div>
          <div className={css['LoginPage-label']}>
            <div className={classNames(css.LoginPage__toInfo)} onClick={handleChangeRenderPage}>
              {t('back')}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RecoveryPageSuccess;
