/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Input } from 'antd';
import DayPicker from '@/components/DayPicker';
import MonthList from '@/components/MonthList';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import MonthCard from './MonthCard';

import classNames from 'classnames';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './CalendarModal.module.scss';

import Arrow from '@/assets/images/blue-arrow-up.svg';

const { TextArea } = Input;

class CalendarTableModal extends Component {
  static propTypes = {
    courses: PropTypes.bool,
    dateEvent: PropTypes.any,
    disabledSelect: PropTypes.bool,
    hasEvents: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    hasEventsFlag: PropTypes.bool,
    showInterest: PropTypes.bool,
    headerFixToModal: PropTypes.bool,
    isLoadingPlan: PropTypes.bool,
    noComment: PropTypes.bool,
    noEvent: PropTypes.bool,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    openDate: PropTypes.any,
    planInfo: PropTypes.object,
    planningTopicId: PropTypes.number,
    rest: PropTypes.object,
    selectMonthEvents: PropTypes.bool,
    title: PropTypes.string,
    toPrev: PropTypes.func,
    open: PropTypes.bool,
    visibleModalData: PropTypes.bool,
    dayPicker: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: props ? props.comment || '' : '',
      selectedMonths: !props.disabledSelect ? [] : [dayjs(props.dateEvent).format('YYYY-MM-DD')],
      openDate: dayjs(props.openDate).format('YYYY-MM-DD'),
      workDate:
        !props.openDate || dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(props.openDate).format('YYYY-MM-DD')
          ? dayjs().add(6, 'day').format('YYYY-MM-DD')
          : dayjs(props.openDate).format('YYYY-MM-DD'),
      currentYear: props.noEvent
        ? Number(dayjs().format('YYYY'))
        : Number(
            dayjs(
              !props.openDate || dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(props.openDate).format('YYYY-MM-DD')
                ? dayjs().add(6, 'day').format('YYYY-MM-DD')
                : dayjs(props.openDate).format('YYYY-MM-DD')
            ).format('YYYY')
          ),
      selectedYear: props.noEvent
        ? Number(dayjs().format('YYYY'))
        : Number(
            dayjs(
              !props.openDate || dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(props.openDate).format('YYYY-MM-DD')
                ? dayjs().add(6, 'day').format('YYYY-MM-DD')
                : dayjs(props.openDate).format('YYYY-MM-DD')
            ).format('YYYY')
          ),
    };
  }

  testFunc = () => {
    this.setState({ selectedMonths: undefined });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.open === this.props.open) return;
    if (prevProps.open && prevProps.open !== this.props.open) {
      this.setState({
        comment: '',
        selectedMonths: !prevProps.disabledSelect ? [] : [dayjs(prevProps.dateEvent).format('YYYY-MM-DD')],
        hasSelectedDay: false,
        openDate: dayjs(prevProps.openDate).format('YYYY-MM-DD'),
        workDate:
          !prevProps.openDate ||
          dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(prevProps.openDate).format('YYYY-MM-DD')
            ? dayjs().add(6, 'day').format('YYYY-MM-DD')
            : dayjs(prevProps.openDate).format('YYYY-MM-DD'),
        currentYear: prevProps.noEvent
          ? Number(dayjs().format('YYYY'))
          : Number(
              dayjs(
                !prevProps.openDate ||
                  dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(prevProps.openDate).format('YYYY-MM-DD')
                  ? dayjs().add(6, 'day').format('YYYY-MM-DD')
                  : dayjs(prevProps.openDate).format('YYYY-MM-DD')
              ).format('YYYY')
            ),
        selectedYear: prevProps.noEvent
          ? Number(dayjs().format('YYYY'))
          : Number(
              dayjs(
                !prevProps.openDate ||
                  dayjs().add(6, 'day').format('YYYY-MM-DD') > dayjs(prevProps.openDate).format('YYYY-MM-DD')
                  ? dayjs().add(6, 'day').format('YYYY-MM-DD')
                  : dayjs(prevProps.openDate).format('YYYY-MM-DD')
              ).format('YYYY')
            ),
      });
    }
  }

  handleOkClick = () => {
    const { onOk, planningTopicId, rest, planInfo, topicInfo } = this.props;
    if (onOk) {
      onOk(
        planningTopicId,
        this.state.selectedMonths,
        this.state.comment,
        this.props.visibleModalData,
        rest,
        planInfo,
        undefined,
        undefined,
        topicInfo
      );
    }
  };

  changeComment = event => {
    const text = event.target.value;
    this.setState({ comment: text });
  };

  handleMonthClick = month => {
    const arr = [];
    const isSelectedMonthIsCurrent = dayjs(month).format('M') === dayjs().format('M');
    arr.push(
      isSelectedMonthIsCurrent ? dayjs(dayjs(month).set('date', dayjs().format('DD'))).format('YYYY-MM-DD') : month
    );
    this.setState({ selectedMonths: arr });
  };

  handleDayClick = day => {
    const arr = [];
    arr.push(dayjs(this.state.selectedMonths.toString()).set('date', day).format('YYYY-MM-DD'));
    this.setState({ selectedMonths: arr });
  };

  increaseYear = () => {
    this.setState(state => ({ selectedYear: state.selectedYear + 1 }));
  };

  decreaseYear = () => {
    this.setState(state => ({ selectedYear: state.selectedYear - 1 }));
  };

  renderMonthCard = props => {
    return (
      <MonthCard
        {...props}
        onClick={this.handleMonthClick}
        selectedMonths={
          !this.props.disabledSelect ? this.state.selectedMonths.toString() : this.props.dateEvent.toString()
        }
        disabledSelect={this.props.disabledSelect}
      />
    );
  };

  renderDayCard = (day, selected) => {
    const currentDateDayjs = dayjs().format('YYYY-MM-DD');
    const selectedDateStr = this.state.selectedMonths.toString();

    const selectedDate = dayjs()
      .date(day)
      .month(dayjs(selectedDateStr).month())
      .year(this.state.selectedYear)
      .format('YYYY-MM-DD');

    const isSelected = dayjs(currentDateDayjs).isSameOrBefore(selectedDate) && selected == day;
    const isDisabled = dayjs(selectedDate).isBefore(currentDateDayjs);

    const daysClassNames = {
      [css['Calendar-modal-day-selected']]: isSelected,
      [css['Calendar-modal-day-disabled']]: isDisabled,
    };

    const className = classNames(css['Calendar-modal-day'], daysClassNames);

    return (
      <div
        data-qa={`userCollectionDayEnabled-${!isDisabled}`}
        className={className}
        onClick={() => this.handleDayClick(day)}
        key={day}
      >
        {day}
      </div>
    );
  };

  renderPrev = (hasEventsFlag, hasEvents, toPrev) => {
    if (!this.props.showPrevLink) {
      if (!toPrev) {
        return null;
      }
      if (!hasEvents || !hasEventsFlag) {
        return null;
      }
    }
    const { comment } = this.state;
    return (
      <div className={css['Calendar-modal-toPrev']} onClick={() => toPrev({ hasEventsFlag: true, comment })}>
        <img src={Arrow} alt='' />
        {this.props.t('back')}
      </div>
    );
  };

  render() {
    const { okText, title, disabledSelect, dateEvent, selectMonthEvents, toPrev, hasEvents, hasEventsFlag, t } =
      this.props;

    const year = !disabledSelect ? this.state.selectedYear.toString() : dayjs(dateEvent, 'YYYY').toString();

    const topicTypeId = _get(this.props, 'topicInfo.type.id');
    const titleText = [16, 15, 5, 4, 1].includes(topicTypeId) ? (
      <>
        {t('selectPlan')} <br /> {t('addMaterial')}
      </>
    ) : (
      <>
        {t('selectMonth')} <br /> {t('application')}
      </>
    );

    return (
      <div className={classNames(css['App-card-item'], css['Calendar-modal'])}>
        {this.renderPrev(hasEventsFlag, hasEvents, toPrev)}
        <div
          className={classNames(
            css['Calendar-modal-header'],
            css['Calendar-modal-header--ext'],
            css['Calendar-modal-header--flex']
          )}
        >
          <div>{!selectMonthEvents ? title : <div>{titleText}</div>}</div>
          <div>
            <div className={classNames(css['Calendar-modal-year'], css['Calendar-modal-year--ext'])}>
              <CaretLeftOutlined
                disabled={disabledSelect || this.state.selectedYear <= this.currentYear}
                onClick={this.decreaseYear}
              />
              <div className={classNames(css['Calendar-modal-year-value'], css['Calendar-modal-year--ext-value'])}>
                {this.state.selectedYear}
              </div>
              <CaretRightOutlined onClick={this.increaseYear} />
            </div>
          </div>
        </div>
        <MonthList
          courses={this.props.courses}
          openDate={this.state.openDate}
          startDate={year}
          dateEvent={dateEvent}
          disabledSelect={disabledSelect}
          year={year}
          outputFormat='YYYY-MM-DD'
          displayFormat='MMM'
          className={css['Calendar-modal-months']}
          monthClassName={css['Calendar-modal-month-container']}
          renderMonth={this.renderMonthCard}
        />
        {this.props.dayPicker && (
          <div className={css['Calendar-modal-day-container']}>
            <DayPicker choosedDate={this.state.selectedMonths.toString()} renderDays={this.renderDayCard} />
          </div>
        )}
        {this.props.courses && (
          <div className={css['Calendar-modal__row__field-margin']}>
            <TextArea
              className={css.Comment}
              placeholder={t('comment')}
              rows={3}
              onChange={this.changeComment}
              value={this.state.comment}
              maxLength={500}
            />
          </div>
        )}
        <div className={css['Calendar-modal-btn']}>
          <Button
            disabled={this.props.isLoadingPlan || !this.state.selectedMonths || this.state.selectedMonths.length === 0}
            dataqa='userCollectionAddToPlanSaveBtn'
            type='primary'
            size='large'
            onClick={this.handleOkClick}
          >
            {this.props.showInterest ? t('showInterest') : okText}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation('calendarTableModal')(CalendarTableModal);
