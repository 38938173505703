/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';

import css from './style.module.scss';

import './custom-ant.css';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'; // TODO: вынести в константы(конфиг) проекта

export const CustomRangeDatePicker = ({ onDatesSelect, startDate, endDate }) => {
  const { t } = useTranslation('sort');

  const [value, setValue] = useState();

  useEffect(() => {
    if (!startDate || !endDate) {
      setValue('');
    }
  }, [startDate, endDate]);

  const getFormatingDate = date => {
    if (!date) return '';
    return date.format(dateFormat);
  };

  const setSelectedRangeDates = values => {
    const startDate = values ? getFormatingDate(values[0]) : '';
    const endDate = values ? getFormatingDate(values[1]) : '';
    setValue(values);
    onDatesSelect({ startDate, endDate });
  };

  return (
    <div className={css.RangeCalendarContainer}>
      <div className={css['RangeCalendarContainer-label']}>{t('selectPeriod')}</div>
      <RangePicker value={value} onChange={val => setSelectedRangeDates(val)} format={dateFormat} />
    </div>
  );
};
