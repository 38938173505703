// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-oFr1_d.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-yMoQLh .user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-label-nTQSxj[data-value="Montserrat"]:before, .user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-oFr1_d.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-yMoQLh .user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-item-b2GGLd[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-Montserrat-TrhEHC {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-_CKhY_ {
  width: 620px !important;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-body-hhIeOE {
  text-align: left;
  padding: 32px 16px;
  font-size: 1.5em;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-footer-EjH9Re {
  justify-content: center;
  display: flex;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-footer-EjH9Re button {
  width: 200px;
}

.user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-btn-YFoukD {
  min-width: 160px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,uBAAuB;AACzB;;AAMA;EAHE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAKA;EAFE,uBAAuB;EACvB,aAAa;AACf;;AAIA;EADE,YAAY;AACd;;AAGA;EAAE,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.ModalPaidTopicRestriction {\n  width: 620px !important;\n}\n.ModalPaidTopicRestriction-body {\n  padding: 32px 16px;\n  font-size: 1.5em;\n  text-align: left;\n}\n.ModalPaidTopicRestriction-footer {\n  display: flex;\n  justify-content: center;\n}\n.ModalPaidTopicRestriction-footer button {\n  width: 200px;\n}\n.ModalPaidTopicRestriction-btn {\n  min-width: 160px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-oFr1_d`,
	"qlPicker": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-oFr1_d`,
	"ql-font": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-yMoQLh`,
	"qlFont": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-yMoQLh`,
	"ql-picker-label": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-label-nTQSxj`,
	"qlPickerLabel": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-label-nTQSxj`,
	"ql-picker-item": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-item-b2GGLd`,
	"qlPickerItem": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-picker-item-b2GGLd`,
	"ql-font-Montserrat": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-Montserrat-TrhEHC`,
	"qlFontMontserrat": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ql-font-Montserrat-TrhEHC`,
	"ModalPaidTopicRestriction": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-_CKhY_`,
	"modalPaidTopicRestriction": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-_CKhY_`,
	"ModalPaidTopicRestriction-body": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-body-hhIeOE`,
	"modalPaidTopicRestrictionBody": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-body-hhIeOE`,
	"ModalPaidTopicRestriction-footer": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-footer-EjH9Re`,
	"modalPaidTopicRestrictionFooter": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-footer-EjH9Re`,
	"ModalPaidTopicRestriction-btn": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-btn-YFoukD`,
	"modalPaidTopicRestrictionBtn": `user-src-components-ModalPaidTopicRestriction-ModalPaidTopicRestriction-module__ModalPaidTopicRestriction-btn-YFoukD`
};
export default ___CSS_LOADER_EXPORT___;
