export const GET_CHAT_GROUP_BY_ID_START = 'GET_CHAT_GROUP_BY_ID_START';
export const GET_CHAT_GROUP_BY_ID_SUCCESS = 'GET_CHAT_GROUP_BY_ID_SUCCESS';
export const GET_CHAT_GROUP_BY_ID_FAIL = 'GET_CHAT_GROUP_BY_ID_FAIL';

export const GET_CHAT_GROUPS_START = 'GET_CHAT_GROUPS_START';
export const GET_CHAT_GROUPS_SUCCESS = 'GET_CHAT_GROUPS_SUCCESS';
export const GET_CHAT_GROUPS_FAIL = 'GET_CHAT_GROUPS_FAIL';

export const CREATE_CHAT_GROUP_START = 'CREATE_CHAT_GROUP_START';
export const CREATE_CHAT_GROUP_SUCCESS = 'CREATE_CHAT_GROUP_SUCCESS';
export const CREATE_CHAT_GROUP_FAIL = 'CREATE_CHAT_GROUP_FAIL';

export const UPDATE_CHAT_GROUP_BY_ID_START = 'UPDATE_CHAT_GROUP_BY_ID_START';
export const UPDATE_CHAT_GROUP_BY_ID_SUCCESS = 'UPDATE_CHAT_GROUP_BY_ID_SUCCESS';
export const UPDATE_CHAT_GROUP_BY_ID_FAIL = 'UPDATE_CHAT_GROUP_BY_ID_FAIL';

export const UPDATE_CHAT_GROUP_AUTHOR_START = 'UPDATE_CHAT_GROUP_AUTHOR_START';
export const UPDATE_CHAT_GROUP_AUTHOR_SUCCESS = 'UPDATE_CHAT_GROUP_AUTHOR_SUCCESS';
export const UPDATE_CHAT_GROUP_AUTHOR_FAIL = 'UPDATE_CHAT_GROUP_AUTHOR_FAIL';

export const UPDATE_CHAT_GROUP_USERS_START = 'UPDATE_CHAT_GROUP_USERS_START';
export const UPDATE_CHAT_GROUP_USERS_SUCCESS = 'UPDATE_CHAT_GROUP_USERS_SUCCESS';
export const UPDATE_CHAT_GROUP_USERS_FAIL = 'UPDATE_CHAT_GROUP_USERS_FAIL';

export const DELETE_CHAT_GROUP_USER_START = 'DELETE_CHAT_GROUP_USER_START';
export const DELETE_CHAT_GROUP_USER_SUCCESS = 'DELETE_CHAT_GROUP_USER_SUCCESS';
export const DELETE_CHAT_GROUP_USER_FAIL = 'DELETE_CHAT_GROUP_USER_FAIL';

export const DELETE_CHAT_GROUP_BY_ID_START = 'DELETE_CHAT_GROUP_BY_ID_START';
export const DELETE_CHAT_GROUP_BY_ID_SUCCESS = 'DELETE_CHAT_GROUP_BY_ID_SUCCESS';
export const DELETE_CHAT_GROUP_BY_ID_FAIL = 'DELETE_CHAT_GROUP_BY_ID_FAIL';

export const SEARCH_USERS_START = 'SEARCH_USERS_START';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL';

export const GET_MESSAGES_START = 'GET_MESSAGES_START';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const GET_USERS_IN_CHAT_START = 'GET_USERS_IN_CHAT_START';
export const GET_USERS_IN_CHAT_SUCCESS = 'GET_USERS_IN_CHAT_SUCCESS';
export const GET_USERS_IN_CHAT_FAIL = 'GET_USERS_IN_CHAT_FAIL';

export const CHECK_NEW_MESSAGE_START = 'CHECK_NEW_MESSAGE_START';
export const CHECK_NEW_MESSAGE_SUCCESS = 'CHECK_NEW_MESSAGE_SUCCESS';
export const CHECK_NEW_MESSAGE_FAIL = 'CHECK_NEW_MESSAGE_FAIL';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const SET_SELECTED_USER = 'SET_SELECTED_USER';

export const CLEAR_SEARCH_USERS = 'CLEAR_SEARCH_USERS';

export const ADD_NEW_MESSAGE_IN_CHAT = 'ADD_NEW_MESSAGE_IN_CHAT';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const ADD_NEW_GROUP = 'ADD_NEW_GROUP';

export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';

export const READ_MESSAGES_IN_CHAT = 'READ_MESSAGES_IN_CHAT';

export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';

export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE';

export const CLEAR_REPLY_MESSAGE = 'CLEAR_REPLY_MESSAGE';

export const CLEAR_GROUPS = 'CLEAR_GROUPS';

export const CLEAR_USERS = 'CLEAR_USERS';

export const SEARCH_GROUPS = 'SEARCH_GROUPS';

export const CLEAR_FOUND_GROUPS = 'CLEAR_SEARCH_GROUPS';

export const CLEAR_PERSONAL_GROUPS = 'CLEAR_PERSONAL_GROUPS';

export const DELETE_CHAT_GROUP = 'DELETE_CHAT_GROUP';

export const UPDATE_CHAT_GROUP = 'UPDATE_CHAT_GROUP';

export const ADD_NEW_PERSONAL_GROUP = 'ADD_NEW_PERSONAL_GROUP';
