import customTags from '@shared/store/customTags/reducer';

import badge from '@/store/badge/reducer';
import books from '@/store/books/reducer';
import careerPlan from '@/store/career_plan/reducer';
import certificates from '@/store/certificate/reducer';
import chapters from '@/store/chapters/reducer';
import chat from '@/store/chat/reducer';
import company from '@/store/company/reducer';
import competence from '@/store/competence/reducer';
import compilations from '@/store/compilations/reducer';
import course from '@/store/course/reducer';
import d360Project from '@/store/d360/Project/reducer';
// Diagnostic 360
import d360Projects from '@/store/d360/Projects/reducer';
import d360Report from '@/store/d360/Report/reducer';
import d360Vote from '@/store/d360/Vote/reducer';
import department from '@/store/department/reducer';
import diagnostic from '@/store/diagnostic/reducer';
import error from '@/store/error/reducer';
import executive from '@/store/executive/reducer';
import existingMaterial from '@/store/existing-material/reducer';
import faq from '@/store/faq/reducer';
import files from '@/store/files/reducer';
import footer from '@/store/footer/reducer';
import functions from '@/store/function/reducer';
import income from '@/store/income/reducer';
import typeLabel from '@/store/label_topic/reducer';
import lms from '@/store/lms/reducer';
import locales from '@/store/locales/reducer';
import login from '@/store/login/reducer';
import mentee from '@/store/mentee/reducer';
import mentoring from '@/store/mentoring_profile/reducer';
import mentors from '@/store/mentors/reducer';
import news from '@/store/news/reducer';
import newNotifications from '@/store/notifications-new/reducer';
import notifications from '@/store/notifications/reducer';
import plan from '@/store/plan/reducer';
import pokaSkills from '@/store/poka/reducer';
import positions from '@/store/position/reducer';
import search from '@/store/search/reducer';
// Shop
import shop from '@/store/shop/reducer';
import studyPlan from '@/store/study-plan/reducer';
import tags from '@/store/tags/reducer';
import talentQ from '@/store/talentq/reducer';
import timezones from '@/store/timezones/reducer';
import topics from '@/store/topics/reducer';
import tracks from '@/store/tracks/reducer';
import trajectory from '@/store/trajectory/reducer';
import competencies from '@/store/user_competencies/reducer';
import userCustomfield from '@/store/user_customfield/reducer';
import skills from '@/store/user_skills/reducer';
import userTimezone from '@/store/user-timezone/reducer';
import userTopics from '@/store/user-topics/reducer';
import usersTopicAccess from '@/store/users-topic-access/reducer';
import users from '@/store/users/reducer';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  // Implements Diagnostic 360
  d360Projects,
  d360Project,
  d360Vote,
  d360Report,

  // Others
  plan,
  tags,
  topics,
  login,
  files,
  userTopics,
  users,
  studyPlan,
  notifications,
  income,
  error,
  course,
  search,
  company,
  compilations,
  faq,
  footer,
  trajectory,
  diagnostic,
  lms,
  chapters,
  locales,
  competence,
  timezones,
  userTimezone,
  department,
  functions,
  positions,
  talentQ,
  skills,
  competencies,
  userCustomfield,
  executive,
  existingMaterial,
  usersTopicAccess,
  mentors,
  mentoring,
  mentee,
  certificates,
  shop,
  tracks,
  typeLabel,
  news,
  books,
  newNotifications,
  chat,
  badge,
  careerPlan,
  customTags,
  pokaSkills,
});
