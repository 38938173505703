/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import {
  clearCompilationComments,
  createCompilationComments,
  deleteCompilationComment,
  getCompilation,
  getCompilationComments,
  restoreCompilationComment,
  toggleIsFavoriteCompilation,
  updateCompilationComment,
} from '@/store/compilations/actions';
import { eventTopicInfo } from '@/store/course/actions';
import { getFile } from '@/store/files/actions';
import { setSearchParams } from '@/store/search/actions';
import { createStudyPlan } from '@/store/study-plan/actions';
import { clearTopicsCompilation, getTopicById, getTopicsCompilation } from '@/store/topics/actions';
import {
  checkFavoriteUserTopic,
  createProfileUserTopic,
  createUserTopic,
  deleteProfileUserTopic,
} from '@/store/user-topics/actions';
import { getUserDomainCompanyImages, getUsers } from '@/store/users/actions';

import { selectCompilations as compilationList } from '@/store/compilations/selectors';
import { selectOpenDate } from '@/store/course/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectSearch } from '@/store/search/selectors';
import { selectTopics as topicsList } from '@/store/topics/selectors';
import { selectUserTopics as userTopics } from '@/store/user-topics/selectors';
import { selectUsers as usersList } from '@/store/users/selectors';

import { FloatButton, Layout, Spin, Tabs, Tooltip } from 'antd';
import AddressBook from '@/components/AddressBook/AddressBook.jsx';
import { Content } from '@/components/Layout';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon.jsx';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal.jsx';
import Participants from '@/components/Participants';

import CalendarModal from '../CalendarModal/index.jsx';
import Comments from './Comments.jsx';
import Header from './Header.jsx';
import Info from './Info.jsx';
import MaterialCard from './MaterialCard.jsx';

import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isCorpCourseWithOrWithoutApprove,
} from '@shared/utils/topic-utils';

import { getBookAddresses } from '@/store/books/actions.js';
import { getBlockStatistic } from '@/store/lms/actions.js';
import Utils from '@/Utils.js';
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './CompilationDetail.module.scss';

class CompilationDetail extends Component {
  static propTypes = {
    checkFavoriteUserTopic: PropTypes.func,
    clearCompilationComments: PropTypes.func,
    clearTopicsCompilation: PropTypes.func,
    compilation: PropTypes.object,
    compilationComments: PropTypes.array,
    compilationIsUpdate: PropTypes.bool.isRequired,
    createCompilationComments: PropTypes.func,
    createProfileUserTopic: PropTypes.func,
    createStudyPlan: PropTypes.func,
    createUserTopic: PropTypes.func,
    currentUser: PropTypes.object,
    currentUserId: PropTypes.number,
    deleteCompilationComment: PropTypes.func,
    deleteProfileUserTopic: PropTypes.func,
    domainCompany: PropTypes.object,
    eventTopicInfo: PropTypes.func,
    externalLogin: PropTypes.bool.isRequired,
    getCompilation: PropTypes.func,
    getCompilationComments: PropTypes.func,
    getFile: PropTypes.func,
    getTopicById: PropTypes.func,
    getTopicsCompilation: PropTypes.func,
    getUsers: PropTypes.func,
    hasMoreCompilationComments: PropTypes.bool.isRequired,
    navigate: PropTypes.func,
    match: PropTypes.any,
    pageCompilationComments: PropTypes.number,
    restoreCompilationComment: PropTypes.func,
    setSearchParams: PropTypes.func,
    toggleIsFavoriteCompilation: PropTypes.func,
    topics: PropTypes.array,
    topicsLoading: PropTypes.bool.isRequired,
    topicsPageNumber: PropTypes.number,
    topicsTotal: PropTypes.number,
    updateCompilationComment: PropTypes.func,
    userTopicsFavorite: PropTypes.array,
    searchParams: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      participants: [],
      likedTopicsDictionary: {},
      isCompilationLiked: props.compilation ? props.compilation.hasFavorites : false,
      addressId: 0,
      addressState: {
        isAddressBookVisible: false,
        calendarModalInfo: {},
        addresses: [],
        flagNow: false,
      },
      showParticipationConfirmationModal: false,
    };
    this.getTopics = props.getTopicsCompilation;
  }

  componentDidMount() {
    this.props.getUserDomainCompanyImages();
    this.props.clearTopicsCompilation();
    this.props.getCompilation(this.props.params.id);
    this.props.getUsers({ compilationId: this.props.params.id }, participants => {
      this.setState({ participants: participants.filter(user => user.id !== this.props.currentUserId) });
    });
    this.getTopics({
      compilationId: this.props.params.id,
      page: 0,
      size: 10,
      sort: 'compilations.position,asc',
    });
    this.props.checkFavoriteUserTopic(this.props.currentUserId, this.props.params.id);
    this.props.clearCompilationComments();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.compilationIsUpdate && this.props.compilationIsUpdate !== prevProps.compilationIsUpdate) {
      this.getTopics({
        page: 0,
        size: 10,
        compilationId: this.props.params.id,
        sort: 'compilations.position,asc',
      });
    }
    if (this.props.compilation !== prevProps.compilation) {
      this.setState({ isCompilationLiked: this.props.compilation?.hasFavorites });
    }
  }

  findMaterialsOnTag = id => {
    const { setSearchParams, navigate } = this.props;

    setSearchParams({ themeId: [id] });

    const referrer = localStorage.getItem('referrer'); //TODO: костыль для запоминания изначального урла при открытии новой вкладке в новой вкладке

    if (referrer) {
      this.props.navigate(referrer);
      return localStorage.removeItem('referrer');
    } else if (window.history.length > 2) {
      return navigate(-1);
    } else if (document.referrer && document.referrer !== document.location.href) {
      const url = new URL(document.referrer);
      return navigate(url.pathname);
    }
    navigate('/compilations');
  };

  closePage = () => {
    this.props.navigate({
      pathname: '/compilations',
      search: this.props.searchParams?.searchResult ? `?search=${this.props.searchParams.searchResult}` : undefined,
    });
  };

  loadMore = () => {
    if (!this.props.topicsLoading) {
      this.getTopics({
        compilationId: this.props.params.id,
        page: this.props.topicsPageNumber + 1,
        size: 10,
        sort: 'compilations.position,asc',
      });
    }
  };

  onFavoriteClick = params => {
    this.props.toggleIsFavoriteCompilation(params, () => {
      this.setState({ isCompilationLiked: !this.state.isCompilationLiked });
    });
  };

  addToFavoriteClick = topicId => {
    this.props.createProfileUserTopic(this.props.currentUserId, topicId, 'anotherPlan', () => {
      this.setState({
        likedTopicsDictionary: {
          ...this.state.likedTopicsDictionary,
          [topicId]: true,
        },
      });
    });
  };

  removeFavoriteClick = topicId => {
    this.props.deleteProfileUserTopic(topicId, true, true, 'anotherPlan', () => {
      this.setState({
        likedTopicsDictionary: {
          ...this.state.likedTopicsDictionary,
          [topicId]: false,
        },
      });
    });
  };

  showCalendarModal = (topicId, type, startDate, topicInfo) => {
    this.setState({
      topicInfo,
      calendarModalVisible: true,
      planningTopicId: topicId,
      planningTopicType: type,
      startDate,
    });
  };

  handleStudyPlanClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    webinarDateTime,
    planInfo,
    selectedId
  ) => {
    for (let i = 0; i < planningMonths.length; i++) {
      this.props.createStudyPlan(
        this.props.currentUserId,
        topicId,
        planningMonths[i],
        comment,
        () => {
          this.setState({
            isInPlan: { ...this.state.isInPlan, [topicId]: true },
          });
          this.getTopics({
            compilationId: this.props.params.id,
            page: this.props.topicsPageNumber,
            sort: 'compilations.position,asc',
          });
          this.handleCalendarCancel();
          if (
            this.props.topics &&
            this.props.topics.some(
              t => t.id === topicId && t.targetAudience === false && isAnyCourseOrConference(t?.type?.id)
            )
          ) {
            this.setState({ showParticipationConfirmationModal: true });
          }
        },
        false,
        getWithoutSchedule(this.state.planningTopicType.id, withoutSchedule),
        undefined,
        selectedId
      );
    }
  };

  handleParticipationConfirmationModalCancel = () => {
    this.setState({ showParticipationConfirmationModal: false });
  };

  handleCalendarCancel = () => this.setState({ calendarModalVisible: false });

  isTopicInFavorites = topic => {
    return this.state.likedTopicsDictionary[topic.id] !== undefined
      ? this.state.likedTopicsDictionary[topic.id]
      : topic.isFavourite;
  };

  showAddressBookModal = (topicId, type, startDate, topicInfo) => {
    this.props.getBookAddresses(
      topicId,
      data => {
        const addresses = data.map(item => ({
          id: item.id,
          address: item.addressDto.address,
          accessible: item.accessible,
        }));

        if (addresses.length > 0) {
          this.setState({
            addressState: {
              addresses,
              isAddressBookVisible: true,
              calendarModalInfo: { topicId, type, startDate, topicInfo },
            },
          });
          return;
        }

        this.showCalendarModal(topicId, type, startDate, topicInfo);

        this.setState(prevState => ({ addressState: { ...prevState.addressState, calendarModalInfo: {} } }));
      },
      () => this.setState(prevState => ({ addressState: { ...prevState.addressState, addresses: [] } }))
    );
  };

  handleAddressBookSubmit = currentAddressId => {
    const { topicId, type, startDate, topicInfo } = this.state.addressState?.calendarModalInfo;

    this.setState({
      addressId: currentAddressId > 0 ? currentAddressId : 0,
      addressState: { isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] },
    });

    if (topicInfo.withNoEvents && this.state.addressState.flagNow) {
      this.addToPlanNow(topicId, topicInfo.duration);
      this.setState({
        addressState: { flagNow: false },
      });
    } else {
      this.showCalendarModal(topicId, type, startDate, topicInfo);
    }
  };

  onCloseAddressBook = () => {
    this.setState({ addressState: { isAddressBookVisible: false, calendarModalInfo: {}, addresses: [] } });
  };

  addToPlanNow = (topicId, topicDuration = 0) => {
    const { navigate, currentUserId } = this.props;

    const date = Utils.getPlanDate(topicDuration);

    this.props.getBlockStatistic({
      topicId,
      userId: currentUserId,
      onSuccess: response => {
        const blocksCount = response?.countBlocks || 0;

        this.props.createStudyPlan(currentUserId, topicId, date, '', () => {
          navigate(Utils.getNavigateUrl(topicId, blocksCount));
        });
      },
    });
  };

  onAddToPlanNow = topic => {
    if (topic.qiwiLibrary) {
      const withNoEvents = Utils.isWithoutEvents(topic);
      const topicInfo = {
        id: topic.id,
        independent: topic.independent,
        startDate: topic.startDate,
        topicCost: topic.cost,
        topicDates: Array.isArray(topic.dates) && topic.dates.map(date => ({ dates: date.startDate })),
        link: topic.firstLink || topic.link || (topic.links && topic.links[0].url),
        eventCount: topic.futureEvents,
        topicComment: topic.comment,
        withNoEvents,
        duration: topic.duration,
      };

      this.props.getBookAddresses(
        topic.id,
        data => {
          const addresses = data.map(item => ({
            id: item.id,
            address: item.addressDto.address,
            accessible: item.accessible,
          }));

          if (addresses.length > 0) {
            this.setState({
              addressState: {
                addresses,
                isAddressBookVisible: true,
                calendarModalInfo: {
                  topicId: topic.id,
                  type: topic.type,
                  startDate: topic.startDate,
                  topicInfo,
                },
                flagNow: true,
              },
            });

            return;
          }

          this.addToPlanNow(topic.id, topic.duration);
        },
        () => this.setState(prevState => ({ addressState: { ...prevState.addressState, addresses: [] } }))
      );
    } else {
      this.addToPlanNow(topic.id, topic.duration);
    }
  };

  render() {
    if (!this.props.compilation) {
      return null;
    }

    const { topics, navigate, currentUserId, currentUser, pageCompilationComments, compilationComments, t } =
      this.props;

    const {
      id,
      cover,
      manager,
      name,
      rate,
      hasMention,
      fullDescription,
      skills,
      themes,
      shortDescription,
      isModalCurrentPaidTopicOpen,
      isModalPaidTopicRestrictionOpen,
    } = this.props.compilation;

    const COMPIL_SUBTYPES = [
      {
        name: t('materials'),
        panname: 'materials',
        dataqa: 'Materials',
        active: true,
      },
      {
        name: t('information'),
        panname: 'info',
        dataqa: 'Information',
        active: false,
      },
      {
        name: t('comment'),
        dataqa: 'Comment',
        panname: 'feedback',
        active: false,
      },
    ];

    const openDate = _get(this.props, 'openDate.openDate');
    const disabledSelect = this.state.planningTopicType && this.state.planningTopicType.id === 8;

    const renderThemes = (
      <div className={css.themes}>
        <div>
          <div className={css.themes__title}>{t('theme')}</div>
          <div>
            {themes.map((theme, i) => (
              <div key={theme.id}>
                <a
                  data-qa={`userCompilationDetailInfoTabThemeItem-${i}`}
                  onClick={() => this.findMaterialsOnTag(theme.id)}
                >
                  {theme.name}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div>
          {!this.state.participants.length ? null : (
            <div className={css['Detail-card-item']}>
              <div className={css['Detail-label']}>{t('favorite')}</div>
              <Participants isFav currentUser={this.props.currentUser} compilationId={this.props.compilation.id} />
            </div>
          )}
        </div>
      </div>
    );
    const tabs = COMPIL_SUBTYPES.map(tab => {
      if (tab.panname === 'materials') {
        return {
          key: tab.panname,
          label: tab.name,
          children: (
            <>
              <InfiniteScroll
                className={css.MaterialsTabsAboutBlock}
                initialLoad={false}
                loadMore={this.loadMore}
                hasMore={topics && this.props.topicsTotal > topics.length}
                threshold={300}
                useWindow
              >
                {topics &&
                  topics.map(topic => (
                    <MaterialCard
                      key={topic.id}
                      addToFavorites={this.addToFavoriteClick}
                      removeFromFavorites={this.removeFavoriteClick}
                      isInFavorites={this.isTopicInFavorites(topic)}
                      getFile={this.props.getFile}
                      domainCompany={this.props.domainCompany}
                      eventTopicInfo={this.props.eventTopicInfo}
                      currentUserId={this.props.currentUserId}
                      userTopicsFavorite={this.props.userTopicsFavorite}
                      getTopicById={this.props.getTopicById}
                      onStudyPlanClick={topic.isQiwiLibrary ? this.showAddressBookModal : this.showCalendarModal}
                      isInPlan={this.state.isInPlan && this.state.isInPlan[topic.id]}
                      topic={topic}
                      coverFileUuid={topic.coverFileUuid}
                      planStatus={topic.planStatus}
                      checkCoursePlan={topic.type && topic.planStatus && !topic.planStatus.planStatus}
                      checkIsEnd={
                        topic.type &&
                        (topic.type.id === 8 || topic.type.id === 23) &&
                        topic.planStatus &&
                        !topic.planStatus.planStatus &&
                        dayjs(`${topic.endDate} ${topic.endTime}`).isBefore(dayjs())
                      }
                      status={topic.status}
                      type={topic.type}
                      name={topic.name}
                      startDate={topic.startDate}
                      startTime={topic.startTime}
                      isExternalLogin={this.props.externalLogin}
                      openDate={openDate}
                      corpCourseBackground={this.props.corpCourseBackground}
                      onAddToPlanNow={() => this.onAddToPlanNow(topic)}
                    />
                  ))}
              </InfiniteScroll>
              {this.props.topicsLoading ? (
                <div className={css.Detail__spinner}>
                  <Spin />
                </div>
              ) : null}
            </>
          ),
        };
      }
      if (tab.panname === 'info') {
        return {
          key: tab.panname,
          label: tab.name,
          children: (
            <div className={css.TabPane__content}>
              <div className={css.TabPane__content__text}>
                <Info dataqa='compilationDetailInfoTab' skills={skills} fullDescription={fullDescription} />
              </div>
              {renderThemes}
            </div>
          ),
        };
      }
      if (tab.panname === 'feedback') {
        return {
          key: tab.panname,
          label: tab.name,
          children: (
            <div className={css.TabPane__content}>
              <div className={css.TabPane__content__text}>
                <Comments
                  compId={id}
                  rate={rate}
                  pageCompilationComments={pageCompilationComments}
                  hasMention={hasMention}
                  getFile={this.props.getFile}
                  createCompilationComments={this.props.createCompilationComments}
                  deleteCompilationComment={this.props.deleteCompilationComment}
                  updateCompilationComment={this.props.updateCompilationComment}
                  restoreCompilationComment={this.props.restoreCompilationComment}
                  currentUserId={currentUserId}
                  currentUser={currentUser}
                  getComments={this.props.getCompilationComments}
                  hasMoreCompilationComments={this.props.hasMoreCompilationComments}
                  comments={compilationComments}
                />
              </div>
              {renderThemes}
            </div>
          ),
        };
      }
    }).filter(tab => !!tab);

    const btnsBlock = (
      <div className={css['Detail-btn-block']}>
        <Tooltip title={t('close')}>
          <ModalCloseIcon onClick={this.closePage} className={css['Detail-btn-close']} />
        </Tooltip>
      </div>
    );

    const participationConfirmationModal = this.state.showParticipationConfirmationModal && (
      <ParticipationConfirmationModal
        open={this.state.showParticipationConfirmationModal}
        onClose={this.handleParticipationConfirmationModalCancel}
      />
    );

    return (
      <Layout>
        <Content className={css.Compilation}>
          <div className={css.Detail__box}>
            {btnsBlock}
            <Header
              compId={id}
              onFavoriteClick={this.onFavoriteClick}
              cover={cover}
              manager={manager}
              hasFavorites={this.state.isCompilationLiked}
              navigate={navigate}
              getFile={getFile}
              name={name}
              rate={rate}
              shortDescription={shortDescription}
            />
            <div className={css.Detail}>
              <div className={css.Detail__content}>
                <Tabs
                  dataqa='userDetailCollectionTabsListItems'
                  animated={false}
                  defaultActiveKey='materials'
                  className={css['Detail-tabs']}
                  onChange={this.handleTabChange}
                  items={tabs}
                />
              </div>
            </div>
          </div>
          {participationConfirmationModal}
        </Content>
        {this.state.calendarModalVisible && (
          <CalendarModal
            topicInfo={this.state.topicInfo}
            comment={this.state.topicInfo && this.state.topicInfo.topicComment}
            eventTypeId={this.state.planningTopicType && this.state.planningTopicType.id}
            disabledSelect={disabledSelect}
            dateEvent={dayjs(this.state.startDate).format('D MMMM YYYY')}
            title={!disabledSelect ? t('choosePlan') : t('planEvent')}
            open={this.state.calendarModalVisible}
            onOk={this.handleStudyPlanClick}
            onCancel={this.handleCalendarCancel}
            okText={t('add')}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 20 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 8 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            planningTopicId={this.state.planningTopicId}
            hasEvents={this.state.topicInfo?.eventCount !== 0}
            selectMonthEvents={
              this.state.planningTopicType &&
              (isCorpCourseWithOrWithoutApprove(this.state.planningTopicType?.id) ||
                this.state.planningTopicType.id === 20)
            }
          />
        )}

        <AddressBook
          textWindowTitle={t('collectionMaterials:addressBookWindowTitle')}
          textSubmitButton={t('collectionMaterials:addressBookSubmitButton')}
          textAccessible={t('collectionMaterials:addressBookaccessible')}
          textNotAccessible={t('collectionMaterials:addressBookNotAccessible')}
          textReadOnline={t('collectionMaterials:addressBookReadOnline')}
          onButtonClose={this.onCloseAddressBook}
          onButtonSubmit={this.handleAddressBookSubmit}
          open={this.state.addressState.isAddressBookVisible}
          data={this.state.addressState?.addresses}
        />
        <FloatButton.BackTop />
      </Layout>
    );
  }
}

const mapStateToProps = createSelector(
  [compilationList, topicsList, usersList, userTopics, loginSelect, selectOpenDate, selectSearch],
  (compilations, topics, users, favoriteTopics, login, openDate, search) => ({
    openDate,
    compilation: compilations.compilation,
    currentUserId: users.currentUser && users.currentUser.id,
    currentUser: users.currentUser,
    domainCompany: users?.currentUser?.domainCompany,
    compilationIsUpdate: compilations.isLoading,
    compilationComments: compilations.compilationComments,
    pageCompilationComments: compilations.pageCompilationComments,
    userTopicsFavorite: favoriteTopics.favoriteTopics,
    hasMoreCompilationComments: compilations.hasMoreCompilationComments
      ? compilations.hasMoreCompilationComments
      : false,
    topics: topics.topicsCompilation,
    topicsLoading: topics.isLoading,
    topicsPageNumber: topics.topicsCompilationPageNumber,
    topicsTotal: topics.topicsCompilationTotal,
    externalLogin: login.externalLogin ? login.externalLogin : false,
    corpCourseBackground: users.corpCourseBackground,
    searchParams: search.searchParams,
  })
);

const mapActionsToProps = {
  getCompilation,
  getFile,
  getUsers,
  setSearchParams,
  createStudyPlan,
  clearTopicsCompilation,
  eventTopicInfo,
  getTopicById,
  createUserTopic,
  checkFavoriteUserTopic,
  createProfileUserTopic,
  deleteCompilationComment,
  deleteProfileUserTopic,
  updateCompilationComment,
  clearCompilationComments,
  getCompilationComments,
  toggleIsFavoriteCompilation,
  restoreCompilationComment,
  createCompilationComments,
  getTopicsCompilation,
  getUserDomainCompanyImages,
  getBookAddresses,
  getBlockStatistic,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['compilationDetail', 'collectionMaterials'])(CompilationDetail));
