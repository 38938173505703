import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setBlockRestart } from '@/store/lms/actions';
import { checkPreviousStudyPlans } from '@/store/study-plan/actions';

import { Button, Collapse, Modal, Tooltip } from 'antd';
import AudienceConfirmationModal from '@/components/ModalTargetAudience/Modal';

import { PlanStatus } from '@shared/constants/plan';
import {
  isAnyCourseOrConference,
  isConference,
  isCorporateCourse,
  isExecutiveTask,
  isExternalCourse,
  shouldCheckPreviousStudyPlans,
} from '@shared/utils/topic-utils';

import classNames from 'classnames';

import css from './ChaptersPreview.module.scss';

import { APPROVED, APPROVED_STATUSES, WAITING, WAITING_BY_USER } from '@/constants/study-plan-statuses';
import articleIcon from '@/assets/icons/article.svg';
import presentationIcon from '@/assets/icons/presentation.svg';
import scormIcon from '@/assets/icons/scorm.svg';
import testIcon from '@/assets/icons/test.svg';
import videoIcon from '@/assets/icons/video.svg';

const ChaptersPreview = ({ chapters, topic, loadData, handleAddToPlanNow, setShowCalendar, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('chaptersPreview');

  const [helpWindow, setHelpWindow] = useState(false);
  const [isAudienceConfirmationModalOpen, setIsAudienceConfirmationModalOpen] = useState(false);
  const topicPlanStatus = topic?.planStatus?.planStatus;

  const isWaitingStatus = topicPlanStatus === WAITING;
  const isWaitingByUser = topicPlanStatus === WAITING_BY_USER;
  const isApproved = topicPlanStatus === APPROVED;

  const isModalStatus = isWaitingStatus || isWaitingByUser || isApproved || topicPlanStatus === null;

  const isApprovedStatus = APPROVED_STATUSES.includes(topicPlanStatus);

  const isCorpCourse = isCorporateCourse(topic?.type?.id);
  const isManagerTask = isExecutiveTask(topic?.type?.id);

  const isConferenceOrExternalCourse = isConference(topic?.type?.id) || isExternalCourse(topic?.type?.id);

  const isWaitinigCorpCourse = isCorpCourse && isWaitingStatus;
  const isApprovedCorpCourse = isCorpCourse && isApprovedStatus;

  const isRepetableManagerTask = isManagerTask && topicPlanStatus === PlanStatus.REJECTED_BY_MANAGER;

  const isWaitingByUserConference = isWaitingByUser && isConferenceOrExternalCourse;
  const isWaitingConference = isWaitingStatus && isConferenceOrExternalCourse;
  const isApprovedConference = isApproved && isConferenceOrExternalCourse;

  const handleModalOk = () => {
    setHelpWindow(false);
    handleStudyPlanClickWithCheck();
  };

  const getRepeatTypeString = typeId => {
    switch (typeId) {
      case 1:
        return ''; // test
      case 2:
        return '/scorm';
      case 3:
        return '/video';
      case 4:
        return '/presentation';
      case 5:
        return '/article';

      default:
        return '';
    }
  };

  const handleModalCancel = () => setHelpWindow(false);

  const handleBlockClick = e => {
    e.stopPropagation();

    if ((isManagerTask && isWaitingStatus) || !isModalStatus) {
      return;
    }

    if (isRepetableManagerTask) {
      const blockId = e?.target?.getAttribute('data-id');
      const typeId = e?.target?.getAttribute('data-type-id');

      const blockType = getRepeatTypeString(+typeId);

      dispatch(
        setBlockRestart(blockId, blockType, () => {
          loadData();
        })
      );

      return;
    }

    setHelpWindow(true);
  };

  const renderTypeIcon = data => {
    switch (data.type.id) {
      case 1:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={testIcon} alt='' /> {data.type.name}
          </div>
        );

      case 3:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={videoIcon} alt='' /> {data.type.name}
          </div>
        );

      case 4:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={presentationIcon} alt='' /> {data.type.name}
          </div>
        );

      case 5:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={articleIcon} alt='' /> {data.type.name}
          </div>
        );

      case 6:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={testIcon} alt='' /> {data.type.name}
          </div>
        );

      default:
        return (
          <div className={css['ChaptersPreview-item-icon']}>
            <img style={{ paddingRight: '5px' }} src={scormIcon} alt='' /> {data.type.name}
          </div>
        );
    }
  };

  const block = (data, index, header = false) => {
    return header ? (
      <div
        className={classNames(css['ChaptersPreview-item-header'], header && css['ChaptersPreview-collapse'])}
        key={data.id}
      >
        {index && <div className={css['ChaptersPreview-item-index']}>{index.toString().padStart(2, '0')}</div>}
        {data.name}
      </div>
    ) : (
      <div
        className={classNames(css['ChaptersPreview-item'], header && css['ChaptersPreview-collapse'])}
        key={data.id}
        onClick={handleBlockClick}
        data-id={data.id}
        data-type-id={data.type.id}
      >
        <div className={css['ChaptersPreview-item-context']}>
          {index && <div className={css['ChaptersPreview-item-index']}>{index.toString().padStart(2, '0')}</div>}
          <Tooltip className={css['ChaptersPreview-item-context-name']} title={data.name.length > 40 && data.name}>
            {data.name}
          </Tooltip>
        </div>
        {renderTypeIcon(data)}
      </div>
    );
  };

  const isNullFooter =
    isWaitinigCorpCourse || isApprovedCorpCourse || isWaitingByUserConference || isApprovedConference;

  const modalTitle = isWaitinigCorpCourse
    ? t('inCoordination')
    : isApprovedCorpCourse
      ? t('approvedByAdmin')
      : isRepetableManagerTask
        ? t('repeat title')
        : t('questionTitle');

  let modalText = '';

  if (isWaitinigCorpCourse) {
    modalText = t('materialWaiting');
  } else if (isApprovedCorpCourse || isWaitingByUserConference || isApprovedConference) {
    modalText = t('approvedCorpCourse');
  } else if (isRepetableManagerTask) {
    modalText = t('repeat text');
  } else if (isWaitingConference) {
    modalText = t('materialWaiting');
  } else {
    modalText = t('questionContent');
  }

  const collapseItem = (chapter, index) => {
    return [
      {
        key: '1',
        label: block(chapter, index + 1, true),
        children: (
          <>
            {chapter.blocks.map(block => (
              <div
                key={block.id}
                data-id={block.id}
                data-type-id={block.type.id}
                className={css['ChaptersPreview-inner']}
                onClick={handleBlockClick}
              >
                <Tooltip className={css['ChaptersPreview-inner-name']} title={block.name?.length > 40 && block.name}>
                  {block.name}
                </Tooltip>
                {renderTypeIcon(block)}
              </div>
            ))}
          </>
        ),
      },
    ];
  };

  const handleModalConfirm = () => {
    setIsAudienceConfirmationModalOpen(false);
    if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
    } else {
      setShowCalendar();
    }
  };

  const handleStudyPlanClickWithCheck = () => {
    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      setIsAudienceConfirmationModalOpen(true);
    } else if (shouldCheckPreviousStudyPlans(topic)) {
      dispatch(checkPreviousStudyPlans(topic.id));
    } else {
      setShowCalendar();
    }
  };

  const buttonForPokaSkill = (
    <div className={css['ChaptersPreview-buttons']}>
      <Button size='large' onClick={handleStudyPlanClickWithCheck}>
        {t('startLater')}
      </Button>
      <Button size='large' type='primary' onClick={handleAddToPlanNow}>
        {t('startNow')}
      </Button>
    </div>
  );

  return chapters ? (
    <div className={css.ChaptersPreview}>
      <div className={css['ChaptersPreview-header']}>
        <div>
          <div className={css['ChaptersPreview-type']}>{t('plan')}</div>
          <div className={css['ChaptersPreview-title']}>{topic && topic.name}</div>
        </div>
      </div>
      {!isLoading ? (
        <>
          {chapters?.map((chapter, index) =>
            chapter.id ? (
              <Collapse
                key={chapter.id}
                className={css['ChaptersPreview-collapse-block']}
                expandIconPosition='end'
                bordered={false}
                defaultActiveKey={['1']}
                items={collapseItem(chapter, index)}
              />
            ) : (
              block(chapter.blocks?.[0], index + 1)
            )
          )}
          {buttonForPokaSkill}
        </>
      ) : null}
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
      <Modal
        centered={true}
        open={helpWindow}
        title={modalTitle}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={t('questionYesButton')}
        cancelText={t('questionNoButton')}
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
        {...(isNullFooter && { footer: null })}
      >
        <p className={css['ChaptersPreview-content']}>{modalText}</p>
      </Modal>
    </div>
  ) : null;
};

export default ChaptersPreview;
