/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { Avatar, Button, Modal } from 'antd';

import Checkbox from '../Checkbox';
import Icon from '../OldIcon';

import { COVER_IMAGE_TYPES } from '@shared/constants/image-types';

import classNames from 'classnames';
import { FastAverageColor } from 'fast-average-color';
import PropTypes from 'prop-types';

import css2 from '../FileInput/FileInput.module.scss';
import css from './ImageInput.module.scss';

import 'react-image-crop/dist/ReactCrop.css';

import galleryImage from '@/assets/images/gallery.png';

class ImageInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    fileInfo: PropTypes.object,
    imageSrc: PropTypes.string,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    loadAvatarUser: PropTypes.func,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc || '',
      nodeImg: null,
      previewImage: null,
      isOpen: false,
      isAspect: true,
      image: '#',
      imageUrl: null,
      crop: {
        x: 10,
        y: 10,
        aspect: 17 / 9,
        width: 45,
      },
      newImg: null,
      maskClosable: true,
      palette: [],
      error: false,
      croppedImageUrl: null,
      sizes: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isLoadingFileImage !== prevProps.isLoadingFileImage &&
      !prevProps.isLoadingFileImage &&
      this.state.croppedImageUrl
    ) {
      this.setState({
        imageSrc: window.URL.createObjectURL(this.state.croppedImageUrl),
        isOpen: !this.state.isOpen,
        croppedImageUrl: null,
        isAspect: true,
        crop: {
          x: 10,
          y: 10,
          aspect: 17 / 9,
          width: 45,
        },
        sizes: {},
        modalError: false,
      });
    }
  }

  // input: ?HTMLInputElement;
  input = null;

  setInputRef = input => {
    const a = COVER_IMAGE_TYPES.join(', ');
    input && input.setAttribute('accept', a);
    this.input = input;
    return this.input;
  };

  openFileDialog = () => {
    if (this.input) {
      this.input.click();
    }
  };

  handleFileChange = event => {
    event.preventDefault();
    const file = event.target.files[0];
    this.getImgSize(window.URL.createObjectURL(event.target.files[0]));
    if (file && file) {
      if (!COVER_IMAGE_TYPES.includes(file.type) || file.size > 10000000) {
        this.setState({ modalError: true, isAspect: true });
      } else {
        this.setState({
          isAspect: true,
          image: window.URL.createObjectURL(event.target.files[0]),
          imageUrl: event.target.files[0],
          defFile: file,
          isOpen: true,
        });
      }
    }
    event.target.value = null;
  };

  onCancel = () => {
    this.hideConfirmDialog();
    this.openFileDialog();
  };

  hideConfirmDialog = () => {
    this.setState({ modalError: false });
  };

  getImgSize = img => {
    const i = new Image();
    i.src = img;
    const that = this;
    i.onload = function () {
      that.setState({
        crop: {
          ...that.state.crop,
          height: (that.state.crop.width / that.state.crop.aspect) * (i.width / i.height),
          width:
            (that.state.crop.width / that.state.crop.aspect) *
            (i.width / i.height) *
            (that.state.crop.aspect / (i.width / i.height)),
        },
        sizes: {
          naturalWidth: i.width,
          naturalHeight: i.height,
        },
      });
    };
  };

  onChangeCrop = crop => {
    if (crop.width < 40 && this.state.isAspect) {
      this.setState({
        maskClosable: false,
      });
      return;
    }
    this.setState({
      maskClosable: false,
      crop,
    });
  };

  onCropComplete = async image => {
    const croppedImageUrl = await this.getCroppedImg(this.state.nodeImg || image);

    this.setState({ croppedImageUrl });
    setTimeout(() => {
      this.setState({
        maskClosable: true,
      });
    }, 200);
  };

  handleFileDelete = () => {
    const { onChange, name } = this.props;
    this.setState({ imageSrc: '' });
    onChange(null, name);
  };

  onImageLoaded = async image => {
    this.setState({ nodeImg: image }, async () => {
      await this.onCropComplete(image);
    });
  };

  getCroppedImg = image => {
    if (image && this.state.sizes.naturalWidth) {
      const canvas = document.createElement('canvas');
      const pixelCrop = this.state.crop;
      canvas.width = Math.round((this.state.sizes.naturalWidth * pixelCrop.width) / 100);
      canvas.height = Math.round((this.state.sizes.naturalHeight * pixelCrop.height) / 100);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        image,
        (this.state.sizes.naturalWidth * pixelCrop.x) / 100,
        (this.state.sizes.naturalHeight * pixelCrop.y) / 100,
        (this.state.sizes.naturalWidth * pixelCrop.width) / 100,
        (this.state.sizes.naturalHeight * pixelCrop.height) / 100,
        0,
        0,
        (this.state.sizes.naturalWidth * pixelCrop.width) / 100,
        (this.state.sizes.naturalHeight * pixelCrop.height) / 100
      );

      const base64 = canvas.toDataURL('image/jpeg');

      const blobBin = atob(base64.split(',')[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

      this.getPalette(base64);

      return new Promise(resolve => resolve(file));
    }
  };

  uploadFile = () => {
    const { onChange, name } = this.props;
    const formData = new FormData();
    formData.append('file', this.state.croppedImageUrl);
    onChange(formData, name, this.state.defFile, this.state.croppedImageUrl, this.state.color);
  };

  resetModal = () =>
    this.setState({
      previewImage: null,
      isOpen: !this.state.isOpen,
      imageUrl: null,
      croppedImageUrl: null,
      newImg: null,
      color: null,
      error: false,
      isAspect: true,
      crop: {
        x: 10,
        y: 10,
        aspect: 17 / 9,
        width: 45,
      },
      sizes: {},
    });

  handleChange = status => {
    this.setState({
      isAspect: !(status && status.value),
      crop: {
        ...this.state.crop,
        x: 10,
        y: 10,
        aspect: !(status && status.value) ? 17 / 9 : null,
        width: 45,
        height: !(status && status.value)
          ? ((((this.state.sizes.naturalWidth * 0.45) / 17) * 9) / this.state.sizes.naturalHeight) * 100
          : this.state.crop.height,
      },
    });
  };

  getPalette = imageSrc => {
    if (!imageSrc) {
      return;
    }
    const canvas = document.createElement('canvas');
    const pixelCrop = this.state.crop;
    canvas.height = 30;
    canvas.width = 30;
    const ctx = canvas.getContext('2d');

    const sourceImage = new Image();
    sourceImage.width = (this.state.sizes.naturalWidth * pixelCrop.width) / 100;
    sourceImage.height = (this.state.sizes.naturalHeight * pixelCrop.height) / 100;
    sourceImage.src = imageSrc;
    sourceImage.onload = () => {
      ctx.drawImage(
        sourceImage,
        0,
        sourceImage.height * 0.4,
        100,
        (this.state.sizes.naturalHeight * pixelCrop.height) / 100,
        0,
        0,
        sourceImage.width,
        sourceImage.height
      );
      setTimeout(() => {
        new FastAverageColor.getColorAsync(canvas)
          .then(color => {
            this.setState({
              color: color.hex,
            });
          })
          .catch(e => {
            this.setState({ color: null });
            console.error(e);
          });
      }, 1000);
    };
  };

  render() {
    const { className, t } = this.props;
    const { imageSrc } = this.state;
    const content = imageSrc ? (
      <div className={css['ImageInput-content']}>
        <div className={css['ImageInput-block']}>
          <Avatar className={css['ImageInput-avatar']} src={this.state.imageSrc} />
          <Icon type='close' className={css['ImageInput-avatar-delete']} onClick={this.handleFileDelete} />
        </div>
        <Button
          className={classNames({ [css.modificate]: this.props.modificate })}
          htmlFor='cover-img-file-input'
          onClick={this.openFileDialog}
          disabled={this.props.isLoadingFile || this.props.isLoadingFileImage}
          size='large'
        >
          {t('choose')}
        </Button>
      </div>
    ) : (
      <Button
        className={classNames({ [css.modificate]: this.props.modificate })}
        disabled={this.props.isLoadingFile || this.props.isLoadingFileImage}
        htmlFor='cover-img-file-input'
        onClick={this.openFileDialog}
        size='large'
      >
        {t('choose')}
      </Button>
    );
    return (
      <div className={classNames(css.ImageInput, className)}>
        {content}
        <input type='file' id='cover-img-file-input' ref={this.setInputRef} onChange={this.handleFileChange} />
        <Modal open={this.state.isOpen} onCancel={this.resetModal} maskClosable={this.state.maskClosable} footer={null}>
          <div className={css['modal-content--box']}>
            <div className={css['modal-content__title']}>{t('loading')}</div>
            <ReactCrop
              src={this.state.image}
              crop={this.state.crop}
              onChange={this.onChangeCrop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              minWidth={40}
              {...(!this.state.isAspect && {
                minWidth: 5,
                minHeight: 5,
              })}
              className={css.ReactCrop}
              keepSelection
            />
            <p className={css['modal-content__note']}>
              {this.state.error ? <div className={css['modal-content__note--error']}>{this.state.error}</div> : ''}
            </p>
            <div>
              <Checkbox
                onChange={this.handleChange}
                name='aspect'
                value={!this.state.isAspect}
                label={t('ratio')}
                big
              />
              <div>
                <p className={css['modal-content__note']}>
                  <span className={css['modal-content__note--info']}>{t('background')}</span>
                </p>
              </div>
            </div>
            <div className={css['modal-content__footer']}>
              <Button
                id='saveBtn'
                disabled={this.props.isLoadingFileImage}
                onClick={this.uploadFile}
                type='primary'
                size='large'
              >
                {t('save')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          modificate
          fileModal
          open={this.state.modalError}
          onOk={this.onCancel}
          onCancel={this.hideConfirmDialog}
          okText={t('add')}
        >
          <div className={css2['App-card-label']}>{t('cover')}</div>
          <div className={css2['App-card-info']}>
            <img src={galleryImage} alt='' />
            <div className={css2['App-card-info-item']}>
              <div className={css2['App-card-info-item-block']}>
                <h2>{t('images')}</h2>
                <div className={css2['App-card-info-little']}>{t('max')}</div>
              </div>
              <h3>{t('imagesTypes')}</h3>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation('imageInput')(ImageInput);
