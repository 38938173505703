/* eslint-disable */
import { API_URL } from '@shared/constants/config';
import { DELETE, GET, POST, PUT, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

export const POST_COMPILATION_START = 'POST_COMPILATION_START';
export const POST_COMPILATION_SUCCESS = 'POST_COMPILATION_SUCCESS';
export const POST_COMPILATION_FAIL = 'POST_COMPILATION_FAIL';

export const GET_THEMES_START = 'GET_THEMES_START';
export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS';
export const GET_THEMES_FAIL = 'GET_THEMES_FAIL';

export const UPDATE_THEME_START = 'UPDATE_THEME_START';
export const UPDATE_THEME_SUCCESS = 'UPDATE_THEME_SUCCESS';
export const UPDATE_THEME_FAIL = 'UPDATE_THEME_FAIL';

export const POST_THEME_START = 'POST_THEME_START';
export const POST_THEME_SUCCESS = 'POST_THEME_SUCCESS';
export const POST_THEME_FAIL = 'POST_THEME_FAIL';

export const DELETE_THEME_START = 'DELETE_THEME_START';
export const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
export const DELETE_THEME_FAIL = 'DELETE_THEME_FAIL';

export const CLEAR_COMPILATIONS = 'CLEAR_COMPILATIONS';
export const GET_COMPILATIONS_START = 'GET_COMPILATIONS_START';
export const GET_COMPILATIONS_SUCCESS = 'GET_COMPILATIONS_SUCCESS';
export const GET_COMPILATIONS_FAIL = 'GET_COMPILATIONS_FAIL';

export const GET_COMPILATION_START = 'GET_COMPILATION_START';
export const GET_COMPILATION_SUCCESS = 'GET_COMPILATION_SUCCESS';
export const GET_COMPILATION_FAIL = 'GET_COMPILATION_FAIL';

export const UPDATE_COMPILATION_START = 'UPDATE_COMPILATION_START';
export const UPDATE_COMPILATION_SUCCESS = 'UPDATE_COMPILATION_SUCCESS';
export const UPDATE_COMPILATION_FAIL = 'UPDATE_COMPILATION_FAIL';

export const DELETE_COMPILATION_START = 'DELETE_COMPILATION_START';
export const DELETE_COMPILATION_SUCCESS = 'DELETE_COMPILATION_SUCCESS';
export const DELETE_COMPILATION_FAIL = 'DELETE_COMPILATION_FAIL';

export const CLEAR_COMPILATIONS_COMMENTS = 'CLEAR_COMPILATIONS_COMMENTS';
export const GET_COMPILATIONS_COMMENTS_START = 'GET_COMPILATIONS_COMMENTS_START';
export const GET_COMPILATIONS_COMMENTS_SUCCESS = 'GET_COMPILATIONS_COMMENTS_SUCCESS';
export const GET_COMPILATIONS_COMMENTS_FAIL = 'GET_COMPILATIONS_COMMENTS_FAIL';

export const POST_COMPILATIONS_COMMENT_START = 'POST_COMPILATIONS_COMMENT_START';
export const POST_COMPILATIONS_COMMENT_SUCCESS = 'POST_COMPILATIONS_COMMENT_SUCCESS';
export const POST_COMPILATIONS_COMMENT_FAIL = 'POST_COMPILATIONS_COMMENT_FAIL';

export const UPDATE_COMPILATIONS_COMMENT_START = 'UPDATE_COMPILATIONS_COMMENT_START';
export const UPDATE_COMPILATIONS_COMMENT_SUCCESS = 'UPDATE_COMPILATIONS_COMMENT_SUCCESS';
export const UPDATE_COMPILATIONS_COMMENT_FAIL = 'UPDATE_COMPILATIONS_COMMENT_FAIL';

export const DELETE_COMPILATIONS_COMMENT_START = 'DELETE_COMPILATIONS_COMMENT_START';
export const DELETE_COMPILATIONS_COMMENT_SUCCESS = 'DELETE_COMPILATIONS_COMMENT_SUCCESS';
export const DELETE_COMPILATIONS_COMMENT_FAIL = 'DELETE_COMPILATIONS_COMMENT_FAIL';

export const RESTORE_COMPILATIONS_COMMENT_START = 'RESTORE_COMPILATIONS_COMMENT_START';
export const RESTORE_COMPILATIONS_COMMENT_SUCCESS = 'RESTORE_COMPILATIONS_COMMENT_SUCCESS';
export const RESTORE_COMPILATIONS_COMMENT_FAIL = 'RESTORE_COMPILATIONS_COMMENT_FAIL';

export const TOGGLE_FAVORITE_COMPILATIONS_START = 'TOGGLE_FAVORITE_COMPILATIONS_START';
export const TOGGLE_FAVORITE_COMPILATIONS_SUCCESS = 'TOGGLE_FAVORITE_COMPILATIONS_SUCCESS';
export const TOGGLE_FAVORITE_COMPILATIONS_FAIL = 'TOGGLE_FAVORITE_COMPILATIONS_FAIL';

export const CLEAR_PAGE = 'CLEAR_PAGE';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};
const defaultToken = '';
export const createCompilation = (compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/topic/compilation`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [POST_COMPILATION_START, POST_COMPILATION_SUCCESS, POST_COMPILATION_FAIL],
    onSuccess,
  };
};

export const updateCompilation = (id, compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [UPDATE_COMPILATION_START, UPDATE_COMPILATION_SUCCESS, UPDATE_COMPILATION_FAIL],
    onSuccess,
  };
};

export const deleteCompilation = (id, onSuccess) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [DELETE_COMPILATION_START, DELETE_COMPILATION_SUCCESS, DELETE_COMPILATION_FAIL],
    onSuccess,
  };
};

export const clearCompilations = () => ({
  type: CLEAR_COMPILATIONS,
});

export const getCompilations = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation${getQueryString({
      page: 0,
      size: 10,
      isVisible: true,
      sort: 'createdDate,desc',
      ...params,
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATIONS_START, GET_COMPILATIONS_SUCCESS, GET_COMPILATIONS_FAIL],
    onSuccess,
  };
};

export const clearCompilationComments = (params, onSuccess) => {
  return {
    type: CLEAR_COMPILATIONS_COMMENTS,
    types: [CLEAR_COMPILATIONS_COMMENTS],
    onSuccess,
  };
};

export const getCompilationComments = (params, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation/comment${getQueryString({
      page: 0,
      size: 10,
      ...params,
      sort: 'createdDate,desc',
    })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATIONS_COMMENTS_START, GET_COMPILATIONS_COMMENTS_SUCCESS, GET_COMPILATIONS_COMMENTS_FAIL],
    onSuccess,
  };
};

export const createCompilationComments = (commentVM, onSuccess) => ({
  type: REST_API,
  method: POST,
  url: `${API_URL}/topic/compilation/comment`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: commentVM,
  types: [POST_COMPILATIONS_COMMENT_START, POST_COMPILATIONS_COMMENT_SUCCESS, POST_COMPILATIONS_COMMENT_FAIL],
  onSuccess,
});

export const updateCompilationComment = (id, commentVM, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/compilation/comment/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: commentVM,
  types: [UPDATE_COMPILATIONS_COMMENT_START, UPDATE_COMPILATIONS_COMMENT_SUCCESS, UPDATE_COMPILATIONS_COMMENT_FAIL],
  onSuccess,
});

export const deleteCompilationComment = (id, onSuccess) => ({
  type: REST_API,
  method: DELETE,
  url: `${API_URL}/topic/compilation/comment/${id}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [DELETE_COMPILATIONS_COMMENT_START, DELETE_COMPILATIONS_COMMENT_SUCCESS, DELETE_COMPILATIONS_COMMENT_FAIL],
  onSuccess,
});

export const restoreCompilationComment = (id, onSuccess) => ({
  type: REST_API,
  method: PUT,
  url: `${API_URL}/topic/compilation/comment/${id}/restore`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  body: {},
  types: [RESTORE_COMPILATIONS_COMMENT_START, RESTORE_COMPILATIONS_COMMENT_SUCCESS, RESTORE_COMPILATIONS_COMMENT_FAIL],
  onSuccess,
});

export const toggleIsFavoriteCompilation = ({ id, hasFavorites }, onSuccess) => ({
  type: REST_API,
  ...(hasFavorites
    ? {
        method: DELETE,
        url: `${API_URL}/topic/compilation/${id}/favorites`,
      }
    : {
        method: POST,
        body: {},
        url: `${API_URL}/topic/compilation/${id}/favorites`,
      }),
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [TOGGLE_FAVORITE_COMPILATIONS_START, TOGGLE_FAVORITE_COMPILATIONS_SUCCESS, TOGGLE_FAVORITE_COMPILATIONS_FAIL],
  onSuccess,
});

export const getCompilation = (id, onSuccess) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/topic/compilation/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_COMPILATION_START, GET_COMPILATION_SUCCESS, GET_COMPILATION_FAIL],
    onSuccess,
  };
};

export const createTheme = (themeVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: POST,
    url: `${API_URL}/theme`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: themeVM,
    types: [POST_THEME_START, POST_THEME_SUCCESS, POST_THEME_FAIL],
    onSuccess,
  };
};

export const updateTheme = (compilationVM = {}, onSuccess) => {
  return {
    type: REST_API,
    method: PUT,
    url: `${API_URL}/theme/${compilationVM.id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    body: compilationVM,
    types: [UPDATE_THEME_START, UPDATE_THEME_SUCCESS, UPDATE_THEME_FAIL],
    onSuccess,
  };
};
export const deleteTheme = (id, onSuccess) => {
  return {
    type: REST_API,
    method: DELETE,
    url: `${API_URL}/theme/${id}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    responseType: 'text',
    types: [DELETE_THEME_START, DELETE_THEME_SUCCESS, DELETE_THEME_FAIL],
    onSuccess,
  };
};

export const getThemes = (onSuccess, userId, name) => {
  return {
    type: REST_API,
    method: GET,
    url: `${API_URL}/theme${getQueryString({ userId, name })}`,
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authorizationToken') || defaultToken,
    },
    types: [GET_THEMES_START, GET_THEMES_SUCCESS, GET_THEMES_FAIL],
    onSuccess,
  };
};

export const clearPage = () => ({
  type: CLEAR_PAGE,
});
