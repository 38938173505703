export const TOPIC_SUBTYPES = (t: any) => [
  {
    name: t('constants:online'),
    active: true,
    id: 12,
    typeId: 1,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'startDate',
        required: false,
      },
      {
        type: 'startTime',
        required: false,
      },
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 13,
    typeId: 1,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'topicClass',
        required: false,
      },
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'startDate',
        required: false,
      },
      {
        type: 'startTime',
        required: false,
      },
      {
        type: 'company',
        required: false,
      },
      {
        type: 'meetPlace',
        required: false,
      },
    ],
  },
  {
    name: t('constants:online'),
    active: true,
    id: 18,
    typeId: 17,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'cost',
        required: true,
      },
      /* {
        type: 'startDate',
        required: false
      },
      {
        type: 'startTime',
        required: false
      }, */
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 19,
    typeId: 17,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'topicClass',
        required: false,
      },
      {
        type: 'cost',
        required: true,
      },
      /* {
        type: 'startDate',
        required: false
      },
      {
        type: 'startTime',
        required: false
      }, */
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
      {
        type: 'meetPlace',
        required: false,
      },
    ],
  },
  {
    name: t('constants:online'),
    active: true,
    id: 21,
    typeId: 20,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'cost',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
    ],
  },
  {
    name: t('constants:offline'),
    active: true,
    id: 22,
    typeId: 20,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: false,
      },
      {
        type: 'topicClass',
        required: false,
      },
      {
        type: 'cost',
        required: true,
      },
      {
        type: 'durationCourses',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
      {
        type: 'meetPlace',
        required: false,
      },
    ],
  },
  {
    name: t('constants:mixed'),
    active: false,
    id: 14,
    typeId: 1,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'teachers',
        required: false,
      },
      {
        type: 'durationCourses',
        required: false,
      },
      {
        type: 'startDate',
        required: true,
      },
      {
        type: 'startTime',
        required: true,
      },
      {
        type: 'company',
        required: false,
      },
      {
        type: 'meetPlace',
        required: false,
      },
    ],
  },
  {
    name: t('constants:article'),
    active: false,
    id: 9,
    typeId: 2,
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    name: t('constants:eBook'),
    active: false,
    id: 10,
    typeId: 2,
    fields: [
      { type: 'files', required: false },
      { type: 'links', required: false },
      { type: 'authors', required: true },
      { type: 'publisher', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    name: t('constants:paperBook'),
    active: false,
    id: 11,
    typeId: 2,
    fields: [
      {
        type: 'links',
        required: true,
      },
      {
        type: 'files',
        required: false,
      },
      {
        type: 'authors',
        required: true,
      },
      {
        type: 'publisher',
        required: false,
      },
      {
        type: 'qiwiLibrary',
        required: true,
      },
      {
        type: 'issued',
        required: false,
      },
    ],
  },
];

export const TOPIC_FIELD = (t: any) => [
  {
    id: 1,
    name: t('constants:course'),
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'startDate',
        name: t('constants:startDate'),
      },
      {
        type: 'durationCourses',
        name: t('constants:dur'),
      },
      {
        type: 'publisher',
        name: t('constants:company'),
      },
    ],
  },
  {
    id: 17,
    name: t('constants:paid'),
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'durationCourses',
        name: t('constants:dur'),
      },
      {
        type: 'publisher',
        name: t('constants:company'),
      },
    ],
  },
  {
    id: 4,
    name: t('constants:video'),
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'hoursDuration',
        name: t('constants:duration'),
      },
    ],
  },
  {
    id: 5,
    name: t('constants:game'),
    fields: [
      {
        type: 'cost',
        name: t('constants:cost'),
      },
    ],
  },
  {
    id: 8,
    name: t('constants:event'),
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'cost',
        name: t('constants:cost'),
      },
      {
        type: 'startDate',
        name: t('constants:startDate'),
      },
      {
        type: 'duration',
        name: t('constants:duration'),
      },
    ],
  },
  {
    id: 23,
    name: t('constants:conference'),
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'cost',
        name: t('constants:cost'),
      },
      {
        type: 'startDate',
        name: t('constants:startDate'),
      },
      {
        type: 'duration',
        name: t('constants:duration'),
      },
    ],
  },
  {
    id: 15,
    name: t('constants:article'),
    active: true,
    code: 'text',
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'year',
        name: t('constants:year'),
      },
    ],
  },
  {
    id: 16,
    name: t('constants:book'),
    active: true,
    code: 'book',
    fields: [
      {
        type: 'authors',
        name: t('constants:authors'),
      },
      {
        type: 'publisher',
        name: t('constants:publisher'),
      },
      {
        type: 'year',
        name: t('constants:year'),
      },
    ],
  },
];

export const TOPIC_TYPE = (t: any) => [
  {
    id: 1,
    params: 'typeId',
    name: t('constants:learn'), // Учебные курсы
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:inner'),
        params: 'topicTypeId',
        sort: '17&topicTypeId=35',
      },
      {
        name: t('constants:providers'),
        params: 'topicTypeId',
        sort: '20',
      },
      {
        name: t('constants:online'),
        params: 'topicTypeId',
        sort: '1 ',
      },
      {
        name: t('constants:meet'),
        params: 'topicSubTypeId',
        sort: '19&topicSubTypeId=22',
      },
      {
        name: t('constants:kampus'),
        params: 'hasBlock',
        sort: 'true',
      },
    ],
  },
  {
    id: 2,
    params: 'typeId',
    name: t('constants:events'), // События
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:actual'),
        params: 'actual',
        sort: 'true',
      },
      {
        name: t('constants:events'),
        params: 'topicTypeLabelId',
        sort: '6',
      },
      // {
      //   name: t('constants:conference'),
      //   params: 'topicTypeLabelId',
      //   sort: '3',
      // },
      {
        name: t('constants:onlineEvent'),
        params: 'isOnline',
        sort: 'true',
      },
      {
        name: t('constants:openSession'),
        params: 'isOpenSession',
        sort: 'true',
      },
    ],
  },
  {
    id: 3,
    params: 'typeId',
    name: t('constants:library'), // Библиотека
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:books'),
        params: 'topicTypeLabelId',
        sort: '7',
      },
      {
        name: t('constants:articles'), // Статья
        params: 'topicTypeLabelId',
        sort: '8',
      },
      {
        name: t('constants:test'), // Тест
        params: 'topicTypeLabelId',
        sort: '9',
      },
      {
        name: t('constants:survey'), // Опрос
        params: 'topicTypeLabelId',
        sort: '10',
      },
      // {
      //   name: t('constants:infographics'), // Инфографика
      //   params: 'topicTypeLabelId',
      //   sort: '11',
      // },
      {
        name: t('constants:video'), // Видео
        params: 'topicTypeLabelId',
        sort: '12',
      },
      {
        name: t('constants:podcasts'), // Подкасты
        params: 'topicTypeLabelId',
        sort: '13',
      },
      // {
      //   name: t('constants:apps'), // Приложение
      //   params: 'topicTypeLabelId',
      //   sort: '14',
      // },
      {
        name: t('constants:kampus'),
        params: 'hasBlock',
        sort: 'true',
      },
      {
        name: t('constants:community'),
        params: 'topicTypeLabelId',
        sort: '15',
      },
    ],
  },
  {
    id: 4,
    params: 'typeId',
    name: t('constants:practice'), // Практика
    fields: [
      {
        name: t('constants:executive'),
        params: 'topicTypeLabelId',
        sort: '4',
      },
    ],
  },
  {
    id: 5,
    params: 'typeId',
    name: t('constants:skills'), // Навыки
    fields: [],
  },
];

export const TOPIC_TYPE_COLLECTION = (t: any) => [
  {
    id: 1,
    params: 'typeId',
    name: t('constants:learn'), // Учебные курсы
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:inner'),
        params: 'topicTypeId',
        sort: '17',
      },
      {
        name: t('constants:providers'),
        params: 'topicTypeId',
        sort: '20',
      },
      {
        name: t('constants:online'),
        params: 'topicSubTypeId',
        sort: '18&topicSubTypeId=21&topicSubTypeId=24',
      },
      {
        name: t('constants:meet'),
        params: 'topicSubTypeId',
        sort: '19&topicSubTypeId=22',
      },
    ],
  },
  {
    id: 2,
    params: 'typeId',
    name: t('constants:events'),
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:actual'),
        params: 'actual',
        sort: 'true',
      },
      {
        name: t('constants:events'),
        params: 'topicTypeLabelId',
        sort: '6',
      },
      // {
      //   name: t('constants:conference'),
      //   params: 'topicTypeLabelId',
      //   sort: '3',
      // },
    ],
  },
  {
    id: 3,
    params: 'typeId',
    name: t('constants:library'), // Библиотека
    fields: [
      {
        name: t('constants:free'),
        params: 'free',
        sort: 'true',
      },
      {
        name: t('constants:books'),
        params: 'topicTypeLabelId',
        sort: '7',
      },
      {
        name: t('constants:articles'), // Статья
        params: 'topicTypeLabelId',
        sort: '8',
      },
      {
        name: t('constants:test'), // Тест
        params: 'topicTypeLabelId',
        sort: '9',
      },
      {
        name: t('constants:survey'), // Опрос
        params: 'topicTypeLabelId',
        sort: '10',
      },
      // {
      //   name: t('constants:infographics'), // Инфографика
      //   params: 'topicTypeLabelId',
      //   sort: '11',
      // },
      {
        name: t('constants:video'), // Видео
        params: 'topicTypeLabelId',
        sort: '12',
      },
      {
        name: t('constants:podcasts'), // Подкасты
        params: 'topicTypeLabelId',
        sort: '13',
      },
      // {
      //   name: t('constants:apps'), // Приложение
      //   params: 'topicTypeLabelId',
      //   sort: '14',
      // },
      {
        name: t('constants:kampus'),
        params: 'hasBlock',
        sort: 'true',
      },
      {
        name: t('constants:community'),
        params: 'topicTypeLabelId',
        sort: '15',
      },
    ],
  },
];

export const TOPIC_TYPES = (t: any) => [
  {
    id: 1,
    name: t('constants:course'),
    active: true,
    code: 'course',
    fields: [
      { type: 'links' },
      { type: 'files' },
      { type: 'authors' },
      { type: 'topicClass' },
      { type: 'startDate' },
      { type: 'startTime' },
      { type: 'durationCourses' },
      { type: 'company' },
      { type: 'meetPlace' },
    ],
  },
  {
    id: 17,
    name: t('constants:corp'),
    active: true,
    code: 'cost',
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'cost', required: false },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    id: 35,
    name: t('constants:corp'),
    active: true,
    code: 'costwithoutapp',
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'cost', required: false },
      { type: 'topicClass', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    id: 20,
    name: t('constants:outer'),
    active: true,
    code: 'external',
    fields: [
      { type: 'links', required: true },
      { type: 'authors', required: false },
      { type: 'cost', required: false },
      { type: 'durationCourses', required: true },
      { type: 'company', required: false },
      { type: 'meetPlace', required: false },
    ],
  },
  {
    id: 2,
    name: t('constants:text'),
    active: true,
    code: 'text',
    fields: [
      { type: 'files' },
      { type: 'links' },
      { type: 'authors' },
      { type: 'publisher' },
      { type: 'qiwiLibrary' },
      { type: 'issued' },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 3,
    name: t('constants:audio'),
    active: true,
    code: 'audio',
    fields: [
      { type: 'files', required: true },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'duration', required: true },
      { type: 'issued', required: false },
    ],
  },
  {
    id: 4,
    name: t('constants:video'),
    active: true,
    code: 'video',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'hoursDuration', required: true },
    ],
  },
  {
    id: 5,
    name: t('constants:game'),
    active: true,
    code: 'game',
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'cost', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 6,
    name: t('constants:img'),
    active: true,
    code: 'img',
    fields: [
      { type: 'files', required: true },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 7,
    name: t('constants:presa'),
    active: true,
    code: 'presa',
    fields: [
      { type: 'files', required: false },
      { type: 'links', required: false },
      { type: 'authors', required: false },
      { type: 'company', required: false },
      { type: 'duration', required: false },
      { type: 'issued', required: false },
    ],
  },
  {
    id: 8,
    name: t('constants:event'),
    active: true,
    code: 'event',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'cost', required: false },
      { type: 'company', required: false },
      { type: 'schedule', required: true },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 23,
    name: t('constants:conference'),
    active: true,
    code: 'conference',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'cost', required: false },
      { type: 'company', required: false },
      { type: 'schedule', required: true },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 15,
    name: t('constants:article'),
    active: true,
    code: 'text',
    fields: [
      { type: 'links', required: true },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'year', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 16,
    name: t('constants:book'),
    active: true,
    code: 'book',
    fields: [
      { type: 'links', required: false },
      { type: 'files', required: false },
      { type: 'authors', required: false },
      { type: 'publisher', required: false },
      { type: 'qiwiLibrary', required: false },
      { type: 'cost', required: false },
      { type: 'year', required: false },
      { type: 'hoursDuration', required: false },
    ],
  },
  {
    id: 25,
    name: t('constants:executive'),
    active: true,
    code: 'executive',
    fields: [
      { type: 'files', required: true },
      { type: 'executive_duration', required: true },
      { type: 'executive_activity', required: true },
      { type: 'executive_results', required: true },
      { type: 'executive_indicators', required: false },
      { type: 'executive_needfile', required: true },
      { type: 'hoursDuration' },
    ],
  },
  {
    id: 38,
    name: t('constants:pokaskills'),
    active: true,
    code: 'pokaSkills',
  },
];

export const COMMON_FIELDS = [
  {
    apiField: 'name',
    required: true,
  },
  {
    apiField: 'typeId',
    required: true,
  },
  {
    apiField: 'description',
    required: true,
  },
  {
    apiField: 'coverFileUuid',
    required: false,
  },
  {
    apiField: 'tagNames',
    required: false,
  },
  {
    apiField: 'qiwi',
    required: true,
  },
  {
    apiField: 'categoryIds',
    required: true,
  },
  {
    apiField: 'levelId',
    required: true,
  },
  {
    apiField: 'languageId',
    required: true,
  },
];

export const FIELDS = (t: any) => ({
  files: {
    apiField: 'fileUuids',
    responseField: 'files',
    type: 'files',
    label: t('constants:files'),
  },
  links: {
    apiField: 'links',
    type: 'tagsInput',
    label: t('constants:links'),
  },
  lectors: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:lectors'),
  },
  videoStyle: {
    apiField: 'videoTypeId',
    responseField: 'videoType',
    type: 'videoStyleselector',
    label: t('constants:videoTypeId'),
  },
  topicClass: {
    apiField: 'topicClassId',
    responseField: 'topicClass',
    type: 'topicClassSelector',
    label: t('constants:topicClassId'),
  },
  company: {
    apiField: 'publisher',
    type: 'inputCompany',
    label: t('constants:company'),
  },
  durationCourses: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:duration'),
  },
  duration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'week',
    label: t('constants:dur'),
  },
  weeksDuration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'week',
    label: t('constants:duration'),
  },
  schedule: {
    apiField: 'dates',
    type: 'schedule',
    label: t('constants:dates'),
  },
  hoursDuration: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:duration'),
  },
  cost: {
    apiField: 'cost',
    type: 'numberInput',
    label: t('constants:cost'),
  },
  year: {
    apiField: 'issued',
    type: 'yearIssued',
    label: t('constants:year'),
  },
  publisher: {
    apiField: 'publisher',
    type: 'inputPublisher',
    label: t('constants:publisher'),
  },
  authors: {
    apiField: 'authors',
    type: 'authorsInput',
    label: t('constants:authors'),
  },
  teachers: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:teacher'),
  },
  startDate: {
    apiField: 'startDate',
    type: 'calendar',
    label: t('constants:startDate'),
  },
  startTime: {
    apiField: 'startTime',
    type: 'dateTimeInput',
    label: t('constants:startTime'),
  },
  meetPlace: {
    apiField: 'meetPlace',
    type: 'inputPlace',
    label: t('constants:meetPlace'),
  },
  qiwiLibrary: {
    apiField: 'qiwiLibrary',
    type: 'checkbox',
    label: t('constants:qiwiLibrary'),
  },
  executive_duration: {
    apiField: 'executive_duration',
    type: 'timeInput',
    label: t('constants:executive_duration'),
  },
  executive_activity: {
    apiField: 'executive_activity',
    type: 'textArea',
    label: t('constants:executive_activity'),
  },
  executive_results: {
    apiField: 'executive_results',
    type: 'textArea',
    label: t('constants:executive_results'),
  },
  executive_indicators: {
    apiField: 'executive_indicators',
    type: 'textArea',
    label: t('constants:executive_indicators'),
  },
  executive_needfile: {
    apiField: 'executive_needfile',
    type: 'checkbox',
    label: t('constants:executive_needfile'),
  },
});

export const PUBLIC_FIELDS = (t: any) => ({
  lectors: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:lectors'),
  },
  videoStyle: {
    apiField: 'videoTypeId',
    responseField: 'videoType',
    type: 'videoStyleselector',
    label: t('constants:videoTypeId'),
  },
  topicClass: {
    apiField: 'topicClassId',
    responseField: 'topicClass',
    type: 'topicClassSelector',
    label: t('constants:videoTypeId'),
  },
  company: {
    apiField: 'publisher',
    type: 'input',
    label: t('constants:company'),
  },
  durationCourses: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:dur'),
  },
  duration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'day',
    label: t('constants:duration'),
  },
  weeksDuration: {
    apiField: 'duration',
    type: 'timeInput',
    startWith: 'week',
    label: t('constants:duration'),
  },
  hoursDuration: {
    apiField: 'duration',
    type: 'timeInput',
    label: t('constants:duration'),
  },
  cost: {
    apiField: 'cost',
    type: 'numberInput',
    label: t('constants:cost'),
  },
  year: {
    apiField: 'issued',
    type: 'yearIssued',
    label: t('constants:year'),
  },
  publisher: {
    apiField: 'publisher',
    type: 'inputPublisher',
    label: t('constants:publisher'),
  },
  authors: {
    apiField: 'authors',
    type: 'authorsInput',
    label: t('constants:authors'),
  },
  teachers: {
    apiField: 'lectors',
    type: 'input',
    label: t('constants:teacher'),
  },
  startDate: {
    apiField: 'startDate',
    type: 'calendar',
    label: t('constants:startDate'),
  },
  startTime: {
    apiField: 'startTime',
    type: 'dateTimeInput',
    label: t('constants:startTime'),
  },
  meetPlace: {
    apiField: 'meetPlace',
    type: 'input',
    label: t('constants:meetPlace'),
  },
  qiwiLibrary: {
    apiField: 'qiwiLibrary',
    type: 'checkbox',
    label: t('constants:qiwiLibrary'),
  },
});

export const COMPILATIONS_TYPE = (t: any) => [
  { name: t('constants:title'), type: 'COMPILATION' },
  { name: t('constants:expert'), type: 'EXPERT' },
  { name: t('constants:skill'), type: 'SKILL' },
];
