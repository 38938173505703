/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getAlpinaCredentials } from '@/store/topics/actions';

import PropTypes from 'prop-types';

import css from './Header.module.scss';

export const TradeLink = props => {
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);
  const [domain, setDomain] = useState(null);

  useEffect(() => {
    if (props && props.id && props.url && props.name && props.domain) {
      setId(props.id);
      setUrl(props.url);
      setName(props.name);
      setDomain(props.domain);
    }
  }, [props, props.id, props.url, props.name, props.domain]);

  useEffect(() => {
    if (url !== null && url.search('alpina') > -1) {
      dispatch(
        getAlpinaCredentials(domain, response => {
          if (response.url) {
            setUrl(response.url);
          }
        })
      );
    }
  }, [url]);

  let link = undefined;

  if (id !== null) {
    if (props.elementType === 'mobileMenu') {
      link = (
        <a
          id={`tradelink-${id}`}
          className={css['App-mobile-header-dropdown-link']}
          target='_blank'
          rel='noopener noreferrer'
          href={url}
        >
          <span className={css['App-mobile-header-dropdown-item']}>{name}</span>
        </a>
      );
    } else {
      link = (
        <a id={`tradelink-${id}`} target='_blank' rel='noopener noreferrer' href={url}>
          {name}
        </a>
      );
    }
  }

  return link;
};

TradeLink.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
};

export default TradeLink;
