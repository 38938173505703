// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-c_W5UQ.user-src-pages-ShopId-Tabs-Tabs-module__ql-font-cauAsp .user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-label-Fvvzrv[data-value="Montserrat"]:before, .user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-c_W5UQ.user-src-pages-ShopId-Tabs-Tabs-module__ql-font-cauAsp .user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-item-R7dRYN[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-ShopId-Tabs-Tabs-module__ql-font-Montserrat-EhXrEj {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-ShopId-Tabs-Tabs-module__Tabs-about-text-TKOYbj {
  white-space: pre-wrap;
  word-break: break-word;
  color: #000;
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/ShopId/Tabs/Tabs.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,qBAAqB;EACrB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Tabs-about-text {\n  white-space: pre-wrap;\n  word-break: break-word;\n  color: #000;\n  font-size: 16px;\n  line-height: 24px;\n  margin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-c_W5UQ`,
	"qlPicker": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-c_W5UQ`,
	"ql-font": `user-src-pages-ShopId-Tabs-Tabs-module__ql-font-cauAsp`,
	"qlFont": `user-src-pages-ShopId-Tabs-Tabs-module__ql-font-cauAsp`,
	"ql-picker-label": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-label-Fvvzrv`,
	"qlPickerLabel": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-label-Fvvzrv`,
	"ql-picker-item": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-item-R7dRYN`,
	"qlPickerItem": `user-src-pages-ShopId-Tabs-Tabs-module__ql-picker-item-R7dRYN`,
	"ql-font-Montserrat": `user-src-pages-ShopId-Tabs-Tabs-module__ql-font-Montserrat-EhXrEj`,
	"qlFontMontserrat": `user-src-pages-ShopId-Tabs-Tabs-module__ql-font-Montserrat-EhXrEj`,
	"Tabs-about-text": `user-src-pages-ShopId-Tabs-Tabs-module__Tabs-about-text-TKOYbj`,
	"tabsAboutText": `user-src-pages-ShopId-Tabs-Tabs-module__Tabs-about-text-TKOYbj`
};
export default ___CSS_LOADER_EXPORT___;
