import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getEventsInCourse, getOpenDate } from '@/store/course/actions';
import {
  addUserEvent,
  checkStudyPlan,
  checkWaitingListInfo,
  createStudyPlan,
  eventRegistrAction,
  updateButtonFunc,
  updateStudyPlanDate,
  waitingListAction,
} from '@/store/study-plan/actions';

import { selectCourse as courseSelect } from '@/store/course/selectors';
import { selectStudyPlan as planSelect } from '@/store/study-plan/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Input, Layout, Modal as ModalAntd, Popover, Spin, Table, Tooltip } from 'antd';
import { message } from 'antd/lib';
import CalendarModal from '@/pages/CalendarModal/index.jsx';
import Modal from '@/components/Modal';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EventCost from '../EventCost';
import { SchedulePopoverData } from '../EventRow/SchedulePopover';
import AudienceConfirmationModal from '../ModalTargetAudience/Modal';

import { formatDateDayjs } from '@shared/utils/format-date';
import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isConfOrAllCourse,
  isCorpAllCourse,
  isCorpCourseWithOrWithoutApprove,
  isCorporateCourseWithoutApprove,
  isCorpOrExtCourseWithOrWithoutApp,
  isExternalCourse,
  isTestCourses,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import css from './MaterialsTabs.module.scss';

import {
  APPROVED_BY_USER,
  ON_REGISTRATION_STATUS,
  PLANNED,
  REJECTED_BY_USER,
  SCHEDULED,
  WAITING_BY_USER,
} from '@/constants/study-plan-statuses';
import { ON_WAITING_LIST /*REGISTERED*/ } from '@/constants/waiting-list-status';

const { Content } = Layout;
const { TextArea } = Input;

// в 6970 удалил APPROVED
const updateStatusTopicWithoutApprove = [APPROVED_BY_USER, PLANNED, REJECTED_BY_USER, WAITING_BY_USER];

class MaterialsTabsEvents extends Component {
  static propTypes = {
    checkStudyPlan: PropTypes.func,
    createStudyPlan: PropTypes.func,
    waitingListAction: PropTypes.func,
    checkWaitingListInfo: PropTypes.func,
    eventRegistrAction: PropTypes.func,
    currentTopicId: PropTypes.number,
    currentUserId: PropTypes.number,
    endedCount: PropTypes.number,
    endedEvents: PropTypes.array,
    eventCount: PropTypes.number,
    events: PropTypes.array,
    getEventsInCourse: PropTypes.func,
    getOpenDate: PropTypes.func,
    isLoading: PropTypes.bool,
    loadMore: PropTypes.bool,
    page: PropTypes.number,
    planIsLoading: PropTypes.bool,
    status: PropTypes.string,
    studyPlanId: PropTypes.object,
    topic: PropTypes.object,
    topicId: PropTypes.number,
    topicStatus: PropTypes.string,
    topicType: PropTypes.number,
    topicTypeName: PropTypes.string,
    updateButtonFunc: PropTypes.func,
    currentUser: PropTypes.object,
    checkPreviousStudyPlansFunc: PropTypes.func,
    waitingList: PropTypes.any,
    updateStudyPlanDate: PropTypes.func,
    addUserEvent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInPlan: {},
      modalVisible: false,
      studyPlanId: props.studyPlanId,
      startEndedLoad: false,
      independent: this.props.topic && this.props.topic.independent,
      isModalCurrentPaidTopicOpen: false,
      isModalPaidTopicRestrictionOpen: false,
      data: null,
      calendarModalVisible: false,
      comment: '',
      selectedEvent: null,
      toCalendar: false,
      checkEventDate: null,
      topicInfo: null,
      isPopoverScheludeOpen: false,
      popoverRowData: {},
      isAudienceConfirmationModalOpen: false,
      studyPlanModalInfo: {},
    };
  }

  componentDidMount() {
    this.props.getOpenDate();
    this.props.getEventsInCourse({
      topicId: this.props.topicId,
      isEnded: false,
    });
    if (this.props?.topicId && isConfOrAllCourse(this.props?.topicType)) {
      this.props.checkWaitingListInfo(this.props?.topicId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.studyPlanId !== nextProps.studyPlanId) {
      this.setState({ studyPlanId: nextProps.studyPlanId });
    }
  }

  // в аргументах ничего не приходит
  createStudyPlan = (planningTopicId, selectedMonths, comment, withoutSchedule) => {
    const userId = this.props.currentUserId;
    const topicId = this.props.topicId;
    const studyPlanId = this.props?.studyPlanId?.id;
    const planDate = dayjs(this.state.data || selectedMonths[0]).format('YYYY-MM-DD');
    const onSuccess = () => {
      if (!this.state.calendarModalVisible) {
        this.setState({ modalVisible: !this.state.modalVisible });
      } else {
        this.setState({ data: dayjs(selectedMonths[0], 'YYYY-MM-DD') });
      }

      this.setState({ calendarModalVisible: false });
      this.props.updateButtonFunc();
    };
    const commentStudyPlan = comment || this.state.comment;

    const isAnother = false;
    const withoutScheduleResult = getWithoutSchedule(
      this.props.topicType,
      withoutSchedule || (isCorpCourseWithOrWithoutApprove(this.props.topicType) && this.props.eventCount === 0)
    );

    const update35Posibility =
      isCorporateCourseWithoutApprove(this.props.topicType) &&
      updateStatusTopicWithoutApprove.includes(this.props?.studyPlanId.status);

    const updateStudyPlanPosibility = isTestCourses(this.props.topicType) && this.props?.studyPlanId?.id;

    if (updateStudyPlanPosibility) {
      this.props.updateStudyPlanDate(
        studyPlanId,
        planDate,
        commentStudyPlan,
        onSuccess,
        true,
        this.state.selectedEvent
      );
    } else if (update35Posibility) {
      this.props.addUserEvent(this.state.selectedEvent, [this.props?.studyPlanId?.requestId], onSuccess);
    } else {
      this.props.createStudyPlan(
        userId,
        topicId,
        planDate,
        commentStudyPlan,
        onSuccess,
        isAnother,
        withoutScheduleResult,
        undefined,
        this.state?.selectedEvent // Для MARS (KAM-7787)
      );
    }
  };

  // в Modal onOk ни один параметр не передается
  handleStudyPlanClick = (planningTopicId, selectedMonths, comment, withoutSchedule) => {
    if (this.state?.singUpFlag && isConfOrAllCourse(this.props.topicType)) {
      this.props.eventRegistrAction(
        this.state.selectedEvent,
        () => {
          this.createStudyPlan(planningTopicId, selectedMonths, comment, withoutSchedule);
        },
        err => message.error(this.props.t('error'))
      );
    } else {
      this.createStudyPlan(planningTopicId, selectedMonths, comment, withoutSchedule);
    }
  };

  handleModalCancel = () => {
    this.setState({ isAudienceConfirmationModalOpen: false });
  };

  handleStudyPlanModal = (data, selectedEvent, singUpFlag) => {
    if (this.state.isAudienceConfirmationModalOpen) this.setState({ isAudienceConfirmationModalOpen: false });

    if (this.props.checkPreviousStudyPlansFunc(this.props.topic)) {
      return;
    }
    if (data) {
      this.setState({ data: dayjs(data, 'YYYY-MM-DD') });
    }
    if (selectedEvent) {
      this.setState({ selectedEvent });
    }
    this.setState({ modalVisible: !this.state.modalVisible, singUpFlag });
  };

  handleCalendarModal = params => {
    if (this.props.checkPreviousStudyPlansFunc(this.props.topic)) {
      return;
    }
    // toCalendar: bool
    this.setState({
      topicInfo: {
        ...this.props.topic,
        link: this.props.topic.firstLink,
        topicCost: this.props.topic.cost,
        eventCount: this.props.topic.eventCount,
      },
      calendarModalVisible: !this.state.calendarModalVisible,
      toCalendar: params?.toCalendar,
      checkEventDate: params?.checkEventDate,
    });
  };

  changeComment = event => {
    const text = event.target.value;
    this.setState({ comment: text });
  };

  loadEndedEvents = (params, onButton) => {
    if (onButton) {
      this.setState({ startEndedLoad: true });
    }
    this.props.getEventsInCourse(
      {
        topicId: this.props.topicId,
        size: params.size,
        page: this.props.page,
        isEnded: true,
      },
      res => {
        if (res.length === 0) {
          this.setState({ startEndedLoad: true });
        }
      }
    );
  };

  getTimeFromMins = mins => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;
    if (hours === 0 && hours === 0) {
      return `${minutes} м.`;
    }
    if (minutes > 0 && hours !== 0) {
      return `${hours} ${this.props.t('hour1')} ${minutes} ${this.props.t('minutes')}`;
    }
    return `${hours} ${this.props.t('hour1')}`;
  };

  toCalendarClick = () => this.handleCalendarModal({ toCalendar: true });

  render() {
    const { events, endedEvents, topicType, isLoading, loadMore, status, planIsLoading, topicTypeName, endedCount, t } =
      this.props;
    const { studyPlanId, isAudienceConfirmationModalOpen, studyPlanModalInfo } = this.state;
    const eventDisabled = status === 'DISABLED';

    const columnsEvent = endedKey => {
      return [
        {
          dataIndex: 'date',
          key: endedKey + 'date',
          className: css['MaterialsTabsEventsTable-table-date'],
        },
        {
          dataIndex: 'cost',
          key: endedKey + 'cost',
        },
        {
          dataIndex: 'time',
          key: endedKey + 'time',
          className: css['MaterialsTabsEventsTable-table-time'],
        },
        {
          dataIndex: 'status',
          key: endedKey + 'status',
        },
        {
          dataIndex: 'deadline',
          key: endedKey + 'deadline',
          className: css['MaterialsTabsEventsTable-table-deadline'],
        },
        {
          dataIndex: 'availableSeats',
          key: endedKey + 'availableSeats',
          className: css['MaterialsTabsEventsTable-table-availableSeats'],
        },
        {
          dataIndex: 'btn',
          key: endedKey + 'btn',
        },
      ];
    };

    const getDataEvent = eventArr => {
      return eventArr?.map((item, index) => {
        const checkBtnStatus =
          this.state?.studyPlanId?.status === ON_REGISTRATION_STATUS ||
          this.state?.studyPlanId?.status === WAITING_BY_USER;
        const correctBtnStatus = isCorpAllCourse(this.props.topicType) ? !checkBtnStatus : true;

        const isManyEvents = Array.isArray(item?.periods) && item?.periods?.length > 1;
        const eventExpiredDate = dayjs(item?.deadlineRegistration).diff(dayjs(), 'day') < 0;

        const hoursOffset =
          this.props.currentUser.timezone.offset / 60 - this.props.currentUser.domainCompany.timezone.offset / 60;

        const isPossiblySignUp =
          (item.placeCount > 0 || (item.placeCount === 0 && item.approvedCount + item.registerCount === 0)) &&
          !eventExpiredDate;

        const startTime = formatDateDayjs(
          dayjs(item.startTime).add(hoursOffset, 'H'),
          'DD MMM',
          this.props.currentUser.language
        );

        const isNotManyEventsContent =
          dayjs(item.startTime).format('DD MMM') !== dayjs(item.endTime).format('DD MMM')
            ? `${startTime}
            - ${formatDateDayjs(dayjs(item.endTime).add(hoursOffset, 'H'), 'DD MMM', this.props.currentUser.language)}`
            : startTime;

        const renderDeadlineCancellation = () => {
          const { currencies } = this?.props?.currentUser;
          const { currencyId } = this?.props?.currentUser?.domainCompany;
          var currencyCode = 'RUB';

          if (!Number.isNaN(Number(currencyId))) {
            currencies.map(currency => {
              if (currency.id === currencyId) {
                currencyCode = currency?.code;
              }
            });
          }

          const penaltyCost = Intl.NumberFormat('ru-RU', {
            style: 'currency',
            maximumSignificantDigits: 21,
            currency: currencyCode || 'RUB',
          }).format(item.cancellationPenalty);

          if (item?.deadlineCancellation) {
            return (
              <Tooltip
                className={css['MaterialsTabsEventsTable-deadline-tooltip']}
                overlayInnerStyle={{ textAlign: 'center' }}
                title={this.props.t('deadlineCancel', {
                  penalty: penaltyCost,
                  deadlineCancel: dayjs(item?.deadlineCancellation).format('DD.MM.YYYY'),
                })}
              >
                <ExclamationCircleFilled style={{ color: '#F4A900' }} />
              </Tooltip>
            );
          } else {
            return null;
          }
        };

        const checkStatusWaitingList = () => {
          const eventWaitSatus = this.props.waitingList?.find(list => list?.courseEventId === item?.id)?.status;
          // const checkRegistration = this.props.waitingList?.find(list => list?.status === REGISTERED); закомментировано в рамказ задачи https://kampus.atlassian.net/browse/KAM-5718

          const disableWaitingList = eventWaitSatus === ON_WAITING_LIST && (item?.placeCount === 0 || eventExpiredDate);

          if (disableWaitingList) {
            return true;
          } else {
            return false;
          }
        };

        const waitingLisatAction = () => {
          if (!isPossiblySignUp && isCorpOrExtCourseWithOrWithoutApp(this.props.topicType)) {
            this.props.waitingListAction(
              item.id,
              () => {
                ModalAntd.success().update({
                  title: this.props.t('waitingListTitle1'),
                  content: this.props.t('waitingListContent1'),
                  zIndex: 10001,
                  footer: null,
                  closable: true,
                });
              },
              err => {
                message.error(this.props.t('error'));
              }
            );
          } else {
            if (this.props.topic.targetAudience === false && isAnyCourseOrConference(this.props.topic.type.id)) {
              this.setState({
                studyPlanModalInfo: { data: item.startTime, selectedEvent: item.id, singUpFlag: isPossiblySignUp },
                isAudienceConfirmationModalOpen: true,
              });
              return;
            }
            this.handleStudyPlanModal(item.startTime, item.id, isPossiblySignUp);
          }
        };

        const actionButtons = !item.active ? null : (
          <Button.Group>
            <Button
              onClick={waitingLisatAction}
              type='primary'
              disabled={this.props.topicStatus === 'DISABLED' || checkStatusWaitingList()}
              size='large'
            >
              {eventExpiredDate || item?.placeCount === 0 ? this.props.t('waitList') : this.props.t('singUp')}
            </Button>
          </Button.Group>
        );

        const dateRender = !isManyEvents ? (
          <div>
            {item.participate && (
              <div>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
                  <path
                    fill='#FF8C00'
                    fillRule='nonzero'
                    d='M4.148 7.883a1.016 1.016 0 0 0-1.452.01 1.055 1.055 0 0 0 .01 1.476l3.127 3.13a1.016 1.016 0 0 0 1.476-.035l6.819-7.513a1.055 1.055 0 0 0-.059-1.475 1.016 1.016 0 0 0-1.452.06l-6.098 6.72-2.37-2.373z'
                  />
                </svg>
              </div>
            )}
            {isNotManyEventsContent}
          </div>
        ) : (
          <div>
            {item.periods.map(period => {
              return period.dates.map((date, dateIndex) => {
                const lastDateIndex = period.dates.length - 1;
                return (
                  dateIndex === 0 && (
                    <p key={dateIndex + date}>
                      {`${dayjs(date).format('DD MMM')} - ${dayjs(period.dates[lastDateIndex]).format('DD MMM')}`}
                    </p>
                  )
                );
              });
            })}
          </div>
        );

        const deadlineRender = item?.deadlineRegistration && isConfOrAllCourse(topicType) && (
          <div className={classNames(css['MaterialsTabsEventsTable-deadline'])}>
            {this.props.t('deadlineRegistr', { deadline: dayjs(item?.deadlineRegistration).format('DD.MM.YYYY') })}
            {renderDeadlineCancellation()}
          </div>
        );

        return {
          key: index,
          date: dateRender,
          cost: (
            <div className={css['MaterialsTabsEventsTable-table-cost']}>
              <EventCost initialCost={item?.cost} currentUser={this.props.currentUser} />
            </div>
          ),
          time: item?.duration && this.getTimeFromMins(item.duration),
          status: item?.active
            ? !item.ended
              ? isPossiblySignUp && !eventDisabled
                ? this.props.t('groupsSet')
                : this.props.t('groupDone')
              : this.props.t('courseEnd')
            : item?.ended
              ? this.props.t('courseEnd')
              : this.props.t('groupDone'),
          deadline: deadlineRender,
          availableSeats: this.props.t('numbOfPlaces', {
            placeCount: item.placeCount,
            participantsCount: item.participantsCount,
          }),
          btn: !item?.ended && !eventDisabled && !!actionButtons && correctBtnStatus ? actionButtons : null,
          eventPeriods: item?.periods,
          eventId: item?.id,
        };
      });
    };

    const infoString = `${topicTypeName} ${t('addedToPlan')} ${
      !isCorpCourseWithOrWithoutApprove(topicType) && !studyPlanId.withoutSchedule
        ? dayjs(studyPlanId.startDate).format('DD MMMM YYYY')
        : dayjs(studyPlanId.startDate).format('MMMM YYYY')
    }.`;

    const notDeactivatedStatus = status !== t('deactivated');

    return (
      <Content className={css.MaterialsTabsEventsTable}>
        {notDeactivatedStatus ? (
          !isEmpty(studyPlanId) ? (
            <div className={css.MaterialsTabsEventsTable__infoText}>
              {infoString} <a href='/Planning'>{t('goToPlan')}</a>
            </div>
          ) : events && events.length === 0 ? (
            <div className={css.MaterialsTabsEventsTable__infoText}>
              {isExternalCourse(topicType) ? (
                <div>
                  {t('participation')}
                  <a onClick={this.toCalendarClick}>{t('showInterest')}</a>
                </div>
              ) : (
                <div>
                  {t('leaveCorporateRequest')}
                  <a onClick={this.toCalendarClick}>
                    {isCorpOrExtCourseWithOrWithoutApp(topicType) ? t('showInterest') : t('chooseMonth')}
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div className={css.MaterialsTabsEventsTable__infoText}>
              {isCorpOrExtCourseWithOrWithoutApp(topicType) ? (
                <div>
                  {t('dontFindDate')}
                  <a onClick={() => this.handleCalendarModal({ toCalendar: true, checkEventDate: true })}>
                    {t('showInterest')}
                  </a>
                </div>
              ) : (
                <div>
                  {t('leave')}
                  <a onClick={this.toCalendarClick}>{t('leaveRequest')}</a>
                </div>
              )}
            </div>
          )
        ) : (
          <div className={css.MaterialsTabsEventsTable__infoText}>{t('unActive')}</div>
        )}
        <Spin spinning={isLoading}>
          {!isLoading && (
            <Popover
              content={
                <SchedulePopoverData
                  t={this.props.t}
                  event={this.state.popoverRowData}
                  currentUser={this.props.currentUser}
                />
              }
              placement='topRight'
              arrow={false}
              open={this.state.isPopoverScheludeOpen}
            >
              <Table
                className={css['MaterialsTabsEventsTable-table']}
                columns={columnsEvent()}
                dataSource={getDataEvent(events)}
                pagination={false}
                showHeader={false}
                rowKey={record => record.id}
                onRow={(record, rowIndex) => ({
                  onMouseEnter: () => {
                    this.setState({ isPopoverScheludeOpen: true, popoverRowData: record });
                  },
                  onMouseLeave: () => {
                    this.setState({ isPopoverScheludeOpen: false });
                  },
                })}
              />{' '}
            </Popover>
          )}
          {this.state.startEndedLoad && (
            <Table
              title={() => t('compCourse')}
              className={css['MaterialsTabsEventsTable-table']}
              columns={columnsEvent('EndedEvent-')}
              dataSource={getDataEvent(endedEvents)}
              pagination={false}
              showHeader={false}
            />
          )}
          <div className={css.MaterialsTabsEventsTable__loadBtnBox}>
            {endedCount > 0 && (loadMore || !this.state.startEndedLoad) && !isLoading && (
              <Button
                disabled={isLoading}
                onClick={() => this.loadEndedEvents({ size: 10 }, true)}
                type='primary'
                size='large'
              >
                {endedEvents && (endedEvents.length < 3 ? t('showPast') : t('showMore'))}
              </Button>
            )}
          </div>
        </Spin>
        <CalendarModal
          open={this.state.calendarModalVisible}
          onOk={this.handleStudyPlanClick}
          onCancel={this.handleCalendarModal}
          okText={t('addToPlan')}
          title={t('month')}
          planningTopicId={this.props.currentTopicId}
          topicInfo={this.state.topicInfo}
          eventTypeId={this.props.topicType}
          toCalendar={this.state.toCalendar}
          checkEventDate={this.state.checkEventDate}
          hasEvents={this.state.topicInfo && this.state.topicInfo.eventCount !== 0}
          selectMonthEvents={
            isCorpCourseWithOrWithoutApprove(this.props.topicType) || isExternalCourse(this.props.topicType)
          }
        />
        <Modal
          open={this.state.modalVisible}
          width={600}
          loading={planIsLoading}
          onCancel={this.handleStudyPlanModal}
          title={t('confirmation')}
          okText={t('sendRequest')}
          contentClassName={classNames(css['App-card-item'], css['Planning-topic-modal'])}
          onOk={this.handleStudyPlanClick}
          footerBtnOrange
          modificate
          cardOn
          joinConfirmationModal
        >
          <div>
            <div className={css['Description']}>{t('sendDescription')}</div>
            <div className={css['Comment-block']}>
              <TextArea
                className={css.Comment}
                placeholder={t('comment')}
                rows={3}
                style={{ resize: 'none' }}
                onChange={this.changeComment}
                value={this.state.comment}
                maxLength={500}
              />
            </div>
          </div>
        </Modal>

        <AudienceConfirmationModal
          open={isAudienceConfirmationModalOpen}
          onConfirm={() =>
            this.handleStudyPlanModal(
              studyPlanModalInfo.data,
              studyPlanModalInfo.selectedEvent,
              studyPlanModalInfo.singUpFlag
            )
          }
          onCancel={this.handleModalCancel}
        />
      </Content>
    );
  }
}

const mapStateToProps = createSelector(
  [courseSelect, topicsSelect, usersSelect, planSelect],
  (course, topics, users, studyPlan) => ({
    events: course.events,
    endedEvents: (course.endedEvents && course.endedEvents.elements) || [],
    endedCount: topics.topic && topics.topic.endedCount,
    loadMore: course.endedEvents && course.endedEvents.loadMore,
    page: course.endedEvents && course.endedEvents.page,
    isLoading: course.isLoading,
    planIsLoading: studyPlan.isLoading,
    topicId: topics.topic && topics.topic.id,
    topicDate: topics.topic && topics.topic.startDate,
    currentUserId: users.currentUser && users.currentUser.id,
    topicStatus: topics.topic && topics.topic.status,
    studyPlanId: studyPlan.studyPlanId.length !== 0 ? studyPlan.studyPlanId[0] : {},
    waitingList: studyPlan.waitingList,
  })
);

const mapActionsToProps = {
  getEventsInCourse,
  getOpenDate,
  updateButtonFunc,
  createStudyPlan,
  eventRegistrAction,
  waitingListAction,
  checkWaitingListInfo,
  checkStudyPlan,
  updateStudyPlanDate,
  addUserEvent,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('materialsTabsEvents')(MaterialsTabsEvents));
