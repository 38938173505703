/* eslint-disable */
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './Answer.module.scss';

const Answer = ({ data, onClick, checked, getFile, letter }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (data.imgUuid) {
      getFile(data.imgUuid, blob => {
        setImage(URL.createObjectURL(blob));
      });
    }
  }, [data]);

  return (
    <div
      className={classNames([css.Answer, { [css['Answer-active']]: checked, [css['Answer-large']]: !data.imgUuid }])}
      onClick={() => onClick(data.id)}
    >
      {image && (
        <div className={css['Answer-image']} style={{ backgroundColor: data.color }}>
          <img style={{ backgroundColor: data.color }} src={image} alt='' />
        </div>
      )}
      <div className={css['Answer-textWrapper']}>
        <div className={classNames([css['Answer-number'], { [css['Answer-number-active']]: checked }])}>{letter}</div>
        <div className={css['Answer-text']}>{data.answer}</div>
      </div>
    </div>
  );
};

Answer.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  getFile: PropTypes.func,
  letter: PropTypes.string,
};

export default Answer;
