import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import Draggable from './Draggable';
import Droppable, { DroppableItem } from './Droppable';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import css from './MatchingQuestion.module.scss';

const MatchingQuestion = ({ data, confirmAnswerClick, setAnswers, lockUI }) => {
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } });
  const touchSensor = useSensor(TouchSensor, { activationConstraint: { delay: 100, tolerance: 0 } });
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const { t } = useTranslation('MatchingQuestion');
  const [containers, setContainers] = useState({});
  const [draggingId, setDraggingId] = useState(null);

  useEffect(() => {
    const newContainers = data.leftMatching?.reduce((acc, question, index) => {
      const questionId = `droppable-${question.leftId}`;
      const answer = { ...data.rightMatching?.[index], key: data.rightMatching?.[index].rightId };

      acc[questionId] = { ..._cloneDeep(question), key: questionId, answer: answer ?? undefined };

      return acc;
    }, {});

    setContainers(_cloneDeep(newContainers));

    const matchingAnswers = collateAnswers(newContainers);

    setAnswers(_cloneDeep(matchingAnswers));
  }, [data.leftMatching, data.rightMatching]);

  const collateAnswers = containers => {
    const matchingAnswers = containers
      ? Object.keys(containers).reduce((acc, key) => {
          const question = containers[key];

          if (question && question.answer) {
            // pairId относится только к вопросу с левой части
            acc.push({ pairId: question.pairId, leftId: question.leftId, rightId: question.answer.rightId });
          }

          return acc;
        }, [])
      : [];

    return matchingAnswers;
  };

  const pairQuestions = containers
    ? Object.entries(containers).map(([key, question]) => {
        const answer = question.answer;
        answer.id = answer.rightId;

        const draggable = answer ? (
          <Draggable answer={answer} draggingId={draggingId} wasDragged={question?.wasDragged} />
        ) : null;

        return (
          <Droppable key={key} id={key}>
            <DroppableItem question={question} /> {draggable}
          </Droppable>
        );
      })
    : [];

  const handleDragCancel = event => {
    setDraggingId(null);

    console.log(event);
  };

  const handleDragStart = event => {
    const id = event?.active?.id;

    setDraggingId(id);
  };

  // Проверяем, что каждый объект в массиве имеет свойство wasDragged равное true
  const isAllDragged = useMemo(() => {
    if (!containers) return false;

    const values = Object.values(containers);

    return values.every(obj => obj?.wasDragged === true);
  }, [containers]);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (over && over.id !== active?.id) {
      const sourceId = containers && Object.keys(containers).find(key => containers[key].answer?.key === active?.id);
      const targetId = over.id;

      if (sourceId !== targetId) {
        setContainers(prevContainers => {
          const target = prevContainers[targetId];
          const source = prevContainers[sourceId];

          const targetAnswer = target.answer ? { ...target.answer } : undefined;

          target.answer = { ...source.answer };
          source.answer = targetAnswer ? { ...targetAnswer } : undefined;

          const newContainers = {
            ...prevContainers,
            [sourceId]: { ...source },
            [targetId]: { ...target, wasDragged: true },
          };

          const matchingAnswers = collateAnswers(newContainers);

          setAnswers(_cloneDeep(matchingAnswers));

          return newContainers;
        });
      } else {
        setContainers(prevContainers => {
          const target = prevContainers[targetId];

          const newContainers = {
            ...prevContainers,
            [targetId]: { ...target, wasDragged: true },
          };

          const matchingAnswers = collateAnswers(newContainers);

          setAnswers(_cloneDeep(matchingAnswers));

          return newContainers;
        });
      }
    }
  }

  return (
    <div className={css['container']}>
      <DndContext
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        {pairQuestions}
      </DndContext>
      <div className={css['confirmButton']}>
        <Button onClick={confirmAnswerClick} disabled={lockUI || !isAllDragged} type='primary'>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};

MatchingQuestion.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MatchingQuestion;
