// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-NumberInput-NumberInput-module__ql-picker-fZTsDD.user-src-components-NumberInput-NumberInput-module__ql-font-LiPvOQ .user-src-components-NumberInput-NumberInput-module__ql-picker-label-pm__7g[data-value="Montserrat"]:before, .user-src-components-NumberInput-NumberInput-module__ql-picker-fZTsDD.user-src-components-NumberInput-NumberInput-module__ql-font-LiPvOQ .user-src-components-NumberInput-NumberInput-module__ql-picker-item-yaY7sK[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-NumberInput-NumberInput-module__ql-font-Montserrat-RFzo_u {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-NumberInput-NumberInput-module__NumberInput-Q9bJdR {
  float: left;
  display: flex;
}

.user-src-components-NumberInput-NumberInput-module__labelInput-s2NL9O {
  color: #000;
  align-items: center;
  width: auto;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/NumberInput/NumberInput.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,WAAW;EACX,aAAa;AACf;;AAOA;EAJE,WAAW;EACX,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.NumberInput {\n  display: flex;\n  float: left;\n}\n.labelInput {\n  width: auto;\n  display: flex;\n  align-items: center;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #000;\n  margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-NumberInput-NumberInput-module__ql-picker-fZTsDD`,
	"qlPicker": `user-src-components-NumberInput-NumberInput-module__ql-picker-fZTsDD`,
	"ql-font": `user-src-components-NumberInput-NumberInput-module__ql-font-LiPvOQ`,
	"qlFont": `user-src-components-NumberInput-NumberInput-module__ql-font-LiPvOQ`,
	"ql-picker-label": `user-src-components-NumberInput-NumberInput-module__ql-picker-label-pm__7g`,
	"qlPickerLabel": `user-src-components-NumberInput-NumberInput-module__ql-picker-label-pm__7g`,
	"ql-picker-item": `user-src-components-NumberInput-NumberInput-module__ql-picker-item-yaY7sK`,
	"qlPickerItem": `user-src-components-NumberInput-NumberInput-module__ql-picker-item-yaY7sK`,
	"ql-font-Montserrat": `user-src-components-NumberInput-NumberInput-module__ql-font-Montserrat-RFzo_u`,
	"qlFontMontserrat": `user-src-components-NumberInput-NumberInput-module__ql-font-Montserrat-RFzo_u`,
	"NumberInput": `user-src-components-NumberInput-NumberInput-module__NumberInput-Q9bJdR`,
	"numberInput": `user-src-components-NumberInput-NumberInput-module__NumberInput-Q9bJdR`,
	"labelInput": `user-src-components-NumberInput-NumberInput-module__labelInput-s2NL9O`
};
export default ___CSS_LOADER_EXPORT___;
