import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import CustomList from '@shared/components/CustomList';

import style from './MaterialsTabs.module.scss';

const PokaSkillAbout = ({ poka }) => {
  const { t } = useTranslation('pokaSkillAbout');

  const ELEMENTS_ABOUT = [
    {
      key: 1,
      caption: t('categorySkills'),
      value: poka.categories,
      objectKey: 'label',
    },
    {
      key: 2,
      caption: t('site'),
      value: poka.sites,
      objectKey: 'name',
    },
    {
      key: 3,
      caption: t('targetAssessment'),
      value: poka.endorsementLevel.label,
    },
    {
      key: 4,
      caption: t('passingPriority'),
      value: poka.endorsementPriority + ' ' + t('day'),
    },
    {
      key: 5,
      caption: t('cyclicality'),
      value: poka.cyclicity ? poka.cyclicity + ' ' + t('day') : t('never'),
    },
  ];

  const renderElement = ({ caption, value, objectKey, key }) => {
    if (Array.isArray(value)) {
      return (
        <div key={key} className={style['poka-skill-about__element']}>
          <Typography.Text className={style['poka-skill-about__caption']}>{caption}</Typography.Text>
          <CustomList data={value} objectKey={objectKey} />
        </div>
      );
    } else {
      return (
        <div key={key} className={style['poka-skill-about__element']}>
          <Typography.Text className={style['poka-skill-about__caption']}>{caption}</Typography.Text>
          <Typography.Text className={style['poka-skill-about__value']}>{value}</Typography.Text>
        </div>
      );
    }
  };

  return (
    <div className={style['poka-skill-about']}>
      {ELEMENTS_ABOUT.map(element => (element ? renderElement(element) : null))}
    </div>
  );
};

export default PokaSkillAbout;
