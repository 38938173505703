/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import useIsMobile from '@shared/hooks/useIsMobile';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './CollectionMaterials.module.scss';

export default function MaterialsEmptyPlaceholder(props) {
  const { t } = useTranslation('materialsEmptyPlaceholder');
  const navigate = useNavigate();
  const [isMobile] = useIsMobile(769);

  let maxLength = isMobile ? 20 : 45;

  if (props.isSearching || !props.isFavorite) {
    let materialName = props.searchName ? props.searchName : t('material');
    if (materialName.length > maxLength) {
      materialName = '"' + Utils.trString(materialName, maxLength) + '"<br />';
    }

    return (
      <div
        className={classNames(
          css['CollectionMaterials-material-card-noFound'],
          props.isLibrary && css['CollectionMaterials-library'],
          {
            [css['CollectionMaterials-material-card-noFound-external']]: props.isExternalLogin,
          }
        )}
      >
        <span
          data-qa='userCollectionNotFoundBlock'
          dangerouslySetInnerHTML={{
            __html: t('findMaterial', { materialName }),
          }}
        />
      </div>
    );
  }

  return (
    <div className={css['CollectionMaterials-noCard']}>
      {t('favorite')}
      <Button onClick={() => navigate('/collection-topics')} size='large' type='primary'>
        {t('goTo')}
      </Button>
    </div>
  );
}

MaterialsEmptyPlaceholder.propTypes = {
  isExternalLogin: PropTypes.bool,
  isFavorite: PropTypes.any,
  isSearching: PropTypes.bool,
  searchName: PropTypes.string,
  isLibrary: PropTypes.bool,
};
