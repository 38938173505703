/* eslint-disable */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectTalentQ } from '@/store/talentq/selectors';

import { Button, Card, Col, Row, Spin } from 'antd';

import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import css from './welcome.module.scss';

import trajectory from '@/assets/images/trajectory1.svg';

export const Welcome = props => {
  const BTNDISABLED = props.hasScore;
  const { t } = props;

  const render = () => {
    return (
      <Card>
        <h2 className={css.DiagnosticTalentQ_SubTitle}>
          {t('subTitle')}
          <br />
          {t('subTitle2')}
        </h2>
        <div className={css.DiagnosticTalentQ}>
          <Row className={css.DiagnosticTalentQ_Row}>
            <img className={css.DiagnosticTalentQ_Row_Image} src={trajectory} alt={t('plan')} />
            <Col span={8}>
              <div className={css.DiagnosticTalentQ_Row_Label}>{t('rowLabel')}</div>
            </Col>
            <Col span={8}>
              <div className={css.DiagnosticTalentQ_Row_Label}>{t('rowLabel2')}</div>
            </Col>
            <Col span={8}>
              <div className={css.DiagnosticTalentQ_Row_Label}>{t('rowLabel3')}</div>
            </Col>
          </Row>
        </div>

        <Spin size='large' spinning={props.loading}>
          <div className={css.DiagnosticTalentQ}>
            <Row className={css.DiagnosticTalentQ_Row}>
              <Col span={10}>
                <div className={css.DiagnosticTalentQ_Row_Label}>
                  <Button onClick={props.onButtonTest} disabled={BTNDISABLED} type='primary'>
                    {t('goTest')}
                  </Button>
                </div>
              </Col>
              <Col span={14}>
                <div className={css.DiagnosticTalentQ_Row_Label}>
                  <div className='FirstScreenContainer__label'>{BTNDISABLED ? t('rowLabel6') : t('rowLabel4')}</div>
                </div>
              </Col>
            </Row>
            <Row className={css.DiagnosticTalentQ_Row}>
              <Col span={10}>
                <div className={css.DiagnosticTalentQ_Row_Label}>
                  <Button onClick={props.onButtonResult} disabled={!BTNDISABLED} type='primary'>
                    {t('checkResults')}
                  </Button>
                </div>
              </Col>
              <Col span={14}>
                <div className={css.DiagnosticTalentQ_Row_Label}>
                  <div className='FirstScreenContainer__label'>{t('rowLabel5')}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Card>
    );
  };

  return render();
};

Welcome.propTypes = {
  t: PropTypes.func.isRequired,
  onButtonTest: PropTypes.func.isRequired,
  onButtonResult: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasScore: PropTypes.bool,
};

const mapStateToProps = createSelector([selectTalentQ], selectTalentQ => ({
  loading: selectTalentQ.loading,
  hasScore: selectTalentQ?.assessmentUrl?.hasScore,
}));

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('DiagnosticTalentq')(Welcome));
