/* eslint-disable */

import { Select } from 'antd';

import PropTypes from 'prop-types';

const InfiniteScrollSelect = ({ data, value, loadMore, hasMore, optionClassname, state, ...rest }) => {
  const { Option } = Select;

  const onScroll = e => {
    e.preventDefault();
    const isEndOfScroll = Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
    isEndOfScroll && hasMore && loadMore();
  };

  return (
    <Select onPopupScroll={onScroll} value={state} {...rest}>
      {data?.map(item => (
        <Option className={optionClassname} key={item?.id} value={item?.id}>
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};

InfiniteScrollSelect.propTypes = {
  data: PropTypes.array,
  value: PropTypes.string,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  optionClassname: PropTypes.string,
  state: PropTypes.array,
};

export default InfiniteScrollSelect;
