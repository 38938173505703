// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-planning-page-status-popover-styles-module__ql-picker-ncynJE.user-src-pages-planning-page-status-popover-styles-module__ql-font-fyXBNC .user-src-pages-planning-page-status-popover-styles-module__ql-picker-label-tLMd2z[data-value="Montserrat"]:before, .user-src-pages-planning-page-status-popover-styles-module__ql-picker-ncynJE.user-src-pages-planning-page-status-popover-styles-module__ql-font-fyXBNC .user-src-pages-planning-page-status-popover-styles-module__ql-picker-item-mvGFRy[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-planning-page-status-popover-styles-module__ql-font-Montserrat-IWNQWV {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-planning-page-status-popover-styles-module__status-popover-z0ksKa {
  max-width: 560px;
}

.user-src-pages-planning-page-status-popover-styles-module__status-popover__title-q8F9oS {
  color: #000;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-pages-planning-page-status-popover-styles-module__status-popover__title-q8F9oS {
    font-size: 14px;
  }
}

.user-src-pages-planning-page-status-popover-styles-module__status-popover__description-qcPIWv {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-pages-planning-page-status-popover-styles-module__status-popover__description-qcPIWv {
    font-size: 12px;
  }
}

.user-src-pages-planning-page-status-popover-styles-module__status-popover__status-kF41Tb {
  width: 16px;
  height: 16px;
  margin: 0 0 -3px 2px;
}

.user-src-pages-planning-page-status-popover-styles-module__status-popover-z0ksKa .ant-popover-inner-content {
  padding: 14px 16px;
}

@media (min-width: 360px) and (max-width: 992px) {
  .user-src-pages-planning-page-status-popover-styles-module__status-popover-z0ksKa .ant-popover-inner-content {
    padding: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/planning-page/status-popover/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,gBAAgB;AAClB;;AAMA;EAHE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAKA;EACE;IAFE,eAAe;EACjB;AACF;;AAIA;EADE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAGA;EACE;IAAE,eAAe;EACjB;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AACA;EAEE,kBAAkB;AACpB;;AAAA;EACE;IAGE,YAAY;EACd;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.status-popover {\n  max-width: 560px;\n}\n.status-popover__title {\n  font-weight: 700;\n  margin-bottom: 8px;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #000;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .status-popover__title {\n    font-size: 14px;\n  }\n}\n.status-popover__description {\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #000;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .status-popover__description {\n    font-size: 12px;\n  }\n}\n.status-popover__status {\n  margin: 0 0 -3px 2px;\n  width: 16px;\n  height: 16px;\n}\n.status-popover :global .ant-popover-inner-content {\n  padding: 14px 16px;\n}\n@media (width >= 360px) and (max-width: 992px) {\n  .status-popover :global .ant-popover-inner-content {\n    padding: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-ncynJE`,
	"qlPicker": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-ncynJE`,
	"ql-font": `user-src-pages-planning-page-status-popover-styles-module__ql-font-fyXBNC`,
	"qlFont": `user-src-pages-planning-page-status-popover-styles-module__ql-font-fyXBNC`,
	"ql-picker-label": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-label-tLMd2z`,
	"qlPickerLabel": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-label-tLMd2z`,
	"ql-picker-item": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-item-mvGFRy`,
	"qlPickerItem": `user-src-pages-planning-page-status-popover-styles-module__ql-picker-item-mvGFRy`,
	"ql-font-Montserrat": `user-src-pages-planning-page-status-popover-styles-module__ql-font-Montserrat-IWNQWV`,
	"qlFontMontserrat": `user-src-pages-planning-page-status-popover-styles-module__ql-font-Montserrat-IWNQWV`,
	"status-popover": `user-src-pages-planning-page-status-popover-styles-module__status-popover-z0ksKa`,
	"statusPopover": `user-src-pages-planning-page-status-popover-styles-module__status-popover-z0ksKa`,
	"status-popover__title": `user-src-pages-planning-page-status-popover-styles-module__status-popover__title-q8F9oS`,
	"statusPopoverTitle": `user-src-pages-planning-page-status-popover-styles-module__status-popover__title-q8F9oS`,
	"status-popover__description": `user-src-pages-planning-page-status-popover-styles-module__status-popover__description-qcPIWv`,
	"statusPopoverDescription": `user-src-pages-planning-page-status-popover-styles-module__status-popover__description-qcPIWv`,
	"status-popover__status": `user-src-pages-planning-page-status-popover-styles-module__status-popover__status-kF41Tb`,
	"statusPopoverStatus": `user-src-pages-planning-page-status-popover-styles-module__status-popover__status-kF41Tb`
};
export default ___CSS_LOADER_EXPORT___;
