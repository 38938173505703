// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-ArticleFrame-index-module__ql-picker-e6P18j.user-src-components-ArticleFrame-index-module__ql-font-mh69Rz .user-src-components-ArticleFrame-index-module__ql-picker-label-hRaPRx[data-value="Montserrat"]:before, .user-src-components-ArticleFrame-index-module__ql-picker-e6P18j.user-src-components-ArticleFrame-index-module__ql-font-mh69Rz .user-src-components-ArticleFrame-index-module__ql-picker-item-P9kPJS[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-ArticleFrame-index-module__ql-font-Montserrat-ifzmVI {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-ArticleFrame-index-module__ArticleFrame-kjJMb4 {
  border: 0;
  width: 100%;
}

.user-src-components-ArticleFrame-index-module__ArticleFrame-kjJMb4 img {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/ArticleFrame/index.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,SAAS;EACT,WAAW;AACb;;AAOA;EAJE,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* eslint-disable */\n.ArticleFrame {\n  border: 0;\n  width: 100%;\n}\n.ArticleFrame img {\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-ArticleFrame-index-module__ql-picker-e6P18j`,
	"qlPicker": `user-src-components-ArticleFrame-index-module__ql-picker-e6P18j`,
	"ql-font": `user-src-components-ArticleFrame-index-module__ql-font-mh69Rz`,
	"qlFont": `user-src-components-ArticleFrame-index-module__ql-font-mh69Rz`,
	"ql-picker-label": `user-src-components-ArticleFrame-index-module__ql-picker-label-hRaPRx`,
	"qlPickerLabel": `user-src-components-ArticleFrame-index-module__ql-picker-label-hRaPRx`,
	"ql-picker-item": `user-src-components-ArticleFrame-index-module__ql-picker-item-P9kPJS`,
	"qlPickerItem": `user-src-components-ArticleFrame-index-module__ql-picker-item-P9kPJS`,
	"ql-font-Montserrat": `user-src-components-ArticleFrame-index-module__ql-font-Montserrat-ifzmVI`,
	"qlFontMontserrat": `user-src-components-ArticleFrame-index-module__ql-font-Montserrat-ifzmVI`,
	"ArticleFrame": `user-src-components-ArticleFrame-index-module__ArticleFrame-kjJMb4`,
	"articleFrame": `user-src-components-ArticleFrame-index-module__ArticleFrame-kjJMb4`
};
export default ___CSS_LOADER_EXPORT___;
