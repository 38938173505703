/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getExecutiveTask, getExecutiveTopicInfo } from '@/store/executive/actions';
import { checkStudyPlan } from '@/store/study-plan/actions';

import ExecutiveForm from './ExecutiveForm';

import _ from 'lodash';
import PropTypes from 'prop-types';

class ExecutiveFinishedScreen extends Component {
  static propTypes = {
    onFinish: PropTypes.func,
    disabled: PropTypes.bool,
    topic: PropTypes.object,
    user: PropTypes.object,
    review: PropTypes.string,
    files: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      topicExecutiveId: null,
      needFile: false,
      review: this.props.review || '',
      files: this.props.files || [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.review !== this.state.review) {
      this.setState({ review: nextProps.review });
    }

    if (!_.isEqual(nextProps.files, this.state.files)) {
      this.setState({ files: nextProps.files });
    }
  }

  componentDidMount() {
    const { getExecutiveTopicInfo, topic, checkStudyPlan, user, getExecutiveTask } = this.props;
    getExecutiveTopicInfo(topic.id, topicExecutive => {
      this.setState({ topicExecutiveId: topicExecutive.id });
      if (topicExecutive.requiredFile) {
        this.setState({ needFile: true });
      }

      if (
        topic?.planStatus &&
        (topic.planStatus.planStatus === 'WAITING' ||
          topic.planStatus.planStatus === 'REJECTED_BY_MANAGER' ||
          topic.planStatus.planStatus === 'FINISHED')
      ) {
        checkStudyPlan({
          userId: user.id,
          topicId: topic.id,
          isCurrent: null,
          onSuccess: res => {
            if (res && res[0].id) {
              getExecutiveTask(res[0].id, info => {
                this.setState({
                  review: info.answer,
                  files:
                    (info?.fileNames &&
                      info?.fileNames.map((file, index) => ({
                        name: file,
                        uuid: info.fileUuids[index],
                      }))) ||
                    [],
                });
              });
            }
          },
        });
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <ExecutiveForm
        windowTitle={t('windowTitle')}
        review={this.state.review}
        reviewPlaceHolder={t('reviewPlaceholder')}
        addFileButtonText={t('addFileButtonText')}
        files={this.state.files}
        onFinish={this.props.onFinish}
        disabled={this.props.disabled}
        needFile={this.state.needFile}
        topicExecutiveId={this.state.topicExecutiveId}
      />
    );
  }
}

const mapStateToProps = null;

const mapActionToProps = {
  getExecutiveTopicInfo,
  getExecutiveTask,
  checkStudyPlan,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withTranslation('ExecutiveFinishedScreen')(ExecutiveFinishedScreen));
