export const GET_INFO_EXISTING_MATERIAL_START = 'GET_INFO_EXISTING_MATERIAL_START';
export const GET_INFO_EXISTING_MATERIAL_SUCCESS = 'GET_INFO_EXISTING_MATERIAL_SUCCESS';
export const GET_INFO_EXISTING_MATERIAL_FAIL = 'GET_INFO_EXISTING_MATERIAL_FAIL';

export const PUT_START_EXISTING_MATERIAL_START = 'PUT_START_EXISTING_MATERIAL_START';
export const PUT_START_EXISTING_MATERIAL_SUCCESS = 'PUT_START_EXISTING_MATERIAL_SUCCESS';
export const PUT_START_EXISTING_MATERIAL_FAIL = 'PUT_START_EXISTING_MATERIAL_FAIL';

export const PUT_FINISH_EXISTING_MATERIAL_START = 'PUT_FINISH_EXISTING_MATERIAL_START';
export const PUT_FINISH_EXISTING_MATERIAL_SUCCESS = 'PUT_FINISH_EXISTING_MATERIAL_SUCCESS';
export const PUT_FINISH_EXISTING_MATERIAL_FAIL = 'PUT_FINISH_EXISTING_MATERIAL_FAIL';
