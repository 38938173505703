import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Select } from 'antd';

import Icon from '../OldIcon';
import SearchSuggestItem from './SearchSuggestItem';

import useIsMobile from '@shared/hooks/useIsMobile';

import classNames from 'classnames';

import css from './ContextSearch.module.scss';

const { Search } = Input;

export default function ContextSearch(props) {
  const {
    results,
    onSearchTypeChange,
    types,
    onChange,
    searchText,
    onSubmit,
    onSearchSuggestClick,
    dataQa,
    isFavorite,
    isNews,
    searchType,
    hasSearched,
  } = props;

  const [isSearchOpen, setOpen] = useState(false);
  const [isSearchSubmitted, setSearchSubmitted] = useState(false);
  const { t } = useTranslation();
  const [isMobile] = useIsMobile(769);

  const onCloseSearch = () => {
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const onOpenSearch = () => {
    setTimeout(() => {
      setOpen(true);
    }, 300);
  };

  const onTypeChange = result => {
    onSearchTypeChange(result);
  };

  const currentTypes = types?.map(el => ({
    value: el.type,
    label: el.name,
  }));

  const hasResults = results && results.length;

  const searchNameDefaultValue = (isNews && currentTypes?.[0]?.label) || searchType?.name || '';

  const typeSelect = (
    <Select
      data-qa={dataQa}
      onChange={onTypeChange}
      name='searchName'
      options={currentTypes}
      value={searchNameDefaultValue}
      className={css['ContextSearch-select']}
      popupClassName={css['ContextSearch-select__dropdown']}
      size='large'
    />
  );

  const onClickShowAllResult = () => onSubmit(searchText);

  const searchTypeTemp = props.searchType.type;

  const handleSearch = value => {
    setSearchSubmitted(false);
    onSubmit(value);
  };

  return (
    <div
      className={classNames(
        css.ContextSearch,
        {
          [css['ContextSearch-favorite']]: isFavorite,
        },
        'smallSizeSearchButton'
      )}
      onFocus={onOpenSearch}
      onBlur={onCloseSearch}
    >
      <Search
        data-qa={`${dataQa}-input`}
        className={css['ContextSearch-input']}
        type='text'
        size={'large'}
        onChange={({ target: { value } }) => {
          setSearchSubmitted(true);
          onChange(value);
        }}
        value={searchText || ''}
        placeholder={t('search')}
        enterButton={hasSearched}
        allowClear
        onSearch={handleSearch}
        addonBefore={
          !isMobile &&
          types && (
            <Select
              data-qa={dataQa}
              onChange={onTypeChange}
              name='searchName'
              options={currentTypes}
              value={searchNameDefaultValue}
              className={css['ContextSearch-select']}
              popupClassName={css['ContextSearch-select__dropdown']}
            />
          )
        }
      />

      {isMobile && typeSelect}

      {isSearchOpen && searchText && hasResults && isSearchSubmitted ? (
        <div className={css['ContextSearch-result']}>
          <div className={css['ContextSearch-result-blockName']}>
            <SearchResults results={results} onSearchItemClick={onSearchSuggestClick} searchType={searchTypeTemp} />
            <div className={css['ContextSearch-result-blockName-result']} onClick={onClickShowAllResult}>
              {t('showAllResult')}
              <Icon type='arrow-right' />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export function SearchResults(props) {
  return (
    <>
      {props.results.map(item => (
        <SearchSuggestItem
          key={item.type + item.id}
          item={item}
          onItemClick={props.onSearchItemClick}
          type={props.searchType}
        />
      ))}
    </>
  );
}
