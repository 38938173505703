/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createOrder } from '@/store/shop/actions';
import { updateCoins } from '@/store/users/actions';

import { selectShop } from '@/store/shop/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Form, Input, Modal, notification } from 'antd';
import { CoinsIcon } from '@/components/elements';

import ModalCloseIcon from '../ModalCloseIcon/ModalCloseIcon';
import { CustomPhoneInput } from './CustomPhoneInput';
import SubModals from './SubModals';

import classNames from 'classnames';

import css from './PurchaseModal.module.scss';

const { TextArea } = Input;
const MAX_LENGTH = 256;

const PurchaseModal = ({ product, productImg, visible, changeVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('purchaseModal');
  const [form] = Form.useForm();

  const [errors, setErrors] = useState({ phone: false });
  const [isVisibleSubModal, setIsVisibleSubModal] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const { isLoadingPurchase } = useSelector(selectShop);
  const { currentUser } = useSelector(selectUsers);

  const isRussian = currentUser.language === 'ru_RU';

  const changeModalVisible = () => {
    changeVisible();
    form.resetFields();
  };

  const onMakeOrder = () => {
    form.validateFields().then(values => {
      const dto = { ...values, productId: product?.id };

      if (dto.phone) {
        dto.phone = `+${dto.phone}`;
      }

      dispatch(
        createOrder(
          dto,
          () => {
            onChangeSubModalVisiability();
            changeModalVisible();
            dispatch(updateCoins(currentUser?.account - product?.cost));
          },
          () => {
            notification.error({
              message: t('error'),
              description: t('errorDescription'),
            });
          }
        )
      );
    });
  };

  const onChangeSubModalVisiability = () => setIsVisibleSubModal(!isVisibleSubModal);

  const footerModal = [
    <Button
      key='cancel'
      className={css['PurchaseModal-container__button']}
      onClick={changeModalVisible}
      type='default'
      size='large'
    >
      {t('cancel')}
    </Button>,
    <Button
      key='makeOrder'
      className={css['PurchaseModal-container__button']}
      onClick={onMakeOrder}
      disabled={disabledSubmit}
      type='primary'
      size='large'
    >
      {t('makeOrder')}
    </Button>,
  ];

  const titleModal = <p className={css['PurchaseModal-title']}>{t('title')}</p>;

  return (
    <>
      <SubModals visible={isVisibleSubModal} changeVisible={onChangeSubModalVisiability} />
      <Modal
        open={visible}
        width={740}
        className={css['PurchaseModal']}
        title={titleModal}
        centered
        okButtonProps={{ loading: isLoadingPurchase }}
        footer={footerModal}
        closeIcon={<ModalCloseIcon onClick={changeModalVisible} className={css['ModalCloseIcon']} />}
      >
        <div className={css['PurchaseModal-container']}>
          <div className={css['PurchaseModal-form']}>
            <Form form={form} labelCol={{ span: 24 }}>
              <Form.Item
                label={t('phoneNumberTitle')}
                validateStatus={errors.phone ? 'error' : ''}
                help={errors.phone && t('notTel')}
                name={'phone'}
                initialValue={''}
                required
              >
                <CustomPhoneInput
                  isRussian={isRussian}
                  setDisabledSubmit={setDisabledSubmit}
                  form={form}
                  setErrors={setErrors}
                />
              </Form.Item>
              <Form.Item label={t('comment')} name={'comment'} initialValue={''}>
                <TextArea
                  className={classNames(css['PurchaseModal-form-input'], css['PurchaseModal-form-input-textarea'])}
                  placeholder={t('comment')}
                  maxLength={MAX_LENGTH}
                  autoSize
                />
              </Form.Item>
            </Form>
          </div>
          <div className={css['PurchaseModal-product']}>
            <img className={css['PurchaseModal-product-img']} src={productImg} alt='' />
            <p className={css['PurchaseModal-product-name']}>{product?.name}</p>
            <div className={css['PurchaseModal-product-cost']}>
              <span className={css['PurchaseModal-product-cost-value']}>{product?.cost}</span>
              <CoinsIcon
                uuidCoinsIcon={currentUser.domainCompany.coinIcon}
                className={css['PurchaseModal-product-cost-coin']}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseModal;
