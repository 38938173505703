import { FiUser, FiUsers } from 'react-icons/fi';

export const ASIDE_ELEMENTS = [
  {
    path: '/chat/groups',
    text: 'groups',
    image: <FiUsers />,
  },
  {
    path: '/chat/personal',
    text: 'personal',
    image: <FiUser />,
  },
];
