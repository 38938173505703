import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Modal } from 'antd';
import { BiChevronRight } from 'react-icons/bi';

import Utils from '@/Utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TrackModal.module.scss';

const MAX_TRACK_NAME = 25;

const TrackModal = ({ tracks, visible, changeVisible }) => {
  const { t } = useTranslation('TrackModal');

  const renderTracks = useMemo(
    () =>
      tracks.map(track => (
        <Link key={track.trackId} className={css['TrackModal-item']} to={`/track/${track.trackId}`}>
          <div className={css['TrackModal-item-info']}>
            <p>{Utils.trString(track.trackName, MAX_TRACK_NAME)}</p>
            <p
              className={classNames(
                css['TrackModal-item-status'],
                track.available && css['TrackModal-item-status-active']
              )}
            >
              {track.available ? t('trackActive') : t('trackWaiting')}
            </p>
          </div>
          <BiChevronRight size={24} />
        </Link>
      )),
    [tracks]
  );

  return (
    <Modal
      open={visible}
      title={
        <div className={css['TrackModal-header']}>
          <p className={css['TrackModal-header-title']}>{t('title')}</p>
          <p className={css['TrackModal-header-subtitle']}>{t('subtitle')}</p>
        </div>
      }
      onCancel={changeVisible}
      footer={<Button onClick={changeVisible}>{t('close')}</Button>}
    >
      <div className={css['TrackModal-container']}>{renderTracks}</div>
    </Modal>
  );
};

TrackModal.propTypes = {
  tracks: PropTypes.array,
  changeVisible: PropTypes.func,
  visible: PropTypes.bool,
};

export default TrackModal;
