/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  eventRequestConfirm,
  eventRequestFinish,
  eventRequestRefuse,
  getApprovedCoursesCost,
} from '@/store/course/actions';
import { getMenteeAvailableStatuses, getMentees } from '@/store/mentee/actions';
import {
  getCountStudyPlans,
  getStudyPlanStat,
  getUserIdArchiveTopicPlan,
  getUserIdTopicPlan,
  resetCurrentPaidTopicId,
  setMaterialLoading,
} from '@/store/study-plan/actions';
import { checkTopicComment, showSendReviewModal } from '@/store/topics/actions';
import { getTracks, getTracksStatus } from '@/store/tracks/actions';

import { selectStudyPlan } from '@/store/study-plan/selectors';
import { selectTracks } from '@/store/tracks/selectors';
import { selectCurrentUser, selectUserDomainCompany } from '@/store/users/selectors';

import { Badge, Button, Col, Flex, Input, Pagination, Radio, Row, Select, Spin, Tabs, Typography } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import CurrencySymbol from '@/components/MaterialCard/CurrencySymbol';
import TrackCard from '@/components/TrackCard';
import TrackModal from '@/components/TracksModal';
import { CloseOutlined } from '@ant-design/icons';

import { CardsBlock } from './cards-block';
import { planStatusForRequest, studyPlanBlocks, tabsKeys } from './constants';
import { MentorCard } from './mentor-card/index';
import { Modals } from './modals';
import PokaSkills from './PokaSkills';
import Purpose from './Purpose';
import { ActivityWidget, CareerPlanWidget, FinishedWidget } from './widgets';

import { PlanStatus } from '@shared/constants/plan';
import UtilsPoka from '@shared/constants/showPoka';
import useIsMobile from '@shared/hooks/useIsMobile';
import { currentFullYear } from '@shared/utils/date-utils';
import { priceFormat } from '@shared/utils/format';
import getQueryString from '@shared/utils/get-query-string';
import { classNames } from '@shared/utils/styles-utils';

import { planning } from '@/router/routes';
import { selectMenteeAvailableStatuses, selectMenteeLoading, selectMentees } from '@/store/mentee/selector';
import _get from 'lodash/get';

import styles from './styles.module.scss';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

const cn = classNames(styles);

const WAITING = 'waiting';
const IN_PROGRESS = 'inProgress';
const PLANNING = 'planning';
const ARCHIVE = 'archive';
const IS_FILTER = 'isFilter';
const IS_TOPIC = 'isTopic';

const HIDE_APPROVED = [7252, 6953, 12503]; // ECO_OKNA, SEVERSTAL, rosdolg

const PlanningPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('planningPage');

  const [searchState, setSearchState] = useState('');

  const {
    studyPlan,
    loading: { studyPlan: loading },
    mainPage,
    pageSize,
    totalElements,
    isLoading,
    countStudyPlan,
    currentPaidTopicId,
  } = useSelector(selectStudyPlan);

  const tracksSelector = useSelector(selectTracks);

  const domainCompany = useSelector(selectUserDomainCompany);
  const disableDeletingFromPlan = domainCompany && domainCompany.disableDeletingFromPlan;
  const currentUser = useSelector(selectCurrentUser);

  const [coursesCost, setCoursesCost] = useState();
  const [finishedCourses, setFinishedCourses] = useState();
  const [nearestActivity, setNearestActivity] = useState();
  const [modal, setModal] = useState();
  const [spinner, setSpinner] = useState(true);
  const [purposeVisible, setPurposeVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentValueSelectMentor, setCurrentValueSelectMentor] = useState();
  const [activeTab, setActiveTab] = useState('');
  const [trackStatus, setTrackStatus] = useState('');

  // tracks
  const [trackModalVisible, setTrackModalVisible] = useState(false);
  const [currentTracks, setCurrentTracks] = useState([]);

  const [view, setView] = useState(PLANNING);
  const [totalView, setTotalView] = useState({
    wait: null,
    in_prog: null,
    plan: null,
    archive: null,
  });

  const [addedByAdmin, setAddedByAdmin] = useState(null);

  const mentees = useSelector(selectMentees);
  const menteeAvailableStatuses = useSelector(selectMenteeAvailableStatuses);
  const loadingMentors = useSelector(selectMenteeLoading);

  const currYear = currentFullYear();

  const [isMobile] = useIsMobile(992);
  const [isTablet] = useIsMobile(768);

  const data = {
    userId: currentUser.id,
    sort: view !== 'archive' ? 'startDate,asc' : undefined,
    page: 0,
    addedByAdmin: addedByAdmin,
  };

  const loadStudyPlanStat = () =>
    currentUser &&
    dispatch(
      getStudyPlanStat(false, currentUser.id, res => {
        setNearestActivity(res.nearest);
        setFinishedCourses(res.finished);
      })
    );

  const checkView = () => {
    switch (view) {
      case WAITING:
        data.waiting = true;
        data.isCurrent = true;
        break;
      case IN_PROGRESS:
        data.planStatus = PlanStatus.IN_PROGRESS;
        data.isCurrent = true;
        break;
      case IS_FILTER:
        data.planStatus = planStatusForRequest;
        data.name = searchValue;
        break;
      case IS_TOPIC:
        data.topicId = currentPaidTopicId;
        break;

      case ARCHIVE:
        break;
      default:
        data.planning = true;
        data.isCurrent = true;
        break;
    }
  };

  useEffect(() => {
    dispatch(getCountStudyPlans());

    if (spinner) {
      setSpinner(false);
    }
  }, []);

  useEffect(() => {
    if (currentPaidTopicId) {
      setView(IS_TOPIC);
    } else {
      setView(PLANNING);
    }
    return () => {
      dispatch(resetCurrentPaidTopicId());
    };
  }, [currentPaidTopicId]);

  useEffect(() => {
    if (view && currentUser?.id && activeTab === tabsKeys.education) {
      data.page = 0;
      checkView();
      data.addedByAdmin = addedByAdmin;
      if (view === 'archive') {
        dispatch(getUserIdArchiveTopicPlan(data));
      } else {
        dispatch(getUserIdTopicPlan(data));
      }
    }
  }, [view, searchValue, addedByAdmin, activeTab]);

  useEffect(() => {
    switch (view) {
      case WAITING:
        setTotalView({ ...totalView, wait: totalElements });
        break;

      case IN_PROGRESS:
        setTotalView({ ...totalView, in_prog: totalElements });
        break;

      case PLANNING:
        setTotalView({ ...totalView, plan: totalElements });
        break;

      case ARCHIVE:
        setTotalView({ ...totalView, archive: totalElements });
        break;

      default:
        break;
    }
  }, [totalElements]);

  useEffect(() => {
    switch (view) {
      case WAITING:
        setTotalView({ ...totalView, wait: totalElements });
        break;

      case IN_PROGRESS:
        setTotalView({ ...totalView, in_prog: totalElements });
        break;

      case PLANNING:
        setTotalView({ ...totalView, plan: totalElements });
        break;

      case ARCHIVE:
        setTotalView({ ...totalView, archive: totalElements });
        break;

      default:
        break;
    }
  }, [totalElements]);

  const isBlockNotEmpty = () => !!(studyPlan && studyPlan.length);

  const notEmptyBlocks = studyPlanBlocks(t, disableDeletingFromPlan, currentUser, isMobile).filter(isBlockNotEmpty);

  const showCards = useMemo(() => !!notEmptyBlocks.length, [notEmptyBlocks]);

  const _loading = !!Object.keys(loading).find(name => !!loading[name]);

  const changeTracksVisible = (visible, tracks = []) => {
    setTrackModalVisible(visible);
    setCurrentTracks(tracks);
  };

  const onAction = ({ action, material, blockName }) => {
    switch (action) {
      case 'connectionWithQampus':
        window.open(domainCompany && domainCompany.supportLink, '_blank');
        break;

      case 'goToStudy':
        navigate(`/collection-topics/${material.topic.id}/study`);
        break;

      case 'goToTrack':
        if (Array.isArray(material.trackInfo) && material.trackInfo.length > 1) {
          changeTracksVisible(!trackModalVisible, material.trackInfo);
        } else {
          navigate(`/track/${material.trackInfo[0].trackId}`);
        }
        break;

      default:
        setModal({ action, material, blockName });
        break;
    }
  };

  const onCloseModal = () => setModal(null);

  const onConfirmModal = (blockName, action) => {
    const id = _get(modal, 'material.id');

    if (action && action !== 'iCantGo' && action !== 'removeFromPlan') {
      onCloseModal();
    }

    if (blockName && id) {
      // todo костыль, необходимо переделать SendReviewModal
      dispatch(setMaterialLoading({ name: blockName, id, value: true }));
      loadStudyPlanStat();
    }

    checkView();
    dispatch(getUserIdTopicPlan(data));
    onCloseModal();
  };

  const getCoursesCost = coursesCost =>
    coursesCost !== undefined && (
      <span>
        {priceFormat(coursesCost)} <CurrencySymbol currentUser={currentUser} />
      </span>
    );

  useEffect(() => {
    if (activeTab === tabsKeys.mentoring) {
      dispatch(getMentees(currentValueSelectMentor));
    }
  }, [dispatch, currentValueSelectMentor, activeTab]);

  useEffect(() => {
    if (activeTab === tabsKeys.mentoring) {
      dispatch(getMenteeAvailableStatuses());
    }

    if (activeTab === tabsKeys.tracks) {
      dispatch(getTracks(currentUser.id, trackStatus, { page: 0, size: tracksSelector.pageSize }));
      dispatch(getTracksStatus());
      setTrackStatus('');
    }
  }, [dispatch, activeTab]);

  useEffect(() => {
    dispatch(getApprovedCoursesCost(currYear, setCoursesCost));

    loadStudyPlanStat();

    // todo старый код, нуждается в рефакторинге
    if (location.search.includes('confirm')) {
      dispatch(
        eventRequestConfirm(
          location.search.substring(location.search.indexOf('code=') + 5),
          () => setModal({ action: 'eventRequestConfirm' }),
          () => setModal({ action: 'eventRequestError' })
        )
      );
    }
    if (location.search.includes('refuse')) {
      dispatch(
        eventRequestRefuse(
          location.search.substring(location.search.indexOf('code=') + 5),
          () => setModal({ action: 'eventRequestRefuse' }),
          () => setModal({ action: 'eventRequestError' })
        )
      );
    }
    if (location.search.includes('planId')) {
      if (location.search.includes('topicId')) {
        dispatch(
          checkTopicComment(
            location.search.substr(
              location.search.indexOf('topicId=') + 8,
              location.search.indexOf('&userId=') - (location.search.indexOf('topicId=') + 8)
            ),
            response => {
              if (response) {
                if (response === 1) {
                  setModal({ action: 'eventRequestErrorMention' });
                }
                if (response === 2) {
                  setModal({ action: 'eventRequestError' });
                }
              } else {
                dispatch(
                  showSendReviewModal(
                    location.search.substr(
                      location.search.indexOf('topicId=') + 8,
                      location.search.indexOf('&userId=') - (location.search.indexOf('topicId=') + 8)
                    ),
                    {
                      studyPlanId: location.search.substring(location.search.indexOf('planId=') + 7),
                      currentUserId: location.search.substring(location.search.indexOf('userId=') + 7),
                      viewPlan: false,
                      callback: () => navigate('/planning'),
                      onCancel: () => navigate('/planning'),
                    }
                  )
                );
              }
            }
          )
        );
      } else {
        dispatch(
          eventRequestFinish(
            location.search.substring(location.search.indexOf('planId=') + 7),
            false,
            () => setModal({ action: 'eventRequestNoVisit' }),
            () => setModal({ action: 'eventRequestError' })
          )
        );
      }
    }
  }, []);

  const templateButtons = [
    {
      title: t('inWaiting'),
      name: WAITING,
      className:
        view === WAITING ? cn('planning-page__blockButtons__button_active') : cn('planning-page__blockButtons__button'),
      total: countStudyPlan?.waiting,
    },
    {
      title: t('inProgress'),
      name: IN_PROGRESS,
      className:
        view === IN_PROGRESS
          ? cn('planning-page__blockButtons__button_active')
          : cn('planning-page__blockButtons__button'),
      total: countStudyPlan?.inProgress,
    },
    {
      title: t('planned'),
      name: PLANNING,
      className:
        view === PLANNING
          ? cn('planning-page__blockButtons__button_active')
          : cn('planning-page__blockButtons__button'),
      total: countStudyPlan?.planning,
    },
    {
      title: t('archive'),
      name: ARCHIVE,
      className:
        view === ARCHIVE ? cn('planning-page__blockButtons__button_active') : cn('planning-page__blockButtons__button'),
      total: countStudyPlan?.archive,
    },
  ];

  const templateButtonsAddedByAdmin = [
    { title: t('necessarily'), name: true },
    { title: t('optional'), name: false },
  ];

  const buttonsAddedByAdmin = templateButtonsAddedByAdmin.map(item => {
    const isActive = addedByAdmin === item.name;
    const className = isActive ? cn('planning-page_materialPriority_active') : cn('planning-page_materialPriority');

    const handleClick = () => {
      setAddedByAdmin(isActive ? null : item.name);
    };

    return (
      <div key={item.name} onClick={handleClick} className={className}>
        {item.title}
        {isActive && <CloseOutlined className={cn('planning-page_materialPriority_active_close')} />}
      </div>
    );
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const currentTab = query.get('tab');

    switch (currentTab) {
      case tabsKeys.education:
        setActiveTab(tabsKeys.education);
        break;

      case tabsKeys.mentoring:
        setActiveTab(tabsKeys.mentoring);
        break;

      case tabsKeys.tracks:
        setActiveTab(tabsKeys.tracks);
        break;

      case tabsKeys.pokaskills:
        setActiveTab(tabsKeys.pokaskills);
        break;

      default:
        setActiveTab(tabsKeys.education);
        break;
    }
  }, [location.search]);

  useEffect(() => {
    if (document.location.search.includes(tabsKeys.mentoring)) {
      setActiveTab(tabsKeys.mentoring);
    }
  }, [document.location.search]);

  const onSearchTopic = value => {
    if (value.length === 0) {
      setView(PLANNING);
    } else {
      setView(IS_FILTER);
      setSearchValue(value);
    }
  };

  const templateBlocks = block => (
    <CardsBlock
      key={block.name}
      name={block.name}
      title={block.title}
      cards={studyPlan}
      actions={block.actions}
      onAction={onAction}
      currentUser={currentUser}
      buttonsAddedByAdmin={view !== 'archive' ? buttonsAddedByAdmin : null}
    />
  );

  const resultButtons = (
    <Flex vertical gap='middle'>
      <Radio.Group
        className={cn('planning-page__blockButtons__radio')}
        size={isMobile ? 'middle' : 'large'}
        disabled={isLoading}
      >
        {templateButtons.map((item, index) => (
          <Badge count={item.total} overflowCount={1000} style={{ backgroundColor: '#fff', color: '#999' }} key={index}>
            <Radio.Button
              value={item.name}
              onClick={() => {
                setView(item.name);
                dispatch(getCountStudyPlans());
              }}
              className={item.className}
              key={item.name}
            >
              {item.title}
            </Radio.Button>
          </Badge>
        ))}
      </Radio.Group>
    </Flex>
  );

  const results = () => {
    let name = '';
    switch (view) {
      case WAITING:
        name = 'waitAction';
        break;
      case IN_PROGRESS:
        name = 'inProgress';
        break;
      case PLANNING:
        name = 'planned';
        break;
      case IS_FILTER:
        name = 'isFilter';
        break;
      case IS_TOPIC:
        name = 'isFilter';
        break;

      case ARCHIVE:
        name = 'archive';
        break;

      default:
        break;
    }
    return notEmptyBlocks.filter(item => item.name === name).map(templateBlocks);
  };

  const onChangePage = pagination => {
    checkView();
    if (view === 'archive') {
      dispatch(getUserIdArchiveTopicPlan({ ...data, page: pagination - 1 }));
    } else {
      dispatch(getUserIdTopicPlan({ ...data, page: pagination - 1 }));
    }
  };

  const pagination = totalElements > pageSize && (
    <Pagination
      current={mainPage + 1}
      pageSize={pageSize}
      total={totalElements}
      onChange={onChangePage}
      showSizeChanger={false}
      responsive
      hideOnSinglePage
    />
  );

  const renderMentees = () => {
    return mentees?.length > 0
      ? mentees.map(i => <MentorCard key={i.id} data={i} currentValueSelectMentor={currentValueSelectMentor} />)
      : renderEmptyBox;
  };

  const renderMenteesStatuses = () => {
    return (
      menteeAvailableStatuses?.length > 0 &&
      menteeAvailableStatuses.map(({ value, name }) => <Option key={value}>{name}</Option>)
    );
  };

  const renderEmptyBox = (
    <div className={cn('planning-page__emptyBoxContainer')}>
      <CustomEmpty withoutTitle description={t('noData')} />
    </div>
  );

  const onChangeTracksPagination = page => {
    dispatch(getTracks(currentUser.id, trackStatus, { page: page - 1, size: tracksSelector.pageSize }));
  };

  const tracksPagination = () => (
    <Pagination
      current={tracksSelector.currentPage + 1}
      pageSize={tracksSelector.pageSize}
      total={tracksSelector.totalElements}
      onChange={onChangeTracksPagination}
    />
  );

  const onChangeStatus = status => {
    if (status === trackStatus) {
      setTrackStatus('');
      status = '';
    } else {
      setTrackStatus(status);
    }
    dispatch(getTracks(currentUser.id, status, { page: 0, size: tracksSelector.pageSize }));
  };

  // Генерация статусов
  const tracksStatuses = useMemo(() => {
    return tracksSelector?.statuses.map(status => {
      const isActive = status.value === trackStatus;

      return (
        <div
          className={cn('planning-page_track_status', isActive && 'planning-page_track_status_active')}
          key={status.value}
          onClick={() => onChangeStatus(status.value)}
        >
          {status.name}
          {isActive && <CloseOutlined className={cn('planning-page_track_status_close')} />}
        </div>
      );
    });
  }, [trackStatus, tracksSelector.statuses]);

  const renderTracks = () => {
    return Array.isArray(tracksSelector.tracks) && tracksSelector.tracks?.length > 0
      ? tracksSelector.tracks?.map(track => <TrackCard key={track.trackId} track={track} />)
      : renderEmptyBox;
  };

  const onChangeTabs = value => {
    setActiveTab(value);

    navigate({
      pathname: `/${planning.path}`,
      search: getQueryString({ tab: value }),
    });
  };

  const searchChange = value => {
    setView(null);
    setSearchState(value);
  };

  const itemTabs = [
    // Изучение
    {
      key: tabsKeys.education,
      label: t('tabEducation'),
      children: (
        <>
          <Row className={cn('planning-page__tabs__btnInput')}>
            <Col className={cn('planning-page__search')}>
              <Search
                placeholder={t('searchTopic')}
                onSearch={onSearchTopic}
                className={cn('planning-page__search_comp', 'smallSizeSearchButton')}
                enterButton
                allowClear
                onChange={e => searchChange(e.target.value)}
                value={searchState}
                size='large'
              />
            </Col>
          </Row>
          <Row>
            <Col className={cn('planning-page__blockButtons')}>{resultButtons}</Col>
          </Row>
          {_loading || spinner ? (
            <Spin size='large' className={cn('planning-page__spin')} />
          ) : showCards ? (
            <div className={cn('planning-page__body')}>
              <Spin size='large' spinning={isLoading}>
                {results()}
              </Spin>
              <div className={cn('planning-page__body__pagination')}>{pagination}</div>
            </div>
          ) : (
            <div className={cn('planning-page__body', 'planning-page__body_empty')}>
              {view !== 'archive' && (
                <Row className={cn('planning-page__body_empty_filters')}>{buttonsAddedByAdmin}</Row>
              )}
              <p>
                {t('world')} <br />
                {t('add')}
              </p>
              <Button type='primary' key='goTo' size='large' onClick={() => navigate(`/collection-topics`)}>
                {t('goTo')}
              </Button>
            </div>
          )}
          {modal && <Modals modal={modal} onConfirm={onConfirmModal} onClose={onCloseModal} />}
        </>
      ),
    },
    // Треки
    {
      key: tabsKeys.tracks,
      label: t('tabTracks'),
      children: (
        <>
          <Row>
            <Col className={cn('planning-page_track_status_container')} sm={24} md={24} lg={24}>
              {/* Фильтр треков */}
              {tracksStatuses}
            </Col>
          </Row>
          <Spin size='large' spinning={tracksSelector.isLoading}>
            {!tracksSelector.isLoading && renderTracks()}

            <div className={cn('planning-page__body__pagination')}>
              {tracksSelector.totalPages > 1 && tracksPagination()}
            </div>
          </Spin>
        </>
      ),
    },
    // Менторинг
    ...(domainCompany?.hasMentors
      ? [
          {
            key: tabsKeys.mentoring,
            label: t('tabMentoring'),
            children: (
              <>
                <Spin spinning={loadingMentors} size='large'>
                  {menteeAvailableStatuses?.length > 0 && (
                    <div className={cn('planning-page__select')}>
                      <Select
                        className={cn('planning-page__selectMentorStatus')}
                        placeholder={t('selectStatus')}
                        notFoundContent='No Data'
                        onChange={value => setCurrentValueSelectMentor(value)}
                        dropdownStyle={{
                          borderBottom: '0.1px solid #666',
                          borderRight: '0.1px solid #666',
                          borderLeft: '0',
                        }}
                        value={currentValueSelectMentor}
                        allowClear
                        size='large'
                      >
                        {renderMenteesStatuses()}
                      </Select>
                    </div>
                  )}

                  {!loadingMentors && renderMentees()}
                </Spin>
              </>
            ),
          },
        ]
      : []),

    // удалить после слития всей поки в прод и вернуть без условия
    ...(UtilsPoka.showPokaElems(tabsKeys.pokaskills, domainCompany.domain, currentUser?.email)
      ? []
      : [
          {
            key: tabsKeys.pokaskills,
            label: t('tabPokaSkills'),
            children: <PokaSkills />,
          },
        ]),
  ];

  const tabsStyle = isMobile
    ? {
        width: 'fit-content',
        alignSelf: 'center',
      }
    : {};

  const hasIpr = currentUser?.domainCompany?.hasIpr;

  return (
    <>
      <div className={cn('planning-page')}>
        <TrackModal
          visible={trackModalVisible}
          tracks={currentTracks}
          changeVisible={() => changeTracksVisible(!trackModalVisible)}
        />
        <div className={cn('planning-page__header')}>
          <Row>
            <Col sm={24} md={hasIpr ? 24 : 8} className={cn('planning-page__title')}>
              <Title level={1}>{t('plan')}</Title>
              {coursesCost && !HIDE_APPROVED.includes(currentUser?.domainCompany?.id) && (
                <>
                  <p>
                    {t('description1', { date: currYear })}
                    {getCoursesCost(coursesCost.cost)}
                  </p>
                  {coursesCost.reserved !== 0 && (
                    <p>
                      {t('description2', { date: currYear })}
                      {getCoursesCost(coursesCost.reserved)}
                    </p>
                  )}
                </>
              )}
              {currentUser?.purpose && currentUser.purpose.length && (
                <>
                  <Button
                    className={cn('planning-page__btn-link')}
                    onClick={() => setPurposeVisible(!purposeVisible)}
                    type='link'
                    key='setPurposeVisible'
                  >
                    {t('purpose')}
                  </Button>
                  <Purpose
                    content={currentUser.purpose}
                    title={t('mypurpose')}
                    visible={purposeVisible}
                    onClose={() => setPurposeVisible(false)}
                  />
                </>
              )}
            </Col>

            {activeTab !== 'pokaskills' && (
              <Col xs={24} sm={24} md={hasIpr ? 24 : 16}>
                <Flex className={cn('planning-page__widgets')} gap={10} vertical={isTablet}>
                  {hasIpr && (
                    <Col xs={24} sm={24} md={8}>
                      <CareerPlanWidget count={finishedCourses} />
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={hasIpr ? 8 : 12}>
                    <FinishedWidget count={finishedCourses} />
                  </Col>
                  <Col xs={24} sm={24} md={hasIpr ? 8 : 12}>
                    <ActivityWidget activity={nearestActivity} user={currentUser} />
                  </Col>
                </Flex>
              </Col>
            )}
          </Row>
        </div>
        <Tabs
          animated={false}
          className={cn('planning-page__tabs')}
          onChange={onChangeTabs}
          activeKey={activeTab}
          items={itemTabs}
          tabBarStyle={tabsStyle}
        />
      </div>
    </>
  );
};

export default PlanningPage;
