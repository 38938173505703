export const selectLMS = state => state.lms;

export const selectScorm = state => state.lms.scorm;

export const selectVideo = state => state.lms;

export const selectArticle = state => state.lms.article;

export const selectPresentation = state => state.lms.presentation;

export const selectTypeform = state => state.lms.typeform;

export const selectPokaFiles = state => state.lms.pokaFiles;
