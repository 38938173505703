/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../Input';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TagsInput.module.scss';

const InputItem = ({
  tag,
  error,
  handleInputDelete,
  handleInputConfirm,
  id,
  linkItem,
  topicType,
  placeholder,
  deletable,
  modificate,
  errorRequiredIfEmpty,
  onChange = () => {},
}) => {
  const [inputTag, setTag] = useState(tag);
  const { t } = useTranslation('input');

  const onInputDelete = () => handleInputDelete(inputTag, id);

  const onInputConfirm = event => {
    setTag(event.target.value);
    handleInputConfirm(event.target.value, id);
  };

  const errorFlag = error !== undefined && error !== -1;

  return (
    <div
      className={classNames(
        { [css.Link]: linkItem },
        { [css.error]: errorFlag },
        { [css.error]: errorRequiredIfEmpty }
      )}
    >
      <Input
        modificate={modificate}
        value={tag}
        placeholder={deletable ? placeholder : topicType && topicType.id === 4 ? t('youtube') : placeholder}
        onChange={onInputConfirm}
        linkItem={linkItem}
        deletable={deletable}
        maxLength={255}
        handleInputDelete={onInputDelete}
        size='large'
      />
    </div>
  );
};

InputItem.propTypes = {
  deletable: PropTypes.bool,
  deleteAuthors: PropTypes.func,
  error: PropTypes.any,
  errorRequiredIfEmpty: PropTypes.bool,
  handleInputConfirm: PropTypes.func,
  handleInputDelete: PropTypes.func,
  id: PropTypes.number,
  isAuthors: PropTypes.bool,
  linkItem: PropTypes.bool,
  modificate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  tag: PropTypes.string,
  topicType: PropTypes.object,
  value: PropTypes.array,
};

export default InputItem;
