/* eslint-disable */
import { GET_SKILLS_FAIL, GET_SKILLS_START, GET_SKILLS_SUCCESS } from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getSkills = (id, onSuccess) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/${id}/skills`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_SKILLS_START, GET_SKILLS_SUCCESS, GET_SKILLS_FAIL],
  onSuccess,
});
