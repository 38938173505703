import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCertificate } from '@/store/certificate/actions';

import { Button, message, Modal, Spin, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PropTypes from 'prop-types';

import css from './certificate.module.scss';

import certsvg from '@/assets/icons/certificate.svg';

const Certificate = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation('CertificateModal');

  const certificateRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [certificateHtml, setCertificateHtml] = useState('');
  const [certificateOrientation, setCertificateOrientation] = useState('landscape'); // 'landscape' | 'portrait'
  const [messageApi, contextHolder] = message.useMessage();

  const isPortrait = certificateOrientation === 'portrait';

  const showCertificateModal = () => {
    const topicId = props.topicId;
    setIsModalVisible(true);

    dispatch(
      getCertificate(
        topicId,
        res => {
          const orientation = res.orientation || 'landscape';
          setCertificateHtml(res.content);
          setCertificateOrientation(orientation);
          if (orientation === 'landscape') {
            props.setIsMobile(960);
          }
        },
        () =>
          messageApi.open({
            type: 'error',
            content: t('showCertficateError'),
          })
      )
    );
  };

  const handleGeneratePdf = async () => {
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      return message.error(t('stopDownloadingSafari'));
    }

    const iframeDocument = certificateRef?.current?.contentDocument || certificateRef?.current?.contentWindow.document;

    let canvas = await html2canvas(
      iframeDocument.body,
      isPortrait ? { width: 1360, height: 1920 } : { width: 1920, height: 1360 }
    );
    let imgData = canvas.toDataURL('image/png');

    const doc = new jsPDF(
      isPortrait
        ? {
            orientation: 'portrait',
            format: [297, 210],
            unit: 'mm',
          }
        : {
            orientation: 'landscape',
            format: [210, 297],
            unit: 'mm',
          }
    );

    isPortrait ? doc.addImage(imgData, 'PNG', 0, 0, 210, 297) : doc.addImage(imgData, 'PNG', 0, 0, 297, 210);

    await doc.save(t('certificatePDF'));
  };

  const footerModal = (
    <Button type='primary' size='large' onClick={handleGeneratePdf}>
      {t('downloadPDF')}
    </Button>
  );

  const iframeClass = isPortrait ? css['Certificate-container-portrait'] : css['Certificate-container-landscape'];

  return (
    <>
      <div className={css['Certificate-icon']} onClick={showCertificateModal}>
        <Tooltip title={t('showCertificateTooltip')}>
          <img src={certsvg} alt='certificate icon' />
        </Tooltip>
      </div>
      {contextHolder}
      <Modal
        destroyOnClose
        centered
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={footerModal}
        width={isPortrait ? 630 : 890}
        closeIcon={<CloseOutlined className={css['Certificate-icon-close']} />}
      >
        <div className={css['Certificate-container']}>
          <Spin size='large' spinning={!certificateHtml}>
            <iframe
              ref={certificateRef}
              srcDoc={certificateHtml}
              title={`certificate-${props.topicId}`}
              seamless
              className={iframeClass}
            ></iframe>
            {props.isMobile && <div className={css['Certificate-container-desc']}>{t('descMobile')}</div>}
          </Spin>
        </div>
      </Modal>
    </>
  );
};

Certificate.propTypes = {
  topicId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  setIsMobile: PropTypes.func,
};

export default Certificate;
