import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearMessages, clearReplyMessage, getMessages } from '@/store/chat/actions';

import { selectChat } from '@/store/chat/selectors';

import Date from './Date';
import Message from './Message';

import classNames from 'classnames';
import dayjs from 'dayjs';

import styles from '../Messages.module.scss';

const Main = ({ socket }) => {
  const dispatch = useDispatch();

  const { selectedUser, messages, replyMessage } = useSelector(selectChat);

  useEffect(() => {
    if (selectedUser?.chatId) dispatch(getMessages(selectedUser.chatId));

    dispatch(clearMessages());
    dispatch(clearReplyMessage());
  }, [selectedUser]);

  useEffect(() => {
    if (messages.length) {
      socket?.emit('readMessages', { chatId: selectedUser.chatId });
    }
    return () => socket?.off('readMessages');
  }, [messages]);

  const checkFormat = (messageDate, currentDate) => {
    if (dayjs(messageDate).year() !== dayjs(currentDate).year()) {
      return 'DD MMMM YYYY';
    }

    return 'DD MMMM';
  };

  const renderMessages = () => {
    return messages.map((message, index) => {
      const currentMessageDate = message.createdAt;
      const currentDate = new window.Date();

      if (index + 1 === messages.length) {
        return (
          <React.Fragment key={message.message_id}>
            <Message data={message} />
            <Date text={dayjs(currentMessageDate).format(checkFormat(currentMessageDate, currentDate))} />
          </React.Fragment>
        );
      }

      if (
        dayjs(currentMessageDate).format('DD:MM:YYYY') === dayjs(messages[index + 1].createdAt).format('DD:MM:YYYY')
      ) {
        return <Message data={message} key={message.message_id} />;
      }

      return (
        <React.Fragment key={message.message_id}>
          <Message data={message} />
          <Date text={dayjs(currentMessageDate).format(checkFormat(currentMessageDate, currentDate))} />
        </React.Fragment>
      );
    });
  };

  return (
    <div className={replyMessage ? classNames(styles.main, styles.main_reply) : styles.main}>{renderMessages()}</div>
  );
};

export default Main;
