import {
  GET_TRACK_BY_ID_FAIL,
  GET_TRACK_BY_ID_START,
  GET_TRACK_BY_ID_SUCCESS,
  GET_TRACKS_FAIL,
  GET_TRACKS_START,
  GET_TRACKS_STATUS_FAIL,
  GET_TRACKS_STATUS_START,
  GET_TRACKS_STATUS_SUCCESS,
  GET_TRACKS_SUCCESS,
} from './constants';

import { API_URL } from '@shared/constants/config';
import { GET, REST_API } from '@shared/constants/rest-api';
import getQueryString from '@shared/utils/get-query-string';

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const defaultToken = '';

export const getTracks = (userId, status, pageable, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/user/topic/plan/track${getQueryString({ userId, status, ...pageable })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TRACKS_START, GET_TRACKS_SUCCESS, GET_TRACKS_FAIL],
  onSuccess,
  onError,
});

export const getTrackById = (trackId, ignoreTopics = true, onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/${trackId}${getQueryString({ ignoreTopics })}`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TRACK_BY_ID_START, GET_TRACK_BY_ID_SUCCESS, GET_TRACK_BY_ID_FAIL],
  onSuccess,
  onError,
});

export const getTracksStatus = (onSuccess, onError) => ({
  type: REST_API,
  method: GET,
  url: `${API_URL}/track/study-plan-status`,
  headers: {
    ...headers,
    Authorization: localStorage.getItem('authorizationToken') || defaultToken,
  },
  types: [GET_TRACKS_STATUS_START, GET_TRACKS_STATUS_SUCCESS, GET_TRACKS_STATUS_FAIL],
  onSuccess,
  onError,
});
