// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-elements-button-styles-module__ql-picker-oIaxTn.user-src-components-elements-button-styles-module__ql-font-ai3276 .user-src-components-elements-button-styles-module__ql-picker-label-sXAkVg[data-value="Montserrat"]:before, .user-src-components-elements-button-styles-module__ql-picker-oIaxTn.user-src-components-elements-button-styles-module__ql-font-ai3276 .user-src-components-elements-button-styles-module__ql-picker-item-Gy0DOA[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-elements-button-styles-module__ql-font-Montserrat-NV1gM9 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-elements-button-styles-module__button-sXDkqZ {
  text-align: center;
  color: #000;
  border-color: #e6e6e6;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
}

.user-src-components-elements-button-styles-module__button-sXDkqZ:hover, .user-src-components-elements-button-styles-module__button-sXDkqZ:focus {
  color: var(--personalColor);
  border-color: var(--personalColor);
}
`, "",{"version":3,"sources":["webpack://./user/src/components/elements/button/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,kBAAkB;EAClB,WAAW;EACX,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAMA;EAHE,2BAA2B;EAC3B,kCAAkC;AACpC","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.button {\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 1.43;\n  text-align: center;\n  color: #000;\n  border-color: #e6e6e6;\n}\n.button:hover, .button:focus {\n  color: var(--personalColor);\n  border-color: var(--personalColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-elements-button-styles-module__ql-picker-oIaxTn`,
	"qlPicker": `user-src-components-elements-button-styles-module__ql-picker-oIaxTn`,
	"ql-font": `user-src-components-elements-button-styles-module__ql-font-ai3276`,
	"qlFont": `user-src-components-elements-button-styles-module__ql-font-ai3276`,
	"ql-picker-label": `user-src-components-elements-button-styles-module__ql-picker-label-sXAkVg`,
	"qlPickerLabel": `user-src-components-elements-button-styles-module__ql-picker-label-sXAkVg`,
	"ql-picker-item": `user-src-components-elements-button-styles-module__ql-picker-item-Gy0DOA`,
	"qlPickerItem": `user-src-components-elements-button-styles-module__ql-picker-item-Gy0DOA`,
	"ql-font-Montserrat": `user-src-components-elements-button-styles-module__ql-font-Montserrat-NV1gM9`,
	"qlFontMontserrat": `user-src-components-elements-button-styles-module__ql-font-Montserrat-NV1gM9`,
	"button": `user-src-components-elements-button-styles-module__button-sXDkqZ`
};
export default ___CSS_LOADER_EXPORT___;
