import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';
import { DropdownMenu } from '@/components';
import { MaterialCard } from '@/components/material-card';

import { STUDY_PLAN_DELETE_BY_ADMIN } from '../../../constants/permissions';
import { IN_PROGRESS } from '../../../constants/study-plan-statuses';
import { StatusPopover } from '../status-popover';

import useIsMobile from '@shared/hooks/useIsMobile';
import { classNames } from '@shared/utils/styles-utils';
import { isExecutiveTask } from '@shared/utils/topic-utils';

import _get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const cn = classNames(styles);

export const Card = ({ material, currentUser, blockName, actions, onAction }) => {
  const { t } = useTranslation('planningPage');

  const { topic, status, showStudyButton, addedByAdmin, trackInfo } = material;
  const topicTypeId = _get(topic, 'type.id');
  const withEvents = _get(topic, 'type.withEvents');

  const [isMobile] = useIsMobile(800);

  const _onAction = action => () => onAction({ action, material, blockName });

  const _actions =
    !!actions &&
    material?.isCurrent &&
    actions({
      status,
      typeId: topicTypeId,
      showStudyButton,
      addedByAdmin,
      track: trackInfo,
    });

  const mainActions = _actions && _actions.main;

  const REMOVE_FROM_PLAN = isMobile ? t('removeFromPlanMobile').toUpperCase() : t('removeFromPlan').toUpperCase();
  const RESCHEDULE = t('reschedule').toUpperCase();

  const inProgress = material.status === IN_PROGRESS;
  const showRemoveFromPlan = !addedByAdmin && !withEvents;

  const additionalActions = Array.isArray(_actions?.additional)
    ? _get(_actions, 'additional', [])
        .map(item => ({ key: item.name, label: item.label }))
        .filter(item => {
          const removeButton = item.label.toUpperCase().includes(REMOVE_FROM_PLAN);

          const hideButton =
            (addedByAdmin && !currentUser.authorities.includes(STUDY_PLAN_DELETE_BY_ADMIN) && removeButton) ||
            (addedByAdmin && item.label.toUpperCase().includes(RESCHEDULE));

          if (removeButton && inProgress) {
            return showRemoveFromPlan;
          }

          return !hideButton;
        })
    : [];

  const onClickAdditionalMenu = params => _onAction(params.key)();

  const getIcon = Icon => (Icon ? <Icon /> : null);

  return (
    <MaterialCard
      material={material}
      dataqa='userMaterialPlanningCard'
      className={cn('planning-card')}
      asideClass={cn('planning-card__aside')}
      currentUser={currentUser}
    >
      {material.loading ? (
        <Spin wrapperClassName={cn('planning-card__spinner')} />
      ) : (
        <>
          <div className={cn('planning-card__status')}>
            {material.info.nameUser}&nbsp;
            {!!material.info.description && <StatusPopover status={material.info} />}
          </div>
          {mainActions && !isExecutiveTask(topic.type.id) && (
            <div className={cn('planning-card__actions')}>
              {mainActions.map(action => (
                <Button
                  key={action.name}
                  type='link'
                  onClick={_onAction(action.name)}
                  className={cn('planning-card__action')}
                >
                  {getIcon(action.icon)} {action.label}
                </Button>
              ))}
            </div>
          )}
          {mainActions && isExecutiveTask(topic.type.id) && (
            <div className={cn('planning-card__actions')}>
              {mainActions.map(action => (
                <Button
                  key={action.name}
                  type='link'
                  onClick={_onAction('goToStudy')}
                  className={cn('planning-card__action')}
                >
                  {getIcon(action.icon)} {isMobile ? t('goToProcessMobile') : t('goToProcess')}
                </Button>
              ))}
            </div>
          )}
          {additionalActions.length ? (
            <DropdownMenu
              menu={additionalActions}
              onClick={onClickAdditionalMenu}
              className={cn('planning-card__button-more')}
            />
          ) : null}
        </>
      )}
    </MaterialCard>
  );
};

Card.propTypes = {
  actions: PropTypes.any,
  onAction: PropTypes.any,
  material: PropTypes.any,
  loading: PropTypes.bool,
  blocksFinished: PropTypes.bool,
  blockName: PropTypes.string,
  currentUser: PropTypes.object,
};
