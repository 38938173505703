// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-OldIcon-OldIcon-module__ql-picker-layWgR.user-src-components-OldIcon-OldIcon-module__ql-font-kLS6Xi .user-src-components-OldIcon-OldIcon-module__ql-picker-label-hhKuRl[data-value="Montserrat"]:before, .user-src-components-OldIcon-OldIcon-module__ql-picker-layWgR.user-src-components-OldIcon-OldIcon-module__ql-font-kLS6Xi .user-src-components-OldIcon-OldIcon-module__ql-picker-item-UFKIw4[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-OldIcon-OldIcon-module__ql-font-Montserrat-qOhCOd {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: anticon;
  src: url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot");
  src: url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot?#iefix") format("embedded-opentype"), url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.woff") format("woff"), url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.ttf") format("truetype"), url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.svg#iconfont") format("svg");
}
`, "",{"version":3,"sources":["webpack://./user/src/components/OldIcon/OldIcon.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,oBAAoB;EACpB,6DAA6D;EAC7D,wUAAwU;AAC1U","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n@font-face {\n  font-family: \"anticon\";\n  src: url(\"https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot\");\n  /* IE9 */\n  src: url(\"https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot?#iefix\") format(\"embedded-opentype\"), url(\"https://at.alicdn.com/t/font_zck90zmlh7hf47vi.woff\") format(\"woff\"), url(\"https://at.alicdn.com/t/font_zck90zmlh7hf47vi.ttf\") format(\"truetype\"), url(\"https://at.alicdn.com/t/font_zck90zmlh7hf47vi.svg#iconfont\") format(\"svg\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-OldIcon-OldIcon-module__ql-picker-layWgR`,
	"qlPicker": `user-src-components-OldIcon-OldIcon-module__ql-picker-layWgR`,
	"ql-font": `user-src-components-OldIcon-OldIcon-module__ql-font-kLS6Xi`,
	"qlFont": `user-src-components-OldIcon-OldIcon-module__ql-font-kLS6Xi`,
	"ql-picker-label": `user-src-components-OldIcon-OldIcon-module__ql-picker-label-hhKuRl`,
	"qlPickerLabel": `user-src-components-OldIcon-OldIcon-module__ql-picker-label-hhKuRl`,
	"ql-picker-item": `user-src-components-OldIcon-OldIcon-module__ql-picker-item-UFKIw4`,
	"qlPickerItem": `user-src-components-OldIcon-OldIcon-module__ql-picker-item-UFKIw4`,
	"ql-font-Montserrat": `user-src-components-OldIcon-OldIcon-module__ql-font-Montserrat-qOhCOd`,
	"qlFontMontserrat": `user-src-components-OldIcon-OldIcon-module__ql-font-Montserrat-qOhCOd`
};
export default ___CSS_LOADER_EXPORT___;
