import React from 'react';

import AnswerCard from './AnswerCard';

import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import cssStyles from './MatchingQuestion.module.scss';

const Draggable = ({ answer, draggingId, inResults, wasDragged }) => {
  const id = answer.key || answer.id;
  const isDragging = Number(draggingId) === Number(id);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

  const style = transform ? { transform: CSS.Translate.toString(transform) } : undefined;

  return (
    <div
      className={classNames(cssStyles['draggable'], {
        [cssStyles['draggable-dragging']]: isDragging,
        [cssStyles['draggable-results']]: inResults,
      })}
      style={style}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
    >
      <AnswerCard answer={answer} inResults={inResults} wasDragged={wasDragged} />
    </div>
  );
};

Draggable.propTypes = {
  answer: PropTypes.object.isRequired,
  wasDragged: PropTypes.bool,
};

export default Draggable;
