import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCompetence, getCompetenceMore } from '@/store/competence/actions';
import { createMentor } from '@/store/mentoring_profile/actions';
import { getLimitSettings } from '@/store/mentors/actions';

import { selectCompetence } from '@/store/competence/selectors';
import { selectMentors } from '@/store/mentors/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Avatar, Button, Statistic as Field, Input, message, Modal, Row, Tooltip, Typography } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';
import InfiniteScrollSelect from '@/components/InfiniteScrollSelect';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';
import useIsMobile from '@shared/hooks/useIsMobile';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './MentorRequestModal.module.scss';

const { TextArea } = Input;
const { Title, Text } = Typography;

const MAX_LENGTH_VALUE = 25;

const MentorRequestModal = ({ open, onClose }) => {
  const { t } = useTranslation('MentorRequestModal');
  const dispatch = useDispatch();

  const { currentUser } = useSelector(selectUsers);
  const { competence, competencesMore, pageTotal, pageNumber } = useSelector(selectCompetence);
  const { limit } = useSelector(selectMentors);

  const defaultValues = {
    competenceIds: [],
    personalInfo: '',
    photoUuid: currentUser.localPhotoUuid,
  };
  const [data, setData] = useState(defaultValues);
  const [infoUser, setInfoUser] = useState({
    function: '',
    company: '',
    position: '',
    department: '',
    name: '',
  });
  const [maxCompetences, setMaxCompetences] = useState(limit.maxMentorCompetences);

  const [isSm] = useIsMobile(576);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const emptyText = t('infoLabels.empty');
      setInfoUser({
        function: currentUser.function || emptyText,
        company: currentUser.company || emptyText,
        position: currentUser.position || emptyText,
        department: currentUser.department || emptyText,
        name: currentUser.firstName + ' ' + currentUser.lastName,
      });
      dispatch(getLimitSettings());
    }
  }, []);

  useEffect(() => {
    setMaxCompetences(limit.maxMentorCompetences);
  }, [limit]);

  // Иницилизация
  useEffect(() => {
    dispatch(getCompetence());
  }, [open, dispatch, currentUser]);

  const [currentAvatar] = useLocalFileByUUID(currentUser.localPhotoUuid, { defaultImg: PortraitPlaceholder });

  const loadMore = () => {
    dispatch(getCompetenceMore('', pageTotal !== pageNumber ? pageNumber + 1 : 0));
  };

  const handleSelect = competenceIds => {
    if (competenceIds.length <= maxCompetences) {
      setData({ ...data, competenceIds });
    } else {
      message.warning(t('warningCompetence', { total: maxCompetences }));
      return;
    }
  };

  const footerModal = (
    <Button
      className={css['MentorRequestModal-footerBtn']}
      onClick={() => {
        dispatch(createMentor(data));
        onClose();
      }}
      disabled={!data.competenceIds?.length || !data.personalInfo}
      type='primary'
      size='large'
    >
      {isSm ? t('sendMobile') : t('send')}
    </Button>
  );

  const cancelModal = () => {
    setData(defaultValues);
    onClose();
  };

  const titleModal = (
    <Title className={css['MentorRequestModal-title']} level={4}>
      {t('title')}
    </Title>
  );

  const templateField = [
    {
      title: <Text strong>{t('infoLabels.fullName')}</Text>,
      value: infoUser.name,
    },
    {
      title: <Text strong>{t('infoLabels.position')}</Text>,
      value: infoUser.position,
    },
    {
      title: <Text strong>{t('infoLabels.department')}</Text>,
      value: infoUser.department,
    },
    {
      title: <Text strong>{t('infoLabels.company')}</Text>,
      value: infoUser.company,
    },
    {
      title: <Text strong>{t('infoLabels.function')}</Text>,
      value: infoUser.function,
    },
  ];

  const renderFields = () => {
    return templateField.map((item, index) => {
      return (
        <Tooltip title={item?.value?.length > MAX_LENGTH_VALUE ? item.value : false} key={`${item.value}-${index}`}>
          <Field
            className={css['MentorRequestModal-container-info-item']}
            title={item.title}
            value={item.value.length > MAX_LENGTH_VALUE ? Utils.trString(item.value, MAX_LENGTH_VALUE) : item.value}
            key={index}
          />
        </Tooltip>
      );
    });
  };

  return (
    <Modal open={open} title={titleModal} width={600} onCancel={cancelModal} footer={footerModal}>
      <Row className={css['MentorRequestModal-container']} type='flex'>
        <div>
          <Avatar shape='circle' className={css['MentorRequestModal-avatar']} src={currentAvatar} />
        </div>
        <div className={css['MentorRequestModal-container-info']}>{renderFields()}</div>
      </Row>
      <Row className={css['MentorRequestModal-container']} type='flex'>
        <div className={css['MentorRequestModal-container-count']}>
          {t('chooseCompetence', { total: data.competenceIds?.length })}
        </div>
        <InfiniteScrollSelect
          size='large'
          mode='multiple'
          className={css['MentorRequestModal-select']}
          placeholder={t('competences', { count: maxCompetences })}
          data={
            Array.isArray(competence.content) && competence.content.length > 0
              ? competence.content?.concat(competencesMore)
              : []
          }
          loadMore={loadMore}
          onChange={handleSelect}
          hasMore={pageTotal !== pageNumber}
          state={data.competenceIds}
        />
        <TextArea
          value={data.personalInfo}
          onChange={e => setData({ ...data, personalInfo: e.target.value })}
          className={css['MentorRequestModal-textArea']}
          placeholder={t('personalInfo')}
          maxLength={1000}
          rows={10}
          autoSize={true}
        />
      </Row>
    </Modal>
  );
};

MentorRequestModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MentorRequestModal;
