/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputItem } from './InputItem';

import _map from 'lodash/map';
import PropTypes from 'prop-types';

import { FIELDS } from '@/constants/topic-types';

const InputList = ({ fields, topicType, dataqa, isIPR, ...props }) => {
  const { t } = useTranslation(['constants', 'constants']);
  const fieldsItem = isIPR
    ? fields.filter(item => item.type !== 'files' && item.type !== 'executive_needfile')
    : fields;

  const inputs = _map(fieldsItem, field => {
    const fieldObject = FIELDS(t)[field.type];
    return (
      fieldObject && (
        <InputItem
          modificate
          key={fieldObject.apiField}
          field={fieldObject}
          topicType={topicType}
          files={props.files}
          required={field.required || !!field.requiredIfEmpty}
          {...props}
        />
      )
    );
  });

  return <div>{inputs}</div>;
};

InputItem.propTypes = {
  curTime: PropTypes.any,
  currentUser: PropTypes.any,
  fields: PropTypes.any,
  files: PropTypes.any,
  handleAddSchedule: PropTypes.any,
  handleDeleteSchedule: PropTypes.any,
  modificate: PropTypes.bool,
  onAuthorsChange: PropTypes.any,
  onChange: PropTypes.any,
  onChangeSchedule: PropTypes.any,
  onFilesChange: PropTypes.any,
  onLinksChange: PropTypes.any,
  topic: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  topicClasses: PropTypes.any,
  topicType: PropTypes.any,
  topicVideoTypes: PropTypes.any,
  onDateTimeChange: PropTypes.any,
  onYearChange: PropTypes.any,
};

export default InputList;
