/* eslint-disable */
/*

 */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';
import PortraitPlaceholder from '@/pages/Header/PortraitPlaceholder.png';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css2 from '../MaterialListItem/styles.module.scss';
import css from './MaterialsTabs.module.scss';

import lock from '@/assets/images/lock.svg';

class PartyUser extends Component {
  static propTypes = {
    domainCompany: PropTypes.object,
    getFile: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
    };
  }

  componentDidMount() {
    if (this.props.user && (this.props.user.localPhotoUuid || this.props.user.photoUuid)) {
      this.getFile(this.props.user.localPhotoUuid || this.props.user.photoUuid);
    }
  }

  getFile = uuid => {
    this.setState({ avatar: null });
    this.props.getFile(uuid, blob => {
      const imageUrl = URL.createObjectURL(blob);
      this.setState({
        avatar: imageUrl,
      });
    });
  };

  render() {
    const { user } = this.props;
    const DELETED_USER = user && user.status && user.status === 'DELETED';

    return (
      <div className={css['MaterialsTabsParty-item']}>
        <div className={classNames(css['MaterialsTabsParty-item-col'], css.user)}>
          <Link to={`/collegues/${user.id}`} target='_blank' className={css['MaterialsTabsParty-item-link']}>
            <div
              className={classNames(css['MaterialsTabsParty-item-avatar'], {
                [css2.DeleteUser]: DELETED_USER,
              })}
            >
              <img
                className={classNames({
                  [css2['DeleteUser-image']]: DELETED_USER,
                })}
                src={this.state.avatar || PortraitPlaceholder}
                alt=''
              />
            </div>
            {DELETED_USER && (
              <Tooltip title={this.props.t('userDeleted')}>
                <div className={classNames(css2['DeleteUser-key'], css2['DeleteUser-key-fix1'])}>
                  <img key='deleteUser' src={lock} alt='' />
                </div>
              </Tooltip>
            )}
            <div className={classNames(css['MaterialsTabsParty-item-col'], css.name)}>
              {user.firstName} {user.lastName}
            </div>
          </Link>
        </div>
        {this.props.domainCompany && this.props.domainCompany.hasAddititonalInfo && (
          <div className={css['MaterialsTabsParty-item-col']}>{user.function}</div>
        )}
      </div>
    );
  }
}

export default withTranslation('partyUser')(PartyUser);
