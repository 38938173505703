import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

import PropTypes from 'prop-types';

import 'react-phone-input-2/lib/style.css';

export const CustomPhoneInput = props => {
  const { setDisabledSubmit, onChange, value, isRussian, setErrors } = props;

  /**
   * Сравнение количества цифр и количества точек в формате страны
   *
   * в некоторых случаях в react-phone-input-2
   * не совпадает формат с допустимым количеством символов и не получится валидировать номер телефона
   *
   * думаю в будущем можно составить список стран для которых нужно сделать свои проверки
   *
   * @param {String | Number} number - 11234567890
   * @param {String} format - +. (...) ...-....
   */
  const validatePhone = (number = '', format = '') => {
    const numberOfDots = (format.match(/\./g) || []).length;
    const numberOfDigits = number.replace(/\D/g, '').length;
    const disabledSubmitButton = numberOfDigits !== numberOfDots;

    setDisabledSubmit(disabledSubmitButton);
    setErrors(errors => ({ ...errors, phone: disabledSubmitButton }));
  };

  return (
    <PhoneInput
      inputStyle={{ height: '35px', display: 'flex' }}
      buttonStyle={{ height: '35px' }}
      containerStyle={{ display: 'flex' }}
      country={'ru'}
      enableSearch={true}
      value={value}
      onChange={(currentValue, country) => {
        onChange(currentValue); // из antd Form.Item
        validatePhone(currentValue, country.format);
      }}
      localization={isRussian ? ru : ''}
    />
  );
};

CustomPhoneInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRussian: PropTypes.bool,
  setDisabledSubmit: PropTypes.func,
};
