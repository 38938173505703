import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { updateCareerPlan } from '@/store/career_plan/actions';
import { clearTopics, getTopics } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';

import { Button, Input, Modal, notification } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';

import { TASKS, TOPICS } from '../../constants';
import TopicModalItem from './TopicModalItem';

import { debounce, remove } from 'lodash';
import PropTypes from 'prop-types';

import css from './TopicsCareerPlanModal.module.scss';

const TopicsCareerPlanModal = ({ visible, closeModal, currentCompetence, data, isTasks }) => {
  const { t } = useTranslation('careerPlan');
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    name: currentCompetence?.name,
    size: 20,
    ipr: true,
    status: 'ACTIVE',
  });
  const { topicsAll, topicsTotal, isLoadingAll } = useSelector(selectTopics);
  const [active, setActive] = useState([]);

  useEffect(() => {
    setActive(isTasks ? currentCompetence?.tasks : currentCompetence?.topics);
  }, [currentCompetence]);

  useEffect(() => {
    if (isTasks) {
      delete filter.excludedTopicType;

      setFilter({ ...filter, topicTypeId: 25 });
    } else {
      delete filter.topicTypeId;

      setFilter({ ...filter, excludedTopicType: 25 });
    }
  }, [isTasks]);

  const onSearchTopics = useCallback(
    debounce(filters => {
      dispatch(getTopics(filters, () => {}, '', true));
    }, 600),
    []
  );

  useEffect(() => {
    if (filter.topicTypeId || filter.excludedTopicType) {
      onSearchTopics({ ...filter, page: 1 });
    }
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(clearTopics());
      onSearchTopics.cancel();
    };
  }, []);

  const onTopicClick = topic => {
    if (active.some(e => e?.id === topic?.id)) {
      setActive(remove(active, n => n?.id !== topic?.id));
    } else if (active?.length + 1 <= 50) {
      setActive([...active, topic]);
    } else {
      notification.warning({
        message: t('topicsModal.errorTitle'),
        description: t('topicsModal.errorDescription'),
      });
    }
  };

  const topicsItems = useMemo(() => {
    if (Array.isArray(topicsAll)) {
      return topicsAll?.map(topic => {
        return (
          <TopicModalItem
            key={topic?.id}
            topic={topic}
            onTopicClick={onTopicClick}
            isSelected={active.filter(el => el.id === topic.id).length}
          />
        );
      });
    }
  }, [topicsAll, active]);

  const onAddTopics = () => {
    const topics = active?.length > 0 ? active?.map(topic => topic?.id) : [];

    dispatch(
      updateCareerPlan({ [isTasks ? TASKS : TOPICS]: topics, competenceId: currentCompetence?.id }, data?.id, () =>
        closeModal()
      )
    );
  };

  const activeTopics = useMemo(
    () => active?.map(topic => <TopicModalItem key={topic?.id} topic={topic} onTopicClick={onTopicClick} isActive />),
    [active]
  );

  const loadMore = page => hasMore && dispatch(getTopics({ ...filter, page: page + 1 }, () => {}, '', false));

  const onChangeInput = e => {
    setFilter({ ...filter, name: e.target.value });
  };

  const modalFooter = (
    <div>
      <div className={css['TopicsCareerPlanModal-topic-img-mini-container']}>{activeTopics}</div>
      <Button type='primary' onClick={onAddTopics}>
        {active.length > 0 ? t('topicsModal.add') : t('topicsModal.save')}
      </Button>
    </div>
  );

  const hasMore = topicsAll.length < topicsTotal && !isLoadingAll;

  return (
    <Modal
      title={isTasks ? t('topicsModal.titleTasks') : t('topicsModal.title')}
      open={visible}
      onCancel={closeModal}
      footer={modalFooter}
      width={700}
    >
      <div className={css['TopicsCareerPlanModal-content']}>
        <div className={css['TopicsCareerPlanModal-content-header']}>
          <Input allowClear placeholder={t('topicsModal.name')} onChange={onChangeInput} value={filter?.name} />
        </div>
        <div className={css['TopicsCareerPlanModal-content-container']}>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} initialLoad={false} threshold={500} useWindow={false}>
            {Array.isArray(topicsAll) && topicsAll?.length > 0 ? (
              topicsItems
            ) : (
              <CustomEmpty
                className={css['TopicsCareerPlanModal-content-container-empty']}
                description={t('topicsModal.empty', { search: filter.name })}
                withoutTitle
                isLoading={isLoadingAll}
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
};

TopicsCareerPlanModal.propTypes = {
  closeModal: PropTypes.func,
  currentCompetence: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    topics: PropTypes.array,
  }),
  data: PropTypes.shape({
    id: PropTypes.number,
  }),
  isTasks: PropTypes.bool,
  visible: PropTypes.bool,
};

export default TopicsCareerPlanModal;
