/* eslint-disable */
/*

 */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Input } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './TimeInput.module.scss';

class TimeInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool,
    modificate: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    startWith: PropTypes.string,
    value: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    if (props.value) {
      let { value } = props;
      const weeks = props.startWith === 'week' ? Math.floor(value / (24 * 60 * 7)) : 0;
      value -= weeks * 24 * 60 * 7;
      const days = props.startWith === 'day' || props.startWith === 'week' ? Math.floor(value / (24 * 60)) : 0;
      value -= days * 24 * 60;
      const hours = Math.floor(value / 60);
      value -= hours * 60;
      const minutes = value;
      this.state = {
        weeks: weeks || undefined,
        days: days || undefined,
        hours: hours || undefined,
        minutes: minutes || undefined,
        value: props.value,
      };
    } else {
      this.state = {
        value: undefined,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { value } = nextProps;
    const weeks = nextProps.startWith === 'week' ? Math.floor(value / (24 * 60 * 7)) : 0;
    value -= weeks * 24 * 60 * 7;

    const days = nextProps.startWith === 'day' || nextProps.startWith === 'week' ? Math.floor(value / (24 * 60)) : 0;

    value -= days * 24 * 60;

    const hours = Math.floor(value / 60);
    value -= hours * 60;

    const minutes = value;

    this.setState({
      weeks: weeks || undefined,
      days: days || undefined,
      hours: hours || undefined,
      minutes: minutes || undefined,
      value: nextProps.value,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      this.setState({
        value: this.state.value,
      });
    }
  }

  handleChange = event => {
    const { name } = event.target;
    const value = parseInt(event.target.value);
    if (value < 0) {
      return;
    }
    const newValue = isNaN(value) ? undefined : value;
    const { name: inputName, onChange } = this.props;
    this.setState(state => {
      const newState = { ...state, [name]: newValue };
      newState.value =
        (newState.minutes || 0) +
        60 * (newState.hours || 0) +
        24 * 60 * (newState.days || 0) +
        24 * 60 * 7 * (newState.weeks || 0);
      onChange({ name: inputName, value: newState.value });
      return newState;
    });
  };

  render() {
    const { className, startWith, t } = this.props;

    return (
      <div className={classNames(className, css.TimeInput)}>
        {startWith === 'week' ? (
          <div className={css['TimeInput-labelBlock']}>
            <Input
              placeholder='0'
              name='weeks'
              value={this.state.weeks}
              onChange={this.handleChange}
              type='text'
              className={classNames(css['TimeInput-input'], {
                [css['TimeInput-modificate']]: this.props.modificate,
              })}
              maxLength={3}
            />
            <p className={css['TimeInput-label']}>{t('week')}</p>
          </div>
        ) : null}
        {startWith === 'day' || startWith === 'week' ? (
          <div className={css['TimeInput-labelBlock']}>
            <Input
              placeholder='0'
              name='days'
              value={this.state.days}
              onChange={this.handleChange}
              type='text'
              className={classNames(css['TimeInput-input'], {
                [css['TimeInput-modificate']]: this.props.modificate,
              })}
              maxLength={3}
            />
            <p className={css['TimeInput-label']}>{t('day')}</p>
          </div>
        ) : null}
        <Input
          placeholder='0'
          name='hours'
          value={this.state.hours}
          onChange={this.handleChange}
          type='text'
          className={classNames(
            css['TimeInput-input'],
            {
              [css['TimeInput-modificate']]: this.props.modificate,
            },
            { [css['TimeInput-error']]: this.props.error }
          )}
          maxLength={3}
        />
        <p className={css['TimeInput-label']}>{t('hour')}</p>
        <Input
          placeholder='0'
          name='minutes'
          value={this.state.minutes}
          onChange={this.handleChange}
          type='text'
          className={classNames(
            css['TimeInput-input'],
            { [css['TimeInput-modificate']]: this.props.modificate },
            { [css['TimeInput-error']]: this.props.error }
          )}
          maxLength={2}
        />
        <p className={css['TimeInput-label']}>{t('minute')}</p>
      </div>
    );
  }
}

export default withTranslation('timeInput')(TimeInput);
