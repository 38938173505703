/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'antd';

import PropTypes from 'prop-types';

import css from './Compilations.module.scss';

export default function CompilationsEmptyPlaceholder(props) {
  const { t } = useTranslation('compilations');
  return props.isFavorite && !props.isSearching ? (
    <div className={css.notFound}>
      {t('favorite')}
      <Link to='/compilations'>
        <Button size='large' type='primary' onClick={() => null}>
          {t('goTo')}
        </Button>
      </Link>
    </div>
  ) : (
    <div data-qa='userCompilationsNotFoundBlock' className={css.notFound}>
      {t('experts')}
    </div>
  );
}

CompilationsEmptyPlaceholder.propTypes = {
  isFavorite: PropTypes.bool,
  isSearching: PropTypes.bool,
};
