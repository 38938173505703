/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { createCareerPlanTask } from '@/store/career_plan/actions';
import { createExecutiveTopicInfo, getExecutiveTopicInfo, updateExecutiveTopicInfo } from '@/store/executive/actions';
import { createFile, createFileImage, deleteFile, getFile } from '@/store/files/actions';
import { getUserIncome2, setScopeIncome } from '@/store/income/actions';
import { getTopicTypeLabel, getTypeLabels } from '@/store/label_topic/actions';
import { getLanguages } from '@/store/locales/actions';
import { createTag } from '@/store/tags/actions';
import {
  checkDuplicate,
  clearDuplicate,
  createTopic,
  editTopic,
  editTopicVisibility,
  failTopicValidation,
  getTopicCategories,
  getTopicCategoryCompetencies,
  getTopicClasses,
  getTopicLevel,
  getTopicMandatory,
  getTopicSubTypes,
  getTopicTypes,
  getTopicVideoTypes,
} from '@/store/topics/actions';
import { getUserById } from '@/store/users/actions';

import { selectFiles as filesSelect } from '@/store/files/selectors';
import { selectTypeLabel } from '@/store/label_topic/selectors';
import { selectLocales } from '@/store/locales/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Form, Input, Select, Tag } from 'antd';
import { INCOME_FORMAT } from '@/pages/CareerPlan/constants';
import DoublesList from '@/components/DoublesList';
import ImageInput from '@/components/ImageInput';
import Modal from '@/components/Modal';
import ModalCategory from '@/components/ModalCategory/ModalCategory';
import OldIcon from '@/components/OldIcon';

import AdditionalInputCard from './AdditionalInputCard';

import TagsAutocomplete from '@shared/components/TagsAutocomplete';
import { IMAGE_TYPES } from '@shared/constants/image-types';
import debounce from '@shared/utils/debounce';
import { isExecutiveTask } from '@shared/utils/topic-utils';

import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import Stickyfill from 'stickyfilljs';

import css from './TopicAddModal.module.scss';

import { NEW_TOPIC, TOPIC } from '@/constants/income-types';
import { COMMON_FIELDS, FIELDS, TOPIC_SUBTYPES, TOPIC_TYPES } from '@/constants/topic-types';

dayjs.extend(objectSupport);
const YYYY_MM_DD = 'YYYY-MM-DD';
const { TextArea } = Input;

export class TopicAddModal extends Component {
  static propTypes = {
    categories: PropTypes.array,
    checkDuplicate: PropTypes.func,
    clearDuplicate: PropTypes.func,
    createFile: PropTypes.func,
    createFileImage: PropTypes.func,
    createTag: PropTypes.func,
    createTopic: PropTypes.func,
    currentUser: PropTypes.object,
    currentUserId: PropTypes.number,
    currentUserTypes: PropTypes.any,
    deleteFile: PropTypes.func,
    domainCompany: PropTypes.object,
    editTopic: PropTypes.func,
    editable: PropTypes.bool,
    failTopicValidation: PropTypes.func,
    fileInfo: PropTypes.object,
    getFile: PropTypes.func,
    getLanguages: PropTypes.func,
    getUserIncome: PropTypes.func,
    setScopeIncome: PropTypes.func,
    getTopicCategories: PropTypes.func,
    getTopicClasses: PropTypes.func,
    getTopicLevel: PropTypes.func,
    getTopicSubTypes: PropTypes.func,
    getTopicTypes: PropTypes.func,
    getTopicVideoTypes: PropTypes.func,
    getUserById: PropTypes.func,
    imageSrc: PropTypes.string,
    isLoadingDuplicate: PropTypes.bool,
    isLoadingFile: PropTypes.bool,
    isLoadingFileImage: PropTypes.bool,
    links: PropTypes.array,
    name: PropTypes.string,
    onCancel: PropTypes.func,
    onTopicAddSuccess: PropTypes.func,
    tempImageSrc: PropTypes.string,
    topic: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    topicCategories: PropTypes.array,
    topicClasses: PropTypes.array,
    languages: PropTypes.array,
    topicDuplicate: PropTypes.array,
    topicLevel: PropTypes.array,
    topicSubTypes: PropTypes.array,
    topicTypes: PropTypes.array,
    topicVideoTypes: PropTypes.array,
    visible: PropTypes.bool,
    isGlobal: PropTypes.bool,
    getTopicCategoryCompetencies: PropTypes.func,
    getTopicMandatory: PropTypes.func,
    topicCategoryCompetencies: PropTypes.array,
    topicMandatories: PropTypes.array,
    isIPR: PropTypes.bool,
    createCareerPlanTask: PropTypes.func,
    careerPlan: PropTypes.object,
    competenceId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    if (props.topic) {
      const {
        type,
        tags,
        categories,
        level,
        videoType,
        topicClass,
        qiwi,
        qiwiLibrary,
        subType,
        dates,
        files,
        language,
        ...topic
      } = props.topic;
      this.state = {
        links: [],
        ...topic,
        files,
        fileUuids: files && files.map(file => file.uuid),
        qiwi: qiwi || false,
        qiwiLibrary: qiwiLibrary || false,
        typeId: type && type.id,
        subTypeId: subType && subType.id,
        categoryIds: categories,
        levelId: level && level.id,
        languageId: language && language.id,
        videoTypeId: videoType && videoType.id,
        topicClassId: topicClass && topicClass.id,
        tagNames: tags || [],
        errors: {},
        dates: dates || [{ dates: [], endTime: '', startTime: '', place: '' }],
        thumbFileUuid: this.props.tempImageSrc,
        confirmVisible: false,
        createBtnClick: false,
        formChange: false,
        formFields: [],
        duplicateParams: {
          name: props.topic.name,
          urls: props.topic.links && props.topic.links.length > 0 ? props.topic.links.map(item => item.url) : [],
        },
        modalCategory: false,
        topicCompetenceCategory: this.props.topic?.topicCompetenceCategory || [],
        topicMandatory: this.props.topic?.topicMandatory,
      };
    } else {
      this.state = {
        issued: dayjs().format('YYYY-MM-DD'),
        tagNames: [],
        links: [],
        qiwi: false,
        qiwiLibrary: false,
        errors: {},
        confirmVisible: false,
        createBtnClick: false,
        duplicateParams: {
          name: '',
          urls: [],
        },
        dates: [{ dates: [], endTime: '', startTime: '', place: '' }],
        languageId: this.props.languages.length && this.props.languages[0].id,
        modalCategory: false,
        topicCompetenceCategory: this.props.topic?.topicCompetenceCategory || [],
        topicMandatory: this.props.topic?.topicMandatory,
        typeId: this.props.isIPR ? 25 : null,
        subTypeId: this.props.isIPR && null,
      };
    }
    this.checkDuplicate = debounce(props.checkDuplicate, 100);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.topicMandatories?.length) {
      this.props.getTopicMandatory(false);
    }

    if (!this.props.topicCategoryCompetencies?.length) {
      this.props.getTopicCategoryCompetencies();
    }

    if (this.props.topic && this.props.topic.type) {
      this.props.getTopicSubTypes(this.props.topic.type.id);
    }
    if (!this.props.topicTypes) {
      this.props.getTopicTypes();
    }
    if (Array.isArray(this.props.typeLabel) && !this.props.typeLabel.length) {
      this.props.getTypeLabels();
    }
    if (!this.props.topicCategories) {
      this.props.getTopicCategories(this.props.isGlobal);
    }
    if (!this.props.topicLevel) {
      this.props.getTopicLevel();
    }
    if (!this.props.topicVideoTypes) {
      this.props.getTopicVideoTypes();
    }
    if (!this.props.topicClasses) {
      this.props.getTopicClasses();
    }
  }

  componentDidMount() {
    if (!this.props.languages.length) {
      this.props.getLanguages(response => !this.state.languageId && this.setState({ languageId: response[0].id }));
    }

    if (this.props.isIPR) {
      this.props.getTopicSubTypes(25);
      this.setState({ executive_needfile: true });
    } else {
      this.props.checkDuplicate(this.state.duplicateParams);
    }

    if (this.props?.topic?.type?.id === 25 && this.props.editable) {
      this.props.getExecutiveTopicInfo(
        this.props.topic.id,
        res => {
          if (res && res.id) {
            this.setState({
              executiveId: res.topicId,
              executive_duration: res.duration,
              executive_results: res.resultInfo,
              executive_activity: res.activityInfo,
              executive_indicators: res.indicatorInfo,
              executive_needfile: res.requiredFile,
            });
          }
        },
        err => {
          this.setState({
            executiveId: null,
            executive_duration: 0,
            executive_results: 'error',
            executive_activity: 'error',
            executive_indicators: 'error',
            executive_needfile: false,
          });
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.props.visible) {
      const elements = document.querySelectorAll('.sticky');
      Stickyfill.add(elements);
    }
  }

  handleChangeSchedule = (key, name, value) => {
    this.setState(prevState => {
      const dates = prevState.dates.map(date => _cloneDeep(date));

      dates[key][name] = value;

      return { dates };
    });
  };

  handleDeleteSchedule = id => {
    const dates = this.state.dates.slice();
    dates.splice(id, 1);
    this.setState({ dates });
  };

  handleAddSchedule = () => {
    const dates = this.state.dates.slice();
    dates.push({ dates: [], endTime: '', startTime: '', place: '' });
    this.setState({ dates });
  };

  handleChange = (event, nameSelect) => {
    const name = nameSelect ? nameSelect : event?.target ? event?.target.name : event.name;
    const value = nameSelect ? event : event?.target ? event?.target.value : event.value;

    if (name === 'startDate') {
      this.setState({
        [name]: dayjs(value).format('YYYY-MM-DD'),
        formChange: true,
        errors: {},
      });
    } else {
      this.setState({ [name]: value, formChange: true, errors: {} });
    }
    if (name === 'name') {
      if (value.length >= 3) {
        this.setState(
          {
            duplicateParams: {
              ...this.state.duplicateParams,
              name: value,
              errors: {},
            },
          },
          () => {
            !this.props.withoutDublicate && this.checkDuplicate({ ...this.state.duplicateParams, name: value });
          }
        );
      } else if (value.length === 3) {
        this.setState(
          {
            duplicateParams: {
              ...this.state.duplicateParams,
              name: '',
              errors: {},
            },
          },
          () => {
            !this.props.withoutDublicate && this.checkDuplicate({ ...this.state.duplicateParams, name: '' });
          }
        );
      } else {
        this.props.clearDuplicate();
      }
    }
  };

  handleChangeCategory = value => {
    this.setState({ categoryIds: value, modalCategory: false, errors: {} });
  };

  getTypeLabel = value => {
    const topicTypeFields = this.props.currentUserTypes.find(type => type.id === value || value === '') || {};

    const label = this.props.typeLabel.find(label => topicTypeFields.name?.includes(label.name));

    this.props.getTopicTypeLabel(label.id, topicTypeFields.approved, topicTypeFields.withEvents, res => {
      this.setState({ typeLabelId: res.id });
    });
  };

  handleSubtypeChange = subtypes => {
    if (subtypes && subtypes[1]) {
      this.setState({ subTypeId: subtypes[1].id, formChange: true });
    }
  };

  handleTypeChange = value => {
    this.setState({ typeId: value, subTypeId: value === 1 ? 24 : null, errors: {} }, () => {
      if (value) {
        this.props.getTopicSubTypes(value, this.handleSubtypeChange);
        this.getTypeLabel(value);
        this.setState({ formChange: true });
      }
    });
  };

  handleLinksChange = event => {
    const { name, value } = event.target || event;
    const stateValues = this.state[name];
    const undeleteble = stateValues.filter(data => data.undeletable);
    const local = stateValues.filter(data => !data.undeletable);
    const linksValue =
      local.length > value.length
        ? local.filter(data => value.includes(data.url))
        : local.length < value.length
          ? [...local, { name: value[value.length - 1], url: value[value.length - 1] }]
          : value.map(link => {
              const oldOne = local.find(field => field.url === link);
              if (oldOne) {
                return oldOne;
              } else {
                const edited = local.find(field => !value.includes(field.url));
                if (!edited.undeletable) {
                  return { name: link, url: link };
                }
                return { ...edited, url: link };
              }
            });

    if (Object.keys(this.state.errors).length) {
      this.setState({ errors: {} });
    }

    this.setState(
      {
        [name]: [...undeleteble, ...linksValue],
        formChange: true,
        duplicateParams: { ...this.state.duplicateParams, urls: value },
      },
      () => {
        !this.props.withoutDublicate && this.checkDuplicate({ ...this.state.duplicateParams, urls: value });
      }
    );
  };

  handleAuthorsChange = event => {
    const { name, value } = event.target || event;
    const authorsValue = value;
    this.setState({ [name]: authorsValue, formChange: true });
  };

  handleDateTimeChange = event => {
    this.setState({
      startTime: dayjs(event).format('HH:mm:ss'),
      formChange: true,
    });
  };

  handleYearChange = ({ value, name }) => {
    if (value && value > dayjs().year()) {
      this.setState({ [name]: dayjs().month(0).date(1).format(YYYY_MM_DD), formChange: true });
      return;
    }
    if (!value) {
      this.setState({ [name]: undefined, formChange: true });
      return;
    }

    const dayjsValue = dayjs(String(value).padStart(4, '0'), 'YYYY', true);
    const resultValue = dayjsValue.isValid() ? dayjsValue.format(YYYY_MM_DD) : undefined;

    this.setState({ [name]: resultValue, formChange: true });
  };

  isFieldEmpty = fieldName => {
    if (typeof this.state[fieldName] === 'boolean') {
      return false;
    }
    if (Array.isArray(this.state[fieldName])) {
      return !this.state[fieldName].length;
    }
    return !this.state[fieldName];
  };

  isLinkCorrect = (fieldName, required) => {
    const flag = [];
    const value = this.state[fieldName];
    value &&
      value
        .filter(link => link.url)
        .map((link, item) => {
          if (
            link.url.indexOf('http://') !== 0 &&
            link.url.indexOf('https://') !== 0 &&
            link.url.indexOf('ftp://') !== 0
          ) {
            flag.push(item);
          }
        });
    if ((!value || !value.length || !value[0].url) && required) {
      flag.push(0);
    }
    return flag;
  };

  isScheduleCorrect = () => {
    const flag = [];

    this.state.dates &&
      this.state.dates.map((schedule, item) => {
        if (!schedule.startTime || !schedule.endTime || !schedule.place || schedule.dates.length === 0) {
          flag.push(item);
        }
      });
    return flag;
  };

  formValidation = () => {
    const errors = {};
    let haveErrors = false;
    let errorMessage = null;
    for (let i = 0; i < COMMON_FIELDS.length; i++) {
      const field = COMMON_FIELDS[i];
      if (field.required && this.isFieldEmpty(field.apiField)) {
        errors[field.apiField] = this.props.t('require');
        haveErrors = true;
      }
    }

    const topicType = TOPIC_TYPES(this.props.t).find(type => type.id === this.state.typeId) || {};
    const topicTypeTab = TOPIC_SUBTYPES(this.props.t).find(
      subtype => subtype.active && subtype.typeId === this.state.typeId && subtype.id === this.state.subTypeId
    );
    const topicTypeObject = topicTypeTab || topicType;
    const topicTypeFields = this.props.isIPR
      ? topicTypeObject.fields?.filter(item => item.type !== 'files' && item.type !== 'executive_needfile')
      : topicTypeObject.fields || [];

    for (let i = 0; i < topicTypeFields.length; i++) {
      const field = topicTypeFields[i];
      const fieldObject = FIELDS(this.props.t)[field.type];

      switch (fieldObject.apiField) {
        case 'links':
          const flagLink = this.isLinkCorrect(fieldObject.apiField, field.required);

          const hasLinks =
            this.state[fieldObject.apiField] && this.state[fieldObject.apiField].filter(item => item.url !== '');
          if (flagLink.length) {
            if (hasLinks && hasLinks.length) {
              errors[fieldObject.apiField] = flagLink;
              errorMessage = this.props.t('link2');
              haveErrors = true;
            } else {
              errors[fieldObject.apiField] = flagLink;
              errorMessage = this.props.t('require');
              haveErrors = true;
            }
          }
          break;
        case 'dates':
          const scheduleCorrect = this.isScheduleCorrect();

          if (scheduleCorrect.length) {
            errors[fieldObject.apiField] = scheduleCorrect;
            haveErrors = true;
          }
          break;
        default:
          if (field.required && this.isFieldEmpty(fieldObject.apiField)) {
            errors[fieldObject.apiField] = this.props.t('require');
            haveErrors = true;
          }
          break;
      }

      const requiredFieldObject = FIELDS(this.props.t)[field.requiredIfEmpty] || {};
      if (
        field.requiredIfEmpty &&
        this.isFieldEmpty(fieldObject.apiField) &&
        this.isFieldEmpty(requiredFieldObject.apiField)
      ) {
        errors[fieldObject.apiField] = this.props.t('requireField', {
          label: requiredFieldObject.label || '',
        });
        haveErrors = true;
      }
    }

    this.setState({ errors });
    if (haveErrors) {
      this.props.failTopicValidation();
    }
    if (errorMessage) {
      this.props.failTopicValidation(errorMessage);
    }
    return !haveErrors;
  };

  tagMap = item => ({ id: String(item.id).startsWith('new') ? 0 : item.id, name: item.name });

  // Создание материала
  handleAddTopicClick = () => {
    this.setState({ links: this.state.links.filter(link => link.url) });

    const topicToSend = {
      ...this.state,
      errors: '',
      scope: 'UNMODERATE',
      confirmVisible: '',
      categoryIds: this.state.categoryIds && this.state.categoryIds.map(cat => cat.id),
      dates: this.state.dates.reduce((res, date) => {
        date.dates.forEach(dt =>
          res.push({
            startDate: dayjs(dt).format('YYYY-MM-DD'),
            startTime: dayjs(date.startTime).format('HH:mm:ss'),
            endTime: dayjs(date.endTime).format('HH:mm:ss'),
            place: date.place,
          })
        );
        return res;
      }, []),
      competenceId: this.props.competenceId,
      coverColor: this.state.coverColor,
      tags: this.state.tagNames.map(this.tagMap),
    };

    if (this.formValidation()) {
      this.setState({ createBtnClick: true });
      if (this.props.isIPR) {
        this.props.createCareerPlanTask(
          {
            ...topicToSend,
            startDateForPlan: dayjs(this.props.careerPlan.deadline, INCOME_FORMAT).format('YYYY-MM-DD'),
          },
          this.props.careerPlan.id,
          newTopic => {
            const executiveData = {
              topicId: newTopic.id,
              activityInfo: topicToSend.executive_activity,
              duration: topicToSend.executive_duration,
              fileUuids: topicToSend.fileUuids,
              indicatorInfo: topicToSend.executive_indicators,
              resultInfo: topicToSend.executive_results,
              requiredFile: topicToSend.executive_needfile && topicToSend.executive_needfile,
            };
            this.props.createExecutiveTopicInfo(executiveData);
            this.props.onCancel();
          }
        );
      } else {
        this.props.createTopic(
          topicToSend,
          newTopic => {
            if (isExecutiveTask(newTopic?.type?.id)) {
              const executiveData = {
                topicId: newTopic.id,
                activityInfo: topicToSend.executive_activity,
                duration: topicToSend.executive_duration,
                fileUuids: topicToSend.fileUuids,
                indicatorInfo: topicToSend.executive_indicators,
                resultInfo: topicToSend.executive_results,
                requiredFile: topicToSend.executive_needfile && topicToSend.executive_needfile,
              };
              this.props.createExecutiveTopicInfo(executiveData);
            }

            const isAdminOrSuperAdmin = this.props.currentUser && Utils.isAdminOrSuperAdmin(this.props.currentUser);
            const isManager = this.props.currentUser && Utils.isManager(this.props.currentUser);

            const hasCoins = this.props.domainCompany?.hasCoins;

            if (hasCoins) {
              this.props.getUserIncome({
                userId: this.props.currentUserId,
                topicId: newTopic.id,
                bonus: TOPIC,
                incomeType: NEW_TOPIC,
                onSuccess: () => {
                  this.props.setScopeIncome(newTopic.scope);
                },
              });
            }

            if (isAdminOrSuperAdmin || isManager) {
              this.props.editTopicVisibility(newTopic.id, false);
            }
            this.props.getUserById(false, this.props.currentUserId);
            this.props.onTopicAddSuccess(newTopic);
            this.setState({ createBtnClick: false });
          },
          () => {
            this.setState({ createBtnClick: false });
          },
          this.props.domainCompany?.hasManager
        );
      }
    }
  };

  handleEditTopicClick = () => {
    const { ...topic } = this.state;
    topic.errors = '';
    topic.confirmVisible = '';
    topic.categoryIds = topic.categoryIds && topic.categoryIds.map(cat => cat.id);
    topic.links = topic.links.filter(link => link && link.url);
    topic.tags = topic.tagNames.map(this.tagMap);

    const resultDates = [];
    topic.dates.map(shed => {
      shed.dates.map(date => {
        resultDates.push({
          startDate: dayjs(date).format('YYYY-MM-DD'),
          startTime: dayjs(shed.startTime).format('HH:mm:ss'),
          endTime: dayjs(shed.endTime).format('HH:mm:ss'),
          place: shed.place,
        });
      });
    });
    topic.dates = resultDates;
    delete topic.confirmVisible;
    delete topic.createBtnClick;
    delete topic.duplicateParams;
    delete topic.errors;
    delete topic.formChange;
    delete topic.modalCategory;
    if (this.formValidation()) {
      this.setState({ createBtnClick: true });
      this.props.editTopic(
        topic,
        () => {
          if (isExecutiveTask(topic.typeId)) {
            let files = [];
            if (this.state.fileUuids.length) {
              files = this.state.fileUuids;
            }
            let data = {
              activityInfo: this.state.executive_activity,
              duration: this.state.executive_duration,
              fileUuids: files,
              indicatorInfo: this.state.executive_indicators,
              requiredFile: this.state.executive_needfile,
              resultInfo: this.state.executive_results,
              topicId: topic.id,
            };
            this.props.updateExecutiveTopicInfo(data);
            this.props.onCancel();
          }

          this.setState({ createBtnClick: false });
          this.props.onTopicAddSuccess();
        },
        () => this.setState({ createBtnClick: false }),
        this.props.domainCompany && this.props.domainCompany.hasManager
      );
    }
  };

  handleImageChange = (file, name, info, cropImageSrc, coverColor) => {
    if (file && info) {
      if (IMAGE_TYPES.includes(info.type)) {
        if (this.state.coverFileUuid) {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        } else {
          this.props.createFileImage(file, fileInfo => {
            this.setState(() => ({
              [name]: fileInfo.uuid,
              thumbFileUuid: fileInfo.thumbUuid,
              errors: {},
              coverColor,
              formChange: true,
              cropImageSrc,
            }));
          });
        }
        return true;
      } else {
        this.setState(state => ({
          [name]: null,
          thumbFileUuid: null,
          coverColor: null,
          errors: {
            ...state.errors,
            [name]: this.props.t('format'),
          },
        }));
      }
    } else {
      this.setState(state => ({
        [name]: file,
        coverColor: null,
        thumbFileUuid: null,
        errors: { ...state.errors, [name]: '' },
      }));
    }
    this.setState({ formChange: true });
  };

  handleFilesChange = (files, name) => {
    if (Array.isArray(files)) {
      const filesValue = files.map(file => file && file.uuid);
      this.setState({ [name]: filesValue, formChange: true });
    }
  };

  showConfirmDialog = () => {
    if (this.state.formChange) {
      return this.setState({ confirmVisible: true });
    } else {
      return this.props.onCancel();
    }
  };

  handleCategoryClose = id => {
    const closeCategory = this.state.categoryIds.filter(category => {
      if (category.id !== id) {
        return category;
      }
    });
    this.setState({ categoryIds: closeCategory });
  };

  showCategory = () => {
    return (
      this.state.categoryIds &&
      this.state.categoryIds.map(category => (
        <Tag key={category.id} className={classNames(css.tag, css.modificate)}>
          {category.name}
          <OldIcon className={css.closeIcon} type='close' onClick={() => this.handleCategoryClose(category.id)} />
        </Tag>
      ))
    );
  };

  hideConfirmDialog = () => this.setState({ confirmVisible: false });

  openModalCategory = () => this.setState({ modalCategory: true });
  closeModalCategory = () => this.setState({ modalCategory: false });

  render() {
    const {
      visible,
      onCancel,
      topicCategories,
      topicVideoTypes,
      fileInfo,
      editable,
      topicDuplicate,
      topicSubTypes,
      topicClasses,
      topicLevel,
      currentUserTypes,
      languages,
      isIPR,
      withoutDublicate,
      t,
    } = this.props;
    const topicTypesConst = !isIPR
      ? currentUserTypes &&
        currentUserTypes.filter(topicType => {
          const topicTypeFields =
            TOPIC_TYPES(this.props.t).find(type => type.id === topicType.id || topicType.id === '') || {};
          return topicTypeFields.active;
        })
      : TOPIC_TYPES(this.props.t);

    const submitButton = editable ? (
      <Button
        id={'TopicAddModal_Edit_Button'}
        disabled={this.state.createBtnClick || this.props.isLoadingFileImage || this.props.isLoadingFile}
        onClick={this.handleEditTopicClick}
        type='primary'
        size='large'
      >
        {t('edit')}
      </Button>
    ) : (
      <Button
        data-qa={'collectionTopicsAddMaterialModalSaveMaterial'}
        id={'TopicAddModal_Submit_Button'}
        disabled={this.state.createBtnClick || this.props.isLoadingFileImage || this.props.isLoadingFile}
        onClick={this.handleAddTopicClick}
        type='primary'
        size='large'
      >
        {t('addMaterial')}
      </Button>
    );

    const competenceOptions = this.props?.topicCategoryCompetencies?.map(option => ({
      label: option.name,
      value: option.value,
    }));

    const mandatoriesOptions = this.props.topicMandatories?.map(option => ({
      label: option.name,
      value: option.value,
    }));

    const getModalTitle = () => {
      switch (editable) {
        case true:
          if (isIPR) {
            return t('editCareerPlan');
          } else {
            return t('editPlan');
          }

        case false:
          if (isIPR) {
            return t('addCareerPlan');
          } else {
            return t('addPlan');
          }

        default:
          return '';
      }
    };

    return (
      <Modal
        className={css.Modal}
        modificate
        title={getModalTitle()}
        open={visible}
        onCancel={this.showConfirmDialog}
        hideFooter
        width={800}
        id='materialModal'
      >
        <Form className={css['Add-modal-form']} labelCol={{ span: 6 }} labelAlign='left'>
          {!withoutDublicate && (
            <div className={css['Add-modal-DoublesList']}>
              <div className={classNames(css['Add-modal-DoublesList-box'], 'sticky')}>
                <DoublesList list={topicDuplicate} isLoadingDuplicate={this.props.isLoadingDuplicate} />
              </div>
            </div>
          )}
          <div id='area' className={classNames(css['App-card-item'], css['Add-modal'])}>
            <Form.Item
              label={t('title')}
              error={this.state.errors.name}
              required
              validateStatus={this.state.errors.name ? 'error' : ''}
            >
              <Input
                id={'TopicAddModal_MaterialName'}
                name='name'
                data-qa={'collectionNameInput'}
                className={css['Add-modal-form-inputModificate']}
                placeholder={t('titleDescription')}
                onChange={this.handleChange}
                value={this.state.name}
                maxLength={500}
                size='large'
              />
            </Form.Item>
            <Form.Item label={t('description')} error={this.state.errors.description} required>
              <TextArea
                id={'TopicAddModal_MaterialDescription'}
                data-qa={'collectionDescriptionInput'}
                className={this.state.errors.description ? css['Add-modal-textarea-error'] : css['Add-modal-textarea']}
                rows={8}
                name='description'
                placeholder={t('descriptionPlaceholder')}
                onChange={this.handleChange}
                value={this.state.description}
                maxLength={4096}
              />
            </Form.Item>
            {/* Тип топика */}
            <Form.Item
              label={t('type')}
              error={this.state.errors.typeId}
              required
              validateStatus={this.state.errors.typeId ? 'error' : ''}
            >
              <Select
                id={'TopicAddModal_MaterialType'}
                data-qa={'collectionMaterialType'}
                className={css['Add-modal-form-selectModificate']}
                disabled={
                  (!dayjs(this.state.startDate).isSame(dayjs(), 'day') &&
                    !dayjs(this.state.startDate).isAfter() &&
                    this.state.typeId === 8 &&
                    this.state.startDate !== null) ||
                  editable ||
                  isIPR
                }
                placeholder={t('chooseMaterial')}
                onChange={this.handleTypeChange}
                name='typeId'
                options={topicTypesConst?.map(option => ({ label: option.name, value: option.id }))}
                value={this.state.typeId}
                size='large'
              />
            </Form.Item>
            <Form.Item
              label={t('Cover')}
              error={this.state.errors.coverFileUuid}
              className={css['Add-modal-form-imageInput']}
            >
              <ImageInput
                id={'TopicAddModal_MaterialCover'}
                modificate
                onChange={this.handleImageChange}
                name='coverFileUuid'
                fileInfo={fileInfo}
                imageSrc={this.props.imageSrc}
                isLoadingFile={this.props.isLoadingFile || this.state.createBtnClick}
                isLoadingFileImage={this.props.isLoadingFileImage}
              />
            </Form.Item>
          </div>
          <div className={classNames(css['App-card-item'], css['Add-modal'])}>
            <Form.Item
              label={<div style={{ textWrap: 'wrap' }}>{t('topicCategoryCompetencies')}</div>}
              error={this.state.errors.topicCompetenceCategory}
              required
            >
              <Select
                data-qa={'topicCompetenceCategory'}
                id={'TopicAddModal_topicCompetenceCategory'}
                className={css['Add-modal-form-selectModificate']}
                placeholder={t('deepLevel')}
                onChange={value => this.handleChange(value, 'topicCompetenceCategory')}
                options={competenceOptions}
                value={this.state?.topicCompetenceCategory}
                size='large'
              />
            </Form.Item>
            <Form.Item label={t('area')} error={this.state.errors.categoryIds} required>
              <div className={css.tag__tagList}>
                <Button
                  data-qa={'collectionTopicsAddMaterialModalScopeButton'}
                  id={'TopicAddModal_MaterialCategory'}
                  name='categoryIds'
                  onClick={this.openModalCategory}
                  className={classNames(css.categoryButton, {
                    [css['categoryButton-error']]: this.state.errors.categoryIds,
                  })}
                  size='large'
                >
                  {t('select')}
                </Button>
                {this.showCategory()}
              </div>
            </Form.Item>
            <Form.Item label={t('topicMandatories')} error={this.state.errors.topicMandatory} required>
              <Select
                data-qa={'topicMandatory'}
                id={'TopicAddModal_topicMandatory'}
                className={css['Add-modal-form-selectModificate']}
                placeholder={t('deepLevel')}
                onChange={value => this.handleChange(value, 'topicMandatory')}
                options={mandatoriesOptions}
                value={this.state.topicMandatory}
                disabled={editable}
                size='large'
              />
            </Form.Item>
            <Form.Item
              label={t('deep')}
              error={this.state.errors.levelId}
              required
              validateStatus={this.state.errors.levelId ? 'error' : ''}
            >
              <Select
                data-qa={'collectionMaterialLevel'}
                id={'TopicAddModal_MaterialLevel'}
                className={css['Add-modal-form-selectModificate']}
                placeholder={t('deepLevel')}
                getPopupContainer={() => document.getElementById('area')}
                onChange={value => this.handleChange(value, 'levelId')}
                options={topicLevel?.map(option => ({ label: option.name, value: option.id }))}
                value={this.state.levelId}
                size='large'
              />
            </Form.Item>
            <Form.Item label={t('language')} error={this.state.errors.languageId}>
              <Select
                data-qa={'collectionMaterialLanguage'}
                id={'TopicAddModal_MaterialLanguage'}
                onChange={value => this.handleChange(value, 'languageId')}
                options={languages?.map(option => ({ label: option.name, value: option.id }))}
                value={this.state.languageId}
                size='large'
              />
            </Form.Item>
            <Form.Item label={t('Tags')} error={this.state.errors.tagNames}>
              <TagsAutocomplete
                id='tagsAutocomplete'
                onChange={this.handleChange}
                className={css['App-card-item__tags-input']}
                name='tagNames'
                createTag={this.props.createTag}
                placeholder={t('tagDescription')}
                value={this.state.tagNames}
                // isGlobal={this.props.isGlobal}
                rulesForCreate
                t={this.props.t}
              />
            </Form.Item>
          </div>
          <AdditionalInputCard
            topic={this.state}
            topicTypeId={this.state.typeId}
            topicSubTypeId={this.state.subTypeId}
            handleAddSchedule={this.handleAddSchedule}
            handleDeleteSchedule={this.handleDeleteSchedule}
            onChange={this.handleChange}
            onChangeSchedule={this.handleChangeSchedule}
            onFilesChange={this.handleFilesChange}
            onLinksChange={this.handleLinksChange}
            onAuthorsChange={this.handleAuthorsChange}
            onDateTimeChange={this.handleDateTimeChange}
            onYearChange={this.handleYearChange}
            topicVideoTypes={topicVideoTypes}
            topicClasses={topicClasses}
            topicSubTypes={topicSubTypes}
            files={this.state.fileUuids}
            editable={editable}
            currentUser={this.props.currentUser}
            isIPR={isIPR}
          />
          <div className={classNames(css['App-card-item'], css['App-card-item-submitButton'])}>{submitButton}</div>
        </Form>
        <Modal
          open={this.state.confirmVisible}
          onOk={onCancel}
          onCancel={this.hideConfirmDialog}
          contentClassName={css['App-card-item-modal']}
          okText={t('cancel')}
          title={t('cancelWithoutSave')}
          footerBtnOrange
          manualCancel
          modificate
          cardOn
        />
        {this.state.modalCategory && (
          <ModalCategory
            visible={this.state.modalCategory}
            open={this.props.visible}
            category={this.state.categoryIds}
            categories={this.props.categories}
            onChange={this.handleChangeCategory}
            domainCompany={this.props.domainCompany}
            name='categoryIds'
            options={topicCategories}
            value={this.state.categoryIds}
            closeModalCategory={this.closeModalCategory}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  [topicsSelect, filesSelect, usersSelect, selectLocales, selectTypeLabel],
  (topics, files, users, { languages }, typeLabel) => ({
    topicVideoTypes: topics.topicVideoTypes,
    topicClasses: topics.topicClasses,
    topicCategories: topics.categories?.filter(({ sharing }) => sharing === users.currentUser.domainCompany.global),
    topicLevel: topics.level,
    topicTypes: topics.types,
    topicSubTypes: topics.subtypes,
    topicDuplicate: topics.duplicate,
    categories: topics.categories?.filter(({ sharing }) => sharing === users.currentUser.domainCompany.global),
    isLoadingDuplicate: topics.isLoadingDuplicate,
    fileInfo: files.info,
    isLoadingFileImage: files.isLoadingImage,
    isLoadingFile: files.isLoading,
    currentUser: users.currentUser,
    currentUserTypes: users.currentUser?.domainCompany && users.currentUser.domainCompany.topicTypes,
    currentUserId: users.currentUser?.id,
    domainCompany: users.currentUser?.domainCompany,
    isGlobal: users?.currentUser?.domainCompany?.global,
    languages,
    typeLabel: typeLabel.typeLabel,
    topicCategoryCompetencies: topics.topicCategoryCompetencies,
    topicMandatories: topics.topicMandatories,
  })
);

const mapActionsToProps = {
  getFile,
  createTopic,
  editTopic,
  createTag,
  failTopicValidation,
  createFile,
  deleteFile,
  createFileImage,
  getTopicVideoTypes,
  getTopicClasses,
  getTopicCategories,
  getTopicLevel,
  getTopicTypes,
  getTopicSubTypes,
  getUserIncome: getUserIncome2,
  setScopeIncome,
  checkDuplicate,
  clearDuplicate,
  getUserById,
  getLanguages,
  createExecutiveTopicInfo,
  getTypeLabels,
  getTopicTypeLabel,
  editTopicVisibility,
  getTopicCategoryCompetencies,
  getTopicMandatory,
  createCareerPlanTask,
  getExecutiveTopicInfo,
  updateExecutiveTopicInfo,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['topicDialog', 'constants'])(TopicAddModal));
