/* eslint-disable */
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCountComment, getCountReview } from '@/store/topics/actions';

import { selectTopics } from '@/store/topics/selectors';
import { selectCurrentUser } from '@/store/users/selectors';

import { Tabs } from 'antd';
import css from '@/pages/CollectionMaterialsID/CollectionMaterialsID.module.scss';
import PokaSkillAbout from '@/components/MaterialsTabs/PokaSkillAbout';

import MaterialsTabsAbout from './MaterialsTabsAbout';
import MaterialsTabsComment from './MaterialsTabsComment';
import MaterialsTabsEvents from './MaterialsTabsEvents';
import MaterialsTabsExecutive from './MaterialsTabsExecutive';
import MaterialsTabsFile from './MaterialsTabsFile';
import MaterialsTabsParty from './MaterialsTabsParty';
import MaterialsTabsReview from './MaterialsTabsReview';

import {
  isConference,
  isCorpCourseWithOrWithoutApprove,
  isEvent,
  isExecutiveTask,
  isExternalCourse,
  isSkills,
} from '@shared/utils/topic-utils';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export class MaterialsTabs extends Component {
  static propTypes = {
    clearTopicComment: PropTypes.func,
    clearTopicMention: PropTypes.func,
    comments: PropTypes.object,
    createTopicComment: PropTypes.func,
    currentUser: PropTypes.object,
    deleteComment: PropTypes.func,
    eventsCounts: PropTypes.number,
    getAllFile: PropTypes.func,
    getFile: PropTypes.func,
    getTopicComments: PropTypes.func,
    getTopicMentions: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingAllFile: PropTypes.bool,
    match: PropTypes.object,
    mentions: PropTypes.object,
    onAddComment: PropTypes.func,
    restoreComment: PropTypes.func,
    topic: PropTypes.object,
    updateComment: PropTypes.func,
    updateMention: PropTypes.func,
    getCountComment: PropTypes.func,
    getCountReview: PropTypes.func,
    checkPreviousStudyPlansFunc: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      topicActiveKey: isSkills(props.topic?.type?.id) ? 'poka' : 'about',
    };
  }

  componentDidMount() {
    if (this.props.topic && this.props.topic.id) {
      this.props.getCountComment({
        topicIds: this.props.topic.id,
        mention: false,
      });

      this.props.getCountReview({
        topicIds: this.props.topic.id,
        mention: true,
      });
    }
  }

  changeTabs = event => {
    this.setState({ topicActiveKey: event });
  };

  deleteMyComment = (id, onSuccess) => {
    this.props.deleteComment(id, onSuccess);
  };

  restoreMyComment = (id, onSuccess) => {
    this.props.restoreComment(id, onSuccess);
  };

  render() {
    const { topic, currentUser, t } = this.props;
    if (!topic.id) {
      return null;
    }

    const currentUserId = currentUser?.id;

    const tabItems = [
      {
        key: 'about',
        label: t('about'),
        children: <MaterialsTabsAbout topic={topic} />,
      },
      ...(topic?.type && isSkills(topic.type.id)
        ? [
            {
              key: 'poka',
              label: t('pokaSkillAbout'),
              children: <PokaSkillAbout poka={topic.poka} />,
            },
          ]
        : []),
      ...(topic?.type && isExecutiveTask(topic.type.id)
        ? [
            {
              key: 'executive',
              label: t('executive'),
              children: <MaterialsTabsExecutive topic={topic} companyID={this.props.currentUser.domainCompany.id} />,
            },
          ]
        : []),
      {
        key: 'comment',
        label: (
          <p data-qa='detailCollectionCommentItem'>
            {t('comment')}
            <span className={css['Details-tabs-name-count']}>{this.props.commentCount}</span>
          </p>
        ),
        children: (
          <MaterialsTabsComment
            topic={topic}
            currentUser={this.props.currentUser}
            isLoading={this.props.isLoading}
            updateComment={this.props.updateComment}
            restoreComment={this.restoreMyComment}
            deleteComment={this.deleteMyComment}
            clearTopicComment={this.props.clearTopicComment}
            onAddComment={this.props.onAddComment}
            getTopicComments={this.props.getTopicComments}
            createTopicComment={this.props.createTopicComment}
            comments={this.props.comments}
            getFile={this.props.getFile}
            getCount={this.props.getCount}
          />
        ),
      },
      {
        key: 'review',
        label: (
          <p data-qa='detailCollectionReviewItem'>
            {t('feedback')}
            <span className={css['Details-tabs-name-count']}>{this.props.reviewCount}</span>
          </p>
        ),
        children: (
          <MaterialsTabsReview
            topic={topic}
            currentUser={this.props.currentUser}
            isLoading={this.props.isLoading}
            updateMention={this.props.updateMention}
            clearTopicMention={this.props.clearTopicMention}
            getTopicMentions={this.props.getTopicMentions}
            createTopicComment={this.props.createTopicComment}
            mentions={this.props.mentions}
            getFile={this.props.getFile}
            restoreComment={this.restoreMyComment}
            deleteComment={this.deleteMyComment}
          />
        ),
      },
      {
        key: 'file',
        label: (
          <p data-qa='detailCollectionFileItem'>
            {t('file')}
            <span className={css['Details-tabs-name-count']}>{(topic && topic.files && topic.files.length) || ''}</span>
          </p>
        ),
        children: (
          <MaterialsTabsFile
            topic={topic}
            isLoadingAllFile={this.props.isLoadingAllFile}
            isLoading={this.props.isLoading}
            getFile={this.props.getFile}
            getAllFile={this.props.getAllFile}
          />
        ),
      },
      ...(topic?.type?.id && (isExternalCourse(topic.type.id) || isCorpCourseWithOrWithoutApprove(topic.type.id))
        ? [
            {
              key: 'events',
              label: (
                <p data-qa='detailCollectionDateItem'>
                  {t('date')}
                  <span className={css['Details-tabs-name-count']}>{topic?.eventCount || ''}</span>
                </p>
              ),
              children: (
                <MaterialsTabsEvents
                  status={topic.status}
                  topic={topic}
                  topicType={topic?.type?.id}
                  topicTypeName={topic?.type?.name}
                  eventCount={topic?.eventCount}
                  currentUser={this.props.currentUser}
                  checkPreviousStudyPlansFunc={this.props.checkPreviousStudyPlansFunc}
                />
              ),
            },
          ]
        : []),
      ...(topic?.type?.id && (isEvent(topic.type.id) || isConference(topic.type.id))
        ? [
            {
              key: 'events',
              label: (
                <p data-qa='detailCollectionMembersItem'>
                  {t('members')}
                  <span className={css['Details-tabs-name-count']}>
                    {topic.planStatus.planStatus ? topic.planStatus.count : topic.planStatus.count || ''}
                  </span>
                </p>
              ),
              children: <MaterialsTabsParty currentUserId={currentUserId} topic={topic} />,
            },
          ]
        : []),
    ];

    return (
      <div>
        <Tabs
          onChange={this.changeTabs}
          activeKey={this.state.topicActiveKey}
          className={classNames(css['Details-tabs'], {
            [css['Details-tabs--events']]:
              topic?.type && (isExternalCourse(topic.type.id) || isCorpCourseWithOrWithoutApprove(topic.type.id)),
          })}
          items={tabItems}
          defaultActiveKey={'about'}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector([selectTopics, selectCurrentUser], (selectTopics, currentUser) => ({
  commentCount: selectTopics.countComment,
  reviewCount: selectTopics.countReview,
  currentUser,
}));

const mapActionsToProps = {
  getCountComment,
  getCountReview,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('materialsTabs')(MaterialsTabs));
