export const SINGLE_ID = 0;
export const MULTIPLE_ID = 1;
export const OPEN_ID = 2;
export const MATCHING_ID = 3;

export const SINGLE = '1';
export const MULTIPLE = '2';
export const OPEN = '3';
export const MATCHING = '4';

export const MIN_MATCHING_ANSWERS = 2;
export const MAX_MATCHING_ANSWERS = 10;

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const getLetter = index => {
  if (index < alphabet.length) {
    return alphabet[index];
  }
  const quotient = Math.floor(index / alphabet.length);
  const remainder = index % alphabet.length;
  return alphabet[quotient - 1] + alphabet[remainder];
};
