import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { getFile } from '@/store/files/actions';

import { FiDownload, FiFile, FiTrash } from 'react-icons/fi';

import PropTypes from 'prop-types';

import styles from '../Messages.module.scss';

const File = ({ fileName, fileUuid, handleDelete, canDelete }) => {
  const dispatch = useDispatch();

  const handleDownloadFile = () => {
    dispatch(
      getFile(fileUuid, blob => {
        const url = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
    );
  };

  return (
    <div className={styles.file}>
      <FiFile size={16} />
      <p className={styles.file__name}>{fileName}</p>
      {!canDelete ? (
        <FiDownload onClick={handleDownloadFile} className={styles.file__icon} size={16} />
      ) : (
        <FiTrash onClick={() => handleDelete(fileUuid)} className={styles.file__icon} size={16} />
      )}
    </div>
  );
};

File.propTypes = {
  fileName: PropTypes.string,
  fileUuid: PropTypes.string,
  handleDelete: PropTypes.func,
  canDelete: PropTypes.bool,
};

File.defaultProps = {
  canDelete: false,
};

export default memo(File);
