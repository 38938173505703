/* eslint-disable */
import {
  CLEAR_POKA_SKILLS_FILTERS,
  GET_POKA_ASSESSOR_FAIL,
  GET_POKA_ASSESSOR_START,
  GET_POKA_ASSESSOR_SUCCESS,
  GET_POKA_CATEGORY_FAIL,
  GET_POKA_CATEGORY_START,
  GET_POKA_CATEGORY_SUCCESS,
  GET_POKA_CHECKLIST_START,
  GET_POKA_CHECKLIST_SUCCESS,
  GET_POKA_DIR_SITE_FAIL,
  GET_POKA_DIR_SITE_START,
  GET_POKA_DIR_SITE_SUCCESS,
  GET_POKA_SKILLS_FAIL,
  GET_POKA_SKILLS_START,
  GET_POKA_SKILLS_SUCCESS,
  GET_POKA_STATUS_FAIL,
  GET_POKA_STATUS_START,
  GET_POKA_STATUS_SUCCESS,
  SAVE_POKA_SKILLS_FILTERS,
  SAVE_POKA_SKILLS_SORT,
  SET_IS_FAVOURITE,
} from './constants';

const initialState = {
  isError: false,
  isLoadingPoka: false,
  pokaSkills: [],
  pokaSkiilsTotal: null,
  pokaSkiilsPageSize: null,
  pokaSkiilsPageNumber: null,
  pokaSkiilsCurrentPage: null,
  pokaSkillsFilters: {},
  pokaSkillsSort: '',
  isLoading: false,
  assessor: null,
  checkListInfo: null,
  pokaStatus: [],
  pokaCategory: [],
  pokaSites: [],
  loadingPokaSites: false,
  errorPokaSites: false,
};

export default (state = initialState, { type, response, value }) => {
  switch (type) {
    case GET_POKA_SKILLS_START:
      return { ...state, isLoadingPoka: true, isError: false };

    case GET_POKA_STATUS_START:
    case GET_POKA_CATEGORY_START:
      return { ...state, isLoading: true, isError: false };

    case GET_POKA_STATUS_SUCCESS:
      return { ...state, isLoading: true, isError: false, pokaStatus: response };

    case GET_POKA_CATEGORY_SUCCESS:
      return { ...state, isLoading: true, isError: false, pokaCategory: response };

    case GET_POKA_SKILLS_SUCCESS:
      return {
        ...state,
        isLoadingPoka: false,
        isError: false,
        pokaSkills: response.content,
        pokaSkiilsTotal: response.totalElements,
        pokaSkiilsPageSize: response.size,
        pokaSkiilsPageNumber: response.totalPages,
        pokaSkiilsCurrentPage: response.number,
      };

    case GET_POKA_SKILLS_FAIL:
    case GET_POKA_CATEGORY_FAIL:
      return { ...state, isLoadingPoka: false, isError: true };

    case GET_POKA_STATUS_FAIL:
      return { ...state, isLoading: false, isError: true };

    case SAVE_POKA_SKILLS_SORT:
      return { ...state, pokaSkillsSort: value };

    case SAVE_POKA_SKILLS_FILTERS:
      return { ...state, pokaSkillsFilters: value };

    case CLEAR_POKA_SKILLS_FILTERS:
      return { ...state, pokaSkillsSort: {} };

    case GET_POKA_ASSESSOR_START:
      return { ...state, isLoading: true, assessor: null };

    case GET_POKA_ASSESSOR_SUCCESS:
      return { ...state, isLoading: false, assessor: response };

    case GET_POKA_ASSESSOR_FAIL:
      return { ...state, isLoading: false, assessor: null };

    case GET_POKA_CHECKLIST_START:
      return { ...state, isLoading: true, checkListInfo: null };

    case GET_POKA_CHECKLIST_SUCCESS:
      return { ...state, isLoading: false, checkListInfo: response };

    case SET_IS_FAVOURITE:
      const newState = state.pokaSkills.map(item => {
        if (item.topicId === value.topicId) {
          return { ...item, isFavorite: value.isFavourite };
        }
        return item;
      });

      return { ...state, pokaSkills: newState };

    case GET_POKA_DIR_SITE_START:
      return {
        ...state,
        loadingPokaSites: true,
        errorPokaSites: false,
        pokaSites: [],
      };

    case GET_POKA_DIR_SITE_SUCCESS:
      return {
        ...state,
        loadingPokaSites: false,
        errorPokaSites: false,
        pokaSites: response,
      };

    case GET_POKA_DIR_SITE_FAIL:
      return {
        ...state,
        loadingPokaSites: false,
        errorPokaSites: true,
        pokaSites: [],
      };

    default:
      return { ...state };
  }
};
