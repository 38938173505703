/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Card, Col, Row } from 'antd';

import { PROJECT_STATUS_INACTIVE, PROJECT_STATUS_UNKNOWN } from './constants';
import ProjectCardCover from './ProjectCardCover';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import css from '../css/styles.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

export const ProjectCard = props => {
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [fileUUID, setFileUUID] = useState(null);
  const [start, setStart] = useState(null);
  const [finish, setFinish] = useState(null);
  const [status, setStatus] = useState(PROJECT_STATUS_UNKNOWN);

  const navigate = useNavigate();
  const { t } = useTranslation('D360_Project');

  useEffect(() => {
    if (props.project) {
      if (props.project.id) {
        setId(props.project.id);
      }

      if (props.project.name) {
        setName(truncateString(props.project.name, 50));
      }

      if (props.project.description) {
        setDescription(truncateString(props.project.description, 150));
      }

      if (props.project.file_uuid) {
        setFileUUID(props.project.file_uuid);
      }

      if (props.project.start) {
        setStart(dayjs(props.project.start).format(DATE_FORMAT).toString());
      }

      if (props.project.d360_status_id) {
        setStatus(props.project.d360_status_id);
      }

      if (props.project.finish) {
        setFinish(dayjs(props.project.finish).format(DATE_FORMAT).toString());
      }
    }
  }, [props]);

  const truncateString = (str, len) => {
    let sliced = str.slice(0, len);
    if (sliced.length < str.length) {
      sliced += '...';
    }

    return sliced;
  };

  const render = () => {
    return (
      <Card
        className={css.ProjectCard}
        hoverable={true}
        bordered={true}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/d360/${id}`)}
      >
        {/*<Link to={`/d360/${id}`}>*/}
        <ProjectCardCover uuid={fileUUID} status={status} projectId={id} />

        {status === PROJECT_STATUS_INACTIVE && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '5px 10px',
              backgroundColor: 'rgba(0,0,0,.75)',
              color: '#98e10a',
              fontWeight: '500',
              borderRadius: '0 0 0 10px',
            }}
          >
            {t('completed')}
          </div>
        )}

        <h2 className={css.ProjectCardTitle}>{name}</h2>

        <p className={css.ProjectCardDescription}>{description}</p>

        <Row className={css.ProjectCardRow}>
          <Col span={15} className={css.ProjectCardFieldKey}>
            {t('startDate')}
          </Col>
          <Col span={9} className={css.ProjectCardFieldVal} style={{ backgroundColor: '#88c053', color: 'white' }}>
            {start}
          </Col>
        </Row>

        <Row className={css.ProjectCardRow}>
          <Col span={15} className={css.ProjectCardFieldKey}>
            {t('endDate')}
          </Col>
          <Col span={9} className={css.ProjectCardFieldVal} style={{ backgroundColor: '#e7553e', color: 'white' }}>
            {finish}
          </Col>
        </Row>
        {/*</Link>*/}
      </Card>
    );
  };

  return render();
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectCard;
