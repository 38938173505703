/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';

import { getBookAddresses, updateBookAddresses } from '@/store/books/actions';
import { getChapters, getChaptersOffline } from '@/store/chapters/actions';
import { eventRequestFinish, eventTopicInfo } from '@/store/course/actions';
import { getAllFile, getFile } from '@/store/files/actions';
import { getUserIncome2 } from '@/store/income/actions';
import { clearQuestionNumber, getBlockStatistic, getBlockStatisticOffline } from '@/store/lms/actions';
import { setSearchText } from '@/store/search/actions';
import {
  checkPreviousStudyPlans,
  checkStudyPlan,
  clearPlan,
  createStudyPlan,
  getEventParty,
  getUserTopicStudyPlanStatus,
  notUpdateButtonFunc,
  sendNotificationStudyPlanFinished,
  updateButtonFunc,
  updateStudyPlanStatus,
} from '@/store/study-plan/actions';
import {
  addTopicCommentsToList,
  changeTopicFilter,
  checkTopicComment,
  clearSubType,
  clearTopicComment,
  clearTopicFilter,
  clearTopicMention,
  createTopicComment,
  deleteTopicComment,
  getAlpinaLink,
  getTopicById,
  getTopicComments,
  getTopicMentions,
  getTopicOffline,
  increaseTopicViews,
  restoreTopicComment,
  showSendReviewModal,
  updateTopicComment,
  updateTopicMention,
} from '@/store/topics/actions';
import {
  checkFavoriteUserTopic,
  createUserTopic,
  createUserTopicToMultipleUsers,
  deleteUserTopic,
  sendNotification,
} from '@/store/user-topics/actions';
import { getUserById, getUsers } from '@/store/users/actions';

import { selectCourse as coursesSelect, selectOpenDate } from '@/store/course/selectors';
import { selectFiles as fileSelect } from '@/store/files/selectors';
import { selectLMS } from '@/store/lms/selectors';
import { selectLogin as loginSelect } from '@/store/login/selectors';
import { selectStudyPlan as studyPlanSelect } from '@/store/study-plan/selectors';
import { selectTopics as topicsSelect } from '@/store/topics/selectors';
import { selectUserTopics as userTopics } from '@/store/user-topics/selectors';
import { selectUsers as usersSelect } from '@/store/users/selectors';

import { Button, Col, Divider, Layout, message, Modal, Row, Spin, Tag, Tooltip } from 'antd';
import AddressBook from '@/components/AddressBook/AddressBook';
import BadgeElement from '@/components/BadgeElement';
import Certificate from '@/components/Certificate';
import ExpandableInfo from '@/components/ExpandableInfo/ExpandableInfo.jsx';
import FieldItem from '@/components/FieldItem/FieldItem';
import FieldItemFile from '@/components/FieldItem/FieldItemFile';
import MaterialCost from '@/components/MaterialCard/MaterialCost';
import MaterialsLink from '@/components/MaterialsLink/MaterialsLink';
import MaterialsTabs from '@/components/MaterialsTabs/MaterialsTabs';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon.jsx';
import ModalCurrentPaidTopic from '@/components/ModalCurrentPaidTopic/ModalCurrentPaidTopic.jsx';
import ModalPaidTopicRestriction from '@/components/ModalPaidTopicRestriction/ModalPaidTopicRestriction.jsx';
import AudienceConfirmationModal from '@/components/ModalTargetAudience/Modal.jsx';
import ParticipationConfirmationModal from '@/components/ModalTargetAudienceApproval/Modal.jsx';
import Rate from '@/components/Rate';
import ShowProgress from '@/components/ShowProgress/ShowProgress';
import TopicAddModal from '@/components/TopicAddModal/index.jsx';
import Participants from '@/components/TopicParticipants';
import TrackModal from '@/components/TracksModal';
import TypeBadge from '@/components/TypeBadge';
import UsersModal from '@/components/UsersModal/UsersModal';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleFilled,
  HeartFilled,
  HeartOutlined,
  UpOutlined,
} from '@ant-design/icons';

import CalendarModal from '../CalendarModal/index.jsx';

import { domainCompanyid } from '@/utils/domainCompanyId';
import { getLinkFromUrl, getStudyUrl, shareLink } from '@/utils/topicLinks';
import { ADMIN_NAME } from '@shared/constants/admin-name';
import { checkActiveCourse } from '@shared/utils/course-utils';
import { formatFullDate, formatTime } from '@shared/utils/date-utils';
import {
  getWithoutSchedule,
  isAnyCourseOrConference,
  isBook,
  isConference,
  isCorpCourseWithOrWithoutApprove,
  isEvent,
  isEventOrConference,
  isExecutiveTask,
  isExternalCourse,
  isSkills,
  isVideo,
  shouldCheckPreviousStudyPlans,
} from '@shared/utils/topic-utils';
import { isYouTube } from '@shared/utils/validate-utils';

import disable_material from '@/assets/images/dis.png?react';
import EditGrey from '@/assets/images/edit-grey-16px.svg?react';
import Recommend from '@/assets/images/recommend.svg?react';
import Share from '@/assets/images/share-black.svg?react';
import Resources from '@/resources';
import Utils from '@/Utils';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { openDB } from 'idb';
import { isEmpty } from 'lodash';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import css from './CollectionMaterialsID.module.scss';

import { COMMENT_TOPIC } from '@/constants/income-types';
import { CHAPTERS_IDB, IDB_NAME, STATISTIC_IDB, TOPICS_IDB, TOPICS_IMAGES_IDB } from '@/constants/IndexedDB';
import { FINISHED, SCHEDULED, WAITING } from '@/constants/study-plan-statuses';
import { ADD_REPEAT } from '@/constants/studyPlanStatus';
import { PUBLIC_FIELDS, TOPIC_TYPES } from '@/constants/topic-types';
import { RECOMMENDED } from '@/constants/user-topic-results';
import views from '@/assets/images/views.svg';

const { Content } = Layout;

const isFieldEmpty = field => {
  if (typeof field === 'boolean') {
    return false;
  }

  if (Array.isArray(field)) {
    return !field.length;
  }

  return !field;
};

const mobileWidth = 768;

export class CollectionMaterialsID extends Component {
  static propTypes = {
    addTopicCommentsToList: PropTypes.func,
    changeTopicFilter: PropTypes.func,
    checkFavoriteUserTopic: PropTypes.func,
    checkStudyPlan: PropTypes.func,
    checkStudyPlanMonth: PropTypes.array,
    checkPreviousStudyPlans: PropTypes.func,
    checkTopicComment: PropTypes.func,
    clearSubType: PropTypes.func,
    clearTopicComment: PropTypes.func,
    clearTopicFilter: PropTypes.func,
    clearTopicMention: PropTypes.func,
    comments: PropTypes.array,
    commentsCount: PropTypes.number,
    commentsHasMore: PropTypes.bool,
    commentsPage: PropTypes.number,
    commentsPageNumber: PropTypes.number,
    createStudyPlan: PropTypes.func,
    createTopicComment: PropTypes.func,
    createUserTopic: PropTypes.func,
    createUserTopicToMultipleUsers: PropTypes.func,
    currentTopicId: PropTypes.number,
    currentUser: PropTypes.object,
    currentUserId: PropTypes.number,
    deleteTopicComment: PropTypes.func,
    deleteUserTopic: PropTypes.func,
    domainCompany: PropTypes.object,
    eventRequestFinish: PropTypes.func,
    eventSchedule: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    eventTopicInfo: PropTypes.func,
    externalLogin: PropTypes.string,
    file: PropTypes.any,
    lms: PropTypes.object,
    getAllFile: PropTypes.func,
    getEventParty: PropTypes.func,
    getFile: PropTypes.func,
    getUserIncome2: PropTypes.func,
    getStudyPlan: PropTypes.func,
    getTopicById: PropTypes.func,
    getTopicOffline: PropTypes.func,
    getTopicComments: PropTypes.func,
    getTopicMentions: PropTypes.func,
    getTopics: PropTypes.func,
    getUserById: PropTypes.func,
    getUsers: PropTypes.func,
    navigate: PropTypes.func,
    increaseTopicViews: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingAllFile: PropTypes.bool,
    location: PropTypes.object,
    mentions: PropTypes.array,
    mentionsCount: PropTypes.number,
    mentionsHasMore: PropTypes.bool,
    mentionsPage: PropTypes.number,
    mentionsPageNumber: PropTypes.number,
    newStudyPlan: PropTypes.array,
    notUpdateButtonFunc: PropTypes.func,
    onCancel: PropTypes.func,
    restoreTopicComment: PropTypes.func,
    sendNotification: PropTypes.func,
    sendNotificationStudyPlanFinished: PropTypes.func,
    setSearchText: PropTypes.func,
    getAlpinaLink: PropTypes.func,
    showSendReviewModal: PropTypes.func,
    // social: PropTypes.string,
    studyPlanId: PropTypes.array,
    topic: PropTypes.object,
    topicStatus: PropTypes.bool,
    topicWasFinishedBefore: PropTypes.bool,
    updateButton: PropTypes.bool,
    updateButtonFunc: PropTypes.func,
    updateStudyPlanStatus: PropTypes.func,
    updateTopicComment: PropTypes.func,
    updateTopicMention: PropTypes.func,
    getBlockStatistic: PropTypes.func,
    getBlockStatisticOffline: PropTypes.func,
    userTopicsFavorite: PropTypes.array,
    visible: PropTypes.bool,
    getChapters: PropTypes.func,
    getChaptersOffline: PropTypes.func,
    studyPlan: PropTypes.object,
    clearPlan: PropTypes.func,

    getUserTopicStudyPlanStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      calendarModalVisible: false,
      editModalVisible: false,
      usersModalVisible: false,
      active: dayjs('YYYY-MM'),
      activeMonthName: dayjs('MMMM YYYY'),
      isFavorite: false,
      imageSrc: null,
      errorMention: true,
      eventNotFound: false,
      openProperties: false,
      openJobLevels: false,
      openPositions: false,
      participants: null,
      preloader: true,
      visibleSchedule: false,
      isQiwiLibrary: false,
      isAddressBookVisible: false,
      addressId: null,
      addresses: [],
      correctStartDate: null,
      correctEndDate: null,
      loaded: 0,
      isTopicIDB: false,
      topicId: +this.props.params.id,
      topic: {},
      topicIDBimage: null,
      offlineMode: false,
      isTransactionLoading: false,
      mobileWidth: 768,
      isMobile: window.innerWidth < mobileWidth,
      isAvailableOnTrack: true,
      trackModalVisible: false,
      isManyTracks: false,
      isAudienceConfirmationModalOpen: false,
      isParticipationConfirmationModalOpen: false,
      isModalCurrentPaidTopicOpen: false,
      isModalPaidTopicRestrictionOpen: false,
      flagNow: false,
      studyPlan: {},
      currentPaidTopicName: '',
      currentPaidTopicId: null,
      retakeTopic: false,
    };
  }

  isOnline = () => window.navigator.onLine;

  // Функция для проверки доступен ли топик с треком
  isAvailableOnTrack = () => {
    const [studyPlanTopic] = this.props.studyPlanId;

    this.setState({
      isManyTracks: Array.isArray(studyPlanTopic?.trackInfo) && studyPlanTopic?.trackInfo.length > 1,
      isAvailableOnTrack: !studyPlanTopic?.blockedInTrack,
    });
  };

  componentDidMount() {
    if (this.isOnline()) {
      this.checkTopicIDB();
    } else {
      this.getTopicIDB();
      this.setState({ preloader: false });
    }
    this.props.getUserTopicStudyPlanStatus({
      userId: this.props?.currentUserId,
      topicId: this.state?.topicId,
      isAdmin: false,
    });

    this.props.getBookAddresses(
      this.props.params.id,
      data => {
        const addresses = data.map(item => ({
          id: item.id,
          address: item.addressDto.address,
          accessible: item.accessible,
        }));
        this.setState({ addresses });
      },
      () => this.setState({ addresses: [] })
    );

    window.addEventListener('resize', this.updateMobileState);
  }

  updateMobileState = () => this.setState({ isMobile: window.innerWidth < this.state.mobileWidth });

  checkTopicIDB = async () => {
    const db = await openDB(IDB_NAME);
    const topic = await db.get(TOPICS_IDB, this.state.topicId);
    topic && this.setState({ isTopicIDB: true });
  };

  getTopicIDB = async () => {
    const { topicId } = this.state;
    const db = await openDB(IDB_NAME);
    if (!db) return;
    const tx = await db.transaction(TOPICS_IDB, 'readwrite');
    const store = tx.objectStore(TOPICS_IDB);
    const topic = await store.get(topicId);
    await tx.done;
    if (topic) this.setState({ isTopicIDB: true, topic });
    const topicImg = await db.get(TOPICS_IMAGES_IDB, topicId);
    if (topicImg) this.setState({ imageSrc: window.URL.createObjectURL(topicImg.value) });
    this.props.getTopicOffline(topic);
    await this.getChaptersIDB(db);
    await this.getStatisticIDB(db);
  };

  getChaptersIDB = async db => {
    const chapters = await db.get(CHAPTERS_IDB, this.state.topicId);
    if (chapters) {
      this.props.getChaptersOffline(chapters.value);
    }
  };

  getStatisticIDB = async db => {
    const statistic = await db.get(STATISTIC_IDB, this.state.topicId);
    this.props.getBlockStatisticOffline(statistic.value);
  };

  getTopicFiles = async () => {
    this.setState({ isTransactionLoading: true });
    const { topic, getFile } = this.props;
    if (!topic.files.length) {
      this.transactionTopicIDB(topic);
      return;
    }
    const topicIDB = { ...topic };
    let filesNumber = 0;
    for (let file of topicIDB.files) {
      getFile(file.uuid, res => {
        file.blob = res;
        filesNumber++;
        if (filesNumber === topicIDB.files.length) {
          this.transactionTopicIDB(topicIDB);
        }
      });
    }
  };

  transactionTopicIDB = async topicIDB => {
    const db = await openDB(IDB_NAME);
    if (this.state.isTopicIDB) {
      await db.delete(TOPICS_IMAGES_IDB, this.state.topicId);
      await db.delete(TOPICS_IDB, this.state.topicId);
      await db.delete(CHAPTERS_IDB, this.state.topicId);
      await db.delete(STATISTIC_IDB, this.state.topicId);
      this.setState({ isTopicIDB: false });
    } else {
      await db.add(TOPICS_IDB, topicIDB);
      if (topicIDB.coverFileUuid) {
        this.props.getFile(topicIDB.coverFileUuid, async res => {
          await db.add(TOPICS_IMAGES_IDB, {
            value: res,
            id: this.state.topicId,
          });
        });
      }
      this.saveStatisticIDB();
      this.setState({ isTopicIDB: true, isTransactionLoading: false });
    }
  };

  updateTopicIDB = async planStatus => {
    const db = await openDB(IDB_NAME);
    const topic = await db.get(TOPICS_IDB, this.state.topicId);
    if (topic) {
      const topicToSave = { ...topic, planStatus };
      await db.delete(TOPICS_IDB, this.state.topicId);
      await db.add(TOPICS_IDB, topicToSave);
    }
  };

  saveStatisticIDB = async () => {
    const { topic, lms } = this.props;
    const db = await openDB(IDB_NAME);
    await db.add(STATISTIC_IDB, { value: lms.statistic, id: topic.id });
  };

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.topic).length > 0 && !!this.props.topic.event) {
      this.createDate(this.props.topic);
    }

    if (this.props?.topicStudyPlanStatus && prevProps.topicStudyPlanStatus !== this.props.topicStudyPlanStatus) {
      this.setState({ retakeTopic: this.props?.topicStudyPlanStatus?.type === ADD_REPEAT });
    }

    if (prevProps?.topic?.event !== this.props.topic?.event) {
      this.setState({ loaded: 0 });
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.isOnline()) {
      return;
    }
    const topicId = this.props.params.id;

    Promise.all([
      this.props.getTopicById(
        topicId,
        response => {
          if (isBook(response?.type?.id) && response?.qiwiLibrary) {
            this.setState({ isQiwiLibrary: true });
          }

          if (response.coverFileUuid) {
            this.props.getFile(response.coverFileUuid, res => {
              this.setState({ imageSrc: URL.createObjectURL(res) });
            });
          }

          if (
            (isCorpCourseWithOrWithoutApprove(response.type?.id) || isExternalCourse(response.type?.id)) &&
            response.event
          ) {
            this.props.eventTopicInfo(response.event.id);
            this.setState({ eventNotFound: false });
          } else {
            this.setState({ eventNotFound: true });
          }
        },
        error => {
          if (error.status === 404) {
            this.props.navigate('/notFound');
          }
        }
      ),

      this.props.getEventParty(topicId, { page: 0, size: 10 }, response => {
        this.updateParticipants(response);
      }),

      this.props.clearTopicComment(),
      this.props.checkStudyPlan({
        userId: this.props.currentUserId,
        topicId,
        isCurrent: true,
        onSuccess: ([plan]) => {
          this.isAvailableOnTrack();

          this.props.getBlockStatistic({
            topicId,
            planId: null || plan?.id,
            userId: this.props.currentUserId,
          });
        },
        withTrackInfo: true,
      }),
      this.props.checkFavoriteUserTopic(this.props.currentUserId, topicId),

      this.props.checkTopicComment(topicId, response => {
        this.setState({ errorMention: response ? true : false });
      }),

      this.props.getBlockStatistic({ topicId, userId: this.props.currentUserId }),
    ]).then(() => {
      this.setState({ preloader: false });
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.isOnline()) {
      return;
    }
    if (newProps.updateButton !== this.props.updateButton && newProps.updateButton) {
      const topicId = this.props.params.id;
      this.setState({ preloader: true });
      Promise.all([
        this.props.getTopicById(topicId, response => {
          if (response.coverFileUuid) {
            this.props.getFile(response.coverFileUuid, res => {
              this.setState({ imageSrc: URL.createObjectURL(res) });
            });
          }
        }),

        this.props.checkStudyPlan({ userId: this.props.currentUserId, topicId, isCurrent: true, withTrackInfo: true }),
        this.props.checkFavoriteUserTopic(this.props.currentUserId, topicId),
        this.props.notUpdateButtonFunc(),
      ]).then(() => this.setState({ preloader: false }));
    }
    if (newProps.studyPlan?.previousStudyPlansCheckResult !== this.props.studyPlan?.previousStudyPlansCheckResult) {
      const availableCreation = newProps.studyPlan?.previousStudyPlansCheckResult?.availableCreation;
      const currentStudyPlanEnded = newProps.studyPlan?.previousStudyPlansCheckResult?.currentStudyPlanEnded;
      const currentStudyPlan = newProps.studyPlan?.previousStudyPlansCheckResult?.currentStudyPlan > 0;

      if (!availableCreation && !currentStudyPlanEnded && currentStudyPlan) {
        this.setState({ isModalCurrentPaidTopicOpen: true });
      }

      if (!availableCreation && currentStudyPlanEnded && currentStudyPlan) {
        this.setState({ isModalPaidTopicRestrictionOpen: true });
      }

      if (availableCreation) {
        this.showCalendarModal();
      }
    }
    this.isAvailableOnTrack();
  }

  updateParticipants = participants => {
    const { totalElements, content } = participants;
    if (
      !totalElements ||
      (totalElements === 1 && content.length && content[0].user && content[0].user.id === this.props.currentUserId)
    ) {
      this.setState({
        participants: null,
        totalParticipants: totalElements,
      });
    } else {
      this.setState({
        participants: content,
        totalParticipants: totalElements,
      });
    }
  };

  scheduleStudyPlanClick = (
    topicId,
    planningMonths = [],
    comment,
    withoutSchedule,
    webinarDateTime,
    planInfo,
    selectedId
  ) => {
    const { currentUserId } = this.props;

    if (currentUserId && topicId) {
      this.props.createStudyPlan(
        currentUserId,
        topicId,
        planningMonths[0],
        comment,
        () => {
          if (this.state.addressId > 0) {
            this.props.updateBookAddresses(this.state.addressId, currentUserId);
          }

          this.handleCalendarCancel();
          if (
            this.props.topic &&
            this.props.topic.targetAudience === false &&
            isAnyCourseOrConference(this.props.topic?.type?.id)
          ) {
            this.setState({ isParticipationConfirmationModalOpen: true });
          }

          this.setState({ preloader: true });
          Promise.all([
            this.props.getTopicById(this.props.params.id, response => {
              this.updateTopicIDB(response.planStatus);
            }),
            this.props.getEventParty(this.props.params.id, { page: 0, size: 10 }, response => {
              this.updateParticipants(response);
            }),
            this.props.checkStudyPlan({
              userId: currentUserId,
              topicId,
              isCurrent: true,
              onSuccess: ([plan]) => {
                this.props.getBlockStatistic({
                  topicId: this.props.params.id,
                  userId: currentUserId,
                  planId: plan?.id,
                });
              },
              withTrackInfo: true,
            }),
          ]).then(() => this.setState({ preloader: false }));

          this.props.getUserTopicStudyPlanStatus({
            userId: this.props?.currentUserId,
            topicId: this.state?.topicId,
            isAdmin: false,
          });
        },
        false,
        getWithoutSchedule(topicId, withoutSchedule),
        webinarDateTime,
        selectedId // Для MARS (KAM-7787)
      );
    }
  };

  handleCalendarCancel = () => this.setState({ calendarModalVisible: false });
  showEditModal = () => this.setState({ editModalVisible: true });
  hideEditModal = () => {
    this.setState({ editModalVisible: false });
    this.props.clearSubType();
  };

  // Закрытие топика
  closePage = () => {
    const trackId = this.props.location?.state?.fromTrackId;
    const fromPlanning = this.props.location?.state?.fromPlanning;

    if (this.props?.domainCompany?.id === domainCompanyid.BIDZAAR_ID || fromPlanning) {
      this.props.navigate(`/planning`);
    } else if (trackId) {
      this.props.navigate(`/track/${trackId}`);
    } else {
      this.props.navigate(`/collection-topics`);
    }
  };

  handleAddressBookClose = () => this.setState({ isAddressBookVisible: false });

  handleAddressBookSubmit = addressId => {
    const { topic } = this.props;

    const withNoEvents = Utils.isWithoutEvents(topic);

    this.setState({ isAddressBookVisible: false, addressId: addressId > 0 ? addressId : 0 });

    if (withNoEvents && this.state.flagNow) {
      this.nowAddToPlan();
    } else {
      this.showCalendarModal();
    }
  };

  isEventType = topicTypeId => topicTypeId === 8;

  showAddressBookModal = (flagNow = false) => {
    const topicTypeId = this.props.topic?.type?.id;

    if (this.props.topic?.isTopicFull && this.isEventType(topicTypeId)) {
      message.warning(this.props.t('topicFull'));
      return;
    }

    if (this.state.isQiwiLibrary) {
      this.setState({ isAddressBookVisible: true, flagNow });
    }
  };

  showCalendarModal = (planId, topicId) => {
    const topicTypeId = this.props.topic?.type?.id;

    if (this.props.topic?.isTopicFull && this.isEventType(topicTypeId)) {
      message.warning(this.props.t('topicFull'));
      return;
    }

    this.setState({
      planningTopicType: this.props.topic.type,
      topicInfo: {
        ...this.props.topic,
        link: this.props.topic.firstLink,
        topicCost: this.props.topic.cost,
        eventCount: this.props.topic.eventCount,
        topicTargetAudience: this.props.topic.targetAudience,
      },
      startDate: this.props.topic.startDate,
      calendarModalVisible: true,
      planningTopicId: topicId,
      planId,
    });
  };

  showButtonBlock = () =>
    this.props.userTopicsFavorite &&
    this.props.userTopicsFavorite.filter(favTop => favTop.user.id === this.props.currentUserId);

  handleTopicEditSuccess = () => {
    this.setState({ preloader: true });
    this.props.getTopicById(this.props.params.id, response => {
      if (response.coverFileUuid) {
        this.props.getFile(response.coverFileUuid, res => {
          this.setState({ imageSrc: URL.createObjectURL(res) });
        });
      }
      this.setState({ preloader: false });
    });

    this.hideEditModal();
  };

  handleFavoriteClick = () => {
    const { currentUserId, topic } = this.props;
    this.props.createUserTopic(currentUserId, topic.id, () => {
      this.props.checkFavoriteUserTopic(currentUserId, topic.id);
    });
  };

  showUsersModal = () => {
    this.setState({ usersModalVisible: true });
    this.props.getUsers(
      {
        size: 10,
        page: 0,
        isPersonalized: true,
        topicId: this.state.topicId,
        status: 'ACTIVE',
      },
      () => {},
      () => {},
      false,
      true
    );
  };

  hideUsersModal = () => {
    this.setState({ usersModalVisible: false });
  };

  handleRecommendPost = userIds => {
    this.props.createUserTopicToMultipleUsers(userIds, this.props.topic.id, this.sendNotificationAboutTopics);
  };

  sendNotificationAboutTopics = topics => {
    if (topics.some(topic => topic.result === RECOMMENDED)) {
      this.props.sendNotification(RECOMMENDED);
    }

    topics.forEach(topic => {
      if (topic.result !== RECOMMENDED) {
        const { firstName, lastName } = topic.userTopic.user;
        this.props.sendNotification(topic.result, `${firstName} ${lastName}`);
      }
    });
  };

  createDate = topic => {
    // у топиков с эвентом берем из эвента даты
    const topicEventDate = topic.event?.periods?.[0]?.date;
    const topicEventStartTime = topic.event?.periods?.[0]?.startTime;
    const topicEventEndTime = topic.event?.periods?.[0]?.endTime;

    let fullStartDate = topic.event
      ? `${topicEventDate}T${topicEventStartTime}`
      : `${topic.startDate}T${topic.startTime}`;
    let fullEndDate = topic.event ? `${topicEventDate}T${topicEventEndTime}` : `${topic.endDate}T${topic.endTime}`;

    if (this.props.currentUser && this.state.loaded !== 2 && (topic.event || (topic.startDate && topic.startTime))) {
      const correctStartDate = dayjs(fullStartDate, 'YYYY-MM-DDTHH:mm').add(
        this.props.currentUser.timezone.offset / 60 - this.props.currentUser.domainCompany.timezone.offset / 60,
        'h'
      );

      const correctEndDate = dayjs(fullEndDate, 'YYYY-MM-DDTHH:mm').add(
        this.props.currentUser.timezone.offset / 60 - this.props.currentUser.domainCompany.timezone.offset / 60,
        'h'
      );

      this.setState({
        correctStartDate,
        correctEndDate,
        loaded: this.state.loaded + 1,
      });
    }
  };

  postComment = comment => {
    const topicId = this.props.params.id;
    this.props.createTopicComment(
      {
        topicId: +topicId,
        text: comment,
      },
      response => {
        this.props.addTopicCommentsToList(response);

        if (this.props.domainCompany?.hasCoins) {
          this.props.getUserIncome2({
            userId: this.props.currentUserId,
            topicId: topicId,
            bonus: COMMENT_TOPIC,
            incomeType: COMMENT_TOPIC,
            incomeContentLabel: this.props.t('commentTopicMessage'),
          });
        }
      }
    );
  };

  handleTagClick = tag => {
    this.props.clearTopicFilter();
    this.props.changeTopicFilter('tagId', tag.id);
    this.props.setSearchText(tag.name);
    this.props.navigate({
      pathname: '/collection-topics',
      search: `?tagName=${tag.name}&type=tagName`,
    });
  };

  handleAuthorClick = author => {
    this.props.clearTopicFilter();
    this.props.changeTopicFilter('authorId', author.id);
    this.props.setSearchText(author.name);
    this.props.navigate({
      pathname: '/collection-topics',
      search: `?authorName=${author.name}&itemTag=AUTHOR&itemId=${author.id}&type=AUTHOR`,
    });
  };

  handleOpenProperties = () => this.setState({ openProperties: true });
  handleCloseProperties = () => this.setState({ openProperties: false });
  handleOpenJobLevels = () => this.setState({ openJobLevels: true });
  handleCloseJobLevels = () => this.setState({ openJobLevels: false });
  handleOpenPositions = () => this.setState({ openPositions: true });
  handleClosePositions = () => this.setState({ openPositions: false });

  handleDeleteClick = () => {
    const { currentUserId, topic } = this.props;
    this.props.deleteUserTopic(topic.id, true, true, () => {
      this.props.checkFavoriteUserTopic(currentUserId, topic.id);
    });
  };

  showTopicCover = () => {
    const { topic } = this.props;
    return (
      <div
        className={classNames(css['Details-cover'], {
          [css.hasBg]: topic.coverColor && topic.coverColor.length > 0,
        })}
        style={{ backgroundColor: topic.coverColor }}
      >
        <img src={this.state.imageSrc} alt='image' />
      </div>
    );
  };

  checkPreviousStudyPlansFunc = topic => {
    if (shouldCheckPreviousStudyPlans(topic)) {
      this.props.checkPreviousStudyPlans(topic.id);
      return true;
    }
    return false;
  };

  handleModalConfirm = () => {
    const { topic } = this.props;
    this.setState({ isAudienceConfirmationModalOpen: false });

    if (this.checkPreviousStudyPlansFunc(topic)) {
      return;
    }
    this.showCalendarModal();
  };

  handleModalCancel = () => {
    this.setState({ isAudienceConfirmationModalOpen: false });
  };

  showCalendarModalWithCheck = () => {
    const { topic } = this.props;

    if (topic.targetAudience === false && isAnyCourseOrConference(topic.type.id)) {
      this.setState({ isAudienceConfirmationModalOpen: true });
      return;
    }
    if (this.checkPreviousStudyPlansFunc(topic)) {
      return;
    }
    this.showCalendarModal();
  };

  handleParticipationConfirmationModalCancel = () => {
    this.setState({ isParticipationConfirmationModalOpen: false });
  };

  handleModalCurrentPaidTopicClose = () => {
    this.setState({ isModalCurrentPaidTopicOpen: false });
    this.props.clearPlan();
  };

  handleModalPaidTopicRestrictionClose = () => {
    this.setState({ isModalPaidTopicRestrictionOpen: false });
    this.props.clearPlan();
  };

  openMaterialClick = () => {
    this.props.navigate(getStudyUrl());
    this.props.clearQuestionNumber();
  };

  // Функция для изменения видимости модального окна с треками
  changeTracksVisible = () => this.setState({ trackModalVisible: !this.state.trackModalVisible });

  createEventRows = periods =>
    periods?.map(period => {
      const { startTime, endTime, place, eventLinks, dates, date } = period;
      const { currentUser, t } = this.props;
      const datePeriod = dates || [date];

      if (!currentUser) return null;

      return datePeriod?.map((item, index) => {
        const fullStartDate = dayjs(`${item}T${startTime}`, 'YYYY-MM-DD HH:mm').add(
          currentUser.timezone.offset / 60 - currentUser.domainCompany.timezone.offset / 60,
          'h'
        );

        const fullStartEndTime = dayjs(`${item}T${endTime}`, 'YYYY-MM-DD HH:mm').add(
          currentUser.timezone.offset / 60 - currentUser.domainCompany.timezone.offset / 60,
          'h'
        );

        const placeContent =
          this.props.topic && place ? (
            Array.isArray(eventLinks) && eventLinks.length > 0 && eventLinks[index] ? (
              <a className={css['Details-schedule_item_link']} target='_blank' href={eventLinks[index]}>
                {place}
              </a>
            ) : (
              <span>{place}</span>
            )
          ) : (
            ` ${t('noDescription')}`
          );

        return (
          <div key={`schedule_${index}`} className={css['Details-schedule__schedule__row']}>
            <span>{dayjs(fullStartDate).format('DD.MM.YYYY')}</span>
            <span>{`${formatTime(fullStartDate)} - ${formatTime(fullStartEndTime)}`}</span>
            {placeContent}
          </div>
        );
      });
    });

  nowAddToPlan = () => {
    const { topic, currentUser } = this.props;

    const topicId = topic.id;
    const userId = currentUser.id;

    const date = Utils.getPlanDate(topic.duration);

    const canStudyNow =
      !this.props.lms.isLoading &&
      this.props.lms.statistic &&
      (!!this.props.lms.statistic.countBlocks || isExecutiveTask(topic.type.id));

    const onSuccessEmptyBlocks = () => {
      Promise.all([
        this.props.getTopicById(this.props.params.id, response => {
          this.updateTopicIDB(response.planStatus);
        }),
        this.props.getEventParty(this.props.params.id, { page: 0, size: 10 }, response => {
          this.updateParticipants(response);
        }),
        this.props.checkStudyPlan(this.props.currentUserId, topicId, '', '', () => {}, true),
        this.props.getBlockStatistic({ topicId: this.props.params.id, userId: this.props.currentUserId }),
      ]).then(() => this.setState({ preloader: false }));
    };

    this.props.createStudyPlan(userId, topicId, date, '', () =>
      canStudyNow ? this.props.navigate(getStudyUrl()) : onSuccessEmptyBlocks()
    );

    this.setState({ flagNow: false });
  };

  render() {
    const { topic, externalLogin, lms, t, currentUser, studyPlanId } = this.props;
    const {
      visibleSchedule,
      isMobile,
      isAudienceConfirmationModalOpen,
      isParticipationConfirmationModalOpen,
      isModalCurrentPaidTopicOpen,
      isModalPaidTopicRestrictionOpen,
      retakeTopic,
    } = this.state;
    const { planStatus } = topic;

    const topicId = topic.id;

    const [studyPlanTopic] = studyPlanId;
    const showCertificateBack = studyPlanTopic?.showCertificate;
    const isFinishedMaterial = planStatus?.planStatus === FINISHED;
    const showCertificate = isFinishedMaterial && showCertificateBack;
    const showBadge = isFinishedMaterial && this.props.domainCompany?.hasCoins;

    const topicPlanStatusPlanStatus = planStatus?.planStatus;
    const isWaitingStatus = topicPlanStatusPlanStatus === WAITING;

    const topicTypeId = _get(topic, 'type.id');
    const withNoEvents = Utils.isWithoutEvents(topic);

    const cover = !!(topic.coverFileUuid && this.state.imageSrc) && this.showTopicCover();

    const finalLink = getLinkFromUrl(topic.links);

    const video = isYouTube(_get(topic, 'links[0].url', '')) ? (
      <YouTube className={css['Details-video']} videoId={finalLink} />
    ) : !this.state.imageSrc ? null : (
      this.showTopicCover()
    );

    const mediaContent = isVideo(topicTypeId) ? video : cover;

    const topicType = topic.type && TOPIC_TYPES(this.props.t).find(type => type.id === topicTypeId);

    const typeBadge = !!topicType && (
      <TypeBadge
        className={classNames(css['Details-badge'])}
        type={topicType.code}
        name={topic.typeLabel?.name || ''}
      />
    );

    const mandatoryTeg = <Tag className={classNames(css['Details-mandatoryTag'])}>{t(topic.topicMandatory)}</Tag>;

    const onTradeLinkClick = () =>
      this.state.isManyTracks
        ? this.changeTracksVisible()
        : this.props.navigate(`/track/${studyPlanTopic?.trackInfo[0]?.trackId}`);

    const trackLink = () => (
      <>
        <TrackModal
          visible={this.state.trackModalVisible}
          tracks={studyPlanTopic?.trackInfo}
          changeVisible={() => this.changeTracksVisible()}
        />
        <Button className={css['Details-alert-right-link']} onClick={onTradeLinkClick} type='primary' size='large'>
          {this.state.isManyTracks ? t('lookTracks') : t('goToTrack')}
        </Button>
      </>
    );

    // Плашка для материала в треке, если он не доступен для прохождения в данный момент
    const unavailableCauseByTrack = (
      <Row className={css['Details-alert']} gutter={10}>
        <Col className={css['Details-alert-left']} span={4}>
          <ExclamationCircleFilled />
        </Col>
        <Col className={css['Details-alert-right']} span={20}>
          <p>{this.state.isManyTracks ? t('tracksAlert.0') : t('trackAlert.0')}</p>
          <p>{this.state.isManyTracks ? t('tracksAlert.1') : t('trackAlert.1')}</p>
          {trackLink()}
        </Col>
      </Row>
    );

    const topicTypeFields =
      topicType?.fields &&
      topicType.fields.map(field => {
        const fieldObject = PUBLIC_FIELDS(this.props.t)[field.type] || {};
        const fieldName = fieldObject.responseField || fieldObject.apiField;

        if (
          isEvent(topicTypeId) &&
          (field.type === 'cost' ||
            field.type === 'startDate' ||
            field.type === 'startTime' ||
            field.type === 'meetPlace')
        ) {
          return;
        }
        if ((topic?.subType?.id === 21 || topic?.subType?.id === 18) && field.type === 'meetPlace') {
          return (
            <div className={css['Details-card-item']}>
              <div className={css['Details-label']}>{t('placeMeeting')}</div>
              <div>{t('online')}</div>
            </div>
          );
        }
        return fieldName ? (
          fieldName === 'files' ? (
            <FieldItemFile key={fieldName} field={fieldObject} value={topic[fieldName]} getFile={this.props.getFile} />
          ) : (
            <FieldItem
              topic={topic}
              currentUser={currentUser}
              key={fieldName}
              field={fieldObject}
              value={topic[fieldName]}
              typeId={topicTypeId}
              getFile={this.props.getFile}
              handleAuthorClick={this.handleAuthorClick}
            />
          )
        ) : null;
      });
    const createdBy = topic.creBy || ADMIN_NAME;
    const buttonBlock = this.showButtonBlock();

    const rules =
      topic.creBy?.id === this.props.currentUserId && topic.type && !isCorpCourseWithOrWithoutApprove(topicTypeId);
    const scheduleList = this.props.eventSchedule.periods || this.props.eventSchedule;

    const scheduleItem =
      !this.state.eventNotFound && this.props.eventSchedule ? (
        this.createEventRows(scheduleList)
      ) : (
        <div
          className={classNames(css['Details-schedule__schedule__row'], {
            [css['Details-schedule__schedule__row--noDate']]: !topic.event,
          })}
        >
          <span>{dayjs(this.state.correctStartDate, 'DD.MM.YYYY')}</span>
          <span>{`${formatTime(this.state.correctStartDate)} -  ${formatTime(this.state.correctEndDate)}`}</span>
          <span> {topic.meetPlace ? <span>{topic.meetPlace}</span> : t('noDescription')} </span>
        </div>
      );

    const topicPeriods =
      topic.dates &&
      topic.dates.map(dateItem => ({
        ...dateItem,
        date: dateItem.dates.length ? dateItem.dates[0] : null,
      }));

    const scheduleItemEvent = topic.dates && this.createEventRows(topicPeriods);

    const onCloseSchedule = () => this.setState({ visibleSchedule: false });
    const onOpenSchedule = () => this.setState({ visibleSchedule: true });

    // модалка с расписанием мероприятий
    const scheduleData = (
      <div className={css['Details-schedule__schedule']}>
        <a className={css['Details-schedule__schedule__close']} onClick={onCloseSchedule}>
          <Resources.icons.CloseIcon />
        </a>
        <div className={css['Details-schedule__schedule__title']}>
          {topic.event ? t('schedule') : isEvent(topicTypeId) ? t('scheduleEvents') : t('scheduleConferences')}
        </div>
        {!isMobile ? (
          <>
            <div
              className={classNames(css['Details-schedule__schedule__row'], css['Details-schedule__schedule__header'], {
                [css['Details-schedule__schedule__row--noDate']]: !topic.event,
              })}
            >
              <span>{t('date')}</span>
              <span>{t('time')}</span>
              <span>{t('place')}</span>
            </div>
            {isEvent(topicTypeId) || isConference(topicTypeId) ? scheduleItemEvent : topic.type && scheduleItem}
          </>
        ) : (
          <>
            <Divider className={css['Details-schedule__schedule__divider']} />
            <div className={css['Details-schedule__schedule__header__cell']}>{t('date')}</div>
            <div className={css['Details-schedule__schedule__cell']}>
              {dayjs(this.state.correctStartDate).format('DD.MM.YYYY')}
            </div>
            <div className={css['Details-schedule__schedule__header__cell']}>{t('time')}</div>
            <div className={css['Details-schedule__schedule__cell']}>
              {`${formatTime(this.state.correctStartDate)} - ${formatTime(this.state.correctEndDate)}`}
            </div>
            <div className={css['Details-schedule__schedule__header__cell']}>{t('place')}</div>
            <div className={css['Details-schedule__schedule__cell']}>
              {topic.meetPlace ? <span>{topic.meetPlace}</span> : t('noDescription')}
            </div>
            <Divider className={css['Details-schedule__schedule__divider']} />
          </>
        )}
        {(topic.comment || this.props.eventSchedule.comment) && (
          <div className={classNames(css['Details-schedule__schedule__row'], css['Details-schedule-comment'])}>
            {topic.comment || this.props.eventSchedule.comment}
          </div>
        )}
      </div>
    );

    // дата в заголовке "дд месяц год в чч:мм"
    const scheduleDateTime = this.state.correctStartDate
      ? `${formatFullDate(this.state.correctStartDate)} ${t('at')} ${formatTime(this.state.correctStartDate)}`
      : '';

    const sheduleDropDown = (
      <>
        <div className={css['Details-type-schedule']} onClick={onOpenSchedule}>
          {scheduleDateTime}
        </div>
        <Modal
          open={visibleSchedule}
          title={false}
          footer={null}
          centered={true}
          closable={false}
          mask={false}
          maskClosable={true}
          onCancel={onCloseSchedule}
          className={css['Details--modal']}
          width={'50%'}
        >
          {scheduleData}
        </Modal>
      </>
    );

    const renderProgress = () => {
      switch (topicPlanStatusPlanStatus) {
        case 'REJECTED':
          return <div className={css['Details-type-schedule-not']}>{t('inRejected')}</div>;

        default:
          return <div className={css['Details-type-schedule-not']}>{t('inProcess')}</div>;
      }
    };

    const schedule =
      isCorpCourseWithOrWithoutApprove(topicTypeId) || isExternalCourse(topicTypeId)
        ? topic.event
          ? sheduleDropDown
          : topicPlanStatusPlanStatus
            ? renderProgress()
            : null
        : isEvent(topicTypeId) || isConference(topicTypeId)
          ? sheduleDropDown
          : null;

    const tagElements =
      topic.tags &&
      topic.tags.map(tag => (
        <div key={tag.id}>
          <a data-qa='detailCollectionTagItem' className={css.tagUrl} onClick={() => this.handleTagClick(tag)}>
            {tag.name}
          </a>
        </div>
      ));

    const cost =
      topic.cost &&
      topic.type &&
      (isCorpCourseWithOrWithoutApprove(topicTypeId) ||
        isBook(topicTypeId) ||
        isEvent(topicTypeId) ||
        isExternalCourse(topicTypeId) ||
        isConference(topicTypeId)) ? (
        <div className={css['Details-costLabel']}>
          <MaterialCost currentUser={currentUser} topic={topic} />
        </div>
      ) : null;

    const showCollectionButton = (type, onClick) => {
      return (
        <Button
          className={classNames({
            [css['App-btn']]: type !== 'externalDelete',
            [css['App-favorite-success']]: type === 'delete' || type === 'externalDelete',
            [css['App-btn-ghost']]: type !== 'externalDelete',
            [css['App-favorite']]: type !== 'add',
            [css['App-favorite-externalLogin']]:
              topic.scope === 'INTERNAL' && !externalLogin && (type === 'externalAdd' || type === 'externalDelete'),
          })}
          type='link'
          size='large'
          onClick={onClick}
          icon={
            type === 'delete' || type === 'externalDelete' ? (
              <HeartFilled className={css['App-btn-icon']} />
            ) : (
              <HeartOutlined className={css['App-btn-icon']} />
            )
          }
        >
          {type === 'externalDelete' && t('addedToFavorite')}
          {type === 'externalAdd' && t('addToFavorite')}
        </Button>
      );
    };

    const toMyCollectionButton =
      !this.state.deleted && buttonBlock?.length
        ? showCollectionButton('delete', this.handleDeleteClick)
        : showCollectionButton('add', this.handleFavoriteClick);

    const toMyCollectionButtonExternal =
      !this.state.deleted && buttonBlock?.length
        ? showCollectionButton('externalDelete', this.handleDeleteClick)
        : showCollectionButton('externalAdd', this.handleFavoriteClick);

    const corrDate = isEventOrConference(topicTypeId)
      ? dayjs(`${topic.endDate + ' ' + topic.endTime}`, 'YYYY-MM-DD HH:mm').format()
      : '';

    const checkIsEnd =
      isEventOrConference(topicTypeId) &&
      planStatus &&
      !planStatus.planStatus &&
      dayjs(corrDate, 'YYYY-MM-DD HH:mm').isBefore(dayjs());

    const checkCoursePlan = topic.type && planStatus && !planStatus.planStatus;
    const openDate = _get(this.props, 'openDate.openDate');

    const audienceConfirmationModal = isAudienceConfirmationModalOpen && (
      <AudienceConfirmationModal
        open={isAudienceConfirmationModalOpen}
        onConfirm={this.handleModalConfirm}
        onCancel={this.handleModalCancel}
      />
    );

    const participationConfirmationModal = isParticipationConfirmationModalOpen && (
      <ParticipationConfirmationModal
        open={isParticipationConfirmationModalOpen}
        onClose={this.handleParticipationConfirmationModalCancel}
      />
    );

    const modalCurrentPaidTopic = (
      <ModalCurrentPaidTopic
        open={isModalCurrentPaidTopicOpen}
        onClose={this.handleModalCurrentPaidTopicClose}
        currentPaidTopicName={this.props.studyPlan?.previousStudyPlansCheckResult?.topicName}
        currentPaidTopicId={this.props.studyPlan?.previousStudyPlansCheckResult?.topicId}
      />
    );

    const modalPaidTopicRestriction = (
      <ModalPaidTopicRestriction
        open={isModalPaidTopicRestrictionOpen}
        onClose={this.handleModalPaidTopicRestrictionClose}
      />
    );

    const onAddToPlan = this.state.isQiwiLibrary ? () => this.showAddressBookModal() : this.showCalendarModalWithCheck;
    const onAddToPlanNow = this.state.isQiwiLibrary ? () => this.showAddressBookModal(true) : this.nowAddToPlan;

    const addToPlanBlock = withNoEvents ? (
      <div className={classNames(css['App-btn'])}>
        <Button
          className={classNames(css['App-btn'], css['App-btn-minWidth'], css['App-btn-first'])}
          onClick={onAddToPlanNow}
          type='primary'
          size='large'
        >
          {t('addNow')}
        </Button>
        <Button
          className={classNames(css['App-btn'], css['App-btn-minWidth'])}
          onClick={onAddToPlan}
          type='primary'
          size='large'
        >
          {t('addLater')}
        </Button>
      </div>
    ) : (
      <Button
        className={classNames(css['App-btn'], css['App-btn-minWidth'])}
        onClick={onAddToPlan}
        type='primary'
        size='large'
      >
        {t('addToPlan')}
      </Button>
    );

    const targetAudienceMessage =
      isAnyCourseOrConference(topicTypeId) && topic.targetAudience !== null ? (
        <div className={classNames(css['Details-line-targetAudience'])}>
          {topic.targetAudience ? (
            <>
              {t('targetAudienceMessage')} <CheckCircleOutlined style={{ color: 'green' }} />
            </>
          ) : (
            <>
              {t('targetAudienceMessageDeny')} <CloseCircleOutlined style={{ color: 'red' }} />
            </>
          )}
        </div>
      ) : null;

    const actionButtons =
      planStatus &&
      (((topic.status === t('deactivated') || topic.status === 'Деактивирован') && checkCoursePlan) ||
      checkIsEnd ||
      (!_get(planStatus, 'planStatus') && !checkActiveCourse({ topic, openDate }, 'isTemplate')) ? (
        <div className={css['App-btn-group']}>
          <Button className={classNames(css['App-btn'], css['App-btn-minWidth'])} type='primary' size='large' disabled>
            <div className={css['Details-noActive']} />
            {t('inactive')}
          </Button>
          {toMyCollectionButton}
        </div>
      ) : planStatus?.planStatus && this.props?.topicStudyPlanStatus ? (
        <div className={css['App-btn-group']}>
          <Button
            className={classNames(css['App-btn'], {
              [css['App-btn-success']]: !retakeTopic,
              [css['App-btn-minWidth']]: !retakeTopic,
            })}
            onClick={retakeTopic && this.showCalendarModalWithCheck}
            size='large'
            type={retakeTopic && 'primary'}
            ghost={!retakeTopic}
          >
            {this.props?.topicStudyPlanStatus?.type === ADD_REPEAT ? t('retake') : t('addedToPlan')}
          </Button>
          {toMyCollectionButton}
        </div>
      ) : (
        <div className={css['App-btn-group']}>
          {addToPlanBlock}
          {toMyCollectionButton}
        </div>
      ));

    const getNames = (items, key) => (items?.length > 0 ? items.map(item => item[key]) : []);

    const jobLevelsNames = getNames(topic?.jobLevels, 'name');
    const positionsNames = getNames(topic?.jobFamilies, 'name');

    const jobLevels =
      topic?.jobLevels?.length > 0 ? (
        <ExpandableInfo
          label={t('jobLevel')}
          qa={'detailCollectionJobLevelItem'}
          data={jobLevelsNames}
          isOpen={this.state.openJobLevels}
          onToggle={this.handleOpenJobLevels}
          onClose={this.handleCloseJobLevels}
          t={t}
        />
      ) : null;

    const positions =
      topic?.jobFamilies?.length > 0 ? (
        <ExpandableInfo
          label={t('functions')}
          qa={'detailCollectionFunctionsItem'}
          data={positionsNames}
          isOpen={this.state.openPositions}
          onToggle={this.handleOpenPositions}
          onClose={this.handleClosePositions}
          t={t}
        />
      ) : null;

    // const categories =
    // topic.categories &&
    // topic.categories.map((cat, item) => (
    //   <div data-qa='detailCollectionCategoriesItem' key={item} className={css['Category-label']}>
    //     {cat.name}
    //   </div>
    // ));

    const disabledSelect = isEvent(topicTypeId);

    if (this.state.preloader) {
      return (
        <Content className={css['App-content']}>
          <Row gutter={1} className={css.Details}>
            <div className={css['Details-Spinner']}>
              <Spin size={'large'} />
            </div>
          </Row>
        </Content>
      );
    }

    const studyButtons = () => {
      return (
        !lms.isLoading &&
        lms.statistic &&
        (!!lms.statistic.countBlocks || isExecutiveTask(topicTypeId)) && (
          <>
            <div className={css['App-btn-group']}>
              {/* <Button.Group className={css['App-btn-group']}> */}
              <Button onClick={this.openMaterialClick} type='primary' size='large' className={css['Details-line-btn']}>
                {isExecutiveTask(topicTypeId)
                  ? planStatus?.planStatus === SCHEDULED || planStatus?.planStatus === 'REJECTED_BY_MANAGER'
                    ? t('executive_dotask')
                    : t('executive_viewtask')
                  : planStatus?.planStatus === SCHEDULED
                    ? t('learning')
                    : t('index')}
              </Button>
            </div>
            {/* </Button.Group> */}

            {(!isCorpCourseWithOrWithoutApprove(topicTypeId) || !isWaitingStatus) &&
              (lms.statistic.countBlocks > 0 || !isExecutiveTask(topicTypeId)) && (
                <ShowProgress steps={lms.statistic.countBlocks} completedSteps={lms.statistic.countFinishedBlocks} />
              )}
          </>
        )
      );
    };

    const setIsMobile = newMobileWidth => this.setState({ mobileWidth: newMobileWidth });

    return topic.scope !== 'INTERNAL' || externalLogin ? (
      <Content className={css['App-content']}>
        <Row gutter={1} className={css.Details}>
          <div
            className={classNames([css['Details-name'], { [css['Details-name-recommended']]: topic.trajectory }])}
            style={{ paddingLeft: '48px', paddingRight: '48px' }}
          >
            <div className={css['Details-name-block']}>
              <h1 data-qa='topicNameH1'>{this.props.topic.name}</h1>
              <div className={css['Details-type']}>
                {typeBadge}
                {cost}
                {mandatoryTeg}
                {isFieldEmpty(topic.subType) ? null : topic.subType.name === t('online') ? (
                  isExternalCourse(topicTypeId) ||
                  (isCorpCourseWithOrWithoutApprove(topicTypeId) && (
                    <div className={css['Details-type__green']}>{topic.subType?.name}</div>
                  ))
                ) : (
                  <div className={css['Details-type__blue']}>{topic.subType?.name}</div>
                )}
                {schedule}
              </div>
              {topic?.type?.withEvents && currentUser?.timezone?.site && (
                <div className={css['Details-site']}>{t('site', { site: currentUser?.timezone?.site })}</div>
              )}
            </div>
            <div className={css['Details-btn-block']}>
              {!!topic?.rewardsDto?.length && showBadge && (
                <BadgeElement mini data={topic?.rewardsDto.find(elem => elem.id && elem.name)} />
              )}
              {showCertificate && (
                <Certificate topicId={topicId} isMobile={this.state.isMobile} setIsMobile={setIsMobile} />
              )}

              {!externalLogin && rules && !isSkills(topic?.type?.id) && (
                <Tooltip title={t('edit')}>
                  <Button
                    className={classNames(css['Details-btn-edit'], {
                      [css['Details-btn-edit--recommended']]: topic.trajectory,
                    })}
                    shape='circle'
                    onClick={this.showEditModal}
                    type='default'
                    size='large'
                  >
                    <EditGrey />
                  </Button>
                </Tooltip>
              )}

              <ModalCloseIcon
                onClick={() => this.closePage()}
                className={classNames(css['Details-btn-close'], {
                  [css['Details-btn-close--recommended']]: topic.trajectory,
                })}
              />
              {this.state.isTransactionLoading ? (
                <div className={css['Details-idb-spinner']}>
                  <Spin />
                </div>
              ) : (
                <div>
                  <Button
                    className={css['Details-btn-save']}
                    onClick={this.state.isTopicIDB ? this.transactionTopicIDB : this.getTopicFiles}
                    type={this.state.isTopicIDB ? 'danger' : 'default'}
                    icon={
                      this.state.isTopicIDB ? (
                        <DeleteOutlined className={css['Details-btn-save-delete']} />
                      ) : (
                        <DownloadOutlined />
                      )
                    }
                    shape='circle'
                    size='large'
                  ></Button>
                </div>
              )}
            </div>
          </div>
          <Row className={css['Details-row']}>
            <Col lg={16} className={css['Details-col']}>
              <div className={css['App-card-item2']}>
                <div className={css.MediaContent}>{mediaContent}</div>
                {topic.links && topic.links.length ? (
                  <MaterialsLink currentUser={currentUser} topic={topic} getAlpinaLink={this.props.getAlpinaLink} />
                ) : null}
                <div className={css['App-content-actions']}>
                  {actionButtons}
                  {this.state.isAvailableOnTrack && studyButtons()}
                  {/* Ссылка на трек */}
                  {!studyPlanTopic?.blockedInTrack &&
                    Array.isArray(studyPlanTopic?.trackInfo) &&
                    !isEmpty(studyPlanTopic?.trackInfo) && (
                      <>
                        <Divider />
                        {trackLink()}
                      </>
                    )}
                  {targetAudienceMessage}

                  {/* Плашка, если материал недоступен для прохождения из-за трека */}
                  {studyPlanTopic?.blockedInTrack && planStatus?.planStatus !== FINISHED && unavailableCauseByTrack}
                </div>
                <div className={css['App-group-btn']}>
                  <div className={css['Details-recommendShare']}>
                    <div className={css['Details-recommendShare-labelBlock']}>
                      {this.props.domainCompany?.showColleagues && (
                        <div
                          className={css['Details-recommendShare-label']}
                          type='primary'
                          onClick={this.showUsersModal}
                        >
                          <Recommend />
                          {t('recommend')}
                        </div>
                      )}
                      {this.props.domainCompany?.hasShare && (
                        <div onClick={shareLink} shape='circle' className={css['Details-recommendShare-label']}>
                          <Share />
                          {t('share')}
                        </div>
                      )}
                    </div>
                    <Tooltip title={t('unic')} placement='topRight'>
                      <div className={css['Details-recommendShare-views']}>
                        <img src={views} alt='' /> {topic.viewers && topic.viewers.count}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <MaterialsTabs
                  topic={topic}
                  isLoading={this.props.isLoading}
                  isLoadingAllFile={this.props.isLoadingAllFile}
                  onAddComment={this.postComment}
                  deleteComment={this.props.deleteTopicComment}
                  restoreComment={this.props.restoreTopicComment}
                  updateComment={this.props.updateTopicComment}
                  updateMention={this.props.updateTopicMention}
                  getAllFile={this.props.getAllFile}
                  getTopicComments={this.props.getTopicComments}
                  getTopicMentions={this.props.getTopicMentions}
                  clearTopicComment={this.props.clearTopicComment}
                  clearTopicMention={this.props.clearTopicMention}
                  createTopicComment={this.props.createTopicComment}
                  mentions={{
                    mentions: this.props.mentions,
                    mentionsPage: this.props.mentionsPage,
                    mentionsTotal: this.props.mentionsCount,
                    mentionsHasMore: this.props.mentionsHasMore,
                    mentionsPageNumber: this.props.mentionsPageNumber,
                  }}
                  comments={{
                    comments: this.props.comments,
                    commentsPage: this.props.commentsPage,
                    commentsCount: this.props.commentsCount,
                    commentsHasMore: this.props.commentsHasMore,
                    commentsPageNumber: this.props.commentsPageNumber,
                  }}
                  getFile={this.props.getFile}
                  checkPreviousStudyPlansFunc={this.checkPreviousStudyPlansFunc}
                />
              </div>
            </Col>
            <Col lg={8} className={css['Details-col__right']}>
              <div className={classNames(css['Details-col'], css['Details-col-actions'])}>
                <div className={classNames(css['App-card-item'], css['Details-card'])}>
                  <div className={classNames(css['Details-line'], css['Details-actions'])}>
                    {actionButtons}
                    {this.state.isAvailableOnTrack && studyButtons()}
                    {/* Ссылка на трек */}
                    {!studyPlanTopic?.blockedInTrack &&
                      Array.isArray(studyPlanTopic?.trackInfo) &&
                      !isEmpty(studyPlanTopic?.trackInfo) && (
                        <>
                          <Divider className={classNames(css['Details-line-divider'])} />
                          {trackLink()}
                        </>
                      )}
                    {targetAudienceMessage}

                    {/* Плашка, если материал недоступен для прохождения из-за трека */}
                    {studyPlanTopic?.blockedInTrack && planStatus?.planStatus !== FINISHED && unavailableCauseByTrack}
                  </div>
                </div>
              </div>
              <div className={css['Details-col']}>
                <div className={classNames(css['App-card-item'], css['Details-card'])}>
                  {isFieldEmpty(topic?.language?.name) ? null : (
                    <div className={css['Details-card-item']}>
                      <div data-qa='detailCollectionLanguageItem' className={css['Details-label']}>
                        {t('language')}
                      </div>
                      {topic?.language?.name}
                    </div>
                  )}
                  {isFieldEmpty(topic?.level?.name) ? null : (
                    <div className={css['Details-card-item']}>
                      <div className={css['Details-label']}>{t('level')}</div>
                      <div data-qa='detailCollectionLevelItem'>{topic?.level?.name}</div>
                    </div>
                  )}
                  {topic?.type?.approved && !isFieldEmpty(topic?.jobLevels) ? jobLevels : null}
                  {isFieldEmpty(topic.difficulty) ? null : (
                    <div className={css['Details-card-item']}>
                      <div data-qa='detailCollectionRateItem' className={css['Details-label']}>
                        {t('rate')}
                      </div>
                      <Rate count={5} value={Math.round(topic.rate)} disabled />
                    </div>
                  )}
                  {!isFieldEmpty(this.state.participants) && this.props.domainCompany?.showColleagues && (
                    <div className={css['Details-card-item']}>
                      <div data-qa='detailCollectionAddedToPlanItem' className={css['Details-label']}>
                        {t('addedToPlan2')}
                      </div>
                      <Participants
                        planStatus={planStatus?.planStatus}
                        currentUser={this.props.currentUser}
                        topicId={this.props.params.id}
                        participants={this.state.participants}
                        getFile={this.props.getFile}
                        totalParticipants={this.state.totalParticipants}
                      />
                    </div>
                  )}
                  {this.state.openProperties ? (
                    <div>
                      {topic?.type?.approved && !isFieldEmpty(topic?.jobFamilies) ? positions : null}
                      {/* {isFieldEmpty(topic.categories) ? null : (
                        <div className={css['Details-card-item']}>
                          <div className={css['Details-label']}>{t('area')}</div>
                          {categories}
                        </div>
                      )}{' '}
                      */}
                      {topicTypeFields}
                      <div className={css['Details-card-item']}>
                        <div className={css['Details-label']}>{t('create')}</div>
                        <div>
                          <div data-qa='detailCollectionAuthorItem' className={css['Details-topic-author']}>
                            {`${createdBy.firstName || ''} ${createdBy.lastName || ''}`}
                          </div>
                        </div>
                      </div>
                      {isFieldEmpty(topic.scope) ? null : (
                        <div className={css['Details-card-item']}>
                          {!['UNMODERATE', 'EXTERNAL'].includes(topic.scope) && (
                            <div data-qa='detailCollectionVisibilityScopeItem' className={css['Details-labelScope']}>
                              {t('blocked')}
                            </div>
                          )}
                        </div>
                      )}
                      <div className={css['Details-properties']} onClick={this.handleCloseProperties}>
                        <div
                          data-qa='detailCollectionShowPropertiesDetailsBtn'
                          className={classNames(css['Details-line'], css['Details-line-point'])}
                        >
                          {t('hide')} <UpOutlined />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div data-qa='showPropertiesDetails'>
                      <div className={css['Details-properties']} onClick={this.handleOpenProperties}>
                        <div
                          data-qa='detailCollectionShowPropertiesDetailsBtn'
                          className={classNames(css['Details-line'], css['Details-line-point'])}
                        >
                          {t('show')} <DownOutlined />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {tagElements && tagElements.length ? (
                  <div className={classNames(css['App-card-item'], css['Details-card'])}>
                    <div className={css['Details-label']}>{t('tags')}</div>
                    {tagElements}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Row>
        {this.state.isAddressBookVisible && (
          <AddressBook
            textWindowTitle={t('addressBookWindowTitle')}
            textSubmitButton={t('addressBookSubmitButton')}
            textAccessible={t('addressBookaccessible')}
            textNotAccessible={t('addressBookNotAccessible')}
            textReadOnline={t('addressBookReadOnline')}
            onButtonClose={this.handleAddressBookClose}
            onButtonSubmit={this.handleAddressBookSubmit}
            open={this.state.isAddressBookVisible}
            data={this.state.addresses}
          />
        )}
        {this.state.calendarModalVisible && (
          <CalendarModal
            hasEvents={!!topic.eventCount}
            open={this.state.calendarModalVisible}
            comment={topic.comment}
            disabledSelect={disabledSelect}
            dateEvent={dayjs(this.state.correctStartDate, 'D MMMM YYYY')}
            onOk={this.scheduleStudyPlanClick}
            onCancel={this.handleCalendarCancel}
            okText={t('addToPlan')}
            title={!disabledSelect ? t('choose') : t('planned')}
            planningTopicId={this.props.topic?.id}
            topicInfo={this.state.topicInfo}
            dates={this.props.topic.dates}
            eventTypeId={this.state.planningTopicType && this.state.planningTopicType.id}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 20 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            {...(this.state.planningTopicType &&
              this.state.planningTopicType.id === 8 && {
                okText: t('addToPlan'),
                headerFixToModal: true,
              })}
            selectMonthEvents
            currentUser={currentUser}
          />
        )}

        {this.state.editModalVisible ? (
          <TopicAddModal
            visible
            editable
            topic={topic}
            imageSrc={this.state.imageSrc}
            tempImageSrc={topic.thumbFileUuid}
            onTopicAddSuccess={this.handleTopicEditSuccess}
            onCancel={this.hideEditModal}
          />
        ) : null}
        {this.state.usersModalVisible ? (
          <UsersModal
            onCancel={this.hideUsersModal}
            visible={this.state.usersModalVisible}
            onOk={this.handleRecommendPost}
            getUsers={this.props.getUsers}
            topic={this.props.topic}
            recommendTopic
          />
        ) : null}
        {audienceConfirmationModal}
        {participationConfirmationModal}
        {modalCurrentPaidTopic}
        {modalPaidTopicRestriction}
      </Content>
    ) : topic.scope === 'INTERNAL' || topic.scope === null ? (
      <Content className={css['App-content']}>
        <Row gutter={1} className={css.Details}>
          <div className={css['Details-name']} style={{ paddingLeft: '48px', paddingRight: '48px' }}>
            <div data-qa='asd' className={css['Details-name-block']}>
              <h1>{topic.name}</h1>
            </div>
          </div>
          <div className={css['Details-row']}>
            <Col span={15} className={css['Details-col']}>
              <div className={css['App-card-item2']}>
                <div className={css.MediaContent}>
                  <img src={disable_material} className={css['App-card-item2--img']} />
                </div>
                <div className={css['Details-externalMaterial']}>{t('blocked2')}</div>
              </div>
            </Col>
            <Col span={9} className={css['Details-col']}>
              <div className={classNames(css['App-card-item'], css['Details-card'])}>
                {toMyCollectionButtonExternal}
              </div>
            </Col>
          </div>
        </Row>
      </Content>
    ) : null;
  }
}

const mapStateToProps = createSelector(
  [
    topicsSelect,
    usersSelect,
    userTopics,
    loginSelect,
    studyPlanSelect,
    fileSelect,
    coursesSelect,
    selectOpenDate,
    selectLMS,
  ],
  (topics, users, favoriteTopics, login, studyPlan, file, course, openDate, selectLMS) => ({
    openDate,
    topic: topics.topic || {},
    domainCompany: users.currentUser && users.currentUser.domainCompany,
    comments: topics.topicComments,
    commentsCount: topics.topicCommentsTotal || 0,
    commentsPage: topics.topicCommentsPage || 0,
    commentsHasMore: topics.topicCommentsHasMore,
    commentsPageNumber: topics.topicCommentsPageNumber || 0,
    mentions: topics.topicMentions,
    mentionsCount: topics.topicMentionsTotal || 0,
    mentionsPage: topics.topicMentionsPage || 0,
    mentionsHasMore: topics.topicMentionsHasMore,
    mentionsPageNumber: topics.topicMentionsPageNumber || 0,
    currentUserId: users.currentUser && users.currentUser.id,
    currentUser: users.currentUser,
    externalLogin: login.externalLogin,
    studyPlanId: studyPlan.studyPlanId,
    userTopicsFavorite: favoriteTopics.favoriteTopics,
    updateButton: studyPlan.updateButton,
    file: file.file,
    eventSchedule: course.schedule,
    isLoading: topics.isLoading,
    isLoadingAllFile: file.isLoadingAll,
    lms: selectLMS,
    studyPlan: studyPlan,
    topicStudyPlanStatus: studyPlan.topicStudyPlanStatus,
  })
);

const mapActionsToProps = {
  getAllFile,
  getFile,
  getTopicById,
  getTopicOffline,
  createStudyPlan,
  clearTopicFilter,
  clearSubType,
  clearTopicComment,
  clearTopicMention,
  changeTopicFilter,
  checkFavoriteUserTopic,
  checkStudyPlan,
  checkTopicComment,
  updateStudyPlanStatus,
  increaseTopicViews,
  getUserIncome2,
  getUserById,
  createUserTopic,
  deleteUserTopic,
  updateButtonFunc,
  notUpdateButtonFunc,
  eventRequestFinish,
  eventTopicInfo,
  getUsers,
  getEventParty,
  setSearchText,
  createUserTopicToMultipleUsers,
  sendNotification,
  showSendReviewModal,
  sendNotificationStudyPlanFinished,
  getTopicComments,
  getTopicMentions,
  createTopicComment,
  deleteTopicComment,
  restoreTopicComment,
  addTopicCommentsToList,
  updateTopicComment,
  updateTopicMention,
  getAlpinaLink,
  getBlockStatistic,
  getBlockStatisticOffline,
  getBookAddresses,
  getChapters,
  getChaptersOffline,
  updateBookAddresses,
  clearQuestionNumber,
  checkPreviousStudyPlans,
  clearPlan,
  getUserTopicStudyPlanStatus,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withTranslation(['collectionMaterialId', 'constants'])(CollectionMaterialsID));
