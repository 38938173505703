// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-elements-alert-styles-module__ql-picker-FHTgrM.user-src-components-elements-alert-styles-module__ql-font-KyNRA9 .user-src-components-elements-alert-styles-module__ql-picker-label-yZ_stH[data-value="Montserrat"]:before, .user-src-components-elements-alert-styles-module__ql-picker-FHTgrM.user-src-components-elements-alert-styles-module__ql-font-KyNRA9 .user-src-components-elements-alert-styles-module__ql-picker-item-iILI4w[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-elements-alert-styles-module__ql-font-Montserrat-Pn3IEP {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-elements-alert-styles-module__alert-fMRfjO {
  border: none;
  border-radius: 8px;
  padding: 10px 16px 10px 52px;
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/elements/alert/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.alert {\n  font-weight: 300;\n  border: none;\n  border-radius: 8px;\n  padding: 10px 16px 10px 52px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-elements-alert-styles-module__ql-picker-FHTgrM`,
	"qlPicker": `user-src-components-elements-alert-styles-module__ql-picker-FHTgrM`,
	"ql-font": `user-src-components-elements-alert-styles-module__ql-font-KyNRA9`,
	"qlFont": `user-src-components-elements-alert-styles-module__ql-font-KyNRA9`,
	"ql-picker-label": `user-src-components-elements-alert-styles-module__ql-picker-label-yZ_stH`,
	"qlPickerLabel": `user-src-components-elements-alert-styles-module__ql-picker-label-yZ_stH`,
	"ql-picker-item": `user-src-components-elements-alert-styles-module__ql-picker-item-iILI4w`,
	"qlPickerItem": `user-src-components-elements-alert-styles-module__ql-picker-item-iILI4w`,
	"ql-font-Montserrat": `user-src-components-elements-alert-styles-module__ql-font-Montserrat-Pn3IEP`,
	"qlFontMontserrat": `user-src-components-elements-alert-styles-module__ql-font-Montserrat-Pn3IEP`,
	"alert": `user-src-components-elements-alert-styles-module__alert-fMRfjO`
};
export default ___CSS_LOADER_EXPORT___;
