import {
  CLEAR_NOTIFICATIONS,
  CLEAR_UNREAD_NOTIFICATIONS,
  GET_LIST_NOTIFICATIONS_FAIL,
  GET_LIST_NOTIFICATIONS_START,
  GET_LIST_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATION_BY_ID_FAIL,
  GET_NOTIFICATION_BY_ID_START,
  GET_NOTIFICATION_BY_ID_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  PUT_READ_ALL_NOTIFICATIONS_FAIL,
  PUT_READ_ALL_NOTIFICATIONS_START,
  PUT_READ_ALL_NOTIFICATIONS_SUCCESS,
  PUT_READ_NOTIFICATION_BY_ID_FAIL,
  PUT_READ_NOTIFICATION_BY_ID_START,
  PUT_READ_NOTIFICATION_BY_ID_SUCCESS,
  SET_UNREAD_NOTIFICATIONS,
} from './constants';

const initialState = {
  notifications: [],
  isNotificationLoading: false,

  notification: {},
  isNotificationByIdLoading: false,

  unReadNotifications: [],
  isUnReadNotificationLoading: true,
  countUnReadNotificationCalls: 0,

  selectOptions: [],
  isLoadingSelectOptions: false,
  isErrorSelectOptions: false,

  isPutReadNotificationByIdLoading: false,
  isErrorPutReadNotificationById: false,

  isPutAllReadNotificationsError: false,

  error: false,
};

// eslint-disable-next-line
export default (state = initialState, { type, response, value, isIncrement, action }) => {
  switch (type) {
    case GET_NOTIFICATIONS_START:
      return {
        ...state,
        isNotificationLoading: true,
        error: false,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      const mergedNotifications = [
        ...state.notifications,
        ...response.filter(res => state.notifications.find(ntf => ntf.id === res.id) === undefined),
      ];

      return {
        ...state,
        notifications: mergedNotifications,
        isNotificationLoading: false,
      };

    case GET_NOTIFICATIONS_FAIL:
      return { ...state, error: true, isNotificationLoading: false };

    case GET_NOTIFICATION_BY_ID_START:
      return {
        ...state,
        notification: {},
        isNotificationByIdLoading: true,
        error: false,
      };

    case GET_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        notification: response,
        isNotificationByIdLoading: false,
      };

    case GET_NOTIFICATION_BY_ID_FAIL:
      return {
        ...state,
        error: true,
        isNotificationByIdLoading: false,
      };

    case GET_LIST_NOTIFICATIONS_START:
      return {
        ...state,
        isLoadingSelectOptions: true,
        isErrorSelectOptions: false,
      };

    case GET_LIST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        selectOptions: response,
        isLoadingSelectOptions: false,
      };

    case GET_LIST_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isLoadingSelectOptions: false,
        isErrorSelectOptions: true,
      };

    case PUT_READ_NOTIFICATION_BY_ID_START:
      return {
        ...state,
        isPutReadNotificationByIdLoading: true,
        isErrorPutReadNotificationById: false,
      };

    case PUT_READ_NOTIFICATION_BY_ID_SUCCESS:
      let newNotifications = [...state.notifications];
      const currentId = Number(action.valueId);
      let readElementIndex = newNotifications.findIndex(elem => elem.id === currentId);

      if (readElementIndex !== -1) {
        newNotifications[readElementIndex].unread = false;
      }

      return {
        ...state,
        isPutReadNotificationByIdLoading: false,
        notifications: newNotifications,
        unReadNotifications: state.unReadNotifications.filter(elem => elem.id !== currentId),
      };

    case PUT_READ_NOTIFICATION_BY_ID_FAIL:
      return {
        ...state,
        isPutReadNotificationByIdLoading: false,
        isErrorPutReadNotificationById: true,
      };

    case PUT_READ_ALL_NOTIFICATIONS_START:
      return {
        ...state,
        isNotificationLoading: true,
        isPutAllReadNotificationsError: false,
      };

    case PUT_READ_ALL_NOTIFICATIONS_SUCCESS:
      const currentNotifications = state.notifications.map(el => {
        return { ...el, unread: false };
      });
      return {
        ...state,
        unReadNotifications: [],
        notifications: currentNotifications,
        isNotificationLoading: false,
      };

    case PUT_READ_ALL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isNotificationLoading: false,
        isPutAllReadNotificationsError: true,
      };

    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unReadNotifications: isIncrement
          ? [value, ...state.unReadNotifications]
          : [...value, ...state.unReadNotifications],
        isUnReadNotificationLoading: false,
        countUnReadNotificationCalls: state.countUnReadNotificationCalls + 1,
      };

    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };

    case CLEAR_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unReadNotifications: [],
        isUnReadNotificationLoading: true,
      };

    default:
      return state;
  }
};
