/* eslint-disable */
import {
  AWAIT_MENTOR_FAIL,
  AWAIT_MENTOR_START,
  AWAIT_MENTOR_SUCCESS,
  CLEAR_COMMENTS_GRATITUDE_STATE,
  CLEAR_MENTORS_FILTERS,
  DELETE_MENTOR_COMMENT_FAIL,
  DELETE_MENTOR_COMMENT_START,
  DELETE_MENTOR_COMMENT_SUCCESS,
  GET_COMPETENCE_MENTORS_FAIL,
  GET_COMPETENCE_MENTORS_START,
  GET_COMPETENCE_MENTORS_SUCCESS,
  GET_MENTOR_COMMENTS_COUNT_FAIL,
  GET_MENTOR_COMMENTS_COUNT_START,
  GET_MENTOR_COMMENTS_COUNT_SUCCESS,
  GET_MENTOR_COMMENTS_FAIL,
  GET_MENTOR_COMMENTS_START,
  GET_MENTOR_COMMENTS_SUCCESS,
  GET_MENTOR_GRATITUDE_FAIL,
  GET_MENTOR_GRATITUDE_START,
  GET_MENTOR_GRATITUDE_SUCCESS,
  GET_MENTOR_ID_FAIL,
  GET_MENTOR_ID_START,
  GET_MENTOR_ID_SUCCESS,
  GET_MENTORS_COMPANY_FAIL,
  GET_MENTORS_COMPANY_START,
  GET_MENTORS_COMPANY_SUCCESS,
  GET_MENTORS_DEPARTMENT_FAIL,
  GET_MENTORS_DEPARTMENT_START,
  GET_MENTORS_DEPARTMENT_SUCCESS,
  GET_MENTORS_FAIL,
  GET_MENTORS_FUNCTION_FAIL,
  GET_MENTORS_FUNCTION_START,
  GET_MENTORS_FUNCTION_SUCCESS,
  GET_MENTORS_START,
  GET_MENTORS_SUCCESS,
  GET_SETTINGS_LIMIT_MENTORS_FAIL,
  GET_SETTINGS_LIMIT_MENTORS_START,
  GET_SETTINGS_LIMIT_MENTORS_SUCCESS,
  POST_MENTOR_COMMENT_FAIL,
  POST_MENTOR_COMMENT_START,
  POST_MENTOR_COMMENT_SUCCESS,
  PUT_MENTOR_COMMENT_FAIL,
  PUT_MENTOR_COMMENT_START,
  PUT_MENTOR_COMMENT_SUCCESS,
  REQUEST_MENTEE_FAIL,
  REQUEST_MENTEE_START,
  REQUEST_MENTEE_SUCCESS,
  RESTORE_MENTOR_COMMENT_FAIL,
  RESTORE_MENTOR_COMMENT_START,
  RESTORE_MENTOR_COMMENT_SUCCESS,
  SAVE_MENTORS_FILTERS,
  UPDATE_MENTOR_GRATITUDE_FAIL,
  UPDATE_MENTOR_GRATITUDE_START,
  UPDATE_MENTOR_GRATITUDE_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  mentorsCard: [],
  mentorsCardAll: [],
  mentorsCardTotal: null,
  mentorsCardPageSize: null,
  mentorsCardPageNumber: null,
  mentorsCardCurrentPage: null,
  mentorsCompany: [],
  mentorsCompetence: [],
  mentorsDepartment: [],
  mentorsFunction: [],
  mentorsFilters: {
    filters: {},
  },
  isLoadingCards: false,
  isLoadingMentorsID: false,
  mentorData: {},
  comments: [],
  mentorsCommentsTotal: null,
  mentorsCommentsPageSize: null,
  mentorsCommentsTotalPages: null,
  mentorsCommentsCurrentPage: null,
  mentorsCommentsCount: null,
  commentsPagination: [],
  gratitude: [],
  mentorsGratitudeTotal: null,
  mentorsGratitudePageSize: null,
  mentorsGratitudeTotalPages: null,
  mentorsGratitudeCurrentPage: null,
  limit: {},
};

export default (state = initialState, { type, response, value, headers, action }) => {
  switch (type) {
    case GET_MENTORS_START:
      return { ...state, isLoading: true, isLoadingCards: true };

    case GET_MENTOR_ID_START:
      return { ...state, isLoading: true, isLoadingMentorsID: true };

    case GET_MENTORS_COMPANY_START:
    case GET_COMPETENCE_MENTORS_START:
    case GET_MENTORS_DEPARTMENT_START:
    case GET_MENTORS_FUNCTION_START:
    case REQUEST_MENTEE_START:
    case POST_MENTOR_COMMENT_START:
    case PUT_MENTOR_COMMENT_START:
    case DELETE_MENTOR_COMMENT_START:
    case RESTORE_MENTOR_COMMENT_START:
    case GET_MENTOR_COMMENTS_COUNT_START:
    case GET_MENTOR_COMMENTS_START:
    case AWAIT_MENTOR_START:
    case GET_MENTOR_GRATITUDE_START:
    case UPDATE_MENTOR_GRATITUDE_START:
    case GET_SETTINGS_LIMIT_MENTORS_START:
      return { ...state, isLoading: true };

    // очищаем стейт комменариев и благодарностей при анмаунте компонентов
    case CLEAR_COMMENTS_GRATITUDE_STATE:
      return {
        ...state,
        isLoading: true,
        commentsPagination: [],
        comments: [],
        gratitude: [],
      };

    case GET_MENTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mentorsCard: response.content,
        mentorsCardAll: action?.hasChangedFilters ? response.content : [...state.mentorsCardAll, ...response.content],
        mentorsCardTotal: response.totalElements,
        mentorsCardPageSize: response.size,
        mentorsCardTotalPages: response.totalPages,
        mentorsCardCurrentPage: response.number,
        isLoadingCards: false,
      };

    case GET_COMPETENCE_MENTORS_SUCCESS:
      return { ...state, isLoading: false, mentorsCompetence: response };

    case GET_MENTORS_DEPARTMENT_SUCCESS:
      return { ...state, isLoading: false, mentorsDepartment: response };

    case GET_MENTORS_FUNCTION_SUCCESS:
      return { ...state, isLoading: false, mentorsFunction: response };

    case GET_MENTORS_COMPANY_SUCCESS:
      return { ...state, isLoading: false, mentorsCompany: response };

    case GET_MENTOR_COMMENTS_COUNT_SUCCESS:
      return { ...state, isLoading: false, mentorsCommentsCount: response };

    case GET_MENTOR_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: [...state.comments, ...response.content], // для инфинити скролла
        commentsPagination: response.content, // для пагинации
        mentorsCommentsTotal: response.totalElements,
        mentorsCommentsPageSize: response.size,
        mentorsCommentsTotalPages: response.totalPages,
        mentorsCommentsCurrentPage: response.number,
      };

    case GET_MENTOR_GRATITUDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gratitude: [...state.gratitude, ...response.content], // для инфинити скролла
        mentorsGratitudeTotal: response.totalElements,
        mentorsGratitudePageSize: response.size,
        mentorsGratitudeTotalPages: response.totalPages,
        mentorsGratitudeCurrentPage: response.number,
      };

    case POST_MENTOR_COMMENT_SUCCESS:
    case PUT_MENTOR_COMMENT_SUCCESS:
      const arrComments = state.comments.filter(item => item.id !== response.id);
      return {
        ...state,
        isLoading: false,
        comments: [response, ...arrComments],
      };

    case UPDATE_MENTOR_GRATITUDE_SUCCESS:
      const arrGratitude = state.gratitude.filter(item => item.id !== response.id);
      return {
        ...state,
        isLoading: false,
        gratitude: [response, ...arrGratitude],
      };

    case RESTORE_MENTOR_COMMENT_SUCCESS:
    case DELETE_MENTOR_COMMENT_SUCCESS:
    case AWAIT_MENTOR_SUCCESS:
      return { ...state, isLoading: false };

    case GET_MENTOR_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mentorData: response,
        isLoadingMentorsID: false,
      };

    case REQUEST_MENTEE_SUCCESS:
      return { ...state, isLoading: false };

    case GET_MENTORS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isLoadingCards: false,
      };

    case GET_MENTOR_ID_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isLoadingMentorsID: false,
      };

    case GET_MENTORS_COMPANY_FAIL:
    case GET_COMPETENCE_MENTORS_FAIL:
    case GET_MENTORS_DEPARTMENT_FAIL:
    case GET_MENTORS_FUNCTION_FAIL:
    case REQUEST_MENTEE_FAIL:
    case GET_MENTOR_COMMENTS_FAIL:
    case POST_MENTOR_COMMENT_FAIL:
    case PUT_MENTOR_COMMENT_FAIL:
    case DELETE_MENTOR_COMMENT_FAIL:
    case RESTORE_MENTOR_COMMENT_FAIL:
    case GET_MENTOR_GRATITUDE_FAIL:
    case GET_MENTOR_COMMENTS_COUNT_FAIL:
    case AWAIT_MENTOR_FAIL:
    case UPDATE_MENTOR_GRATITUDE_FAIL:
    case GET_SETTINGS_LIMIT_MENTORS_FAIL:
      return { ...state, isLoading: false, isError: true };

    case SAVE_MENTORS_FILTERS:
      const filters = { ...state.mentorsFilters, filters: value };
      return { ...state, mentorsFilters: filters };

    case CLEAR_MENTORS_FILTERS:
      const clearFilters = { ...state.mentorsFilters, filters: value };
      return { ...state, mentorsFilters: clearFilters };

    case GET_SETTINGS_LIMIT_MENTORS_SUCCESS:
      return { ...state, isLoading: false, limit: response };

    default:
      return { ...state };
  }
};
