/* eslint-disable */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import css from './ModalFlower.module.scss';

export class AboutItem extends Component {
  static propTypes = {
    getFile: PropTypes.func,
    item: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      image: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.item) {
      if (this.props.item.background) {
        this.props.getFile(this.props.item.background, blob => {
          const imageUrl = URL.createObjectURL(blob);
          this.setState({ image: imageUrl });
        });
      } else {
        this.setState({ image: '' });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.item !== this.props.item) {
      if (newProps.item.background) {
        this.props.getFile(newProps.item.background, blob => {
          const imageUrl = URL.createObjectURL(blob);
          this.setState({
            image: imageUrl,
          });
        });
      } else {
        this.setState({
          image: '',
        });
      }
    }
  }

  render() {
    const { item } = this.props;
    return (
      <div className={css['ModalFlower-body-imageBlock']}>
        <img src={this.state.image} />
        <div className={css['ModalFlower-body-imageBlock-name']}>{item.name}</div>
        <div className={css['ModalFlower-body-imageBlock-text']}>{item.description}</div>
      </div>
    );
  }
}

export default AboutItem;
