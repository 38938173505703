/* eslint-disable */
import {
  EVENT_REQUEST_CONFIRM_FAIL,
  EVENT_REQUEST_CONFIRM_START,
  EVENT_REQUEST_CONFIRM_SUCCESS,
  EVENT_REQUEST_CONFIRM_UI_FAIL,
  EVENT_REQUEST_CONFIRM_UI_START,
  EVENT_REQUEST_CONFIRM_UI_SUCCESS,
  EVENT_REQUEST_FINISH_FAIL,
  EVENT_REQUEST_FINISH_START,
  EVENT_REQUEST_FINISH_SUCCESS,
  EVENT_REQUEST_REFUSE_FAIL,
  EVENT_REQUEST_REFUSE_START,
  EVENT_REQUEST_REFUSE_SUCCESS,
  EVENT_REQUEST_REFUSE_UI_FAIL,
  EVENT_REQUEST_REFUSE_UI_START,
  EVENT_REQUEST_REFUSE_UI_SUCCESS,
  EVENT_TOPIC_INFO_FAIL,
  EVENT_TOPIC_INFO_START,
  EVENT_TOPIC_INFO_SUCCESS,
  GET_ACTIVE_EVENT_IN_COURSE_FAIL,
  GET_ACTIVE_EVENT_IN_COURSE_START,
  GET_ACTIVE_EVENT_IN_COURSE_SUCCESS,
  GET_COURSES_COST_FAIL,
  GET_COURSES_COST_START,
  GET_COURSES_COST_SUCCESS,
  GET_ENDED_EVENT_IN_COURSE_FAIL,
  GET_ENDED_EVENT_IN_COURSE_START,
  GET_ENDED_EVENT_IN_COURSE_SUCCESS,
  GET_EVENT_IN_COURSE_FAIL,
  GET_EVENT_IN_COURSE_START,
  GET_EVENT_IN_COURSE_SUCCESS,
  GET_EVENTS_IN_GROUPS_COURSE_FAIL,
  GET_EVENTS_IN_GROUPS_COURSE_START,
  GET_EVENTS_IN_GROUPS_COURSE_SUCCESS,
  GET_OPEN_DATE_FAIL,
  GET_OPEN_DATE_START,
  GET_OPEN_DATE_SUCCESS,
  REJECT_MANAGER_COURSES_FAIL,
  REJECT_MANAGER_COURSES_START,
  REJECT_MANAGER_COURSES_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  events: null,
  eventsGroups: {},
  schedule: [],
  endedEvents: {
    elements: [],
    loadMore: true,
    page: 0,
  },
  openDate: null,
  activeEvents: null,
  event: null,
  error: false,
};

export default (state = initialState, { type, response, headers }) => {
  switch (type) {
    case REJECT_MANAGER_COURSES_START:
    case GET_ENDED_EVENT_IN_COURSE_START:
    case GET_EVENTS_IN_GROUPS_COURSE_START:
    case GET_ACTIVE_EVENT_IN_COURSE_START:
    case GET_COURSES_COST_START:
    case EVENT_TOPIC_INFO_START:
    case EVENT_REQUEST_FINISH_START:
    case EVENT_REQUEST_CONFIRM_START:
      return { ...state, isLoading: true, error: false };
    case GET_EVENT_IN_COURSE_START:
      return {
        ...state,
        endedEvents: {
          elements: [],
          loadMore: true,
          page: 0,
        },
        isLoading: true,
        error: false,
      };

    case GET_EVENT_IN_COURSE_SUCCESS:
      return { ...state, isLoading: false, events: response, error: false };

    case GET_EVENTS_IN_GROUPS_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eventsGroups: response,
        error: false,
      };

    case GET_ENDED_EVENT_IN_COURSE_SUCCESS:
      const elements = [...state.endedEvents.elements, ...response];
      return {
        ...state,
        isLoading: false,
        endedEvents: {
          elements,
          loadMore: Number(headers.get('X-Total-Elements')) > elements.length,
          page: state.endedEvents.page + 1,
        },
        error: false,
      };

    case GET_ACTIVE_EVENT_IN_COURSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeEvents: response,
        error: false,
      };

    case EVENT_REQUEST_CONFIRM_SUCCESS:
      return { ...state, isLoading: false, event: response, error: false };

    case GET_ENDED_EVENT_IN_COURSE_FAIL:
      return { ...state, loadMore: false, error: true, isLoading: false };
    case REJECT_MANAGER_COURSES_FAIL:
    case GET_ACTIVE_EVENT_IN_COURSE_FAIL:
    case GET_EVENTS_IN_GROUPS_COURSE_FAIL:
    case GET_EVENT_IN_COURSE_FAIL:
    case EVENT_TOPIC_INFO_FAIL:
    case EVENT_REQUEST_CONFIRM_FAIL:
      return { ...state, error: true, isLoading: false };

    case EVENT_REQUEST_REFUSE_START:
      return { ...state, isLoading: true, error: false };

    case EVENT_REQUEST_REFUSE_SUCCESS:
      return { ...state, isLoading: false, event: response, error: false };

    case GET_COURSES_COST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coursesCost: response,
        error: false,
      };

    case EVENT_TOPIC_INFO_SUCCESS:
      return { ...state, isLoading: false, schedule: response, error: false };

    case EVENT_REQUEST_REFUSE_FAIL:
      return { ...state, error: true, isLoading: false };

    case EVENT_REQUEST_CONFIRM_UI_START:
      return { ...state, isLoading: true, error: false };

    case EVENT_REQUEST_CONFIRM_UI_SUCCESS:
      return { ...state, isLoading: false, event: response, error: false };

    case EVENT_REQUEST_CONFIRM_UI_FAIL:
      return { ...state, error: true, isLoading: false };

    case EVENT_REQUEST_REFUSE_UI_START:
      return { ...state, isLoading: true, error: false };

    case EVENT_REQUEST_REFUSE_UI_SUCCESS:
      return { ...state, isLoading: false, event: response, error: false };

    case REJECT_MANAGER_COURSES_SUCCESS:
    case EVENT_REQUEST_FINISH_SUCCESS:
      return { ...state, isLoading: false, error: false };

    case GET_OPEN_DATE_START:
      return { ...state, isLoading: false, error: false };

    case GET_OPEN_DATE_SUCCESS:
      return { ...state, openDate: response.openDate, error: false };

    case GET_OPEN_DATE_FAIL:
    case GET_COURSES_COST_FAIL:
    case EVENT_REQUEST_FINISH_FAIL:
    case EVENT_REQUEST_REFUSE_UI_FAIL:
      return { ...state, error: true, isLoading: false };
    default:
      return state;
  }
};
