// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-Fwz3KB.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-OTLMTg .user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-label-HGtImu[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-Fwz3KB.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-OTLMTg .user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-item-F0rpry[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-Montserrat-ojiWV2 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header-AJCME0 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__info-VtqfzH {
  align-items: center;
  gap: 12px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__tag-cfvvBQ {
  align-items: center;
  margin: 0;
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__icon-tOu2Im {
  color: var(--personalColor);
  font-size: 30px;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-comments-RUsZy_ {
  flex-direction: column;
  gap: 32px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__header-mAX3WT {
  margin-bottom: 4px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__author-Nx8SPi, .user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__time-tsvaao {
  font-size: 16px;
  font-weight: 500;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__author-Nx8SPi {
  margin-left: 4px;
  font-weight: 600;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__content-xCghgQ {
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/PokaHeader/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAMA;EAHE,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAKA;EAFE,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAIA;EADE,2BAA2B;EAC3B,eAAe;AACjB;;AAIA;EADE,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAIA;EADE,kBAAkB;EAClB,aAAa;AACf;;AAGA;EAAE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AACA;EAEE,eAAe;AACjB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.poka-header {\n  margin-bottom: 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.poka-header__info {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n}\n.poka-header__tag {\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 600;\n  padding: 6px 8px;\n  margin: 0;\n}\n.poka-header__icon {\n  font-size: 30px;\n  color: var(--personalColor);\n}\n\n.poka-comments {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.comment__header {\n  display: flex;\n  margin-bottom: 4px;\n}\n.comment__author, .comment__time {\n  font-size: 16px;\n  font-weight: 500;\n}\n.comment__author {\n  font-weight: 600;\n  margin-left: 4px;\n}\n.comment__content {\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-Fwz3KB`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-Fwz3KB`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-OTLMTg`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-OTLMTg`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-label-HGtImu`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-label-HGtImu`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-item-F0rpry`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-picker-item-F0rpry`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-Montserrat-ojiWV2`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__ql-font-Montserrat-ojiWV2`,
	"poka-header": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header-AJCME0`,
	"pokaHeader": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header-AJCME0`,
	"poka-header__info": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__info-VtqfzH`,
	"pokaHeaderInfo": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__info-VtqfzH`,
	"poka-header__tag": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__tag-cfvvBQ`,
	"pokaHeaderTag": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__tag-cfvvBQ`,
	"poka-header__icon": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__icon-tOu2Im`,
	"pokaHeaderIcon": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-header__icon-tOu2Im`,
	"poka-comments": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-comments-RUsZy_`,
	"pokaComments": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__poka-comments-RUsZy_`,
	"comment__header": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__header-mAX3WT`,
	"commentHeader": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__header-mAX3WT`,
	"comment__author": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__author-Nx8SPi`,
	"commentAuthor": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__author-Nx8SPi`,
	"comment__time": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__time-tsvaao`,
	"commentTime": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__time-tsvaao`,
	"comment__content": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__content-xCghgQ`,
	"commentContent": `user-src-pages-CollectionMaterialsIDStudy-PokaHeader-style-module__comment__content-xCghgQ`
};
export default ___CSS_LOADER_EXPORT___;
