import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// import { getUserDomainCompanyImages } from '@/store/users/actions';
import { getLanguagesFiltered } from '@/store/locales/actions';
import { /*getTopicCategories, */ clearTopics, getTopicLevel, getTopics, getTopicTypes } from '@/store/topics/actions';

import { selectLocales } from '@/store/locales/selectors';
import { selectTopics } from '@/store/topics/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Layout, Pagination } from 'antd';
import css from '@/pages/CollectionMaterials/CollectionMaterials.module.scss';
import Sort from '@/components/Sort';
import TopicCards from '@/components/TopicCards';

import { parseSearchParams } from '@shared/utils/get-query-string';

import PropTypes from 'prop-types';

const { Content } = Layout;

const initialTopicsParams = {
  size: 20,
  page: 1,
  search: '',
  searchName: '',
  authorId: '',
  tagId: '',
  skillId: '',
  skillIds: '',
  sort: 'createdDate,desc',
  hasEvent: '',
  actual: '',
  startDate: '',
  endDate: '',
  userId: null,
  recommend: false,
};

const initialSearchTopics = {
  authorName: '',
  publisher: '',
  tagName: '',
  skillName: '',
  name: '',
  searchResult: '',
};

const initialAllState = { ...initialTopicsParams, ...initialSearchTopics };

let controllerTopics = null;

const MyCollectionMaterials = props => {
  const { t } = useTranslation('collectionMaterials');
  const dispatch = useDispatch();

  const { topics, level, types, isLoadingTopicsCatalog, topicsTotal } = useSelector(selectTopics);
  const { currentUser } = useSelector(selectUsers);
  const { languagesFiltered } = useSelector(selectLocales);

  // eslint-disable-next-line
  const [state, setState] = useState({
    searchingTopics: false,
    global: currentUser?.domainCompany?.global,
    currentTopicId: 0,
    listAllCompetence: [],
    competencesAndCategoriesQuantity: {},
    skillsNames: null,
    restoreFirstRequest: props.location.state?.comeFromHeader,
  });

  const initialQuerySearchParams = useMemo(() => parseSearchParams(document.location.search), []);

  const [topicsParams, setTopicsParams] = useState({
    ...initialAllState,
    ...initialQuerySearchParams,
  });

  const changePage = page => {
    window.scrollTo(0, 0);
    setTopicsParams({ ...topicsParams, page });
  };

  const handleChangeSort = sort => {
    setTopicsParams({ ...topicsParams, sort, page: 1, typeId: topicsParams?.typeId });
  };

  const getSearchText = () => {
    // Поиск лейблов компетенций (скила)
    const listAllCompetence = state.listAllCompetence || [];
    const skillIds = topicsParams?.skillIds || [];
    const labelSkills = skillIds
      .map(skillId => {
        let competenceSkill = {};

        // Поиск скила по вложенности
        // eslint-disable-next-line
        listAllCompetence.find(t => {
          if (!!t && Array.isArray(t.skills) && t.skills.length > 0) {
            const skill = t.skills.find(b => +b.id === +skillId);
            if (skill) {
              competenceSkill = skill;
            }
          }
        });
        return competenceSkill.name || '';
      })
      .join('', '');
    // ---------------

    const currentSearchText = labelSkills || topicsParams.searchResult || '';
    const labelSearchText =
      currentSearchText.length > 20 ? `${currentSearchText.substring(0, 20)}... ` : currentSearchText;

    return {
      currentSearchText,
      labelSearchText: labelSearchText,
    };
  };

  useLayoutEffect(() => {
    // dispatch(getUserDomainCompanyImages()); Нужен рефакторинг
    if (languagesFiltered?.length === 0) dispatch(getLanguagesFiltered());
    if (!types) dispatch(getTopicTypes());
    if (!level) dispatch(getTopicLevel());
    setTopicsParams({ ...topicsParams, userId: currentUser.id });
    // в компаннте ModalFlower дергается эта ручка getTopicCategories закомментировал чтоб не дублировался код
    // if (!topics) dispatch(getTopicCategories(currentUser?.domainCompany?.global));

    return () => dispatch(clearTopics());
  }, []);

  useEffect(() => {
    if (controllerTopics) controllerTopics.abort();
    controllerTopics = new AbortController();

    dispatch(getTopics(topicsParams, () => {}, controllerTopics.signal));
  }, [topicsParams]);

  const isActiveFilterSkillIds = Boolean(
    topicsParams.skillIds && Array.isArray(topicsParams.skillIds) && topicsParams.skillIds.length > 0
  );

  const isFilterNotEmpty =
    topicsParams &&
    (topicsParams.name ||
      topicsParams.authorName ||
      topicsParams.publisher ||
      topicsParams.tagName ||
      topicsParams.authorId ||
      topicsParams.tagId ||
      topicsParams.skillId ||
      isActiveFilterSkillIds ||
      topicsParams.skillName ||
      topicsParams.searchResult);

  const labelFilter = isFilterNotEmpty
    ? topicsParams.authorName || topicsParams.authorId
      ? t('author')
      : topicsParams.tagName || topicsParams.tagId
        ? t('tag')
        : topicsParams.publisher
          ? t('company')
          : topicsParams.name
            ? t('name')
            : topicsParams.skillName || topicsParams.skillId || isActiveFilterSkillIds
              ? t('skill')
              : topicsParams.searchResult
                ? ''
                : ''
    : null;

  const sortTypes = [
    {
      name: t('rating'),
      dataQa: 'rating',
      value: 'rate,desc',
    },
    {
      name: t('date'),
      dataQa: 'date',
      value: 'createdDate,desc',
    },
  ];

  const { currentSearchText, labelSearchText } = getSearchText();

  return (
    <Content className={css['App-content']}>
      <div className={css['CollectionMaterials-sort-block']}>
        <Sort
          activeSort={topicsParams.sort}
          isFilterEmpty={isFilterNotEmpty}
          isLoading={isLoadingTopicsCatalog}
          labelFilter={labelFilter}
          labelSearchText={labelSearchText}
          labelSkillsText={state.skillsNames}
          topicAmount={topicsTotal}
          handleChangeSort={handleChangeSort}
          sortTypes={sortTypes}
        />
      </div>

      <TopicCards
        isLoading={isLoadingTopicsCatalog}
        topics={topics}
        isFilterNotEmpty={isFilterNotEmpty}
        searchText={currentSearchText}
        isFavorite
      />

      {!isLoadingTopicsCatalog && topics?.length > 0 && (
        <Pagination
          current={topicsParams.page}
          total={topicsTotal}
          pageSize={initialTopicsParams.size}
          onChange={changePage}
          showSizeChanger={false}
          className={css['CollectionMaterials-pagination']}
          responsive
          hideOnSinglePage
        />
      )}
    </Content>
  );
};

MyCollectionMaterials.propTypes = {
  isLoadingTopicsCatalog: PropTypes.bool,
  topics: PropTypes.array,
  topicsTotal: PropTypes.number,
};

export default MyCollectionMaterials;
