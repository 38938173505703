import { useOutletContext } from 'react-router-dom';

export const withOutletContext = Component => props => {
  const contextValue = useOutletContext();

  return (
    <Component
      {...props} // <-- all other passed props
      contextValue={contextValue} // <-- the context value
    />
  );
};
