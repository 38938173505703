// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-eF1eyp.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-paXCaK .user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-label-ubbaAb[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-eF1eyp.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-paXCaK .user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-item-tCoXlS[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-Montserrat-k_g3Qv {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info-vVeEzq {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__element-dCGeeU {
  flex-direction: column;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__text-FyfRUb {
  font-size: 18px;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value-upJkbR {
  font-weight: 600;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value--blue-TphdxD {
  color: var(--personalColor);
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__link-a98_14 {
  color: var(--personalColor);
  font-size: 16px;
}

.user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__assessors-modal__buttons-eQ9MYN {
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/PokaInfoBlock/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,aAAa;AACf;;AAMA;EAHE,sBAAsB;EACtB,aAAa;AACf;;AAKA;EAFE,eAAe;AACjB;;AAIA;EADE,gBAAgB;AAClB;;AAGA;EAAE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.poka-info {\n  width: 100%;\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 24px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-bottom: 24px;\n}\n.poka-info__element {\n  display: flex;\n  flex-direction: column;\n}\n.poka-info__text {\n  font-size: 18px;\n}\n.poka-info__value {\n  font-weight: 600;\n}\n.poka-info__value--blue {\n  color: var(--personalColor);\n}\n.poka-info__link {\n  font-size: 16px;\n  color: var(--personalColor);\n}\n\n.assessors-modal__buttons {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-eF1eyp`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-eF1eyp`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-paXCaK`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-paXCaK`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-label-ubbaAb`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-label-ubbaAb`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-item-tCoXlS`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-picker-item-tCoXlS`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-Montserrat-k_g3Qv`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__ql-font-Montserrat-k_g3Qv`,
	"poka-info": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info-vVeEzq`,
	"pokaInfo": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info-vVeEzq`,
	"poka-info__element": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__element-dCGeeU`,
	"pokaInfoElement": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__element-dCGeeU`,
	"poka-info__text": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__text-FyfRUb`,
	"pokaInfoText": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__text-FyfRUb`,
	"poka-info__value": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value-upJkbR`,
	"pokaInfoValue": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value-upJkbR`,
	"poka-info__value--blue": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value--blue-TphdxD`,
	"pokaInfoValueBlue": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__value--blue-TphdxD`,
	"poka-info__link": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__link-a98_14`,
	"pokaInfoLink": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__poka-info__link-a98_14`,
	"assessors-modal__buttons": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__assessors-modal__buttons-eQ9MYN`,
	"assessorsModalButtons": `user-src-pages-CollectionMaterialsIDStudy-PokaInfoBlock-style-module__assessors-modal__buttons-eQ9MYN`
};
export default ___CSS_LOADER_EXPORT___;
