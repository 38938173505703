import { GET_USER_TIMEZONE_FAIL, GET_USER_TIMEZONE_START, GET_USER_TIMEZONE_SUCCESS } from './constance';

const initialState = {
  loading: false,
  userTimezone: [],
};

// eslint-disable-next-line
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_USER_TIMEZONE_START:
      return { ...state, loading: true };
    case GET_USER_TIMEZONE_SUCCESS:
      return { ...state, loading: false, userTimezone: response };
    case GET_USER_TIMEZONE_FAIL:
      return { ...state, loading: false, userTimezone: [] };
    default:
      return state;
  }
};
