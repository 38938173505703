/* eslint-disable */
import {
  DELETE_REGISTER_TOKEN,
  DELETE_RESTORE_TOKEN,
  GET_INFO_COMPANY_FAIL,
  GET_INFO_COMPANY_START,
  GET_INFO_COMPANY_SUCCESS,
  GET_SUBCOMPANIES_FAIL,
  GET_SUBCOMPANIES_START,
  GET_SUBCOMPANIES_SUCCESS,
  SET_DEFAULT_URL_LOGIN,
  SET_REGISTER_TOKEN,
  SET_RESTORE_TOKEN,
} from '@/store/company/actions';

import { openDB } from 'idb';

import { COMPANY_IDB, createIDB, IDB_NAME } from '@/constants/IndexedDB';

const initialState = {
  isLoading: false,
  company: null,
  error: false,
  urlLogin: '',
  subCompanies: [],
};

const transactionCompanyIDB = async company => {
  const db = await openDB(IDB_NAME);
  const companyIdb = await getCompanyInDB();
  if (company && !companyIdb.length) {
    await db.add(COMPANY_IDB, company);
  }
};

const getCompanyInDB = async () => {
  const db = await openDB(IDB_NAME);
  if (!db.objectStoreNames.contains(COMPANY_IDB)) return;
  if (!db) return;
  const tx = db.transaction(COMPANY_IDB, 'readwrite');
  const store = tx.objectStore(COMPANY_IDB);
  const company = await store.getAll();
  await tx.done;
  return company;
};

!window.navigator.onLine && getCompanyInDB();

export default (state = initialState, { type, response, registerToken, restoreToken, url }) => {
  switch (type) {
    case GET_INFO_COMPANY_START:
      return { ...state, isLoading: true, company: null, error: false };

    case GET_INFO_COMPANY_SUCCESS:
      createIDB().then(() => transactionCompanyIDB(response));
      return {
        ...state,
        isLoading: false,
        company: response,
        error: false,
      };

    case GET_INFO_COMPANY_FAIL:
      return {
        ...state,
        isLoading: false,
        company: null,
        error: true,
      };

    case SET_REGISTER_TOKEN:
      return {
        ...state,
        registerToken,
        urlLogin: 'registrationPageData',
      };

    case SET_RESTORE_TOKEN:
      return {
        ...state,
        restoreToken,
        urlLogin: 'recoveryPageData',
      };

    case DELETE_RESTORE_TOKEN:
      return { ...state, restoreToken: null, urlLogin: 'authorizationFail' };

    case DELETE_REGISTER_TOKEN:
      return { ...state, registerToken: null, urlLogin: 'authorizationFail' };

    case SET_DEFAULT_URL_LOGIN:
      return {
        ...state,
        registerToken: null,
        restoreToken: null,
        urlLogin: url,
      };

    case GET_SUBCOMPANIES_START:
      return { ...state, isLoading: true, error: false };

    case GET_SUBCOMPANIES_SUCCESS:
      return {
        ...state,
        subCompanies: response,
        isLoading: false,
        error: false,
      };

    case GET_SUBCOMPANIES_FAIL:
      return { ...state, isLoading: false, error: true };

    default:
      return state;
  }
};
