import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { menteeCareerPlan } from '@/store/career_plan/actions';
import { awaitMentor, requestMentee } from '@/store/mentors/actions';

import { Button, Input, Modal } from 'antd';
import ModalCloseIcon from '@/components/ModalCloseIcon/ModalCloseIcon';
import { HiOutlineCheckCircle, HiOutlineCloudUpload, HiOutlineExclamationCircle } from 'react-icons/hi';

import {
  CANCEL_REQUEST_MENTORING,
  CLOSE,
  ON_WAITING_LIST,
  REQUEST_MENTORING,
  REQUEST_SEND,
  WAITING_LIST,
} from '../constants';

import getQueryString from '@shared/utils/get-query-string';

import { planning } from '@/router/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './ModalActions.module.scss';

const { TextArea } = Input;

const ModalActions = props => {
  const { t } = useTranslation('mentors');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState({
    request: '',
    experience: '',
    contact: '',
  });
  const [isSend, setIsSend] = useState(false);
  const [isError, setIsError] = useState({
    request: false,
    desc: false,
  });

  const onSendRequest = () => {
    const params = {
      experience: comment.experience,
      mentorId: props.mentorId,
      request: comment.request,
      contact: comment.contact,
      ...(props.isIPR && { competenceId: props.competenceId }),
    };

    if (!comment.request || !comment.experience) {
      setIsError({
        request: !comment.request,
        desc: !comment.experience,
      });
    } else {
      setIsError({
        request: !comment.request,
        desc: !comment.experience,
      });

      if (props.isIPR) {
        dispatch(menteeCareerPlan(props.careerPlanId, params, () => setIsSend(true)));
      } else {
        dispatch(requestMentee(params, () => setIsSend(true)));
      }
    }
  };

  const onSendWaitingList = () => {
    const params = { mentorId: props.mentorId };
    dispatch(awaitMentor(params, () => props.onClose(ON_WAITING_LIST)));
  };

  const goToPlanning = () =>
    navigate({
      pathname: `/${planning.path}`,
      search: getQueryString({ tab: 'mentoring' }),
    });

  const footerModal = !isSend && [
    <div className={css['Modal-request-footer']}>
      {(isError.desc || isError.request) && (
        <div className={css['Modal-request-footer-error']}>
          <HiOutlineExclamationCircle className={css['Modal-request-footer-error-icon']} />
          <span className={css['Modal-request-footer-error-desc']}>{t('errorDesc')}</span>
        </div>
      )}
      <Button key='footerModal' onClick={onSendRequest} type='primary' size='large'>
        {t('sendRequest')}
      </Button>
    </div>,
  ];

  const titleModal = !isSend && <div className={css['Modal-title']}>{t('titleModal')}</div>;

  const renderTitle = props.action === WAITING_LIST || props.action === CANCEL_REQUEST_MENTORING ? null : titleModal;

  const renderFooter = props.action === WAITING_LIST || props.action === CANCEL_REQUEST_MENTORING ? null : footerModal;

  // запросить менторинг REQUEST_MENTORING
  const requestMentoringRender = (
    <div className={css['Modal-request']}>
      <div>
        {/* <div className={css['Modal-request-block']}> */}
        <TextArea
          rows={3}
          className={classNames([css['Modal-request-block-area']], {
            [css['Modal-request-block-area-error']]: isError.request,
          })}
          placeholder={t('placeHolderRequest')}
          onChange={e => setComment({ ...comment, request: e.target.value })}
          maxLength={500}
        />
      </div>
      <div className={css['Modal-request-block']}>
        <TextArea
          rows={3}
          className={classNames([css['Modal-request-block-area']], {
            [css['Modal-request-block-area-error']]: isError.desc,
          })}
          placeholder={t('placeHolderDesc')}
          onChange={e => setComment({ ...comment, experience: e.target.value })}
          maxLength={500}
        />
      </div>
      <div className={css['Modal-request-block']}>
        <TextArea
          rows={3}
          className={classNames([css['Modal-request-block-area']], {
            [css['Modal-request-block-area-error']]: isError.desc,
          })}
          placeholder={t('placeHolderContact')}
          onChange={e => setComment({ ...comment, contact: e.target.value })}
          maxLength={1000}
        />
      </div>
    </div>
  );

  const waitingListRender = (
    <div className={css['Modal-waiting']}>
      <HiOutlineCheckCircle className={css['Modal-waiting-img']} />
      <div className={css['Modal-waiting-desc']}>{t('waitingDesc')}</div>
      <div className={css['Modal-waiting-desc2']}>{t('waitingDesc2')}</div>
      <div className={css['Modal-waiting-action']}>
        <Button key='waitingListRender' onClick={onSendWaitingList} size='large' type='primary'>
          {t('good')}
        </Button>
      </div>
    </div>
  );

  const requestSendRender = (
    <div className={css['Modal-waiting']}>
      <HiOutlineCloudUpload className={css['Modal-waiting-img']} />
      <div className={css['Modal-waiting-desc']}>{t('sendDesc')}</div>
      <div className={css['Modal-waiting-desc2']}>{t('sendDesc2')}</div>
      <div className={css['Modal-waiting-action']}>
        <Button key='requestSendRender' onClick={goToPlanning} size='large' type='primary'>
          {t('goToStudy')}
        </Button>
      </div>
    </div>
  );

  const cancelRequestMentoring = (
    <div className={css['Modal-cancelRequest']}>
      <div className={css['Modal-cancelRequest-desc']}>{t('cancelRequestMentoring')}</div>
      <div className={css['Modal-cancelRequest-action']}>
        <Button key='cancelRequestMentoring' onClick={props.onCancelRequestMentoring} size='large' type='primary'>
          {t('yes')}
        </Button>
      </div>
    </div>
  );

  const onCloseModal = () => {
    props.onClose(REQUEST_SEND, props.action === REQUEST_MENTORING && !isSend ? CLOSE : '');
  };

  return (
    <Modal
      open={props.open}
      title={renderTitle}
      onOk={props.handleOk}
      footer={!isSend ? renderFooter : null}
      className={css['Modal']}
      width={600}
      closeIcon={<ModalCloseIcon onClick={onCloseModal} className={css['ModalCloseIcon-actions']} />}
    >
      {props.action === REQUEST_MENTORING && !isSend && requestMentoringRender}

      {props.action === WAITING_LIST && waitingListRender}

      {props.action === CANCEL_REQUEST_MENTORING && cancelRequestMentoring}

      {isSend && requestSendRender}
    </Modal>
  );
};

ModalActions.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  action: PropTypes.string,
  mentorId: PropTypes.number,
  onCancelRequestMentoring: PropTypes.func,
};

export default ModalActions;
