// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-kgAKmo.user-src-components-DatePicker-AntDatePicker-style-module__ql-font-gC_AuC .user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-label-uAB4cA[data-value="Montserrat"]:before, .user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-kgAKmo.user-src-components-DatePicker-AntDatePicker-style-module__ql-font-gC_AuC .user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-item-kBPWCm[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-DatePicker-AntDatePicker-style-module__ql-font-Montserrat-HQzUiO {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-F7yk2w {
  align-items: center;
  margin-top: 10px;
  padding-right: 1em;
  display: flex;
}

.user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-label-Ihqq2f {
  color: #999;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: grid;
  }
}
`, "",{"version":3,"sources":["webpack://./user/src/components/DatePicker/AntDatePicker/style.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;;AAMA;EAHE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAMA;EACE;IAHE,aAAa;EACf;AACF","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.RangeCalendarContainer {\n  display: flex;\n  align-items: center;\n  padding-right: 1em;\n  margin-top: 10px;\n}\n.RangeCalendarContainer-label {\n  font-size: 13px;\n  font-weight: 300;\n  color: #999;\n  padding-right: 15px;\n}\n\n@media (max-width: 768px) {\n  :global .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {\n    display: grid;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-kgAKmo`,
	"qlPicker": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-kgAKmo`,
	"ql-font": `user-src-components-DatePicker-AntDatePicker-style-module__ql-font-gC_AuC`,
	"qlFont": `user-src-components-DatePicker-AntDatePicker-style-module__ql-font-gC_AuC`,
	"ql-picker-label": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-label-uAB4cA`,
	"qlPickerLabel": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-label-uAB4cA`,
	"ql-picker-item": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-item-kBPWCm`,
	"qlPickerItem": `user-src-components-DatePicker-AntDatePicker-style-module__ql-picker-item-kBPWCm`,
	"ql-font-Montserrat": `user-src-components-DatePicker-AntDatePicker-style-module__ql-font-Montserrat-HQzUiO`,
	"qlFontMontserrat": `user-src-components-DatePicker-AntDatePicker-style-module__ql-font-Montserrat-HQzUiO`,
	"RangeCalendarContainer": `user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-F7yk2w`,
	"rangeCalendarContainer": `user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-F7yk2w`,
	"RangeCalendarContainer-label": `user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-label-Ihqq2f`,
	"rangeCalendarContainerLabel": `user-src-components-DatePicker-AntDatePicker-style-module__RangeCalendarContainer-label-Ihqq2f`
};
export default ___CSS_LOADER_EXPORT___;
