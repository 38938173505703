import { GET_USER_BADGE_FAIL, GET_USER_BADGE_START, GET_USER_BADGE_SUCCESS } from '@/store/badge/constants';

const initialState = {
  isLoading: false,
  isError: false,
  badges: [],
  // pagination: {},
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_USER_BADGE_START:
      return { ...state, badges: [], isError: false, isLoading: true };

    case GET_USER_BADGE_SUCCESS:
      return {
        ...state,
        badges: response,
        // pagination: {
        //   total: response?.totalElements,
        //   current: response?.number,
        //   pageSize: response?.size,
        // },
        isLoading: false,
        isError: false,
      };

    case GET_USER_BADGE_FAIL:
      return { ...state, badges: [], isError: true, isLoading: false };

    default:
      return state;
  }
};
