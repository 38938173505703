/* eslint-disable */
import React from 'react';

import css from './ContextSearch.module.scss';

export default function SearchSuggestItem(props) {
  const { item, onItemClick, type } = props;

  const onClick = () => {
    onItemClick(item, type);
  };

  return (
    <div className={css['ContextSearch-result-item']} onClick={onClick}>
      <div className={css['ContextSearch-result-blockName-name']}>{item.name}</div>
      <div className={css['ContextSearch-result-blockName-label']}>{item.type}</div>
    </div>
  );
}
