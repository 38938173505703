import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import { useParams } from 'react-router-dom';

import { Alert, Avatar, Button, Input, Layout } from 'antd';
import defaultImg from '@/pages/Header/PortraitPlaceholder.png';

import Comment from '../Comment';

import { useLocalFileByUUID } from '@shared/hooks/localFiles';

import throttle from 'lodash/throttle';

import css from './MaterialsTabs.module.scss';

const { Content } = Layout;
const { TextArea } = Input;

const MaterialsTabsComment = props => {
  const params = useParams();
  const [state, setState] = useState({
    comment: '',
    hideActions: true,
  });

  const getTopicComments = throttle(props.getTopicComments, 1000);

  const [currentAvatar] = useLocalFileByUUID(props.currentUser.localPhotoUuid, { defaultImg });

  useEffect(() => {
    props.clearTopicComment();

    const topicId = params.id;
    if (topicId) {
      getTopicComments(topicId, 0, false, 10, response => {
        setState({ ...state, hasMore: !!response.length });
      });
    }
  }, []);

  const loadMore = () => {
    if (props.topic.id) {
      getTopicComments(props.topic.id, props.comments.commentsPageNumber + 1, false, 10, response => {
        setState({ ...state, hasMore: !!response.length });
      });
    }
  };

  const changeComment = event => {
    setState({ ...state, comment: event.target.value });
  };

  const handleAddComment = () => {
    if (state.comment) {
      props.onAddComment(state.comment.trim());
      setState({ ...state, comment: '' });
    }
  };

  const editCommentStart = commentId => {
    setState({ ...state, editableComment: +commentId });
  };

  const editCommentFinish = () => {
    setState({ ...state, editableComment: 0 });
  };

  const compareNumeric = (a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;
  };

  const onCommentUpdate = (id, params, onSuccess) => {
    props.updateComment(
      id,
      {
        ...params,
        topicId: +props.topic.id,
      },
      onSuccess
    );
  };

  if (!props.topic.id) {
    return null;
  }

  const onHideActions = value => {
    setState({ ...state, hideActions: value });
  };

  const arr = props.comments && props.comments.comments;
  arr && arr.sort(compareNumeric);
  const comments =
    arr &&
    arr.map(comment => (
      <Comment
        currentUserId={props.currentUser.id}
        key={comment.id}
        getFile={props.getFile}
        deleteComment={props.deleteComment}
        restoreComment={props.restoreComment}
        updateComment={onCommentUpdate}
        editableComment={state.editableComment}
        editCommentStart={editCommentStart}
        editCommentFinish={editCommentFinish}
        currentUser={props.currentUser}
        comment={comment}
        onHideActions={onHideActions}
        hideActions={state.hideActions}
        topicId={props.topic.id}
      />
    ));

  return (
    <Content className={css.MaterialsTabsAboutBlock}>
      {!window.navigator.onLine && <Alert message={props.t('offlineTabMaterial')} type='warning' />}
      <div className={css['MaterialsTabsAbout-container']}>
        <div className={css['MaterialsTabsAbout-comment']}>
          <Avatar className={css['MaterialsTabsAbout-avatar']} src={currentAvatar} />
          <div className={css['MaterialsTabsAbout-comment-block']}>
            <TextArea
              data-qa='detailCollectionCommentInput'
              className={css['MaterialsTabsAbout-form-textarea']}
              autosize={{ minRows: 4, maxRows: 200 }}
              onChange={changeComment}
              value={state.comment}
              style={{ resize: 'none' }}
              maxLength={1500}
              placeholder={props.t('report')}
            />
            <Button
              data-qa='detailCollectionSendCommentBtn'
              className={css['MaterialsTabsAbout-comment-block-button']}
              onClick={handleAddComment}
              disabled={!state.comment?.trim()}
              type='primary'
              size='large'
            >
              {props.t('send')}
            </Button>
          </div>
        </div>
        <InfiniteScroll
          className={css.MaterialsTabsAboutBlock}
          pageStart={props.comments.commentsPageNumber}
          initialLoad={false}
          loadMore={props.topic.id && loadMore}
          hasMore={state.hasMore && !props.isLoading}
          threshold={300}
          useWindow
        >
          {comments}
        </InfiniteScroll>
      </div>
    </Content>
  );
};

export default withTranslation('materialsTabsComment')(MaterialsTabsComment);
