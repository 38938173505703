/* eslint-disable */
import React, { Component } from 'react';

import { Modal } from 'antd';

export class MyCalendarDay extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <>MyCalendar</>;
  }
}

export default MyCalendarDay;
