import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFile } from '@/store/files/actions';
import { getPokaFiles, setPokaFilesBlockFinish, setPokaFilesBlockStart } from '@/store/lms/actions';

import { selectLMS, selectPokaFiles } from '@/store/lms/selectors';

import { Button, Flex, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useIsMobile from '@shared/hooks/useIsMobile';

import Utils from '@/Utils';
import PropTypes from 'prop-types';

import css from './PokaFiles.module.scss';

import { FILE_IMAGE } from '@/constants/file-image';
import { FINISHED } from '@/constants/study-plan-statuses';

const MAX_FILE_NAME = 50;
const MAX_FILE_NAME_MOBILE = 20;

const PokaFiles = ({ block, getStatistic, getStatisticStart }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('pokaFiles');
  const { description, files } = useSelector(selectPokaFiles);
  const { statistic } = useSelector(selectLMS);
  const [isMobile] = useIsMobile(576);
  const next = statistic?.blocksStat && statistic.blocksStat.filter(item => item.status !== FINISHED).length;

  useEffect(() => {
    if (block?.isAvailableForStart) {
      dispatch(
        setPokaFilesBlockStart(block?.blockId, () => {
          getStatisticStart();
        })
      );
    }

    dispatch(getPokaFiles(block?.blockId));
  }, [block?.blockId, block?.isAvailableForStart]);

  const handleFinishBlock = () => {
    dispatch(
      setPokaFilesBlockFinish(block?.blockId, () => {
        getStatistic(next <= 1);
      })
    );
  };

  // скачивание файла
  const handleDownloadFile = file => {
    dispatch(
      getFile(file?.uuid, blob => {
        // Создаем URL для blob
        const url = window.URL.createObjectURL(blob);

        // Создаем временный элемент <a> для загрузки
        const a = document.createElement('a');
        a.href = url;
        a.download = file?.name;

        // Добавляем элемент <a> в документ
        document.body.appendChild(a);

        // Инициируем загрузку
        a.click();

        // Удаляем элемент <a> и освобождаем URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
    );
  };

  const fileRender = useMemo(
    () =>
      files.map(file => {
        const fileImage =
          FILE_IMAGE.find(type => type.type == file.type) ?? FILE_IMAGE.find(type => type.type === 'UNDEFINED');
        const fileSize = (file?.size / 1024 / 1024).toFixed(2);
        const fileNameLength = isMobile ? MAX_FILE_NAME_MOBILE : MAX_FILE_NAME;

        return (
          <div key={file?.uuid} className={css['PokaFiles-file']} onClick={() => handleDownloadFile(file)}>
            {fileImage && <img src={fileImage.image} alt='' />}
            <Flex wrap align='center' justify='center'>
              <Tooltip
                title={file?.name.length > fileNameLength && file?.name}
                className={css['PokaFiles-file-name-wrapper']}
                overlayStyle={{ wordBreak: 'break-all' }}
              >
                <span className={css['PokaFiles-file-name']}>{Utils.trStringFile(file?.name, fileNameLength)}</span>
                <span className={css['PokaFiles-file-size']}>({fileSize}MB)</span>
              </Tooltip>
            </Flex>
            <DownloadOutlined />
          </div>
        );
      }),
    [files, isMobile]
  );

  return (
    <div className={css['PokaFiles-wrapper']}>
      <div className={css['PokaFiles-header']}>
        <p className={css['PokaFiles-header-subtitle']}>{block?.type?.name}</p>
        <p className={css['PokaFiles-header-title']}>{block?.name}</p>
      </div>
      <p className={css['PokaFiles-description']}>{description}</p>

      <Flex className={css['PokaFiles-files-wrapper']} gap={20} vertical>
        {fileRender}
      </Flex>

      <Flex align='center' justify='center'>
        {block?.status !== FINISHED && (
          <Button type='primary' onClick={handleFinishBlock}>
            {next > 1 ? t('next') : t('end')}
          </Button>
        )}
      </Flex>
    </div>
  );
};

PokaFiles.propTypes = {
  block: PropTypes.object,
  getStatistic: PropTypes.func,
};

export default PokaFiles;
