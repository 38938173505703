export const absLinkTemplate = [
  {
    key: '/Links',
    label: 'linksMenu',
    children: [
      { childKey: '/the-absolute', to: 'https://digest.absgroup.ru/', childTranslate: 'theAbsolute' },
      { childKey: '/digest-alpina', to: 'https://absgr.alpinadigital.ru/', childTranslate: 'digestAlpina' },
      {
        childKey: '/library-podcast',
        to: 'https://portal.absgroup.ru/podcast',
        childTranslate: 'libraryPodcast',
      },
      { childKey: '/RBC-pro', to: 'https://pro.rbc.ru/', childTranslate: 'RBCPro' },
      {
        childKey: '/world-learning',
        to: 'https://kampus.team/absolut/the_world_of_learning/',
        childTranslate: 'worldLearning',
      },
    ],
  },
  {
    key: '/Links2',
    label: 'linksMenu2',
    children: [
      {
        childKey: 'secretStandart',
        to: 'https://kampus.team/absolut/project_manager_development_program/',
        childTranslate: 'secretStandart',
      },
      {
        childKey: 'projManager',
        to: 'https://kampus.team/absolut/project_coordinator_development_program/',
        childTranslate: 'projManager',
      },
      {
        childKey: 'projCoord',
        to: 'https://kampus.team/absolut/secretarial_standards/',
        childTranslate: 'projCoord',
      },
    ],
  },
];
