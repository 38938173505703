/* eslint-disable */
import React, { Component } from 'react';

import { Tag, Tooltip } from 'antd';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './CompetenciesTag.module.scss';

export class CompetenciesTagList extends Component {
  static propTypes = {
    competencies: PropTypes.array,
  };

  constructor(props) {
    super(props);
  }

  getCompetenceName = name => {
    let sliced = name.slice(0, 35);
    if (sliced.length < name.length) {
      sliced += '...';
    }

    return sliced;
  };

  showCompetencies() {
    let tag = [];
    const { competencies } = this.props;
    if (competencies && Array.isArray(competencies)) {
      competencies.map(competence => {
        tag.push(
          <Tag className={classNames(css['CompetenceTag'])} key={competence.id}>
            <Tooltip title={competence.name}>{this.getCompetenceName(competence.name)}</Tooltip>
          </Tag>
        );
      });
    }

    return tag;
  }

  render() {
    return <>{this.showCompetencies()}</>;
  }
}

export default CompetenciesTagList;
