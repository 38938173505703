import React, { useState } from 'react';

import classNames from 'classnames';

import css from './FullScreenButton.module.scss';

const FullScreenButton = ({ iframeRef }) => {
  const currentRef = iframeRef?.current || iframeRef;
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);
  const [currentStrokeColor, setCurrentStrokeColor] = useState('black');

  const onResizeWindow = trigger => {
    if (!trigger) {
      currentRef.style.position = 'fixed';
      currentRef.style.top = '0';
      currentRef.style.left = '0';
      currentRef.style.bottom = '0';
      currentRef.style.right = '0';
      currentRef.style.width = '100%';
      currentRef.style.height = '100%';
      currentRef.style.zIndex = '9998';
    } else {
      currentRef.style.position = 'relative';
      currentRef.style.width = '100%';
      currentRef.style.height = '100%';
      currentRef.style.zIndex = '1';
    }
  };

  const changeState = () => {
    if (!isFullScreenEnabled) {
      setIsFullScreenEnabled(true);
      setCurrentStrokeColor('orange');
    } else {
      setIsFullScreenEnabled(false);
      setCurrentStrokeColor('black');
    }
    onResizeWindow(isFullScreenEnabled);
  };

  return (
    <div
      className={classNames(css.FullScreenButton, isFullScreenEnabled && css['FullScreenButton-active'])}
      onClick={changeState}
    >
      <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='.5' y='.5' width='39' height='39' rx='19.5' fill='#fff' />
        <path fill='#fff' d='M12 12h16v16H12z' />
        <path
          d='M13.752 18v-4.243h4.243M26.252 22v4.245H22M26.252 18v-4.25H22M13.75 22v4.244H18'
          stroke={currentStrokeColor}
        />
        <rect x='.5' y='.5' width='39' height='39' rx='19.5' stroke='#DBDBDB' />
      </svg>
    </div>
  );
};

export default FullScreenButton;
