/* eslint-disable */
import {
  D360_VOTE_FAIL,
  D360_VOTE_START,
  D360_VOTE_SUCCESS,
  D360_VOTE_UPDATE_FAIL,
  D360_VOTE_UPDATE_START,
  D360_VOTE_UPDATE_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
};

export default (state = initialState, { type, response }) => {
  switch (type) {
    case D360_VOTE_START:
      return { ...state, loading: true };
    case D360_VOTE_SUCCESS:
      return { ...state, loading: false };
    case D360_VOTE_FAIL:
      return { ...state, loading: false };

    // VOTE UPDATE CASE
    case D360_VOTE_UPDATE_START:
      return { ...state, loading: true };
    case D360_VOTE_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case D360_VOTE_UPDATE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
