import React from 'react';
import { useTranslation } from 'react-i18next';

import { OPEN_ID } from '../Question/helper';
import { BlockStatus } from './types';

import { classNames } from '@shared/utils/styles-utils';

import css from './CurrentResultStatus.module.scss';

const cn = classNames(css);

const QuizStatusTextStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  ONREVIEW: 'on_review',
};

const PrintStatusText = ({ statusTexts }) => {
  return (
    <div
      className={cn(
        css.StatusText,
        { success: statusTexts.status === QuizStatusTextStatus.SUCCESS },
        { reject: statusTexts.status === QuizStatusTextStatus.FAILURE }
      )}
    >
      {statusTexts.texts.map(item => (
        <span key={item}>{item}</span>
      ))}
    </div>
  );
};

export const CurrentResultStatus = ({ detailsTest }) => {
  const { t } = useTranslation('details');
  const isRegularTest = detailsTest.resultTest.type !== OPEN_ID; // без открытого вопроса
  const withPassingScore = detailsTest.resultTest.pass !== 0; // с проходным баллом

  const withReview = detailsTest.resultTest.review;

  const isFinished = detailsTest.status === BlockStatus.FINISHED;
  const isRejected = detailsTest.status === BlockStatus.REJECTED;
  const isWaiting = detailsTest.status === BlockStatus.WAITING;

  const isPassingScoreSuccess = +detailsTest.resultTest.countCorrectUserAnswers >= detailsTest.resultTest.pass;

  const countOpenQuestions = detailsTest.resultTest.questions.reduce((acc, question) => {
    if (question.type === OPEN_ID) return acc + 1;

    return acc;
  }, 0);

  const allNotCorrectNotOpenQuestions =
    +detailsTest.resultTest.countCorrectUserAnswers === +countOpenQuestions
      ? false
      : +detailsTest.resultTest.countCorrectUserAnswers - +countOpenQuestions === 0; // все неоткрытые вопросы неправильные или false

  const allOpenQuestions = countOpenQuestions === detailsTest.resultTest.countQuestions;

  const statusTexts = {
    testAutoPassingTexts: {
      status: QuizStatusTextStatus.SUCCESS,
      texts: [t('quiz_passed')],
    },
    testFailureText: {
      status: QuizStatusTextStatus.FAILURE,
      texts: [t('quiz_failed')],
    },
    testAutoFailureTexts: {
      status: QuizStatusTextStatus.FAILURE,
      texts: [
        t('quiz_failed'),
        t('you_need_to_answer', {
          total: detailsTest.resultTest.countQuestions,
          current: detailsTest.resultTest.pass,
        }),
      ],
    },
    testReviewPassingTexts: {
      status: QuizStatusTextStatus.SUCCESS,
      texts: [t('quiz_accepted_by_admin')],
    },
    testReviewFailureTexts: {
      status: QuizStatusTextStatus.FAILURE,
      texts: [t('quiz_failed_by_admin')],
    },
    testOnReviewTexts: {
      status: QuizStatusTextStatus.ONREVIEW,
      texts: [t('you_test_on_review')],
    },
    emptyBlockTexsts: {
      status: QuizStatusTextStatus.ONREVIEW,
      texts: [],
    },
  };

  const getStatus = () => {
    switch (true) {
      case allNotCorrectNotOpenQuestions:
        return statusTexts.testFailureText;

      case isFinished && withReview:
        return statusTexts.testReviewPassingTexts;

      case isRegularTest && !isPassingScoreSuccess && !withReview:
      case !isRegularTest && withPassingScore && !isPassingScoreSuccess:
        return statusTexts.testAutoFailureTexts;

      case allOpenQuestions:
      case isRegularTest && !withPassingScore && !withReview:
      case isRegularTest && isPassingScoreSuccess && !withReview:
      case !isRegularTest && !withReview && isPassingScoreSuccess:
        return statusTexts.testAutoPassingTexts;

      case isWaiting:
        return statusTexts.testOnReviewTexts;

      case isRejected:
        return statusTexts.testReviewFailureTexts;

      case !isRegularTest:
      default:
        return statusTexts.emptyBlockTexsts;
    }
  };

  return <PrintStatusText statusTexts={getStatus()} />;
};
