/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDepartments } from '@/store/department/actions';
import { getFunctions } from '@/store/function/actions';
import { getPositions } from '@/store/position/actions';
import { getUsers } from '@/store/users/actions';

import { selectDepartments } from '@/store/department/selectors';
import { selectFunctions } from '@/store/function/selectors';
import { selectPositions } from '@/store/position/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Col, Row, Table, Tooltip } from 'antd';
import BadgeElement from '@/components/BadgeElement';
import BadgeModal from '@/components/BadgeModal';
import { HiOutlineBadgeCheck, HiOutlineClipboardCheck, HiOutlineClock } from 'react-icons/hi';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css2 from '../index.module.scss';
import css from './Views.module.scss';

export const ViewDefault = props => {
  const navigate = useNavigate();

  const [filterDepartment, setFilterDepartment] = useState([]);
  const [filterPosition, setFilterPosition] = useState([]);
  const [filterFunction, setFilterFunction] = useState([]);
  const [openBadgeModal, setOpenBadgeModal] = useState(false);
  const [modalBadges, setModalBadges] = useState([]);

  useEffect(() => {
    props.getUsers({ isDepartment: props.isMyDepartment, status: 'ACTIVE', withRewards: props.hasCoins });
    props.getDepartments({ size: 1000 });
    props.getFunctions({ size: 1000 });
    props.getPositions({ size: 1000 });
  }, []);

  useEffect(() => {
    if (props.departments && Array.isArray(props.departments)) {
      setFilterDepartment(
        props.departments.map(item => {
          const resultDepartments = {};
          resultDepartments.text = item.name;
          resultDepartments.value = item.name;
          return resultDepartments;
        })
      );
    }
    if (props.positions && Array.isArray(props.positions.content)) {
      setFilterPosition(
        props.positions.content.map(item => {
          const resultPositions = {};
          resultPositions.text = item.name;
          resultPositions.value = item.name;
          return resultPositions;
        })
      );
    }
    if (props.functions && Array.isArray(props.functions)) {
      setFilterFunction(
        props.functions.map(item => {
          const resultFunctions = {};
          resultFunctions.text = item.name;
          resultFunctions.value = item.name;
          return resultFunctions;
        })
      );
    }
  }, [props, props.usersAll, props.departments, props.positions, props.functions]);

  const sortFilterByText = (a, b) => {
    let aText = a.text;
    let bText = b.text;
    if (!isNaN(aText) && !isNaN(bText)) {
      return Number(aText) - Number(bText);
    }

    if (!isNaN(aText) && isNaN(bText)) return -1;

    if (isNaN(aText) && !isNaN(bText)) return 1;

    if (aText < bText) {
      return -1;
    } else if (aText === bText) {
      return 0;
    } else {
      return 1;
    }
  };

  const getColumns = () => {
    const { t, hasDepartment, hasPosition, hasFunction, hasCoins, txtDepartment, txtPosition, txtFunction } = props;

    let columns = [];

    columns.push({
      title: t('username'),
      dataIndex: 'lastName',
      key: 'lastName',
      defaultSortOrder: 'ascend',
      sorter: true,
    });

    if (hasCoins === true) {
      columns.push({
        title: t('score'),
        dataIndex: 'account',
        key: 'account',
        align: 'center',
        sorter: true,
      });
    }

    if (hasDepartment === true) {
      columns.push({
        title: txtDepartment,
        dataIndex: 'department',
        key: 'department',
        filters: filterDepartment,
        filterMultiple: false,
      });
    }

    if (hasPosition === true) {
      columns.push({
        title: txtPosition,
        dataIndex: 'position',
        key: 'position',
        filters: filterFunction,
        filterMultiple: false,
      });
    }

    if (hasFunction === true) {
      columns.push({
        title: txtFunction,
        dataIndex: 'function',
        key: 'function',
        filters: filterPosition,
        filterMultiple: false,
      });
    }
    if (hasCoins === true) {
      columns.push({
        title: t('badge'),
        dataIndex: 'rewards',
        key: 'rewards',
        width: 180,
      });
    }

    return columns;
  };

  const renderBadge = user => {
    if (user?.rewards?.length) {
      if (user?.rewards?.length > 3) {
        return (
          <>
            {user?.rewards?.slice(0, 3)?.map((elem, index) => (
              <div className={css.Badge__icon} key={index}>
                <BadgeElement small data={elem} />
              </div>
            ))}
            <div className={css.Badge__icon}>
              <div className={css.Badge__count}>{user?.rewards?.slice(3).length}</div>
            </div>
          </>
        );
      } else {
        return user?.rewards?.map((elem, index) => (
          <div className={css.Badge__icon} key={index}>
            <BadgeElement small data={elem} />
          </div>
        ));
      }
    } else {
      return props.t('noBadge');
    }
  };

  const onOpenBadgeModal = data => {
    setOpenBadgeModal(true);
    setModalBadges(data);
  };

  const getData = () => {
    const { searchString, t, usersAll } = props;
    let data = [];
    if (Array.isArray(usersAll)) {
      usersAll.map(user => {
        if (usersAll.department === null) {
          user.department = t('noData');
        }

        if (usersAll.position === null) {
          user.position = t('noData');
        }

        if (usersAll.function === null) {
          user.function = t('noData');
        }

        let object = {};
        let username = (user.lastName || '') + ' ' + (user.firstName || '') + ' ' + (user.middleName || '');
        object.lastName = (
          <>
            <span className={css2['userLink']} onClick={() => navigate(`/collegues/${user.id}`)}>
              {username}
            </span>
            <div className={css2['userStatistics']}>
              <Tooltip placement='top' title={t('finished')}>
                <div className={css2['iconGreen']}>
                  <HiOutlineBadgeCheck />
                  &nbsp;{user.finished || 0}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('planned')}>
                <div className={css2['iconBlue']}>
                  <HiOutlineClock />
                  &nbsp;{user.planned || 0}
                </div>
              </Tooltip>

              <Tooltip placement='top' title={t('created')}>
                <div className={css2['iconRed']}>
                  <HiOutlineClipboardCheck />
                  &nbsp;{user.created || 0}
                </div>
              </Tooltip>
            </div>
          </>
        );
        object.account = user.account || 0;
        object.department = user.department || t('noData');
        object.position = user.position || t('noData');
        object.function = user.function || t('noData');
        object.key = user.id;
        object.rewards = (
          <div
            className={classNames(css.Badge, { [css.Badge__pointer]: user?.rewards?.length })}
            onClick={user?.rewards?.length ? () => onOpenBadgeModal(user?.rewards) : null}
          >
            {renderBadge(user)}
          </div>
        );
        if (username.length && searchString.length) {
          data.push(object);
        } else {
          data.push(object);
        }
      });

      const filterDepartmentCopy = filterDepartment.sort(sortFilterByText);
      const filterFunctionCopy = filterFunction.sort(sortFilterByText);
      const filterPositionCopy = filterPosition.sort(sortFilterByText);

      filterDepartmentCopy.sort(sortFilterByText);
      filterFunctionCopy.sort(sortFilterByText);
      filterPositionCopy.sort(sortFilterByText);
    }
    return data;
  };

  const onChangeTable = (pagination, filters, sorter) => {
    props.getUsers({
      isDepartment: props.isMyDepartment,
      flexibleName: props.searchString ? props.searchString : '',
      page: pagination.current - 1,
      department: filters.hasOwnProperty('department') ? filters.department?.[0] : '',
      position: filters.hasOwnProperty('position') ? filters.position?.[0] : '',
      function: filters.hasOwnProperty('function') ? filters.function?.[0] : '',
      sort: sorter.hasOwnProperty('order') ? `${sorter.columnKey},${sorter.order == 'ascend' ? 'asc' : 'desc'}` : '',
      status: 'ACTIVE',
      withRewards: props.hasCoins,
    });
  };

  const pagination =
    props.usersTotal > props.usersPageSize
      ? {
          total: props.usersTotal,
          pageSize: props.usersPageSize,
          pageNumber: props.usersPageNumber,
          showSizeChanger: false,
          responsive: true,
          hideOnSinglePage: true,
        }
      : false;

  const render = () => {
    return (
      <Row className={css['Views-tableWrapper']}>
        <Col span={24} style={{ padding: '1rem' }}>
          <Table
            // className={css['Views-table']}
            columns={getColumns()}
            dataSource={getData()}
            loading={props.isLoading}
            bordered={true}
            pagination={pagination}
            onChange={onChangeTable}
            scroll={{ x: true }}
          />
        </Col>
        <BadgeModal data={modalBadges} withOutAction open={openBadgeModal} onCancel={() => setOpenBadgeModal(false)} />
      </Row>
    );
  };
  return render();
};

ViewDefault.propTypes = {
  t: PropTypes.func,
  users: PropTypes.array,
  hasDepartment: PropTypes.bool,
  hasPosition: PropTypes.bool,
  hasFunction: PropTypes.bool,
  searchString: PropTypes.string,
  txtDepartment: PropTypes.string,
  txtPosition: PropTypes.string,
  txtFunction: PropTypes.string,
  getUsers: PropTypes.func,
  getDepartments: PropTypes.func,
  getFunctions: PropTypes.func,
  getPositions: PropTypes.func,
  isMyDepartment: PropTypes.bool,
};

const mapStateToProps = createSelector(
  [selectUsers, selectDepartments, selectFunctions, selectPositions],
  (users, departments, functions, positions) => ({
    usersTotal: users.usersTotal,
    usersPageSize: users.usersPageSize,
    usersPageNumber: users.usersPageNumber,
    usersAll: users.usersList,
    departments: departments.departments,
    functions: functions.functions,
    positions: positions.positions,
    isLoading: users.isLoading,
  })
);

const mapActionsToProps = {
  getUsers,
  getDepartments,
  getFunctions,
  getPositions,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('colleague')(ViewDefault));
