/* eslint-disable */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Spin, Tooltip } from 'antd';

import TypeBadge from '../TypeBadge';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './DoublesList.module.scss';

class DoublesList extends Component {
  static propTypes = {
    isLoadingDuplicate: PropTypes.bool,
    list: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoadingDuplicate: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isLoadingDuplicate !== this.props.isLoadingDuplicate) {
      this.setState({
        isLoadingDuplicate: newProps.isLoadingDuplicate,
      });
    }
  }

  renderMessage = flag => {
    if (!flag) {
      return <div className={css['DoublesList-info']}>{this.props.t('noMatches')}</div>;
    }
    return (
      <div className={classNames(css['DoublesList-info'], css.Spinner)}>
        <Spin size={'small'} />
        <span>{this.props.t('searchingForMatches')}</span>
      </div>
    );
  };

  renderDoublItem = list =>
    list.map((item, index) => {
      return (
        <Link
          to={`/collection-topics/${item.id}`}
          key={`${item.name}-${index}`}
          target='_blank'
          className={css['DoublesList-list-item']}
        >
          <div className={css['DoublesList-list-item-icon']}>
            {item.type && <TypeBadge typeId={item.type.id} noText />}
          </div>
          <div className={css['DoublesList-list-item-name']}>
            {item.name && item.name.length > 70 ? (
              <Tooltip placement='top' title={item.name}>
                {`${item.name.substring(0, 70)}...`}
              </Tooltip>
            ) : (
              item.name
            )}
          </div>
        </Link>
      );
    });

  render() {
    const { list } = this.props;
    const { isLoadingDuplicate } = this.state;
    return (
      <div className={css.DoublesList}>
        <div className={css['DoublesList-title']}>{this.props.t('sameMaterials')}</div>
        <div className={css['DoublesList-list']}>
          {(list && list.length) > 0 ? this.renderDoublItem(list) : this.renderMessage(isLoadingDuplicate)}
        </div>
      </div>
    );
  }
}

export default withTranslation('doublesList')(DoublesList);
