import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { createChatGroup } from '@/store/chat/actions';
import { createFileImage } from '@/store/files/actions';

import { Button, Form, Modal, Tooltip } from 'antd';
import { FiPlus } from 'react-icons/fi';

import TitleGroup from './TitleGroup';
import UsersList from './UsersList';

import PropTypes from 'prop-types';

import styles from '../GroupsList.module.scss';

const RENDER_OPTIONS = {
  MAIN_INFO: 'MAIN_INFO',
  USER_INFO: 'USER_INFO',
};

const NewGroup = () => {
  const [visible, setVisible] = useState(false);
  const [render, setRender] = useState(RENDER_OPTIONS.MAIN_INFO);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [name, setName] = useState(undefined);

  const dispatch = useDispatch();
  const form = Form.useForm();

  const { t } = useTranslation('chat');

  const onSubmitForm = () => {
    if (RENDER_OPTIONS.MAIN_INFO === render) {
      setRender(RENDER_OPTIONS.USER_INFO);
    } else if (RENDER_OPTIONS.USER_INFO === render) {
      const createGroup = res => {
        dispatch(
          createChatGroup(
            name,
            res ? res.uuid : null,
            selectedUser.map(user => user.id)
          )
        );
        onClose();
      };

      if (imageBlob) {
        const formData = new FormData();
        formData.append('file', imageBlob);
        dispatch(createFileImage(formData, res => createGroup(res)));
      } else {
        createGroup();
      }
    }
  };

  const onClose = () => {
    setVisible(false);
    setRender(RENDER_OPTIONS.MAIN_INFO);
    setImageBlob(null);
    setImagePreview(null);
    setName(null);
    setSelectedUser([]);
  };

  const updateImage = blob => {
    setImagePreview(URL.createObjectURL(blob));
    setImageBlob(blob);
  };

  const handleSelectUser = newUser => {
    if (selectedUser.filter(user => user.id === newUser.id).length) {
      setSelectedUser(selectedUser.filter(user => user.id !== newUser.id));
    } else {
      setSelectedUser(prev => [...prev, newUser]);
    }
  };

  const renderStatus = () => {
    switch (render) {
      case RENDER_OPTIONS.MAIN_INFO:
        return (
          <TitleGroup
            onSubmitForm={onSubmitForm}
            name={name}
            setName={setName}
            updateImage={updateImage}
            imagePreview={imagePreview}
            form={form}
            handleCancelButton={handleCancelButton}
          />
        );

      case RENDER_OPTIONS.USER_INFO:
        return <UsersList onSelectUser={handleSelectUser} selectedUser={selectedUser} />;
      default:
        break;
    }
  };

  const handleCancelButton = () => {
    switch (render) {
      case RENDER_OPTIONS.MAIN_INFO:
        onClose();
        break;

      case RENDER_OPTIONS.USER_INFO:
        setRender(RENDER_OPTIONS.MAIN_INFO);
        break;
      default:
        break;
    }
  };

  const customFooter = (
    <div className={styles.groups__block}>
      <Button htmlType='submit' onClick={onSubmitForm} type='primary'>
        {t('ok')}
      </Button>
      <Button onClick={handleCancelButton} ghost>
        {t('cancel')}
      </Button>
    </div>
  );

  if (visible) {
    return (
      <Modal
        open={visible}
        onCancel={onClose}
        onOk={onSubmitForm}
        footer={RENDER_OPTIONS.MAIN_INFO === render ? null : customFooter}
        closable={false}
        width={500}
      >
        {renderStatus()}
      </Modal>
    );
  } else {
    return (
      <div className={styles.groups__add} onClick={() => setVisible(true)}>
        <Tooltip title={t('createGroupTitle')}>
          <FiPlus size={18} />
        </Tooltip>
      </div>
    );
  }
};

NewGroup.propTypes = {
  form: PropTypes.object,
};

export default NewGroup;
