// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-hKTbYo.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-CjE7Ak .user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-label-QGDdiO[data-value="Montserrat"]:before, .user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-hKTbYo.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-CjE7Ak .user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-item-oAYiBu[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-Montserrat-zyu9u9 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller-LE51fy {
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 30px;
  display: flex;
}

.user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller__button-HIi92w {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./user/src/pages/CollectionMaterialsIDStudy/Presentation/parts/Controller/Controller.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAOA;EAJE,uBAAuB;EACvB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAMA;EAHE,eAAe;EACf,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n.Controller {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  gap: 20px;\n  justify-content: center;\n}\n.Controller__button {\n  width: 30px;\n  height: 30px;\n  cursor: pointer;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-hKTbYo`,
	"qlPicker": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-hKTbYo`,
	"ql-font": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-CjE7Ak`,
	"qlFont": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-CjE7Ak`,
	"ql-picker-label": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-label-QGDdiO`,
	"qlPickerLabel": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-label-QGDdiO`,
	"ql-picker-item": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-item-oAYiBu`,
	"qlPickerItem": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-picker-item-oAYiBu`,
	"ql-font-Montserrat": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-Montserrat-zyu9u9`,
	"qlFontMontserrat": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__ql-font-Montserrat-zyu9u9`,
	"Controller": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller-LE51fy`,
	"controller": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller-LE51fy`,
	"Controller__button": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller__button-HIi92w`,
	"controllerButton": `user-src-pages-CollectionMaterialsIDStudy-Presentation-parts-Controller-Controller-module__Controller__button-HIi92w`
};
export default ___CSS_LOADER_EXPORT___;
