import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProducts } from '@/store/shop/actions';

import { selectShop } from '@/store/shop/selectors';
import { selectUsers } from '@/store/users/selectors';

import { Button, Input, Layout, Pagination, Spin, Typography } from 'antd';
import CustomEmpty from '@/components/CustomEmpty';
import { CoinsIcon } from '@/components/elements';
import { Content } from '@/components/Layout';
import MultiCard from '@/components/MultiCard';

import useIsMobile from '@shared/hooks/useIsMobile';

import Utils from '@/Utils';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import css from './Shop.module.scss';

const { Search } = Input;
const { Title } = Typography;

const Shop = ({ navigate }) => {
  const { t } = useTranslation('shop');
  const { products, pagination, isLoading } = useSelector(selectShop);
  const { currentUser } = useSelector(selectUsers);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const PAGE_SIZE = 20;

  const [isMobile] = useIsMobile(769);

  const renderButton = id => (
    <Button
      onClick={() => navigate(`/shop/${id}`)}
      className={css['Shop-container-item-button']}
      type='primary'
      size='large'
    >
      {t('more')}
    </Button>
  );

  useEffect(() => {
    let controller = new AbortController();
    let signal = controller.signal;

    dispatch(getProducts(0, '', PAGE_SIZE, signal));

    return () => controller.abort();
  }, [dispatch]);

  const renderCards = () => {
    if (Array.isArray(products) && products.length > 0) {
      return (
        <div className={css['Shop-container-items']}>
          {products.map(item => (
            <MultiCard
              key={item?.id}
              imageUuid={item?.photoUuid}
              title={item?.name}
              onClick={() => navigate(`/shop/${item?.id}`)}
              parametrs={[
                {
                  label: t('cost'),
                  value: (
                    <div>
                      {item?.cost}{' '}
                      <CoinsIcon
                        uuidCoinsIcon={currentUser.domainCompany.coinIcon}
                        className={css['Shop-container-items-coin']}
                      />
                    </div>
                  ),
                },
                {
                  label: t('count'),
                  value: item?.count,
                },
              ]}
              action={renderButton(item?.id)}
            />
          ))}
        </div>
      );
    } else {
      let formattedSearchText = '';
      if (search) {
        if (isMobile) {
          if (search.length > 20) {
            formattedSearchText = Utils.trString(search, 20);
          } else {
            formattedSearchText = search + '...';
          }
        } else {
          if (search.length > 40) {
            formattedSearchText = Utils.trString(search, 40);
          } else {
            formattedSearchText = search;
          }
        }
      }
      return (
        <CustomEmpty title={formattedSearchText ? t('emptySearch', { search: formattedSearchText }) : t('empty')} />
      );
    }
  };

  const onChangePage = page => {
    dispatch(getProducts(page - 1, search, PAGE_SIZE));
  };

  const getSearchDebounced = useCallback(
    _debounce(val => {
      dispatch(getProducts(0, val, PAGE_SIZE));
    }, 400),
    []
  );

  const onSearch = val => {
    setSearch(val);
    getSearchDebounced(val);
  };

  return (
    <Layout>
      <Content>
        <div className={css['Shop-header']}>
          <div className={css['Shop-header-title']}>
            <Title level={1}>{t('title')}</Title>
          </div>
          <div>
            <Search
              className={classNames(css['Shop-header-search'], 'smallSizeSearchButton')}
              size='large'
              placeholder={t('searchPlaceholder')}
              onChange={e => onSearch(e.target.value)}
              value={search}
              enterButton
            />
          </div>
        </div>
        <div className={css['Shop-container']}>
          {isLoading ? <Spin size='large' /> : renderCards()}
          {pagination.total > PAGE_SIZE && !isLoading && (
            <Pagination
              current={pagination.current + 1}
              total={pagination.total}
              pageSize={pagination.pageSize}
              onChange={onChangePage}
              className={css['Shop-container-pagination']}
              showSizeChanger={false}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

Shop.propTypes = {
  navigate: PropTypes.func,
};

export default Shop;
