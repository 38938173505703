// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/@rsbuild+core@1.1.0/node_modules/@rsbuild/core/compiled/css-loader/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-src-components-elements-select-styles-module__ql-picker-jNOqFG.user-src-components-elements-select-styles-module__ql-font-Uw43ZQ .user-src-components-elements-select-styles-module__ql-picker-label-q4ShuR[data-value="Montserrat"]:before, .user-src-components-elements-select-styles-module__ql-picker-jNOqFG.user-src-components-elements-select-styles-module__ql-font-Uw43ZQ .user-src-components-elements-select-styles-module__ql-picker-item-qo2idR[data-value="Montserrat"]:before {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  content: "Montserrat", Helvetica, Arial, sans-serif !important;
}

.user-src-components-elements-select-styles-module__ql-font-Montserrat-Rt_SF9 {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.user-src-components-elements-select-styles-module__select__dropdown-Qr55K4 {
  box-shadow: none;
  background-color: #f2f2f2;
  border: 1px solid #dadada;
  border-radius: 0 0 17px 17px;
  font-size: 13px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.user-src-components-elements-select-styles-module__select__clear-icon-Agdi3e {
  margin: -4px 0 0 -10px;
}
`, "",{"version":3,"sources":["webpack://./user/src/components/elements/select/styles.module.scss"],"names":[],"mappings":"AAGA;EAFE,qDAAqD;EACrD,8DAA8D;AAChE;;AAOA;EAJE,qDAAqD;AACvD;;AAQA;EALE,gBAAgB;EAChB,yBAAyB;EACzB,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,gBAAgB;EAChB,+BAA+B;AACjC;;AAOA;EAJE,sBAAsB;AACxB","sourcesContent":["@charset \"UTF-8\";\n/* stylelint-disable */\n/* устанавливаем кастомный выбор шрифта*/\n.ql-picker.ql-font .ql-picker-label[data-value=Montserrat]::before,\n.ql-picker.ql-font .ql-picker-item[data-value=Montserrat]::before {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n  content: \"Montserrat\", Helvetica, Arial, sans-serif !important;\n}\n\n/* Set content font-families */\n.ql-font-Montserrat {\n  font-family: \"Montserrat\", Helvetica, Arial, sans-serif;\n}\n\n/* stylelint-disable */\n.select__dropdown {\n  font-size: 13px;\n  box-shadow: none;\n  background-color: #f2f2f2;\n  border: 1px solid #dadada;\n  border-radius: 0 0 17px 17px;\n  transform: translate3d(0, 0, 0);\n  overflow: hidden;\n}\n.select__clear-icon {\n  margin: -4px 0 0 -10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ql-picker": `user-src-components-elements-select-styles-module__ql-picker-jNOqFG`,
	"qlPicker": `user-src-components-elements-select-styles-module__ql-picker-jNOqFG`,
	"ql-font": `user-src-components-elements-select-styles-module__ql-font-Uw43ZQ`,
	"qlFont": `user-src-components-elements-select-styles-module__ql-font-Uw43ZQ`,
	"ql-picker-label": `user-src-components-elements-select-styles-module__ql-picker-label-q4ShuR`,
	"qlPickerLabel": `user-src-components-elements-select-styles-module__ql-picker-label-q4ShuR`,
	"ql-picker-item": `user-src-components-elements-select-styles-module__ql-picker-item-qo2idR`,
	"qlPickerItem": `user-src-components-elements-select-styles-module__ql-picker-item-qo2idR`,
	"ql-font-Montserrat": `user-src-components-elements-select-styles-module__ql-font-Montserrat-Rt_SF9`,
	"qlFontMontserrat": `user-src-components-elements-select-styles-module__ql-font-Montserrat-Rt_SF9`,
	"select__dropdown": `user-src-components-elements-select-styles-module__select__dropdown-Qr55K4`,
	"selectDropdown": `user-src-components-elements-select-styles-module__select__dropdown-Qr55K4`,
	"select__clear-icon": `user-src-components-elements-select-styles-module__select__clear-icon-Agdi3e`,
	"selectClearIcon": `user-src-components-elements-select-styles-module__select__clear-icon-Agdi3e`
};
export default ___CSS_LOADER_EXPORT___;
