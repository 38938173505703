import {
  CREATE_ORDER_FAIL,
  CREATE_ORDER_START,
  CREATE_ORDER_SUCCESS,
  GET_PRODUCT_BY_ID_FAIL,
  GET_PRODUCT_BY_ID_START,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
} from './constants';

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  product: null,
  pagination: {
    current: 0,
    total: 0,
    pageSize: 20,
  },
  isLoadingPurchase: false,
};

/* eslint-disable */
export default (state = initialState, { type, response }) => {
  switch (type) {
    case GET_PRODUCT_BY_ID_START:
    case GET_PRODUCTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: response?.content,
        pagination: {
          total: response?.totalElements,
          current: response?.number,
          pageSize: response?.size,
        },
        isLoading: false,
      };

    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        product: response,
        isLoading: false,
      };

    case CREATE_ORDER_START:
      return {
        ...state,
        isLoadingPurchase: true,
      };

    case CREATE_ORDER_FAIL:
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingPurchase: false,
      };

    case GET_PRODUCTS_FAIL:
    case GET_PRODUCT_BY_ID_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return {
        ...state,
      };
  }
};
