/* eslint-disable */
import React, { Component } from 'react';

import { Tooltip } from 'antd';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

export default class CoinHistoryRow extends Component {
  static propTypes = {
    userIncome: PropTypes.object.isRequired,
    t: PropTypes.func,
  };

  render() {
    const { userIncome, t } = this.props;
    return (
      <tr>
        <Tooltip title={dayjs(userIncome.date).format('DD.MM.YYYY, h:mm:ss a')}>
          <td>{dayjs(userIncome.date).format('DD.MM.YYYY')}</td>
        </Tooltip>
        <td>{userIncome.amount > 0 ? '' : userIncome.amount}</td>
        <td>{userIncome.amount < 0 ? '' : userIncome.amount}</td>
        <td>
          {userIncome?.productId ? (
            <a href={`/shop/${userIncome?.productId}`}>{userIncome.comment}</a>
          ) : (
            <Tooltip title={userIncome?.comment || userIncome?.bonus}>{userIncome.comment || userIncome.bonus}</Tooltip>
          )}
          <br />
          {userIncome?.orderStatus && t('orderStatus', { status: t(userIncome?.orderStatus) })}
        </td>
      </tr>
    );
  }
}
